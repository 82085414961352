import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useEffect, useState} from "react"

import {useInsights} from "components/template-insights/insights-context"

import {fetchInsightJourneyDonut} from "lib/api"

import StatsPie from "./stats-pie"

const JourneysMetrics = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const classes = useStyles()

  const {
    addInsightsCache,
    insights: {journey_donut},
    templateId,
    filter,
  } = useInsights()

  const stats = journey_donut?.value?.stats ?? []
  const total = journey_donut?.value?.totalJourneys ?? 0

  useEffect(() => {
    fetchInsightJourneyDonut(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, filter, templateId])

  if (!isLoaded) return <>Loading...</>

  if (!stats.length)
    return (
      <>
        <Typography className={classes.header} variant="h5">
          Completed Journeys
        </Typography>
        <div>No data can be found.</div>
      </>
    )

  return (
    <>
      <Typography className={classes.header} variant="h5" style={{marginTop: 0}}>
        Journeys
      </Typography>
      <StatsPie
        colors={["#002956", "#489AD4", "#5CBDAF", "#EF6568", "#5C6565"]}
        data={stats}
        total={total}
        totalLabel="Journeys"
      />
    </>
  )
}

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 25,
    fontWeight: 200,
    marginTop: 80,
  },
}))

export default JourneysMetrics
