import camelCase from "lodash/camelCase"
import {node} from "prop-types"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import {useSelector} from "react-redux"

import {createHandler, useSocket} from "contexts/socket-manager"
import {toCamelCase} from "lib/case-converter"

import {actions, initialState, reducer} from "./advanced-team-insights-reducer"

const AdvancedTeamInsightsContext = createContext()

const {SET_ADVANCED_TEAM_INSIGHTS, SET_FILTER_PARAMS, SET_IS_LOADING, SET_SOCKET_OPTION} = actions

export const AdvancedTeamInsightsContextProvider = ({children}) => {
  const teamId = useSelector(({session}) => session.team)?.id
  const [state, dispatch] = useReducer(reducer, initialState)
  const [hasSocket, setHasSocket] = useState(false)

  const setFilterParams = useCallback(
    ({startDate, endDate, objective, attributionModel, templateStatus}) =>
      dispatch({
        type: SET_FILTER_PARAMS,
        startDate,
        endDate,
        objective,
        attributionModel,
        templateStatus,
      }),
    []
  )

  const setAdvancedTeamInsights = (name, insightsCache, args) =>
    dispatch({type: SET_ADVANCED_TEAM_INSIGHTS, args, name, insightsCache})

  const setIsLoading = (name, value) =>
    dispatch({type: SET_IS_LOADING, name: `${name}IsLoading`, value})

  const setSocketOptions = (name, value) => dispatch({type: SET_SOCKET_OPTION, name, value})

  const fetchInsights = useCallback((name, fetcher, args, socketOptions = {}) => {
    setIsLoading(name, true)
    setSocketOptions(name, socketOptions)

    fetcher(...args).then(res => {
      if (res) {
        setAdvancedTeamInsights(name, res.value, res.args)
        setIsLoading(name, false)
      }
      // else it's going to be coming back by socket
    })
  }, [])

  const {addHandler, removeHandler} = useSocket()

  useEffect(() => {
    if (!teamId) return

    const teamInsights = createHandler(
      `insights:${teamId}:team`,
      "insights_cached",
      _insightsCache => {
        const name = camelCase(_insightsCache.name)
        const socketOptions = state.socketOptions[name]

        const {args, value} = toCamelCase(_insightsCache, {
          ignoreKeys: socketOptions?.ignoreCaseConversionForKeys,
          ignorePaths: socketOptions?.ignoreCaseConversionForPaths,
        })

        setAdvancedTeamInsights(name, value, args)
        setIsLoading(name, false)
      }
    )

    addHandler(teamInsights)

    setHasSocket(true)

    return () => removeHandler(teamInsights)
  }, [addHandler, removeHandler, state.socketOptions, teamId])

  const value = useMemo(
    () => ({
      ...state,
      fetchInsights,
      hasSocket,
      setFilterParams,
    }),
    [fetchInsights, hasSocket, setFilterParams, state]
  )

  return (
    <AdvancedTeamInsightsContext.Provider value={value}>
      {children}
    </AdvancedTeamInsightsContext.Provider>
  )
}

AdvancedTeamInsightsContextProvider.propTypes = {
  children: node.isRequired,
}

export const provideAdvancedTeamInsights = Component => props => (
  <AdvancedTeamInsightsContextProvider>
    <Component {...props} />
  </AdvancedTeamInsightsContextProvider>
)

export const useAdvancedTeamInsights = () => useContext(AdvancedTeamInsightsContext)

export const advancedTeamInsightsContext = Component => props => (
  <Component {...useAdvancedTeamInsights()} {...props} />
)
