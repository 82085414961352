import camelCase from "lodash/camelCase"
import {arrayOf, object, string} from "prop-types"
import React, {useMemo} from "react"

const DefaultEntity = ({customStyle, defaultValue, paramParts, target}) => {
  const formatPhone = phoneString =>
    phoneString.replace(/^(1|)?(\d{3})(\d{3})(\d{4})$/, "($2) $3-$4")

  const replacementValue = useMemo(() => {
    if (!paramParts || !target) {
      return defaultValue
    }

    // paramParts is an array of strings used to look up the value
    // in the target object, e.g., ["meta_private", "account_number"]
    // - In most cases, the string at array index 0 is the desired key
    // - For nested objects, like product or meta_public and meta_private,
    //   additional array indexes may be used to reach the desired key
    switch (paramParts[0]) {
      case "contact_account":
        return target?.contactAccount?.[paramParts[1]] || defaultValue
      case "meta_private":
      case "meta_public":
        const [metaKey, ...rest] = paramParts
        const metaProperty = rest.join(".")

        return !!target?.[metaKey]?.[metaProperty]
          ? target[metaKey][metaProperty].toString()
          : defaultValue
      case "nameFull":
        const first = target.nameFirst || ""
        const last = target.nameLast || ""

        return !!first || !!last ? `${first} ${last}`.trim() : defaultValue
      case "product":
        return target?.product?.[paramParts[1]] || defaultValue
      default:
        return target?.[camelCase(paramParts[0])] || defaultValue
    }
  }, [defaultValue, paramParts, target])

  return (
    <span className="personalization-widget" style={customStyle}>
      {paramParts[0].match(/^phone/) ? formatPhone(replacementValue) : replacementValue}
    </span>
  )
}

DefaultEntity.propTypes = {
  customStyle: object,
  defaultValue: string,
  paramParts: arrayOf(string),
  target: object,
}

export default DefaultEntity
