import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material"
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete"
import makeStyles from "@mui/styles/makeStyles"
import {any, func, object} from "prop-types"
import {useEffect, useState} from "react"

import {createService, fetchServices} from "lib/api"
import {requiredField} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

import Feature from "../feature/feature"

const HalfColumn = ({children}) => (
  <Grid item lg={6} md={12} xs={12}>
    {children}
  </Grid>
)

HalfColumn.propTypes = {
  children: any,
}

const filter = createFilterOptions({ignoreCase: true, trim: true})

const validators = {code: [requiredField], type: [requiredField]}

const ProductSettingsModal = ({onClose, product, onSubmit}) => {
  const classes = useStyles()
  const {field, handleSubmit, change} = useForm({initialValues: product, onSubmit, validators})
  const [services, setServices] = useState([])
  const [isNewServiceLoading, setIsNewServiceLoading] = useState(false)
  const [selectedServices, setSelectedServices] = useState(product.services || [])

  const addService = async attrs => {
    setIsNewServiceLoading(true)

    const newService = await createService(attrs)

    setSelectedServices([...selectedServices, newService])
    setServices([...services, newService])
    setIsNewServiceLoading(false)
  }

  useEffect(() => {
    change("services", selectedServices)
  }, [change, selectedServices])

  useEffect(() => {
    fetchServices().then(setServices)
  }, [])

  return (
    <Dialog onClose={onClose} open={true} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.root}>
          <Grid container spacing={3}>
            <HalfColumn>
              <TextField
                fullWidth
                id="productCode"
                label="Product Code"
                type="text"
                {...field("code")}
              />
            </HalfColumn>
            <HalfColumn>
              <TextField
                fullWidth
                id="productName"
                label="Product Name"
                type="text"
                {...field("name")}
              />
            </HalfColumn>
            <HalfColumn>
              <TextField
                fullWidth
                id="productType"
                label="Product Type"
                type="text"
                {...field("type")}
              />
            </HalfColumn>
            <Feature featureKey="product-opening-value-reporting">
              <HalfColumn>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Track Opens?"
                    {...field("trackOpens", {bool: true, exclude: ["error", "helperText"]})}
                    disabled={product.trackOpens === true}
                  />
                </FormGroup>
              </HalfColumn>
            </Feature>
            <Grid item lg={12} md={12} xs={12}>
              <TextField
                fullWidth
                id="productDescription"
                label="Product Description"
                multiline
                type="text"
                {...field("description")}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Autocomplete
                ChipProps={{size: "small"}}
                multiple={true}
                noOptionsText="No services (start typing to add...)"
                disabled={isNewServiceLoading}
                id="product-services"
                options={services}
                clearOnBlur={true}
                value={selectedServices}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    })
                  }

                  return filtered
                }}
                onChange={(event, newValue) => {
                  if (!newValue.length) {
                    return setSelectedServices([])
                  }

                  const previousValue = newValue.slice(0, -1)
                  const latestAddition = newValue[newValue.length - 1]

                  // The service already exists, we don't need to intercept the
                  // value setting at all
                  if (latestAddition.id) {
                    return setSelectedServices(newValue)
                  }

                  // Autocomplete thinks it's a new value, but let's check if
                  // there's a case insensitive match in our list (honestly
                  // you'd have to go out of your way to make this happen)
                  const caseInsensitiveMatch = services.find(
                    service =>
                      service.name.toLowerCase() === latestAddition.inputValue.toLowerCase()
                  )
                  if (caseInsensitiveMatch) {
                    return setSelectedServices([...previousValue, caseInsensitiveMatch])
                  }

                  // If we make it this far, it's a new service
                  return addService({name: latestAddition.inputValue})
                }}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Product Services"
                    placeholder="Product Services"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="grey" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ProductSettingsModal.propTypes = {
  product: object,
  onClose: func,
  onSubmit: func,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
}))

export default ProductSettingsModal
