import {omit} from "lodash"
import {useContext, useState} from "react"
import {useHistory} from "react-router-dom"
import shortid from "shortid"

import {createRewardSet} from "lib/api"

import RewardSetFooter from "./reward-set-footer"
import RewardSetForm from "./reward-set-form"
import RewardSetLayout from "./reward-set-layout"
import {defaultStep} from "./reward-step-conditions/step-components"
import RewardStepList from "./reward-step-list"
import {RewardsContext} from "./rewards-context-provider"

const NewRewardSet = () => {
  const history = useHistory()
  const {dispatch} = useContext(RewardsContext)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Initialize useForm for default reward set and default reward step
  const rewardSetDefaults = {
    category: "monetary",
    name: "",
    personalizationUnit: "None",
    status: "draft",
    unit: "",
  }
  const rewardStepDefaults = {
    ...defaultStep,
    initialValues: {...defaultStep.initialValues, id: shortid.generate()},
  }

  // Can't explain why, but pulling in the RewardSetFormContext here causes
  // a render loop in RewardSetForm. So, RewardSetFooter instead reads
  // the context on save and passes it up here to make the API POST.
  const handleSave = formData => {
    const params = {
      ...omit(formData, ["rewardSteps"]),
      rewardSteps: formData.rewardSteps.map(step => omit(step, ["id"])),
    }
    setIsSubmitting(true)
    createRewardSet(params).then(rewardSet => {
      setIsSubmitting(false)
      dispatch({type: "ADD_REWARD_SET", rewardSet})
      history.push(`/admin/rewards/reward-sets/${rewardSet.id}`)
    })
  }

  const handleDelete = () => history.push("/admin/rewards/reward-sets/")

  return (
    <RewardSetLayout
      footer={
        <RewardSetFooter
          isSubmitting={isSubmitting}
          onSave={handleSave}
          status={rewardSetDefaults.status}
        />
      }
      rewardSetForm={
        <RewardSetForm
          initialValues={rewardSetDefaults}
          isEditable={true}
          onDelete={handleDelete}
        />
      }
      rewardStepList={
        <RewardStepList rewardStepConfigList={[rewardStepDefaults]} isEditable={true} />
      }
    />
  )
}

export default NewRewardSet
