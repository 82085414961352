import {IconButton, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, number, object} from "prop-types"
import {useContext} from "react"
import {MdDelete} from "react-icons/md"

import MuiIcon from "../../../mui-icon"
import {SurveySettingsContext} from "./survey-settings"

const SurveySettingsAnswer = ({answer, i, questionIndex, onRemoveAnswer, onUpdateAnswer}) => {
  const {widgetSlug} = useContext(SurveySettingsContext)

  const onRemove = () => window.confirm("Delete this Answer?") && onRemoveAnswer(answer)

  const onChangeTitle = ({target: {value}}) => {
    onUpdateAnswer({...answer, title: value})
  }

  const classes = useStyles()

  return (
    <div className={classes.answerRow} key={answer.value}>
      <TextField
        fullWidth={true}
        id={`survey-${widgetSlug}-question-${questionIndex}-answer-${i}`}
        label={`Answer ${i + 1}`}
        margin="normal"
        onChange={onChangeTitle}
        value={answer.title || ""}
      />
      <IconButton
        className={classes.deleteIcon}
        data-testid={`answer-${answer.value}-delete-button`}
        onClick={onRemove}
        size="medium"
      >
        <MuiIcon icon={<MdDelete />} />
      </IconButton>
    </div>
  )
}

SurveySettingsAnswer.propTypes = {
  answer: object.isRequired,
  i: number.isRequired,
  onRemoveAnswer: func.isRequired,
  onUpdateAnswer: func.isRequired,
  questionIndex: number.isRequired,
}

const useStyles = makeStyles(theme => ({
  answerRow: {
    display: "flex",
    alignItems: "flex-end",
  },
  deleteIcon: {
    height: 48,
    width: 48,
  },
}))

export default SurveySettingsAnswer
