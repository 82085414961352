import {
  CONTACT_ACCOUNTS_LIST_SET,
  CONTACT_CLEARED,
  CONTACT_CREATED,
  CONTACT_DELETED,
  CONTACT_JOURNEY_LIST_SET,
  CONTACT_OBJECTIVES_LIST_SET,
  CONTACT_OBJECTIVE_UPDATED,
  CONTACT_SET,
  CONTACT_SUBSCRIPTION_SET,
  CONTACT_SUBSCRIPTION_UPDATED,
  CONTACT_TEMPLATE_PAGES_SET,
  CONTACT_UPDATED,
} from "./contact-actions"

const initialState = {
  journeys: [],
  objectives: [],
  contactAccounts: [],
  pagesByTemplateId: {},
  unsubscribeEmail: false,
}

function contact(state = initialState, action) {
  switch (action.type) {
    case CONTACT_SET:
    case CONTACT_CREATED:
    case CONTACT_UPDATED:
    case CONTACT_SUBSCRIPTION_SET:
    case CONTACT_SUBSCRIPTION_UPDATED: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case CONTACT_DELETED:
    case CONTACT_CLEARED:
      return initialState

    case CONTACT_JOURNEY_LIST_SET:
      return {
        ...state,
        journeys: action.payload,
      }

    case CONTACT_TEMPLATE_PAGES_SET:
      return {
        ...state,
        pagesByTemplateId: action.payload,
      }

    case CONTACT_OBJECTIVES_LIST_SET:
      return {
        ...state,
        objectives: action.payload,
      }

    case CONTACT_ACCOUNTS_LIST_SET:
      return {
        ...state,
        contactAccounts: action.payload,
      }

    case CONTACT_OBJECTIVE_UPDATED:
      return {
        ...state,
        objectives: state.objectives.map(o =>
          o.meta.key === action.payload.meta.key ? {...o, ...action.payload} : o
        ),
      }

    default:
      return state
  }
}

export default contact
