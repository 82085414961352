import {Button} from "@mui/material"
import Papa from "papaparse"
import {func, string} from "prop-types"
import {MdFileDownload as DownloadIcon} from "react-icons/md"

import triggerCsvDownload from "lib/trigger-csv-download"

export const triggerDownload = async (getContent, filename) => {
  const records = await getContent()

  triggerCsvDownload(Papa.unparse(records), filename)
}

const CsvDownloadButton = ({buttonText, getContent, download, ...restProps}) => {
  const _getContent = async () => {
    triggerDownload(getContent, download)
  }

  return (
    <Button onClick={_getContent} size="small" variant="contained" {...restProps}>
      <DownloadIcon style={{marginRight: 10}} />
      Download {buttonText}
    </Button>
  )
}

CsvDownloadButton.propTypes = {
  buttonText: string.isRequired,
  download: string,
  getContent: func.isRequired,
}

export default CsvDownloadButton
