import devices from "./devices"

export const defaultContentBlock = {
  grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
  css: "",
  style: {},
}

export const initialState = {
  containers: {},
  selectedBlock: null,
  device: devices[0],
  devices,
  scaleToFit: true,
  rewardSets: [],
}

export const actions = {
  setContainer: "CONTAINER_SET",
  setSelectedBlock: "CONTENT_BLOCK_SELECTED",
  addContentBlock: "CONTENT_BLOCK_ADDED",
  updateContentBlock: "CONTENT_BLOCK_UPDATED",
  removeContentBlock: "CONTENT_BLOCK_REMOVED",
  setContentBlocks: "CONTENT_BLOCKS_SET",
  setMode: "EDITOR_SET_MODE",
  setDevice: "EDITOR_SET_DEVICE",
  pushDeviceToList: "EDITOR_PUSH_DEVICE_TO_LIST",
  setRewardSets: "SET_REWARD_SETS",
  setSelectedBlockDataChanges: "SELECTED_BLOCK_DATA_CHANGES_SET",
  setSelectedBlockMetaChanges: "SELECTED_BLOCK_META_CHANGES_SET",
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setContainer:
      return {
        ...state,
        selectedBlock: null,
        containers: {
          ...state.containers,
          [action.container?.id]: action.container,
        },
      }
    case actions.setSelectedBlock:
      return {...state, selectedBlock: action.contentBlock}
    case actions.addContentBlock: {
      const {containerId} = action

      return {
        ...state,
        containers: {
          ...state.containers,
          [containerId]: {
            ...state.containers[containerId],
            contentBlocks: state.containers[containerId].contentBlocks.concat([
              {
                ...defaultContentBlock,
                ...action.contentBlock,
                order: state.containers[containerId].contentBlocks.length,
              },
            ]),
          },
        },
      }
    }

    case actions.updateContentBlock: {
      const {containerId} = action
      const selectedBlock = {
        ...(state.containers[containerId].contentBlocks.find(({id}) => id === action.id) || {}),
        ...action.contentBlock,
      }

      return {
        ...state,
        containers: {
          ...state.containers,
          [containerId]: {
            ...state.containers[containerId],
            contentBlocks: state.containers[containerId].contentBlocks.map(block =>
              block.id === action.id ? selectedBlock : block
            ),
          },
        },
        selectedBlock,
      }
    }

    case actions.removeContentBlock: {
      const {containerId} = action

      return {
        ...state,
        selectedBlock: null,
        containers: {
          ...state.containers,
          [containerId]: {
            ...state.containers[containerId],
            contentBlocks: state.containers[containerId].contentBlocks.filter(
              block => block.id !== action.id
            ),
          },
        },
      }
    }
    case actions.setContentBlocks: {
      const {containerId} = action

      return {
        ...state,
        containers: {
          ...state.containers,
          [containerId]: {
            ...state.containers[containerId],
            contentBlocks: action.contentBlocks,
          },
        },
      }
    }
    case actions.setMode:
      return {...state, mode: action.mode}
    case actions.setDevice:
      return {...state, device: action.device, scaleToFit: action.scaleToFit}
    case actions.setSelectedBlockDataChanges:
      return {...state, selectedBlockDataChanges: action.selectedBlockDataChanges}
    case actions.setSelectedBlockMetaChanges:
      return {...state, selectedBlockMetaChanges: action.selectedBlockMetaChanges}
    case actions.pushDeviceToList:
      // Avoid duplication of pushed devices by pushing new device onto initial list
      // and not onto device list in state
      return {...state, devices: [action.device, ...devices]}
    case actions.setRewardSets:
      return {...state, rewardSets: action.rewardSets}
    default:
      throw new Error(action.type)
  }
}
