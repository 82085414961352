import {Button} from "@mui/material"
import {bool, number, object, shape, string} from "prop-types"
import {
  MdKeyboardArrowRight as NextPageIcon,
  MdKeyboardArrowLeft as PreviousPageIcon,
} from "react-icons/md"
import {Link, useLocation} from "react-router-dom"

import BrandedButton from "../branded-button/branded-button"
import MuiIcon from "../mui-icon"

function useQueryParam(name) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  return queryParams.get(name)
}

const ContentContainerNavigation = ({classes, contentContainer, isSticky, rightOffset}) => {
  const showNavigationQueryParam = useQueryParam("showNavigation")
  let showNavigation = false
  if (!showNavigationQueryParam === undefined) {
    showNavigation = showNavigationQueryParam === "true"
  } else {
    showNavigation = contentContainer?.engagementChannel?.showNavigation
  }

  if (!showNavigation) {
    return null
  }

  const stickyStyles = {
    position: "fixed",
    bottom: 0,
    right: `${rightOffset}px`,
  }

  return (
    <div className={classes.pageButtons} style={isSticky ? stickyStyles : {}}>
      {contentContainer?.navigation?.prevUrl && (
        <Button
          classes={{root: classes.prevPageButton}}
          component={Link}
          size="large"
          to={contentContainer.navigation.prevUrl}
          variant="contained"
        >
          <MuiIcon
            className={classes.prevPageButtonIcon}
            icon={
              <PreviousPageIcon
                aria-label={`Navigate to ${contentContainer.navigation.prevButtonText} page`}
              />
            }
          />
        </Button>
      )}
      {contentContainer?.navigation?.nextUrl && (
        <BrandedButton
          classes={{root: classes.nextPageButton}}
          color="primary"
          component={Link}
          to={contentContainer.navigation.nextUrl}
          variant="contained"
        >
          {contentContainer.navigation.nextButtonText}
          <MuiIcon
            className={classes.nextPageButtonIcon}
            icon={<NextPageIcon aria-hidden="true" />}
          />
        </BrandedButton>
      )}
    </div>
  )
}

ContentContainerNavigation.propTypes = {
  classes: object.isRequired,
  contentContainer: shape({
    css: string,
    id: string.isRequired,
    styles: object,
    navigation: object,
  }).isRequired,
  isSticky: bool,
  rightOffset: number,
}

export default ContentContainerNavigation
