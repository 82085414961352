// Everything in this reducer is what we save to localStorage if they abandon it,
// so things like request data (i.e. the list of objectives and such) should go
// in local state or another reducer

export const partitionLast = arr => ({
  currentRow: arr[arr.length - 1],
  otherRows: arr.slice(0, arr.length - 1),
})

const wizardStateReducer = (state, action) => {
  switch (action.type) {
    case "ACTIVE_STEP_SET":
      return {
        ...state,
        activeStep: action.payload,
      }

    case "OBJECTIVE_ROW_ADDED":
      return {
        ...state,
        activeStep: 1,
        objectiveRows: [...state.objectiveRows, {...defaultState.objectiveRows[0]}],
      }

    case "OBJECTIVE_ROW_UPDATED":
      return {
        ...state,
        objectiveRows: state.objectiveRows.map((objectiveRow, i) =>
          i === action.payload.objectiveRowIndex ? action.payload.objectiveRow : objectiveRow
        ),
      }

    case "CAMPAIGN_NAME_SET":
      return {
        ...state,
        campaignName: action.payload,
      }

    case "CAMPAIGN_JOURNEY_DURATION_HOURS_SET":
      return {
        ...state,
        campaignJourneyDurationHours: action.payload,
      }

    case "CAMPAIGN_USE_CASE_SET":
      return {
        ...state,
        campaignUseCase: action.payload,
      }

    case "CAMPAIGN_AUDIENCE_SET":
      return {
        ...state,
        campaignAudience: action.payload,
      }

    case "CAMPAIGN_FEATURES_SET":
      return {
        ...state,
        campaignFeatures: action.payload,
      }

    case "CAMPAIGN_TYPE_SET":
      return {
        ...state,
        campaignType: action.payload,
      }

    case "OBJECTIVE_SET": {
      const {currentRow, otherRows} = partitionLast(state.objectiveRows)

      return {
        ...state,
        objectiveRows: [...otherRows, {...currentRow, objective: action.payload}],
      }
    }

    case "PAGE_SET": {
      const {currentRow, otherRows} = partitionLast(state.objectiveRows)

      return {
        ...state,
        objectiveRows: [...otherRows, {...currentRow, page: action.payload}],
      }
    }

    case "MESSAGES_SET": {
      const {currentRow, otherRows} = partitionLast(state.objectiveRows)

      return {
        ...state,
        objectiveRows: [...otherRows, {...currentRow, messages: action.payload}],
      }
    }

    case "CAMPAIGN_SET":
      return action.payload

    default:
      throw new Error("unknown type specified")
  }
}

const defaultState = {
  activeStep: 0,
  campaignAudience: "",
  campaignName: "",
  campaignJourneyDurationHours: 30 * 24,
  campaignType: "standard",
  campaignUseCase: "",
  campaignFeatures: [],
  objectiveRows: [{messages: []}],
}

export {defaultState}

export default wizardStateReducer
