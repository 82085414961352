import {LOADING_STATE_COMPLETED, LOADING_STATE_STARTED} from "../actions/loading-state-actions"

export const TEMPLATE_LOADED = "TEMPLATE_LOADED"
export const TEMPLATE_PAGES_LOADED = "TEMPLATE_PAGES_LOADED"

export const isLoading = (key, state) => !!state[key] && state[key].code === LOADING_STATE_STARTED

export const isLoaded = (key, state) => !!state[key] && state[key].code === LOADING_STATE_COMPLETED

export const loadingStatesWithMessages = state => Object.values(state).filter(s => s.message)
