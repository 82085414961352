import {alpha} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {object, string} from "prop-types"

export const LandingPageHeader = ({classes, backgroundImage, logoImage, headerText}) => (
  <div className={cx(classes.header, {[classes.naked]: backgroundImage})}>
    {!!logoImage && <img alt="logo" src={logoImage} />}
    <h1>{headerText}</h1>
  </div>
)

LandingPageHeader.propTypes = {
  backgroundImage: string,
  classes: object.isRequired,
  headerText: string,
  logoImage: string,
}

const styles = theme => ({
  header: {
    transform: "skewY(-5deg)",
    background: `linear-gradient(
      30deg,
      ${alpha(theme.palette.primary.main, 0.3)} 0%,
      ${alpha(theme.palette.primary.main, 0.9)} 70%,
      ${theme.palette.primary.main} 40%)`,
    height: "180px",
    transformOrigin: "top left",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h1": {
      fontSize: "calc(24px + 2vw)",
      lineHeight: "1.2em",
      textAlign: "center",
      margin: 20,
      fontWeight: 300,
      transform: "skewY(5deg)",
    },
    "& img": {
      maxHeight: 50,
      marginTop: 20,
      transform: "skewY(5deg)",
    },
  },
  naked: {
    background: "none",
    transform: "none",
    "& h1, & img": {
      transform: "none",
    },
  },
  "@media (min-width:768px)": {
    header: {
      height: 270,
      "& img": {
        maxHeight: 75,
      },
    },
  },
})

export default withStyles(styles)(LandingPageHeader)
