import {Box, Button, FormControl, InputLabel, MenuItem, TextField, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {bool, func, shape, string} from "prop-types"
import {useContext, useEffect, useMemo, useState} from "react"
import {FaTimes} from "react-icons/fa"
import {useSelector} from "react-redux"

import DangerButton from "components/danger-button/danger-button"
import DOSelect from "components/do-select/do-select"

import {requiredField, validString} from "lib/field-validations"
import useForm from "lib/hooks/use-form"
import humanize from "lib/string/humanize"

import {RewardSetFormContext} from "./reward-set-form-context-provider"
import {compileValues, isDirty} from "./reward-set-form-helpers"

const REWARD_SET_CATEGORIES = [
  {label: "Monetary", value: "monetary"},
  {label: "Rewards", value: "rewards"},
  {label: "Other", value: "other"},
]

const Form = styled("form")(({theme}) => ({
  "& > .MuiFormControl-root": {marginTop: theme.spacing(2)},
}))

const RewardSetForm = ({isEditable, initialValues, onDelete, onToggleStatus}) => {
  const team = useSelector(state => state.session.team)

  const [showDelete, setShowDelete] = useState(false)
  const {dispatch, isListDirty, rewardSetForm, rewardStepFormList} = useContext(
    RewardSetFormContext
  )

  const personalizationOptions = useMemo(() => {
    const fieldMappings = team?.contact_field_map || {}

    const contactFieldNames = Object.values(fieldMappings)
      .filter(
        fieldMap =>
          typeof fieldMap === "object" &&
          fieldMap.digital_onboarding_field &&
          fieldMap.digital_onboarding_field === "meta_public" &&
          fieldMap.meta_sub_key
      )
      .map(fieldMap => fieldMap.meta_sub_key)

    return contactFieldNames.concat("None")
  }, [team])

  const {field, inputs} = useForm({
    initialValues: {
      category: initialValues.category,
      name: initialValues.name,
      personalizationUnit: initialValues.personalizationUnit,
      status: initialValues.status,
      unit: initialValues.unit,
    },
    validators: {
      category: [requiredField, validString],
      name: [requiredField, validString],
      unit: [requiredField, validString],
    },
  })

  const canToggleStatus = useMemo(() => {
    if (
      !rewardSetForm ||
      initialValues.status === "archived" ||
      initialValues.rewardSteps?.length === 0
    ) {
      return false
    }

    if (initialValues.status !== "draft") {
      return true
    }

    const hasDirtyField =
      rewardSetForm.isDirty ||
      rewardStepFormList.reduce((acc, rewardStepForm) => acc || rewardStepForm.isDirty, false) ||
      isListDirty
    const hasInvalidForm =
      !rewardSetForm.isValid || !!rewardStepFormList.find(rewardStepForm => !rewardStepForm.isValid)

    return !(hasDirtyField || hasInvalidForm)
  }, [initialValues, isListDirty, rewardSetForm, rewardStepFormList])

  useEffect(() => {
    const updated = {
      data: {
        id: initialValues.id,
        ...compileValues(inputs),
      },
      isDirty: isDirty(inputs),
      isValid: Object.values(inputs).reduce((acc, fieldObject) => {
        const shouldNotValidate =
          !acc || ["personalizationUnit", "status"].includes(fieldObject.name)

        if (shouldNotValidate) {
          return acc
        }

        if (
          fieldObject.name === "category" &&
          !requiredField(initialValues.category) &&
          !validString(initialValues.category)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "name" &&
          !requiredField(initialValues.name) &&
          !validString(initialValues.name)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "unit" &&
          !requiredField(initialValues.unit) &&
          !validString(initialValues.unit)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        return "error" in fieldObject ? !fieldObject.error : false
      }, true),
    }

    dispatch({type: "SET_REWARD_SET_FORM", rewardSetForm: updated})
  }, [dispatch, initialValues, inputs])

  useEffect(() => {
    return () => dispatch({type: "RESET_REWARD_SET_FORM"})
  }, [dispatch])

  return (
    <Form
      sx={{flexDirection: "column", alignItems: "flex-start"}}
      onMouseEnter={() => setShowDelete(isEditable)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <Box
        alignItems="flex-end"
        display="flex"
        gap={2}
        justifyContent="space-between"
        sx={{
          "& .MuiButtonBase-root": {
            textWrap: "nowrap",
            width: "100%",
          },
        }}
        width="100%"
      >
        <TextField
          label="Status"
          fullWidth={true}
          value={humanize(inputs.status.value)}
          disabled={true}
        />
        {initialValues.status !== "archived" && (
          <div>
            <Button
              color="primary"
              id="reward-set-status-button"
              disabled={!canToggleStatus}
              size="small"
              variant="contained"
              onClick={onToggleStatus}
            >
              {initialValues.status === "draft" ? "Launch Reward" : "Archive Reward"}
            </Button>
          </div>
        )}
        <div>
          <DangerButton
            color="error"
            disabled={!isEditable}
            onClick={onDelete}
            sx={{visibility: showDelete ? "visible" : "hidden"}}
          >
            <FaTimes size={20} />
          </DangerButton>
        </div>
      </Box>

      <TextField label="Name" fullWidth={true} {...field("name")} disabled={!isEditable} />

      <FormControl fullWidth={true} disabled={!isEditable}>
        <InputLabel id="reward-set-category">Category</InputLabel>
        <DOSelect labelId="reward-set-category" {...field("category")}>
          {REWARD_SET_CATEGORIES.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <Typography variant="inherit" noWrap>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>

      <FormControl fullWidth={true} disabled={!isEditable}>
        <InputLabel id="personalization-unit-label">Personalization</InputLabel>
        <DOSelect labelId="personalization-unit-label" {...field("personalizationUnit")}>
          {personalizationOptions.map(unit => (
            <MenuItem key={unit} value={unit}>
              <Typography variant="inherit" noWrap>
                {unit}
              </Typography>
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>

      <TextField label="Fallback" fullWidth={true} {...field("unit")} disabled={!isEditable} />
    </Form>
  )
}

RewardSetForm.propTypes = {
  initialValues: shape({
    category: string.isRequired,
    name: string.isRequired,
    personalizationUnit: string.isRequired,
    unit: string.isRequired,
  }),
  isEditable: bool,
  onDelete: func.isRequired,
  onToggleStatus: func,
}

export default RewardSetForm
