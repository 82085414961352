import {Skeleton} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {array, bool, string} from "prop-types"
import React, {useEffect, useMemo, useState} from "react"
import {MdChevronLeft as ChevronLeftIcon, MdChevronRight as ChevronRightIcon} from "react-icons/md"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Surface,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import {INSIGHTS_COLORS} from "../template-insights/insights-helpers"
import {formatPeriodLabel} from "./helpers"

const useStyles = makeStyles(theme => {
  return {
    chartLegendList: {
      "& li": {lineHeight: 1},
      "& svg.recharts-surface": {
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: theme.spacing(1),
      },
      display: "flex",
      flexWrap: "wrap",
      gap: `${theme.spacing(1)} ${theme.spacing(3)}`,
      listStyle: "none",
      margin: 0,
      padding: 0,
      width: "100%",
    },
    chartPager: {
      display: "flex",
      fontSize: "2em",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(1),
    },
    chartTooltipWrapper: {
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      display: "block",
      margin: 0,
      padding: "10px",
      whiteSpace: "nowrap",
      "& li": {margin: 0, padding: "4px 0"},
      "& ul": {color: "#000", listStyle: "none", margin: 0, padding: 0},
    },
    chartWrapper: {
      marginTop: theme.spacing(1),
      position: "relative",
    },
    pagingIconDisabled: {color: theme.palette.grey.dark},
    pagingIconEnabled: {color: theme.palette.primary.main, cursor: "pointer"},
  }
})

const ValueOverTimeLineChart = ({businessDriverNames, chartData, isLoading, period}) => {
  const classes = useStyles()

  // Pagination params
  const PERIODS_PER_PAGE = 4
  const [currentPage, setCurrentPage] = useState(1)
  const [pagedData, setPagedData] = useState([])
  const [totalPages, setTotalPages] = useState(0)

  const chartLineItems = useMemo(
    () =>
      businessDriverNames.map((name, index) => ({
        color: INSIGHTS_COLORS[index],
        key: `${name}: ${period}-${currentPage}-of-${totalPages}`,
        name,
      })),
    [businessDriverNames, currentPage, period, totalPages]
  )

  // Reset pagination and current page
  useEffect(() => {
    if (chartData?.length) {
      setTotalPages(Math.ceil(chartData.length / PERIODS_PER_PAGE))
      setCurrentPage(1)
    }
  }, [chartData])

  // Set data based on current page
  useEffect(() => {
    if (chartData.length && totalPages > 1) {
      setPagedData(
        currentPage < totalPages
          ? chartData.slice((currentPage - 1) * PERIODS_PER_PAGE, currentPage * PERIODS_PER_PAGE)
          : chartData.slice(-1 * PERIODS_PER_PAGE)
      )
    } else {
      setPagedData(chartData)
    }
  }, [chartData, currentPage, totalPages])

  return (
    <>
      <div className={classes.chartWrapper}>
        {isLoading ? (
          <Skeleton variant="square" width="100%" height={280} />
        ) : (
          <ResponsiveContainer minHeight={365} width="100%">
            <LineChart
              key={`${period}-${currentPage}-of-${totalPages}`}
              width={500}
              height={300}
              data={pagedData}
              margin={{
                top: 10,
                right: 10,
                left: -20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="timestamp"
                tick={{fontSize: "10px"}}
                tickFormatter={timestamp => formatPeriodLabel({timestamp, currentPeriod: period})}
              />
              <YAxis tick={{fontSize: "10px"}} />
              <Tooltip
                content={({label, payload}) =>
                  payload && (
                    <div className={classes.chartTooltipWrapper}>
                      <span>{formatPeriodLabel({currentPeriod: period, timestamp: label})}</span>
                      <ul>
                        {payload.map(({name, value}) => (
                          <li key={name}>
                            {name}:{" "}
                            {new Intl.NumberFormat("en-US", {
                              currency: "USD",
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                              style: "currency",
                            }).format(value)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                }
                itemStyle={{color: "inherit"}}
              />
              {chartLineItems.map(({color, key, name}) => (
                <Line dataKey={name} key={key} type="monotone" stroke={color} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>

      <div className={classes.chartPager}>
        <ChevronLeftIcon
          className={currentPage > 1 ? classes.pagingIconEnabled : classes.pagingIconDisabled}
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1)
            }
          }}
        />

        <ChevronRightIcon
          className={
            currentPage < totalPages ? classes.pagingIconEnabled : classes.pagingIconDisabled
          }
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1)
            }
          }}
        />
      </div>

      <ul className={classes.chartLegendList}>
        {chartLineItems.map(({color, name}) => (
          <li key={name}>
            <Surface width={14} height={14} viewBox={{x: 0, y: 0, width: 32, height: 32}}>
              <path
                stroke="none"
                fill={color}
                d="M0,4h32v24h-32z"
                className="recharts-legend-icon"
              />
            </Surface>
            <span className="recharts-legend-item-text">{name}</span>
          </li>
        ))}
      </ul>
    </>
  )
}

ValueOverTimeLineChart.defaultProps = {isLoading: false}

ValueOverTimeLineChart.propTypes = {
  businessDriverNames: array.isRequired,
  chartData: array.isRequired,
  isLoading: bool,
  period: string.isRequired,
}

export default ValueOverTimeLineChart
