import {Grid} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {useEffect, useState} from "react"

import {fetchTemplateSurveyInsights} from "lib/api"

import {useInsights} from "./insights-context"
import SurveyCompletion from "./survey-completion"

const SkeletonLoader = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Skeleton variant="circle" width={80} height={80} />
    </Grid>
    <Grid item xs={12}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "flex-end",
          marginLeft: -35,
          width: 330,
        }}
      >
        <Skeleton variant="rectangular" width={60} height={280} />
        <Skeleton variant="rectangular" width={60} height={140} />
        <Skeleton variant="rectangular" width={60} height={70} />
      </div>
    </Grid>
  </Grid>
)

const SurveyCompletions = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  const {
    addInsightsCache,
    filter: filterParams,
    insights: {survey_widget},
    templateId,
  } = useInsights()
  const surveyData = survey_widget?.value?.data || []

  useEffect(() => {
    fetchTemplateSurveyInsights(templateId, filterParams)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, filterParams, templateId])

  if (!isLoaded) return <SkeletonLoader />

  return surveyData.map(({contentBlockId, ...data}) => (
    <SurveyCompletion key={contentBlockId} {...data} />
  ))
}

export default SurveyCompletions
