import {FormControl, InputLabel, MenuItem, Typography} from "@mui/material"
import {node, oneOfType, string} from "prop-types"

import DOSelect from "components/do-select/do-select"

const fontWeights = {
  100: "Thin",
  200: "Extra Light",
  300: "Light",
  400: "Normal",
  500: "Medium",
  600: "Semi Bold",
  700: "Bold",
  800: "Extra Bold",
  900: "Black",
}

const FontWeightSelector = ({id, label, margin, ...props}) => (
  <FormControl fullWidth margin={margin}>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <DOSelect
      id={id}
      renderValue={fontWeight => (
        <Typography style={{fontWeight}}>
          {fontWeights[fontWeight]} ({fontWeight})
        </Typography>
      )}
      {...props}
    >
      {Object.keys(fontWeights).map(fontWeight => (
        <MenuItem key={fontWeight} style={{fontWeight}} value={fontWeight}>
          {fontWeights[fontWeight]} ({fontWeight})
        </MenuItem>
      ))}
    </DOSelect>
  </FormControl>
)

FontWeightSelector.propTypes = {
  id: string,
  label: oneOfType([node, string]),
  margin: string,
}

FontWeightSelector.defaultProps = {
  id: "fontWeight",
  label: "Font Weight",
}

export default FontWeightSelector
