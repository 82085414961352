import {connect} from "react-redux"

import {loginUser} from "../../actions/session-actions"
import Login from "./login"

const mapStateToProps = ({session}) => ({
  serverError: session.error,
})

const addActionsToProps = {
  onSubmit: loginUser,
}

export default connect(mapStateToProps, addActionsToProps)(Login)
