import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import {bool, func} from "prop-types"

import DangerButton from "../danger-button/danger-button"

export const DeleteMessageDialog = ({isOpen, onArchive, onClose, onDelete}) => (
  <Dialog onClose={onClose} open={isOpen}>
    <DialogTitle>Delete Message</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Archiving will keep analytics associated with this message. Deleting will disconnect those
        analytics from the message.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <DangerButton onClick={onDelete}>Delete</DangerButton>
      <Button color="primary" onClick={onArchive} variant="contained">
        Archive
      </Button>
    </DialogActions>
  </Dialog>
)

DeleteMessageDialog.propTypes = {
  isOpen: bool.isRequired,
  onArchive: func.isRequired,
  onClose: func.isRequired,
  onDelete: func.isRequired,
}

export default DeleteMessageDialog
