// https://stackoverflow.com/a/260876/895453

export default function setFavicon(teamId, cacheBuster) {
  const link = document.querySelector("link[rel*='icon']") || document.createElement("link")

  link.type = "image/x-icon"
  link.rel = "shortcut icon"
  link.href = `${process.env.REACT_APP_API_URL}/teams/${teamId}/favicon?variant=icon&cache_buster=${cacheBuster}`
  document.getElementsByTagName("head")[0].appendChild(link)
}
