import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, node, object, oneOf, string} from "prop-types"
import {useState} from "react"
import {BsImage as GeneratingIcon} from "react-icons/bs"
import {useSelector} from "react-redux"

import {libraryIcon} from "./helpers"

const FallbackableImage = ({fallbackComponent, alt, ...props}) => {
  const [failed, setFailed] = useState(false)
  const onError = () => {
    setFailed(true)
  }

  return failed ? fallbackComponent : <img alt={alt} {...props} onError={onError} />
}

FallbackableImage.propTypes = {
  alt: string.isRequired,
  fallbackComponent: node.isRequired,
}

const imgSrc = (teamId, contentType, id) =>
  `${process.env.REACT_APP_API_URL}/content-library/previews?team_id=${teamId}&${
    contentType === "page" ? "page" : "message"
  }_id=${id}`

const useStyles = makeStyles(theme => ({
  iconRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.brand.lightBlue,
  },
  image: {
    height: 112.5,
    width: 150,
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("box-shadow"),
  },
  raiseable: {
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
  },
}))

const NewContentIcon = ({contentType, classes}) => {
  const Icon = libraryIcon(contentType)

  return (
    <div className={classes.iconRoot}>
      <Icon className={classes.icon} size={80} />
    </div>
  )
}

NewContentIcon.propTypes = {
  classes: object.isRequired,
  contentType: oneOf(["page", "sms", "email"]).isRequired,
}

const ContentLibraryFallbackIcon = ({classes}) => (
  <div className={classes.iconRoot}>
    <GeneratingIcon className={classes.icon} size={80} />
  </div>
)

ContentLibraryFallbackIcon.propTypes = {
  classes: object.isRequired,
}

const ContentLibraryImage = ({contentType, id, raiseable, className}) => {
  const classes = useStyles()
  const teamId = useSelector(({session}) => session?.team?.id)

  const iconClasses = {
    iconRoot: cx(classes.image, classes.iconRoot, className),
    icon: classes.icon,
  }

  if (id.includes("new")) return <NewContentIcon classes={iconClasses} contentType={contentType} />

  return (
    <FallbackableImage
      alt={`${contentType} content preview`}
      className={cx(classes.image, className, {[classes.raiseable]: raiseable})}
      fallbackComponent={<ContentLibraryFallbackIcon classes={iconClasses} />}
      src={imgSrc(teamId, contentType, id)}
    />
  )
}

ContentLibraryImage.propTypes = {
  className: string,
  contentType: oneOf(["page", "sms", "email"]).isRequired,
  id: string.isRequired,
  raiseable: bool,
}

ContentLibraryImage.defaultProps = {
  raiseable: true,
}

export default ContentLibraryImage
