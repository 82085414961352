import {arrayOf, shape, string} from "prop-types"
import {SortableContainer} from "react-sortable-hoc"

import TemplateMessage from "./template-message"

const TemplateMessageList = ({templateMessages}) =>
  templateMessages.map((templateMessage, index) => (
    <TemplateMessage index={index} key={templateMessage.id} templateMessage={templateMessage} />
  ))

TemplateMessageList.propTypes = {
  templateMessages: arrayOf(
    shape({
      id: string.isRequired,
    })
  ).isRequired,
}

export default SortableContainer(TemplateMessageList)
