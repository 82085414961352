import {Typography} from "@mui/material"

import {controlAccess} from "../../access-control/access-controlled"
import DocumentTitle from "../../document-title/document-title"
import CaseSensitivitySettings from "./case-sensitivity-settings"
import CsvProcessingSettings from "./csv-processing-settings"
import CsvProcessingSettingsToolbar from "./csv-processing-settings-toolbar"

const FieldMappingsSettings = () => {
  return (
    <>
      <DocumentTitle title="Team Settings - CSV Processing" />
      <CsvProcessingSettingsToolbar />
      <Typography variant="h5">Contact Fields</Typography>
      <CsvProcessingSettings fieldName="contact_field_map" />
      <Typography variant="h5" style={{marginTop: 32}}>
        Account Fields
      </Typography>
      <CsvProcessingSettings fieldName="account_field_map" />
      <CaseSensitivitySettings />
    </>
  )
}

FieldMappingsSettings.propTypes = {}

export default controlAccess({requiredPermissions: "contacts:create"})(FieldMappingsSettings)
