import {Button} from "@mui/material"
import omit from "lodash/omit"
import {func, number, object} from "prop-types"
import shortid from "shortid"

import SurveySettingsAnswer from "./survey-settings-answer"
import {initFromQuestion} from "./survey-settings-helpers"

const SurveySettingsAnswers = ({question, questionIndex, onUpdateQuestion}) => {
  const onAddAnswer = () => {
    const value = shortid.generate()

    onUpdateQuestion({
      ...question,
      answers: Object.values({
        ...initFromQuestion(question),
        [value]: {value},
      }),
    })
  }
  const onUpdateAnswer = answer => {
    onUpdateQuestion({
      ...question,
      answers: Object.values({
        ...initFromQuestion(question),
        [answer.value]: answer,
      }),
    })
  }
  const onRemoveAnswer = answer => {
    onUpdateQuestion({
      ...question,
      answers: Object.values(omit(initFromQuestion(question), [answer.value])),
    })
  }

  return (
    <div>
      {question.answers?.map((answer, i) => (
        <SurveySettingsAnswer
          answer={answer}
          i={i}
          key={answer.value}
          onRemoveAnswer={onRemoveAnswer}
          onUpdateAnswer={onUpdateAnswer}
          questionIndex={questionIndex}
        />
      ))}

      <Button data-testid="add-answer" onClick={onAddAnswer} type="button">
        Add Answer
      </Button>
    </div>
  )
}

SurveySettingsAnswers.propTypes = {
  onUpdateQuestion: func.isRequired,
  question: object.isRequired,
  questionIndex: number.isRequired,
}

export default SurveySettingsAnswers
