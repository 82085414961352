import {Chip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import omit from "lodash/omit"
import some from "lodash/some"
import {arrayOf, bool, func, object, string} from "prop-types"
import {useMemo} from "react"
import {FiCircle as EmptyCircleIcon} from "react-icons/fi"
import {MdCheckCircle as CheckCircleIcon} from "react-icons/md"
import {connect} from "react-redux"

import useFeatures from "lib/hooks/use-features"

import {controlAccess} from "../access-control/access-controlled"
import Feature from "../feature/feature"
import {isRoleChecked, roles} from "./permissions-helpers"
import UserPermissionCheckbox from "./user-permission-checkbox"

export const isLockingSelfOut = (permission, editingUserId, currentUserId) =>
  editingUserId && permission === "team:users" && editingUserId === currentUserId

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
    display: "grid",
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto",
  },
  threeByThree: {
    gridTemplateAreas: "'editor manager admin' 'tech approver .'",
    justifyContent: "stretch",
    rowGap: 30,
    "@media(max-width: 1300px)": {
      gridGap: "30px 0",
      gridTemplateAreas: "'editor manager' 'admin tech' 'approver .'",
      justifyContent: "space-between",
    },
    "@media(max-width: 780px)": {
      gridGap: "30px 0",
      gridTemplateAreas: "'tech' 'admin' 'manager' 'editor' 'approver'",
      justifyContent: "center",
    },
  },
  // TODO should be able to delete this `fourByFour` class after
  // campaign-approval flag fully rolled out
  fourByFour: {
    gridTemplateAreas: "'tech editor manager admin'",
    justifyContent: "stretch",
    "@media(max-width: 1300px)": {
      gridGap: "30px 0",
      gridTemplateAreas: "'tech admin' 'manager editor'",
      justifyContent: "space-between",
    },
    "@media(max-width: 780px)": {
      gridGap: "30px 0",
      gridTemplateAreas: "'tech' 'admin' 'manager' 'editor'",
      justifyContent: "center",
    },
  },
  twoByTwo: {
    gridGap: "30px 0",
    gridTemplateAreas: "'tech admin' 'manager editor' 'approver .'",
    justifyContent: "space-between",
    "@media(max-width: 650px)": {
      gridTemplateAreas: "'tech' 'admin' 'manager' 'editor' 'approver'",
      justifyContent: "center",
    },
  },
  permissionRole: {
    flexBasis: 0,
    flexGrow: 1,
  },
  permissionRoleHeader: {
    marginBottom: 15,
  },
  permissionRoleLabel: {
    marginRight: 10,
  },
  permissionRoleIcon: {
    height: 24,
    width: 24,
  },
  selectedPermissionRole: {
    color: theme.palette.primary.main,
  },
  deselectedPermissionRole: {
    opacity: "0.4",
  },
  disabledPermissionRole: {
    color: theme.palette.grey[400],
  },
  approver: {
    gridArea: "approver",
  },
  tech: {
    gridArea: "tech",
  },
  admin: {
    gridArea: "admin",
  },
  manager: {
    gridArea: "manager",
  },
  editor: {
    gridArea: "editor",
  },
}))

export const RoleChip = ({classes, disabled, isChecked, onClick, type}) => {
  const handleClick = () => (disabled ? null : onClick(type))

  return (
    <Chip
      className={classes.permissionRoleLabel}
      disabled={disabled}
      sx={[isChecked && !disabled && {"& .MuiChip-icon": {color: "primary.main"}}]}
      icon={
        isChecked ? (
          <CheckCircleIcon
            className={cx(classes.permissionRoleIcon, {
              [classes.disabledPermissionRole]: disabled,
            })}
          />
        ) : (
          <EmptyCircleIcon
            className={cx(classes.permissionRoleIcon, classes.deselectedPermissionRole)}
          />
        )
      }
      label={roles[type].name}
      onClick={handleClick}
      value={type}
      variant="outlined"
    />
  )
}

RoleChip.propTypes = {
  classes: object,
  disabled: bool,
  isChecked: bool,
  onClick: func.isRequired,
  type: string,
}

export const UserPermissionsFormGroup = ({
  gridSize,
  onChange,
  selectedPermissions,
  disabled,
  userId,
  currentUserId,
}) => {
  const {hasFeature} = useFeatures()
  const classes = useStyles()

  const campaignApprovalEnabled = hasFeature("campaign-approval")

  const availableRoles = useMemo(
    () => (campaignApprovalEnabled ? roles : omit(roles, ["approver"])),
    [campaignApprovalEnabled]
  )

  const handleChange = (permission, selected) => {
    if (selected) onChange([...selectedPermissions, permission])
    else onChange(selectedPermissions.filter(p => p !== permission))
  }

  const onSelectRole = role => {
    // De-Select/Select all
    if (isRoleChecked(role, selectedPermissions)) {
      const _isLockingSelfOut = some(selectedPermissions, p =>
        isLockingSelfOut(p, userId, currentUserId)
      )
      const mandatoryPermissions = _isLockingSelfOut ? ["team:users"] : []

      onChange([
        ...selectedPermissions.filter(p => !roles[role].permissions.map(rp => rp.name).includes(p)),
        ...mandatoryPermissions,
      ])
    } else {
      onChange([...selectedPermissions, ...roles[role].permissions.map(p => p.name)])
    }
  }

  if (!currentUserId) return null

  return (
    <div
      className={cx(classes.root, {
        [classes.twoByTwo]: gridSize === "2x2",
        [classes.threeByThree]: campaignApprovalEnabled && gridSize === "3x3",
        [classes.fourByFour]: !campaignApprovalEnabled && gridSize !== "2x2",
      })}
    >
      {Object.keys(availableRoles).map(role => (
        <div className={`${classes.permissionRole} ${classes[role]}`} key={role}>
          <div className={classes.permissionRoleHeader}>
            <RoleChip
              classes={classes}
              disabled={disabled}
              isChecked={isRoleChecked(role, selectedPermissions)}
              onClick={onSelectRole}
              type={role}
            />
          </div>
          {roles[role].permissions.map(({name, label, featureKey}) => (
            <Feature featureKey={featureKey} key={name}>
              <UserPermissionCheckbox
                classes={classes}
                disabled={disabled}
                label={label}
                name={name}
                onChange={handleChange}
                permission={name}
                selectedPermissions={selectedPermissions}
                userId={userId}
              />
            </Feature>
          ))}
        </div>
      ))}
    </div>
  )
}

UserPermissionsFormGroup.propTypes = {
  currentUserId: string,
  disabled: bool,
  gridSize: string,
  onChange: func.isRequired,
  selectedPermissions: arrayOf(string).isRequired,
  userId: string,
}

const mapStateToProps = ({session}) => ({
  currentUserId: session?.user?.id,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(controlAccess({requiredPermissions: "team:users"})(UserPermissionsFormGroup))
