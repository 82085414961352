/** Format a url by trimming spaces and adding http if needed */
export const formatUrl = url => enforceUrlScheme(trimSpaces(url))

export const enforceUrlScheme = url => {
  if (!url) return null

  return url.match(/^https?:\/\//) ? url : `http://${url}`
}

export const trimSpaces = url => {
  if (!url) return null

  return url.trim()
}
