import {shape, string} from "prop-types"
import qs from "query-string"
import {useEffect} from "react"

import {fetchVerification} from "lib/api"

const EmailSMSVerification = ({
  match: {
    params: {type, token, contactId},
  },
  location: {search},
}) => {
  useEffect(() => {
    const {redirect_to: redirectToUrl, ...verificationQueryParams} = qs.parse(search)
    let wasSuccessful

    fetchVerification(contactId, type, token, verificationQueryParams)
      .then(() => {
        wasSuccessful = true
      })
      .catch(() => {
        wasSuccessful = false
      })
      .finally(() => {
        window.location = `${redirectToUrl}?verificationSuccessful=${wasSuccessful}`
      })
  }, [contactId, type, token, search])

  return <div />
}

EmailSMSVerification.propTypes = {
  location: shape({
    search: string,
  }),
  match: shape({
    params: shape({
      contactId: string.isRequired,
      token: string.isRequired,
      type: string.isRequired,
    }),
  }),
}

export default EmailSMSVerification
