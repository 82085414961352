// Copy the pasta
// https://gist.github.com/buob/2d1ad52851673784e155d1ac15592f27#file-embed-jsx-L6-L18
// (Forked, see source)
/* eslint-disable no-param-reassign */

const init_embedly = function (w, d) {
  const id = "embedly-platform",
    n = "script"

  if (!d.getElementById(id)) {
    w.embedly =
      w.embedly ||
      function () {
        ;(w.embedly.q = w.embedly.q || []).push(arguments)
      }
    const e = d.createElement(n)

    e.id = id
    e.async = 1
    e.src = `${
      document.location.protocol === "https:" ? "https" : "http"
    }://cdn.embedly.com/widgets/platform.js`
    const s = d.getElementsByTagName(n)[0]

    s.parentNode.insertBefore(e, s)
  }
}

if (process.env.NODE_ENV !== "test") init_embedly(window, document)
