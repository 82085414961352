import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import kebabCase from "lodash/kebabCase"
import {arrayOf, bool, func, shape, string} from "prop-types"
import {PureComponent} from "react"
import {Field, reduxForm} from "redux-form"

import {RFTextField} from "components/mui-redux-form/mui-redux-form"

export class CustomObjectiveDialog extends PureComponent {
  componentDidUpdate(prevProps) {
    if (!prevProps.isObjectiveCreationComplete && this.props.isObjectiveCreationComplete) {
      this.props.onAdd(this.props.addedObjective)
      this.props.onClose()
    }
  }

  onChangeName = (e, newName) => {
    !this.props.wasKeyTouched && this.props.change("key", kebabCase(newName))
  }

  validateKey = key => {
    if (/[^a-z0-9-_]+/.test(key))
      return "Only lowercase letters, numbers, dashes, and underscores are allowed in objective keys."

    if (this.props.otherObjectives.map(o => o.key).includes(key))
      return "It looks like this objective already exists."
  }

  onSubmit = e => {
    e.stopPropagation()
    this.props.handleSubmit(e)
  }

  render() {
    return (
      <Dialog onClose={this.props.onClose} open={true}>
        <form onSubmit={this.onSubmit}>
          <DialogTitle>Custom Objective</DialogTitle>
          <DialogContent>
            <Field
              component={RFTextField}
              fullWidth={true}
              id="custom-objective-name"
              label="Objective Name"
              margin="normal"
              name="name"
              onChange={this.onChangeName}
            />
            <Field
              component={RFTextField}
              fullWidth={true}
              helperText="Unique identifier used for CSV import columns"
              id="custom-objective-key"
              label="Objective Key"
              margin="normal"
              name="key"
              style={{opacity: this.props.wasKeyTouched ? 1 : 0.8}}
              validate={this.validateKey}
            />
            <DialogActions>
              <Button className="cancel" color="primary" onClick={this.props.onClose} type="button">
                Cancel
              </Button>
              <Button
                className="submit"
                color="primary"
                disabled={this.props.isObjectiveCreationStarted}
                type="submit"
                variant="contained"
              >
                Add Objective
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    )
  }
}

CustomObjectiveDialog.propTypes = {
  addedObjective: shape({
    id: string,
    key: string,
    name: string,
    teamId: string,
  }),
  change: func,
  handleSubmit: func,
  isObjectiveCreationComplete: bool,
  isObjectiveCreationStarted: bool,
  onAdd: func,
  onClose: func,
  otherObjectives: arrayOf(
    shape({
      key: string,
    })
  ).isRequired,
  wasKeyTouched: bool,
}

export default reduxForm({
  form: "customObjective",
  shouldError: () => true,
})(CustomObjectiveDialog)
