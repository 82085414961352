import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {string} from "prop-types"

const SuperheroSvg = ({flip, className, ...props}) => {
  const classes = useStyles()

  return (
    <svg
      {...props}
      className={cx(className, {
        [classes.flipHorizontal]: flip === "horizontal",
        [classes.flipVertical]: flip === "vertical",
        [classes.flipBoth]: flip === "both",
      })}
    />
  )
}

SuperheroSvg.propTypes = {
  className: string,
  flip: string,
}

const useStyles = makeStyles({
  flipHorizontal: {
    transform: "rotateY(180deg)",
  },
  flipVertical: {
    transform: "rotateX(180deg)",
  },
  flipBoth: {
    transform: "rotate(180deg)",
  },
})

export default SuperheroSvg
