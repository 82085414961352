import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import {bool, func} from "prop-types"
import {useState} from "react"

import SaveButton from "../../save-button/save-button"

const DuplicateConfirmationDialog = ({onClose, onDuplicatePage, open}) => {
  const [submitting, setSubmitting] = useState(false)

  const onDuplicatePageClicked = () => {
    setSubmitting(true)
    onDuplicatePage().catch(onClose)
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Confirm page duplication</DialogTitle>
      <DialogContent>
        Duplicating this page will make it unique to this campaign. Any edits you make moving
        forward will <b>no longer</b> affect any campaigns that share this content.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <SaveButton
          failed={false}
          onClick={onDuplicatePageClicked}
          stateLabels={{
            default: "Duplicate",
            submitting: "Duplicating Page...",
            saved: "Page Duplicated!",
          }}
          submitting={submitting}
        />
      </DialogActions>
    </Dialog>
  )
}

DuplicateConfirmationDialog.propTypes = {
  onClose: func.isRequired,
  onDuplicatePage: func.isRequired,
  open: bool.isRequired,
}

export default DuplicateConfirmationDialog
