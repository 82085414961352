import {Button, ButtonGroup, TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import orderBy from "lodash/orderBy"
import {array, func, number, object, string} from "prop-types"
import {useCallback, useEffect, useMemo, useState} from "react"
import {BiNetworkChart as SharedIcon} from "react-icons/bi"
import {BsDashLg as DashIcon} from "react-icons/bs"

import Expandable from "components/expandable/expandable"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"
import {Metrics} from "components/template-insights/insights-helpers"

import {fetchAdvancedInsightsMessages} from "lib/api"
import pluralize from "lib/string/pluralize"

import {TemplateListPreview} from "../dialogs/template-list-preview"
import {useAdvancedTeamInsights} from "./advanced-team-insights-context"

const headers = [
  {
    field: "messageName",
    includeFor: "all",
    label: "Message Name",
    tooltipText: "Message Name: message’s content name",
  },
  {
    field: "messageType",
    includeFor: "all",
    label: "Message Type",
  },
  {
    field: "objectiveName",
    includeFor: "all",
    label: "Objective Name",
    tooltipText: "Objective: the objective assigned to the message",
  },
  {
    field: "sentCount",
    includeFor: Metrics.Total,
    label: "Sent",
    tooltipText: "Sent: total number of messages sent",
  },
  {
    field: "emailOpenedCount",
    includeFor: Metrics.Total,
    label: "Email Opens",
    tooltipText: "Email Opens: total number of unique email opens",
  },
  {
    field: "emailOpenedPercent",
    includeFor: Metrics.Rate,
    label: "Email Open Rate",
    tooltipText:
      "Email Open Rate: total number of unique email opens / total number of messages sent excluding bounces",
  },
  {
    field: "clickThroughPercent",
    includeFor: Metrics.Rate,
    label: "CTR",
    tooltipText:
      "CTR: total number of unique email clicks / total number of emails sent excluding bounces",
  },
  {
    field: "clickToOpenPercent",
    includeFor: Metrics.Rate,
    label: "Email CTOR",
    tooltipText:
      "Email CTOR: total number of unique email clicks / total number of unique email opens",
  },
  {
    field: "bouncedCount",
    includeFor: Metrics.Total,
    label: "Bounces",
    tooltipText: "Bounces: total number of message bounces",
  },
  {
    field: "bouncedPercent",
    includeFor: Metrics.Rate,
    label: "Bounce Rate",
    tooltipText: "Bounce Rate: total number of bounces / total number of messages sent",
  },
  {
    field: "unsubscribeCount",
    includeFor: Metrics.Total,
    label: "Unsubscribes",
    tooltipText: "Unsubscribes: total number of unique unsubscribes",
  },
  {
    field: "unsubscribePercent",
    includeFor: Metrics.Rate,
    label: "Unsubscribe Rate",
    tooltipText:
      "Unsubscribe Rate: total number of unique unsubscribes / total number of messages sent excluding bounces",
  },
]

const useStyles = makeStyles({
  messagesContainer: {
    "& th": {width: "13.333333%"},
    "&.rate th:nth-child(n+2):nth-last-child(-n+5)": {width: "12%"},
    "&.total th:nth-child(n+2):nth-last-child(-n+4)": {width: "15%"},
  },
})

const Messages = ({
  classes: globalClasses,
  itemsPerPage,
  page,
  rows,
  setTableState,
  sortColumn,
  sortDirection,
  totalCount,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setTableStateMemo = useMemo(() => setTableState, [])
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    messages,
    messagesIsLoading,
  } = useAdvancedTeamInsights()
  const [sharedTooltipsOpen, setSharedTooltipsOpen] = useState([])
  const [metric, setMetric] = useState(Metrics.Total)
  const localClasses = useStyles()

  const refresh = useCallback(
    attrs => {
      // Updates sortColumn if metric is switched between count (total) and percent (%)
      const normalizeSortColumn = field => {
        const match = field.match(/([a-zA-Z]+)(Count|Percent)$/)

        switch (true) {
          case metric === Metrics.Total && match?.[2] === "Percent":
            return `${match[1]}Count`
          case metric === Metrics.Rate && match?.[2] === "Count":
            return `${match[1]}Percent`
          default:
            return field
        }
      }
      const normalizedAttrs = {
        itemsPerPage: attrs?.itemsPerPage ?? itemsPerPage,
        page: attrs?.page ?? page,
        sortColumn: normalizeSortColumn(attrs?.sortColumn ?? sortColumn),
        sortDirection: attrs?.sortDirection ?? sortDirection,
        totalCount: messages?.data.length ?? totalCount,
      }
      const sortDataFor = attrs => {
        const sorted = orderBy(messages?.data ?? [], [attrs.sortColumn], [attrs.sortDirection])
        const pageStartIndex = attrs.itemsPerPage * attrs.page

        return pageStartIndex + attrs.itemsPerPage > totalCount
          ? sorted.slice(pageStartIndex)
          : sorted.slice(pageStartIndex, pageStartIndex + attrs.itemsPerPage)
      }

      setTableStateMemo({...normalizedAttrs, rows: sortDataFor(normalizedAttrs)})
    },
    [itemsPerPage, messages, metric, page, sortColumn, sortDirection, setTableStateMemo, totalCount]
  )

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, metric, refresh])

  useEffect(() => {
    if (hasSocket) fetchInsights("messages", fetchAdvancedInsightsMessages, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  return (
    <div
      className={cx(localClasses.messagesContainer, {
        [Metrics.Rate]: metric === Metrics.Rate,
        [Metrics.Total]: metric === Metrics.Total,
      })}
    >
      <Typography variant="h5">Messages</Typography>
      <div className={globalClasses.expandableContainer} style={{paddingTop: 16}}>
        <Expandable label="How we use this table">
          <p>
            The message performance table reports on all your messages and their key performance
            metrics.
          </p>

          <Typography variant="h6">At a Glance</Typography>

          <p>
            Use the sorting feature to compare and contrast messages by each metric. With the switch
            at the top right of the table, change the view from <b>TOTAL</b> to <b>%</b> to view a
            percentage rate instead of a total count. Click the message name hyperlink to view the
            message in the content editor. Messages with a shared icon are used in multiple
            campaigns.
          </p>

          <Typography variant="h6">Use of Page Filters</Typography>
          <ul>
            <li>
              <b>Dates</b>: Focus engagement by specific time frames to see how content engagement
              changes over time.
            </li>
            <li>
              <b>Objectives</b>: Filter by objective to focus on a specific subset.
            </li>
            <li>
              <b>Status</b>: Focus the view by live or archived campaign status to compare or to
              view a specific campaign category.
            </li>
          </ul>
        </Expandable>
      </div>
      <div className={globalClasses.buttonGroupsContainer} style={{marginBottom: 16}}>
        <ButtonGroup size="small" variant="outlined">
          <Button
            color={metric === Metrics.Total ? "primary" : "grey"}
            onClick={() => setMetric(Metrics.Total)}
            tabIndex={0}
            value={Metrics.Total}
          >
            Total
          </Button>
          <Button
            color={metric === Metrics.Rate ? "primary" : "grey"}
            onClick={() => setMetric(Metrics.Rate)}
            tabIndex={1}
            value={Metrics.Rate}
          >
            %
          </Button>
        </ButtonGroup>
      </div>
      <DOTable
        headers={headers.filter(({includeFor}) => ["all", metric].includes(includeFor))}
        isTableLoading={messagesIsLoading}
        noResults="No data found."
        paginationEnabled={true}
        refresh={refresh}
        rows={rows}
      >
        {row => (
          <TableRow key={row.messageId}>
            <TableCell data-testid={`${row.messageId}-message-name`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  color="inherit"
                  component="a"
                  href={
                    row.isPublished
                      ? `/admin/templates/content-library/messages/${row.messageId}`
                      : "/admin/templates/content-library"
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{fontSize: 14}}
                >
                  {row.messageName}
                </Typography>{" "}
                {row.isShared && (
                  <Tooltip
                    interactive="true"
                    leaveDelay={750}
                    onClose={() =>
                      setSharedTooltipsOpen(sharedTooltipsOpen.filter(id => id !== row.messageId))
                    }
                    onOpen={() => setSharedTooltipsOpen([...sharedTooltipsOpen, row.messageId])}
                    open={sharedTooltipsOpen.includes(row.messageId)}
                    title={
                      <TemplateListPreview
                        id={row.messageId}
                        message={(isLoaded, totalTemplates) =>
                          isLoaded && totalTemplates > 0
                            ? `List of ${pluralize(
                                "campaign",
                                totalTemplates
                              )} the page is used in:`
                            : "The page is not used in any campaigns."
                        }
                        recordType="message"
                        target="_blank"
                        variant="body2"
                      />
                    }
                  >
                    <div style={{display: "inline-block", width: 20, height: 20, paddingLeft: 10}}>
                      <SharedIcon data-testid={`${row.messageId}-shared-icon`} size={20} />
                    </div>
                  </Tooltip>
                )}
              </div>
            </TableCell>
            <TableCell>{row.messageType === "email" ? "Email" : "SMS"}</TableCell>
            <TableCell>{row.objectiveName}</TableCell>
            {metric === Metrics.Rate && (
              <>
                <TableCell>
                  {row.messageType === "email" ? (
                    `${row.emailOpenedPercent}%`
                  ) : (
                    <DashIcon
                      data-testid={`${row.messageId}-email-opened-percent-dash-icon`}
                      color="#000000"
                      size={20}
                      style={{marginTop: "9%"}}
                    />
                  )}
                </TableCell>
                <TableCell>{`${row.clickThroughPercent}%`}</TableCell>
                <TableCell>{`${row.clickToOpenPercent}%`}</TableCell>
                <TableCell>{`${row.bouncedPercent}%`}</TableCell>
                <TableCell>{`${row.unsubscribePercent}%`}</TableCell>
              </>
            )}
            {metric === Metrics.Total && (
              <>
                <TableCell>{row.sentCount}</TableCell>
                <TableCell>
                  {row.messageType === "email" ? (
                    row.emailOpenedCount
                  ) : (
                    <DashIcon
                      data-testid={`${row.messageId}-email-opened-count-dash-icon`}
                      color="#000000"
                      size={20}
                      style={{marginTop: "9%"}}
                    />
                  )}
                </TableCell>
                <TableCell>{row.bouncedCount}</TableCell>
                <TableCell>{row.unsubscribeCount}</TableCell>
              </>
            )}
          </TableRow>
        )}
      </DOTable>
    </div>
  )
}

Messages.propTypes = {
  classes: object.isRequired,
  itemsPerPage: number,
  page: number,
  rows: array,
  setTableState: func.isRequired,
  sortColumn: string,
  sortDirection: string,
  totalCount: number,
}

export default tabular({sortColumn: "messageName", sortDirection: "asc"})(Messages)
