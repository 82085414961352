// inspriation https://github.com/jpuri/draftjs-utils/blob/6169800ac38867dd8caf2a2d13640382d12cec14/js/inline.js#L111
import {SelectionState} from "draft-js"

// Note that this is currently only necessary on DraftJS 0.10
// In master, DraftDecorater provides this value: https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecorator.js
// This function should be revisited when upgrading DraftJS to 0.11:
// https://github.com/facebook/draft-js/issues/2010
export function findBlockByEntity(contentState, entityKey) {
  let block

  contentState.getBlockMap().find(contentBlock =>
    contentBlock.findEntityRanges(
      value => value.getEntity() === entityKey,
      () => (block = contentBlock)
    )
  )

  return block
}

export function getEntitySelectionRange(contentState, entityKey) {
  let selection

  contentState
    .getBlockMap()
    .toList()
    .forEach(contentBlock => {
      contentBlock.findEntityRanges(
        value => value.getEntity() === entityKey,
        (anchorOffset, focusOffset) => {
          const selectionState = SelectionState.createEmpty(contentBlock.getKey())

          selection = selectionState.merge({
            anchorOffset,
            focusOffset,
          })
        }
      )
    })

  return selection
}
