import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"
import {useLocation} from "react-router-dom"

import BackButton from "components/back-button/back-button"
import PreviewControls from "components/content-block-editor/preview-controls"
import ContentContainerEditable from "components/cross-channel-engagement/content-container-editable"
import CrossChannelEngagementSidebar from "components/cross-channel-engagement/cross-channel-engagement-sidebar"
import DocumentTitle from "components/document-title/document-title"
import ContentWrapper from "components/journeys/content-wrapper"
import PreviewWrapper from "components/templates/preview-wrapper"

const useStyles = makeStyles({
  returnLink: {
    float: "left",
    paddingTop: 5,
    position: "absolute",
    left: 30,
    width: "auto",
    zIndex: 100,
  },
})

const CrossChannelEngagementEditor = ({
  classes: containerClasses,
  contentContainer,
  updateContentContainer,
}) => {
  const classes = useStyles()
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const pageSlug = searchParams.get("pageSlug")
  const templateId = searchParams.get("templateId")

  return (
    contentContainer && (
      <>
        <DocumentTitle title={`Cross Channel Engagement Editor`} />
        {pageSlug && templateId && (
          <BackButton
            className={classes.returnLink}
            to={`/admin/templates/${templateId}/edit/${pageSlug}`}
          >
            Return to Template Page
          </BackButton>
        )}
        {!(pageSlug && templateId) && (
          <BackButton className={classes.returnLink} to="/admin/templates/engagement-channels">
            Return to Channels
          </BackButton>
        )}
        <PreviewControls layoutBasis={contentContainer?.layoutBasis} />
        <PreviewWrapper>
          <ContentWrapper>
            <ContentContainerEditable
              contentContainer={contentContainer}
              containerClasses={containerClasses}
            />
          </ContentWrapper>
          <CrossChannelEngagementSidebar
            container={contentContainer}
            onUpdateContentContainer={updateContentContainer}
          />
        </PreviewWrapper>
      </>
    )
  )
}

CrossChannelEngagementEditor.propTypes = {
  classes: object.isRequired,
  contentContainer: object,
  updateContentContainer: func.isRequired,
}

export default CrossChannelEngagementEditor
