import {object} from "prop-types"
import {NavLink} from "react-router-dom"

import AccessControlled from "../access-control/access-controlled"

const ContactSubNav = ({classes}) => (
  <>
    <NavLink
      className={classes.link}
      exact={true}
      title="Contacts - Dashboard"
      to="/admin/contacts"
    >
      <span>Dashboard</span>
    </NavLink>

    <AccessControlled requiredPermissions="contacts:create" value="batch-upload">
      <NavLink
        className={classes.link}
        title="Contacts - Batch Upload"
        to="/admin/contacts/batch-upload"
      >
        <span>Batch Upload</span>
      </NavLink>
    </AccessControlled>
  </>
)

ContactSubNav.propTypes = {
  classes: object,
}

export default ContactSubNav
