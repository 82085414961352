import {connect} from "react-redux"

import UserInviteForm from "./user-invite-form"
import {onSendUserInvite} from "./users-actions"

const mapStateToProps = null

const addActionsToProps = {onSubmit: onSendUserInvite}

export default connect(mapStateToProps, addActionsToProps)(UserInviteForm)
