import {
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material"
import {arrayOf, bool, func, object, shape, string} from "prop-types"
import {PureComponent, useRef} from "react"
import {connect} from "react-redux"

import useForm from "lib/hooks/use-form"
import {fullName} from "lib/names"

import AccessControlled from "../access-control/access-controlled"
import DOSelect from "../do-select/do-select"
import {onUpdateTemplate} from "./template-actions"

const availableSupportOwnerOptions = [
  {value: "SUPPORT_OWNER", label: "Support Owner"},
  {value: "DO_NOT_SHOW", label: "Do Not Show"},
  {value: "CUSTOM", label: "Custom"},
]

const SupportOwnerOptionsForm = props => {
  const {classes, disabled, users, initialValues, supportOwnerOptions, onSubmit} = props

  const customEmailLinkInputRef = useRef(null)
  const customPhoneLinkInputRef = useRef(null)
  const customCalendarLinkInputRef = useRef(null)

  const {field} = useForm({
    onSubmit,
    autoSubmitOnChange: true,
    enableReinitialize: true,
    initialValues,
  })

  const onAnimationComplete = (ref, link) => (node, done) => {
    if (supportOwnerOptions[link] === "CUSTOM") focusInput(ref.current)

    node.addEventListener("transitioned", done, false)
  }

  const focusInput = node => {
    node.focus()
    node.scrollIntoView({behavior: "smooth"})
  }

  return (
    <div>
      <FormControl disabled={disabled} fullWidth={true}>
        <FormControlLabel
          classes={{label: props.classes.supportOwnerSwitchLabel}}
          control={
            <Switch color="primary" {...field("supportOwnerOptions.showSupportOwnerLinks")} />
          }
          label="Show Support Owner Links"
        />
      </FormControl>
      <FormControl disabled={disabled} fullWidth={true}>
        <InputLabel>Default Support Owner</InputLabel>
        <DOSelect className={classes.field} fullWidth={true} {...field("ownerId")}>
          {users.map(user => (
            <MenuItem key={user.id} value={user.id}>
              {fullName(user)}
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>
      <FormControl disabled={disabled} fullWidth={true}>
        <InputLabel>Email Link</InputLabel>
        <DOSelect
          className={classes.field}
          fullWidth={true}
          {...field("supportOwnerOptions.emailLinkShows")}
        >
          {availableSupportOwnerOptions.map(option => (
            <MenuItem key={`email-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>
      <Collapse
        addEndListener={onAnimationComplete(customEmailLinkInputRef, "emailLinkShows")}
        in={supportOwnerOptions.emailLinkShows === "CUSTOM"}
      >
        <TextField
          className={classes.field}
          disabled={disabled}
          fullWidth={true}
          inputRef={customEmailLinkInputRef}
          label="Custom Email Link"
          {...field("supportOwnerOptions.emailLink")}
        />
      </Collapse>
      <FormControl disabled={disabled} fullWidth={true}>
        <InputLabel>Phone Link</InputLabel>
        <DOSelect
          className={classes.field}
          fullWidth={true}
          {...field("supportOwnerOptions.phoneLinkShows")}
        >
          {availableSupportOwnerOptions.map(option => (
            <MenuItem key={`phone-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>
      <Collapse
        addEndListener={onAnimationComplete(customPhoneLinkInputRef, "phoneLinkShows")}
        in={supportOwnerOptions.phoneLinkShows === "CUSTOM"}
      >
        <TextField
          autoFocus={true}
          className={classes.field}
          disabled={disabled}
          fullWidth={true}
          inputRef={customPhoneLinkInputRef}
          label="Custom Phone Link"
          {...field("supportOwnerOptions.phoneLink")}
        />
      </Collapse>
      <FormControl disabled={disabled} fullWidth={true}>
        <InputLabel>Calendar Link</InputLabel>
        <DOSelect
          className={classes.field}
          fullWidth={true}
          {...field("supportOwnerOptions.calendarLinkShows")}
        >
          {availableSupportOwnerOptions.map(option => (
            <MenuItem key={`calendar-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>
      <Collapse
        addEndListener={onAnimationComplete(customCalendarLinkInputRef, "calendarLinkShows")}
        in={supportOwnerOptions.calendarLinkShows === "CUSTOM"}
      >
        <TextField
          autoFocus={true}
          className={classes.field}
          disabled={disabled}
          fullWidth={true}
          inputRef={customCalendarLinkInputRef}
          label="Custom Calendar Link"
          {...field("supportOwnerOptions.calendarLink")}
        />
      </Collapse>
    </div>
  )
}

SupportOwnerOptionsForm.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
  supportOwnerOptions: object.isRequired,
  users: arrayOf(shape({id: string.isRequired})),
}

class SupportOwnerOptionsContainer extends PureComponent {
  onUpdateSupportOwnerOptions = values => {
    this.props.onUpdateTemplate(this.props.template.id, values)
  }

  render() {
    const {
      template: {supportOwnerOptions, ownerId},
      users,
      classes,
    } = this.props

    return (
      <AccessControlled requiredPermissions="templates:support_owner">
        <SupportOwnerOptionsForm
          classes={classes}
          initialValues={{ownerId, supportOwnerOptions}}
          onSubmit={this.onUpdateSupportOwnerOptions}
          supportOwnerOptions={supportOwnerOptions}
          users={users}
        />
      </AccessControlled>
    )
  }
}

SupportOwnerOptionsContainer.propTypes = {
  classes: object,
  onUpdateTemplate: func.isRequired,
  template: object.isRequired,
  users: arrayOf(shape({id: string.isRequired})),
}

const mapStateToProps = ({session: {teamUsers}, template}) => ({
  template,
  users: teamUsers,
})

const mapDispatchToProps = {onUpdateTemplate}

export default connect(mapStateToProps, mapDispatchToProps)(SupportOwnerOptionsContainer)
