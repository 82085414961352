export const drawerWidth = 300

/**
 * It is important that we don't return a slug with a value of null because
 *
 * the backend is not smart enough to determine this scenario, i.e., in the
 *
 * case of building a sample url https://gitlab.com/digitalonboarding/gondor/-/blob/ba5fc1e36f221be6c051b5cb93a035a110495216/lib/gondor_web/mailers/nurturing_sms.ex#L93.
 *
 * As you can see, we only check that content_variables is a map and we have
 *
 * the slug as the key.
 */
export const createDefaultContentVariables = ({content_variables, templatePage, slug}) => {
  const pageSlug = templatePage?.page?.newSlug

  if (pageSlug) return {...content_variables, [slug]: pageSlug}
  else return content_variables
}
