import {
  Button,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker"
import cx from "classnames"
import {compareAsc, formatISO, getMinutes} from "date-fns"
import {arrayOf, func, object, shape, string} from "prop-types"
import {useCallback, useEffect, useState} from "react"
import {FaArchive as ArchiveIcon, FaEye as PreviewIcon} from "react-icons/fa"
import {MdClose as DeleteIcon, MdEdit as EditIcon} from "react-icons/md"

import {messageHasInvalidCta} from "components/template-content/nurturing-message-row"

import MaybeTooltip from "lib/maybe-tooltip"

import BadgeIfError from "../badge-if-error/badge-if-error"
import {Preview} from "../content-library/content-preview"
import {humanizeType} from "../content-library/helpers"
import DOSelect from "../do-select/do-select"
import IconTooltip from "../icon-tooltip/icon-tooltip"

const useStyles = makeStyles(theme => ({
  archived: {
    position: "relative",

    "&::before": {
      borderBottom: "60px solid transparent",
      borderLeft: `60px solid ${theme.palette.primary.main}`,
      content: `""`,
      height: 0,
      left: 0,
      position: "absolute",
      top: 0,
      width: 0,
    },
  },
  isPast: {
    opacity: 0.8,
  },
  archiveIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: 20,
    left: -8,
    position: "relative",
    top: -17,
  },
  row: {
    "& .showOnHover": {
      visibility: "hidden",
    },
    "&:hover .showOnHover": {
      visibility: "visible",
    },
  },
  edit: {
    textTransform: "uppercase",
    cursor: "pointer",
  },
  actionCell: {
    display: "flex",
    "& > button:first-of-type": {
      marginRight: theme.spacing(1),
    },
  },
  preview: {
    width: 306,
  },
  previewTooltip: {
    maxWidth: "none",
  },
  destructiveAction: {
    color: theme.palette.error.main,
  },
  actionText: {
    cursor: "pointer",
    "$isPast &": {
      cursor: "auto",
    },
  },
  picker: {
    marginTop: 8,
  },
  scheduledAt: {
    "& input": {
      cursor: "pointer",
      width: 155,
      fontSize: 14,
    },
  },
  disabled: {
    "& input": {
      cursor: "auto",
    },
  },
  dateInput: {
    width: 100,
  },
  timeInput: {
    width: 100,
  },
  select: {
    fontSize: 14,
    maxWidth: 200,
  },
}))

const hasValidSchedule = value => compareAsc(value, new Date()) > 0
const isMissingDate = value => value === null

// NB: `hasError `should be kept in-sync and mimic `handleError`. Add any new cases you add to
// `handleError` to `hasError`.
const hasError = value => {
  switch (true) {
    // handleError: Date and time is required
    case isMissingDate(value):
      return true
    // handleError: Use increments of 5 minutes
    case getMinutes(value) % 5 !== 0:
      return true
    // handleError: Invalid Date
    case isNaN(value):
      return true
    // handleError: Schedule must be in the future
    case !hasValidSchedule(value):
      return true
    default:
      // No errors
      return false
  }
}

const ScheduledMessageRow = ({
  column,
  row,
  setTemplateMessageToRemove,
  editTemplateMessage,
  templatePages,
  updateTemplateMessage,
}) => {
  const classes = useStyles()

  // `row` is a templateMessage record, so we rename it for clarity.
  const templateMessage = row
  const {id, messageId, message, queue, templatePageId} = templateMessage
  const {contentName, isArchived, objective} = message
  const messageType = humanizeType(message.type)
  // Offset to local timezone from persisted naive date
  const scheduledAt = new Date(row.scheduledAt)
  const isPast = compareAsc(new Date(), scheduledAt) > 0
  const isDraft = queue === "draft"
  const status = isDraft ? (isPast ? "Skipped" : "Draft") : isPast ? "Sent" : "Scheduled"

  const [dateTimePickerError, setDateTimePickerError] = useState(null)
  const [selectedDatetime, setSelectedDatetime] = useState(scheduledAt)
  const [shouldUpdateOnChange, setShouldUpdateOnChange] = useState(false)

  const onChangeIneligibilityCondition = ({target: {value}}) =>
    updateTemplateMessage(id, {
      templatePageId: value === "objective" ? null : value,
    })

  const handleChange = value => {
    // NB: `handleError` does not get triggered when going from a valid state to an empty state, so
    // we simulate that here in `handleChange` to force our `DateTimePicker` to be a `required`
    // field.
    if (value === null) handleError(null, null)

    setSelectedDatetime(value)
  }

  const handleBlurAndClose = () => {
    if (hasError(selectedDatetime)) return

    // NB: `handleChange` does not get called when date is selected via the DateTimePicker, so if
    // no errors are found, we clear the error status
    setDateTimePickerError(null)

    // shouldUpdateOnChange is a workaround for a race condition between handleChange and handleBlurAndClose
    setShouldUpdateOnChange(true)
  }

  const handleScheduleMessage = () => {
    updateTemplateMessage(id, {
      queue: "scheduled",
    })
  }

  // NB: `hasError` should mimic `handleError`, remember to add any new cases to `hasError` that
  // you add here.
  const handleError = (reason, value) => {
    // NB: A null `value` means no date is set. We cannot rely on `reason` here because `reason`
    // will be `null` when we are in a valid state and our `DateTimePicker` considers an empty date
    // field as valid. MUI version 5's DateTimePicker does not support the required field, so we
    // enforce it ourselves here.
    if (isMissingDate(value)) {
      setDateTimePickerError("Date and time is required")

      return
    }

    switch (reason) {
      case "minutesStep":
        setDateTimePickerError("Use increments of 5 minutes")
        break
      case "invalidDate":
        setDateTimePickerError("dd/mm/yyyy hh:mm AM (or PM)")
        break
      case "disablePast":
        setDateTimePickerError("Schedule must be in the future")
        break
      default:
        // Reset error if date is valid
        setDateTimePickerError(null)
    }
  }

  // This gracefully handles case of user entering a bad format in the text input
  // then opening the picker
  const handleOpen = () => {
    if (hasError(selectedDatetime)) setSelectedDatetime(scheduledAt)
  }

  const hasArchivedColumn = column.find(c => c.field === "archived")

  // Extracts spinning up the schedule update payload to minimize deps in useEffect
  const buildScheduleUpdatePayload = useCallback(() => {
    return {
      id,
      payload: {
        scheduledAt: formatISO(selectedDatetime, {representation: "complete"}),
      },
    }
  }, [id, selectedDatetime])

  // Handles race condition between handleChange and handleBlurAndClose by only updating
  // when handleChange has updated selectedDatetime and flipped shouldUpdateOnChange to true
  useEffect(() => {
    if (shouldUpdateOnChange) {
      const {id, payload} = buildScheduleUpdatePayload()

      updateTemplateMessage(id, payload)

      setShouldUpdateOnChange(false)
    }
  }, [
    buildScheduleUpdatePayload,
    setShouldUpdateOnChange,
    shouldUpdateOnChange,
    updateTemplateMessage,
  ])

  return (
    <TableRow className={cx(classes.row, {[classes.isPast]: isPast})} tabIndex={0}>
      {hasArchivedColumn && (
        <TableCell
          className={cx({[classes.archived]: isArchived})}
          component="td"
          data-testid="archived-column"
          scope="row"
        >
          {isArchived && (
            <IconTooltip
              iconClassName={classes.archiveIcon}
              iconComponent={ArchiveIcon}
              title={"This message is archived, but will still work as normal for this campaign."}
            />
          )}
        </TableCell>
      )}
      <TableCell
        className={cx(classes.scheduledAt, {[classes.disabled]: isPast})}
        component="td"
        scope="row"
      >
        <DateTimePicker
          className={classes.picker}
          disabled={isPast}
          disablePast
          hideTabs={false}
          inputFormat="MM/dd/yyyy hh:mm a"
          id={`message-${id}-date-picker-dialog`}
          margin="normal"
          mask={"__/__/____ __:__ _M"}
          minutesStep={5}
          onChange={handleChange}
          onClose={handleBlurAndClose}
          onError={handleError}
          onOpen={handleOpen}
          placeholder="Click to Schedule"
          renderInput={params => (
            <TextField
              {...params}
              error={!!dateTimePickerError}
              helperText={dateTimePickerError}
              onBlur={handleBlurAndClose}
              variant="standard"
            />
          )}
          showToolbar
          value={selectedDatetime}
        />
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{status}</Typography>
      </TableCell>
      <TableCell>
        <Tooltip
          classes={{tooltip: classes.previewTooltip}}
          placement="right"
          title={
            <div className={classes.preview}>
              <Preview
                contentType={messageType.toLowerCase()}
                id={messageId}
                smsPreviewText={message.payload?.body?.blocks[0]?.text}
              />
            </div>
          }
        >
          <div>
            <PreviewIcon />
          </div>
        </Tooltip>
      </TableCell>
      <TableCell component="td" scope="row">
        <BadgeIfError
          badgeText="This message contains a CTA with an invalid or incomplete link."
          hasError={messageHasInvalidCta(templateMessage, templatePages)}
        >
          <Typography
            className={classes.actionText}
            onClick={isPast ? () => {} : () => editTemplateMessage(id)}
            title={`Edit ${contentName}`}
            variant="body2"
          >
            {contentName}
          </Typography>
        </BadgeIfError>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{messageType}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <DOSelect
          className={classes.select}
          disabled={isPast}
          fullWidth={true}
          onChange={onChangeIneligibilityCondition}
          value={templatePageId || "objective"}
        >
          {objective ? (
            <MenuItem key="objective" value="objective">
              Objective Completed
            </MenuItem>
          ) : (
            <MenuItem key="objective" value="objective">
              Always Send
            </MenuItem>
          )}
          {templatePages?.map(tp => (
            <MenuItem key={tp.id} value={tp.id}>
              Completed {tp.page.contentName}
            </MenuItem>
          ))}
        </DOSelect>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{objective?.name || "(None)"}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        {isDraft ? (
          <Button
            color="primary"
            disabled={isPast}
            onClick={handleScheduleMessage}
            variant="contained"
          >
            Schedule
          </Button>
        ) : (
          <div className={cx(classes.actionCell, "showOnHover")}>
            <MaybeTooltip isTooltip={!isPast} title={`Edit ${contentName}`}>
              <IconButton disabled={isPast} onClick={() => editTemplateMessage(id)} size="medium">
                <EditIcon size={18} />
              </IconButton>
            </MaybeTooltip>
            <MaybeTooltip isTooltip={!isPast} title={`Remove ${contentName}`}>
              <IconButton
                className={classes.destructiveAction}
                disabled={isPast}
                onClick={() => setTemplateMessageToRemove(id)}
                size="medium"
              >
                <DeleteIcon size={18} />
              </IconButton>
            </MaybeTooltip>
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

ScheduledMessageRow.propTypes = {
  column: arrayOf(
    shape({
      field: string.isRequired,
    })
  ).isRequired,
  editTemplateMessage: func.isRequired,
  row: object.isRequired,
  setTemplateMessageToRemove: func.isRequired,
  templatePages: arrayOf(
    shape({
      id: string.isRequired,
      page: shape({
        contentName: string,
      }),
    })
  ),
  updateTemplateMessage: func.isRequired,
}

export default ScheduledMessageRow
