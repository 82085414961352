import pick from "lodash/pick"

import {createFile, fetchFiles, updateFile} from "lib/api"
import apiActionCreator from "lib/api-action-creator"
import uploadFileWithPolicy from "lib/upload-file-with-policy"

export const FILE_LIST_SET = "FILE_LIST_SET"
export const FILE_ADDED = "FILE_ADDED"

export const getFiles = apiActionCreator(FILE_LIST_SET, fetchFiles)

export const uploadFile = (browserFile, flavor) => dispatch =>
  createFile({...pick(browserFile, ["name", "type", "size"]), flavor}).then(fileRecord => {
    const {upload_policy: uploadPolicy, id, name} = fileRecord
    let extraAttrs = {
      ...uploadPolicy.attrs,
      "x-amz-meta-gondor-file-id": id,
      "x-amz-meta-original-file-name": name,
    }

    return uploadFileWithPolicy(browserFile, uploadPolicy.policy, {extraAttrs})
      .then(() => updateFile(fileRecord.id, {isUploaded: true}))
      .then(() => dispatch({type: FILE_ADDED, payload: fileRecord}))
  })
