import {TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cx from "classnames"
import orderBy from "lodash/orderBy"
import pick from "lodash/pick"
import {func, number, object, string} from "prop-types"
import {useEffect, useMemo} from "react"
import {BsDashLg as DashIcon} from "react-icons/bs"
import {FaTrophy} from "react-icons/fa"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchAdvancedInsightsObjectiveTotalValue} from "lib/api"

const namespace = "advanced-team-insights-objective-total-value"

const headers = [
  {
    field: "driverName",
    label: "Value Driver",
    sortable: true,
  },
  {
    field: "value",
    label: "Current",
    sortable: true,
    tooltipText:
      "Current: The Value Driver's value x the total # of objective completions mapped to that Value Driver.",
  },
  {
    field: "valueAtFivePercentLift",
    label: "5% lift",
    sortable: true,
    tooltipText:
      "5% lift: The Value Driver's Current value x 1.05 to showcase a 5% lift value increase.",
  },
  {
    field: "valueAtTenPercentLift",
    label: "10% lift",
    sortable: true,
    tooltipText:
      "10% lift: The Value Driver's Current value x 1.1 to showcase a 10% lift value increase.",
  },
  {
    field: "platformLift",
    label: "Platform lift",
    sortable: true,
    tooltipText:
      "Platform Lift: The average performance of each Value Driver across all teams. If you are outperforming the average, the top team's Current Value is shown instead.",
  },
]

// NB: Input -> Output
// "My String" -> "My String"
// 1 -> $1
// 1. -> $1
// 1.0 -> $1
// 1.2 -> $1.20
// 1.24 -> $1.24
// 1.243 -> $1.24
const humanize = value => {
  if (isNaN(value)) return value

  const dollarValue = Number.isInteger(value) ? value : value.toFixed(2)
  return `$${dollarValue}`
}

const ObjectiveCompletionBenchmarking = ({classes, setTableState, ...props}) => {
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    objectiveTotalValue,
    objectiveTotalValueIsLoading,
  } = useAdvancedTeamInsights()

  const _classes = useStyles()

  const isTopPerformer = row => row.value && row.value >= row.platformMax

  const isBeatingAverage = row => row.value && row.value >= row.platformAverage

  const trophyColors = {beatingAverage: "#6098d2", topPerformer: "#76bbb0"}

  const data = useMemo(() => {
    if (!objectiveTotalValue) return []
    if (!objectiveTotalValue.showTable) return []
    if (!objectiveTotalValue.data) return []

    const calcPlatformLift = row =>
      (isBeatingAverage(row) ? row.platformMax : row.platformAverage) || 0

    // We precalculate platformLift and add it to the response payload before handing it off to
    // DOTable, so that DOTable knows how to sort the associated columns.
    return objectiveTotalValue.data.map(row => ({
      ...row,
      platformLift: calcPlatformLift(row),
    }))
  }, [objectiveTotalValue])

  useEffect(() => {
    if (hasSocket)
      fetchInsights("objectiveTotalValue", fetchAdvancedInsightsObjectiveTotalValue, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  const refresh = attrs => {
    const currentItemsPerPage = attrs?.itemsPerPage ?? props.itemsPerPage
    const currentPage = attrs?.page ?? props.page
    const offset = currentPage * currentItemsPerPage
    const sortColumn = attrs?.sortColumn ?? props.sortColumn
    const sortDirection = attrs?.sortDirection ?? props.sortDirection

    const rows = orderBy(
      data,
      [
        row => {
          const value = row[sortColumn]
          return isNaN(value) ? (value || "").toLowerCase() : value
        },
      ],
      [sortDirection]
    ).filter((_row, i) => i >= offset && i < offset + currentItemsPerPage)

    setTableState({
      ...pick(props, "itemsPerPage", "page", "sortColumn", "sortDirection"),
      ...attrs,
      rows,
      page: currentPage,
      totalCount: data.length ?? 0,
    })
  }

  const renderCell = (row, column) => {
    if (row.isBackfilled && column !== "driverName")
      return <DashIcon data-testid="dash-icon" color="#000000" size={20} />

    return humanize(row[column])
  }

  const renderPlatformLiftValue = row => {
    if (isTopPerformer(row) || !row.platformLift)
      return <DashIcon data-testid="dash-icon" color="#000000" size={20} />

    return (
      <Tooltip title="The Platform average for objective completion rate that are mapped to the same value driver shown in the table multiplied by the value you have inserted for that specific value driver. This represents the Platform's highest objective completion rate for each mapped value driver multiplied by the value you have inserted for that specific value driver.">
        <span>{humanize(row.platformLift)}</span>
      </Tooltip>
    )
  }

  const renderPlatformLiftTrophy = row => {
    if (isTopPerformer(row))
      return renderTrophyIcon({
        tooltip: "Amazing! You’re our top performing team, keep up the amazing work!",
        color: trophyColors.topPerformer,
      })

    if (isBeatingAverage(row))
      return renderTrophyIcon({
        tooltip:
          "Congratulations! You're beating the platform average, so we are now showing the Top Performing team's value.",
        color: trophyColors.beatingAverage,
      })
  }

  const renderTrophyIcon = ({tooltip, color}) => (
    <div style={{float: "right", fontSize: "1.5em"}}>
      <Tooltip title={tooltip}>
        <span>
          <FaTrophy color={color} />
        </span>
      </Tooltip>
    </div>
  )

  useEffect(() => {
    // NB: This is needed so that our rows are effected by our default state.
    // This should only get called after fetchInsights or our socket
    // returns new data.
    refresh({page: 0})

    // FIXME ignoring react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className={cx(_classes.container)}>
      <Typography className={_classes.title} variant="h5">
        Total Value
      </Typography>
      <DOTable
        headers={headers}
        isTableLoading={objectiveTotalValueIsLoading}
        noResults="No data available."
        paginationEnabled={true}
        refresh={refresh}
      >
        {(row, index) => {
          return (
            <TableRow key={`${namespace}-${index}`}>
              <TableCell>{renderCell(row, "driverName")}</TableCell>
              <TableCell>{renderCell(row, "value")}</TableCell>
              <TableCell>{renderCell(row, "valueAtFivePercentLift")}</TableCell>
              <TableCell>{renderCell(row, "valueAtTenPercentLift")}</TableCell>

              <TableCell key={`${namespace}-${index}-platformLift`}>
                {renderPlatformLiftValue(row)}
                {renderPlatformLiftTrophy(row)}
              </TableCell>
            </TableRow>
          )
        }}
      </DOTable>
    </div>
  )
}

ObjectiveCompletionBenchmarking.propTypes = {
  classes: object,
  itemsPerPage: number,
  page: number,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
}

const useStyles = makeStyles(theme => ({
  container: {
    "& tr th": {
      width: 100,
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default tabular({itemsPerPage: 5, sortColumn: "driverName", sortDirection: "asc"})(
  ObjectiveCompletionBenchmarking
)
