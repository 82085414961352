import {Box, Button} from "@mui/material"
import List from "@mui/material/List"
import {isEqual} from "lodash"
import {arrayOf, bool, object} from "prop-types"
import React, {useContext, useEffect, useState} from "react"
import shortid from "shortid"

import {RewardSetFormContext} from "./reward-set-form-context-provider"
import {defaultStep} from "./reward-step-conditions/step-components"
import StepCondition from "./reward-step-conditions/step-condition"

const RewardStepList = ({isEditable, rewardStepConfigList}) => {
  const [configList, setConfigList] = useState(rewardStepConfigList)
  const {dispatch, rewardStepFormList} = useContext(RewardSetFormContext)

  const handleAdd = () => {
    const config = {
      ...defaultStep,
      initialValues: {...defaultStep.initialValues, id: shortid.generate()},
    }

    setConfigList([...configList, config])
  }

  const handleDelete = id => () => {
    dispatch({type: "DELETE_REWARD_STEP_FORM", id})
    setConfigList(configList.filter(config => config.initialValues.id !== id))
  }

  useEffect(() => {
    return () => dispatch({type: "RESET_REWARD_STEP_FORMS_LIST"})
  }, [dispatch])

  // Set form dirty when reward step is deleted
  useEffect(() => {
    const initialIds = rewardStepConfigList.map(config => config.initialValues.id)
    const currentIds = rewardStepFormList.map(form => form.data.id)

    dispatch({type: "SET_IS_LIST_DIRTY", isListDirty: !isEqual(initialIds, currentIds)})
  }, [dispatch, rewardStepConfigList, rewardStepFormList])

  return (
    <List sx={{padding: 0, "& .MuiListItem-root": {padding: 0, margin: 0}}}>
      {configList.map(config => (
        <StepCondition
          config={config}
          isEditable={isEditable}
          key={config.initialValues.id}
          onDelete={handleDelete(config.initialValues.id)}
        />
      ))}
      {isEditable && (
        <Box>
          <Button
            fullWidth
            color="primary"
            id="add-reward-step-button"
            variant="contained"
            onClick={handleAdd}
          >
            Add Step
          </Button>
        </Box>
      )}
    </List>
  )
}

RewardStepList.defaultProps = {isEditable: false}

RewardStepList.propTypes = {
  isEditable: bool,
  rewardStepConfigList: arrayOf(object).isRequired,
}

export default RewardStepList
