import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material"
import {bool, func, object, string} from "prop-types"
import {MdFileDownload as DownloadIcon} from "react-icons/md"
import {useDispatch, useSelector} from "react-redux"

import {cancelExport, exportRecords} from "./exporter-actions"

export const ExportButton = ({
  disabled,
  fetchRecords,
  filename,
  params,
  sortHeaders,
  title,
  transform,
}) => {
  const dispatch = useDispatch()
  const {exportError, exportProgress, isExporting} = useSelector(
    ({exporter: {isExporting, exportError, exportProgress} = {}}) => ({
      exportError,
      exportProgress,
      isExporting,
    })
  )
  const onExport = () =>
    exportRecords(fetchRecords, filename, params, sortHeaders, transform)(dispatch)

  const onCloseDialog = () => dispatch(cancelExport())

  return (
    <>
      <Button
        color="primary"
        disabled={disabled}
        onClick={onExport}
        size="small"
        variant="contained"
      >
        <DownloadIcon style={{marginRight: 10}} /> {title}
      </Button>
      <Dialog onClose={onCloseDialog} open={!!isExporting}>
        <DialogTitle>{exportError ? "An error occurred" : "Preparing your export..."}</DialogTitle>
        <DialogContent>
          <LinearProgress
            sx={theme => {
              const styles = {
                "& .MuiLinearProgress-bar": {
                  transition: theme.transitions.create([
                    "background-color",
                    "transform",
                    {easing: "linear"},
                  ]),
                },
              }
              return exportError
                ? {
                    ...styles,
                    "& .MuiLinearProgress-bar1Determinate": {
                      backgroundColor: theme.palette.error.main,
                    },
                  }
                : styles
            }}
            value={exportProgress}
            variant="determinate"
          />
          {exportError && (
            <Typography sx={theme => ({marginTop: theme.spacing(2)})}>
              There was an error generating your export. Please try again later.
            </Typography>
          )}
        </DialogContent>
        {exportError && (
          <DialogActions>
            <Button className="cancel" color="primary" onClick={onCloseDialog} type="button">
              Cancel
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

ExportButton.propTypes = {
  classes: object,
  disabled: bool,
  fetchRecords: func.isRequired,
  filename: string.isRequired,
  params: object,
  sortHeaders: func,
  title: string.isRequired,
  transform: func,
}

export default ExportButton
