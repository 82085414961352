import {CharacterMetadata, EditorState} from "draft-js"
import {Map} from "immutable"

const modifyCharacterMetadataStyle = (charMetadata, prefix, value) => {
  const inlineStyle = `${prefix}-${value}`
  const newCharStyle = charMetadata
    .getStyle()
    .filter(style => !style.startsWith(prefix))
    .add(inlineStyle)

  const updatedCharMetadata = charMetadata.set("style", newCharStyle)

  return CharacterMetadata.create(updatedCharMetadata)
}

// inspired by: https://github.com/facebook/draft-js/blob/6217dc8189154e1444cff66f3f7ea453ac8f804c/src/model/transaction/ContentStateInlineStyle.js#L38
const modifyInlineStyle = (contentState, selectionState, prefix, value) => {
  const blockMap = contentState.getBlockMap()
  const startKey = selectionState.getStartKey()
  const startOffset = selectionState.getStartOffset()
  const endKey = selectionState.getEndKey()
  const endOffset = selectionState.getEndOffset()

  const newBlocks = blockMap
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat(Map([[endKey, blockMap.get(endKey)]]))
    .map((block, blockKey) => {
      let sliceStart
      let sliceEnd

      if (startKey === endKey) {
        sliceStart = startOffset
        sliceEnd = endOffset
      } else {
        sliceStart = blockKey === startKey ? startOffset : 0
        sliceEnd = blockKey === endKey ? endOffset : block.getLength()
      }

      let chars = block.getCharacterList()
      let current

      while (sliceStart < sliceEnd) {
        current = chars.get(sliceStart)
        chars = chars.set(sliceStart, modifyCharacterMetadataStyle(current, prefix, value))
        sliceStart++
      }

      return block.set("characterList", chars)
    })

  return contentState.merge({
    blockMap: blockMap.merge(newBlocks),
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  })
}

// inspiration https://github.com/facebook/draft-js/blob/37f2f2ac16e4edf29c7c7e8230dec544faeafdfe/examples/draft-0-10-0/color/color.html#L50
export default function toggleInlineStyle(editorState, prefix, value) {
  const inlineStyle = `${prefix}-${value}`
  const selection = editorState.getSelection()
  const otherStyles = editorState
    .getCurrentInlineStyle()
    .filterNot(style => style.startsWith(prefix))

  // Unset style override for current style. https://github.com/facebook/draft-js/blob/6217dc8189154e1444cff66f3f7ea453ac8f804c/src/model/modifier/RichTextEditorUtil.js#L306
  if (selection.isCollapsed())
    return EditorState.setInlineStyleOverride(
      EditorState.forceSelection(editorState, selection),
      otherStyles.add(inlineStyle)
    )

  // Let's just allow one style of the same type at a time.
  const nextContentState = modifyInlineStyle(
    editorState.getCurrentContent(),
    selection,
    prefix,
    value
  )

  // If the style is being toggled on, apply it.
  return EditorState.forceSelection(
    EditorState.push(editorState, nextContentState, "change-inline-style"),
    selection
  )
}
