import {Button, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {uniqBy} from "lodash"
import {arrayOf, func, number, object, oneOfType, string} from "prop-types"
import {Component} from "react"
import {Link} from "react-router-dom"

import Box from "components/box/box"
import ConfirmDialog from "components/dialogs/confirm-dialog"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {createHandler, socketify} from "contexts/socket-manager"
import {deleteBatch, fetchBatches} from "lib/api"
import {toCamelCase} from "lib/case-converter"
import {parameterize} from "lib/hooks/use-query-params"

import BatchProcessingRow from "./batch-processing-row"

const headers = [
  {
    field: "source",
    sortable: false,
  },
  {
    field: "batchId",
    sortable: false,
  },
  {
    field: "type",
    sortable: false,
  },
  {
    field: "uploadedBy",
    sortable: false,
  },
  {
    field: "uploadedAt",
    sortable: false,
  },
  {
    field: "percentCompleted",
    sortable: false,
  },
  {
    field: "batchActions",
    label: "",
    sortable: false,
  },
]

class BatchProcessingControlCenter extends Component {
  state = {
    isTableLoading: false,
    handlers: [],
  }

  componentDidMount = () => {
    this.refresh({})

    this.setupHandlers()
  }

  componentWillUnmount = () => {
    this.removeHandlers()
  }

  setupHandlers = () => {
    const {addHandler} = this.props

    const batchProcessed = createHandler("batches", "batch_processed", batch => {
      this.markBatchCompleted(batch)
    })

    addHandler(batchProcessed)

    const batchOperationCompleted = createHandler("batches", "batch_operation_completed", () => {
      this.refresh({})
    })

    addHandler(batchOperationCompleted)

    const batchAdded = createHandler("batches", "batch_created", batch => {
      this.addBatch(batch)
    })

    addHandler(batchAdded)

    const batchDeleted = createHandler("batches", "batch_deleted", batch => {
      this.removeBatch(batch)
    })

    addHandler(batchDeleted)

    this.setState({handlers: [batchProcessed, batchOperationCompleted, batchAdded, batchDeleted]})
  }

  removeHandlers = () => {
    const {handlers} = this.state
    const {removeHandler} = this.props

    handlers.forEach(removeHandler)
  }

  markBatchCompleted = item => {
    const {rows, setTableState} = this.props

    const completedBatchOperation = toCamelCase(item)

    const batchExists = rows.find(row => row.batchId === completedBatchOperation.batchId)

    if (!batchExists) {
      this.refresh({})
      return
    }

    const nextRows = rows.map(row =>
      row.batchId === completedBatchOperation.batchId
        ? {
            ...row,
            batches: row.batches.map(batchOperation =>
              batchOperation.id === completedBatchOperation.id && !batchOperation.results
                ? {...batchOperation, ...completedBatchOperation}
                : batchOperation
            ),
          }
        : row
    )

    setTableState({rows: nextRows})
  }

  addBatch = item => {
    const {rows} = this.props
    const newBatch = toCamelCase(item)
    const batchExists = rows.find(row => row.batchId === newBatch.batchId)

    if (batchExists) {
      const nextRows = rows.map(row =>
        row.batchId === newBatch.batchId
          ? {...row, batches: uniqBy([...row.batches, newBatch], "id")}
          : row
      )

      this.props.setTableState({rows: nextRows})
      return
    }

    const nextRows = [{...newBatch, batches: [newBatch]}, ...rows]

    this.props.setTableState({rows: nextRows})
  }

  removeBatch = ({batch_id}) => {
    const {rows} = this.props

    const nextRows = rows.filter(row => row.batchId !== batch_id)

    this.props.setTableState({rows: nextRows})
  }

  refresh = attrs => {
    const {updateStateForRequest} = this.props

    const params = updateStateForRequest(attrs)

    this.setState({isTableLoading: true}, () => {
      fetchBatches(params, {withFetchResponse: true})
        .then(([rows, fetchResponse]) => {
          this.props.setTableState({fetchResponse, rows})
        })
        .finally(() => {
          this.setState({isTableLoading: false})
        })
    })
  }

  onClickRow = batchId => {
    if (this.props.batchId === batchId) this.props.updateSearchQuery({batchId: null})
    else this.props.updateSearchQuery({batchId})
  }

  onDeleteBatch = batchId => {
    this.setState({batchToDelete: batchId})
  }

  onCancelBatchDeletion = () => {
    this.setState({batchToDelete: null})
  }

  onConfirmBatchDeletion = () => {
    const batchId = this.state.batchToDelete

    this.setState({batchToDelete: null}, () => {
      deleteBatch(batchId)
    })
  }

  render() {
    const {classes, batchId} = this.props
    const {isTableLoading, batchToDelete} = this.state

    return (
      <Box>
        <ConfirmDialog
          content={
            <>
              Are you sure that you want to cancel processing this batch? This cannot be undone.
              <br />
              We will attempt to stop any in progress journey or contact creations, but any that are
              already created cannot be removed.
            </>
          }
          onClose={this.onCancelBatchDeletion}
          onConfirm={this.onConfirmBatchDeletion}
          open={!!batchToDelete}
          title="Cancel processing this batch?"
        />
        <DOTable
          headers={headers}
          isTableLoading={isTableLoading}
          noResults={
            <div className={classes.noResults}>
              <Typography variant="h2">No batches found.</Typography>
              <Typography variant="body2">Batches are removed after 48 hours.</Typography>
              <Button
                color="primary"
                component={Link}
                to="/admin/contacts/batch-upload"
                variant="contained"
              >
                Upload Contacts
              </Button>
            </div>
          }
          refresh={this.refresh}
        >
          {(row, index, columns) => (
            <BatchProcessingRow
              columns={columns}
              // if batchId happens to be a number (this is user generated),
              // useQueryParams parses it as such and breaks this equality check
              // so we have to coerce it first
              isActiveRow={"" + batchId === row.batchId}
              key={row.batchId}
              onClick={this.onClickRow}
              onDelete={this.onDeleteBatch}
              row={row}
            />
          )}
        </DOTable>
      </Box>
    )
  }
}

BatchProcessingControlCenter.propTypes = {
  addHandler: func.isRequired,
  batchId: oneOfType([string, number]),
  classes: object,
  removeHandler: func.isRequired,
  rows: arrayOf(object),
  setTableState: func.isRequired,
  updateSearchQuery: func.isRequired,
  updateStateForRequest: func.isRequired,
}

const styles = theme => ({
  noResults: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontStyle: "normal",
    padding: theme.spacing(2),
    "& h2": {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginBottom: theme.spacing(1),
    },
  },
})

export default tabular({sortColumn: "insertedAt", sortDirection: "desc"})(
  parameterize({batchId: ""})(socketify(withStyles(styles)(BatchProcessingControlCenter)))
)
