import {Checkbox, FormControlLabel} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, bool, func, string} from "prop-types"
import {connect} from "react-redux"

import MaybeTooltip from "lib/maybe-tooltip"

import {isLockingSelfOut} from "./user-permissions-form-group"

const useStyles = makeStyles({
  labelRoot: {
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    marginTop: 10,
  },
})

const UserPermissionCheckbox = ({
  disabled,
  label,
  onChange,
  permission,
  selectedPermissions,
  currentUserId,
  userId,
}) => {
  const handleChange = ({target: {checked}}) => {
    onChange(permission, checked)
  }
  const classes = useStyles()
  const isChecked = selectedPermissions.includes(permission)
  const _isLockingSelfOut = isChecked && isLockingSelfOut(permission, userId, currentUserId)

  return (
    <MaybeTooltip
      isTooltip={_isLockingSelfOut}
      title="You cannot remove your own permission to manage users"
    >
      <FormControlLabel
        classes={{label: classes.label, root: classes.labelRoot}}
        control={
          <Checkbox
            checked={isChecked}
            color="primary"
            onChange={handleChange}
            value={permission}
          />
        }
        disabled={disabled || _isLockingSelfOut}
        key={permission}
        label={label}
      />
    </MaybeTooltip>
  )
}

UserPermissionCheckbox.propTypes = {
  currentUserId: string.isRequired,
  disabled: bool,
  label: string.isRequired,
  onChange: func.isRequired,
  permission: string.isRequired,
  selectedPermissions: arrayOf(string).isRequired,
  userId: string,
}

export default connect(
  ({
    session: {
      user: {id: currentUserId},
    },
  }) => ({currentUserId}),
  null
)(UserPermissionCheckbox)
