import {Button, Grid, IconButton} from "@mui/material"
import {styled} from "@mui/material/styles"
import {arrayOf, func, string} from "prop-types"
import {FaPlus, FaTrash} from "react-icons/fa"

import ColorInput from "components/color-input/color-input"

const ColorListGridContainer = styled(Grid)(({theme}) => ({
  alignItems: "flex-end",
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(3),
  "& .MuiGrid-item": {alignItems: "center", display: "flex", justifyContent: "center"},
}))

const ColorInputList = ({colors, onChange}) => {
  const handleAddColor = () => {
    // Default to global default for primary color
    const defaultColor = "#00AFFF"

    onChange([...colors, defaultColor])
  }

  const handleChangeColor = updatedIndex => updatedColor => {
    const updatedColors = colors.map((color, index) =>
      index === updatedIndex ? updatedColor : color
    )

    onChange(updatedColors)
  }

  const handleDeleteColor = deleteIndex => () => {
    const updatedColors = colors.filter((_, index) => index !== deleteIndex)

    // Setting to undefined causes the key to be removed from styles JSON blobs
    onChange(updatedColors.length ? updatedColors : undefined)
  }

  return (
    <>
      {colors.map((color, index) => (
        <ColorListGridContainer container key={`confetti-color-${index}`}>
          <Grid item lg={6} md={11} xs={11}>
            <ColorInput
              fullWidth
              id={`rewards-confetti-color-${index}`}
              input={{
                onChange: handleChangeColor(index),
                value: color,
              }}
              hideCustomPalette
              label="Color"
            />
          </Grid>
          <Grid item lg={1} md={1} xs={1}>
            <IconButton aria-label="delete" onClick={handleDeleteColor(index)}>
              <FaTrash fontSize="large" />
            </IconButton>
          </Grid>
        </ColorListGridContainer>
      ))}
      <Grid item lg={7} md={12} xs={12}>
        <Button
          color="primary"
          onClick={handleAddColor}
          sx={theme => ({"& svg": {marginRight: theme.spacing(1)}})}
          variant="contained"
        >
          <FaPlus /> Add a color
        </Button>
      </Grid>
    </>
  )
}

ColorInputList.propTypes = {
  colors: arrayOf(string).isRequired,
  onChange: func.isRequired,
}

export default ColorInputList
