import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, arrayOf, func, number, object, shape, string} from "prop-types"
import {PureComponent} from "react"

import {fetchTemplateJourneys} from "lib/api"
import {formify} from "lib/hooks/use-form"
import {fullName} from "lib/names"

import {templateContentContext} from "../../contexts/template-content-context"
import AccessControlled from "../access-control/access-controlled"
import Box from "../box/box"
import CsvDownloadButton from "../csv-download-button/csv-download-button"
import DOSelect from "../do-select/do-select"
import DocumentTitle from "../document-title/document-title"
import DOTable from "../table/table"
import {tabular} from "../table/table-state"
import {templateContext} from "../template-router/template-context"
import TemplateJourneysRow from "./template-journeys-row"

const tableHeaders = (filterBy = {}) =>
  [
    {field: "name", isDefault: true},
    {field: "viewStatus", label: "View Status", sortable: false, isDefault: true},
    {field: "insertedAt", label: "Created", isDefault: true},
    {field: "journeyDurationHours", label: "Journey Duration", isDefault: true},
    {field: "owner", label: "Support Owner", sortable: false, isDefault: true},
    {field: "analyticsCache.lastViewed", label: "Last Viewed", isDefault: true},
  ].filter(({field}) => (filterBy.hasOwnProperty(field) ? filterBy[field] : true))

export class TemplateJourneys extends PureComponent {
  state = {isTableLoading: false}

  componentDidMount = () => {
    const {batchId, fetchTemplatePages} = this.props
    const filters = {batchId}

    fetchTemplatePages()
    this.refresh({filters})
  }

  onDownloadCsvExport = () => this.props.getCsvExport(this.props.match.params.templateId)
  onDownloadSurveyAnswers = () => this.props.getSurveyAnswers(this.props.match.params.templateId)

  refresh = attrs => {
    const {updateStateForRequest} = this.props
    const {templateId} = this.props.match.params
    const params = updateStateForRequest(attrs)

    if (params.sortColumn === "journey_duration_hours") params.sortColumn = "inserted_at"
    // naive for now, but if we want this to use more columns should look into
    // not simply calling `snakeCase` on the sortColumn in in table-state#prepStateForRequest
    else if (params.sortColumn === "analytics_cache_last_viewed")
      params.sortColumn = "analytics_cache.last_viewed"

    this.setState({isTableLoading: true}, () => {
      fetchTemplateJourneys(templateId, params)
        .then(([rows, fetchResponse]) => {
          this.props.setTableState({fetchResponse, rows})
        })
        .finally(() => {
          this.setState({isTableLoading: false})
        })
    })
  }

  filter = ({batchId, ...filters}) => {
    if (batchId) filters.batchId = this.props.batchId // eslint-disable-line no-param-reassign

    this.refresh({page: 0, filters})
  }

  render() {
    const {batchId, classes, template, users, templatePages} = this.props
    const {isTableLoading} = this.state

    if (!template?.id) return null

    return (
      <Box className="template-journeys">
        {template.name && <DocumentTitle title={`Campaigns - ${template.name} - Journeys`} />}
        <div className={classes.widgetButtons}>
          {template.hasSurveyContentBlocks && (
            <AccessControlled requiredPermissions="journeys:performance">
              <CsvDownloadButton
                buttonText="Survey Answers"
                color="primary"
                data-testid="download-survey-answers"
                download={`${template.name} Survey Answers.csv`}
                getContent={this.onDownloadSurveyAnswers}
              />
            </AccessControlled>
          )}
          <AccessControlled requiredPermissions="journeys:performance">
            <CsvDownloadButton
              buttonText="CSV Export"
              color="primary"
              download={`${template.name} Journeys Export.csv`}
              getContent={this.onDownloadCsvExport}
            />
          </AccessControlled>
        </div>
        <DOTable
          allowColumnCustomization={true}
          filterComponent={
            <TemplateJourneysFilters
              batchId={batchId}
              classes={classes}
              onSubmit={this.filter}
              users={users}
            />
          }
          headers={tableHeaders({journeyDurationHours: !!template.journeyDurationHours})}
          isTableLoading={isTableLoading}
          noResults="No journeys found"
          refresh={this.refresh}
          storageName="template-journeys"
        >
          {(row, index, columns) => (
            <TemplateJourneysRow
              columns={columns}
              key={row.id}
              row={row}
              template={template}
              templatePages={templatePages}
            />
          )}
        </DOTable>
      </Box>
    )
  }
}

TemplateJourneys.propTypes = {
  batchId: string,
  classes: object.isRequired,
  fetchTemplatePages: func.isRequired,
  getCsvExport: func.isRequired,
  getSurveyAnswers: func.isRequired,
  match: shape({
    params: shape({
      templateId: string.isRequired,
    }),
  }),
  setTableState: func.isRequired,
  template: shape({
    journeyDurationHours: number,
    name: string,
  }),
  templatePages: array,
  updateStateForRequest: func.isRequired,
  users: array.isRequired,
}

const Filters = ({batchId, classes, field, resetForm, users}) => (
  <>
    <TextField autoFocus={true} label="Search for Journeys" {...field("search")} />
    <FormControl className={classes.ownerFilter}>
      <InputLabel>Filter by Owner</InputLabel>
      <DOSelect name="owner_id" {...field("owner_id")}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {users.map(user => (
          <MenuItem key={user.id} value={user.id}>
            {fullName(user)}
          </MenuItem>
        ))}
      </DOSelect>
    </FormControl>
    {!!batchId && (
      <FormControlLabel
        className={classes.batchSwitch}
        control={<Switch {...field("batchId", {bool: true, defaultValue: true})} color="primary" />}
        label="Most Recent Only"
      />
    )}
    <Button color="grey" onClick={resetForm}>
      reset
    </Button>
  </>
)

Filters.propTypes = {
  batchId: string,
  classes: object.isRequired,
  field: func.isRequired,
  resetForm: func.isRequired,
  users: arrayOf(
    shape({
      nameFirst: string,
      nameLast: string,
      nameSuffix: string,
      nameTitle: string,
    })
  ).isRequired,
}

const TemplateJourneysFilters = formify({
  autoSubmitOnChange: true,
  autoSubmitDebounceTime: 250,
  enableReinitialize: true,
})(Filters)

const styles = theme => ({
  widgetButtons: {
    marginBottom: theme.spacing(2),
    "& button, &a": {
      marginRight: theme.spacing(1),
    },
  },
  ownerFilter: {
    minWidth: 300,
  },
})

export default withStyles(styles)(
  tabular({sortColumn: "insertedAt", sortDirection: "desc", useQueryParams: true})(
    templateContext(templateContentContext(TemplateJourneys))
  )
)
