// This file was generated automatically from character-10.svg with the following command:
// ./svg-converter.js src/components/superhero/character-10.svg
import SuperheroSvg from "./superhero-svg"

const Character10 = props => (
  <SuperheroSvg viewBox="70 20 120 240" {...props}>
    <g id="Layer_6">
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M140.28,241c0,0-4.59,8.76-2.09,11.26c0,0,13.76,3.75,31.28,1.25
  			c0,0,6.26-2.5-1.25-6.26c0,0-12.51-3.75-13.76-8.76"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M165.55,246.44c0,0-4.77,3.65-0.81,7.61"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M137.62,248.25c0,0,4.24,2.63,25.55,2.69"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M148.82,239.13c0,0-0.42,5.93,14.8,10.47"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M152.57,242.88c0,0,2.5-2.5,2.5,0s0,2.5,2.5,2.5
  			c2.5,0,2.5,0,2.5,1.25s2.5,0,2.5,0"
        />
      </g>
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M80.7,231.77c0,0-7.29,6.68-5.78,9.88c0,0,11.67,8.2,29,11.79
  			c0,0,6.73-0.23,0.94-6.31c0,0-10.5-7.77-9.98-12.91"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M102.64,245.46c0,0-5.73,1.81-3.34,6.89"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M75.74,237.69c0,0,3.1,3.91,23.12,11.2"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M89.38,232.91c0,0-2.41,5.43,10.37,14.87"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M91.64,237.71c0,0,3.2-1.51,2.35,0.85
  			c-0.85,2.35-0.85,2.35,1.51,3.2c2.35,0.85,2.35,0.85,1.93,2.03c-0.42,1.18,2.35,0.85,2.35,0.85"
        />
      </g>
      <path
        style={{fill: "#CC5F64"}}
        d="M162.73,181.36l-2.95-77.05l-20.63-4.27l3.37,22.76l0.18,64.89c0,0-2.42,20.44-5.11,51.85
  		l22.18-0.67C159.77,238.88,163.08,205.28,162.73,181.36z"
      />
      <path
        style={{fill: "#EB6669"}}
        d="M109.02,186.74l9.24-63.38l2.41-15.65l6.22-0.82c10.35-0.79,20.7-1.59,31.04-2.38
  		c1.4,5.04,2.8,10.07,4.21,15.11c0.18,0.88,0.3,1.78,0.35,2.7l0.19,1.46l-0.17,0.01c0,0.19,0,0.37-0.01,0.56
  		c-0.48,10.03-8.54,18.04-18.78,19.44l-0.17,0.39L135,185.32c0,0-2.1,9.68-6.16,15.45l-24.12,36.41l-31.41-6.16l30.65-35.99
  		C103.97,195.03,107.91,190.97,109.02,186.74z"
      />
      <g>
        <defs>
          <rect
            id="SVGID_00000114772510280768368730000011828268075274687381_"
            x="-46.83"
            y="28.57"
            width="240.28"
            height="193.45"
          />
        </defs>
        <clipPath id="6e1e8bc8-SVGID_00000160177110373809879350000009953181120760189070_">
          <use
            xlinkHref="#SVGID_00000114772510280768368730000011828268075274687381_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <g style={{clipPath: "url(#SVGID_00000160177110373809879350000009953181120760189070_)"}}>
          <g>
            <g>
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.4939",
                  strokeMiterlimit: "10",
                }}
                d="M87.09,223.3c0,0-9.7,6.58-8.55,9.75
  						c0,0,10.49,8.84,26.86,13.84c0,0,6.52,0.41,1.51-5.88c0,0-9.39-8.33-8.4-13.13"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.4939",
                  strokeMiterlimit: "10",
                }}
                d="M104.93,239.22c0,0-5.7,1.18-3.88,6.2"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.4939",
                  strokeMiterlimit: "10",
                }}
                d="M79.72,229.37c0,0,2.62,3.98,21.25,12.74
  						"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.4939",
                  strokeMiterlimit: "10",
                }}
                d="M93.33,226.12c0,0-2.84,4.91,8.6,15.02"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.4939",
                  strokeMiterlimit: "10",
                }}
                d="M95.05,230.87c0,0,3.23-1.12,2.19,1.02
  						s-1.04,2.15,1.15,3.17c2.19,1.02,2.19,1.02,1.67,2.09c-0.52,1.07,2.19,1.02,2.19,1.02"
              />
            </g>
            <g>
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.752",
                  strokeMiterlimit: "10",
                }}
                d="M143.05,235.89c0,0-5.49,8.86-2.99,11.64
  						c0,0,14.44,4.83,33.31,3.37c0,0,6.85-2.2-0.91-6.63c0,0-13.11-4.75-14.11-10.07"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.752",
                  strokeMiterlimit: "10",
                }}
                d="M169.68,243.25c0,0-5.34,3.5-1.37,7.91"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.752",
                  strokeMiterlimit: "10",
                }}
                d="M139.73,243.3c0,0,4.35,3.03,27.1,4.5"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.752",
                  strokeMiterlimit: "10",
                }}
                d="M152.31,234.49c0,0-0.85,6.18,15.1,11.93"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#5198D2",
                  strokeWidth: "2.752",
                  strokeMiterlimit: "10",
                }}
                d="M156.06,238.67c0,0,2.84-2.45,2.67,0.17
  						c-0.17,2.62-0.17,2.62,2.5,2.78c2.67,0.17,2.67,0.17,2.59,1.47c-0.08,1.31,2.67,0.17,2.67,0.17"
              />
            </g>
            <path
              style={{fill: "#8C715E"}}
              d="M138.8,33.06c-0.61-0.04-1.22-0.04-1.83-0.02c-1.26,13.97-14.68,20.49-17.21,21.6
  					c1.65,7.68,8.35,13.65,16.74,14.17c10.08,0.62,18.76-6.87,19.4-16.75C156.53,42.19,148.88,33.69,138.8,33.06z"
            />
            <path
              style={{fill: "#8C715E"}}
              d="M155.9,52.02c-0.51,7.94-6.64,14.32-14.11,16.21l0.06,9.53h-7.04l0.19-9.13
  					c-5.04-0.71-9.36-3.4-12.18-7.2c-0.68,0.42-1.5,0.64-2.36,0.58c-2.2-0.14-3.88-2-3.74-4.15c0.11-1.71,1.33-3.1,2.93-3.53
  					c0-0.02-0.01-0.04-0.01-0.06c0.13-0.06,0.26-0.12,0.39-0.18l35.8-4.87C155.94,50.14,155.96,51.07,155.9,52.02z"
            />
            <path
              style={{fill: "#8C715E"}}
              d="M129.13,45.78l24.57-3.34c1.17,2.09,1.93,4.42,2.16,6.9l-35.44,4.82
  					C124.21,52.45,127.32,49.49,129.13,45.78z"
            />
            <path
              style={{fill: "#5DBDAF"}}
              d="M157.87,99.22l-2.05-19.77c-0.6-6.27-3.76-9.09-10.75-9.1l-3.25,0.15l-3.48,6.28l-3.42-5.96
  					l-6.95,0.32c-5.53,0.4-9.69,5.11-9.29,10.53l1.42,13.65l-0.09-0.01l0.58,12.36l37.81-3.3L157.87,99.22z"
            />
            <ellipse style={{fill: "#0D1E36"}} cx="146.41" cy="47.43" rx="1.46" ry="1.43" />
            <g>
              <g>
                <path
                  style={{fill: "#0D1E36"}}
                  d="M145.76,44.19c0.08-0.07-0.17,0.12-0.07,0.05c0.06-0.04,0.11-0.08,0.17-0.11
  							c0.08-0.05,0.17-0.08,0.25-0.13c-0.19,0.11-0.03,0.02,0.03-0.01c0.06-0.02,0.13-0.04,0.2-0.06c0.07-0.02,0.13-0.03,0.2-0.04
  							c0.14-0.03-0.1,0.01-0.03,0c0.04,0,0.09-0.01,0.13-0.01c0.08,0,0.16-0.01,0.24,0c0.04,0,0.08,0,0.13,0.01
  							c0.06,0,0.15,0.04,0,0c0.18,0.06,0.38,0.08,0.56,0.14c0.05,0.02,0.1,0.04,0.15,0.06c-0.13-0.05,0.02,0.01,0.05,0.03
  							c0.13,0.06,0.25,0.13,0.37,0.2c0.29,0.18,0.72,0.07,0.88-0.23c0.17-0.31,0.08-0.69-0.23-0.88c-0.83-0.51-1.81-0.75-2.76-0.52
  							c-0.43,0.1-0.85,0.3-1.19,0.59c-0.26,0.22-0.24,0.69,0,0.91C145.12,44.44,145.48,44.42,145.76,44.19L145.76,44.19z"
                />
              </g>
            </g>
            <path
              style={{fill: "#8C715E"}}
              d="M136.98,33.04c-9.28,0.32-16.98,7.5-17.57,16.77c-0.11,1.66,0.03,3.28,0.36,4.83
  					C122.29,53.53,135.72,47.01,136.98,33.04z"
            />
            <path
              style={{fill: "#735A4C"}}
              d="M136.94,33.15c0,0,4.69,5.84,14.88,6.47C151.82,39.62,146.45,32.53,136.94,33.15z"
            />
            <ellipse style={{fill: "#0D1E36"}} cx="133.81" cy="48.69" rx="1.46" ry="1.43" />
            <g>
              <g>
                <path
                  style={{fill: "#0D1E36"}}
                  d="M134.24,44.03c-0.4-0.15-0.87-0.19-1.29-0.17c-0.97,0.05-1.87,0.48-2.5,1.22
  							c-0.22,0.26-0.27,0.66,0,0.91c0.24,0.22,0.68,0.27,0.91,0c0.08-0.09,0.16-0.18,0.25-0.26c0.05-0.04,0.1-0.09,0.15-0.13
  							c0.1-0.09-0.1,0.06,0.02-0.01c0.09-0.06,0.17-0.12,0.26-0.17c0.04-0.02,0.09-0.05,0.13-0.07c0.02-0.01,0.04-0.02,0.06-0.03
  							c0.06-0.03,0.05-0.02-0.04,0.02c0.07,0,0.19-0.07,0.26-0.09c0.08-0.02,0.17-0.05,0.25-0.06c0.04-0.01,0.07-0.01,0.11-0.02
  							c0.13-0.02-0.17,0.01-0.01,0c0.08-0.01,0.16-0.01,0.23-0.02c0.14,0,0.27,0,0.41,0.02c0.06,0.01,0.19,0.04-0.04-0.01
  							c0.02,0,0.05,0.01,0.07,0.01c0.05,0.01,0.1,0.02,0.15,0.03c0.1,0.02,0.2,0.05,0.29,0.08c0.32,0.12,0.72-0.13,0.79-0.45
  							C134.78,44.45,134.58,44.15,134.24,44.03L134.24,44.03z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#0D1E36"}}
                  d="M139.89,52.75c0.04-0.07,0.09-0.13,0.13-0.19c-0.06,0.08,0.01-0.01,0.03-0.04
  							c0.03-0.04,0.06-0.07,0.09-0.11c0.07-0.08,0.15-0.15,0.22-0.22c0.02-0.02,0.13-0.1,0.09-0.07c0.05-0.04,0.1-0.07,0.16-0.11
  							c0.05-0.03,0.11-0.07,0.17-0.1c0.03-0.01,0.05-0.03,0.08-0.04c0.04-0.02-0.01,0,0.05-0.02c0.26-0.09,0.49-0.12,0.74-0.08
  							c0.43,0.08,0.81,0.32,1.13,0.61c0.31,0.27,0.77-0.18,0.45-0.45c-0.64-0.56-1.45-0.97-2.32-0.75
  							c-0.53,0.13-1.01,0.49-1.35,0.91c-0.09,0.11-0.17,0.22-0.24,0.33C139.12,52.78,139.68,53.11,139.89,52.75L139.89,52.75z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#8C715E"}}
                  d="M125.09,80.37c-0.49,1.01-0.99,2.03-1.48,3.04c0,0-1.84,3.01-3.55,7.3
  							c-1.21,3.03-0.94,3.45-2.27,6.46c-0.62,1.39-1.11,2.28-2.1,4.06c-2.03,3.66-3.42,5.5-5.47,9.71
  							c-0.01,0.02-0.02,0.04-0.03,0.05c-0.3-1.73-0.6-3.47-0.9-5.2c1.51,2.95,3.36,6.72,5.34,11.17c0.92,2.07,2.37,5.42,2.33,5.44
  							c-0.04,0.02-1.47-3-7.66-16.61c0.22-0.21,1.04-0.99,1.64-0.77c0.66,0.24,0.97,1.67,0.45,3.56c2.64,3.96,6.21,9.99,8.19,16.48
  							c0,0,2.48,8.08,0.06,9.66c-0.6,0.39,1.84-0.59,0,0c-1.19,0.38-1.24,1.65-1.96,1.68c-1.3,0.06-1.63-4.04-4.43-9.5
  							c-1.21-2.36-2.49-4.21-4.26-7.87c-0.24-0.51-0.74-1.55-1.26-2.95c-0.48-1.29-0.96-2.55-1.26-4.2
  							c-0.23-1.26-0.56-3.09-0.14-5.19c0.3-1.48,0.81-2.29,1.68-3.92c1.44-2.71,0.62-1.6,3.36-7.29c2.22-4.6,3.33-6.9,4.07-7.98
  							c1.95-2.88,4.17-5.13,5.15-7.15c0.6-1.24,1.19-2.49,1.81-3.71c0.01-0.02,0.02-0.03,0.03-0.05c0.69-1.42,1.97-1.93,2.67-0.85
  							C125.75,76.83,125.84,78.85,125.09,80.37z"
                />
              </g>
            </g>
            <path
              style={{fill: "#5DBDAF"}}
              d="M121.69,91.05c1.36-3.13,2.71-6.25,4.07-9.38c0.66-1.52,1.36-3.03,1.98-4.57
  					c0.01-0.02,0.02-0.04,0.03-0.07c0.77-1.78-0.19-4.16-2.09-4.82c-1.96-0.68-4.09,0.14-4.92,2.05c-0.54,1.24-1.08,2.49-1.62,3.73
  					c-1.25,2.88-2.5,5.76-3.75,8.64l6.17,4.7C121.61,91.24,121.65,91.15,121.69,91.05z"
            />
            <path
              style={{fill: "#123252"}}
              d="M123.86,62.52c-0.15-0.17-0.3-0.34-0.44-0.52C123.56,62.18,123.71,62.35,123.86,62.52z"
            />
            <path
              style={{fill: "#123252"}}
              d="M123.99,62.67c-0.04-0.05-0.09-0.1-0.13-0.15C123.9,62.57,123.94,62.62,123.99,62.67z"
            />
            <path
              style={{fill: "#123252"}}
              d="M123.41,62.01c-0.04-0.05-0.08-0.11-0.13-0.16C123.33,61.9,123.37,61.95,123.41,62.01z"
            />
            <path
              style={{fill: "#FFFFFF"}}
              d="M134.74,59.74c0.11-0.16,0.35-0.18,0.5-0.06c0.37,0.29,1.03,0.89,2.02,1.15
  					c1.34,0.35,2.56-0.11,2.82,0.25c0.15,0.2-0.06,0.43-0.45,0.71c-0.39,0.28-1.56,0.8-2.79,0.5c-1.29-0.32-2.18-1.87-2.15-2.39
  					C134.69,59.83,134.71,59.78,134.74,59.74z"
            />

            <line
              style={{
                fill: "none",
                stroke: "#123252",
                strokeWidth: "2.0668",
                strokeMiterlimit: "10",
              }}
              x1="125.27"
              y1="142.26"
              x2="130.92"
              y2="156.3"
            />
            <path
              style={{fill: "#8C715E"}}
              d="M125.8,141.25c0,0-0.59-0.37-0.77-0.11c-0.17,0.26-0.1,1.25,0.92,1.87
  					c0.67,0.41,3.08,2.59,2.22,5.19c0,0-1.68,2.76-4.73,1.51c-2.29-0.94-3.9-2.16-5.08-5.44c-1.1-3.07-0.65-5.01-0.77-6.08
  					c-1.27-3.28-2.54-6.57-3.82-9.85c-0.19-0.48-0.37-0.96-0.56-1.43l6.34-1.85c1.3,3.35,2.6,6.7,3.9,10.06
  					c0.13,0.35,0.27,0.69,0.4,1.04c1.03,0.34,3.4,1.26,4.61,2.73c0,0,0.68,0.62,0.86,1.77S127.26,142.25,125.8,141.25z"
            />

            <line
              style={{
                fill: "none",
                stroke: "#123252",
                strokeWidth: "2.0668",
                strokeMiterlimit: "10",
              }}
              x1="117.65"
              y1="154.93"
              x2="125.63"
              y2="141.78"
            />
            <g>
              <polygon
                style={{fill: "#123252"}}
                points="139.35,155.56 109.19,151.13 100.64,189.53 133.31,194.46 					"
              />
              <polygon
                style={{fill: "#0A273F"}}
                points="142.86,190.52 133.31,194.46 139.35,155.56 					"
              />
            </g>

            <line
              style={{
                fill: "none",
                stroke: "#123252",
                strokeWidth: "2.0668",
                strokeMiterlimit: "10",
              }}
              x1="159.45"
              y1="108.78"
              x2="160.96"
              y2="96.96"
            />
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M154.15,76.34c0.25,0.77,0.49,1.55,0.74,2.32c0.59,1.86,1.18,3.71,1.77,5.57
  						c0.71,2.25,1.43,4.49,2.14,6.74c0.62,1.95,1.24,3.9,1.86,5.86c0.3,0.94,0.57,1.9,0.9,2.83c0,0.01,0.01,0.03,0.01,0.04l2.27-2.9
  						c0.03-0.03,0-0.08-0.04-0.08c-1.62-0.75-3.23-1.51-4.85-2.26c-7.69-0.49-12.54-0.64-12.59-0.22c-0.03,0.31,2.59,1.06,2.54,1.21
  						c-0.02,0.07-0.51-0.11-1.1,0.13c-0.14,0.06-0.29,0.14-0.42,0.25c-0.42,0.32-0.79,0.89-0.87,1.44
  						c-0.16,1.11,0.45,2.47,1.57,2.56c1.54,0.13,2.17,0.34,5.08,0.43c1.47,0.05,3.19,0.29,6.62,0.78c1.08,0.15,2.19-0.02,3.29,0.06
  						c0.22,0.02,0.76,0.06,1.29-0.23c0.34-0.18,0.52-0.41,0.69-0.61c0.32-0.38,0.84-0.88,1.64-1.41c0-0.93,0-1.87,0-2.8
  						c-0.47-1.5-1.15-3.48-2.1-5.75c-0.54-1.29-1.14-2.7-2.1-4.48c-2.07-3.85-4.13-6.29-4.62-6.87c-0.88-1.02-1.67-1.83-2.24-2.38
  						C155.14,76.49,154.64,76.41,154.15,76.34c0.66-2.01,0.79-2.51,0.75-2.53C154.83,73.79,153.74,75.02,154.15,76.34z"
              />
            </g>
            <path
              style={{fill: "#609E92"}}
              d="M156.58,87.27c-1.97-2.5-3.94-5-5.92-7.51c-0.96-1.22-1.95-2.42-2.88-3.66
  					c-0.01-0.02-0.03-0.04-0.04-0.05c-1.13-1.43-0.83-3.79,0.72-4.82c1.6-1.06,3.72-0.81,4.92,0.71c0.78,0.99,1.57,1.99,2.35,2.98
  					c1.82,2.3,3.63,4.61,5.45,6.91l-4.42,5.66C156.7,87.43,156.64,87.35,156.58,87.27z"
            />
            <g>
              <polygon
                style={{fill: "#123252"}}
                points="174.03,103.86 154.93,109.77 156.94,150.14 179.06,141.28 					"
              />
            </g>
            <polyline
              style={{fill: "#0A273F"}}
              points="154.93,109.77 148.39,150.14 156.94,150.14 				"
            />
            <path
              style={{fill: "#F5F6F5"}}
              d="M141.71,82.25l-8.11,0.02c-0.81,0-1.35,0.53-1.35,1.33l0.05,18.54c0,0.79,0.54,1.32,1.36,1.32
  					l8.11-0.02c0.81,0,1.35-0.53,1.35-1.33l-0.05-18.54C143.07,82.78,142.52,82.25,141.71,82.25z"
            />
            <path
              style={{fill: "#8C715E"}}
              d="M141.83,92.47c0,0,0.51,0.48,0.29,0.7c-0.22,0.23-1.21,0.39-2.07-0.43
  					c-0.56-0.54-3.28-2.32-5.65-0.89c0,0-2.33,2.24-0.39,4.84c1.46,1.95,3.05,3.19,6.57,3.54c3.29,0.32,5.11-0.56,6.19-0.69
  					c3.55,0.44,7.09,0.87,10.64,1.31c0.52,0.06,1.03,0.13,1.55,0.19l0.34-6.45c-3.62-0.45-7.24-0.89-10.86-1.34
  					c-0.37-0.05-0.75-0.09-1.12-0.14c-0.58-0.9-2.04-2.93-3.79-3.73c0,0-0.78-0.5-1.95-0.4C140.41,89.08,140.49,91.31,141.83,92.47z
  					"
            />
            <path
              style={{fill: "#F5F6F5"}}
              d="M142.86,87.01l0.1,6.37c0,0-2.2,0.8-3.01-1.6C139.34,89.96,142.86,87.01,142.86,87.01z"
            />
            <g>
              <path
                style={{fill: "#123252"}}
                d="M121.25,54.62c0,0,2.45-2.1,2.45-5.98c0,0,11.12-4.91,13.49-12.16c0,0,6.25,5.78,16.54,5.85
  						c-3.09-5.63-9.17-9.46-16.15-9.46c-10.12,0-18.33,8.04-18.33,17.96c0,1.05,0.1,2.08,0.27,3.08l0.16,0.43L121.25,54.62z"
              />
            </g>
          </g>
          <path
            style={{fill: "none", stroke: "#123252", strokeWidth: "2.0668", strokeMiterlimit: "10"}}
            d="M160.96,94.01
  				c2.17,4.52,4.51,9.12,7.04,13.79c0.82,1.52,1.65,3.02,2.48,4.5"
          />
        </g>
      </g>
      <path
        style={{fill: "#EB6669"}}
        d="M140.6,33.05c-3.29,0.86-6.91,2.28-10.54,4.23c-3.63,1.95-6.82,4.17-9.36,6.43
  		c-0.13-3.81,2.62-8.04,7.19-10.49C132.45,30.77,137.5,30.84,140.6,33.05z"
      />
    </g>
  </SuperheroSvg>
)

export default Character10
