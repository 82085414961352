// This file was generated automatically from character-17.svg with the following command:
// ./svg-converter.js src/components/superhero/character-17.svg
import SuperheroSvg from "./superhero-svg"

const Character17 = props => (
  <SuperheroSvg viewBox="220 70 120 210" {...props}>
    <g id="Layer_16">
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M245.76,274.96l-0.05-0.01c-0.09-0.03-2.19-0.68-2.36-2.43c-0.13-1.32,0.83-2.75,2.92-4.35
  				l0.06-0.04c0.02-0.01,2.45-1.27,4.92-3.09c3.2-2.35,5.03-4.56,5.3-6.37l0.78,0.1c-0.73,4.91-9.96,9.81-10.69,10.19
  				c-2.18,1.68-2.57,2.78-2.51,3.4c0.09,0.94,1.27,1.52,1.78,1.69c13.09,0.11,23.39-4.45,24.54-4.98c1.22-2.02-1.31-7.54-2.4-9.53
  				l0.67-0.54c0.44,0.8,4.25,7.9,2.27,10.73l-0.07,0.1l-0.1,0.05c-0.11,0.05-10.99,5.23-25.01,5.1L245.76,274.96z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M249.92,274.69l-0.6-0.56c0.75-1.07,1.07-2.13,0.95-3.14c-0.23-1.91-1.93-3.12-1.95-3.13l0.44-0.8
  				c0.08,0.06,2.02,1.42,2.29,3.75C251.22,272.09,250.83,273.4,249.92,274.69z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M250.73,271.79l-0.06-0.92c16.52-2.65,19.98-5.56,20.01-5.59l0.46,0.72
  				C271.01,266.13,267.62,269.08,250.73,271.79z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M250.4,270.56l-0.28-0.84c11.34-5.84,10.95-10.99,10.95-11.04l0.78-0.18
  				C261.89,258.73,262.44,264.37,250.4,270.56z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M251.66,267.97c-0.38-0.07-0.72-0.2-0.89-0.27l0.3-0.88c0.59,0.24,1.28,0.35,1.45,0.23
  				c0-0.01,0-0.02,0-0.04c-0.04-0.62-0.07-1.15,0.38-1.5c0.32-0.25,0.82-0.32,1.91-0.49c0.77-0.12,1.33-0.21,1.46-0.38
  				c0.12-0.18,0.08-0.83,0.03-1.73c-0.05-0.75,0.12-1.26,0.49-1.51c0.73-0.48,1.83,0.46,2.15,0.76l-0.52,0.74
  				c-0.53-0.49-1.15-0.8-1.28-0.7c0,0-0.09,0.11-0.06,0.58c0.08,1.29,0.12,1.93-0.22,2.41c-0.34,0.48-0.89,0.57-2,0.74
  				c-0.78,0.12-1.46,0.23-1.57,0.35c-0.02,0.06,0,0.35,0.01,0.6c0.02,0.36-0.09,0.67-0.31,0.87
  				C252.67,268.06,252.13,268.06,251.66,267.97z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M279.56,275.71l-0.05-0.01c-0.09-0.03-2.2-0.65-2.39-2.41c-0.14-1.32,0.79-2.76,2.87-4.39
  				l0.06-0.04c0.02-0.01,2.43-1.3,4.88-3.15c3.17-2.39,4.97-4.62,5.22-6.44l0.78,0.09c-0.67,4.91-9.84,9.93-10.56,10.32
  				c-2.16,1.71-2.54,2.81-2.47,3.43c0.1,0.94,1.29,1.51,1.8,1.66c13.09-0.04,23.34-4.73,24.48-5.27c1.19-2.04-1.4-7.53-2.52-9.5
  				l0.67-0.54c0.45,0.8,4.34,7.85,2.39,10.7l-0.07,0.1l-0.1,0.05c-0.11,0.05-10.93,5.36-24.95,5.4L279.56,275.71z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M283.73,275.39l-0.6-0.55c0.74-1.08,1.05-2.14,0.92-3.15c-0.25-1.91-1.97-3.1-1.98-3.11l0.43-0.81
  				c0.08,0.06,2.03,1.4,2.34,3.72C284.99,272.77,284.62,274.09,283.73,275.39z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M284.5,272.48l-0.07-0.92c16.49-2.85,19.91-5.8,19.94-5.83l0.47,0.71
  				C304.71,266.58,301.36,269.57,284.5,272.48z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M284.16,271.26l-0.29-0.84c11.27-5.97,10.82-11.12,10.82-11.17l0.78-0.19
  				C295.5,259.29,296.12,264.92,284.16,271.26z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M285.38,268.65c-0.38-0.07-0.72-0.19-0.89-0.26l0.29-0.88c0.59,0.23,1.28,0.33,1.45,0.21
  				c0-0.01,0-0.02,0-0.04c-0.05-0.62-0.08-1.15,0.36-1.5c0.32-0.25,0.81-0.33,1.9-0.52c0.77-0.13,1.33-0.22,1.45-0.4
  				c0.12-0.18,0.07-0.83,0.01-1.73c-0.06-0.75,0.1-1.26,0.48-1.51c0.73-0.49,1.84,0.44,2.16,0.74l-0.51,0.74
  				c-0.53-0.49-1.16-0.79-1.29-0.69c0,0-0.09,0.11-0.05,0.58c0.09,1.28,0.14,1.93-0.19,2.41c-0.33,0.49-0.89,0.58-1.99,0.76
  				c-0.78,0.13-1.45,0.25-1.57,0.37c-0.02,0.06,0,0.35,0.02,0.6c0.03,0.36-0.08,0.67-0.3,0.88
  				C286.39,268.73,285.86,268.74,285.38,268.65z"
          />
        </g>
      </g>
      <polygon
        style={{fill: "#0A273F"}}
        points="276.05,179.55 272.73,259.06 252.51,259.06 259.88,149.67 280.9,145.07 	"
      />
      <path
        style={{fill: "#123252"}}
        d="M306.03,260.18c-1.77-12.42-3.55-24.84-5.32-37.26c-2.45-25.26-4.91-50.51-7.36-75.77
  		c-5.39,21.96-10.78,43.92-16.17,65.87c1.7,15.85,3.41,31.71,5.11,47.56"
      />
      <path
        style={{fill: "#123252"}}
        d="M289.47,144.85l-29.65,6.42l-0.88,3.82c-0.19,0.8-0.32,1.61-0.41,2.45l-0.22,1.32l0.14,0.02
  		c0,0.17-0.01,0.34-0.01,0.51c0,9.16,6.53,16.78,15.19,18.49l0.13,0.36c0.43,4.43,0.87,8.86,1.3,13.3
  		c1.14,11.62,2.27,23.23,3.41,34.85c0.26,2.62,0.51,5.23,0.77,7.85c4.89-27.11,9.77-54.21,14.66-81.32"
      />
      <g>
        <defs>
          <rect
            id="SVGID_00000082369714483791335000000013362037265760972465_"
            x="132.66"
            y="51.63"
            width="193.91"
            height="161"
          />
        </defs>
        <clipPath id="0bcec421-SVGID_00000070816131153659859320000004590225004116350117_">
          <use
            xlinkHref="#SVGID_00000082369714483791335000000013362037265760972465_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#DABCAB",
          }}
          d="M283.6,70.67
  			c0.58,0,1.15,0.03,1.71,0.08c0.34,13.4,12.49,20.41,14.79,21.62c-2.01,7.23-8.63,12.53-16.49,12.53
  			c-9.45,0-17.12-7.66-17.12-17.12C266.48,78.33,274.15,70.67,283.6,70.67z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#DABCAB",
          }}
          d="M266.48,87.74
  			c0,7.61,5.34,14.05,12.21,16.29l0,5.12l6.31,0l0-4.33c4.75-0.38,8.95-2.69,11.82-6.15c0.61,0.44,1.36,0.7,2.17,0.7
  			c2.07,0,3.74-1.68,3.74-3.74c0-1.64-1.06-3.03-2.53-3.54c0-0.02,0.01-0.04,0.01-0.06c-0.12-0.06-0.23-0.13-0.35-0.19l-33.16-6.75
  			C266.56,85.95,266.48,86.83,266.48,87.74z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#DABCAB",
          }}
          d="M292.52,83.37
  			l-22.76-4.63c-1.22,1.92-2.07,4.11-2.43,6.45l32.82,6.68C296.72,90.02,294,87.02,292.52,83.37z"
        />

        <circle
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#0D1E36",
          }}
          cx="276.28"
          cy="83.92"
          r="1.36"
        />
        <g style={{clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)"}}>
          <g>
            <path
              style={{fill: "#F5F6F6"}}
              d="M280.08,78.65c-0.33-0.47-0.75-0.86-1.27-1.12c-0.54-0.27-1.04-0.4-1.64-0.45
  					c-0.56-0.05-1.15,0.06-1.68,0.24c-0.37,0.13-0.69,0.34-0.9,0.69c-0.19,0.32-0.27,0.8-0.15,1.16c0.12,0.36,0.34,0.71,0.69,0.9
  					c0.34,0.18,0.77,0.28,1.16,0.15c0.23-0.08,0.47-0.14,0.71-0.17c-0.13,0.02-0.27,0.04-0.4,0.05c0.24-0.03,0.48-0.03,0.71,0
  					c-0.13-0.02-0.27-0.04-0.4-0.05c0.21,0.03,0.41,0.08,0.6,0.16c-0.12-0.05-0.24-0.1-0.36-0.15c0.18,0.08,0.35,0.18,0.51,0.3
  					c-0.1-0.08-0.2-0.16-0.3-0.23c0.13,0.1,0.25,0.21,0.35,0.34c-0.08-0.1-0.16-0.2-0.23-0.3c0,0.01,0.01,0.01,0.01,0.02
  					c0.23,0.33,0.5,0.58,0.9,0.69c0.36,0.1,0.84,0.05,1.16-0.15c0.32-0.21,0.6-0.51,0.69-0.9
  					C280.32,79.43,280.31,78.97,280.08,78.65L280.08,78.65z"
            />
          </g>
        </g>
        <g style={{clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)"}}>
          <polyline style={{fill: "#DABCAB"}} points="281.58,83.77 279.46,88.8 281.76,89.21 			" />
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M281.22,83.67c-0.62,1.46-1.23,2.93-1.85,4.39c-0.09,0.21-0.18,0.42-0.27,0.63
  					c-0.07,0.17,0.08,0.42,0.26,0.45c0.76,0.14,1.53,0.28,2.29,0.42c0.46,0.08,0.66-0.63,0.2-0.71c-0.76-0.14-1.53-0.28-2.29-0.42
  					c0.09,0.15,0.17,0.3,0.26,0.45c0.62-1.46,1.23-2.93,1.85-4.39c0.09-0.21,0.18-0.42,0.27-0.63c0.08-0.19-0.08-0.4-0.26-0.45
  					C281.46,83.35,281.3,83.49,281.22,83.67L281.22,83.67z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#DABCAB",
          }}
          d="M285.17,70.75
  			c8.65,0.86,15.41,8.15,15.41,17.03c0,1.59-0.22,3.13-0.63,4.59C297.66,91.16,285.5,84.15,285.17,70.75z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#DABCAB",
          }}
          d="M285.98,70.86
  			c0,0-4.73,5.3-14.29,5.3C271.69,76.16,277.13,69.71,285.98,70.86z"
        />

        <circle
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#0D1E36",
          }}
          cx="287.98"
          cy="85.87"
          r="1.36"
        />
        <g style={{clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)"}}>
          <g>
            <path
              style={{fill: "#F5F6F6"}}
              d="M288.04,82.49c0.12-0.04,0.26-0.06,0.38-0.08c-0.13,0.02-0.27,0.04-0.4,0.05
  					c0.26-0.03,0.52-0.03,0.77,0c-0.13-0.02-0.27-0.04-0.4-0.05c0.23,0.03,0.45,0.09,0.66,0.18c-0.12-0.05-0.24-0.1-0.36-0.15
  					c0.21,0.09,0.4,0.2,0.58,0.34c-0.1-0.08-0.2-0.16-0.3-0.23c0.18,0.15,0.35,0.31,0.49,0.5c-0.08-0.1-0.16-0.2-0.23-0.3
  					c0.01,0.01,0.02,0.03,0.03,0.04c0.09,0.17,0.22,0.32,0.39,0.42c0.14,0.13,0.31,0.22,0.51,0.27c0.36,0.1,0.84,0.05,1.16-0.15
  					c0.32-0.21,0.6-0.51,0.69-0.9c0.08-0.37,0.09-0.84-0.15-1.16c-0.41-0.54-0.86-1.03-1.48-1.34c-0.55-0.28-1.06-0.41-1.67-0.47
  					c-0.38-0.04-0.76,0.02-1.14,0.07c-0.11,0.02-0.22,0.04-0.32,0.07c-0.37,0.12-0.7,0.35-0.9,0.69c-0.19,0.32-0.27,0.8-0.15,1.16
  					c0.12,0.36,0.34,0.71,0.69,0.9C287.23,82.52,287.65,82.62,288.04,82.49L288.04,82.49z"
            />
          </g>
        </g>
        <g style={{clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)"}}>
          <path
            style={{fill: "#3499CC"}}
            d="M296.9,119c0.73-5.35-3.01-10.28-8.37-11.01L276,106.96c-6.79-0.41-10.04,2.19-11.01,8.37
  				l-3.23,19.48l32.53,0L296.9,119z"
          />
          <polygon
            style={{fill: "#3499CC"}}
            points="294.93,134.81 261.75,134.81 260.1,146.25 293.94,147.88 			"
          />
        </g>

        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "none",
            stroke: "#C6AB9D",
            strokeMiterlimit: "10",
          }}
          d="
  			M288.11,89.3c0,0,1.98,0.79,3.17-0.79"
        />

        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "none",
            stroke: "#C6AB9D",
            strokeMiterlimit: "10",
          }}
          d="
  			M271.76,85.6c0,0,0.71,2.01,2.69,1.87"
        />

        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "none",
            stroke: "#C6AB9D",
            strokeMiterlimit: "10",
          }}
          d="
  			M280.61,75.98c0,0,4.92-1.15,7.69,2.41"
        />

        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "none",
            stroke: "#C6AB9D",
            strokeMiterlimit: "10",
          }}
          d="
  			M281.4,74.79c0,0,4.52-1.15,7.69,2.41"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#F5F6F6",
          }}
          d="M274.44,94.35
  			l0.16-1.58c0.11-1.1,1.1-1.91,2.2-1.79l8.09,0.83c1.1,0.11,1.91,1.1,1.79,2.2l-0.16,1.58L274.44,94.35z"
        />
        <g style={{clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)"}}>
          <defs>
            <path
              id="SVGID_00000044861076149486540050000007857012732385099673_"
              d="M294.66,134.82l-0.99,13.07l-33.84-1.63l1.66-11.44
  					l3.23-19.48c0.97-6.18,4.22-8.78,11.01-8.37l12.54,1.02c5.35,0.73,9.1,5.66,8.37,11.01l-2.62,15.81"
            />
          </defs>
          <clipPath id="0bcec421-SVGID_00000049900360004045824470000001856144424001996192_">
            <use
              xlinkHref="#SVGID_00000044861076149486540050000007857012732385099673_"
              style={{overflow: "visible"}}
            />
          </clipPath>

          <line
            style={{
              clipPath: "url(#SVGID_00000049900360004045824470000001856144424001996192_)",
              fill: "none",
              stroke: "#EB6669",
              strokeWidth: "5",
              strokeMiterlimit: "10",
            }}
            x1="267.09"
            y1="147.99"
            x2="273.83"
            y2="105.56"
          />

          <line
            style={{
              clipPath: "url(#SVGID_00000049900360004045824470000001856144424001996192_)",
              fill: "none",
              stroke: "#EB6669",
              strokeWidth: "5",
              strokeMiterlimit: "10",
            }}
            x1="283.49"
            y1="148.79"
            x2="289.44"
            y2="104.77"
          />
        </g>

        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "none",
            stroke: "#5DBDAF",
            strokeWidth: "6",
            strokeMiterlimit: "10",
          }}
          d="
  			M260.01,147.21c11.31,0.53,22.62,1.05,33.93,1.58"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#DABCAB",
          }}
          d="M292.73,135.19
  			c0,0,0.7,0.16,0.63,0.48c-0.07,0.32-0.85,0.99-2.04,0.7c-0.78-0.19-4.12-0.34-5.43,2.18c0,0-0.84,3.24,2.27,4.54
  			c2.33,0.97,4.39,1.25,7.67-0.3c3.06-1.45,4.18-3.19,5.05-3.89c3.34-1.48,6.68-2.96,10.03-4.44c0.49-0.22,0.97-0.43,1.46-0.65
  			l-2.8-5.14c-3.41,1.51-6.68,2.72-10.09,4.23c-0.35,0.16-0.65,0.33-1.58-0.04c-0.99-0.5-3.36-1.54-5.33-1.34
  			c0,0-0.95-0.03-1.93,0.67S290.94,134.87,292.73,135.19z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#3499CC",
          }}
          d="M293.95,135.56
  			l0.99-5.17c-1.15-0.35-2.82,0.95-4.12,1.19c0,0,1.02-0.61,0.03,0.19c-0.99,0.79-1.67,3.32,0.15,3.51c0,0,0.71,0.11,0.64,0.44
  			l-1.3,0.51c0,0,2.18,0.63,3.27-0.33"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)",
            fill: "#DABCAB",
          }}
          d="M263.88,132.88
  			c0,0-0.7,0.15-0.64,0.47c0.06,0.32,0.83,1,2.03,0.74c0.79-0.17,4.13-0.26,5.39,2.28c0,0,0.78,3.25-2.35,4.49
  			c-2.35,0.93-4.41,1.17-7.66-0.44c-3.03-1.5-4.12-3.27-4.98-3.98c-3.31-1.54-6.63-3.08-9.94-4.62c-0.48-0.22-0.96-0.45-1.45-0.67
  			l2.89-5.09c3.38,1.57,6.62,2.84,10.01,4.42c0.35,0.16,0.64,0.34,1.58-0.01c1-0.48,3.39-1.48,5.35-1.24c0,0,0.95-0.02,1.92,0.71
  			S265.68,132.59,263.88,132.88z"
        />
        <g style={{clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)"}}>
          <path
            style={{fill: "#3499CC"}}
            d="M256.16,136.71c-3.6-1.52-7.21-3.03-10.81-4.55c-0.52-0.22-1.04-0.44-1.55-0.65
  				c-0.52-0.22-1.01-0.42-1.42-0.83c-0.5-0.5-0.79-1.15-0.88-1.85c-0.18-1.31,0.44-2.49,1.54-3.21c2.6-1.7,5.19-3.39,7.79-5.09
  				c4.11-2.69,8.21-5.37,12.32-8.06c0.94-0.62,5.14-3.15,6.09-3.76c0.29-0.19,0.48-0.23,0.6-0.17c0.76,0.4,0.56,3.11,0.54,3.24
  				c-0.07,0.76-0.22,1.34-0.42,2.09c0,0-0.32,1.25-0.74,2.32c-0.79,2.04-5.32,3.48-7.79,5.09c-4.11,2.69-8.21,5.37-12.32,8.06
  				c-0.94,0.62-1.88,1.23-2.83,1.85c-0.26-1.96-0.51-3.92-0.77-5.88c3.6,1.52,7.21,3.03,10.81,4.55c0,0,1.55,0.65,1.55,0.65"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000070816131153659859320000004590225004116350117_)"}}>
          <path
            style={{fill: "#3499CC"}}
            d="M305.12,137.15c3.6-1.52,7.21-3.03,10.81-4.55c0.52-0.22,1.04-0.44,1.55-0.65
  				c0.52-0.22,1.01-0.42,1.42-0.83c0.5-0.5,0.79-1.15,0.88-1.85c0.18-1.31-0.44-2.49-1.54-3.21c-2.6-1.7-5.19-3.39-7.79-5.09
  				c-4.11-2.69-8.21-5.37-12.32-8.06c-0.94-0.62-5.14-3.15-6.09-3.76c-0.09-0.06-0.35-0.25-0.6-0.17c-0.73,0.23-0.58,2.59-0.54,3.24
  				c0.03,0.51,0.13,1.44,0.46,2.59c0,0,0.28,0.94,0.69,1.81c1,2.1,5.9,3.86,7.79,5.09c4.11,2.69,8.21,5.37,12.32,8.06
  				c0.94,0.62,1.88,1.23,2.83,1.85c0.26-1.96,0.51-3.92,0.77-5.88c-3.6,1.52-7.21,3.03-10.81,4.55c0,0-1.55,0.65-1.55,0.65"
          />
        </g>
      </g>
      <path
        style={{fill: "#3499CC"}}
        d="M262.21,132.8l0.73-5.39c1.14,0.82,1.72,1.21,1.73,1.19c0,0-0.02-0.03-0.08-0.08l0,0l0,0
  		c0.12-0.09,1.06-0.78,1.81-0.47c0.83,0.35,1.34,1.89,0.55,3.55c-0.64,0.72-1.28,1.44-1.93,2.16c0,0-0.69-0.19-0.73,0.15l0.73-0.15
  		c-1.03,0.63-2.24,0.26-2.61-0.49c-0.26-0.52-0.11-1.23,0.34-1.78"
      />
    </g>
  </SuperheroSvg>
)

export default Character17
