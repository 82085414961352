import {CompositeDecorator} from "draft-js"

import {Link, Subscript, Superscript} from "./decorator-entities"
import PersonalizationEntity, {PERSONALIZATION_ENTITY} from "./personalization-entity"

const createTypeStrategy = type => (contentBlock, callback, contentState) =>
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()

    return entityKey !== null && contentState.getEntity(entityKey).getType() === type
  }, callback)

export const contentBlockEditorDecorators = new CompositeDecorator([
  {
    strategy: createTypeStrategy("LINK"),
    component: Link,
  },
  {
    strategy: createTypeStrategy(PERSONALIZATION_ENTITY),
    component: PersonalizationEntity,
  },
  {
    strategy: createTypeStrategy("SUPERSCRIPT"),
    component: Superscript,
  },
  {
    strategy: createTypeStrategy("SUBSCRIPT"),
    component: Subscript,
  },
])

export default () => contentBlockEditorDecorators
