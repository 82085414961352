import {Tooltip, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {useEffect} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"
import StatsPie from "components/template-insights/stats-pie"

import {fetchAdvancedInsightsCampaignEngagement} from "lib/api"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const CampaignEngagement = () => {
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    campaignEngagement,
    campaignEngagementIsLoading,
  } = useAdvancedTeamInsights()

  const stats = campaignEngagement?.stats ?? []
  const totalContacts = campaignEngagement?.totalContacts ?? 0

  useEffect(() => {
    if (hasSocket)
      fetchInsights("campaignEngagement", fetchAdvancedInsightsCampaignEngagement, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  return (
    <>
      <Tooltip
        placement="left-start"
        title={
          <div>
            Engagement is when a contact performs at least one of the following campaign
            interactions in a journey:
            <ul>
              <li>Opens an email message</li>
              <li>Clicks on an email or SMS message's CTA link</li>
              <li>Views a page</li>
              <li>Interacts with a widget</li>
              <li>Interacts with a microsite page</li>
              <li>Completes a campaign objective</li>
            </ul>
          </div>
        }
      >
        {/*NB: We are setting the `max-width` to 250px, so that the tooltip will flip to the right-hand side of the title, rather than the right-hand side of the end of the page*/}
        <Typography variant="h5" style={{maxWidth: 250}}>
          Active Campaign Engagement
        </Typography>
      </Tooltip>
      {campaignEngagementIsLoading ? (
        <Skeleton variant="circle" width={150} height={150} style={{marginTop: 50}} />
      ) : totalContacts > 0 ? (
        <StatsPie
          colors={colors}
          data={stats}
          legendTitle="Engagement status of contacts enrolled in at least one campaign"
          total={totalContacts}
          totalLabel="Contacts"
          totalLabelTooltip="Number of contacts with an in-progress journey"
        />
      ) : (
        <div>No data available</div>
      )}
    </>
  )
}

export default CampaignEngagement
