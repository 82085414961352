import {omit, pick} from "lodash"
import {func, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import {fetchContactActionCtas, fetchContactActions} from "lib/api"
import flattenObj from "lib/flatten-obj"

import AnalyticsFilter from "../analytics-filter/analytics-filter"
import AnalyticsRow from "../analytics-row/analytics-row"
import DOTable from "../table/table"
import {tabular} from "../table/table-state"

const exportTransform = record =>
  flattenObj({
    ...record,
    contact: pick(record.contact, ["email", "id", "name_first", "name_last", "unique_id"]),
    content_block: record.content_block ? pick(record.content_block, ["data.name", "type"]) : null,
    journey: record.journey ? pick(record.journey, ["id"]) : null,
    objective: record.objective ? pick(record.objective, ["id", "key", "name"]) : null,
    page: record.page ? pick(record.page, ["id", "navigationTitle"]) : null,
    meta: record.meta ? omit(record.meta, ["payload"]) : null,
    message: record.message ? omit(record.message, ["payload"]) : null,
  })

const ActionsTable = props => {
  const fetchRecords = fetchContactActions
  const {setTableState, updateStateForRequest, contact} = props
  const [ctas, setCtas] = useState([])
  const [isTableLoading, setTableLoading] = useState(true)

  const refresh = attrs => {
    setTableLoading(true)

    const params = updateStateForRequest(attrs)

    fetchRecords({contactId: contact.id, ...params}, {withFetchResponse: true})
      .then(([rowsWithoutKey, fetchResponse]) => {
        const rows = rowsWithoutKey.map(row => ({key: row.id, contact, ...row}))

        setTableState({rows, fetchResponse, ...attrs})
      })
      .finally(() => {
        setTableLoading(false)
      })
  }

  const filter = filters => {
    refresh({page: 0, filters})
  }

  useEffect(() => {
    contact.id && refresh({})

    contact.id && fetchContactActionCtas(contact.id).then(ctas => setCtas(ctas))

    // While this effect uses `refresh` and `contact, it only needs to subscribe to
    // changes in contact.id.
    // eslint-disable-next-line
  }, [contact.id])

  return (
    <DOTable
      allowColumnCustomization={true}
      exporterProps={{
        params: {...props.filters, contactId: contact?.id},
        fetchRecords,
        filename: "contact_actions_report",
        title: "Export Results",
        transform: exportTransform,
      }}
      filterComponent={<AnalyticsFilter ctas={ctas} id={"contacts-analytics"} onSubmit={filter} />}
      headers={[
        {field: "name", isDefault: true},
        {field: "timestamp", label: "Created", isDefault: true},
        {field: "location", sortable: false, isDefault: true},
        {field: "journey", sortable: false, isDefault: true},
      ]}
      isTableLoading={isTableLoading}
      noResults={
        Object.keys(props?.filters ?? {}).filter(key => key !== "templateId").length > 0
          ? "No analytics matched your selected filters"
          : "No analytics have been gathered yet"
      }
      refresh={refresh}
      storageName="contact-action-analytics"
    >
      {(row, index, columns) => <AnalyticsRow columns={columns} key={row.id} row={row} />}
    </DOTable>
  )
}

ActionsTable.propTypes = {
  contact: shape({id: string.isRequired}),
  filters: object,
  setTableState: func.isRequired,
  updateStateForRequest: func.isRequired,
}

export default tabular({
  itemsPerPage: 10,
  namespace: "at",
  sortColumn: "timestamp",
  sortDirection: "desc",
  useQueryParams: true,
})(ActionsTable)
