import {
  Divider,
  IconButton,
  ListSubheader,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import debounce from "lodash/debounce"
import {arrayOf, bool, func, shape, string} from "prop-types"
import {useEffect, useRef, useState} from "react"
import {MdDelete as DeleteIcon} from "react-icons/md"

import DOSelect from "../../do-select/do-select"
import {
  deleteMapping,
  updateCsvField,
  updateDoField,
  updateMetaSubKey,
} from "./csv-processing-helpers"

const useStyles = makeStyles(theme => ({
  listSubheader: {
    backgroundColor: theme.palette.grey[100],
  },
  metaSubKeyField: {
    marginLeft: theme.spacing(2),
  },
  doFieldWrapper: {
    display: "flex",
    alignItems: "baseline",
  },
  select: {
    minWidth: 200,
  },
  cell: {
    verticalAlign: "top",
  },
  buttonCell: {
    padding: theme.spacing(),
  },
}))

const FieldMapRow = ({
  disabled,
  onChange,
  id,
  csv_field,
  digital_onboarding_field,
  meta_sub_key,
  fields,
  csvFieldTaken,
  objectives,
}) => {
  const debouncedOnChange = useRef(debounce(onChange, 100)).current

  const [_csvField, setCsvField] = useState(csv_field)
  const [_digitalOnboardingField, setDigitalOnboardingField] = useState(digital_onboarding_field)
  const [_metaSubKey, setMetaSubKey] = useState(meta_sub_key)

  const onDelete = () => {
    onChange(deleteMapping({id}))
  }

  const onChangeCsvField = ({target: {value}}) => {
    setCsvField(value)
    debouncedOnChange(updateCsvField({id, csv_field: value}))
  }

  const onChangeDigitalOnboardingField = ({target: {value}}) => {
    if (value === "subheader") return
    setDigitalOnboardingField(value)
    onChange(updateDoField({id, digital_onboarding_field: value}))
  }

  const onChangeMetaSubKey = ({target: {value}}) => {
    setMetaSubKey(value)
    debouncedOnChange(updateMetaSubKey({id, meta_sub_key: value}))
  }

  useEffect(() => {
    setDigitalOnboardingField(digital_onboarding_field)
  }, [digital_onboarding_field])

  const classes = useStyles()

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <TextField
          autoFocus={true}
          disabled={disabled}
          error={csvFieldTaken || _csvField === _digitalOnboardingField}
          fullWidth={true}
          onChange={onChangeCsvField}
          placeholder="CSV Column"
          {...(csvFieldTaken
            ? {helperText: "This field has already been mapped, and will be ignored."}
            : {})}
          {...(_csvField === _digitalOnboardingField
            ? {
                helperText:
                  "This field matches Digital Onboarding, and does not need to be remapped.  It will be ignored.",
              }
            : {})}
          value={_csvField}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <div className={classes.doFieldWrapper}>
          <DOSelect
            className={classes.select}
            disabled={disabled}
            onChange={onChangeDigitalOnboardingField}
            value={_digitalOnboardingField}
          >
            <MenuItem value="ignored">ignored</MenuItem>
            <ListSubheader className={classes.listSubheader} value="subheader">
              Standard Fields
            </ListSubheader>
            {fields.map(field => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
            {objectives.length > 0 && [
              <Divider key="field-map-divider" />,
              <ListSubheader
                className={classes.listSubheader}
                key="field-map-list-sub-header"
                value="subheader"
              >
                Objectives
              </ListSubheader>,
              objectives.map(({value}) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              )),
            ]}
          </DOSelect>
          {_digitalOnboardingField.match(/^meta_(private|public)/) && (
            <TextField
              autoFocus={true}
              className={classes.metaSubKeyField}
              disabled={disabled}
              fullWidth={true}
              helperText={!_metaSubKey && "subkey required"}
              onChange={onChangeMetaSubKey}
              placeholder="Metadata Key (e.g. my_key)"
              value={_metaSubKey}
              required={true}
              error={!_metaSubKey}
            />
          )}
        </div>
      </TableCell>
      <TableCell className={cx(classes.cell, classes.buttonCell)}>
        <IconButton disabled={disabled} onClick={onDelete} size="medium">
          <DeleteIcon className={classes.button} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

FieldMapRow.propTypes = {
  csvFieldTaken: bool.isRequired,
  csv_field: string.isRequired,
  digital_onboarding_field: string.isRequired,
  disabled: bool,
  fields: arrayOf(string).isRequired,
  id: string.isRequired,
  meta_sub_key: string.isRequired,
  objectives: arrayOf(
    shape({
      name: string,
      value: string,
    })
  ).isRequired,
  onChange: func.isRequired,
}

export default FieldMapRow
