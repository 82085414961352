import {makeStyles} from "@mui/styles"
import {array, arrayOf, bool, func, number, shape, string} from "prop-types"

import DOTable from "components/table/table"

import CardOnFileMetricRow from "./card-on-file-metric-row"

const CardOnFileMetrics = ({drivers, isLoading, metrics, onChange}) => {
  const classes = useStyles()

  const handleChange = (index, objective) => onChange(metrics.toSpliced(index, 1, objective))

  return (
    <div className={classes.container}>
      <DOTable
        headers={[
          {
            field: "name",
            label: "Card on File metric",
            sortable: false,
            tooltipText:
              "The cost of ordering cards, along with the expected volume, expected number of sites that will utilize the card, and your interchange rate. These values are used to calculate our expected ROI for the Card on File widget.",
          },
          {
            field: "valueInput",
            label: "Value Input",
            sortable: false,
          },
          {
            field: "defaultValue",
            label: "Default Value",
            sortable: false,
            tooltipText:
              "The default value based on market research. This default value is used in calculations if no value input is provided.",
          },
        ]}
        isTableLoading={isLoading}
        noResults="There are no Card on File metrics."
        paginationEnabled={false}
        rows={metrics ?? []}
      >
        {(row, index) => (
          <CardOnFileMetricRow
            key={row.key}
            onChange={changed => handleChange(index, changed)}
            metric={row}
            drivers={drivers}
          />
        )}
      </DOTable>
    </div>
  )
}

CardOnFileMetrics.propTypes = {
  drivers: arrayOf(shape({key: string, name: string, value: number})),
  isLoading: bool,
  metrics: array,
  onChange: func,
}

const useStyles = makeStyles(() => ({
  container: {
    "& tr th:nth-child(3)": {
      width: 120,
    },
  },
}))

export default CardOnFileMetrics
