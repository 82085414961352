import CardOnFileButton, {CreditCardIcon} from "./card-on-file-button"
import CardOnFileReadonly from "./card-on-file-readonly"
import CardOnFileSettings from "./card-on-file-settings"

export default {
  title: "Card on File",
  type: "card-on-file",
  Button: CardOnFileButton,
  Icon: CreditCardIcon,
  Readonly: CardOnFileReadonly,
  Settings: CardOnFileSettings,
  suitableFor: ["content_container", "page"],
}
