export const initialState = {isListDirty: false, rewardSetForm: null, rewardStepFormList: []}

export const reducer = (state, action) => {
  switch (action.type) {
    case "DELETE_REWARD_STEP_FORM":
      return {
        ...state,
        rewardStepFormList: state.rewardStepFormList.filter(({data: {id}}) => id !== action.id),
      }
    case "RESET_REWARD_SET_FORM":
      return {...state, rewardSetForm: initialState.rewardSetForm}
    case "RESET_REWARD_STEP_FORMS_LIST":
      return {...state, rewardStepFormList: initialState.rewardStepFormList}
    case "SET_IS_LIST_DIRTY":
      return {...state, isListDirty: action.isListDirty}
    case "SET_PRISTINE":
      return {
        isListDirty: false,
        rewardSetForm: {...state.rewardSetForm, isDirty: false},
        rewardStepFormList: state.rewardStepFormList.map(rewardSetForm => ({
          ...rewardSetForm,
          isDirty: false,
        })),
      }
    case "SET_REWARD_SET_FORM":
      return {...state, rewardSetForm: action.rewardSetForm}
    case "SET_REWARD_STEP_FORM":
      const registeredIndex = state.rewardStepFormList.findIndex(
        ({data: {id}}) => id === action.rewardStepForm.data.id
      )

      return {
        ...state,
        rewardStepFormList:
          registeredIndex === -1
            ? [...state.rewardStepFormList, action.rewardStepForm]
            : state.rewardStepFormList.toSpliced(registeredIndex, 1, action.rewardStepForm),
      }
    default:
      throw new Error(`Unhandled action of type ${action.type}`)
  }
}
