import {Button} from "@mui/material"
import {shape, string} from "prop-types"
import {useMemo} from "react"
import {MdAdd as AddIcon} from "react-icons/md"
import {Link, Route, Switch} from "react-router-dom"

import AccessControlled from "components/access-control/access-controlled"
import AccessControlledRoute from "components/access-control/access-controlled-route"
import BatchProcessingControlCenter from "components/batch-processing-control-center/batch-processing-control-center"
import Padded from "components/padded/padded"
import TitleBar from "components/title-bar/title-bar"

import SessionLogs from "./session-logs/session-logs"
import TeamAuditLogsContainer from "./team-audit-logs-container"
import TeamSeeding from "./team-seeding"
import UsersListContainer from "./users-list-container"
import {WebhookLogDetailsContainer} from "./webhook-logs/webhook-log-details"
import WebhookLogs from "./webhook-logs/webhook-logs"

// (0) / admin (1) / team (2) / (3) / :idParams
const TAB_SECTION_INDEX = 3
const validTabs = ["dashboard", "audit-logs", "batches", "seeding", "session-logs", "webhook-logs"]

export const TeamRouter = ({location}) => {
  const currentTab = useMemo(() => {
    const urlParts = location.pathname.split("/")
    const currentTabValue = urlParts[TAB_SECTION_INDEX]

    return validTabs.indexOf(currentTabValue) > -1 ? currentTabValue : "dashboard"
  }, [location])

  return (
    <>
      <TitleBar title="Team Dashboard">
        {currentTab === "dashboard" && (
          <AccessControlled requiredPermissions="team:users">
            <Button
              color="primary"
              component={Link}
              size="small"
              to="/admin/team/invite-user"
              variant="contained"
            >
              <AddIcon /> Add Member
            </Button>
          </AccessControlled>
        )}
      </TitleBar>

      <Padded>
        <Switch>
          <Route component={UsersListContainer} exact={true} path="/admin/team" />
          <Route component={TeamAuditLogsContainer} path="/admin/team/audit-logs" />
          <Route component={SessionLogs} path="/admin/team/session-logs" />
          <AccessControlledRoute
            component={BatchProcessingControlCenter}
            path={"/admin/team/batches"}
            requiredPermissions="contacts:create"
          />
          <Route component={WebhookLogs} exact={true} path="/admin/team/webhook-logs" />
          <Route
            component={WebhookLogDetailsContainer}
            path="/admin/team/webhook-logs/:webhookLogId"
          />
          <AccessControlledRoute
            component={TeamSeeding}
            path={"/admin/team/seeding"}
            requiredPermissions="team:seeding"
          />
        </Switch>
      </Padded>
    </>
  )
}

TeamRouter.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }),
}

export default TeamRouter
