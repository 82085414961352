import accountsTable from "./accounts-table/accounts-table-manifest"
import cardOnFile from "./card-on-file/card-on-file-manifest"
import cta from "./cta/cta-manifest"
import defaultPlugin from "./default-plugin/default-plugin-manifest"
import directDeposit from "./direct-deposit/direct-deposit-manifest"
import divider from "./divider/divider-manifest"
import document from "./document/document-manifest"
import enrollment from "./enrollment/enrollment-manifest"
import html from "./html/html-manifest"
import image from "./image/image-manifest"
import mobileAppDownload from "./mobile-app-download/mobile-app-download-manifest"
import optIn from "./opt-in/opt-in-manifest"
import progressMeter from "./progress-meter/progress-meter-manifest"
import rewards from "./rewards/rewards-manifest"
import survey from "./survey/survey-manifest"
import text from "./text/text-manifest"
import video from "./video/video-manifest"
import webpage from "./webpage/webpage-embed-manifest"

const plugins = {}

const addPlugin = plugin => (plugins[plugin.type] = plugin)

// Add plugins to this list here
addPlugin(accountsTable)
addPlugin(cardOnFile)
addPlugin(cta)
addPlugin(directDeposit)
addPlugin(divider)
addPlugin(document)
addPlugin(enrollment)
addPlugin(html)
addPlugin(image)
addPlugin(mobileAppDownload)
addPlugin(optIn)
addPlugin(progressMeter)
addPlugin(rewards)
addPlugin(survey)
addPlugin(text)
addPlugin(webpage)
addPlugin(video)

export const pluginsForContainer = type =>
  Object.values(plugins)
    .filter(plugin => plugin.suitableFor.includes(type))
    .sort((a, b) => a.title.localeCompare(b.title))

export const containerPlugins = {
  content_container: pluginsForContainer("content_container"),
  message: pluginsForContainer("message"),
  page: pluginsForContainer("page"),
  template: pluginsForContainer("template"),
}

export const pluginFor = ({type}) => plugins[type] || defaultPlugin
