import {
  Autocomplete,
  Collapse,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"
import {useState} from "react"
import {useSelector} from "react-redux"

import completionConditions, {
  calculateInitialProductValue,
  getCombinedProductList,
} from "lib/completion-conditions"
import useFeatures from "lib/hooks/use-features"
import useForm from "lib/hooks/use-form"
import MaybeTooltip from "lib/maybe-tooltip"

import AccessControlled, {isUserPermitted} from "../access-control/access-controlled"
import DOSelect from "../do-select/do-select"
import Feature from "../feature/feature"
import ObjectiveSelect from "../objective-select/objective-select"
import {checkPageForActionableWidget} from "../templates/page-settings-LEGACY"

const useStyles = makeStyles({
  menuItem: {
    boxSizing: "border-box",
    height: "auto",
    maxWidth: 252,
    whiteSpace: "normal",
  },
  listItemTextSecondary: {
    color: "#7a7b7f",
  },
})

const onProductCompletionChanges = (newValue, change, setCompletionProduct) => {
  setCompletionProduct(newValue)
  if (newValue === null) {
    change("completionProductId", null)
    change("completionProductType", null)
  } else if (newValue.type === "Products") {
    change("completionProductType", null)
    change("completionProductId", newValue.value)
  } else if (newValue.type === "Product Types") {
    change("completionProductId", null)
    change("completionProductType", newValue.value)
  }
}

const GeneralPageSettings = ({page, initialValues, onSubmit}) => {
  const classes = useStyles()
  const {user: currentUser, team} = useSelector(state => ({
    user: state?.session?.user,
    team: state?.session?.team,
  }))
  const {change, inputs, field} = useForm({
    autoSubmitOnChange: true,
    initialValues,
    onSubmit,
  })
  const onChangeObjective = objective => change("objectiveId", objective.id || null)
  const isPermittedToArchive = isUserPermitted(currentUser, "templates:archive")
  const combinedProductList = getCombinedProductList(team)
  const initialProductValue = calculateInitialProductValue(
    team,
    initialValues.completionProductType,
    initialValues.completionProductId
  )
  const [completionProduct, setCompletionProduct] = useState(initialProductValue)

  const {hasFeature} = useFeatures()

  return (
    <>
      <TextField
        margin="normal"
        fullWidth={true}
        helperText="Sets the internal name of the content which can be used for organizing content"
        label="Content Name"
        {...field("contentName")}
      />
      <TextField
        margin="normal"
        fullWidth={true}
        helperText="Shown to contacts as a link to the page in the journey"
        label="Navigation Title"
        {...field("navigationTitle")}
      />
      <FormControl fullWidth={true} margin="normal">
        <InputLabel htmlFor="page-editor-objective-label">Objective</InputLabel>
        <ObjectiveSelect
          labelId="page-editor-objective-label"
          onChange={onChangeObjective}
          value={inputs?.objectiveId ? {id: inputs.objectiveId.value} : ""}
        />
      </FormControl>
      <Collapse
        appear={true}
        in={!!inputs?.objectiveId?.value}
        sx={theme => ({marginTop: theme.spacing(2)})}
      >
        <TextField
          className={classes.field}
          fullWidth={true}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          inputProps={{
            //eslint-disable-line react/jsx-no-duplicate-props
            min: 0,
            max: 100,
          }}
          label="Benchmark"
          type="number"
          {...field("benchmark")}
        />
      </Collapse>
      <FormControl fullWidth={true} margin="normal" sx={theme => ({marginTop: theme.spacing(2)})}>
        <InputLabel htmlFor="completionCondition">Completion Condition</InputLabel>
        <DOSelect
          limitHeight={false}
          renderValue={value =>
            completionConditions.find(condition => condition.value === value).primary
          }
          helperText="Page completion does not cause Objective Completion"
          {...field("completionCondition", {exclude: ["helperText", "onChange"]})}
          onChange={event => {
            // Clear completion product values if the completion condition changes
            change("completionProductId", null)
            change("completionProductType", null)
            setCompletionProduct(null)

            // Call the original onChange function provided by the useForm hook
            field("completionCondition").onChange(event)
          }}
        >
          {completionConditions
            .filter(
              condition =>
                hasFeature("product-opening-value-reporting") || condition.value !== "open"
            )
            .map(({value, primary, secondary}) => (
              <MenuItem
                classes={{root: classes.menuItem}}
                disabled={value === "action" && checkPageForActionableWidget(page)}
                key={value}
                value={value}
              >
                <ListItemText
                  classes={{secondary: classes.listItemTextSecondary}}
                  primary={primary}
                  secondary={secondary}
                />
              </MenuItem>
            ))}
        </DOSelect>
      </FormControl>
      {inputs.completionCondition?.value === "open" && (
        <Feature featureKey="product-opening-value-reporting">
          <Autocomplete
            options={combinedProductList}
            groupBy={option => option.type}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label="Account" />}
            value={completionProduct}
            onChange={(_event, newValue) => {
              onProductCompletionChanges(newValue, change, setCompletionProduct)
            }}
            isOptionEqualToValue={(option, value) =>
              option.type === value.type && option.value === value.value
            }
          />
        </Feature>
      )}
      <AccessControlled requiredPermissions="templates:archive">
        <MaybeTooltip
          enterDelay={500}
          isTooltip={isPermittedToArchive}
          title="This page will be hidden from the content library, but remain active in your campaigns."
        >
          <FormControl margin="normal">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...field("isArchived", {bool: true, exclude: ["error", "helperText"]})}
                />
              }
              disabled={!isPermittedToArchive}
              label="Archive"
            />
          </FormControl>
        </MaybeTooltip>
      </AccessControlled>
    </>
  )
}

GeneralPageSettings.propTypes = {
  initialValues: object,
  onSubmit: func.isRequired,
  page: object,
}

export default GeneralPageSettings
