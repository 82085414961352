import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {convertToRaw} from "draft-js"
import {arrayOf, bool, func, object, shape, string} from "prop-types"
import {Component, createRef} from "react"
import ReactDOM from "react-dom"
import {MdInfo as InfoIcon} from "react-icons/md"
import shortid from "shortid"

import draftJsBlockRenderers from "lib/draft-js/block-renderers"
import customStyleFn from "lib/draft-js/custom-style-fn"
import {PERSONALIZATION_ENTITY} from "lib/draft-js/entities/personalization-entity"
import Editor from "lib/draft-js/simple-editor"
import {validDomainAndTld, validPhone, validString} from "lib/field-validations"
import {featurify} from "lib/hooks/use-features"
import MaybeTooltip from "lib/maybe-tooltip"
import {formatUrl} from "lib/string/url-helpers"

import {ContentType} from "../../contexts/editor-context"
import {templatePageContext} from "../../contexts/template-page-context"
import {history} from "../../reducers/store"
import AccessControlled, {isUserPermitted} from "../access-control/access-controlled"
import {humanizeType} from "../content-library/helpers"
import DangerButton from "../danger-button/danger-button"
import ConfirmDialog from "../dialogs/confirm-dialog"
import DeleteMessageDialog from "../dialogs/delete-message-dialog"
import {TemplateListPreview} from "../dialogs/template-list-preview"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import DOSelect from "../do-select/do-select"
import {journeyContext} from "../journeys/journey-context"
import ObjectiveSelect from "../objective-select/objective-select"
import DuplicateMessageConfirmationDialog from "./duplicate-message-confirmation-dialog-LEGACY"
import SharedMessageNotice from "./shared-message-notice-LEGACY"

const maxShortIdLength = 14
const nbrdUrlMaxLength = ` ${process.env.REACT_APP_NBRD_URL}`.length + maxShortIdLength

const gondorPersonalizationLength = 15

// Each block introduces a new line character for the final text message
// that's why we need the (blocks.length - 1)
const getPlainTextLength = blocks =>
  blocks.reduce((acc, block) => acc + block.text.length, 0) + blocks.length - 1

const calculateSmsLength = ({entityMap, blocks} = {}) => {
  if (!entityMap) return 0

  return (
    nbrdUrlMaxLength +
    Object.values(entityMap).reduce((acc, {type, data: {value}}) => {
      if (type === PERSONALIZATION_ENTITY) {
        const offset = value.includes("nameFull")
          ? gondorPersonalizationLength * 2
          : gondorPersonalizationLength

        // 2 chars for the decorations in the personalization
        // in eg #CONTACT.NAME_FULL
        return acc - value.length - 2 + offset
      }

      return acc
    }, getPlainTextLength(blocks))
  )
}
export class MessageForm extends Component {
  state = {
    content_variables: this.props.content_variables,
    payload: this.props.message.payload,
    contentName: this.props.message.contentName,
    contentNameHasError: false,
    isArchived: this.props.message?.isArchived ?? false,
    isWithoutLink: this.props.message?.isWithoutLink ?? false,
    smsLength: 0,
    focus: false,
    sampleRecipientHasError: false,
    isSendingSampleMessage: false,
    sendSampleMessageFailureMessage: null,
    showDuplicateConfirmation: false,
  }

  componentDidMount = () => {
    const {
      message: {payload, contentName, objectiveId},
      currentUser,
    } = this.props

    this.setState({
      smsLength: calculateSmsLength(payload.body),
      sampleRecipient: currentUser.phoneMobile || "",
      smsLinksTo: payload.linksTo || "journey-page",
      contentName: contentName || "New SMS",
      url: payload.url || "",
      objectiveId,
    })

    this.checkMessageType()
  }

  checkMessageType = () => {
    const {message, templateId, templateMessage} = this.props
    if (message.type === "sms") return

    if (this.props.templateId) {
      history.push(`/admin/templates/${templateId}/messages/${templateMessage.id}`)
    } else {
      history.push(`/admin/templates/content-library/messages/${message.id}`)
    }
  }

  onOpenDeleteDialog = () => this.setState({isDeleteDialogOpen: true})
  onCloseDeleteDialog = () => this.setState({isDeleteDialogOpen: false})

  onOpenSamplePanel = () => {
    this.setState({isSamplePanelOpen: true}, () => {
      const inputNode = ReactDOM.findDOMNode(this.sampleSendInput.current)

      inputNode.focus()

      // We have to wait here for the animation to complete, otherwise the element won't be
      // where we expect
      setTimeout(() => {
        inputNode.scrollIntoView({behavior: "smooth"})
      }, 500)
    })
  }
  onCloseSamplePanel = () => this.setState({isSamplePanelOpen: false})

  onChangeObjective = ({id}) => this.setState({objectiveId: id || null})

  onChangeSmsBody = smsBodyState => {
    const body = convertToRaw(smsBodyState.getCurrentContent())

    this.setState(state => ({
      payload: {
        ...state.payload,
        body,
      },
      smsLength: calculateSmsLength(body),
    }))
  }

  onChangeContentName = ({target: {value}}) => {
    const contentNameErrorMessage = validString(value)

    this.setState({
      contentName: value,
      contentNameErrorMessage,
      contentNameHasError: !!contentNameErrorMessage,
    })
  }

  onChangeArchived = ({target: {checked}}) => this.setState({isArchived: checked})

  onChangeSuppressLink = ({target: {checked}}) => this.setState({isWithoutLink: checked})

  onToggleTransactional = ({target: {checked}}) => this.setState({isTransactional: checked})

  onArchive = () => {
    this.props.onArchive()
    this.setState({isDeleteDialogOpen: false})
  }

  onDelete = () => {
    this.props.onDelete(this.props.id, this.props.message.messageId)
    this.setState({isDeleteDialogOpen: false})
  }

  onSave = () => {
    const {type, onSubmit, onClose} = this.props
    const {
      content_variables,
      isArchived,
      isTransactional,
      smsLinksTo,
      contentName,
      url,
      objectiveId,
    } = this.state

    if (this.state.contentNameHasError) return

    if (type === "sms" && smsLinksTo === "url" && (!url || validDomainAndTld(url))) {
      this.setState({smsLinkError: true})
      return
    }

    const payload = {...this.state.payload}

    payload.linksTo = smsLinksTo
    payload.slug = payload?.slug ?? shortid.generate()
    payload.url = smsLinksTo === "url" ? formatUrl(url) : null

    // when in an SMS form, content variables are a top level property
    // so we need to directly call setContentVariables here before calling onSbumit
    if (this.props.setContentVariables) {
      this.props.setContentVariables(content_variables).then(() => {
        onSubmit({isArchived, isTransactional, payload, contentName, objectiveId})
        onClose()
      })
    } else {
      onSubmit({isArchived, isTransactional, payload, contentName, objectiveId})
      onClose()
    }
  }

  onRecipientChange = ({target: {value}}) => {
    this.setState({sampleRecipient: value})
  }

  onSendSample = () => {
    const {type, templateId, onSendSampleMessage} = this.props
    const {sampleRecipient, smsLinksTo, contentName, url} = this.state

    if (this.state.contentNameHasError) return

    if (type === "sms" && !!validPhone(sampleRecipient)) {
      this.setState({sampleRecipientHasError: true})
      return
    }

    const payload = {...this.state.payload}

    payload.linksTo = smsLinksTo
    payload.url = url

    this.setState({isSendingSampleMessage: true}, () => {
      onSendSampleMessage({
        recipient: sampleRecipient,
        type,
        contentName,
        payload,
        templateId,
        content_variables:
          type === "sms" ? this.state.content_variables : this.props.content_variables,
      })
        .then(() => {
          this.setState({
            isSendingSampleMessage: false,
            sendSampleMessageFailureMessage: null,
            isSamplePanelOpen: false,
          })
        })
        .catch(({message: sendSampleMessageFailureMessage}) => {
          this.setState({
            isSendingSampleMessage: false,
            sendSampleMessageFailureMessage,
          })
        })
    })
  }

  onChangeSmsLinksTo = (_e, value) => {
    this.setState({smsLinksTo: value})
    this.setState({isWithoutLink: value === "none"})
  }

  onChangeSmsPage = e => {
    // eslint-disable-next-line no-unused-expressions
    this.setState(state => ({
      ...state,
      content_variables: {
        ...state.content_variables,
        [this.props?.message?.payload?.slug]: e.target.value,
      },
    }))
  }

  onChangeSmsUrl = ({target: {value}}) => {
    this.setState({url: value})
  }

  contentEditorRef = createRef()
  subjectEditorRef = createRef()
  sampleSendInput = createRef()

  onSubjectTab = event => {
    if (!event.shiftKey) {
      event.preventDefault()
      this.contentEditorRef.current.focus()
    }
  }

  onBodyTab = event => {
    if (event.shiftKey) {
      event.preventDefault()
      this.subjectEditorRef.current.focus()
    }
  }
  onFocus = () => this.setState({focus: true})
  onBlur = () => this.setState({focus: false})

  onShowDuplicateConfirmation = () => this.setState({showDuplicateConfirmation: true})
  onHideDuplicateConfirmation = () => this.setState({showDuplicateConfirmation: false})
  onDuplicateConfirmed = () => {
    const {id, type} = this.props.message
    const {smsLinksTo: linksTo, url, contentName} = this.state
    const payload = {...this.state.payload, linksTo, url}

    return this.props.onDuplicate(id, {
      content_variables:
        type === "sms" ? this.state.content_variables : this.props.content_variables,
      payload,
      contentName,
    })
  }

  renderDialog() {
    const {hasFeature, isContentLibrary, message} = this.props
    const {isDeleteDialogOpen} = this.state

    if (isContentLibrary)
      return (
        <UltraConfirmDialog
          confirmationText={message.contentName}
          isHighSeverity={message.id !== "new"}
          key={message.id}
          onClose={this.onCloseDeleteDialog}
          onConfirm={this.onDelete}
          open={Boolean(isDeleteDialogOpen)}
          recordType="message"
        >
          {message.id !== "new" && <TemplateListPreview id={message.id} recordType="message" />}
        </UltraConfirmDialog>
      )

    if (hasFeature("atomic-assets"))
      return (
        <ConfirmDialog
          content="Are you sure you want to remove this message from this campaign?"
          onClose={this.onCloseDeleteDialog}
          onConfirm={this.onDelete}
          open={Boolean(isDeleteDialogOpen)}
        />
      )

    return (
      <DeleteMessageDialog
        isOpen={Boolean(isDeleteDialogOpen)}
        onArchive={this.onArchive}
        onClose={this.onCloseDeleteDialog}
        onDelete={this.onDelete}
      />
    )
  }

  renderDangerButtonText() {
    const {hasFeature, isContentLibrary} = this.props

    if (isContentLibrary) return "Delete Message"

    if (hasFeature("atomic-assets")) return "Remove Message"

    return "Delete Message"
  }

  render() {
    const {
      classes,
      currentUser,
      hasFeature,
      dialogTitle,
      isContentLibrary,
      message,
      onClose,
      templatePages,
      type,
    } = this.props
    const {
      content_variables,
      isArchived,
      isWithoutLink,
      isSamplePanelOpen,
      sampleRecipient,
      smsLength,
      smsLinkError,
      smsLinksTo,
      contentName,
      contentNameHasError,
      contentNameErrorMessage,
      url,
      sampleRecipientHasError,
      isSendingSampleMessage,
      sendSampleMessageFailureMessage,
      objectiveId,
    } = this.state

    const isPermittedToArchive = isUserPermitted(currentUser, "templates:archive")

    // TODO: currently, templates use the /v1/templates call, and atomic assets uses the /v1/messages call, and they return different values for id. https://trello.com/c/99To0ntR
    const messageId = this.props.id || message.id

    return (
      <Dialog
        className={classes.dialog}
        data-testid="message-form"
        fullWidth={true}
        maxWidth={type === "sms" ? "md" : "lg"}
        onClose={onClose}
        open={true}
        PaperProps={{
          "aria-labelledby": "message-form-header",
        }}
      >
        {dialogTitle && <DialogTitle id="message-form-header">{dialogTitle}</DialogTitle>}
        <DialogContent>
          <FormControl
            className={cx(classes.formControl, classes.titleField)}
            error={contentNameHasError}
            fullWidth={true}
            required={true}
          >
            <TextField
              fullWidth={true}
              id="contentName"
              label="Content Name"
              onChange={this.onChangeContentName}
              value={contentName}
            />
            {contentNameHasError && (
              <Typography className={classes.errorText} gutterBottom={true} variant="caption">
                {contentNameErrorMessage}
              </Typography>
            )}
          </FormControl>
          <Editor
            autoFocus={true}
            blockRenderMap={draftJsBlockRenderers}
            contentType={ContentType.Sms}
            customStyleFn={customStyleFn}
            fullWidth={true}
            initialValue={message.payload.body}
            label="Body"
            margin="normal"
            minimal={true}
            multiline={true}
            onChange={this.onChangeSmsBody}
            ref={this.subjectEditorRef}
            smsLength={smsLength}
            tabIndex={1}
          />
          <FormControl className={classes.smsObjectiveSelect} fullWidth={true}>
            <InputLabel id="message-form-objective-label">Select an Objective</InputLabel>
            <ObjectiveSelect
              labelId="message-form-objective-label"
              onChange={this.onChangeObjective}
              value={objectiveId ? {id: objectiveId} : ""}
            />
          </FormControl>
          <FormControl className={classes.linkToGroup} component="fieldset">
            <FormLabel className={classes.linkToLabel} component="legend">
              Link to
            </FormLabel>
            <RadioGroup onChange={this.onChangeSmsLinksTo} row={true} value={smsLinksTo}>
              <MaybeTooltip
                isTooltip={!isContentLibrary && !templatePages?.length}
                title="This campaign has no pages"
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={!isContentLibrary && !templatePages?.length}
                  label={
                    <>
                      Journey Page
                      <Tooltip title="The button will direct a recipient to the page in the journey that this message is attached to.">
                        <span>
                          <InfoIcon className={classes.infoIcon} />
                        </span>
                      </Tooltip>
                    </>
                  }
                  value="journey-page"
                />
              </MaybeTooltip>
              <FormControlLabel
                control={<Radio />}
                label={
                  <>
                    URL
                    <Tooltip title="Use this option if you want to direct the recipient to a different place outside of the journey.">
                      <span>
                        <InfoIcon className={classes.infoIcon} />
                      </span>
                    </Tooltip>
                  </>
                }
                value="url"
              />
              {type === "sms" && (
                <FormControlLabel
                  control={<Radio />}
                  label={
                    <>
                      No Link
                      <Tooltip title="Use this option to send no clickable link with this message">
                        <span>
                          <InfoIcon className={classes.infoIcon} />
                        </span>
                      </Tooltip>
                    </>
                  }
                  value="none"
                />
              )}
            </RadioGroup>
          </FormControl>
          {smsLinksTo === "journey-page" && !isWithoutLink && isContentLibrary && (
            <div className={classes.journeyPagePanel}>
              You can choose a page to link to once this SMS gets added to a campaign.
            </div>
          )}
          {smsLinksTo === "journey-page" &&
            !isWithoutLink &&
            !isContentLibrary &&
            templatePages?.length > 0 && (
              <div className={classes.journeyPagePanel}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="pageSlug">Pages</InputLabel>
                  <DOSelect
                    id="pageSlug"
                    onChange={this.onChangeSmsPage}
                    value={content_variables?.[this.props?.message?.payload?.slug] ?? ""}
                  >
                    {templatePages?.map(({page: p}) => (
                      <MenuItem key={p.slug} value={p.slug}>
                        {p.contentName}
                      </MenuItem>
                    ))}
                  </DOSelect>
                </FormControl>
                {hasFeature("atomic-assets") && (
                  <FormHelperText className={classes.formHelperText}>
                    This only affects the campaign you are currently editing. Other campaigns that
                    use this page will not be affected.
                  </FormHelperText>
                )}
              </div>
            )}
          {smsLinksTo === "url" && !isWithoutLink && (
            <>
              <TextField
                autoFocus={true}
                fullWidth={true}
                label="URL"
                onChange={this.onChangeSmsUrl}
                value={url}
              />
              {smsLinkError && (
                <Typography
                  className={cx("sms-error-text", classes.errorText)}
                  gutterBottom={true}
                  variant="caption"
                >
                  Your custom url is not valid. Please double check that you have provided a valid
                  url, e.g. <em>https://www.google.com</em>
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions classes={{root: classes.actions}}>
          <div>
            {this.props.onDelete && (
              <DangerButton onClick={this.onOpenDeleteDialog} tabIndex={2}>
                {this.renderDangerButtonText()}
              </DangerButton>
            )}
            {isContentLibrary && (
              <AccessControlled requiredPermissions="templates:archive">
                <MaybeTooltip
                  enterDelay={500}
                  isTooltip={isPermittedToArchive}
                  title={`This ${humanizeType(
                    type
                  )} will be hidden from the content library, but remain active in your campaigns.`}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isArchived}
                        className={classes.archiveSwitch}
                        color="primary"
                        onChange={this.onChangeArchived}
                      />
                    }
                    disabled={!isPermittedToArchive}
                    label="Archive"
                  />
                </MaybeTooltip>
              </AccessControlled>
            )}
          </div>
          <div>
            <Button color="grey" onClick={onClose} tabIndex={2}>
              Cancel
            </Button>
            <Button color="grey" onClick={this.onOpenSamplePanel} tabIndex={2}>
              Test
            </Button>
            <Button color="grey" data-testid="save" onClick={this.onSave} tabIndex={2}>
              Save
            </Button>
          </div>
        </DialogActions>
        {this.renderDialog()}
        <Collapse in={Boolean(isSamplePanelOpen)}>
          <DialogContent>
            <DialogContentText>
              Enter the {type === "sms" ? "phone number" : "e-mail address"} that you would like
              this sample message to be sent to:
              <br />
              <Typography variant="caption">
                If you have entered any personalizations, they will not be replaced in the sample.
              </Typography>
            </DialogContentText>
            <TextField
              fullWidth={true}
              inputRef={this.sampleSendInput}
              onChange={this.onRecipientChange}
              tabIndex={3}
              value={sampleRecipient}
            />
            {sampleRecipientHasError && (
              <Typography className={classes.errorText} gutterBottom={true} variant="caption">
                This {type === "sms" ? "phone number" : "e-mail address"} is invalid. Please double
                check you have entered a valid {type === "sms" ? "phone number" : "e-mail address"}{" "}
                and try again.
              </Typography>
            )}
            {!!sendSampleMessageFailureMessage && (
              <Typography className={classes.errorText} gutterBottom={true} variant="caption">
                {sendSampleMessageFailureMessage}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button color="grey" onClick={this.onCloseSamplePanel}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={isSendingSampleMessage}
              onClick={this.onSendSample}
              variant="contained"
            >
              Send
            </Button>
          </DialogActions>
        </Collapse>

        {hasFeature("atomic-assets") && message?.isShared && (
          <div className={classes.messageNotice}>
            <SharedMessageNotice
              editingTemplateId={this.props.templateId}
              forceClose={this.state.showDuplicateConfirmation}
              message={`You are editing a Shared ${humanizeType(
                type
              )}. Any edits you make here will be reflected on all
              campaigns that share this ${type}. You can change this setting by making a unique copy.`}
              messageId={messageId}
              onShowDuplicateConfirmation={
                this.isContentLibrary ? undefined : this.onShowDuplicateConfirmation
              }
              title={`Shared ${humanizeType(type)}`}
            />
            {!this.isContentLibrary && (
              <DuplicateMessageConfirmationDialog
                message={
                  <span>
                    Duplicating this {type} will make it unique to this campaign. Any edits you make
                    moving forward will <b>no longer</b> affect any campaigns that share this
                    content.
                  </span>
                }
                onClose={this.onHideDuplicateConfirmation}
                onDuplicate={this.onDuplicateConfirmed}
                open={this.state.showDuplicateConfirmation}
                title={`Confirm ${type} duplication`}
              />
            )}
          </div>
        )}
      </Dialog>
    )
  }
}

const styles = theme => ({
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
  archiveSwitch: {
    marginLeft: theme.spacing(2),
  },
  dialog: {
    marginTop: 45,
  },
  formHelperText: {
    lineHeight: 1.5,
  },
  journeyPagePanel: {
    alignItems: "center",
    background: "rgba(242, 243, 247, 1)",
    boxShadow: "inset 0 8px 5px -5px #e4e4e6",
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    justifyContent: "center",
    marginTop: theme.spacing(0.5),
    minHeight: 100,
    padding: 10,

    // break out of parent's padding
    marginLeft: -24,
    width: "calc(100% + 48px)",
  },
  messageNotice: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 150,
  },
  errorText: {
    marginTop: 10,
    color: theme.palette.error.main,
  },
  link: {
    display: "inline",
  },
  infoIcon: {
    verticalAlign: "middle",
    marginLeft: theme.spacing(1),
  },
  linkToGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  linkToLabel: {
    fontSize: "0.75rem",
  },
  titleField: {
    marginBottom: 10,
  },
  smsObjectiveSelect: {
    marginTop: 20,
  },
})

MessageForm.propTypes = {
  classes: object.isRequired,
  content_variables: object,
  currentUser: object.isRequired,
  dialogTitle: string,
  hasFeature: func.isRequired,
  id: string,
  isContentLibrary: bool,
  message: shape({
    payload: object.isRequired,
    isArchived: bool,
    isTransactional: bool,
    contentName: string,
  }).isRequired,
  onArchive: func,
  onClose: func.isRequired,
  onDelete: func,
  onDuplicate: func,
  onSendSampleMessage: func.isRequired,
  onSubmit: func.isRequired,
  pageId: string,
  setContentVariables: func,
  templateId: string,
  templateMessage: shape({
    id: string,
  }),
  templatePages: arrayOf(
    shape({
      page: shape({
        contentName: string.isRequired,
        id: string.isRequired,
        slug: string.isRequired,
      }),
    })
  ),
  type: string,
}

export default withStyles(styles)(journeyContext(templatePageContext(featurify(MessageForm))))
