import {object} from "prop-types"
import {NavLink} from "react-router-dom"

import Feature from "../feature/feature"

const TeamSettingSubNav = ({classes}) => (
  <>
    <NavLink
      className={classes.link}
      exact={true}
      title="Team Settings - General"
      to="/admin/settings/general"
    >
      <span>General</span>
    </NavLink>

    <NavLink
      className={classes.link}
      title="Team Settings - Branding"
      to="/admin/settings/branding"
    >
      <span>Branding</span>
    </NavLink>

    <NavLink
      className={classes.link}
      title="Team Settings - Messaging"
      to="/admin/settings/messaging"
    >
      <span>Messaging</span>
    </NavLink>

    <NavLink
      className={classes.link}
      title="Team Settings - Notifications"
      to="/admin/settings/notifications"
    >
      <span>Notifications</span>
    </NavLink>

    <NavLink className={classes.link} title="Team Settings - API Access" to="/admin/settings/api">
      <span>API Access</span>
    </NavLink>

    <NavLink className={classes.link} title="Team Settings - SFTP" to="/admin/settings/sftp">
      <span>SFTP</span>
    </NavLink>

    <NavLink
      className={classes.link}
      title="Team Settings - Field Mappings"
      to="/admin/settings/field-mappings"
    >
      <span>Field Mappings</span>
    </NavLink>

    <NavLink
      className={classes.link}
      title="Team Settings - Products"
      to="/admin/settings/products"
    >
      <span>Products</span>
    </NavLink>

    <Feature featureKey="advanced-insights">
      <NavLink
        className={classes.link}
        title="Team Settings - Insights"
        to="/admin/settings/insights"
      >
        <span>Insights</span>
      </NavLink>
    </Feature>

    <Feature featureKey="cmc-flex-integration">
      <NavLink
        className={classes.link}
        title="Team Settings - CMC Flex Diagnostics"
        to="/admin/settings/cmc-flex-diagnostics"
      >
        <span>CMC Flex Diagnostics</span>
      </NavLink>
    </Feature>
  </>
)

TeamSettingSubNav.propTypes = {
  classes: object,
}

export default TeamSettingSubNav
