import {Button} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func} from "prop-types"

import DatePicker from "components/date-picker/date-picker"

import dateParser from "lib/date/date-parser"
import useForm from "lib/hooks/use-form"

const DateFilter = ({onSubmit}) => {
  const classes = useStyles()
  const {field, resetForm} = useForm({
    autoSubmitOnChange: true,
    autoSubmitDebounceTime: 250,
    enableReinitialize: true,
    parse: {
      startDate: dateParser,
      endDate: dateParser,
    },
    onSubmit,
  })

  return (
    <div className={classes.container}>
      <DatePicker format="MMMM dd yyyy" label="From" {...field("startDate")} />
      <DatePicker format="MMMM dd yyyy" label="To" {...field("endDate")} />

      <Button color="grey" onClick={resetForm}>
        reset
      </Button>
    </div>
  )
}

DateFilter.propTypes = {
  onSubmit: func.isRequired,
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",

    "& > :nth-last-child(n + 2)": {
      marginRight: theme.spacing(2),
    },
  },
}))

export default DateFilter
