import {node, shape, string} from "prop-types"
import {createContext, useCallback, useContext, useEffect, useReducer, useState} from "react"

import {fetchInsightsPageStats, fetchTemplate, fetchWhichTemplateContentBlocks} from "lib/api"
import {toCamelCase} from "lib/case-converter"

import {createHandler, useSocket} from "../../contexts/socket-manager"
import insightsReducer, {actions, initialState} from "./insights-reducer"

const InsightsContext = createContext()
InsightsContext.displayName = "InsightsContext"

export const InsightsContextProvider = ({children, templateId}) => {
  const {addHandler, removeHandler} = useSocket()
  const [hasSocket, setHasSocket] = useState(false)
  const [state, dispatch] = useReducer(insightsReducer, initialState)

  const addInsightsCache = useCallback(insightsCache => {
    if (insightsCache) dispatch({type: actions.setInsights, insightsCache})
  }, [])

  const hasWidget = useCallback(widgetType => state?.contentBlocks?.[widgetType] ?? false, [state])

  const hasWidgets = useCallback(widgetTypes => widgetTypes.some(hasWidget), [hasWidget])

  const loadPageStats = useCallback(
    (templateId, params) => fetchInsightsPageStats(templateId, params).then(addInsightsCache),
    [addInsightsCache]
  )

  const setFilterParams = filter => dispatch({type: actions.setFilterParams, filter})

  useEffect(() => {
    if (!templateId) return

    const insightsCached = createHandler(
      `insights:${templateId}:template`,
      "insights_cached",
      insightsCache => addInsightsCache(toCamelCase(insightsCache))
    )

    addHandler(insightsCached)

    setHasSocket(true)

    return () => removeHandler(insightsCached)
  }, [addHandler, addInsightsCache, templateId, removeHandler])

  useEffect(() => {
    if (!hasSocket) return

    Promise.all([
      fetchTemplate(templateId).then(template => dispatch({type: actions.setTemplate, template})),
      fetchWhichTemplateContentBlocks(templateId).then(contentBlocks =>
        dispatch({type: actions.setContentBlocks, contentBlocks})
      ),
      loadPageStats(templateId, state.filter),
    ])
  }, [hasSocket, loadPageStats, state.filter, templateId])

  const value = {
    ...state,
    addInsightsCache,
    hasWidget,
    hasWidgets,
    setFilterParams,
    templateId,
  }

  return <InsightsContext.Provider value={value}>{children}</InsightsContext.Provider>
}

InsightsContextProvider.propTypes = {
  children: node.isRequired,
  match: shape({params: shape({templateId: string})}),
  templateId: string.isRequired,
}

export const useInsights = () => useContext(InsightsContext)

export const insightsConsumer = Component => props => <Component {...useInsights()} {...props} />

export const insightsContextProvider = Component => props => (
  // eslint-disable-next-line react/prop-types
  <InsightsContextProvider templateId={props.match.params.templateId}>
    <Component {...props} />
  </InsightsContextProvider>
)

export const insightsContext = Component => props => (
  <InsightsContext.Consumer>
    {value => <Component {...value} {...props} />}
  </InsightsContext.Consumer>
)
