import {Typography} from "@mui/material"
import {Grid} from "@mui/material"
import {useEffect} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"

import {fetchAdvancedInsightsEnrollmentWidget} from "lib/api"

import EnrollmentWidgetChart from "./enrollment-widget-chart"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const OptInWidget = () => {
  const {enrollmentWidget, fetchInsights, filterParams, hasSocket} = useAdvancedTeamInsights()

  // Fetch data
  useEffect(() => {
    if (hasSocket) {
      fetchInsights("enrollmentWidget", fetchAdvancedInsightsEnrollmentWidget, [{...filterParams}])
    }
  }, [fetchInsights, filterParams, hasSocket])

  return (
    <>
      {enrollmentWidget?.data && enrollmentWidget.data.length > 0 ? (
        <div style={{marginTop: "30px", marginBottom: "50px"}}>
          <Typography variant="h6">Enrollment</Typography>
          <Grid container={true} spacing={2}>
            {enrollmentWidget.data.map(data => (
              <Grid key={data.name} item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
                <EnrollmentWidgetChart
                  name={data.name}
                  enrollmentData={data.result || []}
                  totalContacts={data.totalContacts || 0}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}
    </>
  )
}

export default OptInWidget
