import {Button, IconButton, LinearProgress, SnackbarContent, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import uniqBy from "lodash/uniqBy"
import {arrayOf, func, object, string} from "prop-types"
import {useContext, useEffect, useReducer, useState} from "react"
import {FaExclamationCircle as FailedIcon, FaCheckCircle as SuccessIcon} from "react-icons/fa"
import {MdClose as CloseIcon} from "react-icons/md"
import {useDispatch} from "react-redux"
import {Link} from "react-router-dom"

import {getBatch} from "lib/api"
import pluralize from "lib/string/pluralize"

import {dismissAppMessage, displayAppMessage} from "../../../actions/app-actions"
import {SocketManagerContext, createHandler} from "../../../contexts/socket-manager"

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cancelledIcon: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  cancelledText: {
    fontSize: "1.2rem",
  },
  cancelledActions: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    "& > *": {
      margin: "0 auto",
    },
    "& > button": {
      marginBottom: theme.spacing(2),
    },
  },
  buttons: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const computeResults = completedBatches =>
  completedBatches.reduce(
    (acc, curr) => ({
      existing: acc.existing + curr.existing,
      failed: acc.failed + curr.failed,
      new: acc.new + curr.new,
    }),
    {existing: 0, new: 0, failed: 0}
  )

const MARK_BATCH_COMPLETED = "MARK_BATCH_COMPLETED"

const markBatchCompleted = payload => ({type: MARK_BATCH_COMPLETED, payload})

const reducer = (state, action) => {
  switch (action.type) {
    case MARK_BATCH_COMPLETED:
      return uniqBy([...state, action.payload], "id")

    default:
      throw new Error("unknown type specified")
  }
}

const WaitForBatchProcessing = ({batchOperationIds, batchId, onComplete, onReset}) => {
  const [completedBatches, dispatch] = useReducer(reducer, [])
  const {addHandler, removeHandler} = useContext(SocketManagerContext)
  const [batchCancelled, setBatchCancelled] = useState(false)

  const classes = useStyles()

  const globalDispatch = useDispatch()

  // it's possible the batch processed super quickly
  useEffect(() => {
    if (batchOperationIds.length === 0) return
    let mounted = true

    const getCompletedBatches = async () => {
      const nextCompletedBatches = await batchOperationIds
        .map(async batchOperationId => await getBatch(batchOperationId))
        .filter(batch => !!batch.results)

      if (nextCompletedBatches.length === batchOperationIds.length)
        onComplete(computeResults(nextCompletedBatches))
      else if (mounted)
        nextCompletedBatches.forEach(batch => {
          dispatch(markBatchCompleted(batch))
        })
    }

    getCompletedBatches()
    return () => {
      mounted = false
    }
  }, [batchOperationIds, onComplete])

  useEffect(() => {
    const processedHandler = createHandler("batches", "batch_processed", processedBatch => {
      if (batchOperationIds.find(batch => batch.id === processedBatch.id))
        dispatch(markBatchCompleted(processedBatch))
    })

    addHandler(processedHandler)

    const deletedHandler = createHandler("batches", "batch_deleted", deletedBatch => {
      if (deletedBatch.batch_id === batchId) {
        removeHandler(processedHandler)
        setBatchCancelled(true)
      }
    })

    addHandler(deletedHandler)

    return () => {
      removeHandler(processedHandler)
      removeHandler(deletedHandler)
    }
  }, [addHandler, batchId, batchOperationIds, removeHandler])

  useEffect(() => {
    let batchCompletedNormally = false
    const handler = createHandler("batches", "batch_operation_completed", ({batch_id, results}) => {
      if (batch_id === batchId) {
        batchCompletedNormally = true
        onComplete(results)
      }
    })

    addHandler(handler)

    return () => {
      if (!batchCompletedNormally) {
        const globalNotificationHandler = createHandler(
          "batches",
          "batch_operation_completed",
          (data, id) => {
            if (!window.location.pathname.includes("/team/batches") && data.batch_id === batchId) {
              const onClose = () => {
                globalDispatch(dismissAppMessage())
              }

              globalDispatch(
                displayAppMessage(<CompletionNotification {...data} onClose={onClose} />)
              )
            }
            removeHandler({id})
          }
        )

        addHandler(globalNotificationHandler)
      }

      removeHandler(handler)
    }
  }, [addHandler, batchId, globalDispatch, onComplete, removeHandler])

  if (batchCancelled)
    return (
      <div>
        <FailedIcon className={classes.cancelledIcon} size={60} />
        <Typography className={classes.cancelledText} variant="h2">
          This batch was cancelled by another user before it could finish processing.
        </Typography>
        <div className={classes.cancelledActions}>
          <Button color="primary" onClick={onReset} variant="contained">
            Re-upload
          </Button>
          <Button component={Link} to="/admin/team/batches" variant="contained">
            View all batches
          </Button>
        </div>
      </div>
    )

  return (
    <>
      <div>
        <Typography>We've received your upload and are processing it now...</Typography>
        <Typography variant="caption">
          You can safely start another upload or leave this page. If you stick around we'll notify
          you when we've finished processing this batch.
        </Typography>
        <div className={classes.actions}>
          <Button className={classes.buttons} color="grey" onClick={onReset} variant="contained">
            Need to upload another?
          </Button>
          <Button
            className={classes.buttons}
            color="primary"
            component={Link}
            to="/admin/team/batches"
            variant="contained"
          >
            View all batches
          </Button>
        </div>
      </div>
      <LinearProgress
        value={(completedBatches.length / batchOperationIds.length) * 100}
        variant="determinate"
      />
    </>
  )
}

const useCompletionStyles = makeStyles(theme => ({
  notification: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  close: {
    padding: theme.spacing(0.5),
  },
}))

const CompletionNotification = ({results, type, onClose, batch_id}) => {
  const classes = useCompletionStyles()
  const hasFailures = results.failed > 0

  const notice = hasFailures
    ? `Your upload finished processing, but we were unable to process some ${pluralize(type)}.`
    : "Your upload successfully finished!"
  const Icon = hasFailures ? FailedIcon : SuccessIcon

  return (
    <SnackbarContent
      action={[
        <Button
          color="inherit"
          component={Link}
          key="link"
          onClick={onClose}
          to={`/admin/${hasFailures ? "team/batches" : "contacts"}?batchId=${batch_id}`}
        >
          {hasFailures ? "Review" : "See results"}
        </Button>,
        <IconButton
          aria-label="close"
          className={classes.close}
          color="inherit"
          key="close"
          onClick={onClose}
          title="Dismiss"
          size="medium"
        >
          <CloseIcon />
        </IconButton>,
      ]}
      className={cx(classes.notification, {[classes.error]: hasFailures})}
      message={
        <span className={classes.content} id="batch-operation">
          <Icon className={classes.icon} />
          {notice}
        </span>
      }
    />
  )
}

CompletionNotification.propTypes = {
  batch_id: string.isRequired,
  onClose: func.isRequired,
  results: object.isRequired,
  type: string.isRequired,
}

WaitForBatchProcessing.propTypes = {
  batchId: string.isRequired,
  batchOperationIds: arrayOf(string).isRequired,
  onComplete: func.isRequired,
  onReset: func.isRequired,
}

export default WaitForBatchProcessing
