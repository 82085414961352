// This file was generated automatically from character-07.svg with the following command:
// ./svg-converter.js src/components/superhero/character-07.svg
import SuperheroSvg from "./superhero-svg"

const Character07 = props => (
  <SuperheroSvg viewBox="110 85 140 230" {...props}>
    <g id="Layer_9">
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M176.18,307.81l0.04-0.02c0.08-0.04,2.04-0.99,1.93-2.89c-0.08-1.43-1.24-2.84-3.54-4.3
  				l-0.07-0.03c-0.03-0.01-2.6-1.06-5.3-2.71c-3.5-2.13-5.64-4.27-6.19-6.18l-0.74,0.2c1.48,5.16,11.29,9.29,12.06,9.6
  				c2.41,1.53,2.96,2.66,3,3.34c0.06,1.02-1.01,1.79-1.48,2.03c-12.83,1.72-23.63-1.91-24.83-2.34c-1.51-2.02,0.11-8.24,0.87-10.51
  				l-0.73-0.49c-0.31,0.91-2.93,8.98-0.55,11.75l0.08,0.1l0.1,0.04c0.11,0.04,11.59,4.26,25.31,2.41L176.18,307.81z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M172.06,308.02l0.49-0.67c-0.91-1.05-1.38-2.15-1.43-3.25c-0.08-2.08,1.41-3.58,1.42-3.6
  				l-0.55-0.8c-0.07,0.07-1.75,1.77-1.66,4.29C170.39,305.4,170.97,306.75,172.06,308.02z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M170.81,305.02l-0.08-0.98c-16.61-0.82-20.45-3.52-20.48-3.54l-0.34,0.82
  				C150.06,301.43,153.83,304.18,170.81,305.02z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M170.94,303.66l0.14-0.93c-12.03-4.87-12.45-10.44-12.45-10.5l-0.79-0.1
  				C157.85,292.4,158.18,298.5,170.94,303.66z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M169.3,301.04c0.36-0.12,0.68-0.3,0.83-0.4l-0.43-0.89c-0.53,0.33-1.21,0.53-1.39,0.42
  				c0-0.01,0-0.02-0.01-0.05c-0.06-0.67-0.11-1.24-0.6-1.55c-0.35-0.22-0.85-0.25-1.95-0.3c-0.78-0.04-1.34-0.06-1.49-0.24
  				s-0.21-0.88-0.3-1.85c-0.07-0.81-0.31-1.33-0.72-1.55c-0.79-0.43-1.72,0.72-1.99,1.08l0.62,0.72c0.44-0.59,1-0.99,1.15-0.91
  				c0,0,0.11,0.11,0.15,0.62c0.25,2.77,0.44,3,2.66,3.1c0.79,0.04,1.47,0.07,1.6,0.18c0.03,0.06,0.06,0.38,0.08,0.65
  				c0.03,0.38,0.19,0.7,0.44,0.89C168.33,301.26,168.85,301.19,169.3,301.04z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M210,308.56l0.04-0.02c0.08-0.04,2.04-0.99,1.93-2.89c-0.08-1.43-1.24-2.84-3.54-4.3l-0.07-0.03
  				c-0.03-0.01-2.6-1.06-5.3-2.71c-3.5-2.13-5.64-4.27-6.19-6.18l-0.74,0.2c1.48,5.16,11.29,9.29,12.06,9.6
  				c2.41,1.53,2.96,2.66,3,3.34c0.06,1.02-1.01,1.79-1.48,2.03c-12.83,1.72-23.63-1.91-24.83-2.34c-1.51-2.02,0.11-8.24,0.87-10.51
  				l-0.73-0.49c-0.31,0.91-2.93,8.98-0.55,11.75l0.08,0.1l0.1,0.04c0.11,0.04,11.59,4.26,25.31,2.41L210,308.56z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M205.88,308.78l0.49-0.67c-0.91-1.05-1.38-2.15-1.43-3.25c-0.08-2.08,1.41-3.58,1.42-3.6
  				l-0.55-0.8c-0.07,0.07-1.75,1.77-1.66,4.29C204.21,306.15,204.78,307.51,205.88,308.78z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M204.63,305.77l-0.08-0.98c-16.61-0.82-20.45-3.52-20.48-3.54l-0.34,0.82
  				C183.88,302.18,187.65,304.93,204.63,305.77z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M204.76,304.42l0.14-0.93c-12.03-4.87-12.45-10.44-12.45-10.5l-0.79-0.1
  				C191.67,293.15,192,299.26,204.76,304.42z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M203.12,301.79c0.36-0.12,0.68-0.3,0.83-0.4l-0.43-0.89c-0.53,0.33-1.21,0.53-1.39,0.42
  				c0-0.01,0-0.02-0.01-0.05c-0.06-0.67-0.11-1.24-0.6-1.55c-0.35-0.22-0.85-0.25-1.95-0.3c-0.78-0.04-1.34-0.06-1.49-0.24
  				s-0.21-0.88-0.3-1.85c-0.07-0.81-0.31-1.33-0.72-1.55c-0.79-0.43-1.72,0.72-1.99,1.08l0.61,0.72c0.44-0.59,1-0.99,1.15-0.91
  				c0,0,0.11,0.11,0.15,0.62c0.25,2.77,0.44,3,2.66,3.1c0.79,0.04,1.47,0.07,1.6,0.18c0.03,0.06,0.06,0.38,0.08,0.65
  				c0.03,0.38,0.19,0.7,0.44,0.89C202.15,302.02,202.67,301.95,203.12,301.79z"
          />
        </g>
      </g>
      <path
        style={{fill: "#0A273F"}}
        d="M180.25,192.16l1.34,102.04l20.27,0.69c-0.84-35.46-1.68-70.91-2.52-106.37
  		c-7.06-9.82-14.12-19.64-21.17-29.46L180.25,192.16z"
      />
      <path
        style={{fill: "#123252"}}
        d="M178.87,227.27l-6.26,68.02l-25.16-1.28l25.16,1.28l-25.16-1.28l12.71-103.38
  		c-0.01-0.48,0-0.96,0.01-1.44c0.19-8.77,3.05-16.6,7.4-21.82l-0.02-0.18l29.18-8.6l2.07,24.23c0.17,1.21,0.28,2.45,0.34,3.71
  		l0.18,2l-0.15,0.03c0,0.13,0,0.26,0,0.39c0,0,0,0.2,0,0.39c-0.12,9.07-5.82,14.59-5.82,14.59c-4.3,4.17-9.5,4.81-11.32,4.95
  		c0.19-1.16,0.38-2.33,0.57-3.49"
      />
      <g>
        <g>
          <path
            style={{fill: "#D1B79F"}}
            d="M194.65,140.72c0.98,1.22-0.84-4.58,0.74-0.77c2.14,5.15,4.18,11.33,4.96,13.52
  				c0.46,1.28,0.97,2.99,2,6.42c1.33,4.44,1.23,4.38,1.74,5.79c1.31,3.62,2.5,5.48,2.17,5.72c-0.18,0.12-0.79-0.22-2.77-2.43
  				c-0.74,0.58-1.48,1.16-2.22,1.74c0.62-2.88,1.81-4.28,2.82-5.03c0.34-0.25,0.27-0.14,2.21-1.23c0.51-0.29,1-0.57,3.93-2.3
  				c1.02-0.6,2.06-1.18,3.07-1.8c2.37-1.46,2.58-1.75,3.33-1.81c1.4-0.11,3,0.64,3.57,1.96c0.09,0.2,0.47,1.16,0.04,1.83
  				c-0.22,0.35-0.54,0.5-0.74,0.59c-1.22,0.59-1.07,1.06-2.98,2.52c-0.96,0.73-1.26,0.81-1.71,1.12c-0.25,0.17-0.28,0.23-0.67,0.52
  				c-0.33,0.25-0.38,0.27-0.66,0.48c-0.28,0.21-0.55,0.45-1.06,0.88c-0.63,0.54-0.7,0.55-1.17,0.98c-0.23,0.21-0.25,0.24-0.49,0.45
  				c-0.34,0.3-0.55,0.46-0.9,0.74c-0.56,0.45-0.51,0.43-1.04,0.86c-0.59,0.47-0.88,0.71-1.15,0.89c-0.62,0.4-1.15,0.62-1.47,0.74
  				c-0.4,0.16-1.12,0.43-2.05,0.53c-0.82,0.08-2.02,0.22-3-0.42c-0.6-0.4-0.92-0.92-1.48-2.1c-1-2.12-1.57-3.84-1.64-4.06
  				c-0.56-1.69-0.32-0.71-2.03-5.28c-0.92-2.45-1.61-4.4-1.99-5.48c-0.89-2.54-1.96-5.69-1.92-5.7c0.04-0.02,1.33,3.48,1.32,3.48
  				c0,0-0.26-0.69-0.78-2.07c-0.4-1.05-1.02-2.69-2.02-5.34c-0.32-0.84-1.41-3.72-1.41-3.72c0,0-0.68-4.56,0.7-5.32
  				C191,136.98,193.35,139.1,194.65,140.72z"
          />
        </g>
      </g>
      <g>
        <defs>
          <rect
            id="SVGID_00000127018602364342875400000005626504397773006991_"
            x="134.32"
            y="66.75"
            width="198.77"
            height="173.21"
          />
        </defs>
        <clipPath id="0588c0a7-SVGID_00000176761923781460762050000005312140010081969799_">
          <use
            xlinkHref="#SVGID_00000127018602364342875400000005626504397773006991_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#5198D2",
          }}
          d="M155.03,123.58
  			c-3.99,8.19-11.97,5.01-11.97,5.01c2.39,0,4.39-3.19,6.39-8.19c2.61-6.55,2.19-21.67,2.19-21.67c0.08-1.57,0.07-7.88,6.27-7.46
  			s6.13,5.95,6.13,5.95l0.05,1.56l-4.34,3.65C159.74,102.42,157.22,119.08,155.03,123.58z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#CC5F64",
          }}
          d="M185.67,135.06
  			c-0.39-1.64,0.11-3.56,1.52-4.4c1.59-0.95,3.77-0.53,4.68,1.37c1.49,3.1,2.98,6.21,4.46,9.31l-5.98,3.66L185.67,135.06z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#D1B79F",
          }}
          d="M174.09,88.01
  			c-0.58,0-1.15,0.03-1.71,0.1c-0.34,15.16-12.53,23.1-14.84,24.46c2.01,8.18,8.66,14.18,16.55,14.18c9.49,0,17.18-8.67,17.18-19.37
  			C191.26,96.69,183.57,88.01,174.09,88.01z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#D1B79F",
          }}
          d="M191.26,107.33
  			c0,8.61-5.36,15.9-12.25,18.43l0,6.71l-6.33,0l0-5.82c-4.76-0.43-8.98-3.05-11.86-6.96c-0.61,0.49-1.36,0.79-2.18,0.79
  			c-2.07,0-3.76-1.9-3.76-4.24c0-1.86,1.06-3.43,2.54-4c0-0.02-0.01-0.04-0.01-0.06c0.12-0.07,0.24-0.15,0.35-0.22l33.28-7.64
  			C191.19,105.3,191.26,106.31,191.26,107.33z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#D1B79F",
          }}
          d="M165.79,102.38
  			l22.83-5.24c1.23,2.18,2.07,4.65,2.44,7.3L158.13,112C161.58,109.91,164.31,106.51,165.79,102.38z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#EB6669",
          }}
          d="M161.49,143.43
  			c-0.73-6.05,3.02-11.63,8.39-12.46l12.58-1.15c6.81-0.47,10.08,2.48,11.05,9.47l3.24,22.04l-32.64,0L161.49,143.43z"
        />
        <g style={{clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)"}}>
          <g>
            <path
              style={{fill: "#D1B79F"}}
              d="M172.23,152.58c-4.51,0.59-9.98,1.82-9.98,1.82c-0.75,0.17-2.3,0.51-4.2,0.88
  					c-0.85,0.17-1.55,0.3-2,0.38c0.61,1.31,1.23,2.62,1.84,3.93c1.18-2.54,2.37-5.08,3.55-7.63c1.89-4.07,3.79-8.14,5.68-12.21
  					c0.43-0.93,0.87-1.86,1.3-2.79c0.37-0.8,0.59-1.6,0.49-2.34c-0.08-0.57-0.5-1.23-1.06-1.54c-1.38-0.74-3.56,0.56-4.74,1.92
  					c-0.25,0.29-0.3,0.4-1.48,2.92c0,0-1.24,2.65-2.53,5.41c-0.66,1.42-0.58,1.21-0.96,2.04c-1.42,3.04-2.19,4.96-3.74,8.81
  					c0,0-0.3,0.76-0.97,2.98c-0.28,0.94-0.42,1.47-0.39,2.17c0.02,0.52,0.06,1.63,0.79,2.17c0.55,0.4,1.25,0.32,1.61,0.25
  					c1.82-0.35,3.65-0.69,5.47-1.04c2.9-0.55,5.81-1.1,8.71-1.66c0.67-0.13,1.33-0.25,2-0.38c1.37-0.26,2.85-2.2,2.23-5.79
  					C173.72,152.19,172.57,152.54,172.23,152.58z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#EB6669",
          }}
          d="M164.12,161.32
  			c10.89-0.06,21.77-0.12,32.66-0.18l0.6,5.76l-33.19,3.31C164.17,167.25,164.14,164.29,164.12,161.32z"
        />

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#0D1E36",
          }}
          cx="182.09"
          cy="103.01"
          rx="1.37"
          ry="1.54"
        />
        <g style={{clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M181.34,99.61c0.06-0.06,0.12-0.12,0.19-0.17c-0.22,0.19-0.09,0.07-0.02,0.02
  					c0.04-0.03,0.09-0.06,0.13-0.08c0.05-0.03,0.1-0.06,0.16-0.08c0.02-0.01,0.05-0.02,0.07-0.03c-0.22,0.11-0.12,0.05-0.06,0.03
  					c0.13-0.05,0.27-0.07,0.4-0.1c-0.23,0.05-0.12,0.02-0.06,0.01c0.05,0,0.09-0.01,0.14-0.01c0.08,0,0.16,0,0.24,0.01
  					c0.06,0.01,0.2,0.05-0.02-0.01c0.04,0.01,0.08,0.01,0.12,0.02c0.1,0.02,0.2,0.05,0.29,0.08c0.04,0.01,0.09,0.03,0.13,0.05
  					c0.07,0.03,0.06,0.02-0.04-0.02c0.03,0.01,0.06,0.03,0.09,0.04c0.11,0.05,0.22,0.12,0.33,0.18c0.33,0.2,0.81,0.08,1-0.26
  					c0.19-0.35,0.09-0.78-0.26-1c-0.8-0.49-1.8-0.73-2.72-0.41c-0.41,0.14-0.85,0.37-1.14,0.71c-0.25,0.28-0.3,0.75,0,1.03
  					C180.59,99.86,181.07,99.91,181.34,99.61L181.34,99.61z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#5198D2",
          }}
          d="M172.38,88.11
  			c-8.68,0.97-15.46,9.23-15.47,19.27c0,1.8,0.22,3.54,0.63,5.19C159.84,111.21,172.04,103.27,172.38,88.11z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#5198D2",
          }}
          d="M172.35,88.23
  			c0,0,4.75,5.99,14.34,6C186.69,94.23,181.23,86.93,172.35,88.23z"
        />

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#0D1E36",
          }}
          cx="170.35"
          cy="105.22"
          rx="1.37"
          ry="1.54"
        />
        <g style={{clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M170.55,100.13c-0.28-0.1-0.59-0.13-0.88-0.13c-0.63-0.01-1.28,0.16-1.8,0.52
  					c-0.43,0.3-0.78,0.67-1.07,1.11c-0.21,0.32-0.07,0.82,0.26,1c0.36,0.19,0.77,0.08,1-0.26c0.03-0.05,0.07-0.11,0.11-0.16
  					c0.05-0.06,0.04-0.05-0.03,0.04c0.02-0.02,0.04-0.05,0.06-0.07c0.07-0.09,0.15-0.17,0.23-0.25c0.04-0.04,0.08-0.07,0.12-0.11
  					c0.15-0.12-0.1,0.07,0,0c0.07-0.05,0.14-0.09,0.21-0.14c0.08-0.05,0.16-0.08,0.24-0.12c-0.24,0.12,0.02,0,0.09-0.02
  					c0.08-0.02,0.15-0.04,0.23-0.06c0.04-0.01,0.08-0.01,0.11-0.02c-0.24,0.06-0.14,0.02-0.07,0.01c0.13-0.01,0.26-0.02,0.38-0.01
  					c0.06,0,0.11,0.01,0.17,0.01c0.14,0.01-0.2-0.03-0.1-0.01c0.02,0,0.04,0.01,0.06,0.01c0.09,0.02,0.19,0.04,0.28,0.07
  					c0.36,0.13,0.81-0.14,0.9-0.51C171.15,100.62,170.93,100.26,170.55,100.13L170.55,100.13z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#D9D8D6",
          }}
          d="M182.82,142.38
  			l5.88,2.97c0.59,0.3,0.78,0.88,0.48,1.47l-6.94,13.59c-0.3,0.58-0.89,0.77-1.48,0.47l-5.88-2.97c-0.59-0.3-0.78-0.88-0.48-1.47
  			l6.94-13.59C181.64,142.28,182.23,142.09,182.82,142.38z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#D1B79F",
          }}
          d="M178.05,151.25
  			c0,0-0.43,0.48-0.21,0.67c0.22,0.2,1.14,0.28,1.86-0.54c0.48-0.54,2.84-2.36,5.11-1.2c0,0,2.3,1.93,0.69,4.47
  			c-1.2,1.91-2.57,3.16-5.77,3.71c-2.99,0.51-4.71-0.18-5.71-0.24c-3.22,0.64-6.43,1.27-9.65,1.91c-0.47,0.09-0.94,0.19-1.4,0.28
  			l-0.77-5.96c3.28-0.65,6.56-1.3,9.85-1.95c0.34-0.07,0.68-0.13,1.01-0.2c0.47-0.87,1.66-2.85,3.2-3.71c0,0,0.68-0.51,1.76-0.5
  			C179.11,148.01,179.19,150.09,178.05,151.25z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#D9D8D6",
          }}
          d="M179.31,146.82
  			l-2.45,4.64c0,0,1.3,1.39,2.78-0.08C180.77,150.27,179.31,146.82,179.31,146.82z"
        />
        <polyline
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#D1B79F",
          }}
          points="
  			179.02,129.39 178.02,135.66 172.68,129.97 		"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#FFFFFF",
          }}
          d="M172.35,113.45
  			c0.1-0.2,0.34-0.24,0.5-0.11c0.39,0.31,1.1,0.97,2.12,1.19c1.38,0.31,2.58-0.31,2.87,0.09c0.16,0.23-0.04,0.5-0.41,0.86
  			c-0.38,0.35-1.53,1.05-2.79,0.78c-1.33-0.28-2.33-2.02-2.33-2.63C172.31,113.56,172.32,113.5,172.35,113.45z"
        />
        <g style={{clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M176.1,109.65c0.81-0.3,1.61-0.61,2.42-0.91c0.22-0.08,0.36-0.33,0.22-0.55
  					c-0.71-1.19-1.41-2.39-2.12-3.58c-0.1-0.17-0.2-0.34-0.3-0.51c-0.25-0.42-0.89-0.04-0.65,0.38c0.71,1.19,1.41,2.39,2.12,3.58
  					c0.1,0.17,0.2,0.34,0.3,0.51c0.07-0.18,0.15-0.37,0.22-0.55c-0.81,0.3-1.61,0.61-2.42,0.91
  					C175.45,109.1,175.65,109.82,176.1,109.65L176.1,109.65z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "#EB6669",
          }}
          d="M163.44,133.54
  			c1.03-1.29,2.74-1.92,4.13-1.1c1.57,0.93,2.42,3.18,1.48,5.1c-1.54,3.14-3.08,6.27-4.62,9.41l-5.97-3.44L163.44,133.54z"
        />

        <path
          style={{
            clipPath: "url(#SVGID_00000176761923781460762050000005312140010081969799_)",
            fill: "none",
            stroke: "#F8D73E",
            strokeMiterlimit: "10",
          }}
          d="
  			M172.69,158.28c0,0-4.76-2.32-1.27-5.98"
        />
      </g>
      <g>
        <path
          style={{fill: "#D1B79F"}}
          d="M222.63,154.15c0,0-0.14,0.55,0.14,0.63c0.28,0.09,1.11-0.17,1.33-1.11
  			c0.15-0.62,1.28-2.98,3.78-2.82c0,0,2.89,0.78,2.75,3.46c-0.11,2.01-0.66,3.54-3.13,5.14c-2.3,1.5-4.11,1.54-4.99,1.84
  			c-2.44,1.68-4.87,3.36-7.31,5.03c-0.35,0.24-0.71,0.49-1.06,0.73l-3.53-4.67c2.49-1.71,4.98-3.43,7.46-5.14
  			c0.26-0.18,0.51-0.35,0.77-0.53c-0.02-0.89,0.04-2.96,0.95-4.22c0,0,0.33-0.66,1.26-1.04
  			C221.98,151.08,223.05,152.78,222.63,154.15z"
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Character07
