import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {debounce} from "lodash/function"
import {arrayOf, func, object, shape, string} from "prop-types"
import {Component} from "react"
import {FaUser as UserIcon} from "react-icons/fa"
import {MdExpandMore as ExpandIcon} from "react-icons/md"

import {fullName} from "lib/names"

import AccessControlled from "../access-control/access-controlled"
import CopyTextInput from "../copy-text-input/copy-text-input"
import DangerButton from "../danger-button/danger-button"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import UploadedImage from "../uploaded-image/uploaded-image"
import {getUserRoles} from "./permissions-helpers"
import UserPermissionsFormGroup from "./user-permissions-form-group"

export class UsersListRow extends Component {
  state = {
    isDialogOpen: false,
    selectedPermissions: this.props.user.permissions,
  }

  componentWillUnmount() {
    this.onSave.flush()
  }

  onOpenDialog = () => this.setState({isDialogOpen: true})

  onCloseDialog = () => this.setState({isDialogOpen: false})

  onDeleteUser = () => {
    this.props.onDeleteUser(this.props.user.id)
    this.setState({isDialogOpen: false})
  }

  onChange = selectedPermissions => {
    this.setState({selectedPermissions}, this.onSave)
  }

  onSave = debounce(async () => {
    const {onUpdateUser, user} = this.props
    const {selectedPermissions} = this.state

    await onUpdateUser(user.id, {permissions: selectedPermissions})
  }, 2000)

  render() {
    const {classes, user} = this.props
    const {selectedPermissions} = this.state
    const roles = getUserRoles(selectedPermissions)

    return (
      <>
        <Accordion
          className={classes.expansionPanel}
          key={`ulr-exp-${user.id}`}
          sx={{"& .MuiAccordionSummary-expandIconWrapper": {fontSize: "1.5rem"}}}
        >
          <AccordionSummary expandIcon={<ExpandIcon />}>
            <div className={classes.userAvatar}>
              {user.isAvatarUploaded ? (
                <UploadedImage
                  alt={user.nameFirst}
                  src={`${process.env.REACT_APP_API_URL}/users/${user.id}/avatar`}
                  variant="thumb"
                />
              ) : (
                <UserIcon />
              )}
            </div>
            <div>
              <Typography variant="subtitle2">
                {fullName(user)}
                {roles.map(role => (
                  <Chip
                    className={classes.userRoleChip}
                    key={role}
                    label={role}
                    size="small"
                    variant="outlined"
                  />
                ))}
              </Typography>
              <Typography>{user.email}</Typography>
              {user.isFromTower && (
                <Typography sx={{fontSize: "0.9em", color: "#f2654b"}}>
                  non-FI superadmin
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionContent}>
            <CopyTextInput
              helperText="To reference this user in API requests, please use the ID provided above"
              label="User ID"
              value={user.id}
            />
            <UserPermissionsFormGroup
              gridSize="3x3"
              onChange={this.onChange}
              selectedPermissions={selectedPermissions}
              userId={user.id}
            />
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <AccessControlled requiredPermissions="team:users">
              <DangerButton onClick={this.onOpenDialog}>Remove From The Team</DangerButton>
            </AccessControlled>
          </AccordionActions>
        </Accordion>

        <UltraConfirmDialog
          confirmationText={this.props.user.nameFirst || "Delete"}
          key={`ulr-ucd-${user.id}`}
          onClose={this.onCloseDialog}
          onConfirm={this.onDeleteUser}
          open={this.state.isDialogOpen}
          recordType="user"
        />
      </>
    )
  }
}

UsersListRow.propTypes = {
  classes: object.isRequired,
  onDeleteUser: func.isRequired,
  onUpdateUser: func.isRequired,
  user: shape({
    id: string.isRequired,
    nameFirst: string,
    nameLast: string,
    email: string.isRequired,
    avatar: string,
    permissions: arrayOf(string),
  }).isRequired,
}

const styles = {
  expansionContent: {
    display: "flex",
    flexDirection: "column",
  },
  userRoleChip: {
    marginLeft: 10,
  },
  userAvatar: {
    fontSize: 35,
    display: "flex",
    marginRight: 20,
    width: 42,
    height: 42,
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      borderRadius: "50%",
      width: "100%",
      height: "100%",
    },
  },
}

export default withStyles(styles)(UsersListRow)
