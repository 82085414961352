import {arrayOf, object, shape, string} from "prop-types"

export const contentBlockPropType = shape({
  grid: object.isRequired,
  id: string.isRequired,
  slug: string.isRequired,
  type: string.isRequired,
})

export const containerPropType = shape({
  _type: string.isRequired,
  id: string.isRequired,
  layoutBasis: string.isRequired,
  contentBlocks: arrayOf(contentBlockPropType).isRequired,
})
