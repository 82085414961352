import * as Sentry from "@sentry/browser"

/*global initCardupdatr*/
const cardOnFile = async config => {
  Sentry.addBreadcrumb({
    message: "COFF initialized (but not loaded)",
    level: "debug",
    data: config,
  })

  await loadLib()
  initializer(config)
}

const domain = process.env.REACT_APP_CARDUPDATR_DOMAIN

const loadLib = () =>
  new Promise(resolve => {
    if (document.querySelector(`script[src="https://${domain}/cardupdatr-client.js"]`))
      return resolve(isAvailable())

    const source = document.createElement("script")

    source.src = `https://${domain}/cardupdatr-client.js`
    source.async = true
    source.onload = resolve
    document.body.appendChild(source)
  })

const isAvailable = (count = 0) => {
  // Gives the library 60 seconds to load
  if (count > 600) return Promise.reject(new Error("Couldn't initialize card on file widget."))
  else if (window.initCardupdatr) return Promise.resolve()

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(isAvailable(count + 1))
    }, 100)
  })
}

const initializer = config => {
  const {
    teamId,
    teamName,
    financialInstitution,
    contactId,
    pageId,
    journeyId,
    slug,
    cardDescription,
    merchantSelectionMessage,
    finalMessage,
    buttonColor,
    widgetDiv,
  } = config

  Sentry.addBreadcrumb({
    message: "COFF initialized and loaded",
    level: "debug",
    data: config,
  })

  if (!financialInstitution) throw new Error("No financial institution set for card on file widget")

  widgetDiv.innerHTML = ""

  initCardupdatr({
    user: {
      custom_data: {
        digital_onboarding: {
          team_id: teamId,
          contact_id: contactId,
          page_id: pageId,
          journey_id: journeyId,
          slug,
        },
      },
    },
    config: {
      app_container_id: slug,
      hostname: domain,
      financial_institution: financialInstitution,
    },
    style_template: {
      name: teamName,
      page_title: teamName,
      final_message: finalMessage,
      button_color: buttonColor,
      border_color: buttonColor,
      drop_shadow: false,
      dynamic_height: true,
      ...chooseMessage(teamName, merchantSelectionMessage, cardDescription),
    },
  })
}

// Legacy value is card_description, new one is merchant_selection_message
function chooseMessage(teamName, merchantSelectionMessage, cardDescription) {
  if (merchantSelectionMessage) return {merchant_selection_message: merchantSelectionMessage}
  else if (cardDescription) return {card_description: cardDescription}
  else
    return {
      merchant_selection_message: `Select the sites below to make your ${teamName} card the primary payment method.`,
    }
}

export default cardOnFile
