import {filtersMismatchInsights} from "../team-insights/team-insights-reducer"

export const actions = {
  SET_FILTER_PARAMS: "SET_FILTER_PARAMS",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_ADVANCED_TEAM_INSIGHTS: "SET_ADVANCED_TEAM_INSIGHTS",
  SET_SOCKET_OPTION: "SET_SOCKET_OPTION",
}

const transformFilterParams = ({
  endDate,
  startDate,
  objective,
  attributionModel,
  templateStatus,
}) => {
  let filterParams = {}

  if (startDate) filterParams = {...filterParams, startDate}
  if (endDate) filterParams = {...filterParams, endDate}
  if (objective?.length > 0) filterParams = {...filterParams, objective}
  if (templateStatus === TemplateStatuses.Live || templateStatus === TemplateStatuses.Archived)
    filterParams = {...filterParams, templateStatus}
  if (attributionModel === "premier") filterParams = {...filterParams, attributionModel}

  return filterParams
}

export const TemplateStatuses = {All: "all", Live: "live", Archived: "archived"}
export const ADVANCED_TEAM_INSIGHTS_FILTER_INITIAL_VALUES = {
  attributionModel: "standard",
  objective: [],
  templateStatus: TemplateStatuses.All,
  startDate: "",
  endDate: "",
}

export const initialState = {
  filterParams: transformFilterParams(ADVANCED_TEAM_INSIGHTS_FILTER_INITIAL_VALUES),
  socketOptions: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_FILTER_PARAMS:
      return {...state, filterParams: transformFilterParams(action)}
    case actions.SET_IS_LOADING:
      return {...state, [action.name]: action.value}
    case actions.SET_ADVANCED_TEAM_INSIGHTS:
      // We short circuit setting/updating team insights when the filters do not match to
      // prevent two (or more) open tabs/windows on the same insights team from
      // effecting one another.
      // See https://trello.com/c/BDrLqJcI
      if (filtersMismatchInsights(state, action.args)) return state

      return {...state, [action.name]: action.insightsCache}
    case actions.SET_SOCKET_OPTION:
      return {...state, socketOptions: {...state.socketOptions, [action.name]: action.value}}
    default:
      return state
  }
}
