import {connectRouter} from "connected-react-router"
import {combineReducers} from "redux"
import {reducer as form} from "redux-form"

import auditLogs from "../components/audit-logs/audit-logs-reducer"
import contact from "../components/contacts/contact-reducer"
import surveys from "../components/content-block-editor/plugins/survey/surveys-reducer"
import exporter from "../components/export-button/exporter-reducer"
import fileBrowser from "../components/file-browser/file-browser-reducer"
import forgotPassword from "../components/forgot-password/forgot-password-reducer"
import journey from "../components/journeys/journey-reducer"
import landingPage from "../components/landing-page/landing-page-reducer"
import resetPassword from "../components/reset-password/reset-password-reducer"
import clients from "../components/teams/clients-reducer"
import emailSettings from "../components/teams/email-settings-reducer"
import pgpKeys from "../components/teams/pgp-keys-reducer"
import sshKeys from "../components/teams/ssh-keys-reducer"
import templates from "../components/templates-list/templates-reducer"
import objectives from "../components/templates/objectives-reducer"
import template from "../components/templates/template-reducer"
import a11y from "./accessability"
import app from "./app"
import loadingStates from "./loading-states"
import session from "./session"

export default history =>
  combineReducers({
    a11y,
    app,
    auditLogs,
    clients,
    contact,
    emailSettings,
    exporter,
    fileBrowser,
    forgotPassword,
    form,
    pgpKeys,
    journey,
    landingPage,
    loadingStates,
    objectives,
    resetPassword,
    router: connectRouter(history),
    session,
    sshKeys,
    surveys,
    template,
    templates,
  })
