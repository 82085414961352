import {push} from "connected-react-router"

import {createJourney, createOrUpdateLandingPage, fetchLandingPage} from "lib/api"
import apiActionCreator from "lib/api-action-creator"
import {toJourneyAndContact} from "lib/to-journey-and-contact"

import {loadingStateCompleted} from "../../actions/loading-state-actions"

export const LANDING_PAGE_SET = "LANDING_PAGE_SET"
export const LANDING_PAGE_SUCCESS = "LANDING_PAGE_SUCCESS"
export const LANDING_PAGE_FAILED = "LANDING_PAGE_FAILED"
export const LANDING_PAGE_RESET = "LANDING_PAGE_RESET"
export const LANDING_PAGE_CLEARED = "LANDING_PAGE_CLEARED"

export const getLandingPage = templateId => dispatch =>
  fetchLandingPage(templateId)
    .catch(error => {
      if (error.status === 404) return {}
      else throw error
    })
    .then(landingPage => {
      dispatch({type: LANDING_PAGE_SET, payload: landingPage})
      dispatch(loadingStateCompleted(LANDING_PAGE_SET))
    })

export const submitLandingPage = apiActionCreator(LANDING_PAGE_SET, createOrUpdateLandingPage)
export const clearLandingPage = () => ({type: LANDING_PAGE_CLEARED})

export const onLpCreateJourney = (templateId, params, kioskMode) => dispatch => {
  const attrs = toJourneyAndContact(params)

  createJourney(templateId, {...attrs, source: "landing"})
    .then(journey => {
      dispatch({type: LANDING_PAGE_SUCCESS})
      setTimeout(() => {
        if (kioskMode) dispatch({type: LANDING_PAGE_RESET})
        else dispatch(push(`/journeys/${journey.slug}`))
      }, 3000)
    })
    .catch(() => dispatch({type: LANDING_PAGE_FAILED}))
}
