import {Route, Switch} from "react-router-dom"

import RewardSetList from "components/reward/reward-set-list"

import ExistingRewardSet from "./existing-reward-set"
import NewRewardSet from "./new-reward-set"
import RewardSetFormContextProvider from "./reward-set-form-context-provider"
import RewardsContextProvider from "./rewards-context-provider"

export const RewardRouter = () => (
  <RewardsContextProvider>
    <RewardSetFormContextProvider>
      <Switch>
        <Route component={NewRewardSet} path="/admin/rewards/reward-sets/new" />
        <Route component={ExistingRewardSet} path="/admin/rewards/reward-sets/:rewardSetId" />
        <Route component={RewardSetList} path="/admin/rewards/reward-sets" />
      </Switch>
    </RewardSetFormContextProvider>
  </RewardsContextProvider>
)

export default RewardRouter
