import {Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {number, shape, string} from "prop-types"
import React, {useEffect} from "react"
import {animated, useSpringValue} from "react-spring"

const CircularWrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  left: 0,
  position: "absolute",
  top: 0,
  width: "100%",
})
const LinearWrapper = styled("div")({display: "flex"})
const CenterText = styled("div")(({progressType, branding}) => ({
  textAlign: progressType === "linear" ? "left" : "center",
  "& .MuiTypography-root.rewards-widget-value": {
    lineHeight: "normal",
    color: branding.valueTextColor,
    fontFamily: branding.valueFontFamily,
    fontSize: branding.valueFontSize,
  },
  "& .MuiTypography-root.rewards-widget-caption": {
    lineHeight: "normal",
    color: branding.captionTextColor,
    fontFamily: branding.captionFontFamily,
    fontSize: branding.captionFontSize,
  },
  "& .MuiTypography-subtitle1": {fontSize: "1em"},
}))

const RewardText = styled(animated(Typography))({fontSize: "32px", fontWeight: 100})

const ProgressData = ({caption, progressType, slug, unit, value, branding}) => {
  const accrued = useSpringValue(0, {delay: 500})
  const Wrapper = progressType === "linear" ? LinearWrapper : CircularWrapper

  useEffect(() => {
    accrued.start(value)
  }, [accrued, value])

  return (
    <Wrapper role="figure" aria-labelledby={`reward-subtitle-${slug}`}>
      <CenterText progressType={progressType} branding={branding}>
        <RewardText className="rewards-widget-value" variant="h5">
          {accrued.to(value => `${Math.round(value)} ${unit}`)}
        </RewardText>
        <Typography className="rewards-widget-caption" id={`reward-subtitle-${slug}`}>
          {caption}
        </Typography>
      </CenterText>
    </Wrapper>
  )
}

ProgressData.propTypes = {
  caption: string,
  progressType: string,
  slug: string.isRequired,
  unit: string,
  value: number,
  branding: shape({
    valueFontFamily: string,
    valueFontSize: string,
    valueTextColor: string,
    captionFontFamily: string,
    captionFontSize: string,
    captionTextColor: string,
    inProgressColor: string,
    completedColor: string,
    trackColor: string,
    widgetBackgroundColor: string,
  }),
}

export default ProgressData
