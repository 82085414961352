import {Button} from "@mui/material"
import {omit, pick} from "lodash"
import debounce from "lodash/debounce"
import memoize from "memoize-one"
import {bool, func, object, shape, string} from "prop-types"
import {useCallback, useEffect} from "react"
import {
  MdKeyboardArrowRight as NextPageIcon,
  MdKeyboardArrowLeft as PreviousPageIcon,
} from "react-icons/md"
import {useDispatch} from "react-redux"
import {Link} from "react-router-dom"

import {compileCss} from "lib/custom-css"
import {getRawContentFromEditorState} from "lib/draft-js/editor-helpers"
import useAnalytics from "lib/hooks/use-analytics"

import {setA11yMessage} from "../../actions/accessability-actions"
import BrandedButton from "../branded-button/branded-button"
import ContentBlockEditor from "../content-block-editor/content-block-editor"
import MuiIcon from "../mui-icon"
import {previewContext} from "../templates/preview-context"
import {addConfirmedPage} from "./journey-actions"
import PageCompletionDescriptor from "./page-completion-descriptor"
import PageConfirm from "./page-confirm"

const createCompiledCustomCss = memoize((scope, source) => {
  try {
    return compileCss(scope, source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const Page = ({
  id,
  analyticsCache,
  journeyId,
  navigationTitle,
  completionCondition,
  completionMessage,
  content,
  classes,
  nextPage,
  prevPage,
  swipeStatus,
  pageUrlGenerator,
  onChange,
  onEditorFocus,
  onUpdatePage,
  isContentLibrary,
  isFocused,
  isEditable,
  isPreviewMode,
  isConfirmed,
  styles,
  css,
  ...currentPage
}) => {
  const dispatch = useDispatch()
  const {track} = useAnalytics()

  useEffect(() => {
    dispatch(setA11yMessage(`Navigated to the ${navigationTitle} page`))
  }, [dispatch, navigationTitle])

  useEffect(() => {
    if (id) track("page_viewed", {pageId: id})
  }, [id, track])

  const onConfirmPage = () => {
    if (journeyId) dispatch(addConfirmedPage(id))
  }

  const onEditorChange = editorState => {
    onChange(id, {content: getRawContentFromEditorState(editorState)})
  }
  // FIXME ignoring react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnEditorChange = useCallback(debounce(onEditorChange, 2000), [])

  useEffect(
    () => () => {
      if (debouncedOnEditorChange) debouncedOnEditorChange.flush()
    },
    [id, debouncedOnEditorChange]
  )

  const compiledCss = createCompiledCustomCss(`.page-root-${id}`, css || "")

  return (
    <div
      className={`page-content page-root-${id} ${classes.content} ${swipeStatus}`}
      data-testid="main-content"
      id="main-content"
      style={omit(styles || {}, ["gap"])}
    >
      <style>{compiledCss}</style>
      <PageCompletionDescriptor
        className={classes.journeyCompletionDescriptor}
        completionCondition={completionCondition}
        completionMessage={completionMessage}
        content={content}
        contentBlocks={currentPage?.contentBlocks ?? []}
        onUpdatePage={onUpdatePage}
        pageId={id}
        pagesCompleted={analyticsCache?.pagesCompleted}
        readOnly={isPreviewMode || !isEditable}
      />
      <ContentBlockEditor
        initialContainer={{...currentPage, id, styles: pick(styles || {}, ["gap"])} || {}}
        containerId={id}
        initialMode={isEditable ? "Edit" : "View"}
      />

      {completionCondition === "confirm" && (
        <PageConfirm
          addConfirmedPage={onConfirmPage}
          isConfirmed={isConfirmed}
          journeyId={journeyId}
          pageId={id}
        />
      )}
      <div className={classes.pageButtons}>
        {prevPage && (
          <Button
            classes={{root: classes.prevPageButton}}
            component={Link}
            size="large"
            to={`${pageUrlGenerator(prevPage.slug)}${isPreviewMode ? "#preview" : ""}`}
            variant="contained"
          >
            <MuiIcon
              className={classes.prevPageButtonIcon}
              icon={
                <PreviousPageIcon aria-label={`Navigate to ${prevPage.navigationTitle} page`} />
              }
            />
          </Button>
        )}
        {nextPage && (
          <BrandedButton
            classes={{root: classes.nextPageButton}}
            color="primary"
            component={Link}
            to={`${pageUrlGenerator(nextPage.slug)}${isPreviewMode ? "#preview" : ""}`}
            variant="contained"
          >
            {nextPage.cta || nextPage.navigationTitle}
            <MuiIcon
              className={classes.nextPageButtonIcon}
              icon={<NextPageIcon aria-hidden="true" />}
            />
          </BrandedButton>
        )}
      </div>
    </div>
  )
}

Page.propTypes = {
  analyticsCache: object,
  classes: object,
  completionCondition: string,
  completionMessage: string,
  content: object,
  content_variables: object,
  css: string,
  cta: string,
  id: string,
  isConfirmed: bool,
  isContentLibrary: bool,
  isEditable: bool,
  isFocused: bool,
  isPreviewMode: bool,
  journeyId: string,
  navigationTitle: string,
  nextPage: shape({
    cta: string,
    navigationTitle: string,
  }),
  onChange: func,
  onEditorFocus: func,
  onUpdatePage: func,
  pageUrlGenerator: func,
  prevPage: shape({
    cta: string,
    navigationTitle: string,
  }),
  slug: string.isRequired,
  styles: object,
  swipeStatus: string,
}

export default previewContext(Page)
