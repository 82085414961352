import makeStyles from "@mui/styles/makeStyles"
import {func, object, string} from "prop-types"
import {useState} from "react"

import Batch from "./batch"
import WaitForBatchProcessing from "./wait-for-batch-processing"

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    minHeight: 370,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
  },
  main: {
    textAlign: "center",
    "& p": {
      fontSize: 24,
      marginBottom: theme.spacing(3),
    },
  },
}))

const Upload = ({data, onContinue, templateId, onReset}) => {
  const [step, setStep] = useState("batch")
  const [batchOperationIds, setBatchOperationIds] = useState(null)
  const [batchId, setBatchId] = useState(null)
  const classes = useStyles()
  const {batchType, file, totalNumLines, fieldMap} = data

  if (!file) return null

  const onCompleteBatchCreation = ({batchOperationIds: results, batchId: newBatchId}) => {
    setBatchOperationIds(results)
    setBatchId(newBatchId)
    setStep("wait")
  }

  const onCompleteBatchWaiting = results => {
    onContinue({...data, ...results}, batchId)
  }

  let child = null

  switch (step) {
    case "batch":
      child = (
        <Batch
          additionalAttrs={{template_id: templateId}}
          batchType={batchType}
          fieldMap={fieldMap}
          file={file}
          onComplete={onCompleteBatchCreation}
          totalNumLines={totalNumLines}
        />
      )
      break

    case "wait":
      child = (
        <WaitForBatchProcessing
          batchId={batchId}
          batchOperationIds={batchOperationIds}
          onComplete={onCompleteBatchWaiting}
          onReset={onReset}
        />
      )
      break
    default:
      child = null
      break
  }

  return (
    <div className={classes.container}>
      <div className={classes.main}>{child}</div>
    </div>
  )
}

Upload.propTypes = {
  data: object.isRequired,
  onContinue: func.isRequired,
  onReset: func.isRequired,
  templateId: string,
}

export default Upload
