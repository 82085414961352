const location = window.location.pathname.split("/").filter(Boolean)[0] || "admin"

const ACCESS_TOKEN = `api:access-token:${location}`
const ACCESS_TOKEN_EXPIRATION = `api:expiration:${location}`
const REFRESH_TOKEN = `api:refresh-token:${location}`

export const setAccessToken = (
  storage,
  {accessToken, expiresIn, refreshToken, insertedAt},
  rememberMe = false
) => {
  storage.setItem(ACCESS_TOKEN, accessToken)
  storage.setItem(ACCESS_TOKEN_EXPIRATION, Date.parse(insertedAt) + expiresIn * 1000)

  if (rememberMe) storage.setItem(REFRESH_TOKEN, refreshToken)
}

export const getAccessToken = storage => ({
  accessToken: storage.getItem(ACCESS_TOKEN),
  expiration: storage.getItem(ACCESS_TOKEN_EXPIRATION),
  refreshToken: storage.getItem(REFRESH_TOKEN),
  location,
})

export const clearAccessToken = storage => {
  storage.removeItem(ACCESS_TOKEN)
  storage.removeItem(ACCESS_TOKEN_EXPIRATION)
  storage.removeItem(REFRESH_TOKEN)
}
