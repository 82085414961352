import {Tooltip as MaterialTooltip, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import makeStyles from "@mui/styles/makeStyles"
import {object} from "prop-types"
import {useCallback, useEffect} from "react"
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  LabelList,
  ResponsiveContainer,
  Surface,
  XAxis,
} from "recharts"

import {NAMED_INSIGHTS_COLORS} from "components/template-insights/insights-helpers"

import {fetchAdvancedInsightsGapAnalysis} from "lib/api"

import Expandable from "../expandable/expandable"
import {useAdvancedTeamInsights} from "./advanced-team-insights-context"

const {coral, gray, lightBlue, lightGray, navyBlue} = NAMED_INSIGHTS_COLORS

const useStyles = makeStyles(theme => ({
  chartMain: {
    marginTop: theme.spacing(2),
    maxWidth: "80%",
    overflowX: "scroll",
    overflowY: "hidden",
    position: "relative",
  },
  chartSidebar: {
    marginTop: theme.spacing(2),
    maxWidth: "20%",
    padding: "0 18px 0 36px",
  },
  chartWrapper: {alignItems: "center", display: "flex"},
  expandableLabelRight: {display: "flex", justifyContent: "flex-end"},
  legendListItem: {
    color: "#000",
    display: "block",
    margin: "0 4px 5px 0",
    textIndent: "-18px",
    verticalAlign: "middle",
  },
  tooltipList: {color: "#000", listStyle: "none", margin: 0, padding: 0},
  tooltipListItem: {margin: 0, padding: "4px 0"},
  tooltipWrapper: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    display: "block",
    margin: 0,
    padding: "10px",
    whiteSpace: "nowrap",
  },
}))

const GAP_CHART_SIDEBAR = [
  [
    lightGray,
    "Eligible for Completion",
    "The % of contacts that have not completed the objective but have been enrolled in a campaign with it.",
  ],
  [
    lightBlue,
    "Completed During/After campaign",
    "The % of contacts that completed the objective after being enrolled in a campaign with it.",
  ],
  [
    navyBlue,
    "Completed Before Campaign Enrollment",
    "The % of contacts that completed the objective before being enrolled in a campaign with it.",
  ],
  [
    coral,
    "Completed but not Presented to a Contact in a Campaign",
    "The % of contacts that completed the objective but have never been enrolled in a campaign with it.",
  ],
]

// NB: Labels for tooltips come from Gondor, while colors are specified in Hendricks,
// with no unique machine-readable key provided to link them.  Additionally, the tooltip
// labels do not exactly match the labels in the legend. As such, there's no single
// source of truth for label-to-color mappings, so we make it explicit here:
const TOOLTIP_LABEL_TO_COLOR_MAPPING = {
  "Eligible for completion": gray,
  "Completed during/after campaign": lightBlue,
  "Completed before campaign enrollment": navyBlue,
  "Completed but not presented to contact in a campaign": coral,
}

const GapAnalysis = ({classes: pageClasses}) => {
  const classes = {...pageClasses, ...useStyles()}
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    gapAnalysis,
    gapAnalysisIsLoading,
  } = useAdvancedTeamInsights()

  const data = gapAnalysis?.data ?? []

  useEffect(() => {
    if (hasSocket) fetchInsights("gapAnalysis", fetchAdvancedInsightsGapAnalysis, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  //
  // Rendering helpers
  //

  const renderTooltipContent = useCallback(
    barData => {
      if (barData.payload?.length) {
        const objectiveName =
          barData.label.length > 55 ? `${barData.label.slice(0, 54)} ...` : barData.label

        return (
          <div className={classes.tooltipWrapper}>
            <span>{objectiveName}</span>
            <ul className={classes.tooltipList}>
              {barData.payload.map(({name, payload}, index) => {
                const count = payload[`${name}Count`]
                const label = payload[`${name}Label`]
                const textColor = TOOLTIP_LABEL_TO_COLOR_MAPPING[label]

                return (
                  <li
                    className={classes.tooltipListItem}
                    style={{color: textColor}}
                    key={`layer-${index}`}
                  >
                    {`${label}: ${count}`}
                  </li>
                )
              })}
            </ul>
          </div>
        )
      }

      return null
    },
    [classes.tooltipList, classes.tooltipListItem, classes.tooltipWrapper]
  )

  const renderSkeleton = () => (
    <Skeleton variant="rounded" width={400} height={200} style={{marginTop: 50}} />
  )

  const renderChart = (classes, data, renderTooltipContent) => (
    <div className={classes.chartWrapper}>
      <div className={classes.chartMain}>
        <ResponsiveContainer minHeight={280} width={data.length * 150 || 300}>
          <BarChart
            data={data}
            isAnimationActive={false}
            margin={{top: 15, right: 10, bottom: 5, left: 5}}
          >
            <XAxis
              dataKey="name"
              tick={{fontSize: "10px"}}
              tickFormatter={objectiveName =>
                objectiveName.length > 18 ? `${objectiveName.slice(0, 17)} ...` : objectiveName
              }
            />
            <ChartTooltip content={renderTooltipContent} cursor={{fill: "transparent"}} />
            <Bar
              barSize={60}
              dataKey="notPresentedPercent"
              fill={coral}
              name="notPresented"
              stackId="gap-analysis"
            >
              <LabelList
                dataKey="notPresentedPercent"
                formatter={value => (value ? `${value}%` : "")}
                position="center"
                style={{fill: "white", fontSize: 10}}
              />
            </Bar>
            <Bar
              barSize={60}
              dataKey="beforeEnrollmentPercent"
              fill={navyBlue}
              name="beforeEnrollment"
              stackId="gap-analysis"
            >
              <LabelList
                dataKey="beforeEnrollmentPercent"
                formatter={value => (value ? `${value}%` : "")}
                position="center"
                style={{fill: "white", fontSize: 10}}
              />
            </Bar>
            <Bar
              barSize={60}
              dataKey="duringAfterPercent"
              fill={lightBlue}
              name="duringAfter"
              stackId="gap-analysis"
            >
              <LabelList
                dataKey="duringAfterPercent"
                formatter={value => (value ? `${value}%` : "")}
                position="center"
                style={{fill: "white", fontSize: 10}}
              />
            </Bar>
            <Bar
              barSize={60}
              dataKey="eligiblePercent"
              fill={lightGray}
              name="eligible"
              stackId="gap-analysis"
            >
              <LabelList
                dataKey="eligiblePercent"
                formatter={value => (value ? `${value}%` : "")}
                position="center"
                style={{fill: "black", fontSize: 10}}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={classes.chartSidebar}>
        {GAP_CHART_SIDEBAR.map(([color, label, tooltip], index) => (
          <MaterialTooltip key={`layer-${index}-item`} placement="top" title={tooltip}>
            <p className={classes.legendListItem} id={`layer-${index}-item`}>
              <Surface
                width={14}
                height={14}
                viewBox={{x: 0, y: 0, width: 32, height: 32}}
                style={{display: "inline-block", verticalAlign: "middle", marginRight: 4}}
              >
                <path
                  stroke="none"
                  fill={color}
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                />
              </Surface>
              <span className="recharts-legend-item-text">{label}</span>
            </p>
          </MaterialTooltip>
        ))}
      </div>
    </div>
  )

  return (
    <div className={classes.section}>
      <Typography className={classes.header} variant="h5">
        Gap Analysis
      </Typography>
      <div className={classes.expandableContainer}>
        <Expandable
          label="Hide this section"
          labelClassName={classes.expandableLabelRight}
          startExpanded={true}
        >
          <Expandable label="How we use this table">
            <p>
              The gap analysis bar chart categorizes objective completions by four contact
              behaviors.
            </p>

            <Typography variant="h6">Definitions</Typography>

            <ul>
              <li>
                <b>Completed but not Presented to a Contact in a Campaign</b>: The percent of
                contacts that completed the objective but have never been enrolled in a campaign
                with it.
              </li>
              <li>
                <b>Completed Before Campaign Enrollment</b>: The percent of contacts that completed
                the objective before enrollment in a campaign with it.
              </li>
              <li>
                <b>Completed During/After Campaign</b>: The percent of contacts that completed the
                objective after enrollment in a campaign with it.
              </li>
              <li>
                <b>Eligible for Completion</b>: The percent of contacts that have not completed the
                objective but have been enrolled in a campaign with it.
              </li>
            </ul>

            <Typography variant="h6">At a Glance</Typography>

            <p>Objectives without a bar have not been presented or completed in any campaign.</p>

            <ul>
              <li>
                <b>Eligible for Completion</b>: Shows contacts that have been enrolled in a campaign
                with the objective but did not complete it. They can be retargeted easily by using
                campaign targeting.
              </li>
              <li>
                <b>Completed but not Presented to a Contact in a Campaign</b>: When this is the only
                layer, then no campaign with the objective has targeted contacts. If it is an
                important business objective, create a campaign to drive completion.
              </li>
            </ul>

            <Typography variant="h6">Use of Page Filters</Typography>

            <ul>
              <li>
                <b>Dates</b>: Easily build reports showcasing increases in objective completion over
                time. Monitor contact behavior over different time frames to see seasonal changes.
                Or, see support from other channels that causes an uptick in completions, like a
                complimentary in-branch two-week campaign for card on file.
              </li>
              <li>
                <b>Objectives</b>: Focus your view on specific objectives.
              </li>
              <li>
                <b>Status</b>: Filter setting does not affect this chart.
              </li>
            </ul>
          </Expandable>

          {gapAnalysisIsLoading
            ? renderSkeleton()
            : renderChart(classes, data, renderTooltipContent)}
        </Expandable>
      </div>
    </div>
  )
}

export default GapAnalysis

GapAnalysis.propTypes = {classes: object.isRequired}
