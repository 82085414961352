import {TextField} from "@mui/material"
import {func} from "prop-types"

import CtaButtonSettings from "components/content-block-editor/cta-button-settings"

import {formify} from "lib/hooks/use-form"

import {journeyContext} from "../../../journeys/journey-context"
import FormActions from "../form-actions"

const DirectDepositSettings = ({change, handleSubmit, field, resetForm}) => {
  return (
    <form onSubmit={handleSubmit}>
      <TextField fullWidth={true} label="Text" margin="normal" {...field("text")} />
      <CtaButtonSettings change={change} field={field} />
      <FormActions resetForm={resetForm} />
    </form>
  )
}

DirectDepositSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  handleSubmit: func,
  onClose: func,
  resetForm: func,
}

export default formify()(journeyContext(DirectDepositSettings))
