import {ImageList} from "@mui/material"
import {arrayOf, func, string} from "prop-types"

import ThemeBaseChooserOption from "./theme-base-chooser-option"

const ThemeBaseChooser = ({onSelect, options, selected}) => (
  <ImageList rowHeight="auto" cols={1}>
    {options.map(option => (
      <ThemeBaseChooserOption
        key={option}
        onSelect={onSelect}
        option={option}
        selected={selected}
      />
    ))}
  </ImageList>
)

ThemeBaseChooser.propTypes = {
  onSelect: func.isRequired,
  options: arrayOf(string),
  selected: string,
}

export default ThemeBaseChooser
