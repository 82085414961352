import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {push} from "connected-react-router"
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom"

import Page from "components/journeys/page"

import {deletePage, duplicatePage, fetchPage, updatePage} from "lib/api"
import createTeamTheme from "themes/team-theme-creator"

import BackButton from "../back-button/back-button"
import {ContentBlockEditorProvider} from "../content-block-editor/content-block-editor-context"
import DocumentTitle from "../document-title/document-title"
import ContentWrapper from "../journeys/content-wrapper"
import {JourneyContextProvider} from "../journeys/journey-context"
import themeBases from "../journeys/theme-bases"
import withParamKey from "../with-param-key/with-param-key"
import PageSidebar from "./page-sidebar"

const usePageStyles = themeBase => makeStyles(themeBase)()

const useStyles = makeStyles({
  main: {
    // we only need this component until preview wrapper is wrapping this
    display: "flex",
  },
  container: {
    maxWidth: 1000,
    margin: "0 auto",
    position: "relative",
    marginTop: 50,
  },
  returnLink: {
    float: "left",
    paddingTop: 5,
    position: "absolute",
    left: 30,
    width: "auto",
    zIndex: 100,
  },
})

const PageEditor = () => {
  const [page, setPage] = useState()
  const dispatch = useDispatch()
  const pageClasses = usePageStyles(themeBases.default)
  const classes = useStyles()
  const team = useSelector(state => state?.session?.team)
  const teamThemeStyles = team?.themeStyles
  const {pageId} = useParams()

  useEffect(() => {
    fetchPage(pageId).then(setPage)
  }, [pageId])

  const onUpdatePage = async attrs => {
    const updatedPage = await updatePage(page.id, attrs)
    setPage(updatedPage)
  }

  const onDuplicate = async () => {
    const newPage = await duplicatePage(page.id)

    dispatch(push(`/admin/templates/content-library/pages/${newPage.id}`))
  }

  const onDelete = async () => {
    await deletePage(page.id)

    dispatch(push("/admin/templates/content-library"))
  }

  return (
    <ContentBlockEditorProvider>
      <DocumentTitle title={`${page?.contentName} - Editor`} />
      <BackButton className={classes.returnLink} to={`/admin/templates/content-library`}>
        Return to Content Library
      </BackButton>
      <div className={classes.main}>
        {page?.id && (
          <JourneyContextProvider
            legacyInitialState={{
              isContentLibrary: true,
              themeClasses: pageClasses,
              pageId: page.id,
              team,
            }}
          >
            <ContentWrapper>
              <div className={classes.container}>
                <ThemeProvider theme={createTeamTheme(teamThemeStyles)}>
                  <Page
                    isEditable={true}
                    key={page.id}
                    isContentLibrary={true}
                    onUpdatePage={onUpdatePage}
                    {...page}
                    classes={pageClasses}
                  />
                </ThemeProvider>
              </div>
            </ContentWrapper>
          </JourneyContextProvider>
        )}
        <PageSidebar
          page={page}
          onUpdatePage={onUpdatePage}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      </div>
    </ContentBlockEditorProvider>
  )
}

const getKey = ({pageId}) => `page-${pageId}`

export default withParamKey(getKey)(PageEditor)
