import {Button, FormControlLabel, Switch, TextField} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object, string} from "prop-types"
import {PureComponent} from "react"

import DocumentTitle from "components/document-title/document-title"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchContacts} from "lib/api"
import {formify} from "lib/hooks/use-form"

import ButtonBar from "./button-bar"
import headers from "./contacts-list-fields"
import ContactsListRow from "./contacts-list-row"

class ContactsList extends PureComponent {
  state = {isTableLoading: false}

  componentDidMount = () => {
    const {batchId} = this.props
    const filters = {batchId}

    this.refresh({filters})
  }

  filter = ({batchId, ...filters}) => {
    if (batchId) filters.batchId = this.props.batchId // eslint-disable-line no-param-reassign

    this.refresh({page: 0, filters})
  }

  refresh = attrs => {
    const {updateStateForRequest} = this.props
    const params = updateStateForRequest(attrs)

    this.setState({isTableLoading: true}, () => {
      fetchContacts(params, {withFetchResponse: true})
        .then(([rows, fetchResponse]) => {
          this.props.setTableState({fetchResponse, rows})
        })
        .finally(() => {
          this.setState({isTableLoading: false})
        })
    })
  }

  render() {
    const {batchId, classes} = this.props
    const {isTableLoading} = this.state

    return (
      <div>
        <DocumentTitle title="Contacts Dashboard - Contacts" />
        <ButtonBar />
        <DOTable
          allowColumnCustomization={true}
          exporterProps={{
            fetchRecords: fetchContacts,
            filename: "contacts_export",
            title: "Export Results",
          }}
          filterComponent={
            <ContactListFilters batchId={batchId} classes={classes} onSubmit={this.filter} />
          }
          headers={headers}
          isTableLoading={isTableLoading}
          noResults="No contacts found"
          refresh={this.refresh}
          storageName="contacts-list"
        >
          {(row, index, columns) => (
            <ContactsListRow classes={classes} columns={columns} key={row.id} row={row} />
          )}
        </DOTable>
      </div>
    )
  }
}

ContactsList.propTypes = {
  batchId: string,
  classes: object.isRequired,
  setTableState: func.isRequired,
  updateStateForRequest: func.isRequired,
}

const Filters = ({batchId, classes, field, resetForm}) => (
  <>
    <TextField autoFocus={true} fullWidth={true} label="Search for Contacts" {...field("search")} />
    {batchId && (
      <FormControlLabel
        className={classes.batchSwitch}
        control={<Switch {...field("batchId", {bool: true, defaultValue: true})} color="primary" />}
        label="Most Recent Only"
      />
    )}
    <Button color="grey" onClick={resetForm}>
      reset
    </Button>
  </>
)

Filters.propTypes = {
  batchId: string,
  classes: object.isRequired,
  field: func.isRequired,
  resetForm: func.isRequired,
}

const ContactListFilters = formify({
  autoSubmitOnChange: true,
  autoSubmitDebounceTime: 250,
  enableReinitialize: true,
})(Filters)

const styles = theme => ({
  link: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.background.default,
    },
  },
  batchSwitch: {
    marginLeft: theme.spacing(1),
  },
  filterSelector: {
    minWidth: 325,
    marginRight: theme.spacing(1.5),
  },
  selectorPlaceholder: {
    color: theme.palette.text.hint,
  },
})

export default withStyles(styles)(
  tabular({
    sortColumn: "insertedAt",
    sortDirection: "desc",
    useQueryParams: true,
  })(ContactsList)
)
