import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {MdViewAgenda as DividerIcon} from "react-icons/md"
import shortid from "shortid"

const DividerButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {
        height: 40,
      },
      type: "divider",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <DividerIcon />
      </ListItemIcon>
      <ListItemText primary="Divider" ref={ref} />
    </MenuItem>
  )
})

DividerButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func.isRequired,
}

export {DividerIcon}

export default DividerButton
