import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {func, object} from "prop-types"

import PersonalizationButton from "../plugins/personalization/personalization-button"
import {
  AlignBlockCenterButton,
  AlignBlockJustifyButton,
  AlignBlockLeftButton,
  AlignBlockRightButton,
  BoldButton,
  ItalicButton,
  LineHeightButton,
  LinkButton,
  OrderedListButton,
  SubButton,
  SupButton,
  UnderlineButton,
  UnorderedListButton,
} from "./buttons"
import BlockTypeInput from "./inputs/block-type-input"
import FontColorInput from "./inputs/font-color-input"
import FontSelectorInput from "./inputs/font-selector-input"
import FontSizeInput from "./inputs/font-size-input"

const Toolbar = ({classes, editorState, onSetEditorState}) => {
  const childrenProps = {
    editorState,
    onSetEditorState,
  }
  const preventBubblingUp = e => e.preventDefault()

  return (
    <div className={cx("do-toolbar", classes.toolbarContainer)} onMouseDown={preventBubblingUp}>
      <div className={classes.toolbar}>
        <BlockTypeInput {...childrenProps} />
        <div className={classes.separator} />
        <FontSelectorInput {...childrenProps} />
        <div className={classes.separator} />
        <FontSizeInput {...childrenProps} />
        <div className={classes.separator} />
        <div className={classes.buttonGroup}>
          <BoldButton classes={classes} {...childrenProps} />
          <ItalicButton classes={classes} {...childrenProps} />
          <UnderlineButton classes={classes} {...childrenProps} />
          <FontColorInput {...childrenProps} />
          <LinkButton classes={classes} {...childrenProps} />
        </div>
        <div className={classes.separator} />
        <div className={classes.buttonGroup}>
          <SubButton classes={classes} {...childrenProps} />
          <SupButton classes={classes} {...childrenProps} />
        </div>
        <div className={classes.separator} />
        <div className={classes.buttonGroup}>
          <AlignBlockLeftButton classes={classes} {...childrenProps} />
          <AlignBlockCenterButton classes={classes} {...childrenProps} />
          <AlignBlockRightButton classes={classes} {...childrenProps} />
          <AlignBlockJustifyButton classes={classes} {...childrenProps} />
        </div>
        <div className={classes.separator} />
        <div className={classes.buttonGroup}>
          <LineHeightButton classes={classes} {...childrenProps} />
        </div>
        <div className={classes.separator} />
        <div className={classes.buttonGroup}>
          <UnorderedListButton classes={classes} {...childrenProps} />
          <OrderedListButton classes={classes} {...childrenProps} />
        </div>
        <div className={classes.separator} />
        <PersonalizationButton classes={classes} {...childrenProps} />
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  classes: object,
  editorState: object,
  onSetEditorState: func,
}

const styles = theme => ({
  toolbarContainer: {
    order: -1,
    overflow: "visible",
    zIndex: 1100, // below MUI drawer / dialogs
    left: "0 !important",
    position: "sticky",
    marginBottom: theme.spacing(1),
  },
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
    background: "#fff",
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "3px 3px 0 0",
    padding: `0 ${theme.spacing(1)} ${theme.spacing(0.5)}`,
  },
  buttonGroup: {
    display: "inline-flex",
    alignItems: "center",
  },
  button: {
    background: "inherit",
    padding: 0,
    cursor: "pointer",
    border: 0,
    height: 28,
    width: 28,
    borderRadius: "unset",
    color: "#000",
    "&:hover": {
      background: "inherit",
    },
  },
  buttonActive: {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  menuButtonIcon: {}, //needed for overrideability
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  separator: {
    borderRight: "1px solid #ddd",
    marginRight: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    height: 24,
  },
  errorText: {
    marginTop: 10,
    color: theme.palette.error.main,
  },
})

export default withStyles(styles)(Toolbar)
