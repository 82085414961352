import {Box, FormControl, InputLabel, TextField, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import _ from "lodash"
import {func, object, shape, string} from "prop-types"
import {useCallback, useEffect, useMemo, useState} from "react"
import {connect} from "react-redux"

import ColorInput from "components/color-input/color-input"
import FontSelector from "components/font-selector/font-selector"
import {validators as brandingSettingsValidators} from "components/teams/branding-settings/helpers"

import {templatePageContext} from "contexts/template-page-context"

import FormActions from "../form-actions"
import AccountsTableSettingsColumnPanel from "./accounts-table-settings-column-panel"

const maxColumns = 7

const Form = styled("form")({maxWidth: "100%"})

const SectionHeading = styled("div")(({theme}) => ({
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(2.5),
  "& .MuiTypography-subtitle1": {fontSize: "0.8em", fontStyle: "italic"},
}))

const Subsection = styled("div")(({theme}) => ({paddingTop: theme.spacing(2)}))

const ColorInputGroup = styled("div")(({theme}) => ({
  marginTop: theme.spacing(2),
  "& .MuiFormControl-root:first-of-type": {
    marginBottom: theme.spacing(2),
  },
}))

const AccountsTableSettings = ({initialValues, onSubmit, team, template}) => {
  const defaultName = initialValues.tableName || ""
  const defaultHeader = initialValues.tableHeader || ""
  const defaultColumns = initialValues.columns || [{header: "", contentId: "", id: 1}]
  const defaultBrandingSettings = initialValues.brandingSettings || {}
  const [validationErrors, setValidationErrors] = useState({})

  const [tableName, setTableName] = useState(defaultName)
  const [tableHeader, setTableHeader] = useState(defaultHeader)
  const [columns, setColumns] = useState(defaultColumns)
  const [brandingSettings, setBrandingSettings] = useState(defaultBrandingSettings)

  const handleResetForm = () => {
    setTableName(defaultName)
    setTableHeader(defaultHeader)
    setColumns(defaultColumns)
  }

  // Convert a branding setting path to a nested object with the given value in it.
  // Example:
  //  > convertPathToObject("foo.bar.baz.zeb", 3)
  // => {foo: {bar: {baz: {zeb: 3}}}}
  const convertPathToObject = (str, value) => {
    const keys = str.split(".")

    return keys.reduceRight((obj, key, index) => {
      if (index === keys.length - 1) return {[key]: value}
      return {[key]: obj}
    }, {})
  }

  const setBrandingField = (field, value) => {
    const validators = brandingSettingsValidators[field] || []
    const errors = validators.map(validator => validator(value)).filter(result => !!result)

    if (!value) {
      errors.push("Required")
    }

    const partialSettings = convertPathToObject(field, value)
    setBrandingSettings(old => _.merge(_.clone(old), partialSettings))

    if (errors.length > 0) {
      setValidationErrors(old => ({...old, [field]: errors.join(", ")}))
    } else {
      setValidationErrors(old => ({...old, [field]: null}))
    }
  }

  const checkForValidationErrors = useCallback(() => {
    let anyValidationErrors = false

    if (!tableName) {
      setValidationErrors(old => ({...old, tableName: "Required"}))
      anyValidationErrors = true
    }

    if (tableName && validationErrors.tableName) {
      const {tableName, ...rest} = validationErrors
      setValidationErrors(rest)
    }

    if (!tableHeader) {
      setValidationErrors(old => ({...old, tableHeader: "Required"}))
      anyValidationErrors = true
    }

    if (tableHeader && validationErrors.tableHeader) {
      const {tableHeader, ...rest} = validationErrors
      setValidationErrors(rest)
    }

    columns.forEach(col => {
      anyValidationErrors = col.error ? true : anyValidationErrors
    })

    if (anyValidationErrors) {
      return true
    } else {
      setValidationErrors({})
      return false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName, tableHeader])

  const fieldHasError = key => !!validationErrors[key]

  const helperText = (key, infoText = "") => (fieldHasError(key) ? validationErrors[key] : infoText)

  const formHasNoValidationErrors = () => {
    return Object.values(validationErrors).filter(val => !!val).length === 0
  }

  useEffect(() => {
    checkForValidationErrors()
  }, [tableName, tableHeader, columns, checkForValidationErrors])

  const handleSubmit = e => {
    e.preventDefault()

    // Note: it's important to use this function rather than checkForValidationErrors because
    // the latter does not look at errors on the branding fields.
    if (formHasNoValidationErrors()) {
      onSubmit({
        tableName,
        tableHeader,
        columns: columns.map(({error, ...rest}) => rest),
        brandingSettings,
      })
    }
  }

  const muiTheme = useMemo(() => {
    return _.merge({}, team.themeStyles, template.theme, brandingSettings)
  }, [template, team, brandingSettings])

  return (
    <Form aria-label="Accounts Table Settings" onSubmit={handleSubmit}>
      <TextField
        error={fieldHasError("tableName")}
        fullWidth={true}
        helperText={helperText("tableName")}
        label="Table Name"
        onChange={event => {
          setTableName(event.target.value)
        }}
        sx={{paddingBottom: 2.5}}
        value={tableName}
      />

      <TextField
        error={fieldHasError("tableHeader")}
        fullWidth={true}
        helperText={helperText("tableHeader")}
        label="Table Header"
        onChange={event => setTableHeader(event.target.value)}
        sx={{paddingBottom: 2.5}}
        value={tableHeader}
      />

      <SectionHeading>
        <Box sx={{borderBottom: 1}}>
          <Typography variant="h6">Columns</Typography>
        </Box>
        <Typography variant="subtitle1">{`Maximum of ${maxColumns}`}</Typography>
      </SectionHeading>

      <AccountsTableSettingsColumnPanel
        columns={columns}
        initialValues={initialValues}
        onChange={changed => setColumns(changed)}
      />

      <SectionHeading>
        <Box sx={{borderBottom: 1}}>
          <Typography variant="h6">Branding</Typography>
        </Box>
      </SectionHeading>

      <Subsection>
        <Typography variant="overline">Table</Typography>
      </Subsection>

      <FormControl fullWidth margin="normal">
        <InputLabel htmlFor="fontFamily">Font Family</InputLabel>
        <FontSelector
          enableGoogleFonts={true}
          error={fieldHasError("custom.accountsTable.fontFamily")}
          helperText={helperText("custom.accountsTable.fontFamily")}
          id="tableFontFamily"
          name="custom.accountsTable.fontFamily"
          value={muiTheme.custom?.accountsTable?.fontFamily ?? "Roboto (Default)"}
          onSelect={({family}) => setBrandingField(`custom.accountsTable.fontFamily`, family)}
        />
      </FormControl>

      <Subsection>
        <Typography variant="overline">Table Header</Typography>
      </Subsection>

      <ColorInputGroup>
        <ColorInput
          fullWidth
          id="tableHeaderBackgroundColor"
          input={{
            name: "custom.accountsTable.header.backgroundColor",
            value: muiTheme.custom?.accountsTable?.header?.backgroundColor ?? "#489AD4",
            onChange: hex => setBrandingField("custom.accountsTable.header.backgroundColor", hex),
          }}
          label="Background Color"
          hideCustomPalette
        />
        <ColorInput
          fullWidth
          id="tableHeaderFontColor"
          input={{
            name: "custom.accountsTable.header.fontColor",
            value: muiTheme.custom?.accountsTable?.header?.fontColor ?? "#FFFFFF",
            onChange: hex => setBrandingField("custom.accountsTable.header.fontColor", hex),
          }}
          label="Font Color"
          hideCustomPalette
        />
      </ColorInputGroup>

      <TextField
        error={fieldHasError("custom.accountsTable.header.fontSize")}
        fullWidth
        helperText={helperText(
          "custom.accountsTable.header.fontSize",
          "Accepts CSS numbers like 14px, 1.2em, etc"
        )}
        id="tableHeaderFontSize"
        InputLabelProps={{shrink: true}}
        label="Font Size"
        margin="normal"
        type="text"
        name="custom.accountsTable.header.fontSize"
        value={muiTheme.custom?.accountsTable?.header?.fontSize ?? "20px"}
        onChange={event =>
          setBrandingField("custom.accountsTable.header.fontSize", event.target.value)
        }
      />

      <Subsection>
        <Typography variant="overline">Column Header</Typography>
      </Subsection>

      <ColorInputGroup>
        <ColorInput
          fullWidth
          id="columnHeaderBackgroundColor"
          input={{
            name: "custom.accountsTable.columnHeader.backgroundColor",
            value: muiTheme.custom?.accountsTable?.columnHeader?.backgroundColor ?? "#FFFFFF",
            onChange: hex =>
              setBrandingField("custom.accountsTable.columnHeader.backgroundColor", hex),
          }}
          label="Background Color"
          hideCustomPalette
        />
        <ColorInput
          fullWidth
          id="columnHeaderFontColor"
          input={{
            name: "custom.accountsTable.columnHeader.fontColor",
            value: muiTheme.custom?.accountsTable?.columnHeader?.fontColor ?? "#489AD4",
            onChange: hex => setBrandingField("custom.accountsTable.columnHeader.fontColor", hex),
          }}
          label="Font Color"
          hideCustomPalette
        />
      </ColorInputGroup>

      <TextField
        error={fieldHasError("custom.accountsTable.columnHeader.fontSize")}
        fullWidth
        helperText={helperText(
          "custom.accountsTable.columnHeader.fontSize",
          "Accepts CSS numbers like 14px, 1.2em, etc"
        )}
        id="columnHeaderFontSize"
        InputLabelProps={{shrink: true}}
        label="Font Size"
        margin="normal"
        type="text"
        name="custom.accountsTable.columnHeader.fontSize"
        value={muiTheme.custom?.accountsTable?.columnHeader?.fontSize ?? "17px"}
        onChange={event =>
          setBrandingField("custom.accountsTable.columnHeader.fontSize", event.target.value)
        }
      />

      <Subsection>
        <Typography variant="overline">Data Rows</Typography>
      </Subsection>

      <TextField
        error={fieldHasError("custom.accountsTable.dataRow.fontSize")}
        fullWidth
        helperText={helperText(
          "custom.accountsTable.dataRow.fontSize",
          "Accepts CSS numbers like 14px, 1.2em, etc"
        )}
        id="dataRowFontSize"
        InputLabelProps={{shrink: true}}
        label="Font Size"
        margin="normal"
        type="text"
        name="custom.accountsTable.dataRow.fontSize"
        value={muiTheme.custom?.accountsTable?.dataRow?.fontSize ?? "14px"}
        onChange={event =>
          setBrandingField("custom.accountsTable.dataRow.fontSize", event.target.value)
        }
        sx={{paddingBottom: 2.5}}
      />

      <FormActions resetForm={handleResetForm} />
    </Form>
  )
}

AccountsTableSettings.propTypes = {
  initialValues: shape({
    iconSrc: string,
  }).isRequired,
  onSubmit: func.isRequired,
  team: object,
  template: object,
}

export default connect(({session, template}) => ({team: session.team, template}))(
  templatePageContext(AccountsTableSettings)
)
