import WebpageEmbedButton, {WebPageIcon} from "./webpage-embed-button"
import WebpageEmbedReadonly from "./webpage-embed-readonly"
import WebpageEmbedSettings from "./webpage-embed-settings"

export default {
  title: "Webpage Embed",
  type: "webpage",
  Button: WebpageEmbedButton,
  Icon: WebPageIcon,
  Readonly: WebpageEmbedReadonly,
  Settings: WebpageEmbedSettings,
  suitableFor: ["content_container", "page", "template"],
}
