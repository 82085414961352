import {PGP_KEY_CREATED, PGP_KEY_DELETED, PGP_KEY_LIST_SET} from "./team-actions"

const initialState = {
  list: [],
}

function pgpKeys(state = initialState, action) {
  switch (action.type) {
    case PGP_KEY_LIST_SET:
      return {
        ...state,
        list: action.payload,
      }

    case PGP_KEY_DELETED:
      return {
        ...state,
        list: state.list.filter(key => key.id !== action.payload.id),
      }

    case PGP_KEY_CREATED:
      return {
        ...state,
        list: [...state.list, action.payload],
      }

    default:
      return state
  }
}

export default pgpKeys
