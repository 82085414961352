import {createTheme} from "@mui/material"

export const buttonColors = ({backgroundColor}) => {
  if (backgroundColor) {
    const {
      palette: {getContrastText},
    } = createTheme()
    return {backgroundColor, color: getContrastText(backgroundColor)}
  } else {
    return {}
  }
}
