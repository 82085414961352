import {Box, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, func, object, shape, string} from "prop-types"

import PagesList from "../content-library/pages-list"

const setPage = payload => ({type: "PAGE_SET", payload})

const usePagesListStyles = makeStyles({
  pagesList: {
    paddingLeft: 0,
    height: "auto",
  },
})

const AddPageStep = ({currentRow: {objective, page}, otherRows, dispatch}) => {
  const classes = usePagesListStyles()

  return (
    <>
      <Box key="title" my={1}>
        <Typography>
          Showing pages related to <i>{objective?.name ?? "no objective"}</i>
        </Typography>
      </Box>
      <PagesList
        className={classes.pagesList}
        excludedPageIds={otherRows.map(row => row.page?.id).filter(id => !id?.includes("new"))}
        objectiveId={objective?.id}
        onChange={p => dispatch(setPage(p))}
        selectedPage={page}
      />
    </>
  )
}

AddPageStep.propTypes = {
  currentRow: shape({
    objective: shape({id: string}),
    page: object,
  }),
  dispatch: func.isRequired,
  otherRows: arrayOf(
    shape({
      page: shape({
        id: string,
      }),
    })
  ),
}

export default AddPageStep
