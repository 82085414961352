// original source: https://spin.atomicobject.com/2018/09/10/javascript-concurrency/
// This will ensure an inFlight request gets called only once
// regardless of how many requests are called.
const synchronize = proc => {
  let inFlight = false

  return (...args) => {
    if (!inFlight) {
      inFlight = (async () => {
        try {
          return await proc(...args)
        } finally {
          inFlight = false
        }
      })()
    }
    return inFlight
  }
}

export default synchronize
