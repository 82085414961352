import {TextField, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, object} from "prop-types"

import {requiredField} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

import {controlAccess} from "../access-control/access-controlled"
import SaveButton from "../save-button/save-button"

const initialValues = {publicKey: "", title: ""}
const useStyles = makeStyles({
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
})

export const CreatePgpKey = ({onSubmit, disabled}) => {
  const classes = useStyles()

  const {submitting, invalid, field, handleSubmit, resetForm} = useForm({
    onSubmit: i => onSubmit(i).then(resetForm),
    initialValues,
    validators: {
      publicKey: [requiredField],
      title: [requiredField],
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Typography>Add new PGP public key</Typography>
      <TextField
        className={classes.field}
        disabled={disabled}
        fullWidth={true}
        helperText="Paste your public PGP key."
        InputLabelProps={{shrink: true}}
        label="Key"
        multiline={true}
        rows="10"
        variant="outlined"
        {...field("publicKey")}
      />
      <TextField
        className={classes.field}
        disabled={disabled}
        fullWidth={true}
        label="Title"
        {...field("title")}
      />
      <div className={classes.formActions}>
        <SaveButton
          disabled={disabled || invalid}
          stateLabels={{default: "Add PGP public key"}}
          submitting={submitting}
        />
      </div>
    </form>
  )
}

CreatePgpKey.propTypes = {
  classes: object,
  disabled: bool,
  onSubmit: func.isRequired,
}

export default controlAccess({requiredPermissions: "team_settings:sftp"})(CreatePgpKey)
