// This file was generated automatically from character-13.svg with the following command:
// ./svg-converter.js src/components/superhero/character-13.svg
import SuperheroSvg from "./superhero-svg"

const Character13 = props => (
  <SuperheroSvg viewBox="240 65 120 260" {...props}>
    <g id="Layer_11">
      <g>
        <g>
          <path
            style={{fill: "#8C715E"}}
            d="M262.68,113.16c1.52-0.55,4.06,2.16,3.95,2.33C266.51,115.69,262.66,113.19,262.68,113.16
  				c0.04-0.09,2.58,0.43,3.95,2.33c1.37,1.9,1.02,4.41,0.56,7.68c-0.32,2.25-0.57,2.18-1.15,5.5c-0.23,1.3-0.55,3.22-0.72,5.64
  				c-0.26,3.72,0.02,6.28-0.19,6.3c-0.25,0.02-0.76-3.54-0.88-3.52c-0.08,0.01,0.16,1.35,0.3,4.81c0,0.02,0,0.05,0,0.07
  				c-1.87-1.24-3.74-2.48-5.61-3.72c2.62-1.19,5.25-2.37,7.87-3.56c4.15-1.88,8.29-3.75,12.44-5.63c0.96-0.44,1.93-0.87,2.89-1.31
  				c0.85-0.38,1.69-0.55,2.68-0.21c0.86,0.3,1.83,1.06,2.3,1.95c0.96,1.78,0.84,4.41-0.9,5.2c-2.62,1.19-5.25,2.37-7.87,3.56
  				c-4.15,1.88-8.3,3.75-12.44,5.63c-0.96,0.44-1.93,0.87-2.89,1.31c-1.13,0.51-2.33,0.58-3.55-0.22c-1.16-0.75-1.97-2.12-2.06-3.5
  				c-0.14-2.13-0.28-4.32-0.28-4.32c0,0,2.03-10.91,2.03-10.91c0,0,1.23-5.75,1.23-5.75c0,0,0.25-2.12,0.73-3.94
  				C261.66,114.46,261.93,113.43,262.68,113.16z"
          />
        </g>
      </g>
    </g>
    <g id="Layer_12"></g>
    <g id="Layer_13"></g>
    <g id="Layer_14"></g>
    <g id="Layer_15">
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M265.34,316.79l-0.06-0.02c-0.11-0.03-2.67-0.86-2.85-3.01c-0.14-1.62,1.05-3.34,3.63-5.27
  				l0.08-0.05c0.03-0.01,3-1.52,6.05-3.7c3.94-2.83,6.2-5.49,6.56-7.7l0.95,0.14c-0.96,5.98-12.29,11.83-13.19,12.28
  				c-2.69,2.02-3.18,3.35-3.12,4.12c0.1,1.15,1.53,1.87,2.14,2.08c15.97,0.32,28.61-5.1,30.02-5.72c1.51-2.45-1.49-9.22-2.8-11.67
  				l0.83-0.64c0.53,0.98,5.07,9.71,2.61,13.12l-0.09,0.12l-0.12,0.06c-0.13,0.06-13.49,6.23-30.6,5.86L265.34,316.79z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M270.42,316.52l-0.72-0.69c0.93-1.29,1.34-2.58,1.21-3.82c-0.25-2.34-2.31-3.84-2.33-3.85
  				l0.54-0.98c0.1,0.07,2.44,1.77,2.75,4.61C272.04,313.36,271.55,314.96,270.42,316.52z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M271.45,312.99l-0.06-1.12c20.21-3,24.46-6.5,24.5-6.53l0.55,0.88
  				C296.28,306.37,292.11,309.93,271.45,312.99z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M271.07,311.49l-0.33-1.03c13.92-6.96,13.53-13.26,13.52-13.32l0.96-0.21
  				C285.25,297.21,285.85,304.11,271.07,311.49z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M272.64,308.34c-0.46-0.09-0.88-0.25-1.08-0.34l0.38-1.07c0.71,0.3,1.55,0.44,1.77,0.3
  				c0-0.01,0-0.03,0-0.05c-0.04-0.76-0.07-1.41,0.48-1.82c0.4-0.3,1-0.38,2.34-0.58c0.95-0.14,1.63-0.24,1.78-0.45
  				c0.15-0.21,0.11-1.01,0.06-2.11c-0.05-0.92,0.16-1.54,0.62-1.83c0.9-0.58,2.23,0.59,2.62,0.96l-0.64,0.89
  				c-0.64-0.61-1.39-0.99-1.55-0.87c0,0-0.11,0.13-0.08,0.71c0.08,1.57,0.12,2.36-0.3,2.94c-0.42,0.58-1.1,0.68-2.45,0.88
  				c-0.96,0.14-1.78,0.26-1.92,0.4c-0.02,0.07-0.01,0.43,0.01,0.74c0.02,0.44-0.12,0.81-0.39,1.06
  				C273.87,308.47,273.22,308.46,272.64,308.34z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M310.06,317.53l-0.06-0.01c-0.11-0.03-2.72-0.66-3.07-2.78c-0.26-1.6,0.8-3.41,3.23-5.53
  				l0.07-0.05c0.03-0.02,2.88-1.74,5.76-4.15c3.71-3.11,5.77-5.94,5.96-8.17l0.96,0.07c-0.51,6.03-11.37,12.72-12.23,13.24
  				c-2.53,2.21-2.92,3.58-2.8,4.34c0.18,1.14,1.67,1.75,2.29,1.92c15.95-0.87,28.15-7.23,29.51-7.96c1.32-2.56-2.18-9.09-3.66-11.43
  				l0.78-0.7c0.6,0.94,5.79,9.3,3.59,12.89l-0.08,0.12l-0.12,0.07c-0.13,0.07-12.99,7.22-30.07,8.14L310.06,317.53z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M315.11,316.87l-0.77-0.64c0.83-1.36,1.14-2.67,0.92-3.9c-0.42-2.31-2.59-3.65-2.61-3.67
  				l0.47-1.01c0.1,0.06,2.57,1.58,3.08,4.39C316.48,313.6,316.12,315.23,315.11,316.87z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M315.87,313.28l-0.14-1.11c19.92-4.5,23.9-8.31,23.94-8.35l0.62,0.84
  				C340.13,304.82,336.24,308.68,315.87,313.28z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M315.38,311.81l-0.41-1.01c13.36-7.98,12.49-14.23,12.48-14.3l0.94-0.28
  				C328.45,296.51,329.56,303.34,315.38,311.81z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M316.71,308.55c-0.47-0.06-0.89-0.19-1.1-0.26l0.3-1.09c0.73,0.25,1.58,0.32,1.78,0.17
  				c0-0.01,0-0.03-0.01-0.05c-0.09-0.75-0.18-1.4,0.35-1.85c0.37-0.32,0.97-0.46,2.29-0.75c0.93-0.21,1.61-0.36,1.74-0.58
  				c0.14-0.22,0.04-1.01-0.1-2.1c-0.11-0.92,0.05-1.55,0.48-1.87c0.85-0.64,2.27,0.42,2.68,0.77l-0.57,0.94
  				c-0.68-0.56-1.46-0.89-1.62-0.75c0,0-0.1,0.14-0.03,0.71c0.2,1.56,0.29,2.34-0.08,2.95c-0.38,0.61-1.04,0.76-2.38,1.06
  				c-0.94,0.21-1.76,0.39-1.89,0.55c-0.02,0.08,0.02,0.43,0.06,0.73c0.05,0.43-0.06,0.82-0.31,1.09
  				C317.95,308.59,317.29,308.62,316.71,308.55z"
          />
        </g>
      </g>
      <path
        style={{fill: "#123252"}}
        d="M327.56,221.39c0-0.35,0-0.7-0.02-1.05c-0.26-6.35-3.38-11.92-8.08-15.53l-2.51,0.01l-31.36-5.04
  		l-2.07,17.67c-0.17,0.88-0.29,1.79-0.34,2.71l-0.18,1.46l0.16,0.01c0,0.19,0,0.37,0.01,0.56c0.41,10.06,8,18.15,17.69,19.63
  		l0.16,0.39l11.3,54.91l29.56-1.24L327.56,221.39z"
      />
      <polygon
        style={{fill: "#0A273F"}}
        points="302.23,208.63 297.92,297.01 272.87,297.01 281.87,183.82 303.82,180.01 	"
      />
      <g>
        <defs>
          <rect
            id="SVGID_00000008139237532533494520000001365524141171414154_"
            x="123.61"
            y="52.85"
            width="232.68"
            height="191.23"
          />
        </defs>
        <clipPath id="3410efb9-SVGID_00000006666903310440590300000009965317241268107417_">
          <use
            xlinkHref="#SVGID_00000008139237532533494520000001365524141171414154_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <g style={{clipPath: "url(#SVGID_00000006666903310440590300000009965317241268107417_)"}}>
          <path
            style={{fill: "#609E92"}}
            d="M295,129.29c1.37-1.11,2.07-3.05,1.26-4.73c-0.92-1.89-3.23-3.04-5.25-2.08
  				c-3.3,1.57-6.6,3.14-9.9,4.7l3.69,9.05L295,129.29z"
          />
          <path
            style={{fill: "#8C715E"}}
            d="M308.29,80.12c0.68,0,1.34,0.03,2,0.1c0.39,15.7,14.64,23.93,17.33,25.34
  				c-2.35,8.47-10.11,14.69-19.33,14.69c-11.08,0-20.07-8.98-20.07-20.07C288.22,89.1,297.21,80.12,308.29,80.12z"
          />
          <path
            style={{fill: "#8C715E"}}
            d="M288.23,100.13c0,8.92,6.27,16.47,14.31,19.1l0,6.96l7.4,0l0-6.03
  				c5.57-0.44,10.49-3.16,13.85-7.21c0.72,0.51,1.59,0.82,2.54,0.82c2.42,0,4.39-1.96,4.39-4.39c0-1.92-1.24-3.56-2.97-4.15
  				c0.01-0.02,0.01-0.04,0.02-0.07c-0.14-0.07-0.28-0.15-0.41-0.23l-38.87-7.91C288.32,98.03,288.23,99.07,288.23,100.13z"
          />
          <path
            style={{fill: "#8C715E"}}
            d="M317.98,95.01l-26.67-5.43c-1.43,2.25-2.42,4.81-2.85,7.56l38.47,7.83
  				C322.9,102.81,319.71,99.28,317.98,95.01z"
          />
          <polygon
            style={{fill: "#EB6669"}}
            points="320.6,151.59 281.92,155.31 280.93,161.25 321.86,165.91 			"
          />
          <circle style={{fill: "#0D1E36"}} cx="298.95" cy="95.66" r="1.6" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M300.93,91.08c-0.34-0.34-0.82-0.58-1.28-0.72c-1.04-0.33-2.14-0.08-3.08,0.42
  						c-0.34,0.18-0.47,0.67-0.26,1c0.22,0.34,0.64,0.46,1,0.26c0.07-0.04,0.13-0.07,0.2-0.1c0.03-0.02,0.24-0.1,0.08-0.04
  						c0.11-0.04,0.21-0.08,0.32-0.12c0.12-0.04,0.24-0.07,0.36-0.09c0.05-0.01,0.21-0.01,0,0c0.05,0,0.09-0.01,0.14-0.01
  						c0.19-0.01,0.37,0.01,0.56,0.01c-0.22-0.01-0.1-0.01-0.04,0c0.04,0.01,0.09,0.02,0.13,0.03c0.08,0.02,0.15,0.04,0.23,0.06
  						c0.06,0.02,0.12,0.05,0.19,0.07c-0.2-0.05-0.12-0.05-0.05-0.02c0.03,0.01,0.06,0.03,0.09,0.05c0.1,0.05,0.19,0.1,0.28,0.17
  						c0.03,0.02,0.06,0.05,0.09,0.07c-0.23-0.16-0.11-0.09-0.06-0.04c0.02,0.02,0.04,0.03,0.06,0.05c0.27,0.27,0.76,0.29,1.03,0
  						C301.2,91.82,301.22,91.37,300.93,91.08L300.93,91.08z"
              />
            </g>
          </g>
          <path
            style={{fill: "#123252"}}
            d="M310.29,80.22c10.14,1,18.07,9.56,18.07,19.97c0,1.86-0.26,3.67-0.73,5.38
  				C324.93,104.15,310.69,95.92,310.29,80.22z"
          />
          <path
            style={{fill: "#123252"}}
            d="M310.32,80.34c0,0-5.55,6.21-16.75,6.21C293.57,86.55,299.95,78.99,310.32,80.34z"
          />
          <circle style={{fill: "#0D1E36"}} cx="312.66" cy="97.94" r="1.6" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M312.85,94.1c0.11-0.03,0.22-0.05,0.33-0.07c0.06-0.01,0.16-0.01-0.11,0.01
  						c0.03,0,0.06-0.01,0.09-0.01c0.06-0.01,0.12-0.01,0.18-0.01c0.15-0.01,0.31,0,0.46,0.01c0.04,0,0.09,0.02,0.13,0.01
  						c-0.06,0-0.19-0.03-0.06-0.01c0.1,0.02,0.19,0.04,0.29,0.06c0.09,0.02,0.17,0.05,0.26,0.08c0.19,0.07-0.11-0.05,0.01,0
  						c0.04,0.02,0.07,0.03,0.11,0.05c0.1,0.05,0.2,0.11,0.3,0.17c0.04,0.02,0.07,0.05,0.11,0.08c0.06,0.05,0.05,0.04-0.04-0.03
  						c0.02,0.02,0.05,0.04,0.07,0.06c0.09,0.07,0.17,0.15,0.25,0.23c0.05,0.05,0.09,0.1,0.14,0.15c0.02,0.03,0.05,0.05,0.07,0.08
  						c-0.06-0.08-0.06-0.08-0.01-0.02c0.22,0.31,0.64,0.49,1,0.26c0.3-0.2,0.5-0.67,0.26-1c-0.36-0.49-0.79-0.92-1.33-1.21
  						c-0.46-0.25-0.94-0.37-1.45-0.43c-0.47-0.05-0.99-0.03-1.45,0.11c-0.36,0.11-0.63,0.51-0.51,0.9
  						C312.07,93.95,312.46,94.22,312.85,94.1L312.85,94.1z"
              />
            </g>
          </g>
          <path
            style={{fill: "#8C715E"}}
            d="M269.81,103.76c0,0-0.55,0.56-0.8,0.31c-0.26-0.25-0.43-1.35,0.51-2.29
  				c0.62-0.62,2.67-3.62,1.08-6.27c0,0-2.52-2.62-5.49-0.49c-2.23,1.6-3.66,3.35-4.1,7.27c-0.41,3.66,0.57,5.69,0.71,6.89
  				c-0.54,3.94-1.08,7.88-1.62,11.81c-0.08,0.57-0.16,1.15-0.24,1.72l7.32,0.46c0.55-4.02,1.1-8.04,1.65-12.06
  				c0.06-0.41,0.11-0.83,0.17-1.24c1.02-0.63,3.35-2.23,4.28-4.16c0,0,0.57-0.86,0.48-2.17
  				C273.68,102.22,271.14,102.29,269.81,103.76z"
          />
          <g>
            <path
              style={{fill: "#123252"}}
              d="M272.99,109.29l-1.01,0.23c-1.04,0.24-2.08-0.42-2.32-1.46l-3.18-14.02
  					c-0.24-1.04,0.42-2.08,1.46-2.32l1.01-0.23c1.04-0.24,2.08,0.42,2.32,1.46l3.18,14.02
  					C274.69,108.02,274.03,109.05,272.99,109.29z"
            />
            <g>
              <path
                style={{
                  fill: "none",
                  stroke: "#123252",
                  strokeWidth: "1.4902",
                  strokeMiterlimit: "10",
                }}
                d="M268.45,92.92
  						c0,0-11.43-21.88-5.43-23.96c0,0,4.48-2.45,5.5,5.23c1.02,7.69,0.98,17.05,0.76,19.26"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#123252",
                  strokeWidth: "1.4902",
                  strokeMiterlimit: "10",
                }}
                d="M267.93,92.75
  						c0,0-15.55-20.95-5.43-23.96c0,0,7.68-3.18,8.25,4.61c0.56,7.79-0.85,17.46-1.52,19.78"
              />
              <path
                style={{
                  fill: "none",
                  stroke: "#123252",
                  strokeWidth: "1.4902",
                  strokeMiterlimit: "10",
                }}
                d="M269.76,95.5
  						c-5.25-16.8-6.16-21.7-6.11-23.8c0.06-2.89,0.75-3.05,0.75-3.05s2.12-3.36,4.37,25.64"
              />
            </g>
          </g>
          <path
            style={{fill: "#8C715E"}}
            d="M302.57,120.15c0,0-0.92,6.17,2.85,6.65c3.27,0.41,4.51-1.41,4.51-4.24"
          />
          <path
            style={{fill: "#5DBDAF"}}
            d="M320.02,152.58l0.25,1.35l2.85-17.16c0.71-5.23-2.22-10.12-6.85-12.09
  				C315.24,130.41,311.82,152.58,320.02,152.58z"
          />
          <path
            style={{fill: "#5DBDAF"}}
            d="M294.97,122.73c-5.5,0.6-8.31,3.71-9.26,9.74l-3.69,22.27l2.68-5.93
  				C284.69,148.81,291.09,146.19,294.97,122.73z"
          />
          <polygon
            style={{fill: "#5DBDAF"}}
            points="311.07,138.92 313.29,123.86 298.76,122.68 295.06,137.5 			"
          />
          <polygon
            style={{fill: "#5397D1"}}
            points="320.27,153.93 320.04,155.3 281.92,155.31 282.01,154.74 281.87,155.06 269.15,244.08
  				335.56,244.08 			"
          />
          <path
            style={{fill: "#5397D1"}}
            d="M320.04,155.3l0.23-1.38l-0.25-1.35c-8.2,0-4.32-22.88-3.3-28.6c-1.88,0-2.37,0.03-3.41-0.11
  				l-0.03,0l-2.21,15.06l-16.01-1.41l3.71-14.83l-0.15-0.01c-1.33-0.08-2.54-0.05-3.65,0.07c-3.88,23.46-10.27,26.08-10.27,26.08
  				l-2.68,5.93l-0.09,0.57L320.04,155.3z"
          />
          <g>
            <path
              style={{fill: "#EB6669"}}
              d="M282.04,145.04c0,0-2.54,16.47,14.27,15.79c16.82-0.68,15.66-10.27,14.48-16.65"
            />

            <ellipse
              transform="matrix(0.9992 -0.0406 0.0406 0.9992 -5.6556 12.1644)"
              style={{
                fill: "#CC5F64",
                stroke: "#EB6669",
                strokeWidth: "1.9509",
                strokeMiterlimit: "10",
              }}
              cx="296.44"
              cy="145.22"
              rx="14.08"
              ry="4.43"
            />
          </g>
          <g>
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M320.59,127.28c0,0-2.29,5.58-2.29,5.58l0,0c0,0,0.78,3.07,2.98,11.65
  						c0.86,3.38,1.73,6.75,2.59,10.13c0.42,1.63,0.79,3.28,1.25,4.89c0.01,0.02,0.01,0.05,0.02,0.07c1.38-1.64,2.77-3.28,4.15-4.91
  						c-2.82-0.37-5.63-0.74-8.45-1.1c-4.45-0.58-8.9-1.16-13.35-1.74c-1.03-0.14-2.07-0.27-3.1-0.41c-0.91-0.12-1.75-0.04-2.57,0.54
  						c-0.72,0.51-1.4,1.46-1.58,2.4c-0.37,1.88,0.53,4.24,2.4,4.49c2.82,0.37,5.63,0.74,8.45,1.1c4.45,0.58,8.9,1.16,13.35,1.74
  						c1.03,0.14,2.07,0.27,3.1,0.41c1.21,0.16,2.36-0.11,3.26-1.17c0.86-1,1.21-2.47,0.89-3.75c-0.34-1.34-0.68-2.67-1.02-4.01
  						c-0.82-3.21-1.64-6.42-2.46-9.63c-1.29-5.04-1.44-8.6-2.98-11.65c-0.51-1.02-1.19-2.33-2.54-3.3
  						C322.01,128.13,319.89,126.84,320.59,127.28L320.59,127.28z"
              />
            </g>
          </g>
          <path
            style={{fill: "#8C715E"}}
            d="M289.7,149.6c0,0,0.59,0.57,0.33,0.83c-0.26,0.27-1.41,0.46-2.4-0.51
  				c-0.65-0.64-3.81-2.75-6.56-1.06c0,0-2.71,2.65-0.45,5.73c1.7,2.31,3.54,3.79,7.63,4.2c3.82,0.39,5.93-0.65,7.19-0.82
  				c4.12,0.52,8.23,1.04,12.35,1.56c0.6,0.08,1.2,0.15,1.8,0.23l0.41-7.64c-4.2-0.53-8.41-1.06-12.61-1.6
  				c-0.43-0.05-0.87-0.11-1.3-0.16c-0.67-1.06-2.36-3.47-4.39-4.42c0,0-0.9-0.59-2.27-0.48S288.14,148.23,289.7,149.6z"
          />
          <path
            style={{fill: "#5DBDAF"}}
            d="M316.72,130.04c1.06,3.84,2.12,7.69,3.18,11.53l8.37-2.29l-3.48-12.25
  				c-0.79-1.42-0.54-2.59-10.43-3.06"
          />
          <polygon style={{fill: "#5397D1"}} points="316,128.45 316.72,123.97 313.43,123.97 			" />
          <path
            style={{fill: "#8C715E"}}
            d="M268.8,110.25c0.1-0.41,3.87-3.08,5-4.9c0,0,0.66-0.79,0.71-2.11s-2.49-1.52-3.96-0.2
  				c0,0-0.6,0.5-0.83,0.22l-3.3-0.66"
          />
          <path
            style={{fill: "#123252"}}
            d="M315.13,79.2c-0.48,2.78,2.64,3.92,4.89,5.62c2.25,1.7,3.48,1.75,5.26,0.36
  				c1.78-1.39,4.2-3.36,3.23-6.23c-0.97-2.87-2.06-3.28-3.73-4.34c-1.67-1.06-2.14-1.45-4.4-0.45c0,0-1.28,1.17-3.09,0.47
  				S315.46,77.25,315.13,79.2z"
          />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M306.41,101.27c-0.69,0.34-1.39,0.35-2.08,0.07c-0.02-0.01-0.02-0.01,0.01,0
  						c-0.02-0.01-0.03-0.01-0.05-0.02c-0.03-0.02-0.07-0.03-0.1-0.05c-0.06-0.03-0.11-0.06-0.16-0.09c-0.1-0.06-0.19-0.12-0.28-0.18
  						c-0.02-0.01-0.03-0.03-0.05-0.04c0.09,0.06,0.04,0.03,0.02,0.01c-0.03-0.02-0.05-0.04-0.08-0.07
  						c-0.02-0.02-0.04-0.04-0.06-0.05c0.02,0.15,0.04,0.3,0.06,0.45c0.31-0.63,0.63-1.26,0.94-1.88c0.09-0.18,0.05-0.41-0.13-0.51
  						c-0.17-0.1-0.42-0.05-0.51,0.13c-0.31,0.63-0.63,1.26-0.94,1.88c-0.07,0.15-0.06,0.33,0.06,0.45c0.31,0.31,0.72,0.53,1.12,0.69
  						c0.87,0.34,1.8,0.24,2.63-0.16c0.18-0.09,0.23-0.35,0.13-0.51C306.81,101.22,306.59,101.19,306.41,101.27L306.41,101.27z"
              />
            </g>
          </g>
          <path
            style={{fill: "#FFFFFF"}}
            d="M309.24,106.4c-0.12-0.22-0.4-0.25-0.58-0.1c-0.43,0.37-1.22,1.14-2.37,1.44
  				c-1.57,0.41-2.97-0.24-3.28,0.22c-0.17,0.26,0.06,0.57,0.51,0.95c0.45,0.39,1.79,1.12,3.23,0.77c1.51-0.38,2.58-2.39,2.55-3.08
  				C309.29,106.52,309.27,106.45,309.24,106.4z"
          />
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default Character13
