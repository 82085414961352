import {Tooltip} from "@mui/material"
import {lighten} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {array, object} from "prop-types"
import {Fragment} from "react"

const getProgressStatus = ({id}, analyticsCache) => {
  if (!analyticsCache) return "notViewed"

  const {pagesCompleted, pagesViewed} = analyticsCache

  if (pagesCompleted.indexOf(id) > -1) return "completed"

  if (pagesViewed.indexOf(id) > -1) return "inProgress"

  return "notViewed"
}

const JourneyProgress = ({analyticsCache = {}, templatePages = [], classes}) => (
  <div className={classes.progress}>
    {templatePages.map(({page}, i) => {
      const status = getProgressStatus(page, analyticsCache)

      return (
        <Fragment key={page.id}>
          <Tooltip
            title={`${page.navigationTitle}${
              status === "completed" ? " (completed)" : status === "inProgress" ? " (viewed)" : ""
            }`}
          >
            <div
              className={cx(classes.dot, {
                [classes.inProgress]: status === "inProgress",
                [classes.completed]: status === "completed",
              })}
            />
          </Tooltip>
          {i < templatePages.length - 1 && (
            <div
              className={cx(classes.line, {
                [classes.inProgress]: status === "inProgress",
                [classes.completed]: status === "completed",
              })}
            />
          )}
        </Fragment>
      )
    })}
  </div>
)

JourneyProgress.propTypes = {
  analyticsCache: object,
  classes: object.isRequired,
  templatePages: array,
}

const journeyProgressStyles = theme => ({
  progress: {
    margin: `${theme.spacing(1)} 0`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  inProgress: {
    "&$dot, &$line": {
      background: lighten(theme.palette.primary.main, 0.85),
      "&:before": {
        background: lighten(theme.palette.primary.main, 0.85),
      },
      "&:after": {
        background: lighten(theme.palette.primary.main, 0.85),
      },
    },
  },
  completed: {
    "&$dot, &$line": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:before": {
        background: theme.palette.primary.main,
      },
      "&:after": {
        background: theme.palette.primary.main,
      },
    },
  },
  dot: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    borderRadius: "50%",
    background: theme.palette.grey[200],
    zIndex: 200,
  },
  line: {
    position: "relative",
    width: theme.spacing(2),
    height: theme.spacing(1),
    background: theme.palette.grey[200],
    "&:before": {
      position: "absolute",
      display: "block",
      content: `""`,
      width: theme.spacing(1),
      left: theme.spacing(-1),
      height: theme.spacing(1),
      background: theme.palette.grey[200],
    },
    "&:after": {
      position: "absolute",
      display: "block",
      content: `""`,
      width: theme.spacing(1),
      right: theme.spacing(-1),
      height: theme.spacing(1),
      background: theme.palette.grey[200],
    },
  },
})

export default withStyles(journeyProgressStyles)(JourneyProgress)
