import {
  FormControl,
  IconButton,
  InputAdornment,
  List,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {arrayOf, bool, checkPropTypes, func, node, object, shape, string} from "prop-types"
import {Component, createRef} from "react"
import {MdClear as ClearIcon, MdArrowDropDown as DropDownArrowIcon} from "react-icons/md"

import humanize from "lib/string/humanize"
import score from "lib/string/score"

class ComplexSelect extends Component {
  state = {
    filter: {raw: "", value: ""},
    showPopover: false,
    selectedValue: this.props.value || {
      category: this.props.categories[0].category,
      value: this.props.values[this.props.categories[0].category][0],
    },
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.selectedItemRef.current)
        this.selectedItemRef.current.scrollIntoView({block: "nearest"})
    }, 50)
  }

  containerRef = createRef()
  inputRef = createRef()
  selectedItemRef = createRef()

  showPopover = () => {
    document.addEventListener("keydown", this.onKeyDown)
    this.setState({showPopover: true})
  }

  hidePopover = () => {
    document.removeEventListener("keydown", this.onKeyDown)
    this.setState({showPopover: false})
  }

  onKeyDown = event => {
    if (
      event.ctrlKey ||
      event.shiftKey ||
      event.altKey ||
      event.metaKey ||
      !this.selectionCollapsed()
    )
      return

    switch (event.key) {
      case "ArrowDown":
        return this.moveValueSelection(event, 1)
      case "ArrowUp":
        return this.moveValueSelection(event, -1)
      case "ArrowLeft":
        return this.moveCategorySelection(event, -1)
      case "ArrowRight":
        return this.moveCategorySelection(event, 1)
      case "Enter":
        return this.triggerOnChange(event)
      default:
        return
    }
  }

  selectionCollapsed = () => {
    const {selectionStart, selectionEnd} = this.inputRef.current

    return selectionStart === selectionEnd
  }

  moveValueSelection = (event, difference) => {
    const values = this.flattenedValues()

    if (values.length === 0) return

    event.preventDefault()

    const {selectedValue} = this.state

    const currentIndex = values.findIndex(
      ([category, value]) => category === selectedValue.category && value === selectedValue.value
    )

    let nextIndex = currentIndex + difference

    if (nextIndex < 0) nextIndex = 0
    else if (nextIndex >= values.length) nextIndex = values.length - 1

    const [nextCategory, nextValue] = values[nextIndex]

    this.setState({
      selectedValue: {
        category: nextCategory,
        value: nextValue,
      },
    })
  }

  moveCategorySelection = (event, difference) => {
    const {categories, values} = this.filteredValues()

    if (categories.length <= 1) return

    event.preventDefault()

    const {selectedValue} = this.state
    const categoryIndex = categories.findIndex(({category}) => category === selectedValue.category)
    const valueIndex = values[selectedValue.category].findIndex(
      ({value}) => value === selectedValue.value
    )

    let nextCategoryIndex = categoryIndex + difference
    let nextValueIndex = valueIndex

    if (nextCategoryIndex < 0) nextCategoryIndex = 0
    else if (nextCategoryIndex >= categories.length) nextCategoryIndex = categories.length - 1

    const nextCategory = categories[nextCategoryIndex].category

    if (nextValueIndex < 0) nextValueIndex = 0
    else if (nextValueIndex >= values[nextCategory].length)
      nextValueIndex = values[nextCategory].length - 1

    this.setState({
      selectedValue: {
        category: nextCategory,
        value: values[nextCategory][0].value,
      },
    })
  }

  select = (category, value) => {
    this.setState({selectedValue: {category, value}})
  }

  isSelected = (category, value) =>
    category === this.state.selectedValue.category && value === this.state.selectedValue.value

  mouseDownReset = event => event.preventDefault()
  reset = () => this.setFilter("")

  setFilter = raw => {
    this.setState({filter: {raw, value: raw.toLowerCase()}})
  }

  triggerOnChange = event => {
    event.preventDefault()
    this.hidePopover()

    const value = this.state.selectedValue

    this.props.onChange({target: {value}})
  }

  filteredValues = () => {
    const filteredValues = {}
    const {categories, values} = this.props
    const {
      filter: {value: filter},
    } = this.state
    let totalResults = 0

    const filteredCategories = categories.filter(({category}) => {
      filteredValues[category] = values[category]
        .map(value => ({value, similarity: filter ? score(value.name.toLowerCase(), filter) : 1}))
        .filter(({similarity}) => similarity >= 0.1)
        .sort((lhs, rhs) => (!filter ? 0 : rhs.similarity - lhs.similarity))
        .map(({value}) => value)

      totalResults += filteredValues[category].length

      return filteredValues[category].length
    })

    return {values: filteredValues, categories: filteredCategories, totalResults}
  }

  flattenedValues = () => {
    const {categories, values} = this.filteredValues()

    return categories
      .map(({category}) => values[category].map(({value}) => [category, value]))
      .flat()
  }

  currentValue = () => {
    const {categories, values, value: currentValue} = this.props
    const current = {}

    categories.forEach(category => {
      if (category.category !== currentValue.category) return

      current.category = category

      values[category.category].forEach(value => {
        if (value.value === currentValue.value) {
          current.value = value.value
          current.name = value.name
        }
      })
    })

    return current
  }

  render() {
    const {classes, disabled, label, noMatchMessage} = this.props
    const {
      showPopover,
      filter: {raw: rawFilter},
    } = this.state
    const {categories, values, totalResults} = this.filteredValues()
    const currentValue = this.currentValue()

    return (
      <FormControl ref={this.containerRef} classes={{root: classes.root}}>
        <TextField
          disabled={disabled}
          fullWidth={true}
          InputLabelProps={{
            shrink: !!currentValue.value,
          }}
          InputProps={{
            classes: {root: classes.inputBase},
            onClick: () => {
              if (!disabled) this.showPopover()
            },
            startAdornment: (
              <InputAdornment className={classes.startAdornment} position="start">
                {currentValue?.category?.icon || ""}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment className={classes.endAdornment} position="end">
                <DropDownArrowIcon size={25} />
              </InputAdornment>
            ),
          }}
          inputProps={{"data-testid": "complex-select", className: classes.textField}}
          label={label}
          value={currentValue?.name || ""}
        />
        <Popover
          anchorEl={this.containerRef.current}
          anchorOrigin={{horizontal: "left", vertical: "bottom"}}
          classes={{paper: classes.popover}}
          onClose={this.hidePopover}
          open={showPopover}
          transformOrigin={{horizontal: "left", vertical: "top"}}
        >
          <TextField
            autoFocus={true}
            className={classes.search}
            fullWidth={true}
            InputProps={{
              endAdornment: rawFilter && (
                <InputAdornment position="end">
                  <IconButton onClick={this.reset} onMouseDown={this.mouseDownReset} size="medium">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{"data-testid": "complex-select-filter", ref: this.inputRef}}
            label="Filter..."
            onChange={({target}) => this.setFilter(target.value)}
            value={rawFilter}
          />
          <div className={classes.columns}>
            {categories.map(({category, icon, label}) => (
              <div className={classes.category} key={category}>
                <Typography className={classes.subheader} variant="subtitle2">
                  {icon} {label || humanize(category)}
                </Typography>
                <List className={classes.list}>
                  {values[category].map(({value, name, renderContent}) => (
                    <MenuItem
                      className={cx(classes.menuItem, {
                        [classes.menuItemSelected]: this.isSelected(category, value),
                      })}
                      data-testid={`${category}-${value}-select-item`}
                      key={value}
                      onClick={this.triggerOnChange}
                      onMouseEnter={() => this.select(category, value)}
                      ref={this.isSelected(category, value) ? this.selectedItemRef : null}
                    >
                      {renderContent || name}
                    </MenuItem>
                  ))}
                </List>
              </div>
            ))}
            {totalResults === 0 && <div className={classes.noMatchMessage}>{noMatchMessage}</div>}
          </div>
        </Popover>
      </FormControl>
    )
  }
}

ComplexSelect.propTypes = {
  categories: arrayOf(
    shape({
      category: string.isRequired,
      icon: node,
      label: string,
    })
  ),
  classes: object,
  disabled: bool,
  label: string.isRequired,
  noMatchMessage: string,
  onChange: func.isRequired,
  value: shape({
    category: string,
    value: string,
  }),
  values: (props, propName, componentName) => {
    const validators = {}

    props.categories.forEach(({category}) => {
      validators[category] = arrayOf(
        shape({
          name: string,
          value: string,
          renderContent: node,
        })
      ).isRequired
    })

    checkPropTypes(validators, props.values, "values", `${componentName}.values`)
  },
}

const styles = theme => ({
  root: {},
  category: {
    minWidth: 250,
  },
  columns: {
    maxHeight: 370,
    overflow: "auto",
    textAlign: "left",
    marginBottom: theme.spacing(),
    "& > *": {
      flex: 1,
    },
    "& ul": {
      overflow: "auto",
      width: "100%",
    },
  },
  endAdornment: {
    color: theme.palette.action.active,
  },
  inputBase: {
    cursor: "pointer",
  },
  list: {},
  menuItem: {
    "&:hover": {
      background: "transparent",
    },
  },
  menuItemSelected: {
    backgroundColor: theme.palette.action.hover,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  noMatchMessage: {
    marginTop: theme.spacing(),
    fontStyle: "italic",
  },
  popover: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  search: {
    marginBottom: theme.spacing(),
  },
  startAdornment: {
    marginRight: theme.spacing(),
  },
  subheader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "#fff",
    display: "inline-flex",
    alignItems: "center",
    fontWeight: "bold",
    width: "100%",
    flex: "0 0 100%",
    marginTop: theme.spacing(),
    "& > svg": {
      marginRight: theme.spacing(),
    },
  },
  textField: {
    color: "transparent",
    textShadow: `0 0 0 ${theme.palette.text.primary}`,
    cursor: "pointer",
  },
})

export default withStyles(styles)(ComplexSelect)
