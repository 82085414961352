import React from "react"

import CrossChannelEngagement from "components/cross-channel-engagement/cross-channel-engagement"
import CrossChannelEngagementContainer from "components/cross-channel-engagement/cross-channel-engagement-container"

const ThemedCrossChannelEngagement = () => (
  <CrossChannelEngagementContainer
    render={({classes, contentContainer, contentContainerId}) => (
      <CrossChannelEngagement
        classes={classes}
        contentContainer={contentContainer}
        contentContainerId={contentContainerId}
      />
    )}
  />
)

export default ThemedCrossChannelEngagement
