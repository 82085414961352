import Box from "@mui/material/Box"

import {ExportButton} from "components/export-button/export-button"

import {fetchContacts, getMessageEligibilityReport} from "lib/api"
import useFeatures from "lib/hooks/use-features"

const ButtonBar = () => {
  const {hasFeature} = useFeatures()

  return (
    <Box
      sx={{
        marginBottom: 1.25,
        "& button": {
          marginRight: 1.25,
        },
      }}
    >
      {hasFeature("tcpa") ? (
        <ExportButton
          fetchRecords={getMessageEligibilityReport}
          filename="message_eligibility"
          title="Download Message Eligibility"
        />
      ) : (
        <ExportButton
          fetchRecords={fetchContacts}
          filename="contacts_unsubscribes"
          params={{isUnsubscribed: true}}
          title="Download Unsubscribes"
        />
      )}
    </Box>
  )
}

export default ButtonBar
