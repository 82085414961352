import {Checkbox, TextField} from "@mui/material"
import {func, object, shape, string} from "prop-types"

export const RFCheckbox = ({input}) => (
  <Checkbox checked={!!input.value} onChange={input.onChange} />
)

RFCheckbox.propTypes = {
  input: shape({
    onChange: func,
  }),
}

export const RFTextField = ({label, input, meta: {touched, invalid, error}, ...custom}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)

RFTextField.propTypes = {
  label: string,
  input: object,
  meta: object,
}
