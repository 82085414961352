// This file was generated automatically from character-05.svg with the following command:
// ./svg-converter.js src/components/superhero/character-05.svg
import SuperheroSvg from "./superhero-svg"

const Character05 = props => (
  <SuperheroSvg viewBox="260 80 110 230" {...props}>
    <g id="Layer_4">
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M336.41,301.85c-4.37,0.84-10.53,1.55-17.52,0.98l-0.09-0.02c-0.09-0.03-2.14-0.75-2.33-2.32
  				c-0.14-1.18,0.77-2.36,2.78-3.63l0.06-0.03c0.09-0.04,9.23-3.72,9.81-7.62l0.76,0.15c-0.64,4.27-9.55,7.94-10.26,8.23
  				c-2.1,1.33-2.47,2.27-2.4,2.82c0.11,0.94,1.5,1.51,1.75,1.61c12.73,1.03,22.69-2.26,23.81-2.64c0.99-1.54-1.73-6.89-3.72-9.96
  				l0.63-0.45c0.01,0.02,1.38,2.14,2.51,4.49c1.6,3.34,1.96,5.48,1.11,6.54l-0.07,0.08l-0.1,0.04
  				C343.12,300.14,340.58,301.05,336.41,301.85z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M322.9,302.89l-0.59-0.54c0.72-0.89,1.01-1.8,0.88-2.7c-0.25-1.7-1.92-2.89-1.94-2.9l0.41-0.68
  				c0.08,0.06,1.98,1.4,2.29,3.47C324.12,300.68,323.77,301.81,322.9,302.89z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M336.67,298.82c-2.96,0.57-7.15,1.15-13.02,1.57l-0.07-0.81c16.04-1.16,19.36-3.48,19.39-3.5
  				l0.46,0.67C343.35,296.8,341.98,297.8,336.67,298.82z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M323.31,299.28l-0.28-0.76c10.95-4.34,10.5-8.91,10.5-8.96l0.76-0.11
  				C334.32,289.66,334.94,294.68,323.31,299.28z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M325.44,297.15c-0.62,0.12-1.51-0.23-1.8-0.37l0.28-0.75c0.57,0.25,1.25,0.4,1.41,0.31
  				c0-0.01,0-0.02,0-0.04c-0.12-1.43,0.28-1.46,2.2-1.59c0.75-0.05,1.29-0.09,1.41-0.24c0.12-0.15,0.07-0.72,0-1.52
  				c-0.06-0.67,0.1-1.1,0.46-1.29c0.7-0.37,1.79,0.54,2.11,0.83l-0.49,0.61c-0.52-0.47-1.12-0.79-1.26-0.71c0,0-0.08,0.09-0.05,0.51
  				c0.19,2.28,0.03,2.47-2.11,2.62c-0.76,0.05-1.41,0.1-1.52,0.2c-0.02,0.05,0,0.31,0.02,0.53c0.03,0.32-0.08,0.58-0.29,0.75
  				C325.7,297.07,325.57,297.12,325.44,297.15z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M285.99,301.66l-0.04-0.02c-0.09-0.03-2.11-0.75-2.22-2.31c-0.09-1.18,0.88-2.36,2.97-3.63
  				l0.06-0.03c0.02-0.01,2.41-0.95,4.87-2.38c3.17-1.85,5.01-3.66,5.33-5.24l0.75,0.15c-0.85,4.27-9.95,7.94-10.67,8.22
  				c-2.17,1.33-2.58,2.26-2.54,2.82c0.06,0.84,1.19,1.43,1.68,1.61c12.71,1.02,22.84-2.27,23.97-2.66c1.24-1.69-1.05-6.74-2.06-8.56
  				l0.67-0.42c0.4,0.74,3.9,7.26,1.89,9.61l-0.07,0.08l-0.1,0.04c-0.11,0.04-10.83,3.84-24.43,2.73L285.99,301.66z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M290.05,301.71l-0.56-0.54c0.76-0.89,1.1-1.8,1.02-2.7c-0.16-1.7-1.78-2.89-1.8-2.9l0.45-0.68
  				c0.08,0.06,1.92,1.4,2.12,3.46C291.38,299.51,290.97,300.64,290.05,301.71z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M290.92,299.22l-0.03-0.81c16.12-1.17,19.56-3.49,19.59-3.51l0.43,0.67
  				C310.77,295.66,307.4,298.02,290.92,299.22z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M290.63,298.11l-0.25-0.76c11.18-4.34,10.95-8.91,10.95-8.96l0.77-0.11
  				C302.12,288.5,302.5,293.5,290.63,298.11z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M291.93,295.92c-0.37-0.09-0.69-0.23-0.85-0.3l0.32-0.75c0.56,0.25,1.23,0.4,1.4,0.3
  				c0-0.01,0-0.02,0-0.04c-0.02-0.55-0.04-1.02,0.41-1.29c0.32-0.19,0.8-0.23,1.87-0.3c0.75-0.05,1.3-0.09,1.43-0.24
  				c0.13-0.15,0.11-0.72,0.08-1.52c-0.02-0.67,0.15-1.1,0.52-1.29c0.72-0.37,1.77,0.54,2.07,0.82l-0.52,0.61
  				c-0.5-0.47-1.09-0.79-1.22-0.71c0,0-0.09,0.09-0.07,0.51c0.04,1.14,0.06,1.71-0.28,2.11c-0.34,0.4-0.88,0.44-1.96,0.51
  				c-0.76,0.05-1.42,0.1-1.53,0.2c-0.02,0.05-0.01,0.31,0,0.53c0.01,0.32-0.11,0.58-0.33,0.75
  				C292.9,296.07,292.39,296.03,291.93,295.92z"
          />
        </g>
      </g>
      <path
        style={{fill: "#609E92"}}
        d="M291.75,230.69l8.92-56.58l23.67-2.22l-5.46,16.6l-4.91,47.76c0,0,1.26,15.16,2.03,38.4l-25.06-1.49
  		C290.93,273.17,289.61,248.29,291.75,230.69z"
      />
      <path
        style={{display: "none", fill: "#5DBDAF"}}
        d="M374.52,238.86c-2.98-20.79-5.96-41.57-8.93-62.36c-1.74-3.41-3.48-6.83-5.21-10.24
  		c-5.66-2.01-11.32-4.02-16.97-6.02c-6.24-0.64-12.48-1.28-18.73-1.92c0.82,5.92,1.65,11.83,2.47,17.75
  		c-0.21,0.79-0.36,1.6-0.45,2.42l-0.24,1.31l0.16,0.02c-0.01,0.17-0.01,0.34-0.01,0.51c0,9.08,7.29,16.64,16.94,18.33
  		c-0.17,2.71-0.33,5.43-0.5,8.14c1.21,7.85,2.43,15.7,3.64,23.55c1.4,3.68,2.89,7.43,4.48,11.25c1.3,3.12,2.6,6.16,3.92,9.12
  		l21.25,33.87l30.09-4.26l-27.46-33.76C378.95,246.55,375.38,242.72,374.52,238.86z"
      />
      <path
        style={{fill: "#5DBDAF"}}
        d="M295.74,186.48l-10.94,103.78l61.95,0.18L337.3,188.4c0.03-0.47,0.04-0.94,0.05-1.42
  		c0.14-8.63-2.59-16.4-7.02-21.63l0.03-0.18l-30.7-9.04l-3.16,23.81c-0.22,1.19-0.4,2.4-0.51,3.64l-0.27,1.97l0.15,0.03
  		c-0.01,0.25-0.02,0.51-0.03,0.76"
      />
      <g>
        <path
          style={{fill: "#CC5F64"}}
          d="M299.8,144.27L299.8,144.27L299.8,144.27c-0.73-0.3-1.45-0.61-2.18-0.91
  			c1.38,0.58,2.76,1.16,4.14,1.74c0.62-1.27,1.25-2.54,1.87-3.81c1-2.04,2-4.07,2.99-6.11c0.23-0.47,0.46-0.93,0.68-1.4
  			c0.2-0.4,0.17-0.79-0.26-1.13c-0.4-0.32-1.24-0.6-2.1-0.66c-1.73-0.11-3.91,0.37-4.33,1.22c-0.62,1.27-1.25,2.54-1.87,3.81
  			c-1,2.04-2,4.07-2.99,6.11c-0.23,0.47-0.46,0.93-0.68,1.4c-0.27,0.54-0.08,1.06,0.79,1.44c1.27,0.55,3.36,0.3,3.36,0.3s0,0,0,0
  			c0.01,0,2.6-0.7,2.55-1.17C301.71,144.65,299.81,144.27,299.8,144.27z"
        />
        <path
          style={{fill: "#CC5F64"}}
          d="M301.49,153.74L301.49,153.74L301.49,153.74c-0.7,0.14-1.41,0.28-2.11,0.42
  			c1.34-0.26,2.68-0.53,4.02-0.79c-0.42-1.15-0.84-2.3-1.25-3.45c-0.67-1.84-1.34-3.68-2.01-5.53c-0.15-0.42-0.31-0.84-0.46-1.26
  			c-0.13-0.36-0.41-0.61-0.94-0.64c-0.49-0.02-1.26,0.18-1.89,0.55c-1.27,0.74-2.45,2.1-2.17,2.87c0.42,1.15,0.84,2.3,1.25,3.45
  			c0.67,1.84,1.34,3.68,2.01,5.53c0.15,0.42,0.31,0.84,0.46,1.26c0.18,0.49,0.65,0.75,1.5,0.59c1.24-0.23,2.52-1.38,2.52-1.38
  			s0,0,0,0c0-0.01,1.33-1.7,0.98-1.99C303.06,153.09,301.5,153.74,301.49,153.74z"
        />
        <g>
          <defs>
            <rect
              id="SVGID_00000068660994952506908070000011282559663755089539_"
              x="185.65"
              y="70.94"
              width="178.27"
              height="146.77"
            />
          </defs>
          <clipPath id="42bf53a4-SVGID_00000090262549127606671730000000021133988257046971_">
            <use
              xlinkHref="#SVGID_00000068660994952506908070000011282559663755089539_"
              style={{overflow: "visible"}}
            />
          </clipPath>
          <g style={{clipPath: "url(#SVGID_00000090262549127606671730000000021133988257046971_)"}}>
            <g>
              <g>
                <g>
                  <path
                    style={{fill: "#CC5F64"}}
                    d="M306.52,140.18c1.52-2.55,3.03-5.09,4.55-7.64c0.22-0.37,0.44-0.73,0.65-1.1
  								c0.42-1.25,0.84-2.5,1.26-3.74c0.09-0.45,0.17-1.17-0.14-1.89c-0.7-1.63-2.81-1.86-3.55-1.94c-0.49-0.05-2.17-0.25-3.74,0.82
  								c-1.32,0.9-1.77,2.03-3.45,5.62c-0.51,1.08-1.33,2.82-2.4,5.01C301.97,136.93,304.25,138.56,306.52,140.18z"
                  />
                </g>
                <path
                  style={{fill: "#8C715E"}}
                  d="M322.34,90.61c0.52,0,1.03,0.03,1.53,0.08c0.3,12.81,11.22,19.52,13.28,20.67
  							c-1.8,6.91-7.75,11.98-14.81,11.98c-8.49,0-15.37-7.33-15.38-16.37S313.85,90.61,322.34,90.61z"
                />
                <path
                  style={{fill: "#8C715E"}}
                  d="M306.97,106.94c0,7.27,4.8,13.44,10.96,15.58l-0.92,13.36l6.23,0l0.36-12.61
  							c4.26-0.36,8.04-2.58,10.61-5.88c0.55,0.42,1.22,0.67,1.95,0.67c1.86,0,3.36-1.6,3.36-3.58c0-1.57-0.95-2.9-2.27-3.38
  							c0-0.02,0.01-0.04,0.01-0.05c-0.11-0.06-0.21-0.12-0.32-0.19l-29.78-6.46C307.04,105.22,306.97,106.07,306.97,106.94z"
                />
                <path
                  style={{fill: "#8C715E"}}
                  d="M329.77,102.76l-20.44-4.43c-1.1,1.84-1.86,3.93-2.19,6.17l29.48,6.39
  							C333.53,109.12,331.09,106.24,329.77,102.76z"
                />
                <g>
                  <path
                    style={{fill: "#EB6669"}}
                    d="M333.7,136.82c0.66-5.12-2.71-9.83-7.51-10.53l-2.68-0.41l-2.89,8.07l-1.8,0.77l-1.44-1.15
  								l0.36-8.07l-2.81-0.18c-6.1-0.4-9.02,2.09-9.89,8l-2.9,18.63l29.21,0L333.7,136.82z"
                  />
                  <polygon
                    style={{fill: "#EB6669"}}
                    points="331.77,148.91 302.14,151.94 301.38,156.79 330.57,160.59 							"
                  />
                </g>
                <ellipse style={{fill: "#0D1E36"}} cx="315.18" cy="103.29" rx="1.23" ry="1.3" />
                <g>
                  <g>
                    <path
                      style={{fill: "#0D1E36"}}
                      d="M316.84,99.45c-0.28-0.3-0.68-0.5-1.06-0.63c-0.84-0.28-1.76-0.07-2.51,0.36
  									c-0.34,0.2-0.49,0.69-0.27,1.03c0.22,0.34,0.66,0.48,1.03,0.27c0.08-0.05,0.17-0.1,0.26-0.14c0.05-0.02,0.19-0.06-0.01,0
  									c0.04-0.01,0.08-0.03,0.12-0.05c0.15-0.05,0.3-0.09,0.46-0.11c-0.07,0.01-0.13,0.02-0.2,0.03c0.19-0.02,0.38-0.03,0.56,0
  									c-0.07-0.01-0.13-0.02-0.2-0.03c0.15,0.02,0.3,0.06,0.45,0.11c0.12,0.04-0.19-0.09-0.05-0.02c0.03,0.01,0.06,0.03,0.08,0.04
  									c0.05,0.03,0.1,0.06,0.15,0.09c0.04,0.03,0.08,0.05,0.12,0.08c0.09,0.06-0.17-0.14-0.09-0.07c0.01,0.01,0.03,0.02,0.04,0.04
  									c0.02,0.02,0.05,0.04,0.07,0.07c0.27,0.29,0.79,0.29,1.06,0C317.12,100.2,317.13,99.75,316.84,99.45L316.84,99.45z"
                    />
                  </g>
                </g>
                <path
                  style={{fill: "#8C715E"}}
                  d="M323.87,90.69c7.77,0.82,13.84,7.8,13.84,16.29c0,1.52-0.2,2.99-0.56,4.39
  							C335.09,110.21,324.17,103.5,323.87,90.69z"
                />
                <path
                  style={{fill: "#8C715E"}}
                  d="M323.89,90.79c0,0-4.25,5.07-12.83,5.07C311.06,95.86,315.95,89.69,323.89,90.79z"
                />
                <ellipse style={{fill: "#0D1E36"}} cx="325.69" cy="105.15" rx="1.23" ry="1.3" />
                <g>
                  <g>
                    <path
                      style={{fill: "#0D1E36"}}
                      d="M325.88,102.16c0.1-0.03,0.21-0.05,0.32-0.07c-0.07,0.01-0.13,0.02-0.2,0.03
  									c0.22-0.03,0.45-0.03,0.68,0c-0.07-0.01-0.13-0.02-0.2-0.03c0.13,0.02,0.25,0.05,0.37,0.08c0.04,0.01,0.07,0.02,0.11,0.04
  									c0.13,0.05-0.04-0.01-0.07-0.03c0.04,0.05,0.15,0.07,0.21,0.11c0.07,0.04,0.13,0.08,0.19,0.13
  									c0.13,0.09-0.13-0.11-0.03-0.02c0.03,0.03,0.06,0.05,0.09,0.08c0.07,0.06,0.13,0.12,0.19,0.19
  									c0.03,0.04,0.06,0.07,0.09,0.11c-0.16-0.16-0.03-0.03,0.01,0.02c0.11,0.16,0.25,0.29,0.45,0.34
  									c0.18,0.05,0.42,0.03,0.58-0.08c0.32-0.21,0.51-0.68,0.27-1.03c-0.43-0.62-1.01-1.11-1.75-1.32
  									c-0.49-0.14-0.99-0.14-1.49-0.05c-0.08,0.01-0.15,0.03-0.23,0.05c-0.37,0.12-0.65,0.52-0.52,0.92
  									C325.08,102.01,325.48,102.3,325.88,102.16L325.88,102.16z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      style={{fill: "#0D1E36"}}
                      d="M320.92,108.19c-0.13-0.27-0.32-0.52-0.53-0.73c-0.44-0.44-1.01-0.67-1.63-0.57
  									c-0.47,0.07-0.89,0.35-1.25,0.65c-0.15,0.13-0.13,0.38,0,0.51c0.15,0.15,0.36,0.13,0.51,0c0.14-0.11,0.29-0.22,0.45-0.31
  									c0.04-0.02,0.08-0.04,0.12-0.06c0.04-0.02,0,0-0.01,0c0.02-0.01,0.03-0.01,0.05-0.02c0.08-0.03,0.15-0.05,0.23-0.07
  									c0.03-0.01,0.06-0.01,0.09-0.02c-0.09,0.01,0.01,0,0.03,0c0.07,0,0.14,0,0.2,0c0.07,0-0.06-0.02,0.01,0
  									c0.03,0.01,0.06,0.01,0.09,0.02c0.06,0.01,0.11,0.04,0.17,0.05c0.05,0.01-0.06-0.03-0.02-0.01
  									c0.01,0.01,0.02,0.01,0.03,0.02c0.02,0.01,0.05,0.03,0.07,0.04c0.05,0.03,0.1,0.06,0.14,0.09c0.04,0.03,0.06,0.05,0.01,0.01
  									c0.02,0.02,0.04,0.03,0.06,0.05c0.04,0.03,0.08,0.07,0.12,0.11c0.07,0.07,0.13,0.14,0.19,0.22
  									c0.01,0.01-0.05-0.07-0.01-0.01c0.01,0.01,0.02,0.03,0.03,0.04c0.02,0.03,0.05,0.07,0.07,0.1c0.05,0.07,0.09,0.15,0.13,0.23
  									c0.08,0.18,0.34,0.22,0.5,0.13C320.97,108.58,321,108.37,320.92,108.19L320.92,108.19z"
                    />
                  </g>
                </g>
                <path
                  style={{fill: "#FFFFFF"}}
                  d="M324.15,113.03c-0.08-0.14-0.27-0.17-0.4-0.06c-0.3,0.24-0.85,0.75-1.64,0.96
  							c-1.08,0.27-2.05-0.15-2.26,0.16c-0.12,0.17,0.04,0.37,0.35,0.63s1.23,0.73,2.22,0.49c1.04-0.25,1.78-1.58,1.77-2.03
  							C324.19,113.11,324.17,113.07,324.15,113.03z"
                />
                <polygon
                  style={{fill: "#D9D8D6"}}
                  points="326.36,145.02 327.71,164.96 298.49,164.42 296.87,144.48 						"
                />
                <path
                  style={{fill: "#8C715E"}}
                  d="M317.53,160.96c0,0,0.52,0.32,0.38,0.57c-0.14,0.25-0.92,0.61-1.8,0.05
  							c-0.58-0.37-3.19-1.39-4.86,0.4c0,0-1.48,2.51,0.67,4.44c1.61,1.45,3.18,2.23,6.17,1.79c2.79-0.41,4.12-1.59,4.98-1.94
  							c3.03-0.36,6.05-0.72,9.08-1.08c0.44-0.05,0.88-0.1,1.32-0.16l-1.03-5.88c-3.09,0.37-6.18,0.74-9.27,1.11
  							c-0.32,0.04-0.64,0.08-0.95,0.11c-0.66-0.68-2.29-2.2-3.9-2.55c0,0-0.74-0.28-1.7,0.05
  							C315.66,158.21,316.18,160.21,317.53,160.96z"
                />
                <ellipse style={{fill: "#FFFFFF"}} cx="311.89" cy="154.32" rx="1.95" ry="2.07" />
              </g>
            </g>
            <path
              style={{fill: "none", stroke: "#F8D73E", strokeMiterlimit: "10"}}
              d="M317.7,125.15c0,0-0.19,4.22,1.35,6.03
  					c1.54,1.81,3.47-0.78,4.6-5.33"
            />
            <g>
              <path
                style={{fill: "#EB6669"}}
                d="M352.24,159.56c-0.35-0.78-0.69-1.55-1.04-2.33c-7.91-14.27-13.54-24.49-14.56-26.72
  						c-0.3-0.66-0.92-2.19-2.3-3.27c-0.24-0.27-2.31-2.55-5.17-2.14c-0.43,0.06-2.95,0.49-3.85,2.65c-0.29,0.69-0.32,1.34-0.31,1.79
  						c0,0-0.34,2.05-0.37,2.19c-0.45,2.86,9.21,17.47,9.21,17.47c1.21,1.89,1.81,2.84,1.81,2.84c0,0-0.34-0.55-1.03-1.64
  						c0.97,1.78,1.94,3.56,2.91,5.34c-1.14,0.07-2.27,0.14-3.41,0.2c-2.77,0.4-3.84,0.56-3.84,0.56c0,0,3.84-0.53,3.84-0.56
  						c0-0.02-2.91,0.34-7.39,0.92c0.32,3.85,0.64,7.7,0.96,11.55c6.59-0.6,13.18-1.19,19.77-1.79c0.57-0.06,2.24-0.33,3.45-1.79
  						c0.37-0.44,0.62-0.9,0.78-1.29c0.19-0.24,0.4-0.56,0.56-0.96C352.82,161.24,352.39,159.94,352.24,159.56z M346.63,156.7
  						c-0.02,0-0.03-0.02-0.03-0.04c0-0.02,0.02-0.04,0.03-0.04c0.02,0,0.03,0.02,0.03,0.04C346.66,156.68,346.65,156.7,346.63,156.7
  						z"
              />
            </g>
            <path
              style={{fill: "#EB6669"}}
              d="M322.73,137.66c-0.79-5.14,0.01-8.86,1.63-9.4c0.25-0.08,1.37-0.37,3.36,1.74"
            />
          </g>
        </g>
        <path
          style={{fill: "#123252"}}
          d="M305.47,113.5c1.23,4.8,3.24,9.32,5.36,13.76c1.38,2.88,2.47,5.26,5.48,6.52
  			c5.18,2.18,11.5,0.94,15.73-2.94c7.45-6.84,10.96-20.94,9.5-31.11c-1.62-11.33-14.55-19.56-24.77-16.3
  			c-6.81,2.18-10.48,8.46-11.78,15.46C304.08,103.79,304.33,109.05,305.47,113.5z M315.9,121.86c-0.36-0.25-0.67-0.58-0.96-0.93
  			c-1.84-2.18-2.98-4.93-3.66-7.81c-0.49-2.07-0.75-4.21-0.85-6.26c-0.2-3.65,0.78-7.09,2.48-10.25c1.49-2.77,4.66-5.54,7.83-4.68
  			c1.96,0.53,3.58,1.87,4.97,3.39c1.9,2.08,3.21,4.62,5.33,6.48c0.59,0.51,1.21,0.99,1.89,1.37c0.1,0.06,1.47,0.78,1.49,0.7
  			c-0.13,0.79-0.34,1.56-0.54,2.33c-1.44,5.31-3.78,10.8-8.27,13.94c-0.3,0.21-0.6,0.4-0.91,0.58c-2.21,1.28-5.87,2.45-8.35,1.38
  			C316.19,122.04,316.04,121.96,315.9,121.86z"
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Character05
