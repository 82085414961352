import {googleFontFamily, loadGoogleFontFromFamily} from "../fonts"

const getStyle = (styles, type) => {
  const key = styles.filter(val => val.startsWith(type)).first()

  return key && key.replace(`${type}-`, "")
}

const customStyleFn = styles => {
  const newInlineStyle = {}

  const isItalic = styles.filter(s => s === "ITALIC")
  const isBold = styles.filter(s => s === "BOLD")
  const isUnderline = styles.filter(s => s === "UNDERLINE")
  const fontColor = getStyle(styles, "font-color")
  const fontSize = getStyle(styles, "font-size")
  const fontFamily = getStyle(styles, "font-family")

  if (fontColor) newInlineStyle.color = fontColor
  if (fontSize) newInlineStyle.fontSize = `${fontSize}px`
  if (fontFamily) {
    const googleFontFamilyString = googleFontFamily(fontFamily)

    if (googleFontFamilyString) loadGoogleFontFromFamily(googleFontFamilyString)

    newInlineStyle.fontFamily = fontFamily
  }
  if (isBold.count() > 0) newInlineStyle.fontWeight = "bold"
  if (isItalic.count() > 0) newInlineStyle.fontStyle = "italic"
  if (isUnderline.count() > 0) newInlineStyle.textDecoration = "underline"

  return newInlineStyle
}

export default customStyleFn
