import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"
import {useState} from "react"
import {MdDelete as DeleteIcon} from "react-icons/md"
import {TiPlus as PlusIcon} from "react-icons/ti"

import ColorInput from "components/color-input/color-input"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"
import ResetLabel from "components/teams/branding-settings/reset-label"

import FontSelector from "../../font-selector/font-selector"
import FontWeightSelector from "./font-weight-selector"
import {unparse} from "./helpers"

const useStyles = makeStyles({
  titleContainer: {
    paddingBottom: "0 !important",
    paddingTop: "24px !important",
    "&:first-child": {
      paddingTop: "12px !important",
    },
  },
  title: {
    fontSize: 21,
    fontWeight: "bold",
  },
})

const GeneralSettings = ({change, field, muiTheme}) => {
  const classes = useStyles()
  const [customPalette, setCustomPalette] = useState(
    Array.isArray(muiTheme.custom?.palette) ? muiTheme.custom?.palette : []
  )

  const handleChangeCustomColorName = (changedIndex, value) => {
    const changed = customPalette.map((color, index) =>
      index === changedIndex ? {hex: color.hex, name: value} : color
    )
    change("custom.palette", changed)
    setCustomPalette(changed)
  }

  const handleDeleteCustomColor = deleteIndex => {
    const filtered = customPalette.filter((_, index) => index !== deleteIndex)
    change("custom.palette", filtered)
    setCustomPalette(filtered)
  }

  const handleAddCustomColor = () => {
    const added = [
      ...customPalette,
      {
        hex: muiTheme.palette.secondary.main,
        name: `Custom Color #${customPalette.length + 1}`,
      },
    ]
    change("custom.palette", added)
    setCustomPalette(added)
  }

  const handleChangeCustomColorHex = (changedIndex, value) => {
    const changed = customPalette.map((color, index) =>
      index === changedIndex ? {hex: value, name: color.name} : color
    )
    change("custom.palette", changed)
    setCustomPalette(changed)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.title}>Colors</Typography>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="primaryColor"
            input={{
              ...field("palette.primary.main", {defaultValue: muiTheme.palette.primary.main}),
              onChange: hex => change("palette.primary.main", hex),
            }}
            label={<ResetLabel name="palette.primary.main">Primary Color</ResetLabel>}
            hideCustomPalette
          />
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="secondaryColor"
            input={{
              ...field("palette.secondary.main", {defaultValue: muiTheme.palette.secondary.main}),
              onChange: hex => change("palette.secondary.main", hex),
            }}
            label={<ResetLabel name="palette.secondary.main">Secondary Color</ResetLabel>}
            hideCustomPalette
          />
        </Grid>
        {customPalette.map(({hex, name}, index) => (
          <Grid container spacing={3} item key={index} alignItems="flex-end">
            <Grid item lg={5} xs={12}>
              <TextField
                fullWidth
                id={`customColorName${index}`}
                name={`customColorName${index}`}
                margin="none"
                label={<ResetLabel name={`customColorName${index}`}>Color Name</ResetLabel>}
                value={name}
                onChange={({target: {value}}) => handleChangeCustomColorName(index, value)}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <ColorInput
                fullWidth
                id={`customColorHex${index}`}
                name={`customColorHex${index}`}
                input={{
                  name: `customColorHex${index}`,
                  onChange: hex => handleChangeCustomColorHex(index, hex),
                  value: hex,
                }}
                label={<ResetLabel name={`customColorHex${index}`}>Color</ResetLabel>}
                hideCustomPalette
              />
            </Grid>
            <Grid item xs={12} lg={1}>
              <IconButton onClick={() => handleDeleteCustomColor(index)} size="medium">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button color="primary" type="button" variant="contained" onClick={handleAddCustomColor}>
            <PlusIcon />
            Add a color
          </Button>
        </Grid>
        <Grid item lg={12} md={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.title}>Text</Typography>
        </Grid>

        <Grid item lg={3} md={false} xs={false} />
        <Grid item lg={6} md={12} xs={12}>
          <ThemeProvider theme={muiTheme}>
            <Typography variant="body1" gutterBottom={true}>
              Lorem ipsum
            </Typography>
            <Typography>
              dolor sit amet, consectetur adipiscing elit. Vestibulum viverra.
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item lg={3} md={false} xs={false} />

        <Grid item lg={6} md={12} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="fontFamily">
              <ResetLabel name="typography.fontFamily">Font Family</ResetLabel>
            </InputLabel>
            <FontSelector
              enableGoogleFonts={true}
              id="fontFamily"
              {...field(`typography.fontFamily`, {
                defaultValue: muiTheme.typography.fontFamily,
                exclude: ["helperText"],
              })}
              onSelect={({value}) => change(`typography.fontFamily`, `${value}`)}
            />
          </FormControl>

          <TextField
            fullWidth
            helperText="Must be a number; other typography settings are calculated assuming this is a pixel value."
            id="fontSize"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="typography.fontSize">Font Size</ResetLabel>}
            margin="normal"
            type="number"
            {...field(`typography.fontSize`, {
              defaultValue: muiTheme.typography.fontSize,
            })}
          />

          <FontWeightSelector
            label={<ResetLabel name="typography.fontWeightRegular">Font Weight</ResetLabel>}
            margin="normal"
            {...field(`typography.fontWeightRegular`, {
              defaultValue: muiTheme.typography.fontWeightRegular,
              exclude: ["helperText"],
            })}
          />

          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="lineHeight"
            InputLabelProps={{shrink: true}}
            margin="normal"
            label={<ResetLabel name="typography.body1.lineHeight">Line Height</ResetLabel>}
            type="text"
            {...field(`typography.body1.lineHeight`, {
              defaultValue: muiTheme.typography.body1.lineHeight,
            })}
          />
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="letterSpacing"
            InputLabelProps={{shrink: true}}
            margin="normal"
            label={<ResetLabel name="typography.body1.letterSpacing">Letter Spacing</ResetLabel>}
            type="text"
            {...field(`typography.body1.letterSpacing`, {
              defaultValue: muiTheme.typography.body1.letterSpacing || "0px",
            })}
          />

          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="marginBottom"
            InputLabelProps={{shrink: true}}
            margin="normal"
            label={
              <ResetLabel name="overrides.MuiTypography.body1.marginBottom">
                Paragraph Margin Bottom
              </ResetLabel>
            }
            type="text"
            {...field(`overrides.MuiTypography.body1.marginBottom`, {
              defaultValue:
                unparse(muiTheme.components?.MuiTypography?.styleOverrides?.body1?.marginBottom) ||
                "6px",
            })}
          />

          <ColorInput
            fullWidth
            id="general-color"
            input={{
              ...field(`overrides.MuiTypography.body1.color`, {
                defaultValue:
                  muiTheme.components?.MuiTypography?.styleOverrides?.body1?.color ||
                  muiTheme.palette.text.primary,
              }),
              onChange: hex => change(`overrides.MuiTypography.body1.color`, hex),
            }}
            label={<ResetLabel name="overrides.MuiTypography.body1.color">Color</ResetLabel>}
            style={{marginBottom: 8, marginTop: 16}}
          />
        </Grid>
      </Grid>
    </>
  )
}

GeneralSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  muiTheme: object.isRequired,
}

export default brandingSettings(GeneralSettings)
