export default {
  clear() {
    document.cookie.split(";").forEach(c => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`)
    })
  },

  getItem(name) {
    return document.cookie.split(/;/).reduce((prev, item) => {
      const [key, value] = item.trim().split(/=/)

      return key === name ? value : prev
    }, null)
  },

  key(index) {
    return document.cookie.match(/[^; .+]+?(?==)/gim)[index] || null
  },

  removeItem: name => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`

    return null
  },

  setItem(name, value) {
    const date = new Date()
    const isSecure = process.env.NODE_ENV === "prod"

    date.setFullYear(date.getFullYear() + 1)
    document.cookie = `${name}=${value}; samesite=strict;${
      isSecure ? " secure; " : ""
    }expires=${date.toUTCString()}; path=/`
  },

  get length() {
    return document.cookie ? document.cookie.match(/[^; .+?(?==)].+?(?=;|$)/gim).length : 0
  },
}
