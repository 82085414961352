import {Button, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {string} from "prop-types"
import {FiMap as MapIcon} from "react-icons/fi"

import {useCampaignBuilder} from "../campaign-builder/campaign-builder-context"
import {TemplateListPreview} from "../dialogs/template-list-preview"
import ContentLibraryImage from "./content-library-image"
import SmsBubble from "./sms-bubble"

const useStyles = makeStyles(theme => ({
  root: {
    width: 306,
  },
  image: {
    width: "100%",
    height: 217.5,
    boxShadow: "none !important",
  },
  list: {
    paddingLeft: theme.spacing(3),
  },
  button: {
    marginBottom: 5,
  },
  buttonIcon: {
    marginRight: 15,
  },
}))

const usePreviewStyles = makeStyles(() => ({
  image: {
    width: "100%",
    height: 217.5,
    boxShadow: "none !important",
  },
}))

export const Preview = ({smsPreviewText, contentType, ...props}) => {
  const classes = usePreviewStyles()

  switch (contentType) {
    case "email":
    case "page":
      return <ContentLibraryImage className={classes.image} contentType={contentType} {...props} />
    case "sms":
      return <SmsBubble inTooltip={true} text={smsPreviewText} />
    default:
      throw new Error(`Unrecognized content type: ${contentType}`)
  }
}

Preview.propTypes = {
  contentType: string,
  smsPreviewText: string,
}

const ContentPreview = props => {
  const {bodyPreview, id, type, contentName} = props
  const classes = useStyles()
  const {startCampaign} = useCampaignBuilder()

  const previewProps = {
    bodyPreview,
    contentType: type,
    id,
  }

  const onStartCampaign = () => startCampaign(props)

  return (
    <div className={classes.root}>
      <Preview {...previewProps} />
      <Typography variant="subtitle2">{contentName}</Typography>
      <TemplateListPreview id={id} recordType={type} variant="body2" />
      <Button
        className={classes.button}
        color="primary"
        fullWidth={true}
        onClick={onStartCampaign}
        variant="contained"
      >
        <MapIcon className={classes.buttonIcon} />
        Start a Campaign...
      </Button>
    </div>
  )
}

ContentPreview.propTypes = {
  bodyPreview: string,
  contentName: string,
  id: string.isRequired,
  type: string.isRequired,
}

export default ContentPreview
