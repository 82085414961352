import {Typography} from "@mui/material"
import {node, object, string} from "prop-types"

const Header = ({classes, variant, children}) => (
  <Typography variant={variant} gutterBottom={true}>
    {children}
  </Typography>
)

Header.propTypes = {
  children: node,
  classes: object,
  variant: string,
}

export default Header
