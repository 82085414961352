import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {func, string} from "prop-types"
import {useEffect} from "react"
import {Link} from "react-router-dom"

import {useForm} from "lib/hooks/use-form"

import CenteredBox from "../centered-box/centered-box"
import DocumentTitle from "../document-title/document-title"

const useStyles = makeStyles({
  actions: {
    display: "block",
    marginTop: 20,
  },
  field: {
    margin: "10px 0",
  },
  content: {
    width: 400,
  },
  forgotPassword: {
    display: "block",
    color: "initial",
    textDecoration: "none",
    textAlign: "center",
  },
  alternativeActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})

const Login = ({serverError, onSubmit}) => {
  const classes = useStyles()

  const {field, change, inputs, handleSubmit} = useForm({
    onSubmit,
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const email = queryParams.get("email")
    if (email) change("email", email)
  }, [change])

  return (
    <CenteredBox classes={{content: classes.content}} errorMessage={serverError} title="Sign In">
      <DocumentTitle title="Sign in" />
      <form onSubmit={handleSubmit}>
        <TextField
          className={classes.field}
          fullWidth={true}
          id="email"
          label="Email"
          {...field("email")}
        />
        <TextField
          className={classes.field}
          fullWidth={true}
          id="password"
          label="Password"
          type="password"
          {...field("password")}
        />
        <FormGroup className={classes.alternativeActions}>
          <FormControlLabel
            control={<Checkbox color="primary" {...field("rememberMe", {bool: true})} />}
            label="Remember me"
          />
          <Typography
            className={classes.forgotPassword}
            color="initial"
            component={Link}
            to="/admin/account/forgot-password"
            variant="body1"
          >
            Forgot your password?
          </Typography>
        </FormGroup>
        {inputs.rememberMe?.checked && (
          <Typography variant="caption">
            Your session will be authorized for up to 14 days of inactivity. This should only be
            checked on trusted devices.
          </Typography>
        )}
        <FormControl className={classes.actions} fullWidth={true} margin="normal">
          <Button color="primary" fullWidth={true} type="submit" variant="contained">
            Sign In
          </Button>
        </FormControl>
      </form>
    </CenteredBox>
  )
}

Login.propTypes = {
  onSubmit: func.isRequired,
  serverError: string,
}

export default Login
