import {connect} from "react-redux"

import ForgotPassword from "./forgot-password"
import {onSubmit} from "./forgot-password-actions"

const mapStateToProps = ({forgotPassword}) => ({wasSubmitted: forgotPassword.wasSubmitted})

const addActionsToProps = {onSubmit}

export default connect(mapStateToProps, addActionsToProps)(ForgotPassword)
