import omit from "lodash/omit"
import shortid from "shortid"

import * as questionTypes from "./survey-question-types"

export const ADD_QUESTION = "ADD_QUESTION"
export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const UPDATE_NAME = "UPDATE_NAME"
export const UPDATE_CTA_SETTINGS = "UPDATE_OTHER_SETTINGS"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const RESET_QUESTIONS = "RESET_QUESTIONS"

export const addQuestion = () => ({
  type: ADD_QUESTION,
  payload: {slug: shortid.generate(), type: Object.values(questionTypes)[0]},
})

export const updateName = payload => ({
  type: UPDATE_NAME,
  payload,
})

export const updateCtaSettings = payload => ({
  type: UPDATE_CTA_SETTINGS,
  payload,
})

export const updateQuestion = payload => ({
  type: UPDATE_QUESTION,
  payload,
})

export const deleteQuestion = payload => ({
  type: DELETE_QUESTION,
  payload,
})

export const resetQuestions = payload => ({
  type: RESET_QUESTIONS,
  payload,
})

export const initFromProps = ({questions, ...otherSettings}) => ({
  ...otherSettings,
  questions: questions.reduce((acc, q) => ({...acc, [q.slug]: q}), {}),
})
export const initFromQuestion = ({answers = []}) =>
  answers.reduce((acc, q) => ({...acc, [q.value]: q}), {})

export const surveySettingsReducer = (state, action) => {
  switch (action.type) {
    case ADD_QUESTION:
    case UPDATE_QUESTION:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.slug]: action.payload,
        },
      }

    case UPDATE_NAME:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_CTA_SETTINGS:
      return {
        ...state,
        ...action.payload,
      }

    case DELETE_QUESTION:
      return {
        ...state,
        questions: omit(state.questions, [action.payload.slug]),
      }

    case RESET_QUESTIONS:
      return action.payload

    default:
      return state
  }
}
