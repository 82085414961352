import {FormControl, InputLabel} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {Editor} from "draft-js"
import {bool, func, object, oneOf, string} from "prop-types"
import {PureComponent} from "react"
import * as React from "react"
import ReactDOM from "react-dom"

import contentBlockEditorBlockRenderMap from "lib/draft-js/block-renderers"

import EditorContext, {ContentType} from "../../contexts/editor-context"
import mainTheme from "../../themes/main"
import EditorBlock from "./EditorBlock"
import {createEditorStateFromContent} from "./editor-helpers"
import "./editor.css"
import getDecorators from "./entities"
import Toolbar from "./toolbar/text-editor-toolbar"

const findAlignment = (alignment, direction) => {
  switch (alignment) {
    case "start":
      return direction === "ltr" ? "left" : "right"
    case "end":
      return direction === "ltr" ? "right" : "left"
    default:
      return alignment
  }
}

class RichTextEditor extends PureComponent {
  state = {
    focused: false,
    isReadOnly: false,
    editorState: createEditorStateFromContent(this.props.initialValue),
  }

  componentDidMount() {
    if (this.props.autoFocus) this.focus()

    const element = ReactDOM.findDOMNode(this.contentEditorRef.current)
    const style = window.getComputedStyle(element)
    const alignment = style.getPropertyValue("text-align")
    const direction = style.getPropertyValue("direction")

    this.setState({
      defaultAlignment: findAlignment(alignment, direction),
    })
  }

  blockRendererFn = () => ({component: EditorBlock})

  contentEditorRef = this.props.forwardRef || React.createRef()

  onChange = (editorState, callback) => {
    const oldContentState = this.state.editorState.getCurrentContent()

    this.setState({editorState}, () => {
      if (!editorState.getCurrentContent().equals(oldContentState)) this.props.onChange(editorState)

      if (callback) callback()
    })
  }

  // This is used by the EditorContext consumers below
  onEditorUpdate = updater => this.onChange(updater(this.state.editorState))

  setReadOnly = isReadOnly => this.setState({isReadOnly})

  editorContext = {
    onEditorUpdate: this.onEditorUpdate,
    setReadOnly: this.setReadOnly,
    ...(this.props.options || {}),
  }

  onFocus = () => {
    this.setState({focused: true})
    if (this.props.onFocus) this.props.onFocus()
  }
  onBlur = () => {
    this.setState({focused: false})
    if (this.props.onBlur) this.props.onBlur()
  }

  blur = () => {
    if (this.state.focused) {
      this.contentEditorRef.current.blur()
      this.setState({focused: false}, () => {
        if (this.props.onBlur) this.props.onBlur()
      })
    }
  }

  focus = () => {
    if (!this.state.focused) {
      this.contentEditorRef.current.focus()
      this.setState({focused: true}, () => {
        if (this.props.onFocus) this.props.onFocus()
      })
    }
  }

  render() {
    const {
      autoFocus,
      classes,
      className,
      contentType,
      label,
      viewerMode,
      onBlur,
      onFocus,
      options,
      showToolbar,
      ...editorProps
    } = this.props
    const {defaultAlignment, focused, isReadOnly: stateReadOnly, editorState} = this.state
    const {menuButtonIcon, toolbar, toolbarContainer} = classes

    const isReadOnly = viewerMode || stateReadOnly

    return (
      <div className={classes.root}>
        <EditorContext.Provider
          value={{
            ...this.editorContext,
            contentType,
            defaultAlignment,
          }}
        >
          <div className={classes.editorWrapper}>
            <FormControl className={cx(classes.formControl, className)}>
              {label && (
                <InputLabel
                  className={cx(classes.label, {[classes.labelFocused]: focused})}
                  focused={focused}
                  shrink={true}
                >
                  {label}
                </InputLabel>
              )}
              <div onClick={this.focus}>
                <Editor
                  {...editorProps}
                  autoFocus={autoFocus}
                  blockRendererFn={this.blockRendererFn}
                  blockRenderMap={contentBlockEditorBlockRenderMap}
                  decorators={[getDecorators()]}
                  editorState={editorState}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  readOnly={isReadOnly}
                  ref={this.contentEditorRef}
                  spellCheck={!isReadOnly}
                  stripPastedStyles={true}
                />
              </div>
            </FormControl>
          </div>
          {!viewerMode && showToolbar !== false && (
            <ThemeProvider theme={mainTheme}>
              <Toolbar
                classes={{menuButtonIcon, toolbar, toolbarContainer}}
                editorState={editorState}
                onSetEditorState={this.onChange}
              />
            </ThemeProvider>
          )}
        </EditorContext.Provider>
      </div>
    )
  }
}

RichTextEditor.defaultProps = {
  viewerMode: false,
}

RichTextEditor.propTypes = {
  autoFocus: bool,
  className: string,
  classes: object,
  contentType: oneOf([
    ContentType.ContentContainer,
    ContentType.Page,
    ContentType.EmailBody,
    ContentType.EmailSubject,
    ContentType.Sms,
    ContentType.TemplateFooter,
  ]).isRequired,
  editorState: object,
  forwardRef: object,
  initialValue: object,
  label: string,
  onBlur: func,
  onChange: func,
  onFocus: func,
  onTab: func,
  options: object,
  readOnly: bool,
  showToolbar: bool,
  viewerMode: bool,
}

const styles = ({palette, spacing, transitions}) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    width: "100%",
  },
  editorWrapper: {
    position: "relative",
  },
  errorText: {
    marginTop: 10,
    color: palette.error.main,
  },
  label: {
    color: "#5e68718a",
  },
  labelFocused: {
    color: `rgb(0, 114, 178)`,
  },
  menuButtonIcon: {
    minWidth: "33px !important",
  },
  toolbar: {},
  toolbarContainer: {},
})

const StyledEditor = withStyles(styles)(RichTextEditor)

export default React.forwardRef((props, ref) => <StyledEditor {...props} forwardRef={ref} />)
