import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import debounce from "lodash/debounce"
import {array, bool, func, instanceOf, object, shape, string} from "prop-types"
import {useCallback, useEffect, useMemo, useState} from "react"

import DatePicker from "components/date-picker/date-picker"

import {validateAddressWithUsps} from "lib/api"
import {validDate, validEmail} from "lib/field-validations"

import * as questionTypes from "./survey-question-types"

// NB: Survey answer validation logic lives in survey-readonly-helpers.js answerHasErrors().

const TextAnswer = ({
  multiline,
  themeClasses,
  onChange,
  question: {hasError, answer: currentAnswer = ""},
  "aria-labelledby": ariaLabelledby,
  type = "text",
  errorText = "Please provide an answer for this question.",
  onBlur = () => null,
}) => {
  const [answer, setAnswer] = useState(currentAnswer)

  const _onChange = ({target: {value}}) => {
    setAnswer(value)
    onChange(value)
  }

  useEffect(() => {
    setAnswer(currentAnswer)
  }, [currentAnswer])

  return (
    <FormControl className={themeClasses.surveyQuestion} error={hasError} fullWidth={true}>
      <Input
        inputProps={{"aria-labelledby": ariaLabelledby}}
        multiline={multiline}
        onChange={_onChange}
        value={answer}
        type={type}
        onBlur={onBlur}
      />
      {hasError && (
        <FormHelperText aria-atomic="true" aria-live="assertive" role="alert">
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

TextAnswer.propTypes = {
  "aria-labelledby": string,
  multiline: bool,
  onChange: func.isRequired,
  question: shape({hasError: bool, answer: string}),
  themeClasses: object.isRequired,
  type: string,
  errorText: string,
  onBlur: func,
}

export const ShortAnswer = props => <TextAnswer {...props} />

export const NumberAnswer = props => (
  <ShortAnswer {...props} type="number" inputmode="numeric" step="any" />
)

const EmailAnswer = ({
  multiline,
  themeClasses,
  onChange,
  question: {hasError, answer: currentAnswer = ""},
  "aria-labelledby": ariaLabelledby,
}) => {
  const [isInvalid, setIsInvalid] = useState(false)

  useEffect(() => {
    setIsInvalid(!!validEmail(currentAnswer))
  }, [currentAnswer, setIsInvalid])

  return (
    <FormControl
      className={themeClasses.surveyQuestion}
      error={hasError && isInvalid}
      fullWidth={true}
    >
      <Input
        inputProps={{"aria-labelledby": ariaLabelledby}}
        multiline={multiline}
        onChange={({target: {value}}) => onChange(value)}
        value={currentAnswer}
        type="email"
      />
      {hasError && isInvalid && (
        <FormHelperText aria-atomic="true" aria-live="assertive" role="alert">
          Please use a valid email.
        </FormHelperText>
      )}
    </FormControl>
  )
}

EmailAnswer.propTypes = {
  "aria-labelledby": string,
  multiline: bool,
  onChange: func.isRequired,
  question: shape({hasError: bool, answer: string}),
  themeClasses: object.isRequired,
}

export const DateAnswer = ({
  multiline,
  themeClasses,
  onChange,
  question,
  "aria-labelledby": ariaLabelledby,
}) => {
  const [isInvalid, setIsInvalid] = useState(false)

  // Set currentAnswer whenever the question.answer changes (ie. when we receive api data on this contact's
  // answers to this survey)
  const currentAnswer = useMemo(() => (!!question.answer ? new Date(question.answer) : null), [
    question.answer,
  ])

  useEffect(() => {
    setIsInvalid(!!currentAnswer && !!validDate(currentAnswer))
  }, [currentAnswer, setIsInvalid])

  useEffect(() => {
    onChange(currentAnswer)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControl className={themeClasses.surveyQuestion} error={isInvalid} fullWidth={true}>
      <DatePicker
        inputProps={{"aria-labelledby": ariaLabelledby}}
        multiline={multiline}
        onChange={({target: {value}}) => onChange(value)}
        value={currentAnswer}
        name={question.slug}
      />
      {isInvalid && (
        <FormHelperText aria-atomic="true" aria-live="assertive" role="alert">
          Please provide a valid date in the format "MM/dd/yyyy".
        </FormHelperText>
      )}
    </FormControl>
  )
}

DateAnswer.propTypes = {
  "aria-labelledby": string,
  multiline: bool,
  onChange: func.isRequired,
  question: shape({
    hasError: bool,
    answer: instanceOf(Date),
    slug: string,
  }),
  themeClasses: object.isRequired,
}

export const Paragraph = props => <TextAnswer multiline={true} {...props} />

export const MultipleChoice = ({
  question: {hasError, answers, answer: currentAnswer = "", slug},
  onChange,
  themeClasses,
  "aria-labelledby": ariaLabelledby,
}) => {
  const [answer, setAnswer] = useState(currentAnswer)

  const _onChange = ({target: {value}}) => {
    setAnswer(value)
  }

  useEffect(() => {
    setAnswer(currentAnswer)
  }, [currentAnswer])

  useEffect(() => {
    onChange(answer)
  }, [answer, onChange])

  return (
    <div className={themeClasses.surveyQuestion}>
      <FormControl
        classes={{root: themeClasses.surveyAnswerGroup}}
        component="fieldset"
        error={hasError}
      >
        <RadioGroup
          aria-labelledby={ariaLabelledby}
          name={slug}
          onChange={_onChange}
          value={answer}
        >
          {answers.map(a => (
            <FormControlLabel
              classes={{root: themeClasses.surveyAnswer}}
              control={<Radio color="primary" inputProps={{"aria-label": a.title}} />}
              key={a.value}
              label={a.title}
              value={a.value}
            />
          ))}
        </RadioGroup>
        {hasError && (
          <FormHelperText aria-atomic="true" aria-live="assertive" role="alert">
            Please provide an answer for this question.
          </FormHelperText>
        )}
      </FormControl>
    </div>
  )
}

MultipleChoice.propTypes = {
  "aria-labelledby": string,
  onChange: func.isRequired,
  question: shape({
    hasError: bool,
    answer: string,
    answers: array,
    slug: string,
  }),
  themeClasses: object.isRequired,
}

export const Checkboxes = ({
  question,
  themeClasses,
  "aria-labelledby": ariaLabelledby,
  onChange,
}) => {
  const {answers, answer: currentAnswer = []} = question

  const [answer, setAnswer] = useState(currentAnswer)

  const _onChange = ({target: {checked, value}}) => {
    if (checked) setAnswer([...answer, value])
    else setAnswer(answer.filter(a => a !== value))
  }

  useEffect(() => {
    onChange(answer)
  }, [answer, onChange])

  return (
    <div className={themeClasses.surveyQuestion}>
      <FormControl
        aria-labelledby={ariaLabelledby}
        classes={{root: themeClasses.surveyAnswerGroup}}
        component="fieldset"
      >
        <FormGroup>
          {answers.map(a => (
            <FormControlLabel
              classes={{root: themeClasses.surveyAnswer}}
              control={
                <Checkbox
                  checked={answer.includes(a.value)}
                  color="primary"
                  inputProps={{"aria-label": a.title}}
                  onChange={_onChange}
                  value={a.value}
                />
              }
              key={a.value}
              label={a.title}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  )
}

Checkboxes.propTypes = {
  "aria-labelledby": string,
  onChange: func.isRequired,
  question: shape({
    hasError: bool,
    answer: array,
    answers: array,
  }),
  themeClasses: object.isRequired,
}

export const AddressAnswer = ({
  question,
  themeClasses,
  "aria-labelledby": ariaLabelledby,
  onChange,
}) => {
  const parseAddressFromString = string => {
    const [street1, street2 = "", city = "", state = "", zipcode = ""] = string.split("\n")
    return {street1, street2, city, state, zipcode}
  }

  const joinAddressToString = a => {
    return [a.street1, a.street2, a.city, a.state, a.zipcode].join("\n")
  }

  const [address, setAddress] = useState(parseAddressFromString(question.answer || ""))
  const [correctedAddress, setCorrectedAddress] = useState(null)
  const [errorText, setErrorText] = useState(null)
  const [validationStartedAt, setValidationStartedAt] = useState(null)
  const [isIncomplete, setIsIncomplete] = useState(false)

  // Populate address from question answers once they're loaded. Note: to prevent callback loops
  // and other bugs, we ignore this if a corrected address was loaded from the USPS API, or if the
  // question answer data is blank.
  useEffect(() => {
    if (!!question.answer && !correctedAddress) setAddress(parseAddressFromString(question.answer))
  }, [question.answer, correctedAddress])

  useEffect(() => {
    // This hook runs address validation, which consists of several steps:
    //
    // - Detect whenever the user changes address form data.
    // - Validate that all required fields are present; if any are missing, ABORT the chain
    //   and display a validation error msg to the user.
    // - Before validating the address with USPS, DEBOUNCE so that the API call is only invoked
    //   after there has been no keypress for 1s.
    // - As soon as the debounce delay starts, show the user a message "Validating address..."
    //   so they know there's something happening.
    // - Also prevent submission at this time, pending the validation api response.
    // - Set/update validationLastStartedAt so we can later check if the api response is stale.
    // - Validate the address with USPS by sending it to Gondor `POST /v1/usps/validate-address`.
    //   After Gondor returns the results:
    // - Abort if the component has become unmounted, so we don't set state.
    // - Abort if the api response is stale. (ie. validationLastStartedAt has since changed)
    // - Clear validationLastStartedAt, to hide the "Validating address..." message.
    // - If the API response says that the address isn't recognized, display a validation error
    //   saying so, and prevent submission.
    // - If the API response considers the address valid, display a warning showing what the
    //   address was corrected to (the corrected version will be used on submission).
    // - If the API response says that the address is valid but underspecified, use the USPS-corrected
    //   address same as in the previous point, but additionally display a warning saying that the
    //   address may be incomplete and encouraging the user to double-check it. Allow submission of
    //   the address as-is.
    //
    // FIXME: The fact that we have such intricate validation logic in this low-level component is a
    // major code smell, especially since this component is using onChange("") and question.hasError
    // to write and read the parent survey component's validation state. Ideally we'd rearchitect the
    // whole survey system so this sort of validation logic and API checking is done at a higher layer.

    let isMounted = true
    const startedAt = new Date().toISOString()

    // Clear the to-submit answer (if any) so the user can't submit until we revalidate.
    onChange("")

    if (validateRequiredFields()) {
      resetValidationRelatedState(startedAt)

      withDebounce(() => {
        validateAddressWithUsps({address: address}).then(resp => {
          // Abort if the component has become unmounted.
          if (!isMounted) return

          // Abort if the form data has changed since this validation api call was sent out.
          if (validationStartedAt && validationStartedAt > startedAt) return

          setValidationStartedAt(null)

          processUspsApiResponse(resp)
        })
      })
    }

    return () => (isMounted = false) // Keep track of whether the component has been unmounted

    // ONLY run this effect when the address or question error status changes, not validationStartedAt.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, question.hasError])

  // Silence unnecessary ESLint warnings about useCallback being given an opaque function.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const withDebounce = useCallback(
    debounce(func => func(), 1000),
    []
  )

  const validateRequiredFields = useCallback(() => {
    const requiredFields = [
      ["street1", "Street Address"],
      ["city", "City"],
      ["state", "State"],
      ["zipcode", "Zip Code"],
    ]

    let missingFieldNames = requiredFields
      .filter(([key, label]) => !address[key])
      .map(([key, label]) => label)

    if (missingFieldNames.length === requiredFields.length) {
      if (question.hasError) setErrorText("Please provide an address.")
      return false
    } else if (missingFieldNames.length > 0) {
      setErrorText(`Missing required field: ${missingFieldNames.join(", ")}`)
      return false
    } else {
      return true
    }
  }, [address, question.hasError])

  const resetValidationRelatedState = startedAt => {
    // Clear certain state flags so they don't hold onto stale values from previous validations.
    setErrorText(null)
    setIsIncomplete(false)
    setCorrectedAddress(null)
    setValidationStartedAt(startedAt)
  }

  const processUspsApiResponse = resp => {
    if (resp.code === "error") {
      // USPS thinks this is an invalid address.
      setErrorText(resp.error)
    } else if (resp.code === "corrected") {
      // USPS recognized this address, and returned a corrected version that may or may not be
      // different from what the user wrote.
      setCorrectedAddress(resp.address)
      onChange(joinAddressToString(resp.address))
    } else if (resp.code === "incomplete") {
      // USPS thinks this address is valid, but underspecified (eg. missing a suite number).
      // We warn the user about this fact, but allow submission anyway.
      setIsIncomplete(true)
      setCorrectedAddress(resp.address)
      onChange(joinAddressToString(resp.address))
    }
  }

  return (
    <div className={themeClasses.surveyQuestion}>
      <FormControl className={themeClasses.surveyQuestion} error={!!errorText} fullWidth={true}>
        <TextField
          label="Street Address"
          value={address.street1}
          required={true}
          onChange={event => setAddress({...address, street1: event.target.value})}
        />
        <TextField
          label="Apt/Suite/Bldg"
          value={address.street2}
          onChange={event => setAddress({...address, street2: event.target.value})}
        />
        <TextField
          label="City"
          value={address.city}
          required={true}
          onChange={event => setAddress({...address, city: event.target.value})}
        />
        <TextField
          label="State"
          value={address.state}
          required={true}
          onChange={event => setAddress({...address, state: event.target.value})}
        />
        <TextField
          label="Zip Code"
          value={address.zipcode}
          required={true}
          onChange={event => setAddress({...address, zipcode: event.target.value})}
        />

        {validationStartedAt && (
          <FormHelperText aria-atomic="true" aria-live="assertive" role="alert">
            Validating address...
          </FormHelperText>
        )}

        {errorText && (
          <FormHelperText aria-atomic="true" aria-live="assertive" role="alert">
            {errorText}
          </FormHelperText>
        )}

        {isIncomplete && (
          <div>
            <br />
            The address you entered was found but more information is needed (such as an apartment,
            suite, or box number) to match to a specific address.
            <br />
            <br />
            Please correct your address if possible. If the address entered is your full address,
            please ignore this warning.
          </div>
        )}

        {correctedAddress && (
          <div>
            <br />
            Your address has been validated with the USPS' address info, and will be saved as:
            <br />
            <br />
            <div>{correctedAddress.street1}</div>
            <div>{correctedAddress.street2}</div>
            <div>
              {correctedAddress.city}, {correctedAddress.state}
            </div>
            <div>{correctedAddress.zipcode}</div>
          </div>
        )}
      </FormControl>
    </div>
  )
}

AddressAnswer.propTypes = {
  "aria-labelledby": string,
  onChange: func.isRequired,
  question: shape({hasError: bool, answer: string}),
  themeClasses: object.isRequired,
}

export const SURVEY_ANSWER_COMPONENT_MAP = {
  [questionTypes.SHORT_ANSWER]: ShortAnswer,
  [questionTypes.PARAGRAPH]: Paragraph,
  [questionTypes.MULTIPLE_CHOICE]: MultipleChoice,
  [questionTypes.CHECKBOXES]: Checkboxes,
  [questionTypes.NUMBER]: NumberAnswer,
  [questionTypes.DATE]: DateAnswer,
  [questionTypes.EMAIL]: EmailAnswer,
  [questionTypes.ADDRESS]: AddressAnswer,
}
