import {actions as contentBlockActions} from "components/content-block-editor/content-block-editor-reducer"

import {
  PAGE_MADE_UNIQUE,
  PAGE_UPDATED,
  TEMPLATE_CLEARED,
  TEMPLATE_DELETED,
  TEMPLATE_INSIGHTS_SET,
  TEMPLATE_PAGE_CREATED,
  TEMPLATE_PAGE_DELETED,
  TEMPLATE_PAGE_LIST_SET,
  TEMPLATE_PAGE_STASHED,
  TEMPLATE_PAGE_UPDATED,
  TEMPLATE_SET,
  TEMPLATE_UPDATED,
} from "./template-actions"

const initialState = {}

export default function template(state = initialState, action) {
  switch (action.type) {
    case TEMPLATE_CLEARED:
      return initialState
    case TEMPLATE_SET:
    case TEMPLATE_UPDATED: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case TEMPLATE_DELETED: {
      return initialState
    }

    case TEMPLATE_PAGE_LIST_SET: {
      return {
        ...state,
        templatePages: action.payload,
      }
    }

    case TEMPLATE_PAGE_CREATED: {
      return {
        ...state,
        templatePages: state.templatePages.concat([action.payload]),
      }
    }

    case TEMPLATE_PAGE_UPDATED: {
      return {
        ...state,
        templatePages: state.templatePages.map(tp =>
          tp.id === action.payload.id ? action.payload : tp
        ),
      }
    }

    case PAGE_UPDATED: {
      return {
        ...state,
        templatePages: state.templatePages.map(tp =>
          tp.page.id === action.payload.id ? {...tp, page: action.payload} : tp
        ),
      }
    }

    case TEMPLATE_PAGE_STASHED:
      return {
        ...state,
        templatePages: state.templatePages.map(templatePage =>
          templatePage.page.id === action.payload.pageId
            ? {
                ...templatePage,
                page: {
                  ...templatePage.page,
                  content: action.payload?.content ?? templatePage.page.content,
                },
              }
            : templatePage
        ),
      }

    case PAGE_MADE_UNIQUE: {
      return {
        ...state,
        templatePages: state.templatePages.map(tp =>
          tp.id === action.payload.oldTemplatePage.id ? action.payload.newTemplatePage : tp
        ),
      }
    }

    case TEMPLATE_PAGE_DELETED: {
      return {
        ...state,
        templatePages: state.templatePages.filter(
          templatePage => templatePage.id !== action.payload
        ),
      }
    }

    case TEMPLATE_INSIGHTS_SET:
      return {
        ...state,
        insights: action.payload,
      }

    case contentBlockActions.addContentBlock: {
      const contentBlock = action.payload
      const {containerType} = contentBlock

      if (containerType === "page")
        return {
          ...state,
          templatePages: state.templatePages.map(tp => {
            if (tp.page.id === contentBlock.pageId)
              return {
                ...tp,
                page: {...tp.page, contentBlocks: [...tp.page.contentBlocks, contentBlock]},
              }

            return tp
          }),
        }

      return state
    }

    case contentBlockActions.removeContentBlock: {
      const contentBlock = action.payload
      const {containerType} = contentBlock

      if (containerType === "page")
        return {
          ...state,
          templatePages: state.templatePages.map(tp => {
            if (tp.page.id === contentBlock.pageId)
              return {
                ...tp,
                page: {
                  ...tp.page,
                  contentBlocks: tp.page.contentBlocks.filter(c => c.id !== contentBlock.id),
                },
              }

            return tp
          }),
        }

      return state
    }

    default:
      return state
  }
}
