import TextButton, {TextIcon} from "./text-button"
import TextReadonly from "./text-readonly"

export default {
  title: "Text",
  type: "text",
  Button: TextButton,
  Icon: TextIcon,
  Readonly: TextReadonly,
  suitableFor: ["content_container", "message", "page", "template"],
}
