import {Link} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import _ from "lodash"
import {arrayOf, number, object, oneOfType, shape, string} from "prop-types"
import {Fragment} from "react"
import {NavLink} from "react-router-dom"

import {formatDate, formatDateTime} from "lib/date-time-formatters"

import {nameForUser} from "./audit-log-row"

const useStyles = makeStyles(theme => ({
  term: {
    fontWeight: "bold",
  },
  definition: {
    marginInlineStart: 0,
  },
  number: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}))

const MetadataDisplay = ({field, value}) => {
  const classes = useStyles()

  switch (field) {
    case "location":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Location</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "source":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Source</dt>
          <dt className={classes.definition}>{value === "sftp" ? "SFTP" : "Admin Console"}</dt>
        </dl>
      )
    case "uploadResults":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Upload results</dt>
          <dd className={classes.definition}>
            <span className={classes.number}>{value?.new?.toLocaleString()}</span> {value.type}
            {value.new !== 1 ? "s" : null} created
          </dd>
          <dd className={classes.definition}>
            <span className={classes.number}>{value?.existing?.toLocaleString()}</span> {value.type}
            {value.existing !== 1 ? "s" : null} updated
          </dd>
          <dd className={classes.definition}>
            <span className={classes.number}>{value?.failed?.toLocaleString()}</span> {value.type}
            {value.failed !== 1 ? "s" : null} failed validation
          </dd>
        </dl>
      )
    case "originalFilename":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>File name</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "totalRowsInCsv":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Total rows in CSV</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "deletedAt":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>File deleted</dt>
          <dd className={classes.definition}>{formatDateTime(value)}</dd>
        </dl>
      )
    case "affectedUser":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Affected User</dt>
          <dd className={classes.definition}>{nameForUser(value)}</dd>
        </dl>
      )
    case "removed":
    case "added":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>{field === "removed" ? "Removed" : "Added"} Permissions</dt>
          {value.length > 0 ? (
            value.map(name => (
              <dd className={classes.definition} key={name}>
                {name}
              </dd>
            ))
          ) : (
            <dd className={classes.definition}>None</dd>
          )}
        </dl>
      )
    case "contact":
      return (
        <dl>
          <dt className={classes.term}>Contact ID</dt>
          <dd className={classes.definition}>
            {value.id}{" "}
            <Link component={NavLink} to={`/admin/contacts/${value.id}`} variant="body1">
              View
            </Link>
          </dd>
        </dl>
      )
    case "notes":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Notes</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "users":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Users</dt>
          {value.map(user => (
            <Fragment key={user.id}>
              <dd className={classes.definition}>{nameForUser(user)}</dd>
            </Fragment>
          ))}
        </dl>
      )
    case "version":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Version</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "date":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Date From</dt>
          <dd className={classes.definition}>{formatDate(value)}</dd>
        </dl>
      )
    case "objectiveId":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Objective ID</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "deletedActionCount":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Deleted Actions Count</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "deletedObjectiveCompletionCount":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>Deleted Objective Completions Count</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    case "engagementThreshold":
    case "attributionWindow":
      return (
        <dl className={classes.list}>
          <dt className={classes.term}>{humanizeCamelCasedString(field)}</dt>
          <dd className={classes.definition}>{value}</dd>
        </dl>
      )
    default:
      return null
  }
}

const humanizeCamelCasedString = string => _.capitalize(string.replace(/([A-Z])/g, " $1").trim())

const uploadResult = shape({
  new: number,
  existing: number,
  failed: number,
})

const affectedUser = shape({
  id: string,
  email: string,
  nameFirst: string,
  nameLast: string,
})

MetadataDisplay.propTypes = {
  field: string.isRequired,
  value: oneOfType([
    number,
    arrayOf(string),
    string,
    uploadResult,
    affectedUser,
    object,
    arrayOf(object),
  ]),
}

export default MetadataDisplay
