import {IconButton, Tooltip} from "@mui/material"
import cx from "classnames"
import {RichUtils} from "draft-js"
import {func, object} from "prop-types"

export default ({style, children, tooltipText}) => {
  const InlineStyleButton = props => {
    const {onSetEditorState, editorState, classes} = props
    const toggleStyle = e => {
      e.preventDefault()
      onSetEditorState(RichUtils.toggleInlineStyle(editorState, style))
    }

    const isStyleActive = editorState && editorState.getCurrentInlineStyle().has(style)

    return (
      <Tooltip title={tooltipText}>
        <IconButton
          className={cx(classes.button, {
            [classes.buttonActive]: isStyleActive,
          })}
          onClick={toggleStyle}
          type="button"
          size="medium"
        >
          {children}
        </IconButton>
      </Tooltip>
    )
  }

  InlineStyleButton.propTypes = {
    classes: object,
    editorState: object,
    onSetEditorState: func,
  }

  return InlineStyleButton
}
