import {node} from "prop-types"
import {createContext, useContext, useReducer} from "react"

import {updateTemplateTargeting} from "lib/api"
import {toCamelCase} from "lib/case-converter"

import {createHandler, useSocket} from "../../contexts/socket-manager"
import {
  FORM_STATES,
  TARGETING_IMPACT,
  actions,
  initialState,
  reducer,
  targetingImpactFormState,
} from "./template-targeting-helpers"

const TemplateTargetingContext = createContext()
TemplateTargetingContext.displayName = "TemplateTargetingContext"

const {Provider} = TemplateTargetingContext
const {
  ADD_TARGETING_CONDITION,
  ADD_TARGETING_GROUP,
  REMOVE_TARGETING_CONDITION,
  REMOVE_TARGETING_GROUP,
  SET_FORM_STATE,
  SET_IS_LOADING,
  SET_IS_TARGETING_PRIORITY,
  SET_JOURNEY_CREATION_STRATEGY,
  SET_TARGETING_GROUP_LOGICAL_OPERATOR,
  SET_TARGETING_GROUPS,
  SET_TARGETING_IMPACT,
  UPDATE_TARGETING_CONDITION,
  UPDATE_TARGETING_GROUP,
} = actions

export const TemplateTargetingContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const basicAction = (type, key) => value => dispatch({type, [key]: value})

  const addTargetingCondition = basicAction(ADD_TARGETING_CONDITION, "targetingGroupId")
  const addTargetingGroup = basicAction(ADD_TARGETING_GROUP)
  const updateTargetingGroup = basicAction(UPDATE_TARGETING_GROUP, "targetingGroup")
  const updateTargetingCondition = basicAction(UPDATE_TARGETING_CONDITION, "targetingCondition")
  const removeTargetingGroup = basicAction(REMOVE_TARGETING_GROUP, "targetingGroupId")
  const removeTargetingCondition = basicAction(REMOVE_TARGETING_CONDITION, "targetingCondition")
  const setJourneyCreationStrategy = basicAction(
    SET_JOURNEY_CREATION_STRATEGY,
    "journeyCreationStrategy"
  )
  const setTargetingGroups = basicAction(SET_TARGETING_GROUPS, "targetingGroups")
  const setTargetingGroupLogicalOperator = basicAction(
    SET_TARGETING_GROUP_LOGICAL_OPERATOR,
    "targetingGroupLogicalOperator"
  )
  const setTargetingImpact = basicAction(SET_TARGETING_IMPACT, "targetingImpact")

  const setIsTargetingPriority = basicAction(SET_IS_TARGETING_PRIORITY, "isTargetingPriority")

  const setIsLoading = isLoading => {
    dispatch({type: SET_IS_LOADING, isLoading})
  }

  const setFormState = (formState, resetState, after = 3000) => {
    dispatch({type: SET_FORM_STATE, formState})
    if (resetState) setTimeout(() => dispatch({type: SET_FORM_STATE, formState: resetState}), 3000)
  }

  const saveTargeting = (template, groups) => {
    const {
      isTargetingPriority,
      journeyCreationStrategy,
      targetingGroupLogicalOperator,
      targetingGroups,
    } = state
    const templateLeader =
      !template.splitTestingGroupId || template.isSplitTestingGroupLeader
        ? template
        : groups
            .find(group => group.id === template.splitTestingGroupId)
            .templates.find(t => t.isSplitTestingGroupLeader)

    const nextTargetingGroups = targetingGroups.map(targetingGroup => {
      const group = targetingGroup.isNew ? {...targetingGroup, id: undefined} : targetingGroup
      group.targetingConditions = group.targetingConditions.map(targetingCondition => {
        const condition = targetingCondition.isNew
          ? {...targetingCondition, id: undefined}
          : targetingCondition

        if (targetingGroup.isNew) condition.targetingGroupId = undefined

        return condition
      })
      return group
    })

    const templateParams = {
      isTargetingPriority,
      journeyCreationStrategy,
      targetingGroupLogicalOperator,
      targetingGroups: nextTargetingGroups,
    }
    setFormState(FORM_STATES.SUBMITTING)

    return updateTemplateTargeting(templateLeader.id, templateParams)
      .then(({isTargetingPriority, journeyCreationStrategy, targetingGroups}) => {
        setTargetingGroups(targetingGroups)
        setIsTargetingPriority(isTargetingPriority)
        setJourneyCreationStrategy(journeyCreationStrategy)
        setTargetingImpact(null)
        setFormState(FORM_STATES.COMPLETE, FORM_STATES.CLEAN)
      })
      .catch(() => {
        setFormState(FORM_STATES.ERROR)
      })
  }

  const {addHandler, removeHandler} = useSocket()

  const subscribeToImpactUpdates = templateId => {
    if (!templateId) return

    const targetingImpactCompleted = createHandler(
      `targeting-impact:${templateId}`,
      "targeting_impact_completed",
      targeting_impact => {
        setTargetingImpact(toCamelCase(targeting_impact))

        const targetingFormState = targetingImpactFormState(targeting_impact)
        setFormState(targetingFormState ? targetingFormState : FORM_STATES.READY_TO_SUBMIT)
      }
    )

    addHandler(targetingImpactCompleted)

    const targetingImpactFailed = createHandler(
      `targeting-impact:${templateId}`,
      "targeting_impact_failed",
      () => {
        setTargetingImpact({
          numContacts: 0,
          completedAt: null,
          state: TARGETING_IMPACT.FAILED,
        })
        setFormState(FORM_STATES.ERROR)
      }
    )

    addHandler(targetingImpactFailed)

    return () => {
      removeHandler(targetingImpactCompleted)
      removeHandler(targetingImpactFailed)
    }
  }

  const value = {
    ...state,

    addTargetingCondition,
    addTargetingGroup,
    removeTargetingCondition,
    removeTargetingGroup,
    saveTargeting,
    setFormState,
    setIsLoading,
    setIsTargetingPriority,
    setJourneyCreationStrategy,
    setTargetingGroupLogicalOperator,
    setTargetingGroups,
    setTargetingImpact,
    subscribeToImpactUpdates,
    updateTargetingCondition,
    updateTargetingGroup,
  }

  return <Provider value={value}>{children}</Provider>
}

TemplateTargetingContextProvider.propTypes = {
  children: node.isRequired,
}

export const useTemplateTargeting = () => useContext(TemplateTargetingContext)

export const provideTemplateTargeting = Component => props => (
  <TemplateTargetingContextProvider>
    <Component {...props} />
  </TemplateTargetingContextProvider>
)

export const templateTargetingConsumer = Component => props => (
  <Component {...useTemplateTargeting()} {...props} />
)
