import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {EditorState, Modifier} from "draft-js"
import {func, object, string} from "prop-types"
import {MdPersonPinCircle as PersonalizationIcon} from "react-icons/md"

import {PERSONALIZATION_ENTITY} from "../../entities/personalization-entity"

const insertPersonalization = editorState => {
  const selection = editorState.getSelection()
  const currentStyle = editorState.getCurrentInlineStyle()
  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity(PERSONALIZATION_ENTITY, "IMMUTABLE", {
    value: "contact.nameFirst",
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  const modifier = selection.isCollapsed() ? Modifier.insertText : Modifier.replaceText
  const textWithEntity = modifier(
    contentStateWithEntity,
    selection.set("hasFocus", true),
    "#CONTACT.NAME_FIRST",
    currentStyle,
    entityKey
  )

  return EditorState.push(editorState, textWithEntity, "insert-characters")
}

const PersonalizationButton = ({editorState, onSetEditorState, className, classes}) => {
  const onClick = () => {
    onSetEditorState(insertPersonalization(editorState))
  }

  return (
    <MenuItem className={className} onClick={onClick}>
      <ListItemIcon classes={{root: classes.menuButtonIcon}}>
        <PersonalizationIcon />
      </ListItemIcon>
      <ListItemText primary="Personalization" style={{color: "#000000"}} />
    </MenuItem>
  )
}

PersonalizationButton.propTypes = {
  className: string,
  classes: object.isRequired,
  editorState: object.isRequired,
  onSetEditorState: func.isRequired,
}

export default PersonalizationButton
