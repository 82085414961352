import {FormControl, FormControlLabel, Switch} from "@mui/material"
import {func} from "prop-types"

import {formify} from "lib/hooks/use-form"

import FormActions from "../form-actions"

const DocumentSettings = ({field, handleSubmit, resetForm}) => (
  <form aria-label="Document Settings" onSubmit={handleSubmit}>
    <FormControlLabel
      control={
        <Switch
          color="primary"
          {...field("isDownloadDisabled", {
            bool: true,
            defaultValue: true,
            exclude: ["error", "helperText"],
          })}
        />
      }
      label="Disable print and download"
    />
    <FormControl margin="normal" fullWidth={true}>
      <FormActions resetForm={resetForm} />
    </FormControl>
  </form>
)

DocumentSettings.propTypes = {
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func.isRequired,
}

export default formify()(DocumentSettings)
