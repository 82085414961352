/* eslint-disable react/prop-types */
import {CircularProgress} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {replace} from "connected-react-router"
import {useEffect} from "react"
import {useDispatch} from "react-redux"

import {fetchPageByOldSlug} from "lib/api"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
    justifyContent: "center",
  },
  loading: {
    alignSelf: "center",
  },
})

const DeprecatedPageRedirector = ({
  match: {
    params: {journeySlug, oldPageSlug},
  },
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    fetchPageByOldSlug({journeySlug, oldPageSlug})
      .then(page => dispatch(replace(`/journeys/${journeySlug}/${page.newSlug}`)))
      .catch(() => dispatch(replace(`/journeys/${journeySlug}`)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeySlug, oldPageSlug])

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loading} />
    </div>
  )
}

export default DeprecatedPageRedirector
