import {Paper, Typography} from "@mui/material"
import Box from "@mui/material/Box"
import {arrayOf, shape, string} from "prop-types"
import React from "react"

import CtaSummaryTable from "./cta-summary-table"

const CtaSummary = ({ctas}) => {
  return (
    <Paper
      sx={theme => ({
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
      })}
    >
      <Typography variant="h6" gutterBottom>
        CTA Summary
      </Typography>
      <Box>
        <CtaSummaryTable rows={ctas} />
      </Box>
    </Paper>
  )
}

CtaSummary.propTypes = {
  ctas: arrayOf(
    shape({
      contentSourceId: string.isRequired,
      destination: string.isRequired,
      name: string,
      type: string.isRequired,
    })
  ),
}

export default CtaSummary
