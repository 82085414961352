import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {FaCreditCard as CreditCardIcon} from "react-icons/fa"
import shortid from "shortid"

const CardOnFileButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {},
      type: "card-on-file",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <CreditCardIcon />
      </ListItemIcon>
      <ListItemText primary="Card on File" ref={ref} />
    </MenuItem>
  )
})

CardOnFileButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func,
}

export {CreditCardIcon}

export default CardOnFileButton
