import {IconButton, Link, Typography} from "@mui/material"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import {isBefore, parseISO} from "date-fns"
import {object, shape, string} from "prop-types"
import {useEffect, useMemo, useState} from "react"
import {FaDownload} from "react-icons/fa"

import ShadowBox from "components/box/box"
import HeaderLogo from "components/journeys/header-logo"
import Padded from "components/padded/padded"
import SkeletonLoader from "components/skeleton-loader/skeleton-loader"

import {fetchApproval, submitApprovalVote} from "lib/api"

import ApproveForm from "./approve-form"
import DisapproveForm from "./disapprove-form"
import ExpiredForm from "./expired-form"

const ApproveCampaign = ({approval, token}) => {
  const {expiresAt, id, team} = approval
  const teamName = team?.name
  const teamHasLogo = !!team?.isLogoUploaded
  const templateName = approval?.template?.name || "Campaign"

  const [hasVoted, setHasVoted] = useState(!!approval?.response)

  const sendApproval = data => {
    submitApprovalVote(id, data).then(() => setHasVoted(true))
  }

  const headerMessage = hasVoted
    ? "Thank you for submitting your response"
    : `${templateName} is ready for review`

  const isExpired = useMemo(() => isBefore(parseISO(expiresAt), new Date()), [expiresAt])

  return (
    <>
      {teamHasLogo && (
        <HeaderLogo
          alt={`${teamName} logo`}
          src={`${process.env.REACT_APP_API_URL}/teams/${team?.id}/logo`}
          variant="large"
        />
      )}
      {isExpired ? (
        <ExpiredForm approvalId={id} />
      ) : (
        <>
          <Typography sx={{marginBottom: 6}} variant="h3">
            {headerMessage}
          </Typography>

          <Box
            sx={{
              marginBottom: 6,
              textAlign: "center",
            }}
          >
            <Typography variant="body1">Download campaign content to review</Typography>
            <IconButton
              component={Link}
              href={`${process.env.REACT_APP_API_URL}/approvals/${team?.id}/${token}/download`}
              size="medium"
            >
              <Box
                sx={{
                  cursor: "pointer",
                  fontSize: "6rem",
                }}
              >
                <FaDownload />
              </Box>
            </IconButton>
          </Box>

          {!hasVoted && (
            <Box
              sx={{
                display: "flex",
                margin: "0 auto",
                "& > *": {
                  flex: 1,
                  padding: 2,
                },
                "& h4": {
                  fontWeight: "bold",
                },
                "& form": {
                  width: "100%",
                },
              }}
            >
              <ApproveForm onSubmit={sendApproval} />
              <DisapproveForm onSubmit={sendApproval} />
            </Box>
          )}
        </>
      )}
    </>
  )
}

ApproveCampaign.propTypes = {
  approval: object,
  logoFileId: string,
  token: string,
}

const ApprovalSkeleton = () => (
  <>
    <Typography variant="h2">
      <Skeleton variant="rectangular" />
    </Typography>
    <Typography variant="body1">
      <Skeleton component="span" />
      <Skeleton component="span" />
    </Typography>
  </>
)

const CampaignReview = ({
  match: {
    params: {token},
  },
}) => {
  const [isLoaded, setLoaded] = useState(false)
  const [approval, setApproval] = useState(null)
  useEffect(() => {
    fetchApproval(token).then(approval => {
      setApproval(approval)
      setLoaded(true)
    })
  }, [token])

  return (
    <Box
      sx={{
        maxWidth: 1280,
        margin: "32px auto",
      }}
    >
      <ShadowBox>
        <Padded>
          <SkeletonLoader loaded={isLoaded} Loader={ApprovalSkeleton}>
            <ApproveCampaign approval={approval} token={token} />
          </SkeletonLoader>
        </Padded>
      </ShadowBox>
    </Box>
  )
}

CampaignReview.propTypes = {
  match: shape({
    params: shape({
      token: string,
    }),
  }),
}

export default CampaignReview
