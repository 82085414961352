/* ISC License (ISC). Copyright 2017 Michal Zalecki */

/**
 *
 * @param {array} storageProviders - Storage methods in ascending order, i.e., localStorage, sessionStorage, document.cookie, in memory, etc.,
 * will be gracefully degraded until the next supported available storage method.
 * @returns {{removeItem: *, using: *, clear: *, length: *, getItem: *, setItem: *, key: *}}
 */
export default storageProviders => {
  const isSupported = storage => {
    try {
      const store = storage()
      const testKey = "2beda411-facc-4891-80d5-567d66011a09"

      store.setItem(testKey, testKey)

      if (store.getItem(testKey) !== testKey) return false

      store.removeItem(testKey)

      return true
    } catch (e) {
      return false
    }
  }

  return storageProviders.find(isSupported)()
}
