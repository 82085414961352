import {push} from "connected-react-router"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import {templateContentContext} from "../../contexts/template-content-context"
import {
  TEMPLATE_LOADED,
  TEMPLATE_PAGES_LOADED,
  isLoaded,
  loadingStatesWithMessages,
} from "../../lib/loading-states"
import {
  clearComponentData,
  getTemplateComponentData,
  onCreateTemplatePage,
  onDeletePageAndRemoveFromTemplate,
  onMakePageUnique,
  onRemoveTemplatePage,
  onSortPages,
  onStashPage,
  onUpdatePage,
  onUpdateTemplate,
  onUpdateTemplatePage,
} from "../templates/template-actions"
import Template from "./template"

const mapStateToProps = (
  {session: {teamUsers, team}, template, loadingStates},
  {
    location: {hash},
    match: {
      params: {templateId, pageSlug},
    },
  }
) => ({
  template,
  users: teamUsers,
  pageSlug,
  templateId,
  isEditable: true,
  isPageLoaded: isLoaded(TEMPLATE_PAGES_LOADED, loadingStates) && !!pageSlug,
  isTemplateLoaded: isLoaded(TEMPLATE_LOADED, loadingStates),
  loadingStates: loadingStatesWithMessages(loadingStates),
  isPreviewMode: hash === "#preview",
  team: team,
})

const mapDispatchToProps = (
  dispatch,
  {
    location: {hash},
    match: {
      params: {templateId, pageSlug},
    },
  }
) => ({
  getComponentData: newTemplateId =>
    dispatch(getTemplateComponentData(newTemplateId, pageSlug, hash)),
  onNavigateToPage: newPageSlug =>
    dispatch(push(`/admin/templates/${templateId}/edit/${newPageSlug}`)),
  setPreviewMode: mode =>
    dispatch(push(`/admin/templates/${templateId}/edit/${pageSlug}${mode ? "#preview" : ""}`)),
  ...bindActionCreators(
    {
      clearComponentData,
      onUpdateTemplate,
      onUpdateTemplatePage,
      onCreateTemplatePage,
      onDeletePage: onDeletePageAndRemoveFromTemplate,
      onMakePageUnique,
      onRemoveTemplatePage,
      onSortPages,
      onUpdatePage,
      onStashPage,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(templateContentContext(Template))
