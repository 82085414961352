import withStyles from "@mui/styles/withStyles"
import {object, string} from "prop-types"
import {useEffect, useState} from "react"
import {connect} from "react-redux"

import * as actions from "../../actions/accessability-actions"

// source: https://almerosteyn.com/2017/03/accessible-react-navigation
const A11yMessage = ({a11yMessage, classes}) => {
  const [currentA11yMessage, setCurrentA11yMessage] = useState("")

  useEffect(() => {
    let setMessageTimeoutId,
      clearMessageTimeoutId = null

    //We delay the setting and clearing of the accessible route transition
    //text to ensure that the screen readers don't miss it.
    if (a11yMessage) {
      setMessageTimeoutId = setTimeout(() => setCurrentA11yMessage(a11yMessage), 50)
      clearMessageTimeoutId = setTimeout(() => setCurrentA11yMessage(""), 500)
    }

    return () => {
      if (setMessageTimeoutId) clearTimeout(setMessageTimeoutId)
      if (clearMessageTimeoutId) clearTimeout(clearMessageTimeoutId)
    }
  }, [a11yMessage])

  return (
    <div aria-atomic="true" aria-live="polite" className={classes.screenReaderOnly} role="status">
      {currentA11yMessage || ""}
    </div>
  )
}

A11yMessage.propTypes = {
  a11yMessage: string,
  classes: object,
}

const mapStateToProps = ({a11y}) => ({a11yMessage: a11y.message})

const styles = {
  screenReaderOnly: {
    height: 1,
    left: -999,
    overflow: "hidden",
    position: "absolute",
    top: "auto",
    width: 1,
  },
}

export default connect(mapStateToProps, actions)(withStyles(styles)(A11yMessage))
