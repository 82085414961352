import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {FaClipboardCheck as SurveyIcon} from "react-icons/fa"
import shortid from "shortid"

import {SHORT_ANSWER} from "./survey-question-types"

const defaultName = container => {
  if (container._type === "page") {
    return container.contentName
  } else if (container._type === "content_container") {
    return container.engagementChannel.name
  } else {
    throw new Error(
      `SurveyButton assumed that all survey widget blocks are on either a Page or a ContentContainer, but this container is of type ${container._type}.`
    )
  }
}

const SurveyButton = forwardRef(({classes, container, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    onAddContentBlock({
      slug: shortid.generate(),
      data: {
        name: defaultName(container),
        backgroundColor: "",
        iconSrc: "",
        questions: [
          {
            slug: shortid.generate(),
            title: "Example Question",
            type: SHORT_ANSWER,
          },
        ],
      },
      type: "survey",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <SurveyIcon />
      </ListItemIcon>
      <ListItemText primary="Survey" ref={ref} />
    </MenuItem>
  )
})

SurveyButton.propTypes = {
  classes: object.isRequired,
  container: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func,
}

export {SurveyIcon}

export default SurveyButton
