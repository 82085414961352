const pageWidth = 1135

const zContent = 40
const zHeader = 50
const zOwnerInfo = 60
const zMobileNav = 70

const pagePadding = theme => theme.spacing(6)

export default theme => ({
  "@global": {
    body: {
      // THESE MUST BE KEPT IN SYNC
      backgroundColor: "white !important",
      "--main-background-color": "white !important",
    },
  },
  journey: {
    display: "flex",
    flexDirection: "column",
  },
  pageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  journeyWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: `calc(${pageWidth}px + ${pagePadding(theme)} * 2)`,
    padding: `0 ${pagePadding(theme)} ${theme.spacing(10)}`,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    ".is-mobile-preview &,.is-tablet-preview &": {
      padding: 0,
    },
  },
  notLiveWarning: {
    background: `repeating-linear-gradient(315deg,#FFF,#FFF 20px,#FF634745 20px,#FF634745 40px)`,
    backgroundColor: "#FFF",
    textAlign: "center",
    textShadow: "1px 1px 5px #FFF, -1px -1px 5px #FFF, 1px -1px 5px #FFF, -1px 1px 5px #FFF",
  },
  notLiveWarningContent: {
    display: "inline-block",
    background: "#fffe",
    padding: "0px 10px",
    margin: "10px 0",
  },
  content: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(6),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(6),
    },
    "&::after": {
      content: '""',
      clear: "both",
      display: "block",
    },
  },
  journeyCompletionDescriptor: {
    textAlign: "center",
    fontStyle: "italic",
    color: theme.palette.text.hint,
    position: "relative",
    top: -20,
    marginBottom: 28,
    [theme.breakpoints.down("md")]: {
      top: "auto",
      marginBottom: 20,
    },
  },
  header: {
    position: "relative",
    zIndex: zHeader,
    padding: 20,
    minHeight: 120,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingTop: 0, // visual padding added by mobile menu button
    },
    ".is-tablet-preview &": {
      paddingLeft: 0,
      paddingTop: 0, // visual padding added by mobile menu button
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: theme.spacing(2),
      paddingTop: 0, // visual padding added by mobile menu button
    },
    ".is-mobile-preview &": {
      textAlign: "center",
      padding: theme.spacing(2),
      paddingTop: 0, // visual padding added by mobile menu button
    },
  },
  headerLogoWrapper: {
    display: "inline-block",
    maxWidth: 150,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: "0 auto",
    },
  },
  headerLogo: {
    maxWidth: "100%",
    verticalAlign: "middle",
  },
  contactLoginPaperRoot: {
    margin: 0,
  },
  pageNotFoundWrapper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    boxSizing: "border-box",
    color: theme.palette.grey[500],
  },
  progressCompletedPercentage: {
    color: "rgba(0, 0, 0, 0.87)", // Reset after branding overrides.
    fontWeight: "normal", // Reset after branding overrides.
    letterSpacing: "normal", // Reset after branding overrides.
    marginBottom: "0 !important", // Reset after branding overrides.
  },
  mobileMenuButton: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    ".is-mobile-preview &,.is-tablet-preview &": {
      display: "block",
    },
  },
  navigation: {
    border: "1px solid #DDD",
    borderLeft: "none",
    borderRight: "none",
    transition: theme.transitions.create("left"),
    [theme.breakpoints.down("lg")]: {
      position: "fixed",
      top: 0,
      left: -300,
      width: 300,
      bottom: 0,
      zIndex: zMobileNav,
      "&:after": {
        position: "fixed",
        content: `""`,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        zIndex: -1,
        transition: theme.transitions.create("opacity"),
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        opacity: 0,
      },
    },
    ".is-mobile-preview &,.is-tablet-preview &": {
      position: "absolute",
      top: 0,
      left: -300,
      width: 300,
      bottom: 0,
      zIndex: zMobileNav,
      "&:after": {
        position: "fixed",
        content: `""`,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        zIndex: -1,
        transition: theme.transitions.create("opacity"),
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        opacity: 0,
      },
    },
  },
  navigationMobileMenuOpen: {
    [theme.breakpoints.down("lg")]: {
      left: 0,
      "&:after": {
        opacity: 1,
      },
    },
    ".is-mobile-preview &,.is-tablet-preview &": {
      left: 0,
      zIndex: 1110,
      "&:after": {
        opacity: 1,
      },
    },
  },
  navigationList: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("lg")]: {
      display: "block",
      paddingBottom: 0,
      height: "100%",
      backgroundColor: "#fff",
      overflow: "auto",
    },
    ".is-mobile-preview &,.is-tablet-preview &": {
      display: "block",
      paddingBottom: 0,
      height: "100%",
      overflow: "auto",
    },
  },
  navigationItem: {
    listStyleType: "none",
    textAlign: "center",
    flex: 1,
  },
  navigationItemIcon: {
    display: "inline-block",
    borderRadius: "50%",
    marginBottom: theme.spacing(2),
    width: 18,
    height: 18,
    "& svg": {
      display: "none",
      marginTop: -2,
      marginLeft: -2,
      height: 20,
      width: 20,
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
    ".is-mobile-preview &,.is-tablet-preview &": {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
  navigationItemPage: {
    background: "#fff",
    display: "block",
    padding: "20px 0",
    marginBottom: "0 !important", // Reset after branding overrides.
    boxSizing: "border-box",
    height: "100%",
    opacity: 0.75,
    borderBottom: "4px solid transparent",
    transition: "all .2s ease",
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "500 !important", // Reset after branding overrides.
    lineHeight: "normal", // Reset after branding overrides.
    letterSpacing: "normal", // Reset after branding overrides.
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(3),
      borderBottom: "none",
      "&:hover,&:active": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
    ".is-mobile-preview &,.is-tablet-preview &": {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(3),
      borderBottom: "none",
      "&:hover,&:active": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  navigationItemNotLive: {
    "& $navigationItemPage": {
      background: "#F1F3F6",
      opacity: 0.6,
    },
  },
  navigationItemIsSortable: {
    cursor: "move",
  },
  navigationItemActive: {
    "& $navigationItemPage": {
      borderBottomColor: theme.palette.primary.main,
      opacity: 1,
      [theme.breakpoints.down("lg")]: {
        color: theme.palette.primary.main,
      },
      ".is-mobile-preview &,.is-tablet-preview &": {
        color: theme.palette.primary.main,
      },
    },
    "& $navigationItemIcon": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 1px 15px ${theme.palette.primary.main}`,
    },
    "&$navigationItemComplete $navigationItemIcon": {
      backgroundColor: "#fff",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      ".is-mobile-preview &,.is-tablet-preview &": {
        color: theme.palette.primary.main,
      },
    },
  },
  navigationItemComplete: {
    "& svg": {
      visibility: "visible",
    },
    "& $navigationItemIcon": {
      border: "none",
      color: "#D5DFE3",
      "& svg": {
        display: "inline-block",
      },
    },
  },
  navigationItemEmpty: {
    "& $navigationItemIcon": {
      display: "inline-block",
      border: `2px solid #D5DFE3`,
    },
  },
  navigationHidden: {
    display: "none",
  },
  pageConfirm: {},
  pageButtons: {
    position: "absolute",
    bottom: -8,
    right: -8,
    [theme.breakpoints.down("lg")]: {
      right: 0,
    },
  },
  prevPageButton: {
    minWidth: 0,
    borderRadius: 0,
    padding: theme.spacing(1.375),
  },
  nextPageButton: {
    borderRadius: 0,
    padding: theme.spacing(0.9),
    paddingLeft: theme.spacing(3),
  },
  prevPageButtonIcon: {
    color: "white",
  },
  nextPageButtonIcon: {
    marginLeft: 10,
  },
  muiIconWrapper: {
    fontSize: 1.3 * theme.typography.fontSize,
    lineHeight: 0,
  },
  footerContent: {
    textAlign: "center",
    zIndex: zContent,
    padding: theme.spacing(3),
  },
  journeyOwnerInfoRoot: {},
  journeyOwnerInfoInner: {
    backgroundColor: theme.palette.background.darkened,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    padding: theme.spacing(1),
    textAlign: "center",
    opacity: 0.95,
    zIndex: zOwnerInfo,
  },
  journeyOwnerInfoContainer: {
    margin: "0 auto",
    maxWidth: `calc(${pageWidth}px + ${pagePadding(theme)} * 2)`,
    padding: `0 ${theme.spacing(6)}`,
    width: "100%",
  },
  journeyOwnerInfoName: {
    display: "inline",
    fontWeight: 600,
    fontStyle: "normal",
  },
  journeyOwnerInfoText: {
    display: "inline",
    verticalAlign: "middle",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  journeyOwnerInfoIcons: {
    marginLeft: 10,
    verticalAlign: "middle",
  },
  journeyOwnerInfoIcon: {
    width: 40,
    height: 40,
  },
  blockquote: {
    borderLeft: "2px solid #ddd",
    margin: 0,
    padding: "6px 0 5px 18px",
    color: "#999",
    fontStyle: "italic",
  },
  documentContainer: {
    width: "100%",
    height: "calc(100vh - 200px)",
    border: 0,
  },
  documentIframe: {
    height: "100%",
    width: "100%",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    width: "auto",
  },
  enrollmentSuccessIcon: {
    display: "block",
    fontSize: "150px",
    padding: "20px 0 50px",
    color: theme.palette.primary.main,
  },
  enrollmentSuccessMessage: {
    textAlign: "center",
  },
  surveyQuestionTitle: {
    paddingBottom: 20,
  },
  surveyNav: {
    textAlign: "right",
  },
  surveyQuestion: {
    paddingBottom: 40,
  },
  surveyAnswerGroup: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  surveyAnswer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  surveySuccessIcon: {
    display: "block",
    fontSize: "150px",
    padding: "20px 0 50px",
    color: theme.palette.primary.main,
  },
  surveySuccessMessage: {
    textAlign: "center",
  },
  mobileAppDownload: {
    textAlign: "center",
  },
  mobileAppDownloadSuccessIcon: {
    fontSize: "68px",
    color: theme.palette.primary.main,
  },
  mobileAppDownloadFailureIcon: {
    fontSize: "68px",
    color: theme.palette.error.main,
  },
  mobileAppDownloadPhoneIcon: {
    marginLeft: 15,
  },
  mobileAppDownloadInputLabel: {
    lineHeight: 2,
  },
  mobileAppDownloadInputLine: {
    marginBottom: 20,
  },
  mobileAppDownloadInput: {
    width: 153,
  },
  mobileAppDownloadFinePrint: {
    marginTop: 20,
  },
  mobileAppDownloadQrCode: {
    margin: theme.spacing(2),
  },
  mobileAppDownloadIsMobile: {
    "& $mobileAppDownloadInputLine, & $mobileAppDownloadFinePrint": {
      display: "none",
    },
  },
})
