import {styled} from "@mui/material/styles"
import {func, string} from "prop-types"
import React, {useEffect, useState} from "react"
import Confetti from "react-confetti"
import {useSelector} from "react-redux"
import {useParams} from "react-router-dom"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import {useContentBlockEditor} from "components/content-block-editor/content-block-editor-context"
import {contentBlockPropType} from "components/content-block-editor/content-block-editor-prop-types"
import {actions} from "components/content-block-editor/content-block-editor-reducer"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {useJourneyContext} from "components/journeys/journey-context"

import {createHandler, useSocket} from "contexts/socket-manager"
import {useTemplateContentContext} from "contexts/template-content-context"
import {fetchRewardSets} from "lib/api"

import RewardCircular from "./reward-circular"
import RewardLinear from "./reward-linear"

const Wrapper = styled("div")(({branding}) => ({
  position: "relative",
  backgroundColor: branding.blockBackgroundColor,
}))

const rainbowColors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
]

const RewardsReadonly = ({contentBlock, className, customCss, onClick}) => {
  const {
    data: {caption, progressType, rewardSetId},
    slug,
  } = contentBlock
  const {dispatch, isEditMode, isViewMode, rewardSets, containers} = useContentBlockEditor()
  const {contact, rewards} = useJourneyContext()
  const [unit, setUnit] = useState("")
  const hasRewards = !!Object.values(rewards || {}).length

  const rewardSet = rewards?.reward_sets?.[contentBlock.data.rewardSetId]
  let accrued = 50
  let completed = false
  let completedPercentage = 50
  if (rewardSet) {
    accrued = rewardSet.accrued_rewards
    completed = rewardSet.accrued_rewards === rewardSet?.total_rewards_possible
    completedPercentage = (rewardSet.accrued_rewards * 100) / rewardSet.total_rewards_possible
  }

  useEffect(() => {
    if (isEditMode) {
      fetchRewardSets().then(rewardSets => dispatch({rewardSets, type: actions.setRewardSets}))
    }
  }, [dispatch, isEditMode])

  useEffect(() => {
    if (isViewMode && rewards) {
      // Source carefully to guard nulls
      const personalizedUnit =
        contact?.meta_public && rewardSet
          ? contact.meta_public[rewardSet.personalization_unit]
          : null
      setUnit(personalizedUnit || rewardSet?.unit || "")
    }
  }, [contact, contentBlock, dispatch, rewards, rewardSet, isViewMode])

  const {dispatch: journeyContextDispatch, journeyId} = useJourneyContext()
  const {addHandler, removeHandler} = useSocket()

  useEffect(() => {
    if (isViewMode && hasRewards) {
      const rewardProgressUpdatedHandler = createHandler(
        `journeys:${journeyId}`,
        "reward_progress_updated",
        rewardSets => journeyContextDispatch({type: "SET_REWARDS", rewardSets})
      )
      addHandler(rewardProgressUpdatedHandler)

      return () => removeHandler(rewardProgressUpdatedHandler)
    }
  }, [isViewMode, hasRewards, journeyContextDispatch, journeyId, addHandler, removeHandler])

  useEffect(() => {
    if (rewardSets?.length && rewardSetId) {
      setUnit(rewardSets.find(({id}) => id === rewardSetId)?.unit || "")
    }
  }, [contentBlock, rewardSetId, rewardSets])

  const branding = useBrandingStyles(contentBlock, containers)

  return (
    <Wrapper className={className} onClick={onClick} branding={branding}>
      {completed && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          style={{position: "fixed", top: 0, left: 0}}
          confettiSource={{x: window.innerWidth / 2, y: (window.innerHeight * 9) / 10}}
          recycle={false}
          initialVelocityY={{min: -18, max: -8}}
          initialVelocityX={{min: -10, max: 10}}
          tweenDuration={1000}
          colors={
            contentBlock.data?.confettiTheme === "rainbow"
              ? rainbowColors
              : branding?.confettiColors || rainbowColors
          }
        />
      )}
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      {progressType === "linear" ? (
        <RewardLinear
          accrued={accrued}
          caption={caption}
          progressType={progressType}
          slug={slug}
          unit={unit}
          completed={completed}
          branding={branding}
          completedPercentage={completedPercentage}
        />
      ) : (
        <RewardCircular
          accrued={accrued}
          caption={caption}
          progressType={progressType}
          slug={slug}
          unit={unit}
          completed={completed}
          branding={branding}
          completedPercentage={completedPercentage}
        />
      )}
    </Wrapper>
  )
}

const useBrandingStyles = (contentBlock, containers) => {
  const defaultStyles = {
    valueFontFamily: "Roboto (Default)",
    valueFontSize: "32px",
    valueTextColor: "#000",
    captionFontFamily: "Roboto (Default)",
    captionFontSize: "20px",
    captionTextColor: "#000",
    inProgressColor: "#42a5f5",
    completedColor: "#1976d2",
    trackColor: "#1565c0",
    widgetBackgroundColor: "#FFF",
  }

  // team styles
  const teamStyles = useSelector(state => state.session.team?.themeStyles?.custom?.rewards || {})

  // channel styles
  const {contentContainerId} = useParams()
  const channelStyles =
    containers[contentContainerId]?.engagementChannel?.themeStyles?.custom?.rewards || {}

  // template (from editor) styles
  const templateEditorStyles = useTemplateContentContext()?.template?.theme?.custom.rewards || {}

  // template (from journey) styles
  const templateJourneyStyles = useJourneyContext()?.template?.theme?.custom.rewards || {}

  // content block styles
  const contentBlockStyles = contentBlock.data?.branding || {}

  return {
    ...defaultStyles,
    ...teamStyles,
    ...channelStyles,
    ...templateEditorStyles,
    ...templateJourneyStyles,
    ...contentBlockStyles,
  }
}

RewardsReadonly.propTypes = {
  className: string,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  onClick: func,
}

export default RewardsReadonly
