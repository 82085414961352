import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {array, bool, number, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import Box from "components/box/box"
import Padded from "components/padded/padded"

import {fetchWebhookLogDetails} from "lib/api"
import {formatDateTime} from "lib/date-time-formatters"
import HttpStatusPill from "lib/http-status-pill"

const formatBody = body => {
  try {
    let json = JSON.parse(body)
    return JSON.stringify(json, null, 2)
  } catch {
    return body
  }
}

const NotFoundError = () => {
  return (
    <Padded>
      <Typography variant="h2">Could not find a reference to that webhook</Typography>
      <Typography variant="caption">
        Webhook logs are retained for 30 days. They can not be recovered after that.
      </Typography>
    </Padded>
  )
}

const ResponseDetail = ({classes, request, response, errors}) => {
  if (errors)
    return (
      <Padded>
        <Typography gutterBottom={true} variant="subtitle1">
          <>
            <HttpStatusPill unknown={true} />
            {" Error Sending Webhook"}
          </>
        </Typography>
      </Padded>
    )

  const {body, headers, statusCode, timestamp} = response

  const formattedBody = formatBody(body)

  const renderHeaders = headers =>
    Object.entries(headers).reduce((acc, [key, value]) => {
      const line = (
        <>
          {`${key}: ${value}`}
          <br />
        </>
      )
      return acc === null ? (
        line
      ) : (
        <>
          {acc}
          {line}
        </>
      )
    }, null)

  return (
    <Padded classes={{padded: classes.padded}}>
      <Typography gutterBottom={true} variant="subtitle1">
        <>
          <HttpStatusPill status={statusCode} />
          {" Response"}
        </>
      </Typography>
      <Box>
        {formatDateTime(timestamp)}
        <br />
        <br />
        <b>Request Headers</b>
        <pre className={classes.headers}>{renderHeaders(request.headers)}</pre>
        <b>Response Headers</b>
        <pre className={classes.headers}>{renderHeaders(headers)}</pre>
        <b>Body</b>
        <br />
        <pre className={classes.headers} style={{fontSize: 14}}>
          {formattedBody}
        </pre>
      </Box>
    </Padded>
  )
}

ResponseDetail.propTypes = {
  classes: object,
  errors: string,
  request: object,
  response: shape({
    body: string,
    headers: object,
    statusCode: number,
    timestamp: string,
  }),
}

const useStyles = makeStyles(theme => ({
  headers: {
    fontSize: 14,
    overflowX: "auto",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
}))

const WebhookLogDetails = ({classes: overrideClasses, didError, webhookDetails}) => {
  const classes = {...useStyles(), ...overrideClasses}

  if (didError) return <NotFoundError />

  if (webhookDetails === null)
    return (
      <Padded>
        <Typography variant="subtitle1">Loading</Typography>
      </Padded>
    )

  const {attempts, body, type, url} = webhookDetails

  return (
    <>
      <Padded classes={{padded: classes.padded}}>
        <Typography variant="subtitle1">Request</Typography>
        <Box
          style={{
            background: "linear-gradient(155deg, #489AD488 35%, #5CBDAF88 100%)",
          }}
        >
          <Typography gutterBottom={true} variant="h4">
            {type}
          </Typography>
          <i>{url}</i>
          <br />
          <br />
          <b>Body</b>
          <br />
          <pre style={{fontSize: 14}}>{formatBody(body)}</pre>
        </Box>
      </Padded>
      {attempts.map((attempt, i) => (
        <ResponseDetail classes={classes} key={i} {...attempt} />
      ))}
    </>
  )
}

WebhookLogDetails.propTypes = {
  classes: object,
  didError: bool,
  webhookDetails: shape({
    attempts: array,
    body: string,
    type: string,
    url: string,
  }),
}

export const WebhookLogDetailsContainer = ({match}) => {
  const webhookLogId = match?.params?.webhookLogId
  const [webhookDetails, setWebhookDetails] = useState(null)
  const [didError, setDidError] = useState(false)

  useEffect(() => {
    fetchWebhookLogDetails(webhookLogId)
      .then(details => {
        setWebhookDetails(details)
      })
      .catch(() => {
        setDidError(true)
      })
  }, [webhookLogId])

  return <WebhookLogDetails didError={didError} webhookDetails={webhookDetails} />
}

WebhookLogDetailsContainer.propTypes = {
  match: object,
}

export default WebhookLogDetails
