import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {object, string} from "prop-types"

const useStyles = makeStyles({
  finePrintWrapper: {
    lineHeight: "14px",
    marginTop: 30,
  },
  finePrint: {
    fontSize: 12,
    lineHeight: "inherit",
    color: "#7a7a7a",
  },
})

const OptInFinePrint = ({classOverrides, teamName, termsOfServiceUrl, privacyPolicyUrl}) => {
  const classes = {...useStyles(), ...classOverrides}

  return (
    <div className={classes.finePrintWrapper}>
      <Typography className={classes.finePrint} component="small">
        By submitting your contact information you are agreeing to receive communication from{" "}
        {teamName} and the{" "}
        <a href={termsOfServiceUrl} rel="noopener noreferrer" target="_blank">
          Terms of Use
        </a>{" "}
        and{" "}
        <a href={privacyPolicyUrl} rel="noopener noreferrer" target="_blank">
          Privacy Policy
        </a>
        . Standard messaging rates apply. Message frequency varies. You can opt out at any time.
      </Typography>
    </div>
  )
}

OptInFinePrint.propTypes = {
  classOverrides: object,
  privacyPolicyUrl: string,
  teamName: string,
  termsOfServiceUrl: string,
}

export default OptInFinePrint
