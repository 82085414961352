import {FormControl, InputLabel, ListSubheader, MenuItem, Typography} from "@mui/material"
import cx from "classnames"
import {array, bool, func, object, string} from "prop-types"

import DOSelect from "components/do-select/do-select"

const CampaignTargetingCondition = ({
  classes,
  disabled = false,
  name,
  onChange,
  templateId,
  templates,
  value,
}) => {
  return (
    <>
      <span className={classes.in}>in</span>
      <FormControl classes={{root: classes.fieldSelect}}>
        <InputLabel htmlFor="journey-campaign">Campaign</InputLabel>
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          id="journey-campaign"
          MenuProps={{classes: {paper: classes.fieldSelectMax}}}
          name={name}
          onChange={onChange}
          value={value}
        >
          {templates
            .filter(({value}) => value !== templateId)
            .filter(({isArchived}) => !isArchived)
            .map(({name, value}) => (
              <MenuItem key={value} value={value}>
                <Typography variant="inherit" noWrap>
                  {name}
                </Typography>
              </MenuItem>
            ))}
          <ListSubheader className={classes.listSubheader}>Archived Campaigns</ListSubheader>
          {templates
            .filter(({value}) => value !== templateId && value !== "any")
            .filter(({isArchived}) => isArchived)
            .map(({name, value}) => (
              <MenuItem key={value} value={value}>
                <Typography variant="inherit" noWrap>
                  {name}
                </Typography>
              </MenuItem>
            ))}
        </DOSelect>
      </FormControl>
    </>
  )
}

CampaignTargetingCondition.propTypes = {
  classes: object,
  disabled: bool,
  name: string,
  onChange: func,
  templateId: string,
  templates: array,
  value: string,
}

export default CampaignTargetingCondition
