import {connect} from "react-redux"

import {teamConfig} from "lib/config"

import {onCreateContact} from "./contact-actions"
import NewContact from "./new-contact"

const mapStateToProps = ({session}) => ({
  contactDefaults: teamConfig(session?.team, "do.contact_defaults"),
})

const addActionsToProps = {
  onCreateContact,
}

export default connect(mapStateToProps, addActionsToProps)(NewContact)
