import HtmlButton, {HtmlIcon} from "./html-button"
import HtmlReadonly from "./html-readonly"
import HtmlSettings from "./html-settings"

export default {
  title: "HTML",
  type: "html",
  Button: HtmlButton,
  Icon: HtmlIcon,
  Readonly: HtmlReadonly,
  Settings: HtmlSettings,
  suitableFor: ["content_container", "message", "page", "template"],
}
