import withStyles from "@mui/styles/withStyles"
import {object} from "prop-types"
import {FaArchive as ArchiveIcon} from "react-icons/fa"

const ArchiveCornerBanner = ({classes}) => (
  <div className={classes.cornerBanner}>
    <div className={classes.background} />
    <ArchiveIcon className={classes.icon} />
  </div>
)

const style = theme => ({
  background: {
    position: "absolute",
    borderStyle: "solid",
    borderTopColor: theme.palette.primary.main,
    borderBottomColor: "transparent",
    borderWidth: "45px 45px 0 0",
    borderTopLeftRadius: "4px",
  },
  cornerBanner: {
    position: "relative",
    color: "white",
  },
  icon: {
    position: "absolute",
    marginTop: "5px",
    marginLeft: "5px",
  },
})

ArchiveCornerBanner.propTypes = {
  classes: object.isRequired,
}

export default withStyles(style)(ArchiveCornerBanner)
