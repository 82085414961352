import {node, object} from "prop-types"
import {createContext, useContext} from "react"

const ContentBlockContext = createContext()
ContentBlockContext.displayName = "ContentBlockContext"
const {Provider} = ContentBlockContext

export const ContentBlockProvider = ({contentBlock, children}) => {
  return <Provider value={{contentBlock}}>{children}</Provider>
}

ContentBlockProvider.propTypes = {
  children: node.isRequired,
  contentBlock: object.isRequired,
}

export const useContentBlock = () => useContext(ContentBlockContext)

export const provideContentBlock = Component => props => (
  <ContentBlockProvider>
    <Component {...props} />
  </ContentBlockProvider>
)

export const contentBlockConsumer = Component => props => (
  <Component {...useContentBlock()} {...props} />
)
