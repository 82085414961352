import {Box, Paper, Typography} from "@mui/material"
import {arrayOf, number, shape, string} from "prop-types"

import pluralize from "../../lib/string/pluralize"

const DripTimelineOverview = ({overview}) => (
  <Paper
    sx={theme => ({
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
    })}
  >
    <Typography variant="body1" paragraph fontWeight="bold">
      {`There ${overview.length === 1 ? "is" : "are"} ${overview.length} ${pluralize(
        "nurturing message",
        overview.length
      )} that will send after journey creation`}
    </Typography>
    <Typography variant="body1" paragraph>
      Messages send according to the schedule and order as set below. Each journey is started on
      "Day 0" and each contact receives messages in the order below. When a contact has completed
      all criteria, they will no longer be eligible to receive that message and will receive the
      next message they're eligible to receive.
    </Typography>
    {overview
      .sort((a, b) => a.day - b.day)
      .map((message, index) => (
        <Box key={`${message.day}-${message.type}-${index}`} mb={2}>
          <Typography variant="subtitle1" component="div" fontWeight="bold">
            {`Message #${index + 1}`}
          </Typography>
          <Typography variant="body1">{`Type: ${message.type}`}</Typography>
          <Typography variant="body1">{`Sends: Day ${message.day} at 10:00 AM`}</Typography>
        </Box>
      ))}
  </Paper>
)

DripTimelineOverview.defaultProps = {overview: []}

DripTimelineOverview.propTypes = {
  overview: arrayOf(
    shape({
      day: number.isRequired,
      type: string.isRequired,
    })
  ),
}

export default DripTimelineOverview
