import {IconButton, MenuItem, TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {arrayOf, func, number, object, shape, string} from "prop-types"
import {
  FaArchive as ArchiveIcon,
  FaArrowsAltV as DragHandleIcon,
  FaEye as PreviewIcon,
} from "react-icons/fa"
import {MdClose as DeleteIcon, MdEdit as EditIcon} from "react-icons/md"
import {SortableElement, SortableHandle} from "react-sortable-hoc"

import {getCtaLinkErrorForBlock} from "components/content-block-editor/plugins/cta/cta-readonly"

import BadgeIfError from "../badge-if-error/badge-if-error"
import {Preview} from "../content-library/content-preview"
import {humanizeType} from "../content-library/helpers"
import DOSelect from "../do-select/do-select"
import IconTooltip from "../icon-tooltip/icon-tooltip"
import MuiIcon from "../mui-icon"

const DragHandle = SortableHandle(({className}) => (
  <div className={className}>
    <MuiIcon icon={<DragHandleIcon />} />
  </div>
))

const useStyles = makeStyles(theme => ({
  archived: {
    position: "relative",

    "&::before": {
      borderBottom: "60px solid transparent",
      borderLeft: `60px solid ${theme.palette.primary.main}`,
      content: `""`,
      height: 0,
      left: 0,
      position: "absolute",
      top: 0,
      width: 0,
    },
  },
  archiveIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: 20,
    left: -8,
    position: "relative",
    top: -17,
  },
  row: {
    "& .showOnHover": {
      visibility: "hidden",
    },
    "&:hover .showOnHover": {
      visibility: "visible",
    },
  },
  edit: {
    textTransform: "uppercase",
    cursor: "pointer",
  },
  dragHandleCell: {
    cursor: "pointer",
  },
  actionCell: {
    display: "flex",
    "& > button:first-of-type": {
      marginRight: theme.spacing(1),
    },
  },
  preview: {
    width: 306,
  },
  previewTooltip: {
    maxWidth: "none",
  },
  destructiveAction: {
    color: theme.palette.error.main,
  },
  actionText: {
    cursor: "pointer",
  },
  select: {
    fontSize: 14,
    maxWidth: 200,
  },
}))

const ensureValidValue = (templatePageId, templatePages) => {
  // MUI 5 now console.warn's if the selected value
  // is not an available MenuItem option
  if (templatePages?.find(tp => tp.id === templatePageId)) return templatePageId
  return ""
}

export const messageHasInvalidCta = (templateMessage, templatePages) => {
  const content_variables = templateMessage.content_variables
  const message = templateMessage.message

  // Email messages vs SMS messages encode CTA links differently, so we need branching validation logic.
  if (message.type === "email") {
    return message.contentBlocks
      .filter(block => ["cta", "image"].includes(block.type))
      .some(
        block =>
          !!getCtaLinkErrorForBlock({
            contentBlock: block,
            content_variables: content_variables,
            isEditMode: true,
            template: {id: templateMessage.templateId, templatePages: templatePages},
          })
      )
  } else {
    // Message type is "SMS":
    const slug = message.payload.slug
    const pageSlugExistsInTemplate = templatePages.find(
      ({page}) => page.newSlug === content_variables?.[slug]
    )

    return message.payload.linksTo === "journey-page" && !pageSlugExistsInTemplate
  }
}

const NurturingMessageRow = ({
  column,
  row,
  i,
  setTemplateMessageToRemove,
  editTemplateMessage,
  templatePages,
  updateTemplateMessage,
}) => {
  const classes = useStyles()

  // `row` is a templateMessage record, so we rename it for clarity.
  const templateMessage = row
  const {id, messageId, message, templatePageId} = templateMessage
  const {contentName, isArchived, objective} = message
  const type = humanizeType(message.type)

  const onChangeIneligibilityCondition = ({target: {value}}) =>
    updateTemplateMessage(id, {
      templatePageId: value === "objective" ? null : value,
    })

  const hasArchivedColumn = column.find(c => c.field === "archived")

  return (
    <TableRow className={classes.row} tabIndex={0}>
      {hasArchivedColumn && (
        <TableCell
          className={cx({[classes.archived]: isArchived})}
          component="td"
          data-testid="archived-column"
          scope="row"
        >
          {isArchived && (
            <IconTooltip
              iconClassName={classes.archiveIcon}
              iconComponent={ArchiveIcon}
              title={"This message is archived, but will still work as normal for this campaign."}
            />
          )}
        </TableCell>
      )}
      <TableCell className={classes.dragHandleCell} component="td" scope="row">
        <DragHandle className="showOnHover" />
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{i + 1}</Typography>
      </TableCell>
      <TableCell>
        <Tooltip
          classes={{tooltip: classes.previewTooltip}}
          placement="right"
          title={
            <div className={classes.preview}>
              <Preview
                contentType={type.toLowerCase()}
                id={messageId}
                smsPreviewText={message.payload?.body?.blocks[0]?.text}
              />
            </div>
          }
        >
          <div>
            <PreviewIcon />
          </div>
        </Tooltip>
      </TableCell>
      <TableCell component="td" scope="row">
        <BadgeIfError
          badgeText="This message contains a CTA with an invalid or incomplete link."
          hasError={messageHasInvalidCta(templateMessage, templatePages)}
        >
          <Typography
            className={classes.actionText}
            onClick={() => editTemplateMessage(id)}
            title={`Edit ${contentName}`}
            variant="body2"
          >
            {contentName}
          </Typography>
        </BadgeIfError>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{type}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <DOSelect
          className={classes.select}
          fullWidth={true}
          onChange={onChangeIneligibilityCondition}
          value={(templatePageId && ensureValidValue(templatePageId, templatePages)) || "objective"}
        >
          {objective ? (
            <MenuItem key="objective" value="objective">
              Objective Completed
            </MenuItem>
          ) : (
            <MenuItem key="objective" value="objective">
              Always Send
            </MenuItem>
          )}
          {templatePages?.map(tp => (
            <MenuItem key={tp.id} value={tp.id}>
              Completed {tp.page.contentName}
            </MenuItem>
          ))}
        </DOSelect>
      </TableCell>
      <TableCell component="td" scope="row">
        <Typography variant="body2">{objective?.name || "(None)"}</Typography>
      </TableCell>
      <TableCell component="td" scope="row">
        <div className={cx(classes.actionCell, "showOnHover")}>
          <Tooltip title={`Edit ${contentName}`}>
            <IconButton onClick={() => editTemplateMessage(id)} size="medium">
              <EditIcon size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Remove ${contentName}`}>
            <IconButton
              className={classes.destructiveAction}
              onClick={() => setTemplateMessageToRemove(id)}
              size="medium"
            >
              <DeleteIcon size={18} />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  )
}

NurturingMessageRow.propTypes = {
  column: arrayOf(
    shape({
      field: string.isRequired,
    })
  ).isRequired,
  editTemplateMessage: func.isRequired,
  i: number.isRequired,
  row: object.isRequired,
  setTemplateMessageToRemove: func.isRequired,
  templatePages: arrayOf(
    shape({
      id: string,
      page: shape({
        contentName: string,
      }),
    })
  ),
  updateTemplateMessage: func.isRequired,
}

export default SortableElement(NurturingMessageRow)
