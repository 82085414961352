import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {array, bool, func, string} from "prop-types"
import {useEffect, useState} from "react"
import {TiPlus as PlusIcon} from "react-icons/ti"
import {Link} from "react-router-dom"

import {fetchContacts, fetchCurrentTeam} from "lib/api"
import isIe11 from "lib/browser/is-ie-11"
import useFeatures from "lib/hooks/use-features"
import {parameterize} from "lib/hooks/use-query-params"
import storage from "lib/storage"
import score from "lib/string/score"

import AccessControlled from "../access-control/access-controlled"
import {useCampaignBuilder} from "../campaign-builder/campaign-builder-context"
import DOSelect from "../do-select/do-select"
import DocumentTitle from "../document-title/document-title"
import ExportButton from "../export-button/export-button"
import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"
import TemplateCard from "./template-card"

const useStyles = makeStyles(theme => ({
  grid: {
    // Grid for real browsers
    display: "grid",
    gridTemplateColumns: "1fr",
    msGridTemplateColumns: "1fr",
    gridColumnGap: "32px",
    msGridColumnGap: "32px",
    gridRowGap: "32px",
    msGridRowGap: "32px",
    fallbacks: [{display: "-ms-grid"}],
    [theme.breakpoints.up("md")]: {
      // What is this doing you might ask? Well it creates as many columns as it
      // can cram on the screen that are no smaller than 300px.
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      msGridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    },
  },
  filterBar: {
    display: "flex",
    paddingBottom: 0,
  },
  filterText: {
    flex: 1,
    marginLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  buttonBar: {
    width: "100%",
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    paddingTop: 0,
  },
  ieGrid: {
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 0,
    margin: "0 auto",
  },
  sortingSelect: {
    width: 220,
  },
}))

const campaignSortKey = "campaigns:dashboard:sort"
const storeCampaignSort = state => storage.setItem(campaignSortKey, JSON.stringify(state))

const defaultSorting = {
  sortKey: "max_contact_added_at",
  sortDirection: "desc",
}

const campaignStatusFilterKey = "campaigns:dashboard:status-filter"
const storeCampaignStatusFilter = state =>
  storage.setItem(campaignStatusFilterKey, JSON.stringify(state))

export const isTemplateLimitReached = team =>
  !team ||
  (team.package === "starter" && team.countActiveTemplates >= team.org.starterPackageTemplateLimit)

const AllStatuses = [
  {label: "Approved", value: "approved"},
  {label: "Archived", value: "archived"},
  {label: "Draft", value: "draft"},
  {label: "Live", value: "live"},
  {label: "Not Approved", value: "not-approved"},
  {label: "Pending Approval", value: "pending-approval"},
]

const allStatusesExcept = value => AllStatuses.filter(s => s.value !== value)

const TemplatesList = ({
  getTemplates,
  archived,
  updateSearchQuery,
  filter,
  sortKey,
  sortDirection,
  templates,
}) => {
  const [initialSorting] = useState(() => {
    try {
      const item = storage.getItem(campaignSortKey)
      return item ? JSON.parse(item) : defaultSorting
    } catch (e) {
      return defaultSorting
    }
  })

  const {onOpen: onOpenCampaignBuilder} = useCampaignBuilder()

  const sortKeyValue = sortKey || initialSorting.sortKey
  const sortDirectionValue = sortDirection || initialSorting.sortDirection

  const [mayAddTemplates, setMayAddTemplates] = useState(undefined)

  useEffect(() => {
    fetchCurrentTeam({withOrg: true, withCountActiveTemplates: true}).then(team =>
      setMayAddTemplates(!isTemplateLimitReached(team))
    )
  }, [templates])

  useEffect(() => {
    getTemplates({
      withFacts: true,
      includeArchived: archived,
      sortColumn: sortKeyValue,
      sortDirection: sortDirectionValue,
    })
  }, [getTemplates, archived, sortKeyValue, sortDirectionValue])

  useEffect(() => {
    storeCampaignSort({sortKey: sortKeyValue, sortDirection: sortDirectionValue})
  }, [sortKeyValue, sortDirectionValue])

  const {hasFeature} = useFeatures()
  const classes = useStyles()

  const [statusFilter, setStatusFilter] = useState(() =>
    JSON.parse(storage.getItem(campaignStatusFilterKey) || "[]")
  )

  const updateStatusFilter = update => {
    setStatusFilter(update)
    storeCampaignStatusFilter(update)
  }

  const handleFilter = ({target}) => updateSearchQuery({filter: target.value})

  const handleDisplayArchived = ({target}) =>
    updateStatusFilter(target.checked ? [] : allStatusesExcept("archived").map(s => s.value))

  const onSortChange = ({target}) => {
    const [_sortKey, _sortDirection] = target.value.split("-")

    updateSearchQuery({sortKey: _sortKey, sortDirection: _sortDirection})
  }

  const lowercasedFilter = filter?.toLowerCase()

  const launchedAtField = hasFeature("campaign-approval") ? `launched_at` : `min_contact_added_at`

  const renderNewCampaignOrTemplateButton = ({disabled}) =>
    hasFeature("atomic-assets") ? (
      <Button
        color="primary"
        onClick={onOpenCampaignBuilder}
        size="small"
        variant="contained"
        disabled={disabled}
      >
        <PlusIcon /> Build a new Campaign
      </Button>
    ) : (
      <Button
        color="primary"
        component={Link}
        size="small"
        to="/admin/templates/new"
        variant="contained"
        disabled={disabled}
      >
        <PlusIcon /> Create a new Template
      </Button>
    )

  return (
    <div data-testid="dashboard">
      <DocumentTitle title="Campaigns" />

      <TitleBar title="Campaign Dashboard">
        <AccessControlled requiredPermissions="templates:edit">
          {mayAddTemplates ? (
            renderNewCampaignOrTemplateButton({disabled: false})
          ) : (
            <Tooltip title="You cannot create a campaign without exceeding your Active Campaign Limit. Please archive one of your existing campaigns before creating a new campaign">
              <span>{renderNewCampaignOrTemplateButton({disabled: true})}</span>
            </Tooltip>
          )}
        </AccessControlled>
      </TitleBar>

      <Padded className={classes.filterBar} unbounded={true}>
        <FormControlLabel
          className={classes.filterText}
          control={
            <TextField
              fullWidth={true}
              onChange={handleFilter}
              placeholder="Filter campaigns..."
              value={filter}
            />
          }
          label=""
        />

        {!hasFeature("campaign-approval") && (
          <FormControlLabel
            control={
              <Switch
                checked={statusFilter?.includes("archived") || statusFilter?.length === 0}
                color="primary"
                onChange={handleDisplayArchived}
              />
            }
            label="Display Archived"
          />
        )}

        {hasFeature("campaign-approval") && (
          <FormControl margin="none" sx={{minWidth: "150px", marginRight: "10px"}}>
            <InputLabel>Filter by Status</InputLabel>
            <DOSelect
              multiple={true}
              value={statusFilter}
              renderValue={() => (
                <div style={{maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis"}}>
                  {statusFilter
                    .map(value => AllStatuses.find(s => s.value === value).label)
                    .join(", ")}
                </div>
              )}
              onChange={e => updateStatusFilter(e.target.value)}
            >
              {AllStatuses.map(({label, value}) => (
                <MenuItem key={value} value={value}>
                  <Checkbox checked={statusFilter.includes(value)} color={"primary"} />
                  {label}
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
        )}

        <FormControl margin="none">
          <InputLabel>Sort By</InputLabel>
          <DOSelect
            className={classes.sortingSelect}
            onChange={onSortChange}
            value={`${sortKeyValue}-${sortDirectionValue}`}
          >
            <MenuItem value={`${launchedAtField}-desc`}>Launch Date (newest first)</MenuItem>
            <MenuItem value={`${launchedAtField}-asc`}>Launch Date (oldest first)</MenuItem>
            <MenuItem value="name-asc">A to Z</MenuItem>
            <MenuItem value="name-desc">Z to A</MenuItem>
            <MenuItem value="inserted_at-asc">Date Added (oldest first)</MenuItem>
            <MenuItem value="inserted_at-desc">Date Added (newest first)</MenuItem>
            <MenuItem value="max_contact_added_at-asc">
              Latest Contact Added (oldest first)
            </MenuItem>
            <MenuItem value="max_contact_added_at-desc">
              Latest Contact Added (newest first)
            </MenuItem>
            <MenuItem value="journey_count-desc">Most journeys</MenuItem>
            <MenuItem value="journey_count-asc">Least journeys</MenuItem>
          </DOSelect>
        </FormControl>
      </Padded>
      <Padded className={classes.buttonBar} unbounded={true}>
        <AccessControlled requiredPermissions="contacts:view">
          <ExportButton
            fetchRecords={fetchContacts}
            filename="contacts_unsubscribes"
            params={{isUnsubscribed: true}}
            title="Download Unsubscribes"
          />
        </AccessControlled>
      </Padded>

      <Padded
        className={cx({
          [classes.grid]: !isIe11(),
          [classes.ieGrid]: isIe11(),
        })}
        role="list"
        unbounded={true}
      >
        {templates
          .map(template => ({
            template,
            similarity: lowercasedFilter ? score(template.name.toLowerCase(), lowercasedFilter) : 1,
          }))
          .filter(
            ({similarity, template}) =>
              !lowercasedFilter ||
              similarity >= 0.85 ||
              template.name.toLowerCase().includes(lowercasedFilter)
          )
          .filter(({template}) =>
            statusFilter.length === 0 ? true : statusFilter.includes(template.status)
          )
          .sort((lhs, rhs) => (!lowercasedFilter ? 0 : rhs.similarity - lhs.similarity))
          .map(({template}) => (
            <TemplateCard key={template.id} template={template} />
          ))}
      </Padded>
    </div>
  )
}

TemplatesList.propTypes = {
  archived: bool.isRequired,
  filter: string.isRequired,
  getTemplates: func.isRequired,
  sortDirection: string,
  sortKey: string,
  templates: array.isRequired,
  updateSearchQuery: func.isRequired,
}

export default parameterize({archived: true, filter: ""}, ["filter"])(TemplatesList)
