import makeStyles from "@mui/styles/makeStyles"
import get from "lodash/get"
import {arrayOf, node, string} from "prop-types"

import {useBrandingSettings} from "components/teams/branding-settings/branding-settings-context"

const ResetLabel = ({children, name, names}) => {
  const classes = useStyles()

  const {change, theme, type} = useBrandingSettings()

  if (type !== "template") return <div>{children}</div>

  if (!names) names = [name]

  if (get(theme, names[0]) === undefined) return <div>{children}</div>

  return (
    <div>
      {children} (
      <span
        className={classes.link}
        onClick={e => {
          // Prevents ColorInput from opening its color picker when clicking reset label
          e.preventDefault()
          names.forEach(name => change(name, undefined))
        }}
      >
        reset to default
      </span>
      )
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}))

ResetLabel.propTypes = {
  children: node.isRequired,
  name: string,
  names: arrayOf(string),
}

export default ResetLabel
