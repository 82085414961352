import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import cloneDeep from "lodash/cloneDeep"
import memoize from "memoize-one"
import {bool, object, shape, string} from "prop-types"
import {useEffect, useMemo} from "react"

import {compileCss} from "lib/custom-css"
import {loadFontsFromTheme} from "lib/fonts"

import unmemoizedCreateTheme from "../../themes/team-theme-creator"
import Journey from "./journey"
import themeBases from "./theme-bases"

const createCompiledCustomCss = memoize((source, isEditable) => {
  try {
    return compileCss(isEditable ? "#custom-css-root" : "#root", source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const createTheme = memoize(theme => unmemoizedCreateTheme(theme))
const createClasses = memoize(base => makeStyles(base))

const JourneyThemeProvider = props => {
  const theme = useMemo(() => cloneDeep(props.template.theme) || {}, [props.template.theme])
  const muiTheme = createTheme(theme)

  useEffect(() => {
    loadFontsFromTheme(theme)
  }, [theme])

  return (
    <ThemeProvider theme={muiTheme}>
      <ThemedJourney {...props} />
    </ThemeProvider>
  )
}

JourneyThemeProvider.propTypes = {
  template: shape({
    theme: object,
  }).isRequired,
}

const ThemedJourney = props => {
  const {
    isEditable,
    template: {themeBase, customCss},
  } = props
  const compiledCustomCss = createCompiledCustomCss(customCss, isEditable) || ""
  const classes = createClasses(themeBases[themeBase] || themeBases.default)()

  const key = props.journeySlug || props.templateId

  if (!key) return null

  return <Journey {...props} classes={classes} customCss={compiledCustomCss} key={key} />
}

ThemedJourney.propTypes = {
  isEditable: bool,
  journeySlug: string,
  template: shape({
    themeBase: string,
    customCss: string,
  }).isRequired,
  templateId: string,
}

export default JourneyThemeProvider
