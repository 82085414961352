import SurveyButton, {SurveyIcon} from "./survey-button"
import SurveyReadonly from "./survey-readonly"
import SurveySettings from "./survey-settings"

export default {
  title: "Survey",
  type: "survey",
  Button: SurveyButton,
  Icon: SurveyIcon,
  Readonly: SurveyReadonly,
  Settings: SurveySettings,
  suitableFor: ["content_container", "page"],
}
