import MobileAppDownloadButton, {MobileAppIcon} from "./mobile-app-download-button"
import MobileAppDownloadReadonly from "./mobile-app-download-readonly"
import MobileAppDownloadSettings from "./mobile-app-download-settings"

export default {
  title: "Mobile App Download",
  type: "mobile-app-download",
  Button: MobileAppDownloadButton,
  Icon: MobileAppIcon,
  Readonly: MobileAppDownloadReadonly,
  Settings: MobileAppDownloadSettings,
  suitableFor: ["content_container", "page"],
}
