import {EXPORT_CLEARED, EXPORT_ERRORED, EXPORT_PROGRESSED, EXPORT_STARTED} from "./exporter-actions"

const initialState = {
  isExporting: false,
  exportError: false,
  exportProgress: 0,
}

function exporter(state = initialState, action) {
  switch (action.type) {
    case EXPORT_STARTED:
      return {
        ...state,
        isExporting: true,
      }
    case EXPORT_ERRORED:
      return {
        ...state,
        exportError: true,
      }
    case EXPORT_PROGRESSED:
      return {
        ...state,
        exportProgress: action.payload,
      }
    case EXPORT_CLEARED:
      return {
        ...state,
        isExporting: false,
        exportError: false,
        exportProgress: 0,
      }

    default:
      return state
  }
}

export default exporter
