import {Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import cx from "classnames"
import {object} from "prop-types"
import {useEffect, useState} from "react"

import {fetchInsightCardOnFileOverview} from "lib/api"
import pluralize from "lib/string/pluralize"

import {useInsights} from "./insights-context"
import {INSIGHTS_COLORS} from "./insights-helpers"
import StatsPie from "./stats-pie"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const CardOnFileOverview = ({classes}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const {
    insights: {card_on_file_overview},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const coffData = card_on_file_overview?.value.result || []
  const totalJourneys = card_on_file_overview?.value.totalJourneys || 0
  const totalLabel = pluralize("journey", totalJourneys)

  useEffect(() => {
    fetchInsightCardOnFileOverview(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return <Skeleton variant="circular" width={150} height={150} />

  return (
    <>
      <Typography className={cx(classes.subheader, classes.coffSubheader)} variant="h6">
        Overview
      </Typography>
      {totalJourneys > 0 ? (
        <StatsPie colors={colors} data={coffData} total={totalJourneys} totalLabel={totalLabel} />
      ) : (
        <div>No data available</div>
      )}
    </>
  )
}

CardOnFileOverview.propTypes = {
  classes: object.isRequired,
}

export default CardOnFileOverview
