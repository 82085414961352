// Field validations for redux-form
import {format} from "date-fns"

import {isBlank} from "./string/presence"
import validateEmail from "./string/validate-email"

const maxStringLength = 255

export const validDate = val => {
  const errorMessage = "Invalid date"

  if (!val || isNaN(val)) {
    return errorMessage
  }

  const dateString = format(val, "MM/dd/yyyy")
  const isReasonableDate = dateString.match(
    /^(0[1-9]|1[1-2])\/([0-2][0-9]|3[0-1])\/(19|20)[0-9][0-9]/
  )

  return isReasonableDate ? null : errorMessage
}

export const validEmail = val => (val && !validateEmail(val) ? "Invalid email address" : null)

export const validPhone = val =>
  val && !val.replace(/\D/g, "").match(/^1?\d{10}$/) ? "Invalid phone number" : null

export const validDomainAndTld = val => (val && !/\.[a-zA-Z]{2,}/.test(val) ? "Invalid URL" : null)

export const validUrlOrMultipleUrls = val => {
  if (!val) return null
  return !val.split(/\s+/).every(u => isUrlValid(u)) ? "Invalid URL" : null
}

const isUrlValid = userInput => {
  return userInput.startsWith("https://")
}

export const requiredField = val => (val ? null : "Required")

export const validHtml = val => {
  const testEl = document.createElement("div")

  testEl.innerHTML = val

  return testEl.innerHTML !== val ? "Invalid HTML" : null
}

export const validCssFontSize = val => {
  const cssNumberRegex = /^-?\d*\.?\d+(px|em|rem|%|vh|vw|vmin|vmax|ch|ex|cm|mm|in|pt|pc)$/
  return cssNumberRegex.test(val) ? null : "Invalid CSS"
}

export const validString = val =>
  isBlank(val)
    ? "Cannot be blank"
    : val?.length > maxStringLength
    ? `Cannot be longer than ${maxStringLength} characters`
    : null

export const nonEmptyString = val => (isBlank(val) ? "Cannot be blank" : null)

const validIntegerGreaterThan = (valueGreaterThan, message) => valueToTest =>
  // eslint-disable-next-line
  parseInt(valueToTest, 10) == valueToTest &&
  Number.isInteger(parseInt(valueToTest, 10)) &&
  valueGreaterThan < valueToTest
    ? null
    : message

export const validPositiveNumber = val => {
  if (!val) return null
  const value = Number(val)
  return value >= 0 ? null : "Invalid number (must be positive)"
}

export const validNonnegativeInteger = validIntegerGreaterThan(
  -1,
  "Must be an integer zero or greater"
)

export const validPositiveInteger = validIntegerGreaterThan(0, "Must be a positive integer")

export const nonEmptyArray = arr =>
  arr && Array.isArray(arr) && 0 < arr.length ? null : "Required"
