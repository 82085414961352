import {TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cx from "classnames"
import orderBy from "lodash/orderBy"
import pick from "lodash/pick"
import {func, node, number, object, string} from "prop-types"
import {useEffect, useMemo} from "react"
import {BsDashLg as DashIcon} from "react-icons/bs"
import {CgArrowLongDown as ArrowDownIcon, CgArrowLongUp as ArrowUpIcon} from "react-icons/cg"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchAdvancedInsightsObjectiveCompletionBenchmarks} from "lib/api"
import theme from "themes/main"

const namespace = "advanced-team-insights-objective-completion-benchmarking"

const headers = [
  {
    field: "objective",
    label: "Objective",
    sortable: true,
    tooltipText: "Objective: List of all objectives on your team",
  },
  {
    field: "presentedObjectiveCompletionRate",
    label: "Objective Completion Rate v. Benchmark",
    sortable: true,
    tooltipText:
      "Objective Completion Rate v. Benchmark: total # of unique objective completions for all contacts / # of unique objectives presented to contacts",
  },
  {
    field: "benchmark",
    label: "Your Benchmark",
    sortable: true,
    tooltipText: (
      <span>
        Your Benchmark: Objective's benchmark input (found on the{" "}
        <Typography
          color="inherit"
          component="a"
          href={"/admin/insights/your-metrics"}
          rel="noopener noreferrer"
          target="_blank"
          style={{fontSize: 14}}
        >
          Your Metrics
        </Typography>{" "}
        page)
      </span>
    ),
  },
]
const directions = ["up", "down"]

const getColor = color => {
  switch (color) {
    case "blue":
      return theme.palette.brand.lightBlue
    case "red":
      return theme.palette.brand.lightCoral
    default:
      return "#000000"
  }
}

// NB: We need to sort the row object in accordance to the header's index per field to get the corresponding data for each column.
const fields = headers.map(header => header.field)
const sortByIndex = ([a], [b]) =>
  fields.findIndex(field => field === a) - fields.findIndex(field => field === b)

const humanize = value => {
  if (isNaN(value)) return value
  return parseInt(value, 10).toString()
}

const Arrow = ({children, color, difference, direction}) => {
  const renderArrow = () => {
    switch (direction) {
      case "up":
        return <ArrowUpIcon color={color} size={20} />
      case "down":
        return <ArrowDownIcon color={color} size={20} />
      default:
        return <DashIcon color="#000000" size={20} />
    }
  }

  const valueMarginRight = direction === "dash" ? 10 : 0
  const valueAlignment =
    direction === "up" ? "flex-start" : direction === "down" ? "flex-end" : "center"

  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <span style={{display: "inline-block", marginRight: valueMarginRight}}>{children}</span>
      <Tooltip title="Arrow/Dash and PP: Percentage point difference between the Objective Completion Rate and Your Benchmark. An up arrow & blue text highlights a PP lift, a down arrow & corral represent a PP decrease, and a dash means there is no PP difference.">
        <div
          style={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: valueAlignment,
          }}
        >
          {renderArrow()}
          {directions.includes(direction) && (
            <span style={{color: color, display: "inline-block", fontSize: 10}}>{`${humanize(
              difference
            )}pp`}</span>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

Arrow.propTypes = {
  children: node,
  color: string,
  difference: number,
  direction: string,
}

const ObjectiveCompletionBenchmarking = ({classes, setTableState, ...props}) => {
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    objectiveCompletionBenchmarks,
    objectiveCompletionBenchmarksIsLoading,
  } = useAdvancedTeamInsights()

  const _classes = useStyles()
  const data = useMemo(() => objectiveCompletionBenchmarks?.data ?? [], [
    objectiveCompletionBenchmarks,
  ])

  useEffect(() => {
    if (hasSocket)
      fetchInsights(
        "objectiveCompletionBenchmarks",
        fetchAdvancedInsightsObjectiveCompletionBenchmarks,
        [filterParams]
      )
  }, [fetchInsights, filterParams, hasSocket])

  const refresh = attrs => {
    const currentItemsPerPage = attrs?.itemsPerPage ?? props.itemsPerPage
    const currentPage = attrs?.page ?? props.page
    const offset = currentPage * currentItemsPerPage
    const sortColumn = attrs?.sortColumn ?? props.sortColumn
    const sortDirection = attrs?.sortDirection ?? props.sortDirection

    const rows = orderBy(
      data,
      [
        item => {
          const column = item[sortColumn].value

          return isNaN(column) ? column.toLowerCase() : column
        },
      ],
      [sortDirection]
    ).filter((item, i) => i >= offset && i < offset + currentItemsPerPage)

    setTableState({
      ...pick(props, "itemsPerPage", "page", "sortColumn", "sortDirection"),
      ...attrs,
      rows,
      page: currentPage,
      totalCount: data.length ?? 0,
    })
  }

  const renderCell = (column, {color, difference, direction, hasArrow, value}) => {
    if (column === "benchmark" && value === 0) return <DashIcon color="#000000" size={20} />

    const sign = isNaN(value) ? "" : "%"

    if (!hasArrow) return `${humanize(value)}${sign}`

    return (
      <Arrow color={getColor(color)} difference={difference} direction={direction}>
        {`${humanize(value)}${sign}`}
      </Arrow>
    )
  }

  useEffect(() => {
    // NB: This is needed so that our rows are effected by our default state.
    // This should only get called after fetchInsights or our socket
    // returns new data.
    refresh({page: 0})

    // FIXME ignoring react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className={cx(_classes.container)}>
      <Typography className={_classes.title} variant="h5">
        Objective Completion Benchmarking
      </Typography>
      <DOTable
        headers={headers}
        isTableLoading={objectiveCompletionBenchmarksIsLoading}
        noResults="No data available."
        paginationEnabled={true}
        refresh={refresh}
      >
        {(row, index) => {
          return (
            <TableRow key={`${namespace}-${index}`}>
              {Object.entries(row)
                .sort(sortByIndex)
                .map(([key, value], i) => (
                  <TableCell key={`${namespace}-${key}-${index}-${i}`}>
                    {renderCell(key, value)}
                  </TableCell>
                ))}
            </TableRow>
          )
        }}
      </DOTable>
    </div>
  )
}

ObjectiveCompletionBenchmarking.propTypes = {
  classes: object,
  itemsPerPage: number,
  page: number,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
}

const useStyles = makeStyles(theme => ({
  container: {
    "& tr th": {
      width: 100,
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default tabular({itemsPerPage: 5, sortColumn: "objective", sortDirection: "asc"})(
  ObjectiveCompletionBenchmarking
)
