import {Button, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip} from "@mui/material"
import {alpha} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {array, bool, object, string} from "prop-types"
import {Fragment} from "react"
import {MdDelete as DeleteIcon} from "react-icons/md"

import TargetingCondition from "./targeting-condition"
import {useTemplateTargeting} from "./template-targeting-context"

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${alpha(theme.palette.brand.mediumGray, 0.15)}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[6],
    "&:hover $removeTargetingGroup": {
      opacity: 1,
      color: theme.palette.error.main,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.brand.lightGreen}`,
    background: theme.palette.brand.lightestGray,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    height: 60,
    overflow: "hidden",
  },
  removeTargetingGroup: {
    opacity: 0.5,
    transition: theme.transitions.create("all"),
  },
  content: {
    padding: theme.spacing(2),
  },
  logicalOperator: {
    display: "flex",
    height: 0,
    justifyContent: "center",
    alignItems: "center",
    borderTop: `1px dashed ${theme.palette.brand.mediumGray}`,
    margin: `${theme.spacing(4)} 0 ${theme.spacing(3)}`,
    "& span": {
      border: `1px solid ${theme.palette.brand.mediumGray}`,
      borderRadius: theme.spacing(2),
      background: theme.palette.common.white,
      padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
      fontWeight: "bold",
      "&.or": {
        borderStyle: "dashed",
      },
    },
  },
  addCondition: {
    marginTop: theme.spacing(2),
  },
}))

const TargetingGroup = ({
  cardOnFileTargetingValues,
  disabled,
  enrollmentContentBlocks,
  id,
  logicalOperator,
  objectives,
  surveyContentBlocks,
  surveyQuestions,
  targetingConditions,
  templates,
}) => {
  const classes = useStyles()
  const {addTargetingCondition, updateTargetingGroup, removeTargetingGroup} = useTemplateTargeting()

  const onUpdateLogicalOperator = ({target}) => {
    updateTargetingGroup({id, logicalOperator: target.value})
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <RadioGroup
          name="status"
          row={true}
          onChange={onUpdateLogicalOperator}
          value={logicalOperator}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Match ALL of the conditions below"
            labelPlacement="end"
            value="AND"
            disabled={disabled}
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Match ANY of the conditions below"
            labelPlacement="end"
            value="OR"
            disabled={disabled}
          />
        </RadioGroup>
        {!disabled && (
          <Tooltip title="Remove targeting group">
            <IconButton
              aria-label="Remove targeting group"
              className={classes.removeTargetingGroup}
              onClick={() => removeTargetingGroup(id)}
              tabIndex={-1}
              size="medium"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.content}>
        {targetingConditions.map((targetingCondition, i) => (
          <Fragment key={targetingCondition.id}>
            <TargetingCondition
              cardOnFileTargetingValues={cardOnFileTargetingValues}
              disabled={disabled}
              enrollmentContentBlocks={enrollmentContentBlocks}
              objectives={objectives}
              targetingCondition={{...targetingCondition, targetingGroupId: id}}
              templates={templates}
              surveyContentBlocks={surveyContentBlocks}
              surveyQuestions={surveyQuestions}
            />
            {i + 1 !== targetingConditions.length && (
              <div className={classes.logicalOperator}>
                <span className={logicalOperator.toLowerCase()}>{logicalOperator}</span>
              </div>
            )}
          </Fragment>
        ))}
        <Button
          className={classes.addCondition}
          color="primary"
          disabled={disabled}
          onClick={() => addTargetingCondition(id)}
        >
          Add new condition
        </Button>
      </div>
    </div>
  )
}

TargetingGroup.propTypes = {
  cardOnFileTargetingValues: object,
  disabled: bool,
  enrollmentContentBlocks: array,
  id: string,
  logicalOperator: string,
  objectives: array,
  targetingConditions: array,
  templates: array,
  surveyContentBlocks: array,
  surveyQuestions: array,
}

export default TargetingGroup
