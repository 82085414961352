import {Container, FormControl, InputLabel, MenuItem, Typography} from "@mui/material"
import cx from "classnames"
import {array, bool, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import DOSelect from "components/do-select/do-select"
import {useTemplateTargeting} from "components/template-targeting/template-targeting-context"

import {fetchRewardSets} from "lib/api"

const requiresRewardStepId = op => ["has_completed_step", "has_not_completed_step"].includes(op)

const RewardsTargetingCondition = ({
  classes,
  disabled = false,
  targetingCondition,
  templateId,
  templates,
}) => {
  const {updateTargetingCondition} = useTemplateTargeting()

  const [rewardSets, setRewardSets] = useState(undefined)

  // Load this team's RewardSets & RewardSteps
  useEffect(() => {
    if (!rewardSets) {
      fetchRewardSets({withSteps: true}).then(data => setRewardSets(data))
    }
  }, [rewardSets])

  // Validate rewards-related conditions.
  // This hooks into `conditionValidator` in template-targeting.jsx, which is only smart enough to know
  // about operator and value. Rewards-related conditions don't use the value field directly, so instead
  // we hijack it to send valid/invalid information to the "Check Impact" button.
  useEffect(() => {
    const shouldBeValid = !!(
      targetingCondition.operator &&
      targetingCondition.meta.rewardSetId &&
      (!requiresRewardStepId(targetingCondition.operator) || targetingCondition.meta.rewardStepId)
    )

    if (shouldBeValid !== !!targetingCondition.value) {
      const value = shouldBeValid ? "valid" : null
      updateTargetingCondition({
        id: targetingCondition.id,
        targetingGroupId: targetingCondition.targetingGroupId,
        value: value,
      })
    }
  }, [targetingCondition, updateTargetingCondition])

  const rewardsOperators = [
    {name: "has earned reward", value: "has_earned_reward"},
    {name: "has not earned reward", value: "has_not_earned_reward"},
    {name: "has completed step", value: "has_completed_step"},
    {name: "has not completed step", value: "has_not_completed_step"},
  ]

  // Render a loading screen if the rewards list hasn't been fetched yet
  if (!rewardSets) {
    return (
      <Container sx={{marginLeft: "auto", marginRight: "auto", paddingTop: "3em"}}>
        Loading...
      </Container>
    )
  }

  return (
    <>
      {/* Operator dropdown */}
      <span className={classes.in}>for</span>
      <FormControl classes={{root: classes.fieldSelect}}>
        <InputLabel htmlFor="journey-rewards-operator">Operator</InputLabel>
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          id="journey-rewards-operator"
          name="operator"
          value={targetingCondition.operator || ""}
          onChange={event =>
            updateTargetingCondition({
              id: targetingCondition.id,
              targetingGroupId: targetingCondition.targetingGroupId,
              operator: event.target.value,
            })
          }
        >
          {rewardsOperators.map(({name, value}) => (
            <MenuItem key={value} value={value}>
              <Typography variant="inherit" noWrap>
                {name}
              </Typography>
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>

      {/* RewardSet dropdown */}
      {!!targetingCondition.operator && (
        <>
          <span className={classes.in}>for</span>
          <FormControl classes={{root: classes.fieldSelect}}>
            <InputLabel htmlFor="journey-rewards-reward-set">Reward Set</InputLabel>
            <DOSelect
              className={cx(classes.labelLessInput, classes.inputField)}
              disabled={disabled}
              id="journey-rewards-reward-set"
              name="meta.rewardSetId"
              value={targetingCondition.meta.rewardSetId || ""}
              onChange={event => {
                updateTargetingCondition({
                  id: targetingCondition.id,
                  targetingGroupId: targetingCondition.targetingGroupId,
                  meta: {
                    ...targetingCondition.meta,
                    rewardSetId: event.target.value,
                    rewardStepId: null,
                  },
                })
              }}
            >
              {rewardSets.map(set => (
                <MenuItem key={set.id} value={set.id}>
                  <Typography variant="inherit" noWrap>
                    {set.name}
                  </Typography>
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
        </>
      )}

      {/* RewardStep dropdown */}
      {requiresRewardStepId(targetingCondition.operator) && targetingCondition.meta.rewardSetId && (
        <>
          <span className={classes.in}>for</span>
          <FormControl classes={{root: classes.fieldSelect}}>
            <InputLabel htmlFor="journey-rewards-reward-step">Reward Step</InputLabel>
            <DOSelect
              className={cx(classes.labelLessInput, classes.inputField)}
              disabled={disabled}
              id="journey-rewards-reward-step"
              name="meta.rewardStepId"
              value={targetingCondition.meta.rewardStepId || ""}
              onChange={event => {
                updateTargetingCondition({
                  id: targetingCondition.id,
                  targetingGroupId: targetingCondition.targetingGroupId,
                  meta: {...targetingCondition.meta, rewardStepId: event.target.value},
                })
              }}
            >
              {rewardSets
                .find(set => set.id === targetingCondition.meta.rewardSetId)
                .rewardSteps.map(step => (
                  <MenuItem key={step.id} value={step.id}>
                    <Typography variant="inherit" noWrap>
                      {step.condition} (value: {step.value})
                    </Typography>
                  </MenuItem>
                ))}
            </DOSelect>
          </FormControl>
        </>
      )}
    </>
  )
}

RewardsTargetingCondition.propTypes = {
  classes: object,
  disabled: bool,
  targetingCondition: shape({
    id: string,
    model: string,
    field: string,
    meta: object,
    meta_sub_key: string,
    operator: string,
    value: string,
  }),
  templateId: string,
  templates: array,
}

export default RewardsTargetingCondition
