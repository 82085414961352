import {Button, ButtonGroup as MuiButtonGroup, Typography} from "@mui/material"
import Box from "@mui/material/Box"
import {arrayOf, func, string} from "prop-types"

const ValueOverTimeChartTruncateHeader = ({heading, onChange, periods, selected}) => {
  return (
    <Box
      sx={{
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "space-between",
        marginTop: 2,
        paddingRight: 1,
      }}
    >
      {!!heading && <Typography variant="h5">{heading}</Typography>}
      <MuiButtonGroup size="small" variant="outlined">
        {periods.map((period, index) => (
          <Button
            color={period.toLowerCase() === selected.toLowerCase() ? "primary" : "grey"}
            key={period}
            onClick={() => onChange(periods[index])}
            tabIndex={index}
            value={period}
          >
            {period}
          </Button>
        ))}
      </MuiButtonGroup>
    </Box>
  )
}

ValueOverTimeChartTruncateHeader.defaultProps = {heading: "", selected: "Month"}

ValueOverTimeChartTruncateHeader.propTypes = {
  heading: string,
  onChange: func.isRequired,
  periods: arrayOf(string).isRequired,
  selected: string,
}

export default ValueOverTimeChartTruncateHeader
