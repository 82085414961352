import {Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useEffect, useState} from "react"

import Box from "components/box/box"
import EngagementChannelRow from "components/cross-channel-engagement/cross-channel-engagement-settings-row"
import DocumentTitle from "components/document-title/document-title"
import Padded from "components/padded/padded"
import TitleBar from "components/title-bar/title-bar"

import {
  createEngagementChannel,
  deleteEngagementChannel,
  fetchEngagementChannels,
  updateEngagementChannel,
} from "lib/api"
import SubmissionError from "lib/submission-error"

const useStyles = makeStyles(theme => ({
  addChannelButton: {
    marginTop: theme.spacing(3),
  },
}))

const CrossChannelEngagementSettings = () => {
  const classes = useStyles()

  const [channels, setChannels] = useState([])

  const onAddRow = () => {
    setChannels(state => {
      const tempId = state.length + 1
      return [...state, {key: tempId}]
    })
  }

  const onCreate = formData => {
    return createEngagementChannel(formData)
      .then(channel => {
        setChannels(state => {
          return state.map(item => (item.key === formData.key ? channel : item))
        })
        return channel
      })
      .catch(error => {
        const errors = error.message.split(",").reduce((acc, error) => {
          const [fieldName, ...errorMessageParts] = error.split(" ")

          return {
            ...acc,
            [fieldName]: errorMessageParts.join(" "),
          }
        }, {})
        throw new SubmissionError(errors)
      })
  }
  const onUpdate = (id, formData) =>
    updateEngagementChannel(id, formData)
      .then(channel =>
        setChannels(state => state.map(item => (item.id === id ? {...item, channel} : item)))
      )
      .catch(error => {
        const errors = error.message.split(",").reduce((acc, error) => {
          const [fieldName, ...errorMessageParts] = error.split(" ")

          return {
            ...acc,
            [fieldName]: errorMessageParts.join(" "),
          }
        }, {})
        throw new SubmissionError(errors)
      })

  // NB: This assumes client and server state will match. If an error occurs, neither will be updated.
  const onPartialUpdate = (id, partialData) => {
    updateEngagementChannel(id, partialData).then(() =>
      setChannels(state => state.map(item => (item.id === id ? {...item, ...partialData} : item)))
    )
  }

  const onRemove = id => {
    return deleteEngagementChannel(id).then(() => {
      setChannels(state => {
        return state.filter(item => item.id !== id)
      })
      return id
    })
  }

  useEffect(() => {
    fetchEngagementChannels().then(response => {
      setChannels(response)
    })
  }, [])

  return (
    <div className={classes.component}>
      <DocumentTitle title="Engagement Channels" />
      <TitleBar title="Engagement Channels" />
      <Padded>
        <Typography gutterBottom={true}>
          You can add new channels to your content here by defining what the channel is and what
          size you want your content to be in that channel.
        </Typography>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Channel Name</TableCell>
                <TableCell>Width x Height (in pixels)</TableCell>
                <TableCell>Show Navigation</TableCell>
                <TableCell align="center">Branding</TableCell>
                <TableCell align="center">Default Content</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {channels.map(channel => (
                <EngagementChannelRow
                  onCreate={onCreate}
                  onPartialUpdate={onPartialUpdate}
                  onUpdate={onUpdate}
                  onRemove={onRemove}
                  key={channel.key || channel.id}
                  row={channel}
                />
              ))}
            </TableBody>
          </Table>
          {!channels.some(c => !c.id) && (
            <Button
              className={classes.addChannelButton}
              color="grey"
              variant="contained"
              onClick={onAddRow}
              data-testid="add-new-channel"
            >
              + Add New Channel
            </Button>
          )}
        </Box>
      </Padded>
    </div>
  )
}

export default CrossChannelEngagementSettings
