import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {instanceOf, object, shape, string} from "prop-types"
import {useContext, useEffect, useReducer} from "react"
import {MdExpandMore as ExpandSessionIcon} from "react-icons/md"

import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import Padded from "components/padded/padded"

import {fetchSessionLogs} from "lib/api"
import {toCamelCase} from "lib/case-converter"
import NoData from "lib/svgs/no-data"

import {SocketManagerContext, createHandler} from "../../../contexts/socket-manager"
import {
  addSessionLog,
  dateRangeForSession,
  formatTimestamp,
  reducer,
  setSessionLogList,
} from "./helpers"

const useLogRowStyles = makeStyles(theme => ({
  row: {
    fontFamily: "monospace",
    whiteSpace: "nowrap",
  },
  timestamp: {
    color: theme.palette.grey[500],
  },
  level: {
    color: theme.palette.primary.main,
  },
  meta: {
    color: theme.palette.text.hint,
  },
}))

const LogRow = ({log}) => {
  const classes = useLogRowStyles()

  return (
    <div className={classes.row}>
      <span className={classes.timestamp}>{formatTimestamp(log.timestamp)}</span>{" "}
      <span className={classes.level}>{log.level}</span> {log.message}{" "}
      <span className={classes.meta}>
        sessionId={log.sessionId}{" "}
        {Object.entries(log.meta).reduce(
          (acc, [key, value]) => (acc === "" ? `${key}=${value}` : `${acc} ${key}=${value}`),
          ""
        )}
      </span>
    </div>
  )
}

LogRow.propTypes = {
  log: shape({
    timestamp: instanceOf(Date),
    level: string,
    message: string,
    meta: object,
  }),
}

const useSessionLogStyles = makeStyles(theme => ({
  summary: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    marginRight: theme.spacing(4),
  },
  header: {
    fontSize: "1.3rem",
  },
  logList: {
    overflowX: "scroll",
  },
  empty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyHelpTextContainer: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.hint,
    "& h2": {
      fontSize: "1.4rem",
    },
  },
  helperText: {
    marginBottom: theme.spacing(2),
  },
  sessionLogFooter: {
    marginTop: theme.spacing(2),
  },
}))

const SessionLogs = () => {
  const [logs, dispatch] = useReducer(reducer, {})
  const classes = useSessionLogStyles()

  const {addHandler, removeHandler} = useContext(SocketManagerContext)

  useEffect(() => {
    const handler = createHandler("session-logs", "session_log_added", newSessionLog => {
      dispatch(addSessionLog(toCamelCase(newSessionLog)))
    })

    addHandler(handler)

    return () => {
      removeHandler(handler)
    }
  }, [addHandler, removeHandler])

  useEffect(() => {
    fetchSessionLogs().then(sessionLogs => {
      dispatch(setSessionLogList(sessionLogs))
    })
  }, [])

  return (
    <Box>
      <Padded>
        <DocumentTitle title="Team Dashboard - SFTP Session Logs" />
        {Object.keys(logs).length === 0 ? (
          <div className={classes.empty}>
            <NoData height="400" />
            <div className={classes.emptyHelpTextContainer}>
              <Typography className={classes.helperText} variant="h2">
                No session logs found
              </Typography>
              <Typography variant="caption">
                We retain session logs for 24-48 hours. You can try reconnecting to our SFTP server,
                and logs will appear here.
                <br />
                If you're still stuck,{" "}
                <a href="https://docs.digitalonboarding.com">visit our documentation</a> or{" "}
                <a href="mailto:support@digitalonboarding.com">email our support team</a>.
              </Typography>
            </div>
          </div>
        ) : (
          <>
            {Object.entries(logs).map(([sessionId, sessionLogs]) => {
              const {min, max} = dateRangeForSession(sessionLogs)

              return (
                <Accordion key={sessionId}>
                  <AccordionSummary expandIcon={<ExpandSessionIcon />}>
                    <div className={classes.summary}>
                      <div>
                        <Typography className={classes.header}>Session ID: {sessionId}</Typography>
                      </div>
                      <div>
                        Start: {formatTimestamp(min)}
                        <br />
                        End: {formatTimestamp(max)}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.logList}>
                      {sessionLogs.map(log => (
                        <LogRow key={log.id} log={log} />
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
            <div className={classes.sessionLogFooter}>
              <Typography variant="caption">
                Session logs are retained for 24-48 hours. They can not be recovered after that.
              </Typography>
            </div>
          </>
        )}
      </Padded>
    </Box>
  )
}

export default SessionLogs
