import {Checkbox, FormControl, FormControlLabel} from "@mui/material"
import {bool, func, object, string} from "prop-types"

import useAnalytics from "lib/hooks/use-analytics"

import {journeyContext} from "./journey-context"

export const PageConfirm = ({addConfirmedPage, themeClasses, isConfirmed, pageId}) => {
  const {track} = useAnalytics()

  const onChange = () => {
    if (!isConfirmed) {
      track("page_confirmed", {pageId})
      addConfirmedPage(pageId)
    }
  }

  return (
    <div className={themeClasses.pageConfirm}>
      <FormControl>
        <FormControlLabel
          control={<Checkbox checked={isConfirmed} color="primary" onChange={onChange} />}
          label="I've done this"
        />
      </FormControl>
    </div>
  )
}

PageConfirm.propTypes = {
  addConfirmedPage: func,
  isConfirmed: bool,
  pageId: string.isRequired,
  themeClasses: object.isRequired,
}

PageConfirm.defaultProps = {
  isConfirmed: false,
}

export default journeyContext(PageConfirm)
