import {OBJECTIVE_CREATED, OBJECTIVE_LIST_SET} from "./objectives-actions"

const defaultState = {
  list: [],
}

function objectives(state = defaultState, action) {
  switch (action.type) {
    case OBJECTIVE_LIST_SET:
      return {
        ...state,
        list: action.payload,
      }

    case OBJECTIVE_CREATED:
      return {
        ...state,
        list: state.list.concat([action.payload]),
      }

    default:
      return state
  }
}

export default objectives
