import {TextField} from "@mui/material"
import {bool, func, object} from "prop-types"

import {requiredField, validEmail} from "lib/field-validations"
import {formify} from "lib/hooks/use-form"

import SaveButton from "../save-button/save-button"

const AccountGeneralSettingsForm = ({classes, field, handleSubmit, submitting}) => (
  <form className="account-general-settings-form" onSubmit={handleSubmit}>
    <TextField
      autoComplete="given-name"
      fullWidth={true}
      label="First Name"
      margin="normal"
      {...field("nameFirst")}
    />
    <TextField
      autoComplete="family-name"
      fullWidth={true}
      label="Last Name"
      margin="normal"
      {...field("nameLast")}
    />
    <TextField
      autoComplete="email"
      fullWidth={true}
      label="Email"
      margin="normal"
      {...field("email")}
    />
    <TextField
      autoComplete="tel-national"
      fullWidth={true}
      label="Phone (work)"
      margin="normal"
      {...field("phoneWork")}
    />
    <TextField
      autoComplete="url"
      fullWidth={true}
      label="Meeting Schedule Link"
      margin="normal"
      {...field("link")}
    />
    <div className={classes.formActions}>
      <SaveButton submitting={submitting} />
    </div>
  </form>
)

AccountGeneralSettingsForm.propTypes = {
  classes: object.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  initialValues: object.isRequired,
  submitting: bool,
}

export default formify({
  enableReinitialize: true,
  validators: {
    email: [requiredField, validEmail],
  },
})(AccountGeneralSettingsForm)
