import {makeStyles} from "@mui/styles"
import orderBy from "lodash/orderBy"
import {array, bool, func, number, string} from "prop-types"
import {useCallback, useEffect, useMemo} from "react"

import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import ProductOpeningMetricRow from "./product-opening-metric-row"

const ProductOpeningMetrics = ({
  drivers,
  isLoading,
  itemsPerPage,
  onChange,
  page,
  products,
  setTableState,
  sortColumn,
  sortDirection,
}) => {
  const totalCount = useMemo(() => products?.length ?? 0, [products])
  const setTableStateMemoized = useCallback(setTableState, [setTableState])

  const handleChange = product => {
    const index = products.findIndex(p => p.id === product.id)
    onChange(products.toSpliced(index, 1, product))
  }

  const refreshTableRows = useCallback(
    attrs => {
      const currentItemsPerPage = attrs?.itemsPerPage ?? itemsPerPage
      const currentPage = attrs?.page ?? page
      const currentSortColumn = attrs?.sortColumn ?? sortColumn
      const currentSortDirection = attrs?.sortDirection ?? sortDirection

      const currentOffset = currentPage * currentItemsPerPage

      const sortFn = value => (value && isNaN(value) ? value.toLowerCase() : value)

      let rows = products ?? []
      rows = rows.filter((item, i) => i >= currentOffset && i < currentOffset + currentItemsPerPage)
      rows = orderBy(rows, [item => sortFn(item[currentSortColumn])], [currentSortDirection])

      setTableStateMemoized({
        itemsPerPage: currentItemsPerPage,
        page: currentPage,
        rows,
        sortColumn: currentSortColumn,
        sortDirection: currentSortDirection,
        totalCount,
      })
    },
    [itemsPerPage, page, products, setTableStateMemoized, sortColumn, sortDirection, totalCount]
  )

  // Ignoring `refreshTableRows` dependency as the `setTableState` function from `tabular` gets redefined
  // in a manner that causes this effect to run on every render, regardless of any of its dependencies.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => refreshTableRows({}), [products])

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DOTable
        headers={[
          {
            field: "name",
            label: "Product",
            sortable: true,
            tooltipText: "List of all tracked products on your team.",
          },
          {
            field: "valueDriver",
            label: "Value Driver",
            sortable: false,
            tooltipText:
              "List of most common value drivers in the financial industry. If you have an additional value driver you would like to see, please submit an enhancement request via the support portal.",
          },
          {
            field: "valueInput",
            label: "Value Input",
            sortable: false,
            tooltipText:
              "The overall value your team has for each value driver adoption. Only whole dollar values are accepted at this time (no cents).",
          },
          {
            field: "defaultValue",
            label: "Default Value",
            sortable: false,
            tooltipText:
              "The default value for each business objective based on market research. This value is used if there is a business and campaign objective mapping with a custom value input.",
          },
        ]}
        isTableLoading={isLoading}
        noResults="There are no product opening metrics."
        paginationEnabled={true}
        refresh={refreshTableRows}
      >
        {row => (
          <ProductOpeningMetricRow
            drivers={drivers}
            key={`product-${row.id}`}
            onChange={handleChange}
            product={row}
          />
        )}
      </DOTable>
    </div>
  )
}

ProductOpeningMetrics.propTypes = {
  drivers: array.isRequired,
  isLoading: bool,
  itemsPerPage: number,
  onChange: func.isRequired,
  page: number,
  products: array,
  setTableState: func.isRequired,
  sortColumn: string,
  sortDirection: string,
}

const useStyles = makeStyles(() => ({
  container: {
    "& tr th:nth-child(5)": {
      width: 120,
    },
  },
}))

export default tabular({
  itemsPerPage: 20,
  page: 0,
  sortColumn: "name",
  sortDirection: "asc",
})(ProductOpeningMetrics)
