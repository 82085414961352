// This file was generated automatically from character-04.svg with the following command:
// ./svg-converter.js src/components/superhero/character-04.svg
import SuperheroSvg from "./superhero-svg"

const Character04 = props => (
  <SuperheroSvg viewBox="220 65 160 220" {...props}>
    <g id="Layer_5">
      <g>
        <g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M280.84,278.52c-5.03,0-12.02-0.49-19.65-2.48l-0.1-0.04c-0.09-0.05-2.22-1.21-2.1-2.88
  					c0.09-1.25,1.35-2.31,3.86-3.23l0.07-0.02c0.11-0.02,11.03-2.03,12.51-5.98l0.81,0.3c-1.62,4.33-12.29,6.37-13.13,6.52
  					c-2.62,0.96-3.22,1.87-3.26,2.46c-0.07,1,1.34,1.88,1.6,2.03c13.91,3.62,25.66,2.19,26.98,2.01c1.43-1.4-0.45-7.53-2-11.13
  					l0.8-0.34c0.01,0.02,1.08,2.5,1.83,5.19c1.06,3.8,1.01,6.1-0.16,7.03l-0.09,0.07l-0.11,0.02
  					C288.64,278.07,285.64,278.52,280.84,278.52z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M265.63,276.89l-0.54-0.68c0.99-0.78,1.51-1.67,1.56-2.64c0.09-1.82-1.52-3.4-1.53-3.41l0.6-0.62
  					c0.08,0.07,1.9,1.86,1.8,4.07C267.45,274.84,266.82,275.95,265.63,276.89z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M281.77,275.41c-3.41,0-8.18-0.24-14.78-0.97l0.1-0.86c18.05,2,22.23,0.25,22.27,0.23l0.37,0.79
  					C289.61,274.65,287.88,275.41,281.77,275.41z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M266.85,273.22l-0.15-0.85c13.08-2.33,13.56-7.19,13.56-7.24l0.87,0.04
  					C281.11,265.4,280.73,270.75,266.85,273.22z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M269.66,271.42c-0.72,0-1.62-0.54-1.92-0.74l0.47-0.73c0.58,0.38,1.3,0.66,1.5,0.6
  					c0-0.01,0-0.02,0-0.04c0.17-1.52,0.62-1.47,2.78-1.22c0.84,0.1,1.45,0.17,1.62,0.04c0.16-0.13,0.23-0.74,0.33-1.58
  					c0.08-0.71,0.35-1.13,0.79-1.26c0.86-0.25,1.87,0.92,2.16,1.28l-0.68,0.54c-0.48-0.6-1.08-1.04-1.24-0.99
  					c0,0-0.11,0.08-0.16,0.52c-0.28,2.41-0.49,2.59-2.91,2.31c-0.85-0.1-1.59-0.18-1.73-0.1c-0.03,0.05-0.06,0.32-0.09,0.56
  					c-0.04,0.34-0.21,0.59-0.48,0.72C269.97,271.39,269.82,271.42,269.66,271.42z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M273.64,223.49l8.07-56.19l21.43-2.2l-4.94,16.49l-4.45,47.42c0,0,1.14,15.05,1.84,38.13
  				l-25.65-1.48C269.94,265.66,271.71,240.96,273.64,223.49z"
          />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M317.27,278.45l-0.05,0c-0.1-0.01-2.52-0.24-3.07-1.81c-0.41-1.19,0.33-2.66,2.27-4.49l0.06-0.05
  					c0.02-0.02,2.39-1.59,4.7-3.69c2.98-2.71,4.51-5.05,4.43-6.76l0.87-0.04c0.22,4.62-8.77,10.71-9.48,11.19
  					c-2.02,1.92-2.22,2.99-2.02,3.55c0.29,0.85,1.69,1.17,2.27,1.23c14.22-2.18,24.44-8.15,25.58-8.84c0.9-2.06-2.98-6.67-4.58-8.3
  					l0.62-0.61c0.64,0.66,6.24,6.49,4.67,9.42l-0.05,0.1l-0.1,0.06c-0.11,0.07-10.84,6.7-26.06,9.03L317.27,278.45z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M321.73,277.47l-0.76-0.41c0.59-1.11,0.72-2.13,0.38-3.04c-0.64-1.71-2.74-2.52-2.76-2.53
  					l0.31-0.81c0.1,0.04,2.48,0.95,3.26,3.03C322.59,274.86,322.44,276.13,321.73,277.47z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M322,274.68l-0.25-0.83c17.37-5.31,20.51-8.57,20.54-8.6l0.65,0.58
  					C342.82,265.96,339.76,269.25,322,274.68z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M321.4,273.61l-0.48-0.72c11.09-7.32,9.6-11.97,9.59-12.01l0.81-0.3
  					C331.4,260.78,333.16,265.85,321.4,273.61z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M322.22,271.02c-0.43,0-0.82-0.06-1.02-0.09l0.15-0.85c0.69,0.12,1.46,0.09,1.62-0.04
  					c0-0.01-0.01-0.02-0.01-0.04c-0.17-0.56-0.32-1.04,0.1-1.44c0.3-0.28,0.82-0.44,1.97-0.79c0.81-0.25,1.4-0.42,1.5-0.61
  					c0.1-0.18-0.08-0.77-0.33-1.58c-0.21-0.68-0.13-1.17,0.22-1.47c0.69-0.57,2.08,0.1,2.49,0.32l-0.41,0.76
  					c-0.67-0.36-1.41-0.53-1.53-0.42c0,0-0.07,0.12,0.06,0.54c0.35,1.16,0.53,1.74,0.26,2.24c-0.27,0.5-0.85,0.68-2.01,1.03
  					c-0.82,0.25-1.53,0.46-1.63,0.59c-0.01,0.06,0.07,0.32,0.14,0.55c0.1,0.32,0.04,0.63-0.16,0.85
  					C323.33,270.93,322.75,271.02,322.22,271.02z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#113353"}}
            d="M318.99,223.42l-3.88-52.04l-1.06-12.85l-4.69-0.98l-24.94-5.64l-2.47,14.25
  				c-0.18,0.71-0.31,1.44-0.4,2.18l-0.22,1.18l0.13,0.02c-0.01,0.15-0.02,0.3-0.02,0.46c-0.13,8.19,5.59,15.1,13.31,16.75l0.11,0.32
  				l4.46,33.91c0,0,1.11,7.99,3.91,12.88l16.53,30.83l24.17-3.45l-21.51-30.81C322.42,230.41,319.62,226.91,318.99,223.42z"
          />
        </g>
        <g>
          <g>
            <g>
              <path
                style={{fill: "#0F2541"}}
                d="M335.59,94.75c0.07,1.79-2.15,3.41-2.42,5.11c-0.28,1.75,1.31,3.98,0.71,5.61
  						c-0.61,1.65-3.28,2.3-4.2,3.78c-0.92,1.49-0.31,4.17-1.5,5.45c-1.19,1.28-3.9,0.87-5.32,1.9c-1.41,1.02-1.86,3.73-3.46,4.47
  						c-1.57,0.72-3.92-0.7-5.64-0.29c-1.67,0.4-3.13,2.73-4.91,2.79c-1.79,0.07-3.41-2.15-5.11-2.42c-1.75-0.28-3.98,1.31-5.61,0.71
  						c-1.65-0.61-2.3-3.28-3.78-4.2c-1.49-0.92-4.17-0.31-5.45-1.5c-1.28-1.19-0.87-3.9-1.9-5.32c-1.02-1.41-3.73-1.86-4.47-3.46
  						c-0.72-1.57,0.7-3.92,0.29-5.64c-0.4-1.67-2.73-3.13-2.79-4.91c-0.07-1.79,2.15-3.41,2.42-5.11c0.28-1.75-1.31-3.98-0.71-5.61
  						c0.61-1.65,3.28-2.3,4.2-3.78c0.92-1.49,0.31-4.17,1.5-5.45c1.19-1.28,3.9-0.87,5.32-1.9c1.41-1.02,1.86-3.73,3.46-4.47
  						c1.57-0.72,3.92,0.7,5.64,0.29c1.67-0.4,3.13-2.73,4.91-2.79c1.79-0.07,3.41,2.15,5.11,2.42c1.75,0.28,3.98-1.31,5.61-0.71
  						c1.65,0.61,2.3,3.28,3.78,4.2c1.49,0.92,4.17,0.31,5.45,1.5c1.28,1.19,0.87,3.9,1.9,5.32c1.02,1.41,3.73,1.86,4.47,3.46
  						c0.72,1.57-0.7,3.92-0.29,5.64C333.19,91.51,335.52,92.96,335.59,94.75z"
              />
            </g>
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M306.51,82.87c0.56,0,1.11,0.03,1.66,0.08c0.33,13.02,12.14,19.84,14.37,21.01
  						c-1.95,7.02-8.38,12.18-16.03,12.18c-9.19,0-16.64-7.45-16.64-16.63C289.87,90.32,297.32,82.87,306.51,82.87z"
              />
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#8B725E"}}
                  d="M289.34,120.82c-1.47,2.34-2.94,4.68-4.42,7.02c-2.35,3.73-4.69,7.46-7.04,11.19
  							c-0.54,0.86-1.08,1.71-1.61,2.57c1.8,0,3.6,0,5.4,0c-0.95-2.23-1.9-4.46-2.84-6.68c-1.51-3.56-3.03-7.11-4.54-10.67
  							c-0.35-0.82-0.7-1.64-1.05-2.47c-0.28-0.67-1.2-1.25-1.87-1.44c-0.75-0.21-1.74-0.11-2.41,0.32
  							c-0.67,0.43-1.26,1.07-1.44,1.87c-0.04,0.28-0.07,0.55-0.11,0.83c0,0.56,0.14,1.09,0.43,1.58c0.95,2.23,1.9,4.46,2.84,6.68
  							c1.51,3.56,3.03,7.11,4.54,10.67c0.35,0.82,0.7,1.64,1.05,2.47c0.42,0.98,1.71,1.55,2.7,1.55c1.13,0,2.11-0.61,2.7-1.55
  							c1.47-2.34,2.94-4.68,4.42-7.02c2.35-3.73,4.69-7.46,7.04-11.19c0.54-0.86,1.08-1.71,1.61-2.57c0.87-1.39,0.34-3.51-1.12-4.28
  							C292.08,118.88,290.27,119.34,289.34,120.82L289.34,120.82z"
                />
              </g>
            </g>
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M289.87,99.46c0,7.39,5.19,13.66,11.86,15.83l0,2.35l6.13,0l0-1.59
  						c4.61-0.37,8.7-2.62,11.48-5.98c0.59,0.42,1.32,0.68,2.11,0.68c2.01,0,3.64-1.63,3.64-3.64c0-1.6-1.03-2.95-2.46-3.44
  						c0-0.02,0.01-0.04,0.01-0.05c-0.12-0.06-0.23-0.13-0.34-0.19l-32.23-6.56C289.95,97.72,289.87,98.58,289.87,99.46z"
              />
            </g>
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M314.54,95.21l-22.11-4.5c-1.19,1.87-2.01,3.99-2.36,6.27l31.9,6.49
  						C318.62,101.68,315.98,98.76,314.54,95.21z"
              />
            </g>
            <g>
              <rect x="301.6" y="117.54" style={{fill: "#5EBDAE"}} width="6.44" height="2.65" />
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#5EBDAE"}}
                  d="M318.8,129.83c0.71-5.2-2.93-9.99-8.13-10.7l-12.18-0.99c-6.6-0.4-9.76,2.13-10.7,8.13
  							l-3.14,18.93l31.61,0L318.8,129.83z"
                />
              </g>
              <g>
                <polygon
                  style={{fill: "#5EBDAE"}}
                  points="316.71,142.12 284.65,145.2 283.83,150.13 315.41,154 						"
                />
              </g>
            </g>
            <g>
              <circle style={{fill: "#0D1E36"}} cx="298.76" cy="95.75" r="1.33" />
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#0D1E36"}}
                  d="M300.18,92.19c-0.25-0.25-0.59-0.42-0.92-0.52c-0.78-0.24-1.6-0.06-2.31,0.32
  							c-0.32,0.17-0.04,0.66,0.29,0.49c0.6-0.32,1.27-0.46,1.9-0.25c0.05,0.02,0.1,0.04,0.16,0.06c-0.05-0.02,0.03,0.01,0.04,0.02
  							c0.03,0.01,0.05,0.02,0.08,0.04c0.08,0.04,0.16,0.09,0.23,0.14c0.03,0.02,0.05,0.04,0.08,0.06c-0.08-0.06,0,0,0.02,0.02
  							c0.02,0.01,0.03,0.03,0.05,0.04C300.04,92.85,300.44,92.45,300.18,92.19L300.18,92.19z"
                />
              </g>
            </g>
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M308.17,82.95c8.41,0.83,14.98,7.92,14.98,16.55c0,1.54-0.21,3.04-0.61,4.46
  						C320.3,102.79,308.49,95.97,308.17,82.95z"
              />
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#8B725E"}}
                  d="M312.28,125.46c0.59,1,1.19,2,1.78,3c1.43,2.4,2.86,4.81,4.28,7.21
  							c1.73,2.91,3.46,5.82,5.18,8.73c1.5,2.53,3,5.06,4.5,7.59c0.72,1.22,1.42,2.47,2.18,3.67c0.01,0.02,0.02,0.03,0.03,0.05
  							c0.9-1.57,1.8-3.14,2.7-4.7c-2.62,0.24-5.24,0.48-7.87,0.72c-4.14,0.38-8.29,0.75-12.43,1.13c-0.96,0.09-1.93,0.18-2.89,0.26
  							c-0.85,0.08-1.59,0.3-2.21,0.92c-0.54,0.54-0.95,1.43-0.92,2.21c0.07,1.57,1.38,3.28,3.13,3.13c2.62-0.24,5.24-0.48,7.87-0.72
  							c4.14-0.38,8.29-0.75,12.43-1.13c0.96-0.09,1.93-0.18,2.89-0.26c1.13-0.1,2.1-0.53,2.7-1.55c0.56-0.96,0.57-2.2,0-3.16
  							c-0.59-1-1.19-2-1.78-3c-1.43-2.4-2.86-4.81-4.28-7.21c-1.73-2.91-3.46-5.82-5.18-8.73c-1.5-2.53-3-5.06-4.5-7.59
  							c-0.73-1.22-1.43-2.46-2.18-3.67c-0.01-0.02-0.02-0.03-0.03-0.05c-0.83-1.41-2.85-2.04-4.28-1.12
  							C311.99,122.09,311.39,123.95,312.28,125.46L312.28,125.46z"
                />
              </g>
            </g>
            <g>
              <path
                style={{fill: "#A99286"}}
                d="M308.19,83.06c0,0-4.6,5.15-13.89,5.15C294.3,88.21,299.59,81.94,308.19,83.06z"
              />
            </g>
            <g>
              <circle style={{fill: "#0D1E36"}} cx="310.13" cy="97.65" r="1.33" />
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#0D1E36"}}
                  d="M310.2,94.15c0.09-0.03,0.18-0.04,0.27-0.06c0.02,0,0.08-0.01,0,0c0.03,0,0.05-0.01,0.08-0.01
  							c0.05-0.01,0.1-0.01,0.15-0.01c0.13-0.01,0.25,0,0.38,0.01c0.12,0.01,0.23,0.03,0.39,0.07c0.15,0.04,0.26,0.08,0.39,0.15
  							c0.34,0.17,0.62,0.42,0.85,0.72c0.09,0.12,0.25,0.19,0.39,0.1c0.12-0.07,0.19-0.26,0.1-0.39c-0.5-0.68-1.19-1.11-2.03-1.21
  							c-0.36-0.04-0.76-0.03-1.11,0.08C309.7,93.71,309.85,94.26,310.2,94.15L310.2,94.15z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{fill: "#0D1E36"}}
                  d="M304.76,100.85c-0.38-0.75-1.19-1.34-2.06-1.22c-0.46,0.07-0.87,0.31-1.24,0.6
  							c-0.14,0.11,0.06,0.31,0.2,0.2c0.51-0.4,1.16-0.69,1.8-0.44c0.37,0.14,0.68,0.44,0.9,0.77c0.05,0.08,0.1,0.15,0.14,0.24
  							C304.59,101.16,304.84,101.02,304.76,100.85L304.76,100.85z"
                />
              </g>
            </g>
            <g>
              <path
                style={{fill: "#0F2541"}}
                d="M322.53,92.79c-0.04,0.38-0.21,0.63-0.65,0.93c-0.52,0.36-1.1,0.64-1.69,0.85
  						c-1.16,0.41-2.42,0.53-3.64,0.32c-1.36-0.23-2.65-0.88-3.67-1.81c-0.5-0.46-0.99-1-1.31-1.6c-0.26-0.46-0.38-1.32-1.02-1.4
  						c-0.27-0.03-0.53,0.12-0.76,0.27c-1.65,1.05-3.01,2.36-5.11,2.25c-1.21-0.06-2.32-0.61-3.26-1.34c-0.8-0.63-0.71-1.48-1.36-2.1
  						c-0.37-0.35-0.97-0.25-1.42-0.02c-0.86,0.43-1.36,1.27-2.16,1.8c-1.04,0.68-2.55,0.99-3.74,0.51
  						c-0.47-0.19-0.88-0.59-0.96-1.09c-0.18-1.05,0.87-2.21,1.44-3.01c2.12-3,4.92-5.61,8.27-7.18c3.01-1.42,6.44-1.91,9.69-1.08
  						c5.63,1.43,10.26,7.61,11.33,13.06C322.55,92.41,322.54,92.62,322.53,92.79z"
              />
            </g>
            <g>
              <path
                style={{fill: "#5397D1"}}
                d="M319.92,91.38C319.92,91.38,319.91,91.37,319.92,91.38c-3.29-3.49-8.05-6.15-12.28-6.33
  						c-7.36-0.32-10.14,1.32-13.45,2.8l-0.14,0.07c0.1-0.14,0.19-0.29,0.29-0.43c4.25-6.12,10.85-9.29,16.75-7.37
  						c4.65,1.51,7.79,5.83,8.84,11.24C319.92,91.36,319.92,91.37,319.92,91.38z"
              />
            </g>
            <g>
              <g>
                <g>
                  <path
                    style={{fill: "#0C1B2F"}}
                    d="M307.32,105.9c-1.86,0.52-4.1-0.13-5.04-0.46c-0.13,0.79-0.25,1.9-0.05,2.62l0,0
  								c0.15,0.58,0.5,1.1,1.03,1.44c0.35-0.58,0.83-1.06,1.43-1.4l2.21,0.03C306.9,108.13,307.13,107.6,307.32,105.9z"
                  />
                </g>
                <g>
                  <path
                    style={{fill: "#FFFFFF"}}
                    d="M307.42,104.93c0,0-1.91,0.46-4.96-0.42c0,0-0.09,0.39-0.18,0.93
  								c0.95,0.33,3.18,0.99,5.04,0.46C307.36,105.61,307.39,105.29,307.42,104.93z"
                  />
                </g>
              </g>
              <g>
                <path
                  style={{fill: "#EE5C60"}}
                  d="M306.8,108.5c0.14-0.29,0.21-0.58,0.23-0.88c-0.57-0.06-1.16-0.01-1.72,0.18
  							c-0.88,0.32-1.59,0.92-2.05,1.69c0.09,0.06,0.18,0.11,0.28,0.16C304.76,110.24,306.22,109.72,306.8,108.5z"
                />
              </g>
            </g>
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M269.41,113.02c0,0-0.15,0.65-0.45,0.58c-0.3-0.07-0.91-0.8-0.64-1.9
  						c0.18-0.73,0.34-3.82-1.99-5.05c0,0-3-0.8-4.22,2.07c-0.92,2.16-1.19,4.06,0.23,7.11c1.32,2.85,2.93,3.9,3.57,4.71
  						c1.35,3.11,2.7,6.22,4.05,9.33c0.2,0.45,0.39,0.9,0.59,1.36l5.54-2.9c-1.38-3.18-2.76-6.35-4.13-9.53
  						c-0.14-0.33-0.28-0.65-0.43-0.98c0.47-0.92,1.46-3.11,1.28-4.93c0,0,0.04-0.88-0.61-1.79
  						C271.55,110.19,269.73,111.36,269.41,113.02z"
              />
            </g>
            <g>
              <polygon
                style={{fill: "#D8D7D5"}}
                points="310.85,138.16 312.32,158.43 280.7,157.89 278.94,137.61 					"
              />
            </g>
            <g>
              <path
                style={{fill: "#8B725E"}}
                d="M301.3,154.37c0,0,0.56,0.33,0.41,0.58c-0.15,0.26-1,0.62-1.95,0.05
  						c-0.62-0.37-3.45-1.41-5.26,0.41c0,0-1.6,2.56,0.72,4.51c1.74,1.47,3.44,2.26,6.67,1.82c3.02-0.42,4.46-1.61,5.39-1.97
  						c3.27-0.37,6.55-0.73,9.82-1.1c0.48-0.05,0.95-0.11,1.43-0.16l-1.12-5.97c-3.34,0.37-6.69,0.75-10.03,1.12
  						c-0.34,0.04-0.69,0.08-1.03,0.12c-0.72-0.69-2.47-2.24-4.22-2.6c0,0-0.8-0.29-1.84,0.06
  						C299.28,151.57,299.85,153.6,301.3,154.37z"
              />
            </g>
            <g>
              <circle style={{fill: "#FFFFFF"}} cx="295.19" cy="147.62" r="2.11" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default Character04
