import {Button, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, func, object} from "prop-types"
import {Component} from "react"
import {FaKey as KeyIcon} from "react-icons/fa"
import {connect} from "react-redux"

import AccessControlled from "../access-control/access-controlled"
import {controlAccess} from "../access-control/access-controlled"
import Box from "../box/box"
import CopyTextInput from "../copy-text-input/copy-text-input"
import DocumentTitle from "../document-title/document-title"
import DOTable from "../table/table"
import ClientCredentialRow from "./client-credential-row"
import * as teamActions from "./team-actions"

export class ApiAccessCredentials extends Component {
  componentDidMount = () => this.props.getClients()

  componentWillUnmount = () => this.props.clearNewClient()

  render() {
    const {classes, client, clients, onCreateClient, onRevokeClient} = this.props
    const allowMoreCredentials = clients.filter(c => !c.revokedAt).length < 2

    return (
      <>
        <DocumentTitle title="Team Settings - API Access" />
        <Typography variant="h5">Credentials</Typography>
        <Box>
          <Typography className={classes.contentSpacing}>
            You are allowed to have up to two active sets of client credentials at a time. It is
            highly recommended that you rotate your credentials on a regular basis. To rotate your
            credentials, first create a second set of credentials. Update your application to use
            the new set of credentials. Then revoke the old credentials. This allows you to update
            your credentials without incurring any downtime. If you believe your credentials have
            been compromised revoke them immediately.
          </Typography>
          <div className={classes.controls}>
            {!client && allowMoreCredentials && (
              <AccessControlled requiredPermissions="team_settings:api">
                <Button
                  className={classes.generateButton}
                  color="primary"
                  onClick={onCreateClient}
                  variant="contained"
                >
                  <KeyIcon /> Generate Credentials
                </Button>
              </AccessControlled>
            )}
            {!allowMoreCredentials && (
              <Typography className={classes.contentSpacing} component="em">
                You need to revoke a set of credentials before you can generate any more.
              </Typography>
            )}
            {client && (
              <div className={classes.contentSpacing}>
                <Typography variant="subtitle1">
                  <KeyIcon /> Credentials
                </Typography>
                <CopyTextInput label="Client ID" value={client.clientId} />
                <CopyTextInput
                  helperText={`You will not be able to retrieve this client secret again. Store
                it somewhere safe. If you believe it has been compromised return
                to this page and revoke it immediately.`}
                  label="Client Secret"
                  value={client.clientSecret}
                />
              </div>
            )}
            <div className={classes.flexSpacer} />
            <Button
              className={classes.docsButton}
              color="primary"
              href={`${process.env.REACT_APP_DOCS_URL}/docs/api`}
              target="_blank"
            >
              Visit Documentation
            </Button>
          </div>
          {clients.length > 0 && (
            <DOTable
              headers={[
                {field: "clientId", sortable: false},
                {field: "Created", sortable: false},
                {field: ""},
              ]}
              paginationEnabled={false}
              rows={clients}
            >
              {row => (
                <ClientCredentialRow
                  classes={classes}
                  key={row.id}
                  onRevokeClient={onRevokeClient}
                  row={row}
                />
              )}
            </DOTable>
          )}
        </Box>
      </>
    )
  }
}

ApiAccessCredentials.propTypes = {
  classes: object.isRequired,
  clearNewClient: func.isRequired,
  client: object,
  clients: array.isRequired,
  getClients: func.isRequired,
  onCreateClient: func.isRequired,
  onRevokeClient: func.isRequired,
}

const styles = theme => ({
  generateButton: {
    marginBottom: "1em",
    "& svg": {
      marginRight: 5,
    },
  },
  controls: {
    display: "flex",
  },
  flexSpacer: {
    flex: "1",
  },
  docsButton: {
    marginBottom: "1em",
    alignSelf: "flex-end",
    minWidth: 180,
  },
  contentSpacing: {
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
  },
})

const mapStateToProps = ({clients, session}) => ({
  client: clients.new,
  clients: clients.list,
})

const addActionsToProps = {
  clearNewClient: teamActions.clearNewClient,
  getClients: teamActions.getClients,
  onCreateClient: teamActions.onCreateClient,
  onRevokeClient: teamActions.onRevokeClient,
}

export default connect(
  mapStateToProps,
  addActionsToProps
)(
  withStyles(styles)(
    controlAccess({requiredPermissions: "team_settings:api"})(ApiAccessCredentials)
  )
)
