import * as Sentry from "@sentry/browser"

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_RELEASE_LEVEL || "dev",
    release: process.env.REACT_APP_RELEASE,
  })

  Sentry.configureScope(scope => scope.setTag("domain", window.location.host))
}
