import {Typography} from "@mui/material"
import {bool, func, string} from "prop-types"
import React, {useContext, useMemo} from "react"

import {SaveButton} from "../save-button/save-button"
import {RewardSetFormContext} from "./reward-set-form-context-provider"

const RewardSetFooter = ({isSubmitting, onSave, status}) => {
  const {dispatch, isListDirty, rewardSetForm, rewardStepFormList} = useContext(
    RewardSetFormContext
  )

  const disabled = useMemo(() => {
    if (!rewardSetForm) {
      return true
    }

    const hasDirtyField =
      rewardSetForm.isDirty ||
      rewardStepFormList.reduce((acc, rewardStepForm) => acc || rewardStepForm.isDirty, false) ||
      isListDirty
    const hasInvalidForm =
      !rewardSetForm.isValid || !!rewardStepFormList.find(rewardStepForm => !rewardStepForm.isValid)

    return !hasDirtyField || hasInvalidForm
  }, [isListDirty, rewardSetForm, rewardStepFormList])

  const handleSave = () => {
    onSave({
      ...rewardSetForm.data,
      rewardSteps: rewardStepFormList.map(({data}) => data),
    }).then(() => dispatch({type: "SET_PRISTINE"}))
  }

  return status === "draft" ? (
    <SaveButton
      id="save-reward-updates-button"
      classes={{}}
      disabled={disabled}
      fullWidth
      onClick={handleSave}
      submitting={isSubmitting}
      sx={{mt: 4}}
    />
  ) : (
    <Typography
      sx={{
        paddingTop: "1.5em",
        textAlign: "center",
        color: "rgb(137, 52, 67)",
        fontWeight: "bold",
      }}
    >
      THESE REWARDS ARE {status.toUpperCase()} AND CANNOT BE EDITED.
    </Typography>
  )
}

RewardSetFooter.propTypes = {isSubmitting: bool, onSave: func, status: string}

export default RewardSetFooter
