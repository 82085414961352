export const LOADING_STATE_STARTED = "LOADING_STATE_STARTED"
export const LOADING_STATE_FAILED = "LOADING_STATE_FAILED"
export const LOADING_STATE_COMPLETED = "LOADING_STATE_COMPLETED"
export const LOADING_STATE_RESET = "LOADING_STATE_RESET"

const loadingStateCreator = type => (key, message) => ({
  type,
  payload: {
    key,
    message,
  },
})

export const loadingStateStarted = loadingStateCreator(LOADING_STATE_STARTED)
export const loadingStateFailed = loadingStateCreator(LOADING_STATE_FAILED)
export const loadingStateCompleted = loadingStateCreator(LOADING_STATE_COMPLETED)
export const resetLoadingState = loadingStateCreator(LOADING_STATE_RESET)
