import cx from "classnames"
import {number, object, string} from "prop-types"
import {SortableContainer as ReactSortableHocContainer} from "react-sortable-hoc"

import ContentBlock, {SortableContentBlock} from "components/content-block-editor/content-block"
import {useContentBlockEditor} from "components/content-block-editor/content-block-editor-context"
import PluginMenu from "components/content-block-editor/plugin-menu"

const Container = ({classes, containerId, sortIndex}) => {
  const {isEditMode, selectedBlock, containers} = useContentBlockEditor()
  const container = containers[containerId]

  const {layoutBasis, contentBlocks} = container

  const ContentBlockReadonly = isEditMode ? SortableContentBlock : ContentBlock

  // We need to use `classes.container` passed in from the parent here b/c the parent
  // uses the parent's container class to generate the layout basis css using
  // `generateLayoutBasisCss(classes.container, layoutBasis)`, which will have its own
  // randomly assigned number to the class, i.e., `.makeStyles-container-951`
  return (
    <div
      className={cx(classes.container, layoutBasis, `${container._type}-container`)}
      data-testid={`content-block-editor-container-${containerId}`}
      style={container.styles?.gap ? {gap: `${container.styles.gap}px`} : {gap: "8px"}}
    >
      {contentBlocks.map((contentBlock, index) => (
        <ContentBlockReadonly
          contentBlock={contentBlock}
          index={index}
          isDragging={index === sortIndex}
          key={contentBlock.id}
          layoutBasis={layoutBasis}
        />
      ))}
      {isEditMode && !selectedBlock && <PluginMenu classes={classes} container={container} />}
    </div>
  )
}

Container.propTypes = {
  classes: object.isRequired,
  containerId: string.isRequired,
  sortIndex: number,
}

export const SortableContainer = ReactSortableHocContainer(Container)

export default Container
