import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {node} from "prop-types"

import {useContentBlockEditor} from "../content-block-editor/content-block-editor-context"
import deviceBackground from "./device-background.png"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  deviceFrame: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 88,
    bottom: 0,
    justifyContent: "center",
    background: `url(${deviceBackground}) center center repeat rgb(0, 33, 51)`,
    overflow: "auto",
  },
}))

const PreviewWrapper = ({children}) => {
  const {device} = useContentBlockEditor()
  const classes = useStyles()

  return (
    <div
      className={cx("preview-wrapper", classes.root, {
        [classes.deviceFrame]: device.type !== "desktop",
      })}
    >
      {children}
    </div>
  )
}

PreviewWrapper.propTypes = {
  children: node.isRequired,
}

export default PreviewWrapper
