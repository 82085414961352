import {Button, Grid, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {string} from "prop-types"

import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import PgpKeys from "./pgp-keys"
import SshKeys from "./ssh-keys"

const DocHeader = ({link, title}) => (
  <Grid alignItems="center" container={true} spacing={4}>
    <Grid item={true}>
      <Typography variant="h5">{title}</Typography>
    </Grid>
    <Grid item={true}>
      <Button color="primary" href={link} target="_blank">
        Visit Documentation
      </Button>
    </Grid>
  </Grid>
)

DocHeader.propTypes = {
  link: string.isRequired,
  title: string.isRequired,
}

export const Sftp = () => {
  const classes = useStyles()

  return (
    <>
      <DocumentTitle title="Team Settings - SFTP" />
      <DocHeader link={`${process.env.REACT_APP_DOCS_URL}/docs/sftp`} title="SSH Keys" />
      <Box>
        <SshKeys classes={classes} />
      </Box>
      <div className={classes.spacer} />
      <DocHeader
        link={`${process.env.REACT_APP_DOCS_URL}/docs/sftp/#sending-digital-onboarding-pgp-encrypted-data`}
        title="PGP Keys"
      />
      <Box>
        <PgpKeys classes={classes} />
      </Box>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  description: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(4)}`,
  },
  spacer: {
    height: theme.spacing(4),
  },
  code: {
    fontFamily: `"Monaco","Consolas","Lucida Console",monospace`,
    color: "#F92672",
    background: "#FEECF2",
  },
  noKeysFound: {
    fontStyle: "italic",
  },
}))

export default Sftp
