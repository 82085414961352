import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {convertToRaw} from "draft-js"
import debounce from "lodash/debounce"
import {func, object, shape, string} from "prop-types"
import {Component} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"

import AccessControlled from "components/access-control/access-controlled"
import Feature from "components/feature/feature"
import ObjectiveSelect from "components/objective-select/objective-select"

import draftJsBlockRenderers from "lib/draft-js/block-renderers"
import customStyleFn from "lib/draft-js/custom-style-fn"
import Editor from "lib/draft-js/simple-editor"
import {formify} from "lib/hooks/use-form"
import MaybeTooltip from "lib/maybe-tooltip"

import {ContentType} from "../../contexts/editor-context"
import {isUserPermitted} from "../access-control/access-controlled"
import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import {humanizeType} from "../content-library/helpers"

const CAN_SPAM_LINK =
  "https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business"

class GeneralMessageSettings extends Component {
  state = {
    showSubjectToolbar: false,
  }

  componentWillUnmount() {
    this.onChangeSubject.flush()
  }

  onSubjectTab = event => {
    if (!event.shiftKey) {
      event.preventDefault()
      this.contentEditorRef.current.focus()
    }
  }

  onChangeSubject = debounce(emailSubjectState => {
    this.props.change("payload.subject", convertToRaw(emailSubjectState.getCurrentContent()))
  }, 500)

  onChangeObjective = objective => this.props.change("objectiveId", objective.id || null)

  render() {
    const {
      currentUser,
      field,
      inputs,
      message,
      classes,
      match: {
        params: {templateId},
      },
    } = this.props
    const {onChangeSubject} = this
    const isPermittedToArchive = isUserPermitted(currentUser, "templates:archive")
    const isContentLibrary = !templateId

    return (
      <>
        <TextField margin="normal" fullWidth={true} label="Name" {...field("contentName")} />
        <FormControl margin="normal" fullWidth={true}>
          <Editor
            ariaLabel="Email Subject"
            blockRenderMap={draftJsBlockRenderers}
            classes={{toolbarButton: classes.subjectEditorPluginButton}}
            contentType={ContentType.EmailSubject}
            customStyleFn={customStyleFn}
            fullWidth={true}
            initialValue={message.payload.subject}
            label="Subject"
            margin="normal"
            minimal={true}
            multiline={false}
            onChange={onChangeSubject}
            onFocus={() => {
              this.setState({showSubjectToolbar: true})
            }}
            onBlur={() => {
              this.setState({showSubjectToolbar: false})
            }}
            showToolbar={this.state.showSubjectToolbar}
            onTab={this.onSubjectTab}
            tabIndex={0}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth={true}>
          <InputLabel id="message-form-objective-label">Select an Objective</InputLabel>
          <ObjectiveSelect
            labelId="message-form-objective-label"
            onChange={this.onChangeObjective}
            value={inputs?.objectiveId ? {id: inputs.objectiveId.value} : ""}
          />
        </FormControl>
        <Feature featureKey="transactional-emails">
          <Tooltip
            placement="left"
            title={
              <Typography component="div">
                Transactional or relationship emails:
                <ul>
                  <li>
                    Will <strong>not</strong> include an unsubscribe link
                  </li>
                  <li>
                    Will be sent to the contact <strong>regardless</strong> of whether or not they
                    have unsubscribed from email communications.
                  </li>
                </ul>
                It is your responsibility to ensure that this email is not commercial content as
                defined by the{" "}
                <Typography
                  className={classes.link}
                  component="a"
                  href={CAN_SPAM_LINK}
                  rel="noopener"
                  target="_blank"
                >
                  CAN-SPAM act
                </Typography>
                .
              </Typography>
            }
          >
            <FormControl margin="normal" fullWidth={true}>
              <AccessControlled requiredPermissions="emails:transactional">
                <FormControlLabel
                  control={<Switch color="primary" {...field("isTransactional")} />}
                  label="Transactional Message"
                />
              </AccessControlled>
              <Typography component="div" className={classes.transactionalText}>
                This message contains transactional or relationship content and is exempt from
                unsubscribe filtering.
              </Typography>
            </FormControl>
          </Tooltip>
        </Feature>

        {isContentLibrary && (
          <AccessControlled requiredPermissions="templates:archive">
            <MaybeTooltip
              enterDelay={500}
              isTooltip={isPermittedToArchive}
              title={`This ${humanizeType(
                message.type
              )} will be hidden from the content library, but remain active in your campaigns.`}
            >
              <FormControl fullWidth={true} margin="normal">
                <FormControlLabel
                  control={<Switch color="primary" {...field("isArchived")} />}
                  disabled={!isPermittedToArchive}
                  label="Archive"
                />
              </FormControl>
            </MaybeTooltip>
          </AccessControlled>
        )}
      </>
    )
  }
}

GeneralMessageSettings.propTypes = {
  change: func.isRequired,
  classes: object,
  currentUser: object,
  field: func.isRequired,
  inputs: object.isRequired,
  match: shape({
    params: shape({
      templateId: string,
    }),
  }),
  message: object,
}

const styles = {
  subjectEditorPluginButton: {
    display: "inline-flex",
    padding: "0 10px",
    minHeight: 0,
    borderRadius: 4,
  },
  transactionalText: {
    fontSize: 14,
    paddingTop: 10,
  },
}

export default connect(({session}) => ({currentUser: session?.user}))(
  withRouter(
    withStyles(styles)(
      formify({
        autoSubmitOnChange: true,
      })(contentBlockEditorConsumer(GeneralMessageSettings))
    )
  )
)
