import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, string} from "prop-types"

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
    minHeight: 112.5,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: "hidden",
  },
  containerLibraryItem: {
    boxShadow: theme.shadows[4],
    transition: theme.transitions.create("box-shadow"),
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
  },
  text: {
    display: "block",
    color: theme.palette.common.white,
    fontSize: 13,
    overflow: "hidden",
    textOverflow: "ellipsis",
    minHeight: 13,
  },
  libraryItem: {
    maxWidth: 132,
  },
  message: props => ({
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: 15,
    padding: `6px ${theme.spacing(1.5)}`,
    maxHeight: props.allowFullHeight ? "none" : 90,
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    "&:before": {
      content: `""`,
      position: "absolute",
      zIndex: 0,
      bottom: 0,
      right: -8,
      height: 15,
      width: 20,
      background: theme.palette.primary.main,
      borderBottomLeftRadius: 15,
    },
    "&:after": {
      content: `""`,
      position: "absolute",
      zIndex: 1,
      bottom: 0,
      right: -10,
      width: 10,
      height: 15,
      borderBottomLeftRadius: 10,
      background: theme.palette.common.white,
    },
  }),
}))

const SmsBubble = ({inTooltip = false, text, allowFullHeight = false}) => {
  const classes = useStyles({allowFullHeight})

  return (
    <div className={cx(classes.container, {[classes.containerLibraryItem]: !inTooltip})}>
      <div className={cx(classes.message, {[classes.libraryItem]: !inTooltip})}>
        <Typography className={classes.text} component="span">
          {text}
        </Typography>
      </div>
    </div>
  )
}

SmsBubble.propTypes = {
  inTooltip: bool,
  text: string,
  allowFullHeight: bool,
}

export default SmsBubble
