import {func, object, string} from "prop-types"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {useJourneyContext} from "components/journeys/journey-context"
import Progress from "components/progress/progress"

import {useContentBlockEditor} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"

const PluginMeterReadonly = ({className, onClick, contentBlock, customCss, style, ...props}) => {
  const {
    data: {color, type, reward, subtitle},
  } = contentBlock
  const {isEditMode, isPreviewMode} = useContentBlockEditor()
  const {analyticsCache, template} = useJourneyContext()

  // TODO when Progress is dumber still we can just send in a fake value of 50%
  // for edit mode instead of creating a fake analyticsCache
  const fakeAnalyticsCache = {pagesCompleted: ["1"]}
  const fakePages = [
    {completionCondition: "view", id: "1"},
    {completionCondition: "view", id: "2"},
  ]
  const showFake = isEditMode || isPreviewMode

  return (
    <div data-testid="progress-meter-root" className={className} onClick={onClick} style={style}>
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <Progress
        analyticsCache={showFake ? fakeAnalyticsCache : analyticsCache}
        color={color}
        pages={showFake ? fakePages : template.templatePages.map(tp => tp.page)}
        reward={reward}
        subtitle={subtitle}
        type={type}
      />
    </div>
  )
}

PluginMeterReadonly.propTypes = {
  className: string,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  onClick: func,
  style: object,
}

export default PluginMeterReadonly
