import {func, object} from "prop-types"

const DRAG_THRESHOLD = 5

export const onMouseDown = props => event => {
  if (event.button !== 0) return
  event.preventDefault()
  const {currentTarget: element, pageX, pageY} = event

  const ghost = element.cloneNode(true)
  const start = {pageX, pageY}

  ghost.style.position = "fixed"

  const eventHandlers = {}

  eventHandlers.mousemove = onMouseMove({element, ghost, start, eventHandlers, ...props})
  eventHandlers.mouseup = onMouseUp({element, ghost, start, eventHandlers, ...props})

  document.body.addEventListener("mousemove", eventHandlers.mousemove)
  document.body.addEventListener("mouseup", eventHandlers.mouseup)
}

export const dragThresholdCrossed = (a, b, bypass = process.env.NODE_ENV === "test") =>
  bypass ||
  Math.abs(a.pageX - b.pageX) > DRAG_THRESHOLD ||
  Math.abs(a.pageY - b.pageY) > DRAG_THRESHOLD

const onMouseMove = ({
  element,
  ghost,
  dragData,
  eventHandlers,
  onDragStart,
  onDragMove,
  start,
}) => event => {
  event.preventDefault()

  if (dragThresholdCrossed(start, event)) {
    // eslint-disable-next-line no-param-reassign
    eventHandlers.dragStarted = true
    document.body.appendChild(ghost)
    onDragStart(event, {dragData, element, ghost})
  }

  if (!eventHandlers.dragStarted) return

  onDragMove(event, {element, ghost, dragData})
}

const onMouseUp = ({element, ghost, eventHandlers, dragData, onDrop, start}) => event => {
  event.preventDefault()
  document.body.removeEventListener("mousemove", eventHandlers.mousemove)
  document.body.removeEventListener("mouseup", eventHandlers.mouseup)

  if (!eventHandlers.dragStarted) return

  Promise.resolve(onDrop(event, {dragData, element, ghost, start})).then(() => {
    document.body.removeChild(ghost)
  })
}

const draggable = Component => {
  const DraggableComponent = ({dragData, onDragStart, onDragMove, onDrop, ...props}) => {
    const _onMouseDown = onMouseDown({dragData, onDragStart, onDragMove, onDrop})
    return <Component {...props} onMouseDown={_onMouseDown} />
  }

  DraggableComponent.propTypes = {
    dragData: object,
    onDragMove: func,
    onDragStart: func,
    onDrop: func,
  }

  return DraggableComponent
}

export const applyStyles = (el, styles) => {
  Object.entries(styles).forEach(([property, value]) => {
    // eslint-disable-next-line no-param-reassign
    el.style[property] = value
  })
}

export default draggable
