import makeStyles from "@mui/styles/makeStyles"
import {FaArrowsAltV as DragHandleIcon} from "react-icons/fa"
import {SortableHandle} from "react-sortable-hoc"

import MuiIcon from "../mui-icon"

const useClasses = makeStyles(() => ({
  dragHandle: {
    cursor: "move",
    display: "inline-block",
    padding: "0 5px",
    marginLeft: 10,
    "& svg": {
      position: "relative",
      top: 2,
      opacity: 0.4,
      height: 12,
    },
  },
}))

const MessageDragHandle = () => {
  const classes = useClasses()

  return (
    <div className={classes.dragHandle}>
      <MuiIcon icon={<DragHandleIcon />} />
    </div>
  )
}

export default SortableHandle(MessageDragHandle)
