import {TextField} from "@mui/material"
import {func, object} from "prop-types"

import CtaButtonSettings from "components/content-block-editor/cta-button-settings"

import {templatePageContext} from "contexts/template-page-context"
import {MobileOS} from "lib/browser/mobile-os"
import {formify} from "lib/hooks/use-form"

import {journeyContext} from "../../../journeys/journey-context"
import FormActions from "../form-actions"

const MobileAppDownloadSettings = ({
  change,
  contentBlock: {slug: widgetSlug},
  handleSubmit,
  field,
  resetForm,
}) => {
  return (
    <form onSubmit={handleSubmit} aria-label="Mobile App Download Settings">
      <TextField
        id={`mobile-app-download-${widgetSlug}-android`}
        autoFocus={true}
        fullWidth={true}
        label="Android App Link"
        margin="normal"
        {...field(MobileOS.Android)}
      />
      <TextField fullWidth={true} label="iOS App Link" margin="normal" {...field(MobileOS.iOS)} />
      <TextField
        fullWidth={true}
        label="WindowsPhone App Link"
        margin="normal"
        {...field(MobileOS.WindowsPhone)}
      />
      <CtaButtonSettings change={change} field={field} hideIconInput={false} />
      <FormActions resetForm={resetForm} />
    </form>
  )
}

MobileAppDownloadSettings.propTypes = {
  change: func.isRequired,
  contentBlock: object,
  field: func.isRequired,
  handleSubmit: func,
  resetForm: func,
}

const MobileAppDownloadSettingsForm = formify()(MobileAppDownloadSettings)

export default journeyContext(templatePageContext(MobileAppDownloadSettingsForm))
