import camelCase from "lodash/camelCase"
import {node} from "prop-types"
import {createContext, useCallback, useContext, useEffect, useReducer, useState} from "react"
import {useSelector} from "react-redux"

import {createHandler, useSocket} from "contexts/socket-manager"
import {toCamelCase} from "lib/case-converter"

import {actions, initialState, reducer} from "./team-insights-reducer"

const TeamInsightsContext = createContext()

const {SET_FILTER_PARAMS, SET_IS_LOADING, SET_TEAM_INSIGHTS} = actions

export const TeamInsightsContextProvider = ({children}) => {
  const teamId = useSelector(({session}) => session.team)?.id
  const [state, dispatch] = useReducer(reducer, initialState)
  const [hasSocket, setHasSocket] = useState(false)

  const setFilterParams = ({startDate, endDate, templateStatus}) =>
    dispatch({type: SET_FILTER_PARAMS, startDate, endDate, templateStatus})

  const setTeamInsights = (name, insightsCache, args) =>
    dispatch({type: SET_TEAM_INSIGHTS, args, name, insightsCache})

  const setIsLoading = (name, value) =>
    dispatch({type: SET_IS_LOADING, name: `${name}IsLoading`, value})

  const fetchInsights = useCallback((name, fetcher, args) => {
    setIsLoading(name, true)

    fetcher(...args).then(res => {
      if (res) {
        setTeamInsights(name, res.value, res.args)
        setIsLoading(name, false)
      }
      // else it's going to be coming back by socket
    })
  }, [])

  const {addHandler, removeHandler} = useSocket()

  useEffect(() => {
    if (!teamId) return

    const teamInsights = createHandler(
      `insights:${teamId}:team`,
      "insights_cached",
      _insightsCache => {
        const {name: _name, args, value} = toCamelCase(_insightsCache)
        const name = camelCase(_name)
        setTeamInsights(name, value, args)
        setIsLoading(name, false)
      }
    )

    addHandler(teamInsights)

    setHasSocket(true)

    return () => removeHandler(teamInsights)
  }, [addHandler, removeHandler, teamId])

  const value = {
    ...state,

    fetchInsights,
    hasSocket,
    setFilterParams,
  }

  return <TeamInsightsContext.Provider value={value}>{children}</TeamInsightsContext.Provider>
}

TeamInsightsContextProvider.propTypes = {
  children: node.isRequired,
}

export const provideTeamInsights = Component => props => (
  <TeamInsightsContextProvider>
    <Component {...props} />
  </TeamInsightsContextProvider>
)

export const useTeamInsights = () => useContext(TeamInsightsContext)

export const teamInsightsContext = Component => props => (
  <Component {...useTeamInsights()} {...props} />
)
