import {IconButton, Snackbar, SnackbarContent} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, node, oneOfType, string} from "prop-types"
import {Component, forwardRef, isValidElement} from "react"
import {MdClose as CloseIcon} from "react-icons/md"
import {connect} from "react-redux"

import {dismissAppMessage} from "../../actions/app-actions"

class AppMessages extends Component {
  handleClose = (event, reason) => {
    if (reason === "clickaway") return

    this.props.onClose()
  }

  render() {
    const {message} = this.props

    // Don't show the snackbar in previews
    if (window.location.pathname.match(/\/previews\/page\//)) return null

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={30000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        onClose={this.handleClose}
        open={!!message}
      >
        <MessageDisplay message={message} onClose={this.handleClose} />
      </Snackbar>
    )
  }
}

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}))

const MessageDisplay = forwardRef(({message, onClose}, ref) => {
  const classes = useStyles()

  if (isValidElement(message)) return message

  return (
    <SnackbarContent
      action={[
        <IconButton
          aria-label="Close"
          className={classes.close}
          color="inherit"
          key="close"
          onClick={onClose}
          size="medium"
        >
          <CloseIcon />
        </IconButton>,
      ]}
      message={<span id="message-id">{message}</span>}
      ref={ref}
    />
  )
})

MessageDisplay.propTypes = {
  message: oneOfType([node, string]),
  onClose: func.isRequired,
}

AppMessages.propTypes = {
  message: oneOfType([string, node]),
  onClose: func,
}

const mapStateToProps = ({app}) => ({message: app.appMessage})

const mapActionsToProps = {onClose: dismissAppMessage}

export default connect(mapStateToProps, mapActionsToProps)(AppMessages)
