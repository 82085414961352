import {FormControl, InputLabel, ListItemText, MenuItem} from "@mui/material"
import {func, object, string} from "prop-types"

import DOSelect from "components/do-select/do-select"

import {useCaseInputs} from "./journey-use-case-inputs"

const JourneyUseCaseInput = ({classes, onChange, value}) => {
  return (
    <FormControl className={classes.campaignUseCase}>
      <InputLabel htmlFor="campaignUseCase" shrink={true}>
        Use Case
      </InputLabel>
      <DOSelect
        id="campaignUseCase"
        displayEmpty={true}
        onChange={onChange}
        renderValue={value => value}
        value={value}
      >
        {useCaseInputs.map(({description, label, value}) => (
          <MenuItem classes={{root: classes.menuItem}} key={value} value={value}>
            <ListItemText
              classes={{secondary: classes.listItemTextSecondary}}
              primary={label}
              secondary={description}
            />
          </MenuItem>
        ))}
      </DOSelect>
    </FormControl>
  )
}

JourneyUseCaseInput.propTypes = {
  classes: object.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
}

export default JourneyUseCaseInput
