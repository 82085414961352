export const targetingPersonalizationStrategies = [
  {
    label: "Newest Account",
    value: "OPENED_DATE_DESC",
  },
  {
    label: "Oldest Account",
    value: "OPENED_DATE_ASC",
  },
  {
    label: "Most Recently Created",
    value: "INSERTED_AT_DESC",
  },
  {
    label: "Least Recently Created",
    value: "INSERTED_AT_ASC",
  },
  {
    label: "Most Recently Updated",
    value: "UPDATED_AT_DESC",
  },
  {
    label: "Least Recently Updated",
    value: "UPDATED_AT_ASC",
  },
]
