import {TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, shape, string} from "prop-types"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import {fetchUrlHeaders} from "lib/api"
import {formify} from "lib/hooks/use-form"
import {formatUrl} from "lib/string/url-helpers"

import FormActions from "../form-actions"

const WebpageEmbedSettings = ({
  contentBlock: {slug: widgetSlug},
  field,
  handleSubmit,
  resetForm,
}) => {
  const classes = useStyles()

  return (
    <form className={classes.root} onSubmit={handleSubmit} aria-label="Webpage Settings">
      <TextField
        id={`webpage-${widgetSlug}-url`}
        autoFocus={true}
        fullWidth={true}
        label="URL"
        margin="normal"
        type="text"
        {...field("url")}
      />
      <FormActions resetForm={resetForm} />
    </form>
  )
}

WebpageEmbedSettings.propTypes = {
  contentBlock: shape({
    slug: string.isRequired,
  }).isRequired,
  isPage: bool,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func,
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}))

const WebpageEmbedSettingsForm = formify({
  parse: {url: formatUrl},
})(WebpageEmbedSettings)

const mapDispatchToProps = (dispatch, {onSubmit, onCancel}) => ({
  onSubmit: ({url}) => {
    let updatedUrl = url

    if (url.substr(0, 3) === "://") updatedUrl = `https${updatedUrl}`
    if (url.substr(0, 4).toLowerCase() !== "http") updatedUrl = `https://${updatedUrl}`
    updatedUrl = new URL(updatedUrl)
    updatedUrl.protocol = "https:"

    fetchUrlHeaders(updatedUrl.toString())
      .then(headers => {
        const frameOptions = headers.xFrameOptions
        let service

        if (frameOptions && frameOptions !== "ALLOWALL") service = "embedly"
        else service = "iframe"

        onSubmit({
          service,
          type: "webpage",
          url: updatedUrl.toString(),
        })
      })
      .catch(error => {
        if (error.status === 400) alert("Sorry, it looks like that URL isn't working for us.")
        else throw error
      })
  },
  onCancel,
  ...bindActionCreators({}, dispatch),
})

export default connect(mapDispatchToProps)(WebpageEmbedSettingsForm)
