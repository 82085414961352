import makeStyles from "@mui/styles/makeStyles"
import {number} from "prop-types"

const RelativePercentage = ({value}) => {
  const classes = useStyles()

  let className,
    sign = ""

  switch (true) {
    case value < 0:
      className = classes.worse
      break
    case value > 0:
      className = classes.better
      sign = "+"
      break
    default:
      className = classes.same
  }

  return <span className={className}>{`${sign}${parseFloat(value.toFixed(0))}`}%</span>
}

RelativePercentage.propTypes = {
  value: number.isRequired,
}

const useStyles = makeStyles(theme => ({
  better: {
    color: theme.analytics.comparisons.better,
  },
  same: {
    color: theme.analytics.comparisons.same,
  },
  worse: {
    color: theme.analytics.comparisons.worse,
  },
}))

export default RelativePercentage
