import {shape, string} from "prop-types"
import {connect} from "react-redux"

import {auditLogsItemsPerPage} from "../audit-logs/audit-logs"
import {getAuditLogActions} from "../audit-logs/audit-logs-actions.js"
import Box from "../box/box"

const mapStateToProps = ({auditLogs, session}) => ({
  eventActions: {contact: auditLogs.eventActions.contact ?? []},
  currentUser: session.user,
})

const addActionsToProps = {getAuditLogActions}

const AuditLogs = auditLogsItemsPerPage(10)

const ContactAuditLogs = ({contact, ...props}) => (
  <Box>
    {contact.id && (
      <AuditLogs
        entityFilter={{contactId: contact.id}}
        headers={[
          {field: "action", isDefault: true},
          {field: "user", isDefault: true},
          {field: "batchId", isDefault: true},
          {field: "sshKey", label: "SSH Key", sortable: false},
          {field: "location", sortable: false, isDefault: true},
          {field: "timestamp", isDefault: true},
        ]}
        title="My Audit Logs"
        {...props}
      />
    )}
  </Box>
)

ContactAuditLogs.propTypes = {
  contact: shape({id: string}),
}

export default connect(mapStateToProps, addActionsToProps)(ContactAuditLogs)
