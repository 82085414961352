import ImageButton, {ImageIcon} from "./image-button"
import ImageReadonly from "./image-readonly"
import ImageSettings from "./image-settings"

export default {
  title: "Image",
  type: "image",
  Button: ImageButton,
  Icon: ImageIcon,
  Readonly: ImageReadonly,
  Settings: ImageSettings,
  suitableFor: ["content_container", "message", "page", "template"],
}
