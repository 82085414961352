import {replace} from "connected-react-router"
import qs from "query-string"
import {useCallback} from "react"
import {useDispatch, useSelector} from "react-redux"

// We want to maintain a tidy url whenever possible. This means removing
// useless/redundant junk that we don't care about. We don't want falsy
// values or values that already live in `defaultParams`. You should
// call clean anytime you are about to stringify some data into query
// params.
export const clean = (params, defaultParams) =>
  Object.entries(params)
    .filter(([, value]) => !!value)
    .filter(([key, value]) => !(key in defaultParams) || defaultParams[key] !== value)
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

// First we parse query string's values into their respective boolean and
// number counter parts. We then override any parsed values to their
// original string format if their key presides in `ignoreTypeCoercion`.
// Using `ignoreTypeCoercion` can be useful in situations where we want
// to preserve a types string format, such as wanting to preserve `true`
// as `"true"` in the case of a search field. `arrayFormat` is important,
// so that parse will recognize `'foo[]=1&foo[]=2&foo[]=3'` and convert it
// back to its array format `[1, 2, 3]`.
export const parse = (string, ignoreTypeCoercion) => ({
  ...qs.parse(string, {arrayFormat: "bracket", parseBooleans: true, parseNumbers: true}),
  ...qs.parse(qs.pick(string, ignoreTypeCoercion), {arrayFormat: "bracket"}),
})
const stringify = obj => qs.stringify(obj, {arrayFormat: "bracket"})

const useQueryParams = (defaultParams = {}, ignoreTypeCoercion = []) => {
  // Redux
  const location = useSelector(state => state?.router?.location ?? "")
  const dispatch = useDispatch()

  // Handlers

  // Responsible for merging new and old query params.
  const updateRouterState = useCallback(
    params => {
      const query = `?${stringify(
        clean({...parse(location.search, ignoreTypeCoercion), ...params}, defaultParams)
      )}`

      dispatch(replace(query))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, JSON.stringify(ignoreTypeCoercion), location.search]
  )

  return {
    ...defaultParams,
    ...parse(location.search, ignoreTypeCoercion),
    updateSearchQuery: updateRouterState,
  }
}

export default useQueryParams

export const parameterize = (defaultParams = {}, ignoreTypeCoercion = []) => Component => props => (
  <Component {...props} {...useQueryParams(defaultParams, ignoreTypeCoercion)} />
)
