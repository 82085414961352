import withStyles from "@mui/styles/withStyles"
import {object} from "prop-types"
import {useEffect, useState} from "react"
import {Link} from "react-router-dom"

import {fetchContentRelatedToFile} from "lib/api"

const FileRelatedContent = ({classes, file}) => {
  const [isLoading, setLoadingState] = useState(false)
  const [{relatedTemplates, relatedPages, relatedMessages}, setRelatedContent] = useState({
    relatedTemplates: [],
    relatedPages: [],
    relatedMessages: [],
  })

  useEffect(() => {
    setLoadingState(true)

    const _setRelatedContent = async () => {
      const relatedContent = await fetchContentRelatedToFile(file.id)

      setLoadingState(false)
      setRelatedContent(relatedContent)
    }

    _setRelatedContent()
  }, [file])

  if (isLoading) return "Fetching info on where this image is used..."

  if (!relatedTemplates.length && !relatedPages.length && !relatedMessages.length)
    return "This file is currently not being used in any template footers, pages, or messages. (This file may be used as a template logo or a landing page background)"

  return (
    <>
      {!!relatedPages.length && <p>This image is being used in the following page(s):</p>}
      <ul className={classes.list}>
        {relatedPages.map(({id, contentName, templateId, slug}) => (
          <li className={classes.listItem} key={id}>
            <Link to={`/admin/templates/${templateId}/edit/${slug}`}>{contentName}</Link>
          </li>
        ))}
      </ul>
      {!!relatedMessages.length && <p>This image is being used in the following message(s):</p>}
      <ul className={classes.list}>
        {relatedMessages.map(({id, type, order, page: {contentName, templateId, slug}}) => (
          <li className={classes.listItem} key={id}>
            <Link to={`/admin/templates/${templateId}/edit/${slug}`}>
              {contentName} {type} #{order}
            </Link>
          </li>
        ))}
      </ul>
      {!!relatedTemplates.length && (
        <p>This image is being used in the following template footer(s):</p>
      )}
      <ul className={classes.list}>
        {relatedTemplates.map(({id, name}) => (
          <li className={classes.listItem} key={id}>
            <Link to={`/admin/templates/${id}/edit`}>{name}</Link>
          </li>
        ))}
      </ul>
      <p>(this file may also be used as a template logo or a landing page background)</p>
    </>
  )
}

FileRelatedContent.propTypes = {
  classes: object,
  file: object.isRequired,
}

const styles = {
  list: {
    paddingLeft: 20,
  },
  listItem: {
    "& a": {
      color: "inherit",
    },
  },
}

export default withStyles(styles)(FileRelatedContent)
