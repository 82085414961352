import {TableCell, TableRow, Typography} from "@mui/material"
import orderBy from "lodash/orderBy"
import pick from "lodash/pick"
import {func, number, object, string} from "prop-types"
import {useEffect, useMemo} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import Expandable from "components/expandable/expandable"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchAdvancedInsightsCampaignPerformance} from "lib/api"
import {formatDate} from "lib/date-time-formatters"

const TABLE_HEADERS = [
  {
    field: "templateName",
    label: "Campaign Name",
    sortable: true,
    tooltipText: "Campaign Name: internal name of the campaign",
  },
  {
    field: "launchedAt",
    label: "Launch Date",
    sortable: true,
    tooltipText: "Launch Date: the day that the first journey in the campaign was created",
  },
  {
    field: "journeyCount",
    label: "Number of Journeys",
    sortable: true,
    tooltipText: "Number of Journeys: number of journeys in the campaign",
  },
  {
    field: "messageCtr",
    label: "Message CTR",
    sortable: true,
    tooltipText:
      "Message CTR: total number of unique message clicks / total number of messages sent excluding bounces",
  },
  {
    field: "bounceRate",
    label: "Bounce Rate",
    sortable: true,
    tooltipText: "Bounce Rate: total number of bounces / total number of messages sent",
  },
  {
    field: "pageViewRate",
    label: "Page View Rate",
    sortable: true,
    tooltipText:
      "Page View Rate: total number of unique page views / total number of journeys * number of pages",
  },
  {
    field: "pageCtr",
    label: "Page CTR",
    sortable: true,
    tooltipText: "Page CTR: total number of unique page clicks / total number of unique page views",
  },
]

const DEFAULT_DATA = []

const CampaignPerformance = ({classes, setTableState, ...props}) => {
  const {
    campaignPerformance,
    campaignPerformanceIsLoading,
    hasSocket,
    fetchInsights,
    filterParams,
  } = useAdvancedTeamInsights()
  const data = useMemo(() => campaignPerformance?.data ?? DEFAULT_DATA, [campaignPerformance])

  // Fetch data
  useEffect(() => {
    if (!hasSocket) return
    fetchInsights("campaignPerformance", fetchAdvancedInsightsCampaignPerformance, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  useEffect(() => {
    // NB: This is needed so that our rows are effected by our default state.
    // This should only get called after fetchInsights or our socket
    // returns new data.
    refresh({page: 0})
    // FIXME ignoring react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const refresh = attrs => {
    const currentItemsPerPage = attrs?.itemsPerPage ?? props.itemsPerPage
    const currentPage = attrs?.page ?? props.page
    const offset = currentPage * currentItemsPerPage
    const sortColumn = attrs?.sortColumn ?? props.sortColumn
    const sortDirection = attrs?.sortDirection ?? props.sortDirection

    const rows = orderBy(
      data,
      [
        item => {
          const column = item[sortColumn]

          return isNaN(column) ? column.toLowerCase() : column
        },
      ],
      [sortDirection]
    ).filter((item, i) => i >= offset && i < offset + currentItemsPerPage)

    setTableState({
      ...pick(props, "itemsPerPage", "page", "sortColumn", "sortDirection"),
      ...attrs,
      rows,
      page: currentPage,
      totalCount: data.length ?? 0,
    })
  }

  return (
    <div>
      <Typography variant="h5">Campaigns</Typography>
      <div className={classes.expandableContainer} style={{paddingTop: 16}}>
        <Expandable label="How we use this table">
          <p>
            The campaign performance table reports on all campaigns and their key performance
            metrics.
          </p>

          <Typography variant="h6">At a Glance</Typography>
          <p>
            Use this table to monitor overall campaign portfolio performance. Compare engagement
            metrics by using sort features at the column header to see the highest and lowest
            performers. For example, the highest or lowest click through rate.
          </p>

          <Typography variant="h6">Use of Page Filters</Typography>
          <ul>
            <li>
              <b>Dates</b>: Use the date filter to align engagement with your omnichannel marketing
              efforts. For example, see credit card campaign engagement performance in September
              while ads ran for social media credit card rewards.
            </li>
            <li>
              <b>Objectives</b>: Filter by objectives to isolate specific campaign types. For
              example, review activation campaigns by selecting Online Banking, Mobile Banking,
              Direct Deposit, eStatements and Debit Card Activation.
            </li>
          </ul>
        </Expandable>
      </div>

      <Expandable label="Hide chart" labelClassName={classes.hideChartLabel} startExpanded={true}>
        <DOTable
          headers={TABLE_HEADERS}
          isTableLoading={campaignPerformanceIsLoading}
          noResults="No data found."
          paginationEnabled={true}
          refresh={refresh}
        >
          {({
            bouncedRate,
            journeyCount,
            launchedAt,
            messageCtr,
            pageCtr,
            pageViewRate,
            templateId,
            templateName,
          }) => (
            <TableRow key={templateId}>
              <TableCell>
                <Typography
                  color="inherit"
                  component="a"
                  href={`/admin/templates/${templateId}/insights`}
                  target="_blank"
                >
                  {templateName}
                </Typography>
              </TableCell>
              <TableCell>{launchedAt ? formatDate(launchedAt) : ""}</TableCell>
              <TableCell>{journeyCount}</TableCell>
              <TableCell>{messageCtr}%</TableCell>
              <TableCell>{bouncedRate}%</TableCell>
              <TableCell>{pageViewRate}%</TableCell>
              <TableCell>{pageCtr}%</TableCell>
            </TableRow>
          )}
        </DOTable>
      </Expandable>
    </div>
  )
}

CampaignPerformance.propTypes = {
  classes: object,
  itemsPerPage: number,
  page: number,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
}

export default tabular({
  sortColumn: "templateName",
  sortDirection: "asc",
})(CampaignPerformance)
