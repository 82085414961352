import {TextField, Typography} from "@mui/material"
import Box from "@mui/material/Box"
import {darken} from "@mui/material/styles"
import {func} from "prop-types"

import {requiredField} from "../../lib/field-validations"
import useForm from "../../lib/hooks/use-form"
import {SaveButton} from "../save-button/save-button"

const DisapproveForm = ({onSubmit}) => {
  const {field, handleSubmit, submitting} = useForm({
    onSubmit: i => onSubmit(i),
    initialValues: {notes: "", response: "not_approved"},
    validators: {
      notes: [requiredField],
    },
  })

  return (
    <div>
      <Typography variant="h4">Mark as not approved:</Typography>
      <Typography sx={{minHeight: "3.5rem"}} variant="body1">
        Reply that the campaign is not approved and send a note to the editor why it needs revision.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          disabled={submitting}
          fullWidth={true}
          placeholder="Disapproval message text (required):"
          multiline={true}
          rows="6"
          variant="outlined"
          {...field("notes")}
        />
        <Box
          sx={{
            marginTop: 3,
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <SaveButton
            classes={{}}
            stateLabels={{
              default: "Not Approved",
              saved: "Approved",
              submitting: "Sending...",
            }}
            submitting={submitting}
            sx={theme => ({
              backgroundColor: theme.palette.error.main,
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: darken(theme.palette.error.main, 0.15),
              },
            })}
          />
        </Box>
      </form>
    </div>
  )
}

DisapproveForm.propTypes = {
  onSubmit: func.isRequired,
}

export default DisapproveForm
