import omit from "lodash/omit"

import {
  LOADING_STATE_COMPLETED,
  LOADING_STATE_FAILED,
  LOADING_STATE_RESET,
  LOADING_STATE_STARTED,
} from "../actions/loading-state-actions"

function loadingStates(state = {}, action) {
  switch (action.type) {
    case LOADING_STATE_STARTED:
    case LOADING_STATE_FAILED:
    case LOADING_STATE_COMPLETED:
      return {
        ...state,
        [action.payload.key]: {...action.payload, code: action.type},
      }

    case LOADING_STATE_RESET:
      return omit(state, [action.payload.key])

    default:
      return state
  }
}

export default loadingStates
