import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import castArray from "lodash/castArray"
import {array, node, object, oneOfType, string} from "prop-types"
import {MdExpandMore as ExpandIcon, MdInfo as InfoIcon} from "react-icons/md"

const CustomCSSDocumentation = ({classes}) => (
  <Accordion classes={{root: classes.expansionPanel}} elevation={0}>
    <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
      <Typography>
        Custom CSS Documentation <InfoIcon className={classes.helpIcon} />
      </Typography>
    </AccordionSummary>
    <AccordionDetails classes={{root: classes.expansionContent}}>
      <div>
        <Typography>
          Templates that use Custom CSS have no base styling applied. Some components will bring
          enough styling for their basic functionality to be preserved but you will need to supply
          the rest. If your CSS loads any external resources (images, fonts, etc...), you will need
          to make sure they are loaded via <code>https://</code>.<br />
          <br />
          This document covers the basic functionality and selectors available for customizing the
          look and feel of journeys. It is not exhaustive so you may need to inspect the rendered
          html to target specific elements inside a component.
        </Typography>
        <Typography className={classes.header} variant="h4">
          Structure
        </Typography>
        <Typography>
          Custom CSS is sandboxed to make sure it doesn't collide with any assets or resources
          outside the journey view. While this makes some tags like <code>{"<html>"}</code> and{" "}
          <code>{"<body>"}</code> inaccessible, there is still a root container that is full width
          and height that is selectable with the following CSS selector{" "}
          <code>.journey-wrapper</code>.<br />
          <br />
          The sandboxing respects <code>@media</code> queries which means you can still target
          specific screen sizes, orientations, and devices.
          <br />
          <br />
          The basic structure of a journey's markup is illustrated below with selectors that
          indicate their hierarchy by the level of indentation.
        </Typography>
        <pre>
          <span className="class">.journey-wrapper</span>
          {"\n"}
          {"  "}
          <span className="element">header</span>
          <span className="class">.journey-header</span>
          {"\n"}
          {"  "}
          <span className="class">
            <span className="element">nav</span>.navigation
          </span>
          {"\n"}
          {"  "}
          <span className="class">.journey-header</span>
          {"\n"}
          {"  "}
          <span className="element">main</span>
          {"\n"}
          {"    "}
          <span className="class">.page-wrapper</span>
          {"\n"}
          {"      "}
          <span className="class">.page-content</span>
          {"\n"}
          {"        /*widgets and content are rendered here*/\n"}
          {"      "}
          <span className="class">.footer-content</span>
          {"\n"}
          {"    "}
          <span className="class">.journey-support-owner</span>
          {"\n"}
        </pre>
        <Typography variant="caption">
          <i>
            This does not document all elements in the page, but it can be used as a guide in case
            you want to chain selectors.
          </i>
        </Typography>
        <Typography className={classes.header} variant="h4">
          CSS Variables
        </Typography>
        <Typography>
          Branding colors are still available when using custom CSS. Two variables are injected into
          the stylesheets and updated as the fields are updated so that they can be used in your
          custom CSS. The value of the <b>Primary Color</b> field will be accessible in{" "}
          <code>--template-primary-color</code>. Likewise, the value of the <b>Secondary Color</b>{" "}
          field will be accessible in <code>--template-secondary-color</code>.
        </Typography>
        <Typography className={classes.header} variant="h4">
          Navigation
        </Typography>
        <Typography>
          The <code>navigation</code> relies on an unordered list and has the following structure.
        </Typography>
        <pre>
          <span className="class">nav.navigation</span>
          {"\n"}
          {"  "}
          <span className="element">ul</span>
          {"\n"}
          {"    "}
          <span className="element">li</span>
          {"\n"}
          {"      "}
          <span className="element">a</span>
          {"\n"}
          {"        "}
          <span className="element">
            div<span className="class">.icon</span>
          </span>
          {"\n"}
          {"          "}
          <span className="element">svg</span>
          {"\n"}
          {"        "}
          <span className="element">
            div<span className="class">.page-title</span>
          </span>
          {"\n"}
        </pre>
        <Typography>
          Classes are conditionally applied to the list item based on the state of of the content it
          represents.
        </Typography>
        <Documentation classes={classes} selector="li.is-active" title="Active Content">
          If the content represented by the link is currently loaded the list item will have the{" "}
          <code>.is-active</code> class applied to it.
        </Documentation>
        <Documentation classes={classes} selector="li.is-complete" title="Completed Content">
          If the content represented by the link has had its <b>completion condition</b> met the
          list item will have the <code>.is-complete</code> class applied to it.
        </Documentation>
        <Documentation classes={classes} selector="li.is-not-live" title="Hidden Content">
          You don't necessarily need to provide a style for this selector. It is only used in the
          editor interface to indicate to the user editing the content that the page is not live and
          thus will not be displayed to end users.
        </Documentation>
        <Typography className={classes.header} variant="h4">
          Components
        </Typography>
        <Typography>
          There are several components which appear within the journey that have hooks available for
          custom CSS.
        </Typography>
        <Documentation classes={classes} selector=".journey-header" title="Journey Header">
          This component contains the logo if one has been uploaded and the progress meter.
        </Documentation>
        <Documentation classes={classes} selector=".journey-container" title="Journey Container">
          The journey container wraps the page content and navigation components.
        </Documentation>
        <Documentation classes={classes} selector=".page-wrapper" title="Page Wrapper">
          The page wrapper contains the main content element and footer content element.
        </Documentation>
        <Documentation classes={classes} selector=".page-content" title="Content">
          The content element contains all page-specific content – including any widgets that have
          been added to the content. These are documented in more detail below.
        </Documentation>
        <Documentation classes={classes} selector=".footer-content" title="Footer Content">
          The footer content element is similar to the page content element.
        </Documentation>
        <Documentation classes={classes} selector=".navigation" title="Navigation">
          The navigation component contains links to all pages in a journey. Inspect its content for
          a better understanding of statuses that are applied to the links.
        </Documentation>
        <Documentation
          classes={classes}
          selector=".journey-support-owner"
          title="Support Owner Links"
        >
          This is shown as a sticky footer in the default theme. It contains links that are
          automatically hidden if the relevant link is not set.
        </Documentation>
        <Typography className={classes.header} variant="h4">
          Widgets
        </Typography>
        <Typography>
          All widgets are selectable with a class based on their widget type. The content of the
          widgets will vary depending on their purpose and state.
        </Typography>
        <Documentation classes={classes} selector=".cta-widget" title="CTA">
          This widget is fairly simple in that it is a simple wrapper around a button that is built
          with an <code>{"<a>"}</code> tag.
        </Documentation>
        <Documentation
          classes={classes}
          selector=".mobile-app-download-widget"
          title="Mobile App Download"
        >
          The mobile app widget changes what it renders based on the device it is rendered on and
          the its current state. Be sure to test your styling on mobile devices and PCs. When the
          widget is showing a form for a user to enter their number there is an addiotional state
          that can be styled after the user has submitted the form.
        </Documentation>
        <Documentation classes={classes} selector=".survey-widget" title="Survey">
          The survey widget is very complex and contains text inputs, radios, checkboxes, and
          buttons. If you are styling the components in a survey make sure you have provided styling
          for all the elements in it you will be using.
        </Documentation>
        <Documentation classes={classes} selector=".document-embed-widget" title="Document Embed">
          This widget is a simple wrapper around an <code>{"<iframe>"}</code>.
        </Documentation>
        <Documentation classes={classes} selector=".image-widget" title="Image">
          This widget is a simple wrapper with an <code>{"<img>"}</code> tag in it.
        </Documentation>
        <Documentation classes={classes} selector=".webpage-widget" title="Webpage Embed">
          This widget will always be the actual <code>{"<iframe>"}</code> being embedded in the
          page.
        </Documentation>
        <Documentation
          classes={classes}
          selector={[".vimeo-widget", ".wistia-widget", ".youtube-widget"]}
          title="Video Embed"
        >
          Due to the nature of video embeds they will be rendered with a selector that varies based
          on the type of video they are. Wistia videos will have a selector of{" "}
          <code>.wistia-widget</code>, Vimeo videos will have a selector of{" "}
          <code>.vimeo-widget</code>, and YouTube videos will have a selector of{" "}
          <code>.youtube-widget</code>. Their internal markup may vary based on the video service
          being used.
        </Documentation>
        <Documentation classes={classes} selector=".personalization-widget" title="Personalization">
          Although it is possible to target personalizations directly, it is not recommended.
          Personalizations should blend into the content naturally.
        </Documentation>
        <Documentation classes={classes} selector=".divider-widget" title="Divider">
          This widget is a <code>{"<div>"}</code> with a style attribute setting the height.
        </Documentation>
      </div>
    </AccordionDetails>
  </Accordion>
)

CustomCSSDocumentation.propTypes = {
  classes: object.isRequired,
}

const Documentation = ({classes, title, selector, children}) => (
  <>
    <Typography className={classes.subHeader} variant="h6">
      {title}
    </Typography>
    <Typography>
      Select with{" "}
      {castArray(selector)
        .map(sel => <code key={sel}>{sel}</code>)
        .reduce((prev, curr) => [prev, ", ", curr])}
      <br />
      <br />
      {children}
    </Typography>
  </>
)

Documentation.propTypes = {
  children: node,
  classes: object.isRequired,
  selector: oneOfType([array, string]),
  title: string,
}

const styles = theme => ({
  expansionPanel: {
    backgroundColor: "transparent",
    margin: 0,
    "&::before": {
      display: "none", // hides the line mui puts on the expansion panel
    },
  },
  expansionContent: {
    "& code": {
      fontFamily: `"Monaco","Consolas","Lucida Console",monospace`,
      color: "#F92672",
      background: "#FEECF2",
    },
    "& pre": {
      fontFamily: `"Monaco","Consolas","Lucida Console",monospace`,
      display: "block",
      padding: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      backgroundColor: "#272822",
      color: "#F8F8F2",
      "& .element": {
        color: "#66D9EF",
      },
      "& .class": {
        color: "#FD971F",
      },
    },
  },
  codeBlock: {},
  header: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    boxShadow: theme.shadows[1],
  },
  subHeader: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
    fontSize: "1rem",
  },
  helpIcon: {
    verticalAlign: "middle",
  },
})

export default withStyles(styles)(CustomCSSDocumentation)
