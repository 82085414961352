import {IconButton} from "@mui/material"
import debounce from "lodash/debounce"
import PropTypes from "prop-types"
import {forwardRef, useRef, useState} from "react"
import {
  MdKeyboardArrowLeft as PageBackIcon,
  MdKeyboardArrowRight as PageForwardIcon,
} from "react-icons/md"

// originally sourced from https://github.com/mui-org/material-ui/blob/daf2e9dc2a3b1331e9ba598068ee0b2aa7aeb515/packages/material-ui/src/TablePagination/TablePaginationActions.js
const TablePaginationActions = forwardRef(
  (
    {
      backIconButtonProps,
      count,
      nextIconButtonProps,
      onPageChange,
      page,
      rowsPerPage,
      getItemAriaLabel,
      ...other
    },
    ref
  ) => {
    const [currentPage, setCurrentPage] = useState(page + 1)

    const debouncedOnChangePage = useRef(debounce(onPageChange, 1000)).current

    const handleBackButtonClick = event => {
      const nextPage = page - 1

      setCurrentPage(nextPage + 1)
      onPageChange(event, nextPage)
    }

    const handleNextButtonClick = event => {
      const nextPage = page + 1

      setCurrentPage(nextPage + 1)
      onPageChange(event, nextPage)
    }

    const handlePageInputChange = event => {
      const {
        target: {value},
      } = event

      setCurrentPage(value)
      const nextPage = parseInt(value, 10)

      if (isNaN(nextPage)) return

      const normalized = Math.min(Math.max(nextPage, 1), Math.ceil(count / rowsPerPage))

      setCurrentPage(normalized)
      debouncedOnChangePage(event, normalized - 1)
    }

    return (
      <div ref={ref} {...other}>
        <IconButton
          aria-label="previous"
          color="inherit"
          disabled={page === 0}
          onClick={handleBackButtonClick}
          title="previous"
          {...backIconButtonProps}
          size="medium"
        >
          <PageBackIcon />
        </IconButton>
        {count > 0 && (
          <>
            Page{" "}
            <input
              inputMode="numeric"
              onChange={handlePageInputChange}
              pattern="[0-9]*"
              size={count.toString().length}
              style={{textAlign: "right"}}
              value={currentPage}
            />{" "}
            of {Math.ceil(count / rowsPerPage)}
          </>
        )}
        <IconButton
          aria-label="next"
          color="inherit"
          disabled={count !== -1 ? page >= Math.ceil(count / rowsPerPage) - 1 : false}
          onClick={handleNextButtonClick}
          title="next"
          {...nextIconButtonProps}
          size="medium"
        >
          <PageForwardIcon />
        </IconButton>
      </div>
    )
  }
)

TablePaginationActions.propTypes = {
  backIconButtonProps: PropTypes.object,
  count: PropTypes.number.isRequired,
  getItemAriaLabel: PropTypes.func,
  nextIconButtonProps: PropTypes.object,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

export default TablePaginationActions
