import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {useEffect, useState} from "react"
import {MdLock as LockIcon} from "react-icons/md"
import {useDispatch} from "react-redux"

import {setAccessToken} from "lib/access-token"
import {fetchUserInfo, sendOneTimePassword, verifyOneTimePassword} from "lib/api"
import useAnalytics from "lib/hooks/use-analytics"
import {fullName} from "lib/names"
import storage from "lib/storage"

import {setCurrentUser} from "../../actions/session-actions"
import JourneyOwnerInfo from "./journey-owner-info"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    padding: theme.spacing(1),
  },
  isModal: {
    minHeight: "auto",
    padding: theme.spacing(3),
  },
  content: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: 1200,
    justifySelf: "center",
    margin: "0 auto",
    "& p": {
      marginBottom: theme.spacing(1),
    },
    "& p:last-of-type": {
      marginBottom: 0,
    },
    padding: `0 ${theme.spacing(4)}`,
  },
  horizontal: {
    backgroundColor: theme.palette.common.white,
  },
  lock: {
    color: theme.palette.primary.main,
    fontSize: "8rem",
    alignSelf: "center",
    marginBottom: theme.spacing(4),
  },
  header: {
    textAlign: "center",
  },
  choices: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  verificationCode: {
    maxWidth: 400,
    alignSelf: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& button": {
      display: "block",
    },
    "& > :first-child": {
      marginBottom: theme.spacing(2),
    },
  },
  errorText: {
    display: "block",
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  submit: {
    display: "block",
    margin: "0 auto",
  },
  rememberMe: {
    marginBottom: theme.spacing(2),
  },
  textMessageRatesFinePrint: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}))

const ContactLogin = ({template, contact, journeyId, isModal, onLoginSuccess}) => {
  const dispatch = useDispatch()
  const [oneTimePassword, setOneTimePassword] = useState("")
  const [verificationMethod, setVerificationMethod] = useState("")
  const [didError, setDidError] = useState(false)
  const [didSendCode, setDidSendCode] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const {team, owner} = template
  const classes = useStyles()

  const {track} = useAnalytics()

  useEffect(() => {
    track("auth_presented")
  }, [track])

  const onChangeContactMethod = ({target: {value}}) => {
    setVerificationMethod(value)
  }

  const onClickSendCode = () => {
    sendOneTimePassword(journeyId, verificationMethod).then(() => {
      track("auth_code_sent", {type: verificationMethod})
      setDidSendCode(true)
    })
  }

  const onChangeVerificationCode = ({target: {value}}) => {
    setOneTimePassword(value)
  }

  const onChangeRememberMe = ({target}) => {
    setRememberMe(target.checked)
  }

  const onClickSubmitVerification = async () => {
    try {
      const token = await verifyOneTimePassword(journeyId, oneTimePassword)

      setAccessToken(storage, token, rememberMe)

      const {team: _team, ...userInfo} = await fetchUserInfo()

      track("auth_succeeded")

      dispatch(setCurrentUser(userInfo))
      if (onLoginSuccess) onLoginSuccess()
    } catch (e) {
      track("auth_failed")
      setDidError(true)
    }
  }

  const email = contact.emailAuthorized ? contact.email : null
  const phoneMobile = contact.smsAuthorized ? contact.phoneMobile : null

  return (
    <div
      className={cx(classes.root, {
        [classes.horizontal]: template.themeBase === "horizontal",
        [classes.isModal]: isModal,
      })}
    >
      <div className={classes.content}>
        <LockIcon className={classes.lock} />
        {didSendCode ? (
          <>
            <Typography variant="h4">
              Verify by {verificationMethod === "email" ? "email" : "phone"}.
            </Typography>
            <div>
              <Typography>
                We've sent you a verification code by{" "}
                {verificationMethod === "email" ? "email" : "text"} to{" "}
                {verificationMethod === "email" ? email : phoneMobile}.
              </Typography>
              <Typography>Please enter it below.</Typography>
            </div>
            <TextField
              autoFocus={true}
              className={classes.verificationCode}
              fullWidth={true}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              label="Verification code"
              onChange={onChangeVerificationCode}
              value={oneTimePassword}
            />
            <div className={classes.rememberMe}>
              <FormControlLabel
                control={
                  <Checkbox color="primary" onChange={onChangeRememberMe} value={rememberMe} />
                }
                label="Remember me"
              />
            </div>
            {rememberMe && (
              <Typography gutterBottom={true} variant="caption">
                Your session will be authorized for up to 14 days of inactivity. This should only be
                checked on trusted devices.
              </Typography>
            )}
            {didError && (
              <Typography className={classes.errorText} variant="caption">
                Something went wrong when verifying your code. Please try again.
              </Typography>
            )}
            <div className={classes.actions}>
              <Button
                color="primary"
                disabled={!oneTimePassword}
                onClick={onClickSubmitVerification}
                variant="contained"
              >
                Submit
              </Button>
              <Button onClick={onClickSendCode}>Resend code</Button>
            </div>
          </>
        ) : (
          <div>
            <Typography className={classes.header} variant="h4">
              This page contains private&nbsp;information.
            </Typography>
            <Typography>
              To unlock and view this information, we need to verify your identity by sending an
              8-digit code to an authorized email or mobile number.
            </Typography>
            {email || phoneMobile ? (
              <>
                <Typography>Please choose from the options below:</Typography>
                <div className={classes.choices}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="Choose how to receive your one-time verification code"
                      onChange={onChangeContactMethod}
                      value={verificationMethod}
                    >
                      {phoneMobile && (
                        <FormControlLabel
                          control={<Radio color={"primary"} />}
                          label={`Text me a code at ${phoneMobile}`}
                          value="phone_mobile"
                        />
                      )}
                      {email && (
                        <FormControlLabel
                          control={<Radio color={"primary"} />}
                          label={`Email me a code at ${email}`}
                          value="email"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={!verificationMethod}
                  onClick={onClickSendCode}
                  variant="contained"
                >
                  Send me the code
                </Button>
              </>
            ) : (
              <>
                <Typography>
                  Unfortunately, we do not have any authorized contact options on file.
                </Typography>
                <Typography>
                  Please contact us for assistance
                  {owner?.email ? (
                    <>
                      {" at "}
                      <Link href={`mailto:${owner.email}`}>{owner.email}</Link>
                      {"."}
                    </>
                  ) : (
                    "."
                  )}
                </Typography>
              </>
            )}
            {contact.phoneMobile && (
              <Typography className={classes.textMessageRatesFinePrint} variant="caption">
                Standard data fees and text messaging rates may&nbsp;apply.
              </Typography>
            )}
          </div>
        )}
      </div>
      {owner && team && !isModal && (
        <JourneyOwnerInfo
          isSidebarOpen={false}
          {...owner}
          name={fullName(owner)}
          options={template.supportOwnerOptions}
          teamName={team.name}
        />
      )}
    </div>
  )
}

ContactLogin.propTypes = {
  contact: object.isRequired,
  isModal: bool,
  journeyId: string.isRequired,
  onLoginSuccess: func,
  template: object.isRequired,
}

export default ContactLogin
