import {InputAdornment, MenuItem, TableCell, TableRow, TextField} from "@mui/material"
import PropTypes from "prop-types"
import {useMemo, useState} from "react"
import {BsDashLg as DashIcon} from "react-icons/bs"

import DOSelect from "components/do-select/do-select"

import {validNonnegativeInteger} from "lib/field-validations"

const CrossSellAdoptionMetricRow = ({drivers, objective, onChange}) => {
  const [benchmarkErrorMessage, setBenchmarkErrorMessage] = useState(null)
  const [valueErrorMessage, setValueErrorMessage] = useState(null)

  const defaultValue = useMemo(() => {
    const defaultValue = drivers.find(d => d.id === objective.driverId)?.value

    return !!defaultValue ? `$${defaultValue}` : null
  }, [drivers, objective])

  const handleBenchmarkChange = ({target: {value: benchmark}}) => {
    const message = validNonnegativeInteger(benchmark)
    const changed = {
      ...objective,
      benchmark: !!message ? benchmark : parseInt(benchmark, 10),
      hasError: !!message || !!valueErrorMessage,
      isDirty: true,
    }

    setBenchmarkErrorMessage(message)

    onChange(changed)
  }

  const handleDriverIdChange = ({target: {value: driverId}}) =>
    onChange({...objective, driverId, isDirty: true})

  const handleValueChange = ({target: {value}}) => {
    const message = validNonnegativeInteger(value)
    const changed = {
      ...objective,
      hasError: !!message || !!benchmarkErrorMessage,
      isDirty: true,
      value: !!message ? value : parseInt(value, 10),
    }

    setValueErrorMessage(message)

    onChange(changed)
  }

  return (
    <TableRow key={objective.name}>
      <TableCell>{objective.name}</TableCell>
      <TableCell>
        <TextField
          error={!!benchmarkErrorMessage}
          helperText={benchmarkErrorMessage || ""}
          id={`objective-${objective.id}-benchmark`}
          inputProps={{style: {textAlign: "right"}}}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          name="benchmark"
          onChange={handleBenchmarkChange}
          type="text"
          style={{width: 60}}
          value={objective.benchmark === 0 ? 0 : objective.benchmark || ""}
        />
      </TableCell>
      <TableCell>
        {objective.key !== "card-on-file" && (
          <DOSelect
            id={`objective-${objective.id}-driverId`}
            displayEmpty={true}
            name="driverId"
            onChange={handleDriverIdChange}
            renderValue={value => drivers.find(({id}) => id === value)?.name ?? "Other"}
            style={{width: 245}}
            value={objective.driverId || ""}
          >
            {drivers.map(({id, name}) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
            <MenuItem value="">Other</MenuItem>
          </DOSelect>
        )}
      </TableCell>
      <TableCell>
        {objective.key !== "card-on-file" && (
          <TextField
            error={!!valueErrorMessage}
            helperText={valueErrorMessage || ""}
            id={`objective-${objective.id}-value`}
            inputProps={{style: {textAlign: "left"}}}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            name="value"
            onChange={handleValueChange}
            type="text"
            style={{width: 70}}
            value={objective.value === 0 ? 0 : objective.value || ""}
          />
        )}
      </TableCell>
      <TableCell style={{textAlign: "center"}}>
        {defaultValue ? defaultValue : <DashIcon color="#000000" size={20} />}
      </TableCell>
    </TableRow>
  )
}

CrossSellAdoptionMetricRow.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string, name: PropTypes.string}))
    .isRequired,
  objective: PropTypes.shape({
    benchmark: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    driverId: PropTypes.string,
    hasError: PropTypes.bool,
    id: PropTypes.string,
    isDirty: PropTypes.bool,
    key: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CrossSellAdoptionMetricRow
