export const passwordPolicyTextMap = {
  requireLowerCase: "LOWER_CASE_REQUIRED",
  minimum: "MINIMUM_CHAR",
  requireNonAlphanum: "MUST_CONTAIN_SPECIAL_CHAR",
  requireNumber: "MUST_CONTAIN_NUMBER",
  requireUpperCase: "UPPER_CASE_REQUIRED",
}

export const passwordHelperTextMap = (policyKey, options = {}) => {
  switch (policyKey) {
    case "LOWER_CASE_REQUIRED":
      return "Password must contain at least one lower case character."
    case "MINIMUM_CHAR":
      return `Password must be at least ${options.minimum} characters.`
    case "MUST_CONTAIN_NUMBER":
      return "Password must contain at least one number."
    case "MUST_CONTAIN_SPECIAL_CHAR":
      return "Password must contain at least one special character."
    case "UPPER_CASE_REQUIRED":
      return "Password must contain at least one upper case character."
    default:
      return ""
  }
}

export const isPasswordPassingValidation = (policy, currentPassword, options = {}) => {
  switch (policy) {
    case "LOWER_CASE_REQUIRED":
      return currentPassword.toUpperCase() !== currentPassword
    case "MINIMUM_CHAR":
      return currentPassword.length >= options.minimum
    case "MUST_CONTAIN_NUMBER":
      return !!currentPassword.match(/[0-9]/)
    case "MUST_CONTAIN_SPECIAL_CHAR":
      const specialChars = currentPassword.normalize("NFD").replace(/\w*/u, "")
      return specialChars.length > 0
    case "UPPER_CASE_REQUIRED":
      return currentPassword.toLowerCase() !== currentPassword
    default:
      return ""
  }
}

export const validPassword = passwordPolicy => val => {
  const errors = []

  if (!val) return null

  if (val.length < passwordPolicy.minimum)
    errors.push(
      passwordHelperTextMap(passwordPolicyTextMap.minimum, {minimum: passwordPolicy.minimum})
    )

  if (passwordPolicy.requireUpperCase && val.toLowerCase() === val)
    errors.push(passwordHelperTextMap(passwordPolicyTextMap.requireUpperCase))

  if (passwordPolicy.requireLowerCase && val.toUpperCase() === val)
    errors.push(passwordHelperTextMap(passwordPolicyTextMap.requireLowerCase))

  if (passwordPolicy.requireNumber && !val.match(/[0-9]/))
    errors.push(passwordHelperTextMap(passwordPolicyTextMap.requireNumber))

  if (passwordPolicy.requireNonAlphanum) {
    const specialChars = val.normalize("NFD").replace(/\w*/u, "")

    if (specialChars.length === 0)
      errors.push(passwordHelperTextMap(passwordPolicyTextMap.requireNonAlphanum))
  }

  return errors
}

export const matchesPassword = (val, allValues) =>
  val && val !== allValues.password.value ? "Must match Password" : null
