import {ContentBlockEditorProvider} from "components/content-block-editor/content-block-editor-context"
import {JourneyContextProvider} from "components/journeys/journey-context"

import CampaignPackage from "./campaign-package"

const CampaignPackageContainer = () => (
  <JourneyContextProvider>
    <ContentBlockEditorProvider>
      <CampaignPackage />
    </ContentBlockEditorProvider>
  </JourneyContextProvider>
)

export default CampaignPackageContainer
