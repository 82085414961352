import {TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, bool, exact, number, object, string} from "prop-types"
import {useEffect, useState} from "react"
import {FaEye as PreviewIcon} from "react-icons/fa"

import {Preview} from "../content-library/content-preview"
import DOTable, {noWrapCellClass, numberCellClass} from "../table/table"
import {Types, formatValue} from "./insights-helpers"

const commonColumns = [
  {
    name: "contentName",
    label: "Page Name",
    type: Types.Text,
    tooltipText: "The internal name of the page",
  },
  {
    name: "objectiveName",
    label: "Objective",
    type: Types.Text,
    tooltipText: "The objective associated with the page",
  },
  {
    name: "completionCondition",
    label: "Completion Condition",
    type: Types.CompletionCondition,
    tooltipText: "The page completion condition for that page",
  },
]

const percentageUnitColumns = [
  {
    name: "viewRate",
    label: "Unique Views\xa0%",
    type: Types.Percentage,
    tooltipText: "= total number of unique views / total number of journeys",
  },
  {
    name: "pageCompletionRate",
    label: "Pages Completion\xa0%",
    type: Types.Percentage,
    tooltipText: "= total number of page completions / total number of journeys",
  },
  {
    name: "objectiveCompletionRate",
    label: "Objectives Completion\xa0%",
    type: Types.Percentage,
    tooltipText: "= objective completions in this campaign / total number of journeys created",
  },
  {
    name: "benchmark",
    label: "Benchmark",
    type: Types.Percentage,
    tooltipText: "Benchmark completion % from template editor settings",
  },
]

const totalUnitColumns = [
  {
    name: "uniqueViews",
    label: "Unique Views",
    type: Types.Number,
    tooltipText: "The number of contacts who viewed that page (count each person once)",
  },
  {
    name: "pageCompletions",
    label: "Pages Completed",
    type: Types.Number,
    tooltipText: "Number of contacts who completed that page",
  },
  {
    name: "objectivesCompleted",
    label: "Objectives Completed",
    type: Types.Number,
    tooltipText:
      "The number of journeys with completed objectives for this page (objective completions that occurred with activity from this journey as the last activity)",
  },
]

const isNumberCell = type =>
  [Types.Number, Types.Percentage, Types.RelativePercentage].includes(type)

const PageDetails = ({isLoaded, pageDetails, unit}) => {
  const classes = useStyles()
  const [filteredColumns, setFilteredColumns] = useState([])

  useEffect(() => {
    setFilteredColumns(
      unit === "Total"
        ? [...commonColumns, ...totalUnitColumns]
        : [...commonColumns, ...percentageUnitColumns]
    )
  }, [unit])

  if (!isLoaded) return "Loading..."

  if (!pageDetails.length)
    return (
      <>
        <Typography className={classes.header} variant="h5">
          Page Details
        </Typography>
        <div>No pages are set for this campaign.</div>
      </>
    )

  const headers = filteredColumns.map(column => ({
    field: column.name,
    label: column.label,
    sortable: false,
    classes: isNumberCell(column.type) ? numberCellClass : null,
    tooltipText: column.tooltipText,
  }))

  return (
    <>
      <Typography className={classes.header} variant="h5">
        Page Details
      </Typography>
      <DOTable headers={headers} paginationEnabled={false} rows={pageDetails} variant={"light"}>
        {row => <PageDetailsRow key={row.id} filteredColumns={filteredColumns} pageDetails={row} />}
      </DOTable>
    </>
  )
}

const typeToProptype = type => {
  switch (type) {
    case Types.Text:
    case Types.CompletionCondition:
      return string
    default:
      return number
  }
}

export const PageDetailsPropType = exact({
  id: string.isRequired,
  objectiveId: string,
  ...[...commonColumns, ...percentageUnitColumns, ...totalUnitColumns].reduce(
    (acc, cur) => ({
      ...acc,
      [cur.name]: typeToProptype(cur.type),
    }),
    {}
  ),
  performanceVsBenchmark: number,
})

PageDetails.propTypes = {
  isLoaded: bool.isRequired,
  pageDetails: arrayOf(PageDetailsPropType),
  unit: string.isRequired,
}

const PageDetailsRow = ({filteredColumns, pageDetails}) => {
  const classes = useStyles()

  return (
    <TableRow>
      {filteredColumns.map(column =>
        column.name === "contentName" ? (
          <TableCell
            className={isNumberCell(column.type) ? numberCellClass : noWrapCellClass}
            key={column.name}
          >
            <div className={classes.previewContainer}>
              <span className={classes.previewableName}>
                {formatValue(pageDetails[column.name], column.type)}
              </span>
              <Tooltip
                classes={{tooltip: classes.previewTooltip}}
                placement="right"
                title={
                  <div className={classes.preview}>
                    <Preview
                      bodyPreview={pageDetails[column.name]}
                      contentType="page"
                      id={pageDetails.id}
                    />
                  </div>
                }
              >
                <span className={classes.previewEye}>
                  <PreviewIcon />
                </span>
              </Tooltip>
            </div>
          </TableCell>
        ) : (
          <TableCell className={noWrapCellClass} key={column.name}>
            {formatValue(pageDetails[column.name], column.type)}
          </TableCell>
        )
      )}
    </TableRow>
  )
}

PageDetailsRow.propTypes = {
  filteredColumns: arrayOf(object),
  pageDetails: PageDetailsPropType,
}

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginTop: 80,
  },
  previewTooltip: {
    maxWidth: "none",
  },
  preview: {
    width: 306,
  },
  previewContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  previewableName: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  previewEye: {
    marginLeft: 10,
  },
}))

export default PageDetails
