import {Button, FormControl, FormControlLabel, Tooltip, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object, string} from "prop-types"
import {FiMap as MapIcon} from "react-icons/fi"
import {MdContentCopy as CopyIcon} from "react-icons/md"
import {Field, reduxForm} from "redux-form"

import {RFCheckbox, RFTextField} from "components/mui-redux-form/mui-redux-form"

import {requiredField} from "lib/field-validations"

import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"

const NewTemplate = ({classes, handleSubmit, submitting, sourceTemplateName}) => (
  <div className="new-template">
    <DocumentTitle title="Create Template" />
    <TitleBar
      backButtonAttrs={{to: "/admin/templates", children: "Return to Campaigns Dashboard"}}
      title="Setting up your campaign..."
    />

    <div className={classes.container}>
      {sourceTemplateName && (
        <Padded className={classes.noticePadded}>
          <Box classes={{root: classes.noticeBox}}>
            <Typography>
              <CopyIcon className={classes.noticeIcon} />
              This campaign is being duplicated from the <strong>{sourceTemplateName}</strong>{" "}
              template
            </Typography>
          </Box>
        </Padded>
      )}

      <Padded>
        <Box>
          <form className="form" onSubmit={handleSubmit}>
            <FormControl fullWidth={true} margin="normal">
              <Field
                autoFocus={true}
                className="name"
                component={RFTextField}
                id="new-template-name"
                label="New Template Name"
                name="name"
                validate={requiredField}
              />
            </FormControl>

            <FormControl fullWidth={true} margin="normal">
              <Tooltip title="If left unchecked, the template will be duplicated, but the messages/pages will be shared between the old and new templates.">
                <FormControlLabel
                  control={<Field name="duplicateContent" component={RFCheckbox} checked={true} />}
                  label="Duplicate All Content"
                />
              </Tooltip>
            </FormControl>

            <div className={classes.formActions}>
              <Button
                className="submit"
                color="primary"
                disabled={submitting}
                type="submit"
                variant="contained"
              >
                <MapIcon className={classes.mapIcon} />
                {submitting ? "Creating" : "Create"} Campaign
              </Button>
            </div>
          </form>
        </Box>
      </Padded>
    </div>
  </div>
)

const styles = theme => ({
  noticePadded: {
    paddingBottom: 0,
  },
  noticeBox: {
    backgroundColor: "#EBF0F6",
    color: "gray",
  },
  noticeIcon: {
    position: "relative",
    top: 2,
    marginRight: 5,
  },
  sourceTemplateName: {
    display: "inline",
    fontWeight: "bold",
    padding: "0 6px",
  },
  container: {
    maxWidth: 700,
    margin: "0 auto",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
  },
  mapIcon: {
    marginRight: theme.spacing(1),
  },
})

NewTemplate.propTypes = {
  classes: object.isRequired,
  handleSubmit: func.isRequired,
  sourceTemplateName: string,
  submitting: bool,
}

export default withStyles(styles)(reduxForm({form: "new-template"})(NewTemplate))
