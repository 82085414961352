import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {bool, func, object} from "prop-types"
import {forwardRef} from "react"
import {MdTrendingUp as CtaIcon} from "react-icons/md"
import shortid from "shortid"

const CtaButton = forwardRef(({classes, onClose, isEmail, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {
        text: "CTA",
        ...(isEmail
          ? {
              linksTo: "journey-page",
              linkUrl: null,
            }
          : {
              linksTo: "url",
              linkUrl: "http://digitalonboarding.com",
            }),
      },
      type: "cta",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <CtaIcon />
      </ListItemIcon>
      <ListItemText primary="Call to Action" ref={ref} />
    </MenuItem>
  )
})

CtaButton.propTypes = {
  isEmail: bool.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func,
  classes: object.isRequired,
}

export {CtaIcon}

export default CtaButton
