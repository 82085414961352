import {FormControl, InputLabel, ListItemText, MenuItem, Typography} from "@mui/material"
import cx from "classnames"
import {array, bool, func, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import DOSelect from "components/do-select/do-select"
import {useTemplateTargeting} from "components/template-targeting/template-targeting-context"

import {fetchDirectDepositEmployers} from "lib/api"

const DirectDepositTargetingCondition = ({
  classes,
  disabled = false,
  onChange,
  operators,
  targetingCondition,
}) => {
  const {updateTargetingCondition} = useTemplateTargeting()
  const [directDepositEmployers, setDirectDepositEmployers] = useState([])
  const {id, meta, targetingGroupId, operator} = targetingCondition
  const distributionTypeOptions = [
    {name: "Percentage", value: "percentage"},
    {name: "Total", value: "total"},
  ]
  const reasonOptions = [
    {name: "Account Lockout", value: "account-lockout"},
    {name: "Account Setup Incomplete", value: "account-setup-incomplete"},
    {name: "Bad Credentials", value: "bad-credentials"},
    {name: "Connection Error", value: "connection-error"},
    {name: "Distribution not Supported", value: "distribution-not-supported"},
    {name: "Enrolled in Paycard", value: "enrolled-in-paycard"},
    {name: "Product Not Supported", value: "product-not-supported"},
    {name: "Routing Number not Supported", value: "routing-number-not-supported"},
    {name: "Session Timeout", value: "session-timeout"},
    {name: "System Unavailable", value: "system-unavailable"},
    {name: "Unknown Failure", value: "unknown-failure"},
    {name: "User Abandon", value: "user-abandon"},
  ]

  useEffect(() => {
    fetchDirectDepositEmployers().then(employers => {
      setDirectDepositEmployers(employers)
    })
  }, [setDirectDepositEmployers])

  return (
    <>
      <FormControl classes={{root: classes.fieldSelect}}>
        {<InputLabel htmlFor="journey-value">Action</InputLabel>}
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          onChange={onChange}
          name="operator"
          renderValue={value => operators.find(o => o.value === value)?.name}
          value={operator}
        >
          {operators.map(({name, value}) => (
            <MenuItem key={value} value={value}>
              <ListItemText
                classes={{secondary: classes.listItemTextSecondary}}
                primary={
                  <Typography variant="inherit" noWrap>
                    {name}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>

      {["is_completed_direct_deposit_employer", "is_failed_direct_deposit_employer"].includes(
        operator
      ) && (
        <>
          <span className={classes.in}>with</span>
          <FormControl classes={{root: classes.fieldSelect}}>
            {
              <InputLabel error={!meta?.["employer"]} htmlFor="journey-employer">
                Employer
              </InputLabel>
            }
            <DOSelect
              className={cx(classes.labelLessInput, classes.inputField)}
              disabled={disabled}
              data-testid="journey-employer"
              error={!meta?.["employer"]}
              helperText={!meta?.["employer"] ? "required, select an employer" : ""}
              id="journey-employer"
              onChange={({target}) => {
                updateTargetingCondition({
                  id,
                  targetingGroupId,
                  meta: {employer: target.value},
                })
              }}
              name="meta.employerName"
              required={true}
              value={meta?.["employer"] ?? ""}
            >
              {directDepositEmployers.map(name => (
                <MenuItem key={name} value={name}>
                  <Typography variant="inherit" noWrap>
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
        </>
      )}

      {operator === "is_failed_direct_deposit_reason" && (
        <>
          <span className={classes.in}>with</span>
          <FormControl classes={{root: classes.fieldSelect}}>
            {
              <InputLabel error={!meta?.["failedReason"]} htmlFor="journey-reason">
                Reason
              </InputLabel>
            }
            <DOSelect
              className={cx(classes.labelLessInput, classes.inputField)}
              data-testid="journey-reason"
              disabled={disabled}
              error={!meta?.["reason"]}
              helperText={!meta?.["reason"] ? "required, select a failure reason" : ""}
              id="journey-reason"
              onChange={({target}) => {
                updateTargetingCondition({
                  id,
                  targetingGroupId,
                  meta: {reason: target.value},
                })
              }}
              name="meta.reason"
              value={meta?.["reason"] ?? ""}
            >
              {reasonOptions.map(({value, name}) => (
                <MenuItem key={value} value={value}>
                  <Typography variant="inherit" noWrap>
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
        </>
      )}

      {operator === "is_direct_deposit_distribution_type" && (
        <>
          <span className={classes.in}>with</span>
          <FormControl classes={{root: classes.fieldSelect}}>
            {
              <InputLabel error={!meta?.["distributionType"]} htmlFor="journey-distribution-type">
                Distribution Type
              </InputLabel>
            }
            <DOSelect
              className={cx(classes.labelLessInput, classes.inputField)}
              data-testid="journey-distribution-type"
              disabled={disabled}
              error={!meta?.["distributionType"]}
              helperText={!meta?.["distributionType"] ? "required, select a distribution type" : ""}
              id="journey-distribution-type"
              onChange={({target}) => {
                updateTargetingCondition({
                  id,
                  targetingGroupId,
                  meta: {distributionType: target.value},
                })
              }}
              name="meta.distributionType"
              value={meta?.["distributionType"] ?? ""}
            >
              {distributionTypeOptions.map(({value, name}) => (
                <MenuItem key={value} value={value}>
                  <Typography variant="inherit" noWrap>
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
        </>
      )}
    </>
  )
}

DirectDepositTargetingCondition.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  onChange: func.isRequired,
  operators: array.isRequired,
  targetingCondition: shape({
    id: string,
    model: string,
    field: string,
    meta: object,
    meta_sub_key: string,
    operator: string,
    value: string,
  }).isRequired,
}

export default DirectDepositTargetingCondition
