export default {
  PREPAID_ACCOUNT: "Prepaid-only merchant account",
  INACTIVE_ACCOUNT: "Inactive user account",
  INVALID_CARD: "Invalid card",
  INVALID_ADDRESS: "Invalid address",
  PASSWORD_RESET_REQUIRED: "Password reset required",
  BUNDLED_SUBSCRIPTION: "Billed through other merchant",
  FREE_ACCOUNT: "Free account",
  ACCOUNT_LOCKED: "Account locked",
  DUPLICATE_CARD: "Card already on file",
  EXPIRED_CARD: "Expired card",
  INVALID_CVV: "Invalid CVV",
  INVALID_NETWORK: "Invalid card type",
  MAX_LIMIT_OF_STORED_CARDS: "Too many stored cards",
  TIMEOUT_CREDENTIALS: "User failed to input credentials",
  TIMEOUT_TFA: "User failed to input TFA code",
  TOO_MANY_LOGIN_FAILURES: "Too many login attempts",
  TOO_MANY_TFA_FAILURES: "Too many failed TFA codes",
  UNSUCCESSFUL: "Card placement failed",
  TIMEOUT_CAPTCHA: "Captcha timeout",
  NETWORK_ISSUE: "Unable to connecto to merchant",
  PROXY_PROBE_FAILED: "General failure",
  VBS_TIMEOUT: "General failure",
  VBS_ERROR: "General failure",
  CANCELLED_QUICKSTART: "No credentials input",
  ABANDONED_QUICKSTART: "No credentials input",
  CANCELLED: "Cancelled by user",
  ABANDONED: "Abandoned by user",
}
