import {Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {useEffect} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"
import StatsPie from "components/template-insights/stats-pie"

import {fetchAdvancedInsightsCampaignEnrollment} from "lib/api"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])
// Source: https://stackoverflow.com/a/48456512
colors.unshift(colors.splice(3, 1)[0])

const CampaignEnrollment = () => {
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    campaignEnrollment,
    campaignEnrollmentIsLoading,
  } = useAdvancedTeamInsights()

  const stats = campaignEnrollment?.stats ?? []
  const totalContacts = campaignEnrollment?.totalContacts ?? 0

  useEffect(() => {
    if (hasSocket)
      fetchInsights("campaignEnrollment", fetchAdvancedInsightsCampaignEnrollment, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  return (
    <>
      <Typography variant="h5">Active Campaign Enrollment</Typography>
      {campaignEnrollmentIsLoading ? (
        <Skeleton variant="circle" width={150} height={150} style={{marginTop: 50}} />
      ) : totalContacts > 0 ? (
        <StatsPie
          colors={colors}
          data={stats}
          legendTitle="Number of contacts enrolled"
          total={totalContacts}
          totalLabel="Contacts"
          totalLabelTooltip="Number of contacts on site"
        />
      ) : (
        <div>No data available</div>
      )}
    </>
  )
}

export default CampaignEnrollment
