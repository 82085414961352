import {APP_MESSAGE, DISMISS_APP_MESSAGE} from "../actions/app-actions"

function app(state = {}, action) {
  switch (action.type) {
    case APP_MESSAGE:
      return {
        ...state,
        appMessage: action.payload,
      }

    case DISMISS_APP_MESSAGE:
      return {
        ...state,
        appMessage: undefined,
      }

    default:
      return state
  }
}

export default app
