import {LinearProgress, Typography} from "@mui/material"
import chunkArray from "lodash/chunk"
import Papa from "papaparse"
import {func, number, object, string} from "prop-types"
import {Component} from "react"
import {connect} from "react-redux"
import shortid from "shortid"

import {createEvent, scheduleBatchUpload} from "lib/api"

const CHUNK_SIZE = 2000

export function* batchProcessor(
  totalNumLines,
  step = () => {},
  transform = t => t,
  batchOperationAttrs = {}
) {
  let linesSeen = 0
  let linesProcessed = 0
  let chunks = []
  let batchOperationIds = []

  const onCreateBatch = ({id}, records) => {
    batchOperationIds = [...batchOperationIds, id]
    linesProcessed += records.length
    step(linesProcessed)
  }

  while (linesProcessed < totalNumLines) {
    // While we're still processing chunks of data, we take the chunks in as arguments to `next()`

    const chunk = yield

    if (Array.isArray(chunk)) {
      linesSeen += chunk.length
      chunks = [...chunks, ...chunk]

      if (chunks.length > CHUNK_SIZE || linesSeen === totalNumLines) {
        for (const records of chunkArray(chunks, CHUNK_SIZE))
          scheduleBatchUpload({
            ...batchOperationAttrs,
            records: records.map(transform),
          }).then(batchOperation => {
            onCreateBatch(batchOperation, records)
          })

        chunks = []
      }
    }
  }
  // Once we're all done, we just let our generator continously yield out the operation ids
  while (true) yield batchOperationIds
}

const batchAndUpload = async (
  file,
  batchId,
  totalNumLines,
  step,
  additionalRowAttrs,
  additionalOperationAttrs
) => {
  const transform = r => ({...r, ...additionalRowAttrs})

  const totalNumBatches = Math.ceil(Math.max(1, totalNumLines / CHUNK_SIZE))

  const worker = batchProcessor(totalNumLines, step, transform, {
    ...additionalOperationAttrs,
    batch_id: batchId,
    total_num_batches: totalNumBatches,
  })
  let batchOperationIds = []

  Papa.parse(file, {
    header: true,
    trimHeaders: true,
    skipEmptyLines: "greedy",
    transform: data => data.trim(),
    beforeFirstChunk: () => {
      // prime the generator before we start yielding into it
      worker.next()
    },
    chunk: chunk => {
      worker.next(chunk.data)
    },
  })

  while (batchOperationIds.length < totalNumBatches) {
    const {value} = worker.next()

    if (value) batchOperationIds = value

    await new Promise(resolve => setTimeout(resolve, 1000))
  }

  return {batchOperationIds, batchId}
}

class Batch extends Component {
  state = {
    numLinesProcessed: 0,
  }

  componentDidMount() {
    const {
      batchType,
      file,
      onComplete,
      fieldMap,
      additionalAttrs,
      totalNumLines,
      userId,
    } = this.props

    const batchId = shortid.generate()

    return createEvent({
      action: "user/file-processed",
      userId,
      batchId,
      meta: {
        originalFilename: file.name,
        batchId,
        totalRowsInCsv: totalNumLines,
        type: batchType,
      },
    })
      .then(() =>
        batchAndUpload(file, batchId, totalNumLines, this.step, additionalAttrs, {
          field_map: fieldMap,
          type: batchType,
          source: "admin-console",
        })
      )
      .then(result => {
        onComplete(result)
      })
  }

  step = numLines => {
    this.setState({numLinesProcessed: numLines})
  }

  render() {
    const {totalNumLines} = this.props
    const {numLinesProcessed} = this.state

    return (
      <>
        <Typography>Uploading your file..</Typography>
        <Typography variant="caption">Please stay on this page.</Typography>
        <LinearProgress value={(numLinesProcessed / totalNumLines) * 100} variant="determinate" />
      </>
    )
  }
}

Batch.propTypes = {
  additionalAttrs: object,
  batchType: string.isRequired,
  fieldMap: object.isRequired,
  file: object.isRequired,
  onComplete: func.isRequired,
  totalNumLines: number.isRequired,
  userId: string,
}

const mapStateToProps = state => ({userId: state.session?.user?.id})

export default connect(mapStateToProps)(Batch)
