import omit from "lodash/omit"
import {connect} from "react-redux"

import {isLoaded} from "lib/loading-states"

import {getUsers} from "../../actions/session-actions"
import {
  LANDING_PAGE_SET,
  clearLandingPage,
  getLandingPage,
  submitLandingPage,
} from "../landing-page/landing-page-actions"
import {getTemplate, onDeleteTemplate, onUpdateTemplate} from "../templates/template-actions"
import TemplateManagement from "./template-management"

const mapStateToProps = (
  {session, landingPage, template, loadingStates},
  {
    match: {
      params: {templateId},
    },
  }
) => ({
  currentUser: session.user,
  landingPage,
  template,
  templateId,
  isLandingPageLoaded: isLoaded(LANDING_PAGE_SET, loadingStates),
  users: session.teamUsers,
  // Used by TemplateManagement formify()
  initialValues: omit(template, ["footerContent"]),
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: {templateId},
    },
  }
) => ({
  clearLandingPage: () => dispatch(clearLandingPage()),
  onDeleteTemplate: () => dispatch(onDeleteTemplate(templateId)),
  onMount: () => dispatch(getTemplate(templateId, {withFacts: true})),
  getLandingPage: () => dispatch(getLandingPage(templateId)),
  onSubmitLandingPage: values => dispatch(submitLandingPage(templateId, values)),
  getUsers: () => dispatch(getUsers()),
  // Used by TemplateManagement formify()
  onSubmit: attrs => dispatch(onUpdateTemplate(templateId, attrs)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagement)
