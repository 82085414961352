import camelCase from "lodash/camelCase"
import snakeCase from "lodash/snakeCase"

const iterator = (obj, path, converter, opts = {}) => {
  if (!obj || typeof obj !== "object") return obj

  const next = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    const value = obj[key]
    const nextPath = !path ? key : `${path}.${key}`
    const nextKey = converter(key)

    // prettier-ignore
    if (opts.ignoreKeys && shouldIgnore(key, opts.ignoreKeys))
      next[key] = value
    else if (opts.ignorePaths && shouldIgnore(nextPath, opts.ignorePaths))
      next[key] = value
    else if (typeof value === "object")
      next[nextKey] = iterator(value, nextPath, converter, opts)
    else
      next[nextKey] = value
  }

  return next
}

const shouldIgnore = (source, rejections) =>
  rejections.some(
    rejection => rejection === source || (rejection instanceof RegExp && rejection.test(source))
  )

export const toSnakeCase = (obj, opts) => iterator(obj, "", snakeCase, opts)
export const toCamelCase = (obj, opts) => iterator(obj, "", camelCase, opts)
