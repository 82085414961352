import {
  ClickAwayListener,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from "@mui/material"
import {styled} from "@mui/material/styles"
import cx from "classnames"
import {func, object, shape, string} from "prop-types"
import {useState} from "react"
import {
  MdAddCircleOutline as AddIcon,
  MdDelete as DeleteIcon,
  MdInfoOutline as InfoIcon,
  MdStar,
  MdStarBorder as UnfavoritedIcon,
} from "react-icons/md"

import {formatDateTime} from "lib/date-time-formatters"

import DeleteFileDialog from "../dialogs/delete-file-dialog"
import MuiIcon from "../mui-icon"
import FileIcon from "./file-browser-icon"
import FileRelatedContent from "./file-related-content"

const Item = styled(ImageListItem)({
  backgroundColor: "#fbfbfb",
  overflow: "visible",

  "& .hover-controls": {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 5,
    right: 5,
  },

  "& .hoverControl": {
    visibility: "hidden",
    color: "#fff",
    cursor: "pointer",
    padding: 7,
    borderRadius: "50%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    height: 30,
    width: 30,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.58)",
    },
  },

  "&:hover .hover-controls .hoverControl": {
    visibility: "visible",
  },

  "& .favorite": {
    position: "absolute",
    top: 5,
    right: 5,
  },
  "& .clearFavorite": {
    color: "transparent",
    backgroundColor: "transparent",
    height: 30,
    width: 30,
  },
})

const FavoritedIcon = styled(MdStar)({
  display: "inline-block",
  color: "#fff",
  cursor: "pointer",
  padding: 7,
  borderRadius: "50%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  height: 30,
  width: 30,
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
  },
})

const FileIconContainer = styled("div")({
  width: "100%",
  height: "100%",
  textAlign: "center",
  overflow: "hidden",
})

/**
 * `style` - passed down from `Grid` (and/or` AutoResizer`?) and includes:
 *    - width, height, top, left, position: "absolute"
 */
const FileBrowserFile = ({variant, file, onDelete, onSelect, onUpdate, style}) => {
  const {id, type, insertedAt, isFavorited, name} = file
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  const handleDelete = () => onDelete(file)
  const handleSelect = () => onSelect(file)
  const handleUpdate = () => onUpdate(file)
  const onOpenDeleteDialog = () => setDeleteDialogOpen(true)
  const onCloseDeleteDialog = () => setDeleteDialogOpen(false)
  const onToggleInfoTooltip = () => setTooltipOpen(!isTooltipOpen)
  const onCloseInfoTooltip = () => setTooltipOpen(false)

  return (
    <Item cols={1} style={style}>
      <span className="hover-controls">
        <div onClick={handleUpdate}>
          <UnfavoritedIcon
            className={cx({
              hoverControl: !isFavorited,
              clearFavorite: isFavorited,
            })}
            size={24}
          />
        </div>
        <ClickAwayListener onClickAway={onCloseInfoTooltip}>
          <div onClick={onToggleInfoTooltip}>
            <Tooltip
              disableFocusListener={true}
              disableHoverListener={true}
              disableTouchListener={true}
              onClose={onCloseInfoTooltip}
              open={isTooltipOpen}
              placement="left-start"
              PopperProps={{
                disablePortal: true,
                style: {width: 275},
              }}
              title={<FileRelatedContent file={file} />}
            >
              <span>
                <InfoIcon className="hoverControl" size={24} />
              </span>
            </Tooltip>
          </div>
        </ClickAwayListener>
        <div onClick={onOpenDeleteDialog}>
          <DeleteIcon className="hoverControl" size={24} />
        </div>
      </span>
      {isFavorited && (
        <div className="favorite" onClick={handleUpdate}>
          <FavoritedIcon size={24} />
        </div>
      )}
      <FileIconContainer>
        <FileIcon id={id} type={type} variant={variant} />
      </FileIconContainer>
      <ImageListItemBar
        actionIcon={
          <IconButton
            style={{color: "rgba(255, 255, 255, 0.54)"}}
            onClick={handleSelect}
            title="Insert File"
            size="medium"
          >
            <MuiIcon icon={<AddIcon />} />
          </IconButton>
        }
        sx={{
          "& .MuiImageListItemBar-title": {fontSize: 16},
          "& .MuiImageListItemBar-subtitle": {fontSize: 13},
        }}
        subtitle={formatDateTime(insertedAt)}
        title={name}
      />
      <DeleteFileDialog
        file={file}
        isOpen={isDeleteDialogOpen}
        onClose={onCloseDeleteDialog}
        onDelete={handleDelete}
      />
    </Item>
  )
}

FileBrowserFile.propTypes = {
  file: shape({
    id: string.isRequired,
    insertedAt: string.isRequired,
    name: string.isRequired,
    type: string.isRequired,
  }).isRequired,
  onDelete: func.isRequired,
  onSelect: func.isRequired,
  onUpdate: func.isRequired,
  style: object,
  variant: string,
}

export default FileBrowserFile
