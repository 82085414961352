import {Link as MuiLink} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {node, object, string} from "prop-types"

import {useContentBlock} from "components/content-block-editor/content-block-context"

import useAnalytics from "lib/hooks/use-analytics"

export const LegacyLink = ({contentState, children, entityKey}) => {
  const {url, target = "_self"} = contentState.getEntity(entityKey).getData()

  return (
    <a href={url} target={target} title={url}>
      {children}
    </a>
  )
}

LegacyLink.propTypes = {
  children: node,
  contentState: object,
  entityKey: string,
}

export const Link = ({contentState, children, entityKey}) => {
  const {url, target = "_self"} = contentState.getEntity(entityKey).getData()
  const {contentBlock} = useContentBlock()
  const {track} = useAnalytics()

  const onClick = () => {
    // Currently only support links in pages, not in template footers
    if (!contentBlock.pageId) {
      return
    }

    // This only supports a flat list (no sub-elements), which I think should be fine for links
    const name = children.map(child => child.props.text).join("")

    track("cta_clicked", {
      pageId: contentBlock.pageId,
      contentBlock: {
        id: contentBlock.id,
        type: "text",
        data: {
          name,
        },
      },
    })
  }

  return (
    <MuiLink color="primary" href={url} onClick={onClick} target={target} title={url}>
      {children}
    </MuiLink>
  )
}

Link.propTypes = {
  children: node,
  contentState: object,
  entityKey: string,
}

const styles = {
  container: {
    lineHeight: 0,

    "& span span": {
      fontSize: "0.6em",
      verticalAlign: "baseline",
    },
  },
}

export const Superscript = withStyles(styles)(({children, classes}) => (
  <sup className={classes.container}>{children}</sup>
))

Superscript.propTypes = {
  children: node,
}

export const Subscript = withStyles(styles)(({children, classes}) => (
  <sub className={classes.container}>{children}</sub>
))

Subscript.propTypes = {
  children: node,
}
