import makeStyles from "@mui/styles/makeStyles"
import {bool, func, object} from "prop-types"
import {useEffect, useMemo, useState} from "react"
import {Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis} from "recharts"

import {COLORS} from "components/template-insights/insights-helpers"

import {fetchTeamInsightsCompletedObjectives} from "lib/api"

import {useObjectives} from "./objectives-context"
import ObjectivesFilter from "./objectives-filter"
import {teamInsightsContext} from "./team-insights-context"

const useStyles = makeStyles(theme => ({
  chartContainer: {
    marginTop: theme.spacing(2),
    maxWidth: "100%",
    overflowX: "scroll",
    overflowY: "hidden",
    position: "relative",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const labelFormatter = value => [value, "# Completed Objectives"]

const CompletedObjectivesBarchart = ({
  completedObjectives,
  fetchInsights,
  filterParams,
  hasSocket,
}) => {
  const classes = useStyles()
  const data = useMemo(() => completedObjectives?.data ?? [], [completedObjectives])
  const [renderedData, setRenderedData] = useState([])
  const [filteredIds, setFilteredIds] = useState([])
  const objectives = useObjectives()

  useEffect(() => {
    if (hasSocket)
      fetchInsights("completedObjectives", fetchTeamInsightsCompletedObjectives, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  useEffect(() => {
    if (filteredIds.length === 0) setRenderedData(data)
    else {
      setRenderedData(data.filter(({id}) => filteredIds.includes(id)))
    }
  }, [filteredIds, data])

  const objectivesWithCompletions = useMemo(
    () => objectives.filter(({id}) => data.find(obj => obj.id === id)),
    [objectives, data]
  )

  const onObjectivesFiltered = objectiveIds => setFilteredIds(objectiveIds)

  return (
    <>
      <div className={classes.filterContainer}>
        <ObjectivesFilter
          id="completed-objectives"
          objectives={objectivesWithCompletions}
          onChange={onObjectivesFiltered}
        />
      </div>
      <div className={classes.chartContainer}>
        <ResponsiveContainer width={renderedData.length * 150 || 300} minHeight={280}>
          <BarChart
            data={renderedData}
            layout="horizontal"
            margin={{top: 15, right: 10, bottom: 5, left: 5}}
          >
            <XAxis dataKey="name" interval={0} type="category" />
            <Tooltip cursor={{fill: "transparent"}} formatter={labelFormatter} />
            <Bar barSize={60} dataKey="value" fill={COLORS[0]}>
              <LabelList dataKey="value" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

CompletedObjectivesBarchart.propTypes = {
  fetchInsights: func.isRequired,
  filterParams: object,
  hasSocket: bool,
  completedObjectives: object,
}

export default teamInsightsContext(CompletedObjectivesBarchart)
