import {FormControl, FormHelperText, InputLabel} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {Editor} from "draft-js"
import {bool, func, number, object, oneOf, string} from "prop-types"
import {PureComponent} from "react"
import * as React from "react"
import ReactDOM from "react-dom"

import EditorContext, {ContentType} from "contexts/editor-context"
import contentBlockEditorBlockRenderMap from "lib/draft-js/block-renderers"

import mainTheme from "../../themes/main"
import pluralize from "../string/pluralize"
import EditorBlock from "./EditorBlock"
import {createEditorStateFromContent} from "./editor-helpers"
import "./editor.css"
import getDecorators from "./entities"
import insertDataBlock from "./insert-data-block"
import Personalization from "./plugins/personalization/personalization-manifest"
import Toolbar from "./toolbar/toolbar"

const plugins = [Personalization]
const pluginRendererMap = {
  [Personalization.type]: Personalization,
}

const findAlignment = (alignment, direction) => {
  switch (alignment) {
    case "start":
      return direction === "ltr" ? "left" : "right"
    case "end":
      return direction === "ltr" ? "right" : "left"
    default:
      return alignment
  }
}

class SimpleEditor extends PureComponent {
  state = {
    focused: false,
    isReadOnly: false,
    editorState: createEditorStateFromContent(this.props.initialValue),
  }

  componentDidMount() {
    if (this.props.autoFocus) this.focus()

    const element = ReactDOM.findDOMNode(this.contentEditorRef.current)
    const style = window.getComputedStyle(element)
    const alignment = style.getPropertyValue("text-align")
    const direction = style.getPropertyValue("direction")

    this.setState({
      defaultAlignment: findAlignment(alignment, direction),
    })
  }

  blockRendererFn = contentBlock => {
    if (contentBlock.getType() !== "atomic") return {component: EditorBlock}

    const handled = pluginRendererMap[contentBlock.getData().get("type")]

    if (handled)
      return {
        component: handled.blockComponent,
        editable: false,
        props: {
          editorState: this.state.editorState,
          getReadOnly: this.getReadOnly,
          setReadOnly: this.setReadOnly,
          onChange: this.onChange,
          viewerMode: this.props.viewerMode,
          focus: this.focus,
        },
      }
  }

  insertDataBlock = data => this.onChange(insertDataBlock(this.state.editorState, data))

  contentEditorRef = this.props.forwardRef || React.createRef()

  isOverMaxLength = (editorState, modifier = 0) => {
    const {maxLength, isOverMaxLength} = this.props

    if (isOverMaxLength) return isOverMaxLength(editorState, modifier)

    if (!maxLength) return false

    return editorState.getCurrentContent().getPlainText().length + modifier > maxLength
  }

  onChange = (editorState, callback) => {
    // This safety check is in here so that plugins will also benefit from the
    // maxLength safety check.
    if (this.isOverMaxLength(editorState)) return

    const oldContentState = this.state.editorState.getCurrentContent()

    this.setState({editorState}, () => {
      if (!editorState.getCurrentContent().equals(oldContentState)) this.props.onChange(editorState)

      if (callback) callback()
    })
  }

  handleBeforeInput = (chars, editorState) => {
    if (this.isOverMaxLength(editorState, chars.length)) return "handled"

    return "not-handled"
  }

  handlePastedText = (chars, html, editorState) => {
    if (this.isOverMaxLength(editorState, chars.length)) return "handled"

    return "not-handled"
  }

  handleReturn = (event, editorState) => {
    if (this.props.multiline && !this.isOverMaxLength(editorState, 1)) return "not-handled"

    return "handled"
  }

  handleOpenDialog = isOpen => (isOpen ? this.blur() : this.focus())

  // This is used by the EditorContext consumers below
  onEditorUpdate = updater => this.onChange(updater(this.state.editorState))

  setReadOnly = isReadOnly => this.setState({isReadOnly})
  getReadOnly = () => this.state.isReadOnly

  editorContext = {
    onEditorUpdate: this.onEditorUpdate,
    setReadOnly: this.setReadOnly,
    ...(this.props.options || {}),
  }

  onFocus = () => {
    this.setState({focused: true})
    if (this.props.onFocus) this.props.onFocus()
  }
  onBlur = () => {
    this.setState({focused: false})
    if (this.props.onBlur) this.props.onBlur()
  }

  blur = () => {
    if (this.state.focused) {
      this.contentEditorRef.current.blur()
      this.setState({focused: false}, () => {
        if (this.props.onBlur) this.props.onBlur()
      })
    }
  }

  focus = () => {
    if (!this.state.focused) {
      this.contentEditorRef.current.focus()
      this.setState({focused: true}, () => {
        if (this.props.onFocus) this.props.onFocus()
      })
    }
  }

  render() {
    const {
      contentType,
      maxLength,
      multiline,
      autoFocus,
      classes,
      className,
      helperText,
      label,
      viewerMode,
      onBlur,
      onFocus,
      options,
      showToolbar,
      smsLength,
      ...editorProps
    } = this.props
    const {defaultAlignment, focused, isReadOnly: stateReadOnly, editorState} = this.state
    const {menuButtonIcon, toolbar, toolbarContainer, toolbarButton} = classes

    const isReadOnly = viewerMode || stateReadOnly

    return (
      <div className={classes.root}>
        <EditorContext.Provider
          value={{
            ...this.editorContext,
            defaultAlignment,
            contentType,
          }}
        >
          <div className={classes.editorWrapper}>
            <FormControl className={cx(classes.formControl, className)}>
              {label && (
                <InputLabel
                  className={cx(classes.label, {[classes.labelFocused]: focused})}
                  focused={focused}
                  shrink={true}
                >
                  {label}
                </InputLabel>
              )}
              <div className={cx(classes.inline, {focused})} onClick={this.focus}>
                <Editor
                  {...editorProps}
                  autoFocus={autoFocus}
                  blockRendererFn={this.blockRendererFn}
                  blockRenderMap={contentBlockEditorBlockRenderMap}
                  decorators={[getDecorators()]}
                  editorState={editorState}
                  handleBeforeInput={this.handleBeforeInput}
                  handlePastedText={this.handlePastedText}
                  handleReturn={this.handleReturn}
                  onBlur={this.onBlur}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  readOnly={isReadOnly}
                  ref={this.contentEditorRef}
                  spellCheck={!isReadOnly}
                  stripPastedStyles={true}
                />
              </div>
            </FormControl>
          </div>

          {!viewerMode && showToolbar !== false && (
            <ThemeProvider theme={mainTheme}>
              <Toolbar
                smsLength={smsLength}
                classes={{menuButtonIcon, toolbar, toolbarContainer, toolbarButton}}
                editorState={editorState}
                handleOpenDialog={this.handleOpenDialog}
                insertDataBlock={this.insertDataBlock}
                onSetEditorState={this.onChange}
                plugins={plugins}
              />
            </ThemeProvider>
          )}

          {contentType === ContentType.Sms && (
            <div className={classes.smsHelper}>
              <FormHelperText sx={{fontWeight: 700}}>
                Your SMS is {smsLength} {pluralize("character", smsLength)} long.
              </FormHelperText>
              <FormHelperText>
                Some carriers and phone models may split long SMS messages in multiple parts,
                instead of a single message. See our{" "}
                <a
                  href="https://support.digitalonboarding.com/a/solutions/articles/48001225874"
                  target="_blank"
                  rel="noreferrer"
                >
                  knowledgebase article
                </a>{" "}
                for further information on when and why a message may be split.
              </FormHelperText>
            </div>
          )}
        </EditorContext.Provider>
      </div>
    )
  }
}

SimpleEditor.defaultProps = {
  multiline: true,
  viewerMode: false,
}

SimpleEditor.propTypes = {
  autoFocus: bool,
  className: string,
  classes: object,
  contentType: oneOf([
    ContentType.Page,
    ContentType.EmailBody,
    ContentType.EmailSubject,
    ContentType.Sms,
    ContentType.TemplateFooter,
  ]).isRequired,
  editorState: object,
  forwardRef: object,
  helperText: string,
  initialValue: object,
  isOverMaxLength: func,
  label: string,
  maxLength: number,
  multiline: bool,
  onBlur: func,
  onChange: func,
  onFocus: func,
  onTab: func,
  options: object,
  readOnly: bool,
  showToolbar: bool,
  smsLength: number,
  viewerMode: bool,
}

const styles = ({palette, spacing, transitions}) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "inherit",
  },
  formControl: {
    width: "100%",
  },
  editorWrapper: {
    position: "relative",
  },
  errorText: {
    marginTop: 10,
    color: palette.error.main,
  },
  label: {
    color: "#5e68718a",
  },
  labelFocused: {
    color: `rgb(0, 114, 178)`,
  },
  menuButtonIcon: {
    minWidth: "33px !important",
  },
  inline: {
    marginBottom: spacing(1),
    marginTop: spacing(3),
    color: palette.text.primary,
    "&::before": {
      left: 0,
      right: 0,
      bottom: 0,
      content: `""`,
      position: "absolute",
      transition: `border-bottom-color ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
      borderBottom: `1px solid rgba(0,0,0,0.42)`,
      pointerEvents: "none",
    },
    "&::after": {
      left: 0,
      right: 0,
      bottom: 0,
      content: `""`,
      position: "absolute",
      transform: "scaleX(0)",
      transition: `transform ${transitions.duration.shorter}ms ${transitions.easing.easeOut}`,
      borderBottom: `2px solid rgb(0,114,178)`,
      pointerEvents: "none",
    },
    "&.focused:after": {
      transform: "scaleX(1)",
    },
  },
  smsHelper: {
    marginTop: spacing(2),
  },
  toolbar: {},
  toolbarButton: {},
  toolbarContainer: {},
})

const StyledEditor = withStyles(styles)(SimpleEditor)

export default React.forwardRef((props, ref) => <StyledEditor {...props} forwardRef={ref} />)
