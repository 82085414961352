import {Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import sortBy from "lodash/sortBy"
import {object} from "prop-types"
import {useEffect, useRef, useState} from "react"

import {getDays, getHours} from "components/journey-duration-input/journey-duration-input"

import {applyStyles} from "lib/draggable"
import pluralize from "lib/string/pluralize"

import {useTemplateContentContext} from "../../contexts/template-content-context"
import MessagePin from "./horizontal-message-pin"

const DEFAULT_MAX_DAY = 30

const getDayPins = timelineMarkers =>
  sortBy(
    timelineMarkers.reduce((acc, marker) => {
      const existingPin = acc.find(m => m.day === marker.day)

      return existingPin
        ? acc.map(m => (m.day === marker.day ? {day: marker.day, pins: [...m.pins, marker]} : m))
        : [...acc, {day: marker.day, pins: [marker]}]
    }, []),
    ["day"]
  )

const stackLength = timelineMarkers =>
  getDayPins(timelineMarkers).reduce(
    (count, {pins}) => (count < pins.length ? pins.length : count),
    0
  )

const getMaxDay = (markers, journeyDurationHours) =>
  Math.max(
    DEFAULT_MAX_DAY,
    Math.ceil(journeyDurationHours / 24), // Use ceil so that we are producing a range inclusive of partial days
    markers.reduce((maxSoFar, {day}) => Math.max(maxSoFar, day), 0) + 10
  )

const MessageScheduler = ({classes, template}) => {
  const [maxDay, setMaxDay] = useState(DEFAULT_MAX_DAY)
  const {
    timelineMarkers,
    createTimelineMarker,
    updateTimelineMarker,
    deleteTimelineMarker,
    timelineMarkersLoading,
  } = useTemplateContentContext()
  const getBounds = () => timelineRef.current && timelineRef.current.getBoundingClientRect()

  const timelineRef = useRef(null)

  const dayToPosition = day => {
    const bounds = getBounds()

    if (!bounds) return {left: 0, opacity: 0}

    // we want the middle of the `div` to be at the position
    const position = (day / (maxDay || 1)) * 100

    return `${position}%`
  }

  const positionToDay = position => {
    const bounds = getBounds()

    if (!bounds) return 0
    const {left, width} = bounds
    const percent = Math.min(position - left, width) / width
    const day = Math.floor(percent * maxDay)

    return Math.max(Math.min(day, maxDay), 0)
  }

  const projectedToPosition = day => {
    const bounds = getBounds()

    if (!bounds) return {opacity: 0}

    return {left: dayToPosition(day, 0)}
  }

  const getStackHeight = stackSize => 95 + 27 * stackSize // padding + pinHeight * stackLength

  const onDragStart = (event, {element, ghost}) => {
    applyStyles(document.body, {cursor: "none"})
    applyStyles(element, {opacity: "0.7"})
    // eslint-disable-next-line no-param-reassign
    ghost.dataset.dayLabel = "Day 0"
    ghost.classList.add("dragging")
  }

  const onDragMove = ({pageX}, {ghost}) => {
    const {top, left, width} = timelineRef.current.getBoundingClientRect()

    const day = positionToDay(pageX)

    const stackSize = getDayPins(timelineMarkers).find(stack => stack.day === day)?.pins.length || 0

    // eslint-disable-next-line no-param-reassign
    ghost.dataset.dayLabel = `Day ${day}`

    const newLeft = (width / maxDay) * day + left
    const styles = {
      top: `${top - getStackHeight(stackSize)}px`,
      left: `${newLeft}px`,
    }

    // the 100 pixel buffer on this is to allow room for the drawer
    if (pageX < left - 100) {
      // if you're more than 30 pixels (plus 100 for the above grace room)
      // outside the drop zone show the max tilt
      const distance = Math.max((left - 100 - pageX) / (left - 100), 0)

      ghost.classList.add("notDroppable")
      styles.transform = `translateX(calc(-50% - ${Math.round(
        distance * 30
      )}px)) rotate(-${Math.round(distance * 30)}deg)`
    } else if (pageX > left + width + 5) {
      const distance = Math.min((pageX - left - width) / 100, 1)

      ghost.classList.add("notDroppable")
      styles.transform = `translateX(calc(-50% + ${distance * 30}px)) rotate(${Math.round(
        distance * 30
      )}deg)`
    } else {
      ghost.classList.remove("notDroppable")
      styles.transform = null
    }

    applyStyles(ghost, styles)
  }

  const onDrop = ({pageX}, {element, ghost, dragData: pin, start}) => {
    const bounds = getBounds()

    applyStyles(document.body, {cursor: "default"})
    applyStyles(element, {opacity: null})

    if (ghost.classList.contains("notDroppable")) {
      // this isn't a perfect alignment with the original element which is why it
      // starts hiding itself with opacity: 0
      applyStyles(ghost, {
        left: `${start.pageX}px`,
        top: `${parseInt(ghost.style.top, 10) + 40}px`,
        opacity: "0",
        transform: null,
      })
      ghost.classList.remove("notDroppable")
      return new Promise(resolve => setTimeout(resolve, 2050))
    }

    applyStyles(ghost, {top: `${(bounds?.top || 0) - 55}px`})

    const day = positionToDay(pageX)

    if (pin.id.match(/^new/)) createTimelineMarker({type: pin.markerType, day, time: "10:00"})
    else updateTimelineMarker({...pin, type: pin.markerType, day})

    return new Promise(resolve => setTimeout(resolve, 150))
  }

  useEffect(() => {
    if (timelineMarkers) setMaxDay(getMaxDay(timelineMarkers, template.journeyDurationHours))
  }, [timelineMarkers, template.journeyDurationHours])

  const days = getDays(template.journeyDurationHours)
  const hours = getHours(template.journeyDurationHours)

  return (
    <div className={classes.root} style={{opacity: timelineMarkersLoading ? 0.5 : 1}}>
      <Typography>
        Messages send according to the schedule and order as set below. Each journey is started on
        "Day 0" and each contact receives messages in the order below. When a contact has completed
        all criteria, they will no longer be eligible to receive that message and will receive the
        next message they're eligible to receive.
      </Typography>
      <div
        className={classes.container}
        style={{height: getStackHeight(stackLength(timelineMarkers))}}
      >
        <div className={classes.pinDrawer}>
          <MessagePin
            dragData={{id: "new-sms", markerType: "sms"}}
            id="new-sms"
            label="New SMS"
            onDragMove={onDragMove}
            onDragStart={onDragStart}
            onDrop={onDrop}
            type="sms"
          />
          <MessagePin
            dragData={{id: "new-email", markerType: "email"}}
            id="new-email"
            label="New Email"
            onDragMove={onDragMove}
            onDragStart={onDragStart}
            onDrop={onDrop}
            type="email"
          />
        </div>
        <div className={classes.timelineContainer}>
          <div className={classes.markers} data-testid="timeline">
            {getDayPins(timelineMarkers).map(
              ({day, pins}, i) =>
                pins.length > 0 && (
                  <div
                    className={classes.stack}
                    key={`${day}.${pins.length}.${i}`}
                    style={{left: dayToPosition(day)}}
                  >
                    <span className={classes.dayLabel}>{day}</span>
                    <div>
                      {pins.map(pin => (
                        <MessagePin
                          day={pin.day}
                          dragData={pin}
                          id={pin.id}
                          key={pin.id}
                          label={pin.time}
                          onDeletePin={deleteTimelineMarker}
                          onDragMove={onDragMove}
                          onDragStart={onDragStart}
                          onDrop={onDrop}
                          onSavePin={updateTimelineMarker}
                          type={pin.type}
                        />
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
          <div className={classes.timeline} ref={timelineRef} />
          <span className={classes.dayAxisLabel}>Day</span>
          {template.journeyDurationHours && (
            <div
              className={classes.deadline}
              style={projectedToPosition(Math.floor(template.journeyDurationHours / 24))}
            >
              <div className={classes.arm} />
              <span>
                {timelineMarkers.find(
                  ({day}) => day === Math.floor(template.journeyDurationHours / 24)
                ) ? null : (
                  <></>
                )}
              </span>
              <span className={classes.deadlineLabel}>
                Projected Completion
                <br />
                {days} {pluralize("day", days)}
                {!!hours && ` ${hours} ${pluralize("hour", hours)}`}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

MessageScheduler.propTypes = {
  classes: object.isRequired,
  template: object.isRequired,
}

const styles = theme => ({
  arm: {
    borderLeftColor: theme.palette.primary.light,
    borderLeftStyle: "dotted",
    borderLeftWidth: 2,
    height: "100%",
  },
  container: {
    display: "flex",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    alignItems: "flex-end",
    minHeight: 150,
  },
  timelineContainer: {
    position: "relative",
    flex: 1,
  },
  pinDrawer: {
    marginRight: theme.spacing(2),
    marginBottom: 40,
    width: 100,
    "& > div:first-child": {
      marginBottom: 26,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dayAxisLabel: {
    left: theme.spacing(-5),
    marginTop: theme.spacing(-1),
    position: "relative",
  },
  dayLabel: {
    bottom: -38,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    left: "50%",
    transform: "translateX(-50%)",
    position: "absolute",
  },
  deadline: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minWidth: 163.48,
    pointerEvents: "none",
    position: "absolute",
    transition: "all ease .1s",
    transform: "translateX(-50%)",
    bottom: -18,
    top: 0,
  },
  deadlineLabel: {
    color: theme.palette.primary.light,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 0.9,
    fontWeight: 700,
    padding: `0 ${theme.spacing(0.5)}`,
    textTransform: "uppercase",
    marginTop: 0,
    marginBottom: theme.spacing(-2.5),
    textAlign: "center",
  },
  markers: {
    position: "relative",
    alignItems: "flex-end",
    display: "flex",
    minHeight: 100,
  },
  root: {
    flex: 1,
    paddingLeft: theme.spacing(4),
  },
  stack: {
    position: "absolute",
    transform: "translateX(-50%)",
    bottom: 13,
  },
  timeline: {
    background: theme.palette.primary.main,
    height: theme.spacing(0.5),
    width: "100%",
  },
})

export default withStyles(styles)(MessageScheduler)
