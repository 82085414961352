// List of fixed or standard devices that may be previewed in the content block editor.
// This is the initial state of the devices property held in state
// via the content block editor reducer. When engagement channel content
// is being edited, an additional device with type "channel" is pushed onto the stack
// for previewing.
const devices = [
  {
    name: "Desktop",
    type: "desktop",
    description: "",
  },
  {
    name: "Galaxy S5",
    type: "mobile",
    height: 640,
    width: 360,
  },
  {
    name: "Pixel 2",
    type: "mobile",
    height: 731,
    width: 411,
  },
  {
    name: "Pixel 2XL",
    type: "mobile",
    height: 823,
    width: 411,
  },
  {
    name: "iPhone 6/7/8",
    type: "mobile",
    height: 667,
    width: 375,
  },
  {
    name: "iPhone 6/7/8 Plus",
    type: "mobile",
    height: 736,
    width: 414,
  },
  {
    name: "iPhone X",
    type: "mobile",
    height: 812,
    width: 375,
  },
  {
    name: "iPad",
    type: "tablet",
    height: 1024,
    width: 768,
  },
  {
    name: "iPad Pro",
    type: "tablet",
    height: 1366,
    width: 1024,
  },
  {
    name: "Surface Duo",
    type: "tablet",
    height: 720,
    width: 540,
  },
  {
    name: "Galaxy Fold",
    type: "mobile",
    height: 653,
    width: 280,
  },
  {
    name: "Custom",
    type: "custom",
    description: "Enter custom screen size",
    height: 812,
    width: 375,
  },
]
export default devices
