import {Button, Checkbox, Dialog, FormControlLabel, TextField, Typography} from "@mui/material"
import {withStyles} from "@mui/styles"
import {arrayOf, bool, func, object, string} from "prop-types"
import {useState} from "react"
import {connect} from "react-redux"

import {sendCampaignForApproval} from "lib/api"

import {TEMPLATE_UPDATED} from "../templates/template-actions"

const CampaignApprovalDialog = ({classes, isOpen, onApprovalSent, onClose, templateId, users}) => {
  const [selectedApproverIds, setSelectedApproverIds] = useState([])
  const [reviewMessage, setReviewMessage] = useState("")

  const handleSelection = e => {
    setSelectedApproverIds(
      e.target.checked
        ? [...selectedApproverIds, e.target.value]
        : selectedApproverIds.filter(id => id !== e.target.value)
    )
  }

  const handleSend = () => {
    sendCampaignForApproval(templateId, {
      approverIds: selectedApproverIds,
      message: reviewMessage,
    }).then(template => {
      onApprovalSent(template)

      // Reset to defaults.
      setSelectedApproverIds([])
      setReviewMessage("")

      onClose()
    })
  }

  return (
    <Dialog
      classes={{paper: classes.paper}}
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
    >
      <Typography variant="h3" className={classes.modalHeader}>
        Send approval to:
      </Typography>
      <Typography variant="body1">
        Approvers will be sent an email with link to review all pages and messages, messages
        schedule, and targeting conditions with the latest impact count. All approvers must approve
        the campaign before it can be launched.
      </Typography>
      {users.length && (
        <div className={classes.approversList}>
          {users
            .filter(user => user.permissions.includes("templates:approve"))
            .map(user => (
              <FormControlLabel
                classes={{label: classes.label, root: classes.labelRoot}}
                control={
                  <Checkbox
                    checked={selectedApproverIds.includes(user.id)}
                    color="primary"
                    onChange={handleSelection}
                    value={user.id}
                  />
                }
                key={user.id}
                label={`${user.nameFirst ?? ""} ${user.nameLast ?? ""}`.trim()}
              />
            ))}
        </div>
      )}
      <TextField
        id="review-message"
        label="Include additional review instructions or notes (optional):"
        multiline
        rows={8}
        value={reviewMessage}
        onChange={({target}) => setReviewMessage(target.value)}
        variant="outlined"
      />
      <div className={classes.buttonGroup}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          disabled={!selectedApproverIds.length}
          variant="contained"
          onClick={handleSend}
        >
          Send
        </Button>
      </div>
    </Dialog>
  )
}

CampaignApprovalDialog.propTypes = {
  classes: object,
  isOpen: bool,
  onApprovalSent: func,
  onClose: func,
  templateId: string,
  users: arrayOf(object),
}

const styles = theme => ({
  approversList: {
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  modalHeader: {
    fontSize: 18,
    fontWeight: "700",
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
  },
})

const mapDispatchToProps = (dispatch, {template}) => ({
  onApprovalSent: updatedTemplate =>
    dispatch({type: TEMPLATE_UPDATED, payload: {...template, ...updatedTemplate}}),
})

export default withStyles(styles)(connect(null, mapDispatchToProps)(CampaignApprovalDialog))
