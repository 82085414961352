import {bool, number, oneOfType, string} from "prop-types"

const HttpStatusPill = ({status, isError}) => {
  const backgroundColor = isError || status >= 400 ? "#FA4A42" : "#5CBDAF"
  return (
    <span
      style={{
        background: backgroundColor,
        color: "#fff",
        fontWeight: "bold",
        padding: "5px 3px",
        borderRadius: 4,
      }}
    >
      {status}
    </span>
  )
}

HttpStatusPill.propTypes = {
  status: oneOfType([number, string]).isRequired,
  isError: bool,
}

export default HttpStatusPill
