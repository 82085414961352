import {
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import last from "lodash/last"
import {bool, func, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"
import {MdCheckCircle as CheckCircleIcon} from "react-icons/md"

import MuiIcon from "components/mui-icon"
import SaveButton from "components/save-button/save-button"

import {fetchActions} from "lib/api"
import useAnalytics from "lib/hooks/use-analytics"
import useForm from "lib/hooks/use-form"
import storage from "lib/storage"

import {contentBlockPropType} from "../../content-block-editor-prop-types"
import {buttonColors} from "../helpers/button-colors"

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  confirmationIcon: {
    display: "block",
    padding: "20px 0 50px",
    color: theme.palette.primary.main,
  },
  confirmationMessage: {
    textAlign: "center",
  },
  radioFormWrapper: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  fullNameInput: {
    marginTop: 0,
    width: 280,
  },
  fullNameWrapper: {
    display: "flex",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
  documentWrapper: {
    marginTop: theme.spacing(2),
  },
  documentText: {
    marginRight: theme.spacing(2),
  },
  incompleteChip: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}))

const EnrollmentForm = ({
  contact,
  onSubmit,
  data,
  contentBlock,
  journeyId,
  isEditMode,
  pageId,
  selectedBlockId,
  template,
  viewAs,
}) => {
  const {track} = useAnalytics()
  const classes = useStyles()

  const {contentContainerId, id: contentBlockId} = contentBlock
  const {
    acceptedConfirmationText,
    agreementText,
    backgroundColor,
    declinedConfirmationText,
    disagreeText,
    documentLinkText,
    documentLinkUrl,
    heading,
    legalWarning,
    name,
    signatureBoxText,
  } = data

  const hasDocument = !!documentLinkText && !!documentLinkUrl

  const [viewedDocument, setViewedDocument] = useState(!hasDocument)
  const [enrollmentValue, setEnrollmentValue] = useState(null)
  const [enrollmentCompletedAction, setEnrollmentCompletedAction] = useState(null)
  const [didSubmit, setDidSubmit] = useState(false)

  const isAgreementSelected = enrollmentValue === agreementText
  const isDisagreementSelected = enrollmentValue === disagreeText

  const {
    change,
    field,
    failed,
    handleInputChange,
    handleSubmit,
    inputs,
    invalid,
    resetForm,
    submitting,
  } = useForm({
    initialValues: data,
    onSubmit,
    validators: {
      fullName: [
        val => {
          if (!isAgreementSelected) return null
          return val?.trim().length > 0 ? null : "Required"
        },
      ],
    },
  })

  const canViewAs = view => isEditMode && selectedBlockId === contentBlock.id && viewAs === view

  const isCompleted = actionName => {
    const allowResubmission = template?.status !== "live" && !didSubmit
    return !allowResubmission && enrollmentCompletedAction?.name === actionName
  }

  // This should be redundant with the fullName validation logic and the `invalid` value returned
  // back from useForm, but that `invalid` value doesn't update until after the user edits the input.
  const isFormValid =
    (isAgreementSelected && inputs?.fullName?.value?.length > 0) || isDisagreementSelected

  const isSaveButtonDisabled = !enrollmentValue || invalid || !viewedDocument || !isFormValid

  const trackOnce = (actionName, params) => {
    const key = `action-tracked:${actionName}-${contentBlock.id}:${journeyId}`

    if (storage.getItem(key)) return
    storage.setItem(key, true)
    track(actionName, {...params, pageId, contentBlockId, contentContainerId})
  }

  const handleKeyUp = () => {
    trackOnce("enrollment_started", {name, element: "type-your-name-field"})
  }

  const handleEnrollmentTermsClicked = e => {
    setViewedDocument(true)
    trackOnce("enrollment_terms_link_clicked", {name})
  }

  const handleEnroll = ({target: {value}}) => {
    // This will clear any failed validation logic, such as if the user hit submit
    // when enrolled and failed validation, then switched to not enrolled.
    if (value === disagreeText && invalid) resetForm()

    // This will run a custom validator on fullName in case an entry was made while
    // agreementText was not selected and passed validation. We need to check if
    // fullName exists in inputs in case the user selected disagree and reset the
    // form if in an invalid state.
    if (value === agreementText && inputs.hasOwnProperty("fullName"))
      handleInputChange({target: {name: "fullName", value: inputs.fullName.value}}, [
        val => (val?.trim().length > 0 ? null : "Required"),
      ])

    // Track click on radio button as "enrollment_started" action
    const element = value === agreementText ? "agree-button" : "disagree-button"
    trackOnce("enrollment_started", {name, element})
    change("enrolled", value === agreementText)
    setEnrollmentValue(value)
  }

  useEffect(() => {
    if (!contact?.id) return

    fetchActions({
      contactId: contact.id,
      name: ["enrollment_accepted", "enrollment_declined"],
    }).then(actions => {
      const action = last(actions.filter(action => action.contentBlockId === contentBlock.id))

      if (action) setEnrollmentCompletedAction(action)
      if (action && submitting) setDidSubmit(true)
    })
  }, [contact, contentBlock.id, submitting])

  const showAccepted = canViewAs("accepted") || isCompleted("enrollment_accepted")
  const showDeclined = canViewAs("declined") || isCompleted("enrollment_declined")

  if (showAccepted) {
    return (
      <div className={classes.confirmationMessage}>
        <MuiIcon
          className={classes.confirmationIcon}
          icon={<CheckCircleIcon aria-hidden="true" color="primary" size={150} />}
        />
        <Typography>{acceptedConfirmationText}</Typography>
      </div>
    )
  }

  if (showDeclined) {
    return (
      <div className={classes.confirmationMessage}>
        <MuiIcon
          className={classes.confirmationIcon}
          icon={<CheckCircleIcon aria-hidden="true" color="primary" size={150} />}
        />
        <Typography>{declinedConfirmationText}</Typography>
      </div>
    )
  }

  return (
    <div>
      <Typography variant="h5">{heading}</Typography>

      <form onSubmit={handleSubmit}>
        {hasDocument && (
          <>
            <div className={classes.documentWrapper}>
              <Button
                onClick={handleEnrollmentTermsClicked}
                href={documentLinkUrl}
                rel="noopener noreferrer"
                target="_blank"
                variant="contained"
                className={classes.documentText}
                style={buttonColors({backgroundColor})}
              >
                <span>{documentLinkText}</span>
              </Button>
              {viewedDocument ? (
                <Chip icon={<CheckCircleIcon />} label="Complete" size="small" variant="outlined" />
              ) : (
                <Chip
                  label="Incomplete"
                  size="small"
                  variant="outlined"
                  className={classes.incompleteChip}
                />
              )}
            </div>

            <Divider className={classes.divider} />
          </>
        )}

        <div className={classes.radioFormWrapper}>
          <div>
            <FormControl aria-label="Enrollment text">
              <FormControlLabel
                control={
                  <Radio
                    checked={isAgreementSelected}
                    color="primary"
                    name="enrollment"
                    onChange={handleEnroll}
                    value={agreementText}
                  />
                }
                label={agreementText}
              />
            </FormControl>

            {isAgreementSelected && (
              <div className={classes.fullNameWrapper}>
                <TextField
                  className={classes.fullNameInput}
                  inputProps={{"aria-label": "Sign your first and last name"}}
                  label="Sign your first and last name"
                  onKeyUp={handleKeyUp}
                  variant="outlined"
                  size="small"
                  {...field("fullName")}
                />
              </div>
            )}
          </div>

          <div>
            <FormControl aria-label="Enrollment text">
              <FormControlLabel
                control={
                  <Radio
                    checked={isDisagreementSelected}
                    color="primary"
                    name="enrollment"
                    onChange={handleEnroll}
                    value={disagreeText}
                  />
                }
                label={disagreeText}
              />
            </FormControl>
          </div>
        </div>

        <SaveButton
          disabled={isSaveButtonDisabled}
          failed={failed}
          stateLabels={{
            default: "Submit",
            saved: "Submitted!",
            submitting: "Submitting...",
          }}
          style={isSaveButtonDisabled ? {} : buttonColors({backgroundColor})}
          submitting={submitting}
          size="large"
        />

        <Divider className={classes.divider} />

        <div>
          <Typography variant="caption">{signatureBoxText}</Typography>
          <Typography variant="caption">{legalWarning}</Typography>
        </div>
      </form>
    </div>
  )
}

EnrollmentForm.propTypes = {
  classes: object,
  contact: object,
  contentBlock: contentBlockPropType.isRequired,
  journeyId: string,
  pageId: string,
  data: shape({
    agreementText: string,
    disagreeText: string,
    documentLinkText: string,
    documentLinkUrl: string,
    heading: string,
    name: string,
    signatureBoxText: string,
  }),
  isEditMode: bool,
  onSubmit: func.isRequired,
  selectedBlockId: string,
  template: object,
  viewAs: string,
}

export default EnrollmentForm
