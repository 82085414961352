import withStyles from "@mui/styles/withStyles"
import memoize from "memoize-one"
import {node, object} from "prop-types"
import {useSelector} from "react-redux"

import {compileCss} from "lib/custom-css"

// This should be reminiscent to the nurturing_layout.html.eex

const bodyOverride = `
      body {background-color: white}
`

const createCompiledCustomCss = memoize((scope, source) => {
  try {
    return compileCss(scope, source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const MessageContainer = ({message, classes, children}) => {
  const team = useSelector(state => state?.session?.team)

  const compiledCss = createCompiledCustomCss(`.message-root-${message?.id}`, message?.css || "")

  return (
    <>
      <style>{bodyOverride}</style>
      <div className={classes.container}>
        <div
          data-testid="message-content"
          id="message-content"
          className={`${classes.content} message-root-${message?.id}`}
          style={message?.styles || {}}
        >
          <style>{compiledCss}</style>
          <div className={`${classes.contentContainer} body-content`}>{children}</div>
        </div>
        <div className={classes.footer}>
          <div className={classes.address}>
            {team?.address && team?.city && team?.state && team?.postCode && (
              <>
                {team.address}
                <br />
                {team.address2 && (
                  <>
                    {team.address2}
                    <br />
                  </>
                )}
                {`${team.city}, ${team.state} ${team.postCode}`}
              </>
            )}
          </div>
          {!message?.isTransactional && (
            <div>
              <span className={classes.fakeLink}>Unsubscribe</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const styles = {
  container: {
    maxWidth: 580,
    margin: "0 auto",
  },
  content: {
    padding: 10,
  },
  contentContainer: {
    position: "relative",
  },
  footer: {
    "&, & a": {
      fontSize: 12,
      color: "#999",
      textAlign: "center",
      marginTop: 10,
    },
  },
  address: {
    fontStyle: "italic",
  },
  fakeLink: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}

MessageContainer.propTypes = {
  classes: object,
  children: node,
  message: object,
}

export default withStyles(styles)(MessageContainer)
