export const initialState = {}

export const actions = {
  setTeam: Symbol(),
  setUser: Symbol(),
}

export const reducer = (state, {type, ...action}) => {
  if (!Object.values(actions).includes(type)) throw new Error(type)
  return {...state, ...action}
}
