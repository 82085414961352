import {Collapse, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, node, string} from "prop-types"
import {useState} from "react"
import {MdArrowRight} from "react-icons/md"
import {animated, useSpring} from "react-spring"

const useStyles = makeStyles(theme => ({
  bg: {
    display: "inline-flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  wrapperInner: {
    paddingLeft: theme.spacing(1),
  },
}))

const Expandable = ({label, labelClassName = "", children, startExpanded = false}) => {
  const [expanded, setExpanded] = useState(startExpanded)
  const classes = useStyles()
  const iconStyle = useSpring({
    config: {
      tension: 260,
      friction: 10,
    },
    height: "20px",
    transform: `rotate(${expanded ? 90 : 0}deg)`,
  })

  const onClick = () => setExpanded(!expanded)
  return (
    <>
      <div className={cx(classes.bg, labelClassName)} onClick={onClick}>
        <animated.span style={iconStyle}>
          <MdArrowRight size={20} />
        </animated.span>
        <Typography onClick={onClick} variant="body2" component="span">
          {label}
        </Typography>
      </div>
      <Collapse classes={{wrapperInner: classes.wrapperInner}} in={expanded}>
        {children}
      </Collapse>
    </>
  )
}

Expandable.propTypes = {
  children: node.isRequired,
  label: string.isRequired,
  labelClassName: string,
  startExpanded: bool,
}

export default Expandable
