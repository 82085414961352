import {useParams} from "react-router-dom"

////////////////////////////////////////////////////////////////////////////////
////
//// This hook lets you leverage the react router params for a key around your
//// component so you can force an unmount/remount if the component is
//// theoretically completely different (i.e. templateId changes) but react
//// would otherwise treat it like the props are just updating.
////
////////////////////////////////////////////////////////////////////////////////

const withParamKey = getKey => Component => props => {
  return <Component key={getKey(useParams())} {...props} />
}

export default withParamKey
