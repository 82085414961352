import {Button, ButtonGroup, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import pick from "lodash/pick"
import {object, string} from "prop-types"
import {PureComponent} from "react"

import {insightsConsumer} from "components/template-insights/insights-context"

import {fetchInsightMessagePerformance} from "lib/api"

import {templateContext} from "../template-router/template-context"
import {Metrics, buttonGroupsContainerStyle, groupByContainerStyle} from "./insights-helpers"
import MessagePerformanceInsightsBarchart from "./message-performance-insights-barchart"
import MessagePerformanceInsightsTable from "./message-performance-insights-table"

const MessageTypes = {Both: "both", Email: "email", Sms: "sms"}

const filterMessagePerformance = (messagePerformance, type) => {
  if (type === MessageTypes.Both) return messagePerformance

  return {
    rows: messagePerformance.rows.filter(row => row.message.type === type),
    footer: {
      [type]: messagePerformance.footer[type],
    },
  }
}

class TemplateInsights extends PureComponent {
  state = {
    isLoading: false,
    messagePerformance: {rows: [], footer: []},
    type: MessageTypes.Both,
    metric: Metrics.Total,
  }

  componentDidMount = () => this.fetchData()

  componentDidUpdate(prevProps) {
    if (prevProps.templateId !== this.props.templateId || prevProps.filter !== this.props.filter)
      this.fetchData()
  }

  fetchData = async () => {
    this.setState({isLoading: true})

    fetchInsightMessagePerformance(this.props.templateId, this.props.filter)
      .then(messagePerformance => this.setState({messagePerformance}))
      .finally(() => this.setState({isLoading: false}))
  }

  setType = ({currentTarget}) => this.setState({type: currentTarget.value})
  setMetric = ({currentTarget}) => this.setState({metric: currentTarget.value})

  render() {
    const {classes, filter, templateId} = this.props
    const {isLoading, messagePerformance, type, metric} = this.state
    const filteredMessagePerformance = filterMessagePerformance(messagePerformance, type)

    return (
      <>
        <div className={classes.headerWrapper}>
          <Typography className={classes.header} style={{marginTop: 0}} variant="h5">
            Message Performance
          </Typography>

          <div className={classes.groupByContainer} style={{marginTop: 0}}>
            <div className={classes.buttonGroupsContainer}>
              <ButtonGroup size="small" variant="outlined">
                <Button
                  color={MessageTypes.Both === type ? "primary" : "grey"}
                  onClick={this.setType}
                  tabIndex={0}
                  value={MessageTypes.Both}
                >
                  Both
                </Button>
                <Button
                  color={MessageTypes.Email === type ? "primary" : "grey"}
                  onClick={this.setType}
                  tabIndex={1}
                  value={MessageTypes.Email}
                >
                  Email
                </Button>
                <Button
                  color={MessageTypes.Sms === type ? "primary" : "grey"}
                  onClick={this.setType}
                  tabIndex={2}
                  value={MessageTypes.Sms}
                >
                  SMS
                </Button>
              </ButtonGroup>
              <ButtonGroup size="small" variant="outlined">
                <Button
                  color={Metrics.Total === metric ? "primary" : "grey"}
                  onClick={this.setMetric}
                  tabIndex={0}
                  value={Metrics.Total}
                >
                  Total
                </Button>
                <Button
                  color={Metrics.Rate === metric ? "primary" : "grey"}
                  onClick={this.setMetric}
                  tabIndex={1}
                  value={Metrics.Rate}
                >
                  %
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </div>

        <MessagePerformanceInsightsBarchart
          isLoading={isLoading}
          messagePerformance={filteredMessagePerformance}
          metric={metric}
        />

        <div className={classes.divider} />

        <MessagePerformanceInsightsTable
          exportFilters={{...pick(filter, ["endDate", "startDate"]), messageType: type}}
          isLoading={isLoading}
          messagePerformance={filteredMessagePerformance}
          metric={metric}
          templateId={templateId}
        />
      </>
    )
  }
}

TemplateInsights.propTypes = {
  classes: object.isRequired,
  filter: object,
  insights: object,
  templateId: string.isRequired,
}

const styles = theme => ({
  container: {},
  divider: {},
  dl: {},
  header: {},
  section: {},
  statsGridItem: {},
  buttonGroupsContainer: buttonGroupsContainerStyle(theme),
  groupByContainer: groupByContainerStyle(theme),
})

export default withStyles(styles)(templateContext(insightsConsumer(TemplateInsights)))
