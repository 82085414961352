import {Button, IconButton, Popover, Tooltip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {EditorState} from "draft-js"
import {func, object} from "prop-types"
import {useEffect, useRef, useState} from "react"
import {connect} from "react-redux"

import ColorPicker from "components/color-input/color-picker"
import {compilePalette} from "components/color-input/helpers"

import toggleInlineStyle from "lib/draft-js/toggle-inline-style"
import createTeamTheme from "themes/team-theme-creator"

const getFontColorFromEditorState = editorState => {
  if (!editorState) return "#5e6771"

  const fontColor = editorState
    .getCurrentInlineStyle()
    .filter(style => style.startsWith("font-color"))
    .first()

  return fontColor ? fontColor.replace("font-color-", "") : "#5e6771"
}

export const FontColorInput = ({editorState, onSetEditorState, themeStyles}) => {
  const classes = useStyles()
  const [color, setColor] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [prevSelection, setSelection] = useState(null)
  const anchorEl = useRef(null)

  const customPalette = compilePalette(createTeamTheme(themeStyles))

  useEffect(() => {
    setColor(getFontColorFromEditorState(editorState))
  }, [editorState])

  const onEnter = () => setSelection(editorState.getSelection().toJS())

  const onExit = () => {
    const selection = editorState.getSelection()
    const updatedSelection = selection.merge(prevSelection)

    onSetEditorState(EditorState.forceSelection(editorState, updatedSelection))
  }

  const onToggle = () => (isOpen ? onClose() : onOpen())

  const onOpen = () => setIsOpen(true)

  const onClose = () => setIsOpen(false)

  const onSave = () => {
    onSetEditorState(toggleInlineStyle(editorState, "font-color", color))
    onClose()
  }

  const onChange = ({hex}) => setColor(hex)

  const stopPropagation = e => e.stopPropagation()

  return (
    <>
      <Tooltip title="Font Color">
        <IconButton
          className={classes.button}
          onClick={onToggle}
          ref={anchorEl}
          type="button"
          size="medium"
        >
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 20h24v4H0z" style={{fill: color}} />
            <path d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z" />
          </svg>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl.current}
        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
        onClose={onClose}
        onMouseDown={stopPropagation}
        open={isOpen}
        TransitionProps={{
          onEnter,
          onExit,
        }}
      >
        <ColorPicker
          className={classes.picker}
          color={color}
          onChange={onChange}
          palette={customPalette}
        />
        <div className={classes.actions}>
          <Button color="grey" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </div>
      </Popover>
    </>
  )
}

FontColorInput.propTypes = {
  editorState: object,
  onSetEditorState: func,
  themeStyles: object,
}

const useStyles = makeStyles(theme => ({
  actions: {
    padding: theme.spacing(2.25),
    paddingTop: 0,
    textAlign: "right",
  },
  picker: {
    boxShadow: "unset !important",
  },
  button: {
    padding: 0,
    cursor: "pointer",
    border: 0,
    height: 32,
    width: 32,
    borderRadius: "unset",
    "&:hover": {
      background: "#fff",
    },
  },
}))

const mapState = state => ({
  themeStyles: state.session.team?.themeStyles || {},
})

export default connect(mapState)(FontColorInput)
