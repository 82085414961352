import {FORGOT_PASSWORD_SUBMITTED} from "./forgot-password-actions"

function forgotPassword(state = {}, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_SUBMITTED:
      return {
        ...state,
        wasSubmitted: true,
      }

    default:
      return state
  }
}

export default forgotPassword
