import {
  FormControlLabel,
  InputAdornment,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Popover,
  Switch,
  TextField,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {number, string} from "prop-types"
import {useRef, useState} from "react"
import {BiNetworkChart as CrossChannelIcon} from "react-icons/bi"
import {
  FaDesktop as DesktopIcon,
  FaMobileAlt as MobileIcon,
  FaTabletAlt as TabletIcon,
} from "react-icons/fa"
import {WiStars as CustomIcon} from "react-icons/wi"

import {breakpointsForLayoutBasis} from "components/content-block-editor/layout-basis"

import humanize from "lib/string/humanize"

import DOSelect from "../do-select/do-select"
import Feature from "../feature/feature"
import {useContentBlockEditor} from "./content-block-editor-context"

const useStyles = makeStyles(theme => ({
  selectValue: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: theme.spacing(),
    },
  },
  gridBadge: {
    color: "#fff",
    padding: "0 4px",
    background: "#00A4FF",
    marginLeft: "8px",
    borderRadius: "4px",
  },
  dimensions: {
    display: "flex",
    alignItems: "baseline",
    width: 180,
    "& > *": {
      flex: 1,
    },
  },
  statusContainer: {
    display: "flex",
    alignItems: "baseline",
    "& > *": {
      flex: 1,
    },
    paddingLeft: 20,
  },
  dimensionsDivider: {
    textAlign: "center",
    flex: "0 0 20px",
  },
}))

const DeviceIcon = ({type, size}) => {
  switch (type) {
    case "desktop":
      return <DesktopIcon size={size} />
    case "tablet":
      return <TabletIcon size={size} />
    case "mobile":
      return <MobileIcon size={size} />
    case "custom":
      return <CustomIcon size={size} />
    case "channel":
      return <CrossChannelIcon size={size} />
    default:
      return null
  }
}

DeviceIcon.propTypes = {
  type: string.isRequired,
  size: number,
}

const deviceDescription = ({description, type, width, height}) =>
  description ?? `${humanize(type)} – ${width}x${height}`

const gridBadge = ({width, type}, layoutBasis) => {
  if (type === "custom" || type === "desktop") return "★"

  const badge =
    breakpointsForLayoutBasis(layoutBasis).find(
      ({range: [lower, upper]}) => (!upper && width >= lower) || (width >= lower && width <= upper)
    )?.key ?? ""

  return badge.toUpperCase()
}

const DeviceControls = ({layoutBasis, status}) => {
  const {device, devices, scaleToFit, setDevice} = useContentBlockEditor()
  const classes = useStyles()

  const onChangeDimensions = ({target}) => {
    const value = Math.max(parseInt(target.value, 10) || 0, 10)
    setDevice({...device, [target.name]: value}, scaleToFit)
  }

  return (
    <>
      <DOSelect
        MenuProps={{style: {zIndex: 100001}}}
        id="layoutBasis"
        limitHeight={false}
        value={device}
        onChange={event => setDevice(event.target.value, scaleToFit)}
        renderValue={() => (
          <div className={classes.selectValue}>
            <DeviceIcon size={30} type={device.type} />
            <div>
              {device.name}
              <br />
              <small>{deviceDescription(device)}</small>
            </div>
          </div>
        )}
      >
        {devices.map(aDevice => (
          <MenuItem classes={{root: classes.menuItem}} key={aDevice.name} value={aDevice}>
            <ListItemAvatar>
              <DeviceIcon size={30} type={aDevice.type} />
            </ListItemAvatar>
            <ListItemText
              classes={{secondary: classes.listItemTextSecondary}}
              primary={aDevice.name}
              secondary={deviceDescription(aDevice)}
            />
            <span className={classes.gridBadge}>{gridBadge(aDevice, layoutBasis)}</span>
          </MenuItem>
        ))}
      </DOSelect>
      {device.type === "custom" && (
        <div className={classes.dimensions}>
          <TextField
            autoFocus={true}
            label="Width"
            defaultValue={device.width}
            name="width"
            onChange={onChangeDimensions}
            type="number"
          />
          <div className={classes.dimensionsDivider}>x</div>
          <TextField
            label="Height"
            defaultValue={device.height}
            name="height"
            onChange={onChangeDimensions}
            type="number"
          />
        </div>
      )}
      <FormControlLabel
        control={
          <Switch
            color="primary"
            name="scaleToFit"
            checked={scaleToFit}
            onChange={event => setDevice(device, event.target.checked)}
          />
        }
        label="Scale to fit"
      />
      {status && (
        <Feature featureKey="campaign-approval">
          <div className={classes.statusContainer}>
            <TextField
              className={classes.field}
              disabled={true}
              label="Current Status"
              fullWidth={true}
              readOnly={true}
              value={`${humanize(status)}`}
            />
          </div>
        </Feature>
      )}
    </>
  )
}

DeviceControls.propTypes = {
  layoutBasis: string,
  status: string,
}

const useSidebarStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(),
  },
  input: {
    "& *": {
      cursor: "pointer !important",
    },
  },
  popover: {
    display: "flex",
    flexFlow: "wrap",
    padding: theme.spacing(2),
    "& > *": {
      flex: "0 0 100%",
    },
    maxWidth: 300,
  },
}))

export const DeviceControlsSidebar = ({layoutBasis, status}) => {
  const {device} = useContentBlockEditor()
  const classes = useSidebarStyles()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)

  return (
    <div className={classes.root} ref={anchorEl}>
      <Popover
        anchorEl={anchorEl.current}
        classes={{paper: classes.popover}}
        open={open}
        onClose={() => setOpen(false)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DeviceControls layoutBasis={layoutBasis} status={status} />
      </Popover>
      <TextField
        className={classes.input}
        fullWidth={true}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <DeviceIcon type={device.type} />
            </InputAdornment>
          ),
        }}
        label="Viewing as"
        onClick={() => setOpen(!open)}
        ref={anchorEl}
        value={
          device.type === "desktop"
            ? device.name
            : `${device.name} (${device.width}x${device.height})`
        }
      />
    </div>
  )
}

DeviceControlsSidebar.propTypes = {
  layoutBasis: string,
  status: string,
}

export default DeviceControls
