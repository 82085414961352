import {Paper} from "@mui/material"
import {lighten} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {array, object, string} from "prop-types"
import {RiMailLine as EmailIcon, RiMessage2Line as SMSIcon} from "react-icons/ri"

import {formatDate, to12Hour} from "lib/date-time-formatters"
import humanize from "lib/string/humanize"

function relativeSendTime(timelineMarker) {
  if (timelineMarker.day === 0) return <i>immediately</i>
  else {
    const date = new Date(timelineMarker.relativeSendTime)

    return (
      <>
        at <i>{to12Hour(timelineMarker.time)}</i> on <i>{formatDate(date)}</i>
      </>
    )
  }
}

export const DripPreview = ({classes, flavor, timelineMarkers}) => {
  return (
    <Paper className={classes.paperClass}>
      {timelineMarkers.sort(
        (a, b) => Date.parse(a.relativeSendTime) - Date.parse(b.relativeSendTime)
      ).length > 0 && (
        <div className={cx(classes.dripMessagesCallout, {[classes.single]: flavor !== "batch"})}>
          <h3>Drip Messages</h3>
          <div className="body">
            {flavor === "batch" ? "These journeys" : "This journey"} will have drip messages
            scheduled for the following dates:
            <ol>
              {timelineMarkers.map(timelineMarker => (
                <li key={timelineMarker.id}>
                  An {timelineMarker.type === "email" ? <EmailIcon /> : <SMSIcon />}{" "}
                  <b>{humanize(timelineMarker.type)}</b> {relativeSendTime(timelineMarker)}
                </li>
              ))}
            </ol>
          </div>
        </div>
      )}
    </Paper>
  )
}

DripPreview.propTypes = {
  classes: object.isRequired,
  flavor: string,
  timelineMarkers: array.isRequired,
}

const styles = theme => ({
  single: {
    marginTop: theme.spacing(3),
  },
  paperClass: {
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
  },
  dripMessagesCallout: {
    fontSize: "0.95rem",
    "& h3": {
      backgroundColor: theme.palette.brand.lightGreen,
      color: "#fff",
      padding: `0 ${theme.spacing(1)}`,
      borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
      margin: 0,
    },
    "& .body": {
      padding: theme.spacing(1),
      borderRadius: `0 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
      backgroundColor: lighten(theme.palette.brand.lightGreen, 0.9),
      "& ol": {
        paddingInlineStart: theme.spacing(2),
        lineHeight: 2,
      },
      "& li svg": {
        marginBottom: -3,
      },
    },
  },
})

export default withStyles(styles)(DripPreview)
