import {FormHelperText, InputLabel, Select} from "@mui/material"
import {bool, object} from "prop-types"

const ITEM_HEIGHT = 42
const ITEM_PADDING_TOP = 8

export const HEIGHT_LIMITING_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
    },
  },
}

export const DEFAULT_MENU_PROPS = {
  MenuListProps: {
    disablePadding: true,
  },
}

const DOSelect = ({
  limitHeight = true,
  MenuProps = {},
  SelectDisplayProps = {},
  labelId,
  label,
  helperText,
  ...props
}) => (
  <>
    {label && (
      <InputLabel error={props.error} id={`${props.id}-label`}>
        {label}
      </InputLabel>
    )}
    <Select
      MenuProps={{
        ...DEFAULT_MENU_PROPS,
        ...(limitHeight ? HEIGHT_LIMITING_PROPS : {}),
        MenuListProps: {
          "aria-labelledby": labelId || `${props.id}-label`,
          ...MenuProps.MenuListProps,
        },
        ...MenuProps,
      }}
      SelectDisplayProps={{
        "aria-labelledby": labelId || `${props.id}-label`,
        ...SelectDisplayProps,
      }}
      {...props}
    />
    {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
  </>
)

DOSelect.propTypes = {
  limitHeight: bool,
  ...Select.propTypes,
}

// TODO: Remove when Redux Form is fully removed
export const DOReduxFormSelect = ({
  input,
  meta: {touched, error},
  limitHeight = true,
  MenuProps = {},
  ...props
}) => (
  <DOSelect
    MenuProps={{
      ...DEFAULT_MENU_PROPS,
      ...(limitHeight ? HEIGHT_LIMITING_PROPS : {}),
      ...MenuProps,
    }}
    error={touched && error}
    inputProps={input}
    {...props}
  />
)

DOReduxFormSelect.propTypes = {
  limitHeight: bool,
  ...DOSelect.propTypes,
  input: object,
  meta: object,
}

export default DOSelect
