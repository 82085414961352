import {Button, ButtonGroup, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, bool, func, string} from "prop-types"

import GroupedBarchart from "./grouped-barchart"
import {
  Types,
  buttonGroupsContainerStyle,
  formatValue,
  groupByContainerStyle,
} from "./insights-helpers"
import {PageDetailsPropType} from "./page-details"

const Units = {Total: "Total", Percent: "Percent"}

const valueFormatter = unit => value =>
  formatValue(value, unit === Units.Percent ? Types.Percentage : Types.Number)

function dataBuilder(unit, pageDetails) {
  return pageDetails.map(p => ({
    name: p.contentName,
    pagesViewed: unit === Units.Total ? p.uniqueViews : p.viewRate,
    pagesCompleted: unit === Units.Total ? p.pageCompletions : p.pageCompletionRate,
    objectivesCompleted: unit === Units.Total ? p.objectivesCompleted : p.objectiveCompletionRate,
  }))
}

const PagePerformance = ({isLoaded, onChangeUnit, pageDetails, unit}) => {
  const classes = useStyles()

  if (!isLoaded) return "Loading..."

  if (!pageDetails?.length)
    return (
      <>
        <Typography className={classes.header} variant="h5">
          Page Performance
        </Typography>
        <div>No pages are set for this campaign.</div>
      </>
    )

  const labelFormatter = valueFormatter(unit)

  const barData = [
    ["pagesViewed", unit === Units.Total ? "Pages Viewed" : "Viewed %"],
    ["pagesCompleted", unit === Units.Total ? "Pages Completed" : "Page Completion %"],
    [
      "objectivesCompleted",
      unit === Units.Total ? "Objectives Completed" : "Objective Completion %",
    ],
  ]

  const data = dataBuilder(unit, pageDetails)

  const isEmpty = data.length < 1

  return (
    <>
      <Typography className={classes.header} variant="h5">
        Page Performance
      </Typography>
      <div className={classes.groupByContainer}>
        <div className={classes.buttonGroupsContainer}>
          <ButtonGroup size="small" variant="outlined">
            {[
              {label: "Total", value: Units.Total},
              {label: "%", value: Units.Percent},
            ].map(({label, value}) => (
              <Button
                color={unit === value ? "primary" : "grey"}
                key={value}
                onClick={onChangeUnit}
                value={value}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </div>

      <GroupedBarchart
        barData={barData}
        data={data}
        isEmpty={isEmpty}
        labelFormatter={labelFormatter}
      />
    </>
  )
}

PagePerformance.propTypes = {
  isLoaded: bool.isRequired,
  onChangeUnit: func.isRequired,
  pageDetails: arrayOf(PageDetailsPropType),
  unit: string.isRequired,
}

const useStyles = makeStyles(theme => ({
  buttonGroupsContainer: buttonGroupsContainerStyle(theme),
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginTop: 80,
  },
  groupByContainer: groupByContainerStyle(theme),
}))

export default PagePerformance
