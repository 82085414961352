import {bool, func, object, string} from "prop-types"
import {useCallback, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {journeyContext} from "components/journeys/journey-context"

import {enroll} from "lib/api"
import {ACTION_ADDED, getSessionId} from "lib/hooks/use-analytics"

import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import EnrollmentForm from "./enrollment-form"

const EnrollmentReadOnly = ({
  className,
  contact,
  contentBlock,
  customCss,
  engagementChannelId,
  isEditMode,
  journeyId,
  onClick,
  pageId: contentContainerPageId,
  selectedBlock,
  selectedBlockMetaChanges,
  style,
  template,
}) => {
  const dispatch = useDispatch()
  const team = useSelector(({session}) => session?.team)

  const {containerType, contentContainerId, data, pageId, id: contentBlockId} = contentBlock
  const teamName = team?.name

  const mergedData = useMemo(
    () => ({
      ...data,
      legalWarning: `The enrollment of the service listed is an agreement between you and ${teamName}.`,
    }),
    [data, teamName]
  )

  const onSubmit = useCallback(
    values => {
      // NB: For content_containers we need to get the page_id from the journeyContext instead of the contentBlock
      if (
        containerType === "content_container" &&
        engagementChannelId &&
        contentContainerId &&
        contentBlockId
      )
        // See #variable-enroll-params in Gondor for param application
        return enroll(contentBlockId, values, {
          queryParams: {
            contentContainerId,
            engagementChannelId,
            journeyId,
            pageId: contentContainerPageId,
            sessionId: getSessionId(),
          },
        })

      if (containerType !== "content_container" && journeyId && pageId && contentBlockId)
        // See #variable-enroll-params in Gondor for param application
        return enroll(contentBlockId, values, {
          queryParams: {
            journeyId,
            pageId,
            sessionId: getSessionId(),
          },
        }).then(() => {
          const name = values?.enrolled ? "enrollment_accepted" : "enrollment_declined"

          // NB: Normally, this is handled for us by useAnalytic's `track` function, however, since we are manually
          // creating the action here, we need to explicitly notify redux that the action was added. This updates
          // the progress bar when the page's completion condition is `Perform Action` without requiring a refresh.
          // Solves: https://trello.com/c/tJQw76mV
          dispatch({
            type: ACTION_ADDED,
            payload: {name, pageId, pages: template?.templatePages?.map(tp => tp.page) ?? []},
          })
        })
    },
    [
      contentBlockId,
      contentContainerId,
      contentContainerPageId,
      containerType,
      dispatch,
      engagementChannelId,
      journeyId,
      pageId,
      template,
    ]
  )

  return (
    <div className={className} onClick={onClick} style={style}>
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />

      <style>{customCss}</style>

      <EnrollmentForm
        contact={contact}
        contentBlock={contentBlock}
        journeyId={journeyId}
        pageId={pageId}
        data={mergedData}
        isEditMode={isEditMode}
        selectedBlockId={selectedBlock?.id}
        viewAs={selectedBlockMetaChanges?.editorOnlyViewAsState ?? "unsubmitted"}
        onSubmit={onSubmit}
        template={template}
      />
    </div>
  )
}

EnrollmentReadOnly.propTypes = {
  className: string,
  contact: object,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  engagementChannelId: string,
  isEditMode: bool,
  journeyId: string,
  onClick: func,
  pageId: string,
  selectedBlock: contentBlockPropType,
  selectedBlockMetaChanges: object,
  style: object,
  template: object,
}

export default contentBlockEditorConsumer(journeyContext(EnrollmentReadOnly))
