import {Box, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {arrayOf, func, number, shape, string} from "prop-types"
import {useEffect} from "react"

import JourneyAudienceInput from "components/journey-audience-input/journey-audience-input"
import JourneyDurationInput from "components/journey-duration-input/journey-duration-input"
import JourneyUseCaseInput from "components/journey-use-case-input/journey-use-case-input"

import useFeatures from "lib/hooks/use-features"

import Feature from "../feature/feature"
import JourneyReentrySwitch from "../journey-reentry-switch/journey-reentry-switch"
import SuperheroBuilder1 from "../superhero/builder-1"

const fixedInputWidth = 400

const useStyles = makeStyles(theme => ({
  bigSpace: {
    marginBottom: theme.spacing(4),
  },
  campaignName: {
    width: fixedInputWidth,
  },
  campaignUseCase: {
    marginTop: theme.spacing(1),
    width: fixedInputWidth,
  },
  campaignAudience: {
    marginTop: theme.spacing(2),
    width: fixedInputWidth,
  },
}))

const setCampaignName = payload => ({type: "CAMPAIGN_NAME_SET", payload})
const setCampaignType = payload => ({type: "CAMPAIGN_TYPE_SET", payload})
const setJourneyDurationHours = payload => ({type: "CAMPAIGN_JOURNEY_DURATION_HOURS_SET", payload})
const setCampaignUseCase = payload => ({type: "CAMPAIGN_USE_CASE_SET", payload})
const setCampaignAudience = payload => ({type: "CAMPAIGN_AUDIENCE_SET", payload})
const setCampaignFeatures = payload => ({type: "CAMPAIGN_FEATURES_SET", payload})

const TemplateStep = ({
  wizardState: {
    campaignName,
    campaignType,
    campaignJourneyDurationHours,
    campaignUseCase,
    campaignAudience,
    campaignFeatures,
  },
  dispatch,
}) => {
  const classes = useStyles()

  const {hasFeature} = useFeatures()

  useEffect(() => {
    const allFeatures = ["campaign-audience", "campaign-use-case"]
    const features = allFeatures.reduce((acc, feature) => {
      if (hasFeature(feature)) acc.push(feature)
      return acc
    }, [])
    dispatch(setCampaignFeatures(features))
  }, [dispatch, hasFeature])

  return (
    <>
      <SuperheroBuilder1 style={{width: 320, float: "right"}} />
      {/* Extend box width to accomodate re-entry control label */}
      <Box maxWidth={460} my={4}>
        <TextField
          className={cx(classes.bigSpace, classes.campaignName)}
          autoFocus={true}
          fullWidth={true}
          helperText="Enter a campaign name to get started."
          id="campaign-name"
          label="Campaign Name"
          onChange={e => dispatch(setCampaignName(e.target.value))}
          value={campaignName}
        />
        <Feature featureKey="tactical-campaign-and-account-management">
          <JourneyReentrySwitch
            helperText="Allow a contact to re-enter the campaign after journey expiration."
            label="Campaign Re-Enrollment"
            labelStyle="bold"
            onChange={e =>
              dispatch(setCampaignType(e.target.value === "standard" ? "tactical" : "standard"))
            }
            value={campaignType}
          />
        </Feature>
        <JourneyDurationInput
          helperText="Enter how long journeys will stay active before they expire."
          labelStyle="bold"
          name="campaignJourneyDuration"
          onChange={e => dispatch(setJourneyDurationHours(e.target.value))}
          value={campaignJourneyDurationHours}
        />
        <Feature featureKey="campaign-use-case">
          <JourneyUseCaseInput
            classes={classes}
            name="campaignUseCase"
            onChange={e => dispatch(setCampaignUseCase(e.target.value))}
            value={campaignUseCase}
          />
        </Feature>
        <Feature featureKey="campaign-audience">
          <JourneyAudienceInput
            classes={classes}
            name="campaignAudience"
            onChange={e => dispatch(setCampaignAudience(e.target.value))}
            value={campaignAudience}
          />
        </Feature>
      </Box>
    </>
  )
}

TemplateStep.propTypes = {
  dispatch: func.isRequired,
  wizardState: shape({
    campaignAudience: string,
    campaignFeatures: arrayOf(string),
    campaignJourneyDurationHours: number,
    campaignName: string,
    campaignType: string,
    campaignUseCase: string,
  }),
}

export default TemplateStep
