import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {AiOutlineTable as AccountsTableIcon} from "react-icons/ai"
import shortid from "shortid"

import {feature} from "components/feature/feature"

const defaultName = container => {
  if (container._type === "page") {
    return container.contentName
  } else if (container._type === "content_container") {
    return container.engagementChannel.name
  } else {
    throw new Error(
      `AccountsTableButton assumed that all widgets are on either a Page or a ContentContainer, but this container is of type ${container._type}.`
    )
  }
}

const AccountsTableButton = forwardRef(({classes, container, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {
        columns: [
          {
            id: 1,
            header: "Account Number",
            contentId: "account.accountNumber",
          },
        ],
        tableName: defaultName(container),
        tableHeader: "MultiAccount Table",
      },
      type: "accounts-table",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <AccountsTableIcon />
      </ListItemIcon>
      <ListItemText primary="Accounts Table" ref={ref} />
    </MenuItem>
  )
})

AccountsTableButton.propTypes = {
  onAddContentBlock: func.isRequired,
  onClose: func,
  classes: object.isRequired,
  container: object.isRequired,
}

export {AccountsTableIcon}

export default feature("accounts-table", AccountsTableButton)
