import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, string} from "prop-types"
import {useEffect, useState} from "react"
import {FaArchive as ArchiveIcon} from "react-icons/fa"
import {Link} from "react-router-dom"

import {fetchTemplates} from "lib/api"
import pluralize from "lib/string/pluralize"

import SkeletonLoader from "../skeleton-loader/skeleton-loader"

const MAX_TEMPLATES = 6

const useStyles = makeStyles(theme => ({
  message: {
    marginBottom: theme.spacing(1),
  },
  skeleton: {
    maxWidth: "65%",
  },
  skeletonRoot: {
    marginBottom: theme.spacing(2),
  },
}))

export const TemplateListPreview = ({
  id,
  message: customMessage,
  newSlug,
  recordType,
  editingTemplateId,
  target,
  variant,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [templates, setTemplates] = useState([])
  const [totalTemplates, setTotalTemplates] = useState(0)

  useEffect(() => {
    let isMounted = true

    if (id.includes("new")) return setIsLoaded(true)

    setIsLoaded(false)

    fetchTemplates({
      [recordType === "page" ? "pageId" : "messageId"]: id,
      includeArchived: true,
      withFetchResponse: true,
    }).then(([data, fetchResponse]) => {
      if (!isMounted) return

      setIsLoaded(true)

      const sharedTemplateData = data.filter(
        sharedTemplate => sharedTemplate.id !== editingTemplateId
      )

      // because true == 1 and false == 0, this sort function will put non-archived before archived
      setTemplates(
        sharedTemplateData.sort((a, b) => a.isArchived - b.isArchived).slice(0, MAX_TEMPLATES)
      )

      if (fetchResponse instanceof window.Response)
        setTotalTemplates(parseInt(fetchResponse.headers.get("x-total-count"), 10))
    })

    return () => {
      isMounted = false
    }
  }, [editingTemplateId, id, recordType])

  const linkTo = templateId =>
    newSlug && recordType === "page"
      ? `/admin/templates/${templateId}/edit/${newSlug}`
      : `/admin/templates/${templateId}/content`

  const classes = useStyles()
  const message = Boolean(customMessage)
    ? customMessage(isLoaded, totalTemplates)
    : isLoaded && totalTemplates > 0
    ? `This content is used in the following other ${pluralize("campaign", totalTemplates)}:`
    : "This content is not used in any campaigns."

  return (
    <>
      <Typography className={classes.message} variant={variant}>
        {message}
      </Typography>
      <div className={classes.skeletonRoot}>
        <SkeletonLoader className={classes.skeleton} loaded={isLoaded}>
          <ul>
            {templates.map(({id: templateId, name, isArchived}, i) => (
              <li key={`${templateId}-${i}`}>
                {isArchived ? <ArchiveIcon style={{marginRight: "3px"}} /> : ""}
                <Typography
                  color="textPrimary"
                  component={Link}
                  rel="noopener noreferrer"
                  target={target}
                  to={linkTo(templateId)}
                  variant={variant}
                >
                  {name}
                </Typography>
              </li>
            ))}
          </ul>
          {totalTemplates > MAX_TEMPLATES && (
            <Typography variant={variant}>and {totalTemplates - MAX_TEMPLATES} more...</Typography>
          )}
        </SkeletonLoader>
      </div>
    </>
  )
}

TemplateListPreview.defaultProps = {
  target: "_self",
  variant: "body1",
}

TemplateListPreview.propTypes = {
  editingTemplateId: string,
  id: string.isRequired,
  message: func,
  newSlug: string,
  recordType: string.isRequired,
  target: string,
  variant: string,
}
