import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {object} from "prop-types"
import {useEffect, useState} from "react"

import {fetchPage} from "lib/api"
import {loadFontsFromTheme} from "lib/fonts"
import createTeamTheme from "themes/team-theme-creator"

import {ContentBlockEditorProvider} from "../content-block-editor/content-block-editor-context"
import {JourneyContextProvider} from "../journeys/journey-context"
import Page from "../journeys/page"
import themeBases from "../journeys/theme-bases"
import PreviewContext from "../templates/preview-context"

const PagePreview = props => {
  const [page, setPage] = useState(null)
  const [theme, setTheme] = useState(null)

  const pageId = props.match?.params?.pageId

  useEffect(() => {
    fetchPage(pageId)
      .then(page => {
        setPage(page)
        setTheme(createTeamTheme(page.team.themeStyles))
      })
      .catch(() => {})
  }, [pageId])

  useEffect(() => {
    loadFontsFromTheme(theme || {})
  }, [theme])

  // If the page hasn't finished loading yet we won't have a theme
  // (and Mui will moan)
  if (!theme) return null

  return (
    <ThemeProvider theme={theme}>
      <ThemedPage {...props} page={page} />
    </ThemeProvider>
  )
}

PagePreview.propTypes = {
  match: object,
}

const useStyles = themeBase => makeStyles(themeBase)()

const styleOverrides = `
.page-content {
  height: 100vh;
  overflow: hidden;
  margin-bottom: 0;
}
`

const ThemedPage = ({page}) => {
  const classes = useStyles(themeBases.default)

  return (
    <JourneyContextProvider legacyInitialState={{themeClasses: {}}}>
      <PreviewContext.Provider value={{isPreviewMode: true}}>
        <ContentBlockEditorProvider>
          <style>{styleOverrides}</style>
          <Page key={page.id} {...page} classes={classes} isEditable={false} isPreviewMode={true} />
        </ContentBlockEditorProvider>
      </PreviewContext.Provider>
    </JourneyContextProvider>
  )
}

ThemedPage.propTypes = {
  page: object,
}

export default PagePreview
