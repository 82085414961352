import {node, object} from "prop-types"
import {createContext, useContext, useEffect, useReducer} from "react"

import {initialState, reducer} from "./journey-context-reducer"

const context = createContext()
context.displayName = "JourneyContext"

export const {Provider, Consumer} = context

export const useJourneyContext = () => useContext(context)

export const journeyContext = Component => props => (
  <Component {...useJourneyContext()} {...props} />
)

export default {Provider, Consumer, journeyContext}

// First step in refactor to normalize JourneyContext. Shift usage of the provider
// directly to use of this structured provider component.
// - Legacy usage of journey context was as a simple data bus, which passed in data
//   via the provider value, and this should be deprecated
// - The prop legacyInitialState handles legacy data bus properties, and successive
//   refactors should remove this dependency.
// - The refactored JourneyContext should only hold properties related to journey
//   state, and legacy properties, such as for application or MUI theme state,
//   should be moved to discrete single-purpose contexts.
export const JourneyContextProvider = ({children, legacyInitialState}) => {
  const [nextState, dispatch] = useReducer(reducer, null, () => ({
    ...initialState,
    ...legacyInitialState,
  }))

  useEffect(() => {
    dispatch({
      type: "UPDATE_LEGACY_INITIAL_STATE",
      legacyInitialState: legacyInitialState,
    })
  }, [legacyInitialState])

  return <Provider value={{...nextState, dispatch}}>{children}</Provider>
}

JourneyContextProvider.defaultProps = {legacyInitialState: {}}

JourneyContextProvider.propTypes = {
  children: node.isRequired,
  legacyInitialState: object,
}
