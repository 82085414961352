import {MenuItem} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {EditorState, Modifier, RichUtils, SelectionState} from "draft-js"
import {func, object} from "prop-types"
import {useEffect, useState} from "react"

import DOSelect from "components/do-select/do-select"

import {menuProps} from "lib/draft-js/editor-helpers"

const blockTypes = [
  "unstyled",
  "blockquote",
  "header-one",
  "header-two",
  "header-three",
  "header-four",
  "header-five",
  "header-six",
]

export const clearBlockFontSizes = editorState => {
  const selection = editorState.getSelection()
  const contentState = editorState.getCurrentContent()
  const contentBlock = contentState.getBlockForKey(selection.getStartKey())
  const selectedBlock = SelectionState.createEmpty(contentBlock.getKey()).merge({
    focusOffset: contentBlock.getLength(),
  })
  const currentStyleKeys = [
    ...new Set(
      contentBlock
        .getCharacterList()
        .reduce((styles, char) => styles.concat([...char.getStyle()]), [])
    ),
  ].filter(style => style.startsWith("font-size"))

  // Let's just allow one style at a time. Turn off all active fontSizes.
  const nextContentState = currentStyleKeys.reduce(
    (acc, size) => Modifier.removeInlineStyle(acc, selectedBlock, size),
    contentState
  )

  return EditorState.push(editorState, nextContentState, "change-inline-style")
}

const BlockTypeInput = ({classes, editorState, onSetEditorState}) => {
  const [blockType, setBlockType] = useState("unstyled")

  useEffect(() => {
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType()

    const localType = blockTypes.includes(type) ? type : "unstyled"

    setBlockType(localType)
  }, [editorState])

  const onChange = e => {
    const nextEditorState = clearBlockFontSizes(editorState)

    onSetEditorState(
      EditorState.forceSelection(
        RichUtils.toggleBlockType(nextEditorState, e.target.value),
        editorState.getSelection()
      )
    )
  }

  return (
    <DOSelect
      className={classes.fontTypeSelect}
      MenuProps={menuProps}
      onChange={onChange}
      value={blockType}
    >
      <MenuItem className={classes.normal} value="unstyled">
        Paragraph
      </MenuItem>
      <MenuItem className={classes.blockquote} value="blockquote">
        Block Quote
      </MenuItem>
      <MenuItem className={classes.headerOne} value="header-one">
        Header 1
      </MenuItem>
      <MenuItem className={classes.headerTwo} value="header-two">
        Header 2
      </MenuItem>
      <MenuItem className={classes.headerThree} value="header-three">
        Header 3
      </MenuItem>
      <MenuItem className={classes.headerFour} value="header-four">
        Header 4
      </MenuItem>
      <MenuItem className={classes.headerFive} value="header-five">
        Header 5
      </MenuItem>
      <MenuItem className={classes.headerSix} value="header-six">
        Header 6
      </MenuItem>
    </DOSelect>
  )
}

BlockTypeInput.propTypes = {
  classes: object,
  editorState: object,
  onSetEditorState: func,
}

const styles = {
  fontTypeSelect: {
    width: 120,
  },
  blockquote: {
    color: "#999",
    padding: "6px 0 5px 18px",
    fontStyle: "italic",
    borderLeft: "2px solid #ddd",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.6,
  },
  headerOne: {
    fontWeight: "bold",
    fontSize: 32,
  },
  headerTwo: {
    fontWeight: "bold",
    fontSize: 24,
  },
  headerThree: {
    fontWeight: "bold",
    fontSize: 20,
  },
  headerFour: {
    fontWeight: "bold",
    fontSize: 16,
  },
  headerFive: {
    fontWeight: "bold",
    fontSize: 14,
  },
  headerSix: {
    fontWeight: "bold",
    fontSize: 10,
  },
  normal: {
    fontSize: 16,
    fontWeight: 400,
    color: "#5e6871f2",
  },
}

export default withStyles(styles)(BlockTypeInput)
