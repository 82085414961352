import {Tooltip} from "@mui/material"
import {bool, node} from "prop-types"

const MaybeTooltip = ({isTooltip, children, ...restProps}) => {
  if (!isTooltip) return children

  return (
    <Tooltip {...restProps}>
      <span>{children}</span>
    </Tooltip>
  )
}

MaybeTooltip.propTypes = {
  children: node.isRequired,
  isTooltip: bool.isRequired,
}

export default MaybeTooltip
