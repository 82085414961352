import {bool, func, object, shape} from "prop-types"
import {connect} from "react-redux"

import PluginColorInput from "components/content-block-editor/plugins/helpers/plugin-color-input"
import PluginIconInput from "components/content-block-editor/plugins/helpers/plugin-icon-input"

const CtaButtonSettings = ({change, field, hideIconInput, team, template}) => {
  const theme = template?.theme || team?.themeStyles

  return (
    <>
      {!hideIconInput && (
        <PluginIconInput
          backgroundColor={field("backgroundColor").value}
          iconSrcInitial={field("iconSrc").value}
          theme={theme}
          {...field("iconSrc")}
          onChange={src => change("iconSrc", src)}
        />
      )}
      <PluginColorInput
        label="Button Color"
        theme={theme}
        {...field("backgroundColor", {
          defaultValue:
            theme?.overrides?.MuiButton?.containedPrimary?.backgroundColor ||
            theme?.palette?.primary?.main,
        })}
        onChange={hex => {
          change("backgroundColor", hex)
        }}
      />
    </>
  )
}

CtaButtonSettings.defaultProps = {
  hideIconInput: false,
}

CtaButtonSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  hideIconInput: bool,
  team: shape({
    themeStyles: object,
  }),
  template: shape({
    theme: object,
  }),
}

export default connect(({session: {team}, template}) => ({team, template}))(CtaButtonSettings)
