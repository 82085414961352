import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {AiOutlineLoading3Quarters as ProgressMeterIcon} from "react-icons/ai"
import shortid from "shortid"

const ProgressMeter = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {type: "circular"},
      type: "progress-meter",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <ProgressMeterIcon />
      </ListItemIcon>
      <ListItemText primary="Progress Meter" ref={ref} />
    </MenuItem>
  )
})

ProgressMeter.propTypes = {
  onAddContentBlock: func.isRequired,
  onClose: func,
  classes: object.isRequired,
}

export {ProgressMeterIcon}

export default ProgressMeter
