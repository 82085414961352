export const availableFields = [
  {field: "uniqueId", isDefault: true},
  {field: "nameFull", label: "Name", isDefault: true},
  {field: "email", label: "Email", isDefault: true},
  {field: "emailVerified", value: "emailVerified", label: "Email Verified"},
  {field: "emailOptedIn", value: "emailOptedIn", label: "Email Opted In"},
  {field: "emailAuthorized", value: "emailAuthorized", label: "Email Authorized"},
  {field: "address", label: "Street Address"},
  {field: "address2", label: "Street Address Line 2"},
  {field: "city"},
  {field: "county"},
  {field: "state"},
  {field: "postCode", label: "Postal Code"},
  {field: "country"},
  {field: "phoneHome", label: "Phone (Home)"},
  {field: "phoneMobile", label: "Phone (Mobile)"},
  {field: "smsVerified", value: "smsVerified", label: "SMS Verified"},
  {field: "smsOptedIn", value: "smsOptedIn", label: "SMS Opted In"},
  {field: "smsAuthorized", value: "smsAuthorized", label: "SMS Authorized"},
  {field: "phoneWork", label: "Phone (Work)"},
  {field: "insertedAt", label: "Created", isDefault: true},
  {field: "updatedAt", label: "Last Updated"},
]

export default availableFields
