import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {MdPhoneIphone as MobileAppIcon} from "react-icons/md"
import shortid from "shortid"

const MobileAppDownloadButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {},
      type: "mobile-app-download",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <MobileAppIcon />
      </ListItemIcon>
      <ListItemText primary="Mobile App Download" ref={ref} />
    </MenuItem>
  )
})

MobileAppDownloadButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func.isRequired,
}

export {MobileAppIcon}

export default MobileAppDownloadButton
