import {object} from "prop-types"
import {NavLink} from "react-router-dom"

import AccessControlled from "../access-control/access-controlled"
import Feature from "../feature/feature"

const TemplateSubNav = ({classes}) => (
  <>
    <NavLink
      className={classes.link}
      exact={true}
      title="Campaigns - Dashboard"
      to="/admin/templates"
    >
      <span>Dashboard</span>
    </NavLink>

    <Feature featureKey="cross-channel-engagement">
      <NavLink
        className={classes.link}
        title="Campaigns - Cross Channel Engagement"
        to="/admin/templates/engagement-channels"
      >
        <span>Channels</span>
      </NavLink>
    </Feature>

    <Feature featureKey="automated-targeting">
      <AccessControlled requiredPermissions="targeting_conditions:edit">
        <NavLink
          className={classes.link}
          title="Campaigns - Targeting"
          to="/admin/templates/targeting"
        >
          <span>Targeting</span>
        </NavLink>
      </AccessControlled>
    </Feature>

    <Feature featureKey="atomic-assets">
      <AccessControlled hideIfNotPermitted={true} requiredPermissions="templates:view">
        <NavLink
          className={classes.link}
          title="Campaigns - Content Library"
          to="/admin/templates/content-library"
        >
          <span>Content Library</span>
        </NavLink>
      </AccessControlled>
    </Feature>

    <Feature featureKey="rewards">
      <AccessControlled hideIfNotPermitted={true} requiredPermissions="rewards:manage">
        <NavLink
          className={classes.link}
          title="Campaigns - Rewards"
          to="/admin/rewards/reward-sets"
        >
          <span>Rewards</span>
        </NavLink>
      </AccessControlled>
    </Feature>
  </>
)

TemplateSubNav.propTypes = {
  classes: object,
}

export default TemplateSubNav
