import {createContext, useContext, useEffect, useState} from "react"

import {fetchTemplate} from "lib/api"

// *****************************************************************************
// *****************************************************************************
//
// Template Context
//
// Automatically fetches and provides the template with the provided `templateId`
//
// NB: This provider is coupled with the router at this time so we always have
//     a template id. It does allow providing a `template`, which is namely for
//     easier testing.
//
// NB: Leverage `templateId` when making requests for data in subcomponents --
//     it will be immediately available from the route, vs. the `template.id`
//     which will be available only once the `template` request has completed.
//
// *****************************************************************************
// *****************************************************************************

const TemplateContext = createContext()
TemplateContext.displayName = "TemplateContext"

// eslint-disable-next-line react/prop-types
export const TemplateContextProvider = ({children, templateId, template: propsTemplate}) => {
  const [template, setTemplate] = useState(propsTemplate)
  const [templateLoading, setTemplateLoading] = useState()

  const refreshTemplate = templateId => {
    setTemplateLoading(true)
    fetchTemplate(templateId, {hasSurveyContentBlocks: true}).then(t => {
      setTemplate(t)
      setTemplateLoading(false)
    })
  }

  useEffect(() => refreshTemplate(templateId), [templateId])

  const value = {
    template,
    templateId,
    templateLoading,
    refreshTemplate,
  }

  return <TemplateContext.Provider value={value}>{children}</TemplateContext.Provider>
}

export {TemplateContext}

export const useTemplateContext = () => useContext(TemplateContext)

export const templateContextProvider = Component => props => (
  // FIXME disabled-react-prop-types
  // eslint-disable-next-line react/prop-types
  <TemplateContextProvider templateId={props.match.params.templateId}>
    <Component {...props} />
  </TemplateContextProvider>
)

export const templateContext = Component => props => (
  <TemplateContext.Consumer>
    {value => <Component {...value} {...props} />}
  </TemplateContext.Consumer>
)
