import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import {bool, func} from "prop-types"
import {useState} from "react"

import SaveButton from "components/save-button/save-button"

const DuplicateConfirmationDialog = ({onClose, onDuplicate, open}) => {
  const [submitting, setSubmitting] = useState(false)

  const onDuplicateClicked = () => {
    setSubmitting(true)
    onDuplicate().catch(onClose)
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Confirm duplication</DialogTitle>
      <DialogContent>
        Duplicating this will make it unique to this campaign. Any edits you make moving forward
        will <b>no longer</b> affect any campaigns that share this content.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <SaveButton
          failed={false}
          onClick={onDuplicateClicked}
          stateLabels={{
            default: "Duplicate",
            submitting: "Duplicating...",
            saved: "Duplicated!",
          }}
          submitting={submitting}
        />
      </DialogActions>
    </Dialog>
  )
}

DuplicateConfirmationDialog.propTypes = {
  onClose: func.isRequired,
  onDuplicate: func.isRequired,
  open: bool.isRequired,
}

export default DuplicateConfirmationDialog
