export const initialState = {
  account: null,
  accounts: null,
  analyticsCache: null,
  contact: null,
  journeyId: null,
  journeySlug: null,
  owner: null,
  rewards: null,
  survey: null,
  template: null,
  templateId: null,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_REWARDS":
      return {
        ...state,
        rewards: {...state.rewards, ...action.rewardSets},
      }

    case "UPDATE_LEGACY_INITIAL_STATE":
      return {
        ...state,
        ...action.legacyInitialState,
      }

    default:
      throw new Error(`Unhandled action type of ${action.type}`)
  }
}
