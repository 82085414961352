import {Badge, Card, CardContent, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, oneOf, shape, string} from "prop-types"
import {MdCheck as SelectedIcon} from "react-icons/md"

import ContentLibraryImage from "./content-library-image"
import {humanizeType} from "./helpers"
import SmsBubble from "./sms-bubble"

const useStyles = makeStyles(theme => ({
  cardRoot: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    cursor: "pointer",
    boxShadow: "none",
    height: 200,
    "& p": {
      fontSize: 14,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: theme.palette.text.hint,
    },
    "& > :last-child": {
      paddingBottom: 0,
    },
  },
  cardContent: {
    padding: 0,
  },
  info: {
    padding: theme.spacing(1),
  },
}))

const MessageLibraryItem = ({onSelect, isSelected, item}) => {
  const {bodyPreview, id, type, contentName, teamId} = item

  const classes = useStyles()

  return (
    <Badge badgeContent={<SelectedIcon size={8} />} color="primary" invisible={!isSelected}>
      <Card classes={{root: classes.cardRoot}} onClick={() => onSelect(item)} title={contentName}>
        <CardContent className={classes.cardContent}>
          {type === "sms" && id !== "new" ? (
            <SmsBubble text={bodyPreview} />
          ) : (
            <ContentLibraryImage contentType={type} id={id} teamId={teamId || ""} />
          )}
          <Typography>{contentName}</Typography>
          {id !== "new" && <Typography>Type: {humanizeType(type)}</Typography>}
        </CardContent>
      </Card>
    </Badge>
  )
}

MessageLibraryItem.propTypes = {
  isSelected: bool,
  item: shape({
    id: string.isRequired,
    teamId: string,
    contentName: string.isRequired,
    type: oneOf(["sms", "email"]),
  }),
  onSelect: func,
}

export default MessageLibraryItem
