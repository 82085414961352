import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {useEffect, useRef, useState} from "react"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"

const HtmlReadonly = ({className, contentBlock, customCss, isEditMode, onClick, style}) => {
  const classes = useStyles()

  const [hasNoDisplayHeight, setHasNoDisplayHeight] = useState(true)
  const containerRef = useRef(null)

  const {
    data: {code: __html, css, js},
  } = contentBlock

  const loadJS = url => {
    const source = document.createElement("script")

    source.src = url
    source.async = true
    document.body.appendChild(source)
  }

  const isDisplayEmpty = node => {
    const computedStyle = getComputedStyle(node)
    const height = node.getBoundingClientRect().height // height with padding
    const padding = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)
    return height - padding === 0 || node?.innerText?.trim() === ""
  }

  useEffect(() => {
    const jsArray = !js ? [] : js.split(/\s+/)

    jsArray.forEach(loadJS)
  }, [js])

  useEffect(() => {
    setHasNoDisplayHeight(!!isDisplayEmpty(containerRef?.current))
  }, [__html]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={cx(className, {
        [classes.emptyBackground]: isEditMode && (__html === "" || hasNoDisplayHeight),
      })}
      data-testid="html-root"
      onClick={onClick}
      ref={containerRef}
      style={style}
    >
      {css.split(/\s+/).map((cssUrl, i) => (
        <link href={cssUrl} key={i} rel="stylesheet" />
      ))}
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <div dangerouslySetInnerHTML={{__html}} />
    </div>
  )
}

HtmlReadonly.propTypes = {
  className: string,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  isEditMode: bool,
  onClick: func,
  style: object,
}

const useStyles = makeStyles(theme => ({
  emptyBackground: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50' width='120'><text x='60' y='25' fill='${theme.palette.text.hint}' font-size='20' font-family='sans-serif' text-anchor='middle' alignment-baseline='central'>HTML</text></svg>")`,
    height: 75,
    border: `1px dashed ${theme.palette.text.hint}`,
  },
}))

export default contentBlockEditorConsumer(HtmlReadonly)
