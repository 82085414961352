export const COLUMN_OPTIONS = [
  {isPrivate: true, label: "Account Number", value: "account.accountNumber"},
  {isPrivate: true, label: "Adverse Status", value: "account.adverseStatus"},
  {label: "APY", value: "account.apy"},
  {label: "Balance", value: "account.balance"},
  {isPrivate: true, label: "Channel Opened", value: "account.channelOpened"},
  {isPrivate: true, label: "Charge Off", value: "account.chargeOff"},
  {isPrivate: true, label: "Closed Reason", value: "account.closedReason"},
  {label: "Date Closed", value: "account.closedDate"},
  {isPrivate: true, label: "Credit Card Activation", value: "account.creditCardActivation"},
  {
    isPrivate: true,
    label: "In Person Check Deposit Date",
    value: "account.inPersonDepositCheckDate",
  },
  {isPrivate: true, label: "Last Mobile Pay Amount", value: "account.lastMobilePayAmount"},
  {label: "Last Mobile Pay Transaction", value: "account.lastMobilePayTransaction"},
  {
    isPrivate: true,
    label: "Last Debit Card Transaction",
    value: "account.lastDebitCardTransaction",
  },
  {isPrivate: true, label: "Last Statement Balance", value: "account.lastStatementBalance"},
  {isPrivate: true, label: "Last Transaction Amount", value: "account.lastTransactionAmount"},
  {label: "Last Transaction Date", value: "account.lastTransactionDate"},
  {label: "Loan Payment", value: "account.loanPayment"},
  {isPrivate: true, label: "Loan Payoff Date", value: "account.loanPayoffDate"},
  {label: "Loan Principal", value: "account.loanPrincipal"},
  {isPrivate: true, label: "Loan Term", value: "account.loanTerm"},
  {isPrivate: true, label: "Maturity Date", value: "account.maturityDate"},
  {isPrivate: true, label: "MICR", value: "account.micr"},
  {label: "Nickname", value: "account.nickname"},
  {
    isPrivate: true,
    label: "Number of Transactions Last 30 Days",
    value: "account.numberTransactionsLast30Days",
  },
  {label: "Date Opened", value: "account.openedDate"},
  {label: "Due Date", value: "account.paymentDueDate"},
  {label: "Payment Frequency", value: "account.paymentFrequency"},
  {isPrivate: true, label: "Payment Method", value: "account.paymentMethod"},
  {isPrivate: true, label: "Product Description", value: "account.product.description"},
  {isPrivate: true, label: "Product Name", value: "account.product.name"},
  {isPrivate: true, label: "Product Type", value: "account.product.type"},
  {label: "Remote Deposit Last 30 Days", value: "account.remoteDepositLast30Days"},
  {label: "Role", value: "account.contact_account.role"},
  {isPrivate: true, label: "Routing Number", value: "account.routingNumber"},
  {isPrivate: true, label: "Account Status Code", value: "account.statusCode"},
  {
    isPrivate: true,
    label: "Amount of Transactions Last 30 Days",
    value: "account.totalAmountLast30Days",
  },
  {label: "First Name (Contact)", value: "contact.nameFirst"},
  {label: "Last Name (Contact)", value: "contact.nameLast"},
  {label: "Full Name (Contact)", value: "contact.nameFull"},
  {isPrivate: true, label: "Unique ID (Contact)", value: "contact.uniqueId"},
]
