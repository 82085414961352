import {createObjective, fetchObjectives} from "lib/api"
import apiActionCreator from "lib/api-action-creator"

import {loadingStateCompleted, loadingStateStarted} from "../../actions/loading-state-actions"

export const OBJECTIVE_LIST_SET = "OBJECTIVE_LIST_SET"
export const OBJECTIVE_LIST_CLEARED = "OBJECTIVE_LIST_CLEARED"
export const OBJECTIVE_CREATED = "OBJECTIVE_CREATED"

export const getObjectives = apiActionCreator(OBJECTIVE_LIST_SET, fetchObjectives)

export const onCreateObjective = attrs => dispatch => {
  dispatch(loadingStateStarted(OBJECTIVE_CREATED, "Adding Objective..."))
  createObjective(attrs).then(objective => {
    dispatch({type: OBJECTIVE_CREATED, payload: objective})
    dispatch(loadingStateCompleted(OBJECTIVE_CREATED))
  })
}

export const clearObjectives = () => ({type: OBJECTIVE_LIST_CLEARED})
