import LinearProgress from "@mui/material/LinearProgress"
import {styled} from "@mui/material/styles"
import {bool, number, shape, string} from "prop-types"
import React from "react"

import ProgressData from "./progress-data"

const RewardLine = styled(LinearProgress)(({branding, completed}) => ({
  backgroundColor: branding.trackColor,
  "& .MuiLinearProgress-bar": {
    backgroundColor: completed === "true" ? branding.completedColor : branding.inProgressColor,
  },
}))

const RewardLinear = ({
  accrued,
  caption,
  progressType,
  slug,
  unit,
  completed,
  branding,
  completedPercentage,
}) => (
  <>
    <ProgressData
      caption={caption}
      progressType={progressType}
      slug={slug}
      unit={unit}
      value={accrued}
      branding={branding}
    />
    <RewardLine
      size="100%"
      thickness={3}
      value={completedPercentage}
      variant="determinate"
      branding={branding}
      completed={completed.toString()}
    />
  </>
)

RewardLinear.propTypes = {
  accrued: number,
  caption: string,
  progressType: string,
  slug: string,
  unit: string,
  completed: bool,
  completedPercentage: number,
  branding: shape({
    valueFontFamily: string,
    valueFontSize: string,
    valueTextColor: string,
    captionFontFamily: string,
    captionFontSize: string,
    captionTextColor: string,
    inProgressColor: string,
    completedColor: string,
    trackColor: string,
    widgetBackgroundColor: string,
  }),
}

export default RewardLinear
