import {Box, LinearProgress, Typography} from "@mui/material"
import cx from "classnames"
import {func, number, object, string} from "prop-types"
import {FiAlertTriangle as AlertIcon} from "react-icons/fi"

import useFeatures from "lib/hooks/use-features"

import Counter from "../counter/counter"
import TargetingImpactDate from "./targeting-impact-date"
import TargetingSelfServiceInput from "./targeting-self-service-input"
import {useTemplateTargeting} from "./template-targeting-context"
import {FORM_STATES} from "./template-targeting-helpers"

const TargetingImpactMessage = ({
  classes,
  onComplete,
  selfServiceTargetingLimit,
  setSelfServiceTargetingLimit,
  setShowSelfServiceTargetingLimit,
  state,
  targetingLimit,
}) => {
  const {hasFeature} = useFeatures()

  const {targetingImpact} = useTemplateTargeting()
  const {numContacts: impactCount, completedAt: impactCompletedAt} = targetingImpact || {
    numContacts: 0,
  }

  switch (state) {
    case FORM_STATES.DIRTY:
      return (
        <Typography>
          Your targeting conditions have changed. To continue with saving them, you need to check
          the impact of your targeting conditions and confirm the number of contacts found are
          correct.
        </Typography>
      )
    case FORM_STATES.CHECKING_IMPACT:
      return (
        <>
          <Typography>
            We're analyzing your contacts and accounts to determine how many will be added to this
            campaign if you save these targeting conditions. We'll save the results along with the
            time that the Impact calculations were completed for you to review, so feel free to come
            back to this page later...
          </Typography>
          <LinearProgress
            classes={{
              root: classes.linearProgressRoot,
              bar: classes.linearProgressBar,
            }}
          />
        </>
      )
    case FORM_STATES.REMOVE:
      return (
        <Typography>
          Removing all targeting conditions will make this campaign unreachable from targeting.
          Journeys will only be added to the campaign if added manually (via a batch upload to the
          campaign or with a `template_id` parameter in a contact batch)
        </Typography>
      )
    case FORM_STATES.PRIOR_IMPACT:
      return (
        <>
          <Typography variant={"h4"} sx={{p: 1, borderRadius: 1}}>
            These targeting conditions will create...
          </Typography>
          <Box sx={{display: "flex", mb: 4}}>
            <Box style={{flex: 1, textAlign: "center"}}>
              <Counter className={classes.counterLarge} initial={0} onComplete={onComplete}>
                {impactCount}
              </Counter>
              journeys
            </Box>
            <Box display={{flex: 2}}>
              {hasFeature("campaign-approval") && false ? (
                <Typography>
                  These targeting conditions will result in{" "}
                  <Counter initial={0}>{impactCount}</Counter> journeys being created in this
                  campaign when it is launched. The number of contacts added could change as your
                  data changes. If contacts that are added or updated in the future also match these
                  conditions they will be added to this campaign at that point.
                </Typography>
              ) : (
                <Typography>
                  These targeting conditions will result in{" "}
                  <Counter initial={0}>{impactCount}</Counter> journeys being created in this
                  campaign based on your data as of{" "}
                  <TargetingImpactDate completedAt={impactCompletedAt} />. If contacts that are
                  added or updated in the future also match these conditions, they will be added to
                  this campaign at that point.
                </Typography>
              )}
            </Box>
          </Box>

          {impactCount > targetingLimit && (
            <TargetingSelfServiceInput
              impactCount={impactCount}
              selfServiceTargetingLimit={selfServiceTargetingLimit}
              setSelfServiceTargetingLimit={setSelfServiceTargetingLimit}
              setShowSelfServiceTargetingLimit={setShowSelfServiceTargetingLimit}
              targetingLimit={targetingLimit}
            />
          )}
        </>
      )
    case FORM_STATES.ERROR:
      return (
        <>
          <AlertIcon className={classes.alertIcon} />{" "}
          {impactCount > 0 && (
            <Counter className={cx(classes.counterLarge, classes.alert)} initial={0}>
              {impactCount}
            </Counter>
          )}
          <Typography>
            {impactCount > 0 ? (
              <>
                These targeting conditions found <Counter initial={0}>{impactCount}</Counter>
                contacts but errors were encountered. These conditions can't be saved because they
                would stop working after the initial contacts found are added to the campaign. This
                usually happens because of inconsistently formatted data in your contact data for
                the fields you are trying to target.
              </>
            ) : (
              <>
                {/* TODO make this smarter. Sometimes we know what the error is, eg empty targeting group */}
                There was an error processing these targeting conditions and they can't be saved.
                This usually happens when a targeting condition represents something that is not
                possible (eg searching for contacts who have an email address within the last{" "}
                <i>x</i> days).
              </>
            )}
          </Typography>
        </>
      )
    default:
      return null
  }
}

TargetingImpactMessage.propTypes = {
  classes: object,
  onComplete: func,
  selfServiceTargetingLimit: number,
  setSelfServiceTargetingLimit: func,
  setShowSelfServiceTargetingLimit: func,
  state: string,
  targetingLimit: number,
}

export default TargetingImpactMessage
