import {createContext, useContext} from "react"

const TemplatePageContext = createContext()
TemplatePageContext.displayName = "TemplatePageContext"

export const useTemplatePageContext = () => useContext(TemplatePageContext)

export const templatePageContext = Component => props => (
  <TemplatePageContext.Consumer>
    {context => <Component {...props} {...context} />}
  </TemplatePageContext.Consumer>
)

export default TemplatePageContext
