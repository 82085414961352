import {Paper as MuiPaper, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {node, object} from "prop-types"

import BackButton from "../back-button/back-button"

const Paper = styled(MuiPaper)`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;

  & a {
    border-radius: 3px;
  }

  & .controls a,
  & .controls button {
    margin-left: 10px;
    text-transform: none;
    font-weight: normal;
    font-size: 13px;
  }

  & .controls a svg,
  & .controls button svg {
    margin-right: 10px;
  }

  & .title {
    font-weight: 300;
    font-size: 26px;
    color: #29323d;
    clear: both;
    flex: 1 1 auto;
    min-width: 0;
    line-height: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 16px 0;
  }
`

const TitleBar = ({children, title, backButtonAttrs}) => (
  <Paper>
    {backButtonAttrs && <BackButton {...backButtonAttrs} />}

    <Typography className="title" component="h1" data-testid="title">
      {title}
    </Typography>

    <div className="controls">{children}</div>
  </Paper>
)

TitleBar.propTypes = {
  backButtonAttrs: object,
  children: node,
  title: node,
}

export default TitleBar
