import {push} from "connected-react-router"

import {resetPassword} from "lib/api"
import SubmissionError from "lib/submission-error"

export const RESET_PASSWORD_ERRORED = "RESET_PASSWORD_ERRORED"

export const onSubmit = attrs => dispatch =>
  resetPassword(attrs)
    .then(() => dispatch(push("/admin/login")))
    .catch(({body}) => {
      if (body.errors) throw new SubmissionError(body.errors)

      dispatch({
        type: RESET_PASSWORD_ERRORED,
        payload: "Sorry, it looks like something went wrong. Please try again in a moment.",
      })
    })
