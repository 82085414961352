const SET_SOCKET = "SET_SOCKET"
const SET_TEAM_ID = "SET_TEAM_ID"
const ADD_HANDLER = "ADD_HANDLER"
const REMOVE_HANDLER = "REMOVE_HANDLER"

const setupChannel = (teamId, socket, {name, handlers}) => {
  // TODO: Make SocketManager not assume every channel's id is the teamId
  // When this was written, all the rooms/channels/topics/whatevers were at the
  // team level, and were all entity:teamId. Let's remove teamId from inside here
  // so users of this component just have to pass in the teamId as part of
  // the name. This will probably look like everything that is on the team right
  // now will be in the room team:teamId with different names.
  name = name.includes(":") ? name : `${name}:${teamId}`

  const channel = socket.channel(name)

  channel.join()
  return {
    name,
    channel,
    handlers: handlers.map(handler => setupHandler(channel, handler)),
  }
}

const setupHandler = (channel, {event, id, callback}) => ({
  id,
  event,
  ref: channel.on(event, callback),
})

const hasChannelNamed = (channels, nameToSearchFor) =>
  channels.find(({name}) => name === nameToSearchFor)

const socketManagerReducer = (state, action) => {
  switch (action.type) {
    case SET_TEAM_ID:
      return {...state, teamId: action.payload.teamId}

    case SET_SOCKET:
      if (state.pendingChannels.length !== 0)
        return {
          ...state,
          socket: action.payload.socket,
          pendingChannels: [],
          channels: [
            ...state.channels,
            ...state.pendingChannels.map(pendingChannel =>
              setupChannel(state.teamId, action.payload.socket, pendingChannel)
            ),
          ],
        }

      return {
        ...state,
        socket: action.payload.socket,
      }

    case ADD_HANDLER: {
      if (!state.socket && !hasChannelNamed(state.pendingChannels, action.payload.channelName))
        return {
          ...state,
          pendingChannels: [
            ...state.pendingChannels,
            {name: action.payload.channelName, handlers: [action.payload]},
          ],
        }

      if (!state.socket && hasChannelNamed(state.pendingChannels, action.payload.channelName))
        return {
          ...state,
          pendingChannels: state.pendingChannels.map(pendingChannel => {
            if (pendingChannel.name === action.payload.channelName)
              return {
                ...pendingChannel,
                handlers: [...pendingChannel.handlers, action.payload],
              }

            return pendingChannel
          }),
        }

      if (state.socket && !hasChannelNamed(state.channels, action.payload.channelName))
        return {
          ...state,
          channels: [
            ...state.channels,
            setupChannel(state.teamId, state.socket, {
              name: action.payload.channelName,
              handlers: [action.payload],
            }),
          ],
        }

      return {
        ...state,
        channels: state.channels.map(connectedChannel => {
          if (connectedChannel.name === action.payload.channelName)
            return {
              ...connectedChannel,
              handlers: [
                ...connectedChannel.handlers,
                setupHandler(connectedChannel.channel, action.payload),
              ],
            }

          return connectedChannel
        }),
      }
    }

    case REMOVE_HANDLER: {
      if (!state.socket)
        return {
          ...state,
          pendingChannels: state.pendingChannels.map(pendingChannel => {
            if (pendingChannel.name === action.payload.channelName)
              return {
                ...pendingChannel,
                handlers: pendingChannel.handlers.filter(({id}) => id !== action.payload.id),
              }

            return pendingChannel
          }),
        }

      return {
        ...state,
        channels: state.channels
          .map(connectedChannel => {
            if (connectedChannel.name === action.payload.channelName)
              return {
                ...connectedChannel,
                handlers: connectedChannel.handlers.filter(handler => {
                  if (handler.id === action.payload.id)
                    connectedChannel.channel.off(handler.event, handler.ref)

                  return handler.id !== action.payload.id
                }),
              }

            return connectedChannel
          })
          .filter(({channel, handlers}) => {
            if (handlers.length === 0) channel.leave()

            return handlers.length > 0
          }),
      }
    }

    default:
      throw new Error("Unknown type specified")
  }
}

export default socketManagerReducer
