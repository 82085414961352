import isMissingPageSlugs from "lib/isMissingPageSlugs"

export const initialState = {
  templatePages: [],
  templateMessages: [],
  timelineMarkers: [],
}

export const actions = {
  setTemplatePages: "TEMPLATE_PAGES_SET",
  setTemplatePagesLoading: "TEMPLATE_PAGES_LOADING_SET",

  setTemplateMessages: "TEMPLATE_MESSAGES_SET",
  addTemplateMessageToState: "TEMPLATE_MESSAGE_ADDED",
  removeTemplateMessageFromState: "TEMPLATE_MESSAGE_REMOVED",
  updateTemplateMessageInState: "TEMPLATE_MESSAGE_UPDATED",
  setTemplateMessagesLoading: "TEMPLATE_MESSAGES_LOADING_SET",

  setTimelineMarkers: "TIMELINE_MARKERS_SET",
  addTimelineMarkerToState: "TIMELINE_MARKER_ADDED",
  removeTimelineMarkerFromState: "TIMELINE_MARKER_REMOVED",
  updateTimelineMarkerInState: "TIMELINE_MARKER_UPDATED",
  setTimelineMarkersLoading: "TIMELINE_MARKERS_LOADING_SET",

  setPendingTemplateMessages: "PENDING_TEMPLATE_MESSAGES_SET",
  setTemplateMessageToEdit: "TEMPLATE_MESSAGE_TO_EDIT_SET",
  setTemplateMessageToRemove: "TEMPLATE_MESSAGE_TO_REMOVE_SET",
}

const mismatchesPageObjective = (templateMessage, templatePages) => {
  if (!templateMessage.templatePageId) return false

  const page = templatePages.find(tp => tp.id === templateMessage.templatePageId)?.page

  return templateMessage.message.objectiveId !== page?.objectiveId
}

const extend = (templateMessage, templatePages) => ({
  ...templateMessage,
  isMissingPageSlugs: isMissingPageSlugs(templateMessage, templatePages),
  mismatchesPageObjective: mismatchesPageObjective(templateMessage, templatePages),
})

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.setTemplatePages:
      return {
        ...state,
        templatePages: action.payload,
        templateMessages: state.templateMessages.map(tm => extend(tm, action.payload)),
      }

    case actions.setTemplateMessages:
      return {...state, templateMessages: action.payload.map(tm => extend(tm, state.templatePages))}
    case actions.removeTemplateMessageFromState:
      return {
        ...state,
        templateMessages: state.templateMessages
          .filter(tm => tm.id !== action.payload)
          .map(tm => extend(tm, state.templatePages)),
      }
    case actions.updateTemplateMessageInState:
      return {
        ...state,
        templateMessages: state.templateMessages
          .map(tm => (tm.id === action.payload.id ? action.payload : tm))
          .map(tm => extend(tm, state.templatePages)),
      }
    case actions.addTemplateMessageToState:
      return {
        ...state,
        templateMessages: [...state.templateMessages, action.payload].map(tm =>
          extend(tm, state.templatePages)
        ),
      }

    case actions.setTimelineMarkers:
      return {...state, timelineMarkers: action.payload}
    case actions.removeTimelineMarkerFromState:
      return {
        ...state,
        timelineMarkers: state.timelineMarkers.filter(marker => marker.id !== action.payload),
      }
    case actions.updateTimelineMarkerInState:
      return {
        ...state,
        timelineMarkers: state.timelineMarkers.map(marker =>
          marker.id === action.payload.id ? action.payload : marker
        ),
      }
    case actions.addTimelineMarkerToState:
      return {
        ...state,
        timelineMarkers: [...state.timelineMarkers, action.payload],
      }

    case actions.setTemplatePagesLoading:
      return {...state, templatePagesLoading: action.payload}
    case actions.setTimelineMarkersLoading:
      return {...state, timelineMarkersLoading: action.payload}
    case actions.setTemplateMessagesLoading:
      return {...state, templateMessagesLoading: action.payload}

    case actions.setTemplateMessageToEdit:
      return {...state, templateMessageToEdit: action.payload}
    case actions.setTemplateMessageToRemove:
      return {...state, templateMessageToRemove: action.payload}

    default:
      throw new Error(action.type)
  }
}
