import {string} from "prop-types"
import {connect} from "react-redux"

import {auditLogsItemsPerPage} from "../audit-logs/audit-logs"
import {getAuditLogActions} from "../audit-logs/audit-logs-actions.js"

const mapStateToProps = ({auditLogs, session}) => ({
  eventActions: auditLogs.eventActions,
  currentUser: session.user,
  users: session.teamUsers,
})

const addActionsToProps = {getAuditLogActions}

const AuditLogs = auditLogsItemsPerPage(10)

const TemplateAuditLogs = ({templateId, ...props}) => (
  <AuditLogs
    entityFilter={{templateId}}
    headers={[
      {field: "action", isDefault: true},
      {field: "user", isDefault: true},
      {field: "location", sortable: false, isDefault: true},
      {field: "timestamp", isDefault: true},
    ]}
    title="My Audit Logs"
    {...props}
  />
)

TemplateAuditLogs.propTypes = {
  templateId: string.isRequired,
}

export default connect(mapStateToProps, addActionsToProps)(TemplateAuditLogs)
