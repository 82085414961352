import {Paper, Step, StepLabel, Stepper} from "@mui/material"
import {bool, object, string} from "prop-types"
import {Component} from "react"

import {fetchObjectives} from "lib/api"

import BatchUploadContacts from "./batch-upload-contacts"
import BatchUploadJourneysContainer from "./batch-upload-journeys-container"
import FieldSelector from "./field-selector"
import UploadFinished from "./upload-finished"
import Upload from "./upload/upload"

const steps = ["Choose CSV", "Review", "Upload", "Finish"]

const initialState = {
  uploadData: null,
  activeStep: 0,
  objectives: [],
  meta: {},
}

class BatchUploader extends Component {
  state = initialState

  componentDidMount() {
    fetchObjectives().then(objectives => {
      this.setState({objectives})
    })
  }

  onContinue = (data, batchId) => {
    this.setState(state => ({
      uploadData: data,
      activeStep: state.activeStep + 1,
      batchId: batchId || state.batchid,
    }))
  }

  onReset = () => {
    this.setState(({objectives}) => ({
      ...initialState,
      objectives,
    }))
  }

  getStepContent = () => {
    const {activeStep, batchId, uploadData, objectives} = this.state
    const {isJourneyUploader, templateId, match} = this.props

    switch (activeStep) {
      case 0:
        const props = {objectives, onContinue: this.onContinue, match}

        // Uploaders here are used for distinct UI purposes. isJourneyUploader switches them:
        // - Journey uploader creates journeys for upserted contacts
        // - Contacts uploader upserts contacts or upserts accounts associated to contacts
        return isJourneyUploader ? (
          <BatchUploadJourneysContainer {...props} />
        ) : (
          <BatchUploadContacts {...props} />
        )
      case 1:
        return (
          <FieldSelector
            data={uploadData}
            objectives={objectives}
            onContinue={this.onContinue}
            onReset={this.onReset}
          />
        )
      case 2:
        return (
          <Upload
            data={uploadData}
            onContinue={this.onContinue}
            onReset={this.onReset}
            templateId={templateId}
          />
        )
      case 3:
        return (
          <UploadFinished
            batchId={batchId}
            data={uploadData}
            onReset={this.onReset}
            templateId={templateId}
          />
        )

      default:
        return null
    }
  }

  render() {
    const {activeStep} = this.state

    return (
      <Paper elevation={0}>
        <Stepper activeStep={activeStep} style={{padding: 24}}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>{this.getStepContent()}</div>
      </Paper>
    )
  }
}

BatchUploader.propTypes = {
  classes: object,
  match: object,
  template: object,
  templateId: string,
  isJourneyUploader: bool,
}

export default BatchUploader
