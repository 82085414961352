import queryString from "query-string"
import {connect} from "react-redux"

import {TEMPLATE_LOADED, isLoaded} from "lib/loading-states"

import {
  LANDING_PAGE_SET,
  getLandingPage,
  onLpCreateJourney,
} from "../landing-page/landing-page-actions"
import {getTemplate} from "../templates/template-actions"
import LandingPage from "./landing-page"

const mapStateToProps = (
  {landingPage, loadingStates, template},
  {
    location: {search},
    match: {
      params: {templateId},
    },
  }
) => ({
  isLoaded: isLoaded(TEMPLATE_LOADED, loadingStates) && isLoaded(LANDING_PAGE_SET, loadingStates),
  landingPage,
  networkError: landingPage.error,
  queryParams: queryString.parse(search),
  success: landingPage.success,
  template,
  templateId,
})

const mapDispatchToProps = {
  getLandingPage,
  getTemplate,
  onLpCreateJourney,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
