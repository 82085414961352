import {FormControl, FormHelperText, InputLabel, MenuItem, TextField} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import {func, object} from "prop-types"

import ResetDefaultValue from "components/templates/reset-default-value"

import {formify} from "lib/hooks/use-form"

import ColorInput from "../color-input/color-input"
import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import FormActions from "../content-block-editor/plugins/form-actions"
import DOSelect from "../do-select/do-select"

const cssNumberText = "(Accepts CSS numbers like 10px, 50%, etc)"

const WidgetCssSettings = ({classes, field, handleSubmit, resetForm}) => {
  const muiTheme = useTheme()
  const defaultBackgroundColor = muiTheme.palette.background.paper
  const defaultBorderColor = muiTheme.palette.background.paper

  const backgroundColor = field("styles.backgroundColor", {defaultValue: defaultBackgroundColor})
  const borderColor = field("styles.borderColor", {defaultValue: defaultBorderColor})
  const color = field("styles.color")

  const onColorChange = eventHandler => value =>
    eventHandler.onChange({target: {name: eventHandler.name, value}})

  return (
    <form style={{width: "100%"}} onSubmit={handleSubmit}>
      <FormHelperText>Customize the base styles of this content block.</FormHelperText>
      <TextField
        autoFocus={true}
        className={classes.inputContainer}
        fullWidth={true}
        helperText={`The space between this widget and its neighbors. ${cssNumberText}`}
        id="widget-style-margin"
        label="Margin"
        margin="normal"
        {...field("styles.margin")}
      />
      <TextField
        className={classes.inputContainer}
        fullWidth={true}
        helperText={`The space between the widget's content and its edges. If this widget has a background color set, the area it takes up will increase if you set a padding value. ${cssNumberText}`}
        id="widget-style-padding"
        label="Padding"
        margin="normal"
        {...field("styles.padding")}
      />
      <FormControl fullWidth={true} className={classes.inputContainer}>
        <InputLabel id="widget-style-text-align">Alignment</InputLabel>
        <DOSelect
          className={classes.field}
          id="widget-style-text-align"
          limitHeight={false}
          {...field("styles.textAlign")}
        >
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </DOSelect>
        <FormHelperText>
          This setting might get overriden by the styling of components inside the widget.
        </FormHelperText>
      </FormControl>
      <ColorInput
        className={classes.inputContainer}
        fullWidth={true}
        id="widget-style-background-color"
        input={{...backgroundColor, onChange: onColorChange(backgroundColor)}}
        label={
          <ResetDefaultValue
            {...backgroundColor}
            defaultValue={defaultBackgroundColor}
            reset={onColorChange(backgroundColor)}
            text="Background Color"
          />
        }
      />
      <div className={classes.inputContainer}>
        <ColorInput
          fullWidth={true}
          id="widget-style-text-color"
          input={{...color, onChange: onColorChange(color)}}
          label="Text Color"
        />
        <FormHelperText>
          This setting might get overriden by the styling of components inside the widget.
        </FormHelperText>
      </div>
      <FormControl fullWidth={true} className={classes.inputContainer}>
        <InputLabel id="widget-style-border-style">Border Style</InputLabel>
        <DOSelect
          className={classes.field}
          id="widget-style-border-style"
          limitHeight={false}
          {...field("styles.borderStyle")}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="hidden">Hidden</MenuItem>
          <MenuItem value="dotted">Dotted</MenuItem>
          <MenuItem value="dashed">Dashed</MenuItem>
          <MenuItem value="solid">Solid</MenuItem>
          <MenuItem value="double">Double</MenuItem>
          <MenuItem value="groove">Groove</MenuItem>
          <MenuItem value="ridge">Ridge</MenuItem>
          <MenuItem value="inset">Inset</MenuItem>
          <MenuItem value="outset">Outset</MenuItem>
        </DOSelect>
      </FormControl>
      <TextField
        className={classes.inputContainer}
        fullWidth={true}
        id="widget-style-border-width"
        label="Border Width"
        margin="normal"
        helperText={cssNumberText}
        {...field("styles.borderWidth")}
      />
      <ColorInput
        className={classes.inputContainer}
        fullWidth={true}
        id="widget-style-border-color"
        input={{...borderColor, onChange: onColorChange(borderColor)}}
        label={
          <ResetDefaultValue
            {...borderColor}
            defaultValue={defaultBorderColor}
            reset={onColorChange(borderColor)}
            text="Border Color"
          />
        }
      />
      <TextField
        className={classes.inputContainer}
        fullWidth={true}
        id="widget-style-border-radius"
        label="Border Radius"
        margin="normal"
        helperText={cssNumberText}
        {...field("styles.borderRadius")}
      />
      <FormActions resetForm={resetForm} />
    </form>
  )
}

WidgetCssSettings.propTypes = {
  classes: object.isRequired,
  device: object.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func.isRequired,
}
const monospace = `"Monaco","Consolas","Lucida Console",monospace`

const styles = theme => ({
  inputContainer: {
    marginTop: 0,
    marginBottom: theme.spacing(3.5),
  },
  cssEditor: {
    fontFamily: monospace,
  },
  errorText: {
    display: "block",
    color: theme.palette.error.main,
    fontFamily: monospace,
  },
})

const validCssNumber = value => {
  if (!value) return null

  const values = value.trim().split(/\s+/)

  if (values.length > 4) return "Too many values"

  if (
    !values.every(number =>
      /^-?(?:0|[\d.]+(?:cm|mm|in|pc|pt|px|%|r?em|ch|lh|vh|vw|vmin|vmax))$/.test(number)
    )
  )
    return "Invalid CSS Number"
}

const WidgetCssSettingsForm = formify({
  validators: {
    "styles.margin": [validCssNumber],
    "styles.padding": [validCssNumber],
    "styles.borderWidth": [validCssNumber],
    "styles.borderRadius": [validCssNumber],
  },
})(WidgetCssSettings)

export default withStyles(styles)(contentBlockEditorConsumer(WidgetCssSettingsForm))
