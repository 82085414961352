import {Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {node, object} from "prop-types"

import {journeyContext} from "components/journeys/journey-context"

const Blockquote = ({classes, themeClasses, children}) => (
  <Typography
    className={themeClasses ? themeClasses.blockquote : classes.blockquote}
    component="blockquote"
    variant="h6"
  >
    {children}
  </Typography>
)

Blockquote.propTypes = {
  children: node,
  classes: object,
  themeClasses: object,
}

const styles = {
  blockquote: {
    borderLeft: "2px solid #ddd",
    margin: 0,
    padding: "6px 0 5px 18px",
    color: "#999",
    fontStyle: "italic",
    fontSize: 20,
  },
}

export default withStyles(styles)(journeyContext(Blockquote))
