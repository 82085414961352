import {Badge, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, shape, string} from "prop-types"
import {FaExclamation} from "react-icons/fa"
import {SortableElement} from "react-sortable-hoc"

import {featurify} from "lib/hooks/use-features"

import {templateContentContext} from "../../contexts/template-content-context"
import MessageDragHandle from "./message-drag-handle"

const useClasses = makeStyles(() => ({
  message: {
    position: "relative",
    zIndex: 1300 /*keeps the ghost visible while dragging*/,
  },
  tile: {
    cursor: "pointer",
    padding: "10px 5px",
    background: "white",
    border: "1px solid gray",
    borderWidth: "0 0 1px 0",
    outline: "none",
    display: "flex",
    justifyContent: "space-between",
    width: 252 /* width: 100% doesn't work, only this triggers the overflow */,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))

export const TemplateMessage = ({
  templateMessage: {
    id,
    message: {contentName},
    isMissingPageSlugs,
    mismatchesPageObjective,
  },
  editTemplateMessage,
  hasFeature,
}) => {
  const classes = useClasses()

  return (
    <div className={classes.message}>
      <Badge
        badgeContent={<FaExclamation size={10} />}
        color="error"
        invisible={!mismatchesPageObjective && !isMissingPageSlugs}
      >
        <button className={classes.tile} onClick={() => editTemplateMessage(id)}>
          <Typography className={classes.text}>{contentName}</Typography>
          {!hasFeature("atomic-assets") && <MessageDragHandle />}
        </button>
      </Badge>
    </div>
  )
}

TemplateMessage.propTypes = {
  editTemplateMessage: func.isRequired,
  hasFeature: func.isRequired,
  templateMessage: shape({
    id: string.isRequired,
    isMissingPageSlugs: bool,
    message: shape({
      id: string.isRequired,
      contentName: string,
    }),
    mismatchesPageObjective: bool,
  }).isRequired,
}

export default SortableElement(featurify(templateContentContext(TemplateMessage)))
