import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import {alpha, darken} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {array, bool, func, object, string} from "prop-types"
import {Component} from "react"
import {FaCheckCircle as CompletedIcon} from "react-icons/fa"
import {MdHelp as HelpIcon} from "react-icons/md"

import {formatDate} from "lib/date-time-formatters"

import {controlAccess} from "../access-control/access-controlled"

const ContactObjectiveHeader = ({
  classes,
  statusFilter,
  onChangeStatusFilter,
  nameFilter,
  onChangeFilterText,
}) => (
  <div className={classes.header}>
    <TextField
      className={classes.filter}
      label="Filter"
      onChange={onChangeFilterText}
      value={nameFilter}
    />
    <Typography className={classes.label}>Show</Typography>
    <RadioGroup
      classes={{root: classes.groupRoot}}
      name="status"
      onChange={onChangeStatusFilter}
      value={statusFilter}
    >
      <FormControlLabel
        control={<Radio classes={{root: classes.radioButtonRoot}} color="primary" />}
        label="All"
        labelPlacement="end"
        value="all"
      />
      <FormControlLabel
        control={<Radio classes={{root: classes.radioButtonRoot}} color="primary" />}
        label="Incomplete"
        labelPlacement="end"
        value="incomplete"
      />
      <FormControlLabel
        control={<Radio classes={{root: classes.radioButtonRoot}} color="primary" />}
        label="Complete"
        labelPlacement="end"
        value="complete"
      />
      <FormControlLabel
        control={<Radio classes={{root: classes.radioButtonRoot}} color="primary" />}
        label={
          <Tooltip
            title={
              "Objectives that are part of a journey that has been sent, but the contact has not completed yet"
            }
          >
            <Typography>
              Presented <HelpIcon />
            </Typography>
          </Tooltip>
        }
        labelPlacement="end"
        value="presented"
      />
    </RadioGroup>
  </div>
)

ContactObjectiveHeader.propTypes = {
  classes: object,
  nameFilter: string,
  onChangeFilterText: func.isRequired,
  onChangeStatusFilter: func.isRequired,
  statusFilter: string,
}

class ContactObjectiveRow extends Component {
  onRowClick = () =>
    !this.props.disabled &&
    this.props.onToggleStatus(
      this.props.objectiveId,
      this.props.status === "complete" ? "incomplete" : "complete"
    )
  render() {
    const {classes, disabled, meta, status, timestamp} = this.props

    return (
      <li className={classes.objective}>
        <div
          className={cx(classes.button, {
            [classes.disabled]: disabled,
            [classes.disableCompleted]: disabled && status === "complete",
            [classes.completed]: !disabled && status === "complete",
          })}
          onClick={this.onRowClick}
        >
          <span style={status === "complete" ? {marginRight: 5} : {}}>{meta.name}</span>
          {status === "complete" && <CompletedIcon size={12} />}
        </div>
        <br />
        {(status === "presented" || status === "complete") && (
          <i className={classes.objectiveTime}>
            {status === "presented" ? "Presented" : "Completed"}{" "}
            {timestamp && formatDate(timestamp)}
          </i>
        )}
      </li>
    )
  }
}

ContactObjectiveRow.propTypes = {
  classes: object,
  disabled: bool,
  meta: object,
  objectiveId: string.isRequired,
  onToggleStatus: func.isRequired,
  status: string,
  timestamp: string,
}

const filterBy = (objective, statusFilter, nameFilter) =>
  (statusFilter === "all" ? true : objective.status === statusFilter) &&
  (nameFilter === ""
    ? true
    : objective.meta.name.toLowerCase().includes(nameFilter.toLowerCase()) ||
      objective.meta.key.toLowerCase().includes(nameFilter.toLowerCase()))

class ContactObjectives extends Component {
  state = {
    statusFilter: "all",
    nameFilter: "",
  }

  onChangeStatusFilter = event => {
    this.setState({statusFilter: event.target.value})
  }

  onChangeFilterText = event => {
    this.setState({nameFilter: event.target.value})
  }

  onToggleStatus = (objectiveId, newStatus) => {
    this.props.updateObjective(objectiveId, newStatus)
  }

  render() {
    const {classes, disabled, objectives} = this.props
    const {statusFilter, nameFilter} = this.state

    return (
      <Paper>
        <ContactObjectiveHeader
          classes={classes}
          nameFilter={nameFilter}
          onChangeFilterText={this.onChangeFilterText}
          onChangeStatusFilter={this.onChangeStatusFilter}
          statusFilter={statusFilter}
        />
        <ul className={classes.objectivesContainer}>
          {objectives &&
            objectives
              .filter(objective => filterBy(objective, statusFilter, nameFilter))
              .map(objective => (
                <ContactObjectiveRow
                  {...objective}
                  classes={classes}
                  disabled={disabled}
                  key={`cor-${objective.meta.key}`}
                  onToggleStatus={this.onToggleStatus}
                />
              ))}
        </ul>
      </Paper>
    )
  }
}

ContactObjectives.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  objectives: array,
  updateObjective: func.isRequired,
}

const styles = theme => ({
  objective: {
    minHeight: theme.spacing(6),
  },
  header: {
    display: "inline-flex",
    alignItems: "baseline",
    padding: `5px ${theme.spacing(1)} 10px`,
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    width: "100%",
  },
  label: {
    marginRight: theme.spacing(2),
  },
  groupRoot: {
    display: "block",
  },
  radioButtonRoot: {
    padding: theme.spacing(0.5),
  },
  filter: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  button: {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: 4,
    padding: "2px 3px",
    fontSize: theme.typography.fontSize,
    "&:hover": {
      cursor: "pointer",
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    transition: theme.transitions.create(["background-color", "border"]),
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
  },
  disabled: {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`,
    "&:hover": {
      border: `1px solid ${theme.palette.action.disabled}`,
      backgroundColor: "transparent",
    },
  },
  disableCompleted: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabledBackground}`,
    "&:hover": {
      border: `1px solid ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  objectivesContainer: {
    height: 350,
    overflowX: "scroll",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    listStyle: "none",
  },
  objectiveTime: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize - 3,
  },
})

export default withStyles(styles)(
  controlAccess({requiredPermissions: "contacts:edit"})(ContactObjectives)
)
