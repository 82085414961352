import {FormHelperText, Link, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {func, object} from "prop-types"
import {useState} from "react"

import {formify} from "lib/hooks/use-form"

import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import {contentBlockPropType} from "../content-block-editor/content-block-editor-prop-types"
import {
  breakpoints,
  breakpointsForLayoutBasis,
  mediaString,
} from "../content-block-editor/layout-basis"
import FormActions from "../content-block-editor/plugins/form-actions"
import IconTooltip from "../icon-tooltip/icon-tooltip"
import WidgetGridSelector from "./widget-grid-selector"

const breakpointClasses = (device, parent, layoutBasis, {key, range: [lowerBound, upperBound]}) =>
  cx(parent, {
    forced:
      device.type !== "desktop" &&
      device.width >= lowerBound &&
      (!upperBound || device.width < upperBound),
    [`breakpoint-${layoutBasis === "table" ? "table" : "default"}-${key}`]:
      device.type === "desktop",
  })

const isCurrentBreakpoint = (device, {key, range: [lowerBound, upperBound]}) => {
  if (device.type === "desktop" && key === "xl") {
    return true
  }

  return device.width >= lowerBound && (!upperBound || device.width < upperBound)
}

function isOnlyNonZero(grid, keyToCheck) {
  const filteredGrid = Object.keys(grid).reduce((acc, key) => {
    if (!(key.includes(keyToCheck) || key === "grid")) {
      acc[key] = grid[key]
    }
    return acc
  }, {})

  return Object.values(filteredGrid).every(element => element.value === 0)
}

const humanContainerType = type => {
  switch (type) {
    case "message":
      return "email"
    case "template":
      return "template footer"
    default:
      return type
  }
}

const WidgetGridSettings = ({
  classes,
  containers,
  device,
  field,
  handleSubmit,
  resetForm,
  inputs,
  selectedBlock: {containerId, containerType},
}) => {
  const {layoutBasis} = containers[containerId]
  const breakpoints = breakpointsForLayoutBasis(layoutBasis)
  const [showAllBreakpoints, setShowAllBreakpoints] = useState(false)

  return (
    <div style={{flex: 1}}>
      <FormHelperText>
        {layoutBasis === "grid" && (
          <>
            This <b>{humanContainerType(containerType)}</b> uses a <b>grid</b> layout basis. The
            content is laid out in a 12 column grid. You can define how wide this content block
            appears in each of the 5 different screen size ranges below.
          </>
        )}
        {layoutBasis === "table" && (
          <>
            This <b>{humanContainerType(containerType)}</b> uses a <b>table</b> layout basis. The
            content is laid out in a 4 column grid. You can define how wide this content block
            appears in both of the different screen size ranges below.
          </>
        )}
        {layoutBasis === "none" && (
          <>
            This <b>{humanContainerType(containerType)}</b> uses <b>no</b> layout basis. The content
            is laid out according to your CSS. Setting a width for the different screen size ranges
            below will apply classes to the content block, but you will have to write the CSS to
            define how those classes behave.
          </>
        )}

        <IconTooltip
          title={breakpoints.map(({key, range: [low, high], description}) => (
            <>
              <Typography variant="subtitle2">
                {key.toUpperCase()}{" "}
                <span className={classes.range}>
                  ({high ? low : `at least ${low}`}px{high ? ` to ${high}px` : ""})
                </span>
              </Typography>
              <Typography>{description}</Typography>
              <br />
            </>
          ))}
        />
      </FormHelperText>
      <FormHelperText>
        {device.type === "desktop" ? "Based on your browser size" : "Based on your selected device"}
        {" you are currently viewing: "}
        {breakpoints.map(breakpoint => (
          <span
            className={breakpointClasses(
              device,
              cx(classes.breakpoint, classes.tag),
              layoutBasis,
              breakpoint
            )}
            key={breakpoint.key}
          >
            {breakpoint.key.toUpperCase()}
          </span>
        ))}
      </FormHelperText>
      <form onSubmit={handleSubmit}>
        {breakpoints.map(breakpoint => (
          <WidgetGridSelector
            breakpoints={breakpoints}
            className={
              showAllBreakpoints
                ? ""
                : breakpointClasses(device, classes.breakpointSelector, layoutBasis, breakpoint)
            }
            key={breakpoint.key}
            breakpoint={breakpoint.key.toUpperCase()}
            current={isCurrentBreakpoint(device, breakpoint)}
            onlyVisible={isOnlyNonZero(inputs, breakpoint.key)}
            layoutBasis={layoutBasis}
            {...field(`grid.${breakpoint.key}`)}
          />
        ))}

        {!showAllBreakpoints && (
          <Link className={classes.showMore} onClick={() => setShowAllBreakpoints(true)}>
            Configure other screen sizes...
          </Link>
        )}
        <FormActions resetForm={resetForm} />
      </form>
    </div>
  )
}

WidgetGridSettings.propTypes = {
  classes: object.isRequired,
  containers: object.isRequired,
  device: object.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  inputs: object.isRequired,
  resetForm: func.isRequired,
  selectedBlock: contentBlockPropType,
}
const monospace = `"Monaco","Consolas","Lucida Console",monospace`

const generateBreakpointClasses = display => ({
  display: "none",
  "&.forced": {display},
  ...breakpoints.default.reduce(
    (acc, breakpoint) => ({
      ...acc,
      [`&.breakpoint-default-${breakpoint.key}`]: {
        [mediaString(...breakpoint.range)]: {display},
      },
    }),
    {}
  ),
  ...breakpoints.table.reduce(
    (acc, breakpoint) => ({
      ...acc,
      [`&.breakpoint-table-${breakpoint.key}`]: {
        [mediaString(...breakpoint.range)]: {display},
      },
    }),
    {}
  ),
})

const styles = theme => ({
  breakpoint: generateBreakpointClasses("inline-flex"),
  breakpointSelector: generateBreakpointClasses("block"),
  tag: {
    background: theme.palette.primary.main,
    color: "#fff",
    padding: `0 ${theme.spacing(0.5)}`,
    borderRadius: theme.spacing(0.5),
  },
  range: {
    color: theme.palette.primary.main,
    fontFamily: monospace,
  },
  showMore: {
    display: "block",
    marginTop: 16,
    marginBottom: 16,
    cursor: "pointer",
  },
})

const WidgetGridSettingsForm = formify()(WidgetGridSettings)

export default withStyles(styles)(contentBlockEditorConsumer(WidgetGridSettingsForm))
