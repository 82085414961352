import {TableCell, TableRow, Typography} from "@mui/material"
import {array, object} from "prop-types"
import {FaChevronRight as ChevronRightIcon} from "react-icons/fa"
import {Link} from "react-router-dom"

import {distanceDateTime} from "lib/date-time-formatters"

import DOTable from "../table/table"
import JourneyProgress from "./journey-progress"

const AssociatedJourneys = ({classes, journeys, pagesByTemplateId}) => (
  <DOTable
    headers={[
      {field: "campaignName"},
      {field: "created"},
      {field: "lastViewed"},
      {field: "status"},
    ]}
    paginationEnabled={false}
    rows={journeys}
  >
    {({id, analyticsCache, insertedAt, template, templateId}) => (
      <TableRow key={id}>
        <TableCell component="th" scope="row">
          <Typography
            className={classes.link}
            color="inherit"
            component={Link}
            to={`/admin/journeys/${id}`}
          >
            {template.name}
            <ChevronRightIcon className={classes.iconRight} />
          </Typography>
        </TableCell>
        <TableCell>{distanceDateTime(insertedAt)}</TableCell>
        <TableCell>
          {analyticsCache && analyticsCache.lastViewed
            ? distanceDateTime(analyticsCache.lastViewed)
            : "never viewed"}
        </TableCell>
        <TableCell>
          <JourneyProgress
            analyticsCache={analyticsCache}
            templatePages={pagesByTemplateId[templateId]}
          />
        </TableCell>
      </TableRow>
    )}
  </DOTable>
)

AssociatedJourneys.propTypes = {
  classes: object.isRequired,
  journeys: array.isRequired,
  pagesByTemplateId: object.isRequired,
}

export default AssociatedJourneys
