import {omit, pick} from "lodash"
import memoize from "memoize-one"
import {bool, object, shape, string} from "prop-types"
import {useEffect, useRef, useState} from "react"

import ContentBlockEditor from "components/content-block-editor/content-block-editor"

import {compileCss} from "lib/custom-css"

import ContentContainerNavigation from "./content-container-navigation"

const createCompiledCustomCss = memoize((scope, source) => {
  try {
    return compileCss(scope, source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const ContentContainerReadonly = ({classes, contentContainer, isEditable}) => {
  const {css, id, styles} = contentContainer
  const compiledCss = createCompiledCustomCss("#main-content", css || "")
  const [isSticky, setIsSticky] = useState(false)
  const [rightOffset, setRightOffset] = useState(0)
  const boxRef = useRef(null)

  const updateRightOffset = () => {
    if (boxRef.current) {
      const boxWidth = boxRef.current.offsetWidth
      const windowWidth = window.innerWidth
      let newRightOffset = (windowWidth - boxWidth) / 2 - 100 //  Adjust for the desired inset
      if (newRightOffset < 0) {
        newRightOffset = 0
      }
      setRightOffset(newRightOffset)
    }
  }

  const checkOverflow = () => {
    const contentHeight = document.getElementById("content-container").offsetHeight + 100 // Adjust for the button height
    const viewportHeight = window.innerHeight
    setIsSticky(contentHeight > viewportHeight)
  }

  useEffect(() => {
    const handleResize = () => {
      checkOverflow()
      updateRightOffset()
    }

    // Update when window is resized
    window.addEventListener("resize", handleResize)

    // Update when container div changes size
    const observedDiv = boxRef.current
    const resizeObserverCallback = entries => {
      entries.forEach(() => {
        handleResize()
      })
    }
    const resizeObserver = new ResizeObserver(resizeObserverCallback)
    resizeObserver.observe(observedDiv)

    return () => {
      resizeObserver.unobserve(observedDiv)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div
      className={`content-container-content ${classes.content}`}
      style={omit(styles || {}, ["gap"])}
    >
      <style>{compiledCss}</style>
      <div ref={boxRef} id="content-container">
        <ContentBlockEditor
          initialContainer={{...contentContainer, id, styles: pick(styles || {}, ["gap"])} || {}}
          containerId={id}
          initialMode={isEditable ? "Edit" : "View"}
        />
      </div>
      <ContentContainerNavigation
        isSticky={isSticky}
        rightOffset={rightOffset}
        classes={classes}
        contentContainer={contentContainer}
      />
    </div>
  )
}

ContentContainerReadonly.defaultProps = {isEditable: false}

ContentContainerReadonly.propTypes = {
  classes: object.isRequired,
  contentContainer: shape({
    css: string,
    id: string.isRequired,
    styles: object,
  }).isRequired,
  isEditable: bool,
}

export default ContentContainerReadonly
