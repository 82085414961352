import {Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, arrayOf, func, object, string} from "prop-types"
import {PureComponent} from "react"

import ContactHousehold from "components/contacts/contact-household"

import {filterPristineParams} from "lib/filter-pristine-params"
import {fullName} from "lib/names"

import Box from "../box/box"
import CopyTextInput from "../copy-text-input/copy-text-input"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import DocumentTitle from "../document-title/document-title"
import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"
import ActionsTable from "./analytics-table"
import AssociatedJourneys from "./associated-journeys"
import ContactAccounts from "./contact-accounts"
import ContactAuditLogs from "./contact-audit-logs-container"
import ContactForm from "./contact-form"
import ContactObjectives from "./contact-objectives"

export class ContactsShow extends PureComponent {
  state = {isDeleteDialogOpen: false}

  componentDidMount = () => this.props.getContactComponentData(this.props.contactId)

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.contactId !== prevProps.contactId)
      this.props.getContactComponentData(this.props.contactId)
  }

  componentWillUnmount = () => this.props.clearContactComponentData()

  onDeleteContact = () => {
    this.props.onDeleteContact(this.props.contactId)
    this.onCloseDeleteDialog()
  }

  onUpdateContactObjective = (objectiveId, newStatus) => {
    this.props.updateContactObjective(this.props.contactId, objectiveId, newStatus)
  }

  onOpenDeleteDialog = () => this.setState({isDeleteDialogOpen: true})

  onCloseDeleteDialog = () => this.setState({isDeleteDialogOpen: false})

  onUpdateContact = params =>
    this.props.onUpdateContact(
      this.props.contactId,
      filterPristineParams(params, this.props.contact)
    )

  render() {
    const {
      classes,
      contact,
      contactId,
      journeys,
      objectives,
      contactAccounts,
      pagesByTemplateId,
      team,
    } = this.props
    const {isDeleteDialogOpen} = this.state
    const contactInDocTitle = ` - ${!!fullName(contact) ? fullName(contact) : contact.uniqueId}`

    return (
      <div>
        <DocumentTitle title={`Contacts${contactInDocTitle}`} />
        <TitleBar
          backButtonAttrs={{
            to: "/admin/contacts",
            children: "Return to Contacts",
          }}
        />
        <Padded>
          <Typography variant="h5">Contact Information</Typography>
          <Box>
            <ContactForm
              classes={classes}
              initialValues={{...contact, timezone: contact.timezone || team.timezone}}
              onOpenDeleteDialog={this.onOpenDeleteDialog}
              onSubmit={this.onUpdateContact}
            />
          </Box>
        </Padded>
        <ContactHousehold contact={contact} />
        <Padded>
          <Typography variant="h5">Associated Journeys</Typography>
          <Box>
            <AssociatedJourneys
              classes={classes}
              journeys={journeys}
              pagesByTemplateId={pagesByTemplateId}
            />
          </Box>
        </Padded>
        <Padded>
          <Typography variant="h5">Objectives</Typography>
          <ContactObjectives
            objectives={objectives}
            updateObjective={this.onUpdateContactObjective}
          />
        </Padded>

        <Padded>
          <Typography variant="h5">Accounts</Typography>
          <Box>
            <ContactAccounts contactAccounts={contactAccounts} />
          </Box>
        </Padded>
        <Padded>
          <Typography variant="h5">Actions</Typography>
          <Box>
            <ActionsTable contact={contact} />
          </Box>
        </Padded>
        <Padded>
          <Typography variant="h5">Audit Events</Typography>
          <ContactAuditLogs contact={contact} />
        </Padded>
        <Padded>
          <Box>
            <CopyTextInput
              helperText="To reference this contact in API requests, please use the ID provided above"
              label="Contact ID"
              value={contactId}
            />
          </Box>
        </Padded>
        {contact && (
          <UltraConfirmDialog
            confirmationText={fullName(contact) || "Delete Contact"}
            key={contact.id}
            onClose={this.onCloseDeleteDialog}
            onConfirm={this.onDeleteContact}
            open={isDeleteDialogOpen}
            recordType="contact"
          />
        )}
      </div>
    )
  }
}

ContactsShow.propTypes = {
  classes: object.isRequired,
  clearContactComponentData: func.isRequired,
  contact: object.isRequired,
  contactAccounts: arrayOf(object),
  contactId: string.isRequired,
  getContactComponentData: func.isRequired,
  journeys: array,
  objectives: array,
  onDeleteContact: func.isRequired,
  onUpdateContact: func.isRequired,
  pagesByTemplateId: object,
  team: object.isRequired,
  updateContactObjective: func.isRequired,
}

const styles = {
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
  iconRight: {
    marginLeft: 10,
  },
  link: {
    textDecoration: "none",
  },
}

export default withStyles(styles)(ContactsShow)
