import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {MdAssignmentTurnedIn as EnrollmentIcon} from "react-icons/md"
import shortid from "shortid"

import {feature} from "components/feature/feature"

const EnrollmentButton = forwardRef(({classes, objective, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {
        name: `Enrollment Widget ${objective?.name ?? ""}`,
        heading: "Enrollment Service Name",
        acceptedConfirmationText: "Thank you for your response, we are excited you enrolled!",
        agreementText: "Yes, I would like to sign up for this service now.",
        documentLinkText: "Terms & Conditions",
        documentLinkUrl: "",
        signatureBoxText:
          "By completing the above actions you are agreeing to enroll in the service(s) listed, and the stated Terms and Conditions. You may elect to opt-out of these service(s) at any time.",
        declinedConfirmationText:
          "Thank you for your response, and sorry to hear this doesn't meet your needs at this time.",
        disagreeText: "I do not wish to move forward, at this time.",
      },
      type: "enrollment",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <EnrollmentIcon />
      </ListItemIcon>
      <ListItemText primary="Enrollment" ref={ref} />
    </MenuItem>
  )
})

EnrollmentButton.propTypes = {
  objective: object,
  onAddContentBlock: func.isRequired,
  onClose: func,
  classes: object.isRequired,
}

export {EnrollmentIcon}

export default feature("enrollment-widget", EnrollmentButton)
