import {FormControl, InputLabel, ListItemText, MenuItem, TextField, Typography} from "@mui/material"
import cx from "classnames"
import {array, bool, func, object, shape, string} from "prop-types"

import DOSelect from "components/do-select/do-select"
import CampaignTargetingCondition from "components/template-targeting/campaign-targeting-condition"
import {useTemplateTargeting} from "components/template-targeting/template-targeting-context"

const JourneyReentryTargetingCondition = ({
  classes,
  disabled = false,
  onChange,
  targetingCondition,
  templates,
  templateId,
}) => {
  const operators = [
    {name: "greater than", value: "greater_than"},
    {name: "is", value: "is"},
    {name: "less than", value: "less_than"},
  ]

  const {updateTargetingCondition} = useTemplateTargeting()
  const {id, meta, targetingGroupId, operator, value} = targetingCondition
  const tacticalTemplates = templates.filter(
    template => template.type === "tactical" || template.value === "any"
  )

  const onChangeSelectedTemplate = ({target}) => {
    updateTargetingCondition({
      id,
      targetingGroupId,
      meta: {selectedTemplate: target.value},
    })
  }

  const isTextFieldError = v => v <= 0

  return (
    <>
      {/* operator */}
      <FormControl classes={{root: classes.fieldSelect}}>
        {<InputLabel htmlFor="journey-value">Operator</InputLabel>}
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          onChange={onChange}
          name="operator"
          renderValue={value => operators.find(o => o.value === value)?.name}
          value={operator}
        >
          {operators.map(({name, value}) => (
            <MenuItem key={value} value={value}>
              <ListItemText
                classes={{secondary: classes.listItemTextSecondary}}
                primary={
                  <Typography variant="inherit" noWrap>
                    {name}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>

      {/* value */}
      <TextField
        disabled={disabled}
        error={isTextFieldError(value)}
        helperText={isTextFieldError(value) <= 0 ? " " : "Number must be positive"}
        name="value"
        label="Value"
        onChange={onChange}
        required={true}
        value={value}
        type="number"
      />

      {/* selectedTemplate */}
      <CampaignTargetingCondition
        classes={classes}
        disabled={disabled}
        onChange={onChangeSelectedTemplate}
        name="meta.selectedTemplate"
        templateId={templateId}
        templates={tacticalTemplates}
        value={meta?.["selectedTemplate"]}
      />
    </>
  )
}

JourneyReentryTargetingCondition.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  onChange: func.isRequired,
  templateId: string,
  templates: array,
  targetingCondition: shape({
    id: string,
    model: string,
    field: string,
    meta: object,
    meta_sub_key: string,
    operator: string,
    value: string,
  }).isRequired,
}

export default JourneyReentryTargetingCondition
