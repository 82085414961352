import {array, element, elementType, node, oneOfType, string} from "prop-types"
import * as React from "react"
import {Redirect, Route} from "react-router-dom"

import {useSession} from "contexts/session/session-context"

import {isUserPermitted} from "./access-controlled"

const RedirectToNotPermitted = () => <Redirect to="/not-permitted" />

const AccessControlled = ({component, requiredPermissions, ...rest}) => {
  const Component = component
  const renderComponent = props => <Component {...props} />

  const {user} = useSession()

  if (!user) return null

  if (isUserPermitted(user, requiredPermissions))
    return <Route {...rest} render={renderComponent} />

  return <Route component={RedirectToNotPermitted} />
}

AccessControlled.propTypes = {
  component: oneOfType([element, elementType, node]).isRequired,
  requiredPermissions: oneOfType([array, string]).isRequired,
}

export default AccessControlled
