import {IconButton, Typography} from "@mui/material"
import {bool, object, string} from "prop-types"
import {
  MdPermContactCalendar as CalendarIcon,
  MdEmail as EmailIcon,
  MdPhone as PhoneIcon,
} from "react-icons/md"

import {drawerWidth as templateSidebarWidth} from "../templates/template-helpers"
import {journeyContext} from "./journey-context"

const shouldShow = (type, ownerProp, options) => {
  switch (options[`${type}LinkShows`]) {
    case "DO_NOT_SHOW":
      return false
    case "SUPPORT_OWNER":
      return !!ownerProp
    case "CUSTOM":
      return true
    default:
      return false
  }
}

const JourneyOwnerInfo = ({
  themeClasses,
  isSidebarOpen,
  name,
  email,
  phoneWork,
  link,
  teamName,
  isFixed = true,
  options = {},
}) => {
  const showEmail = shouldShow("email", email, options)
  const showPhone = shouldShow("phone", phoneWork, options)
  const showCalendar = shouldShow("calendar", link, options)

  // If we don't show any of the links, there's no reason to display this at all.
  if (!options.showSupportOwnerLinks || (!showEmail && !showPhone && !showCalendar)) return null

  return (
    <div className={themeClasses.journeyOwnerInfoRoot}>
      <div
        className={
          isFixed
            ? `journey-support-owner ${themeClasses.journeyOwnerInfoInnerFixed}`
            : // This is used in the Packaging and Approval view
              `journey-support-owner ${themeClasses.journeyOwnerInfoInnerRelative}`
        }
        style={isSidebarOpen ? {right: templateSidebarWidth} : {}}
      >
        <div className={themeClasses.journeyOwnerInfoContainer}>
          <Typography classes={{root: themeClasses.journeyOwnerInfoText}} component="span">
            Need Help? Contact{" "}
            <Typography classes={{root: themeClasses.journeyOwnerInfoName}} component="em">
              {name}
            </Typography>{" "}
            at {teamName}:
          </Typography>
          <span className={themeClasses.journeyOwnerInfoIcons}>
            {showEmail && (
              <IconButton
                aria-label={`Email ${name}`}
                classes={{root: themeClasses.journeyOwnerInfoIcon}}
                href={`mailto:${options.emailLinkShows === "CUSTOM" ? options.emailLink : email}`}
                size="medium"
              >
                <EmailIcon aria-hidden="true" />
              </IconButton>
            )}
            {showPhone && (
              <IconButton
                aria-label={`Call ${name}`}
                classes={{root: themeClasses.journeyOwnerInfoIcon}}
                href={`tel:${options.phoneLinkShows === "CUSTOM" ? options.phoneLink : phoneWork}`}
                size="medium"
              >
                <PhoneIcon aria-hidden="true" />
              </IconButton>
            )}
            {showCalendar && (
              <IconButton
                aria-label={`Book a meeting with ${name}`}
                classes={{root: themeClasses.journeyOwnerInfoIcon}}
                href={
                  options.calendarLinkShows === "CUSTOM"
                    ? !!options.calendarLink
                      ? options.calendarLink
                      : "#"
                    : link
                }
                size="medium"
              >
                <CalendarIcon aria-hidden="true" />
              </IconButton>
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

JourneyOwnerInfo.propTypes = {
  email: string.isRequired,
  isSidebarOpen: bool.isRequired,
  link: string,
  name: string.isRequired,
  options: object,
  phoneWork: string,
  teamName: string.isRequired,
  themeClasses: object.isRequired,
  isFixed: bool,
}

export default journeyContext(JourneyOwnerInfo)
