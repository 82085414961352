import {Badge} from "@mui/material"
import {bool, func, node, number, string} from "prop-types"
import {FaExclamation as ExclamationIcon} from "react-icons/fa"

/**
 * The Material-UI Badge component will render its content for screen readers
 * (and integration test library) even if `invisible` is truthy - this is not
 * good!
 *
 * Using that invisible prop makes sense if the content should be available to
 * screen readers even if it's set to true.
 *
 * In the case of displaying an error message, that is not what we want, and
 * instead we can use this little component to ensure the user does not see an
 * error message if they aren't supposed to.
 */
const BadgeIfError = ({BadgeIcon, badgeSize, badgeText, children, hasError}) =>
  hasError ? (
    <Badge
      aria-label={badgeText}
      badgeContent={<BadgeIcon size={badgeSize} title={badgeText} />}
      color="error"
      style={{display: "flex"}}
    >
      {children}
    </Badge>
  ) : (
    children
  )

BadgeIfError.propTypes = {
  BadgeIcon: func.isRequired,
  badgeSize: number.isRequired,
  badgeText: string.isRequired,
  children: node.isRequired,
  hasError: bool,
}

BadgeIfError.defaultProps = {
  BadgeIcon: ExclamationIcon,
  badgeSize: 10,
}

export default BadgeIfError
