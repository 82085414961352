import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {object} from "prop-types"
import {useEffect, useState} from "react"
import {
  FaStreetView as ContactsIcon,
  FaUsers as GroupIcon,
  FaQuestion as HelpIcon,
  FaMap as MapIcon,
  FaCog as SettingsIcon,
  FaSignOutAlt as SignOutIcon,
  FaUser as UserIcon,
} from "react-icons/fa"
import {MdLightbulbOutline as InsightsIcon} from "react-icons/md"
import {useDispatch, useSelector} from "react-redux"
import {NavLink} from "react-router-dom"

import {onLogout} from "../../actions/session-actions"
import AccessControlled from "../access-control/access-controlled"
import Feature from "../feature/feature"
import UploadedImage from "../uploaded-image/uploaded-image"
import AdminSubNav from "./admin-sub-nav"

const AdminNav = ({classes, location}) => {
  const [route, setRoute] = useState(null)
  const [hoverRoute, setHoverRoute] = useState(null)
  const [timer, setTimer] = useState(-1)
  const dispatch = useDispatch()
  const {team, user} = useSelector(({session: {team, user}}) => ({team, user}))

  useEffect(() => {
    setRoute(location.pathname.match(/^\/admin\/(\w*)/i)?.[1] ?? null)
  }, [location])

  const onMouseEnterMainNavArea = () => {
    clearTimeout(timer)
  }

  const onMouseLeaveNavArea = () => {
    setTimer(
      setTimeout(() => {
        setHoverRoute(null)
      }, 1000)
    )
  }

  const onMouseEnterMainNavItem = previewRoute => () => {
    setHoverRoute(previewRoute)
  }

  return (
    <div onMouseEnter={onMouseEnterMainNavArea} onMouseLeave={onMouseLeaveNavArea}>
      <div className={classes.navWrapper}>
        <nav className={classes.nav}>
          {team && team.id && (
            <div className={classes.teamLogoWrapper}>
              <UploadedImage
                cacheBuster={team.updatedAt}
                src={`${process.env.REACT_APP_API_URL}/teams/${team.id}/favicon?variant=icon&cache_buster=${team.updatedAt}`}
                variant="thumb"
              />
            </div>
          )}
          <ul className={classes.list}>
            <li className={cx(classes.listItem, {previewing: hoverRoute === "templates"})}>
              <NavLink
                onMouseEnter={onMouseEnterMainNavItem("templates")}
                title="Campaigns"
                to="/admin/templates"
              >
                <MapIcon /> <span>Campaigns</span>
              </NavLink>
            </li>
            <AccessControlled hideIfNotPermitted={true} requiredPermissions="contacts:view">
              <li className={cx(classes.listItem, {previewing: hoverRoute === "contacts"})}>
                <NavLink
                  onMouseEnter={onMouseEnterMainNavItem("contacts")}
                  title="Contacts"
                  to="/admin/contacts"
                >
                  <ContactsIcon /> <span>Contacts</span>
                </NavLink>
              </li>
            </AccessControlled>
            <Feature featureKey="team-insights">
              <li className={cx(classes.listItem)}>
                <NavLink title="Insights" to="/admin/insights">
                  <InsightsIcon /> <span>Insights</span>
                </NavLink>
              </li>
            </Feature>
            <li className={cx(classes.listItem, {previewing: hoverRoute === "team"})}>
              <NavLink onMouseEnter={onMouseEnterMainNavItem("team")} title="Team" to="/admin/team">
                <GroupIcon /> <span>Team</span>
              </NavLink>
            </li>
            <li className={cx(classes.listItem, {previewing: hoverRoute === "settings"})}>
              <NavLink
                onMouseEnter={onMouseEnterMainNavItem("settings")}
                title="Settings"
                to="/admin/settings"
              >
                <SettingsIcon /> <span>Settings</span>
              </NavLink>
            </li>
            <li className={cx(classes.listItem, {previewing: hoverRoute === "account"})}>
              <NavLink
                onMouseEnter={onMouseEnterMainNavItem("account")}
                title="Account"
                to="/admin/account"
              >
                {user && user.isAvatarUploaded ? (
                  <UploadedImage
                    alt=""
                    className={classes.userAvatar}
                    src={`${process.env.REACT_APP_API_URL}/users/${user.id}/avatar`}
                    variant="thumb"
                  />
                ) : (
                  <UserIcon />
                )}
                <span>Account</span>
              </NavLink>
            </li>
            <li className={classes.listItem}>
              <a
                href="https://support.digitalonboarding.com"
                target="_blank"
                rel="noreferrer"
                title="Help"
              >
                <HelpIcon /> <span>Help</span>
              </a>
            </li>
            <li className={classes.listItem}>
              <button onClick={onLogout(dispatch)} title="Log out">
                <SignOutIcon /> <span>Log out</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <AdminSubNav isPreviewing={!!hoverRoute} route={hoverRoute ?? route} />
    </div>
  )
}

AdminNav.propTypes = {
  classes: object.isRequired,
  location: object.isRequired,
}

const styles = theme => ({
  nav: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    width: "100vw",
    background: "#1c252a",
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    padding: "0 60px",
    "& a, & button": {
      color: "#8A8F91",
      textDecoration: "none",
      display: "inline-flex",
      alignItems: "center",
      "& :first-child": {
        marginRight: 10,
      },
    },
  },
  navWrapper: {
    height: 44,
    position: "relative",
    zIndex: theme.zIndex.appBar,
  },
  list: {
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    height: 44,
    padding: 0,
    margin: 0,
  },
  listItem: {
    margin: "0 5px",
    flex: 1,
    height: "100%",
    "& a, & button": {
      height: "100%",

      [theme.breakpoints.down("lg")]: {
        fontSize: 18,
        "& span": {
          display: "none",
        },
      },
    },
    "& button": {
      border: "none",
      background: "transparent",
      fontSize: 14,
      cursor: "pointer",
    },
    "& a.active": {
      color: "#f5f6f5",
      textShadow: "0 0 2px #489AD4",
    },
    "&.previewing a": {
      position: "relative",
      "&:after": {
        position: "absolute",
        content: `""`,
        height: theme.spacing(0.4),
        bottom: theme.spacing(0.75),
        left: theme.spacing(-1),
        right: theme.spacing(-1),
        background: "#489AD4",
        borderRadius: theme.spacing(0.4),
      },
    },
  },
  userAvatar: {
    borderRadius: "50%",
    width: 32,
    height: 32,
  },
  teamLogoWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: 60,
  },
})

export default withStyles(styles)(AdminNav)
