import Box from "@mui/material/Box"
import PropTypes from "prop-types"

import DOTable from "components/table/table"

import CrossSellAdoptionMetricRow from "./cross-sell-adoption-metric-row"

const CrossSellAdoptionMetrics = ({drivers, isLoading, objectives, onChange}) => {
  const handleChange = (index, objective) => onChange(objectives.toSpliced(index, 1, objective))

  return (
    <Box
      sx={{
        "& tr th:nth-of-type(5)": {
          width: 120,
        },
      }}
    >
      <DOTable
        headers={[
          {
            field: "name",
            label: "Cross-Sell/Adoption Metric",
            sortable: false,
            tooltipText: "List of all objectives on your team.",
          },
          {
            field: "benchmark",
            label: "Benchmark",
            sortable: false,
            tooltipText:
              "The completion rate for a specific objective. This value is used to compare objective completion rate in the platform versus what your existing/benchmark completion rate.",
          },
          {
            field: "valueDriver",
            label: "Value Driver",
            sortable: false,
            tooltipText:
              "List of most common value drivers in the financial industry. If you have an additional value driver you would like to see, please submit an enhancement request via the support portal.",
          },
          {
            field: "valueInput",
            label: "Value Input",
            sortable: false,
            tooltipText:
              "The overall value your team has for each value driver adoption. Only whole dollar values are accepted at this time (no cents).",
          },
          {
            field: "defaultValue",
            label: "Default Value",
            sortable: false,
            tooltipText:
              "The default value for each business objective based on market research. This value is used if there is a business and campaign objective mapping with a custom value input.",
          },
        ]}
        isTableLoading={isLoading}
        noResults="There are no cross-sell/adoption metrics."
        paginationEnabled={false}
        rows={objectives}
      >
        {(row, index) => (
          <CrossSellAdoptionMetricRow
            drivers={drivers}
            key={row.id}
            onChange={changed => handleChange(index, changed)}
            objective={row}
          />
        )}
      </DOTable>
    </Box>
  )
}

CrossSellAdoptionMetrics.propTypes = {
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  isLoading: PropTypes.bool,
  objectives: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

export default CrossSellAdoptionMetrics
