// This file was generated automatically from character-02.svg with the following command:
// ./svg-converter.js src/components/superhero/character-02.svg
import SuperheroSvg from "./superhero-svg"

const Character02 = props => (
  <SuperheroSvg viewBox="185 50 200 220" {...props}>
    <g id="Layer_2">
      <g>
        <g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M270.54,266.02c-4.98,0-11.91-0.54-19.47-2.74l-0.09-0.04c-0.09-0.05-2.19-1.34-2.08-3.17
  					c0.09-1.38,1.34-2.54,3.83-3.55l0.07-0.02c0.11-0.02,10.94-2.24,12.4-6.6l0.8,0.33c-1.61,4.77-12.18,7.02-13.01,7.19
  					c-2.6,1.06-3.19,2.06-3.24,2.72c-0.07,0.99,1.09,1.93,1.59,2.25c13.8,4,25.44,2.42,26.75,2.22c1.42-1.55-0.44-8.31-1.98-12.28
  					l0.78-0.37c0.44,1.14,4.24,11.19,1.65,13.48l-0.09,0.08l-0.11,0.02C278.27,265.53,275.29,266.02,270.54,266.02z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M255.46,264.22l-0.53-0.74c0.98-0.87,1.5-1.85,1.54-2.92c0.09-2.01-1.5-3.75-1.52-3.77l0.59-0.68
  					c0.08,0.08,1.88,2.05,1.78,4.49C257.26,261.96,256.63,263.18,255.46,264.22z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M271.46,262.59c-3.38,0-8.1-0.26-14.65-1.07l0.09-0.94c18.05,2.23,22.03,0.28,22.07,0.26
  					l0.36,0.85C279.23,261.74,277.51,262.59,271.46,262.59z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M256.67,260.17l-0.15-0.93c12.97-2.57,13.44-7.93,13.44-7.99l0.85,0.05
  					C270.8,251.54,270.42,257.44,256.67,260.17z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M259.46,258.18c-0.71,0-1.6-0.6-1.9-0.82l0.46-0.79c0.57,0.41,1.3,0.74,1.5,0.66
  					c0-0.01,0-0.02,0.01-0.05c0.07-0.64,0.12-1.19,0.65-1.41c0.38-0.15,0.92-0.09,2.1,0.06c0.84,0.11,1.44,0.18,1.61,0.04
  					c0.16-0.14,0.23-0.82,0.33-1.75c0.08-0.78,0.34-1.24,0.78-1.38c0.85-0.27,1.85,1.01,2.14,1.42l-0.66,0.59
  					c-0.47-0.65-1.07-1.16-1.24-1.09c0,0-0.11,0.08-0.17,0.58c-0.14,1.33-0.2,2-0.64,2.38c-0.44,0.39-1.04,0.31-2.23,0.16
  					c-0.85-0.11-1.58-0.2-1.72-0.11c-0.03,0.06-0.06,0.36-0.09,0.62c-0.04,0.37-0.21,0.65-0.48,0.79
  					C259.76,258.16,259.61,258.18,259.46,258.18z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#CB5E65"}}
            d="M263.4,205.31l8-61.99l21.24-2.43l-4.9,18.19l-4.41,52.32c0,0,1.13,16.61,1.82,42.07l-25.42-1.63
  				C259.73,251.84,261.49,224.58,263.4,205.31z"
          />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M306.64,265.93l-0.05-0.01c-0.1-0.01-2.49-0.26-3.03-2c-0.41-1.31,0.33-2.93,2.25-4.95l0.06-0.05
  					c0.02-0.02,2.37-1.75,4.65-4.07c2.95-2.99,4.47-5.57,4.39-7.46l0.85-0.05c0.22,5.09-8.69,11.81-9.39,12.34
  					c-2.01,2.12-2.2,3.3-2.01,3.92c0.29,0.94,1.68,1.29,2.26,1.36c14.11-2.41,24.24-9,25.35-9.75c0.9-2.28-2.95-7.37-4.54-9.17
  					l0.61-0.66c0.64,0.72,6.19,7.16,4.63,10.38l-0.05,0.11l-0.1,0.07c-0.11,0.07-10.74,7.4-25.83,9.96L306.64,265.93z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M311.05,264.86l-0.75-0.45c0.59-1.23,0.72-2.36,0.38-3.36c-0.64-1.89-2.72-2.78-2.74-2.79
  					l0.3-0.89c0.1,0.04,2.46,1.05,3.23,3.34C311.91,261.99,311.76,263.38,311.05,264.86z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M311.33,261.78l-0.25-0.9c17.22-5.86,20.33-9.46,20.36-9.49l0.63,0.63
  					C331.96,252.16,328.93,255.79,311.33,261.78z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M310.73,260.6l-0.47-0.79c10.99-8.08,9.52-13.21,9.5-13.26l0.8-0.33
  					C320.64,246.45,322.39,252.04,310.73,260.6z"
            />
          </g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M311.55,257.74c-0.42,0-0.82-0.06-1.01-0.1l0.14-0.93c0.67,0.13,1.46,0.1,1.61-0.05
  					c0-0.01,0-0.02-0.01-0.05c-0.17-0.62-0.31-1.15,0.1-1.58c0.29-0.31,0.81-0.48,1.95-0.86c0.81-0.27,1.39-0.47,1.49-0.67
  					c0.1-0.2-0.08-0.85-0.32-1.75c-0.2-0.75-0.13-1.29,0.22-1.61c0.68-0.62,2.06,0.12,2.47,0.36l-0.4,0.83
  					c-0.66-0.39-1.39-0.58-1.53-0.46c0,0-0.08,0.13,0.05,0.61c0.69,2.56,0.57,2.83-1.73,3.6c-0.82,0.27-1.52,0.51-1.62,0.66
  					c-0.01,0.07,0.07,0.36,0.14,0.61c0.1,0.35,0.04,0.69-0.16,0.93C312.65,257.64,312.07,257.74,311.55,257.74z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#EA6569"}}
            d="M308.35,205.23l-3.84-57.41l-1.05-14.18l-4.65-1.08l-24.72-6.23l-2.45,15.72
  				c-0.18,0.78-0.31,1.59-0.39,2.41l-0.21,1.3l0.12,0.02c-0.01,0.17-0.02,0.33-0.02,0.5c-0.13,9.03,5.54,16.66,13.19,18.48
  				l0.11,0.36l4.42,37.41c0,0,1.1,8.81,3.88,14.21l16.38,34.01l23.95-3.81l-21.32-33.99C311.75,212.94,308.98,209.09,308.35,205.23z
  				"
          />
        </g>
        <g>
          <path
            style={{fill: "#F7D63E"}}
            d="M279.67,74.7c0,0-4.59,21.71,3.06,42.57c0,0,8.36-4.63,13,0.85c6.12,7.24,4.21,1.28,9.18,0.85
  				c3.62-0.31,4.17,0.79,5.35,0c3.82-2.55,2.29-5.53,4.97-5.53c1.91,0,3.06-2.55,2.68-8.09c-0.85-12.32-6.01-23.92-7.75-31.98"
          />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#E3B59D"}}
              d="M279.31,100.12c-1.55,1.5-3.1,3.01-4.66,4.51c-2.47,2.39-4.95,4.79-7.42,7.18
  					c-0.57,0.55-1.13,1.1-1.7,1.65c1.64,0.48,3.29,0.97,4.93,1.45c-0.82-2.58-1.63-5.16-2.45-7.75c-1.31-4.13-2.61-8.27-3.92-12.4
  					c-0.3-0.95-0.6-1.89-0.9-2.84c-0.26-0.81-0.67-1.52-1.36-1.96c-0.63-0.41-1.56-0.59-2.27-0.33c-1.43,0.52-2.61,2.3-2.06,4.04
  					c0.82,2.58,1.63,5.16,2.45,7.75c1.31,4.13,2.61,8.27,3.92,12.4c0.3,0.95,0.6,1.89,0.9,2.84c0.35,1.1,1.02,1.97,2.06,2.29
  					c1.07,0.33,2.06-0.06,2.87-0.84c1.55-1.5,3.1-3.01,4.66-4.51c2.47-2.39,4.95-4.79,7.42-7.18c0.57-0.55,1.13-1.1,1.7-1.65
  					c1.17-1.13,1.12-3.5,0-4.64C282.24,98.85,280.56,98.92,279.31,100.12L279.31,100.12z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#E3B59D"}}
            d="M295.05,60.92c0.53,0,1.05,0.03,1.57,0.09c0.31,13.68,11.45,20.84,13.56,22.07
  				c-1.84,7.38-7.91,12.79-15.12,12.79c-8.67,0-15.7-7.82-15.7-17.48S286.38,60.92,295.05,60.92z"
          />
        </g>
        <g>
          <path
            style={{fill: "#E3B59D"}}
            d="M296.49,98.12c1.53,0.43,4.02,1.29,4.02,1.29l-0.96,5.1l-7.27,3.41l-7.65-3.83v-5.96
  				c0,0-0.38,0,2.42-0.19c2.8-0.19,3.49-0.48,3.49-0.48l0-2.47c-6.29-2.28-11.19-8.87-11.19-16.63c0-0.93,0.07-1.83,0.2-2.72
  				l30.41,6.89c0.11,0.06,0.21,0.14,0.32,0.2c0,0.02-0.12,0.42-0.13,0.44c1.35,0.51,2.44,1.55,2.44,3.23c0,2.11-1.54,3.82-3.43,3.82
  				c-0.74,0-1.43-0.26-1.99-0.71c-2.63,3.53-6.48,5.89-10.84,6.28l0,1.67v0.66H296.49z"
          />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#5EBDAE"}}
              d="M298.84,98.69c5,0.76,8.49,5.88,7.81,11.45l-2.45,16.43l-30.37,0l3.02-20.25
  					c0.91-6.42,3.5-9.42,8.17-8.55c0,0-1.56,6.5,5.84,7.37C290.86,105.15,298.64,105.58,298.84,98.69z"
            />
          </g>
          <g>
            <polygon
              style={{fill: "#5EBDAE"}}
              points="304.64,123.28 273.83,126.58 273.05,131.85 303.39,135.98 				"
            />
          </g>
        </g>
        <g>
          <ellipse style={{fill: "#0D1E36"}} cx="287.74" cy="74.45" rx="1.25" ry="1.39" />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M289.08,70.71c-0.23-0.26-0.56-0.44-0.87-0.54c-0.74-0.26-1.51-0.07-2.18,0.33
  					c-0.3,0.18-0.03,0.7,0.27,0.52c0.56-0.34,1.2-0.48,1.79-0.27c0.05,0.02,0.1,0.04,0.15,0.06c-0.05-0.02,0.03,0.01,0.04,0.02
  					c0.02,0.01,0.05,0.03,0.07,0.04c0.07,0.04,0.15,0.09,0.22,0.14c0.02,0.02,0.05,0.04,0.07,0.06c-0.08-0.06,0,0,0.02,0.02
  					c0.02,0.01,0.03,0.03,0.04,0.04C288.94,71.4,289.32,70.98,289.08,70.71L289.08,70.71z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#F7D63E"}}
            d="M296.61,61.01c7.94,0.87,14.14,8.32,14.14,17.39c0,1.62,1.31,4.13,0.94,5.62
  				C309.01,82.74,296.01,74.23,296.61,61.01z"
          />
        </g>
        <g>
          <path
            style={{fill: "#F7D63E"}}
            d="M296.63,61.11c0,0-4.34,5.41-13.11,5.41C283.53,66.52,288.52,59.94,296.63,61.11z"
          />
        </g>
        <g>
          <ellipse style={{fill: "#0D1E36"}} cx="298.46" cy="76.44" rx="1.25" ry="1.39" />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M298.53,72.77c0.08-0.03,0.17-0.04,0.26-0.06c0.02,0,0.08-0.01,0,0c0.02,0,0.05-0.01,0.07-0.01
  					c0.05-0.01,0.1-0.01,0.14-0.01c0.12-0.01,0.24,0,0.36,0.01c0.12,0.01,0.22,0.03,0.36,0.07c0.14,0.04,0.25,0.09,0.37,0.15
  					c0.32,0.18,0.59,0.44,0.8,0.76c0.09,0.13,0.23,0.19,0.37,0.11c0.11-0.07,0.18-0.28,0.1-0.41c-0.47-0.71-1.13-1.17-1.92-1.27
  					c-0.34-0.04-0.72-0.03-1.05,0.09C298.06,72.31,298.2,72.89,298.53,72.77L298.53,72.77z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M293.39,79.81c-0.36-0.79-1.12-1.41-1.94-1.28c-0.44,0.07-0.82,0.33-1.17,0.63
  					c-0.14,0.12,0.06,0.33,0.19,0.21c0.48-0.42,1.09-0.72,1.7-0.46c0.35,0.15,0.64,0.46,0.85,0.8c0.05,0.08,0.1,0.16,0.14,0.25
  					C293.24,80.13,293.47,79.98,293.39,79.81L293.39,79.81z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{fill: "#0C1B2F"}}
                d="M295.82,85.11c-1.75,0.55-3.86-0.13-4.76-0.49c-0.12,0.83-0.24,2-0.05,2.75l0,0
  						c0.14,0.61,0.48,1.15,0.97,1.51c0.33-0.61,0.79-1.11,1.35-1.47l2.09,0.04C295.42,87.45,295.63,86.9,295.82,85.11z"
              />
            </g>
            <g>
              <path
                style={{fill: "#FFFFFF"}}
                d="M295.91,84.09c0,0-1.8,0.49-4.68-0.44c0,0-0.09,0.41-0.17,0.97c0.89,0.35,3.01,1.04,4.76,0.49
  						C295.85,84.81,295.88,84.47,295.91,84.09z"
              />
            </g>
          </g>
          <g>
            <path
              style={{fill: "#EE5C60"}}
              d="M295.32,87.85c0.13-0.3,0.2-0.61,0.22-0.93c-0.54-0.07-1.1-0.01-1.62,0.19
  					c-0.83,0.34-1.5,0.96-1.94,1.78c0.08,0.06,0.17,0.12,0.26,0.17C293.4,89.67,294.77,89.13,295.32,87.85z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#E3B59D"}}
            d="M260.94,86.18c0,0-0.17,0.67-0.44,0.58c-0.27-0.09-0.82-0.89-0.52-2.02
  				c0.2-0.75,0.48-3.97-1.65-5.39c0,0-2.77-1.01-4.04,1.91c-0.95,2.2-1.28,4.17-0.08,7.43c1.12,3.05,2.58,4.24,3.14,5.13
  				c1.13,3.33,2.27,6.65,3.4,9.98c0.16,0.48,0.33,0.97,0.49,1.45l5.3-2.7c-1.16-3.39-2.31-6.79-3.47-10.18
  				c-0.12-0.35-0.24-0.7-0.36-1.05c0.47-0.93,1.49-3.16,1.4-5.07c0,0,0.07-0.91-0.5-1.91C263.06,83.35,261.3,84.47,260.94,86.18z"
          />
        </g>
        <g>
          <path
            style={{fill: "#113353"}}
            d="M291.73,101.74c-0.12,0-0.19,0-0.22,0c-3.4-0.22-3.28-3.89-3.28-3.93l0.38,0.02
  				c-0.01,0.13-0.1,3.28,2.91,3.48c0.05,0,4.99,0.17,5.41-3.04l0.38,0.06C296.9,101.54,292.75,101.74,291.73,101.74z"
          />
        </g>
        <g>
          <polygon
            style={{fill: "#D8D7D5"}}
            points="316.99,139.41 323.18,160.46 293.01,168.22 286.54,147.25 			"
          />
        </g>
        <g>
          <ellipse style={{fill: "#FFFFFF"}} cx="304.35" cy="153.54" rx="2.06" ry="2.3" />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#E3B59D"}}
              d="M300.5,105.66c1.78,3.26,3.56,6.52,5.34,9.77c2.81,5.15,5.63,10.3,8.44,15.45
  					c0.64,1.18,1.29,2.35,1.93,3.53c-0.1-0.84-0.2-1.69-0.3-2.53c-0.78,2.26-1.55,4.52-2.33,6.78c-1.23,3.58-2.47,7.16-3.7,10.75
  					c-0.28,0.83-0.57,1.66-0.85,2.48c-0.26,0.75-0.05,1.86,0.3,2.53c0.36,0.68,1.06,1.33,1.76,1.51c0.76,0.19,1.6,0.15,2.27-0.33
  					c0.68-0.49,1.07-1.12,1.36-1.96c1.16-3.38,2.33-6.77,3.49-10.15c0.79-2.29,1.58-4.58,2.37-6.88c0.59-1.73,1.65-3.68,0.73-5.51
  					c-1.19-2.36-2.53-4.64-3.8-6.95c-2.42-4.44-4.85-8.87-7.27-13.31c-1.52-2.79-3.02-5.59-4.57-8.36
  					c-0.02-0.04-0.05-0.09-0.07-0.13c-0.8-1.47-2.68-2.15-4.04-1.18C300.23,102.12,299.64,104.09,300.5,105.66L300.5,105.66z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#E3B59D"}}
            d="M306.17,160.16c0,0,0.53-0.37,0.68-0.1c0.15,0.27,0.07,1.27-0.84,1.88
  				c-0.6,0.4-2.75,2.57-2.04,5.22c0,0,1.44,2.83,4.14,1.61c2.03-0.91,3.47-2.12,4.56-5.44c1.02-3.09,0.65-5.07,0.77-6.15
  				c1.17-3.31,2.34-6.62,3.52-9.93c0.17-0.48,0.34-0.96,0.51-1.44l-5.55-1.98c-1.2,3.38-2.39,6.76-3.59,10.13
  				c-0.12,0.35-0.25,0.7-0.37,1.04c-0.91,0.33-3.01,1.22-4.1,2.7c0,0-0.61,0.62-0.79,1.78S304.87,161.15,306.17,160.16z"
          />
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default Character02
