import {Tooltip} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {arrayOf, bool, func, object, shape, string} from "prop-types"
import React from "react"
import {useEffect, useState} from "react"
import {AiOutlineTrophy as RewardsIcon} from "react-icons/ai"
import {
  FaUser as AccountIcon,
  FaStreetView as ContactIcon,
  FaLock as LockIcon,
} from "react-icons/fa"
import {MdSupportAgent as OwnerIcon, MdGroups as TeamIcon} from "react-icons/md"
import {RiSurveyLine as SurveyIcon} from "react-icons/ri"

import ComplexSelect from "components/complex-select/complex-select"

import useFeatures from "lib/hooks/use-features"

const SecureTooltip = withStyles(theme => ({
  lockIcon: {
    fontSize: 12,
    marginLeft: theme.spacing(1),
    color: theme.palette.brand.darkestGray,
  },
}))(({classes}) => (
  <Tooltip title="This field is secure. If you insert it, the contact will have to authenticate before they can see the value.">
    <span>
      <LockIcon className={classes.lockIcon} />
    </span>
  </Tooltip>
))

const PUBLIC_FIELDS = [
  "account.apy",
  "account.balance",
  "account.closed_date",
  "account.last_transaction_date",
  "account.loan_payment",
  "account.loan_principal",
  "account.nickname",
  "account.opened_date",
  "account.payment_due_date",
  "account.payment_frequency",
]

const config = (surveyFields, rewardSets, hasFeature) =>
  [
    {
      category: "contact",
      icon: <ContactIcon />,
      fields: ({allowPrivate, customPersonalizations: {contact: customPersonalizations}}) => {
        return [
          {renderContent: "First Name", name: "First Name (Contact)", value: "contact.nameFirst"},
          {renderContent: "Last Name", name: "Last Name (Contact)", value: "contact.nameLast"},
          {renderContent: "Full Name", name: "Full Name (Contact)", value: "contact.nameFull"},
          {
            name: "Unique ID (Contact)",
            value: "contact.uniqueId",
            renderContent: (
              <>
                Unique ID <SecureTooltip />
              </>
            ),
          },
        ].filter(({value}) => allowPrivate || value !== "contact.uniqueId")
      },
    },
    {
      category: "contact.meta_public",
      icon: <ContactIcon />,
      fields: ({allowPrivate, customPersonalizations: {contact: customPersonalizations}}) => {
        const publicFields = customPersonalizations.filter(({type}) => type === "meta_public")
        if (publicFields.length === 0) return []
        return [
          ...publicFields.reduce((acc, {key, type}) => {
            return [
              ...acc,
              {
                name: `${key} (Contact meta_public)`,
                renderContent: key,
                value: `contact.${type}.${key}`,
              },
            ]
          }, []),
          {name: "New Contact Personalization", value: "custom-contact-public"},
        ]
      },
    },
    {
      category: "contact.meta_private",
      icon: <ContactIcon />,
      fields: ({allowPrivate, customPersonalizations: {contact: customPersonalizations}}) => {
        const privateFields = customPersonalizations.filter(({type}) => type === "meta_private")
        if (privateFields.length === 0) return []
        return [
          ...privateFields.reduce((acc, {key, type}) => {
            return [
              ...acc,
              {
                name: `${key} (Contact meta_private)`,
                renderContent: (
                  <>
                    {key} <SecureTooltip />
                  </>
                ),
                value: `contact.${type}.${key}`,
              },
            ]
          }, []),
          {name: "New Contact Personalization", value: "custom-contact-private"},
        ]
      },
    },
    {
      category: "account",
      icon: <AccountIcon />,
      fields: ({allowPrivate, customPersonalizations: {account: customPersonalizations}}) => {
        return [
          {name: "Account Number", value: "account.account_number"},
          {name: "Adverse Status", value: "account.adverse_status"},
          {name: "APY", value: "account.apy"},
          {name: "Balance", value: "account.balance"},
          {name: "Channel Opened", value: "account.channel_opened"},
          {name: "Charge Off", value: "account.charge_off"},
          {name: "Closed Reason", value: "account.closed_reason"},
          {name: "Date Closed", value: "account.closed_date"},
          {name: "Credit Card Activation", value: "account.credit_card_activation"},
          {name: "In Person Check Deposit Date", value: "account.in_person_deposit_check_date"},
          {name: "Last Mobile Pay Amount", value: "account.last_mobile_pay_amount"},
          {name: "Last Mobile Pay Transaction", value: "account.last_mobile_pay_transaction"},
          {name: "Last Debit Card Transaction", value: "account.last_debit_card_transaction"},
          {name: "Last Statement Balance", value: "account.last_statement_balance"},
          {name: "Last Transaction Amount", value: "account.last_transaction_amount"},
          {name: "Last Transaction Date", value: "account.last_transaction_date"},
          {name: "Loan Payment", value: "account.loan_payment"},
          {name: "Loan Payoff Date", value: "account.loan_payoff_date"},
          {name: "Loan Principal", value: "account.loan_principal"},
          {name: "Loan Term", value: "account.loan_term"},
          {name: "Maturity Date", value: "account.maturity_date"},
          {name: "MICR", value: "account.micr"},
          {name: "Nickname", value: "account.nickname"},
          {
            name: "Number of Transactions Last 30 Days",
            value: "account.number_transactions_last_30_days",
          },
          {name: "Date Opened", value: "account.opened_date"},
          {name: "Due Date", value: "account.payment_due_date"},
          {name: "Payment Frequency", value: "account.payment_frequency"},
          {name: "Payment Method", value: "account.payment_method"},
          {name: "Product Description", value: "account.product.description"},
          {name: "Product Name", value: "account.product.name"},
          {name: "Product Type", value: "account.product.type"},
          {name: "Remote Deposit Last 30 Days", value: "account.remote_deposit_last_30_days"},
          {name: "Role", value: "account.contact_account.role"},
          {name: "Routing Number", value: "account.routing_number"},
          {name: "Account Status Code", value: "account.status_code"},
          {name: "Amount of Transactions Last 30 Days", value: "account.total_amount_last_30_days"},
        ]
          .filter(
            ({value}) =>
              // allowPrivate allows when the content is a page. For messages, check against the public account fields.
              allowPrivate || PUBLIC_FIELDS.includes(value)
          )
          .map(option =>
            PUBLIC_FIELDS.includes(option.value)
              ? option
              : {
                  ...option,
                  renderContent: (
                    <>
                      {option.name} <SecureTooltip />
                    </>
                  ),
                }
          )
      },
    },
    {
      category: "account.meta_public",
      icon: <AccountIcon />,
      fields: ({allowPrivate, customPersonalizations: {account: customPersonalizations}}) => {
        const publicFields = customPersonalizations.filter(({type}) => type === "meta_public")
        if (publicFields.length === 0) return []
        return [
          ...publicFields.reduce((acc, {key, type}) => {
            return [
              ...acc,
              {
                name: `${key} (Account meta_public)`,
                renderContent: key,
                value: `account.${type}.${key}`,
              },
            ]
          }, []),
          {name: "New Account Personalization", value: "custom-account-public"},
        ]
      },
    },
    {
      category: "account.meta_private",
      icon: <AccountIcon />,
      fields: ({allowPrivate, customPersonalizations: {account: customPersonalizations}}) => {
        const privateFields = customPersonalizations.filter(({type}) => type === "meta_private")
        if (privateFields.length === 0) return []
        return [
          ...privateFields.reduce((acc, {key, type}) => {
            return [
              ...acc,
              {
                name: `${key} (Account meta_private)`,
                renderContent: (
                  <>
                    {key} <SecureTooltip />
                  </>
                ),
                value: `account.${type}.${key}`,
              },
            ]
          }, []),
          {name: "New Account Personalization", value: "custom-account-private"},
        ]
      },
    },
    {
      category: "support_owner",
      icon: <OwnerIcon />,
      fields: [
        {renderContent: "First Name", name: "First Name (Support Owner)", value: "owner.nameFirst"},
        {renderContent: "Last Name", name: "Last Name (Support Owner)", value: "owner.nameLast"},
        {renderContent: "Full Name", name: "Full Name (Support Owner)", value: "owner.nameFull"},
        {renderContent: "Email", name: "Email (Support Owner)", value: "owner.email"},
        {renderContent: "Work Phone", name: "Work Phone (Support Owner)", value: "owner.phoneWork"},
      ],
    },
    {category: "team", icon: <TeamIcon />, fields: [{name: "Team Name", value: "team.name"}]},
    {category: "survey", icon: <SurveyIcon />, fields: surveyFields},
    hasFeature("rewards") ? {category: "rewards", icon: <RewardsIcon />, fields: rewardSets} : null,
  ].filter(item => item !== null)

const PersonalizationSelector = ({
  allowedMetaKeys,
  isPage,
  onChange,
  surveyFields,
  value,
  rewardSets,
}) => {
  const [categories, setCategories] = useState([])
  const [fieldsByCategory, setFieldsByCategory] = useState(null)
  const [valueObject, setValueObject] = useState(null)

  const {hasFeature} = useFeatures()

  useEffect(() => {
    const compiled = config(surveyFields, rewardSets, hasFeature).reduce(
      (acc, {category, categorySplit, categorySplitIndex, categorySplitLabels, fields, icon}) => {
        const fieldsToAccrue = Array.isArray(fields)
          ? fields
          : fields({customPersonalizations: allowedMetaKeys, allowPrivate: isPage})
        const compiledFields = fieldsToAccrue.map(field => ({category, ...field}))

        return {
          ...acc,
          allFields: [...acc.allFields, ...compiledFields],
          categories: [
            ...acc.categories,
            {category, icon, categorySplit, categorySplitIndex, categorySplitLabels},
          ],
          fieldsByCategory: {...acc.fieldsByCategory, ...{[category]: compiledFields}},
        }
      },
      {allFields: [], categories: [], fieldsByCategory: {}, hasFeature}
    )

    setCategories(compiled.categories)
    setFieldsByCategory(compiled.fieldsByCategory)
    setValueObject(compiled.allFields.find(field => field.value === value))
  }, [hasFeature, allowedMetaKeys, isPage, surveyFields, value, rewardSets])

  return fieldsByCategory ? (
    <ComplexSelect
      categories={categories}
      label="Field"
      noMatchMessage="No personalizable attributes matched"
      onChange={onChange}
      value={valueObject || {category: "", value: ""}}
      values={fieldsByCategory}
    />
  ) : null
}

PersonalizationSelector.propTypes = {
  allowedMetaKeys: shape({
    account: arrayOf(string),
    contact: arrayOf(string),
  }),
  classes: object,
  isPage: bool,
  onChange: func,
  surveyFields: arrayOf(object),
  rewardSets: arrayOf(object),
  value: string,
}

export default PersonalizationSelector
