import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import {func, string} from "prop-types"

import DangerButton from "../danger-button/danger-button"

export const DeletePinDialog = ({onClose, onDelete, message}) => (
  <Dialog onClose={onClose} open={true}>
    <DialogTitle>Delete Pin</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <DangerButton className="confirm-delete" onClick={onDelete}>
        Delete
      </DangerButton>
    </DialogActions>
  </Dialog>
)

DeletePinDialog.propTypes = {
  message: string.isRequired,
  onClose: func.isRequired,
  onDelete: func.isRequired,
}

export default DeletePinDialog
