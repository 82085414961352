import {Tooltip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {every} from "lodash"
import {array, arrayOf, bool, node, object, oneOfType, shape, string} from "prop-types"
import {cloneElement, forwardRef} from "react"
import {useSelector} from "react-redux"

import {fullName} from "lib/names"

import {allPermissions} from "../teams/permissions-helpers"

export const isUserPermitted = (user, requiredPermissions) => {
  // eslint-disable-next-line no-param-reassign
  requiredPermissions =
    typeof requiredPermissions === "string" ? [requiredPermissions] : requiredPermissions

  return every(requiredPermissions, requiredPermission =>
    user.permissions.includes(requiredPermission)
  )
}

export const AccessControlled = ({children, requiredPermissions, hideIfNotPermitted}) => {
  const {currentUser, teamUsers} = useSelector(({session: {user, teamUsers}}) => ({
    currentUser: user,
    teamUsers,
  }))
  // eslint-disable-next-line no-param-reassign
  requiredPermissions =
    typeof requiredPermissions === "string" ? [requiredPermissions] : requiredPermissions
  const classes = useStyles()

  if (!currentUser) return null

  if (isUserPermitted(currentUser, requiredPermissions)) return children

  if (hideIfNotPermitted) return null

  const Child = forwardRef((props, ref) => (
    <span {...props} ref={ref}>
      {cloneElement(children, {disabled: true})}
    </span>
  ))

  if (!teamUsers)
    return (
      <Tooltip title="You do not have permission to perform this action. Fetching users...">
        <Child />
      </Tooltip>
    )

  const permittedUsers = teamUsers.filter(user => isUserPermitted(user, requiredPermissions))
  const userManagers = teamUsers.filter(user => isUserPermitted(user, "team:users"))

  return (
    <Tooltip
      title={
        <div className={classes.container}>
          {permittedUsers.length ? (
            <>
              <p>
                You do not have permission to perform the action(s):{" "}
                {requiredPermissions.map(p => allPermissions[p]).join(", ")}. Please contact one of
                the following:
              </p>
              <ul className={classes.list}>
                {permittedUsers.map(user => (
                  <li className={classes.listItem} key={user.id}>
                    <a href={`mailto:${user.email}`} rel="noopener noreferrer" target="_blank">
                      {fullName(user)}
                    </a>
                  </li>
                ))}
              </ul>
              <p>Or contact someone with User Management privileges:</p>
            </>
          ) : (
            <p>
              No one on your team has the following permissions:{" "}
              {requiredPermissions.map(p => allPermissions[p]).join(", ")}. Please contact someone
              with User Management privileges:
            </p>
          )}
          <ul className={classes.list}>
            {userManagers.map(user => (
              <li className={classes.listItem} key={user.id}>
                <a href={`mailto:${user.email}`} rel="noopener noreferrer" target="_blank">
                  {fullName(user)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      }
    >
      <Child />
    </Tooltip>
  )
}

const useStyles = makeStyles({
  container: {
    width: 250,
  },
  list: {
    paddingLeft: 20,
  },
  listItem: {
    "& a": {
      color: "inherit",
    },
  },
})

AccessControlled.propTypes = {
  children: node.isRequired,
  currentUser: object,
  hideIfNotPermitted: bool,
  requiredPermissions: oneOfType([array, string]).isRequired,
  teamUsers: arrayOf(
    shape({
      id: string.isRequired,
      email: string,
      permissions: arrayOf(string).isRequired,
    })
  ),
}

export const controlAccess = (options = {}) => Component => props => (
  <AccessControlled {...options}>
    <Component {...props} />
  </AccessControlled>
)

export default AccessControlled
