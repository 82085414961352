import {Checkbox, Container, FormControl, MenuItem, TextField} from "@mui/material"
import {omit} from "lodash"
import {array, bool, number, shape, string} from "prop-types"
import React, {useContext, useEffect} from "react"

import DOSelect from "components/do-select/do-select"
import {RewardSetFormContext} from "components/reward/reward-set-form-context-provider"
import {compileValues, isDirty} from "components/reward/reward-set-form-helpers"
import {
  ConditionCard,
  ConditionCardContent,
  Form,
} from "components/reward/reward-step-conditions/styled-components"
import {RewardsContext} from "components/reward/rewards-context-provider"

import {nonEmptyArray, requiredField, validPositiveInteger} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const DirectDepositCompleted = ({initialValues, isEditable}) => {
  const {dispatch} = useContext(RewardSetFormContext)
  const {templates} = useContext(RewardsContext)

  const conditions = [
    {
      evaluate: "count",
      text: "Completed count",
    },
    {
      evaluate: "percent",
      text: "Completed percent",
    },
    {
      evaluate: "total",
      text: "Completed total",
    },
  ]

  // Form config
  const {field, inputs} = useForm({
    initialValues: {
      evaluate: !!initialValues.meta.evaluate ? initialValues.meta.evaluate : null,
      threshold: !!initialValues.meta.threshold ? parseInt(initialValues.meta.threshold) : null,
      templateIds: !!initialValues.meta.templateIds ? initialValues.meta.templateIds : [],
      value: initialValues.value ? parseInt(initialValues.value) : undefined,
    },
    validators: {
      evaluate: [requiredField],
      templateIds: [nonEmptyArray],
      threshold: [validPositiveInteger],
      value: [validPositiveInteger],
    },
  })

  // When inputs change, dispatch updated step state
  useEffect(() => {
    const {value, ...meta} = compileValues(inputs)
    const updated = {
      data: {
        condition: "direct_deposit_completed",
        id: initialValues.id,
        meta: meta.evaluate === "total" ? {...omit(meta, ["threshold"])} : meta,
        value,
      },
      isDirty: isDirty(inputs),
      isValid: Object.values(inputs).reduce((acc, fieldObject) => {
        const shouldNotValidate =
          !acc || (inputs.evaluate.value === "total" && fieldObject.name === "threshold")

        if (shouldNotValidate) {
          return acc
        }

        if (fieldObject.name === "evaluate" && !requiredField(initialValues.meta.evaluate)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (fieldObject.name === "templateIds" && !nonEmptyArray(initialValues.meta.templateIds)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "threshold" &&
          !validPositiveInteger(initialValues.meta.threshold)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (fieldObject.name === "value" && !validPositiveInteger(initialValues.value)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        return "error" in fieldObject ? !fieldObject.error : false
      }, true),
    }

    dispatch({type: "SET_REWARD_STEP_FORM", rewardStepForm: updated})
  }, [dispatch, initialValues, inputs])

  return (
    <ConditionCard>
      <ConditionCardContent>
        <Form id={`direct-deposit-completed-${initialValues.id}`}>
          <FormControl disabled={!isEditable} error={!!inputs.templateIds.error} fullWidth>
            <DOSelect
              id="template-id"
              label="Campaigns"
              multiple={true}
              {...field("templateIds")}
              renderValue={templateIds =>
                templateIds
                  .map(id => {
                    const template = templates.find(t => t.id === id)

                    return template ? template.name : []
                  })
                  .join(", ")
              }
            >
              {templates.map(template => (
                <MenuItem key={template.id} value={template.id}>
                  <Checkbox
                    checked={inputs.templateIds.value.includes(template.id)}
                    sx={{pointerEvents: "none"}}
                    color={"primary"}
                  />
                  {template.name.length < 32 ? template.name : `${template.name.slice(0, 31)} ...`}
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>

          <Container sx={{paddingTop: "1em"}} />
          <FormControl disabled={!isEditable} fullWidth>
            <DOSelect
              id="direct-deposit-evaluate-id"
              label="Evaluate"
              multiple={false}
              {...field("evaluate")}
            >
              {conditions.map(condition => (
                <MenuItem key={condition.evaluate} value={condition.evaluate}>
                  {condition.text}
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>

          {inputs.evaluate.value !== "total" && (
            <>
              <Container sx={{paddingTop: "1em"}} />
              <TextField
                disabled={!isEditable}
                label="Threshold"
                fullWidth={true}
                type="number"
                {...field("threshold")}
              />
            </>
          )}

          <Container sx={{paddingTop: "1em"}} />
          <TextField
            disabled={!isEditable}
            label="Value"
            fullWidth={true}
            type="number"
            {...field("value")}
          />

          <Container sx={{paddingTop: "1em"}} />
        </Form>
      </ConditionCardContent>
    </ConditionCard>
  )
}

DirectDepositCompleted.defaultProps = {
  initialValues: {
    condition: "direct-deposit-started",
    meta: {
      evaluate: undefined,
      templateIds: [],
      threshold: undefined,
    },
  },
  value: undefined,
}

DirectDepositCompleted.propTypes = {
  initialValues: shape({
    condition: string,
    id: string.isRequired,
    meta: shape({
      evaluate: string,
      templateIds: array,
      threshold: number,
    }),
    value: number,
  }).isRequired,
  isEditable: bool,
}

export default DirectDepositCompleted
