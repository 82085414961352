import {Button, Divider, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import arrayMove from "array-move"
import cx from "classnames"
import {arrayOf, bool, func, object, oneOf, shape, string} from "prop-types"
import {Component} from "react"
import {MdAdd} from "react-icons/md"
import {connect} from "react-redux"

import {createDefaultContentVariables} from "components/templates/template-helpers"

import {featurify} from "lib/hooks/use-features"

import {templateContentContext} from "../../contexts/template-content-context"
import {
  deleteMessage,
  fetchObjective,
  removeTemplateMessage,
  sendDraftMessage,
  sortMessages,
  updateMessage,
} from "../../lib/api"
import {buildNewContentItem} from "../content-library/helpers"
import TemplateMessageForm from "./template-message-form"
import TemplateMessageList from "./template-message-list"

export class PageMessageSettings extends Component {
  openNewDialog = async type => {
    const {pageId, templatePages, setTemplateMessageToEdit} = this.props
    const list = this.props.templateMessages.filter(
      tm => tm.templatePage.pageId === pageId && tm.message.type === type
    )
    const order = list.length + 1
    const templatePage = templatePages.find(tp => tp.page.id === pageId)

    const objectiveId = this.currentPage()?.objectiveId
    let objective

    if (!!objectiveId) objective = await fetchObjective(objectiveId)

    const message = buildNewContentItem(type, objective, order)

    let content_variables = null

    if (type === "sms")
      content_variables = createDefaultContentVariables({
        content_variables: {},
        templatePage,
        slug: message.payload.slug,
      })

    setTemplateMessageToEdit({
      message,
      templatePageId: templatePage.id,
      isNew: true,
      content_variables,
    })
  }

  currentPage = props =>
    (props || this.props).templatePages
      .flatMap(tp => tp.page)
      .find(p => p.id === (props || this.props).pageId)

  onSendSampleMessage = message =>
    sendDraftMessage({
      ...message,
      pageId: this.props.pageId,
    })

  onDeleteMessage = () => {
    const {
      templateId,
      hasFeature,
      setTemplateMessageToEdit,
      templateMessageToEdit,
      removeTemplateMessageFromState,
    } = this.props
    const {
      id: templateMessageId,
      message: {id: messageId},
    } = templateMessageToEdit

    if (templateMessageToEdit.isNew) {
      setTemplateMessageToEdit(null)
      return
    }

    removeTemplateMessage(templateId, templateMessageId).then(async () => {
      if (!hasFeature("atomic-assets")) await deleteMessage(messageId)

      removeTemplateMessageFromState(templateMessageId)
      setTemplateMessageToEdit(null)
    })
  }

  onArchiveMessage = () => {
    const {
      templateMessageToEdit,
      removeTemplateMessageFromState,
      setTemplateMessageToEdit,
    } = this.props

    if (templateMessageToEdit.isNew) {
      setTemplateMessageToEdit(null)
      return
    }

    updateMessage(templateMessageToEdit.message.id, {isArchived: true})
    removeTemplateMessageFromState(templateMessageToEdit.id)
    setTemplateMessageToEdit(null)
  }

  // It's not immediately obvious, but you can't sort from this view if you have
  // atomic assets turned on (it's in TemplateMessage.jsx)
  // So we don't care about leveraging the context method for sorting, because
  // we have two lists here.
  moveMessage = async (oldIndex, newIndex, type) => {
    const {pageId, templateId, templateMessages, fetchTemplateMessages} = this.props
    const messages = templateMessages.filter(
      tm => tm.templatePage.pageId === pageId && tm.message.type === type
    )

    await sortMessages(
      templateId,
      pageId,
      arrayMove(messages, oldIndex, newIndex).map((message, index) => ({
        ...message,
        order: index + 1,
      }))
    )

    fetchTemplateMessages()
  }

  moveEmail = ({oldIndex, newIndex}) => this.moveMessage(oldIndex, newIndex, "email")
  moveSms = ({oldIndex, newIndex}) => this.moveMessage(oldIndex, newIndex, "sms")

  getScrollableContainer = () => this.props.scrollableContainer

  render() {
    const {
      classes,
      scrollableContainer,
      hasFeature,
      templateMessages,
      currentUser,
      setTemplateMessageToEdit,
      templateMessageToEdit,
      template,
      templatePages,
      completeEditTemplateMessage,
      sendSampleMessage,
      makeMessageUnique,
      templateId,
    } = this.props

    if (!template) return null

    const hasMissingPageSlugs = templateMessages.some(tm => tm.isMissingPageSlugs)
    const hasObjectiveMismatch = templateMessages.some(tm => tm.mismatchesPageObjective)
    const smsTemplateMessages = templateMessages.filter(tm => tm.message.type === "sms")
    const emailTemplateMessages = templateMessages.filter(tm => tm.message.type === "email")

    return (
      <>
        <div className={cx("foo", classes.container)}>
          {hasObjectiveMismatch ? (
            <Typography className={classes.mismatchErrorText} gutterBottom={true} variant="caption">
              Some messages have objectives that do not match the objective for the page.
            </Typography>
          ) : null}
          {hasMissingPageSlugs ? (
            <Typography className={classes.mismatchErrorText} gutterBottom={true} variant="caption">
              Some of these messages link to a journey page but have not had one selected yet.
            </Typography>
          ) : null}
          <Typography className={classes.header} variant="subtitle1">
            SMS Messages
          </Typography>
          {scrollableContainer && (
            <TemplateMessageList
              getContainer={this.getScrollableContainer}
              onSortEnd={this.moveSms}
              templateMessages={smsTemplateMessages}
              useDragHandle={true}
            />
          )}
          {!hasFeature("atomic-assets") && (
            <Button
              className={cx("add-sms", classes.addButton)}
              color="primary"
              onClick={() => this.openNewDialog("sms")}
              size="small"
              variant="contained"
            >
              <MdAdd className={classes.addButtonIcon} /> Add SMS
            </Button>
          )}
          <Divider className={classes.divider} />
          <Typography className={classes.header} variant="subtitle1">
            Email Messages
          </Typography>
          {scrollableContainer && (
            <TemplateMessageList
              getContainer={this.getScrollableContainer}
              onSortEnd={this.moveEmail}
              templateMessages={emailTemplateMessages}
              useDragHandle={true}
            />
          )}
          {!hasFeature("atomic-assets") && (
            <Button
              className={classes.addButton}
              color="primary"
              data-testid="add-email"
              onClick={() => this.openNewDialog("email")}
              size="small"
              variant="contained"
            >
              <MdAdd className={classes.addButtonIcon} /> Add Email
            </Button>
          )}
          {templateMessageToEdit && (
            <TemplateMessageForm
              currentUser={currentUser}
              dialogTitle={templateMessageToEdit.message.contentName}
              onArchive={this.onArchiveMessage}
              onClose={() => setTemplateMessageToEdit(null)}
              onDelete={this.onDeleteMessage}
              onMakeMessageUnique={templateMessageToEdit.id ? makeMessageUnique : null}
              onSendSampleMessage={sendSampleMessage}
              onSubmit={completeEditTemplateMessage}
              templateId={templateId}
              templateMessage={templateMessageToEdit}
              templatePages={templatePages}
              theme={template.theme}
              type={templateMessageToEdit.message.type}
            />
          )}
        </div>
      </>
    )
  }
}

PageMessageSettings.propTypes = {
  classes: object.isRequired,
  completeEditTemplateMessage: func,
  currentUser: object.isRequired,
  fetchTemplateMessages: func.isRequired,
  hasFeature: func.isRequired,
  makeMessageUnique: func,
  pageId: string.isRequired,
  removeTemplateMessageFromState: func.isRequired,
  scrollableContainer: object,
  sendSampleMessage: func,
  setTemplateMessageToEdit: func.isRequired,
  template: shape({theme: object}),
  templateId: string.isRequired,
  templateMessageToEdit: shape({
    id: string,
    isNew: bool,
    message: shape({
      contentName: string,
      id: string,
      type: oneOf(["sms", "email"]),
    }),
  }),
  templateMessages: arrayOf(
    shape({
      isMissingPageSlugs: bool,
      message: shape({type: string}),
      mismatchesPageObjective: bool,
      templatePage: shape({pageId: string}),
    })
  ),
  templatePages: arrayOf(
    shape({
      page: shape({contentName: string.isRequired, id: string.isRequired, slug: string.isRequired}),
    })
  ),
}

const styles = theme => ({
  container: {
    flex: 1,
  },
  dialog: {
    maxWidth: 800,
  },
  content: {
    width: "100%",
    height: 550,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
  },
  divider: {
    backgroundColor: "transparent",
    margin: "20px 0",
  },
  header: {
    marginBottom: 15,
  },
  messageLibraryHeader: {
    zIndex: 1,
    boxShadow: "0px -8px 10px 8px rgba(0,0,0,0.2)",
  },
  messageLibraryFooter: {
    zIndex: 1,
    boxShadow: "0px 8px 10px 8px rgba(0,0,0,0.2)",
  },
  addButton: {
    marginTop: 15,
  },
  addButtonIcon: {
    marginRight: 10,
  },
  mismatchErrorText: {
    color: theme.palette.error.main,
    marginBottom: 15,
    display: "block",
  },
})

export default withStyles(styles)(
  connect(({session}) => ({currentUser: session?.user}))(
    featurify(templateContentContext(PageMessageSettings))
  )
)
