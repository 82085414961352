import {
  LANDING_PAGE_CLEARED,
  LANDING_PAGE_FAILED,
  LANDING_PAGE_RESET,
  LANDING_PAGE_SET,
  LANDING_PAGE_SUCCESS,
} from "../landing-page/landing-page-actions"

const initialState = {
  template: {},
}

function landingPage(state = initialState, action) {
  switch (action.type) {
    case LANDING_PAGE_SET:
      return {
        ...state,
        ...action.payload,
      }

    case LANDING_PAGE_FAILED:
      return {
        ...state,
        success: false,
        error: true,
      }

    case LANDING_PAGE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
      }

    case LANDING_PAGE_RESET:
      return {
        ...state,
        success: false,
        error: false,
      }

    case LANDING_PAGE_CLEARED:
      return initialState

    default:
      return state
  }
}

export default landingPage
