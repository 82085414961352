import {Route, Switch} from "react-router-dom"

import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"
import AccountSettingsContainer from "./account-settings-container"
import UserAuditLogsContainer from "./user-audit-logs-container"

export const AccountRouter = () => (
  <>
    <TitleBar title="Your Account Settings" />

    <Padded>
      <Switch>
        <Route component={AccountSettingsContainer} exact={true} path="/admin/account" />
        <Route component={UserAuditLogsContainer} path="/admin/account/audit-logs" />
      </Switch>
    </Padded>
  </>
)

AccountRouter.propTypes = {}

export default AccountRouter
