import {Paper, Typography} from "@mui/material"
import {array, bool, number, shape, string} from "prop-types"

import pluralize from "lib/string/pluralize"

const CampaignOverview = ({
  approvalMessage,
  dripTimeline,
  scheduledTimeline,
  template,
  templatePages,
}) => {
  const journeyDays = template.journeyDurationHours / 24
  const journeyHours = template.journeyDurationHours % 24

  return (
    <Paper
      sx={theme => ({
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
      })}
    >
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Campaign Name:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {template.name}
      </Typography>
      {template.reward && (
        <>
          <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
            Reward:
          </Typography>
          <Typography variant="body2" gutterBottom>
            {template.reward}
          </Typography>
        </>
      )}
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Time to complete:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {`${journeyDays} ${pluralize("day", journeyDays)} ${journeyHours} ${pluralize(
          "hour",
          journeyHours
        )}`}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Progress ring:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {template.hideProgressRing ? "Hidden" : "Shows"}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Authentication required:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {template.isAuthRequired ? "Yes" : "No"}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Page count:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {templatePages.length}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Drip message count:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {dripTimeline.messages.length}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Drip message pins:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {dripTimeline.overview.length}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Scheduled message count:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {scheduledTimeline.messages.length}
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
        Automated targeting:
      </Typography>
      <Typography variant="body2" gutterBottom>
        {template.targetingGroups?.length > 0 ? "Yes" : "No"}
      </Typography>
      {approvalMessage && (
        <>
          <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
            Comment from sender:
          </Typography>
          <Typography variant="body2" gutterBottom>
            {approvalMessage}
          </Typography>
        </>
      )}
    </Paper>
  )
}

CampaignOverview.propTypes = {
  approvalMessage: string.isRequired,
  dripTimeline: shape({messages: array.isRequired, overview: array.isRequired}).isRequired,
  scheduledTimeline: shape({messages: array.isRequired}).isRequired,
  template: shape({
    hideProgressRing: bool.isRequired,
    isAuthRequired: bool.isRequired,
    journeyDurationHours: number.isRequired,
    name: string.isRequired,
    reward: string,
    targetingGroups: array.isRequired,
  }).isRequired,
  templatePages: array.isRequired,
}

export default CampaignOverview
