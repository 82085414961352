import {Grid, TextField, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useEffect, useState} from "react"
import {useSelector} from "react-redux"

import {createHandler, useSocket} from "contexts/socket-manager"
import {getCmcFlexDiagnostics} from "lib/api"

import AccessControlled from "../access-control/access-controlled"
import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import SaveButton from "../save-button/save-button"
import CmcFlexDiagnosticsResult from "./cmc-flex-diagnostics-result"

const CmcFlexDiagnostics = () => {
  const team = useSelector(({session}) => session.team)
  const teamId = team?.id
  const [url, setUrl] = useState("")
  const [result, setResult] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const onTest = e => {
    e.preventDefault()
    setResult(null)
    setIsLoading(true)
    getCmcFlexDiagnostics({url: url.trim()})
  }

  const {addHandler, removeHandler} = useSocket()

  useEffect(() => {
    if (!teamId) return

    const diagnosticCompleted = createHandler(
      `cmc-flex-diagnostics:${teamId}`,
      "cmc_flex_diagnostic_completed",
      result => {
        setIsLoading(false)
        setResult(result)
      }
    )

    addHandler(diagnosticCompleted)

    return () => {
      removeHandler(diagnosticCompleted)
    }
  }, [addHandler, removeHandler, teamId])

  return (
    <>
      <DocumentTitle title="Team Settings - CMC Flex Diagnostics" />
      <Typography variant="h5">CMC Flex Diagnostics</Typography>
      <Box>
        <Typography className={classes.contentSpacing}>
          Test a potential CMC Flex URL here
        </Typography>
        <form onSubmit={onTest}>
          <AccessControlled requiredPermissions="team_settings:api">
            <TextField
              className={classes.field}
              fullWidth={true}
              label="URL"
              type="url"
              value={url}
              onChange={({target: {value}}) => setUrl(value)}
            />
          </AccessControlled>

          <div className={classes.formActions}>
            <SaveButton
              submitting={isLoading}
              stateLabels={{
                default: "Test",
                saved: "Test Complete!",
                submitting: "Submitting...",
              }}
            />
          </div>
        </form>
        {result && (
          <Grid container={true} item={true}>
            <Grid classes={{item: classes.responseItem}} item={true}>
              <CmcFlexDiagnosticsResult
                classes={classes}
                response={result.response}
                error={result.error}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  contentSpacing: {
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
})

export default CmcFlexDiagnostics
