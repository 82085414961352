import ProgressMeterButton, {ProgressMeterIcon} from "./progress-meter-button"
import ProgressMeterReadonly from "./progress-meter-readonly"
import ProgressMeterSettings from "./progress-meter-settings"

export default {
  title: "Progress Meter",
  type: "progress-meter",
  Button: ProgressMeterButton,
  Icon: ProgressMeterIcon,
  Readonly: ProgressMeterReadonly,
  Settings: ProgressMeterSettings,
  suitableFor: ["content_container"],
}
