import {Button, ButtonGroup, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"

import DatePicker from "components/date-picker/date-picker"
import {
  TEAM_INSIGHTS_FILTER_INITIAL_VALUES,
  TemplateStatuses,
} from "components/team-insights/team-insights-reducer"

import dateParser from "lib/date/date-parser"
import useForm from "lib/hooks/use-form"

const TeamInsightsFilter = ({initialValues = TEAM_INSIGHTS_FILTER_INITIAL_VALUES, onSubmit}) => {
  const classes = useStyles()
  const {change, field, inputs, resetForm} = useForm({
    autoSubmitOnChange: true,
    autoSubmitDebounceTime: 250,
    enableReinitialize: true,
    initialValues,
    parse: {
      startDate: dateParser,
      endDate: dateParser,
    },
    onSubmit,
  })

  const changeStatusFilter = ({currentTarget}) => {
    change("templateStatus", currentTarget.value)
  }

  const statusFilter = inputs.templateStatus.value

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <DatePicker format="MMMM dd yyyy" label="From" {...field("startDate")} />
        <DatePicker format="MMMM dd yyyy" label="To" {...field("endDate")} />

        <div>
          <Typography variant="body2">Status</Typography>
          <div>
            <ButtonGroup size="small" variant="outlined">
              <Button
                color={TemplateStatuses.All === statusFilter ? "primary" : "grey"}
                onClick={changeStatusFilter}
                tabIndex={0}
                value={TemplateStatuses.All}
              >
                All
              </Button>
              <Button
                color={TemplateStatuses.Live === statusFilter ? "primary" : "grey"}
                onClick={changeStatusFilter}
                tabIndex={1}
                value={TemplateStatuses.Live}
              >
                Live
              </Button>
              <Button
                color={TemplateStatuses.Archived === statusFilter ? "primary" : "grey"}
                onClick={changeStatusFilter}
                tabIndex={2}
                value={TemplateStatuses.Archived}
              >
                Archived
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <Button color="grey" onClick={resetForm}>
          reset
        </Button>
      </div>
    </div>
  )
}

TeamInsightsFilter.propTypes = {
  initialValues: object,
  onSubmit: func.isRequired,
}

const NAV_HEIGHT = 44
const SUB_NAV_HEIGHT = 44
const PADDING = 16

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    position: "sticky",
    top: NAV_HEIGHT + SUB_NAV_HEIGHT + PADDING,
    zIndex: theme.zIndex.appBar,
  },
  innerContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    background: "#fff",
    padding: theme.spacing(1),
    borderRadius: 2,
    boxShadow: theme.shadows[2],
    width: "100%",

    "& > :nth-last-child(n + 2)": {
      marginRight: theme.spacing(2),
    },
  },
}))

export default TeamInsightsFilter
