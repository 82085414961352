import {
  allAccountFields,
  allContactFields,
  journeyAccountFields,
} from "../teams/csv-processing-settings/csv-processing-helpers"
import jaro from "./jaro-winkler"

const getFieldFromJaroMatch = jaroMatch => {
  if (!jaroMatch) return "ignored"
  const {field} = jaroMatch

  if (field.key) return field.key
  if (field.value) return field.value
  if (field.digital_onboarding_field) return field.digital_onboarding_field
  return "ignored"
}

const getBaseFieldSet = batchType => {
  switch (batchType) {
    case "account":
      return allAccountFields
    case "journey":
      // Shim to enable journey association with account number.
      return [...journeyAccountFields, ...allContactFields]
    default:
      return allContactFields
  }
}

export const matchColumns = (columns, {fieldMap, objectives}, batchType) => {
  const baseFieldSet = getBaseFieldSet(batchType)

  return columns.reduce(
    ({remainingFieldSet, matchedData}, current) => {
      //  check the overrides first
      const previouslyMappedField = fieldMap.find(({csv_field}) => csv_field === current)

      if (previouslyMappedField) {
        const {csv_field, digital_onboarding_field} = previouslyMappedField

        if (digital_onboarding_field.match(/^meta_(private|public)/))
          return {
            remainingFieldSet: remainingFieldSet.filter(field => field.csv_field !== csv_field),
            matchedData: [...matchedData, previouslyMappedField],
          }

        return {
          remainingFieldSet: remainingFieldSet.filter(field => field !== previouslyMappedField),
          matchedData: [...matchedData, previouslyMappedField],
        }
      }

      if (current.match(/^meta_(private|public)\./)) {
        const meta_prefix = current.indexOf(".")
        const meta_sub_key = current.slice(meta_prefix + 1)

        const digital_onboarding_field = current.match(/^meta_private\./)
          ? "meta_private"
          : "meta_public"

        return {
          remainingFieldSet,
          matchedData: [
            ...matchedData,
            {
              csv_field: current,
              digital_onboarding_field,
              meta_sub_key,
            },
          ],
        }
      }

      const fieldAndSimilarity = remainingFieldSet
        .map(field => {
          const {key, value, csv_field} = field
          const comparator = key || value || csv_field

          const {similarity} = jaro(comparator, current)

          return {
            field,
            similarity,
          }
        })
        .filter(({similarity}) => similarity >= 0.9)
        .sort(({similarity: lhsSimilarity}, {similarity: rhsSimilarity}) => {
          if (lhsSimilarity < rhsSimilarity) return 1
          if (lhsSimilarity > rhsSimilarity) return -1
          return 0
        })
        .find(Boolean)

      const digital_onboarding_field = getFieldFromJaroMatch(fieldAndSimilarity)

      return {
        remainingFieldSet:
          digital_onboarding_field === "ignored"
            ? remainingFieldSet
            : remainingFieldSet.filter(field => field !== fieldAndSimilarity.field),
        matchedData: [
          ...matchedData,
          {
            csv_field: current,
            digital_onboarding_field,
            meta_sub_key: "",
          },
        ],
      }
    },
    {
      remainingFieldSet: [...baseFieldSet, ...fieldMap, ...objectives],
      matchedData: [],
    }
  ).matchedData
}

export const getLink = (variant, templateId, batchId) => {
  if (variant === "journey") return `/admin/templates/${templateId}/journeys?batchId=${batchId}`

  return `/admin/contacts?batchId=${batchId}`
}
export const resultKeys = {
  contact: {
    new: "newContacts",
    existing: "existingContacts",
    failed: "failedContacts",
  },
  journey: {
    new: "newJourneys",
    existing: "existingJourneys",
    failed: "failedJourneys",
  },
}
