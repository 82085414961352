export const audienceInputs = [
  {
    label: "Net New - Direct",
    value: "Net New - Direct",
    description: "Contacts that are brand new to the FI",
  },
  {
    label: "Net New - Indirect",
    value: "Net New - Indirect",
    description: "Contacts that are brand new to the FI, introduced through a third party",
  },
  {
    label: "Existing - Direct",
    value: "Existing - Direct",
    description: "Contacts that already have a relationship with the FI",
  },
  {
    label: "Existing - Indirect",
    value: "Existing - Indirect",
    description: "Contacts that already have a relationship with the FI",
  },
  {
    label: "Attrited/Dormant",
    value: "Attrited/Dormant",
    description: "Contacts with solely inactive or zero-balance accounts",
  },
  {
    label: "All",
    value: "All",
    description: "Entire contact base",
  },
  {
    label: "Other",
    value: "Other",
    description: "",
  },
]
