import {Typography} from "@mui/material"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {MdCheckCircle as CheckIcon} from "react-icons/md"
import {Link} from "react-router-dom"
import {SortableElement} from "react-sortable-hoc"

import {journeyContext} from "./journey-context"

const NavigationItem = ({
  isActive,
  isLive,
  isCompletable,
  isCompleted,
  isSortable,
  isVisited,
  onCloseMobileMenu,
  linkTo,
  title,
  themeClasses,
}) => {
  const hasCheck = (isCompletable && isCompleted) || (!isCompletable && isVisited)

  return (
    <li
      className={cx(themeClasses.navigationItem, {
        [themeClasses.navigationItemActive]: isActive,
        [themeClasses.navigationItemComplete]: hasCheck,
        [themeClasses.navigationItemEmpty]: !hasCheck && !isActive,
        [themeClasses.navigationItemNotLive]: !isLive,
        "is-active": isActive,
        "is-complete": hasCheck,
        "is-not-live": !isLive,
      })}
      role="presentation"
    >
      <Typography
        aria-label={title}
        className={cx(themeClasses.navigationItemPage, {
          [themeClasses.navigationItemIsSortable]: isSortable,
        })}
        color="inherit"
        component={Link}
        gutterBottom={true}
        onClick={onCloseMobileMenu}
        to={linkTo}
      >
        <div aria-hidden="true" className={cx("icon", themeClasses.navigationItemIcon)}>
          <CheckIcon />
        </div>
        <div aria-hidden="true" className="page-title">
          {title}
        </div>
      </Typography>
    </li>
  )
}

NavigationItem.propTypes = {
  id: string.isRequired, // eslint-disable-line react/no-unused-prop-types
  isActive: bool.isRequired,
  isCompletable: bool,
  isCompleted: bool,
  isLive: bool.isRequired,
  isSortable: bool,
  isVisited: bool.isRequired,
  linkTo: string.isRequired,
  onCloseMobileMenu: func.isRequired,
  onSortCancel: func, // eslint-disable-line react/no-unused-prop-types
  themeClasses: object.isRequired,
  title: string.isRequired,
}

export default SortableElement(journeyContext(NavigationItem))
