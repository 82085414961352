import get from "lodash/get"
import isPlainObject from "lodash/isPlainObject"

const getNestedKeys = (errors, keys = []) => {
  return Object.entries(errors).reduce((acc, [key, value]) => {
    if (isPlainObject(value)) return getNestedKeys(value, [...keys, key])

    return [...acc, [...keys, key].join(".")]
  }, [])
}

export default class HttpError extends Error {
  constructor(status, errorResponse) {
    super(errorResponse)

    if (Error.captureStackTrace) Error.captureStackTrace(this, HttpError)

    this.name = "HttpError"
    this.status = status
    this.body = errorResponse

    // {error: "Email is required"}
    // {errors: {"unique_id": ["can't be blank"]}}
    if (typeof errorResponse.error === "string") {
      this.message = errorResponse.error
    } else {
      const {errors} = errorResponse

      this.message = getNestedKeys(errors)
        .map(errorKey => {
          const errorList = get(errors, errorKey)

          return Array.isArray(errorList)
            ? errorList.map(err => `${errorKey} ${err}`).join(",")
            : errorList
        })
        .join(",")
    }
  }
}
