import {Button, IconButton, Switch, TableCell, TableRow, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {func, object} from "prop-types"
import {useState} from "react"
import {FiEdit as EditDefaultContentIcon} from "react-icons/fi"
import {MdBrush as EditBrandingIcon, MdDelete} from "react-icons/md"
import {Link} from "react-router-dom"

import BrandingSettings from "components/cross-channel-engagement/branding-settings"
import MuiIcon from "components/mui-icon"
import SaveButton from "components/save-button/save-button"

import {requiredField} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const useStyles = makeStyles(theme => ({
  buttonText: {
    textTransform: "capitalize",
  },
  channelNameTableCell: {
    width: "400px",
    marginTop: theme.spacing(2),
  },
  theX: {
    marginTop: theme.spacing(3),
    display: "inline-block",
  },
  channelPropertyCell: {
    width: "150px",
  },
  channelPropertyTextField: {
    width: "70px",
    textAlign: "center",
  },
  deleteIcon: {
    height: 48,
    width: 48,
  },
  code: {
    fontFamily: `"Monaco","Consolas","Lucida Console",monospace`,
  },
  rowControls: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invisible: {
    visibility: "hidden",
  },
}))

const CrossChannelEngagementSettingsRow = ({
  onCreate,
  onPartialUpdate,
  onUpdate,
  onRemove,
  row,
}) => {
  const defaultContainer = row?.contentContainers
    ? row.contentContainers.find(({pageId}) => pageId === null)
    : null
  const [isDirty, setIsDirty] = useState(row?.key ?? false)
  const [isBrandingSettingsOpen, setIsBrandingSettingsOpen] = useState(false)

  const {change, field, handleSubmit, failed, invalid, submitting} = useForm({
    initialValues: row,
    onSubmit: formData => {
      setIsDirty(false)

      // NB: Intentionally excluding `themeStyles`, so that when we save we do not override
      // themeStyles in the event that the user edited `themeStyles` through branding
      // settings.
      const transformedFormData = Object.entries(formData).reduce(
        (acc, [key, value]) => (key === "themeStyles" ? acc : {...acc, [key]: value}),
        {}
      )

      if (row.id) {
        return onUpdate(row.id, transformedFormData)
      } else {
        return onCreate(transformedFormData)
      }
    },
    row,
    validators: {
      name: [requiredField],
      "properties.width": [requiredField],
      "properties.height": [requiredField],
    },
  })

  const classes = useStyles()

  const handleRemove = () => {
    window.confirm("Are you sure you want to delete this channel?\nThis cannot be undone.") &&
      onRemove(row.id)
  }

  const handleChange = ({target: {name, value}}) => {
    change(name, value)
    setIsDirty(true)
  }

  return (
    <TableRow key={row.key || row.id}>
      {/* Channel Name */}
      <TableCell>
        <form onSubmit={handleSubmit} className={classes.channelNameTableCell}>
          <div>
            <TextField
              fullWidth={true}
              margin="normal"
              {...field("name", {onChange: handleChange})}
            />

            {row.id && (
              <div>
                Key: <span className={classes.code}>{row.id}</span>
              </div>
            )}
          </div>
        </form>
      </TableCell>

      {/* Width X Height In Pixels */}
      <TableCell>
        <form onSubmit={handleSubmit} className={classes.channelPropertyCell}>
          <TextField
            margin="normal"
            inputProps={{className: classes.channelPropertyTextField}}
            {...field("properties.width", {onChange: handleChange})}
          />
          <span className={classes.theX}>x</span>
          <TextField
            inputProps={{className: classes.channelPropertyTextField}}
            margin="normal"
            {...field("properties.height", {onChange: handleChange})}
          />
        </form>
      </TableCell>

      {/* Show Navigation */}
      <TableCell>
        <form onSubmit={handleSubmit}>
          <Switch
            {...field("showNavigation", {
              onChange: handleChange,
              bool: true,
              exclude: ["error", "helperText"],
            })}
            inputProps={{"data-testid": "show-navigation-switch"}}
          />
        </form>
      </TableCell>

      {/* Branding */}
      <TableCell>
        <form onSubmit={handleSubmit}>
          <Button
            classes={{text: classes.buttonText}}
            disabled={!!row.key}
            onClick={() => setIsBrandingSettingsOpen(true)}
            startIcon={<EditBrandingIcon />}
            color="grey"
            variant="text"
          >
            Edit Branding
          </Button>
        </form>
      </TableCell>

      {/* Default Content */}
      <TableCell>
        <form onSubmit={handleSubmit}>
          <Link
            classes={{text: classes.buttonText}}
            component={({navigate, href, ...props}) => <Button onClick={navigate} {...props} />}
            disabled={!!row.key || !defaultContainer?.id}
            startIcon={<EditDefaultContentIcon />}
            color="grey"
            variant="text"
            to={
              defaultContainer
                ? `/admin/templates/engagement-channels/${row.id}/content-containers/${defaultContainer.id}`
                : "#"
            }
          >
            Edit Default Content
          </Link>
        </form>
      </TableCell>

      {/* Controls */}
      <TableCell>
        <form onSubmit={handleSubmit} className={classes.rowControls}>
          {row.id && (
            <IconButton
              className={classes.deleteIcon}
              onClick={handleRemove}
              size="medium"
              data-testid="delete-button"
            >
              <MuiIcon icon={<MdDelete />} />
            </IconButton>
          )}

          <SaveButton
            className={cx({[classes.invisible]: !isDirty && !invalid})}
            disabled={!isDirty || invalid}
            failed={failed}
            submitting={submitting}
          />
        </form>
      </TableCell>

      {isBrandingSettingsOpen && (
        <BrandingSettings
          channel={row}
          onClose={() => setIsBrandingSettingsOpen(false)}
          onPartialUpdate={onPartialUpdate}
        />
      )}
    </TableRow>
  )
}

CrossChannelEngagementSettingsRow.propTypes = {
  onCreate: func.isRequired,
  onPartialUpdate: func.isRequired,
  onUpdate: func.isRequired,
  onRemove: func.isRequired,
  row: object,
}
export default CrossChannelEngagementSettingsRow
