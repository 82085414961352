import qs from "query-string"

import cookieStorage from "./cookie-storage"
import inMemoryStorage from "./in-memory-storage"
import storageFactory from "./storage-factory"

const {store} = qs.parse(window.location.search)

/**
 * Important:
 *
 * All of these storage providers are purposely wrapped
 * in a function so that they will not execute until
 * they reach the try/catch block in `isSupported`,
 * otherwise they will blow up in the browser if not
 * supported, i.e., window.localStorage/window.sessionStorage.
 */
const storage = storageFactory(
  store === "cookie"
    ? [() => cookieStorage]
    : [
        () => window.localStorage,
        () => window.sessionStorage,
        () => cookieStorage,
        () => inMemoryStorage,
      ]
)
const sessionStore = storageFactory([() => window.sessionStorage, () => inMemoryStorage])

export {storage as default, sessionStore}
