import {Box, Container, FormControl, InputLabel, ListItem, MenuItem} from "@mui/material"
import {bool, func, shape, string} from "prop-types"
import React, {createElement, useState} from "react"
import {FaTimes} from "react-icons/fa"

import DangerButton from "components/danger-button/danger-button"
import DOSelect from "components/do-select/do-select"

import {stepComponents} from "./step-components"

const StepCondition = ({config, isEditable, onDelete}) => {
  const [selected, setSelected] = useState(config)
  const [showDelete, setShowDelete] = useState(false)

  const handleChangeCondition = key => {
    const {initialValues, ...rest} = stepComponents.find(
      ({initialValues: {condition}}) => condition === key
    )
    const config = {...rest, initialValues: {...initialValues, id: selected.initialValues.id}}

    setSelected(config)
  }

  return (
    <ListItem
      sx={{flexDirection: "column", alignItems: "flex-start"}}
      onMouseEnter={() => setShowDelete(isEditable)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <FormControl fullWidth={true}>
        <Box
          alignItems="flex-end"
          display="flex"
          gap={2}
          justifyContent="space-between"
          sx={{
            "& .MuiButtonBase-root": {
              textWrap: "nowrap",
              width: "100%",
            },
          }}
          width="100%"
        >
          <InputLabel htmlFor="selectRewardStepCondition">Condition</InputLabel>
          <DOSelect
            disabled={!isEditable}
            id="selectRewardStepCondition"
            onChange={({target: {value}}) => handleChangeCondition(value)}
            value={selected.initialValues.condition}
          >
            {stepComponents.map(config => (
              <MenuItem key={config.initialValues.condition} value={config.initialValues.condition}>
                {config.label}
              </MenuItem>
            ))}
          </DOSelect>
          <div>
            <DangerButton onClick={onDelete} sx={{visibility: showDelete ? "visible" : "hidden"}}>
              <FaTimes size={20} />
            </DangerButton>
          </div>
        </Box>
        {createElement(selected.component, {
          isEditable: isEditable,
          initialValues: selected.initialValues,
          validDefaults: selected.validDefaults,
        })}
      </FormControl>
      <Container sx={{borderTop: "1px dashed #888", paddingTop: "0.5em", my: 2}}></Container>
    </ListItem>
  )
}

StepCondition.propTypes = {
  config: shape({initialValues: shape({condition: string.isRequired})}),
  isEditable: bool,
  onDelete: func.isRequired,
}

export default StepCondition
