import {node} from "prop-types"
import React, {createContext, useReducer} from "react"

import {initialState, reducer} from "./reward-set-form-reducer"

export const RewardSetFormContext = createContext()
RewardSetFormContext.displayName = "RewardSetFormContext"

const {Provider} = RewardSetFormContext

const RewardSetFormContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, null, () => initialState)

  // Provided data
  const value = {...state, dispatch}

  return <Provider value={value}>{children}</Provider>
}

RewardSetFormContextProvider.propTypes = {
  children: node.isRequired,
}

export default RewardSetFormContextProvider
