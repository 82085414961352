import {Button, TableCell, TableRow} from "@mui/material"
import {func, number} from "prop-types"
import React from "react"

const styles = theme => ({
  "& td": {
    padding: `${theme.spacing(4)} 0`,
    textAlign: "center",
  },
  "& button": {backgroundColor: theme.palette.secondary.main},
})

// Used in journey views when table config has private properties and contact
// has not yet authenticated
const AccountRowRedacted = ({columnsCount, openLogin}) => {
  return (
    <TableRow sx={styles}>
      <TableCell colSpan={columnsCount}>
        <Button onClick={openLogin} size="large" variant="contained">
          Click to reveal
        </Button>
      </TableCell>
    </TableRow>
  )
}

AccountRowRedacted.propTypes = {columnsCount: number, openLogin: func}

export default AccountRowRedacted
