import Papa from "papaparse"

import triggerCsvDownload from "lib/trigger-csv-download"

export const EXPORT_STARTED = "EXPORT_STARTED"
export const EXPORT_ERRORED = "EXPORT_ERRORED"
export const EXPORT_PROGRESSED = "EXPORT_PROGRESSED"
export const EXPORT_CLEARED = "EXPORT_CLEARED"
export const EXPORT_ITEMS_PER_PAGE = 100

export const exportRecords = (
  fetchRecords,
  filename,
  params,
  sortHeaders = (headerA, headerB) => headerA.localeCompare(headerB),
  transform = record => record
) => dispatch => {
  dispatch({type: EXPORT_STARTED})

  return fetchRecords({...params, limit: 0}, {withFetchResponse: true})
    .then(([, fetchResponse]) => parseInt(fetchResponse.headers.get("x-total-count"), 10))
    .then(count => buildRecordsExport(dispatch, fetchRecords, params, count, transform))
    .then(records => {
      // Generate a flat column list for papaparse
      const columns = [...new Set(records.map(Object.keys).flat())]
      columns.sort(sortHeaders)

      return Papa.unparse(records, {columns})
    })
    .then(csv => {
      dispatch({type: EXPORT_PROGRESSED, payload: 100})
      triggerCsvDownload(csv, `${filename}.csv`)
    })
    .then(() => setTimeout(() => dispatch({type: EXPORT_CLEARED}), 1500))
    .catch(() => {
      dispatch({type: EXPORT_ERRORED})
      dispatch({type: EXPORT_PROGRESSED, payload: 100})
    })
}

export const buildRecordsExport = async (
  dispatch,
  fetchRecords,
  exportParams,
  count,
  transform,
  page = 0
) => {
  const offset = page * EXPORT_ITEMS_PER_PAGE
  const params = {...exportParams, offset, limit: EXPORT_ITEMS_PER_PAGE}

  const records = await fetchRecords(params, {skipCamelCase: true})
  const loadedContactsCount = offset + records.length
  const progress = Math.round((loadedContactsCount / count) * 100)

  dispatch({type: EXPORT_PROGRESSED, payload: progress})

  if (count > offset + EXPORT_ITEMS_PER_PAGE)
    return records
      .map(transform)
      .concat(
        await buildRecordsExport(dispatch, fetchRecords, exportParams, count, transform, page + 1)
      )

  return records.map(transform)
}

export const cancelExport = () => ({type: EXPORT_CLEARED})
