import {Typography} from "@mui/material"
import {Grid, List, ListItem, ListItemText} from "@mui/material"
import {array, func, object} from "prop-types"
import {useEffect} from "react"
import {connect} from "react-redux"

import CreatePgpKey from "./create-pgp-key"
import PgpKeyRow from "./pgp-key-row"
import * as addActionsToProps from "./team-actions"

export const PgpKeys = ({classes, keys, getPgpKeys, onCreatePgpKey, onDeletePgpKey}) => {
  useEffect(() => {
    getPgpKeys()
  }, [getPgpKeys])
  return (
    <>
      <Typography className={classes.description}>
        If you would like us to verify the signature in encrypted uploads please add your public PGP
        key here.
      </Typography>
      <Grid container={true} spacing={4}>
        <Grid item={true} lg={6} md={12}>
          <List>
            {keys.map(key => (
              <PgpKeyRow {...key} key={key.id} onDelete={onDeletePgpKey} />
            ))}
            {!keys.length && (
              <ListItem>
                <ListItemText
                  primary="There are no PGP Keys uploaded to your team's account"
                  primaryTypographyProps={{
                    classes: {
                      root: classes.noKeysFound,
                    },
                  }}
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item={true} lg={6} md={12}>
          <CreatePgpKey onSubmit={onCreatePgpKey} />
        </Grid>
      </Grid>
    </>
  )
}

PgpKeys.propTypes = {
  classes: object.isRequired,
  getPgpKeys: func.isRequired,
  keys: array,
  onCreatePgpKey: func.isRequired,
  onDeletePgpKey: func.isRequired,
}

const mapStateToProps = ({pgpKeys}) => ({keys: pgpKeys.list})

export default connect(mapStateToProps, addActionsToProps)(PgpKeys)
