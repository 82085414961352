import {IconButton, Tooltip} from "@mui/material"
import cx from "classnames"
import {func, object} from "prop-types"

import {hasEntity, removeEntity, setEntity} from "./entity-button-helpers"

const Superscript = ({onSetEditorState, editorState, classes}) => {
  const onClick = () => {
    onSetEditorState(
      hasEntity(editorState, "SUPERSCRIPT")
        ? removeEntity(editorState)
        : setEntity(editorState, "SUPERSCRIPT")
    )
  }

  return (
    <Tooltip title="Superscript">
      <IconButton
        className={cx(classes.button, {
          [classes.buttonActive]: hasEntity(editorState, "SUPERSCRIPT"),
        })}
        onClick={onClick}
        type="button"
        size="medium"
      >
        <div>
          X<sup style={{fontSize: 12}}>2</sup>
        </div>
      </IconButton>
    </Tooltip>
  )
}

Superscript.propTypes = {
  classes: object,
  editorState: object,
  onSetEditorState: func,
}

export default Superscript
