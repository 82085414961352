import {connect} from "react-redux"

import {
  clearContactComponentData,
  getContactComponentData,
  onDeleteContact,
  onUpdateContact,
  updateContactObjective,
} from "./contact-actions"
import ContactsShow from "./contacts-show"

const mapStateToProps = (
  {contact, session},
  {
    match: {
      params: {contactId},
    },
  }
) => ({
  contactId,
  contact,
  contactAccounts: contact.contactAccounts,
  journeys: contact.journeys,
  objectives: contact.objectives,
  pagesByTemplateId: contact.pagesByTemplateId,
  team: session.team || {},
})

const addActionsToProps = {
  clearContactComponentData,
  getContactComponentData,
  onDeleteContact,
  onUpdateContact,
  updateContactObjective,
}

export default connect(mapStateToProps, addActionsToProps)(ContactsShow)
