import {areFiltersMismatched} from "./insights-helpers"

export const initialState = {
  contentBlocks: {},
  filter: {endDate: null, startDate: null},
  insights: {},
  pageStats: {},
  template: null,
}

export const actions = {
  setContentBlocks: "setContentBlocks",
  setFilterParams: "setFilterParams",
  setInsights: "setInsights",
  setTemplate: "setTemplate",
}

const filtersMismatchInsights = ({filter}, {args} = {}) => {
  const cacheFilters = args?.args?.[1] || {}
  return areFiltersMismatched(filter, cacheFilters)
}

const insightsReducer = (state, action) => {
  switch (action.type) {
    case actions.setInsights:
      // We short circuit setting/updating template insights when the filters
      // do not match to prevent two (or more) open tabs/windows on the same
      // insights template from effecting one another.
      // See https://trello.com/c/BDrLqJcI
      if (filtersMismatchInsights(state, action.insightsCache)) return state

      return {
        ...state,
        insights: {...state.insights, [action.insightsCache.name]: action.insightsCache},
      }
    default:
      const {type, ...restAction} = action
      if (actions[type]) return {...state, ...restAction}
      else throw new Error(`unknown action type (${type}) specified`)
  }
}

export default insightsReducer
