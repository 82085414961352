import DocumentButton, {DocumentEmbedIcon} from "./document-button"
import DocumentReadonly from "./document-readonly"
import DocumentSettings from "./document-settings"

export default {
  title: "Document Embed",
  type: "document",
  Button: DocumentButton,
  Icon: DocumentEmbedIcon,
  Readonly: DocumentReadonly,
  Settings: DocumentSettings,
  suitableFor: ["content_container", "page"],
}
