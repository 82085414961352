const types = {
  OPEN_OVERWRITE_WARNING: "TEMPLATE_TARGETING/OPEN_OVERWRITE_WARNING",
  OPEN_UPDATE_WARNING: "TEMPLATE_TARGETING/OPEN_UPDATE_WARNING",
  OPEN_GROUP_DIALOG: "TEMPLATE_TARGETING/OPEN_GROUP_DIALOG",
}

const openOverwriteModal = isOpen => ({
  type: types.OPEN_OVERWRITE_WARNING,
  payload: {isOverwriteWarningOpen: isOpen},
})
const openUpdateModal = isOpen => ({
  type: types.OPEN_UPDATE_WARNING,
  payload: {isUpdateWarningOpen: isOpen},
})
const openGroupModal = isOpen => ({
  type: types.OPEN_GROUP_DIALOG,
  payload: {isGroupDialogOpen: isOpen},
})

const initState = {
  isOverwriteWarningOpen: false,
  isUpdateWarningOpen: false,
  isGroupDialogOpen: false,
}

const modalReducer = (state, action) => {
  switch (action.type) {
    case types.OPEN_OVERWRITE_WARNING:
      return {...state, ...action.payload}
    case types.OPEN_UPDATE_WARNING:
      return {...state, ...action.payload}
    case types.OPEN_GROUP_DIALOG:
      return {...state, ...action.payload}
    default:
      throw new Error()
  }
}

export {
  initState as modalInitState,
  modalReducer as default,
  openOverwriteModal,
  openUpdateModal,
  openGroupModal,
}
