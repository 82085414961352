import {IconButton, Tooltip} from "@mui/material"
import cx from "classnames"
import {EditorState, Modifier} from "draft-js"
import {func, object, string} from "prop-types"
import {useMemo} from "react"

import {editorContext} from "contexts/editor-context"

// getCurrentlySelectedBlock and toggleAlignment adapted from:
// https://gist.github.com/joshdover/7c5e61ed68cc5552dc8a25463e357960

export const getCurrentlySelectedBlock = editorState => {
  const selection = editorState.getSelection()
  const startKey = selection.getStartKey()
  let endKey = selection.getEndKey()

  const content = editorState.getCurrentContent()
  let target = selection

  // Triple-click can lead to a selection that includes offset 0 of the
  // following block. The `SelectionState` for this case is accurate, but
  // we should avoid toggling block type for the trailing block because it
  // is a confusing interaction.
  if (startKey !== endKey && selection.getEndOffset() === 0) {
    const blockBefore = content.getBlockBefore(endKey)

    if (!blockBefore) return null

    endKey = blockBefore.getKey()
    target = target.merge({
      anchorKey: startKey,
      anchorOffset: selection.getStartOffset(),
      focusKey: endKey,
      focusOffset: blockBefore.getLength(),
      isBackward: false,
    })
  }

  const hasAtomicBlock = content
    .getBlockMap()
    .skipWhile((_, k) => k !== startKey)
    .takeWhile((_, k) => k !== endKey)
    .some(v => v.getType() === "atomic")

  const currentBlock = content.getBlockForKey(startKey)

  return {
    content,
    currentBlock,
    hasAtomicBlock,
    target,
  }
}

const ALIGNMENT_DATA_KEY = "align"

const toggleAlignment = ({editorState, currentlySelectedBlock}, alignment) => {
  const {content, currentBlock, target} = currentlySelectedBlock

  const blockData = currentBlock.getData()
  const alignmentToSet =
    blockData && blockData.get(ALIGNMENT_DATA_KEY) === alignment ? undefined : alignment

  return EditorState.push(
    editorState,
    Modifier.mergeBlockData(content, target, {
      align: alignmentToSet,
    }),
    "change-block-data"
  )
}

const isAlignmentActive = ({currentBlock} = {}, alignment, defaultAlignment) => {
  if (!currentBlock) return false

  const blockData = currentBlock.getData()

  return (
    (blockData && blockData.get(ALIGNMENT_DATA_KEY) === alignment) ||
    (!blockData.get(ALIGNMENT_DATA_KEY) && alignment === defaultAlignment)
  ) // if we don't have an alignment, then we use the editor's text-align property.
}

export default ({alignment, children, tooltipText}) => {
  const BlockAlignmentButton = ({onSetEditorState, editorState, classes, defaultAlignment}) => {
    const currentlySelectedBlock = useMemo(() => getCurrentlySelectedBlock(editorState), [
      editorState,
    ])

    const toggleAlign = e => {
      e.preventDefault()
      onSetEditorState(toggleAlignment({editorState, currentlySelectedBlock}, alignment))
    }

    return (
      <Tooltip title={tooltipText}>
        <IconButton
          className={cx(classes.button, {
            [classes.buttonActive]: isAlignmentActive(
              currentlySelectedBlock,
              alignment,
              defaultAlignment
            ),
          })}
          onClick={toggleAlign}
          type="button"
          size="medium"
        >
          {children}
        </IconButton>
      </Tooltip>
    )
  }

  BlockAlignmentButton.propTypes = {
    classes: object,
    defaultAlignment: string,
    editorState: object,
    onSetEditorState: func,
  }

  BlockAlignmentButton.displayName = `${alignment}AlignmentButton`

  return editorContext(BlockAlignmentButton)
}
