/*eslint-disable no-param-reassign*/
import cloneDeep from "lodash/cloneDeep"
import get from "lodash/get"
import omit from "lodash/omit"

export const reduceErrors = inputs =>
  Object.keys(inputs)
    .map(name => typeof inputs[name] === "object" && inputs[name].error)
    .some(Boolean)

export const valid = (validators, name, inputs) =>
  (validators || [])
    .map(validator =>
      validator(typeof inputs[name] === "object" ? inputs[name].value : null, inputs)
    )
    .filter(Boolean)

export const checkFormValidity = (inputs = {}, validators = {}) => {
  const localValidationErrors = [...new Set([...Object.keys(inputs), ...Object.keys(validators)])]
    .map(key => {
      if (!validators[key]) return false

      const rawErrors = valid(validators[key], key, inputs)
      const validationResult = Array.isArray(rawErrors) ? rawErrors.flat() : rawErrors

      if (!validationResult || validationResult.length === 0) return false

      return {key, validationResult}
    })
    .filter(Boolean)
    .reduce(
      (errors, {key, validationResult}) => ({
        ...errors,
        [key]: validationResult,
      }),
      {}
    )

  return {
    localValidationErrors,
    isValid: Object.keys(localValidationErrors).length === 0,
  }
}

export const inputStateFromErrors = (inputState, {errors}) => {
  const nextInputsState = {...inputState}

  Object.keys(errors).forEach(name => {
    nextInputsState[name] = {
      // this field might be new to the input state, so we need to make sure this value is set
      name,
      ...nextInputsState[name],
      ...errors[name],
    }
  })
  return nextInputsState
}

export const initialize = (inputs, unparse) => {
  /**
   * Flatten nested objects into a single namespace.
   *
   * Example:
   *
   *
   * Before flattening:
   * -------------------------------------------
   * inputs = {
   *   supportOwnerOptions: {
   *     emailLinkShows: "DO_NOT_SHOW",
   *     phoneEmailLinkShows: "SUPPORT_OWNER"
   *   }
   * }
   *
   * After flattening:
   * -------------------------------------------
   * {
   *   supportOwnerOptions: {
   *     name: "supportOwnerOptions"
   *   },
   *   "supportOwnerOptions.emailLinkShows": {
   *     name: "supportOwnerOptions.emailLinkShows",
   *     value: "DO_NOT_SHOW"
   *   },
   *   "supportOwnerOptions.phoneEmailLinkShows": {
   *     name: "supportOwnerOptions.phoneEmailLinkShows",
   *     value: "SUPPORT_OWNER"
   *   }
   * }
   *
   * Without Flattening (We don't want this)
   * -------------------------------------------
   * {
   *   supportOwnerOptions: {
   *     name: "supportOwnerOptions",
   *     value: {
   *       emailLinkShows: "DO_NOT_SHOW",
   *       phoneEmailLinkShows: "SUPPORT_OWNER"
   *     }
   *   }
   * }
   *
   */
  const flatten = (namespace = null) => (acc, key) => {
    const name = key.includes(".")
      ? `${namespace}['${key}']`
      : [namespace, key].filter(k => k).join(".")
    const unparser = typeof unparse === "function" ? unparse : unparse[name] || noChange
    const value = unparser(get(inputs, name))

    acc[name] = {name}
    if (typeof value === "boolean") acc[name].checked = value
    else if (value !== null && !Array.isArray(value) && typeof value === "object")
      acc = {
        ...acc,
        ...Object.keys(value).reduce(flatten(name), {}),
      }
    else acc[name].value = value ?? ""

    return acc
  }

  return Object.keys(inputs).reduce(flatten(), {})
}

export const typedValue = ({name, type, value, checked}) => {
  if (type === "checkbox") return {name, checked}
  else return {name, value}
}

export const untypedValue = ({value, checked}) => (typeof checked === "boolean" ? checked : value)

// If we drop IE11 support we could probably drop this pattern in favor of a
// proxy wrapping the inputs
export const field = (inputs, defaultOnChange, disabled) => (
  name,
  {bool, defaultValue, exclude = [], onChange} = {}
) => {
  const data = cloneDeep(inputs[name]) || {name}

  if (bool && data.checked === undefined) data.checked = defaultValue || false
  else if (!bool && data.value === undefined)
    data.value = defaultValue === undefined ? "" : defaultValue

  if (onChange)
    data.onChange = (e, validators) => {
      onChange(e, validators)
      defaultOnChange(e, validators)
    }
  else data.onChange = defaultOnChange

  if (disabled) data.disabled = true

  return omit(data, exclude)
}

export const noChange = val => val
