import {
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material"
import {func, string} from "prop-types"
import {useState} from "react"
import {MdDelete as DeleteIcon, MdVpnKey as KeyIcon} from "react-icons/md"
import {connect} from "react-redux"

import {distanceDateTime} from "lib/date-time-formatters"

import AccessControlled from "../access-control/access-controlled"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import * as addActionsToProps from "./team-actions"

export const SshKeys = ({id, title, fingerprint, insertedAt, onDelete}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const onOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }
  const onCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }
  const onDeleteConfirm = () => {
    onDelete(id)
    setIsDeleteDialogOpen(false)
  }

  return (
    <>
      <ListItem ContainerProps={{"aria-labelledby": `key-${id}-title`}}>
        <ListItemIcon>
          <KeyIcon />
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{id: `key-${id}-title`}}
          secondary={
            <>
              <Typography display="block" variant="caption">
                {fingerprint}
              </Typography>
              <Typography variant="caption">Created {distanceDateTime(insertedAt)}</Typography>
            </>
          }
        />
        <ListItemSecondaryAction>
          <AccessControlled requiredPermissions="team_settings:sftp">
            <IconButton edge="end" onClick={onOpenDeleteDialog} size="medium">
              <DeleteIcon />
            </IconButton>
          </AccessControlled>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
      <UltraConfirmDialog
        confirmationText={title}
        onClose={onCloseDeleteDialog}
        onConfirm={onDeleteConfirm}
        open={isDeleteDialogOpen}
        recordType="SSH Key"
      />
    </>
  )
}

SshKeys.propTypes = {
  fingerprint: string,
  id: string,
  insertedAt: string,
  onDelete: func.isRequired,
  title: string,
}

const mapStateToProps = ({sshKeys}) => ({keys: sshKeys.list})

export default connect(mapStateToProps, addActionsToProps)(SshKeys)
