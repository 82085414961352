import withStyles from "@mui/styles/withStyles"
import {array, func, number, object} from "prop-types"

const Toolbar = ({
  plugins = [],
  editorState,
  onSetEditorState,
  charsRemaining,
  classes,
  customClasses,
}) => {
  const childrenProps = {
    classes,
    editorState,
    onSetEditorState,
  }
  const preventBubblingUp = e => e.preventDefault()

  return (
    <div className={classes.toolbar} onMouseDown={preventBubblingUp}>
      {plugins
        .filter(p => p.buttonComponent)
        .map(({buttonComponent: Button, entityType}, index) => (
          <Button
            charsRemaining={charsRemaining}
            className={classes.toolbarButton}
            key={`${index}-${entityType}`}
            {...childrenProps}
          />
        ))}
    </div>
  )
}

Toolbar.propTypes = {
  charsRemaining: number,
  classes: object,
  customClasses: object,
  editorState: object,
  onSetEditorState: func,
  plugins: array,
}

const styles = theme => ({
  toolbar: {
    marginTop: theme.spacing(1),
  },
  toolbarButton: {
    display: "inline-flex",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2.5),
  },
  //needed for overrideability
  toolbarContainer: {},
  menuButtonIcon: {},
})

export default withStyles(styles)(Toolbar)
