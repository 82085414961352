import {bool, string} from "prop-types"
import {useEffect} from "react"

export const useDocumentTitle = (title, isNakedTitle) =>
  useEffect(() => {
    document.title = isNakedTitle
      ? title
      : ["Digital Onboarding", title].filter(Boolean).join(" - ")
  }, [isNakedTitle, title])

const DocumentTitle = ({title, isNakedTitle}) => useDocumentTitle(title, isNakedTitle) || null

DocumentTitle.propTypes = {
  isNakedTitle: bool,
  title: string,
}

export default DocumentTitle
