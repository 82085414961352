// This file was generated automatically from builder-2.svg with the following command:
// ./svg-converter.js src/components/superhero/builder-2.svg
import SuperheroSvg from "./superhero-svg"

const Builder2 = props => (
  <SuperheroSvg viewBox="0 0 700 800" {...props}>
    <defs>
      <clipPath id="8d061499-clip-path">
        <rect height="800" style={{fill: "none"}} width="700" />
      </clipPath>
    </defs>
    <g style={{clipPath: "url(#8d061499-clip-path)"}}>
      <g>
        <g>
          <polygon
            points="352.79 937.12 322.84 1174.37 126.1 1174.37 179.22 786.67 404.87 763.53 352.79 937.12"
            style={{fill: "#5f9e92"}}
          />
          <path
            d="M253.17,401.25l-3.93,3.23-10.69,8.81-15.81,13-19.29,15.9-21.13,17.4L161,477.19l-19.89,16.38-16.8,13.84-12,9.92c-1.92,1.58-3.84,3.15-5.75,4.74a2.43,2.43,0,0,1-.25.2l47.81,27.83,1.95-5.21,5.31-14.18,7.85-21,9.58-25.59,10.5-28,10.59-28.3,9.88-26.39,8.36-22.32,6-16.05c.95-2.54,1.92-5.07,2.85-7.61l.12-.34a21.68,21.68,0,0,0,.51-11.4,21.29,21.29,0,0,0-3.39-10.64,21.71,21.71,0,0,0-7.37-8,21.43,21.43,0,0,0-9.73-5.12l-7.6-1a28.4,28.4,0,0,0-14.44,3.9l-5.79,4.47a28.63,28.63,0,0,0-7.35,12.63L170,345.11l-5.31,14.18-7.85,21-9.58,25.59-10.5,28-10.59,28.3-9.88,26.39L107.9,510.9l-6,16.05c-1,2.54-1.93,5.06-2.85,7.61l-.12.34-1,7.6a21.53,21.53,0,0,0,2.46,11,14.31,14.31,0,0,0,3.68,6.31,21.71,21.71,0,0,0,8,7.37l6.84,2.88,7.6,1a21.53,21.53,0,0,0,11-2.46,21.53,21.53,0,0,0,9.21-5.91l3.92-3.24,10.69-8.81,15.81-13,19.29-15.9,21.13-17.4,21.32-17.57,19.89-16.39,16.8-13.84,12-9.92c1.92-1.58,3.85-3.15,5.75-4.74l.25-.2A31.85,31.85,0,0,0,302,421.47a31.79,31.79,0,0,0-8.37-20.22l-5.79-4.48a28.5,28.5,0,0,0-14.44-3.9l-7.6,1a28.47,28.47,0,0,0-12.62,7.36Z"
            style={{fill: "#b5927e"}}
          />
          <polygon
            points="250.32 280.02 255.09 377.04 522.05 383.98 522.05 297.3 250.32 280.02"
            style={{fill: "#123252"}}
          />
          <path
            d="M446.57,101.62a148.42,148.42,0,0,1,4.29-23.24,150.8,150.8,0,0,0-147.1,4.81,138.05,138.05,0,0,0,86.55,30.36A138.25,138.25,0,0,0,446.57,101.62Z"
            style={{fill: "#123252"}}
          />
          <path
            d="M468.71,89.25a138.75,138.75,0,0,1-22.14,12.37,149.17,149.17,0,0,0,81.07,148.45A150.65,150.65,0,0,0,468.71,89.25Z"
            style={{fill: "#123252"}}
          />
          <g>
            <path
              d="M445.78,117a150.45,150.45,0,0,1,.79-15.39,138.25,138.25,0,0,1-56.26,11.93,138.05,138.05,0,0,1-86.55-30.36,151.71,151.71,0,0,0-49.42,48.38l202.32,41.2A148.28,148.28,0,0,1,445.78,117Z"
              style={{fill: "#b5927e"}}
            />
            <path
              d="M230.82,212.36a150.85,150.85,0,0,0,104,143.37V377h58.94v-14.4a150.51,150.51,0,0,0,104-54.13,32.91,32.91,0,1,0,29.76-57.94c0-.17.07-.34.11-.5-1-.53-2.06-1.15-3.1-1.71L232.71,188.93A150.76,150.76,0,0,0,230.82,212.36Z"
              style={{fill: "#b5927e"}}
            />
            <path
              d="M456.66,172.77l-202.32-41.2a149.7,149.7,0,0,0-21.63,57.36l291.83,59.43A149.56,149.56,0,0,1,456.66,172.77Z"
              style={{fill: "#ec6568"}}
            />
          </g>
          <polygon
            points="386.84 383.98 528.99 408.24 678.07 1098.18 421.51 1098.18 386.84 383.98"
            style={{fill: "#0f2c48"}}
          />
          <rect height="24.27" style={{fill: "#5cbdaf"}} width="58.94" x="334.83" y="377.04" />
          <g>
            <path
              d="M483.48,489.54a87,87,0,0,0-74.38-97.92l-76.76-9.07a87,87,0,0,0-97.92,74.38l-28.74,173.2H460.15Z"
              style={{fill: "#5cbdaf"}}
            />
            <path
              d="M511.44,811.88q.21-4.17.21-8.39a166,166,0,0,0-59.16-127.21l.18-1.07H198.2l-15,90.37a166.77,166.77,0,0,0-3.58,21.59l-1.93,11.66,1.24.16c0,1.5-.11,3-.11,4.5A166.45,166.45,0,0,0,312.87,966.73L314,969.9l31.2,204.56h215Z"
              style={{fill: "#5cbdaf"}}
            />
            <polygon
              points="460.15 630.13 205.68 630.13 198.2 675.21 452.67 675.21 460.15 630.13"
              style={{fill: "#0f2c48"}}
            />
          </g>
          <path
            d="M446.57,101.62a138.75,138.75,0,0,0,22.14-12.37,151.19,151.19,0,0,0-17.85-10.87A148.42,148.42,0,0,0,446.57,101.62Z"
            style={{fill: "#123252"}}
          />
          <circle cx="308.83" cy="177.69" r="12.13" style={{fill: "#0b1d37"}} />
          <circle cx="412.84" cy="195.02" r="12.13" style={{fill: "#0b1d37"}} />
          <path
            d="M418.73,157.66a16.13,16.13,0,0,1,2.38-.56l.62-.1c.44-.09-.59.06.18,0,.45,0,.9-.1,1.36-.13a27.25,27.25,0,0,1,3.5,0l.87.07.51.06h.08c.67.11,1.33.24,2,.4a20.66,20.66,0,0,1,3.78,1.32,21.65,21.65,0,0,1,7.8,6.41,2.68,2.68,0,0,0,3.56.93,2.6,2.6,0,0,0,.93-3.55,26.55,26.55,0,0,0-14.55-10.09,28.83,28.83,0,0,0-12.16-.31,16.82,16.82,0,0,0-2.23.53c-3.16,1.05-1.81,6.07,1.38,5Z"
            style={{fill: "#0b1d37"}}
          />
          <path
            d="M333.34,136.2c-3.95-5.53-11-8.53-17.67-8.76a31,31,0,0,0-11,1.81,2.67,2.67,0,0,0-1.82,3.2,2.63,2.63,0,0,0,3.2,1.82,26.21,26.21,0,0,1,10.74-1.57,19.78,19.78,0,0,1,6.76,2,23.21,23.21,0,0,1,2.64,1.64c.6.42-.14-.13.19.14l.52.43c.26.23.53.46.78.71a16.57,16.57,0,0,1,1.16,1.21,2.08,2.08,0,0,0,.32.4c-.45-.28-.46-.62-.28-.36a2.67,2.67,0,0,0,3.56.93,2.62,2.62,0,0,0,.93-3.56Z"
            style={{fill: "#0b1d37"}}
          />
          <g>
            <path d="M340,212.36s17.34-13.87,27.74,6.93" style={{fill: "#b5927e"}} />
            <path
              d="M341,213.28c-.36.28,0,0,.22-.16.42-.29.85-.58,1.29-.84a22.65,22.65,0,0,1,4.29-2.08c4.2-1.49,8.74-1.51,12.61.91,3.3,2.07,5.55,5.42,7.29,8.84.75,1.49,3,.18,2.24-1.32-3-5.9-7.75-11.21-14.66-12a20.8,20.8,0,0,0-12.52,3.06,21.5,21.5,0,0,0-2.6,1.75c-1.3,1,.55,2.87,1.84,1.84Z"
              style={{fill: "#0b1d37"}}
            />
          </g>
          <path
            d="M436,456.21l4.62,3.61,12.57,9.84,18.59,14.54L494.41,502l24.85,19.44L544.33,541l23.3,18.23,19.84,15.53,14.15,11.07c2.25,1.76,4.49,3.53,6.75,5.28l.29.23,5.79-44.92L601.7,554l-30.38,17.95L534.61,593.6l-31.82,18.81c-5.14,3-10.31,6.05-15.44,9.12l-.21.12a27.56,27.56,0,0,0-13.65,28.5,21.29,21.29,0,0,0,3.39,10.64l4.48,5.79A28.54,28.54,0,0,0,494,673.93l7.6,1a28.5,28.5,0,0,0,14.44-3.9l12.75-7.54,30.37-18,36.71-21.69,31.82-18.8c5.15-3,10.31-6.05,15.44-9.12l.21-.13a28.66,28.66,0,0,0,10.26-10.26,21.36,21.36,0,0,0,3.4-10.63,21.72,21.72,0,0,0-.51-11.41,28.67,28.67,0,0,0-7.36-12.62l-4.62-3.61-12.57-9.84L613.33,522.9l-22.68-17.74-24.84-19.45-25.08-19.62-23.29-18.23L497.6,432.34l-14.16-11.08L476.7,416l-.29-.22a31.79,31.79,0,0,0-20.23-8.38A31.79,31.79,0,0,0,436,415.76l-4.47,5.78a28.42,28.42,0,0,0-3.9,14.44l1,7.6A28.63,28.63,0,0,0,436,456.21Z"
            style={{fill: "#b5927e"}}
          />
          <polygon
            points="377.71 480.17 327.22 446.5 272.81 473.4 268.9 533.96 319.39 567.62 373.8 540.73 377.71 480.17"
            style={{
              fill: "#f5f5f5",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "6.93555283587243px",
            }}
          />
          <polygon
            points="377.71 480.17 327.22 446.5 272.81 473.4 268.9 533.96 319.39 567.62 373.8 540.73 377.71 480.17"
            style={{fill: "#0f2c48"}}
          />
          <path
            d="M317,490.93a17.19,17.19,0,0,0-5.84-1.46,16.76,16.76,0,1,0,11.45,27.79A16.78,16.78,0,1,0,324,496.87a22.26,22.26,0,0,0-3.39-3.74A11.82,11.82,0,0,0,317,490.93Z"
            style={{
              fill: "#f5f5f5",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "5.85351121598736px",
            }}
          />
          <polygon
            points="367.89 485.02 326.51 457.43 281.92 479.47 278.72 529.1 320.1 556.7 364.69 534.66 367.89 485.02"
            style={{
              fill: "none",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "3.46703431698277px",
            }}
          />
          <path
            d="M356.7,276.39a44.67,44.67,0,0,0,10.26.67,49.3,49.3,0,0,0,21.57-5.62,42.19,42.19,0,0,0,12.09-9.82,3.08,3.08,0,0,0,0-4.24,3,3,0,0,0-4.24,0,37.72,37.72,0,0,1-19.57,12.25,48.77,48.77,0,0,1-4.95,1s-.85.12-.39.06-.16,0-.25,0l-1.14.13c-.73.07-1.46.13-2.2.17a51.87,51.87,0,0,1-7.05-.1l-1.35-.13-.55-.07c-.4,0,.95.15.25,0-.3-.05-.59-.08-.88-.14a3.07,3.07,0,0,0-3.69,2.09,3,3,0,0,0,2.09,3.69Z"
            style={{fill: "#0b1d37"}}
          />
        </g>
        <g>
          <path
            d="M304.08,505.72a5.86,5.86,0,1,0,6.26-5.43A5.88,5.88,0,0,0,304.08,505.72Z"
            style={{fill: "#5da4dc"}}
          />
          <path
            d="M336.82,502.15a5.86,5.86,0,1,0,5.44,6.25A5.86,5.86,0,0,0,336.82,502.15Z"
            style={{fill: "#5da4dc"}}
          />
          <path
            d="M337.59,491.28a16.61,16.61,0,0,0-13.65,5.6,22.37,22.37,0,0,0-3.38-3.76l-1,13.69a9.62,9.62,0,1,1-8.94-10.23A9.8,9.8,0,0,1,316.4,499l.57-8.1a17.45,17.45,0,0,0-5.84-1.48,16.76,16.76,0,1,0,11.33,27.83,16.75,16.75,0,1,0,15.13-26Zm-1.85,26.27A9.58,9.58,0,1,1,346,508.67,9.59,9.59,0,0,1,335.74,517.55Z"
            style={{fill: "#5da4dc"}}
          />
        </g>
        <path
          d="M432.82,646s6.33.89,5.95,3.77-6.8,9.4-17.56,7.77c-7.08-1.07-36.77.08-46.47,23.43,0,0-5,29.3,23.52,38.47,21.4,6.88,39.84,7.77,67.69-8.44,26-15.12,34.6-31.4,41.81-38.21L593.25,626l12.43-6.82-32.57-50.32-87.27,47.84-9,4.93c-9.16-3.64-31-11.15-48.19-7.87a30.27,30.27,0,0,0-16.59,7.41C403.9,628.12,416.7,644.49,432.82,646Z"
          style={{fill: "#ec6568"}}
        />
        <path
          d="M431.8,649.16s6.35.72,6,3.61l-12.93,5.3s24.36,1.27,33-7.41h0L461,627.54H404C408,636.89,420.56,648.37,431.8,649.16Z"
          style={{fill: "#0f2c48"}}
        />
        <path
          d="M463.74,610.16c-10.42-2.7-27.67-3.7-38.83-1.27,0,0-12.27,4.36-20.25,11.57-2.44,2.19-2.34,5.88-.62,10h57Z"
          style={{fill: "#5cbdaf"}}
        />
      </g>
    </g>
    <path
      d="M253.87,291.76s-5.42,3.38-7,.93-.8-11.58,8.49-17.23c6.12-3.71,28.17-23.64,20.64-47.77,0,0-15-25.69-42.72-14.44-20.83,8.44-35.55,19.59-46.53,49.88-10.25,28.28-6.41,46.29-7.57,56.14q-17.77,45.39-35.54,90.78-2.59,6.6-5.17,13.2L195.75,441,232,348.3l3.74-9.55c9.36-3.08,30.91-11.3,42-24.87a30.18,30.18,0,0,0,8-16.31C287.55,287,267.24,282.61,253.87,291.76Z"
      style={{fill: "#ec6568"}}
    />
    <path d="M296,224l-60.56-34.09a150.43,150.43,0,0,0,102.13,166.8" style={{fill: "#b5927e"}} />
    <path
      d="M233.68,347.57l3.74-9.55c9.36-3.07,30.91-11.3,42-24.86a30.27,30.27,0,0,0,8-16.32c1.79-10.61-18.53-14.95-31.89-5.8,0,0-5.42,3.38-7,.92"
      style={{fill: "#ec6568"}}
    />
  </SuperheroSvg>
)

export default Builder2
