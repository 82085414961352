import {Box, Container, IconButton, InputAdornment, TextField, Typography} from "@mui/material"
import {func, object} from "prop-types"
import {useState} from "react"
import {FaCircle as InactiveBulletPoint} from "react-icons/fa"
import {
  MdClear as FailingValidationIcon,
  MdVisibilityOff as HidePasswordIcon,
  MdCheck as PassingValidationIcon,
  MdVisibility as ShowPasswordIcon,
} from "react-icons/md"

import {useForm} from "lib/hooks/use-form"
import {
  isPasswordPassingValidation,
  matchesPassword,
  passwordHelperTextMap,
  passwordPolicyTextMap,
  validPassword,
} from "lib/password-policy"

import SaveButton from "../save-button/save-button"

const AccountPasswordSettingsForm = ({classes, onSubmit, passwordPolicy}) => {
  const initialPasswordPolicyState = policy => {
    const passwordHelpersObject = {...policy}
    Object.keys(passwordHelpersObject).forEach(key => {
      // delete any policy that is false as it's irrelevant to the input
      if (passwordHelpersObject[key] === false) {
        delete passwordHelpersObject[key]
        return
      }
      // map policy to object with helperText, isValid (whether newPassword is currently passing, initiates with all false as it's an empty string)
      passwordHelpersObject[key] = {
        helperText: passwordHelperTextMap(passwordPolicyTextMap[key], {
          [key]: passwordHelpersObject[key],
        }),
        isValid: false,
        [key]: passwordHelpersObject[key],
      }
    })
    return passwordHelpersObject
  }
  const {field, handleSubmit, submitting} = useForm({
    enableReinitialize: true,
    validators: {
      password: [validPassword(passwordPolicy)],
      passwordConfirmation: [matchesPassword],
    },
    onSubmit,
  })

  const [showPasswords, setShowPasswords] = useState(false)
  const [passwordHelpers, setPasswordHelpers] = useState(initialPasswordPolicyState(passwordPolicy))
  const [passwordValidatorsActive, setPasswordValidatorsActive] = useState(false)
  const toggleShowPassword = () => setShowPasswords(s => !s)

  const password = field("password")

  const handleNewPasswordFocus = e => {
    setPasswordValidatorsActive(true)
  }

  const validatePasswordPolicies = e => {
    const {value} = e.target
    const passwordHelpersObject = {...passwordHelpers}
    Object.keys(passwordHelpersObject).forEach(key => {
      // helperText and maintaining the password policy as a key/value pair were already calculated on the initialPasswordPolicyState function
      // because the key/value pair is policy: {helperText, isValid, policy: value}, it's why the [key][key] is required
      passwordHelpersObject[key] = {
        helperText: passwordHelpersObject[key].helperText,
        isValid: isPasswordPassingValidation(passwordPolicyTextMap[key], value, {
          [key]: passwordHelpersObject[key][key],
        }),
        [key]: passwordHelpersObject[key][key],
      }
    })
    setPasswordHelpers(passwordHelpersObject)
  }

  const passwordHelpersList = () => {
    const policies = Object.keys(passwordHelpers)
    const inactiveValidation = policyText => {
      return (
        <Box
          edge="start"
          key={`box-${policyText}`}
          display="flex"
          alignItems="center"
          sx={{fontSize: "0.8rem"}}
        >
          <IconButton
            edge="start"
            sx={{color: "grey.darker"}}
            aria-label="Inactive password validation policy"
            size="small"
          >
            <Box size="small" display="flex" alignItems="center" sx={{padding: "7px"}}>
              <InactiveBulletPoint size={5} />
            </Box>
          </IconButton>
          <Typography key={policyText} sx={{color: "grey.darker"}} variant="body-2">
            {policyText}
          </Typography>
        </Box>
      )
    }
    const passingValidation = policyText => {
      return (
        <Box
          edge="start"
          key={`box-${policyText}`}
          display="flex"
          alignItems="center"
          sx={{fontSize: "0.8rem"}}
        >
          <IconButton
            edge="start"
            sx={{color: "success.main"}}
            aria-label="Password passing validation policy"
            size="small"
          >
            <PassingValidationIcon />
          </IconButton>
          <Typography key={policyText} sx={{color: "success.main"}} variant="body-2">
            {policyText}
          </Typography>
        </Box>
      )
    }
    const failingValidation = policyText => {
      return (
        <Box
          edge="start"
          key={`box-${policyText}`}
          display="flex"
          alignItems="center"
          sx={{fontSize: "0.8rem"}}
        >
          <IconButton
            edge="start"
            sx={{color: "error.main"}}
            aria-label="Password failing validation policy"
            size="small"
          >
            <FailingValidationIcon />
          </IconButton>
          <Typography sx={{color: "error.main"}} key={policyText} variant="body-2">
            {policyText}
          </Typography>
        </Box>
      )
    }
    return (
      <div>
        {policies.map(policy => {
          const passwordPolicyHelper = passwordHelpers[policy]
          if (!passwordValidatorsActive) return inactiveValidation(passwordPolicyHelper.helperText)
          if (passwordPolicyHelper.isValid)
            return passingValidation(passwordPolicyHelper.helperText)
          return failingValidation(passwordPolicyHelper.helperText)
        })}
      </div>
    )
  }

  return (
    <form className="account-password-settings-form" onSubmit={handleSubmit}>
      <TextField
        autoComplete="current-password"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={toggleShowPassword}
                tabIndex={-1}
                size="medium"
              >
                {showPasswords ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Current Password"
        margin="normal"
        type={showPasswords ? "text" : "password"}
        {...field("currentPassword")}
      />
      <TextField
        autoComplete="new-password"
        fullWidth={true}
        onFocus={handleNewPasswordFocus}
        onKeyUp={validatePasswordPolicies}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={toggleShowPassword}
                tabIndex={-1}
                size="medium"
              >
                {showPasswords ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="New Password"
        margin="normal"
        type={showPasswords ? "text" : "password"}
        {...password}
        helperText={null}
      />
      <Container maxWidth={false} disableGutters>
        {passwordHelpersList()}
      </Container>
      <TextField
        autoComplete="new-password"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={toggleShowPassword}
                tabIndex={-1}
                size="medium"
              >
                {showPasswords ? <ShowPasswordIcon /> : <HidePasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Repeat Password"
        margin="normal"
        type={showPasswords ? "text" : "password"}
        {...field("passwordConfirmation")}
      />
      <div className={classes.formActions}>
        <SaveButton submitting={submitting} />
      </div>
    </form>
  )
}

AccountPasswordSettingsForm.propTypes = {
  classes: object,
  onSubmit: func.isRequired,
  passwordPolicy: object.isRequired,
}

export default AccountPasswordSettingsForm
