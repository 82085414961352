import {Button, InputAdornment} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object, string} from "prop-types"
import {MdArrowForward as ArrowRightIcon} from "react-icons/md"
import {
  MdMailOutline as EmailIcon,
  MdAccountBox as NameIcon,
  MdPhoneIphone as PhoneIcon,
  MdWarning as WarningIcon,
} from "react-icons/md"
import {Field, reduxForm} from "redux-form"

import {RFTextField} from "components/mui-redux-form/mui-redux-form"

import {requiredField, validEmail, validPhone} from "lib/field-validations"

export const LandingPageForm = ({
  classes,
  disclaimer,
  invalid,
  handleSubmit,
  networkError,
  onShowDisclaimer,
  submitTitle,
}) => (
  <form onSubmit={handleSubmit}>
    <Field
      autoFocus={true}
      className={classes.field}
      component={RFTextField}
      fullWidth={true}
      InputProps={{
        classes: {root: classes.fieldInputBase},
        endAdornment: (
          <InputAdornment position="end">
            <NameIcon />
          </InputAdornment>
        ),
      }}
      label="Your Name"
      name="name"
      validate={[requiredField]}
      variant="filled"
    />
    <Field
      className={classes.field}
      component={RFTextField}
      fullWidth={true}
      InputProps={{
        classes: {root: classes.fieldInputBase},
        endAdornment: (
          <InputAdornment position="end">
            <EmailIcon />
          </InputAdornment>
        ),
      }}
      label="Email"
      name="email"
      onChange={onShowDisclaimer}
      validate={[requiredField, validEmail]}
      variant="filled"
    />
    {disclaimer.email && (
      <ol className={classes.disclaimer}>
        <li>
          By providing your email address, you consent to receive occasional emails to help you get
          the most out of your account.
        </li>
        <li>You can stop emails at any time by clicking the "Unsubscribe" link in an email.</li>
      </ol>
    )}
    <Field
      className={classes.field}
      component={RFTextField}
      fullWidth={true}
      InputProps={{
        classes: {root: classes.fieldInputBase},
        endAdornment: (
          <InputAdornment position="end">
            <PhoneIcon />
          </InputAdornment>
        ),
      }}
      label="Mobile Phone"
      name="phoneMobile"
      onChange={onShowDisclaimer}
      validate={[validPhone]}
      variant="filled"
    />
    {disclaimer.phoneMobile && (
      <ol className={classes.disclaimer}>
        <li>
          By providing your mobile phone number, you consent to receive text messages sent by an
          automatic telephone dialing system.
        </li>
        <li>Consent to these terms is not a condition of purchase.</li>
        <li>You may opt out at any time by replying to a message with STOP or UNSUBSCRIBE.</li>
      </ol>
    )}
    {networkError && (
      <div className={classes.networkError}>
        <WarningIcon size="50" />
        An error occurred while creating your journey. We are sorry about this inconvenience. Please
        try again later.
      </div>
    )}
    <div className={classes.formActions}>
      <Button
        classes={{root: classes.submit, disabled: classes.disabledSubmit}}
        color="primary"
        disabled={invalid}
        size="large"
        type="submit"
        variant="contained"
      >
        {submitTitle || "Create"}
        <ArrowRightIcon className={classes.rightIcon} />
      </Button>
    </div>
  </form>
)

LandingPageForm.propTypes = {
  classes: object.isRequired,
  disclaimer: object.isRequired,
  handleSubmit: func.isRequired,
  invalid: bool.isRequired,
  networkError: bool,
  onShowDisclaimer: func.isRequired,
  submitTitle: string,
}

const styles = theme => ({
  field: {
    margin: "20px 0",
  },
  fieldInputBase: {
    backgroundColor: "white !important",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
  },
  submit: {
    paddingRight: theme.spacing(2),
  },
  disabledSubmit: {
    color: `white !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    opacity: 0.7,
  },
  disclaimer: {
    boxSizing: "border-box",
    margin: 0,
    maxHeight: 200,
    opacity: 1,
    paddingBottom: 20,
    transition: "all linear .2s",
    "& li": {
      fontSize: "13px",
      marginBottom: 10,
    },
    "&.hide": {
      maxHeight: 0,
      overflow: "hidden",
      opacity: 0,
      paddingBottom: 0,
    },
  },
  networkError: {
    animation: "1s linear showGracefully",
    color: theme.palette.error.main,
    margin: "50px 0",
    textAlign: "center",
    "& svg": {
      display: "block",
      margin: "0 auto",
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(2),
  },
})

export default reduxForm({form: "landingPage"})(withStyles(styles)(LandingPageForm))
