import {Box, Button, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, shape, string} from "prop-types"
import {useState} from "react"

import {TemplateListPreview} from "components/dialogs/template-list-preview"
import Feature from "components/feature/feature"

const useStyles = makeStyles(theme => ({
  sharedNotice: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    marginTop: 4,
    textAlign: "center",
    textTransform: "uppercase",
  },
  skeleton: {
    maxWidth: "65%",
  },
  skeletonRoot: {
    marginBottom: theme.spacing(2),
  },
  tooltipBody: {
    marginBottom: theme.spacing(1),
  },
  tooltipPaper: {
    padding: theme.spacing(2),
  },
}))

const SharedContentNotice = ({editingTemplateId, onShowDuplicateConfirmation, content}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const classes = useStyles()
  const {_type: type, id, newSlug} = content

  const onDuplicate = () => {
    onShowDuplicateConfirmation()
    setTooltipOpen(false)
  }

  return (
    <>
      <Tooltip
        classes={{tooltip: classes.tooltipPaper}}
        className={classes.tooltip}
        leaveDelay={750}
        onClose={() => setTooltipOpen(false)}
        onOpen={() => setTooltipOpen(true)}
        open={tooltipOpen}
        title={
          <>
            <Typography className={classes.tooltipBody} variant="body2">
              You are editing a shared {type}. Any edits you make here will be reflected on all
              campaigns that share this {type}.
            </Typography>
            <TemplateListPreview
              editingTemplateId={editingTemplateId}
              id={id}
              newSlug={newSlug}
              recordType={type}
              variant="body2"
            />
            <Feature featureKey="atomic-assets">
              {!!onShowDuplicateConfirmation && (
                <Button fullWidth={true} onClick={onDuplicate} size="small" variant="contained">
                  Make a Unique Copy
                </Button>
              )}
            </Feature>
          </>
        }
      >
        <Box className={classes.sharedNotice}>Shared {type}</Box>
      </Tooltip>
    </>
  )
}

SharedContentNotice.propTypes = {
  editingTemplateId: string,
  onShowDuplicateConfirmation: func.isRequired,
  content: shape({
    _type: string.isRequired,
    id: string,
    newSlug: string,
  }).isRequired,
}

export default SharedContentNotice
