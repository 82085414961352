import {TextField, Typography} from "@mui/material"
import Box from "@mui/material/Box"
import {func} from "prop-types"

import {SaveButton} from "components/save-button/save-button"

import {requiredField} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const ApproveForm = ({onSubmit}) => {
  const {field, handleSubmit, submitting} = useForm({
    onSubmit: i => onSubmit(i),
    initialValues: {notes: "", response: "approved"},
    validators: {
      notes: [requiredField],
    },
  })

  return (
    <div>
      <Typography variant="h4">Approve:</Typography>
      <Typography sx={{minHeight: "3.5rem"}} variant="body1">
        Approve the campaign so it can be launched
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth={true}
          disabled={submitting}
          placeholder="Approval message text (required):"
          multiline={true}
          rows="6"
          variant="outlined"
          {...field("notes")}
        />
        <Box
          sx={{
            marginTop: 3,
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <SaveButton
            classes={{}}
            color="primary"
            submitting={submitting}
            stateLabels={{
              default: "Approve For Launch",
              saved: "Approved",
              submitting: "Sending...",
            }}
          />
        </Box>
      </form>
    </div>
  )
}

ApproveForm.propTypes = {
  onSubmit: func.isRequired,
}

export default ApproveForm
