import {FormControl, InputLabel, ListItemText, MenuItem, Typography} from "@mui/material"
import cx from "classnames"
import pick from "lodash/pick"
import {array, bool, object, shape, string} from "prop-types"

import DOSelect from "components/do-select/do-select"
import CampaignTargetingCondition from "components/template-targeting/campaign-targeting-condition"
import TargetingConditionTextField from "components/template-targeting/targeting-condition-textfield"
import {useTemplateTargeting} from "components/template-targeting/template-targeting-context"
import {
  humanizeAtomizedNames,
  injectCustomOperators,
  isSurveyEntireAvailable,
  isSurveyMultiAnswerAvailable,
  isSurveySingleAnswerAvailable,
} from "components/template-targeting/template-targeting-helpers"

const splitOperator = value => value.split("::")

const getFullOperator = ({field, operator, meta}) => fullOperator => {
  return fullOperator.value === operator && fullOperator.isAvailable(field, meta)
}

// NB: Mini state machine for picking/setting the correct targeting condition defaults because each
// field is dependent on the selections of others.
const getNewTargetingConditionAttrs = ({id, meta, operator, targetingGroupId}, target) => {
  switch (target.name) {
    case "meta.surveyName":
      return {
        id,
        targetingGroupId,
        operator: "is_started",
        meta: {surveyName: target.value, questionTitle: "entire survey"},
      }
    case "meta.questionTitle":
      return {
        id,
        targetingGroupId,
        meta: {...pick(meta, ["surveyName"]), questionTitle: target.value.atomizedName},
      }
    case "meta.answerTitle":
      return {
        id,
        targetingGroupId,
        meta: pick(meta, ["answers", "surveyName", "questionTitle", "answerValue", "type"]),
      }
    case "meta.answerValue":
      return {
        id,
        targetingGroupId,
        meta: {
          ...pick(meta, ["answers", "surveyName", "questionTitle", "answerTitle", "type"]),
          answerValue: target.value,
        },
      }
    default:
      throw new Error(`unknown targeting condition field name (${target.name}) specified`)
  }
}

const SurveyTargetingCondition = ({
  classes,
  disabled = false,
  surveyContentBlocks,
  surveyQuestions,
  targetingCondition,
  templateId,
  templates,
}) => {
  const {updateTargetingCondition} = useTemplateTargeting()
  const {field, id, meta, targetingGroupId, operator, value} = targetingCondition
  const availableOperators = injectCustomOperators(field, meta)

  const renderCondition = (
    {name, value, availableTypes} // eslint-disable-line react/prop-types
  ) => (
    <MenuItem key={value} value={value}>
      <ListItemText
        classes={{secondary: classes.listItemTextSecondary}}
        primary={
          <Typography variant="inherit" noWrap>
            {name}
          </Typography>
        }
        secondary={availableTypes ? `Value can be a ${availableTypes}` : ""}
      />
    </MenuItem>
  )

  const onChangeCampaignValue = ({target}) => {
    updateTargetingCondition({id, targetingGroupId, value: target.value})
  }

  const onChangeSurveyAnswerValue = ({target}) => {
    const changes = getNewTargetingConditionAttrs(targetingCondition, target)
    updateTargetingCondition(changes)
  }

  const onChangeSurveyName = ({target}) => {
    const changes = getNewTargetingConditionAttrs(targetingCondition, target)
    updateTargetingCondition(changes)
  }

  const onChangeSurveyOperator = ({target}) => {
    const [operator, answerTitle] = splitOperator(target.value)
    const changes = {...getNewTargetingConditionAttrs(targetingCondition, target), operator}

    if (answerTitle) changes.meta.answerTitle = answerTitle

    updateTargetingCondition(changes)
  }

  const onChangeSurveyQuestion = ({target}) => {
    const {answers, type} = target.value
    const changes = getNewTargetingConditionAttrs(targetingCondition, target)

    if (answers) changes.meta.answers = answers
    if (type) changes.meta.type = type

    // Set defaults
    switch (true) {
      case isSurveySingleAnswerAvailable(field, changes.meta):
        changes.operator = "contains"
        changes.meta.answerValue = meta?.answerValue ?? ""
        break
      case isSurveyMultiAnswerAvailable(field, changes.meta):
        const [answer] = answers
        changes.operator = `is_selected`
        changes.meta.answerTitle = answer.atomizedName
        break
      case isSurveyEntireAvailable(field, changes.meta):
        changes.operator = "is_started"
        break
      default:
        throw new Error(`unknown survey type, cannot set survey question targeting defaults`)
    }

    updateTargetingCondition(changes)
  }

  const getMetaValue = name => meta?.[name] ?? ""

  const getOperatorValue = () => {
    if (meta.answerTitle) return `${operator}::${meta.answerTitle}`
    return operator
  }

  const surveyOperatorLabel = isSurveySingleAnswerAvailable(field, meta) ? "Operator" : "Value"

  return (
    <>
      {/*Survey Widget Name*/}
      <span className={classes.in}>for</span>
      <FormControl classes={{root: classes.fieldSelect}}>
        <InputLabel htmlFor="journey-survey-actions-widget-name">Survey Widget Name</InputLabel>
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          id="journey-survey-actions-widget-name"
          MenuProps={{classes: {paper: classes.fieldSelectMax}}}
          name="meta.surveyName"
          onChange={onChangeSurveyName}
          value={getMetaValue("surveyName")}
        >
          {surveyContentBlocks.map(({atomizedName, name, value}) => (
            <MenuItem key={value} value={atomizedName}>
              <Typography variant="inherit" noWrap>
                {name}
              </Typography>
            </MenuItem>
          ))}
        </DOSelect>
      </FormControl>

      {/*Survey Questions*/}
      {/*
          NB: Because we are providing an object as a `value` to MenuItem instead of a string,
          we must provide a custom `renderValue` and since we are storing the atomized value, we
          must additionally pass our value to `humanizeAtomizedNames` for it to be Title Cased
      */}
      <FormControl classes={{root: classes.fieldSelect}}>
        <InputLabel htmlFor="journey-survey-actions-question">Survey Question</InputLabel>
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          id="journey-survey-actions-question"
          MenuProps={{classes: {paper: classes.fieldSelectMax}}}
          name="meta.questionTitle"
          onChange={onChangeSurveyQuestion}
          renderValue={value => (value === "entire survey" ? value : humanizeAtomizedNames(value))}
          value={getMetaValue("questionTitle")}
        >
          {surveyQuestions
            .filter(
              ({atomizedContentBlockName, atomizedName}) =>
                atomizedName === "entire survey" ||
                atomizedContentBlockName === getMetaValue("surveyName")
            )
            .map(({answers, atomizedName, name, type, value}) => (
              <MenuItem key={value} value={{answers, atomizedName, type}}>
                <Typography variant="inherit" noWrap>
                  {name}
                </Typography>
              </MenuItem>
            ))}
        </DOSelect>
      </FormControl>

      {/* Survey Operators */}
      <FormControl classes={{root: classes.fieldSelect}}>
        <InputLabel htmlFor="survey-operator">{surveyOperatorLabel}</InputLabel>
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          onChange={onChangeSurveyOperator}
          MenuProps={{classes: {paper: classes.fieldSelectMax}}}
          name="meta.answerTitle"
          renderValue={value =>
            availableOperators.find(o => o.isAvailable(field, meta) && o.value === value)?.name ??
            availableOperators.find(o => o.isAvailable(field, meta))?.name
          }
          value={getOperatorValue()}
        >
          {availableOperators.filter(op => op.isAvailable(field, meta)).map(renderCondition)}
        </DOSelect>
      </FormControl>

      {/* Survey Value */}
      {isSurveySingleAnswerAvailable(field, meta) && (
        <TargetingConditionTextField
          classes={classes}
          disabled={disabled}
          getFullOperator={getFullOperator(targetingCondition)}
          name="meta.answerValue"
          onChange={onChangeSurveyAnswerValue}
          targetingCondition={targetingCondition}
          value={getMetaValue("answerValue")}
        />
      )}

      {/** Campaign Value **/}
      <CampaignTargetingCondition
        classes={classes}
        disabled={disabled}
        name="value"
        onChange={onChangeCampaignValue}
        templateId={templateId}
        templates={templates}
        value={value}
      />
    </>
  )
}

SurveyTargetingCondition.propTypes = {
  classes: object,
  disabled: bool,
  surveyContentBlocks: array,
  surveyQuestions: array,
  targetingCondition: shape({
    id: string,
    model: string,
    field: string,
    meta: object,
    meta_sub_key: string,
    operator: string,
    value: string,
  }),
  templateId: string,
  templates: array,
}

export default SurveyTargetingCondition
