import {connect} from "react-redux"
import {getFormMeta} from "redux-form"

import {isLoaded, isLoading} from "lib/loading-states"

import CustomObjectiveDialog from "./custom-objective-dialog"
import {OBJECTIVE_CREATED, onCreateObjective} from "./objectives-actions"

const mapStateToProps = state => {
  const formMeta = getFormMeta("customObjective")(state)
  const isObjectiveCreationComplete = isLoaded(OBJECTIVE_CREATED, state.loadingStates)

  return {
    addedObjective: isObjectiveCreationComplete
      ? state.objectives.list[state.objectives.list.length - 1]
      : null,
    isObjectiveCreationComplete,
    isObjectiveCreationStarted: isLoading(OBJECTIVE_CREATED, state.loadingStates),
    wasKeyTouched: Boolean(formMeta && formMeta.key && formMeta.key.touched),
    otherObjectives: state.objectives.list,
  }
}

const mapDispatchToProps = {
  onSubmit: onCreateObjective,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomObjectiveDialog)
