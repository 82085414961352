import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {object} from "prop-types"
import {useEffect} from "react"

const SmsPreview = ({message}) => {
  const classes = useStyles()

  // Notify ChromicPDF that we are ready to print
  useEffect(() => {
    document.getElementById("root")?.setAttribute("ready-to-print", "true")
  }, [])

  // NB: This code is lifted from `src/components/content-library/sms-bubble.jsx`
  // The only real change is the removal of the white background colors.
  // TODO: SmsBubble can probably be modified to remove the background color if
  //  desired.
  return (
    <div className={cx(classes.root, "preview-wrapper")}>
      <div className={classes.message}>
        <Typography className={classes.text} component="span">
          {message.payload?.body?.blocks[0]?.text}
        </Typography>
      </div>
    </div>
  )
}

SmsPreview.propTypes = {
  message: object.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 112.5,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: "hidden",
  },
  containerLibraryItem: {
    boxShadow: theme.shadows[4],
    transition: theme.transitions.create("box-shadow"),
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
  },
  text: {
    display: "block",
    color: theme.palette.common.white,
    fontSize: 13,
    overflow: "hidden",
    textOverflow: "ellipsis",
    minHeight: 13,
  },
  libraryItem: {
    maxWidth: 132,
  },
  message: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: 15,
    padding: `6px ${theme.spacing(1.5)}`,
    maxHeight: 90,
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    "&:before": {
      content: `""`,
      position: "absolute",
      zIndex: 0,
      bottom: 0,
      right: -8,
      height: 15,
      width: 20,
      background: theme.palette.primary.main,
      borderBottomLeftRadius: 15,
    },
    "&:after": {
      content: `""`,
      position: "absolute",
      zIndex: 1,
      bottom: 0,
      right: -10,
      width: 10,
      height: 15,
      borderBottomLeftRadius: 10,
      background: theme.palette.common.white,
    },
  },
}))

export default SmsPreview
