import {Paper} from "@mui/material"
import PropTypes from "prop-types"

import SmsBubble from "components/content-library/sms-bubble"

const SmsMessage = ({message}) => {
  return (
    <div>
      <Paper
        sx={theme => ({
          marginBottom: theme.spacing(2),
          padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
        })}
      >
        <div style={{maxWidth: "250px"}}>
          <SmsBubble
            inTooltip={true}
            text={message.payload.body.blocks[0].text}
            allowFullHeight={true}
          />
        </div>
      </Paper>
    </div>
  )
}

SmsMessage.defaultProps = {message: {order: 0}}

SmsMessage.propTypes = {
  message: PropTypes.shape({
    contentName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    order: PropTypes.number,
    objective: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    objectiveId: PropTypes.string,
    payload: PropTypes.shape({
      body: PropTypes.shape({
        blocks: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default SmsMessage
