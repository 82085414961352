import EnrollmentButton, {EnrollmentIcon} from "./enrollment-button"
import EnrollmentReadOnly from "./enrollment-readonly"
import EnrollmentSettings from "./enrollment-settings"

export default {
  title: "Enrollment",
  type: "enrollment",
  Button: EnrollmentButton,
  Icon: EnrollmentIcon,
  Readonly: EnrollmentReadOnly,
  Settings: EnrollmentSettings,
  suitableFor: ["content_container", "page"],
}
