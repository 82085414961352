import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, number, object} from "prop-types"
import {useContext} from "react"
import {FaAt, FaCalendarAlt, FaHashtag} from "react-icons/fa"
import {
  MdCheckBox,
  MdDelete,
  MdFormatAlignLeft,
  MdRadioButtonChecked,
  MdShortText,
} from "react-icons/md"

import MuiIcon from "../../../mui-icon"
import * as questionTypes from "./survey-question-types"
import {SurveySettingsContext} from "./survey-settings"
import SurveySettingsMaybeAnswers from "./survey-settings-maybe-answers"

const SurveySettingsQuestion = ({onRemoveQuestion, onUpdateQuestion, index, question}) => {
  const {widgetSlug} = useContext(SurveySettingsContext)

  const onRemove = () => window.confirm("Delete this Question?") && onRemoveQuestion(question)

  const onChangeTitle = ({target: {value}}) => onUpdateQuestion({...question, title: value})

  const onChangeType = ({target: {value}}) => onUpdateQuestion({...question, type: value})

  const classes = useStyles()

  return (
    <Paper
      classes={{elevation1: classes.paperElevation1, root: classes.paperRoot}}
      className={classes.questionRow}
      key={question.slug}
    >
      <fieldset className="question-body">
        <Typography component="legend">
          Question {index + 1}{" "}
          <IconButton
            className={classes.deleteIcon}
            data-testid={`question-${question.slug}-delete-button`}
            onClick={onRemove}
            size="medium"
          >
            <MuiIcon icon={<MdDelete />} />
          </IconButton>
        </Typography>
        <TextField
          fullWidth={true}
          id={`survey-${widgetSlug}-question-${index}-title`}
          label="Title"
          margin="normal"
          onChange={onChangeTitle}
          value={question.title || ""}
        />
        <FormControl fullWidth={true}>
          <InputLabel htmlFor={`survey-${widgetSlug}-question-${index}-type`}>Type</InputLabel>
          <Select
            className="type-select"
            id={`survey-${widgetSlug}-question-${index}-type`}
            onChange={onChangeType}
            value={question.type}
          >
            <MenuItem value={questionTypes.SHORT_ANSWER}>
              <MuiIcon icon={<MdShortText />} /> &nbsp; Short Answer
            </MenuItem>
            <MenuItem value={questionTypes.PARAGRAPH}>
              <MuiIcon icon={<MdFormatAlignLeft />} /> &nbsp; Paragraph
            </MenuItem>
            <MenuItem value={questionTypes.MULTIPLE_CHOICE}>
              <MuiIcon icon={<MdRadioButtonChecked />} /> &nbsp; Multiple Choice
            </MenuItem>
            <MenuItem value={questionTypes.CHECKBOXES}>
              <MuiIcon icon={<MdCheckBox />} /> &nbsp; Checkboxes
            </MenuItem>
            <MenuItem value={questionTypes.NUMBER}>
              <MuiIcon icon={<FaHashtag />} /> &nbsp; Number
            </MenuItem>
            <MenuItem value={questionTypes.DATE}>
              <MuiIcon icon={<FaCalendarAlt />} /> &nbsp; Date
            </MenuItem>
            <MenuItem value={questionTypes.EMAIL}>
              <MuiIcon icon={<FaAt />} /> &nbsp; Email
            </MenuItem>
            <MenuItem value={questionTypes.ADDRESS}>
              <MuiIcon icon={<FaAt />} /> &nbsp; Address
            </MenuItem>
          </Select>
        </FormControl>

        <SurveySettingsMaybeAnswers
          onUpdateQuestion={onUpdateQuestion}
          question={question}
          questionIndex={index}
        />
      </fieldset>
    </Paper>
  )
}

SurveySettingsQuestion.propTypes = {
  index: number.isRequired,
  onRemoveQuestion: func.isRequired,
  onUpdateQuestion: func.isRequired,
  question: object.isRequired,
}

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    height: 48,
    width: 48,
  },
  questionRow: {
    display: "flex",
    marginBottom: theme.spacing(4),
  },
  paperElevation1: {
    boxShadow: "none",
  },
  paperRoot: {
    background: "none",
  },
}))

export default SurveySettingsQuestion
