import {connect} from "react-redux"

import {updateAvatar, updateUser} from "./account-actions"
import AccountSettings from "./account-settings"

const mapStateToProps = ({session}) => ({currentUser: session.user, currentTeam: session.team})

const mapDispatchToProps = {updateAvatar, updateUser}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
