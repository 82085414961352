import {array, element, elementType, func, node, object, oneOfType} from "prop-types"
import {PureComponent} from "react"
import {connect} from "react-redux"
import {Route} from "react-router-dom"

import {getAccessToken} from "lib/access-token"
import {fetchUserInfo} from "lib/api"
import "lib/case-converter"
import storage from "lib/storage"

import {clearSession, getUsers, setCurrentTeam, setCurrentUser} from "../../actions/session-actions"

// This bears a little bit of documentation...
// We default to assuming the user is authenticated if they have an accessToken
// so that we can begin rendering the route. If the user has no token we
// immediately punt them to the login screen (via api-req.js). If the user has a token we begin
// rendering the route and silently validate their token in the background. If
// their token is valid they continue on unaware of the validation.  api-req.js handles
// sending the user to their destination in a token failure scenario.
export class AdminRoute extends PureComponent {
  state = {isAuthenticated: !!getAccessToken(storage).accessToken}

  componentDidMount() {
    this.testToken()
  }

  testToken() {
    fetchUserInfo().then(({team, ...userInfo}) => {
      const {type} = userInfo
      const isAuthenticated = type === "user"

      this.setState({isAuthenticated})
      if (!isAuthenticated) return

      if (!this.props.team) this.props.setCurrentTeam(team)
      if (!this.props.user) this.props.setCurrentUser(userInfo)
      if (!this.props.users) this.props.getUsers()
    })
  }

  routeRender = props => {
    const {component: Component} = this.props

    return this.state.isAuthenticated ? (
      <Component {...props} />
    ) : (
      (window.location = `/admin/login?redirect=${window.location.pathname}`) && null
    )
  }

  render() {
    const {component, ...rest} = this.props

    return <Route {...rest} render={this.routeRender} />
  }
}

AdminRoute.propTypes = {
  clearSession: func.isRequired,
  component: oneOfType([element, elementType, node]).isRequired,
  getUsers: func.isRequired,
  setCurrentTeam: func.isRequired,
  setCurrentUser: func.isRequired,
  team: object,
  user: object,
  users: array,
}

const mapStateToProps = ({session: {team, user}}) => ({team, user})

const addActionsToProps = {
  clearSession,
  setCurrentTeam,
  setCurrentUser,
  getUsers,
}

export default connect(mapStateToProps, addActionsToProps)(AdminRoute)
