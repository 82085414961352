import {Box, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, object, shape, string} from "prop-types"
import {useState} from "react"
import {MdBrightness1 as FallbackIcon} from "react-icons/md"

import {useContentBlockEditor} from "components/content-block-editor/content-block-editor-context"
import {pluginFor} from "components/content-block-editor/plugins"

const useStyles = makeStyles(theme => ({
  tooltipPaper: {padding: theme.spacing(2)},
}))

const HiddenNotice = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.brand.darkestGray,
  color: "#fff",
  marginTop: 4,
  textAlign: "center",
  textTransform: "uppercase",
}))

const Tag = styled("div")(({theme}) => ({
  background: theme.palette.primary.main,
  fontSize: "13px",
  color: "#fff",
  padding: `0 ${theme.spacing(0.5)}`,
  borderRadius: theme.spacing(0.5),
  textTransform: "uppercase",
  width: "auto",
  height: "auto",
}))

const ContentBlockName = styled(Typography)({
  textDecoration: "underline",
  cursor: "pointer",
  flexShrink: 0,
})

const findHiddenBlocks = contentBlocks => {
  return contentBlocks.filter(contentBlock =>
    Object.values(contentBlock.grid).some(value => value === 0)
  )
}

const getHiddenBreakpoints = contentBlock => {
  return Object.entries(contentBlock.grid)
    .filter(([_, value]) => value === 0)
    .map(([breakpoint, _]) => breakpoint)
}

const ContentBlockRow = ({contentBlock}) => {
  const {setSelectedBlock} = useContentBlockEditor()

  const plugin = pluginFor(contentBlock)
  const PluginIcon = plugin["Icon"] || FallbackIcon
  return (
    <ListItem alignItems="flex-start" sx={{padding: 0}}>
      <ListItemIcon sx={{minWidth: "24px"}}>
        <PluginIcon />
      </ListItemIcon>
      <ListItemText onClick={() => setSelectedBlock(contentBlock)}>
        <Stack direction="row" alignItems="flex-start" gap="8px">
          <ContentBlockName>{plugin.title}</ContentBlockName>
          <Stack direction="row" flexWrap="wrap" gap="4px">
            {getHiddenBreakpoints(contentBlock).map(breakpoint => (
              <Tag key={`${contentBlock.id}-${breakpoint}`}>{breakpoint}</Tag>
            ))}
          </Stack>
        </Stack>
      </ListItemText>
    </ListItem>
  )
}

ContentBlockRow.propTypes = {
  contentBlock: shape({
    grid: object.isRequired,
    type: string.isRequired,
  }),
}

const HiddenBlockNotice = ({contentBlocks}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const classes = useStyles()

  const hiddenBlocks = findHiddenBlocks(contentBlocks)

  return (
    <>
      <Tooltip
        classes={{tooltip: classes.tooltipPaper}}
        leaveDelay={750}
        onClose={() => setTooltipOpen(false)}
        onOpen={() => setTooltipOpen(true)}
        open={tooltipOpen}
        title={
          <>
            <Typography className={classes.tooltipBody} variant="body2">
              This page contains content blocks that are hidden at specific browser sizes via the
              content blocks' width controls. The content blocks listed below are hidden at the
              specified browser sizes.
            </Typography>
            <Typography className={classes.tooltipBody} variant="body2">
              Click a block's name to edit the block
            </Typography>
            {hiddenBlocks.map(contentBlock => (
              <ContentBlockRow key={`${contentBlock.id}`} contentBlock={contentBlock} />
            ))}
          </>
        }
      >
        <HiddenNotice>Hidden Blocks</HiddenNotice>
      </Tooltip>
    </>
  )
}

HiddenBlockNotice.propTypes = {
  contentBlocks: arrayOf(
    shape({
      grid: object.isRequired,
      type: string.isRequired,
    })
  ),
}

export default HiddenBlockNotice
