import {Button} from "@mui/material"
import {array, func} from "prop-types"

import SurveySettingsQuestion from "./survey-settings-question"

const SurveySettingsQuestions = ({
  onAddQuestion,
  onRemoveQuestion,
  onUpdateQuestion,
  questions,
}) => (
  <div>
    {questions.map((question, index) => (
      <SurveySettingsQuestion
        index={index}
        key={question.slug}
        onRemoveQuestion={onRemoveQuestion}
        onUpdateQuestion={onUpdateQuestion}
        question={question}
      />
    ))}

    <Button onClick={onAddQuestion} type="button">
      Add Question
    </Button>
  </div>
)

SurveySettingsQuestions.propTypes = {
  onAddQuestion: func.isRequired,
  onRemoveQuestion: func.isRequired,
  onUpdateQuestion: func.isRequired,
  questions: array.isRequired,
}

export default SurveySettingsQuestions
