import {grey} from "@mui/material/colors"
import {alpha, createTheme, darken} from "@mui/material/styles"

import shadows from "./shadows"

export const updateToV5Shape = ({overrides, ...theme} = {}) => {
  if (overrides) {
    let components = Object.entries(overrides).reduce((obj, [k, v]) => {
      obj[k] = {styleOverrides: v}
      return obj
    }, {})
    return {
      ...theme,
      components,
    }
  }
  return theme
}

const baseTheme = theme =>
  createTheme(
    // Shim needs to be applied both before *and* after the base theme to pick up DO overrides
    // and preserve sane defaults (like contrast text calculations)
    updateToV5Shape(theme),
    {
      analytics: {
        comparisons: {
          better: "#79cc79",
          same: "#f1c190",
          worse: "#ff9898",
        },
      },
      breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: {
          xs: 0,
          sm: 414, // iPhone X and below in portrait mode
          md: 600,
          lg: 960,
          xl: 1280,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              fontSize: "0.875rem",
              lineHeight: 1.43,
              letterSpacing: "0.01071em",
            },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: "standard",
          },
        },
        MuiSelect: {
          defaultProps: {
            variant: "standard",
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: "standard",
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              lineHeight: "1.35rem",
            },
          },
        },
        MuiLink: {
          defaultProps: {
            underline: "hover",
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: "#FFFFFFF8",
              fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
              fontSize: "0.875rem",
              fontWeight: 400,
              letterSpacing: "0.01071em",
              lineHeight: 1.43,
              color: "rgba(0, 0, 0, 0.87)",
              boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.22)",
            },
          },
        },
      },
      palette: {
        primary: {
          main: "#00A4FF",
        },
        secondary: {
          main: "#EE7E10",
        },
        error: {
          main: "#FA4A42",
          light: alpha("#FA4A42", 0.15),
        },
        success: {
          main: "#99EE22",
          dark: darken("#99EE22", 0.5),
        },
        background: {
          default: "#F1F3F6",
          darkened: "#e9ebef",
        },
        text: {
          primary: `rgba(0, 0, 0, 0.87)`,
          secondary: `rgba(0, 0, 0, 0.54)`,
          disabled: `rgba(0, 0, 0, 0.38)`,
          hint: `rgba(0, 0, 0, 0.38)`,
        },
        brand: {
          darkBlue: "#002956",
          lightCoral: "#EF6568",
          lightBlue: "#489AD4",
          lightGreen: "#5CBDAF",
          darkestGray: "#5C6565",
          mediumGray: "#7B8787",
          lightestGray: "#F5F6F6",
        },
        grey: {
          main: grey[300],
          dark: grey[400],
        },
      },
      shadows,
      shape: {
        borderRadius: 3,
      },
      typography: {
        button: {
          borderRadius: 3,
        },
        h4: {
          fontWeight: 100,
          fontSize: "1.2rem",
          marginBottom: 24,
        },
      },
    },
    updateToV5Shape(theme)
  )

export default theme =>
  createTheme(baseTheme(theme), {
    components: {
      MuiButton: {
        variants: [
          {
            props: {variant: "outlined", color: "grey"},
            style: {
              color: baseTheme().palette.text.primary,
              borderColor: "rgba(0, 0, 0, 0.23)",
              "&.Mui-disabled": {
                border: `1px solid ${baseTheme().palette.action.disabledBackground}`,
              },
              "&:hover": {
                borderColor: "rgba(0, 0, 0, 0.23)",
                backgroundColor: alpha(
                  baseTheme().palette.text.primary,
                  baseTheme().palette.action.hoverOpacity
                ),
              },
            },
          },
          {
            props: {variant: "text", color: "grey"},
            style: {
              color: baseTheme().palette.text.primary,
              "&:hover": {
                backgroundColor: alpha(
                  baseTheme().palette.text.primary,
                  baseTheme().palette.action.hoverOpacity
                ),
              },
            },
          },
        ],
      },
    },
  })
