import {FormControlLabel, Stack, Switch, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {array, bool, func, object, string} from "prop-types"
import {number} from "prop-types"
import {useState} from "react"

import {layoutBases} from "../content-block-editor/layout-basis"

const columns = new Array(12).fill()

const WidgetGridSelector = ({
  breakpoints,
  breakpoint,
  classes,
  className,
  current,
  onlyVisible,
  layoutBasis,
  name,
  onChange,
  value,
}) => {
  const basis = layoutBases.find(({value}) => value === layoutBasis)
  const columnModulo = columns.length / basis.columns
  const [nextWidth, setNextWidth] = useState(null)
  const selectWidth = nextValue => {
    onChange({target: {name, value: nextValue}})
    setNextWidth(null)
  }
  const widthForLabel = (nextWidth ?? value) / columnModulo

  const toggleHidden = currentValue => {
    if (currentValue === 0) {
      selectWidth(12)
    } else {
      selectWidth(0)
    }
  }

  return (
    <div className={cx(className, classes.gridSelectorField)}>
      <div>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row">
            <Typography variant="caption">
              Width at <span className={classes.tag}>{breakpoint}</span>
            </Typography>
            {current && <span style={{marginLeft: "5px"}}>(current)</span>}
          </Stack>
          <FormControlLabel
            control={<Switch size="small" disabled={value > 0 && onlyVisible} />}
            label={<Typography variant="caption">Hide Block</Typography>}
            checked={value === 0}
            onChange={() => toggleHidden(value)}
            sx={{marginRight: 0}}
          />
        </Stack>
      </div>
      {value > 0 && (
        <div>
          <div className={classes.gridSelectorContainer} onMouseOut={() => setNextWidth(null)}>
            {columns.map((_, i) => {
              const w = i + 1

              if (w % columnModulo) return null

              return (
                <div
                  className={cx(classes.gridSelector, {
                    preview: nextWidth !== null && w <= nextWidth && value < nextWidth,
                    selected: w <= Math.min(nextWidth ?? basis.columns * columnModulo, value),
                  })}
                  key={w}
                  onMouseEnter={() => setNextWidth(w)}
                  onClick={() => selectWidth(w)}
                />
              )
            })}
          </div>
          {widthForLabel === basis.columns
            ? "100"
            : ((widthForLabel / basis.columns) * 100).toPrecision(2)}
          % ({widthForLabel} of {basis.columns} columns)
        </div>
      )}
    </div>
  )
}

WidgetGridSelector.propTypes = {
  breakpoints: array.isRequired,
  breakpoint: string.isRequired,
  current: bool.isRequired,
  classes: object.isRequired,
  className: string,
  onlyVisible: bool,
  layoutBasis: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  value: number.isRequired,
}

const styles = theme => ({
  gridSelectorField: {
    display: "block",
    marginTop: theme.spacing(3),
  },
  gridSelectorContainer: {
    display: "flex",
    width: "100%",
  },
  tag: {
    background: theme.palette.primary.main,
    color: "#fff",
    padding: `0 ${theme.spacing(0.5)}`,
    borderRadius: theme.spacing(0.5),
  },
  gridSelector: {
    height: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    flex: 1,
    cursor: "pointer",
    "&:first-child": {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      borderRadius: `${theme.spacing(0.5)} 0 0 ${theme.spacing(0.5)}`,
      // These lines are here because we always want the first one to show. We
      // don't allow someone to select less than 1 column.
      background: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
    },
    "&:last-child": {
      borderRight: `1px solid ${theme.palette.primary.main}`,
      borderRadius: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
    },
    "&.preview": {
      background: theme.palette.primary.light,
      boxShadow: theme.shadows[1],
    },
    "&.selected": {
      background: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
    },
  },
})

export default withStyles(styles)(WidgetGridSelector)
