import {connect} from "react-redux"

import {getUsers} from "../../actions/session-actions"
import AuditLogs from "../audit-logs/audit-logs"
import {getAuditLogActions} from "../audit-logs/audit-logs-actions.js"
import Box from "../box/box"

const mapStateToProps = ({auditLogs, session: {teamUsers}}) => ({
  eventActions: auditLogs.eventActions,
  users: teamUsers,
  title: "Team Audit Logs",
  headers: [
    {field: "action", isDefault: true},
    {field: "user", isDefault: true, sortable: false},
    {field: "batchId"},
    {field: "sshKey", label: "SSH Key", sortable: false},
    {field: "location", sortable: false, isDefault: true},
    {field: "timestamp", isDefault: true},
  ],
})

const addActionsToProps = {getUsers, getAuditLogActions}

const BoxedAuditLogs = props => (
  <Box>
    <AuditLogs {...props} />
  </Box>
)

export default connect(mapStateToProps, addActionsToProps)(BoxedAuditLogs)
