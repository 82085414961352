import React from "react"

import CrossChannelEngagementContainer from "components/cross-channel-engagement/cross-channel-engagement-container"
import CrossChannelEngagementEditor from "components/cross-channel-engagement/cross-channel-engagement-editor"

const ThemedCrossChannelEngagementEditor = () => (
  <CrossChannelEngagementContainer
    render={({
      channelId,
      classes,
      contentContainer,
      contentContainerId,
      updateContentContainer,
    }) => (
      <CrossChannelEngagementEditor
        classes={classes}
        channelId={channelId}
        contentContainer={contentContainer}
        contentContainerId={contentContainerId}
        updateContentContainer={updateContentContainer}
      />
    )}
  />
)

export default ThemedCrossChannelEngagementEditor
