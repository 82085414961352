import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {object, string} from "prop-types"

import UploadedImage from "../uploaded-image/uploaded-image"
import {journeyContext} from "./journey-context"

const HeaderLogo = ({themeClasses, className, classes, src, cacheBuster}) => (
  <div className={cx(themeClasses?.headerLogoWrapper, classes.logoWrapper)}>
    <UploadedImage
      className={cx(className, classes.logo)}
      variant="large"
      src={src}
      cacheBuster={cacheBuster}
    />
  </div>
)

HeaderLogo.propTypes = {
  cacheBuster: string,
  src: string.isRequired,
  className: string,
  classes: object.isRequired,
  themeClasses: object,
}

const styles = theme => {
  const logo = {},
    logoWrapper = {}

  const width = theme.custom?.logo?.width
  const height = theme.custom?.logo?.height

  if (width && width !== "auto") {
    logo.width = width

    logo.maxWidth = "100%"
    logoWrapper.maxWidth = "100% !important"
    // We set max height on this as well, because hopefully if any power users
    // are setting a width and leaving height to auto, the height will also need
    // to be unset for flexible height
    logoWrapper.maxHeight = "100% !important"
  }

  if (height && height !== "auto") {
    logo.height = height

    logo.maxHeight = "100%"
    logoWrapper.maxHeight = "100% !important"
  }

  return {
    logo,
    logoWrapper,
  }
}

export default journeyContext(withStyles(styles)(HeaderLogo))
