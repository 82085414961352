import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import uniqBy from "lodash/uniqBy"
import {bool, func, object, string} from "prop-types"
import {createContext, useCallback, useEffect, useReducer, useState} from "react"
import {FaRegFileAlt as PageIcon} from "react-icons/fa"
import {MdEmail as EmailIcon, MdTextsms as SMSIcon} from "react-icons/md"
import {useHistory} from "react-router-dom"

import {fetchContentLibrary, fetchContentLibraryItem, fetchObjectives} from "lib/api"
import useQueryParams from "lib/hooks/use-query-params"

import {CampaignBuilderProvider} from "../campaign-builder/campaign-builder-context"
import DOSelect from "../do-select/do-select"
import DocumentTitle from "../document-title/document-title"
import Padded from "../padded/padded"
import ArchiveCornerBanner from "../templates-list/archive-corner-banner"
import TitleBar from "../title-bar/title-bar"
import ContentLibraryEditor from "./content-library-editor"
import ContentLibraryImage from "./content-library-image"
import ContentPreview from "./content-preview"
import {
  availableContentTypes,
  buildNewContentItem,
  contentTypesForSelectedContentType,
  filterItems,
  humanizeType,
  libraryItemsToLibrary,
} from "./helpers"
import SmsBubble from "./sms-bubble"

const useLibraryItemStyles = makeStyles(theme => ({
  popper: {margin: "-0.5em 0 0 -1.75em"},
  previewTooltip: {
    maxWidth: "none",
  },
  tooltip: {
    backgroundColor: theme.palette.grey["800"],
    color: theme.palette.grey["50"],
    fontWeight: "bold",
  },
}))

export const ContentLibraryContext = createContext()

export const LibraryItem = props => {
  const {
    bodyPreview,
    id,
    isArchived,
    onClick,
    type,
    className,
    contentName,
    onToggleDuplicate,
    shouldDuplicate,
  } = props
  const onClickItem = () => {
    onClick && onClick({id, type})
  }
  const classes = useLibraryItemStyles()

  return (
    <div
      aria-label={`Edit ${contentName}`}
      className={className}
      onClick={onClickItem}
      role="button"
    >
      <Tooltip
        classes={{tooltip: classes.previewTooltip}}
        enterDelay={250}
        placement="right"
        title={<ContentPreview key={`content-preview-${id}`} {...props} />}
      >
        <div>
          {isArchived && <ArchiveCornerBanner />}
          {type === "sms" ? (
            <SmsBubble text={bodyPreview} />
          ) : (
            <ContentLibraryImage contentType={type} id={id} />
          )}
        </div>
      </Tooltip>
      {onToggleDuplicate && (
        <Tooltip
          classes={{popper: classes.popper, tooltip: classes.tooltip}}
          placement="right-start"
          title={`Checking this box makes a unique duplicate of the ${type} in your content library`}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldDuplicate}
                onChange={onToggleDuplicate}
                name="shouldDuplicate"
                color="primary"
              />
            }
            label="Make unique copy"
          />
        </Tooltip>
      )}
      <Typography variant="body1">{contentName}</Typography>
      <Typography variant="body1">Type: {humanizeType(type)}</Typography>
    </div>
  )
}

LibraryItem.propTypes = {
  bodyPreview: string,
  className: string,
  contentName: string.isRequired,
  id: string.isRequired,
  isArchived: bool,
  objective: object,
  onClick: func,
  type: string.isRequired,
  onToggleDuplicate: func,
  shouldDuplicate: bool,
}

const setLibrary = payload => ({type: "LIBRARY_SET", payload})
const itemUpdated = payload => ({type: "ITEM_UPDATED", payload})
const itemDeleted = payload => ({type: "ITEM_DELETED", payload})

const reducer = (state, action) => {
  switch (action.type) {
    case "LIBRARY_SET":
      return libraryItemsToLibrary(action.payload.libraryItems, action.payload.objectives)

    case "ITEM_UPDATED":
      const {includeArchived, updatedItem} = action.payload
      const allItems = uniqBy(
        Object.values(state).reduce((acc, curr) => acc.concat(curr), []),
        "id"
      )

      return libraryItemsToLibrary(
        allItems.find(item => item.id === updatedItem.id)
          ? allItems
              .map(item => (item.id === updatedItem.id ? updatedItem : item))
              .filter(item => includeArchived || !item.isArchived)
          : [...allItems, updatedItem].filter(item => includeArchived || !item.isArchived),
        action.payload.objectives
      )

    case "ITEM_DELETED":
      const {deletedItem} = action.payload
      const demItems = uniqBy(
        Object.values(state).reduce((acc, curr) => acc.concat(curr), []),
        "id"
      )

      return libraryItemsToLibrary(
        demItems.filter(item => item.id !== deletedItem.id),
        action.payload.objectives
      )

    default:
      throw new Error("unknown type specified")
  }
}

const ContentLibrary = () => {
  const [objectives, setObjectives] = useState([])
  const [activeObjective, setActiveObjective] = useState("all-objectives")
  const [contentType, setContentType] = useState("all")
  const [library, dispatch] = useReducer(reducer, {})
  const [selectedItem, setSelectedItem] = useState(null)
  const history = useHistory()

  const {includeArchived, filter, updateSearchQuery} = useQueryParams(
    {includeArchived: false, filter: ""},
    ["filter"]
  )

  const refresh = useCallback(() => {
    fetchContentLibrary({
      includeArchived: includeArchived ? true : undefined,
      contentTypes: contentTypesForSelectedContentType(contentType),
      ...(activeObjective !== "all-objectives" ? {objective: activeObjective} : {}),
    }).then(libraryItems => {
      dispatch(setLibrary({libraryItems, objectives}))
    })
  }, [activeObjective, contentType, includeArchived, objectives])

  const onChangeContentType = event => {
    const {
      currentTarget: {
        dataset: {name},
      },
    } = event

    setContentType(name)
  }

  const onChangeActiveObjective = ({target: {value}}) => {
    setActiveObjective(value)
  }

  const handleArchived = ({target: {checked}}) => updateSearchQuery({includeArchived: checked})

  const handleFilter = ({target: {value}}) => {
    updateSearchQuery({filter: value})
  }

  const onSelectContentLibraryItem = newSelectedItem => {
    // FIXME legacy-content-editor this whole ContentLibrary/ContentLibraryItem/ContentLibraryEditor editor/creation experience is
    // super weird and convoluted. This still has a long way to go to get fully cleaned up. This routing behavior below is
    // duplicated in the ContentLibraryEditor because it also creates content and has to redirect to the content because
    // it isn't really responsible for much editing anymore. If the legacy message editor is reworked to be an sms only editor,
    // addressable at its own page (like the page and email editor) then the ContentLibraryEditor can go away and what's left
    // can be consolidated in a content creator component and clean up some of this gotchya-y duplication.
    switch (newSelectedItem.type) {
      case "page":
        return history.push(`/admin/templates/content-library/pages/${newSelectedItem.id}`)
      case "email":
        return history.push(`/admin/templates/content-library/messages/${newSelectedItem.id}`)
      default:
        return setSelectedItem(newSelectedItem)
    }
  }

  const onChangeContentLibraryItem = newSelectedItem => {
    setSelectedItem(newSelectedItem)
  }

  const onCloseContentLibraryEditor = useCallback(
    action => {
      if (action === "delete") dispatch(itemDeleted({deletedItem: selectedItem, objectives}))

      setSelectedItem(null)
    },
    [objectives, selectedItem]
  )

  const onCompleteEditing = action => {
    if (selectedItem.id.startsWith("new")) {
      fetchObjectives().then(nextObjectives => {
        setObjectives(nextObjectives)
        setSelectedItem(null)
        refresh()
      })

      return action
    }

    if (action === "delete") {
      dispatch(itemDeleted({deletedItem: selectedItem, objectives}))
      setSelectedItem(null)

      return action
    }

    return fetchContentLibraryItem(selectedItem.id, selectedItem.type).then(updatedItem => {
      dispatch(itemUpdated({includeArchived, updatedItem, objectives}))
      setSelectedItem(null)

      return updatedItem
    })
  }

  const addContent = type => setSelectedItem(buildNewContentItem(type))

  useEffect(() => {
    fetchObjectives().then(setObjectives)
  }, [])

  useEffect(() => {
    refresh()
  }, [activeObjective, contentType, includeArchived, objectives, refresh])

  const classes = useStyles()

  return (
    <ContentLibraryContext.Provider value={{objectives}}>
      {!!selectedItem && (
        <ContentLibraryEditor
          key={selectedItem.id}
          onChangeSelection={onChangeContentLibraryItem}
          onClose={onCloseContentLibraryEditor}
          onComplete={onCompleteEditing}
          {...selectedItem}
        />
      )}
      <TitleBar title="Content Library">
        <Button
          data-type="page"
          onClick={() => addContent("page")}
          color="grey"
          variant="contained"
        >
          <PageIcon /> Add Page
        </Button>
        <Button
          data-type="email"
          onClick={() => addContent("email")}
          color="grey"
          variant="contained"
        >
          <EmailIcon /> Add Email
        </Button>
        <Button data-type="sms" onClick={() => addContent("sms")} color="grey" variant="contained">
          <SMSIcon />
          Add SMS
        </Button>
      </TitleBar>
      <Padded>
        <DocumentTitle title="Content Library" />
        <div className={classes.controls}>
          <TextField
            className={classes.searchField}
            onChange={handleFilter}
            placeholder="Search..."
            value={filter}
          />
          <FormControl className={classes.objectiveSelect} margin="normal">
            <InputLabel>Filter by Objective</InputLabel>
            <DOSelect onChange={onChangeActiveObjective} value={activeObjective}>
              <MenuItem value={"all-objectives"}>All objectives</MenuItem>
              <MenuItem value={"no-objective"}>No objective assigned</MenuItem>
              <Divider />
              {objectives.map(objective => (
                <MenuItem key={objective.id} value={objective.id}>
                  {objective.name}
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
          <FormControlLabel
            control={<Switch checked={includeArchived} color="primary" onChange={handleArchived} />}
            label="Display Archived"
          />
          <FormControl>
            <ButtonGroup>
              {availableContentTypes.map((contentTypeOption, i) => (
                <Button
                  color={contentType === contentTypeOption["data-name"] ? "primary" : "grey"}
                  key={i}
                  onClick={onChangeContentType}
                  {...contentTypeOption}
                >
                  {contentTypeOption.label}
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        </div>
        <div className={classes.content}>
          {Object.entries(library).map(([sectionTitle, sectionItems]) => {
            const filteredItems = filterItems(sectionItems, filter.toLowerCase())

            if (filteredItems.length === 0) return null

            return (
              <div className={classes.section} key={sectionTitle}>
                <Typography className={classes.objectiveTitle} variant="h3">
                  {sectionTitle}
                </Typography>
                <div className={classes.libraryItems}>
                  <CampaignBuilderProvider>
                    {filteredItems.map(item => (
                      <LibraryItem
                        key={item.id}
                        onClick={onSelectContentLibraryItem}
                        {...item}
                        className={classes.libraryItem}
                        objective={objectives.find(o => o.id === item.objectiveId)}
                      />
                    ))}
                  </CampaignBuilderProvider>
                </div>
              </div>
            )
          })}
        </div>
      </Padded>
    </ContentLibraryContext.Provider>
  )
}

const useStyles = makeStyles(theme => ({
  controls: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "stretch",
    "& > *": {
      marginRight: theme.spacing(2),
    },
    "& > :last-child": {
      marginRight: 0,
    },
    marginBottom: theme.spacing(3),
  },
  objectiveTitle: {
    fontSize: 16,
    padding: `${theme.spacing(1)} 0`,
  },
  searchField: {
    flex: 1,
  },
  objectiveSelect: {
    minWidth: 150,
  },
  content: {
    "& > :first-child": {
      marginTop: 0,
    },
  },
  section: {
    marginTop: theme.spacing(2),
  },
  libraryItems: {
    display: "grid",
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(2),
    msGridRowGap: theme.spacing(1),
    fallbacks: [{display: "-ms-grid"}],
    gridTemplateColumns: "repeat(auto-fill, 150px)",
    msGridTemplateColumns: "repeat(auto-fill, 150px)",
  },
  libraryItem: {
    cursor: "pointer",
    position: "relative",
    "& p": {
      fontSize: 14,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: theme.palette.text.hint,
    },
  },
}))

export default ContentLibrary
