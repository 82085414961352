import {Tooltip, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import makeStyles from "@mui/styles/makeStyles"
import {useEffect} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"

import {fetchAdvancedInsightsOverallNumberOfCampaigns} from "lib/api"

const useStyles = makeStyles(() => ({
  container: {
    display: "inline-flex",
    flexDirection: "column",
    height: "100%",
  },
  metric: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
}))

const OverallNumberOfCampaigns = () => {
  const classes = useStyles()

  const {
    fetchInsights,
    filterParams,
    hasSocket,
    overallNumberOfCampaigns,
    overallNumberOfCampaignsIsLoading,
  } = useAdvancedTeamInsights()

  useEffect(() => {
    if (hasSocket)
      fetchInsights("overallNumberOfCampaigns", fetchAdvancedInsightsOverallNumberOfCampaigns, [
        filterParams,
      ])
  }, [fetchInsights, filterParams, hasSocket])

  const numberOfCampaignsCount = overallNumberOfCampaigns?.value ?? 0

  return (
    <>
      <div className={classes.container}>
        <Tooltip
          placement="left-start"
          title={
            <div>
              <p>The overall section gives a high-level view of campaign performance.</p>
              <p>
                Quickly see the number of campaigns running, how many contacts are targeted, and the
                level of engagement you receive from them.
              </p>
            </div>
          }
        >
          <Typography variant="h5">Number of Campaigns</Typography>
        </Tooltip>
        <Typography variant="h3" className={classes.metric}>
          {overallNumberOfCampaignsIsLoading ? (
            <Skeleton variant="rect" width={60} hieght={57} />
          ) : (
            numberOfCampaignsCount
          )}
        </Typography>
      </div>
    </>
  )
}

export default OverallNumberOfCampaigns
