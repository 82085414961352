import {Button, Card, CardContent, Divider, Stack, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {object} from "prop-types"
import React, {useEffect, useState} from "react"
import {
  FaArchive as ArchivedIcon,
  FaTools as DraftIcon,
  FaLeaf as LiveIcon,
  FaDollarSign as MonetaryIcon,
  FaAward as OtherIcon,
} from "react-icons/fa"
import {MdOutlineCardGiftcard as RewardsIcon} from "react-icons/md"
import {Link} from "react-router-dom"

import StatsPie from "components/template-insights/stats-pie.jsx"

import {fetchRewardSetCompletionInsights} from "lib/api"

const Header = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  padding: "5px",
})

const CardTitle = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
})

const Category = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  color: "#5C6565",
  padding: "5px",
})

const renderStatus = status => {
  switch (status) {
    case "draft":
      return (
        <Header direction="row" style={{backgroundColor: "#002956"}}>
          <DraftIcon />
          <Typography sx={{paddingLeft: "5px"}}>Draft</Typography>
        </Header>
      )
    case "live":
      return (
        <Header direction="row" style={{backgroundColor: "#489AD4"}}>
          <LiveIcon />
          <Typography sx={{paddingLeft: "5px"}}>Live</Typography>
        </Header>
      )
    case "archived":
      return (
        <Header direction="row" style={{backgroundColor: "#5C6565"}}>
          <ArchivedIcon />
          <Typography sx={{paddingLeft: "5px"}}>Archived</Typography>
        </Header>
      )
    default:
      return null
  }
}

const renderCategory = category => {
  switch (category) {
    case "monetary":
      return (
        <Category direction="row">
          <MonetaryIcon />
          <Typography sx={{paddingLeft: "5px"}}>Monetary</Typography>
        </Category>
      )
    case "rewards":
      return (
        <Category direction="row">
          <RewardsIcon />
          <Typography sx={{paddingLeft: "5px"}}>Rewards</Typography>
        </Category>
      )
    case "other":
      return (
        <Category direction="row">
          <OtherIcon />
          <Typography sx={{paddingLeft: "5px"}}>Other</Typography>
        </Category>
      )
    default:
      return null
  }
}

const RewardSetCard = ({rewardSet}) => {
  const [data, setData] = useState({})

  useEffect(() => {
    fetchRewardSetCompletionInsights(rewardSet.id).then(newData => setData(transformData(newData)))
  }, [rewardSet])

  const transformData = data => {
    const properties = [
      {key: "hasNotStarted", name: "Has not started", color: "#002956"},
      {key: "inProgress", name: "In progress", color: "#489AD4"},
      {key: "complete", name: "Completed", color: "#5CBDAF"},
    ]

    const rowData = properties.map(({key, name}) => ({
      name,
      value: data[key] || 0,
      tooltip: `${data[key] || 0}`,
    }))

    const total = rowData.reduce((sum, {value}) => sum + value, 0)
    const colors = properties
      .map(({key, color}) => ({
        value: data[key] || 0,
        color,
      }))
      .filter(({value}) => value > 0)
      .map(({color}) => color)

    return {rowData, total, colors}
  }

  return (
    <Card>
      {renderStatus(rewardSet.status)}
      <CardContent>
        <Stack spacing={1} alignItems="center">
          <CardTitle variant="h5" align="center">
            {rewardSet.name}
          </CardTitle>
          <div style={{fontStyle: "italic"}}>Reward Steps: {rewardSet.rewardSteps.length}</div>
          {data?.total > 0 ? (
            <div style={{width: "315px"}}>
              <StatsPie
                colors={data?.colors}
                data={data?.rowData}
                total={data?.total}
                totalLabel={"Contacts"}
              />
            </div>
          ) : (
            <div style={{padding: "0 0 225px 315px"}}></div>
          )}
          {renderCategory(rewardSet.category)}
        </Stack>
      </CardContent>
      <Divider />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{width: "100%", margin: "0"}}
      >
        <Button
          component={Link}
          fullWidth={true}
          sx={{
            borderRadius: "0",
            color: "rgba(0, 0, 0, 0.87)",
            "&:hover": {
              background: "#00A4FF",
              color: "#FFFFFF",
            },
          }}
          to={`/admin/rewards/reward-sets/${rewardSet.id}`}
        >
          {rewardSet.status === "draft" ? "Edit" : "View"}
        </Button>
      </Stack>
    </Card>
  )
}

RewardSetCard.propTypes = {
  rewardSet: object,
}

export default RewardSetCard
