// This file was generated automatically from character-25.svg with the following command:
// ./svg-converter.js src/components/superhero/character-25.svg
import SuperheroSvg from "./superhero-svg"

const Character25 = props => (
  <SuperheroSvg viewBox="30 60 100 210" {...props}>
    <g id="Layer_21">
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M96.04,263.33c4.86,0.59,11.68,0.94,19.29-0.09l0.1-0.03c0.09-0.04,2.29-0.91,2.37-2.54
  				c0.06-1.22-1.04-2.39-3.36-3.57l-0.07-0.03c-0.1-0.03-10.43-3.26-11.4-7.25l-0.82,0.2c1.06,4.37,11.14,7.6,11.93,7.85
  				c2.42,1.24,2.89,2.19,2.86,2.76c-0.05,0.97-1.52,1.66-1.79,1.78c-13.88,1.87-25.07-0.9-26.33-1.23
  				c-1.21-1.53,1.32-7.23,3.25-10.52l-0.73-0.43c-0.01,0.02-1.33,2.29-2.38,4.8c-1.48,3.55-1.7,5.78-0.67,6.82l0.08,0.08l0.11,0.03
  				C88.54,261.98,91.4,262.76,96.04,263.33z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M110.94,263.55l0.6-0.59c-0.86-0.87-1.26-1.79-1.19-2.74c0.13-1.77,1.87-3.11,1.88-3.12
  				l-0.51-0.67c-0.08,0.06-2.06,1.57-2.22,3.73C109.41,261.35,109.9,262.49,110.94,263.55z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M95.5,260.21c3.29,0.4,7.93,0.73,14.41,0.8l0.01-0.84c-17.69-0.19-21.52-2.37-21.56-2.4
  				l-0.45,0.72C88.01,258.55,89.6,259.49,95.5,260.21z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M110.19,259.85l0.25-0.81c-12.37-3.79-12.26-8.55-12.26-8.59l-0.84-0.06
  				C97.31,250.61,97.06,255.83,110.19,259.85z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M107.68,257.78c0.69,0.08,1.63-0.34,1.95-0.49l-0.37-0.76c-0.61,0.3-1.34,0.49-1.53,0.4
  				c0-0.01,0-0.02,0-0.04c0.01-1.49-0.43-1.49-2.55-1.51c-0.83-0.01-1.43-0.01-1.57-0.15c-0.14-0.14-0.14-0.74-0.13-1.57
  				c0.01-0.69-0.2-1.13-0.61-1.31c-0.8-0.34-1.92,0.67-2.24,0.99l0.59,0.6c0.53-0.52,1.17-0.88,1.32-0.81c0,0,0.1,0.09,0.1,0.52
  				c-0.02,2.37,0.17,2.56,2.54,2.58c0.84,0.01,1.56,0.01,1.69,0.11c0.02,0.05,0.02,0.32,0.02,0.55c0,0.33,0.13,0.6,0.38,0.75
  				C107.39,257.72,107.53,257.76,107.68,257.78z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M81.49,262.8l0.05-0.02c0.1-0.03,2.34-0.75,2.54-2.37c0.14-1.22-0.87-2.46-3.1-3.8l-0.07-0.03
  				c-0.03-0.01-2.61-1.01-5.24-2.52c-3.4-1.95-5.35-3.85-5.63-5.5l-0.83,0.14c0.76,4.44,10.59,8.35,11.37,8.65
  				c2.33,1.4,2.74,2.38,2.67,2.95c-0.1,0.87-1.37,1.47-1.9,1.65c-13.98,0.91-24.96-2.62-26.18-3.03c-1.29-1.77,1.43-6.97,2.61-8.86
  				l-0.72-0.45c-0.47,0.76-4.58,7.48-2.47,9.94l0.07,0.09l0.11,0.04c0.12,0.04,11.72,4.1,26.69,3.12L81.49,262.8z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M77.04,262.81l0.64-0.55c-0.8-0.93-1.14-1.88-1-2.81c0.25-1.76,2.07-2.97,2.09-2.98l-0.46-0.71
  				c-0.09,0.06-2.16,1.43-2.47,3.57C75.67,260.51,76.08,261.68,77.04,262.81z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M76.19,260.21l0.07-0.84c-17.64-1.4-21.31-3.85-21.34-3.87l-0.49,0.68
  				C54.56,256.29,58.16,258.78,76.19,260.21z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M76.55,259.07l0.3-0.79c-12.08-4.63-11.65-9.37-11.64-9.41l-0.84-0.12
  				C64.34,248.96,63.72,254.15,76.55,259.07z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M75.22,256.77c0.41-0.09,0.77-0.23,0.95-0.3l-0.32-0.78c-0.63,0.26-1.37,0.4-1.55,0.3
  				c0-0.01,0-0.02,0-0.04c0.04-0.57,0.08-1.06-0.4-1.35c-0.34-0.2-0.87-0.25-2.04-0.33c-0.83-0.06-1.42-0.11-1.55-0.26
  				c-0.13-0.15-0.09-0.75-0.02-1.58c0.05-0.69-0.12-1.15-0.52-1.35c-0.78-0.39-1.96,0.54-2.3,0.83l0.55,0.64
  				c0.57-0.48,1.23-0.8,1.37-0.72c0,0,0.09,0.1,0.06,0.53c-0.09,1.18-0.14,1.77,0.22,2.19c0.36,0.42,0.95,0.46,2.13,0.55
  				c0.83,0.06,1.55,0.12,1.68,0.22c0.02,0.05,0,0.32-0.02,0.55c-0.03,0.33,0.09,0.6,0.33,0.78
  				C74.14,256.92,74.71,256.89,75.22,256.77z"
          />
        </g>
      </g>
      <path
        style={{fill: "#CFA38C"}}
        d="M108.03,127.35L108.03,127.35L108.03,127.35c0.37-0.75,0.75-1.49,1.12-2.24
  		c-0.71,1.42-1.42,2.84-2.13,4.26c-1.34-1.45-2.67-2.9-4.01-4.35c-2.14-2.32-4.28-4.65-6.42-6.97c-0.49-0.53-0.98-1.06-1.47-1.59
  		c-0.42-0.46-0.66-1-0.54-1.67c0.11-0.62,0.59-1.39,1.23-1.86c1.3-0.94,3.34-1.28,4.24-0.3c1.34,1.45,2.67,2.9,4.01,4.36
  		c2.14,2.32,4.28,4.65,6.42,6.97c0.49,0.53,0.98,1.06,1.47,1.59c0.57,0.62,0.77,1.41,0.33,2.32c-0.64,1.32-2.46,1.94-2.46,1.94
  		s0,0,0,0c-0.01,0-2.52,0.23-2.79-0.43C106.76,128.73,108.02,127.36,108.03,127.35z"
      />
      <path
        style={{fill: "#CFA38C"}}
        d="M52.04,136.57L52.04,136.57L52.04,136.57c0.84,0.03,1.67,0.06,2.51,0.09
  		c-1.59-0.05-3.18-0.11-4.76-0.16c0.76-1.82,1.53-3.64,2.29-5.46c1.22-2.91,2.45-5.82,3.67-8.74c0.28-0.67,0.56-1.33,0.84-2
  		c0.24-0.57,0.63-1.02,1.29-1.19c0.61-0.16,1.51-0.05,2.2,0.34c1.4,0.79,2.55,2.51,2.04,3.73c-0.76,1.82-1.53,3.64-2.29,5.46
  		c-1.22,2.91-2.45,5.82-3.67,8.74c-0.28,0.67-0.56,1.33-0.84,2c-0.33,0.78-0.96,1.29-1.97,1.26c-1.47-0.04-2.79-1.43-2.79-1.43
  		s0,0,0,0c0-0.01-1.26-2.2-0.77-2.72C50.26,135.99,52.04,136.57,52.04,136.57z"
      />
      <path
        style={{fill: "#4A9AD4"}}
        d="M64.38,145.71L49.9,249.86l25.97,0.5l32.89-26.8l-9.06-62.75c0-0.22-0.01-0.43-0.02-0.65l0.15-0.03
  		l-0.26-1.68c-0.1-1.06-0.26-2.09-0.48-3.11l-2.95-20.29l-29.78,7.88l0.02,0.15"
      />
      <path
        style={{fill: "#123252"}}
        d="M99.63,100.31c0.1,0.7,0.07,1.42-0.1,2.11c-0.49,2.06-2.08,4.03-1.46,6.05
  		c0.42,1.38,1.74,2.23,2.72,3.29c6.91,7.43-16.36,8.99-19.84,9.53c-4.03,0.62-7.98,0.82-12,1.1c-3.72,0.26-6.97,1.39-10.78,0.59
  		c-1.44-0.3-2.89-0.8-3.96-1.81c-1.07-1.01-1.66-2.63-1.14-4c0.43-1.13,1.54-1.96,1.75-3.16c0.47-2.68-3.68-4.21-3.77-6.93
  		c-0.06-1.67,1.48-3.05,1.53-4.72c0.07-2.44-1.87-4.77-1.79-7.32c0.08-2.71,3.11-4.61,3.3-7.32c0.07-1.06-0.31-2.09-0.57-3.11
  		c-2.14-8.44,4.27-15.04,10.78-19.23c3.99-2.57,8.93-4.03,13.61-2.57c0.36,0.11,0.72,0.24,1.09,0.22c0.34-0.01,0.66-0.15,0.99-0.22
  		c5.54-1.32,13.69,8.05,12.59,13.27c-0.33,1.58-0.94,3.24-0.38,4.76c0.82,2.24,3.81,3.2,4.38,5.51c0.61,2.5-2.02,5.01-1.24,7.46
  		c0.46,1.45,1.94,2.28,2.94,3.43C99,98.09,99.48,99.18,99.63,100.31z"
      />
      <g>
        <defs>
          <rect
            id="SVGID_00000055699965805888409400000000715519991781342851_"
            x="36.91"
            y="43.06"
            width="207.06"
            height="160.44"
          />
        </defs>
        <clipPath id="679f33a7-SVGID_00000064332143116511002510000003013922631968084865_">
          <use
            xlinkHref="#SVGID_00000055699965805888409400000000715519991781342851_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#EB6669",
          }}
          d="M85.59,109.92
  			c-2.53-2.58,1.85-7,4.85-5.89c3.15,1.17,12.83,10.13,12.83,10.13l-7.98,7.87C95.29,122.02,87.47,111.84,85.59,109.92z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#CFA38C",
          }}
          d="M73.54,67.44
  			c-0.58,0-1.15,0.03-1.72,0.09c-0.34,13.5-12.58,20.56-14.89,21.78c2.02,7.28,8.69,12.62,16.61,12.62
  			c9.52,0,17.24-7.72,17.25-17.24C90.79,75.17,83.07,67.44,73.54,67.44z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#CFA38C",
          }}
          d="M71.96,104.15
  			c-1.68,0.42-4.42,1.27-4.42,1.27l1.06,5.03l7.98,3.36l8.4-3.78v-5.88c0,0,0.42,0-2.66-0.18c-3.08-0.18-3.83-0.47-3.83-0.47l0-2.44
  			c6.91-2.25,12.29-8.75,12.29-16.41c0-0.91-0.08-1.81-0.22-2.68l-33.41,6.8c-0.12,0.06-0.23,0.13-0.36,0.2
  			c0,0.02,0.14,0.42,0.14,0.44c-1.48,0.51-2.68,1.53-2.68,3.18c0,2.08,1.69,3.77,3.77,3.77c0.81,0,1.57-0.26,2.18-0.7
  			c2.89,3.48,7.12,5.82,11.9,6.2l0,1.65v0.65H71.96z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#EB6669",
          }}
          d="M71.07,104.27
  			c-3.57,0.45-6.72,0-9.24,2.94c-1.72,2.01-1.38,6.39-1.03,8.91l2.64,15.93l32.76,0l-1.57-19.62c-0.44-2.81-2.87-6.02-4.24-7.74
  			c-1.68-2.1-5.43-1.3-10.5-0.84c0,0,1.26,3.78-2.94,4.2C76.95,108.05,71.07,109.31,71.07,104.27z"
        />
        <polygon
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#EB6669",
          }}
          points="62.96,128.86
  			96.2,132.06 97.05,137.16 64.31,141.17 		"
        />

        <circle
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#0D1E36",
          }}
          cx="81.57"
          cy="80.8"
          r="1.37"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#CFA38C",
          }}
          d="M71.82,67.53
  			c-8.72,0.86-15.52,8.21-15.53,17.16c0,0,0.26,4.23,0.8,5.19c0,0,14.81-7.86,14.81-22.14"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#CFA38C",
          }}
          d="M71.8,67.64
  			c0,0,4.77,5.34,14.4,5.34C86.19,72.98,80.71,66.48,71.8,67.64z"
        />

        <circle
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#0D1E36",
          }}
          cx="69.79"
          cy="82.76"
          r="1.37"
        />
        <g style={{clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)"}}>
          <g>
            <path
              style={{fill: "#609E92"}}
              d="M79.38,103.84c0.01,0.05,0.01,0.09,0.02,0.14c-0.06-0.29,0.01,0.11,0.02,0.22
  					c0.01,0.19,0.02,0.37,0.01,0.56c0,0.1-0.01,0.2-0.02,0.31c0,0.05-0.01,0.11-0.02,0.16c-0.02,0.18,0.02-0.1-0.01,0.06
  					c-0.1,0.55-0.19,0.85-0.49,1.28c-0.23,0.33-0.68,0.63-1.19,0.77c-0.25,0.07-0.5,0.09-0.76,0.13c0.28-0.05,0.13-0.01,0,0
  					c-0.29,0.03-0.57,0.04-0.86,0.04c-0.2,0-0.4-0.01-0.6-0.02c-0.1-0.01-0.21-0.02-0.31-0.03c-0.13-0.01,0.18,0.03-0.03,0
  					c-0.08-0.01-0.15-0.02-0.23-0.04c-0.47-0.09-0.97-0.25-1.27-0.4c-0.49-0.25-0.85-0.55-1.12-0.98c-0.36-0.57-0.49-1.24-0.49-1.96
  					c-0.01-0.96-1.51-0.97-1.5,0c0.01,1.56,0.57,3.01,1.87,3.94c0.95,0.68,2.15,0.93,3.3,0.99c0.37,0.02,0.74,0.01,1.1-0.01
  					c0.1-0.01,0.19-0.02,0.29-0.03c0.09-0.01,0.17-0.02,0.26-0.04c0,0,0.01,0,0.01,0c-0.12,0.02-0.11,0.02,0.03,0
  					c0.1-0.01,0.21-0.03,0.31-0.04c1.48-0.25,2.61-1.28,3.02-2.71c0.25-0.86,0.27-1.83,0.1-2.71
  					C80.64,102.5,79.19,102.9,79.38,103.84L79.38,103.84z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#EB6669",
          }}
          d="M61.47,107.66
  			c1.99-3.1,6.3-1.53,6.66,1.88c0.36,3.41-5.6,17.52-5.6,17.52l-7.33-5.39C55.2,121.67,59.99,109.97,61.47,107.66z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#F5F6F5",
          }}
          d="M75.55,128.99
  			l6.99,0.41c0.7,0.04,1.14,0.53,1.1,1.23l-0.96,16.32c-0.04,0.7-0.53,1.14-1.23,1.1l-6.99-0.41c-0.7-0.04-1.14-0.53-1.1-1.23
  			l0.96-16.32C74.36,129.39,74.85,128.95,75.55,128.99z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#CFA38C",
          }}
          d="M74.97,138.15
  			c0,0-0.45,0.51-0.22,0.72c0.24,0.21,1.22,0.28,1.98-0.6c0.5-0.58,3-2.57,5.45-1.36c0,0,2.49,2.01,0.82,4.75
  			c-1.25,2.05-2.69,3.41-6.11,4.06c-3.19,0.6-5.05-0.11-6.12-0.15c-3.43,0.74-6.86,1.47-10.29,2.21c-0.5,0.11-1,0.21-1.5,0.32
  			l-0.93-6.34c3.5-0.75,7.01-1.5,10.51-2.25c0.36-0.08,0.72-0.15,1.08-0.23c0.49-0.93,1.73-3.07,3.36-4.01c0,0,0.71-0.56,1.88-0.56
  			C76.05,134.68,76.18,136.9,74.97,138.15z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#F5F6F5",
          }}
          d="M74.17,133.6
  			l-0.14,5.62c0,0,1.9,0.72,2.61-1.39C77.19,136.23,74.17,133.6,74.17,133.6z"
        />
        <g style={{clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M75.45,86.09c0.01-0.02,0.02-0.04,0.04-0.07c0.01-0.02,0.03-0.04,0.04-0.07
  					c0.03-0.04,0.08-0.09,0.01-0.02c0.07-0.07,0.13-0.15,0.2-0.21c0.02-0.02,0.15-0.12,0.07-0.06c0.05-0.04,0.1-0.07,0.16-0.1
  					c0.03-0.02,0.05-0.03,0.08-0.04c0.02-0.01,0.12-0.05,0.04-0.02c0.06-0.03,0.13-0.05,0.19-0.06c0.16-0.04,0.21-0.05,0.41-0.04
  					c0.4,0.01,0.82,0.2,1.19,0.42c0.26,0.16,0.67-0.11,0.55-0.42c-0.37-0.98-0.74-1.96-1.1-2.94c-0.05-0.14-0.1-0.28-0.16-0.42
  					c-0.07-0.19-0.25-0.32-0.46-0.26c-0.18,0.05-0.33,0.27-0.26,0.46c0.37,0.98,0.74,1.96,1.1,2.94c0.05,0.14,0.1,0.28,0.16,0.42
  					c0.18-0.14,0.37-0.28,0.55-0.42c-0.79-0.47-1.75-0.73-2.61-0.28c-0.34,0.18-0.67,0.49-0.84,0.83
  					C74.59,86.14,75.23,86.52,75.45,86.09L75.45,86.09z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#CFA38C",
          }}
          d="M94.16,135.87
  			c0,0,0.69-0.08,0.73,0.24c0.04,0.31-0.46,1.17-1.63,1.29c-0.77,0.08-3.86,0.99-4.25,3.7c0,0,0.26,3.21,3.5,3.41
  			c2.43,0.15,4.39-0.25,6.88-2.7c2.32-2.28,2.79-4.22,3.36-5.13c2.57-2.4,5.14-4.81,7.72-7.21c0.37-0.35,0.75-0.7,1.12-1.05
  			l-2.63-5.3c-2.63,2.45-6.76,6.09-9.38,8.55c-0.27,0.25-0.49,0.5-1.45,0.47c-1.06-0.14-3.55-0.34-5.27,0.47
  			c0,0-0.87,0.27-1.54,1.22C90.64,134.76,92.42,136.14,94.16,135.87z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000064332143116511002510000003013922631968084865_)",
            fill: "#EB6669",
          }}
          d="M95.29,122.02
  			l1.26,14.7c0,0-0.9-0.04-2.52,0.42c-2.94,0.84-5.46-2.1-5.46-2.1L95.29,122.02z"
        />
      </g>
      <path
        style={{fill: "#123252"}}
        d="M78.37,70.29c-0.03,0.07-0.06,0.14-0.09,0.21c-0.72,1.57-1.74,3-2.9,4.28
  		c-2.34,2.58-5.28,4.6-8.32,6.27c-1.77,0.97-3.54,1.95-5.44,2.67c-0.59,0.22-5.54,1.28-5.54,1.44c-0.35-4.42-0.08-7.99,2.28-11.93
  		c0.71-1.18,1.52-2.3,2.5-3.27c2.33-2.31,5.48-3.64,8.68-4.31c3.21-0.66,6.51-0.7,9.79-0.74C79.27,64.91,78.55,69.86,78.37,70.29z"
      />
      <path
        style={{fill: "#FFFFFF"}}
        d="M77.7,91.78c0.18,1.24-0.69,2.4-1.93,2.58c-1.24,0.18-2.4-0.69-2.58-1.93l-0.34-2.37
  		c0.03,0.02,0.06,0.03,0.09,0.05c1.98,1.02,4.55,0.21,4.55,0.21L77.7,91.78z"
      />
      <g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M80.87,77.01c0.02-0.02,0.03-0.03,0.05-0.04c0.05-0.05,0.16-0.11-0.06,0.05
  				c0.03-0.02,0.05-0.04,0.08-0.06c0.08-0.05,0.16-0.1,0.24-0.14c0.04-0.02,0.22-0.1,0.08-0.04c-0.13,0.06-0.01,0,0.02-0.01
  				c0.03-0.01,0.06-0.02,0.08-0.03c0.06-0.02,0.12-0.04,0.18-0.05c0.07-0.02,0.33-0.04,0.08-0.02c0.16-0.01,0.32-0.02,0.48-0.01
  				c0.04,0,0.08,0.01,0.12,0.01c-0.19,0-0.08-0.01-0.02,0c0.1,0.02,0.21,0.05,0.31,0.08c0.05,0.01,0.09,0.03,0.14,0.05
  				c0.01,0,0.13,0.06,0.14,0.05c-0.09-0.04-0.1-0.04-0.02-0.01c0.02,0.01,0.03,0.02,0.05,0.02c0.07,0.03,0.13,0.07,0.2,0.1
  				c0.32,0.17,0.78,0.09,0.96-0.25c0.17-0.33,0.09-0.77-0.25-0.96c-0.82-0.45-1.79-0.66-2.7-0.37c-0.4,0.13-0.83,0.34-1.13,0.64
  				c-0.26,0.26-0.28,0.74,0,0.99C80.16,77.27,80.59,77.29,80.87,77.01L80.87,77.01z"
          />
        </g>
      </g>
      <polyline
        style={{fill: "#4A9AD4"}}
        points="113.24,250.36 75.87,250.36 108.76,223.56 113.24,250.36 	"
      />
      <path
        style={{fill: "#CFA38C"}}
        d="M64.35,144.81L64.35,144.81L64.35,144.81c-0.45-0.7-0.91-1.4-1.36-2.1c0.86,1.33,1.73,2.67,2.59,4
  		c-1.93,0.41-3.86,0.83-5.79,1.24c-3.09,0.66-6.18,1.33-9.26,1.99c-0.71,0.15-1.41,0.3-2.12,0.46c-0.61,0.13-1.2,0.06-1.71-0.38
  		c-0.48-0.41-0.9-1.21-0.98-2c-0.15-1.6,0.6-3.53,1.89-3.81c1.93-0.41,3.86-0.83,5.79-1.24c3.09-0.66,6.18-1.33,9.26-1.99
  		c0.71-0.15,1.41-0.3,2.12-0.46c0.82-0.18,1.61,0.05,2.16,0.9c0.81,1.23,0.43,3.11,0.43,3.11s0,0,0,0c0,0.01-1.09,2.29-1.79,2.18
  		C64.91,146.6,64.36,144.82,64.35,144.81z"
      />
      <path
        style={{fill: "#CFA38C"}}
        d="M48.07,145.95L48.07,145.95L48.07,145.95c0.84,0.03,1.67,0.06,2.51,0.09
  		c-1.59-0.05-3.18-0.11-4.76-0.16c0.76-1.82,1.53-3.64,2.29-5.46c1.22-2.91,2.45-5.82,3.67-8.74c0.28-0.67,0.56-1.33,0.84-2
  		c0.24-0.57,0.63-1.02,1.29-1.19c0.61-0.16,1.51-0.05,2.2,0.34c1.4,0.79,2.55,2.51,2.04,3.73c-0.76,1.82-1.53,3.64-2.29,5.46
  		c-1.22,2.91-2.45,5.82-3.67,8.74c-0.28,0.67-0.56,1.33-0.84,2c-0.33,0.78-0.96,1.29-1.97,1.26c-1.47-0.04-2.79-1.43-2.79-1.43
  		s0,0,0,0c0-0.01-1.26-2.2-0.77-2.72C46.29,145.38,48.06,145.95,48.07,145.95z"
      />
    </g>
  </SuperheroSvg>
)

export default Character25
