import {Link, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import * as Sentry from "@sentry/browser"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {useCallback, useEffect, useState} from "react"
import {useSelector} from "react-redux"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {teamConfig} from "lib/config"

import {journeyContext} from "../../../journeys/journey-context"
import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import cardOnFile from "./card-on-file-helper"

const ErrorHeading = styled("h5")(({theme}) => ({
  fontWeight: "bold",
  color: theme.palette.error.main,
}))

export const CardOnFileReadonly = ({
  cardDescription,
  contact,
  className,
  isEditMode,
  journeyId,
  onClick,
  pageId,
  contentBlock,
  customCss,
  template,
  style,
}) => {
  const classes = useStyles()

  const team = useSelector(({session}) => session?.team)

  const contactId = contact?.id
  const teamId = team?.id
  const teamName = team?.name
  const {financialInstitution} = teamConfig(team, "card-on-file")
  const {
    data: {backgroundColor, finalMessage, merchantSelectionMessage},
    slug,
  } = contentBlock

  const [widgetDiv, setWidgetDiv] = useState(null)

  const widgetRef = useCallback(node => {
    if (node !== null) {
      setWidgetDiv(node)
    }
  }, [])

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: "COFF init attempted",
      level: "debug",
      data: {teamId, widgetDiv},
    })

    if (financialInstitution && teamId && widgetDiv) {
      cardOnFile({
        teamId,
        teamName,
        financialInstitution,
        contactId,
        pageId,
        journeyId,
        slug,
        cardDescription,
        merchantSelectionMessage,
        finalMessage,
        widgetDiv,
        buttonColor:
          backgroundColor ||
          template?.theme?.overrides?.MuiButton?.containedPrimary?.backgroundColor ||
          template?.theme?.palette?.primary?.main,
      })
    }
  }, [
    backgroundColor,
    cardDescription,
    contactId,
    finalMessage,
    journeyId,
    merchantSelectionMessage,
    pageId,
    slug,
    teamId,
    teamName,
    template?.theme,
    financialInstitution,
    widgetDiv,
  ])

  // TODO: Handle missing config better. Patch here uses error placeholder markup similar to that
  //   used in the ErrorBoundary in the ContentBlock component (parent). A placeholder specific
  //   to missing card-on-file config should be swapped in.
  return (
    <>
      {!financialInstitution ? (
        <Typography className={className} component="div" onClick={onClick} style={style}>
          <SortableDragHandle />
          <AppendPluginMenu contentBlock={contentBlock} />
          <ErrorHeading>
            This feature is currently unavailable, please try again later.
          </ErrorHeading>
          It may be due to settings you have chosen or a service it relies on being down.
        </Typography>
      ) : (
        <div className={className} onClick={onClick} style={style}>
          <SortableDragHandle />
          <AppendPluginMenu contentBlock={contentBlock} />
          <style>{customCss}</style>
          <div ref={widgetRef} className={cx(classes.root, {isEditMode})} id={slug} />
          <Link
            className={classes.link}
            href="https://www.digitalonboarding.com/card-on-file-terms-and-conditions"
            target="_blank"
          >
            Terms &amp; Conditions
          </Link>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    "& iframe": {
      width: "100%",
    },
    "&.isEditMode iframe": {
      pointerEvents: "none",
    },
  },
  link: {
    display: "block",
    textAlign: "center",
    color: theme.palette.text.hint,
  },
}))

CardOnFileReadonly.propTypes = {
  cardDescription: string,
  className: string,
  contact: object,
  finalMessage: string,
  isEditMode: bool,
  journeyId: string,
  merchantSelectionMessage: string,
  onClick: func,
  pageId: string,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  template: object,
  style: object,
}

export default contentBlockEditorConsumer(journeyContext(CardOnFileReadonly))
