import {FormControl, FormControlLabel, InputLabel, Switch} from "@mui/material"
import {bool, func, oneOf, string} from "prop-types"

const styles = (theme, labelStyle) => {
  const base = {
    margin: `${theme.spacing(2.75)} 0 ${theme.spacing(1.25)}`,
    "& > .MuiFormLabel-root": {
      left: 0,
      top: theme.spacing(-2.5),
      "&.Mui-focused": {color: "inherit"},
    },
    "& .MuiFormControlLabel-label": {fontSize: "0.75rem", letterSpacing: "0.03333em"},
    "& .MuiSwitch-root": {marginRight: "16px"},
  }

  return labelStyle === "bold"
    ? {...base, "& .MuiFormLabel-root": {fontSize: "18px", fontWeight: "bold"}}
    : base
}

const JourneyReentrySwitch = ({
  disabled,
  helperText,
  id,
  label,
  name,
  onChange,
  labelStyle,
  value,
}) => (
  <FormControl fullWidth={true} sx={theme => styles(theme, labelStyle)}>
    <InputLabel htmlFor={name} shrink={true}>
      {label}
    </InputLabel>
    <FormControlLabel
      control={
        <Switch
          checked={value === "tactical"}
          color="primary"
          disabled={disabled}
          id={id}
          name={name || id}
          onChange={onChange}
          value={value}
        />
      }
      label={helperText}
    />
  </FormControl>
)

JourneyReentrySwitch.defaultProps = {
  disabled: false,
  id: "journey-reentry-switch",
  labelStyle: "normal",
  value: "standard",
}

JourneyReentrySwitch.propTypes = {
  disabled: bool,
  helperText: string.isRequired,
  label: string.isRequired,
  labelStyle: oneOf(["bold", "normal"]),
  id: string,
  name: string,
  onChange: func.isRequired,
  value: oneOf(["standard", "tactical"]),
}

export default JourneyReentrySwitch
