import {Dialog, DialogActions, DialogContent, Typography} from "@mui/material"
import {string} from "prop-types"
import {useState} from "react"

import {SaveButton} from "components/save-button/save-button"

import {renewExpiredApproval} from "lib/api"

const ExpiredForm = ({approvalId}) => {
  const [submitting, setSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const onClick = () => {
    setSubmitting(true)
    renewExpiredApproval(approvalId).then(() => setShowModal(true))
  }

  return (
    <>
      <Typography variant="body1">
        Unfortunately your link to review the campaign has expired. Please request a new link by
        clicking the button below.
      </Typography>
      <SaveButton
        classes={{}}
        color="primary"
        onClick={onClick}
        stateLabels={{
          default: "Request New Link",
          saved: "New Link Requested",
          submitting: "Requesting New Link...",
        }}
        submitting={submitting}
      />
      <Dialog open={showModal}>
        <DialogContent>
          Your request has been sent. Please look for a new email. You can now close this page.
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  )
}

ExpiredForm.propTypes = {approvalId: string.isRequired}

export default ExpiredForm
