// This file was generated automatically from character-22.svg with the following command:
// ./svg-converter.js src/components/superhero/character-22.svg
import SuperheroSvg from "./superhero-svg"

const Character22 = props => (
  <SuperheroSvg viewBox="120 100 100 220" {...props}>
    <g id="Layer_19">
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M167.78,299.99c0,0-4.24,6.19-2.58,8.3
  			c0,0,10.03,4.06,23.45,3.73c0,0,4.94-1.33-0.38-4.82c0,0-9.09-3.94-9.59-7.83"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M186.34,306.34c0,0-3.92,2.32-1.29,5.66"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M165.13,305.22c0,0,2.96,2.36,19.01,4.31"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M174.39,299.34c0,0-0.85,4.43,10.22,9.21"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M176.88,302.51c0,0,2.11-1.66,1.88,0.22
  			c-0.22,1.88-0.22,1.88,1.66,2.11c1.88,0.22,1.88,0.22,1.77,1.17s1.88,0.22,1.88,0.22"
        />
      </g>
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M134.86,298.96c0,0-4.07,6.3-2.35,8.36
  			c0,0,10.14,3.78,23.54,3.09c0,0,4.9-1.46-0.52-4.81c0,0-9.19-3.69-9.79-7.56"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M153.58,304.8c0,0-3.86,2.43-1.13,5.7"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M132.36,304.25c0,0,3.02,2.28,19.12,3.79"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M141.45,298.13c0,0-0.73,4.45,10.46,8.93"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M144.02,301.22c0,0,2.06-1.72,1.89,0.17
  			c-0.17,1.89-0.17,1.89,1.72,2.06c1.89,0.17,1.89,0.17,1.8,1.12c-0.09,0.95,1.89,0.17,1.89,0.17"
        />
      </g>
      <polygon
        style={{fill: "#0A273F"}}
        points="163.85,207.59 164.92,298.82 182.96,299.4 180.22,182.53 162.03,178 	"
      />
      <path
        style={{fill: "#123252"}}
        d="M161.26,238.65l-7.17,60.38l-23.68-1.67l23.68,1.67l-23.68-1.67l13.91-91.69
  		c0-0.42,0.01-0.85,0.03-1.28c0.34-7.79,3.19-14.7,7.39-19.26l-0.01-0.16l27.66-7.03l1.5,21.6c0.13,1.08,0.22,2.18,0.25,3.31
  		l0.14,1.79l-0.14,0.02c-0.01,0.23-0.01,0.46-0.02,0.69c-0.54,12.34-7.36,22.46-15.9,24.55"
      />
      <g>
        <g>
          <g>
            <path
              style={{fill: "#E3B59D"}}
              d="M175.68,158.25c0.94,0.99-1.07-3.93,0.59-0.74c2.25,4.32,4.48,9.55,5.33,11.4
  					c0.5,1.08,1.07,2.53,2.22,5.44c1.49,3.77,1.4,3.72,1.95,4.91c1.41,3.05,2.59,4.58,2.31,4.82c-0.15,0.12-0.7-0.13-2.6-1.9
  					c-0.6,0.57-1.2,1.13-1.8,1.7c0.32-2.56,1.26-3.88,2.08-4.62c0.28-0.25,0.22-0.14,1.83-1.25c0.42-0.29,0.83-0.58,3.25-2.33
  					c0.85-0.61,1.7-1.2,2.54-1.83c1.96-1.47,2.12-1.74,2.76-1.86c1.21-0.21,2.66,0.31,3.25,1.42c0.09,0.17,0.49,0.97,0.17,1.59
  					c-0.13,0.25-0.26,0.24-0.6,0.58c-0.57,0.57-0.56,0.95-0.98,1.49c-0.41,0.53-0.88,0.74-1.26,0.96c-1.02,0.58-1.25,1.09-3.52,3.35
  					c-1.55,1.54-2.32,2.31-3.29,3.03c-1.63,1.2-2.68,1.58-3.51,1.76c-0.78,0.17-1.74,0.36-2.64-0.12c-0.55-0.3-0.87-0.73-1.44-1.71
  					c-1.03-1.77-1.65-3.22-1.73-3.42c-0.62-1.43-0.33-0.6-2.16-4.45c-0.98-2.07-1.73-3.72-2.14-4.63c-0.97-2.15-2.13-4.81-2.09-4.82
  					c0.04-0.02,1.42,2.93,1.41,2.94c0,0-0.28-0.58-0.84-1.74c-0.43-0.89-1.09-2.27-2.16-4.49c-0.34-0.71-1.51-3.14-1.51-3.14
  					c0,0-0.93-3.93,0.21-4.71C172.23,155.28,174.43,156.94,175.68,158.25z"
            />
          </g>
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_00000141421224951751399540000016756142020784637113_"
              x="91.31"
              y="106.97"
              width="189.17"
              height="163.46"
            />
          </defs>
          <clipPath id="470812cc-SVGID_00000086681148500649478380000007392287884270392486_">
            <use
              xlinkHref="#SVGID_00000141421224951751399540000016756142020784637113_"
              style={{overflow: "visible"}}
            />
          </clipPath>
          <g style={{clipPath: "url(#SVGID_00000086681148500649478380000007392287884270392486_)"}}>
            <g>
              <g>
                <path
                  style={{fill: "#4A8FC2"}}
                  d="M171.57,166.19l9.03-0.65l-4.03-11.32c0,0-1.92-6.14-12.28-4.22l0.52,3.5L171.57,166.19z"
                />
                <path
                  style={{fill: "#E3B59D"}}
                  d="M149.23,116.37c-0.55,0.07-1.08,0.16-1.61,0.27c1.2,12.74-9.53,20.77-11.57,22.17
  							c2.72,6.62,9.6,10.91,17.05,10.02c8.96-1.07,15.37-9.2,14.3-18.17S158.19,115.3,149.23,116.37z"
                />
                <path
                  style={{fill: "#E3B59D"}}
                  d="M167.39,130.62c0.86,7.21-3.48,13.93-9.73,16.83l0.27,2.3l-5.99,0.71l-0.18-1.55
  							c-4.55,0.18-8.79-1.54-11.9-4.5c-0.53,0.48-1.21,0.81-1.98,0.9c-1.96,0.23-3.74-1.17-3.97-3.13c-0.19-1.56,0.66-3,2-3.64
  							c-0.01-0.02-0.01-0.03-0.02-0.05c0.11-0.07,0.21-0.15,0.31-0.22l30.68-10.15C167.11,128.93,167.29,129.76,167.39,130.62z"
                />
                <path
                  style={{fill: "#E3B59D"}}
                  d="M142.82,129.35l21.05-6.97c1.38,1.68,2.42,3.66,3.04,5.84l-30.37,10.05
  							C139.6,136.14,141.84,132.98,142.82,129.35z"
                />

                <rect
                  x="151.89"
                  y="150"
                  transform="matrix(-0.993 0.1184 -0.1184 -0.993 326.94 283.172)"
                  style={{fill: "#4A9AD4"}}
                  width="6.33"
                  height="2.61"
                />
                <path
                  style={{fill: "#4A9AD4"}}
                  d="M142.69,163.63c-1.25-5,1.51-10.07,6.16-11.33l14.21-2.26c5.96-1.11,9.14,1.01,10.69,6.77
  							c1.9,7.44,3.8,14.88,5.7,22.32c-10.89-0.26-21.77-0.52-32.66-0.78L142.69,163.63z"
                />
                <path
                  style={{fill: "#4A9AD4"}}
                  d="M146.17,175.37c11.1,1.25,22.2,2.51,33.3,3.76c0.05,1.03,0.09,2.06,0.14,3.09l-30.89,4.6
  							L146.17,175.37z"
                />
                <circle style={{fill: "#0D1E36"}} cx="158.28" cy="128.04" r="1.3" />
                <g>
                  <g>
                    <path
                      style={{fill: "#0D1E36"}}
                      d="M157.22,125.41c0.05-0.06,0.11-0.12,0.17-0.17c0.03-0.03,0.06-0.05,0.09-0.08
  									c-0.23,0.19-0.08,0.06-0.02,0.02c0.09-0.06,0.19-0.12,0.3-0.17c0.06-0.03,0.16-0.04-0.07,0.03c0.03-0.01,0.07-0.03,0.1-0.04
  									c0.06-0.02,0.13-0.04,0.2-0.06c0.07-0.02,0.14-0.03,0.21-0.04c-0.24,0.05-0.12,0.02-0.06,0.01c0.05,0,0.09-0.01,0.14-0.01
  									c0.08,0,0.17,0,0.25,0c0.05,0,0.1,0.01,0.15,0.01c-0.22-0.01-0.09-0.01-0.03,0c0.22,0.04,0.44,0.11,0.65,0.19
  									c0.16,0.07,0.41,0.01,0.56-0.07c0.15-0.09,0.29-0.26,0.33-0.43c0.04-0.19,0.03-0.4-0.07-0.56
  									c-0.11-0.17-0.25-0.26-0.43-0.33c-0.85-0.34-1.78-0.35-2.62,0.03c-0.32,0.14-0.66,0.37-0.88,0.65
  									c-0.12,0.16-0.21,0.3-0.21,0.51c0,0.18,0.08,0.39,0.21,0.51C156.45,125.65,156.97,125.73,157.22,125.41L157.22,125.41z"
                    />
                  </g>
                </g>
                <path
                  style={{fill: "#123252"}}
                  d="M147.57,116.89c0,0,1.96,13.83-11.53,21.93c0,0-0.69-0.17-1.11-4.28
  							c-0.88-8.43,4.58-16.1,12.69-17.89"
                />
                <path
                  style={{fill: "#123252"}}
                  d="M147.61,116.75c0,0,5.09,4.49,14.15,3.41C161.76,120.16,155.87,114.66,147.61,116.75z"
                />
                <circle style={{fill: "#0D1E36"}} cx="147.41" cy="131.21" r="1.3" />
                <g>
                  <g>
                    <path
                      style={{fill: "#0D1E36"}}
                      d="M147.17,126.83c-0.94-0.17-1.91,0.09-2.66,0.67c-0.33,0.26-0.6,0.6-0.81,0.96
  									c-0.19,0.33-0.09,0.81,0.26,1c0.34,0.18,0.8,0.09,1-0.26c0.04-0.07,0.08-0.14,0.12-0.2c0.02-0.03,0.04-0.06,0.07-0.09
  									c0.01-0.02,0.06-0.08-0.01,0.01c-0.08,0.1,0,0,0.01-0.01c0.09-0.11,0.19-0.21,0.3-0.3c0.02-0.02,0.12-0.1,0.01-0.01
  									c-0.11,0.08-0.01,0.01,0.02-0.01c0.05-0.04,0.11-0.07,0.17-0.11c0.05-0.03,0.11-0.06,0.17-0.09c0.03-0.01,0.13-0.06,0,0
  									c-0.13,0.06-0.02,0.01,0.02,0c0.1-0.04,0.21-0.07,0.31-0.1c0.05-0.01,0.1-0.02,0.16-0.03c0.02,0,0.05-0.01,0.07-0.01
  									c0.1-0.02-0.22,0.03-0.09,0.01c0.16-0.02,0.31-0.02,0.47-0.01c0.05,0,0.11,0.01,0.16,0.01c0.06,0.01-0.25-0.04-0.13-0.02
  									c0.2,0.04,0.38,0.03,0.56-0.07c0.15-0.09,0.29-0.26,0.33-0.43C147.76,127.37,147.57,126.91,147.17,126.83L147.17,126.83z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      style={{fill: "#0D1E36"}}
                      d="M153.51,133.87c0.01-0.02,0.02-0.04,0.02-0.06c-0.02,0.07-0.04,0.08-0.01,0.02
  									c0.01-0.03,0.03-0.06,0.04-0.08c0.04-0.07,0.07-0.14,0.12-0.21c0.02-0.04,0.05-0.07,0.07-0.11
  									c0.01-0.02,0.03-0.04,0.04-0.06c0.05-0.07-0.03,0.03,0.01-0.01c0.07-0.08,0.14-0.17,0.22-0.24
  									c0.02-0.02,0.04-0.04,0.06-0.05c0.03-0.03,0.06-0.05,0.01-0.01c0.05-0.04,0.1-0.07,0.16-0.11c0.05-0.03,0.1-0.05,0.15-0.08
  									c0.06-0.03-0.07,0.02,0.02-0.01c0.03-0.01,0.06-0.02,0.1-0.03c0.06-0.02,0.12-0.03,0.18-0.04c0.05-0.01-0.07,0.01-0.01,0
  									c0.01,0,0.03,0,0.04,0c0.04,0,0.09,0,0.13,0c0.04,0,0.08,0,0.12,0.01c0.02,0,0.03,0,0.05,0c-0.08-0.01,0,0,0.01,0
  									c0.1,0.02,0.19,0.04,0.28,0.07c0.11,0.03,0.16,0.05,0.24,0.09c0.12,0.06,0.24,0.12,0.35,0.19c0.4,0.24,0.77-0.38,0.37-0.63
  									c-0.69-0.42-1.5-0.63-2.26-0.27c-0.45,0.21-0.81,0.63-1.04,1.06c-0.06,0.11-0.12,0.24-0.16,0.36
  									c-0.07,0.18,0.07,0.4,0.25,0.45C153.26,134.18,153.44,134.05,153.51,133.87L153.51,133.87z"
                    />
                  </g>
                </g>
                <path
                  style={{fill: "#0D1B30"}}
                  d="M151.11,138.94c1.87,0.29,3.98-0.6,4.87-1.04c0.22,0.76,0.46,1.83,0.36,2.55l0,0
  							c-0.08,0.58-0.36,1.13-0.84,1.52c-0.41-0.52-0.94-0.94-1.55-1.2l-2.16,0.29C151.78,141.06,151.5,140.57,151.11,138.94z"
                />
                <path
                  style={{fill: "#FFFFFF"}}
                  d="M150.9,138c0,0,1.92,0.23,4.79-0.98c0,0,0.14,0.37,0.28,0.88c-0.89,0.44-2.99,1.33-4.87,1.04
  							C151.05,138.66,150.98,138.35,150.9,138z"
                />
                <path
                  style={{fill: "#F05C60"}}
                  d="M151.92,141.42c-0.17-0.26-0.27-0.55-0.33-0.83c0.55-0.13,1.13-0.14,1.7-0.02
  							c0.89,0.21,1.66,0.71,2.2,1.41c-0.08,0.07-0.16,0.13-0.25,0.19C154.12,142.87,152.63,142.54,151.92,141.42z"
                />
              </g>
            </g>
          </g>
          <g style={{clipPath: "url(#SVGID_00000086681148500649478380000007392287884270392486_)"}}>
            <g>
              <path
                style={{fill: "#4A9AD4"}}
                d="M169.31,158.77l-3.08,0.46c-0.57,0.08-1.1-0.31-1.19-0.88l0,0c-0.08-0.57,0.31-1.1,0.88-1.19
  						l3.08-0.46c0.57-0.08,1.1,0.31,1.19,0.88l0,0C170.27,158.15,169.88,158.69,169.31,158.77z"
              />
              <path
                style={{fill: "#4A9AD4"}}
                d="M170.24,155.7l-5.67,0.84c-0.12,0.02-0.2,0.13-0.19,0.25l0.44,2.97
  						c0.02,0.12,0.13,0.2,0.25,0.19l5.67-0.84c0.12-0.02,0.2-0.13,0.19-0.25l-0.44-2.97C170.47,155.76,170.36,155.68,170.24,155.7z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              style={{fill: "#E3B59D"}}
              d="M149.32,165.92c0.58,1.23,0.21-4.05,0.79-0.51c0.78,4.8,1.27,10.45,1.5,12.47
  					c0.13,1.18,0.23,2.73,0.41,5.85c0.24,4.03,0.17,3.96,0.32,5.26c0.39,3.33,1.02,5.15,0.69,5.28c-0.18,0.07-0.63-0.34-1.86-2.62
  					c-0.74,0.34-1.49,0.69-2.23,1.03c1.1-2.32,2.39-3.27,3.4-3.7c0.34-0.14,0.26-0.06,2.12-0.6c0.49-0.14,0.96-0.28,3.79-1.16
  					c0.99-0.31,1.98-0.59,2.97-0.92c2.31-0.76,2.54-0.97,3.19-0.87c1.21,0.18,2.42,1.15,2.63,2.38c0.03,0.19,0.16,1.08-0.33,1.56
  					c-0.2,0.2-0.32,0.14-0.75,0.35c-0.71,0.35-0.82,0.72-1.39,1.1c-0.56,0.37-1.06,0.42-1.49,0.51c-1.15,0.23-1.52,0.63-4.36,2.04
  					c-1.94,0.96-2.91,1.44-4.05,1.81c-1.92,0.61-3.03,0.63-3.86,0.54c-0.79-0.09-1.76-0.21-2.46-0.96c-0.43-0.46-0.6-0.97-0.83-2.08
  					c-0.42-2-0.56-3.57-0.58-3.78c-0.14-1.55-0.13-0.67-0.66-4.89c-0.28-2.26-0.48-4.06-0.59-5.05c-0.25-2.34-0.52-5.22-0.48-5.22
  					c0.04,0,0.43,3.22,0.42,3.22c0,0-0.08-0.64-0.25-1.91c-0.13-0.97-0.33-2.49-0.65-4.93c-0.1-0.78-0.45-3.44-0.45-3.44
  					c0,0,0.34-4,1.66-4.38C146.98,162.02,148.54,164.29,149.32,165.92z"
            />
          </g>
        </g>
        <path
          style={{fill: "#4A9AD4"}}
          d="M142.62,170.17l9.67-1.47l-2.23-13.27c0,0-5.79-3.9-8.39,3.57L142.62,170.17z"
        />
      </g>
      <line
        style={{fill: "none", stroke: "#EA6669", strokeMiterlimit: "10"}}
        x1="307.02"
        y1="61.46"
        x2="307.02"
        y2="61.46"
      />
      <g>
        <ellipse
          style={{fill: "#FFFFFF", stroke: "#EA6669", strokeMiterlimit: "10"}}
          cx="171.62"
          cy="177.63"
          rx="6.41"
          ry="5.72"
        />
        <path
          style={{fill: "none", stroke: "#EA6669", strokeMiterlimit: "10"}}
          d="M169.76,172.16c0,4.59-1.66,8.31-3.71,8.31"
        />
        <path
          style={{fill: "none", stroke: "#EA6669", strokeMiterlimit: "10"}}
          d="M176.35,173.72c-2.37,4.07-2.97,7.98-1.34,8.74"
        />
      </g>
      <path
        style={{fill: "#E3B59D"}}
        d="M167.34,183.92c0.16-0.74,0.64-2.47,1.86-3.59c0,0,0.48-0.58,1.55-0.97
  		c1.06-0.39,1.85,0.95,1.14,2.12c0,0-0.26,0.47,0.02,0.52C171.91,181.99,172.33,183.13,167.34,183.92z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M165.27,184.66c-2.91,1.07-5.82,2.14-8.73,3.21l1.46,5.48c0.41-0.15,0.83-0.3,1.24-0.46
  		c2.85-1.05,5.7-2.1,8.56-3.14c0.92-0.09,2.58,0.33,5.24-0.58c2.85-0.97,3.94-2.33,4.79-4.29c1.14-2.61-1.2-4.09-1.2-4.09
  		c-2.22-0.78-4.14,1.27-4.51,1.84c0,0-2.84-1.06-3.78-0.16"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M201.79,166.73c0,0-0.15,0.59,0.11,0.66c0.27,0.07,1.07-0.26,1.32-1.26
  		c0.16-0.66,1.34-3.22,3.74-3.23c0,0,2.75,0.63,2.52,3.46c-0.17,2.13-0.76,3.78-3.2,5.63c-2.27,1.73-4.01,1.9-4.87,2.28
  		c-2.4,1.93-4.81,3.87-7.21,5.8c-0.35,0.28-0.7,0.56-1.05,0.84l-3.23-4.68c2.45-1.97,4.91-3.95,7.36-5.92
  		c0.25-0.2,0.51-0.41,0.76-0.61c0.01-0.94,0.15-3.12,1.06-4.51c0,0,0.34-0.72,1.25-1.19C201.27,163.55,202.24,165.26,201.79,166.73z
  		"
      />
      <path
        style={{fill: "#609E92", stroke: "#609E92", strokeWidth: "0.51", strokeMiterlimit: "10"}}
        d="M168.02,111.11
  		c0.78,1.13-4.57,7.93-12.17,11.51c-4.59,2.16-10.08,3.2-10.56,2.16c-0.64-1.39,7.83-6.41,10.67-8.1
  		C160.3,114.12,167.25,109.99,168.02,111.11z"
      />
      <path
        style={{fill: "#5DBDAF"}}
        d="M168.41,111.42c0.84,1.95-13.69,9.5-14.9,10.13c-3.52,1.82-6.35,3.26-6.89,2.58
  		c-0.74-0.92,2.8-5.66,7.32-8.75C160.04,111.2,167.83,110.1,168.41,111.42z"
      />
      <path
        style={{fill: "#5DBDAF"}}
        d="M134.62,132.16c-3.93-5.33-1.65-13.1,5.08-17.36c6.73-4.26,15.38-3.39,19.3,1.94"
      />
    </g>
  </SuperheroSvg>
)

export default Character22
