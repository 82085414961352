import {FormControl, InputLabel, ListItemText, MenuItem} from "@mui/material"
import {func, object, string} from "prop-types"

import DOSelect from "components/do-select/do-select"

import {audienceInputs} from "./journey-audience-inputs"

const JourneyAudienceInput = ({classes, onChange, value}) => {
  return (
    <FormControl className={classes.campaignAudience}>
      <InputLabel htmlFor="campaignAudience" shrink={true}>
        Audience
      </InputLabel>
      <DOSelect
        id="campaignAudience"
        displayEmpty={true}
        onChange={onChange}
        renderValue={value => value}
        value={value}
      >
        {audienceInputs.map(({description, label, value}) => (
          <MenuItem classes={{root: classes.menuItem}} key={value} value={value}>
            <ListItemText
              classes={{secondary: classes.listItemTextSecondary}}
              primary={label}
              secondary={description}
            />
          </MenuItem>
        ))}
      </DOSelect>
    </FormControl>
  )
}

JourneyAudienceInput.propTypes = {
  classes: object.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
}

export default JourneyAudienceInput
