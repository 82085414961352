import {Socket} from "phoenix"
import {node} from "prop-types"
import {createContext, useCallback, useContext, useEffect, useReducer} from "react"
import {useSelector} from "react-redux"
import shortid from "shortid"

import {getAccessToken} from "lib/access-token"
import refreshAccessToken from "lib/refresh-token"
import storage from "lib/storage"
import wrapDisplayName from "lib/wrap-display-name"

import socketManagerReducer from "./socket-manager-reducer"

export const SocketManagerContext = createContext()
SocketManagerContext.displayName = "SocketManagerContext"

export const createHandler = (channelName, event, cb) => {
  const id = shortid.generate()

  return {
    id,
    channelName,
    event,
    callback: data => {
      cb(data, id)
    },
  }
}

const SET_SOCKET = "SET_SOCKET"
const SET_TEAM_ID = "SET_TEAM_ID"
const ADD_HANDLER = "ADD_HANDLER"
const REMOVE_HANDLER = "REMOVE_HANDLER"

const setTeamId = teamId => ({type: SET_TEAM_ID, payload: {teamId}})
const setSocket = socket => ({type: SET_SOCKET, payload: {socket}})
const addHandler = payload => ({type: ADD_HANDLER, payload})
const removeHandler = payload => ({type: REMOVE_HANDLER, payload})

const SocketManager = ({children}) => {
  // session will be unset if we're on the login page
  const _teamId = useSelector(state => state?.session?.team?.id)

  const [{socket, teamId}, dispatch] = useReducer(socketManagerReducer, {
    socket: null,
    pendingChannels: [],
    channels: [],
  })

  const {accessToken, refreshToken, location} = getAccessToken(storage)

  const refreshOnError = useCallback(() => {
    if (!!refreshToken) {
      refreshAccessToken(refreshToken)
    }
  }, [refreshToken])

  useEffect(() => {
    if (!teamId) return
    if (location === "admin" && !accessToken) return

    const nextSocket = new Socket(`${process.env.REACT_APP_WS_URL}/${location}-socket`, {
      params: {token: accessToken},
    })

    nextSocket.onError(() => refreshOnError())

    dispatch(setSocket(nextSocket))
    return () => {
      nextSocket.disconnect()
    }
  }, [teamId, accessToken, refreshOnError, location])

  useEffect(() => {
    dispatch(setTeamId(_teamId))
  }, [_teamId])

  const dispatchHander = (fn, handler) => dispatch(fn(handler))

  const dispatchAddHandler = useCallback(
    handler => {
      if (socket && !socket.isConnected()) socket.connect()

      dispatchHander(addHandler, handler)
    },
    [socket]
  )
  const dispatchRemoveHandler = useCallback(handler => dispatchHander(removeHandler, handler), [])

  return (
    <SocketManagerContext.Provider
      value={{
        addHandler: dispatchAddHandler,
        removeHandler: dispatchRemoveHandler,
        socket,
      }}
    >
      {children}
    </SocketManagerContext.Provider>
  )
}

SocketManager.propTypes = {
  children: node,
}

export const useSocket = () => useContext(SocketManagerContext)

export const socketify = Component => {
  wrapDisplayName(Component, "socketify")
  return props => <Component {...props} {...useSocket()} />
}

export default SocketManager
