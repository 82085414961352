import {TextField} from "@mui/material"
import {bool, func, object, shape, string} from "prop-types"

import IconTooltip from "components/icon-tooltip/icon-tooltip"
import {conditionValidator} from "components/template-targeting/targeting-condition-helpers"
import {availableOperators} from "components/template-targeting/template-targeting-helpers"

const TargetingConditionTextField = ({
  classes,
  disabled = false,
  getFullOperator,
  name,
  onChange,
  targetingCondition,
  value,
}) => {
  const {operator} = targetingCondition
  const fullOperator = availableOperators.find(getFullOperator)
  return (
    <>
      <TextField
        disabled={disabled}
        error={!conditionValidator(operator, value)}
        helperText={`${!value ? "required, " : ""}can be a ${fullOperator.availableTypes}`}
        inputProps={{"data-testid": "value-input"}}
        label="Value"
        name={name}
        onChange={onChange}
        required={true}
        value={value}
      />
      <IconTooltip
        iconClassName={classes.tooltip}
        style={{paddingTop: "17px"}}
        title={fullOperator.helpText}
      />
    </>
  )
}

TargetingConditionTextField.propTypes = {
  classes: object,
  disabled: bool,
  getFullOperator: func,
  name: string,
  onChange: func,
  targetingCondition: shape({
    id: string,
    model: string,
    field: string,
    meta: object,
    meta_sub_key: string,
    operator: string,
    value: string,
  }),
  value: string,
}

export default TargetingConditionTextField
