import {Button, ButtonGroup, TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import orderBy from "lodash/orderBy"
import pick from "lodash/pick"
import {func, number, object, string} from "prop-types"
import {useEffect, useMemo, useState} from "react"
import {BiNetworkChart as SharedIcon} from "react-icons/bi"
import {BsDashLg as DashIcon} from "react-icons/bs"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import {TemplateListPreview} from "components/dialogs/template-list-preview"
import Expandable from "components/expandable/expandable"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"
import {Metrics} from "components/template-insights/insights-helpers"

import {fetchAdvancedInsightsPages} from "lib/api"
import pluralize from "lib/string/pluralize"

const namespace = "advanced-team-insights-pages"

const sharedHeaders = [
  {
    field: "name",
    label: "Page Name",
    tooltipText: "Page Name: internal page title linked to page’s content library URL",
  },
  {
    field: "objective",
    label: "Objective",
    tooltipText: "Objective: name of objective",
  },
]
const totalHeaders = [
  {
    field: "views",
    label: "Page Views",
    tooltipText: "Page View: total number of unique page views",
  },
  {
    field: "clicks",
    label: "Page Clicks",
    tooltipText: "Page Clicks: total number of unique CTA clicks on the page",
  },
  {
    field: "widgetUsage",
    label: "Widget Usage",
    tooltipText: "Widget Usage: total number of journeys that interacted with a widget on the page",
  },
  {
    field: "objectiveCompletions",
    label: "Objective Completions",
    tooltipText: "Objective Completions: total number of objective completions",
  },
]
const rateHeaders = [
  {
    field: "viewRate",
    label: "View Rate",
    tooltipText:
      "Page View Rate: total number of unique page views / total number of journeys presenting the page",
  },
  {
    field: "ctr",
    label: "Page CTR",
    tooltipText: "Page CTR: total number of page CTA clicks / total number of page views",
  },
  {
    field: "widgetUsageRate",
    label: "Widget Usage Rate",
    tooltipText:
      "Widget Usage Rate: total number of journeys that interacted with a widget on the page / total number of journeys that viewed the page",
  },
]

// NB: We need to sort the row object in accordance to the header's index per field to get the corresponding data for each column.
const fields = [...sharedHeaders, ...rateHeaders, ...totalHeaders].map(header => header.field)
const sortByIndex = ([a], [b]) =>
  fields.findIndex(field => field === a) - fields.findIndex(field => field === b)

// NB: Swaps the current (total|rate) column with its counterpart to keep the user's current sort column.
// ** Warning: This depends on totals and rates having the same number of columns and their indexes aligning.
const swapColumns = (metric, field) => {
  const _rateHeaders = [...sharedHeaders, ...rateHeaders]
  const _totalHeaders = [...sharedHeaders, ...totalHeaders]

  if (metric === Metrics.Rate) {
    const index = _totalHeaders.findIndex(h => h.field === field)
    return _rateHeaders?.[index]?.field ?? "name"
  } else {
    const index = _rateHeaders.findIndex(h => h.field === field)
    return _totalHeaders?.[index]?.field ?? "name"
  }
}

// NB: We are using 0 as a way to vertically center align the DashIcon with the rest of the table's fontSize.
const Dash = () => {
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        position: "relative",
        alignItems: "center",
      }}
    >
      <span style={{visibility: "hidden"}}>0</span>
      <DashIcon color="#000000" size={20} style={{position: "absolute"}} />
    </div>
  )
}

// NB: We are wrapping SharedIcon in a div with an explicitly set width and height to prevent icon shrinkage.
const Shared = ({pageId, pageSlug}) => {
  const [isOpen, setIsOpen] = useState(false)

  const message = (isLoaded, totalTemplates) =>
    isLoaded && totalTemplates > 0
      ? `List of ${pluralize("campaign", totalTemplates)} the page is used in:`
      : "The page is not used in any campaigns."

  return (
    <Tooltip
      interactive="true"
      leaveDelay={750}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      title={
        <TemplateListPreview
          id={pageId}
          message={message}
          newSlug={pageSlug}
          recordType="page"
          target="_blank"
          variant="body2"
        />
      }
    >
      <div style={{display: "inline-block", width: 20, height: 20, paddingLeft: 10}}>
        <SharedIcon data-testid="shared-icon" size={20} />
      </div>
    </Tooltip>
  )
}

Shared.propTypes = {
  pageId: string.isRequired,
  pageSlug: string.isRequired,
}

const Pages = ({classes, setTableState, ...props}) => {
  const {fetchInsights, filterParams, hasSocket, pages, pagesIsLoading} = useAdvancedTeamInsights()

  const [headers, setHeaders] = useState([...sharedHeaders, ...totalHeaders])
  const [metric, setMetric] = useState(Metrics.Total)

  const data = useMemo(
    () => (metric === Metrics.Total ? pages?.data?.totals ?? [] : pages?.data?.rates ?? []),
    [metric, pages]
  )

  const onClickMetric = ({currentTarget}) => {
    setHeaders(
      currentTarget.value === Metrics.Total
        ? [...sharedHeaders, ...totalHeaders]
        : [...sharedHeaders, ...rateHeaders]
    )
    setMetric(currentTarget.value)
  }

  const renderCell = (key, {value, ...cellProps}, metric) => {
    if (key === "name") {
      // NB: We send users to ../content-library for pages still using do-editor because it does not have a direct link to its content-library content.
      // Do-editor will be sunset at some point, it's not worth the hassle to get the linkage working for a legacy editor.
      const {pageId, isShared} = cellProps
      const url = `/admin/templates/content-library/pages/${pageId}`
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Typography
            color="inherit"
            component="a"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            style={{fontSize: 14}}
          >
            {value}
          </Typography>{" "}
          {isShared && <Shared {...cellProps} />}
        </div>
      )
    }

    if (isNaN(value)) return value

    if (metric === Metrics.Rate) return value !== 0 ? `${value}%` : <Dash />
    if (metric === Metrics.Total) return value !== 0 ? value : <Dash />
  }

  const refresh = attrs => {
    const currentItemsPerPage = attrs?.itemsPerPage ?? props.itemsPerPage
    const currentPage = attrs?.page ?? props.page
    const offset = currentPage * currentItemsPerPage
    const sortColumn = attrs?.sortColumn ?? props.sortColumn
    const sortDirection = attrs?.sortDirection ?? props.sortDirection

    const rows = orderBy(
      data,
      [
        item => {
          const column = item[sortColumn].value

          return isNaN(column) ? column.toLowerCase() : column
        },
      ],
      [sortDirection]
    ).filter((item, i) => i >= offset && i < offset + currentItemsPerPage)

    setTableState({
      ...pick(props, "itemsPerPage", "page", "sortColumn", "sortDirection"),
      ...attrs,
      rows,
      page: currentPage,
      totalCount: data.length ?? 0,
    })
  }

  useEffect(() => {
    if (hasSocket) fetchInsights("pages", fetchAdvancedInsightsPages, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  useEffect(() => {
    // NB: This is needed so that our rows are effected by our default state and metric changes.
    // This should get called in the following scenarios:
    // 1. fetchInsights or our socket returns new data
    // 2. The user switches between totals and rates
    refresh({page: 0, sortColumn: swapColumns(metric, props.sortColumn)})

    // FIXME ignoring react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div>
      <Typography variant="h5">Pages</Typography>
      <div className={classes.expandableContainer} style={{paddingTop: 16}}>
        <Expandable label="How we use this table">
          <p>The page performance table reports on all pages and their key performance metrics.</p>

          <Typography variant="h6">At a Glance</Typography>

          <p>
            Use the sorting feature to compare and contrast pages by each metric. With the switch at
            the top right of the table, change the view from <b>TOTAL</b> to <b>%</b> to view a
            percentage rate instead of a total count. Click the page name link to view the page in
            the content editor. Messages with a shared icon are used in multiple campaigns.
          </p>

          <Typography variant="h6">Use of Page Filters</Typography>
          <ul>
            <li>
              <b>Dates</b>: Focus engagement by specific time frames to see how content engagement
              changes over time.
            </li>
            <li>
              <b>Objectives</b>: Filter by objective to focus on a specific subset.
            </li>
            <li>
              <b>Status</b>: Focus the view by live or archived campaign status to compare, or to
              view a specific campaign category.
            </li>
          </ul>
        </Expandable>
      </div>
      <div className={classes.buttonGroupsContainer} style={{marginBottom: 16}}>
        <ButtonGroup size="small" variant="outlined">
          <Button
            color={Metrics.Total === metric ? "primary" : "grey"}
            onClick={onClickMetric}
            tabIndex={0}
            value={Metrics.Total}
          >
            Total
          </Button>
          <Button
            color={Metrics.Rate === metric ? "primary" : "grey"}
            onClick={onClickMetric}
            tabIndex={1}
            value={Metrics.Rate}
          >
            %
          </Button>
        </ButtonGroup>
      </div>
      <DOTable
        headers={headers}
        isTableLoading={pagesIsLoading}
        noResults="No data found."
        paginationEnabled={true}
        refresh={refresh}
      >
        {(row, index) => {
          return (
            <TableRow key={`${namespace}-${index}`}>
              {Object.entries(row)
                .sort(sortByIndex)
                .map(([key, value], i) => (
                  <TableCell key={`${namespace}-${key}-${index}-${i}`}>
                    {renderCell(key, value, metric)}
                  </TableCell>
                ))}
            </TableRow>
          )
        }}
      </DOTable>
    </div>
  )
}

Pages.propTypes = {
  classes: object,
  itemsPerPage: number,
  page: number,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
}

export default tabular({sortColumn: "name", sortDirection: "asc"})(Pages)
