import Typography from "@mui/material/Typography"
import {node} from "prop-types"

const Paragraph = ({children}) => (
  <Typography className="paragraph" component="div">
    {children}
  </Typography>
)

Paragraph.propTypes = {
  children: node,
}

export default Paragraph
