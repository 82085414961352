import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useEffect, useState} from "react"

import {useInsights} from "components/template-insights/insights-context"

import {fetchInsightObjectiveDonut} from "lib/api"

import {INSIGHTS_COLORS} from "./insights-helpers"
import StatsPie from "./stats-pie"

const colors = [...INSIGHTS_COLORS].reverse()

const ObjectiveMetrics = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const classes = useStyles()

  const {
    insights: {objective_donut},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const objectives = objective_donut?.value.result || []

  useEffect(() => {
    fetchInsightObjectiveDonut(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return <>Loading...</>

  if (!objectives.length)
    return (
      <>
        <Typography className={classes.header} variant="h5" style={{marginTop: 0}}>
          Completed Objectives
        </Typography>
        <div>No objectives are set for this campaign.</div>
      </>
    )

  const totalCompleted = objectives
    .map(objective => objective.value)
    .reduce((cur, acc) => acc + cur, 0)

  return (
    <>
      <Typography className={classes.header} style={{marginTop: 0}} variant="h5">
        Completed Objectives
      </Typography>
      <StatsPie
        colors={colors}
        data={objectives}
        total={totalCompleted}
        totalLabel="Objectives"
        totalLabel2="Completed"
      />
    </>
  )
}

const useStyles = makeStyles(() => ({
  chartWrapper: {
    margin: "0 auto",
  },
  header: {
    fontSize: 25,
    fontWeight: 200,
    marginTop: 80,
  },
  completedCount: {
    fontSize: 24,
    fontWeight: "bold",
  },
}))

export default ObjectiveMetrics
