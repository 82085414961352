import {FormControl, FormHelperText, InputLabel, TextField} from "@mui/material"
import {bool, func, number, oneOf, oneOfType, string} from "prop-types"

const styles = (theme, labelStyle) => {
  const base = {
    margin: `${theme.spacing(1.25)} 0`,
    "& > div": {"& > .MuiFormControl-root": {width: "80px"}, marginTop: theme.spacing(0.25)},
    "& .MuiInputBase-input": {
      textAlign: "right",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        marginLeft: theme.spacing(),
      },
    },
    "& .MuiTextField-root": {marginTop: theme.spacing(2.5)},
    "& .MuiTextField-root:nth-of-type(2)": {marginLeft: theme.spacing(2)},
  }

  return labelStyle === "bold"
    ? {...base, "& > .MuiFormLabel-root": {fontSize: 18, fontWeight: "bold"}}
    : base
}

export const getDays = value => Math.floor(parseInt(value || "0", 10) / 24)
export const getHours = value => parseInt(value || "0", 10) % 24

const JourneyDurationInput = ({disabled, error, helperText, labelStyle, name, onChange, value}) => {
  const triggerChange = (days, hours) => {
    // Limit user input to positive values
    const nextValue = Math.abs(days * 24 + hours)

    onChange({target: {type: "number", name, value: nextValue}})
  }

  return (
    <FormControl fullWidth={true} sx={theme => styles(theme, labelStyle)}>
      <InputLabel htmlFor="journey_duration_days" shrink={true}>
        Journey Duration
      </InputLabel>
      <div>
        <TextField
          error={error}
          margin="none"
          disabled={disabled}
          id="journey_duration_days"
          InputProps={{inputProps: {min: 0}}}
          label="Days"
          type="number"
          onChange={event => triggerChange(parseInt(event.target.value, 10), getHours(value))}
          value={getDays(value)}
        />
        <TextField
          error={error}
          margin="none"
          disabled={disabled}
          label="Hours"
          type="number"
          onChange={event => triggerChange(getDays(value), parseInt(event.target.value, 10))}
          value={getHours(value)}
        />
      </div>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

JourneyDurationInput.defaultProps = {labelStyle: "normal"}

JourneyDurationInput.propTypes = {
  disabled: bool,
  error: bool,
  helperText: string,
  labelStyle: oneOf(["bold", "normal"]),
  name: string.isRequired,
  onChange: func.isRequired,
  value: oneOfType([string, number]).isRequired,
}

export default JourneyDurationInput
