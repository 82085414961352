import {TextField, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"

import {requiredField} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

import {controlAccess} from "../access-control/access-controlled"
import SaveButton from "../save-button/save-button"

const initialValues = {publicKey: "", title: ""}

export const CreateSshKey = ({classes, onSubmit, disabled}) => {
  const {submitting, invalid, field, handleSubmit, resetForm} = useForm({
    onSubmit: i => onSubmit(i).then(resetForm),
    initialValues,
    validators: {
      publicKey: [requiredField],
      title: [requiredField],
    },
  })

  return (
    <form aria-labelledby="add-ssh-key" onSubmit={handleSubmit}>
      <Typography id="add-ssh-key">Add new SSH public key</Typography>
      <TextField
        className={classes.field}
        disabled={disabled}
        fullWidth={true}
        helperText="Paste your public SSH key."
        id="ssh-key"
        InputLabelProps={{shrink: true}}
        label="Key"
        multiline={true}
        rows="10"
        variant="outlined"
        {...field("publicKey")}
      />
      <TextField
        className={classes.field}
        disabled={disabled}
        fullWidth={true}
        id="ssh-key-title"
        label="Title"
        {...field("title")}
      />
      <div className={classes.formActions}>
        <SaveButton
          disabled={disabled || invalid}
          stateLabels={{default: "Add SSH public key"}}
          submitting={submitting}
        />
      </div>
    </form>
  )
}

CreateSshKey.propTypes = {
  classes: object,
  disabled: bool,
  onSubmit: func.isRequired,
}

const styles = {
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
}

export default withStyles(styles)(
  controlAccess({requiredPermissions: "team_settings:sftp"})(CreateSshKey)
)
