import {availableOperators} from "components/template-targeting/template-targeting-helpers"

const humanizeField = field => {
  switch (field) {
    case "name_first":
      return "First Name"
    case "name_last":
      return "Last Name"
    default:
      return field
        .replace(/_/g, " ")
        .replace(/\./g, " ")
        .replace(/\b\w/g, char => char.toUpperCase())
  }
}

const summarizeOperatorAndValue = condition => {
  const {field, operator} = condition

  if (field === "enrollment" && operator === "is_link_clicked") {
    return "has clicked enrollment terms"
  }

  if (field === "mobile_app_download" && operator === "is_link_clicked") {
    return "has clicked mobile app download link"
  }

  return `${humanizeOperator(operator)} ${humanizeValue(condition)}`
}

const humanizeOperator = operator => {
  return availableOperators.find(op => op.value === operator)?.name ?? operator
}

const humanizeValue = condition => {
  const {field, value} = condition
  if (field === "rewards") {
  }

  if (field === "rewards") return ""
  if (valueIsCampaign(condition)) return ""
  if (valueIsInteger(value)) return ` → ${value}`
  if (value === null || value === "") return ""

  return ` → "${value}"`
}

const valueIsCampaign = condition => condition.model === "journey" && condition.field !== "rewards"

const valueIsInteger = value => {
  const parsedValue = parseInt(value, 10)
  return !isNaN(parsedValue) && parsedValue.toString() === value
}

const hasMetaSubKey = condition =>
  ["meta_public", "meta_private", "enrollment"].includes(condition.field)

const hasMetadata = condition =>
  [
    "encountered_failure",
    "has_placed_card",
    "has_failed_to_place_card",
    "is_completed_direct_deposit_employer",
    "is_failed_direct_deposit_employer",
    "is_direct_deposit_distribution_type",
    "is_failed_direct_deposit_reason",
    "has_earned_reward",
  ].includes(condition.operator) || ["survey"].includes(condition.field)

const summarizeMeta = (team, condition) => {
  if (!hasMetadata(condition)) return ""

  const metaSummary = Object.entries(condition.meta)
    .map(([key, value]) => summarizeMetaItem(team, condition, key, value))
    .filter(item => item !== null)
    .join(", ")

  return metaSummary ? `(${metaSummary})` : ""
}

const summarizeMetaItem = (team, condition, key, value) => {
  if (key === "rewardSetId" && value) {
    const rewardSet = team.rewardSets.find(rewardSet => rewardSet.id === value)
    return `RewardSet = "${rewardSet.name}"`
  }

  if (key === "rewardStepId" && value) {
    const rewardStep = team.rewardSets.find(rewardSet =>
      rewardSet.rewardSteps.find(rewardStep => rewardStep.id === value)
    )
    return `RewardStep = ${rewardStep.condition} (value: ${rewardStep.value})`
  }

  if (condition.field === "survey" && ["answers", "type"].includes(key)) {
    return null
  }

  if (
    condition.field === "survey" &&
    ["is_started", "is_completed", "is_abandoned"].includes(condition.operator) &&
    key === "question_title"
  ) {
    return null
  }

  if (value === null) {
    return null
  }

  return `${key} = ${JSON.stringify(value)}`
}

const summarizeCampaign = (team, condition) => {
  if (!valueIsCampaign(condition)) return ""
  if (condition.value === "any") return "in any campaign"
  const campaign = team.templates.find(template => template.id === condition.value)
  return `in campaign "${campaign.name}"`
}

export const summarizeCondition = (team, condition) => {
  return `
  ${humanizeField(condition.field)}
  ${hasMetaSubKey(condition) ? `→ "${condition.metaSubKey}"` : ""}
  → ${summarizeOperatorAndValue(condition)}
  ${summarizeCampaign(team, condition)}
  ${summarizeMeta(team, condition)}
`
}
