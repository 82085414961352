import {object} from "prop-types"

import ContentContainerReadonly from "components/cross-channel-engagement/content-container-readonly"
import PreviewContext from "components/templates/preview-context"

const styleOverrides = `.content-container-content {
    height: 100vh;
    overflow: hidden;
    margin-bottom: 0;
}`

const ContentContainerPreview = ({classes, contentContainer}) => {
  return (
    contentContainer && (
      <PreviewContext.Provider value={{isPreviewMode: true}}>
        <style>{styleOverrides}</style>
        <ContentContainerReadonly classes={classes} contentContainer={contentContainer} />
      </PreviewContext.Provider>
    )
  )
}

ContentContainerPreview.propTypes = {
  classes: object.isRequired,
  contentContainer: object,
}

export default ContentContainerPreview
