import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import debounce from "lodash/debounce"
import {array, bool, func, object, string} from "prop-types"
import {useCallback, useEffect, useState} from "react"
import {MdCheckCircle as CheckIcon, MdNotInterested as NotFoundIcon} from "react-icons/md"
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"

import {fetchContactAccountByNumber, fetchContacts} from "lib/api"
import {requiredField, validEmail, validPhone} from "lib/field-validations"
import {formify} from "lib/hooks/use-form"
import MaybeTooltip from "lib/maybe-tooltip"
import {fullName} from "lib/names"
import humanize from "lib/string/humanize"

import {useTemplateContentContext} from "../../contexts/template-content-context"
import Box from "../box/box"
import DOSelect from "../do-select/do-select"
import DocumentTitle from "../document-title/document-title"
import DripPreview from "../drip-preview/drip-preview"
import Padded from "../padded/padded"
import SaveButton from "../save-button/save-button"
import SuperheroLeaningOut from "../superhero/leaning-out"
import TitleBar from "../title-bar/title-bar"

export const CreateJourney = ({
  change,
  classes,
  disabled,
  field,
  handleSubmit,
  invalid,
  journeyError,
  submitting,
  users,
}) => {
  const [account, setAccount] = useState(null)
  const [accountNumber, setAccountNumber] = useState("")
  const [contact, setContact] = useState(null)
  const [isAccountLoading, setIsAccountLoading] = useState(false)
  const [isContactLoading, setIsContactLoading] = useState(false)
  const [uniqueId, setUniqueId] = useState("")

  const {
    template,
    hasNurturingMessagesAndNoTimelineMarkers,
    fetchTemplateValidity,
    timelineMarkers,
  } = useTemplateContentContext()

  const hasContactViewPermission = useSelector(({session}) =>
    session.user.permissions.includes("contacts:view")
  )

  useEffect(() => {
    fetchTemplateValidity()
  }, [fetchTemplateValidity])

  const fetchAndSetContact = useCallback(async id => {
    if (!id) {
      setContact(null)
      return
    }

    setIsContactLoading(true)
    fetchContacts({uniqueId: id, limit: 1})
      .then(nextContacts => {
        const nextContact = nextContacts[0]

        if (!nextContact) {
          setContact(null)
          return
        }
        setContact(nextContact)
      })
      .finally(() => {
        setIsContactLoading(false)
      })
  }, [])

  const fetchAndSetAccount = useCallback(async (number, _contact) => {
    if (!_contact || number === "") {
      setAccount(null)
      return
    }

    const {id: contactId} = _contact

    fetchContactAccountByNumber(contactId, number)
      .then(({account: nextAccount}) => {
        setAccount(nextAccount) // Account exists, as verified by request to validate
      })
      .catch(error => {
        if (error.status === 404) {
          setAccount(false) // Account doesn't exist, as verified by request to validate
        } else {
          throw error
        }
      })
      .finally(() => {
        setIsAccountLoading(false)
      })
  }, [])

  // FIXME ignoring react-hooks/exhaustive-deps
  /* eslint-disable react-hooks/exhaustive-deps */
  const _fetchContacts = useCallback(debounce(fetchAndSetContact, 1000), [])
  const _fetchAccount = useCallback(debounce(fetchAndSetAccount, 1000), [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const onChangeUniqueId = ({target: {value}}) => {
    change("uniqueId", value)
    setUniqueId(value)
  }

  const onChangeAccountNumber = ({target: {value}}) => {
    change("accountNumber", value)
    if (value !== "") setIsAccountLoading(true)
    setAccountNumber(value)
  }

  useEffect(() => {
    if (hasContactViewPermission) _fetchContacts(uniqueId)
  }, [_fetchContacts, hasContactViewPermission, uniqueId])

  useEffect(() => {
    _fetchAccount(accountNumber, contact)
  }, [_fetchAccount, hasContactViewPermission, accountNumber, contact])

  useEffect(
    () => () => {
      _fetchContacts.cancel()
      _fetchAccount.cancel()
    },
    [_fetchContacts, _fetchAccount]
  )

  const accountFieldEndAdornment = () => {
    if (!contact) return null
    if (accountNumber === "") return null

    if (isAccountLoading) return <CircularProgress size={18} />

    if (!account)
      return (
        <Tooltip title="Account number not found for this contact">
          <InputAdornment position="end">
            <NotFoundIcon className={classes.errorIcon} />
          </InputAdornment>
        </Tooltip>
      )

    return (
      <Tooltip
        title={`${humanize(account?.product?.type)} account (${
          account?.product?.code
        }) found for this contact with this number`}
      >
        <InputAdornment position="end">
          <CheckIcon className={classes.successIcon} />
        </InputAdornment>
      </Tooltip>
    )
  }

  return (
    <div data-testid="create-journey">
      <DocumentTitle title="Create a Journey" />
      <TitleBar
        backButtonAttrs={{to: "/admin/templates", children: "Return to Campaigns Dashboard"}}
        title="Create a Journey..."
      />

      <Padded className={classes.padded}>
        <SuperheroLeaningOut className={classes.superhero} />
        <Box className={classes.box}>
          <Typography>
            Creating a journey in the <strong>{template.name}</strong> campaign...
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className={classes.columns}>
              <div className={classes.column}>
                <TextField
                  autoComplete="off"
                  autoFocus={true}
                  className={classes.field}
                  fullWidth={true}
                  label="Unique ID"
                  {...field("uniqueId")}
                  InputProps={{
                    endAdornment: isContactLoading && <CircularProgress size={18} />,
                  }}
                  onChange={onChangeUniqueId}
                  value={uniqueId}
                />
                <Typography variant="caption" sx={{color: "text.hint"}}>
                  You should supply a unique ID for cross-referencing contacts in your system. If
                  you've already added a contact in Digital Onboarding with that ID, we will use
                  that contact for this Journey. If no unique ID is entered, we'll attempt to use
                  the email address as an ID for you.
                </Typography>
                <MaybeTooltip
                  isTooltip={!contact}
                  title="Provide a Unique ID above to look up a contact. An existing contact and accounts are required to associate an account number with a journey."
                >
                  <TextField
                    autoComplete="off"
                    className={classes.field}
                    fullWidth={true}
                    helperText="If you want to use account information in personalizations or targeting, you can provide an account number to associate with this journey. It has to exist in Digital Onboarding and belong to this contact."
                    InputProps={{
                      endAdornment: accountFieldEndAdornment(),
                    }}
                    disabled={!contact}
                    label="Account Number"
                    onChange={onChangeAccountNumber}
                    value={accountNumber}
                  />
                </MaybeTooltip>
              </div>
              <div className={classes.column}>
                <TextField
                  autoComplete="off"
                  className={classes.field}
                  fullWidth={true}
                  {...(!!contact ? {InputLabelProps: {shrink: true}} : {})}
                  label="First Name"
                  placeholder={contact ? contact.nameFirst : ""}
                  {...field("nameFirst")}
                />
                <TextField
                  autoComplete="off"
                  className={classes.field}
                  fullWidth={true}
                  {...(!!contact ? {InputLabelProps: {shrink: true}} : {})}
                  label="Last Name"
                  placeholder={contact ? contact.nameLast : ""}
                  {...field("nameLast")}
                />
                <TextField
                  className={classes.field}
                  fullWidth={true}
                  {...(!!contact ? {InputLabelProps: {shrink: true}} : {})}
                  label="Email"
                  placeholder={contact ? contact.email : ""}
                  {...field("email")}
                />
                <TextField
                  className={classes.field}
                  fullWidth={true}
                  {...(!!contact ? {InputLabelProps: {shrink: true}} : {})}
                  label="Mobile Phone"
                  placeholder={contact ? contact.phoneMobile : ""}
                  {...field("phoneMobile")}
                />
                <FormControl className={classes.field} fullWidth={true}>
                  <InputLabel htmlFor="ownerId">Support Owner</InputLabel>
                  <DOSelect id="ownerId" {...field("ownerId", {exclude: ["helperText"]})}>
                    {users.map(user => (
                      <MenuItem key={user.id} value={user.id}>
                        {fullName(user)}
                      </MenuItem>
                    ))}
                  </DOSelect>
                </FormControl>
                {!!hasNurturingMessagesAndNoTimelineMarkers && (
                  <>
                    <Typography
                      className={cx("errorText", classes.errorText)}
                      gutterBottom={true}
                      variant="caption"
                    >
                      There are no message pins in this template's message scheduler; if you
                      continue, only scheduled messages will be sent for this journey.
                    </Typography>
                    <Button
                      color="primary"
                      component={Link}
                      to={`/admin/templates/${template.id}/edit`}
                      variant="contained"
                    >
                      Go to Template Editor
                    </Button>
                  </>
                )}
                <div className={classes.formActions}>
                  <SaveButton
                    disabled={disabled || invalid}
                    failed={invalid}
                    stateLabels={{
                      submitting: "Creating...",
                      saved: "Created!",
                      default: "Create",
                    }}
                    submitting={submitting}
                  />
                </div>
              </div>
            </div>
          </form>
        </Box>
        <DripPreview timelineMarkers={timelineMarkers} />
      </Padded>
    </div>
  )
}

CreateJourney.propTypes = {
  change: func.isRequired,
  classes: object.isRequired,
  disabled: bool,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  invalid: bool.isRequired,
  journeyError: string,
  submitting: bool,
  users: array.isRequired,
}

const styles = theme => ({
  box: {
    position: "relative",
    zIndex: 20,
  },
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
  },
  padded: {
    maxWidth: 800,
    margin: "0 auto",
    position: "relative",
  },
  columns: {
    display: "flex",
  },
  column: {
    flex: 1,
    "& + $column": {
      marginLeft: theme.spacing(3),
    },
  },
  errorText: {
    marginTop: theme.spacing(2),
    display: "block",
    color: theme.palette.error.main,
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  superhero: {
    width: 300,
    position: "absolute",
    right: -268,
    zIndex: 20,
  },
})

const StyledCreateJourney = withStyles(styles)(CreateJourney)

export default formify({
  validators: {
    email: [validEmail],
    ownerId: [requiredField],
    phoneMobile: [validPhone],
  },
})(StyledCreateJourney)
