import {TableCell, TableRow, Typography} from "@mui/material"
import {func, object, shape, string} from "prop-types"
import {Component} from "react"
import {MdDelete as DeleteIcon} from "react-icons/md"

import {formatDateTime} from "lib/date-time-formatters"

import AccessControlled from "../access-control/access-controlled"
import DangerButton from "../danger-button/danger-button"

class ClientCredentialRow extends Component {
  onRevoke = () => this.props.onRevokeClient(this.props.row.id)

  render() {
    const {
      classes,
      row: {clientId, insertedAt, revokedAt},
    } = this.props

    return (
      <TableRow>
        <TableCell>{clientId}</TableCell>
        <TableCell>{formatDateTime(insertedAt)}</TableCell>
        {!revokedAt && (
          <TableCell>
            <AccessControlled requiredPermissions="team_settings:api">
              <DangerButton className={classes.deleteButton} onClick={this.onRevoke}>
                <DeleteIcon /> Revoke
              </DangerButton>
            </AccessControlled>
          </TableCell>
        )}
        {revokedAt && (
          <TableCell>
            <Typography component="em">Revoked {formatDateTime(revokedAt)}</Typography>
          </TableCell>
        )}
      </TableRow>
    )
  }
}

ClientCredentialRow.propTypes = {
  classes: object.isRequired,
  onRevokeClient: func.isRequired,
  row: shape({
    clientId: string.isRequired,
    id: string.isRequired,
    insertedAt: string.isRequired,
    revokedAt: string,
  }),
}

export default ClientCredentialRow
