import {ImageListItem, ImageListItemBar} from "@mui/material"
import {alpha} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {startCase} from "lodash"
import {func, object, string} from "prop-types"
import {PureComponent} from "react"
import {MdCheckCircle as CheckIcon} from "react-icons/md"

import cleanSlateThumbnail from "./clean-slate-theme-thumb.png"
import defaultThumbnail from "./default-theme-thumb.jpg"
import horizontalThumbnail from "./simple-horizontal-theme-thumb.jpg"

export class ThemeBaseChooserOption extends PureComponent {
  onSelect = () => this.props.onSelect(this.props.option)

  render() {
    const {classes, option, selected} = this.props
    const isSelected = option === selected

    return (
      <ImageListItem
        classes={{root: classes.itemRoot, item: classes.item}}
        cols={1}
        onClick={this.onSelect}
      >
        {option === "default" && (
          <img alt="Default Theme" className={classes.itemImg} src={defaultThumbnail} />
        )}
        {option === "simple-horizontal" && (
          <img alt="Horizontal Theme" className={classes.itemImg} src={horizontalThumbnail} />
        )}
        {option === "clean-slate" && (
          <img alt="Clean Slate" className={classes.itemImg} src={cleanSlateThumbnail} />
        )}
        <ImageListItemBar
          actionIcon={isSelected && <CheckIcon size={20} />}
          classes={{
            root: cx(classes.itemBar, {
              [classes.itemBarSelected]: isSelected,
            }),
            actionIcon: classes.actionIcon,
          }}
          title={startCase(option)}
        />
      </ImageListItem>
    )
  }
}

ThemeBaseChooserOption.propTypes = {
  classes: object.isRequired,
  onSelect: func.isRequired,
  option: string,
  selected: string,
}

const styles = theme => ({
  itemRoot: {
    marginBottom: theme.spacing(1),
  },
  item: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
  },
  itemImg: {
    height: "auto",
  },
  itemBar: {
    position: "static",
    paddingRight: theme.spacing(2),
  },
  itemBarSelected: {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
  actionIcon: {
    color: "#FFF",
  },
})

export default withStyles(styles)(ThemeBaseChooserOption)
