import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, shape} from "prop-types"
import {FaChevronLeft as LeftChevronIcon} from "react-icons/fa"
import {connect} from "react-redux"

import CenteredBox from "../centered-box/centered-box"

const useStyles = makeStyles({
  backButton: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: 10,
    color: "#999",
    textDecoration: "none",
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    "& svg": {
      marginRight: 10,
    },
  },
})

export const NotPermitted = ({history}) => {
  const classes = useStyles()
  const onClickBack = () => history.goBack()

  return (
    <>
      <Typography className={classes.backButton} onClick={onClickBack}>
        <LeftChevronIcon /> Go Back
      </Typography>
      <CenteredBox
        errorMessage="You are not permitted to access this page right now. Please contact your administrator to gain access."
        title="Oops!"
        variant="error"
      />
    </>
  )
}

NotPermitted.propTypes = {
  history: shape({
    goBack: func.isRequired,
  }),
}

const mapStateToProps = (state, {history}) => ({history})

export default connect(mapStateToProps, null)(NotPermitted)
