// This file was generated automatically from character-23.svg with the following command:
// ./svg-converter.js src/components/superhero/character-23.svg
import SuperheroSvg from "./superhero-svg"

const Character23 = props => (
  <SuperheroSvg viewBox="270 80 110 240" {...props}>
    <g id="Layer_18">
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M310.27,291.55c0,0,4.12,7.86,1.87,10.1
  			c0,0-12.35,3.37-28.06,1.12c0,0-5.61-2.24,1.12-5.61c0,0,11.22-3.37,12.35-7.86"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M287.59,296.43c0,0,4.28,3.27,0.72,6.83"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M312.66,298.06c0,0-3.81,2.36-22.92,2.42"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M302.6,289.87c0,0,0.38,5.32-13.28,9.39"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M299.24,293.24c0,0-2.24-2.24-2.24,0c0,2.24,0,2.24-2.24,2.24
  			c-2.24,0-2.24,0-2.24,1.12s-2.24,0-2.24,0"
        />
      </g>
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M357.93,286.06c0,0,6.29,6.25,4.83,9.07
  			c0,0-10.76,6.93-26.42,9.52c0,0-6.03-0.45-0.62-5.69c0,0,9.69-6.59,9.41-11.21"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M337.78,297.54c0,0,5.07,1.83,2.75,6.3"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M362.16,291.54c0,0-2.92,3.39-21.13,9.21"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M350.11,286.76c0,0,1.97,4.96-9.83,12.96"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M347.92,290.99c0,0-2.82-1.46-2.14,0.68
  			c0.68,2.14,0.68,2.14-1.46,2.82c-2.14,0.68-2.14,0.68-1.8,1.75c0.34,1.07-2.14,0.68-2.14,0.68"
        />
      </g>
      <g>
        <path
          style={{fill: "#CC5F64"}}
          d="M295.32,243.8l8.12-61.9l21.56-2.42l-4.97,18.16l-4.47,52.25c0,0,1.15,16.58,1.85,42.01
  			l-25.81-1.63C291.6,290.27,293.38,263.05,295.32,243.8z"
        />
      </g>
      <g>
        <path
          style={{fill: "#EB6669"}}
          d="M340.14,243.94l-2.44-58.15l-0.71-14.37l-4.74-1.24l-25.24-7.09l-2.93,15.81
  			c-0.2,0.79-0.36,1.59-0.47,2.42l-0.25,1.31l0.13,0.02c-0.01,0.17-0.03,0.34-0.03,0.51c-0.37,9.12,5.26,17.02,13.06,19.11
  			l0.11,0.36l3.56,37.95c0,0,0.9,8.94,3.61,14.49l15.93,34.91l24.71-3.08l-21.01-35.04C343.43,251.85,340.68,247.86,340.14,243.94z"
        />
      </g>
      <g>
        <path
          style={{fill: "#B08875"}}
          d="M313.51,142.71c-0.22,1.08-1.04,1.58-1.46,2.22c-1.17,1.78-2.34,3.56-3.52,5.33
  			c-1.42,2.15-2.84,4.3-4.26,6.46c-1.23,1.87-2.46,3.74-3.7,5.61c-0.59,0.9-1.16,1.82-1.79,2.71c-0.01,0.01-0.02,0.03-0.03,0.04
  			c-0.74-1.16-1.48-2.32-2.22-3.48c3.19,0.17,5.66,0.76,7.35,1.28c1.76,0.54,1.68,0.7,3.24,1.1c3.02,0.78,4.43,0.47,6.47,1.71
  			c0.66,0.4,2.07,1.25,1.97,2.1c-0.15,1.32-3.88,1.9-3.93,1.73c-0.05-0.19,3.94-1.67,3.93-1.73c-0.01-0.04-2.55,1.77-6.07,2.04
  			c-2.12,0.17-4.73-0.53-9.96-1.91c0,0-0.48-0.13-1.26-0.4c-3.13-1.1-3.77-2.1-3.96-2.43c-0.36-0.65-0.35-1.15-0.35-1.29
  			c0.02-0.37,0.13-0.73,0.35-1.05c0.49-0.74,0.98-1.48,1.46-2.22c1.17-1.78,2.34-3.56,3.52-5.33c1.42-2.15,2.84-4.3,4.26-6.46
  			c1.23-1.87,2.47-3.74,3.7-5.61c0.6-0.9,1.17-1.82,1.79-2.71c0.01-0.01,0.02-0.03,0.03-0.04c0.68-1.03,2.36-1.71,3.51-0.83
  			C313.54,140.27,313.71,141.73,313.51,142.71z"
        />
      </g>
      <path
        style={{fill: "#123252"}}
        d="M307.77,122.55c-1.36-10.68,1.58-27.34,12.68-30.19c6.91-1.77,14.36-0.75,20.66,2.91
  		c1.82,1.05,3.59,2.37,4.71,4.3s1.44,4.59,0.34,6.53c-1.1,1.94-1.65,2.35-0.35,4.07c1.14,1.5,2.03,3.28,2.57,5.18
  		c0.63,2.2,0.7,4.7-0.27,6.74c-1.16,2.46-3.66,3.97-4.42,6.62c-0.57,1.97-0.05,4.11-0.01,6.18c0.03,2.17-0.51,4.37-1.68,6.09
  		c-3.25,4.75-7.57,1.84-11.6,0.31c-2.78-1.05-5.55-2.1-8.33-3.16c-2.24-0.85-4.33-2.13-6.63-2.85c-1.99-0.62-4.3-0.96-5.51-3.13
  		c-0.38-0.68-0.58-1.45-0.77-2.22C308.57,127.52,308.08,125.04,307.77,122.55z"
      />
      <g>
        <path
          style={{fill: "#CC5F64"}}
          d="M316.12,138.35c0.35-1.51-0.1-3.26-1.39-4.04c-1.46-0.87-3.45-0.49-4.29,1.26
  			c-1.36,2.85-2.73,5.69-4.09,8.54l5.48,3.36L316.12,138.35z"
        />
        <path
          style={{fill: "#B08875"}}
          d="M326.73,95.18c0.53,0-0.41,0.02,0.1,0.08c0.31,13.91,12.94,21.2,15.05,22.46
  			c-1.84,7.5-7.93,13.01-15.15,13.01c-8.69,0-15.73-7.95-15.73-17.77C311,103.14,318.04,95.18,326.73,95.18z"
        />
        <path
          style={{fill: "#B08875"}}
          d="M311,112.9c0,7.9,4.91,14.59,11.22,16.91l0,6.16l5.8,0l0-5.34c4.36-0.39,8.22-2.8,10.86-6.39
  			c0.56,0.45,1.25,0.72,1.99,0.72c1.9,0,3.44-1.74,3.44-3.89c0-1.7-0.97-3.15-2.32-3.67c0-0.02,0.01-0.04,0.01-0.06
  			c-0.11-0.06-0.22-0.14-0.32-0.2l-30.47-7.01C311.07,111.04,311,111.96,311,112.9z"
        />
        <path
          style={{fill: "#B08875"}}
          d="M331.26,109.44l-17.84-5.88c-1.12,2-1.9,4.26-2.24,6.7l30.16,6.94
  			C338.18,115.28,332.62,113.23,331.26,109.44z"
        />
        <path
          style={{fill: "#EB6669"}}
          d="M338.35,145.35c0.67-5.55-2.77-10.67-7.69-11.43l-11.52-1.06c-6.24-0.43-9.23,2.27-10.12,8.68
  			l-2.97,20.22l30.38,3.57L338.35,145.35z"
        />
        <polygon
          style={{fill: "#123252"}}
          points="336.43,165.33 306.05,161.77 305.28,167.03 337.36,171.16 		"
        />
        <ellipse style={{fill: "#0D1E36"}} cx="319.4" cy="108.94" rx="1.25" ry="1.42" />
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M321.07,104.83c-0.28-0.31-0.69-0.53-1.07-0.66c-0.85-0.3-1.79-0.07-2.54,0.38
  					c-0.32,0.2-0.48,0.66-0.26,1c0.21,0.33,0.65,0.47,1,0.26c0.1-0.06,0.2-0.12,0.3-0.17c0.03-0.01,0.06-0.03,0.08-0.04
  					c-0.1,0.04-0.12,0.05-0.07,0.03c0.05-0.02,0.09-0.04,0.14-0.05c0.09-0.03,0.18-0.05,0.27-0.07c0.04-0.01,0.07-0.01,0.11-0.02
  					c-0.21,0.06-0.12,0.01-0.05,0.01c0.08-0.01,0.16-0.01,0.24-0.01c0.08,0,0.33,0.06,0.06,0c0.13,0.03,0.25,0.05,0.37,0.1
  					c0.06,0.02,0.15,0.08-0.07-0.03c0.02,0.01,0.05,0.02,0.07,0.03c0.05,0.02,0.1,0.05,0.14,0.08c0.04,0.02,0.08,0.05,0.12,0.08
  					c0.03,0.02,0.16,0.12,0.03,0.02c-0.13-0.1,0.03,0.03,0.06,0.06c0.02,0.02,0.03,0.03,0.05,0.05c0.25,0.29,0.77,0.28,1.03,0
  					C321.35,105.56,321.34,105.14,321.07,104.83L321.07,104.83z"
            />
          </g>
        </g>
        <ellipse style={{fill: "#0D1E36"}} cx="330.15" cy="110.96" rx="1.25" ry="1.42" />
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M330.34,107.64c0.08-0.03,0.16-0.05,0.25-0.06c0.02,0,0.04-0.01,0.06-0.01
  					c0.1-0.02-0.24,0.03-0.11,0.01c0.05-0.01,0.1-0.01,0.16-0.01c0.12-0.01,0.24,0,0.35,0.01c0.07,0.01,0.17,0.04-0.08-0.01
  					c0.03,0.01,0.07,0.01,0.1,0.02c0.07,0.01,0.14,0.03,0.21,0.05c0.07,0.02,0.32,0.14,0.07,0.02c0.07,0.03,0.14,0.07,0.21,0.1
  					c0.07,0.04,0.14,0.09,0.21,0.13c0.1,0.07-0.15-0.13-0.01-0.01c0.04,0.03,0.07,0.06,0.11,0.1c0.07,0.07,0.14,0.15,0.21,0.23
  					c0.02,0.02,0.03,0.04,0.05,0.06c-0.07-0.09-0.08-0.11-0.04-0.05c0.03,0.05,0.07,0.1,0.1,0.15c0.21,0.32,0.65,0.48,1,0.26
  					c0.32-0.21,0.48-0.65,0.26-1c-0.42-0.65-1.01-1.17-1.76-1.4c-0.49-0.15-1-0.15-1.5-0.06c-0.08,0.01-0.16,0.03-0.23,0.06
  					c-0.36,0.13-0.64,0.5-0.51,0.9C329.56,107.49,329.96,107.78,330.34,107.64L330.34,107.64z"
            />
          </g>
        </g>
        <path
          style={{fill: "#D9D8D6"}}
          d="M321.89,138.61l-6.24,0.46c-0.62,0.05-1.01,0.55-0.97,1.25l0.96,16.45
  			c0.04,0.71,0.48,1.14,1.11,1.1l6.24-0.46c0.62-0.05,1.01-0.55,0.97-1.25L323,139.71C322.96,139.01,322.52,138.57,321.89,138.61z"
        />
        <path
          style={{fill: "#D9D8D6"}}
          d="M323.29,144.51l0.39,5.65c0,0-1.66,0.83-2.4-1.26C320.72,147.32,323.29,144.51,323.29,144.51z"
        />
        <polyline style={{fill: "#B08875"}} points="322.21,133.14 323.13,138.9 328.02,133.67 		" />
        <path
          style={{fill: "#FFFFFF"}}
          d="M328.32,118.52c-0.09-0.18-0.31-0.22-0.46-0.1c-0.36,0.28-1.01,0.89-1.94,1.1
  			c-1.27,0.28-2.36-0.29-2.63,0.08c-0.15,0.21,0.03,0.46,0.38,0.79c0.35,0.33,1.4,0.96,2.56,0.72c1.22-0.26,2.13-1.85,2.13-2.42
  			C328.36,118.62,328.34,118.57,328.32,118.52z"
        />
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M325.07,114.34c-0.74-0.28-1.48-0.56-2.22-0.83c0.07,0.18,0.15,0.37,0.22,0.55
  					c0.65-1.1,1.29-2.19,1.94-3.29c0.09-0.16,0.18-0.31,0.28-0.47c0.25-0.42-0.4-0.79-0.65-0.38c-0.65,1.1-1.29,2.19-1.94,3.29
  					c-0.09,0.16-0.18,0.31-0.28,0.47c-0.13,0.22,0,0.47,0.22,0.55c0.74,0.28,1.48,0.56,2.22,0.83c0.19,0.07,0.41-0.08,0.46-0.26
  					C325.39,114.59,325.26,114.41,325.07,114.34L325.07,114.34z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#B08875"}}
            d="M335.35,143.33c0.03,1.21,0.16,1.76,0.35,3.19c0.11,0.83,0.1,0.96,0.35,3.78
  				c0.05,0.58,0.21,2.31,0.53,5.78c0.31,3.39,0.64,6.93,0.44,8.47c-0.01,0.08-0.24,1.5-0.38,2.84c0,0.01,0,0.03-0.01,0.04
  				c1.01-0.43,2.01-0.87,3.02-1.3c-0.43-1.45-1.56-4.6-4.28-6.85c-1.41-1.16-2.47-2.04-3.91-2c-1.19,0.03-2.1,0.67-3.31,0.07
  				c-0.41-0.21-0.79-0.53-1.37-0.46c-0.6,0.07-1.26,0.76-1.26,0.76l0,0c0,0,2.3,2.13,2.26,2.18c-0.03,0.05-2.66-2.16-2.57-2.3
  				c0.02-0.04,0.26,0.08,0.31,0.12c2.05,1.57,8.27,9.08,10.33,10.65c0.48,0.36,0.95,0.73,1.43,1.09c0.53,0.41,1.08,0.43,1.28,0.47
  				c1.31,0.28,2.58-0.03,2.58-0.03c0,0,0.98-2.4,1.06-2.59c0.8-1.97,0.51-4.79,0-9.36c-0.86-7.68-1.1-9.02-1.99-10.88
  				c-0.75-1.57-1.99-3.6-2.24-3.46c-0.15,0.09,0.1,1.01,0.36,1.82c0,0-0.52-0.45-3-4.21C335.35,141.14,335.33,142.55,335.35,143.33z
  				"
          />
        </g>
        <path
          style={{fill: "#B08875"}}
          d="M322.72,149.45c0,0,0.2,0.66-0.07,0.76c-0.26,0.1-1.1-0.2-1.42-1.32
  			c-0.21-0.74-1.59-3.55-4.01-3.36c0,0-2.73,0.94-2.28,4.15c0.33,2.41,1.05,4.23,3.64,6.14c2.42,1.78,4.18,1.82,5.07,2.19
  			c2.57,2,5.13,4,7.7,5.99c0.37,0.29,0.75,0.58,1.12,0.87l2.91-5.6c-2.62-2.04-5.24-4.08-7.86-6.12c-0.27-0.21-0.54-0.42-0.81-0.63
  			c-0.08-1.06-0.38-3.54-1.4-5.05c0,0-0.4-0.79-1.35-1.24C323.01,145.78,322.16,147.81,322.72,149.45z"
        />
        <path
          style={{fill: "#EB6669"}}
          d="M337.43,137.15c-0.71-1.34-2.14-2.19-3.53-1.68c-1.57,0.57-2.71,2.46-2.19,4.35
  			c0.86,3.09,1.72,6.18,2.58,9.26l5.95-2.1L337.43,137.15z"
        />
        <path
          style={{fill: "#B08875"}}
          d="M314.94,165.84c0,0-0.57,0.26-0.47,0.55c0.1,0.29,0.85,0.81,1.81,0.36
  			c0.63-0.3,3.42-0.95,4.87,1.25c0,0,1.14,2.95-1.3,4.66c-1.83,1.28-3.53,1.85-6.49,0.88c-2.77-0.91-3.94-2.4-4.77-2.93
  			c-3.01-0.9-6.02-1.8-9.04-2.7c-0.44-0.13-0.88-0.26-1.31-0.39l1.87-6.14c3.08,0.92,6.15,1.84,9.23,2.75
  			c0.32,0.09,0.63,0.19,0.95,0.28c0.77-0.62,2.62-1.98,4.3-2.08c0,0,0.79-0.18,1.71,0.35C317.22,163.2,316.4,165.26,314.94,165.84z"
        />
      </g>
      <polygon
        style={{fill: "#123252"}}
        points="325.76,160.06 329.47,154.67 330.6,155.55 327.01,161.03 	"
      />
      <ellipse style={{fill: "#F8D73E"}} cx="328.17" cy="157.71" rx="1.48" ry="1.67" />
      <g></g>
    </g>
  </SuperheroSvg>
)

export default Character23
