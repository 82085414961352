import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object, shape, string} from "prop-types"
import {forwardRef} from "react"
import {AiOutlineTrophy as RewardsIcon} from "react-icons/ai"
import shortid from "shortid"

import {feature} from "../../../feature/feature"

const RewardsButton = forwardRef(({classes, container, onAddContentBlock}, ref) => {
  const handleClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {},
      type: "rewards",
    })
  }

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <RewardsIcon />
      </ListItemIcon>
      <ListItemText primary="Rewards" ref={ref} />
    </MenuItem>
  )
})

RewardsButton.propTypes = {
  onAddContentBlock: func.isRequired,
  classes: shape({icon: string.isRequired}).isRequired,
  container: object.isRequired,
}

export {RewardsIcon}

export default feature("rewards", RewardsButton)
