import {element, elementType, node, oneOfType} from "prop-types"
import {useEffect} from "react"
import {useDispatch} from "react-redux"
import {Route} from "react-router-dom"

import {getAccessToken} from "lib/access-token"
import {fetchCurrentTeam, fetchUserInfo} from "lib/api"
import storage from "lib/storage"

import {setCurrentTeam, setCurrentUser} from "../../actions/session-actions"

const JourneyRoute = ({component: Component, ...props}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const {accessToken, refreshToken} = getAccessToken(storage)

    if (accessToken || refreshToken)
      fetchUserInfo().then(({team, ...userInfo}) => {
        dispatch(setCurrentTeam(team))
        dispatch(setCurrentUser(userInfo))
      })
    else
      fetchCurrentTeam().then(team => {
        dispatch(setCurrentTeam(team))
      })
  }, [dispatch])

  return <Route {...props} render={renderProps => <Component {...renderProps} />} />
}

JourneyRoute.propTypes = {
  component: oneOfType([element, elementType, node]).isRequired,
}

export default JourneyRoute
