const uploadFileWithPolicy = (file, policy, options) => {
  const body = new FormData()

  body.append("acl", "authenticated-read")

  for (const key in policy.fields) body.append(key, policy.fields[key])

  if (options.extraAttrs)
    for (const key in options.extraAttrs) body.append(key, options.extraAttrs[key])

  body.append("file", file, file.name)

  return window.fetch(policy.url, {method: "POST", body})
}

export default uploadFileWithPolicy
