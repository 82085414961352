import {Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {object} from "prop-types"
import {useEffect, useState} from "react"

import {fetchInsightMobileAppDonut} from "lib/api"
import pluralize from "lib/string/pluralize"

import {useInsights} from "./insights-context"
import {INSIGHTS_COLORS} from "./insights-helpers"
import StatsPie from "./stats-pie"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const MobileAppDonut = ({classes}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const {
    insights: {mobile_app_donut},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const mobileAppData = mobile_app_donut?.value.result || []
  const totalJourneys = mobile_app_donut?.value.totalJourneys || 0
  const totalLabel = pluralize("journey", totalJourneys)

  useEffect(() => {
    fetchInsightMobileAppDonut(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return <Skeleton variant="circular" width={150} height={150} />

  return (
    <>
      <Typography className={classes.subheader} variant="h6">
        Mobile App
      </Typography>
      {totalJourneys > 0 ? (
        <StatsPie
          colors={colors}
          data={mobileAppData}
          total={totalJourneys}
          totalLabel={totalLabel}
        />
      ) : (
        <div>No data available</div>
      )}
    </>
  )
}

MobileAppDonut.propTypes = {
  classes: object.isRequired,
}

export default MobileAppDonut
