import {COMPLETED_SURVEY_LIST_SET} from "./survey-actions"

export default function surveys(state = {}, action) {
  switch (action.type) {
    case COMPLETED_SURVEY_LIST_SET:
      return action.payload.reduce(
        (acc, currentSlug) => ({
          ...acc,
          [currentSlug]: {
            ...(acc[currentSlug] || {}),
            isCompleted: true,
          },
        }),
        state
      )

    default:
      return state
  }
}
