// adapted from https://github.com/winkjs/wink-jaro-distance/blob/master/src/wink-jaro-distance.js

export default (lhs, rhs) => {
  // set them arbitrarily, we'll swap later if needed
  let shortLen = lhs.length
  let longLen = rhs.length

  // early exits
  if (lhs === rhs) return {distance: 0, similarity: 1}
  if (!lhs || !rhs) return {distance: 1, similarity: 0}

  let short = lhs
  let long = rhs

  let matches = 0
  let transposes = 0

  const searchWindow = Math.floor(Math.max(shortLen, longLen) / 2) - 1

  const matchedChars = []

  let i, j
  let char

  let windowStart, windowEnd

  // swap the lens if needed
  if (shortLen > longLen) {
    short = [long, (long = short)][0]
    shortLen = [longLen, (longLen = shortLen)][0]
  }

  const longMatchedAt = new Array(longLen)

  for (i = 0; i < shortLen; i++) longMatchedAt[i] = false
  // determine matches, loop through `short` and search through `long` to minimze time
  for (i = 0; i < shortLen; i++) {
    char = short[i]
    windowStart = Math.max(i - searchWindow, 0)
    windowEnd = Math.min(i + searchWindow + 1, longLen)
    for (j = windowStart; j < windowEnd; j++)
      if (!longMatchedAt[j] && char === long[j]) {
        matches++
        longMatchedAt[j] = true
        matchedChars.push(char)
        break
      }
  }

  for (i = 0, j = 0; j < matches; i++)
    if (longMatchedAt[i]) {
      if (long[i] !== matchedChars[j]) transposes++
      j++
    }

  transposes /= 2

  const similarity =
    matches === 0
      ? 0
      : (matches / shortLen + matches / longLen + (matches - transposes) / matches) / 3

  return {
    distance: 1 - similarity,
    similarity,
  }
}
