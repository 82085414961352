import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"
import {useState} from "react"
import {useSelector} from "react-redux"

import {createEvent} from "lib/api"
import {validUrlOrMultipleUrls} from "lib/field-validations"
import useForm from "lib/hooks/use-form"
import {fullName} from "lib/names"

import FormActions from "../form-actions"

const HtmlSettings = ({contentBlock, initialValues, onSubmit, onChange}) => {
  const {field, handleSubmit, resetForm} = useForm({
    initialValues,
    onSubmit,
    onChange,
    validators: {css: [validUrlOrMultipleUrls], js: [validUrlOrMultipleUrls]},
  })
  const {team, user} = useSelector(({session: {team, user}}) => ({team, user}))

  const [didError, setDidError] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = ({target: {checked}}) => setIsChecked(checked)

  const confirmation = `By saving this html, I assert that I, ${fullName(
    user
  )}, have reviewed this code and determined it to be safe and that ${
    team.name
  } is responsible for its effects and behaviors.`

  const handleSave = e => {
    e.preventDefault()
    e.persist()

    createEvent({
      action: "content/html-updated",
      userId: user.id,
      contentBlockId: contentBlock.id,
      meta: {
        confirmation,
        html: field("code").value,
        css: field("css").value,
        js: field("js").value,
      },
    })
      .then(() => {
        setDidError(false)
        handleSubmit(e)
      })
      .catch(() => setDidError(true))
  }

  const classes = useStyles()

  return (
    <form aria-label="HTML Settings" className={classes.root} onSubmit={handleSave}>
      <TextField
        className={classes.htmlTextField}
        fullWidth={true}
        id={`html-${contentBlock.id}-html`}
        inputProps={{className: classes.htmlEditor}}
        label="HTML"
        multiline={true}
        rows={10}
        type="text"
        variant="outlined"
        {...field("code")}
      />
      {contentBlock.containerType !== "message" && (
        <>
          <TextField
            className={classes.htmlTextField}
            fullWidth={true}
            id={`html-${contentBlock.id}-css`}
            inputProps={{className: classes.htmlEditor}}
            label="CSS URLs"
            multiline={true}
            type="text"
            helperText="Enter one URL per line"
            {...field("css")}
          />
          <TextField
            className={classes.htmlTextField}
            fullWidth={true}
            id={`html-${contentBlock.id}-js`}
            inputProps={{className: classes.htmlEditor}}
            label="JS URLs"
            multiline={true}
            type="text"
            helperText="Enter one URL per line"
            {...field("js")}
          />
        </>
      )}
      <FormControl className={classes.checkboxFormControl} component="fieldset">
        <FormControlLabel
          classes={{label: classes.checkboxLabel, root: classes.checkboxLabelRoot}}
          control={
            <Checkbox
              checked={isChecked}
              color="primary"
              onChange={handleCheckboxChange}
              value={confirmation}
            />
          }
          label={confirmation}
        />
        {!isChecked && (
          <FormHelperText>
            You must confirm that you have reviewed the html before you can save it.
          </FormHelperText>
        )}
      </FormControl>
      {didError && (
        <Typography className={classes.error} color="error" variant="subtitle2">
          Something went wrong. Please try again.
        </Typography>
      )}
      <FormActions resetForm={resetForm} saveProps={{disabled: !isChecked}} />
    </form>
  )
}

HtmlSettings.propTypes = {
  contentBlock: object,
  initialValues: object,
  onChange: func.isRequired,
  onSubmit: func,
}

const monospace = `"Monaco","Consolas","Lucida Console",monospace`

const useStyles = makeStyles(theme => ({
  checkboxFormControl: {
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    fontSize: 13,
    marginTop: 10,
  },
  checkboxLabelRoot: {
    alignItems: "flex-start",
    display: "flex",
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  htmlEditor: {
    fontFamily: monospace,
  },
  htmlTextField: {
    marginBottom: theme.spacing(2),
  },
  root: {
    width: "100%",
  },
}))

export default HtmlSettings
