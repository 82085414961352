import {object} from "prop-types"
import {connect} from "react-redux"

import AuditLogs from "../audit-logs/audit-logs"
import {getAuditLogActions} from "../audit-logs/audit-logs-actions.js"
import Box from "../box/box"

const mapStateToProps = ({auditLogs, session}) => ({
  eventActions: auditLogs.eventActions,
  currentUser: session.user,
  title: "My Audit Logs",
  headers: [
    {field: "action", isDefault: true},
    {field: "batchId", isDefault: true},
    {field: "sshKey", label: "SSH Key", sortable: false},
    {field: "location", sortable: false, isDefault: true},
    {field: "timestamp", isDefault: true},
  ],
})

const addActionsToProps = {getAuditLogActions}

const UserAuditLogs = ({currentUser, ...props}) => (
  <Box>{currentUser && <AuditLogs entityFilter={{userId: currentUser.id}} {...props} />}</Box>
)

UserAuditLogs.propTypes = {
  currentUser: object,
}

export default connect(mapStateToProps, addActionsToProps)(UserAuditLogs)
