import {EditorState, RichUtils} from "draft-js"

const getCurrentEntityKey = editorState => {
  const selection = editorState.getSelection()
  const anchorKey = selection.getAnchorKey()
  const contentState = editorState.getCurrentContent()
  const anchorBlock = contentState.getBlockForKey(anchorKey)
  const offset = selection.anchorOffset
  const index = selection.isBackward ? offset - 1 : offset

  return anchorBlock.getEntityAt(index)
}

export const getCurrentEntity = editorState => {
  const contentState = editorState.getCurrentContent()
  const entityKey = getCurrentEntityKey(editorState)

  if (entityKey) return contentState.getEntity(entityKey)

  return null
}

export const hasEntity = (editorState, entityType) => {
  const entity = getCurrentEntity(editorState)

  return entity && entity.getType() === entityType
}

export const setEntity = (editorState, entityType, data, mutability = "MUTABLE") => {
  const contentState = editorState.getCurrentContent()
  const contentStateWithEntity = contentState.createEntity(entityType, mutability, data)
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  const newState = RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey) // yeah it really should just be toggleEntity: https://github.com/facebook/draft-js/issues/737

  return EditorState.forceSelection(newState, editorState.getSelection())
}

export const removeEntity = editorState => {
  const selection = editorState.getSelection()

  return RichUtils.toggleLink(editorState, selection, null)
}
