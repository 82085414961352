import {connect} from "react-redux"

import {getObjectives} from "../templates/objectives-actions"
import BatchUploadJourneys from "./batch-upload-journeys"

const mapStateToProps = ({objectives, template}) => ({
  objectives: objectives.list,
  isArchived: template.isArchived,
})

const mapDispatchToProps = {getObjectives}

export default connect(mapStateToProps, mapDispatchToProps)(BatchUploadJourneys)
