import {Button, Grid, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {useCallback, useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import Feature from "components/feature/feature"
import SaveButton from "components/save-button/save-button"

import {setCurrentTeam} from "actions/session-actions"

import {
  fetchAdvancedInsightsDrivers,
  fetchObjectives,
  fetchProducts,
  updateCurrentTeam,
  updateObjectives,
  updateProduct,
} from "lib/api"
import useFeatures from "lib/hooks/use-features"

import CardOnFileMetrics from "./card-on-file-metrics"
import CrossSellAdoptionMetrics from "./cross-sell-adoption-metrics"
import PremierSettings from "./premier-settings"
import ProductOpeningMetrics from "./product-opening-metrics"

const cardOnFileMap = {
  average_card_order_volume: "Average card order volume",
  average_card_transactions_per_year: "Average card transactions per year",
  card_interchange_rate: "Card interchange %",
}

const Subheader = styled(Typography)(({theme}) => ({
  fontSize: theme.spacing(2.25),
  fontWeight: "bold",
}))

const Form = styled("form")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(3),
  width: "100%",
}))

const InsightsSettings = () => {
  const {hasFeature} = useFeatures()
  const dispatch = useDispatch()
  const teamConfig = useSelector(state => state?.session?.team?.config)

  // Initial states for forms
  const [initialObjectivesSettings, setInitialObjectivesSettings] = useState([])
  const [initialDriversSettings, setInitialDriversSettings] = useState({
    cardOnFile: [],
    objectives: [],
  })
  const [initialProductsSettings, setInitialProductsSettings] = useState([])
  const [initialPremierSettings, setInitialPremierSettings] = useState({})

  // Loading states
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false)
  const [isLoadingObjectives, setIsLoadingObjectives] = useState(false)
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState([])

  // Form states
  const [drivers, setDrivers] = useState({cardOnFile: [], objectives: []})
  const [cardOnFileMetrics, setCardOnFileMetrics] = useState([])
  const [objectives, setObjectives] = useState([])
  const [premierSettings, setPremierSettings] = useState({})
  const [products, setProducts] = useState([])

  useEffect(() => setInitialPremierSettings(teamConfig?.do?.premier_attribution || {}), [
    teamConfig,
    setInitialPremierSettings,
  ])

  // Used to cause a force remount on cancel
  const [resetKey, setResetKey] = useState(1)

  const compileCardOnFileMetrics = useCallback(
    cardOnFileDrivers =>
      cardOnFileDrivers.map(driver => ({
        ...driver,
        name: cardOnFileMap?.[driver.key] ?? "Unknown",
        value: teamConfig?.["card-on-file"]?.[driver.key],
      })),
    [teamConfig]
  )

  const handleCancel = () => {
    setObjectives(initialObjectivesSettings)
    setDrivers(initialDriversSettings)
    setCardOnFileMetrics(compileCardOnFileMetrics(initialDriversSettings.cardOnFile))
    setProducts(initialProductsSettings)
    setPremierSettings(initialPremierSettings)
    setResetKey(resetKey + 1)
  }

  const handleSubmit = e => {
    e.preventDefault()

    setIsSubmitting(["currentTeam", "objectives", "products"])

    updateCurrentTeam({
      config: {
        ...teamConfig,
        "card-on-file": {
          ...teamConfig?.["card-on-file"],
          ...cardOnFileMetrics.reduce((acc, {key, value}) => {
            if (!!value || value === 0) {
              return {...acc, [key]: value}
            }

            return acc
          }, {}),
        },
        do: {
          ...teamConfig?.do,
          premier_attribution: {
            engagement_threshold: premierSettings?.engagement_threshold,
            attribution_window: premierSettings?.attribution_window,
          },
        },
      },
    }).then(team => {
      dispatch(setCurrentTeam(team))
      setCardOnFileMetrics(initialDriversSettings.cardOnFile)
      setInitialPremierSettings(premierSettings)
      setIsSubmitting(isSubmitting.filter(key => key !== "currentTeam"))
    })

    updateObjectives(objectives.map(({hasError, isDirty, ...objective}) => objective)).then(
      objectives => {
        setInitialObjectivesSettings(objectives)
        setIsSubmitting(isSubmitting.filter(key => key !== "objectives"))
      }
    )

    if (products.some(p => p.hasError))
      throw new Error("Products have errors. handleSubmit shouldn't have been called. Aborting.")

    // Update each changed product one by one (we don't have a batch-update products endpoint).
    Promise.all(
      products
        .filter(product => product.isDirty)
        .map(({id, driverId, value}) => updateProduct(id, {driverId, value}))
    ).then(() => {
      setInitialProductsSettings(products.map(({hasError, isDirty, ...product}) => product))
      setIsSubmitting(isSubmitting.filter(key => key !== "products"))
    })

    setResetKey(resetKey + 1)
  }

  const isFormValid = useMemo(() => {
    // Note: premierSettings is an object which may have an isDirty flag. Other entries here are lists of such objects.
    const allRecords = [...cardOnFileMetrics, ...objectives, ...products, premierSettings]
    const hasError = allRecords.some(({hasError}) => !!hasError)
    const isDirty = allRecords.some(({isDirty}) => !!isDirty)
    return isDirty && !hasError
  }, [cardOnFileMetrics, objectives, products, premierSettings])

  // Load records from API, setting the "initial" state first (current state will be set from that)

  useEffect(() => {
    setIsLoadingObjectives(true)
    fetchObjectives()
      .then(setInitialObjectivesSettings)
      .finally(() => setIsLoadingObjectives(false))
  }, [])

  useEffect(() => {
    setIsLoadingDrivers(true)
    fetchAdvancedInsightsDrivers()
      .then(setInitialDriversSettings)
      .finally(() => setIsLoadingDrivers(false))
  }, [])

  useEffect(() => {
    setIsLoadingProducts(true)
    fetchProducts({track_opens: true})
      .then(setInitialProductsSettings)
      .finally(() => setIsLoadingProducts(false))
  }, [])

  // Set current records' state whenever "initial" state changes

  useEffect(() => {
    setObjectives(initialObjectivesSettings)
  }, [initialObjectivesSettings, setObjectives])

  useEffect(() => {
    setDrivers(initialDriversSettings)
  }, [initialDriversSettings, setDrivers])

  useEffect(() => {
    setProducts(initialProductsSettings)
  }, [initialProductsSettings, setProducts])

  useEffect(() => {
    setPremierSettings(initialPremierSettings)
  }, [initialPremierSettings, setPremierSettings])

  useEffect(() => {
    if (Array.isArray(drivers?.cardOnFile)) {
      setCardOnFileMetrics(compileCardOnFileMetrics(drivers.cardOnFile))
    }
  }, [compileCardOnFileMetrics, drivers, teamConfig])

  return (
    <>
      <DocumentTitle title="Team Settings - Insights" />

      {hasFeature("premier-attribution") && (
        <>
          <Typography variant="h5" sx={{paddingBottom: "0.5em"}}>
            Premier Attribution Model
          </Typography>

          <Box>
            <Form onSubmit={handleSubmit} sx={{padding: 0}}>
              <PremierSettings
                isLoading={!teamConfig}
                premierSettings={premierSettings}
                onChange={setPremierSettings}
              />

              <Grid container>
                <Grid item sx={{display: "flex", gap: 2, justifyContent: "flex-end"}} xs={12}>
                  <SaveButton disabled={!isFormValid} submitting={!!isSubmitting.length} />
                </Grid>
              </Grid>
            </Form>
          </Box>
        </>
      )}

      <Typography variant="h5" sx={{paddingTop: "1em", paddingBottom: "0.5em"}}>
        Insights
      </Typography>
      <Box>
        <Feature featureKey="advanced-insights">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                The following inputs are used to calculate the value add of product and service
                adoption and/or usage. Find this reporting on the Insights Dashboard under "Business
                Objectives".
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Subheader>Objective and Value Driver mapping</Subheader>
              <Typography>
                Please select the value driver that is connected to each Objective. Every time an
                objective completion is reported that is linked to a value driver, that value add
                will be reported in Insights. If an objective is not linked to a value driver, the
                system will not be able to render the value.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Subheader>Your Value Input</Subheader>
              <Typography>
                If you have your own values for each value driver listed, please input them below
                and select the objectives that will be tied to them. If you do not have a value, the
                system will use the default values that are based on industry averages we have
                collected through market research.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Subheader>Data Reporting</Subheader>
              <Typography>
                All objectives will be shown in this list, even if they are not currently in use in
                any campaign.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Subheader>Blank Benchmark Inputs</Subheader>
              <Typography>
                If a benchmark is left blank, the Objective Completion Benchmarking table in
                Insights cannot report the % difference between your benchmarks and the completion
                rates measured on Digital Onboarding.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Subheader>Your Metric Updates</Subheader>
              <Typography>
                After updating Your Metrics, you may need to wait up to a day for the figures to be
                reflected in Insights.
              </Typography>
            </Grid>
            <Form onSubmit={handleSubmit}>
              <Grid item sx={{marginTop: 6}} xs={12}>
                <CrossSellAdoptionMetrics
                  drivers={drivers.objectives}
                  isLoading={isLoadingDrivers || isLoadingObjectives}
                  key={resetKey}
                  objectives={objectives}
                  onChange={setObjectives}
                />
              </Grid>
              <Grid item sx={{marginTop: 6}} xs={12}>
                <CardOnFileMetrics
                  drivers={drivers.cardOnFile}
                  isLoading={isLoadingDrivers}
                  key={resetKey}
                  metrics={cardOnFileMetrics}
                  onChange={setCardOnFileMetrics}
                />
              </Grid>
              <Feature featureKey="product-opening-value-reporting">
                <Grid item sx={{marginTop: 6}} xs={12}>
                  <ProductOpeningMetrics
                    drivers={drivers?.objectives}
                    isLoading={isLoadingDrivers || isLoadingProducts}
                    products={products}
                    onChange={setProducts}
                  />
                </Grid>
              </Feature>
              <Grid item sx={{marginTop: 6}} xs={12}>
                <Subheader>Need Help?</Subheader>
                <Typography>
                  For benchmarking assistance, please visit{" "}
                  <a
                    href="https://support.digitalonboarding.com/a/solutions/articles/48001220350"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    our Support Portal
                  </a>{" "}
                  to download our benchmarking template or contact your account manager.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{display: "flex", gap: 2, justifyContent: "flex-end", marginTop: 6}}
                xs={12}
              >
                <Button color="grey" onClick={handleCancel} type="button">
                  Cancel
                </Button>
                <SaveButton disabled={!isFormValid} submitting={!!isSubmitting.length} />
              </Grid>
            </Form>
          </Grid>
        </Feature>
      </Box>
    </>
  )
}

export default InsightsSettings
