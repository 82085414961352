import {SSH_KEY_CREATED, SSH_KEY_DELETED, SSH_KEY_LIST_SET} from "./team-actions"

const initialState = {
  list: [],
}

function sshKeys(state = initialState, action) {
  switch (action.type) {
    case SSH_KEY_LIST_SET:
      return {
        ...state,
        list: action.payload,
      }

    case SSH_KEY_DELETED:
      return {
        ...state,
        list: state.list.filter(key => key.id !== action.payload.id),
      }

    case SSH_KEY_CREATED:
      return {
        ...state,
        list: state.list.concat([action.payload]),
      }

    default:
      return state
  }
}

export default sshKeys
