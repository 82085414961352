import {Tab, Tabs} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object} from "prop-types"
import {useEffect, useMemo, useState} from "react"
import {connect} from "react-redux"

import FormActions from "components/content-block-editor/plugins/form-actions"
import {TeamBrandingSettingsContextProvider} from "components/teams/branding-settings/branding-settings-context"
import ButtonSettings from "components/teams/branding-settings/button-settings"
import GeneralSettings from "components/teams/branding-settings/general-settings"
import HeadingSettings from "components/teams/branding-settings/heading-settings"
import LinkSettings from "components/teams/branding-settings/link-settings"
import ListSettings from "components/teams/branding-settings/list-settings"
import RewardSettings from "components/teams/branding-settings/reward-settings"
import TableSettings from "components/teams/branding-settings/table-settings"

import {loadFontsFromTheme} from "lib/fonts"
import useFeatures from "lib/hooks/use-features"
import useForm from "lib/hooks/use-form"
import createTeamTheme from "themes/team-theme-creator"

import {controlAccess, isUserPermitted} from "../access-control/access-controlled"
import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import BrandingTabPanel from "./branding-settings/branding-tab-panel"
import {
  a11yProps,
  getThemeFromInputs,
  parse,
  removeEmptyValues,
  unparse,
  validators,
} from "./branding-settings/helpers"
import LogoSettings from "./branding-settings/logo-settings"
import {updateFavicon, updateLogo, updateTeamSettings} from "./team-actions"

const defaultTheme = createTeamTheme({})

const readFile = async file =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result))
    if (file) reader.readAsDataURL(file)
  })

export const BrandingSettings = ({
  classes,
  currentUser,
  onUpdateFavicon,
  onUpdateLogo,
  onUpdateThemeStyles,
  team,
}) => {
  const [favicon, setFavicon] = useState(null)
  const [logo, setLogo] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const {hasFeature} = useFeatures()

  const {change, field, handleSubmit, inputs, resetForm} = useForm({
    enableReinitialize: true,
    initialValues: team?.themeStyles,
    parse,
    unparse,
    onSubmit: themeStyles => {
      if (favicon)
        onUpdateFavicon(favicon.file).then(() => setTimeout(() => setFavicon(null), 1500))
      if (logo) onUpdateLogo(logo.file).then(() => setTimeout(() => setLogo(null), 1500))

      onUpdateThemeStyles({themeStyles: removeEmptyValues(themeStyles)})
    },
    skipUndefined: true,
    validators,
  })

  const onChangeFavicon = async ({
    target: {
      files: [file],
    },
  }) => setFavicon({file, preview: await readFile(file)})

  const onChangeLogo = async ({
    target: {
      files: [file],
    },
  }) => setLogo({file, preview: await readFile(file)})

  const onResetForm = () => {
    setFavicon(null)
    setLogo(null)
    resetForm()
  }

  // Material UI theme
  const muiTheme = useMemo(
    () => (!team ? defaultTheme : createTeamTheme(getThemeFromInputs(inputs))),
    [team, inputs]
  )

  useEffect(() => {
    loadFontsFromTheme({
      overrides: muiTheme.overrides,
      typography: muiTheme.typography,
    })
  }, [muiTheme.overrides, muiTheme.typography])

  return (
    <TeamBrandingSettingsContextProvider
      change={change}
      field={field}
      inputs={inputs}
      muiTheme={muiTheme}
    >
      <Box>
        <DocumentTitle title="Team Settings - Branding" />
        <form className={classes.root} onSubmit={handleSubmit}>
          <Tabs
            aria-label="Team branding tabs"
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="inherit"
            orientation="vertical"
            onChange={(e, i) => setTabIndex(i)}
            value={tabIndex}
          >
            <Tab classes={{root: classes.tabWrapper}} label="General" {...a11yProps(0)} />
            <Tab classes={{root: classes.tabWrapper}} label="Logo" {...a11yProps(1)} />
            <Tab classes={{root: classes.tabWrapper}} label="Buttons" {...a11yProps(2)} />
            <Tab classes={{root: classes.tabWrapper}} label="Headings" {...a11yProps(3)} />
            <Tab classes={{root: classes.tabWrapper}} label="Links" {...a11yProps(4)} />
            <Tab classes={{root: classes.tabWrapper}} label="Lists" {...a11yProps(5)} />

            {hasFeature("accounts-table") && (
              <Tab classes={{root: classes.tabWrapper}} label="Table" {...a11yProps(6)} />
            )}
            {hasFeature("rewards") && isUserPermitted(currentUser, "rewards:manage") && (
              <Tab classes={{root: classes.tabWrapper}} label="Rewards" {...a11yProps(7)} />
            )}
          </Tabs>
          <div className={classes.tabPanels}>
            <BrandingTabPanel value={tabIndex} index={0}>
              <GeneralSettings />
            </BrandingTabPanel>
            <BrandingTabPanel value={tabIndex} index={1}>
              <LogoSettings
                faviconPreview={favicon?.preview}
                logoPreview={logo?.preview}
                onChangeFavicon={onChangeFavicon}
                onChangeLogo={onChangeLogo}
              />
            </BrandingTabPanel>
            <BrandingTabPanel value={tabIndex} index={2}>
              <ButtonSettings />
            </BrandingTabPanel>
            <BrandingTabPanel value={tabIndex} index={3}>
              <HeadingSettings />
            </BrandingTabPanel>
            <BrandingTabPanel value={tabIndex} index={4}>
              <LinkSettings />
            </BrandingTabPanel>
            <BrandingTabPanel value={tabIndex} index={5}>
              <ListSettings />
            </BrandingTabPanel>
            {hasFeature("accounts-table") && (
              <BrandingTabPanel value={tabIndex} index={6}>
                <TableSettings />
              </BrandingTabPanel>
            )}
            {hasFeature("rewards") && isUserPermitted(currentUser, "rewards:manage") && (
              <BrandingTabPanel value={tabIndex} index={7}>
                <RewardSettings />
              </BrandingTabPanel>
            )}
            <div className={classes.formActions}>
              <FormActions resetForm={onResetForm} />
            </div>
          </div>
        </form>
      </Box>
    </TeamBrandingSettingsContextProvider>
  )
}

BrandingSettings.propTypes = {
  classes: object,
  currentUser: object,
  onUpdateFavicon: func.isRequired,
  onUpdateLogo: func.isRequired,
  onUpdateThemeStyles: func.isRequired,
  team: object,
}

const styles = theme => ({
  formActions: {
    alignSelf: "flex-end",
    width: 200,
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  tabPanels: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tabWrapper: {
    alignItems: "flex-start",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
})

export default withStyles(styles)(
  connect(({session}) => ({team: session.team, currentUser: session.user}), {
    onUpdateFavicon: updateFavicon,
    onUpdateLogo: updateLogo,
    onUpdateThemeStyles: updateTeamSettings,
  })(controlAccess({requiredPermissions: "team_settings:branding"})(BrandingSettings))
)
