import {arrayOf, func, shape, string} from "prop-types"
import {useEffect} from "react"

import Box from "components/box/box"
import Padded from "components/padded/padded"

import DocumentTitle from "../document-title/document-title"
import UsersListRow from "./users-list-row"

const UsersList = ({onDeleteUser, users, onUpdateUser, onFetchUsers}) => {
  useEffect(() => {
    onFetchUsers()
  }, [onFetchUsers])

  return (
    <Box>
      <DocumentTitle title="Team Dashboard - Users" />
      <Padded>
        {users.map(user => (
          <UsersListRow
            key={user.id}
            onDeleteUser={onDeleteUser}
            onUpdateUser={onUpdateUser}
            user={user}
          />
        ))}
      </Padded>
    </Box>
  )
}

UsersList.propTypes = {
  onDeleteUser: func.isRequired,
  onFetchUsers: func.isRequired,
  onUpdateUser: func.isRequired,
  users: arrayOf(
    shape({
      id: string.isRequired,
    })
  ),
}

export default UsersList
