import {func, node, object} from "prop-types"
import {createContext, useContext} from "react"

const BrandingSettingsContext = createContext()

export const TemplateBrandingSettingsContextProvider = ({
  change,
  children,
  field,
  inputs,
  muiTheme,
  theme,
}) => {
  return (
    <BrandingSettingsContext.Provider
      value={{change, field, inputs, muiTheme, theme, type: "template"}}
    >
      {children}
    </BrandingSettingsContext.Provider>
  )
}

TemplateBrandingSettingsContextProvider.propTypes = {
  change: func.isRequired,
  children: node.isRequired,
  field: func.isRequired,
  inputs: object.isRequired,
  // The theme created from createTeamTheme
  muiTheme: object.isRequired,
  // The theme as it exists in the database without any fancy bells or whistles
  theme: object.isRequired,
}

export const TeamBrandingSettingsContextProvider = ({
  change,
  children,
  field,
  inputs,
  muiTheme,
}) => {
  return (
    <BrandingSettingsContext.Provider value={{change, field, inputs, muiTheme, type: "team"}}>
      {children}
    </BrandingSettingsContext.Provider>
  )
}

TeamBrandingSettingsContextProvider.propTypes = {
  change: func.isRequired,
  children: node.isRequired,
  field: func.isRequired,
  inputs: object.isRequired,
  // The theme created from createTeamTheme
  muiTheme: object.isRequired,
}

export const useBrandingSettings = () => useContext(BrandingSettingsContext)

export const brandingSettings = Component => props => (
  <Component {...useBrandingSettings()} {...props} />
)
