import withStyles from "@mui/styles/withStyles"
import {func, number, object} from "prop-types"
import {useDragLayer} from "react-dnd"

import MessagePin from "./message-pin"

export const DRAG_PADDING = 16

const getItemStyles = ({dropOffset, positionToDay, maxDay, timelineRect}) => {
  if (!dropOffset) return {display: "none"}

  const rect = timelineRect()
  const day = positionToDay(dropOffset.y) //use day so that we snap to position
  const pos = rect.top - DRAG_PADDING + (day / maxDay) * (rect.height - DRAG_PADDING)
  const transform = `translate(${rect.left + DRAG_PADDING * 1.5}px, ${pos}px)` //show it distinguishably higher than other markers

  return {
    transform,
    WebkitTransform: transform,
  }
}

const MessageDragLayer = ({classes, maxDay, positionToDay, timelineRect}) => {
  const {item, dropOffset, isDragging} = useDragLayer(monitor => ({
    item: monitor.getItem(),
    dropOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging) return null

  const style = getItemStyles({maxDay, dropOffset, positionToDay, timelineRect})

  return (
    <div className={classes.root}>
      <div style={style}>
        {!!dropOffset && (
          <div className={classes.pinHolder}>
            <div>
              <MessagePin label=" " type={item.type} />
            </div>
            <div className={classes.pinDay}>Day {positionToDay(dropOffset.y)}</div>
          </div>
        )}
      </div>
    </div>
  )
}

MessageDragLayer.propTypes = {
  classes: object.isRequired,
  maxDay: number,
  positionToDay: func.isRequired,
  timelineRect: func.isRequired,
}

const styles = theme => ({
  root: {
    position: "fixed",
    pointerEvents: "none",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 10000,
    "& > div": {
      opacity: 0.8,
      transition: "transform linear .05s",
      "& .arrow": {
        borderRightWidth: theme.spacing(10),
        borderRightColor: theme.palette.primary.light,
      },
      "& .icon": {
        background: theme.palette.primary.light,
      },
    },
  },
  pinHolder: {
    display: "flex",
    alignItems: "center",
  },
  pinDay: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 1.2,
    color: theme.palette.primary.light,
    fontWeight: 700,
  },
})

export default withStyles(styles)(MessageDragLayer)
