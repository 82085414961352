import {Button} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
})

const FormActions = ({resetForm, resetProps, saveProps}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button color="grey" onClick={resetForm} type="button" {...resetProps}>
        Cancel
      </Button>
      <Button
        color="primary"
        type="submit"
        variant="contained"
        data-testid="save-button"
        {...saveProps}
      >
        Save
      </Button>
    </div>
  )
}

FormActions.defaultProps = {
  disabled: false,
  tooltip: "",
}

FormActions.propTypes = {
  resetForm: func.isRequired,
  resetProps: object,
  saveProps: object,
}

export default FormActions
