import {Grid, List, ListItem, ListItemText, Typography} from "@mui/material"
import {array, func, object, shape, string} from "prop-types"
import {useEffect} from "react"
import {connect} from "react-redux"

import CreateSshKey from "./create-ssh-key"
import SshKeyRow from "./ssh-key-row"
import * as addActionsToProps from "./team-actions"

export const SshKeys = ({classes, keys, getSshKeys, onCreateSshKey, onDeleteSshKey, team}) => {
  useEffect(() => {
    getSshKeys()
  }, [getSshKeys])

  return (
    <>
      <Typography className={classes.description}>
        Register your public key(s) with us here so you can start uploading files to our SFTP
        service.
      </Typography>
      <Grid container={true} spacing={4}>
        <Grid item={true} lg={6} md={12}>
          <List>
            <ListItemText>
              <Grid alignItems="center" container={true} justifyContent="space-between" spacing={4}>
                <Grid item={true}>
                  <span>
                    SFTP username:{" "}
                    <code className={classes.code}>{team ? team.subdomain : ""}</code>
                  </span>
                </Grid>
                <Grid item={true} />
              </Grid>
            </ListItemText>
            {keys.map(({id, title, fingerprint, insertedAt}) => (
              <SshKeyRow
                fingerprint={fingerprint}
                id={id}
                insertedAt={insertedAt}
                key={id}
                onDelete={onDeleteSshKey}
                title={title}
              />
            ))}
            {!keys.length && (
              <ListItem>
                <ListItemText
                  primary="There are no SSH Keys with access to your team's account"
                  primaryTypographyProps={{
                    classes: {
                      root: classes.noKeysFound,
                    },
                  }}
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item={true} lg={6} md={12}>
          <CreateSshKey onSubmit={onCreateSshKey} />
        </Grid>
      </Grid>
    </>
  )
}

SshKeys.propTypes = {
  classes: object.isRequired,
  getSshKeys: func.isRequired,
  keys: array,
  onCreateSshKey: func.isRequired,
  onDeleteSshKey: func.isRequired,
  team: shape({
    subdomain: string.isRequired,
  }).isRequired,
}

const mapStateToProps = ({session, sshKeys}) => ({keys: sshKeys.list, team: session.team})

export default connect(mapStateToProps, addActionsToProps)(SshKeys)
