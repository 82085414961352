import {node} from "prop-types"
import {createContext, useEffect, useReducer} from "react"

import {fetchRewardSets, fetchTemplates} from "lib/api"

import {initialState, reducer} from "./rewards-reducer"

export const RewardsContext = createContext()
RewardsContext.displayName = "RewardsContext"

const {Provider} = RewardsContext

const RewardsContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, null, () => initialState)

  // Provided data
  const value = {...state, dispatch}

  useEffect(() => {
    fetchRewardSets({withSteps: true}).then(data =>
      dispatch({type: "SET_REWARD_SETS", rewardSets: data})
    )

    fetchTemplates({withPages: true}).then(templates =>
      dispatch({type: "SET_TEMPLATES", templates})
    )
  }, [])

  return <Provider value={value}>{children}</Provider>
}

RewardsContextProvider.propTypes = {
  children: node.isRequired,
}

export default RewardsContextProvider
