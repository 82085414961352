import {Paper, Typography} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import memoize from "memoize-one"
import {array, object} from "prop-types"
import {useSelector} from "react-redux"

import ContentBlockEditor from "components/content-block-editor/content-block-editor"
import {JourneyContextProvider} from "components/journeys/journey-context"
import JourneyHeader from "components/journeys/journey-header"
import JourneyOwnerInfo from "components/journeys/journey-owner-info"
import Navigation from "components/journeys/navigation"
import Page from "components/journeys/page"
import themeBases from "components/journeys/theme-bases"

import {compileCss} from "lib/custom-css"
import {fullName} from "lib/names"

import TemplatePageContext from "../../contexts/template-page-context"

const usePageStyles = themeBase => makeStyles(themeBase)()

const createCompiledCustomCss = memoize((scope, source) => {
  try {
    return compileCss(scope, source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const CampaignPagesSection = ({templatePages, template, theme}) => {
  const team = useSelector(({session}) => session.team)
  const templateThemeBase = template.themeBase || "default"
  const pageClasses = usePageStyles(themeBases[templateThemeBase])

  const renderKeyValue = (key, value) => (
    <Typography sx={{paddingBottom: "1em"}}>
      <Typography component="span" sx={{fontWeight: "bold"}}>
        {key + ": "}
      </Typography>
      {value}
    </Typography>
  )

  const compiledCss = createCompiledCustomCss("#footer-content", template?.css || "")

  return (
    <>
      {templatePages.map((templatePage, index) => {
        const page = templatePage.page

        return (
          <JourneyContextProvider
            key={page.id}
            legacyInitialState={{
              contact: {},
              isApprovalView: true,
              isContentLibrary: false,
              onUpdateContact: () => null,
              pageId: page.id,
              team,
              themeClasses: pageClasses,
            }}
          >
            <ThemeProvider theme={theme}>
              <Paper
                component="section"
                name="campaign-pages"
                sx={theme => ({
                  pageBreakBefore: "always",
                  marginBottom: theme.spacing(2),
                  padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "0 auto",
                  position: "relative",
                  width: "100%",
                  [theme.breakpoints.down("md")]: {
                    padding: 0,
                  },
                })}
              >
                <div className={pageClasses.journeyWrapper}>
                  <JourneyHeader
                    logoFileId={template.logoFileId}
                    openMobileMenu={() => null}
                    pages={templatePages.map(tp => tp.page)}
                    reward={template.reward}
                    showMobileMenuIcon={false}
                    showProgressMeter={!template.hideProgressRing}
                    teamName={template.team?.name}
                  />
                  <Navigation
                    completedPages={[]}
                    onCloseMobileMenu={() => null}
                    pageUrlGenerator={slug => `/journeys/${slug}`}
                    currentPageId={page.id}
                    isMobileMenuOpen={false}
                    isSortable={false}
                    templatePages={templatePages}
                    visitedPages={[]}
                  />
                  <main // journey
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      position: "relative",
                    }}
                    className="page-wrapper"
                  >
                    <TemplatePageContext.Provider
                      value={{
                        content_variables: templatePage.content_variables,
                      }}
                    >
                      <Page
                        isEditable={false}
                        isContentLibrary={false}
                        classes={pageClasses}
                        pageUrlGenerator={slug => `/journeys/${slug}`}
                        nextPage={
                          index < templatePages.length - 1 ? templatePages[index + 1]?.page : null
                        }
                        prevPage={index > 0 ? templatePages[index - 1]?.page : null}
                        {...page}
                      />
                      <div
                        className={`footer-content ${pageClasses.footerContent}`}
                        data-testid="footer-content"
                        id="footer-content"
                        style={template?.styles ?? {}}
                      >
                        {<style>{compiledCss}</style>}
                        {template.id && (
                          <ContentBlockEditor
                            initialContainer={{...template, id: template.id}}
                            containerId={template.id}
                            initialMode={"View"}
                          />
                        )}
                      </div>
                    </TemplatePageContext.Provider>
                  </main>
                  {template.owner && team && (
                    <JourneyOwnerInfo
                      {...template.owner}
                      isSidebarOpen={false}
                      name={fullName(template.owner)}
                      options={template.supportOwnerOptions}
                      teamName={team.name}
                      isFixed={false}
                    />
                  )}
                </div>
              </Paper>
            </ThemeProvider>

            <Paper
              component="section"
              name="campaign-pages-overview"
              sx={theme => ({
                pageBreakBefore: "always",
                pageBreakAfter: "always",
                marginBottom: theme.spacing(2),
                padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
              })}
            >
              {renderKeyValue("Name", page.contentName)}
              {renderKeyValue("Navigation title", page.navigationTitle)}
              {renderKeyValue("Type", "page")}
              {renderKeyValue("Page order", templatePage.order)}
              {renderKeyValue("Objective", page.objective?.name || "none")}
              {renderKeyValue("Completion condition", page.completionCondition)}
              {renderKeyValue("Benchmark", page.benchmark)}
            </Paper>
          </JourneyContextProvider>
        )
      })}
    </>
  )
}

CampaignPagesSection.propTypes = {
  templatePages: array,
  template: object,
  theme: object,
}

export default CampaignPagesSection
