import {Box, Checkbox, Chip, IconButton, TableCell, Tooltip} from "@mui/material"
import {func, object, string} from "prop-types"
import {MdClose as DeleteIcon, MdEdit as EditIcon} from "react-icons/md"

const ProductSettingsCell = ({field, onDelete, onEdit, row}) => {
  switch (field) {
    case "services":
      return (
        <TableCell
          key={field}
          sx={{
            maxWidth: 175,
          }}
        >
          <Box
            sx={theme => ({
              display: "flex",
              flexWrap: "wrap",
              "& > :not(:last-child)": {
                marginRight: theme.spacing(0.5),
              },
            })}
          >
            {row[field].map(service => (
              <Chip key={service.name} size="small" label={service.name} />
            ))}
          </Box>
        </TableCell>
      )
    case "trackOpens":
      return (
        <TableCell key={field}>
          <Checkbox checked={row[field]} disabled />
        </TableCell>
      )
    case "controls":
      return (
        <TableCell>
          <Box
            className="show-on-hover"
            sx={theme => ({
              display: "flex",
              opacity: 0,

              "& > :not(:last-child)": {
                marginRight: theme.spacing(1),
              },
            })}
          >
            <IconButton onClick={() => onEdit()} size="medium">
              <EditIcon size={18} />
            </IconButton>
            <Tooltip
              title={
                !!row.hasActiveAccounts
                  ? "Unable to delete when product has active accounts"
                  : "Delete product"
              }
            >
              <span data-testid="product-delete-button">
                <IconButton
                  aria-label="delete-product"
                  disabled={!!row.hasActiveAccounts}
                  onClick={() => onDelete()}
                  size="medium"
                  sx={theme => ({
                    color: theme.palette.error.main,
                  })}
                >
                  <DeleteIcon size={18} />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </TableCell>
      )
    default:
      return <TableCell key={field}>{row[field]}</TableCell>
  }
}

ProductSettingsCell.propTypes = {
  field: string,
  onDelete: func,
  onEdit: func,
  row: object,
}

export default ProductSettingsCell
