import makeStyles from "@mui/styles/makeStyles"
import {array, arrayOf, exact, number, string} from "prop-types"
import {useState} from "react"
import {Cell, Legend, Pie, PieChart, Surface, Tooltip} from "recharts"

import MaybeTooltip from "lib/maybe-tooltip"

import {INSIGHTS_COLORS, roundNumberForPieChart} from "./insights-helpers"

// http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
const RADIAN = Math.PI / 180

const tooltipFormatter = (value, name, props) =>
  props?.payload?.tooltip ? props?.payload?.tooltip : [value, name]

const RenderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
  const radius = Number(percent >= 0.05 ? 0 : 35) + innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      dominantBaseline="central"
      fill={percent >= 0.05 ? "white" : "black"}
      style={{fontSize: "10px"}}
      textAnchor="middle"
      x={x}
      y={y}
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  )
}

RenderCustomizedLabel.propTypes = {
  cx: number.isRequired,
  cy: number.isRequired,
  innerRadius: number.isRequired,
  midAngle: number.isRequired,
  outerRadius: number.isRequired,
  percent: number.isRequired,
}

const CustomizedLegend = ({payload, title}) => {
  return (
    <ul className="recharts-default-legend" style={{padding: 0, margin: 0, textAlign: "left"}}>
      {title && (
        <li style={{display: "block", marginBottom: 10, marginLeft: -18, textIndent: 0}}>
          <i>{title}</i>
        </li>
      )}
      {payload.map((entry, index) => (
        <li
          className={`recharts-legend-item legend-item-${index}`}
          key={`item-${index}`}
          style={{display: "block", marginRight: 10}}
        >
          <Surface
            className="recharts-surface"
            height="14"
            width="14"
            style={{display: "inline-block", verticalAlign: "middle", marginRight: 4}}
            viewBox={{x: 0, y: 0, width: 32, height: 32}}
            version="1.1"
          >
            <path
              stroke="none"
              fill={entry.color}
              d="M0,4h32v24h-32z"
              className="recharts-legend-icon"
            />
          </Surface>
          <span className="recharts-legend-item-text" style={{color: entry.color}}>
            {entry.value}
          </span>
        </li>
      ))}
    </ul>
  )
}

CustomizedLegend.propTypes = {
  payload: array,
  title: string,
}

const StatsPie = ({
  colors,
  legendTitle,
  total,
  totalLabel,
  totalLabel2,
  totalLabelTooltip,
  data,
}) => {
  const [isTooltip, setIsTooltip] = useState(false)
  const classes = statsPieStyles()
  const innerRadius = 43
  const outerRadius = 79
  const pieData = data.filter(datum => datum.value > 0)
  const sum = pieData.reduce((acc, {value}) => acc + value, 0)
  const hasNoData = sum === 0
  const legendPadding = pieData.some(({value}) => value / sum < 0.05) ? -15 : 0 // Legend padding accounts for breakout label when percentage < 0.05
  const legend = {
    top: "15px",
    right: legendPadding,
    width: "160px",
  }

  const onMouseEnter = () => {
    if (totalLabelTooltip) setIsTooltip(true)
  }

  const onMouseLeave = () => {
    if (totalLabelTooltip) setIsTooltip(false)
  }

  return (
    <MaybeTooltip
      isTooltip={isTooltip}
      placement="top"
      title={totalLabelTooltip}
      PopperProps={{
        popperOptions: {
          modifiers: {
            flip: {enabled: false},
            offset: {
              enabled: true,
              offset: "-250px, -75px",
            },
          },
        },
      }}
    >
      <PieChart className={classes.chartWrapper} height={200} width={370}>
        {hasNoData && (
          <g>
            <circle cx={90} cy={105} fill="#F1F3F6" r={outerRadius} />
            <circle cx={90} cy={105} fill="#ffffff" r={innerRadius} />
          </g>
        )}
        <text
          className={classes.total}
          dominantBaseline="auto"
          textAnchor="middle"
          x="90"
          y="105"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <title>{total.toLocaleString()}</title>
          {roundNumberForPieChart(total)}
        </text>
        <text
          className={classes.totalLabel}
          dominantBaseline="hanging"
          textAnchor="middle"
          x="90"
          y="112"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {totalLabel}
        </text>
        {totalLabel2 && (
          <text
            className={classes.totalLabel}
            dominantBaseline="hanging"
            textAnchor="middle"
            x="90"
            y="123"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {totalLabel2}
          </text>
        )}
        <Pie
          cx={85}
          cy={100}
          data={pieData}
          dataKey="value"
          fill="#8884d8"
          innerRadius={innerRadius}
          isAnimationActive={false}
          label={RenderCustomizedLabel}
          labelLine={false}
          outerRadius={outerRadius}
          paddingAngle={pieData.length <= 1 ? 0 : 1}
          stroke={pieData.length <= 1 ? colors[0] : undefined}
        >
          {data.map((entry, index) => (
            <Cell fill={colors[index % colors.length]} key={`cell-${index}`} />
          ))}
        </Pie>
        <Tooltip formatter={tooltipFormatter} />
        <Legend
          content={<CustomizedLegend title={legendTitle} />}
          layout="vertical"
          verticalAlign="middle"
          wrapperStyle={legend}
        />
      </PieChart>
    </MaybeTooltip>
  )
}

StatsPie.propTypes = {
  colors: arrayOf(string),
  data: arrayOf(
    exact({
      name: string,
      tooltip: string,
      value: number,
    })
  ),
  legendTitle: string,
  total: number.isRequired,
  totalLabel: string.isRequired,
  totalLabel2: string,
  totalLabelTooltip: string,
}

StatsPie.defaultProps = {
  colors: INSIGHTS_COLORS,
}

const statsPieStyles = makeStyles(() => ({
  chartWrapper: {
    margin: "25px auto 0 auto",
    "& li.recharts-legend-item": {
      marginBottom: 5,
      textIndent: -18,
    },
  },
  total: {
    fontSize: 24,
    fontWeight: "bold",
  },
  totalLabel: {
    fontSize: 9,
  },
}))

export default StatsPie
