// This file was generated automatically from character-01.svg with the following command:
// ./svg-converter.js src/components/superhero/character-01.svg
import SuperheroSvg from "./superhero-svg"

const Character01 = props => (
  <SuperheroSvg viewBox="180 17 160 270" {...props}>
    <g id="Layer_1">
      <g>
        <g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M247.27,276.4c-5.17,0-12.37-0.5-20.21-2.55l-0.1-0.04c-0.09-0.05-2.28-1.25-2.16-2.96
  					c0.09-1.29,1.39-2.38,3.98-3.32l0.07-0.02c0.11-0.02,11.35-2.09,12.87-6.15l0.84,0.31c-1.67,4.45-12.65,6.55-13.51,6.71
  					c-2.69,0.99-3.31,1.92-3.36,2.53c-0.07,1.03,1.38,1.93,1.65,2.09c14.31,3.73,26.4,2.25,27.76,2.07
  					c1.47-1.45-0.46-7.74-2.06-11.45l0.82-0.35c0.01,0.03,1.11,2.58,1.88,5.34c1.09,3.91,1.04,6.28-0.16,7.24l-0.09,0.07l-0.12,0.02
  					C255.3,275.94,252.2,276.4,247.27,276.4z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M231.62,274.72l-0.56-0.7c1.01-0.81,1.55-1.72,1.6-2.72c0.09-1.87-1.56-3.5-1.58-3.51l0.62-0.64
  					c0.08,0.08,1.96,1.91,1.85,4.19C233.49,272.61,232.84,273.75,231.62,274.72z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M248.23,273.2c-3.51,0-8.41-0.24-15.21-1l0.1-0.89c18.57,2.06,22.87,0.26,22.91,0.24l0.38,0.81
  					C256.29,272.41,254.51,273.2,248.23,273.2z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M232.88,270.94l-0.16-0.88c13.46-2.4,13.95-7.39,13.95-7.44l0.89,0.04
  					C247.55,262.9,247.15,268.4,232.88,270.94z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M235.77,269.09c-0.74,0-1.67-0.56-1.98-0.76l0.49-0.75c0.6,0.39,1.34,0.68,1.55,0.62
  					c0-0.01,0-0.02,0.01-0.04c0.18-1.56,0.64-1.51,2.86-1.26c0.87,0.1,1.5,0.17,1.66,0.04c0.17-0.13,0.24-0.76,0.34-1.63
  					c0.08-0.73,0.36-1.16,0.81-1.29c0.88-0.25,1.93,0.95,2.22,1.32l-0.7,0.56c-0.49-0.61-1.11-1.07-1.28-1.02
  					c0,0-0.12,0.08-0.17,0.54c-0.28,2.48-0.51,2.66-2.99,2.38c-0.88-0.1-1.64-0.19-1.78-0.1c-0.03,0.05-0.06,0.33-0.09,0.58
  					c-0.04,0.35-0.22,0.61-0.5,0.74C236.08,269.07,235.93,269.09,235.77,269.09z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#498EC1"}}
            d="M240.53,215.62l8.12-61.9l21.56-2.42l-4.97,18.16l-4.47,52.25c0,0,1.15,16.58,1.85,42.01
  				l-25.81-1.63C236.81,262.09,238.59,234.87,240.53,215.62z"
          />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M284.74,278.32l-0.05,0c-0.11-0.01-2.59-0.24-3.15-1.87c-0.43-1.22,0.34-2.73,2.34-4.62
  					l0.06-0.05c0.02-0.02,2.46-1.64,4.83-3.79c3.07-2.79,4.64-5.19,4.56-6.95l0.89-0.04c0.23,4.75-9.02,11.02-9.75,11.51
  					c-2.08,1.97-2.28,3.07-2.08,3.65c0.3,0.87,1.74,1.2,2.34,1.27c14.63-2.24,25.15-8.39,26.31-9.09c0.93-2.12-3.06-6.86-4.71-8.54
  					l0.64-0.62c0.66,0.67,6.42,6.68,4.81,9.69l-0.06,0.1l-0.1,0.06c-0.11,0.07-11.15,6.9-26.82,9.29L284.74,278.32z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M289.33,277.32l-0.79-0.42c0.61-1.14,0.74-2.19,0.4-3.13c-0.66-1.76-2.82-2.59-2.84-2.6
  					l0.31-0.84c0.1,0.04,2.55,0.98,3.36,3.12C290.22,274.63,290.07,275.94,289.33,277.32z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M289.62,274.44l-0.26-0.85c17.87-5.46,21.1-8.82,21.13-8.85l0.67,0.59
  					C311.03,265.47,307.89,268.86,289.62,274.44z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M288.99,273.34l-0.49-0.74c11.41-7.53,9.88-12.31,9.86-12.36l0.84-0.31
  					C299.28,260.15,301.1,265.36,288.99,273.34z"
            />
          </g>
          <g>
            <path
              style={{fill: "#09283F"}}
              d="M289.84,270.68c-0.44,0-0.85-0.06-1.05-0.09l0.15-0.88c0.71,0.12,1.5,0.1,1.67-0.04
  					c0-0.01-0.01-0.02-0.01-0.04c-0.17-0.58-0.32-1.07,0.1-1.48c0.31-0.29,0.84-0.45,2.02-0.81c0.84-0.25,1.44-0.44,1.54-0.62
  					c0.1-0.19-0.08-0.79-0.33-1.63c-0.21-0.7-0.13-1.21,0.23-1.51c0.71-0.58,2.14,0.11,2.57,0.33l-0.42,0.79
  					c-0.69-0.37-1.45-0.55-1.58-0.43c0,0-0.07,0.12,0.06,0.56c0.36,1.2,0.54,1.8,0.27,2.31c-0.28,0.51-0.88,0.7-2.07,1.06
  					c-0.85,0.26-1.58,0.48-1.68,0.61c-0.01,0.06,0.07,0.33,0.14,0.56c0.1,0.33,0.04,0.64-0.16,0.88
  					C290.99,270.59,290.39,270.68,289.84,270.68z"
            />
          </g>
        </g>
        <g>
          <path
            style={{fill: "#5397D1"}}
            d="M285.35,215.76l-2.44-58.15l-0.71-14.37l-4.74-1.24l-25.24-7.09l-2.93,15.81
  				c-0.2,0.79-0.36,1.59-0.47,2.42l-0.25,1.31l0.13,0.02c-0.01,0.17-0.03,0.34-0.03,0.51c-0.37,9.12,5.26,17.02,13.06,19.11
  				l0.11,0.36l3.56,37.95c0,0,0.9,8.94,3.61,14.49l15.93,34.91l24.71-3.08l-21.01-35.04C288.64,223.67,285.89,219.68,285.35,215.76z
  				"
          />
        </g>
        <path
          style={{fill: "#113353"}}
          d="M262.94,104.74c0,0-0.62,10.63,13.14,10.25c0,0,7.6,1.57,12.23,6.5c6.12,6.5,5.35-1.91,10.32-2.29
  			c3.62-0.28,3.06-4.59,2.29-5.73c-1.71-2.56,0.76-3.69,2.29-4.97c2.29-1.91,0-5.35-3.06-7.64c-3.06-2.29-11.26-6.14-12.99-13.38"
        />
        <path
          style={{fill: "#8B725E"}}
          d="M272.06,76.28c0.53,0,1.05,0.03,1.56,0.08c0.31,12.28,11.45,18.71,13.55,19.82
  			c-1.84,6.62-7.91,11.49-15.12,11.49c-8.67,0-15.69-7.02-15.69-15.69S263.4,76.28,272.06,76.28z"
        />
        <g>
          <path
            style={{fill: "#8B725E"}}
            d="M259.2,113.02c-2.08-0.85-4.16-1.7-6.24-2.56c-3.31-1.36-6.63-2.72-9.94-4.07
  				c-0.76-0.31-1.52-0.62-2.28-0.94c0.69,0.69,1.37,1.37,2.06,2.06c-0.47-2.11-0.95-4.23-1.42-6.34c-0.75-3.35-1.51-6.71-2.26-10.06
  				c-0.17-0.77-0.35-1.55-0.52-2.32c-0.34-1.51-2.16-2.53-3.63-2.06c-1.58,0.51-2.42,2.01-2.06,3.63c0.49,2.2,0.99,4.41,1.48,6.61
  				c0.76,3.4,1.53,6.8,2.29,10.19c0.17,0.76,0.28,1.56,0.58,2.28c0.4,0.94,1.09,1.37,1.98,1.74c0.1,0.04,0.21,0.09,0.31,0.13
  				c0.41,0.17,0.81,0.33,1.22,0.5c3.16,1.29,6.31,2.59,9.47,3.88c2.42,0.99,4.82,2.05,7.27,2.98c0.04,0.02,0.08,0.03,0.12,0.05
  				c0.66,0.27,1.69,0.05,2.27-0.3c0.61-0.36,1.2-1.06,1.36-1.76c0.17-0.75,0.13-1.6-0.3-2.27
  				C260.52,113.68,259.96,113.33,259.2,113.02L259.2,113.02z"
          />
        </g>
        <path
          style={{fill: "#8B725E"}}
          d="M256.37,91.93c0,6.97,4.9,12.88,11.19,14.93l0,2.22l5.79,0l0-1.5c4.35-0.35,8.2-2.47,10.83-5.64
  			c0.56,0.4,1.25,0.64,1.99,0.64c1.89,0,3.43-1.54,3.43-3.43c0-1.5-1.09-2.43-2.43-2.9c0-0.02,0.12-0.38,0.13-0.4
  			c-0.11-0.06-0.22-0.12-0.32-0.18l-30.4-6.19C256.45,90.29,256.37,91.1,256.37,91.93z"
        />
        <path
          style={{fill: "#8B725E"}}
          d="M279.64,87.93l-20.86-4.25c-1.12,1.76-1.89,3.76-2.23,5.91l30.09,6.12
  			C283.49,94.03,281,91.27,279.64,87.93z"
        />
        <rect x="267.44" y="108.99" style={{fill: "#5EBDAE"}} width="6.08" height="2.5" />
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M283.66,120.58c0.67-4.9-2.76-9.42-7.67-10.09l-11.49-0.93c-6.22-0.38-9.2,2.01-10.09,7.67
  				l-2.96,17.86l29.81,0L283.66,120.58z"
          />
          <polygon
            style={{fill: "#5EBDAE"}}
            points="281.69,132.17 251.44,135.08 250.67,139.73 280.46,143.37 			"
          />
        </g>
        <circle style={{fill: "#0D1E36"}} cx="264.76" cy="88.43" r="1.25" />
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M266.1,85.07c-0.23-0.23-0.56-0.39-0.87-0.49c-0.74-0.23-1.51-0.06-2.18,0.3
  				c-0.3,0.16-0.03,0.63,0.27,0.46c0.56-0.3,1.19-0.43,1.79-0.24c0.05,0.02,0.1,0.03,0.15,0.05c-0.05-0.02,0.03,0.01,0.04,0.02
  				c0.02,0.01,0.05,0.02,0.07,0.04c0.07,0.04,0.15,0.08,0.22,0.13c0.02,0.02,0.05,0.04,0.07,0.05c-0.08-0.05,0,0,0.02,0.01
  				c0.02,0.01,0.03,0.03,0.04,0.04C265.96,85.7,266.34,85.32,266.1,85.07L266.1,85.07z"
          />
        </g>
        <path
          style={{fill: "#113353"}}
          d="M273.63,76.36c7.93,0.78,14.13,7.47,14.13,15.61c0,1.46,1.31,3.71,0.94,5.05
  			C286.02,95.88,273.02,88.23,273.63,76.36z"
        />
        <g>
          <path
            style={{fill: "#8B725E"}}
            d="M277.51,116.45c1.78,2.93,3.56,5.85,5.34,8.78c2.81,4.62,5.62,9.25,8.44,13.87
  				c0.64,1.06,1.29,2.11,1.93,3.17c-0.1-0.76-0.2-1.51-0.3-2.27c-0.78,2.03-1.55,4.06-2.33,6.08c-1.23,3.22-2.46,6.43-3.7,9.65
  				c-0.28,0.74-0.57,1.49-0.85,2.23c-0.26,0.67-0.05,1.67,0.3,2.27c0.36,0.61,1.06,1.2,1.76,1.36c0.75,0.17,1.6,0.13,2.27-0.3
  				c0.68-0.44,1.07-1.01,1.36-1.76c1.16-3.04,2.33-6.08,3.49-9.12c0.79-2.06,1.58-4.12,2.36-6.17c0.59-1.55,1.65-3.31,0.73-4.95
  				c-1.19-2.12-2.53-4.16-3.8-6.24c-2.42-3.98-4.85-7.97-7.27-11.95c-1.52-2.5-3.02-5.02-4.57-7.51c-0.02-0.04-0.05-0.08-0.07-0.12
  				c-0.8-1.32-2.68-1.93-4.03-1.06C277.25,113.27,276.66,115.04,277.51,116.45L277.51,116.45z"
          />
        </g>
        <path
          style={{fill: "#113353"}}
          d="M273.65,76.46c0,0-4.34,4.86-13.1,4.86C260.55,81.32,265.54,75.4,273.65,76.46z"
        />
        <circle style={{fill: "#0D1E36"}} cx="275.48" cy="90.22" r="1.25" />
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M275.55,86.93c0.08-0.03,0.17-0.04,0.26-0.06c0.02,0,0.08-0.01,0,0c0.02,0,0.05-0.01,0.07-0.01
  				c0.05,0,0.1-0.01,0.14-0.01c0.12-0.01,0.24,0,0.36,0.01c0.12,0.01,0.22,0.03,0.36,0.07c0.14,0.04,0.25,0.08,0.37,0.14
  				c0.32,0.16,0.59,0.39,0.8,0.68c0.09,0.12,0.23,0.17,0.37,0.1c0.11-0.07,0.18-0.25,0.1-0.37c-0.47-0.64-1.13-1.05-1.92-1.14
  				c-0.34-0.04-0.72-0.02-1.05,0.08C275.08,86.51,275.22,87.03,275.55,86.93L275.55,86.93z"
          />
        </g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M270.41,93.25c-0.36-0.71-1.12-1.27-1.94-1.15c-0.44,0.06-0.82,0.3-1.17,0.56
  				c-0.14,0.11,0.06,0.3,0.19,0.19c0.48-0.38,1.09-0.65,1.7-0.42c0.35,0.14,0.64,0.42,0.85,0.72c0.05,0.07,0.1,0.15,0.14,0.22
  				C270.26,93.54,270.49,93.4,270.41,93.25L270.41,93.25z"
          />
        </g>
        <g>
          <g>
            <path
              style={{fill: "#0C1B2F"}}
              d="M272.83,98.01c-1.75,0.49-3.86-0.12-4.76-0.44c-0.12,0.74-0.23,1.8-0.05,2.47l0,0
  					c0.14,0.54,0.48,1.03,0.97,1.36c0.33-0.55,0.79-1,1.34-1.32l2.09,0.03C272.43,100.11,272.65,99.61,272.83,98.01z"
            />
            <path
              style={{fill: "#FFFFFF"}}
              d="M272.92,97.09c0,0-1.8,0.44-4.68-0.39c0,0-0.09,0.37-0.17,0.87c0.89,0.32,3,0.93,4.76,0.44
  					C272.86,97.73,272.89,97.43,272.92,97.09z"
            />
          </g>
          <path
            style={{fill: "#EE5C60"}}
            d="M272.34,100.46c0.13-0.27,0.2-0.55,0.22-0.83c-0.54-0.06-1.1-0.01-1.62,0.17
  				c-0.83,0.3-1.5,0.87-1.94,1.59c0.08,0.05,0.17,0.11,0.26,0.15C270.41,102.1,271.79,101.61,272.34,100.46z"
          />
        </g>
        <path
          style={{fill: "#8B725E"}}
          d="M238.17,84.85c0.06,0.33,0.13,0.66,0.19,0.99c0.62,3.21,1.23,6.41,1.85,9.62l-5.7,1.53
  			c-0.09-0.46-0.18-0.91-0.26-1.37c-0.6-3.14-1.21-6.28-1.81-9.42c-0.42-0.88-1.69-2.18-2.32-5.07c-0.68-3.1-0.04-4.8,1.25-6.6
  			c1.72-2.39,4.31-1.04,4.31-1.04c1.89,1.61,1.11,4.43,0.79,5.06c-0.48,0.96-0.06,1.76,0.2,1.88"
        />
        <path
          style={{fill: "#5EBDAE"}}
          d="M283.34,127.98c0,0,1.15-1.53,2.68-1.15c1.53,0.38,2.4-0.42,2.68-1.53
  			c0.38-1.53,2.29-1.53,3.06-0.76l-9.55-11.85c0,0-1.53-2.29-3.76-1.51c-1.66,0.58-2.74,2.27-2.36,3.8L283.34,127.98z"
        />
        <path
          style={{fill: "#5EBDAE"}}
          d="M247.14,106.92c0,0,0.07,1.91-1.36,2.57c-1.43,0.66-1.61,1.83-1.12,2.87
  			c0.66,1.43-0.83,2.63-1.9,2.51l14.87,3.23c0,0,2.63,0.83,3.87-1.19c0.92-1.49,0.71-3.49-0.55-4.44L247.14,106.92z"
        />
        <line
          style={{fill: "none", stroke: "#FFFFFF", strokeWidth: "5", strokeMiterlimit: "10"}}
          x1="250.66"
          y1="139.64"
          x2="282.2"
          y2="143.27"
        />

        <rect
          x="259.76"
          y="140.06"
          transform="matrix(0.9941 0.1089 -0.1089 0.9941 16.9303 -27.7052)"
          style={{fill: "#F7D63E", stroke: "#F7D63E", strokeWidth: "1.194", strokeMiterlimit: "10"}}
          width="4.66"
          height="2.15"
        />
        <g>
          <g>
            <path
              style={{fill: "#113353"}}
              d="M249.73,54.36c-0.36,0.67-0.71,1.33-1.07,2c-0.94,1.76-1.88,3.52-2.83,5.28
  					c-1.34,2.49-2.67,4.98-4.01,7.48c-1.54,2.87-3.07,5.73-4.61,8.6c-1.54,2.88-3.09,5.76-4.63,8.64c-1.36,2.53-2.71,5.07-4.07,7.6
  					c-0.98,1.83-1.97,3.67-2.95,5.5c-0.41,0.76-0.81,1.51-1.22,2.27c-0.06,0.12-0.12,0.23-0.19,0.34c-0.11,0.18-0.22,0.36-0.34,0.53
  					c-0.07,0.1-0.31,0.39-0.1,0.14c-0.1,0.12-0.19,0.23-0.29,0.35c-0.22,0.25-0.46,0.48-0.71,0.7c-0.08,0.07-0.24,0.18-0.05,0.05
  					c-0.07,0.05-0.13,0.1-0.2,0.15c-0.15,0.1-0.31,0.2-0.47,0.29c-0.09,0.05-0.18,0.09-0.27,0.14c-0.19,0.11,0.15-0.05-0.05,0.02
  					c-0.2,0.07-0.4,0.14-0.61,0.19c-0.09,0.02-0.18,0.04-0.27,0.06c-0.22,0.05,0.15,0-0.08,0.01c-0.24,0.01-0.47,0.02-0.7,0
  					c-0.07,0-0.31-0.04-0.12-0.01c-0.11-0.02-0.22-0.04-0.33-0.06c-0.27-0.06-0.54-0.14-0.8-0.24c-0.14-0.06-0.36-0.16-0.58-0.28
  					c-0.3-0.16-0.59-0.34-0.87-0.54c-0.16-0.12-0.35-0.29-0.53-0.47c-0.09-0.09-0.17-0.18-0.25-0.27c-0.04-0.04-0.08-0.09-0.11-0.14
  					c0.07,0.09,0-0.01-0.03-0.05c-0.13-0.2-0.25-0.41-0.36-0.62c-0.06-0.12,0,0.01,0.01,0.03c-0.02-0.05-0.04-0.1-0.06-0.15
  					c-0.04-0.11-0.08-0.23-0.11-0.34c-0.03-0.11-0.06-0.23-0.08-0.34c-0.01-0.05-0.05-0.32-0.03-0.19c0.01,0.13-0.01-0.15-0.01-0.19
  					c-0.01-0.11-0.01-0.23-0.01-0.34c0-0.21,0.02-0.41,0.04-0.62c0.01-0.14-0.01,0.06-0.01,0.07c0.01-0.05,0.01-0.09,0.02-0.14
  					c0.02-0.12,0.04-0.24,0.07-0.36c0.08-0.36,0.18-0.71,0.31-1.06c0.03-0.07,0.05-0.14,0.08-0.22c0.02-0.04,0.11-0.27,0.04-0.1
  					c-0.08,0.18,0.04-0.08,0.05-0.11c0.03-0.07,0.06-0.13,0.09-0.2c0.12-0.25,0.25-0.5,0.4-0.73c0.34-0.52,0.1-1.27-0.41-1.57
  					c-0.56-0.33-1.23-0.11-1.57,0.41c-1.72,2.64-2.03,6.4,0.27,8.78c1.62,1.68,4.18,2.7,6.5,2.17c1.64-0.37,2.97-1.51,3.96-2.82
  					c0.48-0.64,0.84-1.35,1.21-2.05c0.79-1.48,1.59-2.96,2.38-4.44c1.27-2.36,2.53-4.73,3.8-7.09c1.53-2.86,3.07-5.73,4.6-8.59
  					c1.59-2.96,3.18-5.93,4.77-8.89c1.45-2.71,2.9-5.42,4.35-8.12c1.1-2.05,2.2-4.1,3.3-6.15c0.54-1.01,1.09-2.01,1.62-3.03
  					c0.02-0.04,0.05-0.09,0.07-0.13C252.41,54.22,250.43,53.06,249.73,54.36L249.73,54.36z"
            />
          </g>
          <g>
            <defs>
              <path
                id="SVGID_1_"
                d="M288.26,77.03c0,0-7.63-9.43-21.67-6.42c0,0-6.99-12.95-21.72-11.97c0,0-5.01-11.19-18.23-13.5
  						c0,0,2.31-8.61-12.93-16.07c0,0,28.44-15.46,54.04,1.55C267.76,30.63,294.54,46.23,288.26,77.03z"
              />
            </defs>
            <clipPath id="e9459728-SVGID_00000111886969300284420780000001875735689229781665_">
              <use xlinkHref="#SVGID_1_" style={{overflow: "visible"}} />
            </clipPath>
            <path
              style={{
                clipPath: "url(#SVGID_00000111886969300284420780000001875735689229781665_)",
                fill: "#EA6569",
              }}
              d="M288.37,76.99
  					c0,0-7.63-9.43-21.67-6.42c0,0-6.99-12.95-21.72-11.97c0,0-5.01-11.19-18.23-13.5c0,0,2.31-8.61-12.93-16.07
  					c0,0,28.44-15.46,54.04,1.55C267.87,30.59,294.05,43.13,288.37,76.99z"
            />
          </g>
        </g>
        <path
          style={{fill: "#8B725E"}}
          d="M238.51,80.37c-0.28,0.05-0.63-0.79-0.63-0.79c-0.24-1.58-1.92-2.74-2.56-1.9
  			c-0.64,0.84-0.63,1.67-0.63,1.67c-0.23,1.71,0.47,3.23,0.88,4.11l-0.49,3.2L238.51,80.37z"
        />
        <path
          style={{fill: "#8B725E"}}
          d="M283.18,165.39c0,0,0.53-0.33,0.68-0.09c0.15,0.24,0.07,1.14-0.84,1.69
  			c-0.6,0.36-2.75,2.31-2.04,4.69c0,0,1.44,2.54,4.14,1.45c2.03-0.82,3.47-1.91,4.55-4.88c1.01-2.78,0.65-4.55,0.77-5.52
  			c1.17-2.97,2.34-5.94,3.51-8.91c0.17-0.43,0.34-0.86,0.51-1.3l-5.55-1.78c-1.2,3.03-2.39,6.07-3.59,9.1
  			c-0.12,0.31-0.25,0.62-0.37,0.94c-0.91,0.3-3.01,1.09-4.1,2.42c0,0-0.61,0.56-0.79,1.6S281.88,166.28,283.18,165.39z"
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Character01
