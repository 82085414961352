import {arrayOf, func, object, shape, string} from "prop-types"

import MessageLibrary from "../content-library/message-library"

const setMessages = payload => ({type: "MESSAGES_SET", payload})

const AddMessagesStep = ({currentRow: {objective, messages}, dispatch}) => (
  <MessageLibrary
    objective={objective}
    onChange={value => dispatch(setMessages(value))}
    selectedMessages={messages}
  />
)

AddMessagesStep.propTypes = {
  currentRow: shape({
    objective: shape({id: string}),
    messages: arrayOf(object),
  }),
  dispatch: func.isRequired,
}

export default AddMessagesStep
