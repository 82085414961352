import {Badge, Tooltip} from "@mui/material"
import {Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import makeStyles from "@mui/styles/makeStyles"
import {replace} from "connected-react-router"
import {FaExclamation} from "react-icons/fa"
import {useDispatch} from "react-redux"

import {getCtaLinkErrorForBlock} from "components/content-block-editor/plugins/cta/cta-readonly"

import {useTemplateContentContext} from "../../contexts/template-content-context"
import ContentLibraryImage from "../content-library/content-library-image"
import ArchiveCornerBanner from "../templates-list/archive-corner-banner"

const useStyles = makeStyles(theme => ({
  pages: {
    minHeight: 150,
    display: "grid",
    gridColumnGap: theme.spacing(6),
    gridRowGap: theme.spacing(2),
    msGridRowGap: theme.spacing(1),
    fallbacks: [{display: "-ms-grid"}],
    gridTemplateColumns: "repeat(auto-fill, 150px)",
    msGridTemplateColumns: "repeat(auto-fill, 150px)",

    "& div[role=button]": {
      cursor: "pointer",
    },
  },
}))

const pageHasInvalidCta = (templatePage, templatePages) => {
  const content_variables = templatePage.content_variables
  const page = templatePage.page

  return page.contentBlocks
    .filter(block => ["cta", "image"].includes(block.type))
    .some(
      block =>
        !!getCtaLinkErrorForBlock({
          contentBlock: block,
          content_variables: content_variables,
          isEditMode: true,
          template: {id: templatePage.templateId, templatePages: templatePages},
        })
    )
}

const TemplatePages = () => {
  const {templateId, templatePages, templatePagesLoading} = useTemplateContentContext()
  const dispatch = useDispatch()
  const classes = useStyles()

  const onEditPage = pageSlug =>
    dispatch(replace(`/admin/templates/${templateId}/edit/${pageSlug}`))

  const showWarningBadge = () => (
    <Tooltip
      title="This page contains a CTA with an invalid or incomplete link."
      sx={{position: "absolute", right: 0, top: 0}}
    >
      <Badge badgeContent={<FaExclamation size={10} />} color="error" />
    </Tooltip>
  )

  return (
    <>
      <div className={classes.pages}>
        {templatePagesLoading ? (
          <div>
            <Skeleton animation="wave" height={112.5} variant="rectangular" />
            <Skeleton animation="wave" variant="text" />
          </div>
        ) : (
          templatePages.map(templatePage => (
            <div
              key={templatePage.page.id}
              onClick={() => onEditPage(templatePage.page.slug)}
              role="button"
              title={templatePage.page.contentName}
            >
              {templatePage.page.isArchived && <ArchiveCornerBanner />}
              <ContentLibraryImage contentType="page" id={templatePage.page.id} raiseable={false} />
              <Typography variant="body1" sx={{position: "relative"}}>
                {templatePage.page.contentName}
                {pageHasInvalidCta(templatePage, templatePages) && showWarningBadge()}
              </Typography>
            </div>
          ))
        )}
      </div>
    </>
  )
}

export default TemplatePages
