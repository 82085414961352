import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Drawer,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import pick from "lodash/pick"
import {func, object} from "prop-types"
import {useEffect, useState} from "react"
import {MdExpandMore as ExpandIcon} from "react-icons/md"

import {
  EditorModes,
  useContentBlockEditor,
} from "components/content-block-editor/content-block-editor-context"
import {DeviceControlsSidebar} from "components/content-block-editor/device-controls"
import ConfirmDialog from "components/dialogs/confirm-dialog"
import ContainerCssSettings from "components/templates/container-css-settings"
import ContainerStyleSettings from "components/templates/container-style-settings"
import WidgetSettings from "components/templates/widget-settings"

const DRAWER_WIDTH = 300

const useStyles = makeStyles(theme => ({
  drawer: {
    transition: theme.transitions.create("all"),
  },
  drawerOpen: {
    flexBasis: DRAWER_WIDTH,
  },
  drawerClosed: {
    flexBasis: 0,
  },
  drawerPaper: {
    backgroundColor: "rgb(234,236,240)",
    boxShadow: "inset 0 0 10px rgb(208, 212, 220, 0.8)",
    borderLeft: "white",
    width: DRAWER_WIDTH,
    marginTop: 88,
    paddingBottom: 88,
  },
  expansionContent: {
    padding: 0,
  },
  expansionPanel: {
    backgroundColor: "transparent",
    margin: 0,
  },
  customCssButton: {
    margin: `20px -2px 0 -2px`,
  },
  settingsContainer: {
    padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
  },
  sidebarButton: {
    borderRadius: 0,
  },
  tab: {
    minWidth: 100,
  },
}))

const CrossChannelEngagementSidebar = ({container, onUpdateContentContainer}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState("styles")
  const [isUnsavedDialogOpen, setIsUnsavedDialogOpen] = useState(false)
  const {
    hasUnsavedContentBlockDataChanges,
    isPreviewMode,
    selectedBlock,
    setMode,
  } = useContentBlockEditor()
  const SELECTED_BLOCK_TAB_INDEX = 0
  const CONTAINER_TAB_INDEX = 1
  const [tabIndex, setTabIndex] = useState(CONTAINER_TAB_INDEX)

  const onConfirmCloseWidgetForm = () => {
    setIsUnsavedDialogOpen(false)
    setTabIndex(CONTAINER_TAB_INDEX)
  }

  const toggleContentBlockEditorMode = () => {
    setMode(isPreviewMode ? EditorModes.Edit : EditorModes.Preview)
  }

  const onTabChange = tabIndex => {
    if (!(selectedBlock?.type !== "text" && hasUnsavedContentBlockDataChanges())) {
      setTabIndex(tabIndex)
    } else {
      setIsUnsavedDialogOpen(true)
    }
  }

  useEffect(() => {
    const index = selectedBlock ? SELECTED_BLOCK_TAB_INDEX : CONTAINER_TAB_INDEX

    setTabIndex(index)
  }, [selectedBlock])

  if (!container?.id) {
    return null
  }

  return (
    <Drawer
      anchor="right"
      open={true}
      classes={{
        docked: isPreviewMode ? classes.drawerClosed : classes.drawerOpen,
        paper: classes.drawerPaper,
      }}
      className={classes.drawer}
      variant="persistent"
    >
      <DeviceControlsSidebar layoutBasis={container?.layoutBasis} />
      <Button
        className={classes.sidebarButton}
        color="primary"
        fullWidth={true}
        onClick={toggleContentBlockEditorMode}
        size="small"
        variant="contained"
      >
        Preview
      </Button>
      {selectedBlock && (
        <Tabs
          indicatorColor="primary"
          textColor="inherit"
          onChange={(e, tabIndex) => onTabChange(tabIndex)}
          value={tabIndex}
          variant="fullWidth"
        >
          <Tab
            className={cx(classes.tab)}
            data-testid="content-block-tab"
            label={selectedBlock?.type || "Widget"}
          />
          <Tab className={classes.tab} data-testid="container-tab" label="Container" />
        </Tabs>
      )}
      <div className={classes.settingsContainer}>
        {selectedBlock && tabIndex === SELECTED_BLOCK_TAB_INDEX && (
          <WidgetSettings
            classes={pick(classes, ["settingsContainer", "expansionPanel", "expansionContent"])}
          />
        )}
        {container && tabIndex === CONTAINER_TAB_INDEX && (
          <>
            <Accordion
              classes={{root: classes.expansionPanel}}
              elevation={0}
              expanded={expanded === "styles"}
              key="styles"
              onChange={() => setExpanded("styles")}
            >
              <AccordionSummary
                classes={{root: classes.expansionContent}}
                expandIcon={<ExpandIcon />}
              >
                <Typography>Styles</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{root: classes.expansionContent}}>
                <ContainerStyleSettings
                  containerType="content_container"
                  styles={container.styles || {}}
                  onSubmit={styles => onUpdateContentContainer({styles})}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              classes={{root: classes.expansionPanel}}
              elevation={0}
              expanded={expanded === "css"}
              key="css"
              onChange={() => setExpanded("css")}
            >
              <AccordionSummary
                classes={{root: classes.expansionContent}}
                expandIcon={<ExpandIcon />}
              >
                <Typography>Custom CSS</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{root: classes.expansionContent}}>
                <ContainerCssSettings
                  containerType="content_container"
                  initialValues={{css: container.css || ""}}
                  onSubmit={css => onUpdateContentContainer(css)}
                  selector="&"
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
      {isUnsavedDialogOpen && (
        <ConfirmDialog
          cancelText="Cancel"
          continueText="Abandon Changes"
          content="You have unsaved changes for this widget."
          onClose={() => setIsUnsavedDialogOpen(false)}
          onConfirm={onConfirmCloseWidgetForm}
          open={true}
          title="Abandon changes?"
        />
      )}
    </Drawer>
  )
}

CrossChannelEngagementSidebar.defaultProps = {isPreviewMode: false}

CrossChannelEngagementSidebar.propTypes = {
  container: object,
  onUpdateContentContainer: func,
}

export default CrossChannelEngagementSidebar
