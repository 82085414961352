import {FILE_ADDED, FILE_LIST_SET} from "./file-browser-actions"

const initialState = {
  list: [],
}

export default function fileBrowser(state = initialState, action) {
  switch (action.type) {
    case FILE_LIST_SET: {
      return {
        ...state,
        list: action.payload,
      }
    }

    case FILE_ADDED: {
      return {
        ...state,
        list: [action.payload].concat(state.list),
      }
    }

    default:
      return state
  }
}
