import {Checkbox, Container, FormControl, MenuItem, TextField} from "@mui/material"
import {array, bool, number, shape, string} from "prop-types"
import React, {useContext, useEffect} from "react"

import DOSelect from "components/do-select/do-select"
import {RewardSetFormContext} from "components/reward/reward-set-form-context-provider"
import {compileValues, isDirty} from "components/reward/reward-set-form-helpers"
import {
  ConditionCard,
  ConditionCardContent,
  Form,
} from "components/reward/reward-step-conditions/styled-components"
import {RewardsContext} from "components/reward/rewards-context-provider"

import {nonEmptyArray, validPositiveInteger} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const MobileAppDownload = ({initialValues, isEditable}) => {
  const {dispatch} = useContext(RewardSetFormContext)
  const {templates} = useContext(RewardsContext)

  // Form config
  const {field, inputs} = useForm({
    initialValues: {
      templateIds: !!initialValues.meta.templateIds ? initialValues.meta.templateIds : [],
      value: initialValues.value,
    },
    validators: {
      templateIds: [nonEmptyArray],
      value: [validPositiveInteger],
    },
  })

  // When inputs change, dispatch updated step state
  useEffect(() => {
    const {value, ...meta} = compileValues(inputs)
    const updated = {
      data: {
        condition: "mobile_app_download_link_clicked",
        id: initialValues.id,
        meta,
        value,
      },
      isDirty: isDirty(inputs),
      isValid: Object.values(inputs).reduce((acc, fieldObject) => {
        if (!acc) {
          return acc
        }

        if (fieldObject.name === "templateIds" && !nonEmptyArray(initialValues.meta.templateIds)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (fieldObject.name === "value" && !validPositiveInteger(initialValues.value)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        return "error" in fieldObject ? !fieldObject.error : false
      }, true),
    }

    dispatch({type: "SET_REWARD_STEP_FORM", rewardStepForm: updated})
  }, [dispatch, initialValues, inputs])

  return (
    templates?.length && (
      <ConditionCard>
        <ConditionCardContent>
          <Form id={`mobile-app-download-${initialValues.id}`}>
            <FormControl disabled={!isEditable} error={!!inputs.templateIds.error} fullWidth>
              <DOSelect
                id="template-id"
                label="Campaigns"
                multiple={true}
                {...field("templateIds")}
                renderValue={templateIds =>
                  templateIds
                    .map(id => {
                      const template = templates.find(t => t.id === id)

                      return template ? template.name : []
                    })
                    .join(", ")
                }
              >
                {templates.map(template => (
                  <MenuItem key={template.id} value={template.id}>
                    <Checkbox
                      checked={inputs.templateIds.value.includes(template.id)}
                      sx={{pointerEvents: "none"}}
                      color={"primary"}
                    />
                    {template.name.length < 32
                      ? template.name
                      : `${template.name.slice(0, 31)} ...`}
                  </MenuItem>
                ))}
              </DOSelect>
            </FormControl>

            <Container sx={{paddingTop: "1em"}} />
            <TextField
              disabled={!isEditable}
              label="Value"
              fullWidth={true}
              type="number"
              {...field("value")}
            />

            <Container sx={{paddingTop: "1em"}} />
          </Form>
        </ConditionCardContent>
      </ConditionCard>
    )
  )
}

MobileAppDownload.defaultProps = {
  initialValues: {
    condition: "mobile-app-download",
    meta: {
      templateIds: [],
    },
  },
  value: undefined,
  validDefaults: [],
}

MobileAppDownload.propTypes = {
  initialValues: shape({
    condition: string,
    id: string.isRequired,
    meta: shape({
      templateIds: array,
    }),
    value: number,
  }).isRequired,
  isEditable: bool,
}

export default MobileAppDownload
