import {InputAdornment, TableCell, TableRow, TextField, Tooltip} from "@mui/material"
import PropTypes from "prop-types"
import {useMemo, useState} from "react"
import {BsDashLg as DashIcon} from "react-icons/bs"

import {validNonnegativeInteger, validPositiveNumber} from "lib/field-validations"

const CardOnFileMetricRow = ({drivers, metric, onChange}) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const defaultValue = useMemo(() => {
    const defaultValue = drivers.find(d => d.key === metric.key)?.value

    if (!!defaultValue) {
      return metric.key === "card_interchange_rate" ? `${defaultValue}%` : `$${defaultValue}`
    }

    return null
  }, [drivers, metric])

  const tooltip = useMemo(() => {
    switch (metric.key) {
      case "average_card_order_volume":
        return "The average amount for a given card transaction."
      case "average_card_transactions_per_year":
        return "The average number of transactions expected on the card, per site."
      case "card_interchange_rate":
        return "The percent amount the FI expects to receive on card transactions."
      default:
        return ""
    }
  }, [metric])

  const handleChange = ({target: {value}}) => {
    const message =
      metric.key === "card_interchange_rate"
        ? validPositiveNumber(value)
        : validNonnegativeInteger(value)

    const updatedValue =
      metric.key === "card_interchange_rate" ? value : !!message ? value : parseInt(value, 10)

    const changed = {
      ...metric,
      hasError: !!message,
      isDirty: true,
      value: updatedValue,
    }

    setErrorMessage(message)

    onChange(changed)
  }

  return (
    <TableRow key={metric.name}>
      <TableCell>
        <Tooltip placement="left-start" title={tooltip}>
          <span>{metric.name}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <TextField
          error={!!errorMessage}
          helperText={errorMessage || ""}
          id={`metric-${metric.id}-value`}
          inputProps={
            metric.name.includes("%") ? {style: {textAlign: "right"}} : {style: {textAlign: "left"}}
          }
          InputProps={
            metric.name.includes("%")
              ? {endAdornment: <InputAdornment position="end">%</InputAdornment>}
              : {startAdornment: <InputAdornment position="start">$</InputAdornment>}
          }
          name="value"
          onChange={handleChange}
          sx={{width: 70}}
          type="text"
          value={metric.value === 0 ? 0 : metric.value || ""}
        />
      </TableCell>
      <TableCell style={{textAlign: "center"}}>
        {defaultValue ? defaultValue : <DashIcon color="#000000" size={20} />}
      </TableCell>
    </TableRow>
  )
}

CardOnFileMetricRow.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.string, name: PropTypes.string}))
    .isRequired,
  metric: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CardOnFileMetricRow
