import {InputAdornment, MenuItem, TableCell, TableRow, TextField} from "@mui/material"
import {arrayOf, bool, func, shape, string} from "prop-types"
import {useMemo, useState} from "react"
import {BsDashLg as DashIcon} from "react-icons/bs"

import DOSelect from "components/do-select/do-select"

import {validNonnegativeInteger} from "lib/field-validations"

const ProductOpeningMetricRow = ({drivers, onChange, product}) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const defaultValue = useMemo(() => {
    const defaultValue = drivers.find(d => d.id === product.driverId)?.value
    return !!defaultValue ? `$${defaultValue}` : null
  }, [drivers, product])

  const handleDriverIdChange = ({target: {value: driverId}}) =>
    onChange({...product, driverId, isDirty: true})

  const handleValueChange = ({target: {value}}) => {
    const message = validNonnegativeInteger(value)
    const updatedValue = !!message ? value : parseInt(value, 10)
    const changed = {...product, value: updatedValue, hasError: !!message, isDirty: true}
    setErrorMessage(message)
    onChange(changed)
  }

  return (
    <TableRow key={product.name}>
      <TableCell>{product.name}</TableCell>
      <TableCell>
        <DOSelect
          style={{width: 245}}
          displayEmpty={true}
          renderValue={value => drivers.find(({id}) => id === value)?.name ?? "Other"}
          name="name"
          onChange={handleDriverIdChange}
          value={product.driverId || ""}
        >
          {drivers.map(({id, name}) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
          <MenuItem value="">Other</MenuItem>
        </DOSelect>
      </TableCell>
      <TableCell>
        <TextField
          error={!!errorMessage}
          helperText={errorMessage || ""}
          id={`product-${product.id}-value`}
          inputProps={{style: {textAlign: "left"}}}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="text"
          style={{width: 70}}
          name="name"
          onChange={handleValueChange}
          value={product.value === 0 ? 0 : product.value || ""}
        />
      </TableCell>
      <TableCell style={{textAlign: "center"}}>
        {defaultValue ? defaultValue : <DashIcon color="#000000" size={20} />}
      </TableCell>
    </TableRow>
  )
}

ProductOpeningMetricRow.propTypes = {
  drivers: arrayOf(shape({id: string, name: string})).isRequired,
  onChange: func.isRequired,
  product: shape({hasError: bool, driverId: string, name: string.isRequired}).isRequired,
}

export default ProductOpeningMetricRow
