import {number} from "prop-types"

import {formatMoney} from "lib/currency-formatters"

// https://docs.atomicfi.com/#deposit-account
export function formatDistribution(meta) {
  if (!meta) return []

  const {distributionAmount, distributionType} = meta
  if (!distributionType) return []

  switch (distributionType) {
    case "fixed":
      return ["Fixed Amount", formatMoney(distributionAmount)]
    case "percent":
      return ["Percentage", "50%"]
    case "total":
      return ["Full paycheck"]
    default:
      return []
  }
}

export const DirectDepositFailedIcon = ({size}) => {
  size = size ? `${size}px` : "1em"
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" height={size} width={size}>
      <path
        d="M320 144c-53 0-96 50-96 112s43 112 96 112 96-50 96-112-43-112-96-112zm40 168c0 4-4 8-8 8h-64c-4 0-8-4-8-8v-16c0-4 4-8 8-8h16v-55a8 8 0 01-12-2l-8-14a8 8 0 012-11l15-10a24 24 0 0113-4h14c4 0 8 4 8 8v88h16c4 0 8 4 8 8v16zM608 64H32C14 64 0 78 0 96v320c0 18 14 32 32 32h576c18 0 32-14 32-32V96c0-18-14-32-32-32zm-16 272c-35 0-64 29-64 64H112c0-35-29-64-64-64V176c35 0 64-29 64-64h416c0 35 29 64 64 64v160z"
        fill="currentColor"
      />
      <rect
        transform="rotate(45 325 251)"
        fill="currentColor"
        stroke="#fff"
        rx="35"
        strokeWidth="15"
        height="60"
        width="603.8"
        y="232"
        x="20"
      />
    </svg>
  )
}

DirectDepositFailedIcon.propTypes = {
  size: number,
}
