import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  FormControl,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import pick from "lodash/pick"
import {func, object, shape} from "prop-types"
import {Component} from "react"
import {MdExpandMore as ExpandIcon} from "react-icons/md"
import {withRouter} from "react-router"

import DuplicateConfirmationDialog from "components/duplicate-confirmation-dialog/duplicate-confirmation-dialog"
import SharedContentNotice from "components/shared-content-notice/shared-content-notice"
import ContainerCssSettings from "components/templates/container-css-settings"
import ContainerStyleSettings from "components/templates/container-style-settings"

import {featurify} from "lib/hooks/use-features"
import withDispatch from "lib/with-dispatch"

import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import DangerButton from "../danger-button/danger-button"
import ConfirmDialog from "../dialogs/confirm-dialog"
import {TemplateListPreview} from "../dialogs/template-list-preview"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import WidgetSettings from "../templates/widget-settings"
import GeneralPageSettings from "./general-page-settings"

const drawerWidth = 300

class PageSidebar extends Component {
  state = {
    tabIndex: 1,
    expandedPanel: "general",
    isUnsavedContentBlockConfirmDialogOpen: false,
    pendingTabIndex: null,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selectedBlock && this.props.selectedBlock) this.forceWidgetTabSelection()
    if (prevProps.selectedBlock && !this.props.selectedBlock) this.unforceWidgetTabSelection()
  }

  forceWidgetTabSelection = () => {
    this.previousTabIndex = this.state.tabIndex
    this.onTabChange(null, 0)
  }

  unforceWidgetTabSelection = () => {
    if (this.state.tabIndex === 0) this.onTabChange(null, this.previousTabIndex)
  }

  onTabChange = (e, tabIndex) => {
    const areEditsInSidebar = this.props.selectedBlock?.type !== "text"

    if (!(areEditsInSidebar && this.props.hasUnsavedContentBlockDataChanges()))
      this.setState({tabIndex})
    else this.setState({pendingTabIndex: tabIndex, isUnsavedContentBlockConfirmDialogOpen: true})
  }

  onCancelCloseWidgetForm = () =>
    this.setState({
      pendingTabIndex: null,
      isUnsavedContentBlockConfirmDialogOpen: false,
    })

  onConfirmCloseWidgetForm = () =>
    this.setState({
      tabIndex: this.state.pendingTabIndex,
      pendingTabIndex: null,
      isUnsavedContentBlockConfirmDialogOpen: false,
    })

  renderDeleteDialog = () => {
    const {page} = this.props
    const {isDeleteDialogOpen} = this.state

    return (
      <UltraConfirmDialog
        confirmationText={page.contentName}
        isHighSeverity={page.id !== "new"}
        key={page.id}
        onClose={() => this.setState({isDeleteDialogOpen: false})}
        onConfirm={this.props.onDelete}
        open={Boolean(isDeleteDialogOpen)}
        recordType="page"
      >
        {page.id !== "new" && <TemplateListPreview id={page.id} recordType="page" />}
      </UltraConfirmDialog>
    )
  }

  render() {
    const {classes, selectedBlock, page, onUpdatePage} = this.props
    const {tabIndex, isUnsavedContentBlockConfirmDialogOpen, expandedPanel} = this.state
    return (
      <Drawer
        anchor="right"
        classes={{
          docked: classes.drawerOpen,
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
        open={true}
        variant="persistent"
      >
        {selectedBlock && (
          <Tabs
            indicatorColor="primary"
            textColor="inherit"
            onChange={this.onTabChange}
            value={tabIndex}
            variant="fullWidth"
          >
            <Tab
              className={cx(classes.tab)}
              data-testid="content-block-tab"
              label={selectedBlock?.type || "Widget"}
            />
            <Tab className={classes.tab} data-testid="page-tab" label="Page" />
          </Tabs>
        )}
        <div className={classes.tabContainer} ref={this.setScrollableContainerRef}>
          {selectedBlock && tabIndex === 0 && (
            <WidgetSettings
              isContentLibrary={true}
              classes={pick(classes, ["settingsContainer", "expansionPanel", "expansionContent"])}
            />
          )}
          {page && tabIndex === 1 && (
            <>
              {!!page?.isShared && (
                <>
                  <SharedContentNotice
                    onShowDuplicateConfirmation={() =>
                      this.setState({showDuplicationConfirmation: true})
                    }
                    content={page}
                  />
                  {this.state.showDuplicationConfirmation && (
                    <DuplicateConfirmationDialog
                      onClose={() => this.setState({showDuplicationConfirmation: false})}
                      onDuplicate={this.props.onDuplicate}
                      open={true}
                    />
                  )}
                </>
              )}
              <div className={classes.settingsContainer}>
                <Accordion
                  classes={{root: classes.expansionPanel}}
                  expanded={expandedPanel === "general"}
                  onChange={() => this.setState({expandedPanel: "general"})}
                  key="general"
                  elevation={0}
                >
                  <AccordionSummary
                    classes={{root: classes.expansionContent}}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>General</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.expansionContent}}>
                    <div className={classes.expansionDetailInner}>
                      <GeneralPageSettings
                        page={page}
                        key={`page-${page.id}-settings`}
                        onSubmit={onUpdatePage}
                        initialValues={pick(page, [
                          "contentName",
                          "navigationTitle",
                          "isArchived",
                          "completionCondition",
                          "objectiveId",
                          "benchmark",
                        ])}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{root: classes.expansionPanel}}
                  elevation={0}
                  expanded={expandedPanel === "styles"}
                  key="styles"
                  onChange={() => this.setState({expandedPanel: "styles"})}
                >
                  <AccordionSummary
                    classes={{root: classes.expansionContent}}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>Styles</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.expansionContent}}>
                    <ContainerStyleSettings
                      containerType="page"
                      styles={page.styles || {}}
                      onSubmit={styles => this.props.onUpdatePage({styles})}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{root: classes.expansionPanel}}
                  elevation={0}
                  expanded={expandedPanel === "css"}
                  key="css"
                  onChange={() => this.setState({expandedPanel: "css"})}
                >
                  <AccordionSummary
                    classes={{root: classes.expansionContent}}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>Custom CSS</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.expansionContent}}>
                    <ContainerCssSettings
                      containerType="page"
                      initialValues={{css: page.css || ""}}
                      onSubmit={this.props.onUpdatePage}
                    />
                  </AccordionDetails>
                </Accordion>

                {this.renderDeleteDialog()}

                <FormControl fullWidth={true} margin="normal">
                  <DangerButton
                    fullWidth={true}
                    margin="normal"
                    onClick={() => this.setState({isDeleteDialogOpen: true})}
                    tabIndex={2}
                  >
                    Delete
                  </DangerButton>
                </FormControl>
              </div>
            </>
          )}
        </div>
        {isUnsavedContentBlockConfirmDialogOpen && (
          <ConfirmDialog
            cancelText="Cancel"
            continueText="Abandon Changes"
            content="You have unsaved changes for this widget."
            onClose={this.onCancelCloseWidgetForm}
            onConfirm={this.onConfirmCloseWidgetForm}
            open={true}
            title="Abandon changes?"
          />
        )}
      </Drawer>
    )
  }
}

const styles = theme => ({
  hidden: {
    display: "none",
  },
  tab: {
    minWidth: 100,
  },
  icon: {
    marginRight: 5,
  },
  drawer: {
    transition: theme.transitions.create("all"),
  },
  drawerOpen: {
    flexBasis: drawerWidth,
  },
  drawerClosed: {
    flexBasis: 0,
  },
  drawerPaper: {
    backgroundColor: "rgb(234,236,240)",
    boxShadow: "inset 0 0 10px rgb(208, 212, 220, 0.8)",
    borderLeft: "white",
    width: drawerWidth,
    marginTop: 88,
    paddingBottom: 88,
  },
  menuItem: {
    boxSizing: "border-box",
    height: "auto",
    maxWidth: 252,
    whiteSpace: "normal",
  },
  listItemTextSecondary: {
    color: "#7a7b7f",
  },
  settingsContainer: {
    padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
  },
  tabContainer: {
    overflow: "auto",
    flex: 1,
  },
  tabBadge: {
    padding: theme.spacing(0, 1),
  },
  title: {
    marginTop: 20,
    marginBottom: 10,
    textTransform: "uppercase",
  },
  field: {
    margin: "10px 0",
  },
  listSubheader: {
    backgroundColor: theme.palette.common.white,
  },
  helperText: {
    marginBottom: 10,
  },
  expansionPanel: {
    backgroundColor: "transparent",
    margin: 0,
  },
  expansionDetailInner: {
    width: "100%",
  },
  customCssButton: {
    margin: `20px -2px 0 -2px`,
  },
  expansionContent: {
    padding: 0,
  },
  select: {
    maxWidth: 252,
  },
  sidbarButton: {
    borderRadius: 0,
  },
  supportOwnerSwitchLabel: {
    fontSize: 15,
  },
  switchTooltipIconWrapper: {
    verticalAlign: "middle",
  },
  switchTooltipIcon: {
    fontSize: "1rem",
    position: "relative",
    top: 2,
    color: "rgba(0,0,0,0.54)",
    left: 10,
  },
})

PageSidebar.propTypes = {
  classes: object.isRequired,
  currentUser: object,
  dispatch: func.isRequired,
  hasFeature: func.isRequired,
  hasUnsavedContentBlockDataChanges: func.isRequired,
  history: shape({
    push: func,
  }),
  page: object,
  selectedBlock: object,
  setMode: func,
  setContainer: func.isRequired,
  onUpdatePage: func.isRequired,
  onDuplicate: func.isRequired,
  onDelete: func,
}

export default contentBlockEditorConsumer(
  withDispatch(withRouter(featurify(withStyles(styles)(PageSidebar))))
)
