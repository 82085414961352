export const MobileOS = {
  WindowsPhone: "MobileOSWindowsPhone",
  Android: "MobileOSAndroid",
  iOS: "MobileOSiOS",
  Unknown: "MobileOSUnknown",
}

export default function mobileOS(agent = navigator.userAgent) {
  if (/windows phone/i.test(agent)) return MobileOS.WindowsPhone

  if (/android/i.test(agent)) return MobileOS.Android

  if (/iPad|iPhone|iPod/.test(agent)) return MobileOS.iOS

  return MobileOS.Unknown
}
