// This file was generated automatically from character-11.svg with the following command:
// ./svg-converter.js src/components/superhero/character-11.svg
import SuperheroSvg from "./superhero-svg"

const Character11 = props => (
  <SuperheroSvg viewBox="100 110 80 210" {...props}>
    <g id="Layer_11">
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M147.51,296.08c0,0-3.76,6.28-1.71,8.08c0,0,11.26,2.69,25.6,0.9
  			c0,0,5.12-1.8-1.02-4.49c0,0-10.24-2.69-11.26-6.28"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M168.19,299.98c0,0-3.91,2.62-0.66,5.46"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M145.33,301.29c0,0,3.47,1.89,20.91,1.93"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M154.5,294.74c0,0-0.35,4.26,12.11,7.51"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M157.57,297.43c0,0,2.05-1.8,2.05,0c0,1.8,0,1.8,2.05,1.8
  			s2.05,0,2.05,0.9s2.05,0,2.05,0"
        />
      </g>
      <g>
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M115.43,296.97c0,0-4.1,6.12-2.15,8c0,0,11.1,3.17,25.52,1.98
  			c0,0,5.21-1.58-0.78-4.53c0,0-10.08-3.12-10.9-6.76"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M135.88,301.74c0,0-4.05,2.45-0.96,5.43"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M112.97,302.07c0,0,3.36,2.03,20.78,2.82"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M122.49,295.92c0,0-0.58,4.24,11.69,8.02"
        />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
          d="M125.41,298.74c0,0,2.14-1.71,2.05,0.09s-0.1,1.79,1.95,1.88
  			c2.05,0.09,2.05,0.09,2,0.98c-0.05,0.9,2.05,0.09,2.05,0.09"
        />
      </g>
      <g>
        <path
          style={{fill: "#D9D8D6"}}
          d="M147.39,131.83c0,0,3.88,14.64-2.07,33.46c0,0-3.49-8.17-7.74-2.66c-5.6,7.27-3.85,1.28-8.41,0.86
  			c-3.31-0.31-5.54,0.03-6.62-0.77c-3.5-2.57-5.25-1.28-7.71-1.28c-1.75,0,2.07-6.08,2.42-11.64c0.78-12.38,0.6-11.21,2.2-19.31"
        />
        <polygon
          style={{fill: "#CC5F64"}}
          points="139.01,206.77 143.34,271.18 159.62,271.18 152.84,188.7 136.27,185.92 		"
        />
        <path
          style={{fill: "#EB6669"}}
          d="M137.95,229.34l-3.3,44.63l-20.56-0.39l-3.1,22.96l22.03,1.03l1.62-23.6l-20.56-0.39l7.62-67.88
  			c-0.02-0.31-0.03-0.63-0.03-0.94c-0.08-5.74,2.05-10.92,5.46-14.41l-0.02-0.12l23.57-6.15l2.34,15.82c0.17,0.79,0.3,1.6,0.38,2.42
  			l0.2,1.31l-0.12,0.02c0.01,0.17,0.02,0.34,0.02,0.51c0.13,9.09-5.28,16.77-12.56,18.61"
        />
        <path
          style={{fill: "#E3B59D"}}
          d="M133.3,117.98c-0.48,0-0.96,0.03-1.43,0.09c-0.28,13.75-10.49,20.95-12.42,22.19
  			c1.68,7.41,7.25,12.86,13.85,12.86c7.94,0,14.38-7.86,14.38-17.57C147.68,125.84,141.24,117.98,133.3,117.98z"
        />
        <path
          style={{fill: "#E3B59D"}}
          d="M131.97,155.37c-1.4,0.43-3.68,1.29-3.68,1.29l0.88,5.13l6.66,3.42l7.01-3.85v-5.99
  			c0,0,0.35,0-2.22-0.19c-2.57-0.19-3.19-0.48-3.19-0.48l0-2.49c5.76-2.3,10.25-8.91,10.25-16.72c0-0.93-0.07-1.84-0.18-2.73
  			l-27.86,6.93c-0.1,0.07-0.2,0.14-0.3,0.2c0,0.02,0.11,0.43,0.12,0.45c-1.24,0.52-2.23,1.56-2.23,3.24c0,2.12,1.41,3.84,3.14,3.84
  			c0.68,0,1.31-0.27,1.82-0.71c2.41,3.55,5.94,5.92,9.93,6.31l0,1.68v0.67H131.97z"
        />
        <g>
          <g>
            <path
              style={{fill: "#E3B59D"}}
              d="M142.58,159.67c0.41-0.98,3.15-1.1,3.2-0.98C145.83,158.83,142.6,159.69,142.58,159.67
  					c-0.01-0.02,1.52-1.06,3.2-0.98c4.46,0.2,8.16,8.26,10.78,9.95c0.07,0.05,1.27,0.74,3.14,2.02c0.01,0.01,0.03,0.02,0.04,0.03
  					c-1.59,0.41-3.18,0.81-4.77,1.22c0.58-1.78,1.15-3.57,1.73-5.35c0.91-2.82,1.82-5.64,2.73-8.46c0.21-0.66,0.42-1.31,0.63-1.97
  					c0.19-0.58,0.49-1.06,1.16-1.41c0.58-0.3,1.47-0.46,2.19-0.33c1.46,0.27,2.89,1.41,2.51,2.6c-0.58,1.78-1.15,3.57-1.73,5.35
  					c-0.91,2.82-1.82,5.64-2.73,8.46c-0.21,0.66-0.42,1.31-0.63,1.97c-0.25,0.77-0.78,1.38-1.81,1.66
  					c-0.97,0.26-2.13,0.09-2.96-0.44c-1.27-0.82-2.57-1.65-2.57-1.65l0,0c0,0-5.2-5.52-5.2-5.52l0,0c0,0-2.67-2.99-2.67-2.99l0,0
  					c0,0-1.08-1-1.88-1.99C142.84,160.71,142.38,160.15,142.58,159.67z"
            />
          </g>
        </g>
        <polygon
          style={{fill: "#D9D8D6"}}
          points="115.17,194.14 113.43,215.81 142.14,219.68 144.14,198.04 		"
        />
        <g>
          <g>
            <path
              style={{fill: "#E3B59D"}}
              d="M127.91,163.57c-1.61,2.73-3.22,5.47-4.83,8.2c-2.55,4.32-5.09,8.64-7.64,12.96
  					c-0.58,0.99-1.16,1.98-1.75,2.96c0.04-0.68,0.07-1.36,0.11-2.03c1,1.73,2,3.46,3,5.19c1.59,2.75,3.17,5.49,4.76,8.24
  					c0.37,0.63,0.73,1.27,1.1,1.9c0.33,0.58,0.21,1.48-0.11,2.03c-0.32,0.57-1.01,1.14-1.73,1.34c-0.78,0.21-1.68,0.25-2.42-0.08
  					c-0.76-0.33-1.21-0.81-1.59-1.45c-1.5-2.59-3-5.19-4.5-7.78c-1.02-1.76-2.03-3.51-3.05-5.27c-0.77-1.32-2.04-2.79-1.21-4.32
  					c1.07-1.97,2.29-3.89,3.44-5.83c2.19-3.72,4.39-7.44,6.58-11.16c1.38-2.34,2.73-4.69,4.14-7.02c0.02-0.04,0.04-0.08,0.07-0.11
  					c0.73-1.23,2.65-1.93,4.16-1.26C127.9,160.73,128.68,162.25,127.91,163.57L127.91,163.57z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{fill: "#4A9AD4"}}
              d="M129.7,156.27c-4.5,0.75-7.64,5.81-7.03,11.3l2.2,16.23l27.32,0l-2.71-19.99
  					c-0.82-6.34-3.15-9.3-7.35-8.44c0,0,1.4,6.42-5.25,7.27C136.88,162.64,129.87,163.07,129.7,156.27z"
            />
            <polygon
              style={{fill: "#4A9AD4"}}
              points="124.48,180.54 152.2,183.8 152.9,189 125.6,193.08 				"
            />
          </g>
        </g>
        <ellipse style={{fill: "#0D1E36"}} cx="139.99" cy="131.58" rx="1.15" ry="1.4" />
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M139.44,128.52c0.02-0.03,0.04-0.05,0.07-0.07c0.03-0.03,0.05-0.05,0.08-0.08
  					c0.02-0.01,0.04-0.03,0.05-0.04c0.05-0.04-0.16,0.12-0.1,0.08c0.01-0.01,0.02-0.02,0.04-0.03c0.1-0.07,0.2-0.13,0.31-0.18
  					c0.1-0.05-0.19,0.07-0.05,0.02c0.04-0.01,0.07-0.02,0.11-0.03c0.08-0.02,0.16-0.04,0.24-0.05c-0.06,0.01-0.12,0.02-0.19,0.03
  					c0.15-0.02,0.29-0.02,0.44,0c-0.06-0.01-0.12-0.02-0.19-0.03c0.16,0.02,0.31,0.06,0.45,0.12c0.16,0.06-0.13-0.07-0.01,0
  					c0.04,0.02,0.07,0.04,0.11,0.06c0.09,0.05,0.18,0.1,0.27,0.16c0.31,0.2,0.79,0.07,0.96-0.25c0.19-0.35,0.08-0.74-0.25-0.96
  					c-0.68-0.45-1.56-0.67-2.35-0.38c-0.36,0.14-0.74,0.35-0.99,0.65c-0.12,0.15-0.21,0.3-0.21,0.5c0,0.17,0.08,0.38,0.21,0.5
  					C138.7,128.76,139.19,128.83,139.44,128.52L139.44,128.52z"
            />
          </g>
        </g>
        <path
          style={{fill: "#D9D8D6"}}
          d="M131.86,118.06c-7.27,0.88-12.95,8.37-12.95,17.48c0,1.63-1.2,4.15-0.86,5.65
  			C120.51,139.91,132.42,131.35,131.86,118.06z"
        />
        <path
          style={{fill: "#D9D8D6"}}
          d="M131.84,118.17c0,0,3.97,5.44,12.01,5.44C143.85,123.61,139.28,116.99,131.84,118.17z"
        />
        <ellipse style={{fill: "#0D1E36"}} cx="130.17" cy="133.58" rx="1.15" ry="1.4" />
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M130.36,128.92c-0.24-0.09-0.51-0.12-0.76-0.12c-0.54-0.01-1.11,0.15-1.55,0.47
  					c-0.38,0.28-0.68,0.62-0.92,1.02c-0.19,0.31-0.08,0.78,0.25,0.96c0.34,0.18,0.76,0.08,0.96-0.25c0.05-0.09,0.11-0.18,0.18-0.27
  					c0.07-0.1-0.11,0.14,0,0c0.03-0.04,0.06-0.07,0.09-0.1c0.06-0.06,0.12-0.12,0.19-0.18c0.13-0.11-0.12,0.08-0.02,0.02
  					c0.03-0.02,0.06-0.04,0.09-0.06c0.06-0.04,0.12-0.07,0.18-0.1c0.03-0.02,0.06-0.03,0.1-0.04c-0.23,0.11-0.11,0.04-0.05,0.02
  					c0.12-0.04,0.24-0.07,0.37-0.09c-0.06,0.01-0.12,0.02-0.19,0.03c0.18-0.02,0.37-0.02,0.56,0c-0.06-0.01-0.12-0.02-0.19-0.03
  					c0.11,0.02,0.23,0.04,0.34,0.08c0.16,0.06,0.4,0.01,0.54-0.07c0.15-0.09,0.28-0.25,0.32-0.42
  					C130.94,129.39,130.73,129.06,130.36,128.92L130.36,128.92z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M135.26,137.14c-0.06,0.15-0.03,0.07-0.01,0.02c0.02-0.05,0.05-0.1,0.07-0.15
  					c0.04-0.07,0.08-0.14,0.12-0.21c0.02-0.04,0.05-0.07,0.07-0.11c0.01-0.02,0.03-0.04,0.04-0.05c-0.06,0.09,0,0.01,0.01-0.01
  					c0.07-0.08,0.14-0.16,0.21-0.22c0.02-0.02,0.04-0.03,0.06-0.05c0.04-0.03-0.06,0.04,0.01-0.01c0.04-0.03,0.09-0.06,0.14-0.09
  					c0.02-0.01,0.04-0.02,0.07-0.04c0.08-0.04-0.03,0.01,0.02-0.01c0.05-0.02,0.1-0.04,0.16-0.05c0.05-0.01,0.07-0.01,0.01,0
  					c0.03,0,0.06-0.01,0.09-0.01c0.03,0,0.06,0,0.08,0c0.03,0,0.13,0.03,0.04,0c0.07,0.02,0.14,0.03,0.21,0.05
  					c0.02,0.01,0.04,0.01,0.05,0.02c0.06,0.02-0.03-0.01,0.01,0.01c0.04,0.02,0.08,0.04,0.11,0.06c0.2,0.11,0.32,0.21,0.48,0.37
  					c0.33,0.31,0.82-0.18,0.5-0.5c-0.52-0.5-1.25-0.91-1.97-0.61c-0.48,0.19-0.83,0.59-1.09,1.02c-0.07,0.12-0.13,0.24-0.18,0.37
  					c-0.07,0.18,0.08,0.39,0.24,0.43C135.03,137.44,135.19,137.31,135.26,137.14L135.26,137.14z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{fill: "#0D1B30"}}
              d="M132.59,142.29c1.61,0.55,3.54-0.13,4.36-0.49c0.11,0.83,0.22,2.01,0.04,2.77l0,0
  					c-0.13,0.61-0.44,1.16-0.89,1.52c-0.3-0.61-0.72-1.12-1.23-1.48l-1.91,0.04C132.96,144.65,132.76,144.09,132.59,142.29z"
            />
            <path
              style={{fill: "#FFFFFF"}}
              d="M132.51,141.27c0,0,1.65,0.49,4.29-0.44c0,0,0.08,0.41,0.16,0.98c-0.82,0.35-2.75,1.04-4.36,0.49
  					C132.56,141.99,132.54,141.65,132.51,141.27z"
            />
          </g>
          <path
            style={{fill: "#F05C60"}}
            d="M133.04,145.04c-0.12-0.3-0.18-0.62-0.2-0.93c0.5-0.07,1.01-0.01,1.49,0.19
  				c0.76,0.34,1.37,0.97,1.77,1.78c-0.08,0.06-0.16,0.12-0.24,0.17C134.81,146.87,133.55,146.33,133.04,145.04z"
          />
        </g>
        <path
          style={{fill: "#E3B59D"}}
          d="M163.76,144.44c0,0,0.16,0.71,0.43,0.61c0.26-0.09,0.79-0.94,0.5-2.13
  			c-0.19-0.79-0.46-4.19,1.59-5.7c0,0,2.67-1.07,3.89,2.02c0.92,2.32,1.23,4.41,0.08,7.86c-1.08,3.22-2.49,4.48-3.03,5.42
  			c-1.09,3.51-2.18,7.03-3.28,10.54c-0.16,0.51-0.32,1.02-0.48,1.53l-5.11-2.85c1.11-3.59,2.23-7.18,3.34-10.76
  			c0.11-0.37,0.23-0.74,0.34-1.11c-0.46-0.98-1.44-3.34-1.35-5.36c0,0-0.07-0.97,0.48-2.02
  			C161.72,141.45,163.41,142.63,163.76,144.44z"
        />

        <ellipse
          transform="matrix(0.965 -0.2624 0.2624 0.965 -49.6774 41.1435)"
          style={{fill: "#FFFFFF"}}
          cx="129.19"
          cy="206.55"
          rx="1.88"
          ry="2.32"
        />
        <path
          style={{fill: "#E3B59D"}}
          d="M129.45,213.23c0,0-0.6-0.29-0.7,0c-0.1,0.29,0.16,1.27,1.18,1.75c0.67,0.31,3.22,2.16,2.98,4.89
  			c0,0-0.94,3.01-3.87,2.2c-2.2-0.61-3.86-1.61-5.54-4.74c-1.57-2.93-1.56-4.94-1.88-5.99c-1.77-3.12-3.54-6.23-5.3-9.35
  			c-0.26-0.45-0.51-0.91-0.77-1.36l5.22-2.77c1.8,3.18,3.61,6.36,5.41,9.55c0.19,0.33,0.37,0.66,0.56,0.98
  			c0.97,0.2,3.24,0.77,4.6,2.09c0,0,0.72,0.53,1.11,1.66S130.94,214.03,129.45,213.23z"
        />
        <path
          style={{fill: "#4A9AD4"}}
          d="M123.25,161.43l2.45,9.73l3.85-9.73l-2.66-4.05C126.9,157.39,123.95,157.11,123.25,161.43z"
        />
      </g>
      <g>
        <ellipse style={{fill: "#FFFFFF"}} cx="131.54" cy="155.56" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="131.92" cy="156.23" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="132.31" cy="156.9" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="133.07" cy="157.24" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="133.84" cy="157.57" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="134.61" cy="157.57" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="135.37" cy="157.57" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="136.14" cy="157.57" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="139.59" cy="155.9" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="139.59" cy="155.23" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="136.91" cy="157.57" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="137.68" cy="157.24" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="138.44" cy="156.9" rx="0.38" ry="0.33" />
        <ellipse style={{fill: "#FFFFFF"}} cx="139.21" cy="156.57" rx="0.38" ry="0.33" />
      </g>
      <ellipse style={{fill: "#FFFFFF"}} cx="121.98" cy="145.7" rx="0.74" ry="0.64" />
      <path
        style={{fill: "#CC5F64"}}
        d="M143.03,268.09c0.86,9.36,1.72,18.71,2.58,28.07h15.99l-1.89-24.98
  		C154.14,270.15,148.59,269.12,143.03,268.09z"
      />
    </g>
  </SuperheroSvg>
)

export default Character11
