import {Grid} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, func, object} from "prop-types"

import DocumentTitle from "../document-title/document-title"
import AccountsBatchUploadPanel from "./panels/accounts-batch-upload-panel"
import ContactsBatchUploadPanel from "./panels/contacts-batch-upload-panel"

export const BatchUploadContacts = ({classes, objectives, onContinue}) => {
  return (
    <>
      <DocumentTitle title="Contacts - Batch Upload" />
      <Grid className={classes.uploadGridWrapper} container={true}>
        <Grid className={classes.uploadGridCell} item={true} lg={6} md={12}>
          <ContactsBatchUploadPanel objectives={objectives} onContinue={onContinue} />
        </Grid>
        <Grid className={classes.uploadGridCell} item={true} lg={6} md={12}>
          <AccountsBatchUploadPanel onContinue={onContinue} />
        </Grid>
      </Grid>
    </>
  )
}

BatchUploadContacts.propTypes = {
  classes: object.isRequired,
  objectives: array.isRequired,
  onContinue: func.isRequired,
}

const styles = theme => ({
  uploadGridWrapper: {paddingTop: theme.spacing(6)},
  uploadGridCell: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    "&:not(:first-of-type)": {borderLeft: "1px solid #bdbdbd"},
  },
})

export default withStyles(styles)(BatchUploadContacts)
