import {TableCell, TableRow, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import orderBy from "lodash/orderBy"
import {func, number, string} from "prop-types"
import {useCallback, useEffect, useMemo} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import Expandable from "components/expandable/expandable"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchAdvancedInsightsObjectiveContent} from "lib/api"

const ObjectiveContent = props => {
  const {itemsPerPage, page, sortColumn, sortDirection, setTableState} = props

  const {
    objectiveContent,
    objectiveContentIsLoading,
    hasSocket,
    fetchInsights,
    filterParams,
  } = useAdvancedTeamInsights()

  const classes = useStyles()

  // CODE SMELL: We're ignoring the dependency on setTableState because it would be a much bigger
  // task to refactor useAdvancedTeamInsights to return stable functions/values across renders.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setTableStateMemoized = useMemo(() => setTableState, [])

  const refreshTable = useCallback(
    attrs => {
      const currentItemsPerPage = attrs?.itemsPerPage ?? itemsPerPage
      const currentPage = attrs?.page ?? page
      const offset = currentPage * currentItemsPerPage
      const currentSortColumn = attrs?.sortColumn ?? sortColumn
      const currentSortDirection = attrs?.sortDirection ?? sortDirection
      const sortFunction = value => (isNaN(value) ? value.toLowerCase() : value)

      // Prepare the paginated, sorted list of data rows to display
      let rows = objectiveContent?.data ?? []
      rows = rows.filter((item, i) => i >= offset && i < offset + currentItemsPerPage)
      rows = orderBy(rows, [item => sortFunction(item[currentSortColumn])], [currentSortDirection])

      setTableStateMemoized({
        ...attrs,
        rows,
        page: currentPage,
        itemsPerPage: currentItemsPerPage,
        sortColumn: currentSortColumn,
        sortDirection: currentSortDirection,
        totalCount: objectiveContent?.data?.length ?? 0,
      })
    },
    [objectiveContent, itemsPerPage, page, sortColumn, sortDirection, setTableStateMemoized]
  )

  useEffect(() => {
    if (hasSocket)
      fetchInsights("objectiveContent", fetchAdvancedInsightsObjectiveContent, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  useEffect(() => {
    // NB: This is needed so that our rows are affected by our default state.
    // This should only get called after the API or websocket returns new data.
    refreshTable({page: 0})

    // FIXME: Ignoring dependency on refreshTable because that function gets redefined anytime any param
    // changes, including props.page. I'm not sure what the proper fix is here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveContent])

  return (
    <div className={classes.container}>
      <Typography variant="h5">Objective Content</Typography>
      <div className={classes.expandableContainer} style={{paddingTop: 16}}>
        <Expandable label="How we use this table">
          <p>The objective content table reports on content engagement by objective.</p>

          <Typography variant="h6">At a Glance</Typography>

          <p>
            Compare engagement metrics by using sort features at the column header to see which
            content drives the most engagement by message, page and objective completion. Note
            content that has the most engagement on mobile devices to better understand how contacts
            engage with it. Monitor and compare objective completion rates to understand which
            content supports conversion.
          </p>

          <ul>
            <li>
              <b>Unsubscribe Rate:</b> See which content has the highest and lowest rates to better
              understand the best and worst received.
            </li>
            <li>
              <b>Percent of Actions on Mobile</b>: Note content with the most engagement on mobile
              devices to understand how contacts engage with it.
            </li>
          </ul>

          <Typography variant="h6">Use of Page Filters</Typography>

          <ul>
            <li>
              <b>Dates</b>: Focus engagement by specific timeframes to see how content engagement
              changes over time.
            </li>
            <li>
              <b>Objectives</b>: Filter by objective to focus on a specific subset.
            </li>
            <li>
              <b>Status</b>: Focus the view by live or archived campaign status to compare or to
              view a specific campaign category.
            </li>
          </ul>
        </Expandable>
      </div>
      <DOTable
        headers={tableHeaders}
        isTableLoading={objectiveContentIsLoading}
        noResults="No data found."
        refresh={refreshTable}
        paginationEnabled={true}
      >
        {({
          bounceRate,
          emailCtr,
          mobileActionRate,
          objectiveId,
          objectiveName,
          pageCtr,
          presentedObjectiveCompletionRate,
          smsCtr,
          unsubscribeRate,
        }) => {
          return (
            <TableRow key={objectiveId}>
              <TableCell>{objectiveName}</TableCell>
              <TableCell>{emailCtr}%</TableCell>
              <TableCell>{smsCtr}%</TableCell>
              <TableCell>{bounceRate}%</TableCell>
              <TableCell>{unsubscribeRate}%</TableCell>
              <TableCell>{pageCtr}%</TableCell>
              <TableCell>{presentedObjectiveCompletionRate}%</TableCell>
              <TableCell>{mobileActionRate}%</TableCell>
            </TableRow>
          )
        }}
      </DOTable>
    </div>
  )
}

ObjectiveContent.propTypes = {
  itemsPerPage: number,
  page: number,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
}

// These are extracted out from the component so they don't get redefined on every re-render.
const tableHeaders = [
  {
    field: "objectiveName",
    label: "Objective Name",
    tooltipText: "Objective Name: internal name of the objective",
  },
  {
    field: "emailCtr",
    label: "Email CTR",
    tooltipText:
      "Email CTR: total number of unique email message clicks / total number of email messages sent excluding bounces",
  },
  {
    field: "smsCtr",
    label: "SMS CTR",
    tooltipText:
      "SMS CTR: total number of unique SMS message clicks / total number of SMS messages sent excluding bounces",
  },
  {
    field: "bounceRate",
    label: "Bounce Rate",
    tooltipText: "Bounce Rate: total number of message bounces / total number of messages sent",
  },
  {
    field: "unsubscribeRate",
    label: "Unsubscribe Rate",
    tooltipText:
      "Unsubscribe Rate: total number of unique unsubscribes / total number of messages sent excluding bounces",
  },
  {
    field: "pageCtr",
    label: "Page CTR",
    tooltipText: "Page CTR: total number of unique page clicks / total number of unique page views",
  },
  {
    field: "presentedObjectiveCompletionRate",
    label: "Presented Objective Completion Rate",
    tooltipText:
      "Presented Objective Completion Rate: total number of unique objective completions / total number of objectives presented to journeys",
  },
  {
    field: "mobileActionRate",
    label: "Percent of Actions on Mobile",
    tooltipText:
      "Percent of Actions on a Mobile Device: total number of engagement actions completed on mobile / total number of engagement actions with device type identifier",
  },
]

const useStyles = makeStyles(theme => ({
  container: {
    "& tr th:nth-child(5)": {
      maxWidth: 150,
    },
    "& tr th:nth-child(7)": {
      maxWidth: 170,
    },
    "& tr th:nth-child(8)": {
      maxWidth: 170,
    },
  },
  expandableContainer: {
    marginBottom: theme.spacing(3),
  },
}))

export default tabular({
  itemsPerPage: 10,
  sortColumn: "objectiveName",
  sortDirection: "asc",
})(ObjectiveContent)
