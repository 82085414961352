export function toJourneyAndContact({name, ...params}) {
  const contactName = name.split(" ")

  return {
    ...params,
    contact: {
      email: params.email,
      phoneMobile: params.phoneMobile,
      nameFirst: contactName.shift(),
      nameLast: contactName.join(" "),
      uniqueId: params.uniqueId,
    },
  }
}
