import {Box, FormControlLabel, Grid, Switch, Typography} from "@mui/material"

import SaveButton from "components/save-button/save-button"

import {useSession} from "contexts/session/session-context"
import {updateCurrentTeam} from "lib/api"
import {useForm} from "lib/hooks/use-form"
import SubmissionError from "lib/submission-error"

const CaseSensitivitySettings = () => {
  const {team, setTeam} = useSession()

  const onSubmit = params =>
    updateCurrentTeam(params)
      .then(setTeam)
      .catch(({body}) => {
        throw new SubmissionError(body.errors)
      })

  const {field, handleSubmit, submitting} = useForm({
    onSubmit,
    initialValues: {config: {do: {case_insensitive_keys: team.config.do.case_insensitive_keys}}},
  })

  return (
    <>
      <Typography variant="h5" sx={{mt: 4}}>
        General Settings
      </Typography>
      <Box
        sx={{
          p: 2,
          elevation: 1,
          boxShadow: 1,
          backgroundColor: "white",
          code: {
            fontFamily: `"Monaco","Consolas","Lucida Console",monospace`,
            color: "#F92672",
            background: "#FEECF2",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container={true} sx={{alignItems: "flex-start"}} spacing={4}>
            <Grid item={true} lg={4} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    {...field("config.do.case_insensitive_keys", {
                      bool: true,
                      exclude: ["error", "helperText"],
                    })}
                  />
                }
                label="Use case insensitive field names"
              />
            </Grid>
            <Grid item={true} lg={8} md={12}>
              Any fields in your data that have the same name as a field in Digital Onboarding will
              be imported, even if the case does not match. For example, a field called{" "}
              <code>Unique_id</code> in your data will still be used for our <code>unique_id</code>{" "}
              field. This applies to both contact and account data.
            </Grid>
          </Grid>
          <Box sx={{mt: 4, textAlign: "right"}}>
            <SaveButton submitting={submitting} />
          </Box>
        </form>
      </Box>
    </>
  )
}

export default CaseSensitivitySettings
