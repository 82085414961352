import {TableCell, TableRow} from "@mui/material"
import {arrayOf, shape, string} from "prop-types"

import DOTable from "../table/table"

const ContactAccounts = ({contactAccounts}) => (
  <DOTable
    rows={contactAccounts}
    paginationEnabled={false}
    noResults="No accounts have been imported yet"
    headers={[
      {field: "name", label: "Product Name", sortable: false},
      {field: "code", label: "Product Code", sortable: false},
      {field: "type", sortable: false},
      {field: "nickname", sortable: false},
      {field: "role", sortable: false},
    ]}
  >
    {({
      id,
      role,
      account: {
        nickname,
        product: {name, code, type},
      },
    }) => (
      <TableRow key={id}>
        <TableCell>{name}</TableCell>
        <TableCell>{code}</TableCell>
        <TableCell>{type}</TableCell>
        <TableCell>{nickname}</TableCell>
        <TableCell>{role}</TableCell>
      </TableRow>
    )}
  </DOTable>
)

ContactAccounts.propTypes = {
  contactAccounts: arrayOf(
    shape({
      id: string,
      role: string,
      nickname: string,
    })
  ),
}

export default ContactAccounts
