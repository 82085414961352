import {Container, Grid, Typography} from "@mui/material"
import {node} from "prop-types"

import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import Padded from "components/padded/padded"
import TitleBar from "components/title-bar/title-bar"

const RewardSetLayout = ({footer, rewardSetForm, rewardStepList}) => {
  return (
    <>
      <DocumentTitle title="Reward" />
      <TitleBar
        title="Reward Settings"
        backButtonAttrs={{
          to: "/admin/rewards/reward-sets/",
          children: "Return to Rewards Dashboard",
        }}
      />
      <Padded>
        <Typography>Configure this Reward and its corresponding steps.</Typography>
        <Container sx={{paddingTop: "1em"}}></Container>

        <Grid container={true} spacing={4}>
          <Grid item={true} lg={6} md={12}>
            <Typography variant="h5">Reward</Typography>
            <Container sx={{paddingTop: "1em"}}></Container>

            <Box>{rewardSetForm}</Box>
          </Grid>

          <Grid item={true} lg={6} md={12}>
            <Typography variant="h5">Steps</Typography>
            <Container sx={{paddingTop: "1em"}}></Container>
            <Box>
              <>{rewardStepList}</>
            </Box>
          </Grid>
        </Grid>

        {footer}
      </Padded>
    </>
  )
}

RewardSetLayout.propTypes = {
  footer: node.isRequired,
  rewardSetForm: node.isRequired,
  rewardStepList: node.isRequired,
}

export default RewardSetLayout
