import {CssBaseline} from "@mui/material"
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import "react-app-polyfill/stable"
import ReactDOM from "react-dom"

import Router from "./components/router/router"
import "./index.css"
import "./sentry"
import mainTheme from "./themes/main"

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  </LocalizationProvider>,
  document.getElementById("root")
)
