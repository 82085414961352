import CircularProgress from "@mui/material/CircularProgress"
import {styled} from "@mui/material/styles"
import {bool, number, shape, string} from "prop-types"
import React from "react"

import ProgressData from "./progress-data"

const PaddingHack = styled("div")({height: "100%", paddingBottom: "100%", width: "100%"})

const RewardCircleProgress = styled(CircularProgress)(({branding, completed}) => ({
  left: 0,
  position: "absolute",
  top: 0,
  color: completed === "true" ? branding.completedColor : branding.inProgressColor,
  "& .MuiCircularProgress-circle": {strokeLinecap: "round"},
}))

const RewardCircleBackground = styled(CircularProgress)(({branding}) => ({
  left: 0,
  position: "absolute",
  top: 0,
  color: branding.trackColor,
  "& .MuiCircularProgress-circle": {strokeLinecap: "round"},
}))

const RewardCircular = ({
  accrued,
  caption,
  progressType,
  slug,
  unit,
  completed,
  branding,
  completedPercentage,
}) => (
  <>
    <PaddingHack />
    <svg width="100%" height="100%" style={{position: "absolute", top: 0, left: 0}}>
      <circle cx="50%" cy="50%" r="50%" fill={branding.widgetBackgroundColor} />
    </svg>
    <RewardCircleBackground
      size="100%"
      thickness={3}
      value={100}
      variant="determinate"
      branding={branding}
    />
    <RewardCircleProgress
      size="100%"
      thickness={3}
      value={completedPercentage}
      variant="determinate"
      completed={completed.toString()}
      branding={branding}
    />
    <ProgressData
      caption={caption}
      progressType={progressType}
      slug={slug}
      unit={unit}
      value={accrued}
      branding={branding}
    />
  </>
)

RewardCircular.propTypes = {
  accrued: number,
  caption: string,
  progressType: string,
  slug: string,
  unit: string,
  completed: bool,
  completedPercentage: number,
  branding: shape({
    valueFontFamily: string,
    valueFontSize: string,
    valueTextColor: string,
    captionFontFamily: string,
    captionFontSize: string,
    captionTextColor: string,
    inProgressColor: string,
    completedColor: string,
    trackColor: string,
    widgetBackgroundColor: string,
  }),
}

export default RewardCircular
