import {Grid, Typography} from "@mui/material"
import {object} from "prop-types"

import Expandable from "components/expandable/expandable"

import Feature from "../../feature/feature"
import CardOnFile from "./card-on-file-widget"
import EnrollmentWidget from "./enrollment-widget"
import MobileAppDonut from "./mobile-app-widget"
import OptInWidget from "./opt-in-widget"

const AdvancedAggregateWidgetReporting = ({classes}) => {
  return (
    <Feature featureKey="aggregate-widget-reporting">
      <div className={classes.section}>
        <Typography className={classes.header} variant="h5">
          Widgets
        </Typography>

        <Expandable
          label="Hide section"
          labelClassName={classes.hideChartLabel}
          startExpanded={true}
        >
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
              <MobileAppDonut />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
              <CardOnFile />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
              <OptInWidget />
            </Grid>
          </Grid>
          <EnrollmentWidget />
        </Expandable>
      </div>
    </Feature>
  )
}

AdvancedAggregateWidgetReporting.propTypes = {
  classes: object,
}

export default AdvancedAggregateWidgetReporting
