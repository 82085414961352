import {ThemeProvider} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import {push} from "connected-react-router"
import {func, object, shape, string} from "prop-types"
import {Component} from "react"
import {connect} from "react-redux"

import BackButton from "components/back-button/back-button"
import ContentBlockEditor from "components/content-block-editor/content-block-editor"
import {ContentBlockEditorProvider} from "components/content-block-editor/content-block-editor-context"
import PreviewControls from "components/content-block-editor/preview-controls"
import DocumentTitle from "components/document-title/document-title"
import ContentWrapper from "components/journeys/content-wrapper"
import {JourneyContextProvider} from "components/journeys/journey-context"
import PreviewWrapper from "components/templates/preview-wrapper"
import {getTemplateWithPages as getTemplateWithPagesAction} from "components/templates/template-actions"
import withParamKey from "components/with-param-key/with-param-key"

import {TemplateMessageContextProvider} from "contexts/template-message-context"
import {
  deleteMessage,
  duplicateMessage,
  duplicateTemplateMessage,
  fetchMessage,
  fetchTemplateMessage,
  removeTemplateMessage,
  updateMessage,
  updateTemplateMessage,
} from "lib/api"
import withDispatch from "lib/with-dispatch"
import createTeamTheme from "themes/team-theme-creator"

import MessageContainer from "./message-container"
import MessageSidebar from "./message-sidebar"

class MessageEditor extends Component {
  state = {}

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const {
      match: {
        params: {templateId, templateMessageId, messageId},
      },
      getTemplateWithPages,
    } = this.props

    if (templateId) {
      getTemplateWithPages(templateId)

      fetchTemplateMessage(templateId, templateMessageId).then(templateMessage => {
        const {message} = templateMessage

        this.setState({
          templateMessage,
          message,
        })
      })
    } else {
      fetchMessage(messageId).then(message =>
        this.setState({
          message,
        })
      )
    }
  }

  onUpdateMessage = async attrs => {
    const message = await updateMessage(this.state.message.id, attrs)
    this.setState({message})
  }

  onUpdateTemplateMessage = async attrs => {
    const {
      match: {
        params: {templateId, templateMessageId},
      },
    } = this.props

    const templateMessage = await updateTemplateMessage(templateId, templateMessageId, attrs)
    this.setState({templateMessage})
  }

  onDuplicate = async () => {
    const {
      dispatch,
      match: {
        params: {templateId},
      },
    } = this.props
    const {templateMessage, message} = this.state

    if (templateId) {
      const newTemplateMessage = await duplicateTemplateMessage(templateId, templateMessage.id)

      this.setState({
        templateMessage: newTemplateMessage,
        message: newTemplateMessage.message,
      })
    } else {
      const newMessage = await duplicateMessage(message.id)

      dispatch(push(`/admin/templates/content-library/messages/${newMessage.id}`))
    }
  }

  onRemoveFromTemplate = async () => {
    const {
      dispatch,
      match: {
        params: {templateId},
      },
    } = this.props
    const {
      templateMessage: {id},
    } = this.state

    await removeTemplateMessage(templateId, id)

    dispatch(push(`/admin/templates/${templateId}/content`))
  }

  onDelete = async () => {
    const {dispatch} = this.props
    const {
      message: {id},
    } = this.state

    await deleteMessage(id)

    dispatch(push("/admin/templates/content-library"))
  }

  render() {
    const {message, templateMessage} = this.state

    const {
      match: {
        params: {templateId},
      },
      classes,
      teamThemeStyles,
      template,
    } = this.props

    return (
      <ContentBlockEditorProvider>
        {templateId ? (
          <>
            <DocumentTitle title={`${template?.name} - ${message?.contentName} - Editor`} />
            <BackButton
              className={classes.returnLink}
              to={`/admin/templates/${templateId}/content`}
            >
              Return to Template
            </BackButton>
          </>
        ) : (
          <>
            <DocumentTitle title={`${message?.contentName} - Editor`} />
            <BackButton className={classes.returnLink} to={`/admin/templates/content-library`}>
              Return to Content Library
            </BackButton>
          </>
        )}
        <PreviewControls layoutBasis={message?.layoutBasis} />
        <PreviewWrapper>
          {message?.id && (
            <ContentWrapper type="email">
              <MessageContainer message={message}>
                <JourneyContextProvider
                  legacyInitialState={{
                    template,
                    // onNavigateToPage isn't relevant to messages, so this will be a no-op.
                    onNavigateToPage: () => {},
                  }}
                >
                  <TemplateMessageContextProvider
                    content_variables={templateMessage?.content_variables}
                  >
                    <ThemeProvider theme={createTeamTheme(template?.theme || teamThemeStyles)}>
                      <ContentBlockEditor
                        initialContainer={{...message}}
                        containerId={message.id}
                        initialMode="Edit"
                      />
                    </ThemeProvider>
                  </TemplateMessageContextProvider>
                </JourneyContextProvider>
              </MessageContainer>
            </ContentWrapper>
          )}
          <MessageSidebar
            templateMessage={templateMessage}
            message={message}
            template={template}
            onUpdateMessage={this.onUpdateMessage}
            onDuplicate={this.onDuplicate}
            onDelete={this.onDelete}
            onRemoveFromTemplate={this.onRemoveFromTemplate}
            onUpdateTemplateMessage={this.onUpdateTemplateMessage}
          />
        </PreviewWrapper>
      </ContentBlockEditorProvider>
    )
  }
}

MessageEditor.propTypes = {
  dispatch: func.isRequired,
  match: shape({
    params: shape({
      templateId: string,
      templateMessageId: string,
      messageId: string,
    }),
  }),
  location: shape({
    hash: string,
  }),
  getTemplateWithPages: func.isRequired,
  classes: object,
  teamThemeStyles: object,
  template: shape({
    name: string,
  }),
}

const styles = theme => ({
  returnLink: {
    float: "left",
    paddingTop: 5,
    position: "absolute",
    left: 30,
    width: "auto",
    zIndex: 100,
  },
})

const getKey = ({templateId, messageId, templateMessageId}) => {
  if (templateId) return `template-${templateId}-tm-${templateMessageId}`
  else return `message-${messageId}`
}

const mapStateToProps = ({template, session}) => ({
  template,
  teamThemeStyles: session?.team?.themeStyles,
})

const mapDispatchToProps = {
  getTemplateWithPages: getTemplateWithPagesAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withParamKey(getKey)(withStyles(styles)(withDispatch(MessageEditor))))
