/* eslint-disable react/prop-types */
// FIXME disabled-react-prop-types
import {Typography, useTheme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {number, object, string} from "prop-types"
import {useEffect, useMemo, useState} from "react"
import {
  Bar,
  ComposedChart,
  Label,
  Legend,
  Line,
  Rectangle,
  ResponsiveContainer,
  Surface,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import {useInsights} from "components/template-insights/insights-context"

import {fetchInsightObjectivePerformance} from "lib/api"

import {COLORS} from "./insights-helpers"

const useStyles = makeStyles(theme => ({
  chart404: {
    color: theme.palette.brand.mediumGray,
    fontSize: 20,
    fontStyle: "italic",
    fontWeight: 500,
    marginLeft: -100,
    left: "50%",
    position: "absolute",
    top: 175,
  },
  chartContainer: {
    position: "relative",
  },
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
  legendContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    textAlign: "center",

    // Applies to all children except last
    "& > :nth-last-child(n + 2)": {
      marginRight: 10,
    },
  },
  legendIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 4,
  },
  tooltipContainer: {
    background: "white",
    border: "2px solid #cccccc",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
}))

const CompletionRateBarShape = ({height, width, x, y, payload: {rateDifference}}) => {
  const color = (() => {
    if (!rateDifference) return COLORS[0]
    if (rateDifference >= 0) return COLORS[0]
    if (rateDifference < 0) return COLORS[2]
  })()

  return <Rectangle fill={color} height={height} width={width} x={x} y={y} />
}

CompletionRateBarShape.propTypes = {
  height: number,
  payload: object,
  width: number,
  x: number,
  y: number,
}

const CustomBarLabel = ({value, viewBox}) => (
  <Label fill="white" fontSize={14} offset={5} position="insideRight" viewBox={viewBox}>
    {`${value}%`}
  </Label>
)

CustomBarLabel.propTypes = {
  value: string,
  viewBox: object,
}

const LegendContent = ({classes, theme}) => () => (
  <ul className={classes.legendContainer}>
    <li className={classes.legendContainer}>
      <LegendIcon classes={classes} fill={COLORS[0]} />
      Outperforming
    </li>
    <li className={classes.legendContainer}>
      <LegendIcon classes={classes} fill={COLORS[2]} />
      Underperforming
    </li>
    <li className={classes.legendContainer}>
      <LegendIcon classes={classes} fill={theme.palette.brand.mediumGray} />
      Benchmark
    </li>
  </ul>
)

const LegendIcon = ({classes, fill}) => (
  <Surface
    className={classes.legendIcon}
    height={14}
    viewBox={{height: 32, width: 32, x: 0, y: 0}}
    width={14}
  >
    <Rectangle fill={fill} height={32} width={32} />
  </Surface>
)

LegendIcon.propTypes = {
  classes: object,
  fill: string,
}

const RateDifferenceLabel = ({data, theme}) => ({index, viewBox, y}) => {
  let props

  const rate = data[index]?.rateDifference
  const objectiveCompletionRate = data[index]?.objectiveCompletionRate

  if (objectiveCompletionRate && !rate) return null
  else if (objectiveCompletionRate === 0)
    props = {
      fill: theme.palette.brand.mediumGray,
      fontStyle: "italic",
      children: "No reported data",
      viewBox: {...viewBox, x: 100, y: y - 10},
    }
  else if (rate >= 0)
    props = {fill: COLORS[1], children: `+${rate}%`, viewBox: {...viewBox, y: y + 10}}
  else if (rate < 0)
    props = {fill: COLORS[2], children: `${rate}%`, viewBox: {...viewBox, y: y + 10}}

  return <Label fontSize={18} offset={15} position="right" {...props} />
}

const TooltipContent = classes => ({active, label, payload: outerPayload}) => {
  if (active && outerPayload && outerPayload.length) {
    const rank = {"Objective Completion Rate": 0, Benchmark: 1, "Rate Difference": 2}

    return (
      <div className={classes.tooltipContainer}>
        <p>{label}</p>
        {outerPayload
          .sort((a, b) => rank?.[a.name] - rank?.[b.name])
          .map(({color, dataKey, name, payload: innerPayload}) => {
            const {rateDifference, objectiveCompletionRate} = innerPayload

            const tooltipBuilder = {
              "Objective Completion Rate": (() => {
                if (!objectiveCompletionRate) return {isHidden: true}
                if (!rateDifference) return {color: COLORS[0]}
                if (rateDifference >= 0) return {color: COLORS[0]}
                if (rateDifference < 0) return {color: COLORS[2]}
              })(),
              "Rate Difference": (() => {
                if (!objectiveCompletionRate || !rateDifference) return {isHidden: true}

                if (rateDifference >= 0)
                  return {color: COLORS[1], name: "Overperforming by", value: `+${rateDifference}%`}

                if (rateDifference < 0)
                  return {color: COLORS[2], name: "Underperforming by", value: `${rateDifference}%`}
              })(),
            }

            const fill = tooltipBuilder?.[name]?.color ?? color
            const key = tooltipBuilder?.[name]?.name ?? name
            const value = tooltipBuilder?.[name]?.value ?? `${innerPayload[dataKey]}%`
            const isHidden = tooltipBuilder?.[name]?.isHidden

            return (
              !isHidden && (
                <p key={`tooltip-${dataKey}`} style={{color: fill}}>{`${key} : ${value}`}</p>
              )
            )
          })}
      </div>
    )
  }

  return null
}

const ObjectivePerformanceInsightsBarchart = () => {
  const [data, setData] = useState([])
  const [domainX, setDomainX] = useState(100)

  const classes = useStyles()
  const theme = useTheme()
  const {
    insights: {objective_performance},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const objectivePerformance = useMemo(() => objective_performance?.value.result || null, [
    objective_performance,
  ])

  const setXScale = newData => {
    // Inspect data to find our barchart scale. Break at the first > 50 value.
    setDomainX(
      newData?.find(
        ({benchmark, objectiveCompletionRate}) => benchmark > 50 || objectiveCompletionRate > 50
      )
        ? 100
        : 50
    )
  }

  useEffect(() => {
    fetchInsightObjectivePerformance(templateId, filter).then(addInsightsCache)
  }, [addInsightsCache, templateId, filter])

  useEffect(() => {
    if (!objectivePerformance) return
    setData(objectivePerformance)
    setXScale(objectivePerformance)
  }, [objectivePerformance])

  return (
    <div className={classes.chartContainer}>
      <Typography className={classes.header} variant="h5">
        Objective Performance Rate
      </Typography>
      <ResponsiveContainer height={data.length * 100 || 300}>
        <ComposedChart
          barGap={0}
          data={data}
          layout="vertical"
          margin={{top: 5, right: 75, bottom: 5, left: 5}}
        >
          <XAxis domain={[0, domainX]} hide={data.length > 0} type="number" />
          <YAxis dataKey="name" type="category" width={100} />
          <Tooltip content={TooltipContent(classes)} cursor={false} />
          <Legend content={LegendContent({classes, theme})} />
          <Bar
            barSize={40}
            dataKey="objectiveCompletionRate"
            label={CustomBarLabel}
            legendType={"square"}
            name="Objective Completion Rate"
            shape={CompletionRateBarShape}
          />
          <Line
            activeDot={false}
            dataKey={({benchmark, objectiveCompletionRate}) =>
              Math.max(benchmark, objectiveCompletionRate)
            }
            isAnimationActive={false}
            label={RateDifferenceLabel({data, theme})}
            name="Rate Difference"
            style={{visibility: "hidden"}}
          />
          <Bar
            barSize={20}
            dataKey="benchmark"
            fill={theme.palette.brand.mediumGray}
            label={CustomBarLabel}
            name="Benchmark"
          />
        </ComposedChart>
      </ResponsiveContainer>

      {data.length < 1 && <Typography className={classes.chart404}>No Data Found</Typography>}
    </div>
  )
}

export default ObjectivePerformanceInsightsBarchart
