import {Container, FormControl, MenuItem, TextField} from "@mui/material"
import {bool, number, shape, string} from "prop-types"
import React, {useContext, useEffect, useState} from "react"

import DOSelect from "components/do-select/do-select"
import {RewardSetFormContext} from "components/reward/reward-set-form-context-provider"
import {compileValues, isDirty} from "components/reward/reward-set-form-helpers"
import {
  ConditionCard,
  ConditionCardContent,
  Form,
} from "components/reward/reward-step-conditions/styled-components"
import {RewardsContext} from "components/reward/rewards-context-provider"

import {requiredField, validPositiveInteger} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const PageCompleted = ({initialValues, isEditable}) => {
  const {dispatch} = useContext(RewardSetFormContext)
  const {templates} = useContext(RewardsContext)

  const [pageOptions, setPageOptions] = useState([])

  // Form config
  const {field, inputs} = useForm({
    initialValues: {
      templateId: !!initialValues.meta.templateId ? initialValues.meta.templateId : "",
      templatePageId: !!initialValues.meta.templatePageId ? initialValues.meta.templatePageId : "",
      value: initialValues.value,
    },
    validators: {
      templateId: [requiredField],
      templatePageId: [requiredField],
      value: [validPositiveInteger],
    },
  })

  // When inputs change, dispatch updated step state
  useEffect(() => {
    const {value, ...meta} = compileValues(inputs)
    const updated = {
      data: {
        condition: "page_completed",
        id: initialValues.id,
        meta,
        value,
      },
      isDirty: isDirty(inputs),
      isValid: Object.values(inputs).reduce((acc, fieldObject) => {
        if (!acc) {
          return acc
        }

        if (fieldObject.name === "templateId" && !requiredField(initialValues.meta.templateId)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "templatePageId" &&
          !requiredField(initialValues.meta.templatePageId)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (fieldObject.name === "value" && !validPositiveInteger(initialValues.value)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        return "error" in fieldObject ? !fieldObject.error : false
      }, true),
    }

    dispatch({type: "SET_REWARD_STEP_FORM", rewardStepForm: updated})
  }, [dispatch, initialValues, inputs])

  // Compile list of pages on selection of templateId
  useEffect(() => {
    // CxTBD validate next line
    const templateId = isEditable ? inputs.templateId.value : initialValues.meta.templateId

    if (templates.length) {
      const template = templates.find(({id}) => id === templateId)
      const options = template
        ? template.templatePages.map(({id, page: {contentName}}) => ({
            key: id,
            value: contentName,
          }))
        : []

      setPageOptions(options)
    }
  }, [
    initialValues.meta.templateId,
    inputs.templateId.value,
    isEditable,
    setPageOptions,
    templates,
  ])

  return (
    templates?.length && (
      <ConditionCard>
        <ConditionCardContent>
          <Form id={`page-completed-${initialValues.id}`}>
            <FormControl disabled={!isEditable} error={!!inputs.templateId.error} fullWidth>
              <DOSelect id="template-id" label="Campaign" {...field("templateId")}>
                {templates.map(template => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name.length < 32
                      ? template.name
                      : `${template.name.slice(0, 31)} ...`}
                  </MenuItem>
                ))}
              </DOSelect>
            </FormControl>

            <Container sx={{paddingTop: "1em"}} />
            <FormControl
              disabled={!isEditable || !inputs.templateId.value}
              error={!!inputs.templateId.value && !!inputs.templatePageId.error}
              fullWidth
            >
              <DOSelect id="template-page-id" label="Page" {...field("templatePageId")}>
                {pageOptions.map(option => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </DOSelect>
            </FormControl>

            <Container sx={{paddingTop: "1em"}} />
            <TextField
              disabled={!isEditable}
              label="Value"
              fullWidth={true}
              type="number"
              {...field("value")}
            />

            <Container sx={{paddingTop: "1em"}} />
          </Form>
        </ConditionCardContent>
      </ConditionCard>
    )
  )
}

PageCompleted.defaultProps = {
  initialValues: {
    condition: "page-completed",
    meta: {
      templateId: undefined,
      templatePageId: undefined,
    },
  },
  value: undefined,
  validDefaults: [],
}

PageCompleted.propTypes = {
  initialValues: shape({
    condition: string,
    id: string.isRequired,
    meta: shape({
      templateId: string,
      templatePageId: string,
    }),
    value: number,
  }).isRequired,
  isEditable: bool,
}

export default PageCompleted
