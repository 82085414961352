import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {flatten} from "lodash"
import {arrayOf, node, number, object, string} from "prop-types"

import pluralize from "lib/string/pluralize"

import {
  allAccountFields,
  allContactFields,
} from "../teams/csv-processing-settings/csv-processing-helpers"

const useStyles = makeStyles(theme => ({
  term: {
    fontWeight: "bold",
  },
  definition: {
    marginInlineStart: 0,
  },
  number: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  errorsHeader: {
    fontSize: "1.1rem",
  },
}))

const combineErrors = (acc, curr) => {
  const transformed = transformErrors(curr)

  return {
    columns: [...flatten(acc.columns), ...flatten(transformed.columns)],
    rows: [...acc.rows, ...transformed.rows],
  }
}

const transformErrors = validationErrors =>
  validationErrors.reduce(
    ({columns, rows}, {errors, record}) => {
      if (!record) return {columns, rows}

      const flattened = Object.entries(flattenRecord(record)).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: errors[key] ? {value, errors: errors[key].replace(/has /, "")} : {value},
        }),
        {}
      )

      return {
        columns: [...new Set([...columns, Object.keys(flattened)])],
        rows: [...rows, flattened],
      }
    },
    {columns: [], rows: []}
  )

const flattenRecord = (apiRecord, namespace = "") =>
  Object.entries(apiRecord)
    .filter(([key]) => !keysToRemove.includes(key))
    .reduce((acc, [key, value]) => {
      switch (key) {
        case "meta_public":
        case "meta_private":
          return {
            ...acc,
            ...flattenRecord(value, key),
          }

        case "journey":
          return {
            ...acc,
            ...flattenRecord(value),
          }
        default:
          return {
            ...acc,
            [`${namespace === "" ? key : `${namespace}.${key}`}`]: value,
          }
      }
    }, {})

const keysToRemove = ["batch_id", "source"]

const batchesToResults = batches => {
  const results = batches
    .filter(b => !!b.results)
    .reduce(
      (acc, {results}) => ({
        new: acc.new + results.new,
        existing: acc.existing + results.existing,
        failed: acc.failed + results.failed,
        errors: combineErrors(acc.errors, results.errors),
      }),
      {new: 0, existing: 0, failed: 0, errors: {columns: [], rows: []}}
    )

  return {
    ...results,
    errors: {...results.errors, ...{columns: Array.from(new Set(results.errors.columns))}},
  }
}

const DescriptionDetails = ({children}) => <dd style={{marginInlineStart: 0}}>{children}</dd>

DescriptionDetails.propTypes = {
  children: node,
}

const DescriptionTerm = ({children}) => <dt style={{fontWeight: "bold"}}>{children}</dt>

DescriptionTerm.propTypes = {
  children: node,
}

const useNumberStyles = makeStyles(theme => ({
  number: {
    color: theme.palette.primary.main,
  },
}))

const NumberDisplay = ({number: numberToDisplay}) => {
  const classes = useNumberStyles()

  return <span className={classes.number}>{numberToDisplay.toLocaleString()}</span>
}

NumberDisplay.propTypes = {
  number,
}

const BatchResults = ({batches, type}) => {
  const classes = useStyles()
  const results = batchesToResults(batches)

  return (
    <>
      <dl className={classes.list}>
        <DescriptionTerm>Upload results</DescriptionTerm>
        <DescriptionDetails>
          <NumberDisplay number={results.new} /> {pluralize(type, results.new)} created
        </DescriptionDetails>
        <DescriptionDetails>
          <NumberDisplay number={results.existing} /> {pluralize(type, results.existing)} updated
        </DescriptionDetails>
        <DescriptionDetails>
          <NumberDisplay number={results.failed} /> {pluralize(type, results.failed)} failed
          validation
        </DescriptionDetails>
      </dl>
      {results.errors.rows.length > 0 && (
        <>
          <Typography className={classes.errorsHeader} variant="h2">
            Errors
          </Typography>
          <ErrorsTable {...results.errors} />
        </>
      )}
    </>
  )
}

BatchResults.propTypes = {
  batches: arrayOf(object),
  type: string,
}

const useErrorsTableStyles = makeStyles(theme => ({
  table: {
    marginBottom: theme.spacing(2),
  },
  value: {
    display: "block",
  },
  error: {
    display: "block",
    color: theme.palette.error.main,
  },
  cell: {
    borderBottom: "none !important",
  },
}))

const columnDisplayValue = column =>
  [...allAccountFields, ...allContactFields].find(({value}) => value === column)?.name ?? column

const ErrorsTable = ({columns, rows}) => {
  const classes = useErrorsTableStyles()

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell classes={{root: classes.cell}} key={column}>
              {columnDisplayValue(column)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            {columns.map(column => (
              <TableCell classes={{root: classes.cell}} key={column}>
                <span className={classes.value}>{row[column]?.value ?? ""}</span>
                {row[column]?.errors && <span className={classes.error}>{row[column].errors}</span>}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

ErrorsTable.propTypes = {
  columns: arrayOf(string),
  rows: arrayOf(object),
}

export default BatchResults
