import {createContext} from "react"

export const ContentType = {
  ContentContainer: "ContentContainer",
  Page: "Page",
  EmailBody: "EmailBody",
  EmailSubject: "EmailSubject",
  Sms: "Sms",
  TemplateFooter: "TemplateFooter",
}

export const isContentContainer = type => type === ContentType.ContentContainer
export const isPage = type => type === ContentType.Page
export const isEmailBody = type => type === ContentType.EmailBody
export const isEmailSubject = type => type === ContentType.EmailSubject
export const isSms = type => type === ContentType.Sms
export const isTemplateFooter = type => type === ContentType.TemplateFooter

export const contentTypeProp = (props, propName, componentName) => {
  const value = props[propName]

  if (!value || ContentType[value]) return

  return new Error(
    `Invalid prop(${props[propName]}) \`${propName}\` supplied to` +
      ` \`${componentName}\`. Validation failed.`
  )
}

export const {Provider, Consumer} = createContext()

export const editorContext = Component => props => (
  <Consumer>{context => <Component {...props} {...context} />}</Consumer>
)

export default {Provider, Consumer, editorContext}
