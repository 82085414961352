import {IconButton, Tooltip} from "@mui/material"
import cx from "classnames"
import {func, object} from "prop-types"

import {hasEntity, removeEntity, setEntity} from "./entity-button-helpers"

const Subscript = ({onSetEditorState, editorState, classes}) => {
  const onClick = () => {
    onSetEditorState(
      hasEntity(editorState, "SUBSCRIPT")
        ? removeEntity(editorState)
        : setEntity(editorState, "SUBSCRIPT")
    )
  }

  return (
    <Tooltip title="Subscript">
      <IconButton
        className={cx(classes.button, {
          [classes.buttonActive]: hasEntity(editorState, "SUBSCRIPT"),
        })}
        onClick={onClick}
        type="button"
        size="medium"
      >
        <div>
          X<sub style={{fontSize: 12}}>2</sub>
        </div>
      </IconButton>
    </Tooltip>
  )
}

Subscript.propTypes = {
  classes: object,
  editorState: object,
  onSetEditorState: func,
}

export default Subscript
