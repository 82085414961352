import arrayMove from "array-move"
import cx from "classnames"
import {arrayOf, bool, func, object, shape, string} from "prop-types"
import {Component} from "react"

import ConditionalClickAwayListener from "lib/mui-conditional-click-away-listener"

import {journeyContext} from "./journey-context"
import NavigationList from "./navigation-list"

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {templatePages: props.templatePages}
  }

  static getDerivedStateFromProps({templatePages}, state) {
    if (state.templatePages !== templatePages) return {templatePages}

    return null
  }

  onSortPages = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) return

    const templatePages = arrayMove(this.state.templatePages, oldIndex, newIndex)

    this.setState({templatePages})
    this.props.onSortPages(templatePages.map(({id}, index) => ({id, order: index + 1})))
  }

  render() {
    const {
      themeClasses,
      isMobileMenuOpen,
      onCloseMobileMenu,
      completedPages,
      currentPageId,
      isSortable,
      pageUrlGenerator,
      visitedPages,
    } = this.props

    const {templatePages} = this.state

    return (
      <ConditionalClickAwayListener isPresent={isMobileMenuOpen} onClickAway={onCloseMobileMenu}>
        <nav
          className={cx(`navigation ${themeClasses.navigation}`, {
            [themeClasses.navigationMobileMenuOpen]: isMobileMenuOpen,
            [themeClasses.navigationHidden]: templatePages.length <= 1,
          })}
        >
          <NavigationList
            axis="xy"
            completedPages={completedPages}
            currentPageId={currentPageId}
            isSortable={isSortable}
            onCloseMobileMenu={onCloseMobileMenu}
            onSortEnd={this.onSortPages}
            pages={templatePages.map(tp => tp.page)}
            pageUrlGenerator={pageUrlGenerator}
            useWindowAsScrollContainer={true}
            visitedPages={visitedPages}
          />
        </nav>
      </ConditionalClickAwayListener>
    )
  }
}

Navigation.propTypes = {
  completedPages: arrayOf(string),
  currentPageId: string.isRequired,
  isMobileMenuOpen: bool,
  isSortable: bool,
  onCloseMobileMenu: func.isRequired,
  onSortPages: func,
  pageUrlGenerator: func.isRequired,
  templatePages: arrayOf(
    shape({
      page: shape({
        id: string.isRequired,
        navigationTitle: string.isRequired,
      }),
    })
  ),
  themeClasses: object.isRequired,
  visitedPages: arrayOf(string),
}

export default journeyContext(Navigation)
