import {Button, SvgIcon, TextField, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, func, object, shape, string} from "prop-types"
import {useState} from "react"
import {FiAlertCircle as AlertIcon} from "react-icons/fi"
import {MdChat as ChatIcon, MdCheckCircle as CheckIcon, MdEmail as EmailIcon} from "react-icons/md"
import {useSelector} from "react-redux"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {useJourneyContext} from "components/journeys/journey-context"

import {resendVerification, updateContactSubscription} from "lib/api"
import {teamConfig} from "lib/config"
import {requiredField, validEmail, validPhone} from "lib/field-validations"
import useForm from "lib/hooks/use-form"
import {fullName} from "lib/names"

import PhoneTextMask from "../../../phone-text-mask/phone-text-mask"
import SaveButton from "../../../save-button/save-button"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import {buttonColors} from "../helpers/button-colors"
import OptInFinePrint from "./opt-in-fine-print"

const OptInReadonly = ({className, contentBlock, customCss, onClick, pageId, owner, style}) => {
  const {
    data: {backgroundColor, iconSrc},
    id,
  } = contentBlock
  const searchParams = new URLSearchParams(window.location.search)
  const verificationSuccessful = searchParams.get("verificationSuccessful")
  const classes = useStyles()
  const journeyContextData = useJourneyContext()
  const {isApprovalView, onUpdateContact, journeyId} = journeyContextData
  const contact = journeyContextData.contact ?? {}

  const {privacyPolicyUrl, teamName, termsOfServiceUrl} = useSelector(({session}) => ({
    teamName: session?.team?.name,
    termsOfServiceUrl: teamConfig(session?.team, "do").termsOfServiceUrl,
    privacyPolicyUrl: teamConfig(session?.team, "do").privacyPolicyUrl,
  }))
  const finePrintProps = {
    classOverrides: {finePrintWrapper: classes.finePrintWrapper},
    teamName,
    privacyPolicyUrl,
    termsOfServiceUrl,
  }

  const onSubmit = fields => {
    if (!isApprovalView) {
      if (!(fields.email || fields.phoneMobile)) return Promise.resolve()

      const params = {
        ...fields,
        journeyId,
        redirectUrlOnVerify: window.location.href,
      }

      if (params.email) params.emailOptedIn = true

      if (params.phoneMobile) params.smsOptedIn = true

      return updateContactSubscription(contact.id, params, {pageId}).then(onUpdateContact)
    }
  }

  const {change, submitting, invalid, field, handleSubmit} = useForm({
    onSubmit,
    validators: {
      email: contact.email ? [] : [requiredField, validEmail],
      phoneMobile: contact.phoneMobile ? [] : [requiredField, validPhone],
    },
  })

  const [isResending, setIsResending] = useState(false)
  const [isOptingIn, setIsOptingIn] = useState(false)

  // We made the decision when writing this that we're not accounting for the
  // case where someone has neither. The whole component would probably have
  // to change quite a bit to account for that -- two forms?
  if (contact.id && !contact.phoneMobile && !contact.email)
    throw new Error("This contact has neither a phone number nor an email")

  if (contact.id && !contact.smsOptedIn && !contact.emailOptedIn)
    throw new Error("This contact has neither a phone number nor an email opted in")

  if (verificationSuccessful === "false")
    return (
      <div className={className} data-testid="opt-in-root" onClick={onClick} style={style}>
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        <style>{customCss}</style>
        <div className={classes.complete}>
          <Typography className={classes.completeText}>Oops!</Typography>
          <SvgIcon className={cx(classes.completeIcon, classes.failureIcon)}>
            <AlertIcon />
          </SvgIcon>
          <Typography className={classes.completeText}>
            Sorry, it looks like your contact information verification was unsuccessful.
            <br />
            Please contact{" "}
            <a href={`mailto:${owner.email}`} rel="noopener noreferrer" target="_blank">
              {fullName(owner)}
            </a>{" "}
            for assistance.
          </Typography>
        </div>
        <OptInFinePrint {...finePrintProps} />
      </div>
    )

  if (
    contact.phoneMobile &&
    contact.smsVerified &&
    contact.email &&
    contact.emailVerified &&
    contact.emailOptedIn &&
    contact.smsOptedIn
  )
    return (
      <div className={className} data-testid="opt-in-root" onClick={onClick} style={style}>
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        <style>{customCss}</style>
        <div className={classes.complete}>
          <Typography className={classes.completeText}>Congratulations!</Typography>
          <SvgIcon className={classes.completeIcon}>
            <CheckIcon />
          </SvgIcon>
          <Typography className={classes.completeText}>
            Your contact information has been added and verified for security.
          </Typography>
        </div>
        <OptInFinePrint {...finePrintProps} />
      </div>
    )

  const smsSetButNotVerified = contact.phoneMobile && !contact.smsVerified
  const emailSetButNotVerified = contact.email && !contact.emailVerified
  const type = smsSetButNotVerified ? "sms" : "email"

  const onResend = () => {
    setIsResending(true)
    resendVerification(contact.id, type, {
      journeyId,
      redirectTo: window.location.href,
    }).then(() => setIsResending(false))
  }

  const onReset = attr =>
    updateContactSubscription(contact.id, {[attr]: null}).then(onUpdateContact)

  const onOptIn = attr => {
    let params = {}

    if (attr === "phoneMobile") params = {journeyId, sms_opted_in: true}
    if (attr === "email") params = {journeyId, email_opted_in: true}

    setIsOptingIn(true)
    updateContactSubscription(contact.id, params, {pageId})
      .then(onUpdateContact)
      .then(() => setIsOptingIn(false))
  }

  const contactInfo = {
    sms: {
      device: "phone",
      humanizedAttr: "mobile number",
      attr: "phoneMobile",
      value: contact.phoneMobile,
    },
    email: {
      device: "email",
      humanizedAttr: "email address",
      attr: "email",
      value: contact.email,
    },
  }

  if (smsSetButNotVerified || emailSetButNotVerified) {
    const {device, attr, humanizedAttr} = contactInfo[type]

    return (
      <div className={className} data-testid="opt-in-root" onClick={onClick} style={style}>
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        <style>{customCss}</style>
        <Typography gutterBottom={true}>
          Please check your {device} for a confirmation message and click the link to confirm your{" "}
          {humanizedAttr}.
        </Typography>
        <Typography gutterBottom={true}>
          If you didn't receive a message please click retry or re-enter your {humanizedAttr}.
        </Typography>
        <div className={classes.buttonContainer}>
          <SaveButton
            className={classes.resendButton}
            color="primary"
            iconSrc={iconSrc}
            onClick={onResend}
            stateLabels={{default: "Resend", submitting: "Resending...", saved: "Resent!"}}
            style={buttonColors({backgroundColor})}
            submitting={isResending}
          />
          <Button onClick={() => onReset(attr)} variant="contained">
            Re-enter {humanizedAttr}
          </Button>
        </div>
        <OptInFinePrint {...finePrintProps} />
      </div>
    )
  }

  const smsVerifiedButNotOptedIn = contact.phoneMobile && contact.smsVerified && !contact.smsOptedIn
  const emailVerifiedButNotOptedIn = contact.email && contact.emailVerified && !contact.emailOptedIn

  if (smsVerifiedButNotOptedIn || emailVerifiedButNotOptedIn) {
    const typeNotOptedIn = smsVerifiedButNotOptedIn ? "sms" : "email"

    return (
      <div className={className} data-testid="opt-in-root" onClick={onClick} style={style}>
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        <style>{customCss}</style>
        <Typography gutterBottom={true}>
          We have a verified {contactInfo[typeNotOptedIn].humanizedAttr} on file for you. Would you
          like to opt in to receive communications at {contactInfo[typeNotOptedIn].value}?
        </Typography>
        <div className={classes.buttonContainer}>
          <SaveButton
            color="primary"
            iconSrc={iconSrc}
            onClick={() => onOptIn(contactInfo[typeNotOptedIn].attr)}
            stateLabels={{
              default: `Opt In ${contactInfo[typeNotOptedIn].humanizedAttr}`,
              submitting: "Submitting...",
              saved: "Opted In!",
            }}
            style={buttonColors({backgroundColor})}
            submitting={isOptingIn}
          />
        </div>
        <OptInFinePrint {...finePrintProps} />
      </div>
    )
  }

  const {onChange: unusedOnChange, ...fieldProps} = field("phoneMobile")
  const onAccept = value => change("phoneMobile", value)

  return (
    <div className={className} data-testid="opt-in-root" onClick={onClick} style={style}>
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <form disabled={submitting} onSubmit={handleSubmit}>
        {!contact.email && (
          <div className={classes.row}>
            <SvgIcon className={classes.rowIcon}>
              <EmailIcon />
            </SvgIcon>
            <TextField
              fullWidth={true}
              id={`${id}-opt-in-email`}
              label="Enter your email address"
              type="email"
              {...field("email")}
            />
          </div>
        )}

        {!contact.phoneMobile && (
          <div className={classes.row}>
            <SvgIcon className={classes.rowIcon}>
              <ChatIcon />
            </SvgIcon>
            <TextField
              fullWidth={true}
              id={`${id}-opt-in-phone-mobile`}
              InputProps={{inputProps: {onAccept}, inputComponent: PhoneTextMask}}
              label="Enter your mobile number"
              {...fieldProps}
            />
          </div>
        )}
        <div className={classes.buttonWrapper}>
          <SaveButton
            className={classes.submitButton}
            color="primary"
            disabled={invalid}
            iconSrc={iconSrc}
            stateLabels={{default: "Submit", submitting: "Submitting...", saved: "Submitted!"}}
            style={buttonColors({backgroundColor})}
            submitting={submitting}
          />
        </div>
      </form>
      <OptInFinePrint {...finePrintProps} />
    </div>
  )
}

OptInReadonly.propTypes = {
  className: string,
  contact: shape({
    email: string,
    emailVerified: bool,
    id: string,
    phoneMobile: string,
    smsVerified: bool,
  }),
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  onClick: func,
  owner: shape({
    email: string,
  }),
  pageId: string,
  privacyPolicyUrl: string,
  teamName: string,
  termsOfServiceUrl: string,
  style: object,
}

const useStyles = makeStyles(theme => ({
  finePrintWrapper: {
    margin: "0 auto",
    marginTop: 30,
    maxWidth: 453,
    lineHeight: "14px",
  },
  row: {
    display: "flex",
    alignItems: "flex-end",
    margin: "0 auto",
    maxWidth: 350,
    marginBottom: 10,
  },
  rowIcon: {
    marginRight: 15,
    marginBottom: 4,
  },
  buttonWrapper: {
    textAlign: "center",
  },
  submitButton: {
    margin: "30px 0 15px",
  },
  buttonContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    margin: "20px auto 0",
    maxWidth: 225,
  },
  resendButton: {
    marginBottom: 15,
  },
  complete: {
    textAlign: "center",
  },
  completeText: {
    fontSize: 18,
  },
  completeIcon: {
    height: 110,
    width: 110,
    color: theme.palette.primary.main,
    margin: "22px 0 32px 0",

    // Explicitly set fontSize to avoid theme overrides
    fontSize: 24,
  },
  failureIcon: {
    color: theme.palette.error.main,
  },
}))

export default OptInReadonly
