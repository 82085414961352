import {node, object} from "prop-types"
import {createContext, useContext} from "react"

const TemplateMessageContext = createContext()
TemplateMessageContext.displayName = "TemplateMessageContext"

export const TemplateMessageContextProvider = ({children, content_variables}) => {
  return (
    <TemplateMessageContext.Provider value={{content_variables}}>
      {children}
    </TemplateMessageContext.Provider>
  )
}

TemplateMessageContextProvider.propTypes = {
  children: node.isRequired,
  content_variables: object,
}

export const useTemplateMessageContext = () => useContext(TemplateMessageContext)

export const templateMessageConsumer = Component => props => (
  <Component {...useTemplateMessageContext()} {...props} />
)
