import {Typography} from "@mui/material"
import {useEffect} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"
import StatsPie from "components/template-insights/stats-pie"

import {fetchAdvancedInsightsCardOnFileWidget} from "lib/api"
import pluralize from "lib/string/pluralize"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const CardOnFileWidget = () => {
  const {cardOnFileWidget, fetchInsights, filterParams, hasSocket} = useAdvancedTeamInsights()

  // Fetch data
  useEffect(() => {
    if (hasSocket) {
      fetchInsights("cardOnFileWidget", fetchAdvancedInsightsCardOnFileWidget, [{...filterParams}])
    }
  }, [fetchInsights, filterParams, hasSocket])

  const cardOnFileData = cardOnFileWidget?.result || []
  const totalContacts = cardOnFileWidget?.totalContacts || 0
  const totalLabel = pluralize("contact", totalContacts)

  return (
    <>
      {totalContacts > 0 ? (
        <>
          <Typography variant="h6">Card on File</Typography>
          <StatsPie
            colors={colors}
            data={cardOnFileData}
            total={totalContacts}
            totalLabel={totalLabel}
          />
        </>
      ) : null}
    </>
  )
}

export default CardOnFileWidget
