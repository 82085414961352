import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {array, number, shape, string} from "prop-types"

import Box from "components/box/box"
import Padded from "components/padded/padded"

import HttpStatusPill from "lib/http-status-pill"

const formatBody = body => {
  try {
    let json = JSON.parse(body)
    return JSON.stringify(json, null, 2)
  } catch {
    return body
  }
}

const CmcFlexDiagnosticsResult = ({response, error}) => {
  const classes = useStyles()

  if (error)
    return (
      <Padded>
        <Typography gutterBottom={true} variant="subtitle1">
          <>
            <HttpStatusPill status="x" isError={true} />
            {" Error Sending Request"}
          </>
        </Typography>
        <Box>
          <b>Errors</b>
          <br />
          <pre className={classes.headers} style={{fontSize: 14}}>
            {error}
          </pre>
        </Box>
      </Padded>
    )

  const {body, headers, status} = response

  const formattedBody = formatBody(body)

  const renderHeaders = headers =>
    headers.reduce((acc, [key, value]) => {
      const line = (
        <>
          {`${key}: ${value}`}
          <br />
        </>
      )
      return acc === null ? (
        line
      ) : (
        <>
          {acc}
          {line}
        </>
      )
    }, null)

  return (
    <Padded>
      <Typography gutterBottom={true} variant="subtitle1">
        <>
          <HttpStatusPill status={status} />
          {" Response"}
        </>
      </Typography>
      <Box>
        <b>Response Headers</b>
        <pre className={classes.headers}>{renderHeaders(headers)}</pre>
        <b>Body</b>
        <br />
        <pre className={classes.headers} style={{fontSize: 14}}>
          {formattedBody}
        </pre>
      </Box>
    </Padded>
  )
}

CmcFlexDiagnosticsResult.propTypes = {
  error: string,
  response: shape({
    body: string,
    headers: array,
    status: number,
  }),
}

const useStyles = makeStyles(theme => ({
  headers: {
    fontSize: 14,
    overflowX: "auto",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
}))

export default CmcFlexDiagnosticsResult
