import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import {styled} from "@mui/material/styles"
import {func, shape, string} from "prop-types"
import React from "react"
import {useSelector} from "react-redux"

import ColorInput from "components/color-input/color-input"
import FontSelector from "components/font-selector/font-selector"

import {useTemplateContentContext} from "contexts/template-content-context"
import {requiredField, validCssFontSize} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

import {useContentBlockEditor} from "../../content-block-editor-context"
import FormActions from "../form-actions"

const SwitchGroup = styled(FormGroup)(({theme}) => ({
  marginBottom: theme.spacing(2),
}))

const TextSubsection = styled("div")(({theme}) => ({
  marginBottom: theme.spacing(3),
}))

const RingColorsSubsection = styled("div")(({theme}) => ({
  marginTop: theme.spacing(3),
  "& > .MuiTypography-root": {marginBottom: theme.spacing(2)},
  "& > .MuiFormControl-root": {marginBottom: theme.spacing(2)},
  "& > .MuiFormControl-root:last-of-type": {marginBottom: theme.spacing(3)},
}))

const RewardsSettings = ({contentBlock, initialValues, onSubmit}) => {
  const {rewardSets, containers} = useContentBlockEditor()

  // Destructure to discard branding properties not implemented by content block
  const {confettiColors: _discardFromTeam, ...teamStyles} = useSelector(
    state => state.session.team?.themeStyles?.custom?.rewards ?? {}
  )
  const {template} = useTemplateContentContext() ?? {}
  const {confettiColors: _discardFromChannel, ...channelStyles} =
    containers?.[contentBlock.containerId]?.engagementChannel?.themeStyles?.custom?.rewards ?? {}
  const {confettiColors: _discardFromTemplate, ...templateStyles} =
    template?.theme?.custom.rewards ?? {}
  // Compile persisted branding settings
  const branding = {...teamStyles, ...channelStyles, ...templateStyles}
  // Merge persisted branding settings into defaults
  const brandingDefaults = {
    blockBackgroundColor: "#FFF",
    captionFontFamily: "Roboto (Default)",
    captionFontSize: "20px",
    captionTextColor: "#000",
    completedColor: "#1976d2",
    inProgressColor: "#42A5F5",
    ringBackgroundColor: "#1565C0",
    valueFontFamily: "Roboto (Default)",
    valueFontSize: "20px",
    valueTextColor: "#000",
    widgetBackgroundColor: "#FFF",
  }
  const {change, field, handleSubmit, inputs, invalid, resetForm} = useForm({
    initialValues: {
      confettiTheme: "branded",
      progressType: "circular",
      ...initialValues,
      branding: {
        ...brandingDefaults,
        ...branding,
        ...initialValues.branding,
      },
    },
    onSubmit,
    validators: {
      rewardSetId: [requiredField],
      "branding.captionFontSize": [validCssFontSize],
      "branding.valueFontSize": [validCssFontSize],
    },
  })

  const hasConfettiThemeBrandingError =
    inputs.confettiTheme.value === "branded" && !branding?.confettiColors

  return (
    <form aria-label="Rewards Settings" onSubmit={handleSubmit}>
      <FormControl error={!!inputs.rewardSetId?.error} fullWidth>
        <InputLabel id="reward-set-id-label">Reward Set</InputLabel>
        <Select
          id="reward-set-id"
          labelId="reward-set-id-label"
          {...field("rewardSetId", {except: ["helperText"]})}
          onBlur={event => change("rewardSetId", event.target.value)}
          renderValue={value => rewardSets.find(option => option.id === value)?.name || ""}
        >
          {rewardSets.map(option => (
            <MenuItem key={option.id} value={option.id}>
              <ListItemText>
                <Typography variant="inherit" noWrap>
                  {option.name.length < 32 ? option.name : `${option.name.slice(0, 31)} ...`}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{inputs.rewardSetId?.helperText || ""}</FormHelperText>
      </FormControl>
      <SwitchGroup>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={inputs.progressType.value === "linear"}
              name="progressType"
              onChange={() =>
                change(
                  "progressType",
                  inputs.progressType.value === "circular" ? "linear" : "circular"
                )
              }
            />
          }
          label="Linear Progress Bar"
        />
        <FormHelperText>Display as a horizontal progress bar</FormHelperText>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={inputs.confettiTheme.value === "branded"}
              name="confettiTheme"
              onChange={() =>
                change(
                  "confettiTheme",
                  inputs.confettiTheme.value === "branded" ? "rainbow" : "branded"
                )
              }
            />
          }
          label="Branded Confetti"
        />
        <FormHelperText error={hasConfettiThemeBrandingError}>
          {hasConfettiThemeBrandingError
            ? "No branded confetti colors have been configured, falling back to Rainbow colors until colors are selected in the Campaign to Team branding settings."
            : "Confetti uses colors configured in Branding Settings"}
        </FormHelperText>
      </SwitchGroup>
      <TextSubsection>
        <Typography>Value Text</Typography>
        <FormControl fullWidth margin="normal" sx={{marginBottom: 0}}>
          <InputLabel name="branding.valueFontFamily-label">Font Family</InputLabel>
          <FontSelector
            enableGoogleFonts={true}
            id="branding.valueFontFamily"
            {...field(`branding.valueFontFamily`, {exclude: ["helperText"]})}
            onSelect={({value}) => change(`branding.valueFontFamily`, `${value}`)}
          />
        </FormControl>
        <TextField
          fullWidth
          helperText="Accepts CSS numbers like 14px, 1.2em, etc"
          id="branding.valueFontSize"
          InputLabelProps={{shrink: true}}
          label="Font Size"
          margin="normal"
          type="text"
          sx={{marginBottom: "16px"}}
          {...field(`branding.valueFontSize`)}
        />
        <ColorInput
          fullWidth
          id="branding.valueTextColor"
          input={{
            name: "branding.valueTextColor",
            onChange: hex => change("branding.valueTextColor", hex),
            value: inputs["branding.valueTextColor"]?.value,
          }}
          label="Text Color"
          hideCustomPalette
        />
      </TextSubsection>

      <TextSubsection>
        <Typography>Caption Text</Typography>
        <TextField
          fullWidth={true}
          id={`rewards-caption-${contentBlock.slug}`}
          label="Caption"
          sx={theme => ({marginTop: theme.spacing(2)})}
          {...field("caption")}
        />
        <FormControl fullWidth margin="normal" sx={{marginBottom: 0}}>
          <InputLabel name="branding.captionFontFamily-label">Font Family</InputLabel>
          <FontSelector
            enableGoogleFonts={true}
            id="branding.captionFontFamily"
            {...field(`branding.captionFontFamily`, {exclude: ["helperText"]})}
            onSelect={({value}) => change(`branding.captionFontFamily`, `${value}`)}
          />
        </FormControl>
        <TextField
          fullWidth
          helperText="Accepts CSS numbers like 14px, 1.2em, etc"
          id="branding.captionFontSize"
          InputLabelProps={{shrink: true}}
          label="Font Size"
          margin="normal"
          type="text"
          sx={{marginBottom: "16px"}}
          {...field(`branding.captionFontSize`)}
        />
        <ColorInput
          fullWidth
          id="branding.captionTextColor"
          input={{
            name: "branding.captionTextColor",
            onChange: hex => change("branding.captionTextColor", hex),
            value: inputs["branding.captionTextColor"]?.value,
          }}
          label="Text Color"
          hideCustomPalette
        />
      </TextSubsection>

      <RingColorsSubsection>
        <Typography>Ring Colors</Typography>

        <ColorInput
          fullWidth
          id="rewardsInProgressColor"
          input={{
            name: "branding.inProgressColor",
            onChange: hex => change("branding.inProgressColor", hex),
            value: inputs["branding.inProgressColor"].value,
          }}
          label="In-Progress Color"
          hideCustomPalette
        />

        <ColorInput
          fullWidth
          id="rewardsCompletedColor"
          input={{
            name: "branding.completedColor",
            onChange: hex => change("branding.completedColor", hex),
            value: inputs["branding.completedColor"].value,
          }}
          label="Completed Color"
          hideCustomPalette
        />

        <ColorInput
          fullWidth
          id="rewardsRingBackgroundColor"
          input={{
            name: "branding.ringBackgroundColor",
            onChange: hex => change("branding.ringBackgroundColor", hex),
            value: inputs["branding.ringBackgroundColor"].value,
          }}
          label="Ring Background Color"
          hideCustomPalette
        />

        <ColorInput
          fullWidth
          id="rewardsBlockBackgroundColor"
          input={{
            name: "branding.blockBackgroundColor",
            onChange: hex => change("branding.blockBackgroundColor", hex),
            value: inputs["branding.blockBackgroundColor"].value,
          }}
          label="Content Block Background Color"
          hideCustomPalette
        />

        <ColorInput
          fullWidth
          id="rewardsWidgetBackgroundColor"
          input={{
            name: "branding.widgetBackgroundColor",
            onChange: hex => change("branding.widgetBackgroundColor", hex),
            value: inputs["branding.widgetBackgroundColor"].value,
          }}
          label="Progress Widget Background Color"
          hideCustomPalette
        />
      </RingColorsSubsection>

      <FormActions resetForm={resetForm} saveProps={{disabled: invalid}} />
    </form>
  )
}

RewardsSettings.propTypes = {
  contentBlock: shape({
    slug: string.isRequired,
  }).isRequired,
  initialValues: shape({
    iconSrc: string,
  }).isRequired,
  onSubmit: func.isRequired,
}

export default RewardsSettings
