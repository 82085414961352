import {arrayOf, object, string} from "prop-types"
import React, {useMemo} from "react"

const SurveyEntity = ({customStyle, defaultValue, paramParts, target}) => {
  const personalization = useMemo(() => {
    const [param, ...meta] = paramParts
    const metaField = meta.join(".")

    // Survey names and question titles are normalized via trim and lower case,
    // so normalize param and metaField before lookup
    // - See accrue_survey_interaction/2 in Gondor.Journeys
    const surveyKey = param.trim().toLowerCase()
    const questionKey = metaField.trim().toLowerCase()

    return target[surveyKey] && target[surveyKey][questionKey]
      ? target[surveyKey][questionKey]
      : defaultValue
  }, [defaultValue, paramParts, target])

  return (
    <span className="personalization-widget" style={customStyle}>
      {personalization}
    </span>
  )
}

SurveyEntity.propTypes = {
  customStyle: object,
  defaultValue: string,
  paramParts: arrayOf(string),
  target: object,
}

export default SurveyEntity
