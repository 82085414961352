import {Grid, Typography} from "@mui/material"
import {object} from "prop-types"

import AddedValueByTime from "components/advanced-team-insights/added-value-by-time"
import ObjectiveTotalValue from "components/advanced-team-insights/objective-total-value"
import Expandable from "components/expandable/expandable"

const AdvancedValueDrivers = ({classes}) => {
  return (
    <div className={classes.section}>
      <Typography className={classes.header} variant="h5">
        Value Drivers
      </Typography>

      <div className={classes.expandableContainer}>
        <Expandable label="How we use this section">
          <p>
            The Value Drivers section shows how much value is being generated by those objective
            completions that drive a dollar amount for your institution. This is broken down by time
            and total value.
          </p>

          <Typography variant="h6">At a Glance</Typography>

          <ul>
            <li>
              <b>Added value by time bar chart</b>: View how much value each objective completion
              led to by month, quarter, and year. Hover over the chart’s data labels to read a full
              list of values during that timeframe. Monitor trends over time to see where there are
              upticks and downturns in value. Compare those value drivers to each other to see which
              objectives are giving you the most value.
            </li>
            <li>
              <b>Total value table</b>: View current total value and set goals based on 5% and 10%
              lift opportunities. (All values are rounded to the nearest whole number.) Sort the
              table by each column to see highest or lowest value.
            </li>
          </ul>

          <Typography variant="h6">Use of Page Filters</Typography>

          <ul>
            <li>
              <b>Dates</b>: Easily build reports showcasing important upturns and downturns over
              time. Monitor differences between total value from one time frame to the next.
            </li>
            <li>
              <b>Objectives</b>: Does not affect this section.
            </li>
            <li>
              <b>Status</b>: Focus on objective completions occurring in either live or archived
              campaigns.
            </li>
          </ul>
        </Expandable>
      </div>

      <Expandable label="Hide section" labelClassName={classes.hideChartLabel} startExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
            <AddedValueByTime classes={classes} />
          </Grid>

          <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
            <ObjectiveTotalValue classes={classes} />
          </Grid>
        </Grid>
      </Expandable>
    </div>
  )
}

AdvancedValueDrivers.propTypes = {
  classes: object,
}

export default AdvancedValueDrivers
