import {SET_A11Y_MESSAGE} from "../actions/accessability-actions"

function a11y(state = {}, action) {
  switch (action.type) {
    case SET_A11Y_MESSAGE:
      return {
        ...state,
        message: action.message,
      }

    default:
      return state
  }
}

export default a11y
