import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import {bool, func, object, oneOfType, string} from "prop-types"

const DuplicateMessageConfirmationDialog = ({message, onClose, onDuplicate, open, title}) => {
  const onDuplicateClicked = () => {
    onDuplicate().finally(() => {
      onClose()
    })
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onDuplicateClicked}>
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DuplicateMessageConfirmationDialog.propTypes = {
  message: oneOfType([object, string]).isRequired,
  onClose: func.isRequired,
  onDuplicate: func.isRequired,
  open: bool.isRequired,
  title: string.isRequired,
}

export default DuplicateMessageConfirmationDialog
