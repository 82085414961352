import {Checkbox, Divider, FormControl, InputLabel, ListItemText, MenuItem} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {array, func, string} from "prop-types"
import {useEffect, useState} from "react"

import DOSelect from "components/do-select/do-select"

const getSelectValue = (filteredObjectiveIds, objectives) => {
  // FIXME this `filtered` list can go away once this MUI issue is fixed
  // https://github.com/mui-org/material-ui/issues/18200
  // it allows undefined to be inserted in the list of selected actions
  // so we have to guard against it by filtering
  const filtered = filteredObjectiveIds.filter(Boolean)
  const [filteredObjectiveId] = filtered

  if (!filteredObjectiveId) return ""

  if (filtered.length > 1) return <i>{filtered.length} objectives</i>

  const objective = objectives.find(({id}) => id === filteredObjectiveId)

  return objective?.name
}

const useStyles = makeStyles({
  filterSelector: {
    minWidth: 125,
  },
})

const ObjectivesFilter = ({id, objectives, onChange}) => {
  const classes = useStyles()
  const [selectedObjectives, setSelectedObjectives] = useState([])
  const onSelectChanged = ({target: {value}}) => {
    if (value.includes("all-objectives")) {
      setSelectedObjectives([])
    } else {
      setSelectedObjectives(value)
    }
  }

  useEffect(() => {
    onChange(selectedObjectives)
  }, [onChange, selectedObjectives])

  return (
    <FormControl className={classes.filterSelector} margin="normal">
      <InputLabel id={`${id}-label`}>Objectives</InputLabel>
      <DOSelect
        labelId={`${id}-label`}
        multiple={true}
        onChange={onSelectChanged}
        renderValue={() => getSelectValue(selectedObjectives, objectives)}
        value={selectedObjectives}
      >
        <MenuItem value="all-objectives">All objectives</MenuItem>
        <Divider />
        {objectives.map(objective => (
          <MenuItem key={objective.id} value={objective.id}>
            <Checkbox checked={selectedObjectives.includes(objective.id)} color="primary" />
            <ListItemText primary={objective.name} />
          </MenuItem>
        ))}
      </DOSelect>
    </FormControl>
  )
}

ObjectivesFilter.propTypes = {
  id: string.isRequired,
  objectives: array,
  onChange: func.isRequired,
}

export default ObjectivesFilter
