import {Paper, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {func, number, oneOf, shape, string} from "prop-types"
import {MdDelete as CancelMessageIcon, MdEmail as EmailIcon} from "react-icons/md"

import {formatDateTime, relativeDateTime} from "lib/date-time-formatters"

import DangerButton from "../danger-button/danger-button"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    marginBottom: theme.spacing(3),
  },
  connector: {
    "&::before": {
      borderBottomColor: theme.palette.grey[300],
      content: `""`,
      position: "absolute",
      top: "50%",
      marginTop: theme.spacing(-0.5),
      left: -100,
      width: 100,
      borderBottomWidth: theme.spacing(0.5),
      borderBottomStyle: "dashed",
    },
  },
  header: {
    position: "relative",
    display: "flex",
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1),
    fontSize: "1rem",
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
  futureActionContent: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
  info: {
    display: "flex",
    "& svg": {
      display: "block",
      marginRight: theme.spacing(1),
    },
    "& h5": {
      fontSize: "1rem",
    },
  },
  body: {
    padding: theme.spacing(1),
  },
  cancelIcon: {
    marginRight: theme.spacing(1),
  },
  rowActions: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const ScheduledMessageRow = ({
  timelineMarker,
  templateMessage,
  jobId,
  scheduledTimestamp,
  onCancelScheduledMessage,
}) => {
  const classes = useStyles()
  const time = new Date(scheduledTimestamp * 1000)
  const queue = !!timelineMarker ? "nurturing" : "scheduled"

  const onCancelMessageSend = () => onCancelScheduledMessage(jobId)

  return (
    <Paper className={classes.container}>
      <Typography className={cx(classes.header, classes.connector)} variant="h5">
        <EmailIcon size={20} />
        Message scheduled
        <Tooltip title={formatDateTime(time)}>
          <span>
            &nbsp;
            {relativeDateTime(time)}
          </span>
        </Tooltip>
      </Typography>
      <div className={classes.body}>
        <div>
          {queue === "nurturing" ? (
            <Typography>
              We will figure out the next applicable message to send this contact.
            </Typography>
          ) : (
            <Typography>
              {templateMessage
                ? `This is a scheduled message for ${templateMessage.message?.contentName}.`
                : "This scheduled message was deleted from the campaign after it was queued for this journey."}
            </Typography>
          )}
        </div>
        <div className={classes.rowActions}>
          <DangerButton onClick={onCancelMessageSend}>
            <CancelMessageIcon className={classes.cancelIcon} /> cancel this message
          </DangerButton>
        </div>
      </div>
    </Paper>
  )
}

ScheduledMessageRow.propTypes = {
  jobId: string,
  onCancelScheduledMessage: func.isRequired,
  scheduledTimestamp: number,
  templateMessage: shape({
    contentName: string,
  }),
  timelineMarker: shape({
    type: oneOf(["sms", "email"]),
  }),
}

export default ScheduledMessageRow
