import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  toolbarWrapper: {
    marginBottom: theme.spacing(4),
  },
}))

const CsvProcessingSettingsToolbar = () => {
  const classes = useStyles()

  return (
    <div className={classes.toolbarWrapper}>
      <Typography>
        You can define how we handle columns in CSV uploads by adding them to the list here. If we
        see a column that you've listed here in a CSV we will remap it to the appropriate field in
        Digital Onboarding. Any other column that does not match Digital Onboarding will be ignored.
      </Typography>
    </div>
  )
}

export default CsvProcessingSettingsToolbar
