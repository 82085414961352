import {areFiltersMismatched} from "components/template-insights/insights-helpers"

export const actions = {
  SET_FILTER_PARAMS: "SET_FILTER_PARAMS",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_TEAM_INSIGHTS: "SET_TEAM_INSIGHTS",
}

const transformFilterParams = ({endDate, startDate, templateStatus}) => {
  let filterParams = {}

  if (startDate) filterParams = {...filterParams, startDate}
  if (endDate) filterParams = {...filterParams, endDate}
  if (templateStatus === TemplateStatuses.Live || templateStatus === TemplateStatuses.Archived)
    filterParams = {...filterParams, templateStatus}

  return filterParams
}

export const TemplateStatuses = {All: "all", Live: "live", Archived: "archived"}
export const TEAM_INSIGHTS_FILTER_INITIAL_VALUES = {
  templateStatus: TemplateStatuses.All,
  startDate: "",
  endDate: "",
}

export const initialState = {
  filterParams: transformFilterParams(TEAM_INSIGHTS_FILTER_INITIAL_VALUES),
}

export const filtersMismatchInsights = ({filterParams}, args = {}) => {
  const cacheFilters = args?.args?.[1] || {}
  return areFiltersMismatched(filterParams, cacheFilters)
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_FILTER_PARAMS:
      return {...state, filterParams: transformFilterParams(action)}
    case actions.SET_IS_LOADING:
      return {...state, [action.name]: action.value}
    case actions.SET_TEAM_INSIGHTS:
      // We short circuit setting/updating team insights when the filters do not match to
      // prevent two (or more) open tabs/windows on the same insights team from
      // effecting one another.
      // See https://trello.com/c/BDrLqJcI
      if (filtersMismatchInsights(state, action.args)) return state

      return {...state, [action.name]: action.insightsCache}
    default:
      return state
  }
}
