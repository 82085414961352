import capitalize from "lodash/capitalize"
import kebabCase from "lodash/kebabCase"

const exceptions = [
  "ID",
  "CTA",
  "SMS",
  "CSS",
  "API",
  "URL",
  "SFTP",
  "CMC",
  "eStatement",
  "eStatements",
  "CCE",
]

const humanize = string => {
  const start = kebabCase(string).replace(/[-_]/g, " ").split(" ").map(capitalize).join(" ")

  return exceptions.reduce((acc, curr) => {
    const pattern = new RegExp("\\b" + curr + "\\b", "gi")
    return acc.replace(pattern, curr)
  }, start)
}

export default humanize

export const getHumanContainerType = containerType => {
  switch (containerType) {
    case "content_container":
      return "channel"
    case "message":
      return "email"
    case "template":
      return "campaign footer"
    default:
      return containerType
  }
}
