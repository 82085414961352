import {Box, Paper, Typography} from "@mui/material"
import {arrayOf, shape, string} from "prop-types"

import pluralize from "../../lib/string/pluralize"

const ScheduledTimelineOverview = ({overview}) => {
  return (
    <Paper
      sx={theme => ({
        marginBottom: theme.spacing(2),
        padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
      })}
    >
      <Typography variant="body1" paragraph fontWeight="bold">{`There ${
        overview.length === 1 ? "is" : "are"
      } ${overview.length} ${pluralize(
        "scheduled message",
        overview.length
      )} that will send on the date listed`}</Typography>
      <Typography variant="body1" paragraph>
        Scheduled messages send one time at the date shown to the message to contacts with a journey
        in the campaign. Contacts must have a journey and match the sending criteria in order to
        receive the message.
      </Typography>
      {overview.map((message, index) => (
        <Box key={`${message.scheduledAt}-${message.type}-${index}`}>
          <Typography variant="subtitle1" component="div" fontWeight="bold">
            {`Message #${index + 1}`}
          </Typography>
          <Typography variant="body1">{`Type: ${message.type}`}</Typography>
          <Typography variant="body1">{`Sends at: ${message.scheduledAt}`}</Typography>
        </Box>
      ))}
    </Paper>
  )
}

ScheduledTimelineOverview.defaultProps = {overview: []}

ScheduledTimelineOverview.propTypes = {
  overview: arrayOf(shape({scheduledAt: string.isRequired, type: string.isRequired})),
}

export default ScheduledTimelineOverview
