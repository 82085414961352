import {string} from "prop-types"
import {useSelector} from "react-redux"

import {journeyContext} from "components/journeys/journey-context"

import PersonalizationEntityEditable, {
  PERSONALIZATION_ENTITY,
} from "./personalization-entity-editable"
import PersonalizationEntityReadonly from "./personalization-entity-readonly"

const PersonalizationEntity = ({journeyId, ...props}) => {
  const team = useSelector(({session}) => session?.team || {})

  if (journeyId) return <PersonalizationEntityReadonly {...props} team={team} />

  return <PersonalizationEntityEditable {...props} team={team} />
}

PersonalizationEntity.propTypes = {
  journeyId: string,
}

export {PERSONALIZATION_ENTITY}

export default journeyContext(PersonalizationEntity)
