import {TableCell, TableRow, Tooltip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {array, func, number, shape, string} from "prop-types"

import {formatDateTime} from "lib/date-time-formatters"
import HttpStatusPill from "lib/http-status-pill"

const valueForRecord = (record, field) => {
  switch (field) {
    case "type":
      return <b>{record[field]}</b>
    case "statusCode":
      return record[field] === null ? "-" : <HttpStatusPill status={record[field]} />
    case "numRetries":
      return parseInt(record[field])
    case "timestamp":
      return formatDateTime(record[field])
    default:
      return record[field] || ""
  }
}

const useStyles = makeStyles(theme => ({
  row: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

const WebhookLogRow = ({columns, activeRowIndex, onClick, rowIndex, row: webhookLog}) => {
  const classes = useStyles()
  const onClickRow = () => {
    onClick(webhookLog.id)
  }

  return (
    <>
      <Tooltip title="See webhook details">
        <TableRow className={classes.row} hover={true} onClick={onClickRow}>
          {columns.map(({field}) => (
            <TableCell component="td" key={field} scope="row">
              <span>{valueForRecord(webhookLog, field)}</span>
            </TableCell>
          ))}
        </TableRow>
      </Tooltip>
    </>
  )
}

WebhookLogRow.propTypes = {
  activeRowIndex: number,
  columns: array.isRequired,
  onClick: func.isRequired,
  row: shape({
    id: string,
    type: string,
    url: string,
    statusCode: number,
    timestamp: string,
  }),
  rowIndex: number,
}

export default WebhookLogRow
