import {arrayOf, object, string} from "prop-types"
import React from "react"

const RewardsEntity = ({customStyle, defaultValue, paramParts, target: {contact, rewards}}) => {
  const rewardSet = rewards?.reward_sets[paramParts[1]] || null
  const unit =
    rewardSet && contact.meta_public && contact?.meta_public[rewardSet.personalization_unit]
      ? contact.meta_public[rewardSet.personalization_unit]
      : rewardSet?.unit || ""

  return (
    <span className="personalization-widget" style={customStyle}>
      {rewardSet?.accrued_rewards && unit ? `${rewardSet.accrued_rewards} ${unit}` : defaultValue}
    </span>
  )
}

RewardsEntity.propTypes = {
  customStyle: object,
  defaultValue: string,
  paramParts: arrayOf(string),
  target: object,
}

export default RewardsEntity
