import {LinearProgress} from "@mui/material"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {PureComponent} from "react"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {fetchFile} from "lib/api"

import {journeyContext} from "../../../journeys/journey-context"
import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"

export class DocumentReadonly extends PureComponent {
  state = {}

  componentDidMount() {
    fetchFile(this.props.contentBlock?.data?.id).then(({meta}) => {
      if (!meta) return this.setState({noBoxUpload: true})

      this.setState({embedUrl: meta.embedUrl})
    })
  }

  render() {
    const {
      className,
      themeClasses,
      onClick,
      isEditMode,
      contentBlock,
      customCss,
      style,
    } = this.props
    const {
      data: {isDownloadDisabled, name},
    } = contentBlock

    if (this.state.noBoxUpload) throw new Error("No box upload associated with file")

    if (!this.state.embedUrl)
      return (
        <div className={className}>
          <style>{customCss}</style>
          <LinearProgress />
        </div>
      )

    const {embedUrl} = this.state

    return (
      <div
        className={cx(className, themeClasses.documentContainer)}
        onClick={onClick}
        style={style}
      >
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        <style>{customCss}</style>
        <iframe
          allowFullScreen={true}
          className={themeClasses.documentIframe}
          frameBorder="0"
          src={`${embedUrl}${isDownloadDisabled === true ? "" : "?showDownload=true"}`}
          style={{pointerEvents: isEditMode ? "none" : "auto"}}
          title={name}
        />
      </div>
    )
  }
}

DocumentReadonly.propTypes = {
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  className: string,
  isEditMode: bool,
  onClick: func,
  themeClasses: object.isRequired,
  style: object,
}

export default journeyContext(contentBlockEditorConsumer(DocumentReadonly))
