import {TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, shape, string} from "prop-types"
import {useState} from "react"

import CtaButtonSettings from "components/content-block-editor/cta-button-settings"
import CtaLinkSettings from "components/content-block-editor/cta-link-settings"

import {validDomainAndTld} from "lib/field-validations"
import useForm from "lib/hooks/use-form"
import {formatUrl} from "lib/string/url-helpers"

import {templatePageContext} from "../../../../contexts/template-page-context"
import FormActions from "../form-actions"

const CtaSettings = ({
  contentBlock,
  content_variables: _content_variables,
  initialValues,
  onSubmit,
  setContentVariables: onUpdateContentVariables,
}) => {
  const [content_variables, setContentVariables] = useState(_content_variables)
  const {change, field, handleSubmit, resetForm} = useForm({
    initialValues,
    parse: {url: formatUrl},
    onSubmit: settings => {
      if (onUpdateContentVariables)
        return onUpdateContentVariables(content_variables).then(() => onSubmit(settings))
      else return onSubmit(settings)
    },
    validators: {url: [validDomainAndTld]},
  })

  const classes = useStyles()

  const handleResetForm = () => {
    setContentVariables(_content_variables)
    resetForm()
  }

  return (
    <form className={classes.root} aria-label="CTA Settings" onSubmit={handleSubmit}>
      <CtaLinkSettings
        change={change}
        field={field}
        contentBlock={contentBlock}
        content_variables={content_variables}
        onSetContentVariable={value =>
          setContentVariables(state => ({...state, [contentBlock.slug]: value}))
        }
      />
      <TextField
        fullWidth={true}
        id={`cta-${contentBlock.slug}-text`}
        label="Text"
        margin="normal"
        {...field("text")}
        inputProps={{
          "data-testid": "cta-text-field",
        }}
      />
      <CtaButtonSettings change={change} field={field} />
      <FormActions resetForm={handleResetForm} />
    </form>
  )
}

CtaSettings.propTypes = {
  contentBlock: shape({
    slug: string.isRequired,
  }).isRequired,
  content_variables: object,
  initialValues: shape({
    iconSrc: string,
  }).isRequired,
  onSubmit: func.isRequired,
  setContentVariables: func,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
  },
}))

export default templatePageContext(CtaSettings)
