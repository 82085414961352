import {FormControl, Grid, InputLabel, TextField, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"

import ColorInput from "components/color-input/color-input"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"
import ResetLabel from "components/teams/branding-settings/reset-label"

import FontSelector from "../../font-selector/font-selector"

const useStyles = makeStyles({
  titleContainer: {
    paddingBottom: "0 !important",
    paddingTop: "24px !important",
    "&:first-child": {
      paddingTop: "12px !important",
    },
  },
  title: {
    fontSize: 21,
    fontWeight: "bold",
  },
})

const TableSettings = ({change, field, muiTheme}) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.title}>Table</Typography>
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="fontFamily">
              <ResetLabel name="custom.accountsTable.fontFamily">Font Family</ResetLabel>
            </InputLabel>
            <FontSelector
              enableGoogleFonts={true}
              id="tableFontFamily"
              {...field(`custom.accountsTable.fontFamily`, {
                defaultValue: muiTheme.custom?.accountsTable?.fontFamily || "Roboto (Default)",
                exclude: ["helperText"],
              })}
              onSelect={({value}) => change(`custom.accountsTable.fontFamily`, `${value}`)}
            />
          </FormControl>
        </Grid>

        <Grid item lg={12} md={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.title}>Table Header</Typography>
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="tableHeaderBackgroundColor"
            input={{
              ...field("custom.accountsTable.header.backgroundColor", {
                defaultValue: muiTheme.custom?.accountsTable?.header?.backgroundColor || "#489AD4",
              }),
              onChange: hex => change("custom.accountsTable.header.backgroundColor", hex),
            }}
            label={
              <ResetLabel name="custom.accountsTable.header.backgroundColor">
                Background Color
              </ResetLabel>
            }
            hideCustomPalette
          />
        </Grid>
        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="tableHeaderFontColor"
            input={{
              ...field("custom.accountsTable.header.fontColor", {
                defaultValue: muiTheme.custom?.accountsTable?.header?.fontColor || "#FFFFFF",
              }),
              onChange: hex => change("custom.accountsTable.header.fontColor", hex),
            }}
            label={<ResetLabel name="custom.accountsTable.header.fontColor">Font Color</ResetLabel>}
            hideCustomPalette
          />
        </Grid>
        <Grid item lg={7} md={12} xs={12}>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="tableHeaderFontSize"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="custom.accountsTable.header.fontSize">Font Size</ResetLabel>}
            margin="normal"
            type="text"
            {...field(`custom.accountsTable.header.fontSize`, {
              defaultValue: muiTheme.custom?.accountsTable?.header?.fontSize || "20px",
            })}
          />
        </Grid>

        <Grid item lg={12} md={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.title}>Column Header</Typography>
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="columnHeaderBackgroundColor"
            input={{
              ...field("custom.accountsTable.columnHeader.backgroundColor", {
                defaultValue:
                  muiTheme.custom?.accountsTable?.columnHeader?.backgroundColor || "#FFFFFF",
              }),
              onChange: hex => change("custom.accountsTable.columnHeader.backgroundColor", hex),
            }}
            label={
              <ResetLabel name="custom.accountsTable.columnHeader.backgroundColor">
                Background Color
              </ResetLabel>
            }
            hideCustomPalette
          />
        </Grid>
        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="columnHeaderFontColor"
            input={{
              ...field("custom.accountsTable.columnHeader.fontColor", {
                defaultValue: muiTheme.custom?.accountsTable?.columnHeader?.fontColor || "#489AD4",
              }),
              onChange: hex => change("custom.accountsTable.columnHeader.fontColor", hex),
            }}
            label={
              <ResetLabel name="custom.accountsTable.columnHeader.fontColor">Font Color</ResetLabel>
            }
            hideCustomPalette
          />
        </Grid>
        <Grid item lg={7} md={12} xs={12}>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="columnHeaderFontSize"
            InputLabelProps={{shrink: true}}
            label={
              <ResetLabel name="custom.accountsTable.columnHeader.fontSize">Font Size</ResetLabel>
            }
            margin="normal"
            type="text"
            {...field(`custom.accountsTable.columnHeader.fontSize`, {
              defaultValue: muiTheme.custom?.accountsTable?.columnHeader?.fontSize || "17px",
            })}
          />
        </Grid>

        <Grid item lg={12} md={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.title}>Data Rows</Typography>
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="dataRowFontSize"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="custom.accountsTable.dataRow.fontSize">Font Size</ResetLabel>}
            margin="normal"
            type="text"
            {...field(`custom.accountsTable.dataRow.fontSize`, {
              defaultValue: muiTheme.custom?.accountsTable?.dataRow?.fontSize || "14px",
            })}
          />
        </Grid>
      </Grid>
    </>
  )
}

TableSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  muiTheme: object.isRequired,
}

export default brandingSettings(TableSettings)
