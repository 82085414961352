import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material"
import {bool, func, node, string} from "prop-types"
import {PureComponent} from "react"

import whiteSpaceInsensitiveCompare from "lib/string/compare-strings"

import DangerButton from "../danger-button/danger-button"

export class UltraConfirmDialog extends PureComponent {
  state = {
    acknowledged: false,
    submitting: false,
  }

  onAcknowledgementChange = e =>
    this.setState({
      acknowledged: this.props.isHighSeverity
        ? whiteSpaceInsensitiveCompare(e.target.value, this.props.confirmationText)
        : !this.state.acknowledged,
    })

  onCloseDialog = () => {
    const {onClose} = this.props

    this.setState({acknowledged: false})

    onClose()
  }

  onSubmit = e => {
    e.preventDefault()
    this.setState({submitting: true})
    this.props.onConfirm()
  }

  renderDialogContent = () => {
    const {confirmationText, recordType, isHighSeverity} = this.props

    if (isHighSeverity)
      return (
        <DialogContentText>
          Deleting this {recordType} can't be undone. To confirm that you want to delete this{" "}
          {recordType} please type <b>{confirmationText}</b> in the box below.
        </DialogContentText>
      )

    return <DialogContentText>Deleting this {recordType} can’t be undone.</DialogContentText>
  }

  renderConfirmation = () => {
    const {isHighSeverity} = this.props
    const {acknowledged} = this.state

    if (isHighSeverity)
      return (
        <TextField
          autoFocus={true}
          fullWidth={true}
          name="confirm-text"
          inputProps={{
            "data-testid": "confirmation-input",
          }}
          onChange={this.onAcknowledgementChange}
        />
      )

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={acknowledged}
            color="primary"
            onChange={this.onAcknowledgementChange}
          />
        }
        label="I understand."
      />
    )
  }

  render() {
    const {confirmationText, open, recordType} = this.props
    const {acknowledged, submitting} = this.state

    return (
      <Dialog
        aria-label={`Are you sure you want to delete this ${recordType}?`}
        onClose={this.onCloseDialog}
        open={open}
        role="dialog"
      >
        <form onSubmit={this.onSubmit}>
          <DialogTitle>Are you sure you want to delete this {recordType}?</DialogTitle>
          <DialogContent>
            {this.props.renderDialogContent?.(confirmationText) ?? this.renderDialogContent()}
            <br />
            {this.props.children && (
              <>
                {this.props.children}
                <br />
              </>
            )}
            {this.renderConfirmation()}
          </DialogContent>
          <DialogActions>
            <Button color="grey" onClick={this.onCloseDialog}>
              No
            </Button>
            <DangerButton className="delete" disabled={!acknowledged || submitting} type="submit">
              Yes, delete this {recordType}
            </DangerButton>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

UltraConfirmDialog.defaultProps = {
  isHighSeverity: true,
}

UltraConfirmDialog.propTypes = {
  children: node,
  confirmationText: string.isRequired,
  isHighSeverity: bool,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  open: bool.isRequired,
  recordType: string.isRequired,
  renderDialogContent: func,
}

export {UltraConfirmDialog as default}
