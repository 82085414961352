/* global Atomic */
const cdn = `https://${process.env.REACT_APP_ATOMICFI_DOMAIN}/transact.js`

const isAvailable = (count = 0) => {
  // Gives the library 60 seconds to load
  if (count > 600) return Promise.reject(new Error("Couldn't initialize direct deposit widget."))
  else if (typeof Atomic === "object") return Promise.resolve()

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(isAvailable(count + 1))
    }, 100)
  })
}

export const loadLib = () =>
  new Promise(resolve => {
    if (document.querySelector(`script[src="${cdn}"]`)) return resolve(isAvailable())

    const source = document.createElement("script")

    source.src = cdn
    source.async = true
    source.onload = resolve
    document.body.appendChild(source)
  })
