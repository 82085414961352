import {fetchActions} from "lib/api"

export const COMPLETED_SURVEY_LIST_SET = "COMPLETED_SURVEY_LIST_SET"

export const getCompletedSurveys = journeyId => dispatch =>
  fetchActions({
    journeyId,
    name: "survey_completed",
  }).then(actions => {
    dispatch({type: COMPLETED_SURVEY_LIST_SET, payload: actions.map(a => a.meta.widgetSlug)})
  })

export const clearCompletedSurveys = () => ({
  type: COMPLETED_SURVEY_LIST_SET,
  payload: [],
})
