import {
  isSurveyMultiAnswerAvailable,
  availableOperators as standardAvailableOperators,
} from "./template-targeting-helpers"

export const conditionValidator = (operator, value) => {
  const availableOperators = standardAvailableOperators
  const fullOperator = availableOperators.find(({value: v}) => v === operator)
  if (fullOperator && fullOperator.hideValueInput) return true

  if (!value) return false

  if (operator === "greater_than" || operator === "less_than") {
    return /^-?\d+(\.?\d+)?$/.test(value)
  }

  if (
    ["within_last", "within_at_least", "within_next_x_days", "more_than_x_days_from_now"].includes(
      operator
    )
  )
    return /^\d+$/.test(value)

  return true
}

export const getFullOperator = ({field, operator, meta}) => fullOperator => {
  // NB: `is_empty` (was not answered) and `is_not_empty` (was answered) does not prescribe to the
  // special delimited `::` syntax seen in the survey dynamic operators, so we filter them out here
  // and let the static `availableOperators` filter take over.
  // See `injectCustomOperators` in `template-targeting-helpers.js` for more details
  if (isSurveyMultiAnswerAvailable(field, meta) && !["is_empty", "is_not_empty"].includes(operator))
    return (
      fullOperator.value === `${operator}::${meta.answerTitle}` &&
      fullOperator.isAvailable(field, meta)
    )

  return fullOperator.value === operator && fullOperator.isAvailable(field, meta)
}
