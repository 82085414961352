import {TableCell, TableRow, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import orderBy from "lodash/orderBy"
import pick from "lodash/pick"
import {bool, func, number, object, string} from "prop-types"
import {useEffect} from "react"

import Expandable from "components/expandable/expandable"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"
import {teamInsightsContext} from "components/team-insights/team-insights-context"

import {fetchTeamInsightsObjectiveContent} from "lib/api"

const ObjectiveContent = ({
  fetchInsights,
  filterParams,
  hasSocket,
  objectiveContent,
  objectiveContentIsLoading,
  setTableState,
  ...props
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (hasSocket)
      fetchInsights("objectiveContent", fetchTeamInsightsObjectiveContent, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  useEffect(() => {
    // NB: This is needed so that our rows are effected by our default state.
    // This should only get called after fetchInsights or our socket
    // returns new data.
    refresh({page: 0})

    // FIXME ignoring react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveContent])

  const refresh = attrs => {
    const currentItemsPerPage = attrs?.itemsPerPage ?? props.itemsPerPage
    const currentPage = attrs?.page ?? props.page
    const offset = currentPage * currentItemsPerPage
    const sortColumn = attrs?.sortColumn ?? props.sortColumn
    const sortDirection = attrs?.sortDirection ?? props.sortDirection

    const rows = orderBy(
      (objectiveContent?.data ?? []).filter(
        (item, i) => i >= offset && i < offset + currentItemsPerPage
      ),
      [
        item => {
          const column = item[sortColumn]

          return isNaN(column) ? column.toLowerCase() : column
        },
      ],
      [sortDirection]
    )

    setTableState({
      ...pick(props, "itemsPerPage", "page", "sortColumn", "sortDirection"),
      ...attrs,
      rows,
      page: currentPage,
      totalCount: objectiveContent?.data?.length ?? 0,
    })
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h5">
        Objective content
      </Typography>
      <div className={classes.expandableContainer}>
        <Expandable label="How we use this table">
          <p>This table reports on content engagement by their objective.</p>

          <p>Use Cases</p>
          <ul>
            <li>
              <b>Compare.</b> View which content drives the most engagement by message, page, and
              objective completion by using the column header sorting feature.
            </li>
            <li>
              <b>Mobile Usage.</b> Note the content that has the most engagement on mobile devices
              (ensure this content focuses on SMS messaging and very concise, mobile-friendly page
              content)
            </li>
            <li>
              <b>Unsubscribe Rate.</b> Does a specific objective have a higher than average
              unsubscribe rate? What could be causing this?
            </li>
            <li>
              <b>Objectives.</b> Are the objective completion rates similar or does one outrank the
              others? Do the engagement metrics align with this trend or is there high engagement
              for an objective but low completion? What could be causing that gap?
            </li>
            <li>
              <b>Date Filter.</b> The filter updates the metrics to report performance during the
              selected timeframe. View year over year, quarter over quarter engagement to see if
              metrics have changed. If so, what are the potential drivers of that change?
            </li>
          </ul>
        </Expandable>
      </div>
      <DOTable
        headers={[
          {
            field: "objectiveName",
            label: "Objective Name",
            tooltipText: "Objective Name: Internal name of the objective",
          },
          {
            field: "emailCtr",
            label: "Email CTR",
            tooltipText:
              "Email Click Through Rate: total # of unique email message clicks / total # of email messages sent (excluding bounces)",
          },
          {
            field: "smsCtr",
            label: "SMS CTR",
            tooltipText:
              "SMS Click Through Rate: total # of unique SMS message clicks / total # of SMS messages sent (excluding bounces)",
          },
          {
            field: "bounceRate",
            label: "Bounce Rate",
            tooltipText: "Bounce Rate: total # of message bounces / total # of messages sent",
          },
          {
            field: "unsubscribeRate",
            label: "Unsubscribe Rate",
            tooltipText:
              "Unsubscribe Rate: total # of unique unsubscribes / total # of messages sent (excluding bounces)",
          },
          {
            field: "pageCtr",
            label: "Page CTR",
            tooltipText:
              "Page Click Through Rate: total # of unique page clicks / total # of unique page views",
          },
          {
            field: "presentedObjectiveCompletionRate",
            label: "Presented Objective Completion Rate",
            tooltipText:
              "Presented Objective Completion Rate: total # of unique objective completions / total # of objectives presented to journeys",
          },
          {
            field: "mobileActionRate",
            label: "% of Actions on a Mobile Device",
            tooltipText:
              "% of Actions on a Mobile Device: total # of engagement actions completed on mobile / total # of engagement actions with device type identifier",
          },
        ]}
        isTableLoading={objectiveContentIsLoading}
        noResults="No data found."
        refresh={refresh}
        paginationEnabled={true}
      >
        {({
          bounceRate,
          emailCtr,
          mobileActionRate,
          objectiveId,
          objectiveName,
          pageCtr,
          presentedObjectiveCompletionRate,
          smsCtr,
          unsubscribeRate,
        }) => {
          return (
            <TableRow key={objectiveId}>
              <TableCell>{objectiveName}</TableCell>
              <TableCell>{emailCtr}%</TableCell>
              <TableCell>{smsCtr}%</TableCell>
              <TableCell>{bounceRate}%</TableCell>
              <TableCell>{unsubscribeRate}%</TableCell>
              <TableCell>{pageCtr}%</TableCell>
              <TableCell>{presentedObjectiveCompletionRate}%</TableCell>
              <TableCell>{mobileActionRate}%</TableCell>
            </TableRow>
          )
        }}
      </DOTable>
    </div>
  )
}

ObjectiveContent.propTypes = {
  fetchInsights: func.isRequired,
  filterParams: object,
  hasSocket: bool,
  itemsPerPage: number,
  objectiveContent: object,
  objectiveContentIsLoading: bool,
  page: number,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
}

const useStyles = makeStyles(theme => ({
  container: {
    "& tr th:nth-child(5)": {
      maxWidth: 150,
    },
    "& tr th:nth-child(7)": {
      maxWidth: 170,
    },
    "& tr th:nth-child(8)": {
      maxWidth: 170,
    },
  },
  expandableContainer: {
    marginBottom: theme.spacing(3),
  },
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
}))

export default tabular({
  itemsPerPage: 10,
  sortColumn: "objectiveName",
  sortDirection: "asc",
})(teamInsightsContext(ObjectiveContent))
