import cleanSlateCssStyles from "./journey-clean-slate-styles"
import defaultStyles from "./journey-default-styles"
import simpleHorizontalStyles from "./journey-simple-horizontal-styles"

const themeBases = {
  default: defaultStyles,
  "simple-horizontal": simpleHorizontalStyles,
  "clean-slate": cleanSlateCssStyles,
}

export default themeBases
