import withStyles from "@mui/styles/withStyles"
import {func, object} from "prop-types"

import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"
import ContactForm from "./contact-form"

const NewContact = ({classes, onCreateContact, contactDefaults}) => (
  <div>
    <DocumentTitle title="Create Contact" />
    <TitleBar
      backButtonAttrs={{
        to: "/admin/contacts",
        children: "Return to Contacts",
      }}
      title="Create A New Contact"
    />
    <Padded>
      <Box>
        <ContactForm classes={classes} initialValues={contactDefaults} onSubmit={onCreateContact} />
      </Box>
    </Padded>
  </div>
)

NewContact.propTypes = {
  classes: object.isRequired,
  contactDefaults: object,
  onCreateContact: func.isRequired,
}

const styles = {
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
}

export default withStyles(styles)(NewContact)
