const loadImage = async url => {
  const img = new Image()
  const promise = new Promise((resolve, reject) => {
    img.onload = resolve
    img.onerror = reject
  })

  img.src = url

  return await promise
}

export default loadImage
