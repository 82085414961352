import {FormControl, FormHelperText, InputLabel, MenuItem, TextField} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, string} from "prop-types"

import ColorInput from "components/color-input/color-input"
import FormActions from "components/content-block-editor/plugins/form-actions"
import DOSelect from "components/do-select/do-select"
import ResetDefaultValue from "components/templates/reset-default-value"

import useForm from "lib/hooks/use-form"
import {getHumanContainerType} from "lib/string/humanize"

const cssNumberText = "(Accepts CSS numbers like 10px, 50%, etc)"

const ContainerStyleSettings = ({styles, onSubmit, containerType}) => {
  const {change, field, handleSubmit, resetForm} = useForm({
    enableReinitialize: true,
    initialValues: styles,
    onSubmit,
    validators: {
      margin: [validCssNumber],
      padding: [validCssNumber],
    },
  })
  const type = getHumanContainerType(containerType)

  const classes = useStyles()
  const muiTheme = useTheme()

  const defaultColor = muiTheme.palette.text.primary
  const defaultBackgroundColor = muiTheme.palette.background.paper

  return (
    <form style={{width: "100%"}} onSubmit={handleSubmit}>
      <FormHelperText>Customize the base styles of this {type}.</FormHelperText>
      <TextField
        autoFocus={true}
        className={classes.inputContainer}
        fullWidth={true}
        helperText={`The space between this ${type}'s wrapper and its neighbors. ${cssNumberText}`}
        id="container-style-margin"
        label="Margin"
        {...field("margin")}
      />
      <TextField
        className={classes.inputContainer}
        fullWidth={true}
        helperText={`The space between the ${type}'s content and its edges. If this ${type} has a background color set, the area it takes up will increase if you set a padding value. ${cssNumberText}`}
        id="container-style-padding"
        label="Padding"
        {...field("padding")}
      />
      <FormControl className={classes.inputContainer} fullWidth={true}>
        <InputLabel id="container-style-text-align">Alignment</InputLabel>
        <DOSelect
          className={classes.field}
          id="container-style-text-align"
          limitHeight={false}
          {...field("textAlign")}
        >
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </DOSelect>
        <FormHelperText>
          This setting might get overridden by the styling of components inside the {type}.
        </FormHelperText>
      </FormControl>
      <ColorInput
        className={classes.inputContainer}
        fullWidth={true}
        id="container-style-background-color"
        input={{
          ...field("backgroundColor", {defaultValue: defaultBackgroundColor}),
          onChange: hex => change("backgroundColor", hex),
        }}
        label={
          <ResetDefaultValue
            {...field("backgroundColor")}
            defaultValue={defaultBackgroundColor}
            reset={value => change("backgroundColor", value)}
            text="Background Color"
          />
        }
      />
      <div className={classes.inputContainer}>
        <ColorInput
          fullWidth={true}
          id="container-style-text-color"
          input={{
            ...field("color", {defaultValue: defaultColor}),
            onChange: hex => change("color", hex),
          }}
          label={
            <ResetDefaultValue
              {...field("color")}
              defaultValue={defaultColor}
              reset={value => change("color", value)}
              text="Text Color"
            />
          }
        />
        <FormHelperText>
          This setting might get overriden by the styling of components inside the {type}.
        </FormHelperText>
      </div>
      <FormActions resetForm={resetForm} />
    </form>
  )
}

ContainerStyleSettings.propTypes = {
  containerType: string.isRequired,
  styles: object.isRequired,
  onSubmit: func.isRequired,
}

const validCssNumber = value => {
  if (!value) return null

  const values = value.trim().split(/\s+/)

  if (values.length > 4) return "Too many values"

  if (
    !values.every(number =>
      /^-?(?:0|[\d.]+(?:cm|mm|in|pc|pt|px|%|r?em|ch|lh|vh|vw|vmin|vmax))$/.test(number)
    )
  )
    return "Invalid CSS Number"
}

const useStyles = makeStyles(theme => ({
  inputContainer: {
    marginTop: 0,
    marginBottom: theme.spacing(3.5),
  },
}))

export default ContainerStyleSettings
