import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"

import {
  ADVANCED_TEAM_INSIGHTS_FILTER_INITIAL_VALUES,
  TemplateStatuses,
} from "components/advanced-team-insights/advanced-team-insights-reducer"
import DatePicker from "components/date-picker/date-picker"
import DOSelect from "components/do-select/do-select"
import Feature from "components/feature/feature"
import {useObjectives} from "components/team-insights/objectives-context"

import dateParser from "lib/date/date-parser"
import useForm from "lib/hooks/use-form"

const AdvancedTeamInsightsFilter = ({
  initialValues = ADVANCED_TEAM_INSIGHTS_FILTER_INITIAL_VALUES,
  onSubmit,
}) => {
  const classes = useStyles()
  const {change, field, inputs, resetForm} = useForm({
    autoSubmitOnChange: true,
    autoSubmitDebounceTime: 250,
    enableReinitialize: true,
    initialValues,
    parse: {
      startDate: dateParser,
      endDate: dateParser,
    },
    onSubmit,
  })
  const objectives = useObjectives()

  const changeAttributionFilter = ({currentTarget}) => {
    change("attributionModel", currentTarget.value)
  }

  const changeStatusFilter = ({currentTarget}) => {
    change("templateStatus", currentTarget.value)
  }

  const changeObjectiveFilter = value => {
    value.includes("all") ? change("objective", []) : change("objective", value)
  }

  const renderSelectedObjectives = objectiveIds => {
    if (objectiveIds.length > 1) return <i>{objectiveIds.length} objectives</i>

    const objective = objectives.find(({id}) => id === objectiveIds[0])

    return objective?.name ?? "All Objectives"
  }

  const attributionFilter = inputs.attributionModel.value
  const selectedObjectives = inputs.objective.value
  const statusFilter = inputs.templateStatus.value

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <DatePicker format="MMMM dd yyyy" disableMaskedInput label="From" {...field("startDate")} />
        <DatePicker format="MMMM dd yyyy" disableMaskedInput label="To" {...field("endDate")} />

        <FormControl className={classes.objectiveFilterSelector} margin="normal">
          <InputLabel id="objective-filter-label" shrink={true}>
            Filter by Objective
          </InputLabel>
          <DOSelect
            displayEmpty={true}
            labelId="objective-filter-label"
            multiple={true}
            name="objective"
            onChange={({target: {value}}) => changeObjectiveFilter(value)}
            renderValue={renderSelectedObjectives}
            value={selectedObjectives}
          >
            <MenuItem value="all">All Objectives</MenuItem>
            <Divider />
            {objectives.map(objective => (
              <MenuItem key={objective.id} value={objective.id}>
                <Checkbox checked={selectedObjectives.includes(objective.id)} color="primary" />
                <ListItemText primary={objective.name} />
              </MenuItem>
            ))}
          </DOSelect>
        </FormControl>

        <Feature featureKey="premier-attribution">
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography className={classes.label}>Attribution Model</Typography>
            <Box sx={{display: "flex"}}>
              <ButtonGroup size="small" sx={{marginRight: 1}} variant="outlined">
                <Button
                  color={"standard" === attributionFilter ? "primary" : "grey"}
                  onClick={changeAttributionFilter}
                  tabIndex={0}
                  value="standard"
                >
                  Standard
                </Button>
                <Button
                  color={"premier" === attributionFilter ? "primary" : "grey"}
                  onClick={changeAttributionFilter}
                  tabIndex={1}
                  value="premier"
                >
                  Premier
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Feature>

        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Typography className={classes.label}>Filter by Status</Typography>
          <Box sx={{display: "flex"}}>
            <ButtonGroup size="small" variant="outlined">
              <Button
                color={TemplateStatuses.All === statusFilter ? "primary" : "grey"}
                onClick={changeStatusFilter}
                tabIndex={0}
                value={TemplateStatuses.All}
              >
                All
              </Button>
              <Button
                color={TemplateStatuses.Live === statusFilter ? "primary" : "grey"}
                onClick={changeStatusFilter}
                tabIndex={1}
                value={TemplateStatuses.Live}
              >
                Live
              </Button>
              <Button
                color={TemplateStatuses.Archived === statusFilter ? "primary" : "grey"}
                onClick={changeStatusFilter}
                tabIndex={2}
                value={TemplateStatuses.Archived}
              >
                Archived
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        <Button color="grey" onClick={resetForm}>
          reset
        </Button>
      </div>
    </div>
  )
}

AdvancedTeamInsightsFilter.propTypes = {
  initialValues: object,
  onSubmit: func.isRequired,
}

const INPUT_LABEL_SHRINK_FONT_SIZE = "0.875em"
const NAV_HEIGHT = 44
const SUB_NAV_HEIGHT = 44
const PADDING = 16

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    position: "sticky",
    top: NAV_HEIGHT + SUB_NAV_HEIGHT + PADDING,
    zIndex: theme.zIndex.appBar,
  },
  innerContainer: {
    display: "flex",
    alignItems: "flex-end",
    background: "#fff",
    padding: theme.spacing(1),
    borderRadius: 2,
    boxShadow: theme.shadows[2],
    gap: theme.spacing(2),
    width: "100%",
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: INPUT_LABEL_SHRINK_FONT_SIZE,
  },
  objectiveFilterSelector: {
    marginBottom: 0,
    minWidth: 263.7,
    flex: 1,
  },
}))

export default AdvancedTeamInsightsFilter
