import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {func, object} from "prop-types"
import {Component} from "react"
import {BiNetworkChart} from "react-icons/bi"
import {
  FaCheckCircle as ApprovedIcon,
  FaArchive as ArchivedIcon,
  FaTools as DraftIcon,
  FaLeaf as LiveIcon,
  FaVolumeMute as MuteIcon,
  FaExclamationTriangle as NotApprovedIcon,
  FaStreetView as TargetingIcon,
  FaStar as TargetingPriorityIcon,
} from "react-icons/fa"
import {FiEdit as EditIcon, FiMap as MapIcon} from "react-icons/fi"
import {
  MdDesktopWindows as MonitorIcon,
  MdOutlinePendingActions as PendingIcon,
} from "react-icons/md"
import {Link} from "react-router-dom"

import MuiIcon from "components/mui-icon"
import Character01 from "components/superhero/character-01"
import Character02 from "components/superhero/character-02"
import Character03 from "components/superhero/character-03"
import Character04 from "components/superhero/character-04"
import Character05 from "components/superhero/character-05"
import Character06 from "components/superhero/character-06"
import Character07 from "components/superhero/character-07"
import Character08 from "components/superhero/character-08"
import Character09 from "components/superhero/character-09"
import Character10 from "components/superhero/character-10"
import Character11 from "components/superhero/character-11"
import Character12 from "components/superhero/character-12"
import Character13 from "components/superhero/character-13"
import Character14 from "components/superhero/character-14"
import Character15 from "components/superhero/character-15"
import Character16 from "components/superhero/character-16"
import Character17 from "components/superhero/character-17"
import Character18 from "components/superhero/character-18"
import Character19 from "components/superhero/character-19"
import Character20 from "components/superhero/character-20"
import Character21 from "components/superhero/character-21"
import Character22 from "components/superhero/character-22"
import Character23 from "components/superhero/character-23"
import Character24 from "components/superhero/character-24"
import Character25 from "components/superhero/character-25"

import isIe11 from "lib/browser/is-ie-11"
import {formatDate} from "lib/date-time-formatters"
import {featurify} from "lib/hooks/use-features"
import pluralize from "lib/string/pluralize"

import AccessControlled from "../access-control/access-controlled"
import BadgeIfError from "../badge-if-error/badge-if-error"
import Counter from "../counter/counter"
import ArchiveCornerBanner from "./archive-corner-banner"

const characters = [
  Character01,
  Character02,
  Character03,
  Character04,
  Character05,
  Character06,
  Character07,
  Character08,
  Character09,
  Character10,
  Character11,
  Character12,
  Character13,
  Character14,
  Character15,
  Character16,
  Character17,
  Character18,
  Character19,
  Character20,
  Character21,
  Character22,
  Character23,
  Character24,
  Character25,
]

class TemplateCard extends Component {
  shouldComponentUpdate = ({template}) => this.props.template !== template

  renderStatusIcon() {
    switch (this.props.template?.status) {
      case "draft":
        return (
          <>
            <MuiIcon icon={<DraftIcon />} className={this.props.classes?.statusHeaderIcon} /> Draft
            & Testing
          </>
        )
      case "pending-approval":
        return (
          <>
            <MuiIcon icon={<PendingIcon />} className={this.props.classes?.statusHeaderIcon} />{" "}
            Pending Approval
          </>
        )
      case "approved":
        return (
          <>
            <MuiIcon icon={<ApprovedIcon />} className={this.props.classes?.statusHeaderIcon} />{" "}
            Approved
          </>
        )
      case "not-approved":
        return (
          <>
            <MuiIcon icon={<NotApprovedIcon />} className={this.props.classes?.statusHeaderIcon} />{" "}
            Not Approved
          </>
        )
      case "live":
        return (
          <>
            <MuiIcon icon={<LiveIcon />} className={this.props.classes?.statusHeaderIcon} /> Live
          </>
        )
      case "archived":
        return (
          <>
            <MuiIcon icon={<ArchivedIcon />} className={this.props.classes?.statusHeaderIcon} />{" "}
            Archived
          </>
        )
      default:
        return null
    }
  }

  wrapInTooltipIfArchived = (template, button) => {
    if (template.status === "archived") {
      const message = "This campaign is archived, new journeys cannot be created."
      return (
        <Tooltip title={message}>
          <span>{button}</span>
        </Tooltip>
      )
    } else {
      return button
    }
  }

  render() {
    const {classes, template, hasFeature} = this.props
    const totalJourneys = template?.facts.journeyCount ?? 0
    const status = template?.status

    const Character = characters[parseInt(template.id.substr(0, 3), 16) % characters.length]

    return (
      <Card
        aria-labelledby={`template-${template.id}-title`}
        className={cx("template-card", {
          [classes.card]: !isIe11(),
          [classes.ieCard]: isIe11(),
        })}
        role="listitem"
      >
        {hasFeature("campaign-approval") && (
          <CardHeader
            className={cx(classes.statusHeader, classes[`status-${status}`])}
            title={<Typography align="center">{this.renderStatusIcon()}</Typography>}
          />
        )}
        {!hasFeature("campaign-approval") && template.status === "archived" && (
          <ArchiveCornerBanner />
        )}
        <CardContent className={classes.content}>
          <Box display="flex">
            <Typography
              sx={{flex: "1"}}
              id={`template-${template.id}-title`}
              variant="h5"
              title={template.name}
              className={classes.title}
            >
              {template.name}
            </Typography>
            <BadgeIfError
              BadgeIcon={MuteIcon}
              badgeSize={15}
              badgeText="This campaign has been muted."
              hasError={template.isMuted}
            >
              {" "}
            </BadgeIfError>
          </Box>
          <div style={{fontStyle: "italic"}}>
            Created {formatDate(template.insertedAt, "MMM d, yyyy")}
          </div>
          <div className={classes.iconRow}>
            {!!(template.targetingOrder !== null || template.isTargetingPriority) && (
              <>
                <Link
                  to={`/admin/templates/${template.id}/targeting`}
                  title="This campaign uses automatic targeting"
                >
                  <TargetingIcon size={30} className={classes.targetingIcon} />
                </Link>
                <Link
                  to={`/admin/templates/${template.id}/targeting`}
                  title="This is a priority campaign in automatic targeting"
                  className={cx({[classes.hideLink]: !template.isTargetingPriority})}
                >
                  <TargetingPriorityIcon size={30} className={classes.targetingPriorityIcon} />
                </Link>
              </>
            )}
            {hasFeature("cross-channel-engagement") && template.isCceEnabled && (
              <BiNetworkChart size={30} />
            )}
          </div>
          <div className={classes.body}>
            {totalJourneys > 0 ? (
              <>
                <Character height={120} />
                <Button
                  color="primary"
                  component={Link}
                  to={`/admin/templates/${template.id}/insights`}
                >
                  Get Insights
                </Button>
              </>
            ) : (
              <MapIcon color="#dff0fd" size={120} style={{marginTop: -32}} />
            )}
          </div>

          <Typography className={classes.total}>
            {totalJourneys > 0 ? (
              <>
                <Counter>{totalJourneys}</Counter> {pluralize("journey", totalJourneys)}
              </>
            ) : (
              "No journeys sent yet"
            )}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <div className={cx(classes.verticalDivider, classes.actionButtonWrapper)}>
            <AccessControlled requiredPermissions="templates:view">
              <Button
                className={classes.actionButton}
                component={Link}
                fullWidth={true}
                to={`/admin/templates/${template.id}`}
              >
                <MonitorIcon className={classes.leftIcon} /> View
              </Button>
            </AccessControlled>
          </div>
          <div className={cx(classes.verticalDivider, classes.actionButtonWrapper)}>
            <AccessControlled requiredPermissions={["templates:view", "templates:edit"]}>
              <Button
                className={classes.actionButton}
                component={Link}
                fullWidth={true}
                to={`/admin/templates/${template.id}/edit`}
              >
                <EditIcon className={classes.leftIcon} /> Edit
              </Button>
            </AccessControlled>
          </div>
          <div className={classes.actionButtonWrapper}>
            <AccessControlled requiredPermissions="contacts:create">
              {this.wrapInTooltipIfArchived(
                template,
                <Button
                  disabled={template.status === "archived"}
                  className={classes.actionButton}
                  component={Link}
                  fullWidth={true}
                  to={`/admin/templates/${template.id}/journeys/new`}
                >
                  <MapIcon className={classes.leftIcon} /> Create
                </Button>
              )}
            </AccessControlled>
          </div>
        </CardActions>
      </Card>
    )
  }
}

TemplateCard.propTypes = {
  classes: object.isRequired,
  template: object.isRequired,
  hasFeature: func.isRequired,
}

const styles = theme => ({
  actions: {
    padding: "0",
  },
  actionButtonWrapper: {
    width: "100%",
    margin: "0 !important",
  },
  actionButton: {
    borderRadius: "0",
    color: theme.palette.text.primary,
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  card: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
  },
  ieCard: {
    display: "flex",
    borderRadius: theme.shape.borderRadius,
    flexDirection: "column",
    height: 423,
    marginRight: 32,
    marginBottom: 32,
    [theme.breakpoints.up("md")]: {
      display: "inline-flex",
      width: 300,
    },
  },
  statusHeader: {
    color: "white",
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
  statusHeaderIcon: {
    paddingRight: "5px",
    position: "relative",
    top: "2px",
  },
  "status-draft": {
    backgroundColor: theme.palette.brand.darkBlue,
  },
  "status-pending-approval": {
    backgroundColor: theme.palette.brand.mediumGray,
  },
  "status-approved": {
    backgroundColor: theme.palette.brand.lightGreen,
  },
  "status-not-approved": {
    backgroundColor: theme.palette.brand.lightCoral,
  },
  "status-live": {
    backgroundColor: theme.palette.brand.lightBlue,
  },
  "status-archived": {
    backgroundColor: theme.palette.brand.darkestGray,
  },
  content: {
    padding: "15px 15px 0 15px",
    textAlign: "center",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  verticalDivider: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  total: {
    display: "block",
    margin: "20px 0",
    fontSize: theme.typography.fontSize * 1.4,
    fontWeight: 200,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 120px",
  },
  spacer: {
    flex: 1,
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    margin: `${theme.spacing(2)} ${theme.spacing(5)} 0`,
    minHeight: 35,
  },
  targetingIcon: {
    color: theme.palette.brand.darkBlue,
  },
  targetingPriorityIcon: {
    color: theme.palette.warning.main,
  },
  hideLink: {
    visibility: "hidden",
    pointerEvents: "none",
  },
})

export default withStyles(styles)(featurify(TemplateCard))
