import {FormHelperText, TextField, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object, string} from "prop-types"

import {compileCss} from "lib/custom-css"
import {formify} from "lib/hooks/use-form"
import {getHumanContainerType} from "lib/string/humanize"

import FormActions from "../content-block-editor/plugins/form-actions"

const ContainerCssSettings = ({
  classes,
  field,
  handleSubmit,
  resetForm,
  selector,
  containerType,
}) => {
  const {error, helperText, ...cssField} = field("css")
  const type = getHumanContainerType(containerType)
  let line, char, message

  if (error && helperText) [, line, char, message] = helperText[0].split(":")

  return (
    <form style={{width: "100%"}} onSubmit={handleSubmit}>
      <FormHelperText style={{marginBottom: 32}}>
        You can override any of the styling of this {type} through custom CSS here. Use{" "}
        <span className={classes.highlight}>{selector}</span> to select the root of the {type}.
      </FormHelperText>
      <TextField
        autoFocus={true}
        fullWidth={true}
        id="customCss"
        inputProps={{className: classes.cssEditor}}
        label="Custom CSS"
        margin="dense"
        multiline={true}
        rows={10}
        type="text"
        variant="outlined"
        error={!!error}
        {...cssField}
      />
      {message && (
        <>
          <Typography gutterBottom={true} variant="caption">
            There is a syntax error in your css. Please validate it before saving.
          </Typography>
          <Typography className={classes.errorText} gutterBottom={true} variant="caption">
            Line {line} Character {char}
            <br />
            {message}
          </Typography>
        </>
      )}
      <FormActions resetForm={resetForm} />
    </form>
  )
}

ContainerCssSettings.propTypes = {
  classes: object.isRequired,
  containerType: string.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func.isRequired,
  selector: string,
}
ContainerCssSettings.defaultProps = {
  selector: "&",
}
const monospace = `"Monaco","Consolas","Lucida Console",monospace`

const styles = theme => ({
  cssEditor: {
    fontFamily: monospace,
  },
  errorText: {
    display: "block",
    color: theme.palette.error.main,
    fontFamily: monospace,
  },
  highlight: {
    color: theme.palette.brand.lightCoral,
    fontFamily: monospace,
  },
})

const validateCss = css => {
  try {
    compileCss("#validator", css)
    return null
  } catch (e) {
    return e.message
  }
}

const ContainerCssSettingsForm = formify({
  validators: {
    css: [validateCss],
  },
})(ContainerCssSettings)

export default withStyles(styles)(ContainerCssSettingsForm)
