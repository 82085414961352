import {useCallback, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import shortid from "shortid"

import {useJourneyContext} from "components/journeys/journey-context"

import {sessionStore as storage} from "lib/storage"

import {addAction} from "../api"

export const ACTION_ADDED = "ACTION_ADDED"

const TOP_LEVEL_KEYS = {
  contactId: "contactId",
  journeyId: "journeyId",
  messageId: "messageId",
  pageId: "pageId",
  templateId: "templateId",
  contentBlockId: "contentBlockId",
}

const topLevelKeys = Object.values(TOP_LEVEL_KEYS)

// NB: Filter out top level keys and remove empty values from meta
const getMeta = params =>
  Object.entries(params)
    .filter(([key]) => !topLevelKeys.includes(key))
    .reduce((acc, [key, value]) => (value ? {...acc, [key]: value} : acc), {})

// NB: Older versions of mobile safari don't support crypto's `randomUUID`, therefore,
// we fall back to `shortid.generate()` for those users.
export const getSessionId = () => {
  let sessionId = storage.getItem("sessionId")

  if (!sessionId) {
    /* eslint-disable-next-line no-restricted-globals */
    sessionId = self?.crypto?.randomUUID() ?? shortid.generate()
    storage.setItem("sessionId", sessionId)
  }

  return sessionId
}

const useAnalytics = () => {
  // Extract crossChannelEngagement from journeyFromContext and memoize. Depending
  // on journeyFromContext causes maximum update depth errors.
  const journeyFromContext = useJourneyContext()
  const {engagementChannelId} = journeyFromContext || {engagementChannelId: null}
  const crossChannelEngagement = useMemo(() => {
    // If engagementChannelId is null, then set crossChannelEngagement to null
    return engagementChannelId ? {engagementChannelId} : engagementChannelId
  }, [engagementChannelId])

  const {template, journey} = useSelector(({template: _template, journey: _journey}) => ({
    template: _template,
    journey: _journey,
  }))

  const dispatch = useDispatch()

  const getParam = useCallback(
    (params, name) => {
      const {contactId, journeyId, messageId, pageId, templateId, contentBlockId} = TOP_LEVEL_KEYS

      switch (name) {
        case contactId:
          return params?.contactId ?? journeyFromContext?.contact?.id
        case journeyId:
          return params?.journeyId ?? journeyFromContext?.journeyId ?? journey?.id
        case messageId:
          return params?.messageId
        case pageId:
          return params?.pageId ?? journeyFromContext?.pageId
        case templateId:
          return params?.templateId ?? journeyFromContext?.template?.id ?? template?.id
        case contentBlockId:
          return params?.contentBlockId
        default:
          throw new Error(
            `Param type [${name}] is not supported as a top level key. Please store in meta.`
          )
      }
    },
    [
      journey?.id,
      journeyFromContext?.contact?.id,
      journeyFromContext?.journeyId,
      journeyFromContext?.pageId,
      journeyFromContext?.template?.id,
      template?.id,
    ]
  )

  const getMetaParam = useCallback(
    (params, name) => {
      switch (name) {
        case "contentContainerId":
          return params?.contentContainerId
        case "engagementChannelId":
          return params?.engagementChannelId ?? crossChannelEngagement?.engagementChannelId
        default:
          return params?.[name]
      }
    },
    [crossChannelEngagement]
  )

  const getReduxOnlyParams = useCallback(() => {
    if (Boolean(crossChannelEngagement)) return {}

    return {
      pages: template?.templatePages?.map(tp => tp.page),
    }
  }, [crossChannelEngagement, template])

  const track = useCallback(
    (name, params = {}) => {
      // Prevent tracking when inside the editor
      // NB: Do not move this into its own function or `journey_opened` will not get fired off in time
      // because it is called in the Journey's constructor method.

      // This should be checking for "in editor" but also bails for CCE
      // journey id can come from params, or from "content container"
      if (
        Boolean(crossChannelEngagement) &&
        !getParam(params, TOP_LEVEL_KEYS.journeyId) &&
        !getParam(params, TOP_LEVEL_KEYS.contactId)
      )
        return

      if (!Boolean(crossChannelEngagement) && !getParam(params, TOP_LEVEL_KEYS.journeyId)) return

      // NB: contentBlockId will be moved to top level thanks to getMeta, getMetaParam
      // https://trello.com/c/27TpVjnQ

      const payload = {
        ...getReduxOnlyParams(),
        name,
        contactId: getParam(params, TOP_LEVEL_KEYS.contactId),
        journeyId: getParam(params, TOP_LEVEL_KEYS.journeyId),
        messageId: getParam(params, TOP_LEVEL_KEYS.messageId),
        pageId: getParam(params, TOP_LEVEL_KEYS.pageId),
        templateId: getParam(params, TOP_LEVEL_KEYS.templateId),
        contentBlockId: getParam(params, TOP_LEVEL_KEYS.contentBlockId),
        meta: getMeta({
          ...params,
          contentContainerId: getMetaParam(params, "contentContainerId"),
          engagementChannelId: getMetaParam(params, "engagementChannelId"),
          sessionId: getSessionId(),
        }),
      }

      dispatch({
        type: ACTION_ADDED,
        payload,
      })

      return addAction(payload)
    },
    [crossChannelEngagement, dispatch, getMetaParam, getParam, getReduxOnlyParams]
  )

  return {track}
}

export const analytical = Component => props => <Component {...props} {...useAnalytics()} />

export default useAnalytics
