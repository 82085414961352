import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import omit from "lodash/omit"
import {bool, func, object} from "prop-types"
import {Component} from "react"
import {MdExpandMore as ExpandIcon} from "react-icons/md"
import {connect} from "react-redux"

import {actions} from "components/content-block-editor/content-block-editor-reducer"

import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import {contentBlockPropType} from "../content-block-editor/content-block-editor-prop-types"
import {pluginFor} from "../content-block-editor/plugins"
import DangerButton from "../danger-button/danger-button"
import ConfirmDialog from "../dialogs/confirm-dialog.jsx"
import WidgetCssSettings from "./widget-css-settings"
import WidgetGridSettings from "./widget-grid-settings"
import WidgetStyleSettings from "./widget-style-settings"

class WidgetSettings extends Component {
  state = {
    showDeleteConfirmation: false,
    expanded: !!pluginFor(this.props.selectedBlock).Settings ? "widget-settings" : "styles",
  }

  componentWillUnmount() {
    const areEditsInSidebar = this.props.selectedBlock?.type !== "text"

    if (areEditsInSidebar) {
      this.props.setSelectedBlockDataChanges(null)
    }

    // NB: Clear all meta changes when unmounting
    this.props.setSelectedBlockMetaChanges(null)
  }

  onShowDeleteConfirmation = () => {
    this.setState({showDeleteConfirmation: true})
  }

  onHideDeleteConfirmation = () => {
    this.setState({showDeleteConfirmation: false})
  }

  // NB: We are dispatching `CONTENT_BLOCK_REMOVED` here for any curious listeners, e.g.,
  // template reducer. We want to remove this content block from template.templatePages[?].page.contentBlocks,
  // if we don't it results in a stale editor that requires a refresh to receive updates.
  // See: https://trello.com/c/2pUlXi1V
  onDeletionConfirmed = () => {
    this.props.onDeleteContentBlock(this.props.selectedBlock)

    this.props.notify({type: actions.removeContentBlock, payload: this.props.selectedBlock})

    this.onHideDeleteConfirmation()
  }

  onSelectPanel = expanded => this.setState({expanded})

  onChange = changeset => this.props.onUpdateContentBlock(changeset)

  onChangeData = data => this.onChange({data})

  render() {
    const {
      classes,
      selectedBlock,
      setSelectedBlockDataChanges,
      setSelectedBlockMetaChanges,
      isContentLibrary,
    } = this.props
    const {expanded} = this.state

    if (!selectedBlock) return null

    const {title, Settings} = pluginFor(selectedBlock)

    return [
      <div className={classes.settingsContainer} key="expansions">
        {Settings && (
          <Accordion
            classes={{root: classes.expansionPanel}}
            elevation={0}
            expanded={expanded === "widget-settings"}
            key="widget-settings"
            onChange={() => this.onSelectPanel("widget-settings")}
          >
            <AccordionSummary
              classes={{root: classes.expansionContent}}
              expandIcon={<ExpandIcon />}
            >
              <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{root: classes.expansionContent}}>
              <Settings
                initialValues={omit(selectedBlock.data, ["customClass"])}
                isContentLibrary={isContentLibrary}
                onChange={setSelectedBlockDataChanges}
                onChangeMeta={setSelectedBlockMetaChanges}
                onSubmit={this.onChangeData}
                contentBlock={selectedBlock}
              />
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          classes={{root: classes.expansionPanel}}
          elevation={0}
          expanded={expanded === "styles"}
          key="styles"
          onChange={() => this.onSelectPanel("styles")}
        >
          <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
            <Typography>Base Styles</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionContent}}>
            <WidgetStyleSettings
              initialValues={{styles: selectedBlock.styles}}
              onSubmit={this.onChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          classes={{root: classes.expansionPanel}}
          data-testid="grid-widget-settings"
          elevation={0}
          expanded={expanded === "grid"}
          key="grid"
          onChange={() => this.onSelectPanel("grid")}
        >
          <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
            <Typography>Width</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionContent}}>
            <WidgetGridSettings
              initialValues={{grid: selectedBlock.grid}}
              onSubmit={this.onChange}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          classes={{root: classes.expansionPanel}}
          elevation={0}
          expanded={expanded === "css"}
          key="css"
          onChange={() => this.onSelectPanel("css")}
        >
          <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
            <Typography>Custom Widget CSS</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionContent}}>
            <WidgetCssSettings
              initialValues={{
                css: selectedBlock.css,
                data: {customClass: selectedBlock.data.customClass},
              }}
              onSubmit={this.onChange}
            />
          </AccordionDetails>
        </Accordion>
        <DangerButton
          className={classes.field}
          fullWidth={true}
          onClick={this.onShowDeleteConfirmation}
          size="small"
        >
          Remove this {selectedBlock.type} Block
        </DangerButton>
        <ConfirmDialog
          content="This action can't be reversed."
          key={selectedBlock.id}
          onClose={this.onHideDeleteConfirmation}
          onConfirm={this.onDeletionConfirmed}
          open={this.state.showDeleteConfirmation}
          title={`Do you want to remove this ${selectedBlock.type} block?`}
        />
      </div>,
    ]
  }
}

WidgetSettings.propTypes = {
  classes: object.isRequired,
  device: object.isRequired,
  isContentLibrary: bool,
  notify: func,
  onDeleteContentBlock: func.isRequired,
  onUpdateContentBlock: func.isRequired,
  selectedBlock: contentBlockPropType,
  setSelectedBlockDataChanges: func.isRequired,
  setSelectedBlockMetaChanges: func.isRequired,
}

const styles = theme => ({
  settingsContainer: {},
  expansionPanel: {},
  select: {},
  field: {},
  switchTooltipIcon: {},
  switchTooltipIconWrapper: {},
  supportOwnerSwitchLabel: {},
  sidbarButton: {},
  expansionContent: {},
  customCssButton: {},
  helperText: {},
  listSubheader: {},
  title: {},
  tabBadge: {},
  tabContainer: {},
  listItemTextSecondary: {},
  menuItem: {},
  drawerPaper: {},
  drawerClosed: {},
  drawerOpen: {},
  drawer: {},
  icon: {},
  tab: {},
  hidden: {},
})

const mapDispatchToProps = {notify: action => dispatch => dispatch(action)}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(contentBlockEditorConsumer(WidgetSettings)))
