import {Box, FormControl, InputLabel, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, shape, string} from "prop-types"

import ObjectiveSelect from "../objective-select/objective-select"
import SuperheroBuilder2 from "../superhero/builder-2"

const useStyles = makeStyles(theme => ({
  intro: {
    marginBottom: theme.spacing(2),
  },
}))

const setObjective = payload => ({type: "OBJECTIVE_SET", payload})

const ObjectiveStep = ({currentRow: {objective}, dispatch}) => {
  const classes = useStyles()

  return (
    <>
      <SuperheroBuilder2 style={{width: 320, float: "right"}} />
      <Box maxWidth={400} my={4}>
        <Typography className={classes.intro}>
          Let's add an objective to your new campaign. Once you've picked an objective you can add
          content that will support that objective.
        </Typography>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor="campaign-objective" id="campaign-objective-label">
            Objective
          </InputLabel>
          <ObjectiveSelect
            id="campaign-objective"
            labelId="campaign-objective-label"
            name="campaign-objective"
            onChange={o => dispatch(setObjective(o))}
            required={false}
            value={objective || ""}
          />
        </FormControl>
        <Typography variant="caption">
          Objectives are what you want to accomplish with your campaign.
        </Typography>
      </Box>
    </>
  )
}

ObjectiveStep.propTypes = {
  currentRow: shape({
    objective: shape({id: string}),
  }),
  dispatch: func.isRequired,
}

export default ObjectiveStep
