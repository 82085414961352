import React, {useEffect, useState} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"

import {fetchAdvancedInsightsValueOfOpenedProductsOverTime} from "lib/api"

import ValueOverTimeChartTruncateHeader from "./value-over-time-chart-truncate-header"
import ValueOverTimeLineChart from "./value-over-time-line-chart"

const ValueOfOpenedProductsOverTime = () => {
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    valueOfOpenedProductsOverTime,
    valueOfOpenedProductsOverTimeIsLoading,
  } = useAdvancedTeamInsights()
  const PERIODS = ["Month", "Quarter", "Year"]
  const [currentPeriod, setCurrentPeriod] = useState(PERIODS[0])

  // Fetch data
  useEffect(() => {
    if (hasSocket) {
      fetchInsights(
        "valueOfOpenedProductsOverTime",
        fetchAdvancedInsightsValueOfOpenedProductsOverTime,
        [{...filterParams, period: currentPeriod.toLowerCase()}],
        {ignoreCaseConversionForPaths: [/value\.data\.\d/]}
      )
    }
  }, [fetchInsights, filterParams, hasSocket, currentPeriod])

  return (
    <div>
      <ValueOverTimeChartTruncateHeader
        heading="Value of Opened Products over Time"
        onChange={setCurrentPeriod}
        periods={PERIODS}
        selected={currentPeriod}
      />
      <ValueOverTimeLineChart
        businessDriverNames={valueOfOpenedProductsOverTime?.meta.businessDriverNames || []}
        chartData={valueOfOpenedProductsOverTime?.data || []}
        isLoading={valueOfOpenedProductsOverTimeIsLoading}
        period={currentPeriod}
      />
    </div>
  )
}

export default ValueOfOpenedProductsOverTime
