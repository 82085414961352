import {Box, Paper, Typography} from "@mui/material"
import {arrayOf, number, object, shape, string} from "prop-types"
import React from "react"

import {summarizeCondition} from "./targeting-preview-helpers"

const TargetingConditionsAndImpact = ({template, team, targetingImpact}) => {
  return (
    <Paper
      sx={{
        marginBottom: 2,
        padding: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Targeting Rules
      </Typography>
      {template.targetingGroups.map(targetingGroup => (
        <Box key={targetingGroup.id} sx={{marginBottom: 2}}>
          {targetingGroup.id !== template.targetingGroups[0].id && (
            <Typography
              align="center"
              variant="subtitle1"
              sx={{fontWeight: "bold", marginBottom: 1}}
            >
              {template.targetingGroupLogicalOperator}
            </Typography>
          )}
          <Box sx={{border: "1px solid #ccc", borderRadius: 1, padding: 2}}>
            <Typography variant="subtitle1" gutterBottom>
              Match{" "}
              <Box component="span" sx={{fontWeight: "bold"}}>
                {targetingGroup.logicalOperator === "AND" ? "ALL" : "ANY"}
              </Box>{" "}
              of the conditions below:
            </Typography>
            {targetingGroup.targetingConditions.map(condition => (
              <Typography key={condition.id} variant="body1" gutterBottom>
                {summarizeCondition(team, condition)}
              </Typography>
            ))}
          </Box>
        </Box>
      ))}
      {targetingImpact !== null && (
        <>
          <Typography variant="h6" gutterBottom>
            Targeting Impact
          </Typography>
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{color: "#00A4FF", fontWeight: "bold", marginBottom: 2}}
          >
            {targetingImpact}
          </Typography>
          <Typography variant="body1">
            These targeting conditions will result in {targetingImpact} contacts being added to this
            campaign when it is launched. The number of contacts added could change as your data
            changes. If contacts that are added or updated in the future also match these conditions
            they will be added to this campaign at that point.
          </Typography>
        </>
      )}
    </Paper>
  )
}

TargetingConditionsAndImpact.propTypes = {
  team: object.isRequired,
  targetingImpact: number,
  template: shape({
    id: string.isRequired,
    name: string.isRequired,
    targetingGroupLogicalOperator: string.isRequired,
    targetingGroups: arrayOf(
      shape({
        id: string.isRequired,
        logicalOperator: string.isRequired,
        targetingConditions: arrayOf(object).isRequired,
      })
    ).isRequired,
  }).isRequired,
}

export default TargetingConditionsAndImpact
