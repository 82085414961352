export const layoutBases = [
  {
    label: "Grid (recommended)",
    value: "grid",
    description: "The layout of your content will be based on a 12 column CSS grid.",
    columns: 12,
  },
  {
    label: "None (advanced)",
    value: "none",
    description:
      "Use this option if you want to supply all the CSS for the layout of your content.",
    columns: 12,
  },
  {
    label: "Table",
    value: "table",
    columns: 4,
  },
]

export const breakpoints = {
  default: [
    {key: "xs", range: [0, 575], description: "Extra small, most phones in portrait mode"},
    {key: "sm", range: [576, 767], description: "Small, most phones in landscape mode"},
    {key: "md", range: [768, 991], description: "Medium, most tablets in portrait mode"},
    {
      key: "lg",
      range: [992, 1199],
      description: "Large, larger tablets, tablets in landscape mode, and some laptops",
    },
    {key: "xl", range: [1200], description: "Extra large, larger laptops and desktop computers"},
  ],
  table: [
    {key: "xs", range: [0, 499], description: "Extra small, most phones in portrait mode"},
    {key: "sm", range: [500], description: "Small, suitable for all sizes larger than a phone"},
  ],
}

export const breakpointsForLayoutBasis = layoutBasis =>
  breakpoints[layoutBasis] || breakpoints.default

export const blockClasses = (layoutBasis, grid) =>
  breakpointsForLayoutBasis(layoutBasis)
    .map(({key}) => `cb-${key}-${grid[key]}`)
    .join(" ")

const layoutBasisColumn = (layoutBasis, columnWidth) => {
  switch (layoutBasis) {
    case "none":
      return ""
    case "table":
      const width = (columnWidth / 12) * 100
      if (width < 100) return `display: inline-block; vertical-align: top; width: ${width}%`
      return `width: ${width}%`
    default:
      if (columnWidth === 0) return `display: none`
      return `grid-column: span ${columnWidth}`
  }
}

export const layoutBasisSelectors = (containerClass, layoutBasis, key) =>
  Array(13)
    .fill()
    .map((_, i) => layoutBasisColumn(layoutBasis, i))
    .filter(Boolean)
    .map((declaration, i) => `.${containerClass} .cb-${key}-${i}{\n    ${declaration}\n  }`)
    .join("\n  ")

export const mediaString = (lowerBound, upperBound) => {
  const conditions = []

  if (lowerBound) conditions.push(`(min-width: ${lowerBound}px)`)

  if (upperBound) conditions.push(`(max-width: ${upperBound}px)`)

  return `@media ${conditions.join(" and ")}`
}

const generateLayoutBasisCss = (containerClass, layoutBasis) =>
  breakpointsForLayoutBasis(layoutBasis)
    .map(({key, range: [lowerBound, upperBound]}) => {
      const selectors = layoutBasisSelectors(containerClass, layoutBasis, key)

      if (!selectors) return ""

      return `${mediaString(lowerBound, upperBound)} {\n  ${selectors}\n}\n`
    })
    .join("")

export const forceBreakPoint = ({width}, containerClass, layoutBasis) => {
  const breakpoint = breakpointsForLayoutBasis(layoutBasis).find(
    ({range: [upperBound, lowerBound]}) =>
      (upperBound ? upperBound <= width : true) && (lowerBound ? lowerBound >= width : true)
  )

  return layoutBasisSelectors(containerClass, layoutBasis, breakpoint.key)
}

export default generateLayoutBasisCss
