import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Input,
  LinearProgress,
  ListSubheader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import {alpha} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import Papa from "papaparse"
import {arrayOf, func, object, shape, string} from "prop-types"
import {Component} from "react"
import {FaExclamationCircle as FailedIcon} from "react-icons/fa"
import {connect} from "react-redux"

import {fetchSshKeys} from "lib/api"
import MaybeTooltip from "lib/maybe-tooltip"
import pluralize from "lib/string/pluralize"

import DOSelect from "../do-select/do-select"
import {
  allAccountFields,
  allContactFields,
  compileFieldMap,
  journeyAccountFields,
  rehydrateFieldMap,
} from "../teams/csv-processing-settings/csv-processing-helpers"
import {updateTeamSettings} from "../teams/team-actions"
import {matchColumns} from "./batch-upload-helpers"

const countNumLines = async (file, onGatherFirstLines, onError) => {
  if (!file) return 0
  let numLines = 0 // header row
  let isComplete = false
  let hasSentFirstLines = false
  const firstLines = []

  Papa.parse(file, {
    header: true,
    trimHeaders: true,
    skipEmptyLines: "greedy",
    transform: data => data.trim(),
    chunk: (chunk, parser) => {
      if (!hasSentFirstLines) {
        for (let i = 0; firstLines.length < 5 && firstLines.length < chunk.data.length; i++) {
          const line = chunk.data[i]

          firstLines.push(line)
        }

        onGatherFirstLines({
          columns: chunk.meta.fields,
          previewData: firstLines,
        })
        hasSentFirstLines = true
      }

      if (chunk.meta.aborted) {
        onError(chunk)
        isComplete = true
        parser.abort()
      }

      numLines += chunk.data.length
    },
    complete: () => {
      isComplete = true
    },
  })

  while (!isComplete) await new Promise(res => setTimeout(res, 1000))
  return numLines
}

class FieldSelector extends Component {
  state = {
    data: [],
    fieldMap: null,
    isCalculatingNumberOfLines: true,
    saveMapping: true,
    sshKeys: [],
  }

  componentDidMount() {
    const {
      data: {batchType},
    } = this.props

    // We don't want to default the save mappings checkbox to true if we have
    // evidence that they're uploading via SFTP (i.e. if they have ssh keys)
    fetchSshKeys()
      .then(sshKeys => {
        this.setState({sshKeys, saveMapping: sshKeys.length === 0})
      })
      .catch(() => {})
    countNumLines(
      this.props.data?.file,
      this.onGatherFirstLinesFor(batchType),
      this.onParsingAbort
    ).then(numLines => {
      this.setState({isCalculatingNumberOfLines: false, totalNumLines: numLines})
    })
  }

  onGatherFirstLinesFor = batchType => ({columns, previewData}) => {
    const {objectives} = this.props
    const csvFieldMap = this.getCsvFieldMap(batchType)

    this.setState({
      data: previewData,
      fieldMap: matchColumns(
        columns,
        {
          fieldMap: rehydrateFieldMap(csvFieldMap || {}),
          objectives,
        },
        batchType
      ),
    })
  }

  onParsingAbort = () => {
    this.setState({didAbortParsing: true})
  }

  onChangeMetaSubkey = ({currentTarget, target: {value}}) => {
    this.setState(({fieldMap}) => ({
      fieldMap: fieldMap.map(field => {
        if (currentTarget.dataset.csvField === field.csv_field)
          return {...field, meta_sub_key: value}

        return field
      }),
    }))
  }

  // NB: As of MUI v5, `currentTarget` is no longer supported for Select/MenuItem, so we now access
  // custom attributes `data-*` via `child.props`.
  //
  // See:
  // - https://stackoverflow.com/q/70176871
  // - https://github.com/mui/material-ui/issues/5085
  // - https://stackoverflow.com/a/71858559
  onChangeMapping = ({target: {value}}, child) => {
    const csvField = child.props["data-csv-field"]

    this.setState(({fieldMap}) => ({
      fieldMap: fieldMap.map(field => {
        if (
          field.digital_onboarding_field === value &&
          (!field.digital_onboarding_field.match(/^meta_(public|private)/) ||
            !value.match(/^meta_(public|private)/))
        )
          return {...field, digital_onboarding_field: "ignored"}

        if (csvField === field.csv_field)
          return {
            ...field,
            digital_onboarding_field: value,
          }

        return field
      }),
    }))
  }

  onChangeSaveMapping = ({target: {checked: saveMapping}}) => this.setState({saveMapping})

  onContinue = () => {
    const {
      data: {batchType, file},
      onContinue,
      updateTeam,
    } = this.props
    const {saveMapping, fieldMap, totalNumLines} = this.state
    const csvFieldMap = this.getCsvFieldMap(batchType)

    if (saveMapping) {
      const key = this.getApiFieldMapKey(batchType)

      updateTeam({
        [key]: {...csvFieldMap, ...compileFieldMap(fieldMap)},
      })
    }

    onContinue({
      batchType,
      file,
      totalNumLines,
      fieldMap: {
        ...csvFieldMap,
        ...compileFieldMap(fieldMap),
      },
    })
  }

  getCsvFieldMap = batchType => {
    if (batchType === "account") return this.props.accountCsvFieldMap
    else return this.props.contactCsvFieldMap
  }

  getApiFieldMapKey = batchType => {
    if (batchType === "account") return "account_field_map"
    else return "contact_field_map"
  }

  render() {
    const {
      fieldMap,
      data,
      isLoading,
      isUploadComplete,
      saveMapping,
      isCalculatingNumberOfLines,
      sshKeys,
      totalNumLines,
      didAbortParsing,
    } = this.state
    const {
      classes,
      data: {batchType},
      objectives,
      onReset,
    } = this.props

    const hasBadMetaMapping = !!(
      fieldMap &&
      fieldMap.find(
        field =>
          field.digital_onboarding_field.match(/^meta_(private|public)/) && !field.meta_sub_key
      )
    )

    const getFieldMenuItemsFor = type => {
      switch (type) {
        case "account":
          return allAccountFields
        case "contact":
          return allContactFields
        case "journey":
          // Shim to enable journey association with account number.
          return [...journeyAccountFields, ...allContactFields]
        default:
          return []
      }
    }

    if (didAbortParsing)
      return (
        <div className={cx(classes.main, classes.error)}>
          <FailedIcon className={classes.icon} size={60} />
          <Typography className={classes.failureText} variant="h2">
            Oops!
          </Typography>
          <Typography variant="h4">
            We ran into a problem when uploading your CSV. Please double check it and try again.
          </Typography>
          <div className={classes.actions}>
            <Button
              className={classes.button}
              color="primary"
              onClick={onReset}
              size="large"
              variant="contained"
            >
              Start over
            </Button>
          </div>
        </div>
      )

    if (isCalculatingNumberOfLines)
      return (
        <div className={cx(classes.main, classes.loading)}>
          <Typography>Generating preview..</Typography>
          <Typography variant="caption">Please wait</Typography>
          <LinearProgress />
        </div>
      )

    return (
      <div className={classes.main}>
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                {fieldMap?.map((field, index) => (
                  <TableCell className={classes.headerCell} key={index} valign="top">
                    <Typography color="inherit">{field.csv_field}</Typography>
                    <Typography color="inherit">↓</Typography>
                    <DOSelect
                      classes={{
                        select: classes.select,
                        icon: classes.select,
                      }}
                      input={<Input classes={{underline: classes.selectUnderline}} />}
                      onChange={this.onChangeMapping}
                      value={field.digital_onboarding_field}
                    >
                      <ListSubheader className={classes.listSubheader}>
                        Standard Fields
                      </ListSubheader>
                      {getFieldMenuItemsFor(batchType).map(({name, value}) => (
                        <MenuItem data-csv-field={field.csv_field} key={value} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                      <Divider />
                      <ListSubheader className={classes.listSubheader}>Objectives</ListSubheader>
                      {objectives.map(({name, key}) => (
                        <MenuItem data-csv-field={field.csv_field} key={key} value={key}>
                          {name}
                        </MenuItem>
                      ))}
                    </DOSelect>
                    {field.digital_onboarding_field.match(/^meta_(private|public)/) && (
                      <div>
                        <TextField
                          FormHelperTextProps={{classes: {root: classes.metaSubKeyHelperText}}}
                          helperText={!field.meta_sub_key && "subkey required"}
                          InputProps={{
                            classes: {
                              root: classes.textFieldInput,
                            },
                          }}
                          inputProps={{"data-csv-field": field.csv_field}} //eslint-disable-line react/jsx-no-duplicate-props
                          onChange={this.onChangeMetaSubkey}
                          required={true}
                          value={field.meta_sub_key}
                        />
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  {fieldMap.map(({csv_field, digital_onboarding_field}, fieldIndex) => (
                    <TableCell key={fieldIndex}>
                      <Typography
                        className={cx({[classes.ignored]: digital_onboarding_field === "ignored"})}
                      >
                        {row[csv_field]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className={classes.previewText}>
          {isCalculatingNumberOfLines ? (
            <>
              <CircularProgress size={18} />
              <Typography>Calculating number of lines..</Typography>
            </>
          ) : (
            <Typography>
              Previewing {data.length} of {totalNumLines} {pluralize("row", data.length)}
            </Typography>
          )}
        </div>
        <div className={classes.saveSettings}>
          <FormControlLabel
            control={
              <Checkbox checked={saveMapping} color="primary" onChange={this.onChangeSaveMapping} />
            }
            label="Save CSV column mappings as defaults for next time."
          />
          {sshKeys.length > 0 && (
            <Typography component="div" variant="caption">
              Note: these mappings are shared with SFTP uploads.
            </Typography>
          )}
        </div>
        <div className={classes.actions}>
          <Button color="grey" onClick={this.props.onReset} variant="contained">
            Start Over
          </Button>
          <div className={classes.buttonWrapper}>
            <MaybeTooltip
              isTooltip={hasBadMetaMapping}
              title="You have one or more mappings to a metadata field without a subkey."
            >
              <Button
                color="primary"
                disabled={isLoading || isUploadComplete || hasBadMetaMapping}
                onClick={this.onContinue}
                type="button"
                variant="contained"
              >
                Continue
              </Button>
            </MaybeTooltip>
            {isLoading && <CircularProgress className={classes.uploadProgress} size={24} />}
          </div>
        </div>
      </div>
    )
  }
}

FieldSelector.propTypes = {
  classes: object,
  accountCsvFieldMap: object,
  contactCsvFieldMap: object,
  data: object,
  objectives: arrayOf(
    shape({
      name: string,
      key: string,
    })
  ),
  onContinue: func.isRequired,
  onReset: func.isRequired,
  updateTeam: func.isRequired,
}

const styles = theme => ({
  main: {
    backgroundColor: theme.palette.common.white,
    minHeight: 370,
    display: "flex",
    flexDirection: "column",
  },
  error: {
    flex: 1,
    alignItems: "center",
    textAlign: "center",
    "& h4": {
      marginTop: theme.spacing(2),
    },
  },
  loading: {
    justifyContent: "center",
    textAlign: "center",
    padding: `0px ${theme.spacing(4)}`,
    "& p": {
      fontSize: 24,
      marginBottom: theme.spacing(3),
    },
  },
  headerCell: {
    verticalAlign: "top",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  metaSubKeyHelperText: {
    fontStyle: "italic",
    color: theme.palette.common.white,
  },
  select: {
    color: theme.palette.common.white,
  },
  selectUnderline: {
    "&:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "&:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "&:active:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.7) !important",
    },
    "&:hover:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.7) !important",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        borderBottomColor: "rgba(255, 255, 255, 0.7)",
      },
    },
  },
  textFieldInput: {
    color: "white",
    "&:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "&:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "&:active:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
    "&:hover:before": {
      borderBottomColor: "rgba(255, 255, 255, 0.7) !important",
    },
    "&:hover:after": {
      borderBottomColor: "rgba(255, 255, 255, 0.7)",
    },
  },
  tableWrapper: {
    flex: 1,
    overflowX: "scroll",
  },
  previewText: {
    display: "flex",
    padding: theme.spacing(1),
    "& div": {
      marginRight: theme.spacing(1),
    },
  },
  saveSettings: {
    padding: theme.spacing(1),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  ignored: {
    color: alpha(theme.palette.text.disabled, 0.2),
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  listSubheader: {
    backgroundColor: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.primary.main,
    flex: 1,
  },
  failureText: {
    fontSize: "2em",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  button: {
    margin: `${theme.spacing(1)} auto`,
  },
})

const mapStateToProps = ({
  session: {
    team: {account_field_map: accountCsvFieldMap, contact_field_map: contactCsvFieldMap},
  },
}) => ({
  accountCsvFieldMap,
  contactCsvFieldMap,
})

const mapDispatchToProps = {
  updateTeam: updateTeamSettings,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FieldSelector))
