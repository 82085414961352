import {Badge, Button, Drawer, Tab, Tabs} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import pick from "lodash/pick"
import {arrayOf, bool, func, object, shape, string} from "prop-types"
import {Component} from "react"
import {FaExclamation} from "react-icons/fa"
import {MdAdd} from "react-icons/md"

import {DeviceControlsSidebar} from "components/content-block-editor/device-controls"

import {featurify} from "lib/hooks/use-features"

import {templateContentContext} from "../../contexts/template-content-context"
import TemplatePageContext from "../../contexts/template-page-context"
import {
  EditorModes,
  contentBlockEditorConsumer,
} from "../content-block-editor/content-block-editor-context"
import ConfirmDialog from "../dialogs/confirm-dialog"
import Feature from "../feature/feature"
import NewPageForm from "./new-page-form"
import NextPageSettings from "./next-page-settings"
import PageSettings from "./page-settings-LEGACY"
import PreviewContext from "./preview-context"
import {drawerWidth} from "./template-helpers"
import TemplateSettings from "./template-settings"
import WidgetSettings from "./widget-settings"

class TemplateSidebar extends Component {
  state = {tabIndex: 1, isUnsavedContentBlockConfirmDialogOpen: false, pendingTabIndex: null}

  componentDidUpdate(prevProps) {
    if (!prevProps.selectedBlock && this.props.selectedBlock) this.forceWidgetTabSelection()
    if (prevProps.selectedBlock && !this.props.selectedBlock) this.unforceWidgetTabSelection()
  }

  forceWidgetTabSelection = () => {
    this.previousTabIndex = this.state.tabIndex
    this.onTabChange(null, 0)
  }

  unforceWidgetTabSelection = () => {
    if (this.state.tabIndex === 0) this.onTabChange(null, this.previousTabIndex)
  }

  onTabChange = (e, tabIndex) => {
    const areEditsInSidebar = this.props.selectedBlock?.type !== "text"

    if (!(areEditsInSidebar && this.props.hasUnsavedContentBlockDataChanges()))
      this.setState({tabIndex})
    else this.setState({pendingTabIndex: tabIndex, isUnsavedContentBlockConfirmDialogOpen: true})
  }

  onCancelCloseWidgetForm = () =>
    this.setState({
      pendingTabIndex: null,
      isUnsavedContentBlockConfirmDialogOpen: false,
    })

  onConfirmCloseWidgetForm = () =>
    this.setState({
      tabIndex: this.state.pendingTabIndex,
      pendingTabIndex: null,
      isUnsavedContentBlockConfirmDialogOpen: false,
    })

  onDeletePage = () => this.props.onDeletePage(this.props.page.id)

  onUpdatePage = attrs => this.props.onUpdatePage(this.props.page.id, attrs)

  onUpdateNextPage = attrs => this.props.onUpdatePage(this.props.nextPage.id, attrs)

  onUpdateContentVariables = content_variables =>
    this.props.onUpdateTemplatePage(this.props.template.id, this.props.templatePage.id, {
      content_variables,
    })

  onMakePageUnique = () => this.props.onMakePageUnique(this.props.templatePage)

  onAddPage = () => {
    if (this.props.hasFeature("atomic-assets")) this.openNewPageDialog()
    else
      this.props.onCreatePage({
        id: null,
        navigationTitle: "New Page",
        contentName: `New Page ${this.props.template.name}`,
      })
  }

  onSetPageObjective = objective =>
    this.props.onUpdatePage(this.props.page.id, {objectiveId: objective.id})

  closeNewPageDialog = () => this.setState({addingPage: null})
  openNewPageDialog = () => this.setState({addingPage: true})

  setScrollableContainerRef = scrollableContainerRef => this.setState({scrollableContainerRef})

  render() {
    const {
      classes,
      nextPage,
      onCreatePage,
      onUpdateTemplate,
      hasNurturingMessagesAndNoTimelineMarkers,
      isPreviewMode,
      selectedBlock,
      page,
      setMode,
      template,
      templateMessages,
      templatePage,
    } = this.props
    const {tabIndex, scrollableContainerRef, isUnsavedContentBlockConfirmDialogOpen} = this.state
    const currentPageTemplateMessages = templateMessages.filter(
      tm => tm.templatePage?.pageId === page?.id
    )
    const currentPageHasMismatchedObjectives = currentPageTemplateMessages.some(
      tm => tm.mismatchesPageObjective
    )
    const currentPageIsMissingPageSlugs = currentPageTemplateMessages.some(
      tm => tm.isMissingPageSlugs
    )

    const toggleContentBlockEditorMode = () => {
      setMode(isPreviewMode ? EditorModes.Edit : EditorModes.Preview)
    }
    return (
      <PreviewContext.Consumer>
        {() => (
          <Drawer
            anchor="right"
            classes={{
              docked: isPreviewMode ? classes.drawerClosed : classes.drawerOpen,
              paper: classes.drawerPaper,
            }}
            className={classes.drawer}
            open={!isPreviewMode}
            variant="persistent"
            id="template-sidebar-drawer" // This id is used to hide the sidebar when generating pdf approval documents
          >
            <DeviceControlsSidebar layoutBasis={page?.layoutBasis} />
            <Button
              className={classes.sidbarButton}
              color="primary"
              fullWidth={true}
              onClick={toggleContentBlockEditorMode}
              size="small"
              variant="contained"
            >
              Preview
            </Button>
            <Tabs
              indicatorColor="primary"
              textColor="inherit"
              onChange={this.onTabChange}
              value={tabIndex}
              variant="fullWidth"
            >
              <Tab
                className={cx(classes.tab, {[classes.hidden]: !selectedBlock})}
                data-testid="content-block-tab"
                label={selectedBlock?.type || "Widget"}
              />
              <Tab
                className={classes.tab}
                data-testid="page-tab"
                label={
                  <Badge
                    badgeContent={<FaExclamation size={10} />}
                    className={classes.tabBadge}
                    color="error"
                    invisible={
                      !currentPageHasMismatchedObjectives && !currentPageIsMissingPageSlugs
                    }
                  >
                    Page
                  </Badge>
                }
              />
              <Tab
                className={classes.tab}
                data-testid="template-tab"
                label={
                  <Badge
                    badgeContent={<FaExclamation size={10} />}
                    className={classes.tabBadge}
                    color="error"
                    invisible={!hasNurturingMessagesAndNoTimelineMarkers}
                  >
                    Template
                  </Badge>
                }
              />
            </Tabs>
            <div className={classes.tabContainer} ref={this.setScrollableContainerRef}>
              <TemplatePageContext.Provider
                value={{
                  setContentVariables: this.onUpdateContentVariables,
                  content_variables: templatePage?.content_variables,
                }}
              >
                {selectedBlock && tabIndex === 0 && <WidgetSettings classes={classes} />}
              </TemplatePageContext.Provider>
              {page && tabIndex === 1 && (
                <div>
                  <PageSettings
                    classes={classes}
                    editingTemplateId={template.id}
                    hasMismatchedObjective={currentPageHasMismatchedObjectives}
                    initialValues={pick(page, [
                      "id",
                      "contentName",
                      "navigationTitle",
                      "layoutBasis",
                      "completionCondition",
                      "excludeFromCce",
                      "hideWhenComplete",
                      "benchmark",
                      "objectiveId",
                      "completionProductId",
                      "completionProductType",
                    ])}
                    isMissingPageSlugs={currentPageIsMissingPageSlugs}
                    nextPageChildren={
                      nextPage && (
                        <NextPageSettings
                          classes={classes}
                          initialValues={{cta: nextPage.cta}}
                          nextPage={nextPage}
                          onSubmit={this.onUpdateNextPage}
                        />
                      )
                    }
                    onDeletePage={this.onDeletePage}
                    onDuplicatePage={this.onMakePageUnique}
                    onSubmit={this.onUpdatePage}
                    page={page}
                    scrollableContainer={scrollableContainerRef}
                    template={template}
                    team={this.props.team}
                  />
                </div>
              )}
              {tabIndex === 2 && (
                <div className={classes.settingsContainer}>
                  <TemplateSettings
                    classes={pick(classes, [
                      "expansionPanel",
                      "expansionContent",
                      "field",
                      "helperText",
                      "select",
                      "menuItem",
                      "listItemTextSecondary",
                      "customCssButton",
                    ])}
                    initialValues={pick(template, [
                      "name",
                      "reward",
                      "layoutBasis",
                      "hideProgressRing",
                      "journeyDurationHours",
                      "isAuthRequired",
                      "targetingAccountPriority",
                    ])}
                    onSubmit={onUpdateTemplate}
                    template={template}
                  />
                </div>
              )}
            </div>
            <Button
              className={classes.sidbarButton}
              color="grey"
              fullWidth={true}
              onClick={this.onAddPage}
              size="small"
              variant="contained"
            >
              <MdAdd className={classes.icon} /> Add a New Page
            </Button>
            {this.state.addingPage && (
              <Feature featureKey="atomic-assets">
                <NewPageForm
                  onClose={this.closeNewPageDialog}
                  onSubmit={onCreatePage}
                  template={template}
                />
              </Feature>
            )}
            {isUnsavedContentBlockConfirmDialogOpen && (
              <ConfirmDialog
                cancelText="Cancel"
                continueText="Abandon Changes"
                content="You have unsaved changes for this widget."
                onClose={this.onCancelCloseWidgetForm}
                onConfirm={this.onConfirmCloseWidgetForm}
                open={true}
                title="Abandon changes?"
              />
            )}
          </Drawer>
        )}
      </PreviewContext.Consumer>
    )
  }
}

const styles = theme => ({
  hidden: {
    display: "none",
  },
  tab: {
    minWidth: 100,
  },
  icon: {
    marginRight: 5,
  },
  drawer: {
    transition: theme.transitions.create("all"),
  },
  drawerOpen: {
    flexBasis: drawerWidth,
  },
  drawerClosed: {
    flexBasis: 0,
  },
  drawerPaper: {
    backgroundColor: "rgb(234,236,240)",
    boxShadow: "inset 0 0 10px rgb(208, 212, 220, 0.8)",
    borderLeft: "white",
    width: drawerWidth,
    marginTop: 88,
    paddingBottom: 88,
  },
  menuItem: {
    maxWidth: `calc(${drawerWidth}px - ${theme.spacing(3)})`, //width minus the padding of 1.5 * spacing on each side below
    whiteSpace: "normal",
  },
  listItemTextSecondary: {
    color: "#7a7b7f",
  },
  settingsContainer: {
    padding: theme.spacing(3, 1.5),
  },
  tabContainer: {
    overflow: "auto",
    flex: 1,
  },
  tabBadge: {
    padding: theme.spacing(0, 1),
  },
  title: {
    marginTop: 20,
    marginBottom: 10,
    textTransform: "uppercase",
  },
  field: {
    margin: "10px 0",
  },
  listSubheader: {
    backgroundColor: theme.palette.common.white,
  },
  helperText: {
    marginBottom: 10,
  },
  expansionPanel: {
    backgroundColor: "transparent",
    margin: 0,
  },
  customCssButton: {
    margin: `20px -2px 0 -2px`,
  },
  expansionContent: {
    padding: 0,
  },
  sidbarButton: {
    borderRadius: 0,
  },
  supportOwnerSwitchLabel: {
    fontSize: 15,
  },
  switchTooltipIconWrapper: {
    verticalAlign: "middle",
  },
  switchTooltipIcon: {
    fontSize: "1rem",
    position: "relative",
    top: 2,
    color: "rgba(0,0,0,0.54)",
    left: 10,
  },
})

TemplateSidebar.propTypes = {
  classes: object.isRequired,
  hasFeature: func.isRequired,
  hasNurturingMessagesAndNoTimelineMarkers: bool,
  hasUnsavedContentBlockDataChanges: func.isRequired,
  isPreviewMode: bool,
  nextPage: object,
  onCreatePage: func.isRequired,
  onDeletePage: func.isRequired,
  onMakePageUnique: func.isRequired,
  onUpdatePage: func.isRequired,
  onUpdateTemplate: func.isRequired,
  onUpdateTemplatePage: func.isRequired,
  page: object,
  selectedBlock: object,
  setMode: func,
  template: object.isRequired,
  templateMessages: arrayOf(
    shape({
      templatePage: shape({
        pageId: string,
      }),
      mismatchesPageObjective: bool,
      isMissingPageSlugs: bool,
    })
  ),
  templatePage: object,
  team: object,
}

const StyledTemplateSidebar = withStyles(styles)(templateContentContext(TemplateSidebar))

export default contentBlockEditorConsumer(featurify(StyledTemplateSidebar))
