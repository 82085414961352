import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import _ from "lodash"
import {array, arrayOf, bool, func, number, object, shape, string} from "prop-types"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {journeyContext} from "components/journeys/journey-context"

import {templatePageContext} from "contexts/template-page-context"

import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import AccountRowReadonly from "./account-row-readonly"
import AccountRowRedacted from "./account-row-redacted"
import {COLUMN_OPTIONS} from "./config"

const headerStyles = (theme, overrides) => {
  const mergedTheme = _.merge({}, theme, overrides)

  return {
    backgroundColor:
      mergedTheme.custom?.accountsTable?.header?.backgroundColor ??
      mergedTheme.palette.primary.main,
    color: mergedTheme.custom?.accountsTable?.header?.fontColor ?? "white",
    fontSize: mergedTheme.custom?.accountsTable?.header?.fontSize,
    fontFamily: mergedTheme.custom?.accountsTable?.fontFamily ?? mergedTheme.typography.fontFamily,
    fontWeight: "bold",
    padding: mergedTheme.spacing(1),
  }
}

const stackedStyles = theme => ({
  "& .MuiTableCell-root": {
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
  },
  "& thead": {
    clip: "rect(0 0 0 0)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0",
    position: "absolute",
    width: "1px",
  },
  "& td": {
    display: "block",
  },
  "& td:before": {
    color: theme.palette.primary.main,
    content: "attr(data-label)",
    display: "block",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  "& td:first-of-type": {
    backgroundColor: theme.palette.lightestGray,
  },
  "tr:nth-of-type(odd)": {
    background: "#f3f3f3",
  },
})

const horizontalStyles = theme => ({
  marginTop: theme.spacing(0.25),
  "& th , & td": {
    border: "none",
  },
})

// aka "Column Header" styles (as named in the branding UI)
const headerRowStyles = (theme, overrides, showPlaceholder) => {
  const mergedTheme = _.merge({}, theme, overrides)

  const readonlyStyles = {
    "& .MuiTableCell-head": {
      backgroundColor:
        mergedTheme.custom?.accountsTable?.columnHeader?.backgroundColor ?? "#FFFFFF",
      color:
        mergedTheme.custom?.accountsTable?.columnHeader?.fontColor ??
        mergedTheme.palette.primary.main,
      fontFamily:
        mergedTheme.custom?.accountsTable?.fontFamily ?? mergedTheme.typography.fontFamily,
      fontSize: mergedTheme.custom?.accountsTable?.columnHeader?.fontSize ?? "17px",
      fontWeight: "bold",
      padding: mergedTheme.spacing(1),
      textTransform: "uppercase",
    },
  }

  const editableStyles = {
    border: "1px dashed rgb(0, 0, 0, 0.38)",
    marginTop: mergedTheme.spacing(1),
    "& .MuiTableCell-root": {
      borderBottom: "1px dashed rgb(0, 0, 0, 0.38)",
    },
  }

  if (showPlaceholder) {
    return {...readonlyStyles, ...editableStyles}
  } else {
    return readonlyStyles
  }
}

const AccountsTableReadonly = props => {
  const {
    accounts,
    className,
    contact,
    contentBlock,
    contentBlock: {data},
    customCss,
    device,
    isEditMode,
    isLoggedIn,
    isPreviewMode,
    onClick,
    openLogin,
    style,
  } = props
  // Null guard--when content block is added, columns key does not exist
  const columns = data.columns ?? []
  const brandingOverrides = data.brandingSettings ?? {}

  // Responsive view switches
  const isMobileWidth = useMediaQuery(useTheme().breakpoints.down("md"))
  const isMobilePreview = isPreviewMode && !!device?.width && device?.width < 768

  // Private content switches
  const privateContentIds = COLUMN_OPTIONS.reduce((acc, opt) => {
    return opt.isPrivate ? [...acc, opt.value] : acc
  }, [])
  const hasPrivateField = !!columns.find(
    col => !!col.contentId.match(/^meta_private\./) || privateContentIds.includes(col.contentId)
  )

  // View switches for editor/journey views and private/public properties
  const showPlaceholder = isEditMode || isPreviewMode
  const shouldRedactFields = !isLoggedIn && hasPrivateField
  const showAccounts = !showPlaceholder && !shouldRedactFields

  // Layout switches for stacked/horizontal layout
  const isStacked = isMobileWidth || isMobilePreview

  return (
    <div className={className} onClick={onClick} style={style}>
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <Typography variant="subtitle1" sx={theme => headerStyles(theme, brandingOverrides)}>
        {data.tableHeader}
      </Typography>
      <Table sx={theme => (isStacked ? stackedStyles(theme) : horizontalStyles(theme))}>
        {
          <TableHead>
            <TableRow sx={theme => headerRowStyles(theme, brandingOverrides, showPlaceholder)}>
              {columns.map(({header, index}) => (
                <TableCell key={`${index}-${header}`}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        <TableBody>
          {!showPlaceholder && shouldRedactFields && (
            <AccountRowRedacted columnsCount={columns.length} openLogin={openLogin} />
          )}
          {showAccounts &&
            accounts.map((account, index) => (
              <AccountRowReadonly
                account={account}
                columns={columns}
                contact={contact}
                isStacked={isStacked}
                key={`${index}-${account.nickname}`}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  )
}
AccountsTableReadonly.defaultProps = {isLoggedIn: false}

AccountsTableReadonly.propTypes = {
  accounts: arrayOf(shape({id: string})),
  contact: object,
  className: string,
  contentBlock: shape({data: shape({columns: array, tableHeader: string})}).isRequired,
  customCss: string,
  device: shape({width: number}),
  isEditMode: bool,
  isLoggedIn: bool.isRequired,
  isPreviewMode: bool,
  onClick: func,
  openLogin: func,
  style: object,
  template: object,
}

export default contentBlockEditorConsumer(
  journeyContext(templatePageContext(AccountsTableReadonly))
)
