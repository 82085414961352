import {omit} from "lodash"
import {useContext, useMemo, useState} from "react"
import {useHistory, useParams} from "react-router-dom"

import {deleteRewardSet, updateRewardSet} from "lib/api"

import RewardSetFooter from "./reward-set-footer"
import RewardSetForm from "./reward-set-form"
import RewardSetLayout from "./reward-set-layout"
import {stepComponents} from "./reward-step-conditions/step-components"
import RewardStepList from "./reward-step-list"
import {RewardsContext} from "./rewards-context-provider"

const ExistingRewardSet = () => {
  const history = useHistory()
  const {rewardSetId} = useParams()
  const {dispatch, rewardSets} = useContext(RewardsContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  // Used to cause a force remount on RewardStepList after save
  const [rewardStepListKey, setRewardStepListKey] = useState(1)

  // Initialize Formify for existing reward set and steps
  const rewardSet = useMemo(() => {
    return rewardSets.length ? rewardSets.find(({id}) => id === rewardSetId) || null : null
  }, [rewardSetId, rewardSets])
  const rewardStepConfigList = useMemo(() => {
    return rewardSet
      ? rewardSet.rewardSteps.map(rewardStep => ({
          ...stepComponents.find(
            ({initialValues: {condition}}) => condition === rewardStep.condition
          ),
          initialValues: rewardStep,
        }))
      : []
  }, [rewardSet])

  const handleToggleStatus = () => {
    const status = rewardSet.status === "draft" ? "live" : "archived"
    const updatedRewardSet = {...omit(rewardSet, ["rewardSteps"]), status}

    updateRewardSet(updatedRewardSet, rewardSet.id).then(updated => {
      // When reward steps are omitted, the response only includes the reward set data
      dispatch({
        type: "UPDATE_REWARD_SET",
        rewardSet: {...updated, rewardSteps: rewardSet.rewardSteps},
      })
      // Remount the form to source updated values
      setRewardStepListKey(rewardStepListKey + 1)
    })
  }

  // Can't explain why, but pulling in the RewardSetFormContext here causes
  // a render loop in RewardSetForm. So, RewardSetFooter instead reads
  // the context on save and passes it up here to make the API POST.
  const handleSave = formData =>
    new Promise(resolve => {
      // Remove new (placeholder) id from reward steps so API endpoint knows to create
      const params = {
        ...omit(formData, ["rewardSteps"]),
        rewardSteps: formData.rewardSteps.map(rewardStep => {
          // Matches UUID and not shortid, which is used to stub in id for new steps
          const match = rewardStep.id.match(
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
          )

          return match ? rewardStep : omit(rewardStep, ["id"])
        }),
      }
      setIsSubmitting(true)
      updateRewardSet(params, rewardSet.id).then(rewardSet => {
        setIsSubmitting(false)
        dispatch({type: "UPDATE_REWARD_SET", rewardSet})
        // Remount the form to source updated values
        setRewardStepListKey(rewardStepListKey + 1)

        return resolve()
      })
    })

  const handleDelete = () =>
    deleteRewardSet(rewardSetId).then(() => {
      dispatch({type: "DELETE_REWARD_SET", rewardSetId})
      history.push("/admin/rewards/reward-sets/")
    })

  return (
    rewardSet && (
      <RewardSetLayout
        footer={
          <RewardSetFooter
            isSubmitting={isSubmitting}
            onSave={handleSave}
            status={rewardSet.status}
          />
        }
        rewardSetForm={
          <RewardSetForm
            key={rewardStepListKey}
            initialValues={rewardSet}
            isEditable={rewardSet.status === "draft"}
            onDelete={handleDelete}
            onToggleStatus={handleToggleStatus}
          />
        }
        rewardStepList={
          <RewardStepList
            key={rewardStepListKey}
            rewardStepConfigList={rewardStepConfigList}
            isEditable={rewardSet.status === "draft"}
          />
        }
      />
    )
  )
}

export default ExistingRewardSet
