import {isAfter, parseISO} from "date-fns"
import get from "lodash/get"
import uniq from "lodash/uniq"

export const updateFromServer = (localAnalyticsCache, serverAnalyticsCache) => {
  // we optimistically update the local analytics cache
  // but we also get an analytics cache when we request a journey/template/etc
  // from a server.
  // as such, we have to try to figure out which data needs to be prioritized in
  // the analytics cache
  if (!serverAnalyticsCache) return localAnalyticsCache

  if (!localAnalyticsCache) return serverAnalyticsCache

  // hard to tell on this one, so just to be safe let's prioritize the server
  if (!serverAnalyticsCache.lastViewed && !localAnalyticsCache.lastViewed)
    return {
      ...localAnalyticsCache,
      ...serverAnalyticsCache,
    }

  // local data is newer
  if (!serverAnalyticsCache.lastViewed && localAnalyticsCache.lastViewed)
    return {
      ...serverAnalyticsCache,
      ...localAnalyticsCache,
    }

  // server data is newer
  if (!localAnalyticsCache.lastViewed && serverAnalyticsCache.lastViewed)
    return {
      ...localAnalyticsCache,
      ...serverAnalyticsCache,
    }

  const localLastViewed = parseISO(localAnalyticsCache.lastViewed)
  const serverLastViewed = parseISO(serverAnalyticsCache.lastViewed)

  // We assume that if the server's lastViewed is more recent, that the server has more up to date information
  // In that instance, we prioritize server data over local data
  return isAfter(serverLastViewed, localLastViewed)
    ? {...localAnalyticsCache, ...serverAnalyticsCache}
    : {...serverAnalyticsCache, ...localAnalyticsCache}
}

export default (analyticsCache, {name, pageId, pages}) => {
  let isCompletelyViewed = get(analyticsCache, "isCompletelyViewed", false)
  let isCompleted = get(analyticsCache, "isCompleted", false)
  let pagesCompleted = get(analyticsCache, "pagesCompleted", [])
  let pagesViewed = get(analyticsCache, "pagesViewed", [])
  const lastViewed = get(analyticsCache, "lastViewed", new Date().toISOString())

  if (!pages || !pageId)
    return {isCompletelyViewed, isCompleted, pagesCompleted, pagesViewed, lastViewed}

  const pagesWithCompletionCondition = pages.filter(page => page.completionCondition)

  switch (name) {
    case "page_viewed":
      pagesViewed = uniq(pagesViewed.concat([pageId]))
      if (completesPage(pagesWithCompletionCondition, pageId, "view"))
        pagesCompleted = uniq(pagesCompleted.concat([pageId]))
      break
    case "card_on_file_completed":
    case "direct_deposit_completed":
    case "survey_completed":
    case "cta_clicked":
    case "journey_signup_form_submitted":
    case "enrollment_accepted":
    case "enrollment_declined":
      if (completesPage(pagesWithCompletionCondition, pageId, "action"))
        pagesCompleted = uniq(pagesCompleted.concat([pageId]))
      break
    case "page_confirmed":
      if (completesPage(pagesWithCompletionCondition, pageId, "confirm"))
        pagesCompleted = uniq(pagesCompleted.concat([pageId]))
      break
    default:
  }

  if (pagesViewed.length === pages.length) isCompletelyViewed = true

  if (pagesCompleted.length === pagesWithCompletionCondition.length) isCompleted = true

  return {isCompletelyViewed, isCompleted, pagesCompleted, pagesViewed, lastViewed}
}

const completesPage = (pages, pageId, condition) =>
  pages.find(p => p.id === pageId && p.completionCondition === condition)
