import {TEMPLATE_LIST_SET} from "./templates-actions"

const defaultState = {
  list: [],
}

export default function templates(state = defaultState, action) {
  switch (action.type) {
    case TEMPLATE_LIST_SET:
      return {
        ...state,
        list: action.payload,
      }

    default:
      return state
  }
}
