import {
  Badge,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {orderBy, pick, reject} from "lodash"
import {array, arrayOf, bool, func, number, object, oneOfType, shape, string} from "prop-types"
import {useEffect, useMemo, useRef, useState} from "react"
import {useDropzone} from "react-dropzone"
import {MdCloudUpload} from "react-icons/md"
import AutoSizer from "react-virtualized-auto-sizer"
import {FixedSizeGrid as Grid} from "react-window"

import {createFile, deleteFile, fetchFiles, updateFile} from "lib/api"
import loadImage from "lib/image/load-image"
import uploadFileWithPolicy from "lib/upload-file-with-policy"

import mainTheme from "../../themes/main"
import DOSelect from "../do-select/do-select"
import MuiIcon from "../mui-icon"
import FileBrowserFile from "./file-browser-file"

const caseInsensitiveSort = key => record => record[key].toLowerCase()

const readImageDimensions = file =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve({naturalWidth: img.width, naturalHeight: img.height})
    img.onerror = reject
    // Create a temporary URL for the file to be used as the image source
    img.src = URL.createObjectURL(file)
  })

const uploadFiles = async (browserFiles, flavor) => {
  let fileRecords = []

  for (const browserFile of browserFiles) {
    const fileRecord = await uploadFile(browserFile, flavor)
    fileRecords.push(fileRecord)
  }

  return fileRecords
}

const uploadFile = async (browserFile, flavor) => {
  const fileRecord = await createFile({...pick(browserFile, ["name", "type", "size"]), flavor})

  let extraAttrs = {
    ...fileRecord.upload_policy.attrs,
    "x-amz-meta-gondor-file-id": fileRecord.id,
    "x-amz-meta-original-file-name": fileRecord.name,
  }

  await uploadFileWithPolicy(browserFile, fileRecord.upload_policy.policy, {extraAttrs})
  const meta = browserFile.type.startsWith("image/") ? await readImageDimensions(browserFile) : {}
  return await updateFile(fileRecord.id, {isUploaded: true, meta: meta})
}

const hasImage = types =>
  (Array.isArray(types) && types.filter(type => type.includes("image")).length > 0) ||
  (types && types.includes("image"))

const getMimeTypeFromFileExtension = ext => {
  switch (ext) {
    case "gif":
      return "image/gif"
    case "jpg":
    case "jpeg":
    case "jfif":
    case "pjpeg":
    case "pjp":
      return "image/jpeg"
    case "png":
      return "image/png"
    case "pdf":
      return "application/pdf"
    default:
      return "unknown"
  }
}

const tableColumns = 4
const gutterSize = 4
const rowHeight = 200

// I really don't want to do propType declarations for this react-window coupled "component"...
// eslint-disable-next-line react/prop-types
const Cell = ({data, columnIndex, rowIndex, style}) => {
  const {files, onDelete, onSelect, onUpdate, flavor} = data
  const i = rowIndex * tableColumns + columnIndex
  const file = files[i]

  // A strange artifact of windowing is this is rendered even without a file list...
  // Might be an intermediary step where the files list has updated but the indexes haven't.
  if (!file) return null

  return (
    <FileBrowserFile
      file={file}
      i={i}
      index={i}
      key={file.id}
      onDelete={onDelete}
      onSelect={onSelect}
      onUpdate={onUpdate}
      style={{
        ...style,
        left: style.left + gutterSize,
        top: style.top + gutterSize,
        width: style.width - gutterSize,
        height: style.height - gutterSize,
      }}
      variant={flavor.variant}
    />
  )
}

Cell.propTypes = {
  data: shape({
    files: array.isRequired,
    onDelete: func.isRequired,
    onSelect: func.isRequired,
    onUpdate: func.isRequired,
    flavor: shape({
      variant: string,
    }),
  }).isRequired,
  style: shape({
    left: number.isRequired,
    top: number.isRequired,
    width: number.isRequired,
    height: number.isRequired,
  }),
}

const FileList = ({files, onSelect, onDelete, onUpdate, flavor, gridWrapperClass}) => {
  const rowCount = Math.ceil(files.length / tableColumns)

  return (
    <AutoSizer className={gridWrapperClass}>
      {({height, width}) => (
        <Grid
          columnCount={tableColumns}
          columnWidth={width / tableColumns}
          height={height}
          itemData={{
            files,
            onSelect,
            onDelete,
            onUpdate,
            flavor,
          }}
          rowCount={rowCount}
          rowHeight={rowHeight}
          width={width}
        >
          {Cell}
        </Grid>
      )}
    </AutoSizer>
  )
}

FileList.propTypes = {
  files: array,
  flavor: object,
  gridWrapperClass: string,
  onDelete: func.isRequired,
  onSelect: func.isRequired,
  onUpdate: func.isRequired,
}

const FileBrowser = ({classes, isImageUrlsDisabled, types, onSelect, onClose, flavor}) => {
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState(null)
  const [filter, setFilter] = useState(null)
  const [{sortKey, sortDirection}, setSort] = useState({
    sortKey: "insertedAt",
    sortDirection: "desc",
  })
  const [recentlyUploadedFiles, setRecentlyUploadedFiles] = useState([])
  const [isImageURLError, setImageURLError] = useState(false)
  const [isInsertButtonDisabled, setInsertButtonDisabled] = useState(true)

  const imageURLRef = useRef(null)

  const isImageBrowser = hasImage(types)

  useEffect(() => {
    const _getFiles = async () => {
      const params = flavor.code ? {flavor: flavor.code} : {}
      const apiFiles = await fetchFiles(params)
      const _files = Array.isArray(types)
        ? apiFiles.filter(file => types.includes(file.type))
        : apiFiles

      setFiles(_files)
      setIsLoading(false)
    }

    _getFiles()
  }, [flavor.code, types])

  const onDrop = newlyDroppedFiles => {
    const _uploadFiles = async () => {
      const newlyUploadedFiles = await uploadFiles(newlyDroppedFiles, flavor.code)
      setFiles([...files, ...newlyUploadedFiles])
      setRecentlyUploadedFiles([...recentlyUploadedFiles, ...newlyUploadedFiles.map(f => f.id)])
      setFilter("recent")
    }

    _uploadFiles()
  }

  const {getRootProps, getInputProps, open: openFileDialog, isDragActive} = useDropzone({
    onDrop,
    multiple: true,
    noClick: true,
    noKeyboard: true,
  })

  const {ref, ...rootProps} = getRootProps()

  const onDelete = file => {
    const _onDelete = async () => {
      const {id} = file

      await deleteFile(id)

      setFiles(files.filter(f => f.id !== id))
    }

    _onDelete()
  }

  const onUpdate = file => {
    const _onUpdate = async () => {
      const updatedFile = await updateFile(file.id, {isFavorited: !file.isFavorited})

      setFiles([updatedFile].concat(reject(files, {id: updatedFile.id})))
    }

    _onUpdate()
  }

  const onSearch = ({target}) => setSearch(target.value && target.value.toLowerCase())
  const showStarred = () => setFilter("starred")
  const showRecent = () => setFilter("recent")
  const clearTypeFilter = () => setFilter(null)
  const stopPropagation = e => e.stopPropagation()
  const onSortChange = ({target}) => {
    const _sortKey = target.value.split("-")[0]
    const _sortDirection = target.value.split("-")[1]

    setSort({sortKey: _sortKey, sortDirection: _sortDirection})
  }

  const onImageURL = e => {
    const url = e.target.value

    loadImage(url)
      .then(() => {
        setImageURLError(false)
        setInsertButtonDisabled(false)
      })
      .catch(() => {
        setImageURLError(true)
        setInsertButtonDisabled(true)
      })
  }

  const onSubmitImageUrl = () => {
    const url = imageURLRef.current.value
    const type = url.split(".").slice(-1).pop()

    onSelect({
      id: null,
      url,
      type: getMimeTypeFromFileExtension(type),
    })
  }

  const {pluralLabel: flavorLabel} = flavor

  const sortedFiles = useMemo(() => {
    return orderBy(files, ["isFavorited", caseInsensitiveSort(sortKey)], ["desc", sortDirection])
  }, [files, sortKey, sortDirection])

  const filteredFiles = sortedFiles
    .filter(file => !search || file.name.toLowerCase().includes(search))
    .filter(file => {
      switch (filter) {
        case "starred":
          return file.isFavorited

        case "recent":
          return recentlyUploadedFiles.includes(file.id)

        default:
          return true
      }
    })

  let noFilesMessage

  if (!filteredFiles.length)
    if (isLoading) {
      noFilesMessage = "Loading..."
    } else if (!files.length) {
      noFilesMessage = `You don't have any ${flavorLabel} yet.  Click or drag a file to the right to add one.`
    } else {
      noFilesMessage = `No ${flavorLabel} match those search/filter criteria.`
    }

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog
        classes={{paper: classes.modal}}
        maxWidth={false}
        onClose={onClose}
        open={true}
        PaperProps={{"aria-labelledby": "file-browser-title"}}
        ref={ref}
        {...rootProps}
      >
        <div className={classes.browserSection}>
          <div className={classes.filterBar}>
            <TextField
              className={classes.filterSearch}
              fullWidth={true}
              onChange={onSearch}
              onMouseDown={stopPropagation}
              placeholder="Filter files by name"
              style={{width: "50%"}}
            />
            <FormControl className={classes.filterSelector}>
              <InputLabel>Sort By</InputLabel>
              <DOSelect onChange={onSortChange} value={`${sortKey}-${sortDirection}`}>
                <MenuItem value="name-asc">Name (A - Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z - A)</MenuItem>
                <MenuItem value="insertedAt-desc">Date Added (newest first)</MenuItem>
                <MenuItem value="insertedAt-asc">Date Added (oldest first)</MenuItem>
              </DOSelect>
            </FormControl>
            <input {...getInputProps()} />
            <FormControl>
              <ButtonGroup size="small" variant="outlined">
                <Button color={!filter ? "primary" : "grey"} onClick={clearTypeFilter}>
                  All
                </Button>
                <Button color={filter === "starred" ? "primary" : "grey"} onClick={showStarred}>
                  Starred
                </Button>
                <Button color={filter === "recent" ? "primary" : "grey"} onClick={showRecent}>
                  <Badge
                    badgeContent={recentlyUploadedFiles.length}
                    classes={{badge: classes.buttonGroupBadge}}
                    color="primary"
                    invisible={!recentlyUploadedFiles.length}
                  >
                    Recently Uploaded
                  </Badge>
                </Button>
              </ButtonGroup>
            </FormControl>
          </div>
          <DialogTitle id="file-browser-title">{flavorLabel}</DialogTitle>
          <div
            className={cx(classes.dialogContent, {
              [classes.dialogContentImageURL]: isImageBrowser,
            })}
          >
            {filteredFiles.length ? (
              <FileList
                files={filteredFiles}
                flavor={flavor}
                gridWrapperClass={classes.gridWrapper}
                onDelete={onDelete}
                onSelect={onSelect}
                onUpdate={onUpdate}
              />
            ) : (
              <Typography className={classes.noFiles}>{noFilesMessage}</Typography>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <Fab
            className={classes.uploadButton}
            color="primary"
            onClick={openFileDialog}
            variant="extended"
          >
            <MdCloudUpload className={classes.uploadIcon} />
            Upload
          </Fab>
          {isImageBrowser && !isImageUrlsDisabled && (
            <>
              <Typography className={classes.imageURLMessage} variant="body2">
                If the image is already hosted somewhere else you can insert it by passing in its
                URL. You should only do this if you control the place where the image is hosted.
              </Typography>
              <div className={classes.imageURLInputArea}>
                <TextField
                  error={isImageURLError}
                  fullWidth={true}
                  helperText={isImageURLError ? "Invalid image url" : ""}
                  InputProps={{
                    classes: {
                      input: classes.imageURLInputProps,
                    },
                  }}
                  inputRef={imageURLRef}
                  label={"Image URL"}
                  onChange={onImageURL}
                  onMouseDown={stopPropagation}
                />
                <Button
                  className={classes.insertButton}
                  color={"primary"}
                  disabled={isInsertButtonDisabled}
                  onClick={onSubmitImageUrl}
                  size="large"
                  variant="contained"
                >
                  Insert
                </Button>
              </div>
            </>
          )}
        </div>
        {isDragActive && (
          <div className={classes.uploadOverlay}>
            <div className={classes.uploadOverlayContent}>
              <MuiIcon icon={<MdCloudUpload className={classes.uploadOverlayIcon} />} />
              <Typography className={classes.uploadOverlayText} variant="h5">
                Drop to upload a file
              </Typography>
            </div>
          </div>
        )}
      </Dialog>
    </ThemeProvider>
  )
}

const styles = theme => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    width: 1000,
  },
  browserSection: {
    overflow: "auto",
    padding: 4,
    flexGrow: 1,
  },
  dialogContent: {
    height: "calc(100vh - 340px)",
  },
  dialogContentImageURL: {
    height: "calc(100vh - 500px)",
  },
  // We don't get the grid wrapper styles for free anymore because we're using
  // virtualized to be the wrapper
  gridWrapper: {
    overflowY: "auto",
    margin: -2,
    padding: 0,
    listStyle: "none",
    "-webkit-overflow-scrolling": "touch",
  },
  noFiles: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    fontStyle: "italic",
  },
  // Badges in Button groups don't really work yet
  // (and these padding values are hardcoded into button small)
  // https://github.com/mui-org/material-ui/issues/17383
  buttonGroupBadge: {
    top: -4,
    right: -8,
  },
  filterBar: {
    display: "flex",
    padding: theme.spacing(3),
    paddingBottom: 0,
    alignItems: "baseline",
    position: "relative",
  },
  filterSearch: {
    flex: 1,
    marginRight: theme.spacing(1.5),
  },
  filterSelector: {
    minWidth: 200,
    marginRight: theme.spacing(1.5),
  },
  footer: {
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    borderTop: "1px solid grey",
    padding: theme.spacing(3),
    width: "100%",
    "& > :nth-last-child(n + 2)": {
      marginBottom: theme.spacing(3),
    },
  },
  imageURLInputArea: {
    display: "flex",
    flexDirection: "row",
    width: "100%",

    "& > :nth-last-child(n + 2)": {
      marginRight: theme.spacing(4),
    },
  },
  imageURLInputProps: {
    textIndent: "10px",
    "&::placeholder": {
      textIndent: "10px",
    },
  },
  imageURLMessage: {
    fontSize: "0.875rem",
  },
  insertButton: {
    alignSelf: "flex-start",
  },
  uploadButton: {
    minWidth: "111px",
  },
  uploadIcon: {
    marginRight: 10,
  },
  uploadTarget: {
    cursor: "pointer",
    textAlign: "center",
    borderRadius: 10,
    height: "100%",
    position: "relative",
  },
  uploadOverlay: {
    opacity: 0.9,
    textAlign: "center",
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
      borderRadius: 4,
      border: "2px dashed #97e297",
    },
  },
  uploadOverlayContent: {
    alignSelf: "center",
  },
  uploadOverlayIcon: {
    fontSize: 50,
    opacity: 0.5,
  },
  uploadOverlayText: {
    marginTop: 10,
  },
})

FileBrowser.defaultProps = {
  flavor: {
    pluralLabel: "Files",
  },
  isImageUrlsDisabled: false,
}

FileBrowser.propTypes = {
  classes: object.isRequired,
  isImageUrlsDisabled: bool,
  flavor: shape({
    code: string,
    pluralLabel: string,
  }),
  onClose: func.isRequired,
  onSelect: func.isRequired,
  types: oneOfType([arrayOf(string), string]),
}

export default withStyles(styles)(FileBrowser)
