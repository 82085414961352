import {Button, Paper, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import get from "lodash/get"
import startCase from "lodash/startCase"
import {func, object} from "prop-types"
import {Fragment, PureComponent} from "react"
import {
  FaUserShield as AuthIcon,
  FaCreditCard as CreditCardIcon,
  FaRegMoneyBillAlt as DollarBillIcon,
  FaMap as MapIcon,
} from "react-icons/fa"
import {
  MdTrendingUp as CtaIcon,
  MdEmail as EmailIcon,
  MdError as ErrorIcon,
  MdTextsms as SMSIcon,
  MdFeedback as SurveyIcon,
  MdVisibility as ViewIcon,
} from "react-icons/md"
import {RiMailForbidLine as EmailSkippedIcon, RiChatOffLine as SMSSkippedIcon} from "react-icons/ri"

import {distanceDateTime, formatDistance} from "lib/date-time-formatters"
import {
  DirectDepositFailedIcon as DollarBillSlashedIcon,
  formatDistribution,
} from "lib/direct-deposit-helpers"
import humanize from "lib/string/humanize"
import pluralize from "lib/string/pluralize"

import Padded from "../padded/padded"
import ScheduledMessageRow from "./scheduled-message-row"

class ActionHistory extends PureComponent {
  compressedTimeline = () => {
    const {journey} = this.props

    let lastTime = -Infinity
    let lastName

    return get(journey, ["analyticsCache", "actions"], []).reduce((acc, action) => {
      const currentTime = Date.parse(action.timestamp)

      if (
        currentTime - lastTime > 100 ||
        lastName !== action.name ||
        action.name === "message_sent"
      )
        acc.push([])

      acc[acc.length - 1].push(action)

      lastTime = currentTime
      lastName = action.name

      return acc
    }, [])
  }

  ctaClickedType = action => {
    if (action.meta.isEmail === "true") return "Email"

    if (action.meta.isSms === "true") return "SMS"

    if (action.meta.widgetSlug) return "Page"

    return ""
  }

  onCancelScheduledMessage = jobId => {
    const {onDeleteJourneyScheduledMessage, journey, template} = this.props

    onDeleteJourneyScheduledMessage(template.id, journey.id, jobId)
  }

  renderActionGroupAuth = (name, timestamp, id, actions, classes) => (
    <Typography className={cx(classes.badgeHeader, classes.connector)} variant="h5">
      <span
        className={cx(classes.badge, {
          [classes.badgeError]: name === "auth_failed",
          [classes.badgeSuccess]: name === "auth_succeeded",
        })}
      >
        <AuthIcon data-testid="auth-icon" size={30} />
      </span>
      <span>
        Light authentication {name.split("_")[1]} <br />
        <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
      </span>
    </Typography>
  )

  renderActionGroupAuthCodeSent = (name, timestamp, id, [action], classes) =>
    action?.meta?.type && (
      <Typography className={cx(classes.badgeHeader, classes.connector)} variant="h5">
        <span className={cx(classes.badge)}>
          {action.meta.type === "phone_mobile" ? (
            <SMSIcon data-testid="sms-icon" size={30} />
          ) : (
            <EmailIcon data-testid="email-icon" size={30} />
          )}
        </span>
        <span>
          Verification code sent via {action.meta.type === "phone_mobile" ? "SMS" : "e-mail"}
          <br />
          <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
        </span>
      </Typography>
    )

  renderActionGroupCtaClicked = (name, timestamp, id, [action], classes) => {
    const ctaType = this.ctaClickedType(action)
    const title = action?.page?.contentName

    return (
      <>
        <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
        <Paper>
          <Typography className={cx(classes.actionHeader, classes.connector)} variant="h5">
            <CtaIcon data-testid="cta-icon" size={20} />
            CTA Clicked
          </Typography>
          <Typography className={classes.actionContent} component="p">
            {title && (
              <>
                {ctaType} Name: <b>{title}</b>
                <br />
              </>
            )}
            Type: <b>{ctaType}</b>
          </Typography>
        </Paper>
      </>
    )
  }

  renderActionGroupEmailOpen = (name, timestamp, id, actions, classes) =>
    actions.map(action => (
      <div className={classes.action} key={action.id}>
        <i className={classes.actionTime}>{distanceDateTime(action.timestamp)}</i>
        <Paper>
          <Typography className={cx(classes.actionHeader, classes.connector)} variant="h5">
            <ViewIcon data-testid="view-icon" size={20} />
            {startCase(name)}
          </Typography>
          <Typography className={classes.actionContent} component="p">
            {action.meta && action.meta.payload && action.meta.payload.subject ? (
              <b>{action.meta.payload.subject}</b>
            ) : (
              <i>No Subject</i>
            )}
            <br />
            <code>{action.messageId}</code>
          </Typography>
        </Paper>
      </div>
    ))

  renderActionGroupJourneyOpen = (name, timestamp, id, actions, classes) => (
    <>
      <Typography
        className={cx(classes.badgeHeader, classes.connector)}
        data-testid="journey-opened"
        variant="h5"
      >
        <span className={classes.badge}>
          <MapIcon data-testid="map-icon" size={30} />
        </span>
        <span>
          Journey Opened <br />
          <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
        </span>
      </Typography>
    </>
  )

  renderActionGroupPageViewed = (name, timestamp, id, actions) => {
    const {classes} = this.props
    let lastTime = new Date(timestamp)

    return (
      <>
        <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
        <Paper data-testid="page-viewed">
          <Typography className={cx(classes.actionHeader, classes.connector)} variant="h5">
            <ViewIcon data-testid="view-icon" size={20} />
            {pluralize("Page", actions.length)} Viewed
          </Typography>
          <Typography className={classes.actionContent} component="p">
            {actions.map((action, i) => {
              const currentTime = new Date(action.timestamp)
              const distance = formatDistance(currentTime, lastTime)

              lastTime = currentTime

              return (
                <Fragment key={action.id}>
                  {action.page?.contentName || (
                    <i>The contact viewed a page that no longer exists in this campaign.</i>
                  )}{" "}
                  {i > 0 && <i className={classes.actionTime}>({distance} later)</i>}
                  <br />
                </Fragment>
              )
            })}
          </Typography>
        </Paper>
      </>
    )
  }

  renderActionGroupSurveyCompleted = (name, timestamp, id, actions, classes) => (
    <>
      <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
      <Paper>
        <Typography
          className={cx(classes.actionHeader, classes.connector)}
          data-testid="survey-completed"
          variant="h5"
        >
          <SurveyIcon data-testid="survey-icon" size={20} />
          {startCase(name)}
        </Typography>
        <Typography className={classes.actionContent} component="p">
          <Button onClick={this.props.scrollToSurveyAnswers}>See answers above</Button>
        </Typography>
      </Paper>
    </>
  )

  renderActionGroupMessageSent = (name, timestamp, id, [action], classes) => (
    <>
      <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
      <Paper>
        <Typography className={cx(classes.actionHeader, classes.connector)} variant="h5">
          {action.meta.type === "sms" ? (
            <SMSIcon data-testid="sms-icon" size={20} />
          ) : (
            <EmailIcon data-testid="email-icon" size={20} />
          )}
          {action.meta.type === "sms" ? "SMS" : "Email"}
        </Typography>
        <Typography className={classes.actionContent} component="p">
          To: <b>{action.meta.email || action.meta.phoneMobile}</b>
          <br />
          <br />
          {action.meta.payload && action.meta.payload.subject && (
            <>
              <b>{action.meta.payload.subject}</b>
              <br />
            </>
          )}
          {action.meta.payload && action.meta.payload.body}
        </Typography>
      </Paper>
    </>
  )

  renderActionGroupMessageBounced = (name, timestamp, id, [action], classes) => (
    <>
      <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
      <Paper>
        <Typography
          className={cx(classes.actionHeader, classes.errorActionHeader, classes.connector)}
          variant="h5"
        >
          {action.meta.type === "sms" ? (
            <SMSIcon data-testid="sms-icon" size={20} />
          ) : (
            <EmailIcon data-testid="email-icon" size={20} />
          )}
          {action.meta.type === "sms" ? "SMS" : "Email"} Bounced
        </Typography>
        <Typography className={classes.actionContent} component="p">
          To: <b>{action.meta.email || action.meta.phoneMobile}</b>
          <br />
          <br />
          {action.meta.payload && action.meta.payload.subject && (
            <>
              <b>{action.meta.payload.subject}</b>
              <br />
            </>
          )}
          {action.meta.payload && action.meta.payload.body}
        </Typography>
      </Paper>
    </>
  )

  renderActionGroupMessageFailed = (name, timestamp, _id, [action], classes) => (
    <>
      <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
      <Paper>
        <Typography
          className={cx(classes.actionHeader, classes.errorActionHeader, classes.connector)}
          variant="h5"
        >
          <ErrorIcon data-testid="error-icon" size={20} />
          {startCase(name)}
        </Typography>

        <Typography className={classes.actionContent} component="p">
          {action.meta.errorMessage && action.meta.errorMessage}
          <br />
          <br />
          Message information:
          <br />
          To: <b>{action.meta.email || action.meta.phoneMobile}</b>
          <br />
          {action.meta.payload && action.meta.payload.subject && (
            <>
              Subject: <b>{action.meta.payload.subject}</b>
              <br />
            </>
          )}
        </Typography>
      </Paper>
    </>
  )

  reasonMessage = ({reason, type}) => {
    if (reason === "no_email")
      return "This contact did not have an email set at the time we attempted to send a message."
    else if (reason === "no_phone_mobile")
      return "This contact did not have a mobile phone number set at the time we attempted to send a message."
    else if (reason === "email_unsubscribed")
      return "The email for this contact has been unsubscribed."
    else if (reason === "email_not_verified")
      return "The email for this contact has not been verified."
    else if (reason === "sms_unsubscribed")
      return "The mobile phone # for this contact has been unsubscribed."
    else if (reason === "sms_not_verified")
      return "The mobile phone # for this contact has not been verified."
    else if (reason === "no_message") return `No applicable ${type} could be found to send.`
    else if (reason === "no_timeline_marker")
      return "The timeline marker for this message was removed at some point after this journey was created."
  }

  skippedActionIcon = action => {
    const messageType = action.meta?.type

    if (messageType === "email")
      return props => <EmailSkippedIcon {...props} data-testid="email-skipped-icon" />
    else if (messageType === "sms")
      return props => <SMSSkippedIcon {...props} data-testid="sms-skipped-icon" />

    return ErrorIcon
  }

  renderActionGroupMessageSkipped = (name, timestamp, _id, actions, classes) =>
    actions.map(action => {
      const SkippedIcon = this.skippedActionIcon(action)
      const messageTarget = action.meta.email || action.meta.phoneMobile

      return (
        <div className={classes.action} key={action.id}>
          <i className={classes.actionTime}>{distanceDateTime(action.timestamp)}</i>
          <Paper>
            <Typography
              className={cx(classes.actionHeader, classes.errorActionHeader, classes.connector)}
              variant="h5"
            >
              <SkippedIcon size={20} />
              {startCase(name)}
            </Typography>

            <Typography className={classes.actionContent} component="p">
              {action.meta.reason && (
                <>
                  Reason: <b>{this.reasonMessage(action.meta)}</b>
                  <br />
                </>
              )}
              <br />
              {!!messageTarget && (
                <>
                  To: <b>{messageTarget}</b>
                  <br />
                </>
              )}
              {action.meta.payload && action.meta.payload.subject && (
                <>
                  Subject: <b>{action.meta.payload.subject}</b>
                  <br />
                </>
              )}
            </Typography>
          </Paper>
        </div>
      )
    })

  renderActionGroupUnknown = (name, timestamp, id, actions, classes) => {
    if (process.env.NODE_ENV === "development")
      return (
        <>
          <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
          <Paper>
            <Typography className={cx(classes.actionHeader, classes.connector)} variant="h5">
              {startCase(name)} ({name})
            </Typography>
            <Typography className={classes.actionContent} component="p">
              {actions.map(action => (
                <code key={action.id} style={{display: "block", marginBottom: 24}}>
                  {JSON.stringify(action.meta)}
                </code>
              ))}
            </Typography>
          </Paper>
        </>
      )
    else return null
  }

  renderCardOnFile = (name, timestamp, _id, [action], classes) => (
    <>
      <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
      <Paper>
        <Typography
          className={cx(
            classes.actionHeader,
            {[classes.errorActionHeader]: name === "card_on_file_failed"},
            classes.connector
          )}
          variant="h5"
        >
          <CreditCardIcon size={20} />
          {startCase(name)}
        </Typography>

        <Typography className={classes.actionContent} component="p">
          {action.meta?.job?.merchantSite?.name} ({action.meta?.job?.merchantSite?.host})
        </Typography>
      </Paper>
    </>
  )

  renderDirectDeposit = (name, timestamp, _id, [action], classes) => {
    let caption, isError, Icon
    const logoUrl = action.meta?.company?.branding?.logo?.url

    if (name === "direct_deposit_failed") {
      isError = true
      Icon = DollarBillSlashedIcon
      caption = startCase(action.meta?.data?.reason)
    } else {
      isError = false
      Icon = DollarBillIcon
      caption = formatDistribution(action.meta?.data).filter(Boolean).join(", ")
    }

    return (
      <>
        <i className={classes.actionTime}>{distanceDateTime(timestamp)}</i>
        <Paper>
          <Typography
            className={cx(
              classes.actionHeader,
              {[classes.errorActionHeader]: isError},
              classes.connector
            )}
            variant="h5"
          >
            <Icon size={20} />
            {startCase(name)}
          </Typography>

          <Typography className={classes.actionContent} component="p">
            {logoUrl && (
              <img
                className={classes.directDepositLogo}
                alt={action.meta?.company?.name}
                src={logoUrl}
              />
            )}
            {action.meta?.company?.name}
            <div>{caption}</div>
          </Typography>
        </Paper>
      </>
    )
  }

  renderActionGroup = (actionGroup, currentTime, lastTime) => {
    const {classes} = this.props
    const [{name, timestamp, id}] = actionGroup
    const renderArgs = [name, timestamp, id, actionGroup, classes]
    const diffInPx = Math.floor((currentTime.getTime() - lastTime.getTime()) / 864000) // 1 day = 100px
    const height = Math.min(diffInPx, 400)
    const humanTime = formatDistance(currentTime, lastTime)

    let children

    switch (name) {
      case "auth_presented":
      case "auth_failed":
      case "auth_succeeded":
        children = this.renderActionGroupAuth(...renderArgs)
        break
      case "auth_code_sent":
        children = this.renderActionGroupAuthCodeSent(...renderArgs)
        break
      case "cta_clicked":
        children = this.renderActionGroupCtaClicked(...renderArgs)
        break
      case "email_opened":
        children = this.renderActionGroupEmailOpen(...renderArgs)
        break
      case "journey_opened":
        children = this.renderActionGroupJourneyOpen(...renderArgs)
        break
      case "page_viewed":
        children = this.renderActionGroupPageViewed(...renderArgs)
        break
      case "survey_question_answered":
        children = null
        break
      case "survey_completed":
        children = this.renderActionGroupSurveyCompleted(...renderArgs)
        break
      case "message_sent":
        children = this.renderActionGroupMessageSent(...renderArgs)
        break
      case "message_failed":
        children = this.renderActionGroupMessageFailed(...renderArgs)
        break
      case "message_bounced":
        children = this.renderActionGroupMessageBounced(...renderArgs)
        break
      case "message_skipped":
        children = this.renderActionGroupMessageSkipped(...renderArgs)
        break
      case "card_on_file_completed":
      case "card_on_file_failed":
        children = this.renderCardOnFile(...renderArgs)
        break
      case "direct_deposit_completed":
      case "direct_deposit_failed":
        children = this.renderDirectDeposit(...renderArgs)
        break
      default:
        children = this.renderActionGroupUnknown(...renderArgs)
        break
    }

    return (
      <div className={classes.actionContainer} key={id} role="listitem" title={humanize(name)}>
        {height > 0 && (
          <div className={classes.actionTimeDiff} style={{height: Math.max(height, 50)}}>
            {humanTime} later...
          </div>
        )}
        <div className={classes.action}>{children}</div>
      </div>
    )
  }

  render() {
    const {
      classes,
      journey: {scheduledMessages = []},
    } = this.props
    const timeline = this.compressedTimeline()

    let lastTime

    if (timeline.length) lastTime = new Date(timeline[0][0].timestamp)

    return (
      <Padded>
        <Typography id="action-history-header" variant="h4">
          History
        </Typography>
        <div aria-labelledby="action-history-header" className={classes.actions} role="list">
          {timeline.map(actionGroup => {
            const currentTime = new Date(actionGroup[0].timestamp)
            const tempLastTime = lastTime

            lastTime = currentTime
            return this.renderActionGroup(actionGroup, currentTime, tempLastTime)
          })}
        </div>
        {scheduledMessages.length > 0 && (
          <div className={cx(classes.actions, classes.futureActions)}>
            {scheduledMessages.map(scheduledMessage => (
              <ScheduledMessageRow
                key={scheduledMessage.jobId}
                onCancelScheduledMessage={this.onCancelScheduledMessage}
                {...scheduledMessage}
              />
            ))}
          </div>
        )}
      </Padded>
    )
  }
}

ActionHistory.propTypes = {
  classes: object.isRequired,
  journey: object.isRequired,
  onDeleteJourneyScheduledMessage: func.isRequired,
  scrollToSurveyAnswers: func,
  template: object.isRequired,
}

const styles = theme => ({
  action: {
    marginBottom: theme.spacing(3),
  },
  actions: {
    paddingLeft: 40,
    borderLeftWidth: theme.spacing(0.5),
    borderLeftColor: theme.palette.primary.light,
    borderLeftStyle: "dashed",
    overflow: "hidden",
  },
  futureActions: {
    borderLeftColor: theme.palette.grey[300],
    paddingTop: theme.spacing(2),
  },
  actionTimeDiff: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary,
    fontStyle: "italic",
    fontSize: "1.2rem",
  },
  actionTime: {
    color: theme.palette.text.secondary,
  },
  actionHeader: {
    position: "relative",
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    fontSize: "1rem",
    color: theme.palette.primary.contrastText,
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
  errorActionHeader: {
    backgroundColor: "#f2654b",
  },
  actionContent: {
    padding: theme.spacing(1),
  },
  connector: {
    "&::before": {
      content: `""`,
      position: "absolute",
      top: "50%",
      marginTop: theme.spacing(-0.5),
      left: -100,
      width: 100,
      borderBottomWidth: theme.spacing(0.5),
      borderBottomColor: theme.palette.primary.light,
      borderBottomStyle: "dashed",
    },
  },
  badgeHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    fontSize: "1rem",
  },
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1),
    borderRadius: "50%",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[2],
  },
  badgeError: {
    backgroundColor: "#f2654b",
  },
  badgeSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  directDepositLogo: {
    maxWidth: 20,
    maxHeight: 20,
    verticalAlign: "text-bottom",
    display: "inline",
    marginRight: 7,
  },
})

export default withStyles(styles)(ActionHistory)
