import {Box as MUIBox, Typography} from "@mui/material"
import {omit, pick} from "lodash"
import {func, object, shape, string} from "prop-types"
import {PureComponent} from "react"
import {MdErrorOutline as ErrorIcon} from "react-icons/md"

import {fetchTemplateActionCtas, fetchTemplateActions} from "lib/api"
import flattenObj from "lib/flatten-obj"

import AnalyticsFilter from "../analytics-filter/analytics-filter"
import TemplateAnalyticsRow from "../analytics-row/analytics-row"
import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import DOTable from "../table/table"
import {tabular} from "../table/table-state"
import {templateContext} from "../template-router/template-context"

const exportTransform = record =>
  flattenObj({
    ...record,
    contact: record.contact
      ? pick(record.contact, ["email", "id", "name_first", "name_last", "unique_id"])
      : null,
    content_block: record.content_block ? pick(record.content_block, ["data.name", "type"]) : null,
    journey: record.journey ? pick(record.journey, ["id"]) : null,
    objective: record.objective ? pick(record.objective, ["id", "key", "name"]) : null,
    page: record.page ? pick(record.page, ["id", "navigationTitle"]) : null,
    meta: record.meta ? omit(record.meta, ["payload"]) : null,
    message: record.message ? omit(record.message, ["payload"]) : null,
  })

export class TemplateAnalytics extends PureComponent {
  state = {ctas: [], isTableLoading: false, timestamp: null}

  componentDidMount() {
    const {templateId} = this.props

    this.refresh({filters: {templateId}})
    fetchTemplateActionCtas(templateId).then(ctas => this.setState({ctas}))
  }

  filter = filters => {
    this.setState({filters})
    this.refresh({page: 0, filters})
  }

  isStaleResponse = fetchResponse => {
    let url

    try {
      url = new URL(fetchResponse.url)
    } catch (_e) {
      // Throws TypeError on invalid URL (which should only occur in tests),
      // so bypass timestamp checking
      return false
    }

    const params = url.searchParams

    return params.get("timestamp") !== this.state.timestamp
  }

  refresh = attrs => {
    const {updateStateForRequest} = this.props
    const {templateId} = this.props
    const params = updateStateForRequest(attrs)

    this.setState({isTableLoading: true}, () => {
      // Timestamp requests to defeat race conditions when filters are rapidly changed
      const timestamp = Date.now().toString()
      this.setState({timestamp})

      fetchTemplateActions({...params, templateId, timestamp}, {withFetchResponse: true})
        .then(([rows, fetchResponse]) => {
          if (!this.isStaleResponse(fetchResponse)) {
            this.props.setTableState({fetchResponse, rows})
            this.setState({timestamp: null})
          }
        })
        .catch(error => {
          this.setState({error})
        })
        .finally(() => {
          this.setState({isTableLoading: false})
        })
    })
  }

  noResults = isFiltered => {
    if (this.state.error)
      return (
        <MUIBox sx={{m: 4, textAlign: "center"}}>
          <Typography sx={{color: "error.main", fontSize: 42}}>
            <ErrorIcon />
          </Typography>
          There was an error loading this campaign's actions. Please try again in a moment.
        </MUIBox>
      )
    else if (isFiltered) return "No analytics matched your selected filters"
    else return "No analytics have been gathered yet"
  }

  render() {
    const {templateId, template} = this.props
    const {ctas, isTableLoading} = this.state
    const isFiltered =
      Object.keys(this.props?.filters ?? {}).filter(key => key !== "templateId").length > 0

    if (!template) return null
    return (
      <Box>
        <DocumentTitle title={`Campaigns - ${template?.name} - Analytics`} />
        <DOTable
          allowColumnCustomization={true}
          exporterProps={{
            fetchRecords: fetchTemplateActions,
            filename: "analytics_export",
            params: {...this.props.filters, templateId},
            title: "Export Results",
            transform: exportTransform,
          }}
          filterComponent={<AnalyticsFilter ctas={ctas} onSubmit={this.filter} />}
          headers={[
            {field: "name", isDefault: true},
            {field: "timestamp", label: "Created", isDefault: true},
            {field: "contact", sortable: false, isDefault: true},
            {field: "location", sortable: false, isDefault: true},
            {field: "journey", sortable: false, isDefault: true},
          ]}
          isTableLoading={isTableLoading}
          noResults={this.noResults(isFiltered)}
          refresh={this.refresh}
          storageName="template-analytics"
        >
          {(row, index, columns) => (
            <TemplateAnalyticsRow columns={columns} key={row.id} row={row} />
          )}
        </DOTable>
      </Box>
    )
  }
}

TemplateAnalytics.propTypes = {
  filters: object,
  setTableState: func.isRequired,
  template: shape({
    name: string,
  }),
  templateId: string,
  updateStateForRequest: func.isRequired,
}

export default tabular({
  namespace: "ta",
  sortColumn: "timestamp",
  sortDirection: "desc",
  useQueryParams: true,
})(templateContext(TemplateAnalytics))
