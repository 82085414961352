import apiActionCreator from "lib/api-action-creator"
import SubmissionError from "lib/submission-error"
import uploadFileWithPolicy from "lib/upload-file-with-policy"

import {
  createClient,
  createPgpKey,
  createSshKey,
  deletePgpKey,
  deleteSshKey,
  fetchClients,
  fetchPgpKeys,
  fetchSshKeys,
  getCurrentTeamFaviconUploadPolicy,
  getCurrentTeamLogoUploadPolicy,
  settingsEmailTest,
  updateClient,
  updateCurrentTeam,
} from "../../lib/api"

export const TEAM_UPDATED = "TEAM_UPDATED"
export const SMTP_CREDENTIALS_SUCCESS = "SMTP_CREDENTIALS_SUCCESS"
export const SMTP_CREDENTIALS_FAILED = "SMTP_CREDENTIALS_FAILED"
export const SMTP_CREDENTIALS_LOADING = "SMTP_CREDENTIALS_LOADING"
export const SMTP_TEST_STATUS_CLEARED = "SMTP_TEST_STATUS_CLEARED"
export const CLIENT_LIST_SET = "CLIENT_LIST_SET"
export const CLIENT_REVOKED = "CLIENT_REVOKED"
export const CLIENT_CREATED = "CLIENT_CREATED"
export const NEW_CLIENT_CLEARED = "NEW_CLIENT_CLEARED"
export const SSH_KEY_LIST_SET = "SSH_KEY_LIST_SET"
export const SSH_KEY_DELETED = "SSH_KEY_DELETED"
export const SSH_KEY_CREATED = "SSH_KEY_CREATED"
export const PGP_KEY_LIST_SET = "PGP_KEY_LIST_SET"
export const PGP_KEY_DELETED = "PGP_KEY_DELETED"
export const PGP_KEY_CREATED = "PGP_KEY_CREATED"

export const testSmtpSettings = params => dispatch => {
  dispatch({type: SMTP_CREDENTIALS_LOADING})
  settingsEmailTest(params)
    .then(() => dispatch({type: SMTP_CREDENTIALS_SUCCESS}))
    .catch(err =>
      err.status === 400
        ? dispatch({type: SMTP_CREDENTIALS_FAILED, payload: err.message})
        : dispatch({
            type: SMTP_CREDENTIALS_FAILED,
            payload: "Something went wrong. Please try again.",
          })
    )
}

export const clearSmtpTestStatus = () => ({type: SMTP_TEST_STATUS_CLEARED})

export const updateTeamSettings = params => dispatch =>
  updateCurrentTeam(params)
    .then(team => dispatch({type: TEAM_UPDATED, payload: team}))
    .catch(({body}) => {
      throw new SubmissionError(body.errors)
    })

export const updateLogo = file => dispatch =>
  getCurrentTeamLogoUploadPolicy({type: file.type})
    .then(({policy, attrs}) => uploadFileWithPolicy(file, policy, {extraAttrs: attrs}))
    .then(() => updateCurrentTeam({isLogoUploaded: true, logoType: file.type, logoSize: file.size}))
    .then(team => dispatch({type: TEAM_UPDATED, payload: team}))

export const updateFavicon = file => dispatch =>
  getCurrentTeamFaviconUploadPolicy({type: file.type})
    .then(({policy, attrs}) => uploadFileWithPolicy(file, policy, {extraAttrs: attrs}))
    .then(() =>
      updateCurrentTeam({isFaviconUploaded: true, faviconType: file.type, faviconSize: file.size})
    )
    .then(team => dispatch({type: TEAM_UPDATED, payload: team}))

export const getClients = () => dispatch =>
  fetchClients().then(clients => dispatch({type: CLIENT_LIST_SET, payload: clients}))

export const onCreateClient = () => dispatch =>
  createClient().then(client => dispatch({type: CLIENT_CREATED, payload: client}))

export const onRevokeClient = clientId => dispatch =>
  updateClient(clientId, {isRevoked: true}).then(client =>
    dispatch({type: CLIENT_REVOKED, payload: client})
  )

export const clearNewClient = () => ({type: NEW_CLIENT_CLEARED})

export const getSshKeys = apiActionCreator(SSH_KEY_LIST_SET, fetchSshKeys)

export const onCreateSshKey = params => dispatch =>
  createSshKey(params)
    .then(key => dispatch({type: SSH_KEY_CREATED, payload: key}))
    .catch(({body}) => {
      throw new SubmissionError(body.errors)
    })

export const onDeleteSshKey = apiActionCreator(SSH_KEY_DELETED, deleteSshKey, {
  isRemoveFromList: true,
})

export const getPgpKeys = apiActionCreator(PGP_KEY_LIST_SET, fetchPgpKeys)

export const onCreatePgpKey = params => dispatch =>
  createPgpKey(params)
    .then(key => dispatch({type: PGP_KEY_CREATED, payload: key}))
    .catch(({body}) => {
      throw new SubmissionError(body.errors)
    })

export const onDeletePgpKey = apiActionCreator(PGP_KEY_DELETED, deletePgpKey, {
  isRemoveFromList: true,
})
