import {Button, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, shape, string} from "prop-types"
import {useState} from "react"

import SaveButton from "components/save-button/save-button.jsx"
import WebhookDialog from "components/teams/webhook-dialog"

import {validDomainAndTld} from "lib/field-validations"
import useForm from "lib/hooks/use-form"
import MaybeTooltip from "lib/maybe-tooltip"

const WebhookSettingsForm = ({initialValues, onSubmit}) => {
  const {field, handleSubmit, inputs, invalid, submitting} = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validators: {webhook: [validDomainAndTld]},
  })

  const [isWebhookDialogOpen, setIsWebhookDialogOpen] = useState(false)

  const classes = useStyles()

  const webhook = inputs.webhook.value.trim()

  const onOpenWebhookDialog = () => setIsWebhookDialogOpen(true)
  const onCloseWebhookDialog = () => setIsWebhookDialogOpen(false)

  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* Form Fields */}
        <TextField fullWidth={true} label="Webhook URL" type="url" {...field("webhook")} />

        {/* Actions Buttons */}
        <div className={classes.formActions}>
          <MaybeTooltip
            isTooltip={!webhook || invalid}
            title="A valid url must be provided before testing."
          >
            <Button
              disabled={!webhook || invalid}
              onClick={onOpenWebhookDialog}
              color="grey"
              variant="outlined"
            >
              Test
            </Button>
          </MaybeTooltip>

          <SaveButton submitting={submitting} />
        </div>
      </form>

      {/* Webhook Test Modal */}
      {isWebhookDialogOpen && <WebhookDialog onClose={onCloseWebhookDialog} webhook={webhook} />}
    </>
  )
}

WebhookSettingsForm.propTypes = {
  initialValues: shape({webhook: string}),
  onSubmit: func,
}

const useStyles = makeStyles(() => ({
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
}))

export default WebhookSettingsForm
