import makeStyles from "@mui/styles/makeStyles"
import {func} from "prop-types"

import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import Padded from "components/padded/padded"
import ObjectiveContent from "components/team-insights/objective-content"
import Filter from "components/team-insights/team-insights-filter"
import TitleBar from "components/title-bar/title-bar"

import CampaignPerformance from "./campaign-performance"
import CompletedObjectives from "./completed-objectives"
import {ObjectivesContextProvider} from "./objectives-context"
import OverallPerformance from "./overall-performance"
import {provideTeamInsights, teamInsightsContext} from "./team-insights-context"

const TeamInsights = ({setFilterParams}) => {
  const classes = useStyles()

  return (
    <ObjectivesContextProvider>
      <TitleBar title="Insights" />
      <DocumentTitle title="Insights" />

      <Padded>
        <Box
          sx={{
            background: "#002956",
            color: "white",
            display: "flex",
            alignItems: "center",
            "& .label": {
              fontWeight: "bold",
              fontSize: 32,
              mr: 2,
              pr: 2,
              borderRight: "1px solid white",
            },
            "& div b": {
              fontSize: 17,
            },
            "& div p": {
              fontSize: 14,
            },
          }}
        >
          <div className="label">Beta</div>
          <div>
            <b>Why are team insights in beta?</b>
            <p>
              We're currently focused on scaling team insights to meet your needs and during this
              time it's possible that reports could be slow to generate or outdated.
            </p>
          </div>
        </Box>
      </Padded>

      <Padded>
        <Box>
          <Filter onSubmit={setFilterParams} />

          <div className={classes.divider} />

          <OverallPerformance />

          <div className={classes.divider} />

          <CompletedObjectives />

          <div className={classes.divider} />

          <CampaignPerformance />

          <div className={classes.divider} />

          <ObjectiveContent />
        </Box>
      </Padded>
    </ObjectivesContextProvider>
  )
}

TeamInsights.propTypes = {
  setFilterParams: func.isRequired,
}

const useStyles = makeStyles(() => ({
  divider: {
    height: 80,
  },
}))

export default provideTeamInsights(teamInsightsContext(TeamInsights))
