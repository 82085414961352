import queryString from "query-string"
import {connect} from "react-redux"

import {onCreateTemplate} from "../templates/template-actions"
import NewTemplate from "./new-template"

const mapStateToProps = (_state, {location: {search}}) => {
  const sourceTemplateName = queryString.parse(search).source_template_name
  // NB: queryString.parse automatically decodes URI-encoded strings
  return {
    sourceTemplateName,
    initialValues: sourceTemplateName ? {name: `${sourceTemplateName} (duplicated)`} : {},
  }
}

const mapDispatchToProps = (dispatch, {location: {search}}) => ({
  onSubmit: attrs =>
    dispatch(
      onCreateTemplate({...attrs, sourceTemplateId: queryString.parse(search).source_template_id})
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewTemplate)
