import {FormControl, InputLabel, Popover} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, node, object, oneOfType, shape, string} from "prop-types"
import {PureComponent, createRef} from "react"
import {connect} from "react-redux"

import createTeamTheme from "themes/team-theme-creator"

import ColorPicker from "./color-picker"
import {compilePalette} from "./helpers"

class ColorInput extends PureComponent {
  state = {
    pickerOpen: false,
    color: this.props.input.value,
  }

  static getDerivedStateFromProps(props, state) {
    const {
      input: {value: color},
    } = props

    if (color !== state.color) return {color}

    return null
  }

  anchorRef = createRef()

  customPalette = compilePalette(createTeamTheme(this.props.themeStyles))

  onToggle = () => {
    this.setState({pickerOpen: !this.state.pickerOpen})
  }

  onClose = () => {
    this.setState({pickerOpen: false})
  }

  onChange = ({hex}) => {
    this.setState({color: hex})
    this.props.input.onChange(hex)
  }

  render() {
    const {pickerOpen, color} = this.state,
      {
        classes,
        style,
        className,
        label,
        fullWidth,
        onExiting,
        placeholder,
        id,
        disabled,
      } = this.props
    const renderColor = color || placeholder

    return (
      <FormControl className={className} fullWidth={fullWidth} style={style}>
        <InputLabel focused={pickerOpen} htmlFor={id} shrink={true}>
          {label}
        </InputLabel>
        <button
          className={classes.swatch}
          disabled={disabled}
          id={id}
          onClick={this.onToggle}
          ref={this.anchorRef}
          type="button"
        >
          <div
            className={classes.color}
            style={{
              background: renderColor,
            }}
          />
        </button>
        <Popover
          anchorEl={this.anchorRef.current}
          anchorOrigin={{horizontal: "left", vertical: "bottom"}}
          data-testid="color-input-popover"
          onClose={this.onClose}
          open={this.state.pickerOpen}
          TransitionProps={{
            onExiting,
          }}
        >
          <ColorPicker
            color={this.state.color}
            onChange={this.onChange}
            palette={this.props.hideCustomPalette ? [] : this.customPalette}
          />
        </Popover>
      </FormControl>
    )
  }
}

ColorInput.propTypes = {
  className: string,
  classes: object.isRequired,
  disabled: bool,
  fullWidth: bool,
  hideCustomPalette: bool,
  id: string.isRequired,
  input: shape({
    onChange: func,
    value: string,
  }),
  label: oneOfType([node, string]),
  onExiting: func,
  placeholder: string,
  style: object,
  themeStyles: object,
}

const styles = theme => ({
  color: {
    borderRadius: "2px",
    height: "100%",
    width: "100%",
  },
  swatch: {
    height: theme.spacing(4),
    padding: theme.spacing(0.5),
    background: "#fff",
    borderWidth: 0,
    outline: "none",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    cursor: "pointer",
    marginTop: theme.spacing(3),
    "&:disabled": {
      cursor: "auto",
    },
  },
  popover: {
    position: "absolute",
    zIndex: "2",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
})

const mapState = state => ({
  themeStyles: state.session.team?.themeStyles || {},
})

export default connect(mapState)(withStyles(styles)(ColorInput))
