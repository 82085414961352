import {func, string} from "prop-types"
import {connect} from "react-redux"

import CreateJourney from "./create-journey"
import {onCreateJourney} from "./journey-actions"

const mapStateToProps = (
  {journey, session, template},
  {
    match: {
      params: {templateId},
    },
  }
) => ({
  initialValues: {
    ownerId: session.user && session.user.id,
  },
  template,
  templateId,
  users: session.teamUsers,
})

const addActionsToProps = {
  createJourney: onCreateJourney,
}

const CreateJourneyContainer = ({createJourney, templateId, ...props}) => {
  const onSubmit = params =>
    createJourney(
      templateId,
      Object.entries(params)
        // eslint-disable-next-line no-unused-vars
        .filter(([key, value]) => !!value)
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {source: "direct"})
    )

  return <CreateJourney onSubmit={onSubmit} templateId={templateId} {...props} />
}

CreateJourneyContainer.propTypes = {
  createJourney: func.isRequired,
  templateId: string.isRequired,
}

export default connect(mapStateToProps, addActionsToProps)(CreateJourneyContainer)
