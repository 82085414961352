import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {MdWeb as WebPageIcon} from "react-icons/md"
import shortid from "shortid"

const WebpageEmbedButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {
        url: null,
        service: null,
      },
      type: "webpage",
    })
    onClose()
  }

  return (
    <>
      <MenuItem onClick={onClick}>
        <ListItemIcon classes={{root: classes.icon}}>
          <WebPageIcon />
        </ListItemIcon>
        <ListItemText primary="Webpage Embed" ref={ref} />
      </MenuItem>
    </>
  )
})

WebpageEmbedButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func.isRequired,
}

export {WebPageIcon}

export default WebpageEmbedButton
