import {IconButton, InputAdornment, TextField} from "@mui/material"
import {string} from "prop-types"
import {Component} from "react"
import {MdContentCopy as CopyIcon} from "react-icons/md"

class CopyTextInput extends Component {
  copyValue = () => require("copy-to-clipboard")(this.props.value)

  render() {
    return (
      <TextField
        {...this.props}
        fullWidth={true}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={this.copyValue} size="medium">
                <CopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        margin="normal"
      />
    )
  }
}

CopyTextInput.propTypes = {
  helperText: string,
  label: string,
  value: string.isRequired,
}

export default CopyTextInput
