import Immutable from "immutable"

import Blockquote from "./blockquote"
import Header from "./header"
import {OrderedList, UnorderedList} from "./lists"
import Paragraph from "./unstyled"

const blockRenderMap = Immutable.Map({
  unstyled: {
    element: "div",
    wrapper: <Paragraph />,
  },
  "header-one": {
    wrapper: <Header variant="h1" />,
  },
  "header-two": {
    wrapper: <Header variant="h2" />,
  },
  "header-three": {
    wrapper: <Header variant="h3" />,
  },
  "header-four": {
    wrapper: <Header variant="h4" />,
  },
  "header-five": {
    wrapper: <Header variant="h5" />,
  },
  "header-six": {
    wrapper: <Header variant="h6" />,
  },
  blockquote: {
    wrapper: <Blockquote />,
  },
  "unordered-list-item": {
    wrapper: <UnorderedList />,
  },
  "ordered-list-item": {
    wrapper: <OrderedList />,
  },
})

export default blockRenderMap
