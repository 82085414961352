import {bool, string} from "prop-types"
import {Component} from "react"

export default class WistiaReadonly extends Component {
  componentDidMount() {
    if (document.getElementById("wistia-script")) return

    const script = document.createElement("script")

    script.src = "https://fast.wistia.com/assets/external/E-v1.js"
    script.id = "wistia-script"
    script.async = true
    script.dataset.testid = "wistia-script"

    document.body.appendChild(script)
  }

  componentWillUnmount = () => {
    document.getElementById("wistia-script").remove()
  }

  render() {
    return (
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          pointerEvents: this.props.isEditMode ? "none" : "auto",
        }}
      >
        <div
          data-testid="wistia-container"
          className={`wistia_embed wistia_async_${this.props.id} videoFoam=true`}
          style={{height: "100%", width: "100%"}}
        />
      </div>
    )
  }
}

WistiaReadonly.propTypes = {
  id: string.isRequired,
  isEditMode: bool,
}
