import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import {array, bool, number, shape, string} from "prop-types"
import React, {useContext, useEffect, useState} from "react"

import DOSelect from "components/do-select/do-select"
import {RewardSetFormContext} from "components/reward/reward-set-form-context-provider"
import {compileValues, isDirty} from "components/reward/reward-set-form-helpers"
import {
  ConditionCard,
  ConditionCardContent,
  Form,
} from "components/reward/reward-step-conditions/styled-components"
import {RewardsContext} from "components/reward/rewards-context-provider"

import {fetchCardOnFileTargetingValues} from "lib/api.js"
import {nonEmptyArray, requiredField, validPositiveInteger} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const CardOnFileSpecificMerchants = ({initialValues, isEditable}) => {
  const {dispatch} = useContext(RewardSetFormContext)
  const {templates} = useContext(RewardsContext)
  const [merchants, setMerchants] = useState([])

  // Form config
  const {change, field, inputs} = useForm({
    initialValues: {
      anyTemplates: !!initialValues.meta.anyTemplates ? initialValues.meta.anyTemplates : "true",
      requiredMerchants: !!initialValues.meta.requiredMerchants
        ? initialValues.meta.requiredMerchants
        : [],
      requiredTemplates: !!initialValues.meta.requiredTemplates
        ? initialValues.meta.requiredTemplates
        : [],
      value: initialValues.value ? parseInt(initialValues.value) : undefined,
    },
    validators: {
      anyTemplates: [requiredField],
      requiredMerchants: [nonEmptyArray],
      requiredTemplates: [nonEmptyArray],
      value: [validPositiveInteger],
    },
  })

  // When inputs change, dispatch updated step state
  useEffect(() => {
    const {value, ...meta} = compileValues(inputs)
    const updated = {
      data: {
        condition: "card_on_file_specific",
        id: initialValues.id,
        meta,
        value,
      },
      isDirty: isDirty(inputs),
      isValid: Object.values(inputs).reduce((acc, fieldObject) => {
        const shouldNotValidate =
          !acc || (inputs.anyTemplates.value === "true" && fieldObject.name === "requiredTemplates")

        if (shouldNotValidate) {
          return acc
        }

        if (
          fieldObject.name === "anyTemplates" &&
          !requiredField(initialValues.meta.anyTemplates)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "requiredMerchants" &&
          !nonEmptyArray(initialValues.meta.requiredMerchants)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "requiredTemplates" &&
          !nonEmptyArray(initialValues.meta.requiredTemplates)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (fieldObject.name === "value" && !validPositiveInteger(initialValues.value)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        return "error" in fieldObject ? !fieldObject.error : false
      }, true),
    }

    dispatch({type: "SET_REWARD_STEP_FORM", rewardStepForm: updated})
  }, [dispatch, initialValues, inputs])

  useEffect(() => {
    fetchCardOnFileTargetingValues().then(data => setMerchants(data.merchants.map(e => e.name)))
  }, [])

  return (
    templates?.length && (
      <ConditionCard>
        <ConditionCardContent>
          <Form id={`card-on-file-any-${initialValues.id}`}>
            <Container sx={{paddingTop: "1em"}} />
            <FormControl disabled={!isEditable} fullWidth>
              <DOSelect
                label="Which specific merchants are required to be switched?"
                multiple
                renderValue={merchants => merchants.join(", ")}
                {...field("requiredMerchants")}
                onChange={e => change("requiredMerchants", e.target.value)}
              >
                {merchants.map(merchant => (
                  <MenuItem key={merchant} value={merchant}>
                    <Checkbox
                      checked={inputs.requiredMerchants.value.includes(merchant)}
                      sx={{pointerEvents: "none"}}
                      color={"primary"}
                    />
                    {merchant}
                  </MenuItem>
                ))}
              </DOSelect>
            </FormControl>

            <Container sx={{paddingTop: "1em"}} />
            <FormLabel>Which templates are eligible?</FormLabel>
            <RadioGroup {...field("anyTemplates", {exclude: ["error", "helperText"]})}>
              <FormControlLabel
                value="true"
                control={<Radio disabled={!isEditable} />}
                label="Any Template"
              />
              <FormControlLabel
                value="false"
                control={<Radio disabled={!isEditable} />}
                label="Specific Templates Only"
              />
            </RadioGroup>

            {inputs.anyTemplates.value === "false" && (
              <>
                <Container sx={{paddingTop: "1em"}} />
                <FormControl fullWidth>
                  <DOSelect
                    disabled={!isEditable}
                    multiple
                    label="Campaigns"
                    {...field("requiredTemplates")}
                    renderValue={templateIds =>
                      templateIds.map(id => templates.find(t => t.id === id).name).join(", ")
                    }
                  >
                    {templates.map(template => (
                      <MenuItem key={template.id} value={template.id}>
                        <Checkbox
                          checked={inputs.requiredTemplates.value.includes(template.id)}
                          sx={{pointerEvents: "none"}}
                          color={"primary"}
                        />
                        {template.name.length < 32
                          ? template.name
                          : `${template.name.slice(0, 31)} ...`}
                      </MenuItem>
                    ))}
                  </DOSelect>
                </FormControl>
              </>
            )}

            <Container sx={{paddingTop: "1em"}} />
            <TextField
              disabled={!isEditable}
              label="Value"
              fullWidth={true}
              type="number"
              {...field("value")}
            />

            <Container sx={{paddingTop: "1em"}} />
          </Form>
        </ConditionCardContent>
      </ConditionCard>
    )
  )
}

CardOnFileSpecificMerchants.defaultProps = {
  initialValues: {
    condition: "card_on_file_specific",
    meta: {
      anyMerchants: "true",
      anyTemplates: "true",
      requiredSwitches: undefined,
      requiredTemplates: [],
    },
    value: undefined,
    validDefaults: [],
  },
}

CardOnFileSpecificMerchants.propTypes = {
  initialValues: shape({
    condition: string,
    id: string.isRequired,
    meta: shape({
      anyMerchants: string,
      anyTemplates: string,
      requiredSwitches: number,
      requiredTemplates: array,
    }),
    value: number,
  }).isRequired,
  isEditable: bool,
}

export default CardOnFileSpecificMerchants
