// This file was generated automatically from character-19.svg with the following command:
// ./svg-converter.js src/components/superhero/character-19.svg
import SuperheroSvg from "./superhero-svg"

const Character19 = props => (
  <SuperheroSvg viewBox="150 90 80 220" {...props}>
    <g id="Layer_22">
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M159.38,306.97c4.41,1.03,10.64,2.04,17.75,1.9l0.09-0.01c0.09-0.02,2.2-0.58,2.44-2.03
  				c0.18-1.09-0.71-2.25-2.72-3.55l-0.06-0.03c-0.09-0.04-9.26-3.99-9.74-7.67l-0.78,0.09c0.53,4.03,9.46,7.95,10.17,8.26
  				c2.1,1.36,2.44,2.26,2.35,2.77c-0.14,0.87-1.57,1.33-1.83,1.41c-12.95,0.25-22.96-3.38-24.08-3.8c-0.96-1.49,1.96-6.34,4.06-9.1
  				l-0.63-0.46c-0.01,0.02-1.46,1.92-2.68,4.06c-1.72,3.03-2.15,5.01-1.32,6.04l0.06,0.08l0.1,0.04
  				C152.63,304.99,155.18,305.99,159.38,306.97z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M173.07,308.69l0.61-0.47c-0.7-0.87-0.98-1.74-0.82-2.57c0.3-1.58,2.03-2.59,2.05-2.6l-0.4-0.66
  				c-0.08,0.05-2.05,1.2-2.42,3.11C171.89,306.57,172.22,307.64,173.07,308.69z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M159.21,304.12c2.99,0.7,7.22,1.47,13.17,2.2l0.09-0.76c-16.25-1.98-19.55-4.34-19.58-4.36
  				l-0.48,0.6C152.49,301.86,153.86,302.87,159.21,304.12z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M172.75,305.3l0.31-0.7c-10.99-4.67-10.4-8.92-10.39-8.96l-0.77-0.14
  				C161.86,295.7,161.09,300.35,172.75,305.3z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M170.66,303.19c0.63,0.15,1.53-0.13,1.84-0.24l-0.26-0.72c-0.59,0.2-1.28,0.3-1.44,0.21
  				c0-0.01,0-0.02,0-0.03c0.16-1.33-0.24-1.38-2.19-1.61c-0.76-0.09-1.31-0.16-1.43-0.3c-0.12-0.14-0.05-0.68,0.04-1.42
  				c0.08-0.62-0.07-1.04-0.43-1.23c-0.7-0.39-1.83,0.4-2.16,0.66l0.48,0.6c0.54-0.41,1.16-0.67,1.3-0.59c0,0,0.08,0.09,0.03,0.48
  				c-0.26,2.12-0.1,2.31,2.07,2.57c0.77,0.09,1.43,0.17,1.54,0.27c0.02,0.05-0.01,0.29-0.04,0.5c-0.04,0.29,0.06,0.55,0.27,0.71
  				C170.4,303.11,170.52,303.16,170.66,303.19z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M209.33,308.3c4.5,0.48,10.82,0.72,17.85-0.29l0.09-0.02c0.09-0.03,2.11-0.85,2.16-2.32
  				c0.04-1.1-0.99-2.15-3.14-3.19l-0.06-0.02c-0.1-0.03-9.68-2.82-10.62-6.41l-0.76,0.19c1.03,3.93,10.39,6.73,11.13,6.95
  				c2.25,1.09,2.7,1.94,2.68,2.46c-0.03,0.88-1.39,1.51-1.64,1.62c-12.82,1.84-23.2-0.54-24.37-0.82c-1.14-1.36,1.15-6.53,2.89-9.53
  				l-0.68-0.38c-0.01,0.02-1.21,2.08-2.15,4.35c-1.33,3.22-1.51,5.23-0.55,6.16l0.07,0.07l0.1,0.03
  				C202.38,307.17,205.03,307.84,209.33,308.3z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M223.12,308.34l0.55-0.54c-0.81-0.78-1.19-1.6-1.13-2.45c0.1-1.6,1.69-2.82,1.71-2.83l-0.48-0.6
  				c-0.08,0.06-1.89,1.44-2.01,3.39C221.69,306.37,222.15,307.39,223.12,308.34z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M208.8,305.5c3.05,0.33,7.35,0.57,13.34,0.56l0-0.76c-16.37,0.03-19.94-1.9-19.97-1.92l-0.41,0.65
  				C201.85,304.08,203.33,304.92,208.8,305.5z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M222.39,305.01l0.22-0.73c-11.49-3.28-11.44-7.57-11.44-7.61l-0.78-0.05
  				C210.38,296.82,210.19,301.53,222.39,305.01z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M220.05,303.17c0.64,0.07,1.51-0.32,1.8-0.46l-0.35-0.68c-0.56,0.28-1.23,0.46-1.41,0.38
  				c0-0.01,0-0.02,0-0.03c-0.01-1.34-0.41-1.34-2.37-1.33c-0.77,0-1.32,0.01-1.45-0.12c-0.13-0.13-0.14-0.67-0.14-1.41
  				c0-0.63-0.2-1.02-0.58-1.17c-0.75-0.3-1.77,0.63-2.06,0.92l0.55,0.54c0.49-0.48,1.07-0.81,1.21-0.75c0,0,0.09,0.08,0.09,0.47
  				c0.01,2.13,0.19,2.3,2.38,2.29c0.77,0,1.44-0.01,1.56,0.08c0.02,0.05,0.02,0.29,0.03,0.5c0,0.3,0.13,0.54,0.36,0.67
  				C219.77,303.12,219.9,303.16,220.05,303.17z"
          />
        </g>
      </g>
      <path
        style={{fill: "#B4917C"}}
        d="M209.52,192.53c0,0,0.38-0.51,0.63-0.34c0.24,0.17,0.52,1.04-0.15,1.9
  		c-0.44,0.57-1.77,3.22-0.14,5.17c0,0,2.39,1.81,4.59-0.25c1.65-1.55,2.62-3.12,2.51-6.32c-0.1-2.98-1.15-4.5-1.41-5.45
  		c-0.03-3.23-0.05-6.45-0.08-9.68c0-0.47-0.01-0.94-0.01-1.41l-6.09,0.48c0.03,3.29,0.06,6.59,0.08,9.88c0,0.34,0.01,0.68,0.01,1.02
  		c-0.77,0.63-2.49,2.18-3.03,3.84c0,0-0.37,0.75-0.14,1.8C206.51,194.22,208.6,193.87,209.52,192.53z"
      />
      <path
        style={{fill: "#123252"}}
        d="M204.49,110.5c0.58,1.7-0.9,3.77-0.79,5.48c0.11,1.77,1.83,3.65,1.47,5.34
  		c-0.36,1.72-2.71,2.79-3.51,4.35c-0.8,1.56-0.27,4.03-1.46,5.35c-1.18,1.3-3.75,1.09-5.27,2.07c-1.47,0.94-2.27,3.34-4.02,3.91
  		c-1.75,0.57-3.87-0.88-5.62-0.77c-1.81,0.11-3.74,1.78-5.48,1.43c-1.77-0.35-2.86-2.64-4.47-3.42c-1.6-0.78-4.14-0.26-5.49-1.43
  		c-1.33-1.15-1.12-3.65-2.12-5.13c-0.97-1.43-3.43-2.21-4.01-3.91c-0.58-1.7,0.9-3.77,0.79-5.48c-0.11-1.77-1.83-3.65-1.47-5.34
  		c0.36-1.72,2.71-2.79,3.51-4.35c0.8-1.56,0.27-4.03,1.46-5.35c1.18-1.3,3.75-1.09,5.27-2.07c1.47-0.94,2.27-3.34,4.02-3.91
  		c1.75-0.57,3.87,0.88,5.62,0.77c1.81-0.11,3.74-1.78,5.48-1.43c1.77,0.35,2.86,2.64,4.47,3.42c1.6,0.78,4.14,0.26,5.49,1.43
  		c1.33,1.15,1.12,3.65,2.12,5.13C201.44,108.01,203.9,108.8,204.49,110.5z"
      />
      <g>
        <defs>
          <rect
            id="SVGID_00000031892957720570343310000015357011992605865871_"
            x="142.34"
            y="86.71"
            width="184.63"
            height="139.14"
          />
        </defs>
        <clipPath id="128739b1-SVGID_00000152265044398669076670000006665126310251738529_">
          <use
            xlinkHref="#SVGID_00000031892957720570343310000015357011992605865871_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <g style={{clipPath: "url(#SVGID_00000152265044398669076670000006665126310251738529_)"}}>
          <path
            style={{fill: "#B4917C"}}
            d="M210.43,165.24L210.43,165.24L210.43,165.24c0.57-0.57,1.13-1.13,1.7-1.7
  				c-1.08,1.08-2.15,2.15-3.23,3.23c-0.9-1.86-1.79-3.72-2.69-5.57c-1.44-2.98-2.87-5.95-4.31-8.93c-0.33-0.68-0.66-1.36-0.99-2.04
  				c-0.28-0.59-0.37-1.18-0.07-1.77c0.28-0.54,0.95-1.09,1.7-1.3c1.51-0.41,3.59,0.03,4.19,1.28c0.9,1.86,1.79,3.72,2.69,5.58
  				c1.44,2.98,2.87,5.95,4.31,8.93c0.33,0.68,0.66,1.36,0.99,2.04c0.38,0.79,0.36,1.61-0.32,2.3c-0.99,1.01-2.91,0.92-2.91,0.92
  				s0,0,0,0c-0.01,0-2.51-0.72-2.58-1.44C208.82,166.08,210.42,165.25,210.43,165.24z"
          />
          <path
            style={{fill: "#B4917C"}}
            d="M211.46,182.21L211.46,182.21L211.46,182.21c0.75-0.29,1.5-0.59,2.25-0.88
  				c-1.43,0.56-2.85,1.12-4.28,1.68c-0.04-1.86-0.07-3.72-0.11-5.58c-0.06-2.98-0.12-5.96-0.18-8.94c-0.01-0.68-0.03-1.36-0.04-2.04
  				c-0.01-0.59,0.16-1.12,0.68-1.52c0.48-0.37,1.32-0.62,2.08-0.55c1.54,0.15,3.24,1.19,3.27,2.44c0.04,1.86,0.07,3.72,0.11,5.58
  				c0.06,2.98,0.12,5.96,0.18,8.94c0.01,0.68,0.03,1.36,0.04,2.04c0.02,0.79-0.35,1.48-1.25,1.84c-1.32,0.53-3.03-0.16-3.03-0.16
  				s0,0,0,0c-0.01-0.01-1.98-1.42-1.75-2.05C209.65,182.4,211.45,182.21,211.46,182.21z"
          />
          <g>
            <path
              style={{fill: "#609E92"}}
              d="M202.02,152.72c-1.57-2.41-3.14-4.83-4.71-7.24c-0.23-0.35-0.45-0.69-0.68-1.04
  					c-0.46-0.7-0.66-1.6-0.5-2.42c0.16-0.8,0.72-1.69,1.45-2.12c1.54-0.9,3.65-0.62,4.66,0.93l0,0c1.57,2.41,3.14,4.83,4.71,7.24
  					c0.23,0.35,0.45,0.69,0.68,1.04L202.02,152.72z"
            />
          </g>
          <path
            style={{fill: "#B4917C"}}
            d="M201.56,121.2c0,6.9-4.97,12.74-11.35,14.77l0.95,12.66l-6.45,0l-0.37-11.95
  				c-4.42-0.34-8.32-2.44-10.99-5.58c-0.57,0.4-1.26,0.63-2.02,0.63c-1.92,0-3.48-1.52-3.48-3.39c0-1.49,0.98-2.75,2.35-3.21
  				c0-0.02-0.01-0.03-0.01-0.05c0.11-0.06,0.22-0.12,0.33-0.18l30.85-6.12C201.48,119.58,201.56,120.38,201.56,121.2z"
          />
          <path
            style={{fill: "#B4917C"}}
            d="M177.94,117.24l21.16-4.2c1.14,1.74,1.92,3.72,2.26,5.85l-30.53,6.06
  				C174.04,123.27,176.57,120.55,177.94,117.24z"
          />
          <g>
            <path
              style={{fill: "#5DBDAF"}}
              d="M184.43,139.17l-2.78,0.39c-4.98,0.66-10.09,6.78-9.41,11.63l1.87,12.38l32.45,0.3l-3.01-17.66
  					c-0.9-5.6-3.93-7.96-10.24-7.58l-2.91,0.17l-2.09,2.91L184.43,139.17z"
            />
            <polygon
              style={{fill: "#5DBDAF"}}
              points="174.11,163.57 206.56,163.87 208.12,173.04 177.85,179.6 				"
            />
          </g>
          <path
            style={{fill: "#B4917C"}}
            d="M166.48,197.54c0,0-0.38-0.51-0.63-0.34c-0.24,0.17-0.52,1.04,0.15,1.9
  				c0.44,0.57,1.77,3.22,0.14,5.17c0,0-2.39,1.81-4.59-0.25c-1.65-1.55-2.62-3.12-2.51-6.32c0.1-2.98,1.15-4.5,1.41-5.45
  				c0.03-3.23,0.05-6.45,0.08-9.68c0-0.47,0.01-0.94,0.01-1.41l6.09,0.48c-0.03,3.29-0.06,6.59-0.08,9.88
  				c0,0.34-0.01,0.68-0.01,1.02c0.77,0.63,2.49,2.18,3.03,3.84c0,0,0.37,0.75,0.14,1.8C169.49,199.22,167.4,198.87,166.48,197.54z"
          />
          <path
            style={{fill: "#B4917C"}}
            d="M196.39,108.89c-0.41-0.61-0.94-1.12-1.58-1.63c-0.72-0.56-1.17-0.75-1.58-0.48
  				c-0.24,0.16-0.46,0.47-0.71,0.96c-0.46,0.89-0.92,1.65-1.86,2.15c-1.35,0.73-2.27,0.26-3.62,0.16c-1.18-0.09-1.05,0.8-1.31,1.63
  				c-0.27,0.89-0.84,1.66-1.63,2.17c-1.42,0.92-3.4,0.94-4.83,0.03c-0.21-0.13-0.44-0.22-0.69-0.21c-0.8,0.04-0.84,0.98-1.03,1.66
  				c-0.39,1.42-2.08,2.19-3.58,2.07c-2.43-0.18-3.31-1.51-3.93-0.72c-0.02,0.03-0.04,0.06-0.06,0.09c-0.16,0.26-0.31,0.68-0.47,1.34
  				c-0.16,0.65-0.2,1.88-0.04,3.37c0.36,8.44,7.49,15.18,16.24,15.18c8.98,0,16.26-7.09,16.26-15.84
  				C201.97,116.06,199.81,111.79,196.39,108.89z"
          />
          <ellipse style={{fill: "#0D1E36"}} cx="193.05" cy="117.74" rx="1.27" ry="1.24" />
          <ellipse style={{fill: "#0D1E36"}} cx="182.17" cy="119.51" rx="1.27" ry="1.24" />
          <path
            style={{fill: "#FFFFFF"}}
            d="M183.76,126.99c0.09-0.14,0.28-0.16,0.41-0.06c0.31,0.23,0.88,0.72,1.7,0.91
  				c1.12,0.26,2.12-0.14,2.34,0.15c0.13,0.16-0.04,0.35-0.36,0.59c-0.32,0.24-1.28,0.69-2.3,0.47c-1.08-0.24-1.85-1.49-1.83-1.93
  				C183.72,127.06,183.74,127.02,183.76,126.99z"
          />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M188.36,120.41c0.33,0.64,0.65,1.28,0.98,1.91c0.05,0.09,0.09,0.18,0.14,0.27
  						c0.07-0.18,0.15-0.37,0.22-0.55c-0.5,0.12-1,0.24-1.49,0.36c-0.2,0.05-0.31,0.27-0.26,0.46c0.06,0.2,0.26,0.31,0.46,0.26
  						c0.5-0.12,1-0.24,1.49-0.36c0.25-0.06,0.33-0.34,0.22-0.55c-0.33-0.64-0.65-1.28-0.98-1.91c-0.05-0.09-0.09-0.18-0.14-0.27
  						c-0.09-0.18-0.35-0.23-0.51-0.13C188.31,120.01,188.27,120.23,188.36,120.41L188.36,120.41z"
              />
            </g>
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000152265044398669076670000006665126310251738529_)",
            fill: "#B4917C",
          }}
          d="M165.46,168.28
  			L165.46,168.28L165.46,168.28c-0.57-0.57-1.13-1.13-1.7-1.7c1.08,1.08,2.15,2.15,3.23,3.23c0.9-1.86,1.79-3.72,2.69-5.57
  			c1.44-2.98,2.87-5.95,4.31-8.93c0.33-0.68,0.66-1.36,0.99-2.04c0.28-0.59,0.37-1.18,0.07-1.77c-0.28-0.54-0.95-1.09-1.7-1.3
  			c-1.51-0.41-3.59,0.03-4.19,1.28c-0.9,1.86-1.79,3.72-2.69,5.58c-1.44,2.98-2.87,5.95-4.31,8.93c-0.33,0.68-0.66,1.36-0.99,2.04
  			c-0.38,0.79-0.36,1.61,0.32,2.3c0.99,1.01,2.91,0.92,2.91,0.92s0,0,0,0c0.01,0,2.51-0.72,2.58-1.44
  			C167.07,169.12,165.47,168.29,165.46,168.28z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000152265044398669076670000006665126310251738529_)",
            fill: "#5DBDAF",
          }}
          d="M169.38,150.81
  			c1.53-2.44,3.05-4.88,4.58-7.33c0.22-0.35,0.44-0.7,0.66-1.05c0.44-0.71,1.18-1.28,2.01-1.51c0.81-0.22,1.88-0.12,2.59,0.33
  			c1.51,0.95,2.19,2.92,1.21,4.49l0,0c-1.53,2.44-3.05,4.88-4.58,7.33c-0.22,0.35-0.44,0.7-0.66,1.05L169.38,150.81z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000152265044398669076670000006665126310251738529_)",
            fill: "#B4917C",
          }}
          d="M164.43,185.25
  			L164.43,185.25L164.43,185.25c-0.75-0.29-1.5-0.59-2.25-0.88c1.43,0.56,2.85,1.12,4.28,1.68c0.04-1.86,0.07-3.72,0.11-5.58
  			c0.06-2.98,0.12-5.96,0.18-8.94c0.01-0.68,0.03-1.36,0.04-2.04c0.01-0.59-0.16-1.12-0.68-1.52c-0.48-0.37-1.32-0.62-2.08-0.55
  			c-1.54,0.15-3.24,1.19-3.27,2.44c-0.04,1.86-0.07,3.72-0.11,5.58c-0.06,2.98-0.12,5.96-0.18,8.94c-0.01,0.68-0.03,1.36-0.04,2.04
  			c-0.02,0.79,0.35,1.48,1.25,1.84c1.32,0.53,3.03-0.16,3.03-0.16s0,0,0,0c0.01-0.01,1.98-1.42,1.75-2.05
  			C166.24,185.44,164.44,185.25,164.43,185.25z"
        />
      </g>
      <g>
        <ellipse style={{fill: "#F2D4CF"}} cx="172.26" cy="128.01" rx="1.31" ry="1.28" />
        <path
          style={{fill: "#F2D4CF"}}
          d="M169.6,130.94c-0.08-0.02-0.16-0.04-0.24-0.06c-1.43-0.41-2.39-1.78-2.26-3.23
  			c0.15-1.67,1.63-2.93,3.35-2.82c0,0,0.01,0,0.01,0c0.21,0.01,0.26,0.29,0.07,0.37c-1.02,0.46-1.76,1.45-1.83,2.62
  			c-0.06,1.03,0.4,1.98,1.16,2.58C170.11,130.6,169.92,131,169.6,130.94z"
        />
        <path
          style={{fill: "none", stroke: "#F2D4CF", strokeWidth: "0.7877", strokeMiterlimit: "10"}}
          d="M169.88,125.12c0.42-0.17,1.06-0.07,1.4,0.1
  			c0.4,0.19,0.81,0.62,0.93,1.14c0.17,0.74,0.09,0.79,0.09,1.08"
        />
      </g>
      <g>
        <path
          style={{fill: "#0A273F"}}
          d="M218.87,250l-8.12-61.9l-21.56-2.42l4.97,18.16l4.47,52.25c0,0-1.15,16.58-1.85,42.01l25.81-1.63
  			C222.59,296.47,220.81,269.25,218.87,250z"
        />
      </g>
      <g>
        <path
          style={{fill: "#123252"}}
          d="M174.8,251.96l2.44-58.15l0.71-14.37l4.74-1.24l25.24-7.09l2.93,15.81
  			c0.2,0.79,0.36,1.59,0.47,2.42l0.25,1.31l-0.13,0.02c0.01,0.17,0.03,0.34,0.03,0.51c0.37,9.12-5.26,17.02-13.06,19.11l-0.11,0.36
  			l-3.56,37.95c0,0-0.9,8.94-3.61,14.49l-15.93,34.91l-24.71-3.08l21.01-35.04C171.51,259.86,174.26,255.87,174.8,251.96z"
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Character19
