import {TextField} from "@mui/material"
import debounce from "lodash/debounce"
import {func, object} from "prop-types"
import {Component} from "react"

import {formify} from "lib/hooks/use-form.js"

class NextPageSettings extends Component {
  onChange = () => this.props.handleSubmit()
  debouncedOnChange = debounce(this.onChange, 1000)

  render() {
    const {classes, nextPage, field} = this.props

    return (
      <form onChange={this.debouncedOnChange}>
        <TextField
          className={classes.field}
          fullWidth={true}
          InputLabelProps={{shrink: true}}
          label="Next Page Button Navigation Title"
          placeholder={nextPage.navigationTitle}
          {...field("cta")}
        />
      </form>
    )
  }
}

NextPageSettings.propTypes = {
  classes: object.isRequired,
  field: func,
  handleSubmit: func.isRequired,
  nextPage: object.isRequired,
}

export default formify({
  form: "nextPageSettings",
  enableReinitialize: true,
})(NextPageSettings)
