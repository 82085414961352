import {Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {node, object, string} from "prop-types"

import Box from "../box/box"

const CenteredBox = ({children, variant, classes, errorMessage, title}) => (
  <div className={classes.root}>
    <div className={classes.content}>
      <img
        alt=""
        className={classes.logo}
        src="https://uploads.digitalonboarding.com/do_logo_long.png"
      />
      <Box className={cx({[classes.errorBox]: variant === "error"})}>
        <Typography
          className={cx({[classes.errorTitle]: variant === "error"})}
          gutterBottom={variant !== "error"}
          variant="subtitle2"
        >
          {title}
        </Typography>
        {errorMessage && (
          <Typography
            className={cx({[classes.errorMessage]: variant === "error"})}
            color={variant === "error" ? "initial" : "error"}
            gutterBottom={true}
            variant="caption"
          >
            {errorMessage}
          </Typography>
        )}
        {children}
      </Box>
    </div>
  </div>
)

const styles = theme => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    justifyContent: "center",
  },
  content: {
    alignSelf: "center",
    width: 355,
  },
  logo: {
    width: 246,
    marginBottom: 30,
  },
  errorBox: {
    padding: 0,
  },
  errorTitle: {
    color: "#FFF",
    padding: 20,
    backgroundColor: theme.palette.error.main,
  },
  errorMessage: {
    display: "block",
    padding: 20,
  },
})

CenteredBox.propTypes = {
  children: node,
  classes: object.isRequired,
  errorMessage: string,
  title: string.isRequired,
  variant: string,
}

export default withStyles(styles)(CenteredBox)
