// This file was generated automatically from character-12.svg with the following command:
// ./svg-converter.js src/components/superhero/character-12.svg
import SuperheroSvg from "./superhero-svg"

const Character12 = props => (
  <SuperheroSvg viewBox="20 60 140 230" {...props}>
    <g id="Layer_14">
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M54.23,283.36c4.47,1.29,10.82,2.68,18.15,3.02l0.1-0.01c0.09-0.02,2.31-0.42,2.67-1.8
  				c0.27-1.04-0.56-2.22-2.53-3.6l-0.06-0.03c-0.09-0.04-9.23-4.47-9.44-8.05l-0.81,0.04c0.23,3.92,9.14,8.3,9.84,8.65
  				c2.06,1.45,2.34,2.34,2.21,2.83c-0.22,0.83-1.72,1.18-1.99,1.24c-13.38-0.62-23.41-4.79-24.54-5.27c-0.87-1.5,2.51-5.99,4.9-8.51
  				l-0.61-0.48c-0.02,0.02-1.66,1.75-3.08,3.74c-2.01,2.81-2.61,4.69-1.83,5.74l0.06,0.08l0.1,0.04
  				C47.42,281,49.97,282.13,54.23,283.36z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M68.2,285.94l0.67-0.41c-0.66-0.89-0.87-1.74-0.64-2.54c0.43-1.5,2.3-2.37,2.32-2.37l-0.36-0.66
  				c-0.09,0.04-2.21,1.02-2.74,2.84C67.16,283.81,67.41,284.86,68.2,285.94z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M54.27,280.6c3.03,0.87,7.33,1.9,13.41,3l0.16-0.72c-16.6-3-19.82-5.49-19.85-5.51l-0.55,0.54
  				C47.52,277.97,48.85,279.04,54.27,280.6z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M68.15,282.64l0.37-0.65c-10.97-5.24-10.03-9.3-10.02-9.34l-0.78-0.19
  				C57.67,272.65,56.51,277.09,68.15,282.64z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M66.16,280.47c0.64,0.18,1.59-0.03,1.92-0.11l-0.22-0.71c-0.63,0.16-1.34,0.21-1.51,0.1
  				c0,0,0-0.02,0.01-0.03c0.27-1.28-0.14-1.35-2.13-1.7c-0.78-0.14-1.34-0.24-1.45-0.38c-0.11-0.15,0-0.66,0.15-1.37
  				c0.13-0.59,0.01-1-0.35-1.22c-0.7-0.42-1.92,0.27-2.28,0.49l0.45,0.61c0.59-0.36,1.25-0.57,1.38-0.48c0,0,0.08,0.09,0,0.46
  				c-0.43,2.03-0.29,2.22,1.93,2.62c0.79,0.14,1.46,0.26,1.57,0.36c0.01,0.05-0.03,0.28-0.08,0.48c-0.06,0.28,0.02,0.53,0.23,0.71
  				C65.89,280.37,66.02,280.43,66.16,280.47z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M109.48,286.05c4.65,0.51,11.18,0.8,18.45-0.11l0.09-0.02c0.09-0.03,2.19-0.8,2.26-2.22
  				c0.05-1.07-1-2.09-3.22-3.11l-0.06-0.02c-0.1-0.03-9.98-2.82-10.92-6.31l-0.78,0.17c1.02,3.82,10.67,6.61,11.43,6.83
  				c2.32,1.08,2.77,1.9,2.75,2.41c-0.04,0.85-1.45,1.45-1.71,1.55c-13.27,1.65-23.98-0.74-25.19-1.02
  				c-1.17-1.33,1.25-6.31,3.08-9.19l-0.7-0.37c-0.01,0.02-1.27,2-2.26,4.19c-1.41,3.1-1.61,5.05-0.63,5.95l0.08,0.07l0.1,0.03
  				C102.31,284.89,105.04,285.57,109.48,286.05z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M123.74,286.22l0.57-0.52c-0.83-0.76-1.21-1.56-1.15-2.38c0.12-1.55,1.78-2.71,1.79-2.73
  				l-0.49-0.59c-0.08,0.06-1.96,1.37-2.11,3.26C122.28,284.3,122.74,285.3,123.74,286.22z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M108.96,283.34c3.15,0.34,7.59,0.62,13.79,0.67l0.01-0.74c-16.92-0.13-20.59-2.03-20.63-2.05
  				l-0.43,0.63C101.79,281.9,103.31,282.72,108.96,283.34z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M123.01,282.99l0.24-0.7c-11.85-3.28-11.75-7.43-11.75-7.47l-0.81-0.05
  				C110.68,274.95,110.44,279.51,123.01,282.99z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M120.61,281.19c0.66,0.07,1.56-0.3,1.86-0.43l-0.36-0.66c-0.58,0.26-1.28,0.43-1.46,0.36
  				c0-0.01,0-0.02,0-0.03c0.01-1.3-0.41-1.3-2.44-1.31c-0.79,0-1.36-0.01-1.5-0.13c-0.14-0.13-0.13-0.65-0.13-1.37
  				c0-0.61-0.19-0.99-0.59-1.14c-0.77-0.29-1.83,0.59-2.14,0.87l0.57,0.52c0.51-0.46,1.11-0.77,1.26-0.71c0,0,0.1,0.08,0.09,0.46
  				c-0.01,2.06,0.17,2.23,2.43,2.24c0.8,0,1.49,0.01,1.61,0.09c0.02,0.05,0.02,0.28,0.02,0.48c0,0.29,0.13,0.52,0.37,0.66
  				C120.33,281.14,120.46,281.17,120.61,281.19z"
          />
        </g>
      </g>
      <polygon
        style={{fill: "#0A273F"}}
        points="90.54,173.03 97.96,275.02 121,275.02 109.48,144.41 86.8,140.01 	"
      />
      <path
        style={{fill: "#123252"}}
        d="M88.47,195.11l-9.57,52.31l-25.06-2.65l-6.64,26.76L74,275.08l4.91-27.67l-25.06-2.65l17.73-79.28
  		c0.02-0.37,0.04-0.74,0.08-1.12c0.62-6.78,3.86-12.66,8.46-16.42l-0.01-0.14l29.56-4.74l0.89,18.92c0.11,0.95,0.16,1.91,0.16,2.9
  		l0.09,1.56l-0.15,0.01c-0.01,0.2-0.02,0.4-0.04,0.6c-0.97,10.74-8.53,19.22-17.66,20.61"
      />
      <g>
        <path
          style={{fill: "#E3B59D"}}
          d="M123.01,107.75c-0.77,2.68-1.54,5.36-2.31,8.04c-1.23,4.27-2.45,8.55-3.68,12.82
  			c-0.28,0.98-0.56,1.96-0.84,2.94c1.8-0.59,3.6-1.19,5.39-1.78c-1.61-1.78-3.23-3.55-4.84-5.33c-2.57-2.84-5.15-5.67-7.72-8.51
  			c-0.6-0.66-1.19-1.31-1.79-1.97c-0.48-0.53-1.58-0.78-2.3-0.73c-0.81,0.05-1.78,0.47-2.31,1.09c-0.54,0.62-0.93,1.42-0.88,2.23
  			c0.05,0.27,0.09,0.54,0.14,0.82c0.17,0.53,0.47,0.98,0.9,1.34c1.61,1.78,3.23,3.55,4.84,5.33c2.57,2.84,5.15,5.67,7.72,8.51
  			c0.6,0.66,1.19,1.31,1.79,1.97c0.71,0.78,2.17,0.89,3.16,0.56c1.13-0.37,1.92-1.26,2.23-2.34c0.77-2.68,1.54-5.36,2.31-8.04
  			c1.23-4.27,2.45-8.55,3.68-12.82c0.28-0.98,0.56-1.96,0.84-2.94c0.46-1.59-0.71-3.4-2.4-3.64
  			C125.17,105.03,123.5,106.06,123.01,107.75L123.01,107.75z"
        />
      </g>
      <g>
        <defs>
          <rect
            id="SVGID_00000151528080436132269420000010215729590090381241_"
            x="47.07"
            y="45.15"
            width="214.66"
            height="168.6"
          />
        </defs>
        <clipPath id="2c905866-SVGID_00000025440663906662768470000012641332048699560603_">
          <use
            xlinkHref="#SVGID_00000151528080436132269420000010215729590090381241_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <g style={{clipPath: "url(#SVGID_00000025440663906662768470000012641332048699560603_)"}}>
          <path
            style={{fill: "#5DBDAF"}}
            d="M95.97,115.07c-2.48-2.84,2.27-7.25,5.31-5.93c3.19,1.39,12.7,11.31,12.7,11.31l-6.08,4.76
  				C107.9,125.22,97.81,117.19,95.97,115.07z"
          />
          <g>
            <g>
              <path
                style={{fill: "#E3B59D"}}
                d="M77.65,117.37c-0.47,1.05-0.94,2.1-1.41,3.15c-0.6,1.35-1.58,3.4-3.39,7.57
  						c-0.35,0.82-0.2,0.47-1.21,2.84c-0.81,1.9-1.6,3.75-2.38,5.54c-1.97,4.54-3.45,7.93-4.28,9.5c-0.13,0.25-0.77,1.44-1.51,3.09
  						c-0.01,0.02-0.02,0.04-0.02,0.05c-0.71-1.64-1.42-3.29-2.13-4.93c2.07,0.25,4.15,0.5,6.22,0.75c3.28,0.4,6.56,0.79,9.83,1.19
  						c0.76,0.09,1.52,0.18,2.28,0.28c0.67,0.08,1.26,0.31,1.75,0.96c0.43,0.57,0.75,1.5,0.72,2.32c-0.06,1.64-1.09,3.45-2.47,3.28
  						c-2.07-0.25-4.15-0.5-6.22-0.75c-3.28-0.4-6.56-0.79-9.83-1.19c-0.76-0.09-1.52-0.18-2.28-0.28c-0.4-0.05-0.76-0.2-1.18-0.42
  						c-0.74-0.39-1.43-1.05-1.75-1.74c-0.19-0.4-0.44-1.18,0.69-4.33c0.47-1.3,0.81-2.01,1.74-4.09c1.13-2.54,0.98-2.3,1.71-3.89
  						c0.85-1.87,1.26-2.65,2.65-5.5c2.32-4.74,1.89-4.03,2.85-5.9c1.83-3.57,2.73-4.92,3.62-6.9c1.66-3.7,1.72-3.85,1.72-3.85
  						c0.01-0.02,0.02-0.04,0.02-0.05c0.66-1.48,2.25-2.14,3.38-1.18C77.88,113.84,78.36,115.79,77.65,117.37z"
              />
            </g>
          </g>
          <path
            style={{fill: "#E3B59D"}}
            d="M85.18,70.88c-0.6,0-1.19,0.03-1.78,0.09c-0.35,14.18-12.99,21.61-15.38,22.89
  				c2.09,7.65,8.97,13.27,17.15,13.27c9.83,0,17.8-8.11,17.81-18.12C102.99,79,95.02,70.89,85.18,70.88z"
          />
          <path
            style={{fill: "#E3B59D"}}
            d="M83.55,109.45c-1.73,0.44-4.56,1.33-4.56,1.33l1.09,5.29l8.24,3.53l8.67-3.97v-6.18
  				c0,0,0.43,0-2.74-0.19s-3.95-0.49-3.95-0.49l0-2.56c7.14-2.37,12.69-9.19,12.69-17.25c0-0.96-0.08-1.9-0.22-2.82l-34.49,7.15
  				c-0.12,0.07-0.24,0.14-0.37,0.21c0,0.02,0.14,0.44,0.14,0.46c-1.53,0.53-2.76,1.61-2.76,3.34c0,2.19,1.74,3.96,3.89,3.96
  				c0.84,0,1.62-0.27,2.26-0.74c2.98,3.66,7.35,6.11,12.29,6.51l0,1.73v0.69H83.55z"
          />
          <g>
            <path
              style={{fill: "#5DBDAF"}}
              d="M82.63,109.59c-3.69,0.47-6.94,0-9.54,3.09c-1.78,2.11-1.42,6.71-1.06,9.36l2.73,16.74l33.83,0
  					l-3.36-20.62c-0.46-2.95-1.23-6.33-2.64-8.13c-1.73-2.21-5.61-1.37-10.84-0.88c0,0,1.3,3.97-3.04,4.41
  					C88.7,113.56,82.63,114.88,82.63,109.59z"
            />
            <polygon
              style={{fill: "#5DBDAF"}}
              points="74.26,135.42 108.58,138.78 109.46,144.15 75.66,148.36 				"
            />
          </g>
          <ellipse style={{fill: "#0D1E36"}} cx="93.47" cy="84.92" rx="1.42" ry="1.44" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M92.66,81.75c0.02-0.02,0.03-0.03,0.05-0.05c0.02-0.02,0.04-0.04,0.07-0.05
  						c-0.21,0.18-0.11,0.08-0.05,0.04c0.08-0.05,0.16-0.1,0.24-0.15c0.05-0.03,0.11-0.05,0.17-0.08c-0.23,0.12-0.03,0.02,0.04-0.01
  						c0.07-0.02,0.13-0.04,0.2-0.06c0.03-0.01,0.07-0.01,0.1-0.02c0.03-0.01,0.18-0.03,0.04-0.01c-0.14,0.02,0.02,0,0.05,0
  						c0.04,0,0.07,0,0.11-0.01c0.09,0,0.17,0,0.26,0.01c0.02,0,0.04,0,0.06,0.01c0.08,0.01,0.06,0.01-0.06-0.01
  						c0.04,0.01,0.09,0.01,0.13,0.02c0.1,0.02,0.2,0.04,0.29,0.07c0.06,0.02,0.11,0.03,0.16,0.05c0.04,0.01,0.19,0.06,0.03,0.01
  						c0.12,0.04,0.24,0.11,0.35,0.17c0.32,0.18,0.78,0.09,0.96-0.25c0.17-0.33,0.09-0.77-0.25-0.96c-0.84-0.46-1.84-0.68-2.78-0.39
  						c-0.41,0.13-0.85,0.35-1.16,0.67c-0.26,0.26-0.28,0.73,0,0.99C91.95,82,92.39,82.03,92.66,81.75L92.66,81.75z"
              />
            </g>
          </g>
          <path
            style={{fill: "#E3B59D"}}
            d="M83.41,70.97c-9,0.91-16.03,8.63-16.03,18.03c0,0,0.27,4.44,0.82,5.45c0,0,15.3-8.26,15.3-23.27"
          />
          <path
            style={{fill: "#E3B59D"}}
            d="M83.38,71.09c0,0,4.92,5.61,14.86,5.61C98.25,76.7,92.59,69.87,83.38,71.09z"
          />
          <ellipse style={{fill: "#0D1E36"}} cx="81.31" cy="86.98" rx="1.42" ry="1.44" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M81.5,82.2c-0.28-0.09-0.6-0.12-0.89-0.12c-0.64-0.01-1.3,0.15-1.84,0.49
  						c-0.44,0.27-0.78,0.63-1.08,1.04c-0.22,0.3-0.06,0.79,0.25,0.96c0.36,0.19,0.73,0.07,0.96-0.25c0.02-0.03,0.04-0.05,0.06-0.08
  						c-0.06,0.08-0.07,0.09-0.02,0.03c0.04-0.05,0.08-0.09,0.12-0.14c0.07-0.07,0.14-0.14,0.22-0.21c0.02-0.02,0.04-0.04,0.06-0.05
  						c0.06-0.05,0.04-0.03-0.05,0.03c0.04-0.02,0.09-0.06,0.13-0.09c0.07-0.05,0.15-0.09,0.23-0.13c0.04-0.02,0.09-0.04,0.13-0.06
  						c-0.17,0.1-0.09,0.04-0.04,0.02c0.08-0.03,0.17-0.06,0.26-0.08c0.07-0.02,0.15-0.04,0.22-0.05c0.07-0.01,0.2,0-0.03,0
  						c0.03,0,0.07-0.01,0.1-0.01c0.13-0.01,0.26-0.01,0.39-0.01c0.06,0,0.12,0.01,0.19,0.01c0.18,0.02-0.2-0.03-0.03,0
  						c0.1,0.02,0.19,0.03,0.29,0.06c0.35,0.11,0.78-0.13,0.86-0.49C82.08,82.68,81.87,82.32,81.5,82.2L81.5,82.2z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M87.49,90.73c0.03-0.07,0.08-0.14,0.12-0.2c0.02-0.03,0.04-0.06,0.06-0.09
  						c0.01-0.02,0.03-0.04,0.04-0.06c0.01-0.01,0.06-0.07,0.02-0.03c-0.03,0.04,0.02-0.02,0.03-0.03c0.01-0.02,0.03-0.04,0.04-0.05
  						c0.04-0.04,0.07-0.08,0.11-0.12c0.04-0.04,0.08-0.08,0.12-0.11c0.02-0.02,0.05-0.04,0.07-0.06c0.01-0.01,0.02-0.02,0.03-0.03
  						c0.04-0.03-0.05,0.04-0.01,0.01c0.1-0.07,0.21-0.14,0.32-0.2c0.02-0.01,0.03-0.02,0.05-0.02c0,0-0.05,0.02-0.02,0.01
  						c0.03-0.01,0.05-0.02,0.08-0.03c0.07-0.02,0.13-0.04,0.2-0.05c0.08-0.02-0.02,0,0.04-0.01c0.03,0,0.06-0.01,0.1-0.01
  						c0.08,0,0.16,0,0.23,0.01c0.07,0.01,0.03,0,0.01,0c0.02,0,0.05,0.01,0.07,0.01c0.05,0.01,0.1,0.02,0.14,0.04
  						c0.08,0.02,0.18,0.06,0.24,0.09c0.22,0.1,0.43,0.23,0.61,0.38c0.15,0.12,0.35,0.14,0.5,0c0.12-0.12,0.15-0.38,0-0.5
  						c-0.66-0.52-1.49-0.92-2.34-0.64c-0.55,0.18-0.98,0.58-1.31,1.05c-0.07,0.1-0.13,0.2-0.19,0.3c-0.08,0.17-0.05,0.38,0.13,0.48
  						C87.17,90.95,87.41,90.9,87.49,90.73L87.49,90.73z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{fill: "#0D1B30"}}
                d="M84.31,95.97c1.99,0.57,4.38-0.14,5.4-0.5c0.14,0.86,0.27,2.07,0.06,2.85l0,0
  						c-0.16,0.63-0.54,1.19-1.1,1.56c-0.37-0.63-0.89-1.15-1.53-1.52l-2.37,0.04C84.76,98.4,84.52,97.82,84.31,95.97z"
              />
              <path
                style={{fill: "#FFFFFF"}}
                d="M84.21,94.91c0,0,2.05,0.5,5.31-0.45c0,0,0.1,0.42,0.19,1.01c-1.01,0.36-3.41,1.07-5.4,0.5
  						C84.28,95.65,84.24,95.3,84.21,94.91z"
              />
            </g>
            <path
              style={{fill: "#F05C60"}}
              d="M84.87,98.8c-0.15-0.31-0.23-0.64-0.25-0.96c0.61-0.07,1.24-0.01,1.84,0.2
  					c0.94,0.35,1.7,1,2.2,1.84c-0.1,0.06-0.19,0.12-0.3,0.17C87.06,100.69,85.49,100.13,84.87,98.8z"
            />
          </g>
          <path
            style={{fill: "#E3B59D"}}
            d="M126.96,95.54c-0.28-0.74-0.85-4,1.41-5.71c0,0,3-1.37,4.63,1.47c1.23,2.13,1.75,4.11,0.68,7.59
  				c-1,3.25-2.53,4.64-3.09,5.62c-1,3.53-1.99,7.07-2.99,10.6c-0.14,0.51-0.29,1.03-0.43,1.54l-6.1-2.13
  				c1.02-3.61,2.93-9.59,3.95-13.2"
          />
          <g>
            <path
              style={{fill: "#5DBDAF"}}
              d="M131.59,74.96c0,0-2.6-8.19-11.33-4.18c-8.39,3.84-5,11.44-5,11.44L131.59,74.96z"
            />
            <g>
              <g>
                <g>
                  <path
                    style={{fill: "#5DBDAF"}}
                    d="M145.04,117.69c-2.58-3.07-5.17-6.14-7.75-9.21c-0.37-0.44-0.74-0.88-1.1-1.31
  								c-0.39-0.47-1.19-0.4-1.59,0c-0.46,0.46-0.39,1.12,0,1.59c2.58,3.07,5.17,6.14,7.75,9.21c0.37,0.44,0.74,0.88,1.1,1.31
  								c0.39,0.47,1.19,0.4,1.59,0C145.5,118.82,145.44,118.16,145.04,117.69L145.04,117.69z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{fill: "#5DBDAF"}}
                    d="M142.48,121.08c-1.29-3.93-2.58-7.86-3.87-11.78c-0.18-0.56-0.37-1.13-0.55-1.69
  								c-0.45-1.37-2.62-0.78-2.17,0.6c1.29,3.93,2.58,7.86,3.87,11.78c0.18,0.56,0.37,1.13,0.55,1.69
  								C140.76,123.05,142.93,122.46,142.48,121.08L142.48,121.08z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{fill: "#5DBDAF"}}
                    d="M144.79,121.54c-2.39-4.96-4.78-9.92-7.17-14.87c-0.34-0.71-0.69-1.42-1.03-2.13
  								c-0.63-1.3-2.57-0.16-1.94,1.14c2.39,4.96,4.78,9.92,7.17,14.87c0.34,0.71,0.69,1.42,1.03,2.13
  								C143.47,123.98,145.41,122.84,144.79,121.54L144.79,121.54z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{fill: "#5DBDAF"}}
                    d="M139.58,118.74c-0.55-3.9-1.1-7.81-1.66-11.71c-0.08-0.55-0.16-1.11-0.24-1.66
  								c-0.09-0.6-0.85-0.93-1.38-0.79c-0.65,0.18-0.87,0.78-0.79,1.38c0.55,3.9,1.1,7.81,1.66,11.71c0.08,0.55,0.16,1.11,0.24,1.66
  								c0.09,0.6,0.85,0.93,1.38,0.79C139.44,119.95,139.66,119.34,139.58,118.74L139.58,118.74z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{fill: "#5DBDAF"}}
                    d="M143.06,112.76c-2.03-1.36-4.06-2.71-6.1-4.07c-0.29-0.19-0.58-0.39-0.87-0.58
  								c-0.49-0.33-1.27-0.11-1.54,0.4c-0.3,0.57-0.12,1.19,0.4,1.54c2.03,1.36,4.06,2.71,6.1,4.07c0.29,0.19,0.58,0.39,0.87,0.58
  								c0.49,0.33,1.27,0.11,1.54-0.4C143.76,113.73,143.58,113.11,143.06,112.76L143.06,112.76z"
                  />
                </g>
              </g>
              <polygon
                style={{fill: "#EB6669"}}
                points="140.69,110.98 136.3,114.16 115.25,82.16 131.61,74.86 					"
              />
              <g>
                <g>
                  <g>
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M117.38,75.37c0.59,0.02,1.47-0.13,1.98-1.08c0.16-0.29-0.04-0.66-0.36-0.67
  									c-0.59-0.02-1.47,0.13-1.98,1.08C116.86,74.99,117.05,75.36,117.38,75.37z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M116.61,74.57c0.02-0.6-0.13-1.5-1.06-2.02c-0.29-0.16-0.65,0.04-0.66,0.37
  									c-0.02,0.6,0.13,1.5,1.06,2.02C116.24,75.11,116.6,74.91,116.61,74.57z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M115.93,75.09c-0.59-0.02-1.47,0.13-1.98,1.08c-0.16,0.29,0.04,0.66,0.36,0.67
  									c0.59,0.02,1.47-0.13,1.98-1.08C116.45,75.46,116.26,75.1,115.93,75.09z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M116.7,75.88c-0.02,0.6,0.13,1.5,1.06,2.02c0.29,0.16,0.65-0.04,0.66-0.37
  									c0.02-0.6-0.13-1.5-1.06-2.02C117.07,75.35,116.71,75.55,116.7,75.88z"
                    />
                  </g>
                  <g>
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M117.2,74.87c0.46-0.41,1.07-1.21,0.68-2.36c-0.09-0.26-0.42-0.34-0.62-0.16
  									c-0.46,0.41-1.07,1.21-0.68,2.36C116.67,74.97,117,75.05,117.2,74.87z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M116.23,74.87c-0.4-0.46-1.19-1.09-2.32-0.7c-0.26,0.09-0.33,0.43-0.15,0.63
  									c0.4,0.46,1.19,1.09,2.32,0.7C116.33,75.41,116.41,75.07,116.23,74.87z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M116.11,75.59c-0.46,0.41-1.07,1.21-0.68,2.36c0.09,0.26,0.42,0.34,0.62,0.16
  									c0.46-0.41,1.07-1.21,0.68-2.36C116.64,75.48,116.32,75.4,116.11,75.59z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M117.08,75.59c0.4,0.46,1.19,1.09,2.32,0.7c0.26-0.09,0.33-0.43,0.15-0.63
  									c-0.4-0.46-1.19-1.09-2.32-0.7C116.98,75.05,116.9,75.38,117.08,75.59z"
                    />
                  </g>
                  <ellipse style={{fill: "#F8D73E"}} cx="116.66" cy="75.23" rx="0.85" ry="0.87" />
                </g>
                <g>
                  <g>
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M122.02,71.11c0.42-0.23,0.98-0.69,0.97-1.56c0-0.27-0.28-0.45-0.52-0.32
  									c-0.42,0.23-0.98,0.69-0.97,1.56C121.51,71.06,121.79,71.24,122.02,71.11z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M121.17,70.87c-0.22-0.43-0.68-1-1.54-0.99c-0.26,0-0.44,0.29-0.32,0.53
  									c0.22,0.43,0.68,1,1.54,0.99C121.12,71.39,121.29,71.11,121.17,70.87z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M120.89,71.5c-0.42,0.23-0.98,0.69-0.97,1.56c0,0.27,0.28,0.45,0.52,0.32
  									c0.42-0.23,0.98-0.69,0.97-1.56C121.41,71.56,121.13,71.38,120.89,71.5z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M121.75,71.75c0.22,0.43,0.68,1,1.54,0.99c0.26,0,0.44-0.29,0.32-0.53
  									c-0.22-0.43-0.68-1-1.54-0.99C121.8,71.23,121.62,71.51,121.75,71.75z"
                    />
                  </g>
                  <g>
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M121.7,70.83c0.16-0.47,0.28-1.29-0.45-1.94c-0.16-0.15-0.43-0.07-0.5,0.14
  									c-0.16,0.47-0.28,1.29,0.45,1.94C121.37,71.12,121.63,71.05,121.7,70.83z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M121.02,71.23c-0.46-0.16-1.27-0.28-1.91,0.45c-0.14,0.17-0.07,0.43,0.14,0.51
  									c0.46,0.16,1.27,0.28,1.91-0.45C121.3,71.57,121.22,71.3,121.02,71.23z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M121.22,71.78c-0.16,0.47-0.28,1.29,0.45,1.94c0.16,0.15,0.43,0.07,0.5-0.14
  									c0.16-0.47,0.28-1.29-0.45-1.94C121.55,71.49,121.29,71.57,121.22,71.78z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M121.9,71.39c0.46,0.16,1.27,0.28,1.91-0.45c0.14-0.17,0.07-0.43-0.14-0.51
  									c-0.46-0.16-1.27-0.28-1.91,0.45C121.62,71.05,121.69,71.32,121.9,71.39z"
                    />
                  </g>
                  <ellipse style={{fill: "#F8D73E"}} cx="121.46" cy="71.31" rx="0.69" ry="0.7" />
                </g>
                <g>
                  <g>
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M128.24,72.98c0,0,2.12-0.22,1.9-2.38C130.14,70.6,128.02,70.83,128.24,72.98z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M127.99,72.99c0,0-0.22-2.16-2.34-1.93C125.65,71.05,125.87,73.21,127.99,72.99z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M127.99,72.97c0,0-2.12,0.22-1.9,2.38C126.09,75.35,128.21,75.13,127.99,72.97z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M128.24,72.97c0,0,0.22,2.16,2.34,1.93C130.58,74.9,130.36,72.75,128.24,72.97z"
                    />
                  </g>
                  <g>
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M128.21,72.89c0,0,1.34-1.68-0.31-3.05C127.9,69.84,126.55,71.52,128.21,72.89z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M128.03,73.08c0,0-1.65-1.37-3,0.32C125.03,73.39,126.69,74.76,128.03,73.08z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M128.02,73.07c0,0-1.34,1.68,0.31,3.05C128.33,76.12,129.67,74.43,128.02,73.07z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M128.2,72.88c0,0,1.65,1.37,3-0.32C131.2,72.56,129.54,71.2,128.2,72.88z"
                    />
                  </g>
                  <ellipse style={{fill: "#F8D73E"}} cx="128.11" cy="72.98" rx="0.8" ry="0.81" />
                </g>
                <g>
                  <g>
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.52,74.1c0,0,1.48-0.92,0.58-2.43C121.1,71.67,119.62,72.59,120.52,74.1z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.34,74.19c0,0-0.9-1.51-2.38-0.59C117.95,73.6,118.86,75.11,120.34,74.19z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.33,74.18c0,0-1.48,0.92-0.58,2.43C119.75,76.61,121.23,75.69,120.33,74.18z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.52,74.09c0,0,0.9,1.51,2.38,0.59C122.9,74.68,122,73.17,120.52,74.09z"
                    />
                  </g>
                  <g>
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.47,74.04c0,0,0.41-1.72-1.28-2.13C119.19,71.91,118.78,73.63,120.47,74.04z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.4,74.24c0,0-1.69-0.42-2.09,1.3C118.3,75.54,119.99,75.96,120.4,74.24z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.39,74.24c0,0-0.41,1.72,1.28,2.13C121.67,76.37,122.08,74.66,120.39,74.24z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M120.46,74.04c0,0,1.69,0.42,2.09-1.3C122.55,72.74,120.87,72.32,120.46,74.04z"
                    />
                  </g>
                  <ellipse style={{fill: "#F8D73E"}} cx="120.43" cy="74.14" rx="0.65" ry="0.66" />
                </g>
                <g>
                  <g>
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M125.85,75.27c0.55-0.16,1.33-0.57,1.53-1.61c0.06-0.32-0.23-0.6-0.54-0.51
  									c-0.55,0.16-1.33,0.57-1.53,1.61C125.26,75.08,125.55,75.36,125.85,75.27z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M124.9,74.77c-0.16-0.56-0.56-1.36-1.58-1.55c-0.32-0.06-0.59,0.23-0.5,0.55
  									c0.16,0.56,0.56,1.36,1.58,1.55C124.71,75.38,124.99,75.08,124.9,74.77z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M124.42,75.45c-0.55,0.16-1.33,0.57-1.53,1.61c-0.06,0.32,0.23,0.6,0.54,0.51
  									c0.55-0.16,1.33-0.57,1.53-1.61C125.02,75.64,124.73,75.36,124.42,75.45z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M125.38,75.95c0.16,0.56,0.56,1.36,1.58,1.55c0.32,0.06,0.59-0.23,0.5-0.55
  									c-0.16-0.56-0.56-1.36-1.58-1.55C125.56,75.35,125.29,75.64,125.38,75.95z"
                    />
                  </g>
                  <g>
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M125.54,74.86c0.3-0.52,0.64-1.46-0.06-2.41c-0.16-0.22-0.49-0.19-0.63,0.04
  									c-0.3,0.52-0.64,1.46,0.06,2.41C125.07,75.12,125.4,75.09,125.54,74.86z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M124.63,75.15c-0.51-0.31-1.43-0.65-2.37,0.07c-0.21,0.16-0.18,0.5,0.04,0.64
  									c0.51,0.31,1.43,0.65,2.37-0.07C124.89,75.63,124.86,75.29,124.63,75.15z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M124.74,75.86c-0.3,0.52-0.64,1.46,0.06,2.41c0.16,0.22,0.49,0.19,0.63-0.04
  									c0.3-0.52,0.64-1.46-0.06-2.41C125.2,75.6,124.87,75.63,124.74,75.86z"
                    />
                    <path
                      style={{fill: "#4A9AD4"}}
                      d="M125.64,75.57c0.51,0.31,1.43,0.65,2.37-0.07c0.21-0.16,0.18-0.5-0.04-0.64
  									c-0.51-0.31-1.43-0.65-2.37,0.07C125.39,75.09,125.42,75.43,125.64,75.57z"
                    />
                  </g>
                  <ellipse style={{fill: "#F8D73E"}} cx="125.14" cy="75.36" rx="0.83" ry="0.85" />
                </g>
                <g>
                  <g>
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M117.11,79.26c0,0,1.72,1.31,3.01-0.45C120.13,78.81,118.4,77.5,117.11,79.26z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M116.92,79.08c0,0,1.29-1.76-0.44-3.06C116.48,76.02,115.2,77.77,116.92,79.08z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M116.93,79.07c0,0-1.72-1.31-3.01,0.45C113.92,79.52,115.64,80.83,116.93,79.07z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M117.12,79.25c0,0-1.29,1.76,0.44,3.06C117.56,82.31,118.85,80.56,117.12,79.25z"
                    />
                  </g>
                  <g>
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M117.15,79.16c0,0,2.13-0.32,1.82-2.48C118.97,76.68,116.84,77,117.15,79.16z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M116.89,79.18c0,0-0.31-2.17-2.44-1.85C114.45,77.33,114.76,79.49,116.89,79.18z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M116.89,79.17c0,0-2.13,0.32-1.82,2.48C115.07,81.65,117.2,81.33,116.89,79.17z"
                    />
                    <path
                      style={{fill: "#FFFFFF"}}
                      d="M117.15,79.15c0,0,0.31,2.17,2.44,1.85C119.59,81,119.28,78.84,117.15,79.15z"
                    />
                  </g>
                  <ellipse style={{fill: "#F8D73E"}} cx="117.02" cy="79.16" rx="0.81" ry="0.82" />
                </g>
              </g>
              <path
                style={{fill: "#FFFFFF"}}
                d="M124.8,69.46c-0.06,0.17-0.11,0.47,0.16,0.72c0.06,0.05,0.16,0.03,0.18-0.05
  						c0.06-0.17,0.11-0.47-0.16-0.72C124.92,69.36,124.82,69.38,124.8,69.46z"
              />
              <path
                style={{fill: "#FFFFFF"}}
                d="M125.69,69.91c-0.16,0.07-0.4,0.23-0.4,0.58c0,0.08,0.08,0.13,0.15,0.1
  						c0.16-0.07,0.4-0.23,0.4-0.58C125.85,69.94,125.77,69.88,125.69,69.91z"
              />
              <path
                style={{fill: "#FFFFFF"}}
                d="M124.2,70.43c0.04,0.18,0.15,0.46,0.5,0.52c0.08,0.01,0.15-0.06,0.13-0.14
  						c-0.04-0.18-0.15-0.46-0.5-0.52C124.25,70.27,124.18,70.35,124.2,70.43z"
              />
              <path
                style={{fill: "#FFFFFF"}}
                d="M120.29,77.33c0.1,0.17,0.31,0.4,0.68,0.34c0.08-0.01,0.13-0.11,0.08-0.19
  						c-0.1-0.17-0.31-0.4-0.68-0.34C120.28,77.15,120.24,77.25,120.29,77.33z"
              />
              <path
                style={{fill: "#FFFFFF"}}
                d="M121.26,77.07c-0.01,0.19,0.02,0.51,0.36,0.68c0.08,0.04,0.17-0.02,0.17-0.1
  						c0.01-0.19-0.02-0.51-0.36-0.68C121.35,76.92,121.26,76.98,121.26,77.07z"
              />
              <path
                style={{fill: "#FFFFFF"}}
                d="M120.84,78.3c0.15,0.12,0.43,0.26,0.76,0.07c0.07-0.04,0.08-0.15,0.01-0.21
  						c-0.15-0.12-0.43-0.26-0.76-0.07C120.78,78.14,120.77,78.25,120.84,78.3z"
              />
            </g>
          </g>
          <path
            style={{fill: "#E3B59D"}}
            d="M125.27,103.31c0.13,0.36,0.03-0.77,0.05-1.16c0.85-0.66,2.78-2.3,3.44-4.15
  				c0,0,0.43-0.83,0.24-2.04c-0.19-1.21-2.43-0.96-3.48,0.49c0,0-0.44,0.55-0.68,0.34C124.83,96.8,123.1,97.21,125.27,103.31z"
          />
          <g>
            <g>
              <path
                style={{fill: "#609E92"}}
                d="M91.23,109.13c0.01,0.05,0.01,0.1,0.02,0.14c-0.03-0.15-0.01-0.14,0,0
  						c0.01,0.09,0.02,0.18,0.02,0.27c0.01,0.2,0.02,0.39,0.01,0.59c-0.01,0.24-0.04,0.5-0.05,0.57c-0.2,1.09-0.68,1.88-1.84,2.17
  						c-0.23,0.06-0.45,0.08-0.69,0.11c-0.09,0.01,0.26-0.02,0.12-0.02c-0.05,0-0.1,0.01-0.14,0.02c-0.3,0.03-0.6,0.04-0.91,0.04
  						c-0.88-0.01-1.75-0.14-2.49-0.53c-0.53-0.28-0.89-0.58-1.19-1.06c-0.37-0.6-0.5-1.32-0.5-2.06c-0.01-0.96-1.51-0.97-1.5,0
  						c0.01,1.62,0.58,3.15,1.93,4.11c0.97,0.69,2.18,0.96,3.35,1.03c0.39,0.02,0.79,0.02,1.18-0.01c0.1-0.01,0.19-0.02,0.29-0.03
  						c0.09-0.01,0.18-0.02,0.26-0.04c-0.15,0.02,0.16-0.02,0.25-0.03c1.58-0.24,2.79-1.31,3.22-2.84c0.25-0.9,0.28-1.91,0.1-2.83
  						C92.5,107.78,91.05,108.18,91.23,109.13L91.23,109.13z"
              />
            </g>
          </g>
          <g>
            <path
              style={{fill: "#5DBDAF"}}
              d="M72.72,113.14c2.05-3.26,6.51-1.6,6.88,1.98c0.37,3.58-7.08,17.51-7.08,17.51l-6.27-4.76
  					C66.24,127.87,71.19,115.57,72.72,113.14z"
            />
            <g>
              <g>
                <path
                  style={{fill: "#609E92"}}
                  d="M65.9,128.16c1.84,1.52,3.69,3.04,5.53,4.56c0.26,0.22,0.52,0.43,0.79,0.65
  							c0.32,0.26,0.77,0.31,1.08,0c0.27-0.27,0.32-0.82,0-1.08c-1.84-1.52-3.69-3.04-5.53-4.56c-0.26-0.22-0.52-0.43-0.79-0.65
  							c-0.32-0.26-0.77-0.31-1.08,0C65.63,127.35,65.58,127.9,65.9,128.16L65.9,128.16z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                style={{fill: "#609E92"}}
                d="M108.18,125.72c1.83-1.36,3.66-2.72,5.49-4.09c0.26-0.19,0.52-0.39,0.78-0.58
  						c0.32-0.24,0.49-0.65,0.27-1.03c-0.19-0.32-0.7-0.51-1.03-0.27c-1.83,1.36-3.66,2.72-5.49,4.09c-0.26,0.19-0.52,0.39-0.78,0.58
  						c-0.32,0.24-0.49,0.65-0.27,1.03C107.34,125.77,107.85,125.96,108.18,125.72L108.18,125.72z"
              />
            </g>
          </g>
          <path
            style={{fill: "#EB6669"}}
            d="M71.79,81.34l-3.13,0.9c-2.51,5.72-0.63,11.62-0.63,11.62l2.89-3.25
  				C70.92,90.61,70.49,85.31,71.79,81.34"
          />
          <path
            style={{fill: "#F5F6F5"}}
            d="M90.82,138.32l6.74,2.6c0.67,0.26,0.95,0.88,0.69,1.55l-6.09,15.58
  				c-0.26,0.67-0.88,0.94-1.56,0.68l-6.74-2.6c-0.67-0.26-0.95-0.88-0.69-1.55L89.26,139C89.52,138.33,90.14,138.06,90.82,138.32z"
          />
          <path
            style={{fill: "#E3B59D"}}
            d="M87.36,147.04c0,0-0.61,0.36-0.44,0.63c0.17,0.27,1.11,0.66,2.14,0.04
  				c0.67-0.41,3.75-1.54,5.79,0.4c0,0,1.82,2.74-0.68,4.86c-1.88,1.6-3.72,2.46-7.28,2.01c-3.33-0.43-4.93-1.7-5.97-2.08
  				c-3.6-0.37-7.21-0.74-10.81-1.11c-0.52-0.05-1.05-0.11-1.57-0.16l1.08-6.44c3.68,0.38,7.36,0.75,11.04,1.13
  				c0.38,0.04,0.76,0.08,1.14,0.12c0.77-0.75,2.66-2.43,4.56-2.83c0,0,0.88-0.31,2.02,0.05C89.52,144.02,88.94,146.21,87.36,147.04z
  				"
          />
          <path
            style={{fill: "#F5F6F5"}}
            d="M88.16,141.89l-2.24,5.51c0,0,2.03,1.59,3.67-0.18C90.84,145.88,88.16,141.89,88.16,141.89z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              style={{fill: "#3499CC"}}
              d="M69.29,83.1c11.85-2.95,23.71-5.9,35.56-8.85c1.66-0.41,3.33-0.83,4.99-1.24
  					c2.71-0.67,1.56-4.86-1.15-4.19c-11.85,2.95-23.71,5.9-35.56,8.85c-1.66,0.41-3.33,0.83-4.99,1.24
  					C65.42,79.59,66.57,83.78,69.29,83.1L69.29,83.1z"
            />
          </g>
        </g>
        <path
          style={{fill: "#3499CC"}}
          d="M81.07,66.08l-0.56,0.16c-0.5,0.14-1.02-0.16-1.16-0.67l0,0c-0.14-0.51,0.16-1.04,0.66-1.18
  			l0.56-0.16c0.5-0.14,1.02,0.16,1.16,0.67h0C81.86,65.41,81.57,65.94,81.07,66.08z"
        />
        <g>
          <defs>
            <polygon id="SVGID_3_" points="64.14,67.25 96.09,59.01 99.75,73.69 67.8,81.94 				" />
          </defs>
          <clipPath id="2c905866-SVGID_00000014636127122310787440000014225299228559768192_">
            <use xlinkHref="#SVGID_3_" style={{overflow: "visible"}} />
          </clipPath>
          <path
            style={{
              clipPath: "url(#SVGID_00000014636127122310787440000014225299228559768192_)",
              fill: "#3499CC",
            }}
            d="M99.57,76.47
  				c-2.16-8.63-10.8-13.85-19.28-11.64c-8.48,2.2-13.61,10.99-11.44,19.62L99.57,76.47z"
          />
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default Character12
