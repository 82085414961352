// This file was generated automatically from character-08.svg with the following command:
// ./svg-converter.js src/components/superhero/character-08.svg
import SuperheroSvg from "./superhero-svg"

const Character08 = props => (
  <SuperheroSvg viewBox="175 80 110 230" {...props}>
    <g id="Layer_8">
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M203.5,301.98c4.21,1.3,10.19,2.7,17.1,3.04l0.09-0.01c0.09-0.02,2.18-0.42,2.52-1.81
  				c0.26-1.05-0.52-2.23-2.38-3.63l-0.06-0.03c-0.09-0.04-8.7-4.5-8.89-8.1l-0.76,0.04c0.21,3.95,8.6,8.36,9.27,8.71
  				c1.94,1.46,2.2,2.35,2.08,2.85c-0.2,0.83-1.62,1.19-1.88,1.24c-12.6-0.63-22.05-4.82-23.11-5.31c-0.82-1.51,2.37-6.03,4.62-8.57
  				l-0.58-0.49c-0.02,0.02-1.56,1.77-2.9,3.76c-1.9,2.83-2.46,4.72-1.73,5.78l0.06,0.08l0.09,0.04
  				C197.09,299.6,199.49,300.74,203.5,301.98z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M216.66,304.57l0.63-0.41c-0.62-0.89-0.82-1.75-0.61-2.56c0.41-1.51,2.17-2.38,2.18-2.39
  				l-0.34-0.66c-0.08,0.04-2.08,1.02-2.58,2.86C215.68,302.43,215.91,303.49,216.66,304.57z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M203.54,299.2c2.85,0.88,6.9,1.91,12.63,3.02l0.15-0.73c-15.64-3.02-18.67-5.52-18.7-5.55
  				l-0.51,0.55C197.18,296.55,198.43,297.63,203.54,299.2z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M216.61,301.26l0.35-0.66c-10.33-5.27-9.45-9.36-9.44-9.4l-0.74-0.19
  				C206.74,291.2,205.64,295.66,216.61,301.26z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M214.73,299.06c0.6,0.19,1.5-0.03,1.81-0.11l-0.2-0.72c-0.59,0.16-1.27,0.21-1.42,0.1
  				c0,0,0-0.02,0.01-0.03c0.26-1.28-0.13-1.36-2-1.71c-0.73-0.14-1.26-0.24-1.36-0.39c-0.1-0.15,0-0.66,0.14-1.38
  				c0.12-0.6,0.01-1.01-0.33-1.23c-0.65-0.42-1.81,0.27-2.15,0.49l0.43,0.62c0.56-0.36,1.18-0.57,1.3-0.49c0,0,0.07,0.09,0,0.47
  				c-0.41,2.04-0.27,2.24,1.82,2.63c0.74,0.14,1.38,0.26,1.48,0.36c0.01,0.05-0.03,0.28-0.07,0.48c-0.06,0.28,0.02,0.54,0.21,0.71
  				C214.48,298.96,214.6,299.02,214.73,299.06z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M238.96,302.84c4.38,0.51,10.52,0.8,17.38-0.11l0.09-0.02c0.08-0.03,2.06-0.81,2.13-2.23
  				c0.05-1.08-0.94-2.1-3.03-3.13l-0.06-0.02c-0.09-0.03-9.4-2.84-10.28-6.35l-0.74,0.18c0.96,3.84,10.05,6.66,10.77,6.87
  				c2.18,1.08,2.61,1.91,2.59,2.42c-0.04,0.86-1.37,1.46-1.61,1.56c-12.5,1.67-22.59-0.74-23.72-1.03c-1.1-1.34,1.18-6.35,2.91-9.25
  				l-0.66-0.37c-0.01,0.02-1.2,2.02-2.13,4.22c-1.32,3.12-1.52,5.08-0.59,5.99l0.07,0.07l0.1,0.03
  				C232.21,301.66,234.78,302.35,238.96,302.84z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M252.39,303l0.54-0.52c-0.78-0.77-1.14-1.57-1.08-2.4c0.11-1.56,1.67-2.73,1.69-2.75l-0.46-0.59
  				c-0.08,0.06-1.85,1.38-1.99,3.28C251.01,301.07,251.45,302.07,252.39,303z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M238.47,300.1c2.97,0.35,7.15,0.63,12.98,0.68l0.01-0.74c-15.94-0.13-19.4-2.04-19.43-2.06
  				l-0.4,0.63C231.72,298.65,233.15,299.48,238.47,300.1z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M251.7,299.75l0.22-0.71c-11.16-3.31-11.06-7.48-11.06-7.53l-0.76-0.05
  				C240.09,291.66,239.87,296.24,251.7,299.75z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M249.44,297.94c0.63,0.07,1.47-0.3,1.75-0.44l-0.34-0.67c-0.55,0.26-1.2,0.43-1.37,0.36
  				c0-0.01,0-0.02,0-0.03c0.01-1.31-0.39-1.31-2.3-1.32c-0.75,0-1.28-0.01-1.41-0.13c-0.13-0.13-0.13-0.65-0.12-1.38
  				c0-0.61-0.18-1-0.55-1.15c-0.72-0.3-1.73,0.59-2.02,0.87l0.54,0.53c0.48-0.46,1.05-0.78,1.19-0.72c0,0,0.09,0.08,0.09,0.46
  				c-0.01,2.08,0.16,2.25,2.29,2.26c0.75,0,1.4,0.01,1.52,0.09c0.02,0.05,0.02,0.28,0.02,0.48c0,0.29,0.12,0.52,0.35,0.66
  				C249.18,297.88,249.31,297.92,249.44,297.94z"
          />
        </g>
      </g>
      <path
        style={{fill: "#5DBDAF"}}
        d="M249.21,191.28l12.07,99.85l-69.65,0.31l10.86-98.21c-0.03-0.45-0.05-0.91-0.05-1.37
  		c-0.14-8.31,2.95-15.79,7.94-20.83l-0.03-0.17l34.54-8.77l3.5,22.91c0.25,1.14,0.44,2.31,0.56,3.51l0.3,1.89l-0.17,0.03
  		c0.01,0.24,0.02,0.49,0.03,0.73"
      />
      <path
        style={{fill: "#B5B6B7"}}
        d="M211.32,96.78c-0.23,1.51-1.56,2.26-2.12,3.44c-0.58,1.23-0.63,3.37-1.47,4.15
  		c-0.83,0.79-2.12-0.09-3.1,0.16c-0.95,0.24-1.98,1.72-2.97,1.36c-0.99-0.35-1.49-2.37-2.27-3.22c-0.81-0.88-2.22-0.96-2.74-2.23
  		c-0.52-1.27,0.06-3.22-0.11-4.71c-0.16-1.44-1.13-3-0.9-4.51c0.23-1.51,1.56-2.26,2.12-3.44c0.58-1.23,0.63-3.37,1.47-4.15
  		c0.83-0.79,2.12,0.09,3.1-0.16c0.95-0.24,1.98-1.72,2.97-1.36c0.99,0.35,1.49,2.37,2.27,3.22c0.81,0.88,2.22,0.96,2.74,2.23
  		c0.52,1.27-0.06,3.22,0.11,4.71C210.58,93.71,211.55,95.27,211.32,96.78z"
      />
      <g>
        <defs>
          <rect
            id="SVGID_00000065058774131096243550000007082083000138619319_"
            x="165.73"
            y="71.99"
            width="228.49"
            height="165.65"
          />
        </defs>
        <clipPath id="ff02c743-SVGID_00000179637385595397775730000018004197722527966886_">
          <use
            xlinkHref="#SVGID_00000065058774131096243550000007082083000138619319_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <g style={{clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)"}}>
          <g>
            <path
              style={{fill: "#8B725E"}}
              d="M258.8,140.52c-2.46,1.56-3.75,4.41-4.42,5.77c-3.47,7.02-4.99,9.51-4.82,9.6
  					c0.2,0.1,2.36-3.58,2.34-3.59c-0.01-0.01-1.15,1.75-2.81,4.33c-1.57,2.44-2.72,3.21-2.38,3.8c0.32,0.55,1.72,0.71,2.57,0.1
  					c0.31-0.22,0.48-0.5,0.58-0.7c1.03-0.72,2.05-1.44,3.08-2.16c-3.84-3.88-5.53-6.06-6.34-7.36c-0.16-0.25-0.76-1.24-1.74-2.42
  					c-0.63-0.76-1.2-1.45-2.11-2.2c-1.21-1-1.5-0.85-2.21-1.69c-0.53-0.62-0.7-1.09-1.24-1.21c-0.48-0.11-1.07,0.11-1.36,0.47
  					c-0.68,0.83,0.08,2.46,0.48,3.29c1.96,4.05,4.17,7.56,4.82,8.61c2.56,4.16,3.78,7.46,4.01,7.36c0.18-0.07-0.44-2.12-0.53-2.1
  					c-0.07,0.01-0.03,1.08,0.53,2.1c0.2,0.35,0.34,0.48,0.64,0.86c1.34,1.68,1.24,2.54,1.93,2.74c0.71,0.21,1.52-0.49,2.1-1.01
  					c0.87-0.77,1.02-1.45,2.13-3.26c0.61-1,1.28-2.09,2.03-3.56c0.25-0.48,0.25-0.52,0.54-1.09c0.73-1.44,1.34-2.48,1.63-2.98
  					c1.47-2.52,2.21-3.78,2.71-4.71c1.13-2.1,1.71-3.18,2.11-4.69c0.36-1.34,0.86-3.32-0.19-4.52c-0.1-0.12-0.44-0.49-0.99-0.67
  					C261.52,139.53,260.59,139.38,258.8,140.52z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#D9D8D6",
          }}
          d="M226.33,132.86
  			c-0.69,0.77-5.75-1.5-6.45-0.74c-3.74,4.03-7.05,1.54-11.1-0.87c-2.98-1.76-5.62-1.46-7.54-4.23c-2.36-3.42-4.34-7.14-5.2-11.13
  			c-3.92-18.3,21.1-26.26,35.14-17.28c5.22,3.34,9.94,10.86,8.79,16.88c-0.59,3.05-2.9,5.8-2.66,8.9c0.27,3.33,3.49,6.51,2.14,9.61
  			c-0.53,1.21-1.66,2.08-2.59,3.06c-1.24,1.33-2.15,2.98-2.26,4.75c-0.11,1.76,0.67,3.62,2.22,4.62c-3.83-0.48-6.19-4.77-5.2-8.27
  			c0.32-1.15,0.92-2.22,1.18-3.38c0.26-1.16,0.11-2.52-0.84-3.31c-1.22-1.01-3.2-0.63-4.48,0.32
  			C227.07,132.09,226.69,132.46,226.33,132.86z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#8C715E",
          }}
          d="M262.28,129.28
  			c0,0,0.18,0.72,0.53,0.65c0.35-0.07,1.08-0.89,0.76-2.11c-0.21-0.81-0.41-4.24,2.35-5.61c0,0,3.55-0.89,5,2.3
  			c1.09,2.4,1.41,4.51-0.27,7.9c-1.57,3.16-3.47,4.33-4.23,5.23c-1.6,3.45-3.2,6.91-4.8,10.36c-0.23,0.5-0.46,1-0.7,1.51l-6.56-3.22
  			c1.63-3.53,3.26-7.05,4.9-10.58c0.17-0.36,0.34-0.73,0.5-1.09c-0.55-1.02-1.72-3.45-1.52-5.48c0,0-0.05-0.97,0.72-1.99
  			C259.75,126.14,261.91,127.44,262.28,129.28z"
        />
        <g style={{clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)"}}>
          <path
            style={{fill: "#609E92"}}
            d="M238.62,151.74c-1.94-2.87-3.89-5.75-5.83-8.62c-0.28-0.41-0.56-0.83-0.84-1.24
  				c-0.57-0.84-0.81-1.9-0.61-2.89c0.2-0.95,0.89-2.02,1.8-2.52c1.91-1.07,4.52-0.74,5.77,1.11l0,0c1.94,2.87,3.89,5.75,5.83,8.62
  				c0.28,0.41,0.56,0.83,0.84,1.24L238.62,151.74z"
          />
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#8C715E",
          }}
          d="M218.35,95.8
  			c-0.66,0-1.32,0.03-1.96,0.09c-0.39,14.46-14.38,22.03-17.02,23.33c2.31,7.8,9.93,13.52,18.98,13.52
  			c10.88,0,19.71-8.27,19.71-18.47S229.23,95.8,218.35,95.8z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#8C715E",
          }}
          d="M238.05,114.22
  			c0,8.21-6.15,15.17-14.05,17.58l1.18,15.08l-7.98,0l-0.46-14.23c-5.47-0.41-10.3-2.91-13.6-6.64c-0.7,0.47-1.56,0.75-2.5,0.75
  			c-2.38,0-4.31-1.81-4.31-4.04c0-1.77,1.22-3.27,2.91-3.82c-0.01-0.02-0.01-0.04-0.01-0.06c0.14-0.07,0.27-0.14,0.41-0.21
  			l38.17-7.29C237.96,112.29,238.05,113.25,238.05,114.22z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#8C715E",
          }}
          d="M208.83,109.51
  			l26.19-5c1.41,2.07,2.38,4.43,2.8,6.96l-37.78,7.21C204,116.69,207.13,113.44,208.83,109.51z"
        />
        <g style={{clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)"}}>
          <path
            style={{fill: "#5DBDAF"}}
            d="M203.79,147.95c-0.84-5.77,3.47-11.1,9.63-11.88l3.44-0.46l3.7,9.11l2.31,0.87l1.85-1.3
  				l-0.46-9.11l3.6-0.2c7.81-0.45,11.56,2.36,12.68,9.03l3.72,21.02l-37.44,0L203.79,147.95z"
          />
          <polygon
            style={{fill: "#5DBDAF"}}
            points="206.26,161.6 244.24,165.02 245.21,170.49 207.8,174.78 			"
          />
        </g>

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#0D1E36",
          }}
          cx="227.52"
          cy="110.1"
          rx="1.57"
          ry="1.47"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#8C715E",
          }}
          d="M216.38,95.89
  			c-9.96,0.92-17.74,8.8-17.74,18.38c0,1.72,0.25,3.37,0.72,4.95C202,117.92,215.99,110.35,216.38,95.89z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#8C715E",
          }}
          d="M216.35,96.01
  			c0,0,5.45,5.72,16.45,5.72C232.8,101.73,226.54,94.76,216.35,96.01z"
        />

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#0D1E36",
          }}
          cx="214.06"
          cy="112.21"
          rx="1.57"
          ry="1.47"
        />
        <g style={{clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M220.88,116.03c0.04-0.07,0.08-0.14,0.13-0.21c0.02-0.03,0.05-0.07,0.07-0.1
  					c0.01-0.02-0.05,0.06,0,0.01c0.02-0.02,0.03-0.04,0.05-0.06c0.07-0.09,0.15-0.17,0.23-0.24c0.04-0.04,0.09-0.08,0.14-0.12
  					c0.05-0.04,0,0,0.02-0.02c0.03-0.02,0.06-0.04,0.09-0.06c0.11-0.08,0.23-0.15,0.36-0.2c-0.06,0.03,0.03-0.01,0.05-0.02
  					c0.03-0.01,0.06-0.02,0.1-0.03c0.08-0.02,0.16-0.04,0.24-0.05c0.04-0.01,0,0-0.01,0c0.02,0,0.04,0,0.05-0.01
  					c0.04,0,0.07,0,0.11-0.01c0.08,0,0.19,0,0.24,0.01c0.44,0.05,0.9,0.31,1.27,0.58c0.16,0.12,0.41,0.03,0.5-0.13
  					c0.11-0.18,0.03-0.38-0.13-0.5c-0.72-0.53-1.63-0.87-2.51-0.59c-0.58,0.19-1.05,0.58-1.41,1.06c-0.07,0.1-0.15,0.2-0.2,0.31
  					C220.03,116.08,220.66,116.45,220.88,116.03L220.88,116.03z"
            />
          </g>
        </g>
        <polygon
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#D9D8D6",
          }}
          points="213.2,157.2
  			211.46,179.71 248.91,179.11 251,156.59 		"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#8C715E",
          }}
          d="M224.51,175.2
  			c0,0-0.66,0.36-0.49,0.65c0.17,0.28,1.18,0.69,2.31,0.06c0.74-0.41,4.09-1.57,6.23,0.45c0,0,1.89,2.84-0.86,5.01
  			c-2.06,1.63-4.07,2.51-7.9,2.02c-3.58-0.46-5.28-1.79-6.39-2.19c-3.88-0.41-7.76-0.81-11.64-1.22c-0.56-0.06-1.13-0.12-1.69-0.18
  			l1.32-6.63c3.96,0.42,7.92,0.83,11.88,1.25c0.41,0.04,0.82,0.09,1.22,0.13c0.85-0.77,2.93-2.49,4.99-2.88c0,0,0.95-0.32,2.18,0.06
  			C226.91,172.1,226.24,174.35,224.51,175.2z"
        />

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#FFFFFF",
          }}
          cx="231.75"
          cy="167.7"
          rx="2.5"
          ry="2.34"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#FFFFFF",
          }}
          d="M216.02,121.11
  			c0.11-0.16,0.35-0.19,0.51-0.07c0.38,0.27,1.08,0.85,2.1,1.08c1.39,0.31,2.62-0.17,2.9,0.18c0.16,0.2-0.05,0.42-0.45,0.71
  			s-1.58,0.83-2.85,0.56c-1.34-0.28-2.29-1.78-2.27-2.3C215.98,121.2,215.99,121.15,216.02,121.11z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "#D9D8D6",
          }}
          d="M222.89,99.16
  			c-1.07,1.71-1.67,1.72-2.85,3.53c-1.07,1.64-1.88,3.42-2.78,5.13c-0.67,1.28-1.42,2.56-2.56,3.51c-1.14,0.95-2.75,1.51-4.21,1.11
  			c-1-0.27-1.81-0.94-2.71-1.44c-0.9-0.5-2.04-0.83-2.96-0.37c-1.35,0.68-1.33,2.53-2.3,3.64c-2.22,2.56-6.29-0.89-6.78-3.12
  			c-0.72-3.26,2.14-5.99,4.22-8.25c1.09-1.18,3.26-3.53,6.8-5.38c7.48-3.9,15.21-2.52,17.76-1.95
  			C224.25,96.44,223.78,97.74,222.89,99.16z"
        />
        <g style={{clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)"}}>
          <g>
            <path
              style={{fill: "#8B725E"}}
              d="M207.83,146.49c-0.55,0.99-1.09,1.98-1.64,2.97c-1.31,2.38-2.62,4.75-3.94,7.13
  					c-1.59,2.88-3.18,5.75-4.76,8.63c-1.38,2.5-2.76,5-4.14,7.5c-0.67,1.21-1.3,2.44-2,3.62c-0.01,0.02-0.02,0.03-0.03,0.05
  					c-0.83-1.55-1.65-3.1-2.48-4.65c2.41,0.24,4.82,0.47,7.23,0.71c3.81,0.37,7.62,0.74,11.43,1.12c0.89,0.09,1.77,0.17,2.66,0.26
  					c0.78,0.08,1.46,0.29,2.03,0.9c0.5,0.54,0.87,1.41,0.84,2.18c-0.06,1.55-1.27,3.25-2.87,3.09c-2.41-0.24-4.82-0.47-7.23-0.71
  					c-3.81-0.37-7.62-0.74-11.43-1.12c-0.89-0.09-1.77-0.17-2.66-0.26c-1.04-0.1-1.93-0.52-2.48-1.53c-0.52-0.95-0.52-2.17,0-3.12
  					c0.55-0.99,1.09-1.98,1.64-2.97c1.31-2.38,2.62-4.75,3.94-7.13c1.59-2.88,3.18-5.75,4.76-8.63c1.38-2.5,2.76-5,4.14-7.5
  					c0.67-1.21,1.31-2.43,2-3.62c0.01-0.02,0.02-0.03,0.03-0.05c0.77-1.39,2.62-2.02,3.93-1.11
  					C208.1,143.16,208.65,145,207.83,146.49L207.83,146.49z"
            />
          </g>
        </g>

        <path
          style={{
            clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)",
            fill: "none",
            stroke: "#F8D73E",
            strokeMiterlimit: "10",
          }}
          d="
  			M224.46,135.28c0,0,0.25,4.76-1.73,6.81c-1.98,2.04-4.44-0.88-5.9-6.01"
        />
        <g style={{clipPath: "url(#SVGID_00000179637385595397775730000018004197722527966886_)"}}>
          <path
            style={{fill: "#5DBDAF"}}
            d="M199.19,148.29c1.89-2.91,3.77-5.81,5.66-8.72c0.27-0.42,0.54-0.83,0.81-1.25
  				c0.55-0.85,1.46-1.53,2.49-1.79c1-0.26,2.32-0.14,3.21,0.39c1.87,1.13,2.7,3.47,1.49,5.34l0,0c-1.89,2.91-3.77,5.81-5.66,8.72
  				c-0.27,0.42-0.54,0.83-0.81,1.25L199.19,148.29z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              style={{fill: "#EB6669"}}
              d="M222.81,111.18c0.37,1.77,2.39,2.46,3.98,2.57c1.85,0.13,4.09-0.46,5.36-1.9
  					c0.5-0.57,0.87-1.3,0.82-2.07c-0.05-0.85-0.53-1.55-1.2-2.04c-1.51-1.12-3.76-1.12-5.49-0.6
  					C224.48,107.68,222.48,109.07,222.81,111.18c0.06,0.4,0.56,0.62,0.92,0.52c0.43-0.12,0.59-0.52,0.52-0.92
  					c-0.05-0.32,0.16-0.76,0.46-1.07c0.42-0.45,0.98-0.78,1.56-1c1.29-0.48,2.91-0.63,4.19-0.01c0.92,0.44,1.3,1.19,0.69,2.01
  					c-0.81,1.07-2.4,1.51-3.68,1.56c-1.11,0.04-2.96-0.22-3.22-1.48C224.06,109.84,222.61,110.24,222.81,111.18z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{fill: "#EB6669"}}
              d="M209.68,113.39c0.37,1.77,2.39,2.46,3.98,2.57c1.85,0.13,4.09-0.46,5.36-1.9
  					c0.5-0.57,0.87-1.3,0.82-2.07c-0.05-0.85-0.53-1.55-1.2-2.04c-1.51-1.12-3.76-1.12-5.49-0.6
  					C211.35,109.88,209.36,111.28,209.68,113.39c0.06,0.4,0.56,0.62,0.92,0.52c0.43-0.12,0.59-0.52,0.52-0.92
  					c-0.05-0.32,0.16-0.76,0.46-1.07c0.42-0.45,0.98-0.78,1.56-1c1.29-0.48,2.91-0.63,4.19-0.01c0.92,0.44,1.3,1.19,0.69,2.01
  					c-0.81,1.07-2.4,1.51-3.68,1.56c-1.11,0.04-2.96-0.22-3.22-1.48C210.93,112.05,209.49,112.45,209.68,113.39z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{fill: "#EB6669"}}
              d="M223.77,110.4c-1.75-0.48-3.87-0.19-5.21,1.1c-0.28,0.27-0.29,0.79,0,1.06
  					c0.3,0.28,0.76,0.29,1.06,0c0.05-0.05,0.11-0.1,0.17-0.15c0.14-0.12-0.04,0.02,0.07-0.06c0.1-0.07,0.2-0.14,0.31-0.2
  					c0.07-0.04,0.14-0.08,0.21-0.11c0.04-0.02,0.2-0.09,0.04-0.02c0.11-0.04,0.22-0.08,0.33-0.12c0.2-0.06,0.5-0.18,0.71-0.16
  					c-0.2-0.02,0.01,0,0.07-0.01c0.05-0.01,0.1-0.01,0.16-0.01c0.11-0.01,0.22-0.01,0.33-0.01c0.2,0,0.4,0.01,0.6,0.02
  					c0.09,0.01,0.17,0.02,0.26,0.03c0.15,0.02-0.19-0.03-0.06-0.01c0.04,0.01,0.08,0.01,0.11,0.02c0.15,0.02,0.29,0.05,0.44,0.09
  					c0.38,0.1,0.83-0.13,0.92-0.52C224.38,110.93,224.17,110.51,223.77,110.4L223.77,110.4z"
            />
          </g>
        </g>
      </g>
      <path
        style={{fill: "#D9D8D6"}}
        d="M239.46,134c-0.53,1.21-1.66,2.08-2.59,3.06c-1.24,1.33-2.15,2.98-2.26,4.75
  		c-0.11,1.76,0.67,3.62,2.22,4.62c-3.83-0.48-6.19-4.77-5.2-8.27c0.32-1.15,0.92-2.22,1.18-3.38"
      />
      <ellipse style={{fill: "#0D1E36"}} cx="214.64" cy="112.81" rx="1.57" ry="1.47" />
      <g>
        <g>
          <path
            style={{fill: "#B5B6B7"}}
            d="M223.95,105.18c0.1-0.07,0.21-0.13,0.32-0.19c0.12-0.07,0.25-0.13,0.37-0.19
  				c0.08-0.04,0.17-0.08,0.25-0.12c0.04-0.02,0.08-0.03,0.11-0.05c0.16-0.07-0.05,0.02,0.01-0.01c0.41-0.16,0.82-0.31,1.25-0.41
  				c0.12-0.03,0.23-0.05,0.35-0.07c0.06-0.01,0.13-0.02,0.19-0.03c0.15-0.02-0.06,0,0.03,0c0.26-0.02,0.51-0.04,0.77-0.03
  				c0.39,0.01,0.77-0.35,0.75-0.75c-0.02-0.42-0.33-0.74-0.75-0.75c-1.02-0.03-2.05,0.21-2.99,0.6c-0.49,0.2-0.98,0.42-1.43,0.71
  				c-0.33,0.21-0.5,0.68-0.27,1.03C223.13,105.24,223.6,105.41,223.95,105.18L223.95,105.18z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#B5B6B7"}}
            d="M213.51,105.73c-0.58-0.17-1.24-0.21-1.83-0.14c-0.62,0.07-1.21,0.23-1.77,0.52
  				c-0.68,0.35-1.23,0.89-1.69,1.49c-0.24,0.31-0.06,0.85,0.27,1.03c0.39,0.21,0.77,0.07,1.03-0.27c-0.08,0.11,0.09-0.1,0.1-0.12
  				c0.06-0.07,0.12-0.13,0.18-0.2c0.1-0.11,0.22-0.2,0.33-0.3c0.13-0.11-0.09,0.06,0.04-0.03c0.07-0.04,0.13-0.09,0.2-0.13
  				c0.11-0.07,0.23-0.14,0.35-0.19c0.04-0.02,0.27-0.11,0.07-0.04c0.05-0.02,0.1-0.04,0.15-0.06c0.13-0.05,0.26-0.09,0.39-0.12
  				c0.11-0.03,0.22-0.05,0.33-0.07c0.13-0.02,0.08,0-0.02,0c0.06,0,0.12-0.01,0.18-0.02c0.19-0.02,0.38-0.02,0.57-0.01
  				c0.09,0,0.18,0.01,0.26,0.02c0.04,0,0.07,0.01,0.11,0.01c0.13,0.01-0.23-0.04-0.06-0.01c0.14,0.02,0.28,0.05,0.41,0.09
  				c0.38,0.11,0.83-0.14,0.92-0.52C214.12,106.24,213.91,105.85,213.51,105.73L213.51,105.73z"
          />
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default Character08
