import {Paper, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, number, object} from "prop-types"

import * as questionTypes from "./survey-question-types"
import SurveySettingsAnswers from "./survey-settings-answers"

const SurveySettingsMaybeAnswers = ({question, questionIndex, onUpdateQuestion}) => {
  const classes = useStyles()

  return (
    <div>
      {[questionTypes.MULTIPLE_CHOICE, questionTypes.CHECKBOXES].includes(question.type) && (
        <Paper
          classes={{elevation1: classes.paperElevation1, root: classes.paperRoot}}
          className="answer-body"
        >
          <Typography>Answers</Typography>
          <Typography>
            (the user will be able to select{" "}
            {question.type === questionTypes.MULTIPLE_CHOICE
              ? "only one"
              : "all applicable choices"}
            )
          </Typography>

          <SurveySettingsAnswers
            onUpdateQuestion={onUpdateQuestion}
            question={question}
            questionIndex={questionIndex}
          />
        </Paper>
      )}
    </div>
  )
}

SurveySettingsMaybeAnswers.propTypes = {
  onUpdateQuestion: func.isRequired,
  question: object.isRequired,
  questionIndex: number.isRequired,
}

const useStyles = makeStyles(theme => ({
  paperElevation1: {
    boxShadow: "none",
  },
  paperRoot: {
    background: "none",
  },
}))

export default SurveySettingsMaybeAnswers
