import {Tooltip, Typography} from "@mui/material"
import {object} from "prop-types"

import Performance from "components/advanced-team-insights/performance"

import GapAnalysis from "./gap-analysis"

const AdvancedPerformance = ({classes}) => {
  return (
    <div className={classes.section}>
      <Tooltip
        placement="left-start"
        title={
          <span>
            Platform averages are calculated by aggregating engagement across all the financial
            institution sites on Digital Onboarding. <br />
            <br />
            Every financial institution is unique by geolocation, audience and campaign content.
            Like all industry averages, metrics are useful to have an awareness of fellow FIs but
            should not be a single source for performance measurement.
          </span>
        }
      >
        {/*NB: We are setting the `max-width` to 210px, so that the tooltip will flip to the right-hand side of the title, rather than the right-hand side of the end of the page*/}
        <Typography className={classes.header} variant="h5" style={{maxWidth: 210}}>
          Performance
        </Typography>
      </Tooltip>
      <Performance classes={classes} />
      <GapAnalysis classes={classes} />
    </div>
  )
}

AdvancedPerformance.propTypes = {
  classes: object,
}

export default AdvancedPerformance
