export function navigableTemplatePages(templatePages, pagesCompleted = []) {
  if (!templatePages.length) throw new Error("No Pages on this template?")

  const navigablePages = templatePages.filter(templatePage => {
    if (templatePage.page.hideWhenComplete && pagesCompleted.includes(templatePage.page.id))
      return false
    else return true
  })

  // If no navigable pages and user links directly to page, render the page as normal
  return 0 < navigablePages.length ? navigablePages : templatePages
}

export function getTemplatePageSibling(
  templatePages,
  currentPageId,
  {pagesCompleted = [], reverse = false} = {}
) {
  const _navigableTemplatePages = pagesCompleted.length
    ? navigableTemplatePages(templatePages, pagesCompleted)
    : templatePages
  const currentPageIndex = templatePages.findIndex(tp => tp.page.id === currentPageId)
  const templatePagesInDirection = reverse
    ? templatePages.slice(0, currentPageIndex).reverse()
    : templatePages.slice(currentPageIndex + 1, templatePages.length)

  return templatePagesInDirection.find(templatePage =>
    _navigableTemplatePages.includes(templatePage)
  )
}
