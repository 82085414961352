export const formatCategory = category => {
  if (category === "cmc-flex") return "INTEGRATION: CMC-FLEX"

  if (category === "ssh") return "SFTP"

  return category.toUpperCase()
}

export const formatAction = name => name.replace(/-/g, " ")

export const colorForCategory = category => {
  switch (category) {
    case "user":
      return "#58BEB1"
    case "ssh":
      return "#F2654B"
    case "batch":
      return "#002956"
    default:
      return ""
  }
}
