export const betaFeatures = [
  // Please keep this list alphabetically ordered.
  "accounts-table",
  "advanced-insights",
  "aggregate-widget-reporting",
  "atomic-assets",
  "automated-targeting",
  "card-on-file",
  "content-blocks",
  "cross-channel-engagement",
  "direct-deposit",
  "enrollment-widget",
  "journey-signup-form",
  "premier-attribution",
  "product-opening-value-reporting",
  "split-testing",
  "team-insights",
]

export const betaFeatureActions = {
  card_on_file_completed: "card-on-file",
  card_on_file_failed: "card-on-file",
  direct_deposit_completed: "direct-deposit",
  direct_deposit_failed: "direct-deposit",
  direct_deposit_started: "direct-deposit",
  journey_signup_form_submitted: "journey-signup-form",
  premier_objective_completed: "premier-attribution",
}

export const diagnosticFeatures = ["cmc-flex-integration"]
