import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {BsCardText as TextIcon} from "react-icons/bs"
import shortid from "shortid"

import {createEmptyDraftContent} from "lib/draft-js/editor-helpers"

const TextButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: createEmptyDraftContent(),
      type: "text",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <TextIcon />
      </ListItemIcon>
      <ListItemText primary="Text" ref={ref} />
    </MenuItem>
  )
})

TextButton.propTypes = {
  onAddContentBlock: func.isRequired,
  onClose: func,
  classes: object.isRequired,
}

export {TextIcon}

export default TextButton
