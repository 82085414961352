import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material"
import {bool, func, string} from "prop-types"

const WarningDialog = ({isOpen, message, onClose, onContinue, title}) => {
  const handleSubmit = e => {
    e.preventDefault()

    onContinue()
  }

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={isOpen}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
          <DialogActions>
            <Button className="cancel" color="primary" onClick={onClose} type="button">
              Cancel
            </Button>
            <Button className="submit" color="primary" type="submit" variant="contained">
              Continue?
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  )
}

WarningDialog.propTypes = {
  isOpen: bool.isRequired,
  message: string.isRequired,
  onClose: func.isRequired,
  onContinue: func.isRequired,
  title: string.isRequired,
}

export default WarningDialog
