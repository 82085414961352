import {object} from "prop-types"
import {NavLink} from "react-router-dom"

import Feature from "components/feature/feature"

const TeamSubNav = ({classes}) => (
  <>
    <NavLink className={classes.link} exact={true} title="Team - Users" to="/admin/team">
      <span>Users</span>
    </NavLink>

    <NavLink className={classes.link} title="Team - Audit Logs" to="/admin/team/audit-logs">
      <span>Audit Logs</span>
    </NavLink>

    <NavLink className={classes.link} title="Team - Webhook Logs" to="/admin/team/webhook-logs">
      <span>Webhook Logs</span>
    </NavLink>

    <NavLink className={classes.link} title="Team - SFTP Logs" to="/admin/team/session-logs">
      <span>SFTP Logs</span>
    </NavLink>

    <NavLink className={classes.link} title="Team - Batches" to="/admin/team/batches">
      <span>Batches</span>
    </NavLink>

    <Feature featureKey="team-seeding">
      <NavLink className={classes.link} title="Team - Seeding" to="/admin/team/seeding">
        <span>Seeding</span>
      </NavLink>
    </Feature>
  </>
)

TeamSubNav.propTypes = {
  classes: object,
}

export default TeamSubNav
