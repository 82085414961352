import * as Sentry from "@sentry/browser"

import {ACTION_ADDED} from "lib/hooks/use-analytics"

import analyticsCache, {updateFromServer} from "./analytics-cache"
import {
  CONFIRMED_PAGE_ADDED,
  CONFIRMED_PAGE_LIST_SET,
  JOURNEY_ANALYTIC_LIST_SET,
  JOURNEY_CLEAR,
  JOURNEY_CONTACT_UPDATED,
  JOURNEY_CREATED,
  JOURNEY_SCHEDULED_MESSAGES_LIST_SET,
  JOURNEY_SCHEDULED_MESSAGE_DELETED,
  JOURNEY_SET,
  JOURNEY_UPDATED,
  PENDING_REWARD_LIST_SET,
} from "./journey-actions"

const safelyGetContact = ({payload}, state) => {
  if (!payload) Sentry.captureException("Failed to get journey contact from an undefined payload")

  return payload && payload.contact ? {...state.contact, ...payload.contact} : state.contact
}

const initialState = {
  pendingRewards: [],
}

export default function journey(state = initialState, action) {
  switch (action.type) {
    case JOURNEY_CLEAR:
      return initialState
    case JOURNEY_SET:
    case JOURNEY_UPDATED:
    case JOURNEY_CREATED: {
      return {
        ...state,
        ...action.payload,
        contact: safelyGetContact(action, state),
        analyticsCache: updateFromServer(state.analyticsCache, action.payload.analyticsCache),
      }
    }
    case JOURNEY_CONTACT_UPDATED: {
      return {
        ...state,
        contact: {...state.contact, ...action.payload},
      }
    }
    case JOURNEY_ANALYTIC_LIST_SET:
      const cache = action.payload.reduce(
        (acc, analytic) => ({
          ...acc,
          [analytic.name]: (acc[analytic.name] || []).concat(analytic),
        }),
        {}
      )

      return {
        ...state,
        analyticsCache: {
          ...state.analyticsCache,
          ...cache,
          actions: action.payload,
        },
      }
    case JOURNEY_SCHEDULED_MESSAGES_LIST_SET:
      return {
        ...state,
        scheduledMessages: action.payload,
      }
    case JOURNEY_SCHEDULED_MESSAGE_DELETED:
      return {
        ...state,
        scheduledMessages: state.scheduledMessages.filter(
          message => message.jobId !== action.payload.id
        ),
      }
    case CONFIRMED_PAGE_LIST_SET: {
      return {
        ...state,
        pagesConfirmed: action.payload,
      }
    }
    case CONFIRMED_PAGE_ADDED: {
      return {
        ...state,
        pagesConfirmed: state.pagesConfirmed.includes(action.payload)
          ? state.pagesConfirmed.filter(pageId => pageId !== action.payload)
          : state.pagesConfirmed.concat([action.payload]),
      }
    }
    case ACTION_ADDED: {
      return {
        ...state,
        analyticsCache: analyticsCache(state.analyticsCache, action.payload),
      }
    }
    case PENDING_REWARD_LIST_SET: {
      return {
        ...state,
        pendingRewards: action.payload,
      }
    }
    default:
      return state
  }
}
