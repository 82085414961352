import makeStyles from "@mui/styles/makeStyles"
import {EditorState} from "draft-js"
import defer from "lodash/defer"
import {func, object, string} from "prop-types"
import {useState} from "react"

import FontSelector from "components/font-selector/font-selector"
import {journeyContext} from "components/journeys/journey-context"

import {contentTypeProp, editorContext, isPage} from "contexts/editor-context"
import {menuProps} from "lib/draft-js/editor-helpers"
import toggleInlineStyle from "lib/draft-js/toggle-inline-style"

const getFontFamilyFromEditorState = (editorState, themeFontFamily = "Roboto, sans-serif") => {
  if (!editorState) return themeFontFamily

  // TODO: improve this function to handle multiple font types in selection
  const fontWithPrefix = editorState
    .getCurrentInlineStyle()
    .filter(style => style.startsWith("font-family"))
    .first()

  if (!fontWithPrefix) return themeFontFamily

  return fontWithPrefix.replace("font-family-", "")
}

const FontSelectorInput = ({editorState, onSetEditorState, fontFamily, contentType}) => {
  const classes = useStyles()
  const currentFont = getFontFamilyFromEditorState(editorState, fontFamily)
  const [prevSelection, setPrevSelection] = useState(null)
  const onOpenFontDialog = () => {
    const selection = editorState.getSelection().toJS()
    setPrevSelection(selection)
  }

  const onSelect = ({family}) => {
    // Specifically when you open the google font dialog (because it's dialog-
    // -ception) and select a font or cancel, the focus goes back to the select.
    // Deferring will wait until that has happened and then set the new editor
    // state, including the selection
    defer(() => {
      const selection = editorState.getSelection()
      const updatedSelection = selection.merge(prevSelection)
      const nextState = EditorState.forceSelection(editorState, updatedSelection)

      onSetEditorState(toggleInlineStyle(nextState, "font-family", family))
      setPrevSelection(null)
    })
  }

  return (
    <FontSelector
      className={classes.fontSelectorInput}
      enableGoogleFonts={isPage(contentType)}
      MenuProps={menuProps}
      onSelect={onSelect}
      onOpenFontDialog={onOpenFontDialog}
      value={currentFont}
    />
  )
}

FontSelectorInput.propTypes = {
  contentType: contentTypeProp,
  editorState: object,
  fontFamily: string,
  onSetEditorState: func,
}

const useStyles = makeStyles(() => ({
  fontSelectorInput: {
    width: 150,
    textOverflow: "ellipsis",
  },
}))

export default journeyContext(editorContext(FontSelectorInput))
