// This is far from perfect but it's far more manageable than our previous
// method of email validation. It breaks down email addresses to their two
// main parts: local and domain. And evaluates them separately. The sad truth
// is that these days nearly anything is a valid email address so it's easier
// to match on what is NOT allowed than what is allowed.

// This matches any local part that starts or ends with a `.`.
// It also matches any characters that are only allowed in a local part if
// they are contained inside a quoted string (including repeated `.`).
const INVALID_EMAIL_LOCAL_REGEX = /(?:^\.|\.{2,}|["(),:;<>@[\]]|\.$)/

// This matches any domain part that starts or ends with a `.` or `-` as well
// as any repetition of those characters. It also matches any ASCII characters
// that are NOT allowed anywhere in a domain.
const INVALID_EMAIL_DOMAIN_REGEX = /(?:^-|^\.|\.{2,}|-\.|\.-|-{2,}|\s|[ -,/:-@[-`]|\.$|-$)/

export default function validateEmail(email) {
  if (!email) return false

  const [local, domain, ...rest] = email.split(/@/)

  return (
    rest.length === 0 &&
    !!local &&
    !INVALID_EMAIL_LOCAL_REGEX.test(local) &&
    !!domain &&
    !INVALID_EMAIL_DOMAIN_REGEX.test(depuny(domain))
  )
}

// This is only useful for making sure our domain test above doesn't trip over a
// punycode domain.
export const depuny = domain => domain.replace(/^xn--/, "puny")
