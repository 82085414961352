import RewardsButton, {RewardsIcon} from "./rewards-button"
import RewardsReadonly from "./rewards-readonly"
import RewardsSettings from "./rewards-settings"

export default {
  Button: RewardsButton,
  Icon: RewardsIcon,
  Readonly: RewardsReadonly,
  Settings: RewardsSettings,
  suitableFor: ["content_container", "page"],
  title: "Rewards",
  type: "rewards",
}
