import omit from "lodash/omit"
import {string} from "prop-types"

// A wrapper around uploaded files whose variants might not be ready, this will fall back to the
// original onerror (404)

const UploadedImage = ({src, variant, cacheBuster, ...props}) => {
  // Check if src contains '?variant=' and adjust src and variant accordingly
  const variantMatch = src.match(/(\?|&)variant=([^&]+)/)
  if (variantMatch) {
    variant = variantMatch[2] // Set the variant to the matched value
    src = src.replace(variantMatch[0], "") // Remove the variant parameter from src
  }

  const onError = e => {
    let originalSrc = `${src}?variant=original`

    if (cacheBuster) originalSrc = `${originalSrc}&cache_buster=${cacheBuster}`

    if (e.target.src === originalSrc) return // Oh, original is also a 404?

    e.target.onError = null // eslint-disable-line no-param-reassign
    e.target.src = originalSrc // eslint-disable-line no-param-reassign
  }

  let variantSrc = `${src}?variant=${variant}`

  if (cacheBuster) variantSrc = `${variantSrc}&cache_buster=${cacheBuster}`

  return (
    <img
      alt={""}
      style={{maxWidth: "100%", maxHeight: "100%"}}
      {...omit(props, ["cacheBuster", "variant"])}
      onError={onError}
      src={variantSrc}
    />
  )
}

UploadedImage.propTypes = {
  cacheBuster: string,
  src: string.isRequired,
  variant: string,
  alt: string,
  className: string,
}

export default UploadedImage
