import {bool, func, number, string} from "prop-types"
import {useState} from "react"
import {Swatch} from "react-color/lib/components/common"

// Modification of the CircleSwatch component from the React Color library.
//   Creates a quick-select swatch for predefined custom colors. This mod adds
//   title property shown on hover.
//
//   SEE https://github.com/casesandberg/react-color/blob/master/src/components/circle/CircleSwatch.js
export const CircleSwatch = ({active, color, onClick, circleSize, circleSpacing, title}) => {
  const [isHovered, setIsHovered] = useState(false)
  const styles = {
    swatch: {
      width: circleSize,
      height: circleSize,
      marginRight: circleSpacing,
      marginBottom: circleSpacing,
      transform: "scale(1)",
      transition: "100ms transform ease",
    },
    swatchHover: {
      transform: "scale(1.2)",
    },
    Swatch: {
      borderRadius: "50%",
      background: "transparent",
      boxShadow: `inset 0 0 0 ${circleSize / 2 + 1}px ${color}`,
      transition: "100ms box-shadow ease",
    },
    SwatchActive: {
      boxShadow: `inset 0 0 0 3px ${color}`,
    },
  }

  return (
    <div
      style={isHovered ? {...styles.swatch, ...styles.swatchHover} : styles.swatch}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Swatch
        style={active ? {...styles.Swatch, ...styles.SwatchActive} : styles.Swatch}
        color={color}
        onClick={onClick}
        focusStyle={{boxShadow: `${styles.Swatch.boxShadow}, 0 0 5px ${color}`}}
        title={title}
      />
    </div>
  )
}

CircleSwatch.defaultProps = {
  circleSize: 28,
  circleSpacing: 14,
}

CircleSwatch.propTypes = {
  active: bool,
  color: string,
  onClick: func,
  circleSize: number,
  circleSpacing: string,
  title: string,
}

export default CircleSwatch
