import {displayAppMessage} from "actions/app-actions"
import {
  loadingStateCompleted,
  loadingStateFailed,
  loadingStateStarted,
} from "actions/loading-state-actions"

import {sendMobileAppDownloadLink} from "lib/api"

export const MOBILE_APP_DOWNLOAD_LINK_SENT = "MOBILE_APP_DOWNLOAD_LINK_SENT"

export const requestLink = attrs => dispatch => {
  dispatch(loadingStateStarted(MOBILE_APP_DOWNLOAD_LINK_SENT, "Sending..."))

  return sendMobileAppDownloadLink(attrs)
    .then(() => dispatch(loadingStateCompleted(MOBILE_APP_DOWNLOAD_LINK_SENT)))
    .catch(({body}) => {
      dispatch(displayAppMessage(body?.error))
      return dispatch(loadingStateFailed(MOBILE_APP_DOWNLOAD_LINK_SENT))
    })
}
