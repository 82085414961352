import {TextField} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object, shape, string} from "prop-types"
import {Component} from "react"
import {connect} from "react-redux"

import CtaButtonSettings from "components/content-block-editor/cta-button-settings"

import {validDomainAndTld} from "lib/field-validations"
import {formify} from "lib/hooks/use-form"
import {formatUrl} from "lib/string/url-helpers"

import FormActions from "../form-actions"

class CardOnFileSettings extends Component {
  render() {
    const {change, classes, contentBlock, field, handleSubmit, resetForm, teamName} = this.props

    return (
      <form aria-label="Card on File Settings" onSubmit={handleSubmit} className={classes.root}>
        <TextField
          autoFocus={true}
          fullWidth={true}
          id={`card-on-file-${contentBlock.slug}-merchant-selection-message`}
          label={"Merchant selection text"}
          margin="normal"
          {...field("merchantSelectionMessage", {
            defaultValue: `Select the sites below to make your ${teamName} card the primary payment method.`,
          })}
        />
        <TextField
          fullWidth={true}
          id={`card-on-file-${contentBlock.slug}-final-message`}
          label="Final Message"
          margin="normal"
          {...field("finalMessage")}
        />
        <div className={classes.colorWrapper}>
          <CtaButtonSettings change={change} field={field} hideIconInput={true} />
        </div>
        <FormActions resetForm={resetForm} />
      </form>
    )
  }
}

const styles = theme => ({
  colorWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    "& > *": {
      flex: 1,
    },
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  fakeFormLabel: {
    color: theme.palette.text.secondary,
  },
  root: {
    width: "100%",
  },
})

CardOnFileSettings.propTypes = {
  change: func.isRequired,
  classes: object.isRequired,
  contentBlock: shape({
    slug: string,
  }),
  field: func.isRequired,
  handleSubmit: func.isRequired,
  initialValues: object.isRequired,
  resetForm: func,
  teamName: string.isRequired,
}

const CardOnFileSettingsForm = formify({
  parse: {url: formatUrl},
  validators: {url: [validDomainAndTld]},
})(CardOnFileSettings)

const mapStateProps = ({session}) => ({
  teamName: session?.team?.name,
})

export default connect(mapStateProps)(withStyles(styles)(CardOnFileSettingsForm))
