import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

import Expandable from "components/expandable/expandable"

import CompletedObjectivesBarchart from "./completed-objectives-barchart"

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  label: {
    display: "flex",
    justifyContent: "flex-end",
  },
  subheader: {
    fontSize: 24,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
}))

const CompletedObjectives = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography className={classes.subheader} variant="h5">
        Completed Objectives by contacts
      </Typography>
      <Expandable label="How we use this chart">
        <p>This table reports on the total # of reported objective completions by contacts.</p>

        <p>Recommendations:</p>
        <ul>
          <li>
            <b>Use the Filter!</b> Filter by Online Banking, Mobile Banking, and eStatements to see
            a high-level overview of how many contacts have engaged in services.
          </li>
          <li>
            <b>Data Ingress.</b> The more service and product data the platform has, the more
            campaign objectives can be tracked and reported on.
          </li>
          <li>
            <b>Monitor Objective Completions.</b> Use the aggregated reporting to see upticks in
            specific objectives. For instance, after launching three targeted auto pay campaigns for
            Loan accounts, was there an uptick in completions?
          </li>
        </ul>
      </Expandable>

      <Expandable label="Hide chart" labelClassName={classes.label} startExpanded={true}>
        <CompletedObjectivesBarchart />
      </Expandable>
    </div>
  )
}

export default CompletedObjectives
