import {Collapse, Link, TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import pick from "lodash/pick"
import {array, func, number, object, shape, string} from "prop-types"

import {formatDateTime} from "lib/date-time-formatters"
import {fullName} from "lib/names"

import {colorForCategory, formatAction, formatCategory} from "./humanize-action"
import MetadataDisplay from "./metadata-display"

export const nameForUser = user => {
  if (!user) return ""
  if (user && (user.nameFirst || user.nameLast)) return fullName(user)
  if (user && user.email) return user.email
  return ""
}

const getLocation = remoteIpMeta =>
  [remoteIpMeta?.city, remoteIpMeta?.regionCode].filter(Boolean).join(", ")

const valueForRecord = (record, field) => {
  switch (field) {
    case "action":
      const [category, action] = record[field].split("/")

      return (
        <div>
          <b style={{color: colorForCategory(category)}}>{formatCategory(category)}</b> <br />
          <span style={{textTransform: "capitalize"}}>{formatAction(action)}</span>
        </div>
      )
    case "user":
      const {user} = record

      return (
        <div>
          <div>{nameForUser(user)}</div>
          {user?.isFromTower && (
            <Typography sx={{fontSize: "0.9em", color: "#f2654b"}}>non-FI superadmin</Typography>
          )}
        </div>
      )
    case "location":
      if (record.action.match(/^cmc-flex/)) return ""

      return getLocation(record.remoteIpMeta)
    case "timestamp":
      return formatDateTime(record.timestamp)
    case "batchId":
      return record[field] ? (
        <Link href={`/admin/contacts?batchId=${record[field]}`}>{record[field]}</Link>
      ) : (
        ""
      )
    case "sshKey":
      return (
        record.sshKey && (
          <>
            <Link href={`/admin/settings/sftp`}>{record.sshKey.title}</Link>
            <Typography display="block" variant="caption">
              {record.sshKey.fingerprint}
            </Typography>
          </>
        )
      )
    default:
      return record[field] || ""
  }
}

const useStyles = makeStyles(theme => ({
  hiddenBorder: {
    borderBottom: "none",
  },
  expandableRow: {
    cursor: "pointer",
  },
  noData: {
    padding: theme.spacing(1),
  },
  data: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const metadata = ({action, meta, remoteIpMeta}) => {
  // TODO: all ssh/* logs will have a sessionLogId key in the metadata.
  // this can be used to link to the session in the session log tab
  switch (action) {
    case "user/attribution-model-modified":
      return {
        engagementThreshold: `Changed from "${meta.from.engagementThreshold}" to "${meta.to.engagementThreshold}"`,
        attributionWindow: `Changed from "${meta.from.attributionWindow}" to "${meta.to.attributionWindow}"`,
      }
    case "user/logged-in":
      return {
        location: `${remoteIpMeta?.city ?? "Unknown City"}, ${
          remoteIpMeta?.regionCode ?? "Unknown State"
        } (${remoteIpMeta?.ip ?? "Unknown IP"})`,
      }

    case "user/file-processed":
    case "ssh/file-processed":
      return pick(meta, ["originalFilename", "deletedAt", "totalRowsInCsv"])
    case "batch/operation-completed":
      return pick(meta, ["uploadResults", "source"])

    case "cmc-flex/update-accounts-complete":
    case "cmc-flex/new-accounts-complete":
      return pick(meta, ["uploadResults"])

    case "ssh/file-uploaded":
      return pick(meta, ["originalFilename"])

    case "user/permissions-updated":
    case "user/added":
    case "user/removed":
      return pick(meta, ["affectedUser", "added", "removed"])
    case "contact/modified":
      return pick(meta, ["contact"])

    case "campaign/reverted-to-draft":
      return pick(meta, ["userComment"])

    case "campaign/approval-sent":
      return pick(meta, ["notes", "users"])

    case "campaign/approved":
    case "campaign/not-approved":
      return pick(meta, ["notes"])

    case "campaign/pdf-download":
    case "campaign/fully-approved":
    case "campaign/renew-expired-approval":
      return pick(meta, ["version"])
    case "tower/objective-completions-deleted":
      return pick(meta, [
        "date",
        "deletedActionCount",
        "deletedObjectiveCompletionCount",
        "objectiveId",
      ])

    default:
      return {}
  }
}

const AuditLogRow = ({columns, activeRowIndex, onClick, rowIndex, row: auditLog}) => {
  const onClickRow = () => {
    onClick(rowIndex)
  }
  const classes = useStyles()
  const isActiveRow = activeRowIndex === rowIndex

  const validMetadata = metadata(auditLog)
  const hasAdditionalMetadata = Object.values(validMetadata).filter(Boolean).length > 0

  return (
    <>
      <Tooltip title="View event details">
        <TableRow className={classes.expandableRow} hover={true} onClick={onClickRow}>
          {columns.map(({field}) => (
            <TableCell component="td" key={field} scope="row">
              <span>{valueForRecord(auditLog, field)}</span>
            </TableCell>
          ))}
        </TableRow>
      </Tooltip>
      <TableRow>
        <TableCell
          classes={{root: cx({[classes.hiddenBorder]: !isActiveRow})}}
          colSpan={columns.length}
          style={{paddingBottom: 0, paddingTop: 0}}
        >
          <Collapse in={isActiveRow} unmountOnExit={true}>
            {hasAdditionalMetadata ? (
              <div className={classes.data}>
                {Object.entries(validMetadata).map(([field, value]) => (
                  <MetadataDisplay field={field} key={field} value={value} />
                ))}
              </div>
            ) : (
              <div className={classes.noData}>No additional details to display</div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

AuditLogRow.propTypes = {
  activeRowIndex: number,
  columns: array.isRequired,
  onClick: func.isRequired,
  row: shape({
    user: object,
    batch_id: string,
    ssh_key_id: string,
    insertedAt: string,
    timestamp: string,
    action: string,
  }),
  rowIndex: number,
}

export default AuditLogRow
