import {Button, ButtonGroup as MuiButtonGroup} from "@mui/material"
import PropTypes from "prop-types"

// Refactor this to a generic ButtonGroup wrapper that you pass a key/value object into?
export const ButtonGroup = ({setCurrentValue, classes, currentValue, values}) => (
  <MuiButtonGroup className={classes.buttonGroup} size="small" variant="outlined">
    {Object.entries(values).map(([key, value]) => (
      <Button
        color={key.toLowerCase() === currentValue.toLowerCase() ? "primary" : "grey"}
        key={key}
        onClick={() => setCurrentValue(values[key])}
        tabIndex={0}
        value={value}
      >
        {value}
      </Button>
    ))}
  </MuiButtonGroup>
)

ButtonGroup.propTypes = {
  classes: PropTypes.shape({
    buttonGroup: PropTypes.string,
  }),
  currentValue: PropTypes.string,
  setCurrentValue: PropTypes.func,
  values: PropTypes.shape({}),
}
