import {Button, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object, string} from "prop-types"
import {FaExclamationCircle as FailedIcon, FaCheckCircle as SuccessIcon} from "react-icons/fa"
import {Link} from "react-router-dom"

import pluralize from "lib/string/pluralize"

import Counter from "../counter/counter"
import {getLink} from "./batch-upload-helpers"

const UploadFinished = ({data, batchId, onReset, classes, templateId}) => {
  const {existing: existingCount, failed: failedCount, new: newCount, batchType} = data

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <Header classes={classes} hasFailedRecords={failedCount > 0} />
        <Typography className={classes.resultText} variant="h4">
          We created {<Counter initial={0}>{newCount}</Counter>} {pluralize(batchType, newCount)}{" "}
          and updated {<Counter initial={0}>{existingCount}</Counter>}{" "}
          {pluralize(batchType, existingCount)}.
        </Typography>
        {failedCount > 0 && (
          <Typography variant="h4">
            We were unable to upload {<Counter initial={0}>{failedCount}</Counter>}{" "}
            {pluralize(batchType, failedCount)}.
          </Typography>
        )}
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          component={Link}
          size="medium"
          to={getLink(batchType, templateId, batchId)}
          variant="contained"
        >
          View List
        </Button>
        <Button className={classes.button} color="grey" onClick={onReset} size="medium">
          Need to upload another?
        </Button>
      </div>
    </div>
  )
}

const Header = ({hasFailedRecords, classes}) =>
  hasFailedRecords ? (
    <>
      <FailedIcon className={classes.icon} size={60} />
      <Typography className={classes.failureText} variant="h2">
        Oops!
      </Typography>
    </>
  ) : (
    <>
      <SuccessIcon className={classes.icon} size={60} />
      <Typography variant="h2">Success!</Typography>
    </>
  )

Header.propTypes = {
  classes: object,
  hasFailedRecords: bool,
}

const styles = theme => ({
  container: {
    padding: theme.spacing(1),
    minHeight: 370,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
  },
  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    color: theme.palette.primary.main,
    flex: 1,
  },
  resultText: {
    marginTop: theme.spacing(2),
  },
  actions: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: `${theme.spacing(1)} auto`,
  },
  failureText: {
    fontSize: "2em",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
})

UploadFinished.propTypes = {
  batchId: string.isRequired,
  classes: object,
  data: object,
  onReset: func.isRequired,
  templateId: string,
}

export default withStyles(styles)(UploadFinished)
