import {TableCell, TableRow, Typography} from "@mui/material"
import orderBy from "lodash/orderBy"
import {func, number, object, string} from "prop-types"
import {useCallback, useEffect, useMemo} from "react"

import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"
import {useInsights} from "components/template-insights/insights-context"

import {fetchInsightProductTotalValue} from "lib/api"

const calculateLiftFields = product => {
  return {
    ...product,
    totalValueLift5: Math.round(product.totalValue * 1.05),
    totalValueLift10: Math.round(product.totalValue * 1.1),
  }
}

const ProductTotalValueTable = ({
  classes,
  itemsPerPage,
  page,
  setTableState,
  sortColumn,
  sortDirection,
  totalCount,
}) => {
  const {
    insights: {product_total_value},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()

  useEffect(() => {
    fetchInsightProductTotalValue(templateId, filter).then(addInsightsCache)
  }, [addInsightsCache, templateId, filter])

  const rows = useMemo(
    () =>
      product_total_value
        ? product_total_value.value.products.map(p => calculateLiftFields(p))
        : null,
    [product_total_value]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetTableState = useMemo(() => setTableState, [])

  const applySortAndPagination = useCallback(
    attrs => {
      if (!rows) return

      const mergedAttrs = {
        itemsPerPage: attrs?.itemsPerPage ?? itemsPerPage,
        page: attrs?.page ?? page,
        sortColumn: attrs?.sortColumn ?? sortColumn,
        sortDirection: attrs?.sortDirection ?? sortDirection,
        totalCount: rows.length ?? totalCount,
      }
      const sortDataFor = attrs => {
        const sorted = orderBy(rows, [attrs.sortColumn], [attrs.sortDirection])
        const pageStartIndex = attrs.itemsPerPage * attrs.page

        return pageStartIndex + attrs.itemsPerPage > totalCount
          ? sorted.slice(pageStartIndex)
          : sorted.slice(pageStartIndex, pageStartIndex + attrs.itemsPerPage)
      }

      memoizedSetTableState({...mergedAttrs, rows: sortDataFor(mergedAttrs)})
    },
    [itemsPerPage, page, sortColumn, sortDirection, memoizedSetTableState, totalCount, rows]
  )

  useEffect(() => {
    applySortAndPagination()
  }, [rows, applySortAndPagination])

  if (!rows) return "Loading..."

  if (!rows.length) return <></>

  return (
    <>
      <Typography
        variant="h5"
        sx={theme => ({fontSize: 36, fontWeight: 200, marginBottom: theme.spacing(1)})}
      >
        Total Value of Opened Products
      </Typography>

      <DOTable
        headers={[
          {field: "productName", label: "Product", sortable: true},
          {field: "totalValue", label: "Current", sortable: true},
          {field: "totalValueLift5", label: "5% Lift"},
          {field: "totalValueLift10", label: "10% Lift"},
        ]}
        rows={rows}
        paginationEnabled={true}
        refresh={applySortAndPagination}
      >
        {(row, index, columns) => (
          <TableRow key={index}>
            {columns.map(col => (
              <TableCell key={col.field}>
                {col.field.startsWith("totalValue")
                  ? `$${parseFloat(row[col.field]).toLocaleString("en-US")}`
                  : row[col.field]}
              </TableCell>
            ))}
          </TableRow>
        )}
      </DOTable>

      <div className={classes.divider} />
    </>
  )
}

ProductTotalValueTable.propTypes = {
  classes: object,
  itemsPerPage: number,
  page: number,
  setTableState: func.isRequired,
  sortColumn: string,
  sortDirection: string,
  totalCount: number,
}

export default tabular({
  sortColumn: "productName",
  sortDirection: "asc",
  itemsPerPage: 5,
})(ProductTotalValueTable)

// export default ProductTotalValueTable
