import DirectDepositButton, {DepositIcon} from "./direct-deposit-button"
import DirectDepositReadonly from "./direct-deposit-readonly"
import DirectDepositSettings from "./direct-deposit-settings"

export default {
  title: "Direct Deposit",
  type: "direct-deposit",
  Button: DirectDepositButton,
  Icon: DepositIcon,
  Readonly: DirectDepositReadonly,
  Settings: DirectDepositSettings,
  suitableFor: ["content_container", "page"],
}
