import {TableRow} from "@mui/material"
import {arrayOf, bool, object, shape, string} from "prop-types"
import React from "react"

import AccountsTableCell from "./accounts-table-cell"

const stackedStyles = theme => ({
  "& td": {
    fontSize: theme.custom?.accountsTable?.dataRow?.fontSize ?? "14px",
    fontFamily: theme.custom?.accountsTable?.fontFamily ?? theme.typography.fontFamily,
  },
})

const horizontalStyles = theme => ({
  "& td": {
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    fontSize: theme.custom?.accountsTable?.dataRow?.fontSize ?? "14px",
    fontFamily: theme.custom?.accountsTable?.fontFamily ?? theme.typography.fontFamily,
  },
  "&:nth-of-type(odd)": {backgroundColor: theme.palette.lightestGray},
})

// Used in journey view and reads columns config and sources values for each cell
const AccountRowReadonly = ({account, contact, columns, isStacked}) => {
  const readValueFromJourneyData = contentId => {
    return contentId.split(".").reduce((acc, key) => {
      if (key === "account") {
        return account
      }

      if (key === "contact") {
        return contact
      }

      if (key === "fullName") {
        return `${acc["firstName"]} ${acc["lastName"]}`
      }

      if (["meta_private", "meta_public"].includes(key)) {
        return account[key]
      }

      // Placeholder value to accommodate missing values when light auth successful
      // but API response with private data not yet received
      return !!acc && !!acc[key] ? acc[key] : "Not available"
    }, "")
  }

  return (
    <TableRow sx={theme => (isStacked ? stackedStyles(theme) : horizontalStyles(theme))}>
      {columns.map(({header, contentId}, index) => (
        <AccountsTableCell
          contentId={contentId}
          label={header}
          key={`${index}`}
          value={readValueFromJourneyData(contentId)}
        />
      ))}
    </TableRow>
  )
}

AccountRowReadonly.defaultProps = {columns: []}

AccountRowReadonly.propTypes = {
  account: object.isRequired,
  contact: object.isRequired,
  columns: arrayOf(shape({header: string, contentId: string})),
  isStacked: bool,
}

export default AccountRowReadonly
