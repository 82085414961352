/* eslint-disable react/prop-types */
// FIXME disabled-react-prop-types
import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import truncate from "lodash/truncate"
import {array, arrayOf, bool, func, number, object, string} from "prop-types"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import {COLORS} from "./insights-helpers"

// To allow bar chart to look good when only one element is present
// versus more than one element due to us needing to push down the
// legend to allow staggering x-axis categories to fit lengthy
// category names
export const dynamicStyleBuilder = data => ({
  barChart: {
    height: data.length > 1 ? 320 : 300,
    marginBottom: data.length > 1 ? 20 : 0,
  },
  chartContainer: {
    marginBottom: data.length > 1 ? 50 : 0,
  },
  legend: {
    bottom: data.length > 1 ? -40 : 0,
  },
})

export const CustomXAxisTick = tickCount => props => {
  // 104 represents a static number that gets us the dynamic width
  // of the chart when subtracted from the innerWidth of the window
  const chartWidth = window.innerWidth - 104
  const tickWidth = chartWidth / (tickCount / 2)
  const fontSizeAvg = 10
  const marginTop = tickCount > 1 ? 10 : 0
  const length = tickWidth / fontSizeAvg

  return (
    <Text {...props} y={props.y + marginTop + 30 * (props.payload.index % 2)}>
      {truncate(props.payload.value, {length})}
    </Text>
  )
}

const CustomBarLabel = (labelFn, fillColor) => ({height, value, width, x, y}) => (
  <text
    className="recharts-text recharts-label"
    fill={fillColor}
    height={height || 0}
    textAnchor="middle"
    width={width}
    x={x + width / 2}
    y={(y || 0) + 15}
  >
    {value > 0 ? (
      <tspan dy="-1.5em" fontSize="1.125rem" fontWeight="bold" x={x + width / 2}>
        {labelFn(value)}
      </tspan>
    ) : (
      ""
    )}
  </text>
)

CustomBarLabel.propTypes = {
  height: number,
  labelFn: func.isRequired,
  value: string,
  width: number,
  x: number,
  y: number,
}

const GroupedBarchart = ({barData, data, isEmpty, labelFormatter}) => {
  const classes = useStyles()

  const dynamicStyles = dynamicStyleBuilder(data)

  return (
    <div
      className={classes.chartContainer}
      style={{marginBottom: dynamicStyles.chartContainer.marginBottom}}
    >
      <ResponsiveContainer height={dynamicStyles.barChart.height}>
        <BarChart
          barGap={0}
          data={data}
          margin={{top: 25, left: 0, right: 0, bottom: dynamicStyles.barChart.marginBottom}}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" interval={0} tick={CustomXAxisTick(data.length)} />
          <YAxis tickFormatter={labelFormatter} />
          <Tooltip cursor={{fill: "transparent"}} formatter={labelFormatter} />
          <Legend wrapperStyle={{bottom: dynamicStyles.legend.bottom}} />
          {barData.map(([key, value], i) => (
            <Bar
              dataKey={key}
              fill={COLORS[i]}
              isAnimationActive={false}
              key={key}
              label={CustomBarLabel(labelFormatter, COLORS[i])}
              maxBarSize={75}
              name={value}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      {isEmpty && <Typography className={classes.chart404}>No Data Found</Typography>}
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  chart404: {
    color: theme.palette.brand.mediumGray,
    fontSize: 20,
    fontStyle: "italic",
    fontWeight: 500,
    marginLeft: -40,
    left: "50%",
    position: "absolute",
    top: 100,
  },
  chartContainer: {
    position: "relative",
    width: "100%",
  },
}))

GroupedBarchart.propTypes = {
  // Array of array tuples where the first element contains a bar's machine name and the second contains its human-readable label
  barData: arrayOf(array),
  //  Array of objects with properties that correspond to the machine names found in barData and values that correspond to bar size, along with a `name` property which is used to label the bar group
  data: arrayOf(object),
  isEmpty: bool.isRequired,
  labelFormatter: func.isRequired,
}

export default GroupedBarchart
