import {Skeleton, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {isEmpty} from "lodash"
import {object} from "prop-types"
import React, {useEffect, useMemo, useState} from "react"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Surface,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import {ButtonGroup} from "components/buttons/button-group"
import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"

import {fetchAdvancedObjectiveCompletionRate} from "lib/api"

import {PERIOD, formatPeriodLabel} from "./helpers"

const useStyles = makeStyles(theme => {
  return {
    buttonGroup: {paddingRight: theme.spacing(1)},
    chartLegendList: {
      "& li": {lineHeight: 1},
      "& svg.recharts-surface": {
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: theme.spacing(1),
      },
      display: "flex",
      flexWrap: "wrap",
      gap: `${theme.spacing(1)} ${theme.spacing(3)}`,
      listStyle: "none",
      margin: 0,
      padding: 0,
      width: "100%",
    },
    chartTooltipWrapper: {
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      display: "block",
      margin: 0,
      padding: "10px",
      whiteSpace: "nowrap",
      "& li": {margin: 0, padding: "4px 0"},
      "& ul": {color: "#000", listStyle: "none", margin: 0, padding: 0},
    },
    chartTruncateSelector: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
    },
    chartWrapper: {
      marginTop: theme.spacing(1),
      position: "relative",
    },
    pagingIconDisabled: {color: theme.palette.grey.dark},
    pagingIconEnabled: {color: theme.palette.primary.main, cursor: "pointer"},
  }
})

const ObjectiveCompletionRate = ({classes: pageClasses}) => {
  const classes = {...pageClasses, ...useStyles()}
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    objectiveCompletionRate,
    objectiveCompletionRateIsLoading,
  } = useAdvancedTeamInsights()

  const [currentPeriod, setCurrentPeriod] = useState(PERIOD.MONTH)

  const formattedData = useMemo(() => {
    if (isEmpty(objectiveCompletionRate?.data)) return []

    return objectiveCompletionRate?.data.reduce((accum, value) => {
      const formattedValues = value.objectives.reduce((_accum, val) => {
        return {
          ..._accum,
          [val.objectiveName]: val.pocr,
        }
      }, {})

      /*
        Browsers will automatically interpret dates without time/timezone to be zero-offset
        aka GMT time, and then convert it back to the user browser's local timezone when
        passed into `new Date()`. For mysterious reasons adding the time without the timezone
        forces it to ignore timezones and format the date as expected.

        When in Mountain Standard Time:
        new Date('2022-12-01') // Fri Dec 31 2021 17:00:00 GMT-0700 (Mountain Standard Time)
        new Date('2022-12-01T00:00:00') // Sat Jan 01 2022 00:00:00 GMT-0700 (Mountain Standard Time)
      */
      return [
        ...accum,
        {
          name: `${value.period}T00:00:00`,
          ...formattedValues,
        },
      ]
    }, [])
  }, [objectiveCompletionRate])

  const chartLineItems = useMemo(() => {
    // If no data just return an empty array
    if (!objectiveCompletionRate?.data || objectiveCompletionRate?.data.length <= 0) return []

    const items = objectiveCompletionRate?.data[0].objectives
    return items.map(({objectiveId, objectiveName}, index) => ({
      color: INSIGHTS_COLORS[index],
      name: objectiveName,
      objectiveId: objectiveId,
    }))
  }, [objectiveCompletionRate])

  // Fetch data
  useEffect(() => {
    if (!hasSocket) return

    fetchInsights("objectiveCompletionRate", fetchAdvancedObjectiveCompletionRate, [
      {...filterParams, timeUnit: currentPeriod.toLowerCase()},
    ])
  }, [fetchInsights, filterParams, hasSocket, currentPeriod])

  return (
    <div>
      <div className={classes.chartTruncateSelector}>
        <Typography variant="h5">Objective completion rate by time</Typography>

        <ButtonGroup
          setCurrentValue={setCurrentPeriod}
          currentValue={currentPeriod}
          classes={classes}
          values={PERIOD}
        />
      </div>

      <div className={classes.chartWrapper}>
        {/*
            TODO - Move this out to its own shared component in /src/components/charts

            NOTE – Recharts will automatically omit x-axis labels as-neededif it thinks they
            will collide with each other.
        */}
        {objectiveCompletionRateIsLoading ? (
          <Skeleton variant="square" width="100%" height={280} />
        ) : (
          <ResponsiveContainer minHeight={280} width="100%">
            <LineChart
              width={1000}
              height={300}
              data={formattedData}
              margin={{
                top: 10,
                right: 10,
                left: -35,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />

              <XAxis
                dataKey="name"
                tick={{fontSize: "10px"}}
                tickFormatter={timestamp => formatPeriodLabel({currentPeriod, timestamp})}
              />
              <YAxis tick={{fontSize: "10px"}} />

              {/* FIXME: Use objectiveId for the li key below. I'm unsure how to pass it in. */}
              <Tooltip
                content={({label, payload = []}) => (
                  <div className={classes.chartTooltipWrapper}>
                    <span>{formatPeriodLabel({currentPeriod, timestamp: label})}</span>
                    <ul>
                      {Boolean(payload) &&
                        payload.map(({name, value}) => (
                          <li key={name}>
                            {name}: {value}%
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                itemStyle={{color: "inherit"}}
              />

              {chartLineItems.map(({color, name, objectiveId}) => (
                <Line dataKey={name} key={objectiveId} type="monotone" stroke={color} />
              ))}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>

      {/* TODO - Move to src/charts/chart-line-items.jsx */}
      <ul className={classes.chartLegendList}>
        {chartLineItems.map(({color, name, objectiveId}) => (
          <li key={objectiveId}>
            <Surface width={14} height={14} viewBox={{x: 0, y: 0, width: 32, height: 32}}>
              <path
                stroke="none"
                fill={color}
                d="M0,4h32v24h-32z"
                className="recharts-legend-icon"
              />
            </Surface>
            <span className="recharts-legend-item-text">{name}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

ObjectiveCompletionRate.propTypes = {classes: object.isRequired}

export default ObjectiveCompletionRate
