import arrayMove from "array-move"
import {addHours, getMinutes, setMinutes, setSeconds} from "date-fns"
import orderBy from "lodash/orderBy"
import {createContext, useCallback, useContext, useReducer} from "react"

import {useTemplateContext} from "components/template-router/template-context"

import useActionCreator from "lib/use-action-creator"

import {
  createMessage,
  createTemplateMessage,
  createTimelineMarker as createTimelineMarkerReq,
  deleteTimelineMarker as deleteTimelineMarkerReq,
  duplicateTemplateMessage,
  fetchTemplateMessages as fetchTemplateMessagesReq,
  fetchTemplatePages as fetchTemplatePagesReq,
  fetchTimelineMarkers as fetchTimelineMarkersReq,
  removeTemplateMessage as removeTemplateMessageReq,
  sendDraftMessage,
  sortTemplateMessages as sortTemplateMessagesReq,
  updateTemplateMessage as updateTemplateMessageReq,
  updateTimelineMarker as updateTimelineMarkerReq,
} from "../lib/api"
import {actions, initialState, reducer} from "./template-content-context-reducer"

// *****************************************************************************
// *****************************************************************************
//
// Template Content Context
//
// "Template Content" in this cOnTeXt means templatePages (with Pages),
// templateMessages (with Messages), and timeline markers.
//
// This context provides the fetch utilities for the data, but does not fetch
// any by default, so you can freely use the context and only fetch the data
// you need.
//
// NB: This context requires being inside a TemplateContext.
//
// NB: "Template Validity" really only checks one thing: if the current template
//     DOES have nurturing messages but DOES NOT have timeline markers.
//     see: `hasNurturingMessagesAndNoTimelineMarkers`
//
// NB: Leverage `templateId` when making requests for data in subcomponents --
//     it will be immediately available from the route, vs. the `template.id`
//     which will be available only once the `template` request has completed.
//
// *****************************************************************************
// *****************************************************************************

const TemplateContentContext = createContext()
TemplateContentContext.displayName = "TemplateContentContext"

// eslint-disable-next-line react/prop-types
export const TemplateContentContextProvider = ({children}) => {
  const templateContext = useTemplateContext()
  const {templateId} = templateContext

  const [
    {
      templatePages,
      templatePagesLoading,
      templateMessages,
      templateMessagesLoading,
      timelineMarkers,
      timelineMarkersLoading,
      templateMessageToEdit,
      templateMessageToRemove,
    },
    dispatch,
  ] = useReducer(reducer, initialState)

  const {
    setTemplatePages,
    setTemplatePagesLoading,
    setTemplateMessages,
    addTemplateMessageToState,
    removeTemplateMessageFromState,
    updateTemplateMessageInState,
    setTemplateMessagesLoading,
    setTimelineMarkers,
    addTimelineMarkerToState,
    removeTimelineMarkerFromState,
    updateTimelineMarkerInState,
    setTimelineMarkersLoading,
    setTemplateMessageToEdit,
    setTemplateMessageToRemove,
  } = useActionCreator(actions, dispatch)

  const editTemplateMessage = useCallback(
    id => {
      setTemplateMessageToEdit(templateMessages.find(tm => tm.id === id))
    },
    [setTemplateMessageToEdit, templateMessages]
  )

  const completeTemplateMessageAddition = useCallback(
    async _templateMessages => {
      setTemplateMessagesLoading(true)

      const newTemplateMessages = await Promise.all(
        _templateMessages
          .map(async (templateMessage, i) => {
            if (!templateMessage.message.id.includes("new")) return templateMessage
            const message = await createMessage(templateMessage.message)

            return {...templateMessage, message}
          })
          .map(async (tm, i) => {
            const templateMessage = await tm

            const commonAttrs = {
              queue: templateMessage.queue,
              messageId: templateMessage.message.id,
            }
            // Set default for scheduled message
            const getDefaultScheduledAtString = () => {
              // Default to four hours from now
              const withOffset = addHours(setSeconds(new Date(), 0), 4)
              // Quantize to minutes step of 5
              const minutesQuantized = Math.round(getMinutes(withOffset) / 5) * 5

              return setMinutes(withOffset, minutesQuantized).toISOString()
            }
            const scheduledAttrs =
              templateMessage.queue !== "nurtured"
                ? {scheduledAt: getDefaultScheduledAtString()}
                : {}

            return createTemplateMessage(templateId, {...commonAttrs, ...scheduledAttrs})
          })
      )

      setTemplateMessagesLoading(false)

      setTemplateMessages([...templateMessages, ...orderBy(newTemplateMessages, "order")])
    },
    [setTemplateMessages, setTemplateMessagesLoading, templateId, templateMessages]
  )

  const fetchTemplatePages = useCallback(() => {
    if (!templateId) return

    setTemplatePagesLoading(true)

    return fetchTemplatePagesReq(templateId).then(res => {
      setTemplatePages(res)
      setTemplatePagesLoading(false)
      return res
    })
  }, [setTemplatePages, setTemplatePagesLoading, templateId])

  const fetchTemplateMessages = useCallback(() => {
    if (!templateId) return

    setTemplateMessagesLoading(true)

    fetchTemplateMessagesReq(templateId).then(res => {
      setTemplateMessages(res)
      setTemplateMessagesLoading(false)
    })
  }, [setTemplateMessagesLoading, setTemplateMessages, templateId])

  const sortTemplateMessages = useCallback(
    (oldIndex, newIndex) => {
      setTemplateMessagesLoading(true)

      const nurturingTemplateMessages = templateMessages.filter(tm => tm.queue === "nurturing")

      const nextRows = arrayMove(nurturingTemplateMessages, oldIndex, newIndex).map(
        (row, index) => ({
          ...row,
          order: index,
        })
      )

      sortTemplateMessagesReq(templateId, nextRows).then(updatedTemplateMessages => {
        // Even though we're only passing up nurturing template messages, the api returns the full set
        setTemplateMessages(updatedTemplateMessages)
        setTemplateMessagesLoading(false)
      })
    },
    [setTemplateMessages, setTemplateMessagesLoading, templateMessages, templateId]
  )

  const removeTemplateMessage = useCallback(
    async tm => {
      const templateMessage = templateMessageToRemove || tm

      if (templateMessage) await removeTemplateMessageReq(templateId, templateMessage)

      setTemplateMessageToRemove(null)
      setTemplateMessageToEdit(null)
      removeTemplateMessageFromState(templateMessage)
    },
    [
      removeTemplateMessageFromState,
      setTemplateMessageToEdit,
      setTemplateMessageToRemove,
      templateId,
      templateMessageToRemove,
    ]
  )

  const updateTemplateMessage = useCallback(
    async (templateMessageId, attrs) => {
      const updatedTemplateMessage = await updateTemplateMessageReq(
        templateId,
        templateMessageId,
        attrs
      )

      updateTemplateMessageInState(updatedTemplateMessage)
    },
    [updateTemplateMessageInState, templateId]
  )

  const completeEditTemplateMessage = useCallback(
    async attrs => {
      let templateMessage

      if (templateMessageToEdit.isNew) {
        templateMessage = await createTemplateMessage(templateId, attrs)

        addTemplateMessageToState(templateMessage)
      } else {
        templateMessage = await updateTemplateMessageReq(templateId, attrs.id, attrs)

        updateTemplateMessageInState(templateMessage)
      }

      setTemplateMessageToEdit(null)

      return templateMessage
    },
    [
      addTemplateMessageToState,
      setTemplateMessageToEdit,
      updateTemplateMessageInState,
      templateMessageToEdit,
      templateId,
    ]
  )

  const makeMessageUnique = useCallback(
    async (oldTemplateMessage, attrs = {}) => {
      // woof... the strategy here differs from duplicating template pages. We
      // don't remove, we update here.
      const updatedTemplateMessage = await duplicateTemplateMessage(
        templateId,
        oldTemplateMessage.id,
        attrs
      )

      updateTemplateMessageInState(updatedTemplateMessage)

      setTemplateMessageToEdit(updatedTemplateMessage)
    },
    [setTemplateMessageToEdit, templateId, updateTemplateMessageInState]
  )

  const sendSampleMessage = useCallback(
    message => sendDraftMessage({...message, pageId: templateMessageToEdit.pageId}),
    [templateMessageToEdit]
  )

  const fetchTimelineMarkers = useCallback(async () => {
    setTimelineMarkersLoading(true)
    setTimelineMarkers(await fetchTimelineMarkersReq(templateId))
    setTimelineMarkersLoading(false)
  }, [setTimelineMarkersLoading, setTimelineMarkers, templateId])

  const createTimelineMarker = useCallback(
    async attrs => {
      setTimelineMarkersLoading(true)
      addTimelineMarkerToState(await createTimelineMarkerReq(templateId, attrs))
      setTimelineMarkersLoading(false)
    },
    [addTimelineMarkerToState, setTimelineMarkersLoading, templateId]
  )

  const updateTimelineMarker = useCallback(
    async attrs => {
      setTimelineMarkersLoading(true)
      updateTimelineMarkerInState(await updateTimelineMarkerReq(templateId, attrs.id, attrs))
      setTimelineMarkersLoading(false)
    },
    [setTimelineMarkersLoading, updateTimelineMarkerInState, templateId]
  )

  const deleteTimelineMarker = useCallback(
    async id => {
      setTimelineMarkersLoading(true)
      await deleteTimelineMarkerReq(templateId, id)
      setTimelineMarkersLoading(false)
      removeTimelineMarkerFromState(id)
    },
    [setTimelineMarkersLoading, templateId, removeTimelineMarkerFromState]
  )

  // Really just a convenience method to fetch template messages and timeline
  // markers, this "template validity," concept, i.e. that it has nurturing
  // messages but it doesn't have timeline markers, is really computed from
  // these two data sources
  const fetchTemplateValidity = useCallback(() => {
    fetchTemplateMessages()
    fetchTimelineMarkers()
  }, [fetchTemplateMessages, fetchTimelineMarkers])

  const hasNurturingMessagesAndNoTimelineMarkers =
    templateMessages.filter(tm => tm.queue === "nurturing").length > 0 &&
    timelineMarkers.length === 0

  const value = {
    ...templateContext,

    completeTemplateMessageAddition,

    templateMessageToEdit,
    setTemplateMessageToEdit,
    templateMessageToRemove,
    setTemplateMessageToRemove,
    editTemplateMessage,
    removeTemplateMessage,
    removeTemplateMessageFromState,
    updateTemplateMessage,

    fetchTemplatePages,
    setTemplatePages,
    templatePages,
    templatePagesLoading,

    fetchTimelineMarkers,
    timelineMarkers,
    timelineMarkersLoading,
    createTimelineMarker,
    updateTimelineMarker,
    deleteTimelineMarker,

    fetchTemplateValidity,
    hasNurturingMessagesAndNoTimelineMarkers,

    templateMessages,
    templateMessagesLoading,
    fetchTemplateMessages,
    sortTemplateMessages,

    completeEditTemplateMessage,
    makeMessageUnique,
    sendSampleMessage,
  }

  return <TemplateContentContext.Provider value={value}>{children}</TemplateContentContext.Provider>
}

export const useTemplateContentContext = () => useContext(TemplateContentContext)

export const templateContentContext = Component => props => (
  <TemplateContentContext.Consumer>
    {value => <Component {...value} {...props} />}
  </TemplateContentContext.Consumer>
)

export const templateContentContextProvider = Component => props => (
  <TemplateContentContextProvider>
    <Component {...props} />
  </TemplateContentContextProvider>
)
