import {Paper} from "@mui/material"
import {shape, string} from "prop-types"
import {useEffect, useState} from "react"

import ContentBlock from "components/content-block-editor/content-block"
import MessageContainer from "components/message-editor/message-container"

const EmailMessage = ({message, message: {contentBlocks}}) => {
  const [sortedContentBlocks, setSortedContentBlocks] = useState([])

  useEffect(() => {
    setSortedContentBlocks(
      [...contentBlocks].sort((a, b) => {
        if (a.order < b.order) {
          return -1
        }

        if (a.order > b.order) {
          return 1
        }

        return 0
      })
    )
  }, [contentBlocks, setSortedContentBlocks])

  return (
    <>
      <Paper
        sx={theme => ({
          marginBottom: theme.spacing(2),
          padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
        })}
      >
        <MessageContainer message={message}>
          {sortedContentBlocks.map((contentBlock, index) => (
            <ContentBlock
              contentBlock={contentBlock}
              index={index}
              isDragging={false}
              key={contentBlock.id}
              layoutBasis="table"
            />
          ))}
        </MessageContainer>
      </Paper>
    </>
  )
}

EmailMessage.propTypes = {message: shape({contentName: string.isRequired}).isRequired}

export default EmailMessage
