import {connect} from "react-redux"

import ResetPassword from "./reset-password"
import {onSubmit} from "./reset-password-actions"

const mapStateToProps = ({resetPassword}) => ({serverError: resetPassword.error})

const addActionsToProps = (dispatch, ownProps) => ({
  onSubmit: attrs => dispatch(onSubmit({...attrs, resetToken: ownProps.match.params.resetToken})),
})

export default connect(mapStateToProps, addActionsToProps)(ResetPassword)
