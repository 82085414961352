import {FormControl, InputLabel, ListItemText, MenuItem, Typography} from "@mui/material"
import cx from "classnames"
import {array, bool, func, object, shape, string} from "prop-types"

import DOSelect from "components/do-select/do-select"
import CampaignTargetingCondition from "components/template-targeting/campaign-targeting-condition"
import {useTemplateTargeting} from "components/template-targeting/template-targeting-context"
import {availableOperators} from "components/template-targeting/template-targeting-helpers"

const CardOnFileTargetingCondition = ({
  cardOnFileTargetingValues,
  classes,
  disabled = false,
  targetingCondition,
  templateId,
  templates,
  onChange,
}) => {
  const {updateTargetingCondition} = useTemplateTargeting()
  const {field, id, meta, targetingGroupId, operator, value} = targetingCondition
  const operators = availableOperators.filter(op => op.isAvailable(field, meta))
  const {merchants, failureReasons} = cardOnFileTargetingValues

  const getMetaValue = name => meta?.[name] ?? ""

  const renderCondition = (
    {name, value, availableTypes} // eslint-disable-line react/prop-types
  ) => (
    <MenuItem key={value} value={value}>
      <ListItemText
        classes={{secondary: classes.listItemTextSecondary}}
        primary={
          <Typography variant="inherit" noWrap>
            {name}
          </Typography>
        }
        secondary={availableTypes ? `Value can be a ${availableTypes}` : ""}
      />
    </MenuItem>
  )

  const onChangeMerchant = ({target}) => {
    updateTargetingCondition({
      id,
      targetingGroupId,
      meta: {merchantName: target.value},
    })
  }
  const onChangeFailureReason = ({target}) => {
    updateTargetingCondition({
      id,
      targetingGroupId,
      meta: {failureReason: target.value},
    })
  }
  return (
    <>
      <FormControl classes={{root: classes.fieldSelect}}>
        {<InputLabel htmlFor="journey-value">Action</InputLabel>}
        <DOSelect
          className={cx(classes.labelLessInput, classes.inputField)}
          disabled={disabled}
          onChange={onChange}
          name="operator"
          renderValue={value => operators.find(o => o.value === value)?.name}
          value={operator}
        >
          {operators.map(renderCondition)}
        </DOSelect>
      </FormControl>

      {["has_placed_card", "has_failed_to_place_card"].includes(operator) && (
        <>
          <span className={classes.in}>with</span>
          <FormControl classes={{root: classes.fieldSelect}}>
            {<InputLabel htmlFor="journey-merchant">Merchant</InputLabel>}
            <DOSelect
              required={true}
              className={cx(classes.labelLessInput, classes.inputField)}
              disabled={disabled}
              id="journey-merchant"
              onChange={onChangeMerchant}
              name="meta.merchantName"
              value={getMetaValue("merchantName")}
            >
              {merchants.map(({name}) => (
                <MenuItem key={name} value={name}>
                  <Typography variant="inherit" noWrap>
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
        </>
      )}

      {operator === "encountered_failure" && (
        <>
          <span className={classes.in}>for</span>
          <FormControl classes={{root: classes.fieldSelect}}>
            {<InputLabel htmlFor="journey-failurereason">Reason</InputLabel>}
            <DOSelect
              className={cx(classes.labelLessInput, classes.inputField)}
              disabled={disabled}
              id="journey-failurereason"
              onChange={onChangeFailureReason}
              name="meta.failureReason"
              value={getMetaValue("failureReason")}
            >
              {failureReasons.map(name => (
                <MenuItem key={name} value={name}>
                  <Typography variant="inherit" noWrap>
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </DOSelect>
          </FormControl>
        </>
      )}

      <CampaignTargetingCondition
        classes={classes}
        disabled={disabled}
        name="value"
        onChange={onChange}
        templateId={templateId}
        templates={templates}
        value={value}
      />
    </>
  )
}

CardOnFileTargetingCondition.propTypes = {
  classes: object,
  cardOnFileTargetingValues: shape({
    merchants: array.isRequired,
    failureReasons: array.isRequired,
  }),
  disabled: bool,
  targetingCondition: shape({
    id: string,
    model: string,
    field: string,
    meta: object,
    meta_sub_key: string,
    operator: string,
    value: string,
  }),
  templateId: string,
  templates: array,
  onChange: func,
}

export default CardOnFileTargetingCondition
