import {CircularProgress} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object} from "prop-types"
import {useEffect} from "react"
import {MdCheckCircle as CheckIcon, MdWarning as ErrorIcon} from "react-icons/md"
import {Redirect, Route, Switch} from "react-router-dom"

import AccessControlledRoute from "components/access-control/access-controlled-route"
import CmcFlexDiagnostics from "components/teams/cmc-flex-diagnostics"
import Notifications from "components/teams/notifications/notifications"

import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"
import ApiAccessCredentials from "./api-access-credentials"
import BrandingSettings from "./branding-settings"
import CsvProcessingSettings from "./csv-processing-settings/csv-processing-settings"
import FieldMappingsSettings from "./csv-processing-settings/field-mappings-settings"
import GeneralSettings from "./general-settings"
import InsightsSettings from "./insights-settings/insights-settings"
import MessagingSettings from "./messaging-settings"
import ProductSettings from "./product-settings"
import Sftp from "./sftp"

const RedirectToGeneralSettings = () => <Redirect to="/admin/settings/general" />

export const TeamSettingsRouter = ({classes, emailSettings, location, onClearSmtpTestStatus}) => {
  useEffect(() => {
    onClearSmtpTestStatus()
  }, [location, onClearSmtpTestStatus])

  return (
    <div className="team-dashboard">
      <TitleBar title="Team Settings" />

      <Padded>
        <Switch>
          <Route exact={true} path="/admin/settings" render={RedirectToGeneralSettings} />
          <Route component={GeneralSettings} path="/admin/settings/general" />
          <Route component={BrandingSettings} path="/admin/settings/branding" />
          <Route component={MessagingSettings} path="/admin/settings/messaging" />
          <AccessControlledRoute
            component={Notifications}
            path="/admin/settings/notifications"
            requiredPermissions="notifications:manage"
          />
          <Route component={ApiAccessCredentials} path="/admin/settings/api" />
          <Route component={Sftp} path="/admin/settings/sftp" />
          <Route component={FieldMappingsSettings} path="/admin/settings/field-mappings" />
          <Route component={CsvProcessingSettings} path="/admin/settings/csv" />
          <Route component={ProductSettings} path="/admin/settings/products" />
          <Route component={InsightsSettings} path="/admin/settings/insights" />
          <Route component={CmcFlexDiagnostics} path="/admin/settings/cmc-flex-diagnostics" />
        </Switch>
        {emailSettings.status === "testing" && (
          <div className={`${classes.statusMessage} testing`}>
            <CircularProgress className={classes.statusIcon} color="primary" size={15} />{" "}
            {emailSettings.message}
          </div>
        )}
        {emailSettings.status === "failed" && (
          <div className={`${classes.statusMessage} failed`}>
            <ErrorIcon className={classes.statusIcon} /> {emailSettings.message}
          </div>
        )}
        {emailSettings.status === "success" && (
          <div className={`${classes.statusMessage} success`}>
            <CheckIcon className={classes.statusIcon} /> {emailSettings.message}
          </div>
        )}
      </Padded>
    </div>
  )
}

TeamSettingsRouter.propTypes = {
  classes: object,
  emailSettings: object,
  location: object.isRequired,
  onClearSmtpTestStatus: func.isRequired,
}

const styles = theme => ({
  statusIcon: {
    marginRight: 10,
  },
  statusMessage: {
    padding: "5px 20px",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    "&.failed": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    "&.success": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
  },
})

export default withStyles(styles)(TeamSettingsRouter)
