import {Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {node} from "prop-types"

const styles = theme => ({
  listItem: {
    // We want the specificity of these to be slightly higher than the paragraph
    // styles that are also applied to list elements
    "li&": {
      lineHeight: theme.custom?.listItems?.lineHeight,
      marginBottom: theme.custom?.listItems?.marginBottom,
    },
  },
})

const renderItems = (children, className) =>
  children.map(child => (
    <Typography classes={{root: className}} component="li" key={child.key}>
      {child}
    </Typography>
  ))

const OrderedList = withStyles(styles)(props => (
  <ol>{renderItems(props.children, props.classes.listItem)}</ol>
))
const UnorderedList = withStyles(styles)(props => (
  <ul>{renderItems(props.children, props.classes.listItem)}</ul>
))

OrderedList.propTypes = {children: node}
UnorderedList.propTypes = {children: node}

export {OrderedList, UnorderedList}
