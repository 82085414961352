import {ClickAwayListener} from "@mui/material"
import {bool, node} from "prop-types"

const ConditionalClickAwayListener = ({isPresent, ...props}) =>
  isPresent ? <ClickAwayListener {...props} /> : <>{props.children}</>

ConditionalClickAwayListener.propTypes = {
  children: node,
  isPresent: bool,
}

export default ConditionalClickAwayListener
