import {EditorBlock} from "draft-js"
import {object} from "prop-types"

const Block = props => {
  const data = props.block.getData()
  const textAlign = data && data.get("align")
  const lineHeight = data && data.get("lineHeight")

  return (
    <div style={{lineHeight, textAlign}}>
      <EditorBlock {...props} />
    </div>
  )
}

Block.propTypes = {
  block: object,
}

export default Block
