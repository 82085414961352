import {Box, Button, TableRow} from "@mui/material"
import Papa from "papaparse"
import {func} from "prop-types"
import {Component} from "react"

import PaddedBox from "components/box/box"
import UltraConfirmDialog from "components/dialogs/ultra-confirm-dialog"
import DocumentTitle from "components/document-title/document-title"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"
import ProductSettingsCell from "components/teams/product-settings-cell"

import {createProduct, deleteProduct, fetchProducts, updateProduct} from "lib/api"
import {featurify} from "lib/hooks/use-features"
import pluralize from "lib/string/pluralize"

import ProductSettingsModal from "./product-settings-modal"

export class ProductSettings extends Component {
  state = {
    products: [],
    productsCreated: [],
    productsFailed: [],
  }

  componentDidMount = () => {
    this.refresh({})
  }

  refresh = attrs => {
    const params = this.props.updateStateForRequest(attrs)

    this.setState({isLoading: true})

    fetchProducts(params, {withFetchResponse: true})
      .then(([rows, fetchResponse]) => this.props.setTableState({fetchResponse, rows}))
      .finally(() => this.setState({isLoading: false}))
  }

  onSaveProduct = product => {
    const saveProduct = product.id ? updateProduct(product.id, product) : createProduct(product)

    saveProduct.then(() => {
      this.setState({activeProduct: null})
      this.refresh({})
    })
  }

  onDeleteProduct = () => {
    deleteProduct(this.state.productPendingDeletion.id).then(() => {
      this.refresh({})
      this.setState({productPendingDeletion: null})
    })
  }

  onFileUpload = ({target: {files}}) => {
    const file = files[0]

    Papa.parse(file, {
      header: true,
      trimHeaders: true,
      skipEmptyLines: "greedy",
      transform: data => data.trim(),
      complete: ({data}) => {
        this.setState({
          products: data,
          productsCreated: [],
          productsFailed: [],
        })
        this.createProducts(data)
      },
    })
  }

  createProducts = data => {
    const [product, ...products] = data

    if (product.type) product.type = product.type.toLowerCase()

    if (product.services) product.services = product.services.split(",").map(s => s.trim())

    createProduct(product)
      .then(() => {
        this.setState(({productsCreated}) => ({
          productsCreated: [...productsCreated, product],
        }))
      })
      .catch(error => {
        this.setState(({productsFailed}) => ({
          productsFailed: [...productsFailed, {product, errors: error?.body?.errors || {}}],
        }))
      })
      .finally(() => {
        if (products.length) this.createProducts(products)
        else this.refresh({})
      })
  }

  render() {
    const {activeProduct, productPendingDeletion, isLoading} = this.state
    const {products, productsCreated, productsFailed} = this.state
    const productsProcessed = productsCreated.length + productsFailed.length
    const isUploadingFile = !!this.state.products.length
    const isFileFinished = productsProcessed === products.length

    return (
      <PaddedBox>
        <DocumentTitle title="Team Settings - Products" />
        <DOTable
          paginationEnabled={true}
          headers={[
            {field: "code", label: "Product Code"},
            {field: "name", label: "Product Name"},
            {field: "type", label: "Type"},
            {field: "description", label: "Description"},
            ...(this.props.hasFeature("product-opening-value-reporting")
              ? [{field: "trackOpens", label: "Track Opens"}]
              : []),

            {field: "services", label: "Services", sortable: false},
            {field: "controls", label: "", sortable: false},
          ]}
          isTableLoading={isLoading}
          noResults="No products found"
          refresh={this.refresh}
        >
          {(row, index, columns) => (
            <TableRow
              key={row.id}
              sx={{
                "&:hover .show-on-hover": {
                  opacity: 1,
                },
              }}
            >
              {columns.map(({field}) => (
                <ProductSettingsCell
                  field={field}
                  key={field}
                  onDelete={() => this.setState({productPendingDeletion: row})}
                  onEdit={() => this.setState({activeProduct: row})}
                  row={row}
                />
              ))}
            </TableRow>
          )}
        </DOTable>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2.5,
            textAlign: "right",
          }}
        >
          <div>
            <input
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              data-testid="product-import"
              id="product-import"
              name="productUploadCSV"
              onChange={this.onFileUpload}
              style={{display: "none"}}
              type="file"
            />
            <label htmlFor="product-import">
              <Button color="primary" component="span" variant="contained">
                Import from file
              </Button>
            </label>
          </div>
          <Button
            onClick={() => this.setState({activeProduct: {}})}
            color="grey"
            variant="contained"
          >
            + Add New Product
          </Button>
        </Box>
        {isUploadingFile && !isFileFinished && (
          <Box
            sx={{
              marginTop: 2.5,
            }}
          >
            Creating {productsProcessed} of {products.length} products...
          </Box>
        )}
        {isUploadingFile && isFileFinished && (
          <Box
            sx={{
              marginTop: 2.5,
            }}
          >
            Finished creating {productsCreated.length}{" "}
            {pluralize("product", productsCreated.length)}!
          </Box>
        )}
        {isUploadingFile && !!productsFailed.length && (
          <Box
            sx={{
              marginTop: 2.5,
            }}
          >
            {productsFailed.length} {pluralize("product", productsFailed.length)} could not be
            processed. <br />
            {productsFailed.map(({product, errors}, i) => (
              <Box
                key={i}
                sx={theme => ({
                  marginTop: theme.spacing(2),
                  "& + $error": {
                    paddingTop: theme.spacing(2),
                    borderTop: `1px solid #e0e0e0`,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    color: theme.palette.error.main,
                    fontWeight: "bold",
                  })}
                >
                  {Object.values(errors).length} {pluralize("error", Object.values(errors).length)}{" "}
                  on line
                </Box>
                <pre>{Object.values(product).join(", ")}</pre>
                <ul>
                  {Object.keys(errors).map((field, i) => (
                    <li key={i}>
                      <b>{field}</b> {errors[field].join(", ")}
                    </li>
                  ))}
                </ul>
              </Box>
            ))}
          </Box>
        )}
        {activeProduct && (
          <ProductSettingsModal
            onClose={() => this.setState({activeProduct: null})}
            product={activeProduct}
            onSubmit={this.onSaveProduct}
          />
        )}
        {productPendingDeletion && (
          <UltraConfirmDialog
            confirmationText={`Delete ${productPendingDeletion.name}`}
            onClose={() => this.setState({productPendingDeletion: null})}
            recordType="product"
            open={true}
            onConfirm={this.onDeleteProduct}
          />
        )}
      </PaddedBox>
    )
  }
}

ProductSettings.propTypes = {
  setTableState: func,
  updateStateForRequest: func,
  hasFeature: func,
}

export default tabular({sortColumn: "code", sortDirection: "asc", useQueryParams: true})(
  featurify(ProductSettings)
)
