import {Typography} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, func, object, shape, string} from "prop-types"
import {Component} from "react"
import {FiMap as MapIcon} from "react-icons/fi"

import createTeamTheme from "themes/team-theme-creator"

import DocumentTitle from "../document-title/document-title"
import LandingPageForm from "./landing-page-form"
import LandingPageHeader from "./landing-page-header"

export class LandingPage extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.template.theme && !state.theme)
      return {
        ...state,
        theme: createTeamTheme(props.template.theme),
      }

    return state
  }

  state = {
    disclaimer: {
      email: false,
      phoneMobile: false,
    },
    themeSet: false,
    theme: this.props.template.theme ? createTeamTheme(this.props.template.theme) : undefined,
  }

  componentDidMount = () => {
    this.props.getTemplate(this.props.templateId)
    this.props.getLandingPage(this.props.templateId)
  }

  onShowDisclaimer = e =>
    this.setState({disclaimer: {...this.state.disclaimer, [e.target.name]: true}})

  onSubmit = params => {
    const {onLpCreateJourney, templateId, queryParams} = this.props
    const attrs = {
      ...params,
      ownerId: queryParams && queryParams.ownerId,
    }

    onLpCreateJourney(templateId, attrs, queryParams.kiosk_mode === "true")
  }

  render() {
    const {
      classes,
      isLoaded,
      networkError,
      success,
      landingPage: {backgroundFileId, content, title, submitTitle},
      template: {logoFileId},
    } = this.props
    const {disclaimer, theme} = this.state
    const backgroundImage = backgroundFileId
      ? `url(${process.env.REACT_APP_API_URL}/files/${backgroundFileId}?variant=large)`
      : ""
    const logoImage = logoFileId
      ? `${process.env.REACT_APP_API_URL}/files/${logoFileId}?variant=large`
      : ""

    if (!isLoaded) return <div className={classes.landingPage} />

    return (
      <ThemeProvider theme={theme}>
        <div
          className={cx(classes.landingPage, {[classes.background]: backgroundImage})}
          style={{backgroundImage}}
        >
          <DocumentTitle />
          <LandingPageHeader
            backgroundImage={backgroundImage}
            headerText={title}
            logoImage={logoImage}
          />
          <div className={classes.content}>
            {!success && (
              <>
                <div className={classes.description}>
                  <Typography
                    className={classes.body}
                    dangerouslySetInnerHTML={{__html: content}}
                  />
                </div>
                {this.props.template.status === "archived" ? (
                  <div className={classes.textDanger}>
                    This form cannot be displayed because the campaign is archived. Please report
                    this problem to a representative of your financial institution.
                  </div>
                ) : (
                  <LandingPageForm
                    disclaimer={disclaimer}
                    networkError={networkError}
                    onShowDisclaimer={this.onShowDisclaimer}
                    onSubmit={this.onSubmit}
                    submitTitle={submitTitle}
                  />
                )}
              </>
            )}
            {success && (
              <div className={classes.successMessage}>
                <MapIcon color="#5E6871F2" size={100} />
                <br />
                <Typography variant="h5">Enjoy the journey!</Typography>
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>
    )
  }
}

LandingPage.propTypes = {
  classes: object.isRequired,
  getLandingPage: func.isRequired,
  getTemplate: func.isRequired,
  isLoaded: bool,
  landingPage: shape({
    backgroundFileId: string,
    content: string,
    submitTitle: string,
    title: string,
  }).isRequired,
  networkError: bool,
  onLpCreateJourney: func.isRequired,
  queryParams: object,
  success: bool,
  template: shape({
    logoFileId: string,
    theme: object,
  }).isRequired,
  templateId: string.isRequired,
}

const styles = theme => ({
  body: {},
  background: {
    backgroundSize: "cover",
    "&, & $body": {
      color: "#fafafa",
    },
  },
  content: {
    margin: 10,
    position: "relative",
    padding: 20,
  },
  description: {
    paddingBottom: 20,
  },
  landingPage: {
    minHeight: "100vh",
    backgroundColor: "#FBFEFF",
    lineHeight: "1.5em",
  },
  successMessage: {
    alignItems: "center",
    animation: "1.5s linear $showGracefully",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: 300,
    justifyContent: "center",
  },
  textDanger: {
    color: theme.palette.error.main,
  },
  "@media (min-width:768px)": {
    content: {
      width: 540,
      margin: "0 auto",
    },
  },
  "@keyframes showGracefully": {
    from: {opacity: 0},
    to: {opacity: 1},
  },
})

export default withStyles(styles)(LandingPage)
