import {string} from "prop-types"

const NoData = ({width = "820.16", height = "780.81"}) => (
  <svg
    data-name="Layer 1"
    height={height}
    id="f20e0c25-d928-42cc-98d1-13cc230663ea"
    viewBox="0 0 820.16 780.81"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        gradientTransform="translate(-3.62 1.57)"
        gradientUnits="userSpaceOnUse"
        id="07332201-7176-49c2-9908-6dc4a39c4716"
        x1="539.63"
        x2="539.63"
        y1="734.6"
        y2="151.19"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        gradientTransform="translate(-63.92 7.85)"
        id="0ee1ab3f-7ba2-4205-9d4a-9606ad702253"
        x1="540.17"
        x2="540.17"
        xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
        y1="180.2"
        y2="130.75"
      />
      <linearGradient
        gradientTransform="translate(-84.51 124.6) rotate(-12.11)"
        id="abca9755-bed1-4a97-b027-7f02ee3ffa09"
        x1="540.17"
        x2="540.17"
        xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
        y1="140.86"
        y2="82.43"
      />
      <linearGradient
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        id="2632d424-e666-4ee4-9508-a494957e14ab"
        x1="476.4"
        x2="476.4"
        xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
        y1="710.53"
        y2="127.12"
      />
      <linearGradient
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        id="97571ef7-1c83-4e06-b701-c2e47e77dca3"
        x1="476.94"
        x2="476.94"
        xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
        y1="156.13"
        y2="106.68"
      />
      <linearGradient
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        id="7d32e13e-a0c7-49c4-af0e-066a2f8cb76e"
        x1="666.86"
        x2="666.86"
        xlinkHref="#07332201-7176-49c2-9908-6dc4a39c4716"
        y1="176.39"
        y2="117.95"
      />
    </defs>
    <title>no data</title>
    <rect
      fill="#e0e0e0"
      height="603.82"
      transform="translate(-271.22 62.72) rotate(-12.11)"
      width="437.02"
      x="317.5"
      y="142.55"
    />
    <g opacity="0.5">
      <rect
        fill="url(#07332201-7176-49c2-9908-6dc4a39c4716)"
        height="583.41"
        transform="translate(-271.22 62.72) rotate(-12.11)"
        width="422.25"
        x="324.89"
        y="152.76"
      />
    </g>
    <rect
      fill="#fafafa"
      height="570.52"
      transform="translate(-270.79 62.58) rotate(-12.11)"
      width="411.5"
      x="329.81"
      y="157.1"
    />
    <rect
      fill="url(#0ee1ab3f-7ba2-4205-9d4a-9606ad702253)"
      height="49.45"
      transform="translate(-213.58 43.93) rotate(-12.11)"
      width="204.14"
      x="374.18"
      y="138.6"
    />
    <path
      d="M460.93,91.9c-15.41,3.31-25.16,18.78-21.77,34.55s18.62,25.89,34,22.58,25.16-18.78,21.77-34.55S476.34,88.59,460.93,91.9ZM470.6,137A16.86,16.86,0,1,1,483.16,117,16.66,16.66,0,0,1,470.6,137Z"
      fill="url(#abca9755-bed1-4a97-b027-7f02ee3ffa09)"
      transform="translate(-189.92 -59.59)"
    />
    <rect
      fill="#3399cc"
      height="47.27"
      transform="translate(-212.94 43.72) rotate(-12.11)"
      width="199.84"
      x="375.66"
      y="136.55"
    />
    <path
      d="M460.93,91.9a27.93,27.93,0,1,0,33.17,21.45A27.93,27.93,0,0,0,460.93,91.9ZM470.17,135a16.12,16.12,0,1,1,12.38-19.14A16.12,16.12,0,0,1,470.17,135Z"
      fill="#3399cc"
      transform="translate(-189.92 -59.59)"
    />
    <rect fill="#e0e0e0" height="603.82" width="437.02" x="257.89" y="116.91" />
    <g opacity="0.5">
      <rect
        fill="url(#2632d424-e666-4ee4-9508-a494957e14ab)"
        height="583.41"
        width="422.25"
        x="265.28"
        y="127.12"
      />
    </g>
    <rect fill="#fff" height="570.52" width="411.5" x="270.65" y="131.42" />
    <rect
      fill="url(#97571ef7-1c83-4e06-b701-c2e47e77dca3)"
      height="49.45"
      width="204.14"
      x="374.87"
      y="106.68"
    />
    <path
      d="M666.86,118c-15.76,0-28.54,13.08-28.54,29.22s12.78,29.22,28.54,29.22,28.54-13.08,28.54-29.22S682.62,118,666.86,118Zm0,46.08a16.86,16.86,0,1,1,16.46-16.86A16.66,16.66,0,0,1,666.86,164Z"
      fill="url(#7d32e13e-a0c7-49c4-af0e-066a2f8cb76e)"
      transform="translate(-189.92 -59.59)"
    />
    <rect fill="#3399cc" height="47.27" width="199.84" x="377.02" y="104.56" />
    <path
      d="M666.86,118a27.93,27.93,0,1,0,27.93,27.93A27.93,27.93,0,0,0,666.86,118Zm0,44.05A16.12,16.12,0,1,1,683,145.89,16.12,16.12,0,0,1,666.86,162Z"
      fill="#3399cc"
      transform="translate(-189.92 -59.59)"
    />
    <g opacity="0.5">
      <rect fill="#47e6b1" height="21.33" width="3.76" x="15.27" y="737.05" />
      <rect
        fill="#47e6b1"
        height="21.33"
        transform="translate(824.47 540.65) rotate(90)"
        width="3.76"
        x="205.19"
        y="796.65"
      />
    </g>
    <g opacity="0.5">
      <rect fill="#47e6b1" height="21.33" width="3.76" x="451.49" />
      <rect
        fill="#47e6b1"
        height="21.33"
        transform="translate(523.63 -632.62) rotate(90)"
        width="3.76"
        x="641.4"
        y="59.59"
      />
    </g>
    <path
      d="M961,832.15a4.61,4.61,0,0,1-2.57-5.57,2.22,2.22,0,0,0,.1-.51h0a2.31,2.31,0,0,0-4.15-1.53h0a2.22,2.22,0,0,0-.26.45,4.61,4.61,0,0,1-5.57,2.57,2.22,2.22,0,0,0-.51-.1h0a2.31,2.31,0,0,0-1.53,4.15h0a2.22,2.22,0,0,0,.45.26,4.61,4.61,0,0,1,2.57,5.57,2.22,2.22,0,0,0-.1.51h0a2.31,2.31,0,0,0,4.15,1.53h0a2.22,2.22,0,0,0,.26-.45,4.61,4.61,0,0,1,5.57-2.57,2.22,2.22,0,0,0,.51.1h0a2.31,2.31,0,0,0,1.53-4.15h0A2.22,2.22,0,0,0,961,832.15Z"
      fill="#4d8af0"
      opacity="0.5"
      transform="translate(-189.92 -59.59)"
    />
    <path
      d="M326.59,627.09a4.61,4.61,0,0,1-2.57-5.57,2.22,2.22,0,0,0,.1-.51h0a2.31,2.31,0,0,0-4.15-1.53h0a2.22,2.22,0,0,0-.26.45,4.61,4.61,0,0,1-5.57,2.57,2.22,2.22,0,0,0-.51-.1h0a2.31,2.31,0,0,0-1.53,4.15h0a2.22,2.22,0,0,0,.45.26,4.61,4.61,0,0,1,2.57,5.57,2.22,2.22,0,0,0-.1.51h0a2.31,2.31,0,0,0,4.15,1.53h0a2.22,2.22,0,0,0,.26-.45A4.61,4.61,0,0,1,325,631.4a2.22,2.22,0,0,0,.51.1h0a2.31,2.31,0,0,0,1.53-4.15h0A2.22,2.22,0,0,0,326.59,627.09Z"
      fill="#fdd835"
      opacity="0.5"
      transform="translate(-189.92 -59.59)"
    />
    <path
      d="M855,127.77a4.61,4.61,0,0,1-2.57-5.57,2.22,2.22,0,0,0,.1-.51h0a2.31,2.31,0,0,0-4.15-1.53h0a2.22,2.22,0,0,0-.26.45,4.61,4.61,0,0,1-5.57,2.57,2.22,2.22,0,0,0-.51-.1h0a2.31,2.31,0,0,0-1.53,4.15h0a2.22,2.22,0,0,0,.45.26,4.61,4.61,0,0,1,2.57,5.57,2.22,2.22,0,0,0-.1.51h0a2.31,2.31,0,0,0,4.15,1.53h0a2.22,2.22,0,0,0,.26-.45,4.61,4.61,0,0,1,5.57-2.57,2.22,2.22,0,0,0,.51.1h0a2.31,2.31,0,0,0,1.53-4.15h0A2.22,2.22,0,0,0,855,127.77Z"
      fill="#fdd835"
      opacity="0.5"
      transform="translate(-189.92 -59.59)"
    />
    <circle cx="812.64" cy="314.47" fill="#f55f44" opacity="0.5" r="7.53" />
    <circle cx="230.73" cy="746.65" fill="#f55f44" opacity="0.5" r="7.53" />
    <circle cx="735.31" cy="477.23" fill="#f55f44" opacity="0.5" r="7.53" />
    <circle cx="87.14" cy="96.35" fill="#4d8af0" opacity="0.5" r="7.53" />
    <circle cx="7.53" cy="301.76" fill="#47e6b1" opacity="0.5" r="7.53" />
  </svg>
)

NoData.propTypes = {
  height: string,
  width: string,
}

export default NoData
