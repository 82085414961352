import {array, bool, func, number, object, oneOf, oneOfType, string} from "prop-types"
import ChromeFields from "react-color/lib/components/chrome/ChromeFields"
import ChromePointer from "react-color/lib/components/chrome/ChromePointer"
import ChromePointerCircle from "react-color/lib/components/chrome/ChromePointerCircle"
import {Checkboard, ColorWrap, Hue, Saturation} from "react-color/lib/components/common"

import CircleSwatch from "./circle-swatch"

// Modification of the ChromePicker component from the React Color library.
//   Optionally adds a palette to select custom colors.
//
//   SEE https://github.com/casesandberg/react-color/tree/master/src/components/chrome
export const ColorPicker = ({
  className = "",
  defaultView,
  hex,
  hsl,
  hsv,
  onChange,
  palette,
  renderers,
  rgb,
  width,
}) => {
  const styles = {
    active: {
      absolute: "0px 0px 0px 0px",
      borderRadius: "8px",
      boxShadow: "inset 0 0 0 1px rgba(0,0,0,.1)",
      background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
      zIndex: "2",
    },
    alpha: {
      display: "none",
      height: "10px",
      position: "relative",
    },
    Alpha: {
      radius: "2px",
    },
    body: {
      padding: "16px 16px 12px",
    },
    controls: {
      display: "flex",
    },
    color: {
      width: "22px",
    },
    hue: {
      height: "10px",
      position: "relative",
      marginBottom: "0px",
    },
    Hue: {
      radius: "2px",
    },
    palette: {
      display: "flex",
      flexWrap: "wrap",
      padding: "12px 0 0 6px",
    },
    picker: {
      width,
      background: "#fff",
      borderRadius: "2px",
      boxShadow: "0 0 2px rgba(0,0,0,.3), 0 4px 8px rgba(0,0,0,.3)",
      boxSizing: "initial",
      fontFamily: "Menlo",
    },
    saturation: {
      width: "100%",
      paddingBottom: "55%",
      position: "relative",
      borderRadius: "2px 2px 0 0",
      overflow: "hidden",
    },
    Saturation: {
      radius: "2px 2px 0 0",
    },
    swatch: {
      marginTop: "0px",
      width: "10px",
      height: "10px",
      borderRadius: "8px",
      position: "relative",
      overflow: "hidden",
    },
    toggles: {
      flex: "1",
    },
  }

  return (
    <div style={styles.picker} className={`chrome-picker ${className}`}>
      <div style={styles.saturation}>
        <Saturation
          style={styles.Saturation}
          hsl={hsl}
          hsv={hsv}
          pointer={ChromePointerCircle}
          onChange={onChange}
        />
      </div>
      <div style={styles.body}>
        <div style={styles.controls} className="flexbox-fix">
          <div style={styles.color}>
            <div style={styles.swatch}>
              <div style={styles.active} />
              <Checkboard renderers={renderers} />
            </div>
          </div>
          <div style={styles.toggles}>
            <div style={styles.hue}>
              <Hue style={styles.Hue} hsl={hsl} pointer={ChromePointer} onChange={onChange} />
            </div>
          </div>
        </div>
        <ChromeFields
          rgb={rgb}
          hsl={hsl}
          hex={hex}
          view={defaultView}
          onChange={onChange}
          disableAlpha={true}
        />
        {Array.isArray(palette) && (
          <div style={styles.palette}>
            {palette.map(({hex: swatchHex, name}, index) => (
              <CircleSwatch
                key={btoa(`${swatchHex}-${index}`)}
                color={swatchHex}
                onClick={onChange}
                active={hex.toLowerCase() === swatchHex.toLowerCase()}
                circleSize={24}
                circleSpacing="6px"
                title={name}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

ColorPicker.propTypes = {
  className: string,
  defaultView: oneOf(["hex", "rgb", "hsl"]),
  disableAlpha: bool,
  hex: string,
  hsl: object,
  hsv: object,
  onChange: func.isRequired,
  palette: array,
  renderers: object,
  rgb: object,
  width: oneOfType([string, number]),
}

ColorPicker.defaultProps = {
  width: 225,
  disableAlpha: false,
  styles: {},
}

export default ColorWrap(ColorPicker)
