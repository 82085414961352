import {Button, CircularProgress} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {string} from "prop-types"
import {useEffect, useState} from "react"

import {fetchTemplateLaunchStatus} from "lib/api"

import {createHandler, useSocket} from "../../contexts/socket-manager"

const useStyles = makeStyles(theme => ({spinner: {marginRight: theme.spacing(1)}}))

const CampaignLaunchingSpinner = ({templateId}) => {
  const classes = useStyles()
  const {addHandler, removeHandler} = useSocket()
  const [isLaunching, setIsLaunching] = useState(false)

  useEffect(() => {
    if (templateId) {
      const campaignLive = createHandler(
        `approvals:${templateId}:template`,
        "campaign_launched",
        () => setIsLaunching(false)
      )

      addHandler(campaignLive)

      // If template is just launched, a worker may be running to remove remnants
      // of the approval process
      fetchTemplateLaunchStatus(templateId).then(({status}) => {
        setIsLaunching(status === "launching")
      })

      return () => removeHandler(campaignLive)
    }
  }, [addHandler, removeHandler, setIsLaunching, templateId])

  return isLaunching ? (
    <div>
      <Button disabled={true} id="launching-spinner">
        <CircularProgress className={classes.spinner} size={14} />
        Launching
      </Button>
    </div>
  ) : null
}

CampaignLaunchingSpinner.propTypes = {templateId: string, templateStatus: string}

export default CampaignLaunchingSpinner
