const formatMoney = value => {
  if (!value || isNaN(value)) return ""
  return parseFloat(value).toLocaleString("en-US", {
    currency: "USD",
    maximumFractionDigits: 2,
    style: "currency",
  })
}

export {formatMoney}
