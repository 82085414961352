import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import {styled} from "@mui/material/styles"
import {bool, func, number, shape, string} from "prop-types"
import React, {useEffect, useMemo, useState} from "react"
import {FaTrash} from "react-icons/fa"
import {useSelector} from "react-redux"

import {requiredField} from "lib/field-validations"

import {COLUMN_OPTIONS} from "./config"

const TableColumnControls = styled("div")(({theme}) => ({paddingBottom: theme.spacing(2.5)}))

const TableColumnSubsection = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(1),
  "& svg": {cursor: "pointer"},
}))

const AccountsTableSettingsColumn = ({column, onChange, onDelete}) => {
  const team = useSelector(state => state.session.team)
  // Holds column config parsed for the specific need of mapping a custom meta key
  const [columnWithCustomKey, setColumnWithCustomKey] = useState(null)

  // Compiles all available meta_private, meta_public keys stored in team field mappings
  const metaOptions = useMemo(() => {
    const fieldMappings = team?.account_field_map || {}

    return Object.values(fieldMappings)
      .filter(
        fieldMap =>
          typeof fieldMap === "object" &&
          fieldMap.digital_onboarding_field &&
          ["meta_private", "meta_public"].includes(fieldMap.digital_onboarding_field) &&
          fieldMap.meta_sub_key
      )
      .map(fieldMap =>
        fieldMap.digital_onboarding_field === "meta_private"
          ? {
              isPrivate: true,
              label: `#ACCOUNT.META_PRIVATE.${fieldMap.meta_sub_key}`,
              value: `meta_private.${fieldMap.meta_sub_key}`,
            }
          : {
              isPrivate: false,
              label: `#ACCOUNT.META_PUBLIC.${fieldMap.meta_sub_key}`,
              value: `meta_public.${fieldMap.meta_sub_key}`,
            }
      )
  }, [team])

  const mappedMetaKeys = useMemo(() => metaOptions.reduce((acc, {value}) => [...acc, value], []), [
    metaOptions,
  ])

  // Placeholder options for custom meta keys
  const newPersonalizationOptions = [
    {
      isPrivate: true,
      label: "New Account META_PRIVATE Personalization",
      value: "meta_private.",
    },
    {
      isPrivate: false,
      label: "New Account META_PUBLIC Personalization",
      value: "meta_public.",
    },
  ]

  const handleChangeContentId = value => {
    const updated = {
      ...column,
      contentId: value,
      error: !column.contentId || !column.header,
    }

    onChange(updated)

    setColumnWithCustomKey(updated)
  }

  const handleChangeKey = value => {
    const match = column.contentId.match(/^(?:meta_private|meta_public)\./)

    onChange({
      ...column,
      contentId: `${match[0]}${value}`,
      error: !!requiredField(column.customKey) || !column.contentId || !column.header,
    })
  }

  useEffect(() => {
    const match = column.contentId.match(/^(meta_private|meta_public)\.(.+)?/)

    // On mount, if there's a custom meta key, or on change of custom key
    const updated =
      !!match && !mappedMetaKeys.includes(match[0]) && !!match[2]
        ? {
            ...column,
            contentId: `${match[1]}.`,
            customKey: match[2],
            error: !!requiredField(column.customKey) || !column.contentId || !column.header,
          }
        : {
            ...column,
            contentId: column.contentId,
            error: !column.contentId || !column.header,
          }

    setColumnWithCustomKey(updated)
  }, [column, mappedMetaKeys, setColumnWithCustomKey])

  return (
    columnWithCustomKey && (
      <TableColumnControls key={column.id}>
        <TableColumnSubsection>
          <Typography variant="overline">{`Column ${column.id}`}</Typography>
          <FaTrash onClick={() => onDelete(column.id)} />
        </TableColumnSubsection>
        <TextField
          error={!!requiredField(column.header)}
          fullWidth={true}
          helperText={requiredField(column.header)}
          label="Column Header"
          onChange={event =>
            onChange({
              ...column,
              error: !column.contentId || !column.header,
              header: event.target.value,
            })
          }
          sx={{paddingBottom: 2.5}}
          value={column.header}
        />

        <FormControl error={!!requiredField(column.contentId)} fullWidth sx={{paddingBottom: 2.5}}>
          <InputLabel id="personalization-field-label">Personalization Field</InputLabel>
          <Select
            id="personalization-field"
            labelId="personalization-field-label"
            renderValue={value =>
              [...COLUMN_OPTIONS, ...metaOptions, ...newPersonalizationOptions].find(
                selection => selection.value === value
              ).label
            }
            onChange={({target: {value}}) => handleChangeContentId(value)}
            value={columnWithCustomKey.contentId}
          >
            {[...COLUMN_OPTIONS, ...metaOptions, ...newPersonalizationOptions].map(
              contentOption => (
                <MenuItem key={contentOption.value} value={contentOption.value}>
                  {<ListItemText primary={contentOption.label} />}
                </MenuItem>
              )
            )}
          </Select>
          <FormHelperText>{requiredField(column.contentId)}</FormHelperText>
        </FormControl>

        {["meta_private.", "meta_public."].includes(columnWithCustomKey.contentId) && (
          <TextField
            error={!!requiredField(columnWithCustomKey.customKey)}
            fullWidth={true}
            helperText={requiredField(columnWithCustomKey.customKey)}
            label="Key"
            onChange={({target: {value}}) => handleChangeKey(value)}
            sx={{paddingBottom: 2.5}}
            value={columnWithCustomKey.customKey || ""}
          />
        )}
      </TableColumnControls>
    )
  )
}

AccountsTableSettingsColumn.propTypes = {
  column: shape({contentId: string, error: bool, header: string, id: number}),
  onChange: func,
  onDelete: func,
}

export default AccountsTableSettingsColumn
