import buttonComponent, {DividerIcon} from "./divider-button"
import DividerReadOnly from "./divider-readonly"
import DividerSettings from "./divider-settings"

export default {
  title: "Divider",
  type: "divider",
  Readonly: DividerReadOnly,
  Icon: DividerIcon,
  Settings: DividerSettings,
  Button: buttonComponent,
  suitableFor: ["content_container", "message", "page", "template"],
}
