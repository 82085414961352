// This file was generated automatically from character-06.svg with the following command:
// ./svg-converter.js src/components/superhero/character-06.svg
import SuperheroSvg from "./superhero-svg"

const Character06 = props => (
  <SuperheroSvg viewBox="200 60 200 220" {...props}>
    <g id="Layer_10">
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M247.04,266.86l-0.04-0.03c-0.08-0.06-1.83-1.53-1.29-3.29c0.41-1.32,1.9-2.31,4.56-3l0.07-0.01
  				c0.03,0,2.86-0.24,5.97-0.99c4.02-0.97,6.66-2.35,7.65-3.99l0.71,0.41c-2.67,4.42-13.51,5.42-14.36,5.49
  				c-2.77,0.73-3.59,1.63-3.78,2.26c-0.29,0.94,0.61,1.97,1.03,2.33c12.53,5.37,24.26,5.14,25.57,5.09
  				c1.98-1.45,1.78-7.77,1.53-10.12l0.86-0.24c0.1,0.95,0.9,9.3-2.14,11.21l-0.11,0.07l-0.11,0.01c-0.13,0.01-12.66,0.6-26.07-5.16
  				L247.04,266.86z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M251.15,268.27l-0.35-0.78c1.15-0.72,1.89-1.61,2.18-2.63c0.55-1.93-0.6-3.77-0.61-3.79l0.74-0.6
  				c0.06,0.09,1.36,2.18,0.7,4.52C253.44,266.29,252.54,267.4,251.15,268.27z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M253.09,265.81l0.31-0.9c16.94,4.1,21.42,2.7,21.47,2.68l0.15,0.87
  				C274.85,268.53,270.41,270.01,253.09,265.81z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M253.27,264.5l0.07-0.92c13.24-1.04,14.94-6.15,14.96-6.2l0.83,0.14
  				C269.06,257.75,267.32,263.4,253.27,264.5z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M255.52,262.52c-0.34-0.22-0.61-0.48-0.74-0.61l0.64-0.72c0.47,0.47,1.09,0.85,1.3,0.8
  				c0-0.01,0.01-0.02,0.01-0.04c0.21-0.61,0.4-1.14,0.97-1.29c0.41-0.11,0.92,0.02,2.03,0.29c0.79,0.19,1.36,0.34,1.55,0.22
  				c0.19-0.12,0.41-0.76,0.72-1.65c0.26-0.74,0.62-1.16,1.08-1.25c0.9-0.17,1.57,1.18,1.76,1.6l-0.79,0.5
  				c-0.31-0.69-0.78-1.23-0.95-1.19c0,0-0.13,0.07-0.29,0.54c-0.44,1.27-0.66,1.9-1.18,2.23c-0.52,0.33-1.09,0.19-2.22-0.09
  				c-0.8-0.2-1.49-0.37-1.65-0.3c-0.04,0.05-0.14,0.33-0.23,0.58c-0.12,0.35-0.35,0.61-0.65,0.71
  				C256.45,263.01,255.94,262.79,255.52,262.52z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M363.03,257.97l-0.04,0.03c-0.07,0.07-1.74,1.64-3.42,0.89c-1.27-0.56-2.06-2.16-2.44-4.89l0-0.08
  				c0-0.03,0.11-2.87-0.27-6.05c-0.49-4.1-1.54-6.9-3.05-8.08l0.49-0.65c4.07,3.18,3.76,14.06,3.73,14.92
  				c0.4,2.84,1.19,3.76,1.79,4.02c0.9,0.4,2.03-0.37,2.44-0.74c6.83-11.8,8-23.47,8.11-24.78c-1.21-2.14-7.5-2.69-9.87-2.73
  				l-0.14-0.88c0.95,0.01,9.34,0.22,10.88,3.46l0.05,0.11l-0.01,0.11c-0.01,0.13-0.92,12.64-8.24,25.27L363.03,257.97z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M364.92,254.06l-0.82,0.25c-0.58-1.23-1.37-2.07-2.35-2.48c-1.85-0.78-3.82,0.14-3.84,0.15
  				l-0.5-0.81c0.09-0.04,2.33-1.09,4.58-0.15C363.23,251.55,364.22,252.57,364.92,254.06z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M362.71,251.83l-0.86-0.42c6.1-16.33,5.24-20.95,5.23-20.99l0.89-0.05
  				C368.01,230.56,368.95,235.14,362.71,251.83z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M361.43,251.5l-0.91-0.18c0.55-13.27-4.32-15.57-4.37-15.59l0.24-0.8
  				C356.62,235.02,362.01,237.42,361.43,251.5z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M359.73,249.03c-0.26,0.31-0.55,0.55-0.7,0.67l-0.64-0.72c0.52-0.41,0.97-0.98,0.95-1.2
  				c-0.01,0-0.02-0.01-0.04-0.02c-0.58-0.28-1.08-0.53-1.16-1.11c-0.06-0.42,0.13-0.91,0.53-1.98c0.29-0.76,0.5-1.31,0.4-1.52
  				c-0.1-0.2-0.71-0.5-1.55-0.91c-0.71-0.35-1.08-0.76-1.11-1.22c-0.06-0.91,1.36-1.42,1.8-1.56l0.4,0.85
  				c-0.72,0.22-1.31,0.63-1.29,0.8c0,0,0.06,0.14,0.5,0.35c1.2,0.59,1.81,0.89,2.07,1.44c0.26,0.55,0.06,1.1-0.36,2.19
  				c-0.29,0.77-0.54,1.44-0.49,1.6c0.04,0.05,0.31,0.18,0.55,0.3c0.34,0.16,0.56,0.42,0.63,0.73
  				C360.33,248.16,360.05,248.65,359.73,249.03z"
          />
        </g>
      </g>
      <path
        style={{fill: "#EB6669"}}
        d="M323.11,99.25c3.61,8.27,10.82,5.06,10.82,5.06c-2.16,0-3.97-3.22-5.77-8.27
  		c-2.36-6.61-1.98-21.89-1.98-21.89c-0.07-1.59-0.06-7.96-5.67-7.54c-5.6,0.42-5.54,6.01-5.54,6.01l-0.04,1.58l3.92,3.69
  		C318.85,77.88,321.12,94.71,323.11,99.25z"
      />
      <path
        style={{fill: "#CEAC98"}}
        d="M305.89,63.32c0.52,0,1.04,0.03,1.55,0.1c0.3,15.31,11.32,23.33,13.4,24.71
  		c-1.82,8.26-7.82,14.32-14.95,14.32c-8.57,0-15.52-8.76-15.52-19.56S297.32,63.32,305.89,63.32z"
      />
      <path
        style={{fill: "#CEAC98"}}
        d="M290.37,82.84c0,8.7,4.84,16.06,11.06,18.62l0,8.5l5.72,0l0-7.61c4.3-0.43,8.11-3.08,10.71-7.03
  		c0.55,0.5,1.23,0.8,1.97,0.79c1.87,0,3.39-1.92,3.39-4.28c0-1.88-0.96-3.47-2.29-4.04c0-0.02,0.01-0.04,0.01-0.06
  		c-0.11-0.07-0.21-0.15-0.32-0.22l-30.06-7.72C290.44,80.78,290.37,81.8,290.37,82.84z"
      />
      <g>
        <g>
          <path
            style={{fill: "#CEAC98"}}
            d="M290.92,111.97c-0.86,1.27,0.46-4.54-0.79-0.7c-1.69,5.2-3.2,11.4-3.8,13.61
  				c-0.35,1.29-0.72,3-1.46,6.43c-0.96,4.44-0.86,4.37-1.25,5.79c-1,3.64-2.02,5.56-1.67,5.76c0.18,0.11,0.75-0.27,2.52-2.59
  				c0.77,0.51,1.54,1.02,2.3,1.53c-0.83-2.76-2.1-4.04-3.15-4.69c-0.35-0.22-0.27-0.12-2.25-1.03c-0.52-0.24-1.02-0.48-4.02-1.94
  				c-1.05-0.51-2.1-1-3.14-1.52c-2.43-1.24-2.66-1.51-3.4-1.51c-1.38,0-2.88,0.86-3.33,2.19c-0.07,0.2-0.37,1.17,0.1,1.79
  				c0.19,0.25,0.33,0.21,0.77,0.52c0.73,0.51,0.79,0.94,1.35,1.46c0.55,0.51,1.11,0.65,1.57,0.82c1.24,0.45,1.59,0.96,4.5,3.02
  				c1.99,1.4,2.99,2.1,4.19,2.71c2.02,1.01,3.26,1.22,4.2,1.26c0.89,0.03,1.99,0.06,2.9-0.64c0.56-0.43,0.83-0.97,1.28-2.17
  				c0.81-2.15,1.23-3.87,1.29-4.1c0.42-1.7,0.26-0.72,1.57-5.32c0.7-2.47,1.23-4.43,1.51-5.52c0.67-2.55,1.47-5.71,1.43-5.72
  				c-0.05-0.01-1.02,3.51-1.02,3.51c0,0,0.2-0.69,0.6-2.08c0.31-1.06,0.79-2.71,1.56-5.37c0.25-0.85,1.09-3.75,1.09-3.75
  				c0,0,0.3-4.51-1.1-5.15C294.19,108.04,292.06,110.29,290.92,111.97z"
          />
        </g>
      </g>
      <path
        style={{fill: "#CEAC98"}}
        d="M313.38,77.84l-20.63-5.29c-1.11,2.2-1.87,4.69-2.21,7.37l29.75,7.64
  		C317.19,85.45,314.72,82.01,313.38,77.84z"
      />
      <ellipse style={{fill: "#0D1E36"}} cx="298.66" cy="78.47" rx="1.24" ry="1.56" />
      <path
        style={{fill: "#EB6669"}}
        d="M307.44,63.42c7.84,0.98,13.97,9.32,13.97,19.47c0,1.82-0.2,3.57-0.57,5.24
  		C318.76,86.75,307.74,78.73,307.44,63.42z"
      />
      <path
        style={{fill: "#EB6669"}}
        d="M307.46,63.54c0,0-6.56,6.06-12.95,6.06C294.5,69.6,299.44,62.22,307.46,63.54z"
      />
      <path
        style={{fill: "#0D1E36"}}
        d="M310.5,80.7c0,0.86,0.3,1.56-0.38,1.56c-0.68,0-1.24-0.7-1.24-1.56c0-0.86,0.55-1.56,1.24-1.56
  		C310.8,79.14,310.5,79.84,310.5,80.7z"
      />
      <path
        style={{fill: "#FFFFFF"}}
        d="M308.31,89.02c-0.09-0.2-0.31-0.24-0.46-0.11c-0.35,0.31-1,0.98-1.91,1.21
  		c-1.25,0.31-2.33-0.32-2.59,0.09c-0.15,0.23,0.03,0.51,0.37,0.87c0.34,0.36,0.53,1.06,1.67,0.79c1.2-0.28,2.96-2.04,2.96-2.66
  		C308.35,89.13,308.34,89.07,308.31,89.02z"
      />
      <g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M305.1,84.49c-1.01-0.31-2.03-0.61-3.04-0.92c0.07,0.17,0.13,0.33,0.2,0.5
  				c0.89-1.2,1.77-2.41,2.66-3.61c0.13-0.17,0.26-0.35,0.38-0.52c0.26-0.35-0.33-0.69-0.58-0.34c-0.89,1.2-1.77,2.41-2.66,3.61
  				c-0.13,0.17-0.26,0.35-0.38,0.52c-0.13,0.18,0.01,0.44,0.2,0.5c1.01,0.31,2.03,0.61,3.04,0.92
  				C305.34,85.27,305.52,84.61,305.1,84.49L305.1,84.49z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#CEAC98"}}
            d="M314.63,120.5c-0.18,1.52-1.69-4.24-1.02-0.25c0.9,5.39,2.43,11.59,2.92,13.82
  				c0.28,1.3,0.75,2.99,1.68,6.38c1.2,4.38,1.25,4.28,1.56,5.72c0.79,3.69,0.78,5.86,1.18,5.88c0.21,0.01,0.54-0.59,1.04-3.46
  				c0.92,0.1,1.83,0.19,2.75,0.29c-2.01-2.07-3.73-2.61-4.96-2.71c-0.41-0.03-0.3,0.02-2.47,0.13c-0.57,0.03-1.12,0.05-4.46,0.14
  				c-1.16,0.03-2.33,0.09-3.49,0.1c-2.73,0.02-3.06-0.11-3.71,0.23c-1.22,0.63-2.16,2.09-1.94,3.48c0.03,0.21,0.21,1.21,0.92,1.54
  				c0.29,0.14,0.39,0.03,0.93,0.11c0.89,0.12,1.13,0.47,1.87,0.67c0.73,0.19,1.28,0.07,1.77,0c1.3-0.17,1.85,0.12,5.39,0.6
  				c2.41,0.32,3.62,0.49,4.97,0.46c2.26-0.04,3.45-0.42,4.31-0.83c0.81-0.38,1.79-0.86,2.27-1.91c0.29-0.64,0.29-1.25,0.13-2.51
  				c-0.28-2.28-0.7-4-0.75-4.23c-0.41-1.7-0.1-0.76-1.06-5.44c-0.52-2.51-0.96-4.5-1.2-5.59c-0.58-2.58-1.33-5.74-1.38-5.73
  				c-0.05,0.01,0.71,3.58,0.72,3.58c0,0-0.14-0.71-0.42-2.12c-0.22-1.08-0.55-2.77-1.1-5.49c-0.17-0.87-0.77-3.83-0.77-3.83
  				c0,0-1.81-4.14-3.35-4.06C315.72,115.5,314.87,118.48,314.63,120.5z"
          />
        </g>
      </g>
      <g>
        <path
          style={{fill: "#0A273F"}}
          d="M291.71,124.61c0.75-3.07,1.5-6.14,2.25-9.21c0.37-1.49,0.76-2.98,1.1-4.49
  			c0-0.02,0.01-0.04,0.02-0.07c0.43-1.75-0.46-3.89-1.9-4.33c-1.49-0.45-2.98,0.52-3.44,2.39c-0.3,1.22-0.6,2.44-0.9,3.66
  			c-0.69,2.83-1.38,5.66-2.08,8.49l4.87,3.84C291.66,124.8,291.68,124.71,291.71,124.61z"
        />
        <path
          style={{fill: "#609E92"}}
          d="M266.66,204.32l19.18-63.85l22.71,3.9l-8.31,17.96l-13.72,54.61c0,0-1.7,18.11-5.4,45.55
  			l-23.32-8.62C257.8,253.86,261.3,224.35,266.66,204.32z"
        />
        <path
          style={{fill: "#5DBDAF"}}
          d="M320,205.54l-3.25-48.13l-1.02-15.58l-4.51-1.18l-23.98-6.84l-2.38,17.27
  			c-0.17,0.86-0.3,1.74-0.38,2.64l-0.21,1.43l0.12,0.02c-0.01,0.18-0.02,0.37-0.02,0.55c-0.13,9.92,5.38,18.3,12.79,20.31l0.11,0.39
  			l4.28,41.1c0,0,0.47,13.07,7.58,15.46l43.15,10.76L363.67,224l-40.38-9.99C323.29,214.01,319.57,210.85,320,205.54z"
        />
        <path
          style={{fill: "#123252"}}
          d="M307.46,106.25l4.7,0.65c4.34,0.75,7.38,5.79,6.79,11.27l-2.13,16.18l-29.66-0.82l2.62-19.94
  			c0.79-6.32,3.43-8.99,8.94-8.56l3,0.42c0,0-0.65,3.15,2.67,3.15C307.72,108.6,307.46,106.25,307.46,106.25z"
        />
        <polygon
          style={{fill: "#123252"}}
          points="317.21,131.94 287.16,133.54 286.48,138.73 316.12,144.44 		"
        />
        <g>
          <path
            style={{fill: "#CEAC98"}}
            d="M302.74,145.79c0,0,0.42,0.47,0.24,0.74c-0.18,0.27-0.99,0.55-1.69-0.26
  				c-0.46-0.53-2.7-2.18-4.63-0.39c0,0-1.9,2.68-0.29,5.31c1.21,1.97,2.51,3.16,5.4,3.18c2.69,0.01,4.18-1.13,5.06-1.39
  				c2.9,0.11,5.81,0.22,8.71,0.34c0.42,0.02,0.84,0.03,1.27,0.05l0.24-7.05c-2.96-0.11-5.93-0.23-8.89-0.34
  				c-0.31-0.01-0.61-0.02-0.92-0.04c-0.48-0.92-1.69-2.98-3.12-3.67c0,0-0.64-0.46-1.6-0.24
  				C301.55,142.25,301.64,144.68,302.74,145.79z"
          />
        </g>
        <path
          style={{fill: "#123252"}}
          d="M316.1,127.51c-0.91-3.26-1.82-6.52-2.73-9.77c-0.44-1.59-0.92-3.16-1.33-4.76
  			c-0.01-0.02-0.01-0.05-0.02-0.07c-0.52-1.86,0.36-4.21,1.89-4.76c1.58-0.57,3.22,0.4,3.77,2.38c0.36,1.3,0.72,2.59,1.09,3.89
  			c0.84,3,1.68,6,2.52,9l-5.1,4.39C316.16,127.71,316.13,127.61,316.1,127.51z"
        />
        <path
          style={{fill: "#CEAC98"}}
          d="M267.68,129.13c0,0,0.21,0.58-0.02,0.74c-0.24,0.16-1.02,0.06-1.37-0.94
  			c-0.23-0.65-1.64-3.03-3.84-2.29c0,0-2.45,1.51-1.88,4.46c0.42,2.22,1.17,3.79,3.64,5.03c2.3,1.15,3.92,0.79,4.76,0.94
  			c2.45,1.32,4.9,2.65,7.35,3.97c0.36,0.19,0.71,0.38,1.07,0.58l2.38-5.99c-2.5-1.35-5-2.7-7.51-4.05
  			c-0.26-0.14-0.52-0.28-0.77-0.42c-0.13-0.99-0.52-3.28-1.54-4.49c0,0-0.4-0.67-1.3-0.88C267.76,125.57,267.09,127.7,267.68,129.13
  			z"
        />
      </g>
      <ellipse style={{fill: "#0D1E36"}} cx="309.72" cy="80.7" rx="1.24" ry="1.56" />
      <g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M301.11,73.77c-0.33-0.14-0.67-0.24-1.01-0.32c-0.84-0.18-1.7-0.23-2.55-0.19
  				c-0.34,0.02-0.67,0.29-0.66,0.66c0.02,0.34,0.29,0.68,0.66,0.66c0.45-0.02,0.9-0.02,1.35,0.01c0.1,0.01,0.19,0.02,0.29,0.03
  				c0.03,0,0.11,0,0.13,0.02c-0.03-0.02-0.15-0.02-0.02,0c0.15,0.02,0.31,0.05,0.46,0.08c0.21,0.04,0.42,0.1,0.62,0.16
  				c0.09,0.03,0.41,0.16,0.08,0.02c0.17,0.07,0.32,0.12,0.51,0.07c0.15-0.04,0.32-0.16,0.39-0.3
  				C301.5,74.38,301.45,73.91,301.11,73.77L301.11,73.77z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M309.4,77.22c0.1,0,0.2,0.01,0.31,0.01c0.11,0.01,0.23,0.02,0.34,0.03
  				c0.07,0.01,0.14,0.01,0.2,0.02c0.04,0,0.07,0.01,0.11,0.01c0.02,0,0.05,0.01,0.07,0.01c-0.11-0.01-0.13-0.02-0.08-0.01
  				c0.33,0.06,0.66,0.11,0.99,0.2c0.17,0.05,0.34,0.1,0.5,0.16c0.02,0.01,0.04,0.02,0.06,0.02c0.05,0.02,0.05,0.02,0,0
  				c-0.05-0.02-0.05-0.02,0,0c0.02,0.01,0.04,0.02,0.06,0.03c0.08,0.04,0.16,0.08,0.24,0.12c0.3,0.17,0.73,0.08,0.9-0.24
  				c0.16-0.31,0.09-0.72-0.24-0.9c-0.67-0.37-1.42-0.56-2.17-0.66c-0.43-0.06-0.86-0.11-1.3-0.12c-0.34-0.01-0.67,0.31-0.66,0.66
  				C308.76,76.92,309.03,77.22,309.4,77.22L309.4,77.22z"
          />
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default Character06
