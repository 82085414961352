import {Button, Collapse, IconButton, TextField, Tooltip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {func, number, oneOf, string} from "prop-types"
import {useState} from "react"
import {
  MdClose as DeleteIcon,
  MdEdit as EditIcon,
  MdEmail as EmailIcon,
  MdSms as SMSIcon,
} from "react-icons/md"

import {to12Hour} from "lib/date-time-formatters"
import draggable from "lib/draggable"

import DeletePinDialog from "./delete-pin-dialog"

export const DRAG_TYPE = "MessagePin"

const useStyles = makeStyles(theme => ({
  icon: {
    color: "white",
    alignItems: "center",
    display: "flex",
    height: "100%",
    paddingBottom: 8,
    justifyContent: "center",
  },
  messagePin: {
    background: theme.palette.primary.main,
    boxSizing: "content-box",
    position: "relative",
    width: 26,
    height: 26,
    paddingTop: 5,
    cursor: "move",
    textAlign: "center",
    transition: "all ease-out .1s, top ease-in .1s",
    "&.dragging": {
      opacity: "0.7",
      transform: "translateX(-50%)",
      cursor: "none",
      "&:before": {
        position: "absolute",
        top: -25,
        whiteSpace: "nowrap",
        content: "attr(data-day-label)",
        fontWeight: "bold",
        color: theme.palette.primary.main,
        transform: "translateX(-50%)",
      },
    },
    "&.notDroppable": {
      background: theme.palette.error.main,
      "&:before": {
        display: "none",
      },
      "&:after": {
        borderTopColor: theme.palette.error.main,
      },
    },
    "& .showOnHover": {
      visibility: "hidden",
    },
    "&:hover .showOnHover": {
      visibility: "visible",
    },
    "&.dragging, &.forceTail, &:nth-child(1)": {
      borderTopLeftRadius: 13,
      borderTopRightRadius: 13,
    },
    "&:nth-child(n+2)": {
      borderTop: `2px solid ${theme.palette.primary.dark}`,
      paddingTop: 2,
    },
    "&.dragging, &.forceTail": {
      borderTop: "none",
    },
    "&.dragging, &.forceTail, &:nth-last-child(1)": {
      borderBottomLeftRadius: 13,
      borderBottomRightRadius: 13,
      paddingBottom: 10,
      "&:after": {
        position: "relative",
        content: '""',
        display: "block",
        top: -9,
        borderLeft: "13px solid transparent",
        borderRight: "13px solid transparent",
        borderBottomLeftRadius: 13,
        borderBottomRightRadius: 13,
        borderTop: `26px solid ${theme.palette.primary.main}`,
      },
    },
  },
  deleteIconButton: {
    boxSizing: "border-box",
    width: 18,
    height: 18,
    fontSize: 15,
    padding: 5,
    position: "absolute",
    right: -5,
    top: -11,
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    zIndex: 4998,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  label: {
    position: "absolute",
    left: theme.spacing(4),
    top: 0,
    display: "block",
  },
  editing: {
    opacity: "75%",
  },
  timeEditorBackdrop: {
    zIndex: 1499,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  timeDisplay: {
    display: "flex",
    alignItems: "baseline",
  },
  save: {
    paddingTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const getDeletePinMessage = (type, day, label) =>
  day > 0
    ? `Are you sure you want to delete this ${type} pin that sends a message at ${to12Hour(
        label
      )} on day ${day}?`
    : `Are you sure you want to delete this ${type} pin that sends a message immediately upon journeys being created?`

const MessagePin = ({label, type, day, id, onMouseDown, onDeletePin, onSavePin}) => {
  const classes = useStyles()
  const [timeValue, setTimeValue] = useState(label)

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const [isEditingTime, setIsEditingTime] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const deletePinMessage = getDeletePinMessage(type, day, label)

  const Icon = type === "sms" ? SMSIcon : EmailIcon

  const onCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  const onDeleteConfirmed = () => {
    onDeletePin(id)
  }

  const onEndEdit = () => {
    setIsEditingTime(false)
    setIsTooltipOpen(false)
  }

  const onCloseTooltip = () => {
    if (isEditingTime) return
    setIsTooltipOpen(false)
  }

  const onOpenTooltip = () => {
    if (isDeleteDialogOpen) return
    setIsTooltipOpen(true)
  }

  const onStartEditingTime = () => {
    setIsEditingTime(true)
  }

  const onSaveTime = () => {
    onSavePin({id, time: timeValue})
    setIsEditingTime(false)
  }

  const onChangeTime = ({target: {value}}) => {
    setTimeValue(value)
  }

  const onOpenDeleteDialog = () => {
    setIsTooltipOpen(false)
    setIsDeleteDialogOpen(true)
  }

  const isNewPin = id?.match(/^new-/)

  return (
    <Tooltip
      onClose={onCloseTooltip}
      onOpen={onOpenTooltip}
      open={isTooltipOpen}
      placement={isNewPin ? "left" : "right"}
      title={
        !day ? (
          isNewPin ? (
            label
          ) : (
            ""
          )
        ) : (
          <>
            <div className={classes.timeDisplay}>
              <TextField
                disabled={!isEditingTime}
                inputProps={{step: 300}}
                label="Send at:"
                onChange={onChangeTime}
                type="time"
                value={timeValue}
              />
              <IconButton onClick={onStartEditingTime} size="medium">
                <EditIcon size={15} />
              </IconButton>
            </div>
            <Collapse in={isEditingTime}>
              <div className={classes.save}>
                <Button color="primary" onClick={onSaveTime} size="small">
                  save
                </Button>
              </div>
            </Collapse>
          </>
        )
      }
    >
      <div
        className={cx(classes.messagePin, {
          forceTail: id?.match(/^new/),
        })}
        data-day-label={`Day ${day}`}
        data-id={id}
        data-testid={id}
        data-type={type}
        onMouseDown={onMouseDown}
      >
        {isEditingTime && <div className={classes.timeEditorBackdrop} onClick={onEndEdit} />}
        <div className={classes.icon}>
          <Icon size={18} />
        </div>
        {!id?.match(/^new/) && (
          <IconButton
            className={cx("showOnHover", classes.deleteIconButton)}
            onClick={onOpenDeleteDialog}
            size="medium"
          >
            <DeleteIcon size={22} />
          </IconButton>
        )}
        {isDeleteDialogOpen && (
          <DeletePinDialog
            message={deletePinMessage}
            onClose={onCloseDeleteDialog}
            onDelete={onDeleteConfirmed}
          />
        )}
      </div>
    </Tooltip>
  )
}

MessagePin.propTypes = {
  day: number,
  id: string,
  label: string,
  onDeletePin: func,
  onMouseDown: func,
  onSavePin: func,
  type: oneOf(["sms", "email", "new-sms", "new-email"]),
}

export default draggable(MessagePin)
