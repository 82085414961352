import {Button, FormControl, InputLabel, Popover} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import memoize from "memoize-one"
import {func, object, string} from "prop-types"
import {useState} from "react"

import unmemoizedCreateTheme from "themes/team-theme-creator"

import ColorPicker from "../../../color-input/color-picker"
import {compilePalette} from "../../../color-input/helpers"

const createTheme = memoize(theme => unmemoizedCreateTheme(theme))

const PluginColorInput = ({label, theme, onChange, value}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const pickerOpen = !!anchorEl

  const onColorInputChange = ({hex}) => onChange(hex)

  const resetBackgroundColor = () => onChange(undefined)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggle = event => {
    pickerOpen ? handleClose() : handleOpen(event)
  }

  const muiTheme = createTheme(theme)

  return (
    <FormControl margin="normal">
      <InputLabel focused={pickerOpen} shrink={true}>
        {label}
      </InputLabel>
      <div className={classes.content}>
        <ThemeProvider theme={muiTheme}>
          <Button
            className={classes.swatch}
            onClick={toggle}
            type="button"
            variant="contained"
            color="primary"
            style={{
              background: !value ? "" : value,
            }}
          >
            <div className={classes.color} />
          </Button>
        </ThemeProvider>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{horizontal: "left", vertical: "bottom"}}
          onClose={handleClose}
          open={pickerOpen}
        >
          <ColorPicker
            color={value}
            onChange={onColorInputChange}
            palette={compilePalette(muiTheme)}
          />
        </Popover>
        <Button variant="outlined" color="grey" onClick={resetBackgroundColor}>
          Reset to default
        </Button>
      </div>
    </FormControl>
  )
}

PluginColorInput.propTypes = {
  label: string,
  value: string,
  onChange: func.isRequired,
  theme: object,
}

PluginColorInput.defaultProps = {
  label: "Color",
}

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: "23px",
    display: "flex",
  },
  color: {
    borderRadius: "2px",
    height: "100%",
    width: "100%",
  },
  swatch: {
    marginRight: "5px",
    width: theme.spacing(7),
    height: theme.spacing(5),
    padding: theme.spacing(0.5),
    outline: "none",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "3px",
    cursor: "pointer",
    "&:disabled": {
      cursor: "auto",
    },
  },
  popover: {
    position: "absolute",
    zIndex: "2",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}))

export default PluginColorInput
