import {EVENT_ACTIONS_SET} from "./audit-logs-actions"

const initialState = {
  eventActions: {},
}

function auditLogs(state = initialState, action) {
  switch (action.type) {
    case EVENT_ACTIONS_SET:
      return {
        ...state,
        eventActions: action.payload
          .map(({action: eventAction}) => ({
            category: eventAction.split("/")[0],
            name: eventAction.split("/")[1],
          }))
          .reduce(
            (acc, {category, name}) => ({
              ...acc,
              [category]: (acc[category] || []).concat([name]),
            }),
            {}
          ),
      }

    default:
      return state
  }
}

export default auditLogs
