import {format} from "date-fns"

const SESSION_LOG_LIST_SET = "SESSION_LOG_LIST_SET"
const ADD_SESSION_LOG = "ADD_SESSION_LOG"

export const addSessionLog = payload => ({
  type: ADD_SESSION_LOG,
  payload,
})

export const setSessionLogList = payload => ({
  type: SESSION_LOG_LIST_SET,
  payload,
})

export const logWithTimestamp = log => ({...log, timestamp: new Date(log.timestamp)})

export const addLogToState = (sessionLogs, log) => {
  const {id, sessionId} = log

  // we already have it
  if (sessionLogs[sessionId] && sessionLogs[sessionId].find(l => l.id === id)) return sessionLogs

  if (sessionLogs[sessionId])
    return {
      ...sessionLogs,
      [sessionId]: [...sessionLogs[sessionId], logWithTimestamp(log)],
    }

  return {
    ...sessionLogs,
    [sessionId]: [logWithTimestamp(log)],
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case SESSION_LOG_LIST_SET:
      return action.payload.reduce(addLogToState, state)
    case ADD_SESSION_LOG:
      return addLogToState(state, action.payload)
    default:
      throw new Error("unknown action type")
  }
}

export const dateRangeForSession = sessionLogs => {
  // we concat here to avoid modifying the sessionLogs array reference
  const [firstLog] = sessionLogs
    .concat()
    .sort((lhs, rhs) => lhs.timestamp - rhs.timestamp)
    .slice(0, 1)
  const [lastLog] = sessionLogs
    .concat()
    .sort((lhs, rhs) => rhs.timestamp - lhs.timestamp)
    .slice(0, 1)

  return {
    min: firstLog.timestamp,
    max: lastLog.timestamp,
  }
}

export const formatTimestamp = timestamp => format(timestamp, "MM-dd-yyyy hh:mm:ss.SS a")
