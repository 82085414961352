import {Button, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {object, string} from "prop-types"
import {Component} from "react"
import {MdContentCopy as CopyIcon} from "react-icons/md"

import absoluteUrl from "lib/absolute-url"

class UrlBox extends Component {
  getUrl = () => absoluteUrl(this.props.url)

  copyUrl = () => {
    require("copy-to-clipboard")(this.getUrl())
  }

  render() {
    const {className, classes} = this.props
    const url = this.getUrl()

    return (
      <div className={cx(classes.urlBox, className)}>
        <Typography
          className={classes.url}
          color="inherit"
          component="a"
          href={url}
          target="_blank"
        >
          {url}
        </Typography>
        <Button
          className={classes.copyButton}
          onClick={this.copyUrl}
          color="grey"
          variant="contained"
        >
          <CopyIcon className={classes.leftIcon} /> Copy URL
        </Button>
      </div>
    )
  }
}

UrlBox.propTypes = {
  className: string,
  classes: object.isRequired,
  url: string.isRequired,
}

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  urlBox: {
    display: "flex",
    background: theme.palette.common.white,
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  url: {
    flex: 1,
    padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  copyButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

export default withStyles(styles)(UrlBox)
