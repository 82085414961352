import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import {useContext, useEffect, useState} from "react"
import {TiPlus as PlusIcon} from "react-icons/ti"
import {Link} from "react-router-dom"

import Padded from "components/padded/padded"
import RewardSetCard from "components/reward/reward-set-card"
import TitleBar from "components/title-bar/title-bar"

import storage from "lib/storage"
import score from "lib/string/score"

import AccessControlled from "../access-control/access-controlled"
import {RewardsContext} from "./rewards-context-provider"

const storageKey = "rewardsets:dashboard:sort"

const compare = (rewardSetA, rewardSetB, sortOrder) => {
  switch (sortOrder) {
    case "alphabetical_asc":
      return rewardSetA.name.localeCompare(rewardSetB.name)
    case "alphabetical_desc":
      return rewardSetB.name.localeCompare(rewardSetA.name)
    case "date_added_oldest":
      return new Date(rewardSetA.insertedAt) - new Date(rewardSetB.insertedAt)
    default:
      // "date_added_newest"
      return new Date(rewardSetB.insertedAt) - new Date(rewardSetA.insertedAt)
  }
}

const filterAndSortRewardSets = (rewardSets, searchString, includeArchived, sortOrder) => {
  const searchLower = searchString.toLowerCase()
  return rewardSets
    .filter(rewardSet => includeArchived || rewardSet.status !== "archived")
    .filter(rewardSet => {
      const nameLower = rewardSet.name.toLowerCase()
      const similarity = score(nameLower, searchLower)
      return similarity >= 0.85 || nameLower.includes(searchLower)
    })
    .sort((a, b) => compare(a, b, sortOrder))
}

const RewardSetList = () => {
  const [searchString, setSearchString] = useState("")
  const [includeArchived, setIncludeArchived] = useState(false)
  const [sortOrder, setSortOrder] = useState(storage.getItem(storageKey) || "date_added_newest")
  const {rewardSets} = useContext(RewardsContext)

  useEffect(() => {
    storage.setItem(storageKey, sortOrder)
  }, [sortOrder])

  const sortedAndFilteredRewardSets = filterAndSortRewardSets(
    rewardSets,
    searchString,
    includeArchived,
    sortOrder
  )
  return (
    <>
      <TitleBar title="Rewards Dashboard">
        <AccessControlled requiredPermissions="rewards:manage">
          <Button
            color="primary"
            component={Link}
            to="/admin/rewards/reward-sets/new"
            variant="contained"
          >
            <PlusIcon /> Create a new Reward Set
          </Button>
        </AccessControlled>
      </TitleBar>
      <Padded unbounded={true}>
        <Stack direction="row" spacing={2}>
          <TextField
            label="Filter Reward Sets"
            variant="standard"
            onChange={e => setSearchString(e.target.value)}
            sx={{flexGrow: 1}}
          />
          <FormControlLabel
            control={
              <Switch
                checked={includeArchived}
                onChange={e => setIncludeArchived(e.target.checked)}
              />
            }
            label="Display Archived"
          />
          <FormControl sx={{minWidth: "200px"}}>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOrder}
              onChange={e => setSortOrder(e.target.value)}
              label={"Sort By"}
            >
              <MenuItem value={"date_added_newest"}>Date Added (newest)</MenuItem>
              <MenuItem value={"date_added_oldest"}>Date Added (oldest)</MenuItem>
              <MenuItem value={"alphabetical_asc"}>A to Z</MenuItem>
              <MenuItem value={"alphabetical_desc"}>Z to A</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Padded>
      <Padded unbounded={true}>
        <Grid container spacing={2}>
          {sortedAndFilteredRewardSets.map(rewardSet => (
            <Grid item xs={4} md={3} lg={2} key={rewardSet.id} sx={{minWidth: "400px"}}>
              <RewardSetCard rewardSet={rewardSet} />
            </Grid>
          ))}
        </Grid>
      </Padded>
    </>
  )
}

export default RewardSetList
