import {Typography} from "@mui/material"
import {arrayOf, number, object, string} from "prop-types"

import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"
import StatsPie from "components/template-insights/stats-pie"

import pluralize from "lib/string/pluralize"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const EnrollmentWidgetChart = ({name, enrollmentData, totalContacts}) => {
  const totalLabel = pluralize("contact", totalContacts)

  return (
    <>
      {totalContacts > 0 ? (
        <>
          <Typography variant="h4" sx={{mb: 1}}>
            {name}
          </Typography>
          <StatsPie
            colors={colors}
            data={enrollmentData}
            total={totalContacts}
            totalLabel={totalLabel}
          />
        </>
      ) : null}
    </>
  )
}

EnrollmentWidgetChart.propTypes = {
  name: string.isRequired,
  enrollmentData: arrayOf(object).isRequired,
  totalContacts: number.isRequired,
}

export default EnrollmentWidgetChart
