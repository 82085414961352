import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"
import {useEffect, useState} from "react"
import {MdAdd as AddIcon} from "react-icons/md"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {Field, reduxForm} from "redux-form"

import {RFTextField as TextField} from "components/mui-redux-form/mui-redux-form"

import {fetchUsers} from "lib/api"
import {fullName} from "lib/names"
import {timezones} from "lib/timezones"

import {controlAccess} from "../access-control/access-controlled"
import Box from "../box/box"
import {DOReduxFormSelect} from "../do-select/do-select"
import DocumentTitle from "../document-title/document-title"
import SaveButton from "../save-button/save-button"
import IpRestrictionSettings from "./ip-restriction-settings"
import {updateTeamSettings} from "./team-actions"

const GeneralSettings = ({classes, handleSubmit, submitting, disabled}) => {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [didError, setDidError] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetchUsers()
      .then(usersFromApi => {
        setIsLoading(false)
        setUsers(usersFromApi)
      })
      .catch(() => {
        setIsLoading(false)
        setDidError(true)
      })
  }, [])

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <DocumentTitle title="Team Settings - General" />
          <Grid container={true} spacing={4}>
            <Grid item={true} lg={6} md={12}>
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Team Name"
                name="name"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Address"
                name="address"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="City"
                name="city"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Country"
                name="country"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Privacy Policy URL"
                name="config.do.privacy_policy_url"
                type="url"
              />
            </Grid>
            <Grid item={true} lg={6} md={12}>
              <FormControl className={classes.field} fullWidth={true}>
                <InputLabel htmlFor="timezone">Timezone</InputLabel>
                <Field
                  autoWidth={true}
                  component={DOReduxFormSelect}
                  disabled={disabled}
                  id="timezone"
                  name="timezone"
                >
                  {timezones.map(timezone => (
                    <MenuItem key={timezone.value} value={timezone.value}>
                      {timezone.text}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Address 2"
                name="address2"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="State"
                name="state"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Zip Code"
                name="postCode"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Terms of Service URL"
                name="config.do.terms_of_service_url"
                type="url"
              />
              <Field
                className={classes.field}
                component={TextField}
                disabled={disabled}
                fullWidth={true}
                label="Routing Number"
                name="routingNumber"
              />
            </Grid>
            <Grid item={true} lg={6} md={12}>
              <div className={classes.technicalContactContainer}>
                <FormControl className={classes.technicalContactSelect}>
                  <InputLabel htmlFor="technicalContact">Technical Contact</InputLabel>
                  <Field
                    component={DOReduxFormSelect}
                    disabled={disabled || (!isLoading && didError)}
                    id="technicalContact"
                    name="technicalContactId"
                  >
                    {isLoading ? (
                      <MenuItem>Loading...</MenuItem>
                    ) : (
                      users.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          {fullName(user)}
                        </MenuItem>
                      ))
                    )}
                  </Field>
                </FormControl>
                <Button
                  color="primary"
                  component={Link}
                  disabled={disabled}
                  size="small"
                  to="/admin/team/invite-user"
                  variant="contained"
                >
                  <AddIcon /> Add Member
                </Button>
              </div>
              {didError ? (
                <Typography className={classes.errorText} gutterBottom={true} variant="caption">
                  There was a problem loading users for your team. Please try again later.
                </Typography>
              ) : (
                <FormHelperText>
                  Your team's technical contact will be notified of issues encountered when sending
                  emails.
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <div className={classes.formActions}>
            <SaveButton disabled={disabled} submitting={submitting} />
          </div>
        </form>
      </Box>
      <div className={classes.spacer} />
      <Typography variant="h5">Access</Typography>
      <Box>
        <IpRestrictionSettings />
      </Box>
    </>
  )
}

GeneralSettings.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
}

const styles = theme => ({
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
  technicalContactContainer: {
    display: "flex",
  },
  technicalContactSelect: {
    flex: 1,
    marginRight: theme.spacing(3),
  },
  errorText: {
    marginTop: 10,
    color: theme.palette.error.main,
  },
  spacer: {
    height: theme.spacing(4),
  },
})

const mapStateToProps = ({session}) => ({initialValues: session.team})
const addActionsToProps = {onSubmit: updateTeamSettings}

export default connect(
  mapStateToProps,
  addActionsToProps
)(
  reduxForm({form: "generalSettings"})(
    withStyles(styles)(
      controlAccess({requiredPermissions: "team_settings:general"})(GeneralSettings)
    )
  )
)
