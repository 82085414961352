// This file was generated automatically from character-18.svg with the following command:
// ./svg-converter.js src/components/superhero/character-18.svg
import SuperheroSvg from "./superhero-svg"

const Character18 = props => (
  <SuperheroSvg viewBox="230 70 135 220" {...props}>
    <g id="Layer_17">
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M313,282.27l-0.04-0.01c-0.08-0.03-1.91-0.75-1.93-2.43c-0.02-1.26,0.95-2.57,2.94-3.99l0.06-0.03
  				c0.02-0.01,2.28-1.09,4.63-2.7c3.04-2.08,4.84-4.09,5.22-5.81l0.68,0.13c-1.02,4.63-9.64,8.84-10.32,9.17
  				c-2.08,1.49-2.51,2.52-2.51,3.12c0.01,0.9,1.03,1.51,1.46,1.69c11.7,0.74,21.25-3.11,22.31-3.55c1.24-1.86-0.6-7.24-1.43-9.19
  				l0.63-0.47c0.33,0.78,3.2,7.72,1.21,10.3l-0.07,0.09l-0.09,0.04c-0.1,0.04-10.22,4.45-22.74,3.64L313,282.27z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M316.74,282.22l-0.49-0.56c0.75-0.98,1.12-1.97,1.09-2.95c-0.06-1.83-1.49-3.07-1.5-3.08
  				l0.44-0.74c0.07,0.06,1.69,1.45,1.77,3.67C318.09,279.81,317.65,281.03,316.74,282.22z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M317.68,279.5l0.02-0.86c14.97-1.72,18.27-4.32,18.31-4.35l0.35,0.7
  				C336.23,275.09,332.98,277.74,317.68,279.5z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M317.48,278.31l-0.18-0.81c10.58-5,10.62-9.93,10.62-9.98l0.71-0.14
  				C328.63,267.62,328.7,273.01,317.48,278.31z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M318.8,275.91c-0.33-0.09-0.63-0.23-0.77-0.3l0.33-0.81c0.5,0.25,1.13,0.39,1.28,0.29
  				c0-0.01,0-0.02,0-0.04c0.01-0.59,0.02-1.1,0.45-1.4c0.3-0.22,0.75-0.27,1.74-0.38c0.7-0.08,1.21-0.13,1.33-0.3
  				c0.13-0.16,0.14-0.78,0.16-1.64c0.02-0.72,0.2-1.19,0.55-1.4c0.69-0.42,1.6,0.53,1.86,0.83l-0.51,0.67
  				c-0.43-0.49-0.96-0.81-1.1-0.73c0,0-0.09,0.1-0.1,0.56c-0.05,2.45-0.22,2.66-2.22,2.89c-0.71,0.08-1.32,0.15-1.43,0.26
  				c-0.02,0.06-0.03,0.33-0.04,0.58c-0.01,0.34-0.13,0.63-0.34,0.81C319.69,276.05,319.22,276.01,318.8,275.91z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M278.44,283.62l-0.04-0.01c-0.08-0.03-1.91-0.73-1.96-2.4c-0.03-1.26,0.91-2.58,2.89-4.03
  				l0.06-0.03c0.02-0.01,2.27-1.12,4.6-2.76c3.01-2.12,4.79-4.15,5.15-5.88l0.68,0.13c-0.96,4.64-9.53,8.97-10.2,9.3
  				c-2.06,1.52-2.48,2.55-2.47,3.15c0.02,0.9,1.04,1.5,1.48,1.67c11.71,0.59,21.21-3.37,22.26-3.83c1.22-1.88-0.69-7.23-1.54-9.17
  				l0.63-0.48c0.34,0.78,3.3,7.68,1.34,10.29l-0.07,0.09l-0.09,0.04c-0.1,0.05-10.16,4.57-22.69,3.93L278.44,283.62z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M282.18,283.51l-0.49-0.55c0.74-0.99,1.1-1.99,1.05-2.96c-0.08-1.83-1.53-3.05-1.54-3.06
  				l0.44-0.74c0.07,0.06,1.71,1.43,1.81,3.65C283.5,281.09,283.07,282.32,282.18,283.51z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M283.09,280.78l0.01-0.87c14.95-1.91,18.22-4.55,18.25-4.58l0.36,0.69
  				C301.58,276.14,298.36,278.83,283.09,280.78z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M282.87,279.6l-0.19-0.8c10.52-5.14,10.5-10.06,10.49-10.11l0.7-0.14
  				C293.89,268.76,294.02,274.15,282.87,279.6z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M284.16,277.18c-0.34-0.08-0.63-0.22-0.78-0.29l0.32-0.81c0.5,0.25,1.13,0.38,1.29,0.27
  				c0-0.01,0-0.02,0-0.04c0.01-0.59,0.01-1.1,0.43-1.41c0.3-0.22,0.75-0.28,1.74-0.4c0.7-0.09,1.21-0.15,1.33-0.32
  				s0.13-0.79,0.14-1.65c0.01-0.72,0.19-1.19,0.53-1.41c0.68-0.43,1.61,0.51,1.87,0.81l-0.5,0.67c-0.44-0.49-0.97-0.8-1.11-0.72
  				c0,0-0.09,0.1-0.09,0.56c-0.02,2.45-0.19,2.67-2.18,2.91c-0.71,0.09-1.32,0.16-1.43,0.27c-0.02,0.06-0.03,0.33-0.03,0.58
  				c0,0.34-0.12,0.63-0.33,0.81C285.05,277.3,284.58,277.28,284.16,277.18z"
          />
        </g>
      </g>
      <path
        style={{fill: "#8C715E", stroke: "#8C715E", strokeWidth: "3.2", strokeMiterlimit: "10"}}
        d="M260.71,173.4
  		c0.48-1.25,3.31-8.64,4.46-11.06c0.2-0.43,0.61-1.25,1.08-1.22c0.4,0.02,0.66,0.66,0.74,0.85c1.18,2.79,7.05,11.89,6.68,12.08
  		c-0.21,0.11-2.02-2.86-7.42-12.93"
      />
      <path
        style={{fill: "#DABCAB"}}
        d="M272.14,165.59c0,0-0.11-0.56-0.36-0.52c-0.25,0.04-0.8,0.64-0.6,1.58c0.13,0.62,0.18,3.26-1.83,4.2
  		c0,0-2.57,0.54-3.53-1.96c-0.72-1.88-0.89-3.51,0.4-6.04c1.2-2.36,2.6-3.18,3.17-3.84c1.24-2.58,2.47-5.16,3.71-7.74
  		c0.18-0.38,0.36-0.75,0.54-1.13l4.62,2.72c-1.26,2.64-2.52,5.27-3.78,7.91c-0.13,0.27-0.26,0.54-0.39,0.81
  		c-0.02,0.28-0.06,0.57-0.11,0.88c-0.19,1.06-0.53,1.94-0.87,2.63c-0.22,1.12-0.41,1.67-0.58,1.67
  		C272.37,166.77,272.25,166.38,272.14,165.59z"
      />

      <line
        style={{fill: "#8C715E", stroke: "#8C715E", strokeWidth: "3", strokeMiterlimit: "10"}}
        x1="272.09"
        y1="170.96"
        x2="266.97"
        y2="160.68"
      />
      <polygon
        style={{fill: "#0A273F"}}
        points="307.69,190.11 304.36,268.11 284.14,268.11 291.51,160.79 312.54,156.28 	"
      />
      <g>
        <defs>
          <rect
            id="SVGID_00000065791338857774300820000017165136578508996514_"
            x="149.36"
            y="61.79"
            width="207.45"
            height="158.95"
          />
        </defs>
        <clipPath id="98345485-SVGID_00000165210252226579553630000008634175505919092386_">
          <use
            xlinkHref="#SVGID_00000065791338857774300820000017165136578508996514_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <path
            style={{fill: "#3499CC"}}
            d="M330.3,125.78c0.74-5.39-3.04-10.35-8.43-11.09l-12.63-1.03c-6.84-0.42-10.11,2.2-11.09,8.43
  				l-3.25,19.62l32.76,0L330.3,125.78z"
          />
          <path
            style={{fill: "#123252"}}
            d="M322.88,146.81l-29.65,6.42l-0.88,3.82c-0.19,0.8-0.32,1.61-0.41,2.45l-0.22,1.32l0.14,0.02
  				c0,0.17-0.01,0.34-0.01,0.51c0,9.16,6.53,16.78,15.19,18.49l0.13,0.36c0.43,4.43,0.87,8.86,1.3,13.3
  				c1.14,11.62,2.27,23.23,3.41,34.85c0.26,2.62,0.51,5.23,0.77,7.85c4.89-27.11,9.77-54.21,14.66-81.32"
          />
          <polygon
            style={{fill: "#3499CC"}}
            points="328.31,141.71 294.9,141.71 293.23,153.23 327.32,154.87 			"
          />
          <path style={{fill: "#123252"}} d="M312.66,236.19c0,0-0.77-7.85-2.34-23.9" />
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#DABCAB",
          }}
          d="M316.9,77.11
  			c0.58,0,1.15,0.03,1.72,0.09c0.34,13.49,12.58,20.56,14.89,21.77c-2.02,7.28-8.69,12.62-16.61,12.62
  			c-9.52,0-17.24-7.72-17.24-17.24S307.38,77.11,316.9,77.11z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#DABCAB",
          }}
          d="M299.66,94.3
  			c0,7.66,5.38,14.15,12.29,16.41l0,5.16l6.36,0l0-4.37c4.78-0.38,9.01-2.71,11.9-6.2c0.62,0.44,1.37,0.7,2.18,0.7
  			c2.08,0,3.77-1.69,3.77-3.77c0-1.65-1.07-3.05-2.55-3.56c0-0.02,0.01-0.04,0.01-0.06c-0.12-0.06-0.24-0.13-0.36-0.2l-33.4-6.8
  			C299.74,92.49,299.66,93.39,299.66,94.3z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#DABCAB",
          }}
          d="M325.89,89.9
  			l-22.92-4.67c-1.23,1.94-2.08,4.14-2.45,6.5l33.06,6.73C330.11,96.6,327.37,93.57,325.89,89.9z"
        />

        <circle
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#0D1E36",
          }}
          cx="309.53"
          cy="90.46"
          r="1.37"
        />
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M312.83,85.45c-0.27-0.38-0.64-0.7-1.06-0.91c-0.9-0.46-1.93-0.48-2.88-0.15
  					c-0.44,0.15-0.78,0.62-0.63,1.11c0.14,0.44,0.63,0.79,1.11,0.63c0.23-0.08,0.47-0.14,0.72-0.17c-0.08,0.01-0.16,0.02-0.24,0.03
  					c0.24-0.03,0.48-0.03,0.72,0c-0.08-0.01-0.16-0.02-0.24-0.03c0.21,0.03,0.41,0.08,0.6,0.16c-0.07-0.03-0.14-0.06-0.21-0.09
  					c0.18,0.08,0.36,0.18,0.52,0.3c-0.06-0.05-0.12-0.09-0.18-0.14c0.13,0.1,0.25,0.21,0.36,0.34c-0.05-0.06-0.09-0.12-0.14-0.18
  					c0,0.01,0.01,0.01,0.01,0.02c0.14,0.19,0.3,0.35,0.54,0.41c0.22,0.06,0.5,0.03,0.69-0.09
  					C312.88,86.44,313.12,85.86,312.83,85.45L312.83,85.45z"
            />
          </g>
        </g>
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <polyline style={{fill: "#DABCAB"}} points="314.86,90.3 312.74,95.37 315.05,95.78 			" />
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M314.43,90.18c-0.62,1.48-1.24,2.95-1.86,4.43c-0.09,0.21-0.18,0.42-0.27,0.64
  					c-0.09,0.21,0.1,0.51,0.31,0.55c0.77,0.14,1.54,0.28,2.31,0.42c0.24,0.04,0.48-0.07,0.55-0.31c0.06-0.22-0.07-0.51-0.31-0.55
  					c-0.77-0.14-1.54-0.28-2.31-0.42c0.1,0.18,0.21,0.37,0.31,0.55c0.62-1.48,1.24-2.95,1.86-4.43c0.09-0.21,0.18-0.42,0.27-0.64
  					c0.09-0.22-0.1-0.49-0.31-0.55C314.72,89.8,314.52,89.96,314.43,90.18L314.43,90.18z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#DABCAB",
          }}
          d="M319.28,77.19
  			c8.71,0.86,15.52,8.21,15.52,17.15c0,1.6-0.22,3.15-0.63,4.62C331.86,97.75,319.62,90.69,319.28,77.19z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#FFFFFF",
          }}
          d="M314.62,102.65
  			c0,1.08,0.88,1.96,1.96,1.96c1.08,0,1.96-0.88,1.96-1.96l0-2.06c-0.02,0.02-0.05,0.03-0.07,0.05c-1.57,1.12-3.86,0.73-3.86,0.73
  			L314.62,102.65z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#DABCAB",
          }}
          d="M319.3,77.3
  			c0,0-4.77,5.34-14.39,5.34C304.91,82.64,310.39,76.14,319.3,77.3z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#DABCAB",
          }}
          d="M326.1,142.09
  			c0,0,0.71,0.16,0.64,0.48c-0.07,0.32-0.86,1-2.06,0.71c-0.79-0.19-4.15-0.34-5.47,2.2c0,0-0.84,3.26,2.29,4.57
  			c2.35,0.98,4.42,1.26,7.72-0.3c3.08-1.46,4.21-3.21,5.08-3.91c3.37-1.49,6.73-2.98,10.1-4.47c0.49-0.22,0.98-0.43,1.47-0.65
  			l-2.82-5.18c-3.44,1.52-6.72,2.74-10.16,4.26c-0.35,0.16-0.66,0.33-1.59-0.04c-1-0.5-3.39-1.56-5.36-1.35c0,0-0.95-0.03-1.94,0.68
  			C323,139.79,324.29,141.76,326.1,142.09z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#3499CC",
          }}
          d="M327.46,142.48
  			l0.8-5.09c-1.16-0.39-2.77,0.85-4.05,1.03c0,0-0.95,0-1.91,0.75c-0.96,0.75,0.41,2.67,2.22,2.92c0,0,0.71,0.13,0.65,0.46
  			l-1.27,0.46c0,0,2.18,0.7,3.23-0.21"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#60A4D9",
          }}
          d="M236.47,138.99
  			c-0.01,0.01-0.03,0-0.05,0c0-0.04,0-0.08,0.01-0.11c0.01,0,0.02,0,0.03,0C236.49,138.91,236.51,138.95,236.47,138.99z"
        />
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <path
            style={{fill: "#123252"}}
            d="M319.52,85.1c-2.25-5.5-7.34-6.1-10.78-5.8c2.5-1.39,5.38-2.19,8.44-2.19
  				c8.87,0,16.19,6.64,17.26,15.23l-12.67-2.01C320.16,87.54,319.52,85.1,319.52,85.1z"
          />
          <path
            style={{fill: "#123252"}}
            d="M334.06,98.54c-0.68,0-1.34-0.06-1.97-0.16l-0.02,0c-5.15-0.82-8.41-4.69-10.32-8.04l12.64,2.01
  				c0.09,0.71,0.14,1.43,0.14,2.17C334.54,95.9,334.37,97.25,334.06,98.54z"
          />
          <g>
            <path
              style={{fill: "#123252"}}
              d="M330.59,105.26c-3.19,4-7.89,6.64-13.41,6.64c-7.58,0-14.02-4.85-16.41-11.61
  					c0.92,0.82,4.42,3.6,7.15,1.26c2.58-2.21,5.54-2.58,8.12-2.21c2.19,0.31,6.27,0.74,8.86,4.8c0,0,1.48,2.58,5.54-1.11
  					c1.7-1.54,2.14-3.08,2.92-4.49l0.71,0C333.3,101.06,332.01,103.25,330.59,105.26"
            />
          </g>
          <path
            style={{fill: "#FFFFFF"}}
            d="M319.17,103.48c-0.09-0.16-0.31-0.19-0.46-0.09c-0.36,0.25-1.02,0.79-1.95,0.98
  				c-1.27,0.25-2.38-0.26-2.64,0.07c-0.15,0.19,0.03,0.41,0.38,0.7c0.35,0.29,1.41,0.86,2.57,0.64c1.23-0.23,2.15-1.65,2.15-2.15
  				C319.21,103.57,319.2,103.53,319.17,103.48z"
          />
        </g>

        <circle
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#0D1E36",
          }}
          cx="321.31"
          cy="92.42"
          r="1.37"
        />
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M321.21,88.42c0.12-0.04,0.26-0.06,0.39-0.08c-0.08,0.01-0.16,0.02-0.24,0.03
  					c0.26-0.03,0.52-0.04,0.78,0c-0.08-0.01-0.16-0.02-0.24-0.03c0.23,0.03,0.45,0.09,0.67,0.18c-0.07-0.03-0.14-0.06-0.21-0.09
  					c0.19,0.08,0.37,0.19,0.54,0.31c0.08,0.06-0.16-0.13-0.09-0.06c0.02,0.02,0.04,0.04,0.06,0.05c0.04,0.04,0.09,0.08,0.13,0.12
  					c0.04,0.04,0.08,0.09,0.12,0.13c0.05,0.05,0.2,0.25,0.02,0.02c0.15,0.19,0.29,0.35,0.54,0.41c0.22,0.06,0.5,0.03,0.69-0.09
  					c0.19-0.12,0.36-0.31,0.41-0.54c0.05-0.22,0.05-0.5-0.09-0.69c-0.35-0.46-0.75-0.87-1.27-1.13c-0.44-0.22-0.88-0.34-1.38-0.39
  					c-0.43-0.04-0.91-0.01-1.31,0.13c-0.45,0.15-0.78,0.63-0.63,1.11C320.25,88.24,320.73,88.58,321.21,88.42L321.21,88.42z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)",
            fill: "#60A4D9",
          }}
          d="M239.67,138.12
  			c0.01,0.01,0.03,0,0.05,0c0-0.04,0-0.08-0.01-0.11c-0.01,0-0.02,0-0.03,0C239.65,138.04,239.64,138.08,239.67,138.12z"
        />
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <path
            style={{fill: "#3499CC"}}
            d="M338.17,144.06c3.63-1.53,7.26-3.06,10.89-4.58c0.52-0.22,1.04-0.44,1.57-0.66
  				c0.52-0.22,1.02-0.42,1.43-0.83c0.51-0.51,0.8-1.16,0.89-1.86c0.18-1.32-0.44-2.5-1.55-3.23c-2.61-1.71-5.23-3.42-7.84-5.13
  				c-4.14-2.7-8.27-5.41-12.41-8.11c-0.95-0.62-5.18-3.17-6.13-3.79c-1.42-0.93-0.36,1.6-1.15,3.09c-0.85,1.62-0.35,3.44,1.16,4.43
  				l0,0c2.61,1.71,5.23,3.42,7.84,5.13c4.14,2.7,8.27,5.41,12.41,8.11c0.95,0.62,1.9,1.24,2.85,1.86c0.26-1.97,0.52-3.95,0.77-5.92
  				c-3.63,1.53-7.26,3.06-10.89,4.58c-0.52,0.22-1.04,0.44-1.57,0.66"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <path
            style={{fill: "#3499CC"}}
            d="M302.8,114.48c-2.38,1.72-4.75,3.44-7.13,5.17c-1.65,1.2-3.31,2.4-4.96,3.6
  				c-0.79,0.57-1.64,1.11-2.39,1.73c-0.68,0.57-1.14,1.46-1.62,2.2c-0.86,1.33-1.72,2.67-2.58,4c-2.35,3.64-4.69,7.27-7.04,10.91
  				c-1.48,2.3-2.99,4.59-4.46,6.9c0,0-0.02,0.03-0.07,0.11c-1.11,1.72-1.66,2.59-1.53,3c0.07,0.21,0.54,0.46,1.47,0.95
  				c0.5,0.26,0.93,0.46,1.68,0.81c0.43,0.2,1.01,0.46,1.72,0.77c0.57,0.27,1.13,0.54,1.7,0.81c1.71-2.65,4.73-6.75,6.44-9.39
  				c2.7-4.18,5.4-8.37,8.1-12.55c0.62-0.96,1.24-1.92,1.86-2.88c-0.22,0.25-0.45,0.49-0.67,0.74c1.63-1.18,3.26-2.36,4.89-3.54
  				c2.6-1.88,5.2-3.77,7.8-5.65c0.3-0.22,0.51-0.4,0.72-0.57c0.02-0.02,0.7-0.55,0.72-0.57"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000165210252226579553630000008634175505919092386_)"}}>
          <polygon
            style={{fill: "#F5F6F5"}}
            points="309.51,114.9 312.84,134.8 315.07,134.21 320.26,115.76 			"
          />
          <polygon style={{fill: "#FFFFFF"}} points="314.54,114.92 319.08,117.43 319.91,113.15 			" />
          <polygon style={{fill: "#FFFFFF"}} points="314.88,114.84 310.46,116.76 310.08,112.72 			" />
          <polygon
            style={{fill: "#EB6669"}}
            points="312.09,115.99 314.56,118.57 317.03,116.3 314.59,114.92 			"
          />
          <polygon
            style={{fill: "#EB6669"}}
            points="314.56,118.57 311.27,126.26 312.43,134.39 313.24,135.2 314.46,134.81 316.95,126.3
  				"
          />
          <path
            style={{fill: "#2F8DB2"}}
            d="M322.2,114.69c0,0,1.34,3.77,0.68,6.97l-4.2,1.71l2.99,3.49c0,0-0.94,6.85-8.38,8.31
  				c0,0,4.05-10.22,6.12-21.06L322.2,114.69z"
          />
          <path
            style={{fill: "#2F8DB2"}}
            d="M308.03,113.05c0,0-1.98,4.1-1.83,7.37l3.89,2.34l-3.49,2.99c0,0-0.49,6.87,6.63,9.46
  				c0,0-2.54-10.79-2.9-21.81L308.03,113.05z"
          />
        </g>
      </g>
      <path
        style={{fill: "#123252"}}
        d="M340,267.89c-1.77-12.42-3.55-24.84-5.32-37.26c-2.45-25.26-4.91-50.51-7.36-75.77
  		c-5.39,21.96-10.78,43.92-16.17,65.87c1.7,15.85,3.41,31.71,5.11,47.56"
      />

      <rect
        x="241.72"
        y="171.48"
        transform="matrix(0.9992 0.039 -0.039 0.9992 7.5888 -10.2561)"
        style={{fill: "#8C715E"}}
        width="50.32"
        height="36.11"
      />
      <polygon
        style={{display: "none", fill: "#123252"}}
        points="311.15,220.74 311.15,228.67 315.66,220.1 	"
      />
      <path
        style={{fill: "#DABCAB"}}
        d="M270.55,168.3c-0.33-0.1-0.49-0.71-0.76-1.79c-0.21-0.85-0.37-1.52-0.24-2.31
  		c0.31-1.92,1.88-2.93,1.88-2.93c0.2-0.07,0.48-0.14,0.81-0.17c0.17-0.02,1.25-0.11,1.59,0.25
  		C274.78,162.33,271.74,168.67,270.55,168.3z"
      />
    </g>
  </SuperheroSvg>
)

export default Character18
