import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {PureComponent} from "react"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"

// Include embedly to allow certain iframes to be rendered
// https://trello.com/c/fHtcDy91
require("../../../../lib/load-embedly")

export class WebpageEmbedReadonly extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {url: null}
  }

  embed = el => el && window.embedly("card", el)

  render() {
    const {contentBlock, classes, className, customCss, isEditMode, onClick, style} = this.props
    const {
      data: {service, url},
    } = contentBlock

    return (
      <div
        className={cx(className, classes.root, {[classes.emptyBackground]: isEditMode && !url})}
        onClick={onClick}
        data-testid="webpage-root"
        style={style}
      >
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        {service === "iframe" && (
          <>
            <style>{customCss}</style>
            <iframe
              height={650}
              src={url}
              style={{pointerEvents: isEditMode ? "none" : "auto"}}
              title={url}
              width="100%"
              data-testid="iframe-root"
            />
          </>
        )}
        {service === "embedly" && (
          <>
            <style>{customCss}</style>
            <div style={{pointerEvents: isEditMode ? "none" : "auto"}} data-testid="embedly-root">
              <a
                data-card-controls="0"
                data-card-key={process.env.REACT_APP_EMBEDLY_APP_ID}
                data-card-width="100%"
                href={url}
                ref={this.embed}
              >
                {url}
              </a>
            </div>
          </>
        )}
      </div>
    )
  }
}

WebpageEmbedReadonly.propTypes = {
  classes: object,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  className: string,
  isEditMode: bool,
  onClick: func,
  style: object,
}

const styles = theme => ({
  emptyBackground: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50' width='120'><text x='60' y='25' fill='${theme.palette.text.hint}' font-size='20' font-family='sans-serif' text-anchor='middle' alignment-baseline='central'>WEBPAGE</text></svg>")`,
    height: 75,
    border: `1px dashed ${theme.palette.text.hint}`,
  },
})

export default withStyles(styles)(contentBlockEditorConsumer(WebpageEmbedReadonly))
