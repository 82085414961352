import {arrayOf, shape, string} from "prop-types"

import EmailMessage from "./email-message"
import MessageOverview from "./message-overview"
import SmsMessage from "./sms-message"

const MessageTimeline = ({messages, variant}) => (
  <>
    {messages.map((message, index) => {
      return (
        <div key={`${message.id}-${index}-message`}>
          {message.type === "email" && <EmailMessage message={message} />}
          {message.type === "sms" && <SmsMessage message={message} />}
          <MessageOverview message={message} variant={variant} />
        </div>
      )
    })}
  </>
)

MessageTimeline.propTypes = {
  messages: arrayOf(shape({id: string.isRequired})),
  variant: string.isRequired,
}

export default MessageTimeline
