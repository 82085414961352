import {Box, Button, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, string} from "prop-types"
import {useState} from "react"

import {TemplateListPreview} from "../../dialogs/template-list-preview"
import Feature from "../../feature/feature"

const useStyles = makeStyles(theme => ({
  sharedNotice: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    marginTop: 4,
    textAlign: "center",
    textTransform: "uppercase",
  },
  skeleton: {
    maxWidth: "65%",
  },
  skeletonRoot: {
    marginBottom: theme.spacing(2),
  },
  tooltipBody: {
    marginBottom: theme.spacing(1),
  },
  tooltipPaper: {
    padding: theme.spacing(2),
  },
}))

const SharedPageNotice = ({editingTemplateId, onShowDuplicateConfirmation, page}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const classes = useStyles()

  const onDuplicate = () => {
    onShowDuplicateConfirmation()
    setTooltipOpen(false)
  }

  return (
    <>
      <Tooltip
        classes={{tooltip: classes.tooltipPaper}}
        className={classes.tooltip}
        leaveDelay={750}
        onClose={() => setTooltipOpen(false)}
        onOpen={() => setTooltipOpen(true)}
        open={tooltipOpen}
        title={
          <>
            <Typography className={classes.tooltipBody} variant="body2">
              You are editing a Shared Page. Any edits you make here will be reflected on all
              campaigns that share this page.
            </Typography>
            <TemplateListPreview
              editingTemplateId={editingTemplateId}
              id={page.id}
              newSlug={page.newSlug}
              recordType="page"
              variant="body2"
            />
            <Feature featureKey="atomic-assets">
              {!!onShowDuplicateConfirmation && (
                <Button fullWidth={true} onClick={onDuplicate} size="small" variant="contained">
                  Make a Unique Copy
                </Button>
              )}
            </Feature>
          </>
        }
      >
        <Box className={classes.sharedNotice}>Shared Page</Box>
      </Tooltip>
    </>
  )
}

SharedPageNotice.propTypes = {
  editingTemplateId: string,
  onShowDuplicateConfirmation: func.isRequired,
  page: object.isRequired,
}

export default SharedPageNotice
