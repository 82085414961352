import {toCamelCase} from "lib/case-converter"

// Split the parts on dots, then camelCase the parts, then join.
// In the case of a name with no dots, this will be a noop.
// This "ensures" compatability with nested forms from Phoenix.
const toDottedCamelCase = name =>
  name
    .split(".")
    .map(part => toCamelCase(part))
    .join(".")

export default class SubmissionError extends Error {
  constructor(errors) {
    super()

    if (Error.captureStackTrace) Error.captureStackTrace(this, SubmissionError)

    this.name = "SubmissionError"
    this.errors = Object.keys(errors).reduce((acc, _name) => {
      const name = toDottedCamelCase(_name)
      //eslint-disable-next-line no-param-reassign
      acc[name] = {
        helperText: errors[name],
        error: !!errors[name].length,
      }

      return acc
    }, {})
  }
}
