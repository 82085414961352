import {Container, Paper, Typography} from "@mui/material"
import Box from "@mui/material/Box"
import {ThemeProvider} from "@mui/material/styles"
import {isBefore, parseISO} from "date-fns"
import memoize from "memoize-one"
import {useEffect, useMemo, useState} from "react"
import {useDispatch} from "react-redux"
import {useParams} from "react-router-dom"

import {setCurrentTeam} from "actions/session-actions"

import {fetchPackageData, submitApprovalVote} from "lib/api"
import {compileCss} from "lib/custom-css"
import createTeamTheme from "themes/team-theme-creator"

import ApproveForm from "./approve-form"
import CampaignCoverPage from "./campaign-cover-page"
import CampaignOverview from "./campaign-overview"
import CampaignPagesSection from "./campaign-pages-section"
import CtaSummary from "./cta-summary"
import DisapproveForm from "./disapprove-form"
import DripTimelineOverview from "./drip-timeline-overview"
import ExpiredForm from "./expired-form"
import MessageTimeline from "./message-timeline"
import ScheduledTimelineOverview from "./scheduled-timeline-overview"
import TargetingConditionsAndImpact from "./targeting-conditions-and-impact"

const styles = {
  approvalSpacing: theme => ({
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
  }),
  approvalForm: {
    display: "flex",
    margin: "0 auto",
    "& > *": {
      flex: 1,
      padding: 2,
    },
    "& h4": {
      fontWeight: "bold",
    },
    "& form": {
      width: "100%",
    },
  },
}

export const createCompiledCustomCss = memoize(source => {
  try {
    return compileCss("#root", source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const CampaignPackage = () => {
  const apiUrl = process.env.REACT_APP_API_URL

  const [packageData, setPackageData] = useState(null)
  const [theme, setTheme] = useState({})
  const {token} = useParams()
  const dispatch = useDispatch()
  const templateCustomCss = createCompiledCustomCss(packageData?.template?.customCss)

  // Map messages by the overview order and insert scheduledAt
  const compileScheduledTimelineMessages = (overview, messages) => {
    return overview.map(messageOverview => {
      const message = messages.find(message => message.id === messageOverview.messageId)

      return {...message, scheduledAt: messageOverview.scheduledAt}
    })
  }

  const isApprovalExpired = useMemo(() => isBefore(parseISO(packageData?.expiresAt), new Date()), [
    packageData,
  ])

  const sendApproval = data => {
    submitApprovalVote(packageData.approvalId, data).then(({response}) =>
      setPackageData({...packageData, approvalResponse: response})
    )
  }

  const hasVoted = useMemo(() => !!packageData?.approvalResponse, [packageData])

  useEffect(() => {
    fetchPackageData(token)
      .then(data => {
        setPackageData(data)
        dispatch(setCurrentTeam(data.team))
      })
      .catch(() => setPackageData(false))
  }, [dispatch, setPackageData, token])

  useEffect(() => {
    const teamTheme = packageData?.team.themeStyles
    const templateTheme = packageData?.template.theme

    setTheme(createTeamTheme(templateTheme || teamTheme))
  }, [packageData, setTheme])

  if (packageData === null) {
    return <p>Loading ...</p>
  }

  return (
    <Container>
      <style>{templateCustomCss}</style>
      <CampaignCoverPage
        apiUrl={apiUrl}
        campaignName={packageData.template.name}
        teamId={packageData.team.id}
      />
      {isApprovalExpired ? (
        <Paper sx={styles.approvalSpacing}>
          <ExpiredForm approvalId={packageData.approvalId} />
        </Paper>
      ) : (
        <>
          <Paper sx={styles.approvalSpacing}>
            <Typography gutterBottom variant="h6">
              {hasVoted
                ? "Thank you for submitting your response"
                : `${packageData.template.name} is ready for review`}
            </Typography>
            {!hasVoted && (
              <Box sx={styles.approvalForm}>
                <ApproveForm onSubmit={sendApproval} />
                <DisapproveForm onSubmit={sendApproval} />
              </Box>
            )}
          </Paper>
          <CampaignOverview
            approvalMessage={packageData.approvalMessage}
            dripTimeline={packageData.dripTimeline}
            scheduledTimeline={packageData.scheduledTimeline}
            template={packageData.template}
            templatePages={packageData.templatePages}
          />
          <CampaignPagesSection
            template={packageData.template}
            templatePages={packageData.templatePages}
            theme={theme}
          />
          <DripTimelineOverview overview={packageData.dripTimeline.overview} />
          <ThemeProvider theme={theme}>
            <MessageTimeline messages={packageData.dripTimeline.messages} variant="drip" />
          </ThemeProvider>
          <ScheduledTimelineOverview overview={packageData.scheduledTimeline.overview} />
          <ThemeProvider theme={theme}>
            <MessageTimeline
              messages={compileScheduledTimelineMessages(
                packageData.scheduledTimeline.overview,
                packageData.scheduledTimeline.messages
              )}
              variant="scheduled"
            />
          </ThemeProvider>
          <TargetingConditionsAndImpact
            template={packageData.template}
            team={packageData.team}
            targetingImpact={packageData.targetingImpact}
          />
          <CtaSummary ctas={packageData.ctas} />
        </>
      )}
    </Container>
  )
}

export default CampaignPackage
