// This file was generated automatically from builder-5.svg with the following command:
// ./svg-converter.js src/components/superhero/builder-5.svg
import SuperheroSvg from "./superhero-svg"

const Builder5 = props => (
  <SuperheroSvg viewBox="0 0 989 800" {...props}>
    <defs>
      <clipPath id="1fad4a2e-clip-path">
        <rect height="800" style={{fill: "none"}} width="680" x="936" y="-820" />
      </clipPath>
      <clipPath id="1fad4a2e-clip-path-2">
        <rect height="800" style={{fill: "none"}} width="799" x="-3" y="-820" />
      </clipPath>
      <clipPath id="1fad4a2e-clip-path-3">
        <rect height="800" style={{fill: "none"}} width="992" x="-3" />
      </clipPath>
    </defs>
    <g style={{clipPath: "url(#1fad4a2e-clip-path-2)"}}>
      <g>
        <polygon
          points="436.29 117.12 406.34 354.37 209.6 354.37 262.72 -33.33 488.37 -56.47 436.29 117.12"
          style={{fill: "#5f9e92"}}
        />
        <polygon
          points="470.34 -436.02 612.49 -411.76 761.57 278.18 505.01 278.18 470.34 -436.02"
          style={{fill: "#0f2c48"}}
        />
        <path
          d="M594.94-8.12q.21-4.17.21-8.39A166,166,0,0,0,536-143.72l.18-1.07H281.7l-15,90.37a166.77,166.77,0,0,0-3.58,21.59l-1.93,11.66,1.24.16c0,1.5-.11,3-.11,4.5A166.45,166.45,0,0,0,396.37,146.73l1.16,3.17,31.2,204.56h215Z"
          style={{fill: "#5cbdaf"}}
        />
      </g>
    </g>
    <g>
      <g style={{clipPath: "url(#1fad4a2e-clip-path-3)"}}>
        <g>
          <g>
            <polygon
              points="485.29 937.12 455.34 1174.37 258.6 1174.37 311.72 786.67 537.37 763.53 485.29 937.12"
              style={{fill: "#5f9e92"}}
            />
            <path
              d="M405.89,392.87l-19.12-1.27-45.6-3L286,384.9l-47.89-3.18c-7.71-.51-15.42-1.08-23.13-1.54l-.32,0,24.7,14.17-2.59-5.41-7-14.62L219.5,352.69l-12.66-26.48L193,297.32,179,268l-13-27.19-11-23.08-7.9-16.52c-1.25-2.61-2.48-5.24-3.75-7.84-.05-.12-.11-.23-.16-.35a21.71,21.71,0,0,0-7.37-8,21.43,21.43,0,0,0-9.73-5.12,21.68,21.68,0,0,0-11.4-.51,21.29,21.29,0,0,0-10.64,3.39l-5.79,4.47a28.63,28.63,0,0,0-7.35,12.63l-1,7.6a28.4,28.4,0,0,0,3.9,14.44l2.59,5.4,7,14.62,10.33,21.61,12.66,26.49,13.81,28.88,14,29.29,13,27.19,11,23.07,7.9,16.52c1.25,2.62,2.47,5.24,3.75,7.85l.16.34a28.61,28.61,0,0,0,24.7,14.17l19.12,1.27,45.6,3,55.13,3.66,47.89,3.19c7.71.51,15.41,1.09,23.13,1.53l.32,0a31.85,31.85,0,0,0,20.23-8.37,31.85,31.85,0,0,0,8.37-20.23l-1-7.6a28.6,28.6,0,0,0-7.35-12.62l-5.79-4.48a28.5,28.5,0,0,0-14.44-3.9Z"
              style={{fill: "#b5927e"}}
            />
            <polygon
              points="382.82 280.02 387.59 377.04 654.55 383.98 654.55 297.3 382.82 280.02"
              style={{fill: "#123252"}}
            />
            <path
              d="M579.07,101.62a148.42,148.42,0,0,1,4.29-23.24,150.8,150.8,0,0,0-147.1,4.81,138.05,138.05,0,0,0,86.55,30.36A138.25,138.25,0,0,0,579.07,101.62Z"
              style={{fill: "#123252"}}
            />
            <path
              d="M601.21,89.25a138.75,138.75,0,0,1-22.14,12.37,149.17,149.17,0,0,0,81.07,148.45A150.65,150.65,0,0,0,601.21,89.25Z"
              style={{fill: "#123252"}}
            />
            <g>
              <path
                d="M578.28,117a150.45,150.45,0,0,1,.79-15.39,138.25,138.25,0,0,1-56.26,11.93,138.05,138.05,0,0,1-86.55-30.36,151.71,151.71,0,0,0-49.42,48.38l202.32,41.2A148.28,148.28,0,0,1,578.28,117Z"
                style={{fill: "#b5927e"}}
              />
              <path
                d="M363.32,212.36a150.85,150.85,0,0,0,104,143.37V377h58.94v-14.4a150.51,150.51,0,0,0,104-54.13A32.91,32.91,0,1,0,660,250.57c0-.17.07-.34.11-.5-1-.53-2.06-1.15-3.1-1.71L365.21,188.93A150.76,150.76,0,0,0,363.32,212.36Z"
                style={{fill: "#b5927e"}}
              />
              <path
                d="M589.16,172.77l-202.32-41.2a149.7,149.7,0,0,0-21.63,57.36L657,248.36A149.56,149.56,0,0,1,589.16,172.77Z"
                style={{fill: "#ec6568"}}
              />
            </g>
            <polygon
              points="519.34 383.98 661.49 408.24 810.57 1098.18 554.01 1098.18 519.34 383.98"
              style={{fill: "#0f2c48"}}
            />
            <rect height="24.27" style={{fill: "#5cbdaf"}} width="58.94" x="467.33" y="377.04" />
            <g>
              <path
                d="M616,489.54a87,87,0,0,0-74.38-97.92l-76.76-9.07a87,87,0,0,0-97.92,74.38l-28.74,173.2H592.65Z"
                style={{fill: "#5cbdaf"}}
              />
              <path
                d="M643.94,811.88q.21-4.17.21-8.39A166,166,0,0,0,585,676.28l.18-1.07H330.7l-15,90.37a166.77,166.77,0,0,0-3.58,21.59l-1.93,11.66,1.24.16c0,1.5-.11,3-.11,4.5A166.45,166.45,0,0,0,445.37,966.73l1.16,3.17,31.2,204.56h215Z"
                style={{fill: "#5cbdaf"}}
              />
              <polygon
                points="592.65 630.13 338.18 630.13 330.7 675.21 585.17 675.21 592.65 630.13"
                style={{fill: "#0f2c48"}}
              />
            </g>
            <path
              d="M579.07,101.62a138.75,138.75,0,0,0,22.14-12.37,151.19,151.19,0,0,0-17.85-10.87A148.42,148.42,0,0,0,579.07,101.62Z"
              style={{fill: "#123252"}}
            />
            <circle cx="439.14" cy="173.05" r="14.05" style={{fill: "#0b1d37"}} />
            <circle cx="546.53" cy="194.11" r="14.05" style={{fill: "#0b1d37"}} />
            <path
              d="M551.23,157.66a16.13,16.13,0,0,1,2.38-.56l.62-.1c.44-.09-.59.06.18,0,.45,0,.9-.1,1.36-.13a27.25,27.25,0,0,1,3.5,0l.87.07.51.06h.08c.67.11,1.33.24,2,.4a20.66,20.66,0,0,1,3.78,1.32,21.65,21.65,0,0,1,7.8,6.41,2.68,2.68,0,0,0,3.56.93,2.6,2.6,0,0,0,.93-3.55,26.55,26.55,0,0,0-14.55-10.09,28.83,28.83,0,0,0-12.16-.31,16.82,16.82,0,0,0-2.23.53c-3.16,1.05-1.81,6.07,1.38,5Z"
              style={{fill: "#0b1d37"}}
            />
            <path
              d="M465.84,136.2c-3.95-5.53-11-8.53-17.67-8.76a31,31,0,0,0-11,1.81,2.67,2.67,0,0,0-1.82,3.2,2.63,2.63,0,0,0,3.2,1.82,26.21,26.21,0,0,1,10.74-1.57,19.78,19.78,0,0,1,6.76,2,23.21,23.21,0,0,1,2.64,1.64c.6.42-.14-.13.19.14l.52.43c.26.23.53.46.78.71a16.57,16.57,0,0,1,1.16,1.21,2.08,2.08,0,0,0,.32.4c-.45-.28-.46-.62-.28-.36a2.67,2.67,0,0,0,3.56.93,2.62,2.62,0,0,0,.93-3.56Z"
              style={{fill: "#0b1d37"}}
            />
            <g>
              <path d="M472.53,212.36s17.34-13.87,27.74,6.93" style={{fill: "#b5927e"}} />
              <path
                d="M473.45,213.28c-.36.28,0,0,.22-.16.42-.29.85-.58,1.29-.84a22.65,22.65,0,0,1,4.29-2.08c4.2-1.49,8.74-1.51,12.61.91,3.3,2.07,5.55,5.42,7.29,8.84.75,1.49,3,.18,2.24-1.32-3-5.9-7.75-11.21-14.66-12a20.8,20.8,0,0,0-12.52,3.06,21.5,21.5,0,0,0-2.6,1.75c-1.3,1,.55,2.87,1.84,1.84Z"
                style={{fill: "#0b1d37"}}
              />
            </g>
            <path
              d="M581.08,463.56l4.55,1.44,12.31,3.88,18.29,5.76,22.32,7,24.46,7.7,24.57,7.75,23,7.25,19.45,6.13L744,514.9c2.22.7,4.42,1.41,6.64,2.09l.29.09a21.68,21.68,0,0,0,11.4.51,21.29,21.29,0,0,0,10.64-3.39l5.79-4.47a28.63,28.63,0,0,0,7.35-12.63l2.11-5.29,5.75-14.42,8.51-21.31,10.38-26,11.36-28.49,11.47-28.75,10.7-26.8,9-22.57,6.51-16.3c1-2.57,2.07-5.14,3.08-7.72,0-.12.09-.23.13-.34a21.68,21.68,0,0,0,.51-11.4,21.29,21.29,0,0,0-3.39-10.64,21.71,21.71,0,0,0-7.37-8,21.43,21.43,0,0,0-9.73-5.12l-7.6-1a28.4,28.4,0,0,0-14.44,3.9l-5.79,4.47a28.63,28.63,0,0,0-7.35,12.63l-2.11,5.29-5.75,14.42-8.51,21.31-10.38,26-11.36,28.49-11.47,28.75L749.64,435l-9,22.57-6.51,16.3c-1,2.57-2.06,5.15-3.08,7.72,0,.12-.09.23-.13.34l35.18-20-4.55-1.44-12.31-3.87L731,450.84l-22.32-7-24.46-7.71-24.57-7.75-23-7.25L617.13,415l-13.92-4.39c-2.22-.7-4.42-1.41-6.64-2.09l-.29-.09a21.68,21.68,0,0,0-11.4-.51,21.29,21.29,0,0,0-10.64,3.39,32,32,0,0,0-13.14,17.1l-1,7.6a28.4,28.4,0,0,0,3.9,14.44l4.47,5.79a28.63,28.63,0,0,0,12.63,7.35Z"
              style={{fill: "#b5927e"}}
            />
            <polygon
              points="510.21 480.17 459.72 446.5 405.31 473.4 401.4 533.96 451.89 567.62 506.3 540.73 510.21 480.17"
              style={{
                fill: "#f5f5f5",
                stroke: "#f5f5f5",
                strokeMiterlimit: "10",
                strokeWidth: "6.93555283587243px",
              }}
            />
            <polygon
              points="510.21 480.17 459.72 446.5 405.31 473.4 401.4 533.96 451.89 567.62 506.3 540.73 510.21 480.17"
              style={{fill: "#0f2c48"}}
            />
            <path
              d="M449.52,490.93a17.19,17.19,0,0,0-5.84-1.46,16.76,16.76,0,1,0,11.45,27.79,16.78,16.78,0,1,0,1.39-20.39,22.26,22.26,0,0,0-3.39-3.74A11.82,11.82,0,0,0,449.52,490.93Z"
              style={{
                fill: "#f5f5f5",
                stroke: "#f5f5f5",
                strokeMiterlimit: "10",
                strokeWidth: "5.85351121598736px",
              }}
            />
            <polygon
              points="500.39 485.02 459.01 457.43 414.42 479.47 411.22 529.1 452.6 556.7 497.19 534.66 500.39 485.02"
              style={{
                fill: "none",
                stroke: "#f5f5f5",
                strokeMiterlimit: "10",
                strokeWidth: "3.46703431698277px",
              }}
            />
            <g>
              <g>
                <path
                  d="M526.06,262.79c-11,8.88-27.9,11-35.23,11.45,1.4,5.68,3.82,13.52,7.22,17.76h0a17.86,17.86,0,0,0,11.05,6.69,29,29,0,0,1,5.54-13.52l15-6.16S529.64,274.77,526.06,262.79Z"
                  style={{fill: "#0c1b30"}}
                />
                <path
                  d="M523.9,256s-11.51,8.64-34.53,11.52c0,0,.49,2.87,1.46,6.75,7.33-.49,24.25-2.57,35.23-11.45C525.44,260.74,524.74,258.49,523.9,256Z"
                  style={{fill: "#fff"}}
                />
              </g>
              <path
                d="M530.07,281.79a17.81,17.81,0,0,0-1-6.59,28.76,28.76,0,0,0-11,6.19,29.06,29.06,0,0,0-8.92,17.29,20.11,20.11,0,0,0,2.35.27A17.9,17.9,0,0,0,530.07,281.79Z"
                style={{fill: "#ef5a60"}}
              />
            </g>
          </g>
          <g>
            <path
              d="M436.58,505.72a5.86,5.86,0,1,0,6.26-5.43A5.88,5.88,0,0,0,436.58,505.72Z"
              style={{fill: "#5da4dc"}}
            />
            <path
              d="M469.32,502.15a5.86,5.86,0,1,0,5.44,6.25A5.86,5.86,0,0,0,469.32,502.15Z"
              style={{fill: "#5da4dc"}}
            />
            <path
              d="M470.09,491.28a16.61,16.61,0,0,0-13.65,5.6,22.37,22.37,0,0,0-3.38-3.76l-1,13.69a9.62,9.62,0,1,1-8.94-10.23A9.8,9.8,0,0,1,448.9,499l.57-8.1a17.45,17.45,0,0,0-5.84-1.48A16.76,16.76,0,1,0,455,517.25a16.75,16.75,0,1,0,15.13-26Zm-1.85,26.27a9.58,9.58,0,1,1,10.23-8.88A9.59,9.59,0,0,1,468.24,517.55Z"
              style={{fill: "#5da4dc"}}
            />
          </g>
          <path
            d="M564.3,649.16s6.35.72,6,3.61l-12.93,5.3s24.36,1.27,33-7.41h0l3.08-23.12h-57C540.49,636.89,553.06,648.37,564.3,649.16Z"
            style={{fill: "#0f2c48"}}
          />
          <path
            d="M596.24,610.16c-10.42-2.7-27.67-3.7-38.83-1.27,0,0-12.27,4.36-20.25,11.57-2.44,2.19-2.34,5.88-.62,10h57Z"
            style={{fill: "#5cbdaf"}}
          />
        </g>
      </g>
      <path
        d="M125.13,154.51s-1.26,6.26-4.11,5.71-9-7.34-6.72-18c1.49-7,2.09-36.71-20.64-47.77,0,0-29-6.72-39.8,21.2-8.13,21-10.1,39.31,4.43,68.07,13.56,26.85,29.3,36.4,35.68,44l41.73,88.11,6.06,12.81,52.16-29.54-42.6-90-4.39-9.26c4.18-8.93,13-30.26,10.7-47.65a30.26,30.26,0,0,0-6.41-17C144.72,126.69,127.63,138.5,125.13,154.51Z"
        style={{fill: "#ec6568"}}
      />
    </g>
    <path
      d="M829.43,225.3s1.79,6.14,4.59,5.35,8.32-8.09,5.16-18.5c-2.08-6.85-5.21-36.4,16.5-49.36,0,0,28.28-9.15,41.45,17.74,9.89,20.19,13.42,38.31,1.4,68.2-11.23,27.91-26.1,38.76-31.81,46.87q-17,45.67-34.07,91.35l-4.95,13.28-54.48-25Q790.6,328.62,808,282l3.58-9.6c-4.92-8.54-15.49-29-14.72-46.56a30.25,30.25,0,0,1,4.94-17.48C807.55,199.26,825.58,209.57,829.43,225.3Z"
      style={{fill: "#ec6568"}}
    />
  </SuperheroSvg>
)

export default Builder5
