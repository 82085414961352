import {connect} from "react-redux"

import {
  clearJourney,
  getAdminJourneyComponentData,
  onDeleteJourney,
  onDeleteJourneyScheduledMessage,
  onUpdateJourney,
} from "../journeys/journey-actions"
import JourneyShows from "./journeys-show"

const mapStateToProps = ({journey, contact, template}, {match}) => ({
  journey,
  template,
  contactAccounts: contact.contactAccounts,
  journeyId: match.params.journeyId,
})

const addActionsToProps = {
  clearJourney,
  getJourney: getAdminJourneyComponentData,
  onUpdateJourney,
  onDeleteJourney,
  onDeleteJourneyScheduledMessage,
}

export default connect(mapStateToProps, addActionsToProps)(JourneyShows)
