import {TableCell, TableRow, Tooltip} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, bool, object, shape, string} from "prop-types"
import {Component} from "react"
import {FaEye as PreviewIcon} from "react-icons/fa"

import ExportButton from "components/export-button/export-button"

import {fetchTemplateActions} from "lib/api"
import humanize from "lib/string/humanize"

import {Preview} from "../content-library/content-preview"
import {humanizeType} from "../content-library/helpers"
import DOTable, {noWrapCellClass, numberCellClass} from "../table/table"
import {Metrics, Types, formatValue} from "./insights-helpers"

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    "& button": {
      alignSelf: "flex-end",
      marginBottom: theme.spacing(2),
    },

    // emailOpenRate
    "& th:nth-child(7)": {
      minWidth: 110,
    },

    // emailClickToOpenRate
    "& th:nth-child(8)": {
      minWidth: 160,
    },

    // bounceRate
    "& th:nth-child(11)": {
      minWidth: 140,
    },
  },
  previewTooltip: {
    maxWidth: "none",
  },
  preview: {
    width: 306,
  },
  previewContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  previewableName: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  previewEye: {
    marginLeft: 10,
  },
})

const Footer = (footer, metric) => () =>
  Object.keys(footer).map(key => {
    const row = footer[key]
    const name = humanize(key)

    return (
      <TableRow key={`footer-${key}`}>
        <TableCell className={numberCellClass}>{name}</TableCell>
        <TableCell className={noWrapCellClass}>--</TableCell>
        <TableCell className={noWrapCellClass}>{name}</TableCell>
        <TableCell className={noWrapCellClass}>--</TableCell>
        {metric === Metrics.Total ? (
          <>
            <TableCell className={numberCellClass}>
              {formatValue(row.messageSent, Types.Number)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.emailOpened, Types.Number)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.ctaClicked, Types.Number)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.messageBounced, Types.Number)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.optedOut, Types.Number)}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell className={numberCellClass}>
              {formatValue(row.emailOpenRate, Types.Percentage)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.emailClickToOpenRate, Types.Percentage)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.clickThruRate, Types.Percentage)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.bounceRate, Types.Percentage)}
            </TableCell>
            <TableCell className={numberCellClass}>
              {formatValue(row.unsubscribeRate, Types.Percentage)}
            </TableCell>
          </>
        )}
      </TableRow>
    )
  })

const descriptionHeaders = [
  {
    field: "order",
    label: "Send Order",
    sortable: false,
    classes: numberCellClass,
    tooltipText:
      "Scheduled workflow order of the message in the campaign. This may or may not be the actual send order to the contact",
  },
  {
    field: "contentName",
    label: "Message Name",
    sortable: false,
    tooltipText: "The internal name of the message",
  },
  {
    field: "type",
    sortable: false,
    tooltipText: "Type of message (email or SMS)",
  },
  {
    field: "objective",
    sortable: false,
    tooltipText: "The objective associated with the message",
  },
]

export const metricHeaders = {
  [Metrics.Total]: [
    {
      field: "messageSent",
      label: "Sent",
      sortable: false,
      classes: numberCellClass,
      tooltipText:
        "Number of times the message has been sent (if you have the same message sending twice in the same campaign, it will add only 1 to this count)",
    },
    {
      field: "emailOpened",
      label: "Opened",
      sortable: false,
      classes: numberCellClass,
      tooltipText: "Total number of unique opens for that message (once per journey)",
    },
    {
      field: "ctaClicked",
      label: "Clicks",
      sortable: false,
      classes: numberCellClass,
      tooltipText: "Total number of unique clicks for that message (once per journey)",
    },
    {
      field: "messageBounced",
      label: "Bounces",
      sortable: false,
      classes: numberCellClass,
      tooltipText: "Total number of bounces for that message (once per journey)",
    },
    {
      field: "optedOut",
      label: "Unsubscribed",
      sortable: false,
      classes: numberCellClass,
      tooltipText:
        "Total number of unsubscribes that happened from this message (once per journey)",
    },
  ],
  [Metrics.Rate]: [
    {
      field: "emailOpenedRate",
      label: "Open %",
      sortable: false,
      classes: numberCellClass,
      tooltipText: "= total number of unique opens / total message sends (excluding bounces)",
    },
    {
      field: "emailClickToOpenRate",
      label: "Click to Open %",
      sortable: false,
      classes: numberCellClass,
      tooltipText: "= total number of unique clicks / total number of unique opens",
    },
    {
      field: "clickThruRate",
      label: "CTR",
      sortable: false,
      classes: numberCellClass,
      tooltipText:
        "= total number of unique clicks / total number of message sends (excluding bounces)",
    },
    {
      field: "bounceRate",
      sortable: false,
      classes: numberCellClass,
      tooltipText: "= total number of unique bounces / total number of message sends",
    },
    {
      field: "unsubscribeRate",
      sortable: false,
      classes: numberCellClass,
      tooltipText:
        "= total number of unique unsubscribes / total number of message sends (excluding bounces)",
    },
  ],
}

const exportHeaders = {
  message_name: "Message Name",
  type: "Type",
  objective: "Objective",
  action: "Action",
  unique_id: "Contact Unique ID",
  email: "Email",
  mobile_phone: "Mobile Phone",
}

const exportHeaderValues = Object.values(exportHeaders)

class MessagePerformanceInsightsTable extends Component {
  render() {
    const {classes, exportFilters, isLoading, messagePerformance, metric, templateId} = this.props
    const {rows, footer} = messagePerformance

    return (
      <div className={classes.container}>
        <ExportButton
          fetchRecords={fetchTemplateActions}
          filename="message_performance_data"
          params={{
            ...exportFilters,
            templateId,
            export: "message_performance",
            name: ["cta_clicked", "email_opened", "message_bounced", "opted_out"],
          }}
          title="Export Data"
          transform={datum =>
            Object.entries(datum).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [exportHeaders[key]]: value,
              }),
              {}
            )
          }
          sortHeaders={(a, b) => exportHeaderValues.indexOf(a) - exportHeaderValues.indexOf(b)}
        />
        <DOTable
          headers={descriptionHeaders.concat(metricHeaders[metric])}
          isTableLoading={isLoading}
          noResults={"No Results Found"}
          paginationEnabled={false}
          rows={rows}
          tableFooter={rows.length > 0 ? Footer(footer, metric) : null}
          variant="light"
        >
          {(row, i) => (
            <TableRow key={row.message.id}>
              <TableCell className={numberCellClass}>{row.templateMessage.order + 1}</TableCell>
              <TableCell>
                <div className={classes.previewContainer}>
                  <span className={classes.previewableName}>{row.message.contentName}</span>
                  <Tooltip
                    classes={{tooltip: classes.previewTooltip}}
                    placement="right"
                    title={
                      <div className={classes.preview}>
                        <Preview
                          bodyPreview={row.message.contentName}
                          contentType={row.message.type.toLowerCase()}
                          id={row.message.id}
                        />
                      </div>
                    }
                  >
                    <span className={classes.previewEye}>
                      <PreviewIcon />
                    </span>
                  </Tooltip>
                </div>
              </TableCell>

              <TableCell className={noWrapCellClass}>{humanizeType(row.message.type)}</TableCell>
              <TableCell className={noWrapCellClass}>
                {formatValue(row.message.objective?.name)}
              </TableCell>
              {metric === Metrics.Total ? (
                <>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.messageSent, Types.Number)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.emailOpened, Types.Number)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.ctaClicked, Types.Number)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.messageBounced, Types.Number)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.optedOut, Types.Number)}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.emailOpenRate, Types.Percentage)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.emailClickToOpenRate, Types.Percentage)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.clickThruRate, Types.Percentage)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.bounceRate, Types.Percentage)}
                  </TableCell>
                  <TableCell className={numberCellClass}>
                    {formatValue(row.unsubscribeRate, Types.Percentage)}
                  </TableCell>
                </>
              )}
            </TableRow>
          )}
        </DOTable>
      </div>
    )
  }
}

MessagePerformanceInsightsTable.propTypes = {
  classes: object.isRequired,
  exportFilters: object,
  isLoading: bool,
  messagePerformance: shape({
    rows: array,
  }),
  metric: string,
  templateId: string,
}

export default withStyles(styles)(MessagePerformanceInsightsTable)
