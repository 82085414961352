/* global Atomic */
import {CircularProgress, Link, Typography} from "@mui/material"
import {func, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import BrandedButton from "components/branded-button/branded-button"
import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {useJourneyContext} from "components/journeys/journey-context"

import {createDirectDepositAccessToken} from "lib/api"
import useAnalytics from "lib/hooks/use-analytics"
import defaultTheme from "themes/main"

import {buttonColors} from "../helpers/button-colors"
import {loadLib} from "./direct-deposit-helper"

const DirectDepositIneligible = ({contact}) => (
  <>
    <Typography>
      Unfortunately, we do not have any valid checking account details on file.
    </Typography>
    <Typography>
      Please contact us for assistance
      {contact?.owner?.email ? (
        <>
          {" at "}
          <Link href={`mailto:${contact.owner.email}`}>{contact.owner.email}</Link>
          {"."}
        </>
      ) : (
        "."
      )}
    </Typography>
  </>
)

DirectDepositIneligible.propTypes = {
  contact: object,
}

const DirectDepositReadonly = ({
  className,
  contentBlock,
  customCss,
  onClick,
  style,
  template,
  reloadJourneyBySlug,
}) => {
  const {contact, journeyId, journeySlug} = useJourneyContext()
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const {
    contentContainerId,
    data,
    data: {iconSrc, text},
    id: contentBlockId,
    pageId,
    slug,
  } = contentBlock
  const {track} = useAnalytics()

  const onClose = () => setOpen(false)

  const onFinish = () => {
    setOpen(false)
    setTimeout(reloadJourneyBySlug, 15_000, journeySlug)
  }

  const onInteraction = ({name, value: {query}}) => {
    if (name === "Viewed Welcome Page") {
      trackInteraction("direct_deposit_widget_opened")
    }

    if (name === "Search By Company") {
      trackInteraction("direct_deposit_company_searched", {query})
    }

    if (name === "Search By Payroll") {
      trackInteraction("direct_deposit_payroll_searched", {query})
    }
  }

  const trackInteraction = (action, meta) => {
    track(action, {...meta, contentBlockId, contentContainerId, pageId, widgetSlug: slug})
  }

  const createAccessToken = async () => {
    // createAccessToken only requires contentBlockId and journeyId to construct
    // the necessary request to get an access token.
    //
    // NB: The data provided in the "page" case and the "cce" case are inconsistent:
    // For the channel case, engagement channel, engagementChannelId is null
    // For the page case, a journeyId is set but there doesn't appear to be a journey set.
    // None of these cause a problem because all we need is contentBlockId and journeyId.

    // If the contact isn't eligible for DD, or the window is already open, noop
    if (!contact?.directDepositEligible || isOpen) return

    setOpen(true)

    const theme = {
      brandColor: template?.theme?.palette?.primary?.main ?? defaultTheme?.palette?.primary?.main,
    }

    setLoading(true)
    createDirectDepositAccessToken({
      journeyId,
      contentBlockId: contentBlockId,
    }).then(async ({isAuthenticated, publicToken}) => {
      await loadLib()
      setLoading(false)

      const unauthenticatedConfig = {
        publicToken,
        onClose,
        onFinish,
        onInteraction,
        tasks: [{operation: "deposit"}],
        theme,
      }

      Atomic.transact(
        !!isAuthenticated
          ? {...unauthenticatedConfig, manualDepositFlow: true}
          : unauthenticatedConfig
      )
    })
  }

  useEffect(() => {
    loadLib()
  }, [])

  if (contact && !contact.directDepositEligible)
    return (
      <div className={className} onClick={onClick}>
        <DirectDepositIneligible contact={contact} />
      </div>
    )

  return (
    <div className={className} id={slug} onClick={onClick} style={style}>
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <BrandedButton
        color="primary"
        onClick={createAccessToken}
        size="large"
        style={buttonColors(data)}
        variant="contained"
      >
        {isLoading && <CircularProgress size={20} style={{color: "black", marginRight: 10}} />}
        {text}
        {iconSrc && <img alt="" aria-hidden="true" className="icon" src={iconSrc} />}
      </BrandedButton>
    </div>
  )
}

DirectDepositReadonly.propTypes = {
  className: string,
  contentBlock: shape({
    slug: string,
    data: shape({
      text: string,
      iconSrc: string,
    }),
  }).isRequired,
  customCss: string,
  onClick: func,
  pageId: string,
  reloadJourneyBySlug: func,
  style: object,
  template: object,
}

export default DirectDepositReadonly
