import {TableCell, TableRow, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, object} from "prop-types"
import {useEffect} from "react"

import Expandable from "components/expandable/expandable"
import DOTable from "components/table/table"
import {teamInsightsContext} from "components/team-insights/team-insights-context"

import {fetchTeamInsightsOverallPerformance} from "lib/api"

const OverallPerformance = ({
  fetchInsights,
  filterParams,
  hasSocket,
  overallPerformance,
  overallPerformanceIsLoading,
}) => {
  const classes = useStyles()

  useEffect(() => {
    if (hasSocket)
      fetchInsights("overallPerformance", fetchTeamInsightsOverallPerformance, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h5">
        Performance
      </Typography>
      <Typography className={classes.subheader} variant="h5">
        Overall
      </Typography>
      <div className={classes.expandableContainer}>
        <Expandable label="How we use this table">
          <p>
            This table reports on total performance metrics across all campaigns. The Campaign
            Status view filters the performance metrics by "Live", "Archived", or "All" Campaigns.
          </p>

          <p>Recommended questions to ask:</p>
          <ul>
            <li>
              <b>Email CTR and SMS CTR</b> - Which message type is driving more action?
            </li>
            <li>
              <b>Bounce Rate</b> - Is it comparable to your historical data and/or industry
              averages?
            </li>
            <li>
              <b>Unsubscribe Rate</b> - Have you seen a decrease after implementing strategic
              communication strategies with personalization, audience segmentation, and/or message
              cadence?
            </li>
            <li>
              <b>Page CTR</b> - Are your campaign microsites motivating action?
            </li>
            <li>
              <b>Presented Objective Completion Rate</b> - Are campaigns’ journeys completing the
              objectives presented to them?
            </li>
            <li>
              <b>% of Actions On Mobile</b> - Is your content mobile-friendly? What mobile UX
              improvements are there to cater to this mobile audience?
            </li>
            <li>
              <b>Views (Live / Archived)</b> - Are "Live" campaigns performing differently than
              "Archived" campaigns? What could be impacting the change?
            </li>
          </ul>
        </Expandable>
      </div>
      <DOTable
        headers={[
          {
            field: "emailCtr",
            label: "Email CTR",
            sortable: false,
            tooltipText:
              "Email CTR - total # of unique email message clicks / total # of email messages sent (excluding bounces)",
          },
          {
            field: "smsCtr",
            label: "SMS CTR",
            sortable: false,
            tooltipText:
              "SMS CTR - total # of unique SMS message clicks / total # of SMS messages sent (excluding bounces)",
          },
          {
            field: "bouncedRate",
            label: "Bounce Rate",
            sortable: false,
            tooltipText: "Bounce Rate - total # of message bounces / total # of messages sent",
          },
          {
            field: "unsubscribeRate",
            label: "Unsubscribe Rate",
            sortable: false,
            tooltipText:
              "Unsubscribe Rate - total # of unique unsubscribes / total # of messages sent (excluding bounces)",
          },
          {
            field: "pageCtr",
            label: "Page CTR",
            sortable: false,
            tooltipText: "Page CTR - total # of unique page clicks / total # of unique page views",
          },
          {
            field: "presentedObjectiveCompletionRate",
            label: "Presented Objective Completion Rate",
            sortable: false,
            tooltipText:
              "Presented objective completion rate - total # of unique objective completions / total # of objectives presented to contacts",
          },
          {
            field: "mobileActionRate",
            label: "% of Actions on a Mobile Device",
            sortable: false,
            tooltipText:
              "% of actions on a mobile device - total # of engagement actions completed on mobile / total # of engagement actions",
          },
        ]}
        isTableLoading={overallPerformanceIsLoading}
        noResults="No data found."
        paginationEnabled={false}
        rows={overallPerformance?.data ?? []}
      >
        {({
          bouncedRate,
          emailCtr,
          mobileActionRate,
          pageCtr,
          presentedObjectiveCompletionRate,
          smsCtr,
          unsubscribeRate,
        }) => {
          return (
            <TableRow key="team-insights-overall-performance">
              <TableCell>{emailCtr}%</TableCell>
              <TableCell>{smsCtr}%</TableCell>
              <TableCell>{bouncedRate}%</TableCell>
              <TableCell>{unsubscribeRate}%</TableCell>
              <TableCell>{pageCtr}%</TableCell>
              <TableCell>{presentedObjectiveCompletionRate}%</TableCell>
              <TableCell>{mobileActionRate}%</TableCell>
            </TableRow>
          )
        }}
      </DOTable>
    </div>
  )
}

OverallPerformance.propTypes = {
  fetchInsights: func.isRequired,
  filterParams: object,
  hasSocket: bool,
  overallPerformance: object,
  overallPerformanceIsLoading: bool,
}

const useStyles = makeStyles(theme => ({
  container: {
    "& tr th:nth-child(6)": {
      maxWidth: 170,
    },
    "& tr th:nth-child(7)": {
      maxWidth: 170,
    },
  },
  expandableContainer: {
    marginBottom: theme.spacing(3),
  },
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
  subheader: {
    fontSize: 24,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
}))

export default teamInsightsContext(OverallPerformance)
