import {TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {array, func, shape, string} from "prop-types"
import {createContext, useCallback, useEffect, useReducer, useState} from "react"

import CtaButtonSettings from "components/content-block-editor/cta-button-settings"

import FormActions from "../form-actions"
import {
  addQuestion,
  deleteQuestion,
  initFromProps,
  resetQuestions,
  surveySettingsReducer,
  updateCtaSettings,
  updateName,
  updateQuestion,
} from "./survey-settings-helpers"
import SurveySettingsQuestions from "./survey-settings-questions"

export const SurveySettingsContext = createContext()

const SurveySettings = ({contentBlock, initialValues, onSubmit, onChange}) => {
  const [state, dispatch] = useReducer(surveySettingsReducer, initFromProps(initialValues))
  const [slugState, setSlugState] = useState({widgetSlug: contentBlock.slug})
  const [validationErrors, setValidationErrors] = useState({})

  const onUpdateName = useCallback(event => dispatch(updateName({name: event.target.value})), [])
  const onUpdateCtaSettings = useCallback(settings => dispatch(updateCtaSettings(settings)), [])
  const onUpdateQuestion = useCallback(question => dispatch(updateQuestion(question)), [])
  const onRemoveQuestion = useCallback(question => dispatch(deleteQuestion(question)), [])
  const onAddQuestion = () => dispatch(addQuestion())

  const resetForm = () => dispatch(resetQuestions(initFromProps(initialValues)))

  const {name, backgroundColor, iconSrc, questions} = state

  // NB: This function mimicks useForm's `change`
  const changeCtaSettings = (field, value) => onUpdateCtaSettings({[field]: value})

  // NB: This function mimicks useForm's `field`
  const ctaSettingsField = (field, config) => ({value: state[field] || config?.defaultValue})

  const checkForValidationErrors = useCallback(() => {
    // The survey name may be used as a lookup key in survey-related personalization paths;
    // the path's tokens are split by `::`, so we can't allow that pattern in the name itself.
    // See Slack convo: https://digitalonboarding.slack.com/archives/G9U11S6TZ/p1674583953049029
    if (name.includes("::")) {
      setValidationErrors(old => ({...old, name: 'Survey name cannot include "::".'}))
      return true
    } else if (name === "") {
      const message =
        "Please provide a name for your survey widget, as this is used to uniquely identify the survey in insights, analytics, retargeting, and webhooks. Do not name it the same as another survey unless you would like them reported together."
      setValidationErrors(old => ({...old, name: message}))
      return true
    } else {
      setValidationErrors({})
      return false
    }
  }, [name])

  const handleSubmit = e => {
    e.preventDefault()

    if (checkForValidationErrors() === false) {
      onSubmit({name, backgroundColor, iconSrc, questions: Object.values(questions)})
    }
  }

  useEffect(() => {
    checkForValidationErrors()
    onChange({name, backgroundColor, iconSrc, questions: Object.values(questions)})
  }, [name, backgroundColor, iconSrc, questions, onChange, checkForValidationErrors])

  useEffect(() => {
    setSlugState({widgetSlug: contentBlock.slug})
  }, [initialValues, contentBlock.slug]) // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles()

  return (
    <SurveySettingsContext.Provider value={slugState}>
      <form aria-label="Survey Settings" className={classes.root} onSubmit={handleSubmit}>
        <TextField
          fullWidth={true}
          id={`survey-${contentBlock.slug}-name`}
          label="Name"
          margin="normal"
          onChange={onUpdateName}
          value={name}
        />
        {validationErrors.name && <div className={classes.textDanger}>{validationErrors.name}</div>}

        <SurveySettingsQuestions
          onAddQuestion={onAddQuestion}
          onRemoveQuestion={onRemoveQuestion}
          onUpdateQuestion={onUpdateQuestion}
          questions={Object.values(questions)}
        />
        <CtaButtonSettings change={changeCtaSettings} field={ctaSettingsField} />
        <div className={classes.spacer} />
        <FormActions
          resetForm={resetForm}
          saveProps={{disabled: Object.keys(validationErrors).length > 0}}
        />
      </form>
    </SurveySettingsContext.Provider>
  )
}

SurveySettings.propTypes = {
  contentBlock: shape({slug: string}),
  initialValues: shape({questions: array}),
  onChange: func.isRequired,
  onSubmit: func,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  spacer: {
    marginBottom: theme.spacing(2),
  },
  textDanger: {
    color: theme.palette.error.main,
  },
}))

export default SurveySettings
