import {Tooltip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {func, string} from "prop-types"
import {MdHelp as HelpIcon} from "react-icons/md"

const useStyles = makeStyles({
  defaultWrapper: {
    verticalAlign: "middle",
  },
  defaultIcon: {
    fontSize: "1rem",
    color: "rgba(0,0,0,0.54)",
  },
})

const IconTooltip = ({iconComponent, iconClassName, wrapperClassName, ...restProps}) => {
  const classes = useStyles()
  const Icon = iconComponent || HelpIcon

  return (
    <Tooltip {...restProps}>
      <span className={cx(classes.defaultWrapper, wrapperClassName)}>
        <Icon className={cx(classes.defaultIcon, iconClassName)} />
      </span>
    </Tooltip>
  )
}

IconTooltip.propTypes = {
  iconClassName: string,
  iconComponent: func,
  wrapperClassName: string,
}

export default IconTooltip
