export function compileValues(inputs) {
  return Object.entries(inputs).reduce((acc, [fieldName, fieldObject]) => {
    return {...acc, [fieldName]: fieldObject.value}
  }, {})
}

export function isDirty(inputs) {
  return Object.values(inputs).reduce(
    (acc, fieldObject) => (acc ? acc : "error" in fieldObject),
    false
  )
}
