import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {useEffect, useState} from "react"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import {contentBlockPropType} from "components/content-block-editor/content-block-editor-prop-types"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {ContentType} from "contexts/editor-context"
import draftJsBlockRenderers from "lib/draft-js/block-renderers"
import customStyleFn from "lib/draft-js/custom-style-fn"
import {
  createEditorStateFromContent,
  getRawContentFromEditorState,
} from "lib/draft-js/editor-helpers"
import Editor from "lib/draft-js/rich-text-editor"

import SaveButton from "../../../save-button/save-button"
import {contentBlockEditorConsumer} from "../../content-block-editor-context"

const textBlockContentTypes = {
  content_container: ContentType.ContentContainer,
  page: ContentType.Page,
  message: ContentType.EmailBody,
  template: ContentType.TemplateFooter,
}

const TextReadonly = ({
  className,
  contentBlock,
  customCss,
  isEditMode,
  isSelected,
  onClick,
  onUpdateContentBlock,
  selectedBlock,
  setSelectedBlockDataChanges,
  style,
}) => {
  const [isDirty, setIsDirty] = useState(false)
  const [editorState, setEditorState] = useState(createEditorStateFromContent(contentBlock.data))

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isSelected) setIsDirty(false)
  }, [isSelected])

  const onChange = newEditorState => {
    setEditorState(newEditorState)

    // We fake this so we don't have to call `getRawContentFromEditorState` each keystroke just to
    // warn users of abandoning changes. There's a chance that they reverse their changes and
    // still get the "you have unsaved changes" warning, but to me that's a worthwile sacrifice
    // given the problems we've had with that exact function in the past :harold:
    if (
      !isDirty &&
      createEditorStateFromContent(contentBlock.data).getCurrentContent() !==
        editorState.getCurrentContent()
    ) {
      setIsDirty(true)
      setSelectedBlockDataChanges({dirty: true})
    }
  }

  const handleSave = e => {
    e.preventDefault()

    setIsSubmitting(true)

    // TODO: Handle error case
    //  Note: Ideally, we would capture the error and set an
    //  error flag that we could then pass to SaveButton.
    //  However, we can't because the error is being
    //  swallowed by `onUpdateContentBlock`. Without
    //  rethrowing, not sure how we want to handle this.
    onUpdateContentBlock({
      data: getRawContentFromEditorState(editorState),
      id: selectedBlock.id,
    }).finally(() => setIsSubmitting(false))
  }

  const classes = useStyles()

  // Bunch of temp vars used here despite the code smell to provide a measure of clarity
  //   around complex conditions for showing the text block placeholder
  const currentHasText = editorState.getCurrentContent().hasText()
  const initialHasText = createEditorStateFromContent(contentBlock.data)
    .getCurrentContent()
    .hasText()
  const shouldShowPlaceholder = currentHasText
    ? currentHasText !== initialHasText && !initialHasText
    : !currentHasText

  return (
    <div
      data-testid="text-root"
      className={cx(className, classes.container, {
        [classes.emptyBackground]: !isSelected && isEditMode && shouldShowPlaceholder,
      })}
      onClick={onClick}
      style={style}
    >
      <SortableDragHandle />
      <AppendPluginMenu contentBlock={contentBlock} />
      <style>{customCss}</style>
      <Editor
        autoFocus={isSelected}
        blockRenderMap={draftJsBlockRenderers}
        contentType={textBlockContentTypes[contentBlock.containerType]}
        customStyleFn={customStyleFn}
        id={`text-${contentBlock.id}`}
        initialValue={contentBlock.data}
        key={`${contentBlock.id}-${isSelected}`}
        ariaLabel="Text Block Content"
        onChange={onChange}
        showToolbar={isSelected && isEditMode}
        viewerMode={!isSelected || !isEditMode}
      />
      {isSelected && isEditMode && (
        <div className={classes.saveButtonContainer}>
          <SaveButton disabled={!isDirty} onClick={handleSave} submitting={isSubmitting} />
        </div>
      )}
    </div>
  )
}

TextReadonly.propTypes = {
  className: string,
  container: object,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  isEditMode: bool,
  isSelected: bool,
  onClick: func,
  onUpdateContentBlock: func,
  selectedBlock: object,
  setSelectedBlockDataChanges: func,
  style: object,
}

const useStyles = makeStyles(theme => ({
  container: {width: "100%"},
  emptyBackground: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50' width='120'><text x='60' y='25' fill='${theme.palette.text.hint}' font-size='20' font-family='sans-serif' text-anchor='middle' alignment-baseline='central'>TEXT</text></svg>")`,
    border: `1px dashed ${theme.palette.text.hint}`,
    height: 75,
  },
  saveButtonContainer: {
    bottom: `calc(-36px - ${theme.spacing(2)})`, // -saveButtonHeight - spacing
    right: 0,
    position: "absolute",
  },
}))

export default contentBlockEditorConsumer(TextReadonly)
