import {Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import memoize from "memoize-one"
import {bool, string} from "prop-types"
import {SortableElement} from "react-sortable-hoc"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import {useContentBlockEditor} from "components/content-block-editor/content-block-editor-context"
import {contentBlockPropType} from "components/content-block-editor/content-block-editor-prop-types"
import SortableDragHandle from "components/content-block-editor/drag-handle"
import {blockClasses} from "components/content-block-editor/layout-basis"
import {pluginFor} from "components/content-block-editor/plugins"

import {compileCss} from "lib/custom-css"

import ErrorBoundary from "../error-boundary/error-boundary"
import {ContentBlockProvider} from "./content-block-context"

const compileCustomCss = memoize((source, contentBlockId) => {
  try {
    return compileCss(`.content-block-${contentBlockId}`, source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const ContentBlock = ({contentBlock, isDragging, layoutBasis}) => {
  const {isEditMode, selectedBlock, setSelectedBlock} = useContentBlockEditor()
  const isSelected = selectedBlock && contentBlock.id === selectedBlock.id
  const {Readonly} = pluginFor(contentBlock)
  const classes = useStyles()

  const props = {
    className: cx(
      `${contentBlock.type}-widget`,
      contentBlock.data.customClass,
      classes.root,
      {
        [classes.selectedBlock]: isSelected && isEditMode,
        "is-dragging": isDragging,
      },
      blockClasses(layoutBasis, contentBlock.grid),
      `content-block-${contentBlock.id}`
    ),
    contentBlock,
    customCss: compileCustomCss(contentBlock.css, contentBlock.id) || "",
    key: contentBlock.id,
    isSelected,
    style: contentBlock.styles,
  }

  if (isEditMode)
    props.onClick = event => {
      if (isSelected) return
      event.preventDefault()
      setSelectedBlock(contentBlock)
    }

  return (
    <ErrorBoundary
      errorFallback={
        <Typography component="div" {...props}>
          <SortableDragHandle />
          <AppendPluginMenu contentBlock={contentBlock} />
          <h5 className={classes.errorHeading}>
            This feature is currently unavailable, please try again later.
          </h5>
          It may be due to settings you have chosen or a service it relies on being down.
        </Typography>
      }
    >
      <ContentBlockProvider contentBlock={contentBlock}>
        <Readonly {...props} />
      </ContentBlockProvider>
    </ErrorBoundary>
  )
}

ContentBlock.propTypes = {
  contentBlock: contentBlockPropType.isRequired,
  isDragging: bool,
  layoutBasis: string.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",

    "&:hover > .drag-handle, &.is-dragging > .drag-handle": {
      opacity: 1,
    },
    "&:hover > .append-plugin-menu": {
      opacity: 1,
    },
  },
  selectedBlock: {
    background: "#FFF",
    boxShadow: theme.shadows[24],
    margin: theme.spacing(-1.25),
    padding: theme.spacing(1.25),
    zIndex: 101,
  },
  errorHeading: {
    fontWeight: "bold",
    color: theme.palette.error.main,
  },
}))

export const SortableContentBlock = SortableElement(ContentBlock)

export default ContentBlock
