import queryString from "query-string"
import {connect} from "react-redux"

import ContactsList from "./contacts-list"

const mapStateToProps = () => ({
  batchId: queryString.parse(window.location.search).batchId,
})

export default connect(mapStateToProps)(ContactsList)
