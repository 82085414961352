import {Button, FormControl, Grid, InputLabel, Stack, TextField, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {func, object} from "prop-types"
import React, {useState} from "react"
import Confetti from "react-confetti"
import {MdCelebration as CelebrationIcon} from "react-icons/md"

import ColorInputList from "components/color-input-list/color-input-list"
import ColorInput from "components/color-input/color-input"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"
import ResetLabel from "components/teams/branding-settings/reset-label"

import FontSelector from "../../font-selector/font-selector"

export const TitleGrid = styled(Grid)(({theme}) => ({
  paddingBottom: 0,
  "&.MuiGrid-item": {
    "&:not(:first-of-type)": {
      marginTop: theme.spacing(1.5),
    },
    paddingTop: theme.spacing(1.5),
    ".MuiTypography-root": {
      fontSize: 21,
      fontWeight: "bold",
    },
  },
}))

const rainbowColors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
]

const RewardSettings = ({change, field, inputs, muiTheme}) => {
  const [showConfetti, setShowConfetti] = useState(false)
  const [confettiKey, setConfettiKey] = useState(0) // New state for confetti key

  const handlePreviewClick = () => {
    setConfettiKey(prevKey => prevKey + 1) // Increment key to reset confetti
    setShowConfetti(true)
  }

  return (
    <>
      <Grid container spacing={3}>
        <TitleGrid item lg={12} md={12} xs={12}>
          <Typography>Rewards</Typography>
        </TitleGrid>

        <TitleGrid item lg={12} md={12} xs={12}>
          <Typography>Value Text</Typography>
        </TitleGrid>

        <Grid item lg={7} md={12} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="valueFontFamily">
              <ResetLabel name="custom.rewards.valueFontFamily">Font Family</ResetLabel>
            </InputLabel>
            <FontSelector
              enableGoogleFonts={true}
              id="rewardsValueFontFamily"
              {...field(`custom.rewards.valueFontFamily`, {
                defaultValue: muiTheme.custom?.rewards?.valueFontFamily || "Roboto (Default)",
                exclude: ["helperText"],
              })}
              onSelect={({value}) => change(`custom.rewards.valueFontFamily`, `${value}`)}
            />
          </FormControl>
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="rewardsValueFontSize"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="custom.rewards.valueFontSize">Size</ResetLabel>}
            margin="normal"
            type="text"
            {...field(`custom.rewards.valueFontSize`, {
              defaultValue: muiTheme.custom?.rewards?.valueFontSize || "32px",
            })}
          />
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="rewardsValueTextColor"
            input={{
              ...field("custom.rewards.valueTextColor", {
                defaultValue: muiTheme.custom?.rewards?.valueTextColor || "#000",
              }),
              onChange: hex => change("custom.rewards.valueTextColor", hex),
            }}
            label={<ResetLabel name="custom.rewards.valueTextColor">Color</ResetLabel>}
            hideCustomPalette
          />
        </Grid>

        <TitleGrid item lg={12} md={12} xs={12}>
          <Typography>Caption Text</Typography>
        </TitleGrid>

        <Grid item lg={7} md={12} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="captionFontFamily">
              <ResetLabel name="custom.rewards.captionFontFamily">Font Family</ResetLabel>
            </InputLabel>
            <FontSelector
              enableGoogleFonts={true}
              id="rewardsCaptionFontFamily"
              {...field(`custom.rewards.captionFontFamily`, {
                defaultValue: muiTheme.custom?.rewards?.captionFontFamily || "Roboto (Default)",
                exclude: ["helperText"],
              })}
              onSelect={({value}) => change(`custom.rewards.captionFontFamily`, `${value}`)}
            />
          </FormControl>
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="rewardsCaptionFontSize"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="custom.rewards.captionFontSize">Size</ResetLabel>}
            margin="normal"
            type="text"
            {...field(`custom.rewards.captionFontSize`, {
              defaultValue: muiTheme.custom?.rewards?.captionFontSize || "20px",
            })}
          />
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="rewardsCaptionTextColor"
            input={{
              ...field("custom.rewards.captionTextColor", {
                defaultValue: muiTheme.custom?.rewards?.captionTextColor || "#000",
              }),
              onChange: hex => change("custom.rewards.captionTextColor", hex),
            }}
            label={<ResetLabel name="custom.rewards.captionTextColor">Color</ResetLabel>}
            hideCustomPalette
          />
        </Grid>

        <TitleGrid item lg={12} md={12} xs={12}>
          <Typography>Progress Meter Colors</Typography>
        </TitleGrid>

        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="rewardsInProgressColor"
            input={{
              ...field("custom.rewards.inProgressColor", {
                defaultValue: muiTheme.custom?.rewards?.inProgressColor || "#42a5f5",
              }),
              onChange: hex => change("custom.rewards.inProgressColor", hex),
            }}
            label={<ResetLabel name="custom.rewards.inProgressColor">In-Progress</ResetLabel>}
            hideCustomPalette
          />
        </Grid>
        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="rewardsCompletedColor"
            input={{
              ...field("custom.rewards.completedColor", {
                defaultValue: muiTheme.custom?.rewards?.completedColor || "#1976d2",
              }),
              onChange: hex => change("custom.rewards.completedColor", hex),
            }}
            label={<ResetLabel name="custom.rewards.completedColor">Completed</ResetLabel>}
            hideCustomPalette
          />
        </Grid>
        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="rewardsTrackColor"
            input={{
              ...field("custom.rewards.trackColor", {
                defaultValue: muiTheme.custom?.rewards?.trackColor || "#1565c0",
              }),
              onChange: hex => change("custom.rewards.trackColor", hex),
            }}
            label={<ResetLabel name="custom.rewards.trackColor">Track</ResetLabel>}
            hideCustomPalette
          />
        </Grid>

        <Grid item lg={7} md={12} xs={12}>
          <ColorInput
            fullWidth
            id="rewardsWidgetBackgroundColor"
            input={{
              ...field("custom.rewards.header.widgetBackgroundColor", {
                defaultValue: muiTheme.custom?.rewards?.widgetBackgroundColor || "#FFF",
              }),
              onChange: hex => change("custom.rewards.widgetBackgroundColor", hex),
            }}
            label={
              <ResetLabel name="custom.rewards.widgetBackgroundColor">Reward Background</ResetLabel>
            }
            hideCustomPalette
          />
        </Grid>

        <TitleGrid item lg={12} md={12} xs={12}>
          <Stack direction="row">
            <Typography>Confetti Colors</Typography>
            <Button sx={{marginLeft: "10px"}} onClick={handlePreviewClick}>
              <CelebrationIcon style={{marginRight: "5px"}} /> Preview
            </Button>
          </Stack>
          {showConfetti && (
            <Confetti
              key={confettiKey}
              width={window.innerWidth}
              height={window.innerHeight}
              style={{position: "fixed", top: 0, left: 0}}
              confettiSource={{x: window.innerWidth / 2, y: (window.innerHeight * 9) / 10}}
              recycle={false}
              initialVelocityY={{min: -18, max: -8}}
              initialVelocityX={{min: -10, max: 10}}
              tweenDuration={1000}
              colors={inputs["custom.rewards.confettiColors"]?.value || rainbowColors}
            />
          )}
        </TitleGrid>
        <ColorInputList
          colors={inputs["custom.rewards.confettiColors"]?.value || []}
          onChange={colors => {
            change("custom.rewards.confettiColors", colors?.length > 0 ? colors : null)
          }}
        />
      </Grid>
    </>
  )
}

RewardSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  inputs: object.isRequired,
  muiTheme: object.isRequired,
}

export default brandingSettings(RewardSettings)
