import {Divider, MenuItem} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object, string} from "prop-types"
import {Component} from "react"
import {MdLibraryAdd as MoreIcon} from "react-icons/md"

import {getFontFamilies, googleFontFamily} from "lib/fonts"

import DOSelect, {DOReduxFormSelect} from "../do-select/do-select"
import FontDialog from "./font-dialog"

class FontSelector extends Component {
  static propTypes = {
    classes: object.isRequired,
    enableGoogleFonts: bool,
    input: object,
    isReduxForm: bool,
    onSelect: func.isRequired,
    onOpenFontDialog: func,
    placeholder: string,
    value: string,
  }

  state = {isFontDialogOpen: false}

  onOpenFontDialog = () => {
    this.props.onOpenFontDialog?.()
    this.setState({isFontDialogOpen: true})
  }
  onCloseFontDialog = () => this.setState({isFontDialogOpen: false})

  onChange = e => {
    // This is needed because of redux-form shenanigans with the SelectReduxForm
    // component
    if (!this.props.isReduxForm && e && e.target.value)
      this.props.onSelect({family: e.target.value})
  }

  render() {
    const {
      classes,
      enableGoogleFonts,
      isReduxForm,
      placeholder,
      onOpenFontDialog,
      ...props
    } = this.props
    const {isFontDialogOpen} = this.state
    const Select = isReduxForm ? DOReduxFormSelect : DOSelect
    const value = isReduxForm ? props.input.value : props.value
    const googleFontFamilyName = enableGoogleFonts && !!value && googleFontFamily(value)
    const hasPlaceholder = !!placeholder

    return (
      <>
        <FontDialog
          onClose={this.onCloseFontDialog}
          onSelect={this.props.onSelect}
          open={isFontDialogOpen}
        />
        <Select
          className={classes.fontSelect}
          displayEmpty={hasPlaceholder}
          onChange={this.onChange}
          style={{fontFamily: value}}
          {...props}
        >
          {hasPlaceholder && (
            <MenuItem disabled={true} value="">
              {placeholder}
            </MenuItem>
          )}
          {enableGoogleFonts && (
            <MenuItem className={classes.moreFontsMenuItem}>
              {/*
                MUI clones the MenuItem and loses the onClick if we place it there
                so we create an invisible div to catch the onClick inside the MenuItem
              */}
              <div className={classes.moreFonts} onClick={this.onOpenFontDialog} />
              <MoreIcon className={classes.moreFontsIcon} /> More fonts...
            </MenuItem>
          )}
          {getFontFamilies().map(font => (
            <MenuItem key={font.value} style={{fontFamily: font.value}} value={font.value}>
              {font.text || font.fontFamily}
            </MenuItem>
          ))}
          {googleFontFamilyName && <Divider />}
          {googleFontFamilyName && (
            <MenuItem style={{fontFamily: value}} value={value}>
              {googleFontFamilyName}
            </MenuItem>
          )}
        </Select>
      </>
    )
  }
}

const styles = theme => ({
  moreFontsMenuItem: {
    fontFamily: "Roboto",
    position: "relative",
  },
  moreFonts: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  moreFontsIcon: {
    marginRight: theme.spacing(1),
  },
})

export default withStyles(styles)(FontSelector)
