import {FormControlLabel, Grid, InputLabel, MenuItem, Switch, Typography} from "@mui/material"
import {bool, func, object} from "prop-types"

import DOSelect from "components/do-select/do-select"
import Expandable from "components/expandable/expandable"

const PremierSettings = ({isLoading, premierSettings, onChange}) => {
  const handleChange = (field, value) =>
    onChange({...premierSettings, [field]: value, isDirty: true})

  const windowOptions = [
    {label: "30 days", value: "30"},
    {label: "60 days", value: "60"},
    {label: "90 days", value: "90"},
    {label: "120 days", value: "120"},
    {label: "Unlimited", value: "unlimited"},
  ]

  if (isLoading) return <Typography sx={{fontStyle: "italic"}}>Loading...</Typography>

  return (
    <div>
      <div style={{paddingBottom: "0.5em"}}>
        <Expandable label="How we use this section">
          <p>
            The following fields are used to determine which campaign a given objective completion
            is attributed to.
          </p>
          <p>
            <strong>Attribution Window</strong>
          </p>
          <p>
            The attribution window determines how far back we should look when considering which
            campaign should be attributed a completion. If a campaign has not been presented or
            engaged with within the Attribution Window, it will not be eligible for attribution.
          </p>
          <p>
            <strong>Require Engagement</strong>
          </p>
          <p>
            If enabled, a contact must engage with a piece of campaign objective content for the
            campaign to be eligible for attribution. This engagement can take the form of opening a
            message, viewing the journey, using a widget, or other actions that signify contact
            engagement. If not enabled, a contact needs only to be presented the campaign objective
            content by being enrolled in the campaign.
          </p>
        </Expandable>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <InputLabel htmlFor="premier-settings-attribution-window">Attribution Window</InputLabel>
          <DOSelect
            style={{width: 200}}
            displayEmpty={true}
            renderValue={value => windowOptions.find(o => o.value === value)?.label}
            id="premier-settings-attribution-window"
            name="premier-settings-attribution-window"
            onChange={e => handleChange("attribution_window", e.target.value)}
            // Defaulting to "" to make this input controlled rather than uncontrolled.
            value={premierSettings?.attribution_window || ""}
          >
            {windowOptions.map(({label, value}) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </DOSelect>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={premierSettings?.engagement_threshold === "engaged"}
                onChange={e => {
                  handleChange("engagement_threshold", e.target.checked ? "engaged" : "presented")
                }}
              />
            }
            label="Require Engagement"
          />
        </Grid>
      </Grid>
    </div>
  )
}

PremierSettings.propTypes = {
  isLoading: bool,
  onChange: func.isRequired,
  premierSettings: object,
}

export default PremierSettings
