import {TableCell, TableRow, Typography} from "@mui/material"
import orderBy from "lodash/orderBy"
import {func, number, string} from "prop-types"
import React, {useCallback, useEffect, useMemo} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchAdvancedInsightsTotalValueOfOpenedProducts} from "lib/api"

const TotalValueOfOpenedProducts = ({
  itemsPerPage,
  page,
  setTableState,
  sortColumn,
  sortDirection,
  totalCount,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setTableStateMemo = useMemo(() => setTableState, [])
  const {
    totalValueOfOpenedProducts,
    totalValueOfOpenedProductsIsLoading,
    fetchInsights,
    filterParams,
    hasSocket,
  } = useAdvancedTeamInsights()

  // Fetch data
  useEffect(() => {
    if (hasSocket) {
      fetchInsights("totalValueOfOpenedProducts", fetchAdvancedInsightsTotalValueOfOpenedProducts, [
        {...filterParams},
      ])
    }
  }, [fetchInsights, filterParams, hasSocket])

  // Transform data
  const data = useMemo(
    () =>
      (totalValueOfOpenedProducts?.data || []).map(product => ({
        ...product,
        totalValue5: Math.round(product.totalValue * 1.05),
        totalValue10: Math.round(product.totalValue * 1.1),
      })),
    [totalValueOfOpenedProducts]
  )

  const refresh = useCallback(
    attrs => {
      const normalizedAttrs = {
        itemsPerPage: attrs?.itemsPerPage ?? itemsPerPage,
        page: attrs?.page ?? page,
        sortColumn: attrs?.sortColumn ?? sortColumn,
        sortDirection: attrs?.sortDirection ?? sortDirection,
        totalCount: data.length ?? totalCount,
      }
      const sortDataFor = attrs => {
        const sorted = orderBy(data, [attrs.sortColumn], [attrs.sortDirection])
        const pageStartIndex = attrs.itemsPerPage * attrs.page

        return pageStartIndex + attrs.itemsPerPage > totalCount
          ? sorted.slice(pageStartIndex)
          : sorted.slice(pageStartIndex, pageStartIndex + attrs.itemsPerPage)
      }

      setTableStateMemo({...normalizedAttrs, rows: sortDataFor(normalizedAttrs)})
    },
    [itemsPerPage, page, sortColumn, sortDirection, setTableStateMemo, totalCount, data]
  )

  useEffect(() => {
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalValueOfOpenedProducts, refresh])

  return (
    <div>
      <Typography variant="h5" sx={{marginBottom: 2}}>
        Total Value of Opened Products
      </Typography>
      <DOTable
        rows={data}
        headers={[
          {field: "name", label: "Product", sortable: true},
          {field: "totalValue", label: "Current", sortable: true},
          {field: "totalValue5", label: "5% lift"},
          {field: "totalValue10", label: "10% lift"},
        ]}
        isLoading={totalValueOfOpenedProductsIsLoading}
        idField="productId"
        noResults="No data found."
        paginationEnabled={true}
        refresh={refresh}
      >
        {(row, index, columns) => (
          <TableRow key={index}>
            {columns.map(col => (
              <TableCell key={col.field}>
                {col.field.startsWith("totalValue")
                  ? `$${parseFloat(row[col.field]).toLocaleString("en-US")}`
                  : row[col.field]}
              </TableCell>
            ))}
          </TableRow>
        )}
      </DOTable>
    </div>
  )
}
TotalValueOfOpenedProducts.propTypes = {
  itemsPerPage: number,
  page: number,
  setTableState: func.isRequired,
  sortColumn: string,
  sortDirection: string,
  totalCount: number,
}

export default tabular({
  sortColumn: "totalValue",
  sortDirection: "desc",
  itemsPerPage: 5,
})(TotalValueOfOpenedProducts)
