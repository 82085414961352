import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"

import Box from "../box/box"
import AccountAvatarSettingsForm from "./account-avatar-settings-form"
import AccountGeneralSettingsForm from "./account-general-settings-form"
import AccountPasswordSettingsForm from "./account-password-settings-form"

const useStyles = makeStyles(theme => ({
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
  },
  section: {
    maxWidth: 740,
    margin: "0 auto",
  },
  avatarSection: {
    textAlign: "center",
  },
  avatarWrapper: {
    width: 250,
    height: 250,
    borderRadius: "100%",
    overflow: "hidden",
    margin: "0 auto",
    "& img": {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  },
  noFile: {
    backgroundColor: "gray",
    textAlign: "center",
    color: "white",
    height: "100%",
    width: "100%",
    paddingTop: 20,
  },
  nextBox: {
    marginTop: theme.spacing(8),
  },
}))

export const AccountSettings = ({currentTeam, currentUser, updateAvatar, updateUser}) => {
  const classes = useStyles()

  return (
    <>
      <Box>
        {currentUser && (
          <AccountAvatarSettingsForm
            classes={classes}
            currentUser={currentUser}
            updateAvatar={updateAvatar}
          />
        )}
      </Box>

      <Box className={classes.nextBox}>
        {currentUser && (
          <div className={classes.section}>
            <AccountGeneralSettingsForm
              classes={classes}
              initialValues={currentUser}
              onSubmit={updateUser}
            />
          </div>
        )}
      </Box>

      <Box className={classes.nextBox}>
        {currentUser && (
          <div className={classes.section}>
            <AccountPasswordSettingsForm
              classes={classes}
              passwordPolicy={currentTeam.passwordPolicy}
              onSubmit={updateUser}
            />
          </div>
        )}
      </Box>
    </>
  )
}

AccountSettings.propTypes = {
  currentTeam: object,
  currentUser: object,
  updateAvatar: func.isRequired,
  updateUser: func.isRequired,
}

export default AccountSettings
