import {Typography} from "@mui/material"

import AccessControlled from "components/access-control/access-controlled"
import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import NotificationSettings from "components/teams/notifications/notification-settings"
import WebhookSettings from "components/teams/notifications/webhook-settings"

const Notifications = () => {
  return (
    <>
      <DocumentTitle title="Team Settings - Notifications" />

      <Typography variant="h5">Notification Settings</Typography>
      <Box sx={{mb: 4}}>
        <NotificationSettings />
      </Box>

      <AccessControlled hideIfNotPermitted requiredPermissions="team_settings:api">
        <Typography variant="h5">Webhook Settings</Typography>
        <Box>
          <WebhookSettings />
        </Box>
      </AccessControlled>
    </>
  )
}

export default Notifications
