import queryString from "query-string"
import {connect} from "react-redux"

import {getCsvExport, getSurveyAnswers} from "../templates/template-actions"
import TemplateJourneys from "./template-journeys"

const mapStateToProps = ({session: {teamUsers}}) => ({
  users: teamUsers,
  batchId: queryString.parse(window.location.search).batch_id,
})

const addActionsToProps = {
  getCsvExport,
  getSurveyAnswers,
}

export default connect(mapStateToProps, addActionsToProps)(TemplateJourneys)
