import {Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {object} from "prop-types"

import Box from "../box/box"
import DocumentTitle from "../document-title/document-title"
import SmsSettings from "./sms-settings"
import SmtpSettings from "./smtp-settings"

const MessagingSettings = ({classes}) => (
  <>
    <DocumentTitle title="Team Settings - Messaging" />
    <Typography variant="h5">Email</Typography>
    <Box>
      <SmtpSettings />
    </Box>
    <div className={classes.spacer} />
    <Typography variant="h5">SMS</Typography>
    <Box>
      <SmsSettings />
    </Box>
  </>
)

MessagingSettings.propTypes = {
  classes: object.isRequired,
}

export default withStyles(theme => ({
  spacer: {
    height: theme.spacing(4),
  },
}))(MessagingSettings)
