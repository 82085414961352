import {TextField} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {object} from "prop-types"
import {useEffect, useState} from "react"

import {controlAccess} from "components/access-control/access-controlled"
import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import SaveButton from "components/save-button/save-button"

import {createHandler, useSocket} from "contexts/socket-manager"
import {loadTeamSeeding} from "lib/api"
import {nonEmptyString} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const initialValues = {yamlString: ""}

const TeamSeeding = ({classes}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [seedingMessage, setSeedingMessage] = useState("")

  const {addHandler, removeHandler} = useSocket()

  const submitYaml = yaml => {
    loadTeamSeeding({yaml: yaml})
  }

  const {change, field, invalid, handleSubmit, submitting} = useForm({
    onSubmit: () => {
      submitYaml(field("yamlString").value)
      setHasSubmitted(true)
    },
    initialValues,
    validators: {
      yamlString: [nonEmptyString],
    },
  })

  const canSubmit = !hasSubmitted && field("yamlString").value !== ""

  const handleYamlChange = () => e => {
    change("yamlString", e.target.value)
    setHasSubmitted(true)
    setSeedingMessage("")
  }

  useEffect(() => {
    const teamSeedingHandler = createHandler(
      "team-seeding",
      "team_seeding_processed",
      seedingMessage => {
        setSeedingMessage(!!seedingMessage.ok ? seedingMessage.ok : seedingMessage.error)
        setHasSubmitted(false)
      }
    )

    addHandler(teamSeedingHandler)

    return () => removeHandler(teamSeedingHandler)
  }, [addHandler, removeHandler])

  return (
    <Box>
      <DocumentTitle title="Team Dashboard - Seeding" />
      <form aria-labelledby="yaml-seeder-input" onSubmit={handleSubmit}>
        <TextField
          className={classes.field}
          disabled={hasSubmitted}
          fullWidth={true}
          helperText="Paste YAML string to seed team data"
          id="yamlString"
          InputLabelProps={{shrink: true}}
          label="YAML"
          multiline={true}
          onChange={handleYamlChange}
          rows="20"
          variant="outlined"
          {...field("yamlString", {except: ["onChange"]})}
        />
        <div className={classes.formActions}>
          <SaveButton
            disabled={!canSubmit || invalid}
            stateLabels={{default: "Submit YAML"}}
            submitting={submitting}
          />
        </div>
      </form>
      <TextField
        className={classes.field}
        disabled
        fullWidth={true}
        helperText="Team seeding message"
        id="teamSeedingMessage"
        InputLabelProps={{shrink: true}}
        label="Seeding Message"
        multiline={true}
        rows="7"
        variant="outlined"
        value={seedingMessage}
      />
    </Box>
  )
}

TeamSeeding.propTypes = {
  classes: object,
}

const styles = {
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
}

export default withStyles(styles)(
  controlAccess({requiredPermissions: "team_settings:sftp"})(TeamSeeding)
)
