/****************
apiActionCreator is designed to remove boilerplate code from simple async actions

Arguments:
 - type: (string) The action type. ex. 'JOURNEY_CREATED'
 - controller: (function) the api function. ex. 'createJourney'
 - options: (object - optional)
    - isRemoveFromList: (boolean) in case your delete is removing from a list
****************/

export default function apiActionCreator(type, controller, options = {}) {
  return (...args) => dispatch =>
    controller(...args)
      .then(response =>
        dispatch({type, payload: options.isRemoveFromList ? {id: args[0]} : response})
      )
      .catch(error => {
        if (error.message === "invalid_token") return
        else throw error
      })
}
