import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import isEqual from "lodash/isEqual"
import {useState} from "react"

import MaybeTooltip from "lib/maybe-tooltip"

import {history} from "../../reducers/store"
import DangerButton from "../danger-button/danger-button"
import SaveButton from "../save-button/save-button"
import SuperheroBuilder5 from "../superhero/builder-5"
import AddMessagesStep from "./add-messages-step"
import AddPageStep from "./add-page-step"
import {addObjectiveRow, setActiveStep, useCampaignBuilder} from "./campaign-builder-context"
import ObjectiveStep from "./objective-step"
import ReviewStep from "./review-step"
import saveCampaignFromState from "./save-campaign-from-state"
import TemplateStep from "./template-step"

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.background.default,
  },
  content: {
    paddingTop: theme.spacing(2),
    background: "transparent",
    paddingBottom: 0,
    position: "relative",
  },
  stepper: {
    padding: `${theme.spacing(3)} 0 ${theme.spacing(2)}`,
  },
  messagesStepContent: {
    paddingBottom: 20,
  },
  finishStepSuperHero: {
    position: "absolute",
    bottom: 52,
    right: 0,
    width: 320,
  },
  header: {
    background: "white",
    zIndex: 1,
    boxShadow: "0px -8px 10px 8px rgba(0,0,0,0.2)",
  },
  footer: {
    zIndex: 1,
    background: "white",
    boxShadow: "0px 8px 10px 8px rgba(0,0,0,0.2)",
    display: "block",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // replicates MUI DialogActions spacing
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  clearButton: {
    marginRight: "auto",
  },
  error: {
    textAlign: "right",
    color: theme.palette.error.main,
    paddingTop: theme.spacing(1),
  },
  menuItem: {
    boxSizing: "border-box",
    height: "auto",
    maxWidth: 252,
    whiteSpace: "normal",
  },
  listItemTextSecondary: {
    color: "#7a7b7f",
  },
}))

const CampaignBuilder = () => {
  const classes = useStyles()
  const {
    isOpen,
    onClose,
    state,
    clearState,
    defaultState,
    currentRow,
    otherRows,
    dispatch,
    content,
  } = useCampaignBuilder()

  const [isSaving, setIsSaving] = useState(false)
  const [didError, setDidError] = useState(false)
  const [isConfirmClearOpen, setIsConfirmClearOpen] = useState(false)
  const {activeStep} = state
  const {
    Component: StepComponent,
    key: stepKey,
    getError,
    SuperHeroComponent,
    getCanSkipStep,
  } = steps[activeStep]
  const isFirstStep = activeStep <= 0
  const isLastStep = activeStep + 1 >= steps.length
  const currentStepError = getError?.(state)

  const save = () => {
    setIsSaving(true)
    saveCampaignFromState(state)
      .then(template => {
        setIsSaving(false)
        onClose()
        clearState()
        history.push(`/admin/templates/${template.id}/content`)
      })
      .catch(() => {
        setIsSaving(false)
        setDidError(true)
      })
  }

  const tryClose = () => {
    if (isEqual(state, defaultState)) onClose()
    else setIsConfirmClearOpen(true)
  }

  const clearAndClose = () => {
    clearState()
    closeImmediately()
  }

  const closeImmediately = () => {
    setIsConfirmClearOpen(false)
    onClose()
  }

  const onNextStep = e => {
    e.preventDefault()

    if (currentStepError) return

    if (isLastStep) save()
    else dispatch(setActiveStep(activeStep + 1))
  }

  return (
    <>
      <Dialog
        classes={{paper: classes.paper}}
        fullWidth={true}
        maxWidth="lg"
        onClose={onClose}
        open={isOpen}
        scroll="paper"
      >
        {SuperHeroComponent && (
          <SuperHeroComponent className={classes[`${stepKey}StepSuperHero`]} />
        )}
        <DialogTitle classes={{root: classes.header}}>
          Campaign Builder
          <Stepper activeStep={activeStep} classes={{root: classes.stepper}}>
            {steps.map(step => (
              <Step key={step.key} completed={step.getIsCompleted(content, state)}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>
        <DialogContent
          classes={{root: cx(classes.content, classes[`${stepKey}StepContent`])}}
          dividers={true}
        >
          <form id="campaign-builder" onSubmit={onNextStep}>
            <StepComponent
              currentRow={currentRow}
              dispatch={dispatch}
              otherRows={otherRows}
              wizardState={state}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <div className={classes.actions}>
            <Button className={classes.clearButton} color="grey" onClick={tryClose} tabIndex={-1}>
              Close
            </Button>
            <Button
              color="grey"
              disabled={isFirstStep || isSaving}
              onClick={() => dispatch(setActiveStep(activeStep - 1))}
              tabIndex={1}
            >
              Previous
            </Button>
            {!isLastStep ? (
              <MaybeTooltip isTooltip={!!currentStepError} title={currentStepError}>
                <Button
                  color="grey"
                  disabled={!!currentStepError}
                  form="campaign-builder"
                  tabIndex={0}
                  type="submit"
                >
                  {getCanSkipStep && getCanSkipStep(currentRow) ? "Skip" : "Next"}
                </Button>
              </MaybeTooltip>
            ) : (
              <>
                <Button
                  color="grey"
                  disabled={isSaving}
                  onClick={() => dispatch(addObjectiveRow())}
                  variant="outlined"
                >
                  Add another Objective
                </Button>
                <SaveButton
                  color="primary"
                  failed={didError}
                  form="campaign-builder"
                  stateLabels={{
                    default: "Finish",
                    submitting: "Creating Campaign...",
                    saved: "Campaign Created!",
                  }}
                  submitting={isSaving}
                  variant="contained"
                >
                  Finish
                </SaveButton>
              </>
            )}
          </div>
          <Collapse in={didError}>
            <div className={classes.error}>
              <Typography variant="caption">
                Sorry, something went wrong when creating your campaign. We've saved your current
                progress. Please try again later.
              </Typography>
            </div>
          </Collapse>
        </DialogActions>
      </Dialog>
      {isConfirmClearOpen && (
        <Dialog maxWidth="lg" onClose={() => setIsConfirmClearOpen(false)} open={true}>
          <DialogTitle>Clear campaign builder?</DialogTitle>
          <DialogContent>
            Do you want to cancel creating your campaign? Changes in the campaign builder will be
            lost.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsConfirmClearOpen(false)}>Stay in Campaign Builder</Button>
            <DangerButton onClick={clearAndClose}>Clear Changes & close</DangerButton>
            <Button onClick={closeImmediately} variant="outlined">
              Close and Continue later
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

const isFeatureEnabled = (state, feature) => {
  return state.campaignFeatures?.includes(feature)
}

const templateStepIsComplete = state => {
  return (
    !!state.campaignName &&
    (isFeatureEnabled(state, "campaign-audience") ? !!state.campaignAudience : true) &&
    (isFeatureEnabled(state, "campaign-use-case") ? !!state.campaignUseCase : true)
  )
}

const templateStepError = state => {
  if (!state.campaignName) {
    return "Name your campaign to continue."
  } else if (isFeatureEnabled(state, "campaign-use-case") && !state.campaignUseCase) {
    return "Select a campaign use case to continue."
  } else if (isFeatureEnabled(state, "campaign-audience") && !state.campaignAudience) {
    return "Select a campaign audience to continue."
  } else {
    return ""
  }
}

const steps = [
  {
    Component: TemplateStep,
    key: "name",
    label: "Name",
    getIsCompleted: (_content, state) => templateStepIsComplete(state),
    getError: state => templateStepError(state),
  },
  {
    Component: ObjectiveStep,
    key: "objective",
    label: "Add an Objective",
    getIsCompleted: (_content, state) => !!state.objectiveRows[0].objective?.id,
  },
  {
    Component: AddPageStep,
    key: "page",
    label: "Add a Page",
    getIsCompleted: (content, _state) => content.hasPage,
    getCanSkipStep: currentRow => !currentRow?.page,
  },
  {
    Component: AddMessagesStep,
    key: "messages",
    label: "Add Messages",
    getIsCompleted: (content, _state) => content.hasMessage,
    getCanSkipStep: currentRow => !currentRow?.messages.length,
  },
  {
    Component: ReviewStep,
    key: "finish",
    label: "Finish",
    getIsCompleted: (_content, _state) => false,
    SuperHeroComponent: SuperheroBuilder5,
  },
]

export default CampaignBuilder
