import {Box, Button, Tooltip, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, string} from "prop-types"
import {useState} from "react"

import {TemplateListPreview} from "../dialogs/template-list-preview"

const SharedMessageNotice = ({
  editingTemplateId,
  forceClose,
  messageId,
  message,
  onShowDuplicateConfirmation,
  title,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const classes = useStyles()

  const onDuplicate = () => {
    onShowDuplicateConfirmation()
    setTooltipOpen(false)
  }

  const onOpen = () => {
    if (forceClose || tooltipOpen) return

    setTooltipOpen(true)
  }

  const onClose = () => setTooltipOpen(false)

  return (
    <Tooltip
      classes={{tooltip: classes.tooltipPaper}}
      className={classes.tooltip}
      leaveDelay={750}
      onClose={onClose}
      onOpen={onOpen}
      open={tooltipOpen}
      title={
        <>
          <Typography className={classes.tooltipBody} variant="body2">
            {message}
          </Typography>
          <TemplateListPreview
            editingTemplateId={editingTemplateId}
            id={messageId}
            newSlug={message.newSlug}
            recordType="message"
            variant="body2"
          />
          {!!onShowDuplicateConfirmation && (
            <Button fullWidth={true} onClick={onDuplicate} size="small" variant="contained">
              Make a Unique Copy
            </Button>
          )}
        </>
      }
    >
      <Box className={classes.sharedNotice}>{title}</Box>
    </Tooltip>
  )
}

SharedMessageNotice.propTypes = {
  editingTemplateId: string,
  forceClose: bool,
  message: string.isRequired,
  messageId: string.isRequired,
  onShowDuplicateConfirmation: func,
  title: string.isRequired,
}

SharedMessageNotice.defaultProps = {
  forceClose: false,
}

const useStyles = makeStyles(theme => ({
  sharedNotice: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    textAlign: "center",
    textTransform: "uppercase",
  },
  tooltipBody: {
    marginBottom: theme.spacing(1),
  },
  tooltipPaper: {
    padding: theme.spacing(2),
  },
}))

export default SharedMessageNotice
