import debounce from "lodash/debounce"
import set from "lodash/set"
import {useCallback, useEffect, useRef, useState} from "react"

import wrapDisplayName from "lib/wrap-display-name"

import SubmissionError from "../submission-error"
import {
  checkFormValidity,
  field,
  initialize,
  inputStateFromErrors,
  noChange,
  reduceErrors,
  typedValue,
  untypedValue,
  valid,
} from "./use-form-helpers"

// We need referential equality across renders when initial values have been
// defaulted so we standup an initial values object.
const INITIAL_VALUES = {}
const PARSE = {}
const UNPARSE = {}
const AUTO_SUBMIT_DEBOUNCE_TIME = 2000
const ONCHANGE_DEBOUNCE_TIME = 500

const debounceTypes = ["number", "text", "textarea"]

const noop = () => {}

const valuesFromInputsState = (inputsState, parse, skipUndefined) =>
  Object.keys(inputsState).reduce((acc, name) => {
    const parser = typeof parse === "function" ? parse : parse[name] || noChange
    const value = untypedValue(inputsState[name])

    if (skipUndefined && value === undefined) return acc

    set(acc, name, parser(value, name))

    return acc
  }, {})

/*
  useForm({initialValues, validators, onChange, onSubmit})

  NOTE: This homegrown useForm code predates React's official useForm hook; we'd like to move
  towards using the official one instead, but we should do so in a coordinated & careful way.

  Arguments:

    - initialValues: an object of default/initial field values to populate by calling `field`.
    - validators: an object where each key is a field name and each value is an array of validator
      functions to test if the current value is valid. The validator function accepts 2 arguments:
      (thisValue, allInputs)
    - disabled: set to TRUE to disable all inputs as well as the submit button.
    - onChange: (optional) a function which will run anytime a form input changes. The 1st argument
      is an object of the current form settings. NB: Be careful about calling `change` within this
      callback; you can run into subtle race conditions & might need a setTimeout to avoid them.
    - onSubmit: a function which will run when the form is submitted, and should handle any data
      persistence, api calls, side effects etc.

  Returns an object with the following keys:

    - field: a function which returns props/attrs for a given input (name, onChange, etc). Call it
      with the spread operator as shown below. Its 1st argument is the field name (required);
      you can optionally also pass an object as the 2nd argument to set the following options:
      * bool: set this to true if this field is a checkbox.
      * defaultValue: the default value to set for this field if no value is defined from initialValues.
      * exclude: an array of attribues that should NOT be present in this function's return object.
      * onChange: a callback which will run when this input value changes.
    - inputs: an object describing each form field's current state: value, errors, etc.
    - change: a function you can call to change a field to a new value. Args: (field, newValue).
    - handleSubmit: provide this as the onSubmit callback on the form input.
    - invalid: a boolean indicating whether this form is currently invalid (according to the
      validators you provided). Pass this to the Submit button's `disabled` prop.
    - submitting: pass this to the Submit button's `submitting` prop.
    - resetForm: optionally provide this as the onClick callback for the form's "Reset" button.

  Example usage:

    const {field, handleSubmit, invalid, resetForm, submitting} = useForm({
      initialValues: {condition: initialCondition, value: initialValue},
      validators: {condition: [validString], value: [requiredField]},
      onChange: settings => setCondition(settings.condition),
      onSubmit: settings => {
        if (updating) {
          updateRewardStep(settings, rewardSetId, rewardStepId).then(RewardStep =>
            history.push(`/admin/rewards/reward-sets/${RewardStep.rewardSetId}`)
          )
        } else {
          createRewardStep(settings, rewardSetId).then(RewardStep =>
            history.push(`/admin/rewards/reward-sets/${RewardStep.rewardSetId}`)
          )
        }
      },
    })

  ... then in your JSX:

    <form onSubmit={handleSubmit}>
      <TextField label="Value" fullWidth={true} type="number" {...field("value")} />

      <FormControl fullWidth={true}>
        <InputLabel htmlFor="condition">Condition</InputLabel>
        <DOSelect id="condition" {...field("condition")}>
          {CONDITION_OPTIONS.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>{opt.text}</MenuItem>
          ))}
        </DOSelect>
      </FormControl>

      <Button color="grey" onClick={resetForm} type="button">Cancel</Button>
      <SaveButton submitting={submitting} disabled={invalid} />
    </form>

*/
export const useForm = ({
  enableReinitialize,
  onChange,
  onChangeDebounceTime = ONCHANGE_DEBOUNCE_TIME,
  onSubmit,
  initialValues = INITIAL_VALUES,
  validators = false,
  autoSubmitOnChange = false,
  autoSubmitDebounceTime = AUTO_SUBMIT_DEBOUNCE_TIME,
  parse = PARSE,
  unparse = UNPARSE,
  skipUndefined = false,
  disabled = false,
}) => {
  const [inputs, setInputs] = useState(() => initialize(initialValues, unparse))
  const [submitting, setSubmitting] = useState(false)
  const [failed, setFailed] = useState(false)
  const isMountedRef = useRef(true)
  const useValidators = !!validators

  useEffect(
    () => () => {
      isMountedRef.current = false
    },
    []
  )

  useEffect(() => {
    if (enableReinitialize) setInputs(initialize(initialValues, unparse))
  }, [enableReinitialize, initialValues, unparse])

  const submit = useCallback(
    async inputsState => {
      if (useValidators) {
        const {localValidationErrors, isValid} = checkFormValidity(inputsState, validators)

        if (!isValid) {
          setInputs(currentInputs =>
            inputStateFromErrors(currentInputs, new SubmissionError(localValidationErrors))
          )
          return
        }
      }

      setSubmitting(true)

      let handled

      try {
        handled = onSubmit(valuesFromInputsState(inputsState, parse, skipUndefined))

        if (handled instanceof Promise) handled = await handled
      } catch (submissionErrors) {
        if (submissionErrors instanceof SubmissionError) {
          setInputs(currentInputs => inputStateFromErrors(currentInputs, submissionErrors))
          setFailed(true)
        } else {
          throw submissionErrors
        }
      } finally {
        if (handled !== false && isMountedRef.current) setSubmitting(false)
      }
    },
    [onSubmit, parse, skipUndefined, useValidators, validators]
  )

  // FIXME ignoring react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(debounce(submit, autoSubmitDebounceTime), [
    submit,
    autoSubmitDebounceTime,
  ])

  const _onChange = onChange || noop

  // FIXME ignoring react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(debounce(_onChange, onChangeDebounceTime), [
    _onChange,
    onChangeDebounceTime,
  ])

  useEffect(() => () => debouncedSubmit.flush(), [debouncedSubmit])

  const handleSubmit = useCallback(
    event => {
      if (event && event.type === "submit") event.preventDefault()

      submit(inputs)
    },
    [inputs, submit]
  )

  const handleInputChange = useCallback(
    ({target}, customValidators) => {
      const {name, type} = target
      const value = typedValue(target)

      setInputs(inputsState => {
        const nextInputsState = {
          ...inputsState,
          [name]: value,
        }

        if (useValidators) {
          const localValidators = Array.isArray(customValidators)
            ? customValidators
            : validators[name]
          const rawErrors = valid(localValidators, name, nextInputsState)
          const fieldErrors = Array.isArray(rawErrors) ? rawErrors.flat() : rawErrors
          const error = !!fieldErrors.length

          nextInputsState[name].error = error

          if (error && fieldErrors.length > 0) nextInputsState[name].helperText = fieldErrors
        }

        if (autoSubmitOnChange)
          if (autoSubmitDebounceTime && debounceTypes.includes(type))
            debouncedSubmit(nextInputsState)
          else {
            debouncedSubmit.cancel()
            submit(nextInputsState)
          }

        if (onChange)
          if (onChangeDebounceTime && debounceTypes.includes(type))
            debouncedChange(valuesFromInputsState(nextInputsState, parse, skipUndefined))
          else {
            debouncedChange.cancel()
            onChange(valuesFromInputsState(nextInputsState, parse, valuesFromInputsState))
          }

        return nextInputsState
      })
    },
    [
      autoSubmitOnChange,
      useValidators,
      validators,
      submit,
      autoSubmitDebounceTime,
      debouncedSubmit,
      onChange,
      onChangeDebounceTime,
      debouncedChange,
      parse,
      skipUndefined,
    ]
  )

  const resetForm = useCallback(() => {
    setInputs(initialize(initialValues, unparse))
    if (autoSubmitOnChange) submit(initialValues)
  }, [autoSubmitOnChange, initialValues, submit, unparse])

  const change = useCallback((name, value) => handleInputChange({target: {name, value}}), [
    handleInputChange,
  ])

  return {
    change,
    failed,
    handleSubmit,
    handleInputChange,
    inputs,
    resetForm,
    submitting,
    initialValues,
    invalid: (useValidators && reduceErrors(inputs)) || disabled,
    field: field(inputs, handleInputChange, disabled),
  }
}

export default useForm

export const formify = (options = {}) => Component => {
  wrapDisplayName(Component, "formify")

  return props => <Component {...props} {...useForm({...props, ...options})} />
}
