import {Grid, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

import CrossChannelBarchart from "components/template-insights/cross-channel-barchart"

import CrossChannelOverview from "./cross-channel-overview"

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginBottom: 40,
  },
  subheader: {
    fontSize: theme.typography.fontSize * 1.75,
    fontWeight: 100,
  },
  coffHeader: {
    marginBottom: 30,
  },
  coffSubheader: {
    fontSize: theme.typography.fontSize * 1.5,
  },
  section: {
    marginBottom: 150,
  },
}))

const CrossChannelPerformance = () => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.header} variant="h5">
        Cross-channel
      </Typography>

      <div className={classes.section}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={12} md={6} lg={5} xl={5}>
            <CrossChannelOverview classes={classes} />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6} lg={7} xl={7}>
            <CrossChannelBarchart classes={classes} />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default CrossChannelPerformance
