import {push} from "connected-react-router"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"

import {TEMPLATE_LOADED, TEMPLATE_PAGES_LOADED, isLoaded, isLoading} from "lib/loading-states"

import {setA11yMessage} from "../../actions/accessability-actions"
import {provideContentBlockEditor} from "../content-block-editor/content-block-editor-context"
import {onUpdatePage} from "../templates/template-actions"
import {
  JOURNEY_COMPONENT_DATA_SET,
  clearComponentData,
  getJourneyComponentData,
  onUpdateContact,
} from "./journey-actions"
import Journey from "./themed-journey"

const mapStateToProps = (
  {journey, template, loadingStates, session: {user}},
  {
    match: {
      params: {journeySlug, pageSlug},
    },
  }
) => ({
  ...journey,
  user,
  template,
  pageSlug,
  journeySlug,
  isComponentDataLoading: isLoading(JOURNEY_COMPONENT_DATA_SET, loadingStates),
  isPageLoaded: isLoaded(TEMPLATE_PAGES_LOADED, loadingStates) && !!pageSlug,
  isTemplateLoaded: isLoaded(TEMPLATE_LOADED, loadingStates),
  pageUrlGenerator: slug => `/journeys/${journeySlug}/${slug}`,
  isEditable: false,
})

const mapDispatchToProps = (
  propDispatch,
  {
    match: {
      params: {journeySlug, pageSlug},
    },
  }
) =>
  bindActionCreators(
    {
      getComponentData: (id, params) => dispatch =>
        dispatch(getJourneyComponentData(id, journeySlug, pageSlug, params)),
      onNavigateToPage: newPageSlug => dispatch =>
        dispatch(push(`/journeys/${journeySlug}/${newPageSlug}`)),
      clearComponentData,
      setA11yMessage,
      onUpdatePage,
      onUpdateContact,
    },
    propDispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(provideContentBlockEditor(Journey))
