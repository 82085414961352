import cx from "classnames"
import {EditorState, RichUtils, convertToRaw} from "draft-js"
import {Map} from "immutable"
import {MegadraftEditor, editorStateFromRaw} from "megadraft"
import {bool, func, node, object, oneOfType, string} from "prop-types"
import {PureComponent, createRef} from "react"
import {
  FaBold as Bold,
  FaHeading as Header,
  FaItalic as Italic,
  FaLink as Link,
  FaListOl as ListOl,
  FaListUl as ListUl,
  FaQuoteLeft as QuoteLeft,
  FaUnderline as Underline,
  FaUnlink as Unlink,
} from "react-icons/fa"

import "./rte.css"

export default class RTE extends PureComponent {
  constructor(props) {
    super(props)

    const {initialState} = props

    let editorState

    if (typeof initialState === "string")
      editorState = editorStateFromRaw({
        entityMap: {},
        blocks: [
          {
            key: "ag6qs",
            text: initialState,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      })
    else if (Map.isMap(initialState)) editorState = editorStateFromRaw(initialState.toJS())
    else editorState = editorStateFromRaw(null)

    this.state = {editorState}
  }
  editorRef = createRef()

  onChange = editorState => {
    this.setState({editorState})
    if (this.props.onChange)
      this.props.onChange({
        target: {
          name: this.props.name,
          value: convertToRaw(editorState.getCurrentContent()),
          htmlValue: this.editorRef.current.editorEl.querySelector("[data-contents]").innerHTML,
        },
      })
  }

  focus = () => {
    this.editorRef.current.focus()
  }

  addLink = () => {
    this.setState({addEntity: !this.state.addEntity})
  }

  noop = () => null

  render() {
    const {readOnly} = this.props

    return (
      <div className={cx("rte", {readOnly})} onClick={readOnly ? this.noop : this.focus}>
        <MegadraftEditor
          editorState={this.state.editorState}
          onChange={this.onChange}
          readOnly={readOnly}
          ref={this.editorRef}
          sidebarRendererFn={this.noop}
          Toolbar={Toolbar}
        />
      </div>
    )
  }
}

RTE.propTypes = {
  initialState: oneOfType([string, object]),
  name: string.isRequired,
  onChange: func.isRequired,
  readOnly: bool,
}

function toggleStyle(event, {inlineStyle, editorState, onChange}) {
  event.stopPropagation()
  onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
}

function toggleBlock(event, {blockStyle, editorState, onChange}) {
  event.stopPropagation()
  onChange(RichUtils.toggleBlockType(editorState, blockStyle))
}

class Toolbar extends PureComponent {
  state = {}

  getCurrentEntityKey() {
    const selection = this.props.editorState.getSelection(),
      anchorKey = selection.getAnchorKey(),
      contentState = this.props.editorState.getCurrentContent(),
      anchorBlock = contentState.getBlockForKey(anchorKey),
      offset = selection.anchorOffset,
      index = selection.isBackward ? offset - 1 : offset

    return anchorBlock.getEntityAt(index)
  }

  getCurrentEntity() {
    const contentState = this.props.editorState.getCurrentContent()
    const entityKey = this.getCurrentEntityKey()

    if (entityKey) return contentState.getEntity(entityKey)

    return null
  }

  hasEntity(entityType) {
    const entity = this.getCurrentEntity()

    if (entity && entity.getType() === entityType) return true

    return false
  }

  addLink = () => this.setState({addLink: !this.state.addLink})
  removeLink = () => this.setLink("")

  onKeyDown = event => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.preventDefault()

      if (event.key === "Enter") this.setLink(event.target.value)
      else this.setState({addLink: false})
    }
  }

  setLink = url => {
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) url = `http://${url}` // eslint-disable-line no-param-reassign

    const {editorState, onChange} = this.props,
      contentState = editorState.getCurrentContent(),
      contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {url}),
      entityKey = contentStateWithEntity.getLastCreatedEntityKey(),
      newState = RichUtils.toggleLink(
        editorState,
        editorState.getSelection(),
        url ? entityKey : null
      ),
      selectionState = EditorState.forceSelection(newState, editorState.getSelection())

    onChange(selectionState)
    this.setState({addLink: false})
  }

  render() {
    const {props} = this,
      {addLink} = this.state,
      entity = this.getCurrentEntity(),
      isLink = this.hasEntity("LINK"),
      isCollapsed = props.editorState.getSelection().isCollapsed()

    if (props.readOnly) return null

    return (
      <div className="toolbar">
        <ToolbarItem flex={true} />
        <ToolbarItem blockStyle="header-two" title="Header" {...props}>
          <Header />
        </ToolbarItem>
        <ToolbarItem separator={true} />
        <ToolbarItem inlineStyle="BOLD" title="Bold" {...props}>
          <Bold />
        </ToolbarItem>
        <ToolbarItem inlineStyle="ITALIC" title="Italic" {...props}>
          <Italic />
        </ToolbarItem>
        <ToolbarItem inlineStyle="UNDERLINE" title="Underline" {...props}>
          <Underline />
        </ToolbarItem>
        <ToolbarItem separator={true} />
        <ToolbarItem blockStyle="unordered-list-item" title="Unordered List" {...props}>
          <ListUl />
        </ToolbarItem>
        <ToolbarItem blockStyle="ordered-list-item" title="Ordered List" {...props}>
          <ListOl />
        </ToolbarItem>
        <ToolbarItem blockStyle="blockquote" title="Block Quote" {...props}>
          <QuoteLeft />
        </ToolbarItem>
        <ToolbarItem separator={true} />
        <ToolbarItem
          disabled={props.editorState.getSelection().isCollapsed()}
          isActive={addLink || this.hasEntity("LINK")}
          onClick={this.addLink}
          title="Insert Link"
          {...props}
        >
          <Link />
        </ToolbarItem>
        <ToolbarItem
          isActive={true}
          onClick={this.removeLink}
          style={{
            opacity: isLink && !isCollapsed ? 1 : 0,
            pointerEvents: isLink && !isCollapsed ? "all" : "none",
          }}
          title="Remove Link"
          {...props}
        >
          <Unlink />
        </ToolbarItem>
        <ToolbarItem flex={true} />
        {addLink && (
          <div className="secondary">
            Link
            <input
              autoFocus={true}
              defaultValue={entity && entity.get("data").url}
              onKeyDown={this.onKeyDown}
              placeholder="Type the link and press enter"
              type="text"
            />
          </div>
        )}
      </div>
    )
  }
}

Toolbar.propTypes = {
  editorState: object,
  onChange: func.isRequired,
  readOnly: bool,
}

class ToolbarItem extends PureComponent {
  onMouseDown = event => event.preventDefault()
  onClick = e => {
    this.props.onClick ? this.props.onClick() : this.toggle(e, this.props)
  }
  toggle = () => {}

  render() {
    const {
      separator,
      flex,
      editorState,
      disabled,
      isActive,
      inlineStyle,
      blockStyle,
      style,
      title,
      children,
    } = this.props

    if (separator) return <div className="separator" />

    if (flex) return <div className="flex" />

    let active = isActive

    if (inlineStyle) {
      this.toggle = toggleStyle
      active = editorState.getCurrentInlineStyle().has(inlineStyle)
    } else if (blockStyle) {
      const selection = editorState.getSelection()

      this.toggle = toggleBlock
      active =
        editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType() ===
        blockStyle
    }

    return (
      <button
        className={cx({disabled, active})}
        onClick={this.onClick}
        onMouseDown={this.onMouseDown}
        style={style}
        title={title}
        type="button"
      >
        {children}
      </button>
    )
  }
}

ToolbarItem.propTypes = {
  blockStyle: string,
  children: node,
  disabled: bool,
  editorState: object,
  flex: bool,
  inlineStyle: string,
  isActive: bool,
  onClick: func,
  separator: bool,
  style: object,
  title: string,
}
