import {Badge} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {exact, number, string} from "prop-types"
import {FaRegEnvelope as EmailIcon, FaRegFileAlt as PageIcon} from "react-icons/fa"
import {FiMessageSquare as SMSIcon} from "react-icons/fi"

import pluralize from "lib/string/pluralize"

const ObjectiveCounters = ({counters, name}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {counters.pages ? (
        <Badge
          badgeContent={counters.pages}
          classes={{badge: classes.badgePage}}
          className={classes.badge}
          invisible={counters.pages < 2}
          title={`${name} is associated with ${counters.pages} ${pluralize(
            "page",
            counters.pages
          )}`}
        >
          <PageIcon className={classes.type} size={20} />
        </Badge>
      ) : (
        ""
      )}
      {counters.emails ? (
        <Badge
          badgeContent={counters.emails}
          classes={{badge: classes.badgeEmail}}
          className={classes.badge}
          invisible={counters.emails < 2}
          title={`${name} is associated with ${counters.emails} ${pluralize(
            "email",
            counters.emails
          )}`}
        >
          <EmailIcon className={classes.type} size={20} />
        </Badge>
      ) : (
        ""
      )}
      {counters.smses ? (
        <Badge
          badgeContent={counters.smses}
          classes={{badge: classes.badgeSms}}
          className={classes.badge}
          invisible={counters.smses < 2}
          title={`${name} is associated with ${counters.smses} ${pluralize(
            "SMS",
            counters.smses,
            "es"
          )}`}
        >
          <SMSIcon className={classes.type} size={20} />
        </Badge>
      ) : (
        ""
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  badge: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  badgeEmail: {
    backgroundColor: theme.palette.brand.lightBlue,
    color: theme.palette.primary.contrastText,
  },
  badgePage: {
    backgroundColor: theme.palette.brand.darkBlue,
    color: theme.palette.primary.contrastText,
  },
  badgeSms: {
    backgroundColor: theme.palette.brand.lightGreen,
    color: theme.palette.primary.contrastText,
  },
  container: {
    display: "inline-block",
    marginLeft: theme.spacing(2),
  },
  type: {
    color: theme.palette.brand.darkBlue,
  },
}))

export const CountersProptype = exact({
  emails: number.isRequired,
  pages: number.isRequired,
  smses: number.isRequired,
})

ObjectiveCounters.propTypes = {
  counters: CountersProptype.isRequired,
  name: string.isRequired,
}

export default ObjectiveCounters
