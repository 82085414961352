import {
  Box,
  Button,
  Collapse,
  LinearProgress,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {arrayOf, bool, func, node, number, object, shape, string} from "prop-types"

import {formatDateTime} from "lib/date-time-formatters"
import {fullName} from "lib/names"
import humanize from "lib/string/humanize"

import BatchResults from "./batch-results"

export const nameForUser = user => {
  if (!user) return ""
  if (user && (user.nameFirst || user.nameLast)) return fullName(user)
  if (user && user.email) return user.email
  return ""
}

const LinearProgressWithLabel = props => (
  <Box alignItems="center" display="flex">
    <Box mr={1} width="100%">
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box minWidth={35}>
      <Typography color="textSecondary" variant="body2">{`${Math.round(props.value)}%`}</Typography>
    </Box>
  </Box>
)

LinearProgressWithLabel.propTypes = {
  value: number,
}

const useHideableBorderCellStyles = makeStyles({
  hiddenBorder: {
    borderBottom: "none !important",
  },
})

const HideableBorderCell = ({hideBorder, children, ...props}) => {
  const classes = useHideableBorderCellStyles()

  return (
    <TableCell classes={{root: cx({[classes.hiddenBorder]: hideBorder})}} {...props}>
      {children}
    </TableCell>
  )
}

HideableBorderCell.propTypes = {
  children: node,
  hideBorder: bool,
}

const useStyles = makeStyles({
  expandableRow: {
    cursor: "pointer",
  },
})

const BatchProcessingRow = ({
  row: {type, batchId, batches, insertedAt, source, user},
  columns,
  onClick,
  onDelete,
  isActiveRow,
}) => {
  const classes = useStyles()

  const onClickRow = () => {
    onClick(batchId)
  }

  const onClickDelete = () => {
    onDelete(batchId)
  }
  const completedBatches = batches.filter(b => b.isComplete)

  const allBatchesFinished = completedBatches.length === batches.length

  return (
    <>
      <Tooltip title={`${isActiveRow ? "Hide" : "Show"} batch details`}>
        <TableRow className={classes.expandableRow} hover={true}>
          <HideableBorderCell hideBorder={isActiveRow} onClick={onClickRow}>
            {humanize(source)}
          </HideableBorderCell>
          <HideableBorderCell hideBorder={isActiveRow} onClick={onClickRow}>
            {batchId}
          </HideableBorderCell>
          <HideableBorderCell hideBorder={isActiveRow} onClick={onClickRow}>
            {humanize(type)}
          </HideableBorderCell>
          <HideableBorderCell hideBorder={isActiveRow} onClick={onClickRow}>
            {nameForUser(user)}
          </HideableBorderCell>
          <HideableBorderCell hideBorder={isActiveRow} onClick={onClickRow}>
            {formatDateTime(insertedAt)}
          </HideableBorderCell>
          <HideableBorderCell
            className={classes.progressCell}
            hideBorder={isActiveRow}
            onClick={onClickRow}
          >
            <LinearProgressWithLabel
              value={(batches.length > 0 ? completedBatches.length / batches.length : 0) * 100}
            />
          </HideableBorderCell>
          <HideableBorderCell
            hideBorder={isActiveRow}
            onClick={allBatchesFinished ? onClickRow : null}
          >
            <Button color="grey" disabled={allBatchesFinished} onClick={onClickDelete}>
              {allBatchesFinished ? "Completed" : "Cancel Processing"}
            </Button>
          </HideableBorderCell>
        </TableRow>
      </Tooltip>
      <TableRow>
        <TableCell colSpan={columns.length} style={{paddingBottom: 0, paddingTop: 0}}>
          <Collapse in={isActiveRow} unmountOnExit={true}>
            <BatchResults batches={batches} type={type} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

BatchProcessingRow.propTypes = {
  columns: arrayOf(object),
  isActiveRow: bool,
  onClick: func,
  onDelete: func,
  row: shape({
    type: string,
    batchId: string,
    batches: arrayOf(object),
    insertedAt: string,
    source: string,
    user: object,
  }),
}

export default BatchProcessingRow
