import {
  SMTP_CREDENTIALS_FAILED,
  SMTP_CREDENTIALS_LOADING,
  SMTP_CREDENTIALS_SUCCESS,
  SMTP_TEST_STATUS_CLEARED,
} from "./team-actions"

function emailSettings(state = {}, action) {
  switch (action.type) {
    case SMTP_CREDENTIALS_SUCCESS:
      return {
        ...state,
        status: "success",
        message: "Success!",
      }

    case SMTP_CREDENTIALS_LOADING:
      return {
        ...state,
        status: "testing",
        message: "testing...",
      }

    case SMTP_CREDENTIALS_FAILED:
      return {
        ...state,
        status: "failed",
        message: `Failed! (${action.payload})`,
      }

    case SMTP_TEST_STATUS_CLEARED:
      return {
        ...state,
        status: null,
        message: null,
      }

    default:
      return state
  }
}

export default emailSettings
