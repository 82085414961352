export const initialState = {rewardSets: [], templates: []}

export const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_REWARD_SET":
      return {...state, rewardSets: [...state.rewardSets, action.rewardSet]}
    case "DELETE_REWARD_SET":
      return {...state, rewardSets: state.rewardSets.filter(({id}) => id !== action.rewardSetId)}
    case "SET_REWARD_SETS":
      return {...state, rewardSets: action.rewardSets}
    case "SET_TEMPLATES":
      return {...state, templates: action.templates}
    case "UPDATE_REWARD_SET":
      return {
        ...state,
        rewardSets: state.rewardSets.map(rewardSet =>
          rewardSet.id === action.rewardSet.id ? action.rewardSet : rewardSet
        ),
      }
    default:
      throw new Error(action.type)
  }
}
