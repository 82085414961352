import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Drawer,
  FormControl,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import pick from "lodash/pick"
import {bool, func, object, shape, string} from "prop-types"
import {Component} from "react"
import {MdExpandMore as ExpandIcon} from "react-icons/md"
import {withRouter} from "react-router"

import {DeviceControlsSidebar} from "components/content-block-editor/device-controls"
import DuplicateConfirmationDialog from "components/duplicate-confirmation-dialog/duplicate-confirmation-dialog"
import SharedContentNotice from "components/shared-content-notice/shared-content-notice"
import ContainerCssSettings from "components/templates/container-css-settings"
import ContainerStyleSettings from "components/templates/container-style-settings"

import {featurify} from "lib/hooks/use-features"
import withDispatch from "lib/with-dispatch"

import TemplatePageContext from "../../contexts/template-page-context"
import {
  EditorModes,
  contentBlockEditorConsumer,
} from "../content-block-editor/content-block-editor-context"
import DangerButton from "../danger-button/danger-button"
import ConfirmDialog from "../dialogs/confirm-dialog"
import {TemplateListPreview} from "../dialogs/template-list-preview"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import WidgetSettings from "../templates/widget-settings"
import GeneralMessageSettings from "./general-message-settings"
import MessageTestSettingsPanel from "./message-test-settings-panel"

const drawerWidth = 300

class MessageSidebar extends Component {
  state = {
    tabIndex: 1,
    expandedPanel: "general",
    isUnsavedContentBlockConfirmDialogOpen: false,
    pendingTabIndex: null,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selectedBlock && this.props.selectedBlock) this.forceWidgetTabSelection()
    if (prevProps.selectedBlock && !this.props.selectedBlock) this.unforceWidgetTabSelection()
  }

  forceWidgetTabSelection = () => {
    this.previousTabIndex = this.state.tabIndex
    this.onTabChange(null, 0)
  }

  unforceWidgetTabSelection = () => {
    if (this.state.tabIndex === 0) this.onTabChange(null, this.previousTabIndex)
  }

  onTabChange = (e, tabIndex) => {
    const areEditsInSidebar = this.props.selectedBlock?.type !== "text"

    if (!(areEditsInSidebar && this.props.hasUnsavedContentBlockDataChanges()))
      this.setState({tabIndex})
    else this.setState({pendingTabIndex: tabIndex, isUnsavedContentBlockConfirmDialogOpen: true})
  }

  onCancelCloseWidgetForm = () =>
    this.setState({
      pendingTabIndex: null,
      isUnsavedContentBlockConfirmDialogOpen: false,
    })

  onConfirmCloseWidgetForm = () =>
    this.setState({
      tabIndex: this.state.pendingTabIndex,
      pendingTabIndex: null,
      isUnsavedContentBlockConfirmDialogOpen: false,
    })

  onUpdateContentVariables = content_variables =>
    this.props.onUpdateTemplateMessage({
      content_variables,
    })

  toggleContentBlockEditorMode = () => {
    const {setMode, isPreviewMode} = this.props

    setMode(isPreviewMode ? EditorModes.Edit : EditorModes.Preview)
  }

  renderDeleteDialog = () => {
    const {
      message,
      match: {
        params: {templateId},
      },
    } = this.props
    const isContentLibrary = !templateId
    const {isDeleteDialogOpen} = this.state

    if (isContentLibrary)
      return (
        <UltraConfirmDialog
          confirmationText={message.contentName}
          isHighSeverity={message.id !== "new"}
          key={message.id}
          onClose={() => this.setState({isDeleteDialogOpen: false})}
          onConfirm={this.props.onDelete}
          open={Boolean(isDeleteDialogOpen)}
          recordType="message"
        >
          {message.id !== "new" && <TemplateListPreview id={message.id} recordType="message" />}
        </UltraConfirmDialog>
      )

    return (
      <ConfirmDialog
        content="Are you sure you want to remove this message from this campaign?"
        onClose={() => this.setState({isDeleteDialogOpen: false})}
        onConfirm={this.props.onRemoveFromTemplate}
        open={Boolean(isDeleteDialogOpen)}
      />
    )
  }

  render() {
    const {
      classes,
      selectedBlock,
      templateMessage,
      isPreviewMode,
      message,
      onUpdateMessage,
      match: {
        params: {templateId},
      },
    } = this.props
    const {tabIndex, isUnsavedContentBlockConfirmDialogOpen, expandedPanel} = this.state
    const isContentLibrary = !templateId

    return (
      <Drawer
        anchor="right"
        classes={{
          docked: isPreviewMode ? classes.drawerClosed : classes.drawerOpen,
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
        open={!isPreviewMode}
        variant="persistent"
      >
        <DeviceControlsSidebar layoutBasis={message?.layoutBasis} />
        <Button
          className={classes.sidbarButton}
          color="primary"
          fullWidth={true}
          onClick={this.toggleContentBlockEditorMode}
          size="small"
          variant="contained"
        >
          Preview
        </Button>
        {selectedBlock && (
          <Tabs
            indicatorColor="primary"
            textColor="inherit"
            onChange={this.onTabChange}
            value={tabIndex}
            variant="fullWidth"
          >
            <Tab
              className={cx(classes.tab)}
              data-testid="content-block-tab"
              label={selectedBlock?.type || "Widget"}
            />
            <Tab className={classes.tab} data-testid="message-tab" label="Message" />
          </Tabs>
        )}
        <div className={classes.tabContainer} ref={this.setScrollableContainerRef}>
          {/* TODO: This should probably be setting TemplateMessageContext, not TemplatePageContext? */}
          <TemplatePageContext.Provider
            value={{
              setContentVariables: !isContentLibrary ? this.onUpdateContentVariables : null,
              content_variables: templateMessage?.content_variables,
            }}
          >
            {selectedBlock && tabIndex === 0 && (
              <WidgetSettings
                isContentLibrary={isContentLibrary}
                classes={pick(classes, ["settingsContainer", "expansionPanel", "expansionContent"])}
              />
            )}
          </TemplatePageContext.Provider>
          {message && tabIndex === 1 && (
            <>
              {!!message?.isShared && (
                <>
                  <SharedContentNotice
                    onShowDuplicateConfirmation={() =>
                      this.setState({showDuplicationConfirmation: true})
                    }
                    editingTemplateId={templateId}
                    content={message}
                  />
                  {this.state.showDuplicationConfirmation && (
                    <DuplicateConfirmationDialog
                      onClose={() => this.setState({showDuplicationConfirmation: false})}
                      onDuplicate={this.props.onDuplicate}
                      open={true}
                    />
                  )}
                </>
              )}
              <div className={classes.settingsContainer}>
                <Accordion
                  classes={{root: classes.expansionPanel}}
                  expanded={expandedPanel === "general"}
                  onChange={() => this.setState({expandedPanel: "general"})}
                  key="general"
                  elevation={0}
                >
                  <AccordionSummary
                    classes={{root: classes.expansionContent}}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>General</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.expansionContent}}>
                    <div className={classes.expansionDetailInner}>
                      <GeneralMessageSettings
                        message={message}
                        key={`message-${message.id}-settings`}
                        onSubmit={onUpdateMessage}
                        initialValues={pick(message, [
                          "contentName",
                          "isArchived",
                          "isTransactional",
                          "objectiveId",
                        ])}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{root: classes.expansionPanel}}
                  elevation={0}
                  expanded={expandedPanel === "styles"}
                  key="styles"
                  onChange={() => this.setState({expandedPanel: "styles"})}
                >
                  <AccordionSummary
                    classes={{root: classes.expansionContent}}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>Styles</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.expansionContent}}>
                    <ContainerStyleSettings
                      containerType="message"
                      styles={message.styles || {}}
                      onSubmit={styles => this.props.onUpdateMessage({styles})}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{root: classes.expansionPanel}}
                  elevation={0}
                  expanded={expandedPanel === "css"}
                  key="css"
                  onChange={() => this.setState({expandedPanel: "css"})}
                >
                  <AccordionSummary
                    classes={{root: classes.expansionContent}}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>Custom CSS</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.expansionContent}}>
                    <ContainerCssSettings
                      containerType="message"
                      initialValues={{css: message.css || ""}}
                      onSubmit={this.props.onUpdateMessage}
                      selector=".body-content"
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  classes={{root: classes.expansionPanel}}
                  elevation={0}
                  expanded={expandedPanel === "test"}
                  key="test"
                  onChange={() => this.setState({expandedPanel: "test"})}
                >
                  <AccordionSummary
                    classes={{root: classes.expansionContent}}
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>Test</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.expansionContent}}>
                    <MessageTestSettingsPanel
                      content_variables={templateMessage?.content_variables}
                      message={message}
                    />
                  </AccordionDetails>
                </Accordion>
                {this.renderDeleteDialog()}

                <FormControl fullWidth={true} margin="normal">
                  <DangerButton
                    fullWidth={true}
                    margin="normal"
                    onClick={() => this.setState({isDeleteDialogOpen: true})}
                    tabIndex={2}
                  >
                    {isContentLibrary ? "Delete" : "Remove"}
                  </DangerButton>
                </FormControl>
              </div>
            </>
          )}
        </div>
        {isUnsavedContentBlockConfirmDialogOpen && (
          <ConfirmDialog
            cancelText="Cancel"
            continueText="Abandon Changes"
            content="You have unsaved changes for this widget."
            onClose={this.onCancelCloseWidgetForm}
            onConfirm={this.onConfirmCloseWidgetForm}
            open={true}
            title="Abandon changes?"
          />
        )}
      </Drawer>
    )
  }
}

const styles = theme => ({
  hidden: {
    display: "none",
  },
  tab: {
    minWidth: 100,
  },
  icon: {
    marginRight: 5,
  },
  drawer: {
    transition: theme.transitions.create("all"),
  },
  drawerOpen: {
    flexBasis: drawerWidth,
  },
  drawerClosed: {
    flexBasis: 0,
  },
  drawerPaper: {
    backgroundColor: "rgb(234,236,240)",
    boxShadow: "inset 0 0 10px rgb(208, 212, 220, 0.8)",
    borderLeft: "white",
    width: drawerWidth,
    marginTop: 88,
    paddingBottom: 88,
  },
  menuItem: {
    boxSizing: "border-box",
    height: "auto",
    maxWidth: 252,
    whiteSpace: "normal",
  },
  listItemTextSecondary: {
    color: "#7a7b7f",
  },
  settingsContainer: {
    padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
  },
  tabContainer: {
    overflow: "auto",
    flex: 1,
  },
  tabBadge: {
    padding: theme.spacing(0, 1),
  },
  title: {
    marginTop: 20,
    marginBottom: 10,
    textTransform: "uppercase",
  },
  field: {
    margin: "10px 0",
  },
  listSubheader: {
    backgroundColor: theme.palette.common.white,
  },
  helperText: {
    marginBottom: 10,
  },
  expansionPanel: {
    backgroundColor: "transparent",
    margin: 0,
  },
  expansionDetailInner: {
    width: "100%",
  },
  customCssButton: {
    margin: `20px -2px 0 -2px`,
  },
  expansionContent: {
    padding: 0,
  },
  select: {
    maxWidth: 252,
  },
  sidbarButton: {
    borderRadius: 0,
  },
  supportOwnerSwitchLabel: {
    fontSize: 15,
  },
  switchTooltipIconWrapper: {
    verticalAlign: "middle",
  },
  switchTooltipIcon: {
    fontSize: "1rem",
    position: "relative",
    top: 2,
    color: "rgba(0,0,0,0.54)",
    left: 10,
  },
  migrateButton: {
    marginTop: 25,
  },
})

MessageSidebar.propTypes = {
  classes: object.isRequired,
  currentUser: object,
  dispatch: func.isRequired,
  hasFeature: func.isRequired,
  hasUnsavedContentBlockDataChanges: func.isRequired,
  history: shape({
    push: func,
  }),
  isPreviewMode: bool,
  match: shape({
    params: shape({
      templateId: string,
    }),
  }),
  message: object,
  templateMessage: object,
  selectedBlock: object,
  setMode: func,
  setContainer: func.isRequired,
  onUpdateMessage: func.isRequired,
  onUpdateTemplateMessage: func.isRequired,
  onDuplicate: func.isRequired,
  onDelete: func,
  onRemoveFromTemplate: func,
  template: object,
}

export default contentBlockEditorConsumer(
  withDispatch(withRouter(featurify(withStyles(styles)(MessageSidebar))))
)
