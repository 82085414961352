import {EditorState, convertFromRaw, convertToRaw} from "draft-js"

import getDecorators from "./entities"

export const createEditorStateFromContent = content => {
  if (!content) return EditorState.createEmpty(getDecorators())

  return EditorState.createWithContent(convertFromRaw(content), getDecorators())
}

export const getRawContentFromEditorState = editorState =>
  convertToRaw(editorState.getCurrentContent())

export const createEmptyDraftContent = () =>
  convertToRaw(EditorState.createEmpty(getDecorators()).getCurrentContent())

// Discourage Material UI's menu component from stealing focus,
// which confuses draft js
export const menuProps = {
  autoFocus: false,
  disableAutoFocus: true,
  disableAutoFocusItem: true,
  disableEnforceFocus: true,
  disableRestoreFocus: true,
}
