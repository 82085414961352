import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {omit, pick} from "lodash"
import {array, func, object, string} from "prop-types"
import {PureComponent} from "react"

import {fetchEvents} from "lib/api"
import dateParser from "lib/date/date-parser"
import flattenObj from "lib/flatten-obj"
import {formify} from "lib/hooks/use-form"
import {fullName} from "lib/names"

import DatePicker from "../date-picker/date-picker"
import DOSelect from "../do-select/do-select"
import DocumentTitle from "../document-title/document-title"
import DOTable from "../table/table"
import {tabular} from "../table/table-state"
import AuditLogRow from "./audit-log-row"
import {colorForCategory, formatAction, formatCategory} from "./humanize-action"

const exportTransform = record =>
  flattenObj(
    omit(
      {
        ...record,
        user: record.user
          ? pick(record.user, ["email", "name_first", "name_last", "phone_mobile"])
          : null,
      },
      ["ssh_key"]
    )
  )

class AuditLogs extends PureComponent {
  state = {
    isTableLoading: true,
    activeRowIndex: null,
    filterActions: [],
    timestamp: null,
  }

  componentDidMount = () => {
    const {getAuditLogActions, getUsers, entityFilter} = this.props

    if (getUsers) getUsers()
    getAuditLogActions(entityFilter)
    this.refresh({filters: {...entityFilter}})
  }

  onClickRow = rowIndex => {
    if (this.state.activeRowIndex === rowIndex) this.setState({activeRowIndex: null})
    else this.setState({activeRowIndex: rowIndex})
  }

  filter = filters => this.refresh({page: 0, filters: {...filters, ...this.props.entityFilter}})

  isStaleResponse = fetchResponse => {
    let url

    try {
      url = new URL(fetchResponse.url)
    } catch (_e) {
      // Throws TypeError on invalid URL (which should only occur in tests),
      // so bypass timestamp checking
      return false
    }

    const params = url.searchParams

    return params.get("timestamp") !== this.state.timestamp
  }

  refresh = attrs => {
    const {updateStateForRequest} = this.props
    const params = updateStateForRequest(attrs)

    this.setState({isTableLoading: true}, () => {
      // Timestamp requests to defeat race conditions when filters are rapidly changed
      const timestamp = Date.now().toString()
      this.setState({timestamp})

      fetchEvents({...params, timestamp}, {withFetchResponse: true}).then(
        ([rows, fetchResponse]) => {
          if (!this.isStaleResponse(fetchResponse)) {
            this.props.setTableState({fetchResponse, rows})
            this.setState({isTableLoading: false, timestamp: null})
          }
        }
      )
    })
  }

  render() {
    const {classes, users, eventActions} = this.props
    const {activeRowIndex, isTableLoading} = this.state

    return (
      <>
        <DocumentTitle title={this.props.title} />
        <DOTable
          allowColumnCustomization={true}
          exporterProps={{
            filename: "audit_logs_export",
            fetchRecords: fetchEvents,
            title: "Export Results",
            transform: exportTransform,
            ...this.props.exporterProps,
          }}
          filterComponent={
            <AuditLogsFilters
              classes={classes}
              eventActions={eventActions}
              onSubmit={this.filter}
              users={users}
            />
          }
          headers={this.props.headers}
          isTableLoading={isTableLoading}
          noResults="No audit events matched your selected filters"
          refresh={this.refresh}
          storageName="audit-logs"
        >
          {(row, index, columns) => (
            <AuditLogRow
              activeRowIndex={activeRowIndex}
              columns={columns}
              key={row.id}
              onClick={this.onClickRow}
              row={row}
              rowIndex={index}
            />
          )}
        </DOTable>
      </>
    )
  }
}

AuditLogs.propTypes = {
  classes: object,
  entityFilter: object,
  eventActions: object,
  exporterProps: object,
  getAuditLogActions: func.isRequired,
  getUsers: func,
  headers: array.isRequired,
  setTableState: func.isRequired,
  title: string.isRequired,
  updateStateForRequest: func.isRequired,
  users: array,
}

const getSelectValue = filterActions => {
  // FIXME this `filtered` list can go away once this MUI issue is fixed
  // https://github.com/mui-org/material-ui/issues/18200
  // it allows undefined to be inserted in the list of selected actions
  // so we have to guard against it by filtering
  const filtered = filterActions.filter(Boolean)
  const [filterAction] = filtered

  if (!filterAction) return ""

  if (filtered.length > 1) return <i>{filtered.length} actions</i>

  const [category, action] = filterAction.split("/")

  return (
    <>
      <Typography component="span" style={{color: colorForCategory(category)}} variant="subtitle2">
        {formatCategory(category)}
      </Typography>{" "}
      <span style={{textTransform: "capitalize"}}>{formatAction(action)}</span>
    </>
  )
}

const Filters = ({classes, eventActions, field, resetForm, users}) => {
  const actionField = field("action", {defaultValue: []})

  return (
    <>
      <DatePicker className={classes.filterSelector} label="From" {...field("startDate")} />
      <DatePicker className={classes.filterSelector} label="To" {...field("endDate")} />
      {users && (
        <FormControl className={classes.filterSelector} margin="normal">
          <InputLabel>Filter by User</InputLabel>
          <DOSelect {...field("userId")}>
            <MenuItem value="">
              <em>Clear Filter</em>
            </MenuItem>
            <MenuItem value="no-user">
              <em>No User</em>
            </MenuItem>
            {users.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {fullName(user) || user.email}
              </MenuItem>
            ))}
          </DOSelect>
        </FormControl>
      )}
      <FormControl className={classes.filterSelector} margin="normal">
        <InputLabel>Filter by </InputLabel>
        <DOSelect
          multiple={true}
          renderValue={() => getSelectValue(actionField.value)}
          {...actionField}
        >
          {Object.entries(eventActions).reduce(
            (acc, [category, actions]) => [
              ...acc,
              <ListSubheader
                disableSticky={true}
                key={category}
                style={{color: colorForCategory(category)}}
              >
                {formatCategory(category)}
              </ListSubheader>,
              ...actions.map(action => (
                <MenuItem key={`${category}/${action}`} value={`${category}/${action}`}>
                  <Checkbox
                    checked={actionField.value.includes(`${category}/${action}`)}
                    className={classes.passthrough}
                    color={"primary"}
                  />
                  <ListItemText className={classes.actionText} primary={formatAction(action)} />
                </MenuItem>
              )),
            ],
            []
          )}
        </DOSelect>
      </FormControl>
      <Button color="grey" onClick={resetForm}>
        reset
      </Button>
    </>
  )
}

Filters.propTypes = {
  batchId: string,
  classes: object.isRequired,
  eventActions: object.isRequired,
  field: func.isRequired,
  resetForm: func.isRequired,
  users: array,
}

const userIdParser = userId => {
  switch (userId) {
    case "":
      return userId
    case "no-user":
      return null
    default:
      return userId
  }
}

const AuditLogsFilters = formify({
  autoSubmitOnChange: true,
  autoSubmitDebounceTime: 250,
  enableReinitialize: true,
  parse: {
    startDate: dateParser,
    endDate: dateParser,
    userId: userIdParser,
  },
})(Filters)

const styles = theme => ({
  actionText: {
    textTransform: "capitalize",
  },
  filterSelector: {
    flex: 1,
    minWidth: 150,
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("lg")]: {
      minWidth: "40%", // forces 2 rows split in half
    },
  },
  passthrough: {
    pointerEvents: "none",
  },
})

export const auditLogsItemsPerPage = itemsPerPage =>
  withStyles(styles)(
    tabular({
      itemsPerPage,
      namespace: "al",
      sortColumn: "timestamp",
      sortDirection: "desc",
      useQueryParams: true,
    })(AuditLogs)
  )

export default withStyles(styles)(
  tabular({sortColumn: "timestamp", sortDirection: "desc", useQueryParams: true})(AuditLogs)
)
