import {node} from "prop-types"
import {createContext, useContext, useEffect, useReducer} from "react"

import {InvalidDomain} from "components/router/validate-subdomain"

import {fetchCurrentTeam, fetchUserInfo} from "../../lib/api"
import {actions, initialState, reducer} from "./session-reducer"

const SessionContext = createContext()
SessionContext.displayName = "SessionContext"

const {Provider} = SessionContext

export const Session = ({children}) => {
  const [{team, user, ...state}, dispatch] = useReducer(reducer, initialState)

  const setTeam = team => dispatch({type: actions.setTeam, team})
  const setUser = user => dispatch({type: actions.setUser, user})

  useEffect(() => {
    fetchCurrentTeam()
      .then(setTeam)
      .catch(() => setTeam(false))

    fetchUserInfo()
      .then(setUser)
      .catch(() => setUser(false))
  }, [])

  const value = {
    ...state,
    dispatch,
    team,
    user,

    setTeam,
    setUser,
  }

  if (team === false) return <InvalidDomain />
  else if (team === undefined) return null
  else return <Provider value={value}>{children}</Provider>
}

Session.propTypes = {
  children: node.isRequired,
}

export const useSession = () => useContext(SessionContext)
