import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import cx from "classnames"
import {omit, pick} from "lodash"
import {array, bool, func, number, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"
import {MdArchive as ArchiveIcon, MdClose as DeleteIcon} from "react-icons/md"

import JourneyAudienceInput from "components/journey-audience-input/journey-audience-input"
import JourneyDurationInput from "components/journey-duration-input/journey-duration-input"
import JourneyUseCaseInput from "components/journey-use-case-input/journey-use-case-input"
import {TemplateContext} from "components/template-router/template-context.js"

import {validEmail} from "lib/field-validations"
import flattenObj from "lib/flatten-obj"
import useFeatures from "lib/hooks/use-features"
import {formify} from "lib/hooks/use-form"
import MaybeTooltip from "lib/maybe-tooltip"
import {fullName} from "lib/names"
import humanize from "lib/string/humanize"

import AccessControlled, {isUserPermitted} from "../access-control/access-controlled"
import Box from "../box/box"
import DangerButton from "../danger-button/danger-button"
import ConfirmDialog from "../dialogs/confirm-dialog"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import DOSelect from "../do-select/do-select"
import DocumentTitle from "../document-title/document-title"
import Feature from "../feature/feature"
import JourneyReentrySwitch from "../journey-reentry-switch/journey-reentry-switch"
import LandingPageSettings from "../landing-page/landing-page-settings"
import CampaignApprovalDialog from "./campaign-approval-dialog"
import CampaignLaunchingSpinner from "./campaign-launching-spinner"
import TemplateAuditLogs from "./template-audit-logs"

const transformTemplateAuditLog = record => {
  const user = record.user
    ? pick(record.user, ["email", "name_first", "name_last", "phone_mobile"])
    : null
  return flattenObj(omit({...record, user: user}, ["batch_id", "ssh_key"]))
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    marginRight: 10,
  },
  column: {
    width: "48%",
  },
  field: {
    margin: "10px 0",
  },
  sectionHeader: {
    fontSize: 22,
    letterSpacing: 0,
    fontWeight: "400",
  },
  sectionWrapper: {
    marginBottom: 30,
  },
  emergencyControls: {
    borderBottom: `2px solid ${theme.palette.error.main}`,
    padding: theme.spacing(1),
    marginBottom: 30,
    backgroundColor: theme.palette.error.light,
  },
  emergencyControlsHeader: {
    color: theme.palette.error.main,
  },
  approvalWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sendApproval: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(2),
  },
  campaignUseCase: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  campaignAudience: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}))

const TemplateManagement = props => {
  const template = props.template
  const {hasFeature} = useFeatures()
  const classes = useStyles()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showMuteDialog, setShowMuteDialog] = useState(false)
  const [showSendApprovalDialog, setShowSendApprovalDialog] = useState(false)
  const [showArchiveDialog, setShowArchiveDialog] = useState(false)
  const [showReturnToDraftConfirmDialog, setShowReturnToDraftConfirmDialog] = useState(false)
  const [showLaunchCampaignConfirmDialog, setShowLaunchCampaignConfirmDialog] = useState(false)

  const isPermittedToArchive = isUserPermitted(props.currentUser, "templates:archive")
  const isPermittedToEdit = isUserPermitted(props.currentUser, "templates:edit")

  // NB: Once a template's status is set to `live`, users should no longer be able to modify
  // template `type`. There are two ways campaigns become "live":
  // 1. With `campaign-approval` feature enabled, a campaign is approved and made "live"
  // 2. With `campaign-approval` feature disabled, a contact is enrolled into a campaign and made live
  // See https://trello.com/c/XJ36zlQ5
  const isTemplateTypeSelectDisabled = hasFeature("campaign-approval")
    ? props.template.status === "live"
    : props.template.facts?.journeysCount > 0

  useEffect(() => {
    // One-time initialization logic
    props.getLandingPage()
    props.getUsers()
    props.onMount()

    // Logic to run on unmount
    return () => props.clearLandingPage()

    // Ignore warning about missing `props` dependency; we only want this hook to run once on init.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      {template.name && <DocumentTitle title={`Campaigns - ${template.name} - Management`} />}
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.sectionWrapper}>
            <Typography className={classes.sectionHeader} gutterBottom={true} variant="h2">
              General
            </Typography>
            <AccessControlled requiredPermissions="templates:edit">
              <form>
                <TextField
                  className={classes.field}
                  disabled={!isPermittedToEdit}
                  fullWidth={true}
                  label="Name"
                  {...props.field("name")}
                />
                <Feature featureKey="tactical-campaign-and-account-management">
                  <JourneyReentrySwitch
                    disabled={isTemplateTypeSelectDisabled}
                    id="type"
                    label="Campaign Re-Enrollment"
                    helperText="Allow a contact to re-enter the campaign after journey expiration."
                    onChange={() =>
                      props.change("type", template.type === "standard" ? "tactical" : "standard")
                    }
                    value={template.type}
                  />
                </Feature>
                <JourneyDurationInput
                  disabled={!isPermittedToEdit}
                  {...props.field("journeyDurationHours")}
                />
                <Feature featureKey="campaign-use-case">
                  <JourneyUseCaseInput
                    classes={classes}
                    disabled={!isPermittedToEdit}
                    name={props.field("useCase").name}
                    onChange={e => props.change("useCase", e.target.value)}
                    value={props.field("useCase").value}
                  />
                </Feature>
                <Feature featureKey="campaign-audience">
                  <JourneyAudienceInput
                    classes={classes}
                    disabled={!isPermittedToEdit}
                    name={props.field("audience").name}
                    onChange={e => props.change("audience", e.target.value)}
                    value={props.field("audience").value}
                  />
                </Feature>
                <Feature featureKey="cross-channel-engagement">
                  <Tooltip title="Enable Cross-Channel Engagement for This Campaign">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          disabled={!isPermittedToEdit}
                          {...props.field("isCceEnabled", {
                            bool: true,
                            exclude: ["error", "helperText"],
                          })}
                        />
                      }
                      label="Cross Channel"
                    />
                  </Tooltip>
                </Feature>
              </form>
            </AccessControlled>
          </div>
          <div className={classes.sectionWrapper}>
            <Typography className={classes.sectionHeader} gutterBottom={true} variant="h2">
              Sender Information
            </Typography>
            <Typography gutterBottom={true} variant="caption">
              If any inputs below are left blank, then the team-level information will be used.
            </Typography>
            <AccessControlled requiredPermissions="templates:edit">
              <form>
                <TextField
                  className={classes.field}
                  disabled={!isPermittedToEdit}
                  fullWidth={true}
                  label="Sender Name"
                  {...props.field("emailSenderName")}
                />
                <TextField
                  className={classes.field}
                  disabled={!isPermittedToEdit}
                  fullWidth={true}
                  label="Sender Email"
                  {...props.field("emailSenderAddress")}
                />
              </form>
            </AccessControlled>
          </div>
          <Feature featureKey="campaign-approval">
            <div className={classes.sectionWrapper}>
              <Typography className={classes.sectionHeader} gutterBottom={true} variant="h2">
                Approval
              </Typography>
              <div className={classes.approvalWrapper}>
                <div className={classes.sendApproval}>
                  <TextField
                    className={classes.field}
                    disabled={true}
                    label="Current Status"
                    readOnly={true}
                    value={`${humanize(template.status)}`}
                  />
                  {["draft", "pending-approval", "not-approved"].includes(template.status) && (
                    <>
                      <Button
                        color="primary"
                        id="open-send-approvals-dialog"
                        variant="contained"
                        onClick={() => setShowSendApprovalDialog(true)}
                      >
                        {template.status === "draft" ? "Send for approval" : "Resend for approval"}
                      </Button>
                      <CampaignApprovalDialog
                        isOpen={showSendApprovalDialog}
                        onClose={() => setShowSendApprovalDialog(false)}
                        templateId={props.templateId}
                        users={props.users}
                      />
                    </>
                  )}
                </div>
                {template.status === "live" && (
                  <CampaignLaunchingSpinner templateId={template.id} />
                )}
                {template.status === "approved" && (
                  <div>
                    <AccessControlled
                      requiredPermissions="templates:launch"
                      hideIfNotPermitted={true}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        style={{marginRight: 10}}
                        onClick={() => setShowLaunchCampaignConfirmDialog(true)}
                      >
                        Launch Campaign
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setShowReturnToDraftConfirmDialog(true)}
                      >
                        Return to Draft
                      </Button>
                    </AccessControlled>
                    {showLaunchCampaignConfirmDialog && (
                      <ConfirmDialog
                        cancelText="Cancel"
                        content="Launching campaign will enable journey creation via targeting and set the state to 'Live'."
                        continueText="Launch"
                        onClose={() => setShowLaunchCampaignConfirmDialog(false)}
                        onConfirm={() => props.change("status", "live")}
                        open={true}
                        title="Launch Campaign?"
                      />
                    )}
                    {showReturnToDraftConfirmDialog && (
                      <ConfirmDialog
                        cancelText="Cancel"
                        content="Returning the state of the campaign to 'Draft' will cancel the existing approval and require that the campaign is re-approved prior to launching."
                        continueText="Return to Draft"
                        onClose={() => setShowReturnToDraftConfirmDialog(false)}
                        onConfirm={() => props.change("status", "draft")}
                        open={true}
                        title="Return to Draft?"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Feature>
        </div>
        <div className={classes.column}>
          <AccessControlled requiredPermissions="templates:edit">
            <div className={classes.emergencyControls}>
              <Typography
                className={cx(classes.sectionHeader, classes.emergencyControlsHeader)}
                gutterBottom={true}
                variant="h2"
              >
                Emergency Controls
              </Typography>
              <Typography gutterBottom={true}>
                Muting this campaign will <b>immediately</b> silence messaging for all journeys in
                this campaign (including journeys created while the campaign is muted). Any messages
                that attempt to send while the campaign is muted will not send and will not be
                retried after the campaign is unmuted. Messages that are skipped during that time
                will be shown in a journey's timeline explaining that it was skipped because the
                campaign was muted. Archived campaigns will not send messages, whether they are
                muted or not. This toggle has no effect on archived campaigns.
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={template.isMuted || false}
                    color="primary"
                    onChange={() => setShowMuteDialog(true)}
                  />
                }
                label="Mute this campaign immediately"
              />
            </div>
            <ConfirmDialog
              content={
                template.isMuted
                  ? "This will immediately allow messages to start sending for this campaign."
                  : "This will immediately prevent this campaign from sending any messages."
              }
              key={template.id}
              onClose={() => setShowMuteDialog(false)}
              onConfirm={() => {
                props.change("isMuted", !template.isMuted)
                setShowMuteDialog(false)
              }}
              open={showMuteDialog}
              title={`${template.isMuted ? "Unmute" : "Mute"} this campaign?`}
            />
          </AccessControlled>
          <div className={classes.sectionWrapper}>
            <Typography className={classes.sectionHeader} gutterBottom={true} variant="h2">
              Support Owner
            </Typography>
            <AccessControlled requiredPermissions="templates:support_owner">
              <FormControl className={classes.field} fullWidth={true}>
                <InputLabel>Default Support Owner</InputLabel>
                <DOSelect className={classes.field} fullWidth={true} {...props.field("ownerId")}>
                  {props.users.map(user => (
                    <MenuItem key={user.id} value={user.id}>
                      {fullName(user)}
                    </MenuItem>
                  ))}
                </DOSelect>
              </FormControl>
            </AccessControlled>
          </div>
          <Typography className={classes.sectionHeader} gutterBottom={true} variant="h2">
            Advanced
          </Typography>
          <AccessControlled requiredPermissions="templates:archive">
            <MaybeTooltip
              enterDelay={500}
              isTooltip={template.isArchived || false}
              title="Archived campaigns cannot be un-archived. To launch this campaign again, please duplicate the campaign."
            >
              <DangerButton
                onClick={() => setShowArchiveDialog(true)}
                disabled={!isPermittedToArchive || template.isArchived || false}
              >
                <ArchiveIcon className={classes.icon} /> Archive this Template...
              </DangerButton>
            </MaybeTooltip>
          </AccessControlled>
          <Dialog
            open={showArchiveDialog}
            onClose={() => setShowArchiveDialog(false)}
            aria-label={`Are you sure you want to archive this campaign?`}
            role="dialog"
          >
            <DialogTitle>Are you sure you want to archive this campaign?</DialogTitle>
            <DialogContent>
              <Typography>
                Journeys are still visible to users after the campaign is archived, but no new
                journeys can be created and no further messages will be sent. Archived campaigns
                cannot be un-archived. If you need to launch this campaign after it has been
                archived, you will need to duplicate the archived campaign.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="grey" onClick={() => setShowArchiveDialog(false)}>
                Cancel
              </Button>
              <TemplateContext.Consumer>
                {({refreshTemplate}) => (
                  <DangerButton
                    className="delete"
                    onClick={() => {
                      props.change("isArchived", true)
                      setShowArchiveDialog(false)

                      // Refresh the template stored in TemplateContext to make the "Duplicate" button
                      // available if the team was previously at the starter package template limit.
                      setTimeout(() => refreshTemplate(template.id), 1000)
                    }}
                  >
                    Yes, archive this campaign
                  </DangerButton>
                )}
              </TemplateContext.Consumer>
            </DialogActions>
          </Dialog>

          <br />

          <AccessControlled requiredPermissions="templates:delete">
            <DangerButton onClick={() => setShowDeleteDialog(true)}>
              <DeleteIcon className={classes.icon} /> Delete this Template...
            </DangerButton>
          </AccessControlled>
          {template.name && (
            <UltraConfirmDialog
              confirmationText={template.name}
              key={template.id}
              onClose={() => setShowDeleteDialog(false)}
              onConfirm={props.onDeleteTemplate}
              open={showDeleteDialog}
              recordType="template"
              renderDialogContent={confirmationText => (
                <DialogContentText>
                  Deleting this template will also permanently delete all data associated with the
                  campaign including all data driving analytics and insights. We strongly recommend
                  archiving the campaign if any journeys were created. <br />
                  <br />
                  Deleting this template can't be undone. To confirm that you are ok with the
                  template and the associated data being deleted and still want to move forward
                  please type <b>{confirmationText}</b> in the box below.
                </DialogContentText>
              )}
            />
          )}
        </div>
      </div>

      <Feature featureKey="campaign-approval">
        <TemplateAuditLogs
          templateId={props.templateId}
          exporterProps={{
            transform: transformTemplateAuditLog,
          }}
        />
      </Feature>

      {props.isLandingPageLoaded && props.currentUser && (
        <LandingPageSettings
          currentUser={props.currentUser}
          initialValues={props.landingPage}
          landingPage={props.landingPage}
          onSubmit={props.onSubmitLandingPage}
          template={template}
          users={props.users}
        />
      )}
    </Box>
  )
}

TemplateManagement.propTypes = {
  clearLandingPage: func.isRequired,
  currentUser: object,
  getLandingPage: func.isRequired,
  getUsers: func.isRequired,
  isLandingPageLoaded: bool,
  landingPage: object,
  onDeleteTemplate: func.isRequired,
  onMount: func.isRequired,
  onSubmitLandingPage: func.isRequired,
  // NOTE: `templateId` is always present even if `template` is not yet loaded.
  templateId: string,
  template: shape({
    facts: shape({
      journeysCount: number,
    }),
    id: string,
    isArchived: bool,
  }),
  users: array.isRequired,
  // Provided by formify()
  change: func.isRequired,
  field: func.isRequired,
  // Provided by parent, implicitly used in formify()
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
}

// TODO: It would be nice to replace this formify HOC with a useForm hook so it's easier to reason
// about its inputs and outputs, but when I do so I get major text-editing bugs, for ex in the
// template title field. So leaving this as-is for now.
export default formify({
  autoSubmitOnChange: true,
  enableReinitialize: true,
  validators: {
    emailSenderAddress: [validEmail],
    journeyDurationHours: [
      value =>
        parseInt(value, 10) < 1 ? "Journey Duration must be a positive amount of time" : null,
    ],
  },
})(TemplateManagement)
