// This file was generated automatically from character-03.svg with the following command:
// ./svg-converter.js src/components/superhero/character-03.svg
import SuperheroSvg from "./superhero-svg"

const Character03 = props => (
  <SuperheroSvg viewBox="215 17 163 250" {...props}>
    <g id="Layer_3">
      <g>
        <g>
          <defs>
            <rect
              id="SVGID_00000092425359191528268630000016074183448515087246_"
              x="14.22"
              y="17.86"
              width="356.8"
              height="248.53"
            />
          </defs>
          <clipPath id="ec8af605-SVGID_00000179637207898479799490000003621763226848688039_">
            <use
              xlinkHref="#SVGID_00000092425359191528268630000016074183448515087246_"
              style={{overflow: "visible"}}
            />
          </clipPath>
          <g style={{clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)"}}>
            <g>
              <path
                style={{fill: "#123252"}}
                d="M227.79,253.29c4.37,6.69,15.23,6.7,22.21,6.57c7.91-0.15,15.83-0.39,23.74-0.58
  						c21.88-0.54,43.76-1.08,65.64-1.61c8.83-0.22,17.76-0.18,24.82-6.48c5.12-4.57-2.43-12.06-7.52-7.52
  						c-4.37,3.9-11.26,3.21-16.8,3.35c-8.65,0.21-17.31,0.43-25.96,0.64c-20.06,0.49-40.12,0.99-60.19,1.48
  						c-1.8,0.04-3.59,0.13-5.39,0.13c-3.34,0.01-7.08-0.03-10.24-1.26c-0.55-0.22-1.29-0.8-1.85-0.92c0.15,0.03,0.09-0.12,0.72,0.83
  						C233.25,242.22,224.04,247.54,227.79,253.29L227.79,253.29z"
              />
            </g>
          </g>

          <circle
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            cx="331.21"
            cy="258.96"
            r="4.57"
          />

          <circle
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            cx="261.22"
            cy="260.55"
            r="4.57"
          />
          <g style={{clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)"}}>
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M278.86,237.76c0,0,4.59,8.76,2.09,11.26
  					c0,0-13.76,3.75-31.28,1.25c0,0-6.26-2.5,1.25-6.26c0,0,12.51-3.75,13.76-8.76"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M253.59,243.19c0,0,4.77,3.65,0.81,7.61"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M281.52,245.01c0,0-4.24,2.63-25.55,2.69"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M270.32,235.88c0,0,0.42,5.93-14.8,10.47"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M266.57,239.63c0,0-2.5-2.5-2.5,0s0,2.5-2.5,2.5
  					c-2.5,0-2.5,0-2.5,1.25s-2.5,0-2.5,0"
            />
          </g>
          <g style={{clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)"}}>
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M335.07,232.71c0,0,6.48,7.47,4.62,10.48
  					c0,0-12.53,6.82-30.15,8.41c0,0-6.66-1-0.22-6.38c0,0,11.31-6.53,11.38-11.69"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M311.72,243.81c0,0,5.48,2.45,2.53,7.22"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M339.32,239.15c0,0-3.53,3.53-24.25,8.5"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M326.32,232.85c0,0,1.78,5.67-12,13.59"
            />
            <path
              style={{fill: "none", stroke: "#5198D2", strokeMiterlimit: "10"}}
              d="M323.53,237.36c0,0-3.01-1.86-2.44,0.58
  					c0.58,2.44,0.58,2.44-1.86,3.01s-2.44,0.58-2.15,1.79c0.29,1.22-2.44,0.58-2.44,0.58"
            />
          </g>
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M274.38,89.24
  				c0,0-3.34-0.54-2.38-0.38c1.51,0.24,3.02,0.49,4.53,0.73c-0.94,1.62-1.87,3.24-2.81,4.86c-1.5,2.6-3,5.19-4.5,7.79
  				c-0.34,0.59-0.69,1.19-1.03,1.78c-0.19,0.33-0.43,0.6-0.75,0.77c-0.24,0.13-0.41,0.14-0.62,0.19c-0.92,0.21-1.15,0.73-1.6,0.8
  				c-0.75,0.13-1.79-1.05-2.18-2.09c-0.42-1.1-0.18-2.25,3.01-7.93c3.01-5.37,3.82-6.62,4.5-7.79c0.34-0.59,0.69-1.19,1.03-1.78
  				c0.4-0.69,1.07-1.1,2.03-0.95c1.4,0.21,2.5,1.68,2.5,1.68s0,0,0,0c0,0,0,0,0,0.01c0.88,2.09,0.42,2.64,0.42,2.64
  				C276.27,89.84,275.84,89.57,274.38,89.24z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#CC5F64",
            }}
            d="M275.24,94.51
  				c1.98-0.25,3.07-3.5,5.97-6.8c0.97-1.1,1.97-2.19,2.91-3.31c0.01-0.02,0.03-0.03,0.04-0.05c0.97-1.1,0.65-3.29-0.39-4.63
  				c-1.03-1.33-2.59-1.57-2.93-1.62c-1.92-0.3-3.45,0.67-4.38,1.27c-1.18,0.76-1.94,1.47-3.67,4.09c-0.8,1.21-1.97,2.91-3.53,4.94
  				C272.11,93.72,274.01,94.66,275.24,94.51z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#609E92",
            }}
            d="M261.07,188.41
  				l9.18-63.9l24.37-2.5L289,140.76l-5.06,53.93c0,0,1.3,17.12,2.09,43.37l-25.81-1.68C260.23,236.37,258.88,208.28,261.07,188.41z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#5DBDAF",
            }}
            d="M312.65,188.33
  				l-4.41-59.18l-1.2-14.62l-5.33-1.11L273.34,107l-2.81,16.2c-0.2,0.81-0.36,1.63-0.45,2.48l-0.24,1.34l0.14,0.02
  				c-0.01,0.17-0.02,0.34-0.02,0.52c-0.15,9.31,6.36,17.17,15.13,19.05l0.13,0.37l5.07,38.56c0,0,1.27,9.08,4.45,14.65l18.79,35.06
  				l27.49-3.93l-24.46-35.04C316.55,196.28,313.37,192.3,312.65,188.33z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M294.72,40.78
  				c0.62,0,1.24,0.03,1.85,0.09c0.36,14.5,13.52,22.1,16.01,23.4c-2.17,7.82-9.34,13.57-17.85,13.57c-10.23,0-18.53-8.3-18.53-18.53
  				C276.19,49.08,284.49,40.78,294.72,40.78z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M276.19,59.26
  				c0,8.24,5.79,15.21,13.21,17.64l0,2.62l6.83,0l0-1.77c5.14-0.41,9.69-2.92,12.79-6.66c0.66,0.47,1.47,0.75,2.35,0.75
  				c2.24,0,4.05-1.81,4.05-4.05c0-1.78-1.15-3.28-2.74-3.83c0.01-0.02,0.01-0.04,0.01-0.06c-0.13-0.07-0.25-0.14-0.38-0.21
  				l-35.9-7.31C276.28,57.32,276.19,58.28,276.19,59.26z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M303.68,54.53
  				l-24.63-5.01c-1.32,2.08-2.24,4.45-2.63,6.98l35.53,7.23C308.22,61.73,305.27,58.48,303.68,54.53z"
          />

          <rect
            x="290.01"
            y="79.41"
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#EB6669",
            }}
            width="6.37"
            height="2.62"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#EB6669",
            }}
            d="M310.86,92.33
  				c0.7-5.14-2.9-9.87-8.03-10.58l-15.9-1.75c-6.52-0.4-9.64,2.1-10.58,8.03l-3.1,18.71l35.09,0.77L310.86,92.33z"
          />
          <polygon
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#EB6669",
            }}
            points="
  				308.8,105.24 273.25,106.75 272.44,111.62 307.51,116.98 			"
          />

          <circle
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#0D1E36",
            }}
            cx="286.09"
            cy="55.13"
            r="1.48"
          />
          <g style={{clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)"}}>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M287.85,50.98c-0.3-0.3-0.73-0.51-1.13-0.64c-0.93-0.29-1.92-0.07-2.76,0.38
  						c-0.26,0.14-0.36,0.52-0.2,0.77c0.17,0.26,0.49,0.35,0.77,0.2c0.06-0.03,0.12-0.07,0.19-0.1c0.02-0.01,0.19-0.08,0.1-0.05
  						c0.1-0.04,0.2-0.08,0.3-0.11c0.11-0.03,0.22-0.06,0.33-0.08c0.03-0.01,0.17-0.02,0.03-0.01c0.04,0,0.09-0.01,0.13-0.01
  						c0.09-0.01,0.18-0.01,0.27-0.01c0.03,0,0.23,0.04,0.25,0.02c-0.09-0.01-0.1-0.01-0.04,0c0.01,0,0.02,0,0.04,0.01
  						c0.04,0.01,0.08,0.02,0.12,0.03c0.07,0.02,0.14,0.04,0.21,0.06c0.03,0.01,0.17,0.04,0.17,0.06c-0.09-0.04-0.11-0.05-0.05-0.02
  						c0.01,0.01,0.03,0.01,0.04,0.02c0.03,0.01,0.06,0.03,0.08,0.04c0.09,0.05,0.17,0.1,0.26,0.15c0.03,0.02,0.06,0.04,0.09,0.06
  						c-0.18-0.11-0.08-0.06-0.03-0.02c0.02,0.02,0.04,0.03,0.05,0.05c0.21,0.21,0.59,0.22,0.8,0
  						C288.06,51.55,288.08,51.2,287.85,50.98L287.85,50.98z"
              />
            </g>
          </g>
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M296.57,40.87
  				c9.37,0.93,16.68,8.83,16.68,18.44c0,1.72-0.24,3.38-0.68,4.97C310.09,62.97,296.94,55.37,296.57,40.87z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M296.6,40.99
  				c0,0-5.12,5.74-15.47,5.74C281.13,46.72,287.02,39.74,296.6,40.99z"
          />

          <circle
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#0D1E36",
            }}
            cx="298.76"
            cy="57.24"
            r="1.48"
          />
          <g style={{clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)"}}>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M298.9,53.58c0.1-0.03,0.2-0.05,0.3-0.06c0.05-0.01,0.13-0.01-0.07,0.01
  						c0.03,0,0.06-0.01,0.09-0.01c0.06-0.01,0.11-0.01,0.17-0.01c0.14-0.01,0.28,0,0.42,0.01c0.03,0,0.1,0,0.12,0.01
  						c-0.04-0.03-0.13-0.02-0.02,0c0.09,0.02,0.18,0.03,0.26,0.06c0.08,0.02,0.16,0.05,0.24,0.08c0.15,0.06-0.05-0.03,0.04,0.01
  						c0.03,0.02,0.07,0.03,0.1,0.05c0.09,0.05,0.19,0.1,0.27,0.16c0.03,0.02,0.07,0.05,0.1,0.07c0.05,0.04,0.05,0.03-0.01-0.01
  						c0.02,0.02,0.04,0.04,0.07,0.05c0.08,0.07,0.16,0.14,0.23,0.22c0.04,0.05,0.09,0.09,0.13,0.14c0.02,0.03,0.04,0.05,0.06,0.08
  						c-0.04-0.05-0.04-0.05,0,0.01c0.17,0.24,0.5,0.38,0.77,0.2c0.23-0.15,0.39-0.52,0.2-0.77c-0.32-0.44-0.71-0.82-1.19-1.08
  						c-0.41-0.22-0.83-0.33-1.29-0.39c-0.42-0.05-0.9-0.03-1.31,0.1c-0.28,0.09-0.49,0.4-0.39,0.69
  						C298.3,53.47,298.6,53.68,298.9,53.58L298.9,53.58z"
              />
            </g>
          </g>
          <g style={{clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)"}}>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M292.88,60.75c-0.45-0.89-1.41-1.57-2.43-1.41c-0.54,0.08-1.01,0.37-1.43,0.7
  						c-0.12,0.09-0.1,0.3,0,0.4c0.12,0.12,0.28,0.09,0.4,0c0.55-0.43,1.23-0.71,1.88-0.46c0.19,0.07,0.41,0.23,0.56,0.37
  						c0.08,0.08,0.16,0.16,0.23,0.25c0.01,0.02,0.03,0.04,0.04,0.05c0.03,0.04,0,0,0.01,0.01c0.03,0.04,0.06,0.07,0.08,0.11
  						c0.06,0.08,0.11,0.17,0.16,0.26C292.55,61.36,293.04,61.07,292.88,60.75L292.88,60.75z"
              />
            </g>
          </g>
          <g style={{clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)"}}>
            <path
              style={{fill: "#735A4C"}}
              d="M279.46,124.97c0,0,0.61,0.25,0.5,0.53c-0.11,0.28-0.92,0.76-1.95,0.32
  					c-0.68-0.29-3.67-0.94-5.25,1.14c0,0-1.26,2.8,1.35,4.45c1.96,1.24,3.78,1.81,6.97,0.91c2.99-0.84,4.27-2.24,5.16-2.73
  					c3.25-0.82,6.5-1.64,9.75-2.46c0.47-0.12,0.94-0.24,1.42-0.36l-1.95-5.87c-3.32,0.84-6.63,1.68-9.95,2.52
  					c-0.34,0.09-0.68,0.17-1.03,0.26c-0.82-0.6-2.8-1.91-4.61-2.03c0,0-0.85-0.18-1.85,0.31
  					C277.03,122.44,277.89,124.4,279.46,124.97z"
            />
          </g>
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M312.84,96.76
  				L312.84,96.76L312.84,96.76c-0.75,0.51-1.5,1.02-2.25,1.52c1.42-0.97,2.85-1.93,4.27-2.9c0.62,2.52,1.24,5.05,1.85,7.57
  				c0.99,4.04,1.98,8.08,2.97,12.12c0.23,0.92,0.45,1.85,0.68,2.77c0.2,0.8,0.17,1.54-0.29,2.16c-0.42,0.57-1.28,1.03-2.16,1.04
  				c-1.77,0.02-4-1.15-4.41-2.84c-0.62-2.52-1.24-5.05-1.85-7.57c-0.99-4.04-1.98-8.08-2.97-12.12c-0.23-0.92-0.45-1.85-0.68-2.77
  				c-0.26-1.08-0.07-2.06,0.83-2.68c1.31-0.91,3.44-0.21,3.44-0.21s0,0,0,0c0.01,0.01,2.65,1.64,2.59,2.54
  				C314.81,96.25,312.85,96.76,312.84,96.76z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#EB6669",
            }}
            d="M305.52,100.9
  				c-1.84-0.9-2.37-2.48-2.37-2.48c-0.43-1.28,0.19-2.02-0.14-3.39c-0.19-0.78-0.7-1.86-2.26-2.96c0.23-0.47,0.55-1.26,0.71-2.26
  				c0.34-2.17-0.6-2.68,0-3.95c0.88-1.88,3.44-2.93,3.44-2.93c0,0,3.03-0.22,5.03,0.95c1.02,0.6,1.58,1.45,2.26,2.68
  				c1,1.81,2.43,4.77,3.67,9.04C312.25,100.03,307.95,102.09,305.52,100.9z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            d="M309.59,70.24
  				c-0.33,0.43-0.68,0.85-1.05,1.26c0.44-0.49,0.85-1.01,1.24-1.55C309.72,70.04,309.65,70.14,309.59,70.24z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            d="M307.92,72.15
  				c0.16-0.17,0.32-0.33,0.48-0.5C308.25,71.82,308.09,71.99,307.92,72.15z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            d="M294.76,77.66
  				c5.08,0,9.68-2.05,13.02-5.37c-3.24,3.23-7.54,5.31-12.5,5.41c-8.17,0.17-15.24-4.91-17.96-12.15c0.04,0.04,0.09,0.08,0.14,0.12
  				C280.09,72.68,286.84,77.66,294.76,77.66z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            d="M307.78,72.3
  				c0.05-0.05,0.1-0.1,0.14-0.15C307.88,72.2,307.83,72.25,307.78,72.3z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            d="M308.41,71.65
  				c0.05-0.05,0.09-0.1,0.14-0.16C308.5,71.54,308.45,71.6,308.41,71.65z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            d="M309.78,69.95
  				c0.48-0.73,0.95-1.47,1.38-2.25C310.75,68.48,310.29,69.23,309.78,69.95z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#123252",
            }}
            d="M277.45,65.68
  				c1.19,0.98,4.83,3.56,7.6,1.08c2.74-2.45,5.91-2.91,8.71-2.57c2.37,0.29,6.79,0.65,9.67,4.97c0,0,1.65,2.75,5.95-1.32
  				c1.8-1.71,2.23-3.37,3.05-4.92l0.45-0.01c-0.35,1.69-0.93,3.3-1.71,4.79c-0.43,0.77-0.9,1.52-1.38,2.25
  				c-0.39,0.54-0.8,1.05-1.24,1.55c-0.05,0.05-0.09,0.1-0.14,0.16c-0.16,0.17-0.32,0.34-0.48,0.5c-0.05,0.05-0.1,0.1-0.14,0.15
  				c-3.34,3.32-7.94,5.37-13.02,5.37C286.84,77.66,280.09,72.68,277.45,65.68z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#5DBDAF",
            }}
            d="M284.53,45.9
  				l25.89,8.61c1.94,0.64,4.03-0.4,4.68-2.34v0c0.64-1.94-0.4-4.03-2.34-4.68l-25.89-8.61c-1.94-0.64-4.03,0.4-4.68,2.34v0
  				C281.54,43.16,282.59,45.25,284.53,45.9z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#5DBDAF",
            }}
            d="M296.28,30.25
  				c-1,4.32-6.09,4.35-6.09,4.35c-4.78,0.43-5.22,5.22-5.22,5.22l29.14,9.57c1.3-4.35-0.65-5-3.04-7.39
  				c-2.39-2.39-0.87-5.22-0.87-10.87s-4.09-8.03-4.09-8.03S298.89,18.94,296.28,30.25z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#FFFFFF",
            }}
            d="M297.1,68.58
  				c-0.1-0.18-0.34-0.21-0.5-0.09c-0.39,0.27-1.1,0.86-2.12,1.06c-1.38,0.27-2.58-0.28-2.87,0.08c-0.16,0.2,0.04,0.45,0.41,0.76
  				s1.53,0.93,2.79,0.69c1.33-0.25,2.33-1.79,2.33-2.33C297.14,68.68,297.13,68.63,297.1,68.58z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#D9D8D6",
            }}
            d="M289.61,87.32
  				l7.57,0.5c0.76,0.05,1.23,0.59,1.18,1.34l-1.16,17.65c-0.05,0.76-0.59,1.23-1.34,1.18l-7.57-0.5c-0.76-0.05-1.23-0.59-1.18-1.34
  				l1.16-17.65C288.32,87.74,288.85,87.27,289.61,87.32z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#735A4C",
            }}
            d="M288.21,98.98
  				c0,0-0.49,0.42-0.3,0.64c0.19,0.23,1.09,0.44,1.92-0.28c0.55-0.47,3.14-1.98,5.23-0.51c0,0,2.01,2.23,0.07,4.55
  				c-1.46,1.74-2.98,2.81-6.23,2.93c-3.03,0.11-4.65-0.81-5.63-1.01c-3.27,0.2-6.55,0.41-9.82,0.61c-0.48,0.03-0.95,0.06-1.43,0.09
  				l0.07-6.05c3.34-0.21,6.68-0.42,10.03-0.62c0.34-0.02,0.69-0.04,1.03-0.06c0.59-0.8,2.04-2.62,3.69-3.27c0,0,0.74-0.42,1.81-0.26
  				C289.72,95.89,289.51,97.98,288.21,98.98z"
          />
          <path
            style={{
              clipPath: "url(#SVGID_00000179637207898479799490000003621763226848688039_)",
              fill: "#D9D8D6",
            }}
            d="M287.92,93.65
  				l-0.48,6.06c0,0,2.01,0.89,2.9-1.35C291.03,96.67,287.92,93.65,287.92,93.65z"
          />
        </g>
      </g>
      <path
        style={{fill: "#735A4C"}}
        d="M296.1,122.84c0.48,0.68,0.97,1.36,1.45,2.04c-0.92-1.29-1.84-2.58-2.75-3.87
  		c2.49-0.67,4.98-1.33,7.48-2c3.99-1.07,7.98-2.14,11.97-3.21c0.91-0.24,1.82-0.49,2.74-0.73c0.79-0.21,1.52-0.21,2.12,0.19
  		c0.61,0.41,0.77,1.1,0.98,1.97c0.21,0.91,0.46,1.95,0,2.79c-0.58,1.05-2,1.27-2.89,1.43c-2.54,0.45-4.98,1.33-7.48,2
  		c-3.99,1.07-7.98,2.14-11.97,3.21c-0.91,0.24-1.82,0.49-2.74,0.73c-1.06,0.28-2.02,0.14-2.62-0.67c-0.87-1.18-0.14-3.2-0.14-3.2
  		s0,0,0,0c0,0,0,0,0.01-0.01c1.78-2.56,2.54-2.49,2.54-2.49C295.54,121.08,295.98,122.44,296.1,122.84z"
      />
      <path
        style={{fill: "#735A4C"}}
        d="M278.59,103.68L278.59,103.68L278.59,103.68c-0.35-0.76-0.7-1.51-1.06-2.27
  		c0.67,1.44,1.34,2.88,2.01,4.31c-1.61,0.08-3.23,0.16-4.84,0.24c-2.58,0.13-5.17,0.26-7.75,0.39c-0.59,0.03-1.18,0.06-1.77,0.09
  		c-0.51,0.03-0.99-0.14-1.41-0.65c-0.38-0.48-0.71-1.33-0.74-2.1c-0.07-1.57,0.62-3.33,1.7-3.38c1.61-0.08,3.23-0.16,4.84-0.24
  		c2.58-0.13,5.17-0.26,7.75-0.39c0.59-0.03,1.18-0.06,1.77-0.09c0.69-0.03,1.33,0.32,1.76,1.23c0.63,1.33,0.25,3.08,0.25,3.08
  		s0,0,0,0c0,0.01-0.98,2.04-1.56,1.82C278.99,105.52,278.59,103.69,278.59,103.68z"
      />
    </g>
  </SuperheroSvg>
)

export default Character03
