import {TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func} from "prop-types"

import {formify} from "lib/hooks/use-form"

import FormActions from "../form-actions"
import {embedToData} from "./video-helpers"

const VideoSettings = ({change, field, handleSubmit, resetForm}) => {
  const classes = useStyles()

  const rawEmbed = field("rawEmbed")

  const onChange = event => {
    const {service, id} = embedToData(event.target.value)
    change("service", service)
    change("id", id)
    rawEmbed.onChange(event)
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit} aria-label="Video Settings">
      <TextField
        autoFocus={true}
        fullWidth={true}
        id={`video-embed-or-url`}
        label="URL or Embed Code"
        margin="normal"
        type="text"
        {...rawEmbed}
        onChange={onChange}
      />
      <FormActions resetForm={resetForm} />
    </form>
  )
}

VideoSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func,
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}))

const VideoSettingsForm = formify()(VideoSettings)

export default VideoSettingsForm
