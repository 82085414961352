import shortid from "shortid"

export const CHANGE_CSV_FIELD = "CHANGE_CSV_FIELD"
export const CHANGE_DO_FIELD = "CHANGE_DO_FIELD"
export const CHANGE_META_SUB_KEY = "CHANGE_META_SUB_KEY"
export const ADD_NEW_MAPPING = "ADD_NEW_MAPPING"
export const DELETE_MAPPING = "DELETE_MAPPING"
export const SET_DEFAULT = "SET_DEFAULT"

export const updateCsvField = payload => ({type: CHANGE_CSV_FIELD, payload})
export const updateDoField = payload => ({type: CHANGE_DO_FIELD, payload})
export const updateMetaSubKey = payload => ({type: CHANGE_META_SUB_KEY, payload})
export const addMapping = () => ({type: ADD_NEW_MAPPING})
export const setDefault = payload => ({type: SET_DEFAULT, payload})
export const deleteMapping = payload => ({type: DELETE_MAPPING, payload})

export const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_CSV_FIELD:
      const csvFieldTaken = state.find(({csv_field}) => csv_field === action.payload.csv_field)

      return state.map(fieldMap => {
        if (fieldMap.id === action.payload.id)
          return {
            ...fieldMap,
            csv_field: action.payload.csv_field,
            csv_fieldTaken: !!csvFieldTaken,
          }

        return fieldMap
      })
    case CHANGE_DO_FIELD:
      return state.map(fieldMap => {
        if (fieldMap.id === action.payload.id)
          return {
            ...fieldMap,
            digital_onboarding_field: action.payload.digital_onboarding_field,
          }

        return fieldMap
      })
    case CHANGE_META_SUB_KEY:
      return state.map(fieldMap =>
        fieldMap.id === action.payload.id
          ? {
              ...fieldMap,
              meta_sub_key: action.payload.meta_sub_key,
            }
          : fieldMap
      )
    case ADD_NEW_MAPPING:
      return [
        ...state,
        {
          id: `${state.length + 1}`,
          csv_field: "",
          digital_onboarding_field: "ignored",
          meta_sub_key: "",
          csvFieldTaken: false,
        },
      ]
    case DELETE_MAPPING:
      return state.filter(fieldMap => fieldMap.id !== action.payload.id)
    case SET_DEFAULT:
      return initializeState(action.payload)
    default:
      throw new Error("unknown action type specified")
  }
}

export const compileFieldMap = fieldMappings =>
  fieldMappings
    .filter(
      ({csvFieldTaken, csv_field, digital_onboarding_field}) =>
        !csvFieldTaken && csv_field !== "" && csv_field !== digital_onboarding_field
    )
    .reduce(
      (fieldMap, {csv_field, digital_onboarding_field, meta_sub_key = ""}) =>
        digital_onboarding_field === "ignored"
          ? {
              ...fieldMap,
              ignoredFields: [...fieldMap.ignoredFields, csv_field],
            }
          : {
              ...fieldMap,
              [csv_field]: {
                digital_onboarding_field,
                meta_sub_key: digital_onboarding_field.match(/^meta_(public|private)/)
                  ? meta_sub_key
                  : "",
              },
            },
      {ignoredFields: []}
    )

export const initializeState = (teamCsvFieldMap = {}) =>
  rehydrateFieldMap(teamCsvFieldMap).map(fieldMapSet => ({
    ...fieldMapSet,
    id: shortid.generate(),
    csvFieldTaken: false,
  }))

export const rehydrateFieldMap = ({ignoredFields = [], ...fieldMap} = {}) => {
  const ignoredFieldMap = ignoredFields.map(csv_field => ({
    digital_onboarding_field: "ignored",
    csv_field,
    meta_sub_key: "",
  }))

  return [
    ...Object.entries(fieldMap).reduce(
      (fieldMapArray, [csv_field, {digital_onboarding_field, meta_sub_key}]) => [
        ...fieldMapArray,
        {
          csv_field,
          digital_onboarding_field,
          meta_sub_key: digital_onboarding_field?.match(/^meta_(public|private)/)
            ? meta_sub_key
            : "",
        },
      ],
      []
    ),
    ...ignoredFieldMap,
  ]
}

export const contactFields = [
  {name: "Address", value: "address"},
  {name: "Address 2", value: "address2"},
  {name: "Birthday", value: "birthdate"},
  {name: "City", value: "city"},
  {name: "Country", value: "country"},
  {name: "County", value: "county"},
  {name: "Email", value: "email", priority: true},
  {name: "Email Verified", value: "email_verified"},
  {name: "Email Opted In", value: "email_opted_in"},
  {name: "Email Authorized", value: "email_authorized"},
  {name: "First Name", value: "name_first"},
  {name: "Last Name", value: "name_last"},
  {name: "Name (Suffix)", value: "name_suffix"},
  {name: "Title", value: "name_title"},
  {name: "Phone (Home)", value: "phone_home"},
  {name: "Phone (Mobile)", value: "phone_mobile"},
  {name: "SMS Verified", value: "sms_verified"},
  {name: "SMS Opted In", value: "sms_opted_in"},
  {name: "SMS Authorized", value: "sms_authorized"},
  {name: "Phone (Work)", value: "phone_work"},
  {name: "Post Code", value: "post_code", priority: true},
  {name: "State", value: "state"},
  {name: "Timezone", value: "timezone"},
  {name: "Unique ID", value: "unique_id"},
]

export const journeyAccountFields = [{name: "Account Number", value: "account_number"}]

export const additionalJourneyFields = [{name: "Support Owner ID", value: "owner_id"}]

export const additionalContactFields = [{name: "Template ID", value: "template_id"}]

export const commonFields = [
  {name: "Public Metadata", value: "meta_public", priority: true},
  {name: "Private Metadata", value: "meta_private", priority: true},
]

export const uiOnlyField = [{name: "Ignored", value: "ignored"}]

export const allContactFields = [
  ...contactFields,
  ...additionalJourneyFields,
  ...additionalContactFields,
  ...commonFields,
  ...uiOnlyField,
].sort(({name: lhsName}, {name: rhsName}) => {
  if (lhsName < rhsName) return -1
  if (lhsName > rhsName) return 1
  return 0
})

export const allContactFieldValues = [
  ...contactFields,
  ...additionalJourneyFields,
  ...additionalContactFields,
  ...commonFields,
]
  .map(({value}) => value)
  .sort((lhs, rhs) => {
    if (lhs < rhs) return -1
    if (lhs > rhs) return 1
    return 0
  })

export const accountFields = [
  {name: "Account Number", value: "account_number"},
  {name: "Adverse Status", value: "adverse_status"},
  {name: "APY", value: "apy"},
  {name: "Balance", value: "balance"},
  {name: "Channel Opened", value: "channel_opened"},
  {name: "Charge Off", value: "charge_off"},
  {name: "Closed Reason", value: "closed_reason"},
  {name: "Contact Unique ID", value: "contact.unique_id"},
  {name: "Date Closed", value: "closed_date"},
  {name: "Credit Card Activation", value: "credit_card_activation"},
  {name: "In Person Check Deposit Date", value: "in_person_deposit_check_date"},
  {name: "Last Mobile Pay Amount", value: "last_mobile_pay_amount"},
  {name: "Last Mobile Pay Transaction", value: "last_mobile_pay_transaction"},
  {name: "Last Debit Card Transaction", value: "last_debit_card_transaction"},
  {name: "Last Statement Balance", value: "last_statement_balance"},
  {name: "Last Transaction Amount", value: "last_transaction_amount"},
  {name: "Last Transaction Date", value: "last_transaction_date"},
  {name: "Loan Payment", value: "loan_payment"},
  {name: "Loan Payoff Date", value: "loan_payoff_date"},
  {name: "Loan Principal", value: "loan_principal"},
  {name: "Loan Term", value: "loan_term"},
  {name: "Maturity Date", value: "maturity_date"},
  {name: "MICR", value: "micr"},
  {name: "Nickname", value: "nickname"},
  {name: "Number of Transactions Last 30 Days", value: "number_transactions_last_30_days"},
  {name: "Payment Frequency", value: "payment_frequency"},
  {name: "Payment Method", value: "payment_method"},
  {name: "Date Opened", value: "opened_date", priority: true},
  {name: "Due Date", value: "payment_due_date"},
  {name: "Product Code", value: "product.code", priority: true},
  {name: "Product Description", value: "product.description"},
  {name: "Product Name", value: "product.name"},
  {name: "Product Services", value: "product.services"},
  {name: "Product Type", value: "product.type", priority: true},
  {name: "Remote Deposit Last 30 Days", value: "remote_deposit_last_30_days"},
  {name: "Role", value: "role"},
  {name: "Routing Number", value: "routing_number"},
  {name: "Account Status Code", value: "status_code"},
  {name: "Amount of Transactions Last 30 Days", value: "total_amount_last_30_days"},
]

export const allAccountFields = [...accountFields, ...commonFields, ...uiOnlyField].sort(
  ({name: lhsName}, {name: rhsName}) => {
    if (lhsName < rhsName) return -1
    if (lhsName > rhsName) return 1
    return 0
  }
)

export const allAccountFieldValues = [...accountFields, ...commonFields]
  .map(({value}) => value)
  .sort(({name: lhsName}, {name: rhsName}) => {
    if (lhsName < rhsName) return -1
    if (lhsName > rhsName) return 1
    return 0
  })
