import {connect} from "react-redux"

import {campaignBuilderContextProvider} from "../campaign-builder/campaign-builder-context"
import {getTemplates} from "./templates-actions"
import TemplatesList from "./templates-list"

const mapStateToProps = ({templates}) => ({templates: templates.list})

const addActionsToProps = {getTemplates}

export default connect(
  mapStateToProps,
  addActionsToProps
)(campaignBuilderContextProvider(TemplatesList))
