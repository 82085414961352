// This file was generated automatically from character-15.svg with the following command:
// ./svg-converter.js src/components/superhero/character-15.svg
import SuperheroSvg from "./superhero-svg"

const Character15 = props => (
  <SuperheroSvg viewBox="300 75 100 225" {...props}>
    <g id="Layer_24">
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M367.85,295.41c4.2,0.43,10.07,0.61,16.61-0.4l0.08-0.02c0.08-0.03,1.95-0.83,2-2.23
  				c0.03-1.06-0.93-2.04-2.95-3.02l-0.06-0.02c-0.09-0.03-9.04-2.63-9.94-6.05l-0.7,0.19c0.99,3.75,9.72,6.36,10.41,6.56
  				c2.1,1.02,2.53,1.83,2.51,2.33c-0.02,0.84-1.28,1.45-1.51,1.56c-11.92,1.84-21.6-0.35-22.69-0.61c-1.07-1.29,1.02-6.25,2.62-9.12
  				l-0.64-0.35c-0.01,0.02-1.11,2-1.97,4.17c-1.21,3.08-1.36,5.01-0.46,5.89l0.07,0.07l0.09,0.02
  				C361.37,294.38,363.84,295.01,367.85,295.41z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M380.68,295.35l0.51-0.52c-0.76-0.74-1.12-1.52-1.07-2.34c0.08-1.53,1.55-2.71,1.57-2.72
  				l-0.45-0.57c-0.07,0.06-1.74,1.39-1.85,3.25C379.33,293.48,379.77,294.46,380.68,295.35z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M367.33,292.74c2.84,0.29,6.84,0.5,12.42,0.45l-0.01-0.73c-15.24,0.14-18.57-1.68-18.61-1.7
  				l-0.37,0.62C360.85,291.44,362.23,292.22,367.33,292.74z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M379.97,292.18l0.2-0.7c-10.72-3.06-10.7-7.15-10.7-7.19l-0.73-0.04
  				C368.73,284.44,368.6,288.94,379.97,292.18z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M377.78,290.44c0.6,0.06,1.4-0.32,1.67-0.46l-0.33-0.65c-0.52,0.27-1.14,0.44-1.31,0.37
  				c0-0.01,0-0.02,0-0.03c-0.02-1.28-0.4-1.28-2.22-1.26c-0.71,0.01-1.23,0.02-1.35-0.11c-0.12-0.12-0.13-0.64-0.14-1.35
  				c-0.01-0.6-0.19-0.97-0.55-1.12c-0.7-0.28-1.64,0.61-1.91,0.89l0.52,0.51c0.45-0.46,0.99-0.78,1.12-0.72c0,0,0.09,0.08,0.09,0.45
  				c0.02,2.04,0.19,2.2,2.23,2.18c0.72-0.01,1.34-0.02,1.45,0.06c0.02,0.04,0.02,0.27,0.03,0.47c0,0.28,0.12,0.51,0.34,0.64
  				C377.53,290.39,377.65,290.43,377.78,290.44z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M333.35,296.05c-4.19,0.5-10.06,0.79-16.61-0.11l-0.08-0.02c-0.08-0.03-1.97-0.79-2.03-2.19
  				c-0.05-1.05,0.9-2.06,2.9-3.07l0.06-0.02c0.09-0.03,8.99-2.79,9.83-6.23l0.71,0.17c-0.92,3.77-9.61,6.53-10.29,6.74
  				c-2.09,1.06-2.5,1.88-2.47,2.38c0.04,0.84,1.31,1.43,1.54,1.53c11.95,1.63,21.59-0.73,22.68-1.01c1.05-1.31-1.13-6.23-2.78-9.07
  				l0.63-0.37c0.01,0.02,1.15,1.98,2.04,4.14c1.27,3.06,1.45,4.98,0.57,5.88l-0.07,0.07l-0.09,0.03
  				C339.81,294.9,337.35,295.57,333.35,296.05z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M320.52,296.21L320,295.7c0.74-0.75,1.09-1.54,1.03-2.35c-0.11-1.53-1.6-2.68-1.62-2.69l0.44-0.58
  				c0.07,0.05,1.77,1.36,1.9,3.22C321.83,294.32,321.41,295.3,320.52,296.21z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M333.82,293.37c-2.84,0.34-6.83,0.62-12.41,0.66l-0.01-0.73c15.24-0.13,18.54-2,18.57-2.02
  				l0.38,0.62C340.27,291.95,338.91,292.76,333.82,293.37z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M321.17,293.02l-0.21-0.7c10.66-3.24,10.58-7.34,10.58-7.38l0.73-0.05
  				C332.28,285.09,332.49,289.59,321.17,293.02z"
          />
        </g>
        <g>
          <path
            style={{fill: "#09283F"}}
            d="M323.33,291.25c-0.6,0.07-1.4-0.29-1.68-0.43l0.32-0.65c0.52,0.26,1.15,0.42,1.31,0.35
  				c0-0.01,0-0.02,0-0.03c-0.01-1.28,0.37-1.28,2.2-1.29c0.71,0,1.23-0.01,1.35-0.13c0.12-0.12,0.12-0.64,0.12-1.35
  				c0-0.6,0.17-0.98,0.53-1.13c0.69-0.29,1.65,0.58,1.93,0.86l-0.51,0.52c-0.46-0.45-1-0.76-1.14-0.7c0,0-0.09,0.08-0.08,0.45
  				c0.01,2.04-0.15,2.2-2.19,2.21c-0.72,0-1.34,0.01-1.45,0.09c-0.02,0.05-0.02,0.27-0.02,0.47c0,0.28-0.12,0.51-0.33,0.65
  				C323.58,291.2,323.46,291.23,323.33,291.25z"
          />
        </g>
      </g>
      <polygon
        style={{fill: "#0A273F"}}
        points="350.8,208.33 357.64,284.37 379.47,283.44 367.06,178.94 344.16,175.5 	"
      />
      <path
        style={{display: "none", fill: "#123252"}}
        d="M326.59,133.95c11.32,0.65,22.64,1.3,33.96,1.96c0.09,5.8,0.17,11.6,0.26,17.41
  		c0.27,1.04,0.51,2.15,0.73,3.31c0.43,2.38,0.65,4.59,0.74,6.59c-0.13,1.01-0.26,2.01-0.39,3.02c-0.09,1.33-0.37,3.35-1.34,5.6
  		c-0.98,2.3-2.27,3.92-3.18,4.9c-1.53,1.97-3.69,4.35-6.63,6.63c-2.08,1.61-4.09,2.82-5.83,3.71c-2.37,32.4-4.73,64.8-7.1,97.21
  		c-10.65-0.94-21.3-1.88-31.96-2.83l14.38-131.71"
      />
      <polygon
        style={{fill: "#0A273F"}}
        points="348.97,210.49 342.14,286.53 320.3,285.6 332.72,181.1 355.61,177.66 	"
      />
      <path
        style={{fill: "#EB6669"}}
        d="M335.04,181.58c10.62-0.33,21.24-0.66,31.86-0.99c0.59-0.07,1.1-0.21,1.55-0.39
  		c4.62-1.88,5.22-9.23,5.28-10.14c0-8.44,0-16.88,0-25.31c0-10.82-8.19-19.59-18.29-19.59h-7.71c-10.1,0-18.29,8.77-18.29,19.59
  		v25.31c-0.19,6.13,1.36,10.78,3.99,11.52C333.86,181.7,334.39,181.74,335.04,181.58z"
      />
      <path
        style={{fill: "#EB6669"}}
        d="M388.87,151.13L388.87,151.13L388.87,151.13c0.24-0.91,0.49-1.82,0.73-2.73
  		c-0.46,1.73-0.93,3.46-1.39,5.2c-2.19-1.56-4.39-3.11-6.58-4.67c-3.51-2.49-7.02-4.98-10.54-7.48c-0.8-0.57-1.61-1.14-2.41-1.71
  		c-0.69-0.49-1.18-1.1-1.24-1.89c-0.06-0.73,0.31-1.69,0.99-2.3c1.38-1.23,3.91-1.84,5.39-0.79c2.19,1.56,4.39,3.11,6.58,4.67
  		c3.51,2.49,7.02,4.98,10.54,7.48c0.8,0.57,1.61,1.14,2.41,1.71c0.94,0.66,1.45,1.57,1.17,2.67c-0.41,1.61-2.56,2.52-2.56,2.52
  		s0,0,0,0c-0.01,0-3.19,0.53-3.75-0.22C387.68,152.88,388.87,151.14,388.87,151.13z"
      />
      <g>
        <path
          style={{fill: "#E3B59D"}}
          d="M310.32,119.2c0,0-0.19,0.61-0.48,0.53c-0.29-0.09-0.85-0.84-0.5-1.89
  			c0.23-0.69,0.6-3.67-1.63-5.04c0,0-2.91-1.01-4.31,1.67c-1.05,2.01-1.45,3.83-0.25,6.89c1.12,2.86,2.64,4,3.21,4.84
  			c0.29,0.81,1.04,2.87,1.77,4.89c0.68,1.87,6.59,0.19,6.01-1.44c-0.56-1.55-1.12-3.1-1.68-4.65c-0.12-0.33-0.24-0.65-0.35-0.98
  			c0.52-0.85,1.65-2.89,1.6-4.66c0,0,0.1-0.85-0.48-1.78C312.63,116.63,310.75,117.62,310.32,119.2z"
        />
      </g>
      <path
        style={{fill: "#CC5F64"}}
        d="M368.95,132.95c0,0,6.16-5.96,8.4-7.45c2.24-1.49,5.6-4.97,2.24-8.45c0,0-2.8-1.99-5.6-2.98
  		s-1.68-3.97-1.68-4.47s0.56-3.97-6.16-5.46c-6.72-1.49-22.97-3.97-29.13-0.5c-6.16,3.48-6.72,2.98-6.72,6.46s0,3.48-5.04,5.96
  		c-5.04,2.48-3.36,6.96-0.56,8.94c2.8,1.99,8.4,5.46,8.96,7.95"
      />
      <g>
        <path
          style={{fill: "#F5F6F6"}}
          d="M344.69,132.76l11.76,0.07c0.52,2.18,1.33,5.46,2.38,9.43c1.7,6.44,2.1,7.34,2.89,10.71
  			c1.48,6.31,1.18,7.85,2.32,13.89c0.64,3.39,1.74,8.25,3.75,14.08c-11.46,0.21-22.91,0.43-34.37,0.64
  			c1.49-4.41,2.47-8.03,3.06-10.39c2.46-9.82,2.15-13.32,4.61-23.68c0.26-1.11,1.25-5.18,2.4-9.88
  			C343.99,135.56,344.41,133.86,344.69,132.76z"
        />
      </g>

      <rect
        x="347.44"
        y="123.98"
        transform="matrix(-1 -4.504276e-11 4.504276e-11 -1 701.495 257.892)"
        style={{fill: "#DABCAB"}}
        width="6.62"
        height="9.93"
      />

      <ellipse
        transform="matrix(0.9985 -0.0542 0.0542 0.9985 -6.7316 19.2227)"
        style={{fill: "#DABCAB"}}
        cx="350.75"
        cy="133.62"
        rx="3.31"
        ry="3.31"
      />
      <circle style={{fill: "#DABCAB"}} cx="350.65" cy="107.19" r="21.22" />
      <circle style={{fill: "#DABCAB"}} cx="329.43" cy="107.39" r="4.36" />
      <circle style={{fill: "#DABCAB"}} cx="371.87" cy="107.39" r="4.36" />
      <g>
        <defs>
          <circle
            id="SVGID_00000005266320989831846720000008150560464968708235_"
            cx="350.65"
            cy="107.19"
            r="21.22"
          />
        </defs>
        <clipPath id="c5a85f14-SVGID_00000142876436760545137570000007157005941270287250_">
          <use
            xlinkHref="#SVGID_00000005266320989831846720000008150560464968708235_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <path
          style={{
            clipPath: "url(#SVGID_00000142876436760545137570000007157005941270287250_)",
            fill: "#123252",
          }}
          d="M371.87,111.75
  			c0,0-5.95,6.74-11.5,7.54c0,0,0-4.76-4.76-4.76c-5.55,0-14.28-1.59-14.28,4.76c0,0-5.55,1.59-11.9-7.54v11.9l24.99,8.73
  			l17.45-10.31V111.75z"
        />
      </g>
      <path
        style={{fill: "#FFFFFF"}}
        d="M346.92,117.28c0.11-0.2,0.38-0.24,0.56-0.11c0.43,0.31,1.23,0.96,2.36,1.18
  		c1.54,0.31,2.88-0.31,3.2,0.09c0.18,0.22-0.04,0.5-0.46,0.85c-0.42,0.35-1.7,1.04-3.11,0.77c-1.48-0.28-2.6-2-2.6-2.6
  		C346.87,117.39,346.89,117.33,346.92,117.28z"
      />
      <g>
        <g>
          <path
            style={{fill: "#123252"}}
            d="M348.68,111.01c0.97,0.57,2.05,0.66,3.08,0.2c0.45-0.2,0.89-0.52,1.2-0.91
  				c0.19-0.23,0.23-0.45,0.21-0.72c-0.01-0.23-0.02-0.46-0.03-0.69c-0.03-0.66-0.06-1.31-0.09-1.97c-0.02-0.44-0.04-0.88-0.06-1.32
  				c-0.02-0.39-0.33-0.77-0.75-0.75c-0.39,0.02-0.77,0.33-0.75,0.75c0.06,1.21,0.12,2.42,0.17,3.63c0.01,0.18,0.02,0.35,0.03,0.53
  				c0.07-0.18,0.15-0.35,0.22-0.53c-0.07,0.08-0.14,0.16-0.22,0.23c-0.04,0.04-0.08,0.07-0.12,0.11c-0.02,0.02-0.05,0.04-0.07,0.06
  				c-0.05,0.06,0.16-0.11,0.05-0.04c-0.12,0.08-0.24,0.16-0.36,0.23c-0.03,0.01-0.06,0.03-0.08,0.04c-0.15,0.07,0,0.01,0,0
  				c0,0.02-0.21,0.07-0.24,0.08c-0.08,0.02-0.17,0.04-0.26,0.06c0.05-0.01,0.14-0.01,0.04-0.01c-0.05,0-0.09,0.01-0.14,0.01
  				c-0.1,0-0.2-0.01-0.29-0.01c-0.09,0,0.13,0.03-0.01,0c-0.05-0.01-0.11-0.02-0.16-0.04c-0.05-0.01-0.11-0.03-0.16-0.05
  				c-0.02-0.01-0.18-0.07-0.11-0.04c0.08,0.03-0.1-0.05-0.11-0.06c-0.07-0.04-0.14-0.08-0.21-0.12c-0.34-0.2-0.84-0.09-1.03,0.27
  				C348.22,110.34,348.32,110.8,348.68,111.01L348.68,111.01z"
          />
        </g>
      </g>
      <circle style={{fill: "#123252"}} cx="359.33" cy="103.68" r="1.66" />
      <circle style={{fill: "#123252"}} cx="344.26" cy="103.68" r="1.66" />
      <g>
        <g>
          <path
            style={{fill: "#123252"}}
            d="M363.24,98.97c-0.73-0.18-1.49-0.27-2.23-0.34c-1.44-0.12-2.92-0.05-4.31,0.34
  				c-0.57,0.16-0.97,0.8-0.79,1.38c0.19,0.58,0.78,0.95,1.38,0.79c0.33-0.09,0.67-0.16,1.01-0.22c0.33-0.06-0.23,0.02,0.11-0.02
  				c0.07-0.01,0.14-0.01,0.2-0.02c0.17-0.02,0.33-0.03,0.5-0.04c0.63-0.03,1.26-0.02,1.89,0.04c0.13,0.01,0.26,0.02,0.4,0.04
  				c0.06,0.01,0.12,0.01,0.19,0.02c0.21,0.02-0.19-0.03-0.06-0.01c0.19,0.03,0.39,0.06,0.58,0.09c0.18,0.03,0.35,0.07,0.53,0.11
  				c0.57,0.14,1.25-0.18,1.38-0.79C364.16,99.77,363.85,99.12,363.24,98.97L363.24,98.97z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#123252"}}
            d="M341.43,101.14c0.17-0.04,0.33-0.07,0.5-0.1c0.19-0.04,0.38-0.06,0.57-0.09
  				c0.24-0.04-0.28,0.03-0.02,0c0.05-0.01,0.1-0.01,0.15-0.02c0.14-0.02,0.29-0.03,0.43-0.04c0.61-0.05,1.23-0.07,1.84-0.04
  				c0.17,0.01,0.33,0.02,0.5,0.03c0.08,0.01,0.17,0.02,0.25,0.03c0.22,0.02-0.08-0.02,0.06,0.01c0.36,0.07,0.71,0.13,1.07,0.23
  				c0.57,0.16,1.25-0.19,1.38-0.79c0.14-0.6-0.18-1.21-0.79-1.38c-1.39-0.39-2.88-0.46-4.31-0.34c-0.75,0.06-1.5,0.16-2.23,0.34
  				c-0.57,0.14-0.97,0.82-0.79,1.38C340.24,100.94,340.82,101.29,341.43,101.14L341.43,101.14z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M331.08,136.42c-1.64,1.39-3.28,2.79-4.93,4.18c-2.62,2.22-5.23,4.44-7.85,6.67
  				c-0.6,0.51-1.2,1.02-1.8,1.53c1.74,0.45,3.48,0.9,5.22,1.34c-0.86-2.4-1.73-4.79-2.59-7.19c-1.38-3.84-2.77-7.67-4.15-11.51
  				c-0.32-0.88-0.63-1.75-0.95-2.63c-0.27-0.76-0.71-1.41-1.43-1.82c-0.67-0.38-1.66-0.54-2.41-0.31c-1.52,0.48-2.76,2.14-2.18,3.75
  				c0.86,2.4,1.73,4.79,2.59,7.19c1.38,3.84,2.77,7.67,4.15,11.51c0.32,0.88,0.63,1.75,0.95,2.63c0.37,1.02,1.07,1.83,2.18,2.13
  				c1.13,0.3,2.18-0.06,3.04-0.78c1.64-1.39,3.28-2.79,4.93-4.18c2.62-2.22,5.23-4.44,7.85-6.67c0.6-0.51,1.2-1.02,1.8-1.53
  				c1.24-1.05,1.18-3.25,0-4.31C334.18,135.24,332.4,135.3,331.08,136.42L331.08,136.42z"
          />
        </g>
      </g>
      <g>
        <defs>
          <circle
            id="SVGID_00000143584302183097135830000002950070198341540506_"
            cx="205.71"
            cy="86.36"
            r="26.02"
          />
        </defs>
        <clipPath id="c5a85f14-SVGID_00000171710226342844719180000001118208692132990625_">
          <use
            xlinkHref="#SVGID_00000143584302183097135830000002950070198341540506_"
            style={{overflow: "visible"}}
          />
        </clipPath>
      </g>
      <polygon
        style={{fill: "#123252"}}
        points="356.49,91.54 344.96,91.54 344.96,78.68 356.6,78.68 	"
      />
      <path
        style={{fill: "#F5F6F6"}}
        d="M365.77,130.93c0-0.41-0.36-0.74-0.81-0.74c-0.45,0-0.81,0.33-0.81,0.74v15.09l1.62,1.11V130.93z"
      />
      <path
        style={{fill: "#F5F6F6"}}
        d="M337.95,130.48c0.04-0.41-0.29-0.77-0.73-0.81c-0.44-0.04-0.84,0.25-0.88,0.66l-1.49,15.47
  		l1.71-0.94L337.95,130.48z"
      />
      <g>
        <path
          style={{fill: "#E3B59D"}}
          d="M367.29,162.71c0,0,0.64,0.08,0.6,0.38c-0.04,0.3-0.68,0.98-1.77,0.82
  			c-0.72-0.1-3.71,0.05-4.68,2.48c0,0-0.49,3.04,2.39,3.96c2.16,0.69,4.03,0.76,6.83-0.95c2.62-1.59,3.48-3.29,4.2-4
  			c0.75-0.43,2.65-1.52,4.51-2.59c1.73-0.99-0.96-6.53-2.46-5.66c-1.43,0.82-2.86,1.64-4.29,2.46c-0.3,0.17-0.6,0.35-0.91,0.52
  			c-0.93-0.37-3.13-1.12-4.87-0.76c0,0-0.85,0.05-1.67,0.78C364.36,160.89,365.66,162.57,367.29,162.71z"
        />
      </g>
      <path
        style={{fill: "#EB6669"}}
        d="M390.8,152.36L390.8,152.36L390.8,152.36c-0.78-0.52-1.56-1.04-2.34-1.56
  		c1.48,0.99,2.97,1.97,4.45,2.96c-1.64,1.15-3.29,2.31-4.93,3.46c-2.63,1.85-5.27,3.7-7.9,5.55c-0.6,0.42-1.2,0.85-1.81,1.27
  		c-0.52,0.36-1.12,0.52-1.83,0.28c-0.66-0.22-1.46-0.85-1.92-1.6c-0.93-1.52-1.16-3.72-0.06-4.5c1.64-1.15,3.29-2.31,4.93-3.46
  		c2.63-1.85,5.27-3.7,7.9-5.55c0.6-0.42,1.2-0.85,1.81-1.27c0.7-0.49,1.57-0.57,2.51,0.05c1.38,0.9,1.94,2.91,1.94,2.91s0,0,0,0
  		c0,0.01,0.09,2.68-0.64,2.85C392.21,153.92,390.81,152.37,390.8,152.36z"
      />
    </g>
  </SuperheroSvg>
)

export default Character15
