import {TableRow as MuiTableRow, TableCell, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import cx from "classnames"
import {addHours, formatDistanceToNow, isBefore} from "date-fns"
import {array, arrayOf, bool, number, shape, string} from "prop-types"
import {FaChevronRight as ChevronRightIcon} from "react-icons/fa"
import {Link} from "react-router-dom"

import {distanceDateTime} from "lib/date-time-formatters"
import {fullName} from "lib/names"

const Content = ({field, journey, template, templatePages}) => {
  const {id, analyticsCache, insertedAt, contact} = journey,
    {isCompleted, lastViewed, pagesViewed} = analyticsCache || {},
    journeyDurationHours = template && template.journeyDurationHours,
    owner = journey.owner || (template || {}).owner || {},
    pagesLength = templatePages.length ?? 0,
    pagesViewedLength = pagesViewed ? pagesViewed.length : 0
  let dueIn

  if (journeyDurationHours) dueIn = addHours(new Date(insertedAt), journeyDurationHours)

  switch (field) {
    case "name":
      return (
        <Typography
          className="name-link"
          color="inherit"
          component={Link}
          to={`/admin/journeys/${id}`}
          variant="body2"
        >
          {fullName(contact) || "Unknown"}
          <ChevronRightIcon className="icon-right" />
          {contact && (
            <div>
              <small>{contact.uniqueId}</small>
            </div>
          )}
        </Typography>
      )
    case "viewStatus":
      return (
        <>
          <Typography className="text" component="span">
            {pagesViewedLength} / {pagesLength}
          </Typography>
          <span
            className={cx("circle", {
              unopened: pagesViewedLength === 0,
              opened: pagesViewedLength > 0 && pagesViewedLength < pagesLength,
              completed: pagesViewedLength === pagesLength,
            })}
          />
        </>
      )
    case "insertedAt":
      return distanceDateTime(insertedAt)
    case "journeyDurationHours":
      return isCompleted ? (
        "Complete"
      ) : (
        <span>
          {formatDistanceToNow(dueIn)} {isBefore(dueIn, new Date()) ? "overdue" : "remaining"}
        </span>
      )
    case "owner":
      return owner && fullName(owner)
    case "analyticsCache.lastViewed":
      return lastViewed ? distanceDateTime(lastViewed) : "never viewed"
    default:
      return null
  }
}

const TableRow = styled(MuiTableRow)`
  & .name-link {
    text-decoration: none;
  }

  & .icon-right {
    margin-left: 10px;
  }

  & .view-status .text {
    display: inline-block;
    width: 75px;
  }

  & .view-status .circle::after {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    width: 30px;
    content: "";
    border-radius: 100%;
  }

  & .view-status .circle.unopened::after {
    background-color: #dff0fd;
  }

  & .view-status .circle.opened::after {
    background-color: #81c5f5;
  }

  & .view-status .circle.completed::after {
    background-color: #2299ee;
  }
`

const TemplateJourneysRow = ({columns, template, row: journey, templatePages}) => (
  <TableRow>
    {columns.map(({field}) => (
      <TableCell className={`${field}Cell`} component="td" key={field} scope="row">
        <Content
          field={field}
          journey={journey}
          template={template}
          templatePages={templatePages}
        />
      </TableCell>
    ))}
  </TableRow>
)

TemplateJourneysRow.propTypes = {
  columns: arrayOf(
    shape({
      field: string,
    })
  ),
  row: shape({
    analyticsCache: shape({
      isCompleted: bool,
      pagesViewed: arrayOf(string),
    }),
    id: string.isRequired,
    insertedAt: string.isRequired,
    owner: shape({
      nameFirst: string,
      nameLast: string,
      nameSuffix: string,
      nameTitle: string,
    }),
  }),
  template: shape({
    journeyDurationHours: number,
    owner: shape({
      nameFirst: string,
      nameLast: string,
      nameSuffix: string,
      nameTitle: string,
    }),
  }),
  templatePages: array,
}

Content.propTypes = {
  field: string,
  journey: TemplateJourneysRow.propTypes.row,
  template: TemplateJourneysRow.propTypes.template,
  templatePages: array,
}

export default TemplateJourneysRow
