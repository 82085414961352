import sortBy from "lodash/sortBy"
import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"

import Box from "components/box/box"
import Padded from "components/padded/padded"
import TitleBar from "components/title-bar/title-bar"

import {fetchTemplateActionCtas, fetchTemplateMessages, fetchTemplatePages} from "lib/api"

import CtaSummaryTable from "../campaign-review/cta-summary-table"

const sortContentBlocks = (contentBlocks, templatePages, templateMessages) => {
  const getContainer = ({containerId, containerType}) => {
    switch (containerType) {
      case "page":
        return templatePages.find(({pageId}) => pageId === containerId)
      case "message":
        return templateMessages.find(({messageId}) => messageId === containerId)
      default:
        return null
    }
  }

  return sortBy(
    contentBlocks.map(contentBlock => ({
      ...contentBlock,
      containerOrder: getContainer(contentBlock)?.order ?? 0,
    })),
    ["containerType", "containerOrder", "order"]
  )
}

const CtaSummaryContainer = () => {
  const {templateId} = useParams()
  const [contentBlocks, setContentBlocks] = useState([])
  const [templatePages, setTemplatePages] = useState([])
  const [templateMessages, setTemplateMessages] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    Promise.all([
      fetchTemplateActionCtas(templateId),
      fetchTemplatePages(templateId),
      fetchTemplateMessages(templateId),
    ])
      .then(([_contentBlocks, _templatePages, _templateMessages]) => {
        setContentBlocks(sortContentBlocks(_contentBlocks, _templatePages, _templateMessages))
        setTemplatePages(_templatePages)
        setTemplateMessages(_templateMessages)

        return [_contentBlocks, _templatePages, _templateMessages]
      })
      .catch(setError)
      .finally(() => {
        document.getElementById("root")?.setAttribute("ready-to-print", "true")
      })
  }, [templateId])

  if (error) return error.toLocaleString()

  const getContainer = ({containerId, containerType}) => {
    switch (containerType) {
      case "page":
        return templatePages.find(({pageId}) => pageId === containerId)
      case "message":
        return templateMessages.find(({messageId}) => messageId === containerId)
      default:
        return null
    }
  }

  const getJourneyContainer = contentBlock => {
    const {containerType, slug} = contentBlock
    const container = getContainer(contentBlock)
    const containerSlug = container?.content_variables?.[slug]

    switch (containerType) {
      case "page":
        return templatePages.find(({page}) => page.slug === containerSlug)
      case "message":
        return templateMessages.find(({message}) => message === containerSlug)
      default:
        return null
    }
  }

  const destinationLink = contentBlock => {
    const {
      containerType,
      data: {linksTo, linkUrl},
    } = contentBlock

    switch (linksTo) {
      case "no-link":
        return "No link"
      case "journey-page":
        return getJourneyContainer(contentBlock)?.[containerType]?.contentName ?? "Not Found"
      case "url":
        return linkUrl
      default:
        return "Unknown"
    }
  }

  const location = contentBlock =>
    getContainer(contentBlock)?.[contentBlock.containerType]?.contentName ?? "Not Found"

  return (
    <Padded className="preview-wrapper">
      <TitleBar title="CTA Summary" />
      <Box>
        <CtaSummaryTable
          rows={contentBlocks.map(contentBlock => ({
            destination: destinationLink(contentBlock),
            id: contentBlock.id,
            location: location(contentBlock),
            type: contentBlock.type,
          }))}
        />
      </Box>
    </Padded>
  )
}

export default CtaSummaryContainer
