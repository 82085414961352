import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, oneOf} from "prop-types"
import {useState} from "react"

import ObjectiveSelect from "../objective-select/objective-select"

const useStyles = makeStyles(theme => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
}))

const ObjectivePicker = ({type, onSelectObjective, onClose}) => {
  const classes = useStyles()

  const [selectedObjective, setSelectedObjective] = useState("")

  return (
    <Dialog onClose={onClose} open={true}>
      <DialogTitle>Select an objective to associate the new {type} with</DialogTitle>
      <DialogContent>
        <FormControl fullWidth={true}>
          <InputLabel id="new-entity-objective-label">Objectives</InputLabel>
          <ObjectiveSelect
            labelId="new-entity-objective-label"
            onChange={objective => setSelectedObjective(objective)}
            value={selectedObjective}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Tooltip
          title={selectedObjective === "" ? "You must select an objective before continuing" : ""}
        >
          <span>
            <Button
              color="primary"
              disabled={selectedObjective === ""}
              onClick={() => onSelectObjective(selectedObjective)}
              variant="contained"
            >
              Next
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

ObjectivePicker.propTypes = {
  onClose: func.isRequired,
  onSelectObjective: func.isRequired,
  type: oneOf(["page", "sms", "email"]).isRequired,
}

export default ObjectivePicker
