import {TableRow, Typography} from "@mui/material"
import {array, func, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import TableCellLink from "components/table-cell-link/table-cell-link"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchHouseholdContacts} from "lib/api"
import {age} from "lib/date-time-formatters"
import {fullName} from "lib/names"

import Box from "../box/box"
import Padded from "../padded/padded"

const valueForContact = (contact, field) => {
  switch (field) {
    case "name_full":
      return fullName(contact)
    case "age":
      return age(contact.birthdate)
    default:
      return contact[field] || ""
  }
}

const ContactHousehold = ({contact, setTableState, rows, updateStateForRequest}) => {
  const [isTableLoading, setTableLoading] = useState(true)

  const refresh = attrs => {
    if (!contact.id) return

    setTableLoading(true)

    const params = updateStateForRequest(attrs)

    fetchHouseholdContacts(contact.id, params)
      .then(rows => {
        setTableState({rows, ...attrs})
      })
      .finally(() => {
        setTableLoading(false)
      })
  }

  // While this effect uses `refresh` and `contact, it only needs to subscribe to
  // changes in contact.id.
  // eslint-disable-next-line
  useEffect(refresh, [contact.id])

  if (!rows.length) return null

  const {address, address2, city, state, postCode} = contact

  return (
    <Padded>
      <Typography variant="h5">Household</Typography>
      <Typography>
        {[address, address2, city, state, postCode].filter(Boolean).join(", ")}
      </Typography>
      <Box>
        <DOTable
          paginationEnabled={false}
          noResults="No household members found for this contact"
          headers={[
            {field: "uniqueId"},
            {field: "name_full", label: "Name"},
            {field: "age"},
            {field: "email"},
          ]}
          isTableLoading={isTableLoading}
          refresh={refresh}
        >
          {(contact, index, columns) => (
            <TableRow key={contact.id}>
              {columns.map(({field}) => (
                <TableCellLink
                  className={`contact-field-cell-${field}`}
                  component="td"
                  key={`ch-${field}`}
                  scope="row"
                  to={`/admin/contacts/${contact.id}`}
                >
                  {valueForContact(contact, field)}
                </TableCellLink>
              ))}
            </TableRow>
          )}
        </DOTable>
      </Box>
    </Padded>
  )
}

ContactHousehold.propTypes = {
  contact: shape({
    id: string,
    address: string,
    address2: string,
    city: string,
    state: string,
    postCode: string,
  }),
  setTableState: func,
  updateStateForRequest: func,
  rows: array,
}

export default tabular({
  sortColumn: "birthdate",
  sortDirection: "desc",
})(ContactHousehold)
