import {IconButton, Tooltip} from "@mui/material"
import cx from "classnames"
import {RichUtils} from "draft-js"
import {func, object} from "prop-types"

// original source: https://github.com/draft-js-plugins/draft-js-plugins/blob/9975f41d7f0533843882e5bf4e483081c17f7d12/draft-js-buttons/src/utils/createBlockStyleButton.js

export default ({blockType, children, tooltipText}) => {
  const BlockStyleButton = ({onSetEditorState, editorState, classes}) => {
    const toggleStyle = e => {
      e.preventDefault()
      onSetEditorState(RichUtils.toggleBlockType(editorState, blockType))
    }

    const isBlockTypeActive = () => {
      const type = editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection().getStartKey())
        .getType()

      return type === blockType
    }

    return (
      <Tooltip title={tooltipText}>
        <IconButton
          className={cx(classes.button, {
            [classes.buttonActive]: isBlockTypeActive(),
          })}
          onClick={toggleStyle}
          type="button"
          size="medium"
        >
          {children}
        </IconButton>
      </Tooltip>
    )
  }

  BlockStyleButton.propTypes = {
    classes: object,
    editorState: object,
    onSetEditorState: func,
  }

  return BlockStyleButton
}
