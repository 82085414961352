import {TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {bool, func, shape, string} from "prop-types"

import {formify} from "lib/hooks/use-form"

import FormActions from "../form-actions"

const parseHeight = value => (isNaN(parseInt(value, 10)) ? "" : Math.max(parseInt(value, 10), 10))

const DividerSettings = ({contentBlock: {slug: widgetSlug}, field, handleSubmit, resetForm}) => {
  const classes = useStyles()
  return (
    <form className={classes.root} onSubmit={handleSubmit} aria-label="Divider Settings">
      <TextField
        id={`divider-${widgetSlug}-height`}
        autoFocus={true}
        fullWidth={true}
        label="Height (pixels)"
        margin="normal"
        {...field("height")}
      />
      <FormActions resetForm={resetForm} />
    </form>
  )
}

DividerSettings.propTypes = {
  contentBlock: shape({
    slug: string.isRequired,
  }).isRequired,
  isPage: bool,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func,
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}))

const DividerSettingsForm = formify({
  parse: {
    height: parseHeight,
  },
})(DividerSettings)

export default DividerSettingsForm
