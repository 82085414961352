import makeStyles from "@mui/styles/makeStyles"
import {func} from "prop-types"

import AdvancedCampaignObjectives from "components/advanced-team-insights/advanced-campaign-objectives"
import AdvancedCampaignPerformance from "components/advanced-team-insights/advanced-campaign-performance"
import AdvancedEngagement from "components/advanced-team-insights/advanced-engagement"
import AdvancedPerformance from "components/advanced-team-insights/advanced-performance"
import {
  advancedTeamInsightsContext,
  provideAdvancedTeamInsights,
} from "components/advanced-team-insights/advanced-team-insights-context"
import Filter from "components/advanced-team-insights/advanced-team-insights-filter"
import AdvancedValueDrivers from "components/advanced-team-insights/advanced-value-drivers"
import AdvancedAggregateWidgetReporting from "components/advanced-team-insights/aggregate-widget-reporting/advanced-aggregate-widget-reporting"
import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import Padded from "components/padded/padded"
import {ObjectivesContextProvider} from "components/team-insights/objectives-context"
import TitleBar from "components/title-bar/title-bar"

import AdvancedProducts from "./advanced-products"

const AdvancedTeamInsights = ({setFilterParams}) => {
  const classes = useStyles()

  return (
    <ObjectivesContextProvider>
      <TitleBar title="Insights Dashboard" />
      <DocumentTitle title="Insights - Dashboard" />

      <Padded>
        <Box
          sx={{
            background: "#002956",
            color: "white",
            display: "flex",
            alignItems: "center",
            "& .label": {
              fontWeight: "bold",
              fontSize: 32,
              mr: 2,
              pr: 2,
              borderRight: "1px solid white",
            },
            "& div b": {
              fontSize: 17,
            },
            "& div p": {
              fontSize: 14,
            },
          }}
        >
          <div className="label">Beta</div>
          <div>
            <b>Why are team insights in beta?</b>
            <p>
              We're currently focused on scaling team insights to meet your needs and during this
              time it's possible that reports could be slow to generate or outdated.
            </p>
          </div>
        </Box>
      </Padded>

      <Padded>
        <Box>
          <Filter onSubmit={setFilterParams} />

          <AdvancedCampaignPerformance classes={classes} />
          <AdvancedPerformance classes={classes} />
          <AdvancedCampaignObjectives classes={classes} />
          <AdvancedValueDrivers classes={classes} />
          <AdvancedProducts classes={classes} />
          <AdvancedAggregateWidgetReporting classes={classes} />
          <AdvancedEngagement classes={classes} />
        </Box>
      </Padded>
    </ObjectivesContextProvider>
  )
}

AdvancedTeamInsights.propTypes = {
  setFilterParams: func.isRequired,
}

const useStyles = makeStyles(theme => ({
  buttonGroupsContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1),

    // Applies to all children except last
    "& > :nth-last-child(n + 2)": {
      marginBottom: theme.spacing(0.5),
    },
  },
  expandableContainer: {
    marginBottom: theme.spacing(3),
  },
  header: {
    fontSize: 36,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  hideChartLabel: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  section: {
    paddingTop: 50,
  },
  tables: {
    // Applies to all children except last
    "& > :nth-last-child(n + 2)": {
      marginBottom: theme.spacing(7),
    },
  },
}))

export default provideAdvancedTeamInsights(advancedTeamInsightsContext(AdvancedTeamInsights))
