import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {PureComponent} from "react"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"
import {dataToUrl} from "./video-helpers"
import Wistia from "./wistia"

const Video = ({service, id, isEditMode}) => {
  if (service === "wistia") return <Wistia id={id} isEditMode={isEditMode} />
  else if (service)
    return (
      <iframe
        frameBorder="0"
        src={dataToUrl({service, id})}
        style={{pointerEvents: isEditMode ? "none" : "auto"}}
        title={`${service} video`}
      />
    )
  else return null
}

Video.propTypes = {
  service: string,
  id: string,
  isEditMode: bool,
}

export class VideoReadonly extends PureComponent {
  render() {
    const {
      contentBlock,
      classes,
      className,
      customCss,
      isEditMode,
      isSelected,
      onClick,
      style,
    } = this.props
    const {
      data: {service, id},
    } = contentBlock

    return (
      <div
        className={cx(className, classes.root, {
          [`${service}-widget`]: !!service,
          [classes.emptyBackground]: isEditMode && !service,
          isSelected,
        })}
        onClick={onClick}
        data-testid="video-root"
        style={style}
      >
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        <style>{customCss}</style>
        <Video id={id} isEditMode={isEditMode} service={service} />
      </div>
    )
  }
}

VideoReadonly.propTypes = {
  classes: object,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  className: string,
  isEditMode: bool,
  isSelected: bool,
  onClick: func,
  style: object,
}

const styles = theme => ({
  root: {
    position: "relative",
    paddingBottom: "56.25%", //gotta keep it responsive!
    height: 0,
    "& iframe, & object, & embed": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    "&.isSelected": {
      // Selection halo breaks responsive videos, and they look fine without so temporarily apply !important
      padding: "0 !important",
      margin: "0 !important",
      paddingBottom: "56.25% !important",
    },
  },
  emptyBackground: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50' width='120'><text x='60' y='25' fill='${theme.palette.text.hint}' font-size='20' font-family='sans-serif' text-anchor='middle' alignment-baseline='central'>VIDEO</text></svg>")`,
    height: 75,
    border: `1px dashed ${theme.palette.text.hint}`,
  },
})

export default withStyles(styles)(contentBlockEditorConsumer(VideoReadonly))
