import {getCurrentUserAvatarUploadPolicy, updateCurrentUser} from "lib/api"
import SubmissionError from "lib/submission-error"
import uploadFileWithPolicy from "lib/upload-file-with-policy"

import {CURRENT_USER_SET} from "../../actions/session-actions"

export const updateUser = attrs => dispatch =>
  updateCurrentUser(attrs)
    .then(user => {
      dispatch({type: CURRENT_USER_SET, payload: user})
    })
    .catch(e => {
      throw new SubmissionError({
        currentPassword: e.message.includes("current_password") && [
          "Current Password is incorrect",
        ],
        passwordConfirmation: e.message.includes("password_confirmation") && [
          "Repeat Password must match Password",
        ],
      })
    })

export const updateAvatar = file => dispatch =>
  getCurrentUserAvatarUploadPolicy({type: file.type})
    .then(({policy, attrs}) => uploadFileWithPolicy(file, policy, {extraAttrs: attrs}))
    .then(() =>
      updateCurrentUser({isAvatarUploaded: true, avatarType: file.type, avatarSize: file.size})
    )
    .then(user => dispatch({type: CURRENT_USER_SET, payload: user}))
