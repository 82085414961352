import {TableCell, TableRow, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import orderBy from "lodash/orderBy"
import pick from "lodash/pick"
import {func, number, string} from "prop-types"
import {useEffect, useState} from "react"

import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchTeamInsightsCampaignPerformance} from "lib/api"
import {formatDate} from "lib/date-time-formatters"

import Expandable from "../expandable/expandable"
import {useObjectives} from "./objectives-context"
import ObjectivesFilter from "./objectives-filter"
import {useTeamInsights} from "./team-insights-context"

export const CampaignPerformance = ({setTableState, ...props}) => {
  const {
    campaignPerformance,
    campaignPerformanceIsLoading,
    hasSocket,
    fetchInsights,
    filterParams: {
      endDate: endDateFilter,
      startDate: startDateFilter,
      templateStatus: templateStatusFilter,
    },
  } = useTeamInsights()
  const classes = useStyles()
  const [filteredData, setFilteredData] = useState(campaignPerformance?.data || [])
  const objectives = useObjectives()
  const [selectedObjectiveIds, setSelectedObjectiveIds] = useState([])

  useEffect(() => {
    if (hasSocket) {
      fetchInsights("campaignPerformance", fetchTeamInsightsCampaignPerformance, [
        {endDate: endDateFilter, startDate: startDateFilter},
      ])
    }
  }, [endDateFilter, fetchInsights, hasSocket, startDateFilter])

  useEffect(() => {
    const filtered = (campaignPerformance?.data || [])
      .filter(campaign =>
        templateStatusFilter ? campaign.templateStatus === templateStatusFilter : true
      )
      .filter(({objectiveIds}) =>
        // Return all campaigns which contain ALL selected objective ids
        selectedObjectiveIds.length
          ? selectedObjectiveIds.every(id => objectiveIds.includes(id))
          : true
      )

    setFilteredData(filtered)
  }, [campaignPerformance, selectedObjectiveIds, templateStatusFilter])

  useEffect(() => {
    // NB: This is needed so that our rows are effected by our default state.
    // This should only get called after fetchInsights or our socket
    // returns new data.
    refresh({page: 0})

    // FIXME ignoring react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData])

  const refresh = attrs => {
    const currentItemsPerPage = attrs?.itemsPerPage ?? props.itemsPerPage
    const currentPage = attrs?.page ?? props.page
    const offset = currentPage * currentItemsPerPage
    const sortColumn = attrs?.sortColumn ?? props.sortColumn
    const sortDirection = attrs?.sortDirection ?? props.sortDirection

    const rows = orderBy(
      filteredData.filter((item, i) => i >= offset && i < offset + currentItemsPerPage),
      [
        item => {
          const column = item[sortColumn]

          return isNaN(column) ? column.toLowerCase() : column
        },
      ],
      [sortDirection]
    )

    setTableState({
      ...pick(props, "itemsPerPage", "page", "sortColumn", "sortDirection"),
      ...attrs,
      rows,
      page: currentPage,
      totalCount: filteredData.length ?? 0,
    })
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h5">
        Engagement
      </Typography>
      <Typography className={classes.subheader} variant="h5">
        Campaigns
      </Typography>
      <div className={classes.expandableContainer}>
        <Expandable label="How we use this table">
          <p>This table reports on all campaigns and their key performance metrics.</p>

          <p>Use cases</p>
          <ul>
            <li>
              <b>Overview.</b> Monitor overall campaign portfolio performance.
            </li>
            <li>
              <b>Compare Engagement Metrics.</b> Sort features at the column header to see the
              highest and lowest performers (which has the highest CTR, which has the lowest?).
            </li>
            <li>
              <b>Objective Filter.</b> Filter by objective(s) to hone in on a specific campaign type
              (review Activation Campaigns by selecting Online Banking, Mobile Banking, Direct
              Deposit, eStatements, and Debit Card Activation).
            </li>
            <li>
              <b>Date Filter.</b> Use the date filter to align engagement with your omnichannel
              marketing efforts (see Credit Card campaign engagement performance in September while
              the social media Credit Card Rewards ads were running).
            </li>
          </ul>
        </Expandable>
      </div>
      <Expandable label="Hide chart" labelClassName={classes.label} startExpanded={true}>
        <div className={classes.objectivesFilterContainer}>
          <ObjectivesFilter
            id="campaign-performance-objectives"
            objectives={objectives}
            onChange={setSelectedObjectiveIds}
          />
        </div>
        <DOTable
          headers={[
            {
              field: "templateName",
              label: "Campaign Name",
              sortable: true,
              tooltipText: "Campaign Name: Internal name of the campaign",
            },
            {
              field: "launchedAt",
              label: "Launch Date",
              sortable: true,
              tooltipText:
                "Launch Date: The day that the first journey in the campaign was created",
            },
            {
              field: "journeyCount",
              label: "# of Journeys",
              sortable: true,
              tooltipText: "# of Journeys: # of journeys in the campaign",
            },
            {
              field: "messageCtr",
              label: "Message CTR",
              sortable: true,
              tooltipText:
                "Message Click Through Rate: total # of unique message clicks / total # of messages sent (excluding bounces)",
            },
            {
              field: "bounceRate",
              label: "Bounce Rate",
              sortable: true,
              tooltipText: "Bounce Rate: total # of bounces / total # of messages sent",
            },
            {
              field: "pageViewRate",
              label: "Page View Rate",
              sortable: true,
              tooltipText:
                "Page View Rate: total # of unique page views / total # of journeys x # of pages",
            },
            {
              field: "pageCtr",
              label: "Page CTR",
              sortable: true,
              tooltipText:
                "Page Click Through Rate: total # of unique page clicks / total # of unique page views",
            },
            {
              field: "presentedObjectiveCompletionRate",
              label: "Presented Objective Completion Rate",
              sortable: true,
              tooltipText:
                "Presented Objective Completion Rate: total # of unique objective completions / total # of objectives presented to journeys",
            },
          ]}
          isTableLoading={campaignPerformanceIsLoading}
          noResults="No data found."
          paginationEnabled={true}
          refresh={refresh}
        >
          {({
            bounceRate,
            journeyCount,
            launchedAt,
            messageCtr,
            presentedObjectiveCompletionRate,
            pageCtr,
            pageViewRate,
            templateId,
            templateName,
          }) => {
            return (
              <TableRow key={templateId}>
                <TableCell>
                  <Typography
                    color="inherit"
                    component="a"
                    href={`/admin/templates/${templateId}/insights`}
                    target="_blank"
                  >
                    {templateName}
                  </Typography>
                </TableCell>
                <TableCell>{launchedAt ? formatDate(launchedAt) : ""}</TableCell>
                <TableCell>{journeyCount}</TableCell>
                <TableCell>{messageCtr}%</TableCell>
                <TableCell>{bounceRate}%</TableCell>
                <TableCell>{pageViewRate}%</TableCell>
                <TableCell>{pageCtr}%</TableCell>
                <TableCell>{presentedObjectiveCompletionRate}%</TableCell>
              </TableRow>
            )
          }}
        </DOTable>
      </Expandable>
    </div>
  )
}

CampaignPerformance.propTypes = {
  itemsPerPage: number,
  page: number,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
}

const useStyles = makeStyles(theme => ({
  container: {
    "& tr th:nth-child(6)": {
      maxWidth: 170,
    },
    "& tr th:nth-child(7)": {
      maxWidth: 170,
    },
  },
  expandableContainer: {
    marginBottom: theme.spacing(3),
  },
  objectivesFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  header: {
    fontSize: 36,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
  label: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  subheader: {
    fontSize: 24,
    fontWeight: 200,
    marginBottom: theme.spacing(1),
  },
}))

export default tabular({
  sortColumn: "templateName",
  sortDirection: "asc",
})(CampaignPerformance)
