import {Grid, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {array, number, string} from "prop-types"

import SurveyCompletionDonut from "./survey-completion-donut"
import SurveyWidgetBarchart from "./survey-widget-barchart"

const useStyles = makeStyles(theme => ({
  container: {marginBottom: theme.spacing(4)},
  donutWrapper: {marginBottom: theme.spacing(2), width: 370},
}))

const SurveyCompletion = ({questions, stats, surveyName, totalViews}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="h6" sx={{fontWeight: "normal"}}>
        {surveyName}
      </Typography>
      <div className={classes.donutWrapper}>
        <SurveyCompletionDonut stats={stats} totalViews={totalViews} />
      </div>
      <Grid container spacing={8}>
        {questions.map(questionData => (
          <Grid key={questionData.questionSlug} item xs={12} md={6}>
            <SurveyWidgetBarchart {...questionData} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

SurveyCompletion.propTypes = {
  questions: array,
  stats: array,
  surveyName: string.isRequired,
  totalViews: number.isRequired,
}

export default SurveyCompletion
