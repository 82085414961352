import {IconButton, Link as MUILink, TableCell, TableRow, Tooltip, Typography} from "@mui/material"
import {alpha} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import truncate from "lodash/truncate"
import {array, arrayOf, bool, object, shape, string} from "prop-types"
import {useEffect, useState} from "react"
import {
  FaUserShield as AuthIcon,
  FaCreditCard as CreditCardIcon,
  FaRegMoneyBillAlt as DirectDepositCompletedIcon,
  FaFlagCheckered as JourneyCompletedIcon,
  FaMedal as ObjectiveIcon,
  FaRegFileAlt as PageCompletedIcon,
  FaFilePdf as PdfIcon,
  FaClipboardCheck as SurveyCompletedIcon,
  FaClipboard as SurveyQuestionAnsweredIcon,
} from "react-icons/fa"
import {
  FiMap as MapIcon,
  FiUserCheck as OptInIcon,
  FiUserX as OptOutIcon,
  FiPhoneIncoming as PhoneIncomingIcon,
} from "react-icons/fi"
import {
  MdCheck as CheckIcon,
  MdTrendingUp as CtaIcon,
  MdAssignmentTurnedIn as EnrollmentIcon,
  MdErrorOutline as ErrorIcon,
  MdPhoneIphone as MobileAppIcon,
  MdTextsms as SMSIcon,
  MdCallSplit as SignupIcon,
  MdVerifiedUser as VerifiedIcon,
  MdVisibility as ViewIcon,
} from "react-icons/md"
import {
  RiMailCloseLine as EmailBouncedIcon,
  RiMailLine as EmailIcon,
  RiMailOpenLine as EmailOpenedIcon,
  RiMailForbidLine as EmailSkippedIcon,
  RiChatOffLine as SMSSkippedIcon,
} from "react-icons/ri"
import {TiWarning as WarningIcon} from "react-icons/ti"
import {Link} from "react-router-dom"

import {fetchActionDownloadUrl} from "lib/api"
import {distanceDateTime, formatDateTime} from "lib/date-time-formatters"
import {DirectDepositFailedIcon, formatDistribution} from "lib/direct-deposit-helpers"
import {fullName} from "lib/names"
import humanize from "lib/string/humanize"

import IconTooltip from "../icon-tooltip/icon-tooltip"
import cardOnFileStatuses from "./card-on-file-statuses"

const cardOnFileMerchant = merchant => (merchant ? `${merchant.name} (${merchant.host})` : "")

const rowCaption = ({contentBlock, name, message, objective, page, meta}) => {
  if (name.match(/^card_on_file_(abandoned|failed)/))
    return `${cardOnFileStatuses[meta?.job?.jobStatus]} ${
      meta?.job?.merchantSite ? " - " : ""
    } ${cardOnFileMerchant(meta?.job?.merchantSite)}`
  else if (name.match(/^card_on_file/)) return `${cardOnFileMerchant(meta?.job?.merchantSite)}`
  else if (name.match(/^direct_deposit/)) return directDepositRowCaption({name, message, meta})
  else if (name.match(/^cta_clicked/) && contentBlock)
    return ctaRowCaption({contentBlock, page, message})
  else if (objective) return objective.name
  else if (page) return page.contentName
  else if (message)
    return name === "message_bounced"
      ? meta?.email || humanize(message.type)
      : humanize(message.type)
  else return null
}

const ctaRowCaption = ({contentBlock, page, message}) => {
  const contentName = page?.contentName || message?.contentName

  return `${humanize(contentBlock.type)} - ${contentName}`
}

const directDepositRowCaption = ({name, message, meta}) => {
  if (name === "direct_deposit_completed")
    return [meta?.company?.name, ...formatDistribution(meta?.data)].filter(Boolean).join(", ")
  else if (name === "direct_deposit_failed") return meta?.data?.reason
  else return null
}

const rowTitleWithSubject = (title, subject) => (
  <>
    {humanize(title)}:{" "}
    <Tooltip title={subject}>
      <span>{truncate(subject, {length: 30})}</span>
    </Tooltip>
  </>
)

const rowTitle = ({contentBlock, name}) => {
  const ACTIONS_WITH_SUBJECTS = [
    "cta_clicked",
    "enrollment_started",
    "enrollment_terms_link_clicked",
  ]
  if (ACTIONS_WITH_SUBJECTS.includes(name) && !!contentBlock)
    return rowTitleWithSubject(name, contentBlock.data.name || contentBlock.slug)

  return humanize(name)
}

const RowIcon = ({name, message, meta}) => {
  if (name.match(/^auth_/)) return <AuthIcon />
  if (name === "cta_clicked") return <CtaIcon />
  if (name.match(/^enrollment_/)) return <EnrollmentIcon />
  if (name === "journey_opened") return <MapIcon />
  if (name === "journey_signup_form_submitted") return <SignupIcon />
  if (name.match(/^mobile_app_download_/)) return <MobileAppIcon />
  if (name === "objective_completed") return <ObjectiveIcon />
  if (name === "premier_objective_completed") return <ObjectiveIcon />
  if (name === "journey_completed") return <JourneyCompletedIcon />
  if (name === "page_completed") return <PageCompletedIcon />
  if (name === "page_confirmed") return <CheckIcon />
  if (name === "page_viewed") return <ViewIcon />
  if (name === "survey_question_answered") return <SurveyQuestionAnsweredIcon />
  if (name === "survey_completed") return <SurveyCompletedIcon />
  if (name === "message_bounced") return <EmailBouncedIcon />
  if (name === "email_opened") return <EmailOpenedIcon />
  if (name === "opted_in") return <OptInIcon />
  if (name === "opted_out") return <OptOutIcon />
  if (name === "sms_received") return <PhoneIncomingIcon />
  if (name.match(/_errored$/)) return <ErrorIcon />
  if (name === "contact_method_verified") return <VerifiedIcon />
  if (name === "message_skipped" && (message?.type === "email" || meta?.type === "email"))
    return <EmailSkippedIcon />
  if (name === "message_skipped" && (message?.type === "sms" || meta?.type === "sms"))
    return <SMSSkippedIcon />
  if (name.match(/^message_/) && (message?.type === "email" || meta?.type === "email"))
    return <EmailIcon />
  if (name.match(/^message_/) && (message?.type === "sms" || meta?.type === "sms"))
    return <SMSIcon />
  if (name.match(/^card_on_file/)) return <CreditCardIcon />
  if (name === "direct_deposit_failed") return <DirectDepositFailedIcon />
  if (
    [
      "direct_deposit_company_searched",
      "direct_deposit_completed",
      "direct_deposit_payroll_searched",
      "direct_deposit_started",
      "direct_deposit_widget_opened",
    ].includes(name)
  )
    return <DirectDepositCompletedIcon />
  return null
}

RowIcon.propTypes = {
  name: string.isRequired,
  message: object,
  meta: object,
}

const humanizeTooltipType = (name, type) => {
  if (name === "page_viewed") return "page"
  else if (name === "cta_clicked") return "message or page"
  else if (name.match(/^message_/) && type === "email") return "email"
  else if (name.match(/^message_/) && type === "sms") return "SMS"
  else return "item"
}

const MaybePdfIcon = ({classes, action}) => {
  const [isActiveUrl, setIsActiveUrl] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState(null)

  const {name} = action

  useEffect(() => {
    if (name !== "enrollment_accepted") return () => {}

    // NB: Pre-fetch the url and validate the url is active, meaning it returns a 200 status response, not a 401, 403, 404, etc....
    const fetchUrl = async () => {
      const {url} = await fetchActionDownloadUrl(action.id)
      const response = await fetch(url, {cache: "no-cache"})

      setIsActiveUrl(response?.status === 200)
      setDownloadUrl(url)
    }
    fetchUrl()
  }, [action.id, name])

  const shouldDisplayPdfIcon = name === "enrollment_accepted"

  return (
    shouldDisplayPdfIcon && (
      <Tooltip placement="right" title="View enrollment acceptance">
        <IconButton
          className={cx(classes.pdfIcon, {[classes.visible]: isActiveUrl && downloadUrl})}
          component={MUILink}
          disableRipple={true}
          href={downloadUrl}
          target="_blank"
          rel="noopener"
          size="medium"
        >
          <PdfIcon size={20} />
        </IconButton>
      </Tooltip>
    )
  )
}

const MaybeWarningTooltip = ({classes, message, meta, name, page}) => {
  const displayWarningIcon =
    (name.match(/^(page)_/) && !page) ||
    (name.match(/^(message)_/) && !message) ||
    (name.match(/^(cta)_/) && !page && !message)

  return (
    displayWarningIcon && (
      <IconTooltip
        iconClassName={classes.warningIcon}
        iconComponent={WarningIcon}
        title={`The ${humanizeTooltipType(
          name,
          message?.type ?? meta?.type
        )} associated with this action no longer exists.`}
      />
    )
  )
}

const getLocation = remoteIpMeta =>
  [remoteIpMeta?.city, remoteIpMeta?.regionCode].filter(Boolean).join(", ")

const Content = ({action, classes, field}) => {
  const {
    contentBlock,
    name,
    timestamp,
    contact,
    journey,
    message,
    objective,
    page,
    meta,
    remoteIpMeta,
    template,
  } = action
  const distanceDateTimeDisplay = distanceDateTime(timestamp)
  const formatDateTimeDisplay = formatDateTime(timestamp)

  switch (field) {
    case "name":
      return (
        <>
          <div
            className={cx(classes.avatarIcon, {
              // In-journey actions (low level actions)
              [classes.avatarIconBlue]: name.match(
                /^(cta|card_on_file|enrollment|mobile_app_download|page|survey|token|premier_objective)_/
              ),
              // journey level / message level things (ish) (medium level actions)
              [classes.avatarIconGreen]: name.match(
                /^(auth|email|direct_deposit|journey|message|sms|contact|opted)_/
              ),
              // objectives (high level actions that matter the most)
              [classes.avatarIconRed]: name.match(/^(objective)_/),
            })}
          >
            <RowIcon message={message} meta={meta} name={name} />
          </div>
          <div className={classes.avatarLabel}>
            <div className={classes.nameCell}>
              <Typography variant="subtitle1">{rowTitle({contentBlock, name})}</Typography>
              <MaybeWarningTooltip
                classes={classes}
                message={message}
                meta={meta}
                name={name}
                page={page}
              />
              <MaybePdfIcon action={action} classes={classes} />
            </div>
            <Typography className={classes.caption} variant="caption">
              {rowCaption({contentBlock, name, message, objective, page, meta})}
            </Typography>
          </div>
        </>
      )
    case "timestamp":
      return (
        <>
          <Typography variant="body1">{formatDateTimeDisplay}</Typography>
          {distanceDateTimeDisplay !== formatDateTimeDisplay && (
            <Typography className={classes.caption} variant="caption" />
          )}
        </>
      )
    case "contact":
      return contact ? (
        <Typography
          color="textPrimary"
          component={Link}
          to={`/admin/contacts/${contact.id}`}
          variant="body2"
        >
          {fullName(contact)}
        </Typography>
      ) : null
    case "location":
      return <Typography variant="body1">{getLocation(remoteIpMeta)}</Typography>
    case "journey":
      if (journey && template)
        return (
          <Typography
            color="textPrimary"
            component={Link}
            to={`/admin/journeys/${journey.id}`}
            variant="body2"
          >
            {truncate(template.name, {length: 40})}
          </Typography>
        )
      else if (journey)
        return (
          <IconButton component={Link} size="small" to={`/admin/journeys/${journey.id}`}>
            <MapIcon />
          </IconButton>
        )
      else return null

    default:
      return null
  }
}

const TemplateAnalyticsRow = ({classes, columns, row: action}) => (
  <TableRow>
    {columns.map(({field}) => (
      <TableCell className={classes[`${field}Cell`] ?? ""} component="td" key={field} scope="row">
        <Content action={action} classes={classes} field={field} />
      </TableCell>
    ))}
  </TableRow>
)

TemplateAnalyticsRow.propTypes = {
  classes: object,
  columns: arrayOf(
    shape({
      field: string,
      classes: string,
      sortable: bool,
      isDefault: bool,
    })
  ),
  row: shape({
    journey: shape({
      analyticsCache: shape({
        isCompleted: bool,
        pagesViewed: arrayOf(string),
      }),
      id: string.isRequired,
      owner: shape({
        nameFirst: string,
        nameLast: string,
        nameSuffix: string,
        nameTitle: string,
      }),
    }),
    name: string,
    template: shape({
      owner: shape({
        nameFirst: string,
        nameLast: string,
        nameSuffix: string,
        nameTitle: string,
      }),
      pages: array,
    }),
  }),
}

Content.propTypes = {
  action: TemplateAnalyticsRow.propTypes.row,
  classes: TemplateAnalyticsRow.propTypes.classes,
  field: string,
}

MaybePdfIcon.propTypes = {
  action: shape({id: string, name: string}),
  classes: object,
}

MaybeWarningTooltip.propTypes = {
  classes: object,
  message: object,
  meta: object,
  name: string,
  page: object,
}

export default withStyles(theme => ({
  avatarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    overflow: "hidden",
    textAlign: "center",
    borderRadius: "100%",
    fontSize: theme.spacing(4),
  },
  avatarIconBlue: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  avatarIconGreen: {
    color: "#58BEB1",
    backgroundColor: alpha("#58BEB1", 0.1),
  },
  avatarIconRed: {
    color: "#F2654B",
    backgroundColor: alpha("#F2654B", 0.1),
  },
  avatarLabel: {
    flex: 1,
  },
  caption: {
    color: theme.palette.grey[800],
  },
  journeyButton: {
    textTransform: "none",
  },
  journeyCell: {
    textAlign: "left",
  },
  journeyIcon: {
    marginRight: theme.spacing(0.75),
  },
  nameCell: {
    display: "flex",
    alignItems: "center",
  },
  pdfIcon: {
    color: theme.palette.error.main,
    fontSize: 20,
    marginLeft: theme.spacing(1),
    padding: 0,
    opacity: 0,
    visibility: "hidden",
    transition: "100ms opacity ease",
  },
  visible: {
    opacity: 1,
    visibility: "visible",
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: 20,
    marginLeft: theme.spacing(1),
  },
}))(TemplateAnalyticsRow)
