import {Button} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {bool, string} from "prop-types"
import {useEffect, useReducer, useState} from "react"
import {MdAdd as NewMappingIcon} from "react-icons/md"
import {useDispatch, useSelector} from "react-redux"
import shortid from "shortid"

import DOTable from "components/table/table"

import {fetchObjectives, updateCurrentTeam} from "lib/api"
import MaybeTooltip from "lib/maybe-tooltip"

import {controlAccess} from "../../access-control/access-controlled"
import Box from "../../box/box"
import SaveButton from "../../save-button/save-button"
import {TEAM_UPDATED} from "../team-actions"
import {
  addMapping,
  allAccountFieldValues,
  allContactFieldValues,
  compileFieldMap,
  reducer,
  setDefault,
} from "./csv-processing-helpers"
import FieldMapRow from "./field-map-row"

const defaultState = [
  {
    id: shortid.generate(),
    csv_field: "",
    digital_onboarding_field: "ignored",
    meta_sub_key: "",
    csvFieldTaken: false,
  },
]

const useStyles = makeStyles(theme => ({
  tableActions: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const CsvProcessingSettings = ({disabled, fieldName}) => {
  const {[fieldName]: csvFieldMap} = useSelector(({session: {team = {}}}) => team)
  const globalDispatch = useDispatch()

  const [fieldMappings, dispatch] = useReducer(reducer, defaultState)
  const [objectives, setObjectives] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [didError, setDidError] = useState(false)

  const onAddMapping = () => {
    dispatch(addMapping())
  }

  const onSave = () => {
    setIsLoading(true)
    updateCurrentTeam({[fieldName]: compileFieldMap(fieldMappings)})
      .then(team => {
        globalDispatch({type: TEAM_UPDATED, payload: team})
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setDidError(true)
      })
  }

  const onChange = action => {
    dispatch(action)
  }

  const classes = useStyles()

  useEffect(() => {
    fetchObjectives().then(teamObjectives => {
      setObjectives(teamObjectives.map(({name, key: value}) => ({name, value})))
    })
  }, [])

  useEffect(() => {
    if (csvFieldMap) dispatch(setDefault(csvFieldMap))
  }, [csvFieldMap])

  const hasBadMetaMapping = !!(
    fieldMappings &&
    fieldMappings.find(
      field => field.digital_onboarding_field.match(/^meta_(private|public)/) && !field.meta_sub_key
    )
  )

  return (
    <Box>
      <div className={classes.tableWrapper}>
        <DOTable
          headers={[
            {field: "csv_field", label: "CSV Field", sortable: false},
            {field: "digital_onboarding_field", label: "Digital Onboarding Field", sortable: false},
            {field: ""},
          ]}
          paginationEnabled={false}
          rows={fieldMappings}
        >
          {row => (
            <FieldMapRow
              disabled={disabled}
              fields={
                fieldName === "account_field_map" ? allAccountFieldValues : allContactFieldValues
              }
              id={row.id}
              key={row.id}
              objectives={fieldName === "contact_field_map" ? objectives : []}
              onChange={onChange}
              {...row}
            />
          )}
        </DOTable>
      </div>
      <div className={classes.tableActions}>
        <Button disabled={disabled} onClick={onAddMapping} color="grey" variant="contained">
          <NewMappingIcon /> Add New Mapping
        </Button>
      </div>
      <div className={classes.actions}>
        <MaybeTooltip
          isTooltip={hasBadMetaMapping}
          title="You have one or more mappings to a metadata field without a subkey."
        >
          <SaveButton
            disabled={disabled || isLoading || hasBadMetaMapping}
            failed={didError}
            onClick={onSave}
            submitting={isLoading}
          />
        </MaybeTooltip>
      </div>
    </Box>
  )
}

CsvProcessingSettings.propTypes = {
  disabled: bool,
  fieldName: string,
}

export default controlAccess({requiredPermissions: "contacts:create"})(CsvProcessingSettings)
