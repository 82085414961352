import makeStyles from "@mui/styles/makeStyles"
import {func, shape, string} from "prop-types"

import CtaButtonSettings from "components/content-block-editor/cta-button-settings"

import useForm from "lib/hooks/use-form"

import FormActions from "../form-actions"

const OptInSettings = ({initialValues, onSubmit}) => {
  const {change, field, handleSubmit, resetForm} = useForm({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  })

  const classes = useStyles()

  return (
    <form className={classes.root} aria-label="Full Opt-In Settings" onSubmit={handleSubmit}>
      <CtaButtonSettings change={change} field={field} />
      <FormActions resetForm={resetForm} />
    </form>
  )
}

OptInSettings.propTypes = {
  initialValues: shape({
    iconSrc: string,
  }).isRequired,
  onSubmit: func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
  },
}))

export default OptInSettings
