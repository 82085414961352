// This file was generated automatically from character-20.svg with the following command:
// ./svg-converter.js src/components/superhero/character-20.svg
import SuperheroSvg from "./superhero-svg"

const Character20 = props => (
  <SuperheroSvg viewBox="230 65 110 220" {...props}>
    <g id="Layer_9">
      <g>
        <g>
          <path
            style={{fill: "#DABCAB"}}
            d="M279.35,108.68L279.35,108.68L279.35,108.68c0.45,0.77,0.9,1.54,1.35,2.31
  				c-0.86-1.46-1.72-2.93-2.58-4.39c-1.39,1.72-2.78,3.43-4.17,5.15c-2.22,2.75-4.45,5.5-6.67,8.25c-0.51,0.63-1.02,1.26-1.53,1.89
  				c-0.44,0.54-0.67,1.15-0.5,1.87c0.16,0.66,0.72,1.45,1.45,1.88c1.48,0.88,3.74,1.03,4.68-0.13c1.39-1.72,2.78-3.43,4.17-5.15
  				c2.22-2.75,4.45-5.5,6.67-8.25c0.51-0.63,1.02-1.26,1.53-1.89c0.59-0.73,0.77-1.61,0.23-2.55c-0.78-1.36-2.81-1.84-2.81-1.84
  				s0,0,0,0c-0.01,0-2.79,0.02-3.04,0.77C277.88,107.31,279.34,108.67,279.35,108.68z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#DABCAB"}}
            d="M266.2,124.05L266.2,124.05L266.2,124.05c0.06,0.89,0.12,1.78,0.18,2.67
  				c-0.12-1.69-0.23-3.39-0.35-5.08c-2.01,0.92-4.02,1.84-6.03,2.76c-3.22,1.47-6.43,2.94-9.65,4.41c-0.74,0.34-1.47,0.67-2.21,1.01
  				c-0.63,0.29-1.12,0.73-1.28,1.45c-0.15,0.66,0,1.62,0.46,2.33c0.93,1.45,2.9,2.59,4.25,1.97c2.01-0.92,4.02-1.84,6.03-2.76
  				c3.22-1.47,6.43-2.94,9.65-4.41c0.74-0.34,1.47-0.67,2.21-1.01c0.86-0.39,1.41-1.1,1.34-2.18c-0.09-1.57-1.69-2.9-1.69-2.9
  				s0,0,0,0c-0.01,0-2.51-1.22-3.07-0.67C265.5,122.17,266.2,124.04,266.2,124.05z"
          />
        </g>
      </g>
    </g>
    <g id="Layer_25">
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M272.39,283.35c-4.6,0-10.99-0.45-17.97-2.27l-0.09-0.03c-0.08-0.04-2.03-1.11-1.92-2.63
  				c0.08-1.15,1.24-2.11,3.53-2.95l0.07-0.02c0.1-0.02,10.09-1.85,11.45-5.47l0.74,0.28c-1.48,3.96-11.24,5.82-12.01,5.96
  				c-2.39,0.88-2.94,1.71-2.98,2.25c-0.07,0.91,1.23,1.72,1.47,1.86c12.72,3.31,23.47,2,24.68,1.84c1.31-1.29-0.41-6.89-1.83-10.18
  				l0.73-0.31c0.01,0.02,0.98,2.29,1.67,4.74c0.97,3.48,0.93,5.58-0.15,6.43l-0.08,0.07l-0.1,0.02
  				C279.52,282.94,276.77,283.35,272.39,283.35z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M258.47,281.86l-0.49-0.62c0.9-0.72,1.38-1.53,1.42-2.41c0.08-1.67-1.39-3.11-1.4-3.12l0.55-0.57
  				c0.07,0.07,1.74,1.7,1.64,3.73C260.14,279.98,259.56,280.99,258.47,281.86z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M273.24,280.5c-3.12,0-7.48-0.22-13.52-0.89l0.09-0.79c16.51,1.83,20.33,0.23,20.37,0.21
  				l0.33,0.72C280.41,279.8,278.83,280.5,273.24,280.5z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M259.59,278.5l-0.14-0.78c11.96-2.13,12.4-6.57,12.4-6.62l0.79,0.04
  				C272.64,271.34,272.29,276.24,259.59,278.5z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M262.16,276.85c-0.66,0-1.48-0.5-1.76-0.68l0.43-0.66c0.53,0.35,1.19,0.61,1.38,0.55
  				c0-0.01,0-0.02,0-0.04c0.16-1.39,0.57-1.34,2.54-1.12c0.77,0.09,1.33,0.15,1.48,0.03c0.15-0.12,0.21-0.68,0.3-1.45
  				c0.07-0.65,0.32-1.03,0.72-1.15c0.79-0.22,1.71,0.84,1.98,1.18l-0.62,0.49c-0.44-0.55-0.99-0.96-1.14-0.91
  				c0,0-0.1,0.07-0.15,0.48c-0.25,2.21-0.45,2.36-2.66,2.11c-0.78-0.09-1.45-0.17-1.58-0.09c-0.03,0.05-0.06,0.29-0.08,0.51
  				c-0.04,0.31-0.19,0.54-0.44,0.66C262.44,276.83,262.3,276.85,262.16,276.85z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M310.44,283.22l-0.05,0c-0.09-0.01-2.3-0.22-2.8-1.66c-0.38-1.09,0.3-2.43,2.08-4.11l0.05-0.04
  				c0.02-0.01,2.19-1.45,4.3-3.37c2.73-2.48,4.13-4.62,4.05-6.18l0.79-0.04c0.2,4.22-8.02,9.8-8.67,10.23
  				c-1.85,1.75-2.03,2.73-1.85,3.25c0.27,0.77,1.55,1.07,2.08,1.13c13-2,22.36-7.46,23.4-8.08c0.82-1.89-2.72-6.1-4.19-7.59
  				l0.57-0.56c0.59,0.6,5.71,5.94,4.28,8.61l-0.05,0.09l-0.09,0.06c-0.1,0.06-9.91,6.13-23.84,8.26L310.44,283.22z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M314.52,282.33l-0.7-0.37c0.54-1.02,0.66-1.95,0.35-2.78c-0.59-1.56-2.5-2.3-2.52-2.31l0.28-0.74
  				c0.09,0.03,2.27,0.87,2.98,2.77C315.31,279.95,315.18,281.1,314.52,282.33z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M314.77,279.77l-0.23-0.76c15.89-4.85,18.76-7.84,18.79-7.87l0.59,0.53
  				C333.81,271.8,331.02,274.81,314.77,279.77z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M314.22,278.8l-0.44-0.66c10.14-6.69,8.78-10.95,8.77-10.99l0.74-0.28
  				C323.37,267.06,324.98,271.7,314.22,278.8z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M314.97,276.43c-0.39,0-0.75-0.05-0.93-0.08l0.13-0.78c0.63,0.11,1.33,0.09,1.48-0.04
  				c0,0,0-0.02-0.01-0.03c-0.15-0.51-0.29-0.95,0.09-1.31c0.27-0.26,0.75-0.4,1.8-0.72c0.74-0.22,1.28-0.39,1.37-0.55
  				c0.09-0.17-0.07-0.7-0.3-1.45c-0.19-0.62-0.12-1.07,0.2-1.34c0.63-0.52,1.91,0.1,2.28,0.3l-0.37,0.7
  				c-0.62-0.33-1.29-0.49-1.4-0.38c0,0-0.07,0.11,0.05,0.5c0.32,1.06,0.48,1.6,0.24,2.05c-0.25,0.46-0.78,0.62-1.84,0.94
  				c-0.75,0.23-1.4,0.42-1.49,0.54c-0.01,0.05,0.06,0.29,0.13,0.5c0.09,0.3,0.04,0.57-0.15,0.78
  				C315.99,276.35,315.46,276.43,314.97,276.43z"
          />
        </g>
      </g>
      <path
        style={{fill: "#CC5F64"}}
        d="M264.05,224.47l7.86-61.6l20.86-2.41l-4.81,18.08l-4.33,51.99c0,0,1.11,16.5,1.79,41.81l-24.97-1.62
  		C260.45,270.71,262.17,243.63,264.05,224.47z"
      />
      <path
        style={{fill: "#EB6669"}}
        d="M310.16,224.5l-3.93-57.04l-1.08-14.09l-4.82-1.07l-25.67-6.16l-2.56,15.62
  		c-0.18,0.78-0.32,1.58-0.41,2.39l-0.22,1.29l0.13,0.02c-0.01,0.17-0.02,0.33-0.02,0.5c-0.15,8.97,5.74,16.55,13.68,18.35l0.12,0.35
  		l4.55,37.17c0,0,1.14,8.75,4.01,14.12l16.97,33.78l24.88-3.81l-22.1-33.76C313.68,232.16,310.8,228.33,310.16,224.5z"
      />
      <g>
        <g>
          <g>
            <path
              style={{fill: "#4A9AD4"}}
              d="M278.96,94.55c-1.35,0.72-3.27-0.31-3.13,1.21c0.14,1.52,4.76,1.1,4.9,2.62
  					c0.14,1.52-4.48,1.94-4.34,3.47c0.14,1.52,4.76,1.1,4.9,2.63s-4.42,4.68-3.05,5.35c2.59,1.26,3.85-4.46,6.73-4.52
  					c2.88-0.06,2.97,4.58,5.84,4.52c2.88-0.06,2.79-4.69,5.67-4.75c2.88-0.06,2.97,4.58,5.85,4.52c2.88-0.06,2.08-3.11,4.62-4.47
  					c1.28-0.69,3.33,0.46,3.25-0.99c-0.08-1.45-4.71-1.18-4.8-2.63c-0.08-1.45,4.55-1.72,4.46-3.17c-0.08-1.45-4.71-1.18-4.8-2.64
  					c-0.08-1.45,4.47-4.49,3.16-5.12c-2.64-1.28-3.96,4.39-6.9,4.37c-2.93-0.02-2.91-4.65-5.84-4.67c-2.93-0.02-2.96,4.62-5.9,4.61
  					c-2.94-0.02-3.1-4.88-6.03-4.67C280.98,90.41,281.55,93.16,278.96,94.55z"
            />
          </g>
          <path
            style={{fill: "#DABCAB"}}
            d="M290.41,67.8c0.55-0.03,1.1-0.04,1.64-0.01c1.06,12.91,13.08,18.99,15.35,20.03
  				c-1.52,7.08-7.56,12.57-15.08,13.01c-9.05,0.53-16.81-6.43-17.33-15.56S281.36,68.33,290.41,67.8z"
          />
          <path
            style={{fill: "#DABCAB"}}
            d="M274.98,85.23c0.42,7.34,5.89,13.26,12.58,15.03l0.34,5.89l6.04-0.35l-0.3-5.13
  				c4.52-0.63,8.41-3.1,10.97-6.6c0.61,0.39,1.34,0.6,2.11,0.55c1.98-0.12,3.49-1.83,3.37-3.82c-0.09-1.58-1.18-2.87-2.62-3.27
  				c0-0.02,0.01-0.04,0.01-0.05c-0.12-0.05-0.23-0.11-0.35-0.17l-32.11-4.66C274.95,83.49,274.93,84.35,274.98,85.23z"
          />
          <path
            style={{fill: "#DABCAB"}}
            d="M299.03,79.59L277,76.39c-1.06,1.92-1.75,4.08-1.97,6.36l31.78,4.61
  				C303.41,85.78,300.64,83.03,299.03,79.59z"
          />
          <ellipse style={{fill: "#0D1E36"}} cx="283.52" cy="81.03" rx="1.31" ry="1.32" />
          <ellipse style={{fill: "#0D1E36"}} cx="294.82" cy="82.26" rx="1.31" ry="1.32" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M286.1,76.1c-0.22-0.27-0.55-0.49-0.87-0.63c-0.82-0.36-1.72-0.33-2.54,0.01
  						c-0.15,0.06-0.28,0.13-0.37,0.28c-0.08,0.13-0.11,0.32-0.06,0.47c0.09,0.28,0.44,0.56,0.75,0.43c0.21-0.08,0.42-0.15,0.64-0.2
  						c0.02,0,0.13-0.01,0.14-0.02c-0.1,0.01-0.11,0.01-0.04,0.01c0.03,0,0.05,0,0.08-0.01c0.09-0.01,0.17-0.01,0.26-0.01
  						c0.03,0,0.07,0,0.1,0.01c0.05,0,0.2,0.04,0,0c0.07,0.02,0.14,0.02,0.22,0.04c0.07,0.02,0.13,0.04,0.2,0.06
  						c0.03,0.01,0.05,0.02,0.08,0.03c0.1,0.03-0.16-0.07-0.02-0.01c0.1,0.05,0.2,0.1,0.29,0.16c0.04,0.03,0.08,0.06,0.12,0.08
  						c-0.19-0.13-0.06-0.05-0.01-0.01c0.06,0.05,0.12,0.11,0.18,0.18c0.2,0.25,0.66,0.23,0.87,0
  						C286.34,76.7,286.31,76.37,286.1,76.1L286.1,76.1z"
              />
            </g>
          </g>
          <g>
            <path style={{fill: "#DABCAB"}} d="M287.09,84.6c0,0,1.78-1.61,3.03,0.58" />
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M287.31,84.81c0.01-0.01,0.03-0.02,0.04-0.04c0.06-0.05-0.02,0.01,0,0
  						c0.03-0.02,0.05-0.04,0.08-0.06c0.14-0.1,0.3-0.19,0.46-0.26c-0.05,0.02-0.01,0,0.02-0.01c0.02-0.01,0.05-0.02,0.07-0.03
  						c0.05-0.02,0.1-0.03,0.16-0.05c0.06-0.01,0.11-0.03,0.17-0.04c0.06-0.01-0.04,0,0.03,0c0.03,0,0.06-0.01,0.1-0.01
  						c0.06,0,0.12,0,0.18,0c0.03,0,0.06,0,0.09,0.01c-0.06-0.01,0.03,0.01,0.05,0.01c0.14,0.03,0.23,0.07,0.34,0.12
  						c0.34,0.19,0.59,0.51,0.79,0.85c0.2,0.34,0.73,0.03,0.53-0.31c-0.39-0.67-0.98-1.24-1.79-1.3c-0.5-0.03-1.01,0.16-1.42,0.43
  						c-0.11,0.07-0.21,0.15-0.31,0.23C286.58,84.65,287.02,85.08,287.31,84.81L287.31,84.81z"
              />
            </g>
          </g>
          <path
            style={{fill: "#4A9AD4"}}
            d="M292.05,67.79c8.33,0.34,15.2,7,15.69,15.57c0.09,1.53-0.04,3.03-0.34,4.46
  				C305.13,86.78,293.11,80.69,292.05,67.79z"
          />
          <path
            style={{fill: "#FFFFFF"}}
            d="M289.65,92.4c0.06,1.04,0.94,1.83,1.97,1.77c1.03-0.06,1.82-0.95,1.76-1.99l-0.11-1.97
  				c-0.02,0.02-0.04,0.04-0.06,0.05c-1.43,1.16-3.62,0.92-3.62,0.92L289.65,92.4z"
          />
          <path
            style={{fill: "#4A9AD4"}}
            d="M292.08,67.89c0,0-4.23,5.38-13.38,5.91C278.7,73.8,283.55,67.27,292.08,67.89z"
          />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M294.39,78.21c0.08-0.03,0.17-0.06,0.26-0.08c0.05-0.01,0.09-0.02,0.14-0.03
  						c0.02,0,0.04-0.01,0.07-0.01c0.14-0.02-0.15,0.01-0.05,0.01c0.12-0.01,0.24-0.02,0.36-0.02c0.06,0,0.13,0,0.19,0.01
  						c0.06,0,0.19,0.04-0.01,0c0.03,0.01,0.06,0.01,0.1,0.02c0.16,0.03,0.31,0.1,0.46,0.14c-0.16-0.04-0.06-0.02-0.01,0
  						c0.05,0.02,0.09,0.05,0.14,0.07c0.08,0.05,0.16,0.1,0.24,0.15c0.12,0.08-0.1-0.09,0.01,0.01c0.04,0.04,0.09,0.08,0.13,0.12
  						c0.08,0.08,0.15,0.16,0.22,0.24c0.21,0.24,0.65,0.23,0.87,0c0.24-0.26,0.22-0.6,0-0.87c-0.58-0.68-1.4-1.07-2.28-1.11
  						c-0.38-0.01-0.81,0.03-1.17,0.17c-0.3,0.12-0.54,0.41-0.43,0.75C293.73,78.07,294.07,78.34,294.39,78.21L294.39,78.21z"
              />
            </g>
          </g>
          <path
            style={{fill: "#123252"}}
            d="M306.63,116.52c0.47-6.14-4.08-11.5-10.16-11.98l-12.16-0.45c-6.09-0.48-9.06,3.97-9.53,10.11
  				l-1.47,33.02l32.39,9.99L306.63,116.52z"
          />
          <polygon
            style={{fill: "#D9D8D6"}}
            points="272.77,145.16 242.09,143.77 258.59,137.39 272.77,137.31 			"
          />
          <polygon
            style={{fill: "#D9D8D6"}}
            points="252.16,116.08 258.55,137.39 242.09,143.77 232.93,118.38 			"
          />
          <path
            style={{fill: "#DABCAB"}}
            d="M270.08,143.46c0,0,0.53,0.51,0.31,0.76c-0.23,0.25-1.26,0.43-2.17-0.44
  				c-0.59-0.58-3.46-2.46-5.92-0.9c0,0-2.42,2.44-0.35,5.22c1.55,2.09,3.22,3.41,6.91,3.75c3.45,0.31,5.34-0.65,6.47-0.81
  				c3.71,0.43,7.43,0.87,11.14,1.3c0.54,0.06,1.08,0.13,1.62,0.19l0.29-6.95c-3.79-0.44-7.58-0.89-11.38-1.33
  				c-0.39-0.05-0.78-0.09-1.17-0.14c-0.62-0.96-2.16-3.13-4-3.98c0,0-0.82-0.53-2.05-0.41S268.67,142.23,270.08,143.46z"
          />
          <polygon
            style={{fill: "#123252"}}
            points="250.79,119.31 255.54,135.42 243.46,140.08 237.05,120.69 			"
          />
        </g>
      </g>
      <g>
        <path
          style={{fill: "#DABCAB"}}
          d="M297.91,115.19c0.34,1.23,0.68,2.45,1.02,3.68c0.82,2.95,1.64,5.9,2.46,8.84
  			c0.99,3.57,1.98,7.14,2.97,10.71c0.86,3.1,1.72,6.2,2.58,9.3c0.42,1.5,0.79,3.02,1.25,4.5c0.01,0.02,0.01,0.04,0.02,0.06
  			c1.2-1.58,2.4-3.15,3.61-4.73c-2.54-0.23-5.08-0.46-7.62-0.69c-4.01-0.37-8.03-0.73-12.04-1.1c-0.93-0.09-1.87-0.17-2.8-0.26
  			c-0.82-0.07-1.58,0.03-2.3,0.6c-0.63,0.5-1.22,1.41-1.36,2.29c-0.28,1.76,0.58,3.92,2.27,4.07c2.54,0.23,5.08,0.46,7.62,0.69
  			c4.01,0.37,8.03,0.73,12.04,1.1c0.93,0.09,1.87,0.17,2.8,0.26c1.09,0.1,2.11-0.19,2.9-1.21c0.75-0.96,1.03-2.34,0.7-3.51
  			c-0.34-1.23-0.68-2.45-1.02-3.68c-0.82-2.95-1.64-5.9-2.46-8.84c-0.99-3.57-1.98-7.14-2.97-10.71c-0.86-3.1-1.72-6.2-2.58-9.3
  			c-0.42-1.5-0.81-3.01-1.25-4.5c-0.01-0.02-0.01-0.04-0.02-0.06c-0.48-1.72-2.25-2.81-3.81-2.06
  			C298.38,111.39,297.4,113.34,297.91,115.19L297.91,115.19z"
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Character20
