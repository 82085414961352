import {string} from "prop-types"

import SortableDragHandle from "components/content-block-editor/drag-handle"

import {contentBlockPropType} from "../../content-block-editor-prop-types"

const DefaultPluginReadonly = ({contentBlock: {type, slug}, customCss, ...props}) => {
  if (process.env.NODE_ENV !== "production")
    return (
      <div {...props}>
        <SortableDragHandle />
        <style>{customCss}</style>
        {type} - {slug}
      </div>
    )

  return null
}

DefaultPluginReadonly.propTypes = {
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
}

export default DefaultPluginReadonly
