import {InputBase, MenuItem, Select, TableCell, Toolbar, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {chainPropTypes} from "@mui/utils"
import cx from "classnames"
import PropTypes from "prop-types"
import {forwardRef} from "react"

import TablePaginationActions from "./pagination-actions"

// originally sourced from https://github.com/mui-org/material-ui/blob/daf2e9dc2a3b1331e9ba598068ee0b2aa7aeb515/packages/material-ui/src/TablePagination/TablePagination.js

export const styles = theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    overflow: "auto",
    "&:last-child": {
      padding: 0,
    },
  },
  toolbar: {
    minHeight: 52,
    paddingRight: 2,
  },
  spacer: {
    flex: "1 1 100%",
  },
  caption: {
    flexShrink: 0,
  },
  selectRoot: {
    marginRight: 32,
    marginLeft: 8,
  },
  select: {
    paddingLeft: 8,
    paddingRight: 24,
    textAlign: "right",
    textAlignLast: "right",
  },
  selectIcon: {},
  input: {
    color: "inherit",
    fontSize: "inherit",
    flexShrink: 0,
  },
  menuItem: {},
  actions: {
    flexShrink: 0,
    marginLeft: 20,
  },
})

function defaultLabelDisplayedRows({from, to, count}) {
  return `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
}

function defaultGetAriaLabel(type) {
  return `Go to ${type} page`
}

const TablePagination = forwardRef((props, ref) => {
  const {
    ActionsComponent = TablePaginationActions,
    backIconButtonProps,
    classes,
    className,
    colSpan: colSpanProp,
    component: Component = TableCell,
    count,
    getItemAriaLabel = defaultGetAriaLabel,
    labelDisplayedRows = defaultLabelDisplayedRows,
    labelRowsPerPage = "Rows per page:",
    nextIconButtonProps,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    rowsPerPageOptions = [5, 10, 20, 50, 100],
    SelectProps = {},
    ...other
  } = props
  let colSpan

  if (Component === TableCell || Component === "td") colSpan = colSpanProp || 1000 // col-span over everything

  const MenuItemComponent = SelectProps.native ? "option" : MenuItem

  return (
    <Component className={cx(classes.root, className)} colSpan={colSpan} ref={ref} {...other}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.spacer} />
        {rowsPerPageOptions.length > 1 && (
          <Typography className={classes.caption} color="inherit" variant="body2">
            {labelRowsPerPage}
          </Typography>
        )}
        {rowsPerPageOptions.length > 1 && (
          <Select
            classes={{
              select: classes.select,
              icon: classes.selectIcon,
            }}
            input={<InputBase className={cx(classes.input, classes.selectRoot)} />}
            onChange={onRowsPerPageChange}
            value={rowsPerPage}
            {...SelectProps}
          >
            {rowsPerPageOptions.map(rowsPerPageOption => (
              <MenuItemComponent
                className={classes.menuItem}
                key={rowsPerPageOption.value ? rowsPerPageOption.value : rowsPerPageOption}
                value={rowsPerPageOption.value ? rowsPerPageOption.value : rowsPerPageOption}
              >
                {rowsPerPageOption.label ? rowsPerPageOption.label : rowsPerPageOption}
              </MenuItemComponent>
            ))}
          </Select>
        )}
        <Typography className={classes.caption} color="inherit" variant="body2">
          {labelDisplayedRows({
            from: count === 0 ? 0 : page * rowsPerPage + 1,
            to: count !== -1 ? Math.min(count, (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage,
            count: count === -1 ? -1 : count,
            page,
          })}
        </Typography>
        <ActionsComponent
          backIconButtonProps={backIconButtonProps}
          className={classes.actions}
          count={count}
          getItemAriaLabel={getItemAriaLabel}
          // this line is the only difference.
          // we want to re-render the actions component when the page changed
          // because of window translation when `rowsPerPage` changes in `<Table/>`
          key={page}
          nextIconButtonProps={nextIconButtonProps}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Toolbar>
    </Component>
  )
})

TablePagination.propTypes = {
  ActionsComponent: PropTypes.elementType,
  SelectProps: PropTypes.object,
  backIconButtonProps: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
  component: PropTypes.elementType,
  count: PropTypes.number.isRequired,

  getItemAriaLabel: PropTypes.func,

  labelDisplayedRows: PropTypes.func,

  labelRowsPerPage: PropTypes.node,

  nextIconButtonProps: PropTypes.object,

  onPageChange: PropTypes.func.isRequired,

  onRowsPerPageChange: PropTypes.func,
  page: chainPropTypes(PropTypes.number.isRequired, props => {
    const {count, page, rowsPerPage} = props

    if (count === -1) return null

    const newLastPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1)

    if (page < 0 || page > newLastPage)
      return new Error(
        "Material-UI: The page prop of a TablePagination is out of range " +
          `(0 to ${newLastPage}, but page is ${page}).`
      )

    return null
  }),
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array,
}

export default withStyles(styles, {name: "MuiTablePagination"})(TablePagination)
