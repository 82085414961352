// This file was generated automatically from character-09.svg with the following command:
// ./svg-converter.js src/components/superhero/character-09.svg
import SuperheroSvg from "./superhero-svg"

const Character09 = props => (
  <SuperheroSvg viewBox="100 75 120 215" {...props}>
    <g id="Layer_6">
      <g>
        <defs>
          <rect
            id="SVGID_00000114772510280768368730000011828268075274687381_"
            x="405.17"
            y="28.57"
            width="240.28"
            height="193.45"
          />
        </defs>
        <clipPath id="04eea55a-SVGID_00000004525305402613345910000001921942308749665701_">
          <use
            xlinkHref="#SVGID_00000114772510280768368730000011828268075274687381_"
            style={{overflow: "visible"}}
          />
        </clipPath>
      </g>
    </g>
    <g id="Layer_7">
      <g>
        <g>
          <path
            style={{fill: "#C1A78F"}}
            d="M153.33,144.07c-0.26,1.32,2.55-3.09,0.92,0.06c-2.2,4.27-5.14,9.04-6.15,10.77
  				c-0.59,1.01-1.43,2.3-3.12,4.88c-2.18,3.34-2.2,3.24-2.84,4.36c-1.65,2.87-2.22,4.69-2.56,4.6c-0.18-0.05-0.29-0.64,0.06-3.17
  				c-0.79-0.17-1.58-0.33-2.38-0.5c2.24-1.19,3.82-1.18,4.88-0.93c0.35,0.08,0.24,0.1,2.03,0.77c0.47,0.18,0.93,0.34,3.69,1.31
  				c0.96,0.34,1.92,0.7,2.89,1.02c2.28,0.75,2.59,0.73,3.04,1.19c0.85,0.86,1.24,2.33,0.69,3.43c-0.08,0.17-0.5,0.95-1.18,1.04
  				c-0.28,0.04-0.34-0.08-0.8-0.16c-0.77-0.14-1.08,0.09-1.75,0.05c-0.66-0.03-1.09-0.29-1.48-0.48c-1.04-0.5-1.58-0.4-4.66-0.95
  				c-2.11-0.38-3.16-0.57-4.28-0.95c-1.88-0.64-2.77-1.28-3.38-1.85c-0.57-0.54-1.27-1.21-1.39-2.21c-0.07-0.62,0.09-1.12,0.56-2.14
  				c0.84-1.83,1.66-3.16,1.77-3.34c0.8-1.31,0.29-0.61,2.35-4.26c1.11-1.96,2.01-3.5,2.51-4.35c1.18-2,2.66-4.45,2.69-4.43
  				c0.04,0.02-1.56,2.81-1.56,2.8c0,0,0.31-0.55,0.93-1.66c0.47-0.85,1.21-2.17,2.39-4.29c0.38-0.68,1.67-3,1.67-3
  				c0,0,2.63-2.98,3.89-2.49C153.77,139.59,153.68,142.31,153.33,144.07z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#C1A78F"}}
            d="M180.15,135.32c0.66,1.18,1.19,1.61,3.72,4.12c3.42,3.4,3.12,3.27,4.62,4.62
  				c0.87,0.79,2.08,1.76,4.48,3.7c3.11,2.52,3.1,2.42,4.06,3.29c2.46,2.23,3.57,3.8,3.87,3.6c0.16-0.1,0.08-0.7-1.05-3.02
  				c0.7-0.4,1.4-0.81,2.11-1.21c-2.5-0.44-4,0.05-4.93,0.62c-0.31,0.19-0.2,0.17-1.69,1.37c-0.39,0.31-0.77,0.61-3.1,2.4
  				c-0.81,0.62-1.61,1.26-2.43,1.87c-1.93,1.42-2.24,1.5-2.53,2.07c-0.51,1.03-0.45,2.57,0.41,3.5c1.26,1.36,3.66,0.76,4.9,0
  				c0.28-0.17,0.22-0.18,1.2-1.02c1.23-1.05,2.18-1.78,3.14-2.5c1.13-0.86,2.05-1.51,3.42-2.47c1.89-1.33,2.46-1.68,3-2.57
  				c0.29-0.48,0.85-1.44,0.63-2.54c-0.12-0.61-0.44-1.04-1.2-1.87c-1.37-1.49-2.56-2.51-2.72-2.64c-1.17-1-0.47-0.49-3.56-3.35
  				c-1.66-1.53-3-2.73-3.74-3.39c-1.74-1.55-3.91-3.43-3.93-3.4c-0.03,0.03,2.35,2.2,2.36,2.2c0,0-0.47-0.43-1.4-1.3
  				c-0.71-0.66-1.82-1.7-3.61-3.37c-0.57-0.53-2.52-2.35-2.52-2.35c0,0-3.42-2.03-4.48-1.18
  				C178.35,131.17,179.28,133.75,180.15,135.32z"
          />
        </g>
      </g>
      <g>
        <path
          style={{fill: "#498EC1"}}
          d="M190.47,225.05l-6.65-62.6L166.15,160l4.08,18.37l3.66,52.84c0,0-0.94,16.77-1.52,42.48l21.14-1.65
  			C193.52,272.04,192.06,244.51,190.47,225.05z"
        />
      </g>
      <g>
        <path
          style={{fill: "#5397D1"}}
          d="M138.41,225.81l12.16-47.43l3.11-11.7l4.88-0.11l26.06-0.97l0.24,13.59
  			c0.07,0.69,0.09,1.38,0.06,2.08l0.03,1.12l-0.13,0c-0.02,0.14-0.03,0.28-0.05,0.42c-1.16,7.59-8.04,13-16.08,13.22l-0.16,0.28
  			l-9.87,30.58c0,0-2.39,7.19-5.99,11.24L131.1,263.8l-23.84-7.3l26.58-24.82C133.84,231.68,137.22,228.93,138.41,225.81z"
        />
      </g>
      <g>
        <defs>
          <rect
            id="SVGID_00000032615299841524050790000013258038773996882048_"
            x="91.78"
            y="71.63"
            width="267.01"
            height="216.74"
          />
        </defs>
        <clipPath id="04eea55a-SVGID_00000065044676070683901820000011478740891633328521_">
          <use
            xlinkHref="#SVGID_00000032615299841524050790000013258038773996882048_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <g style={{clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)"}}>
          <path
            style={{fill: "#123252"}}
            d="M177.74,103.07c-1.86,3.04-1.96,6.82-3.12,10.19c-1.23,3.57-4.32,5.77-7.9,6.62
  				c-2.69,0.65-7.96,0.81-10.33-0.83c-0.72-0.5-1.22-1.24-1.7-1.97c-2.11-3.25-3.99-6.64-5.61-10.16c0,0-1.73-3.58-1.94-5.83
  				c-0.56-5.94,2.09-10.31,6.29-13.71c5.97-4.84,18.42-7.02,24.97-11.27c0,0,2.17,1.61,3.81,7.01c0,0,1.64,4.9-1.71,8.28"
          />
          <path
            style={{fill: "#C1A68E"}}
            d="M150.54,104.56c-0.36,9.76,7.25,17.96,17,18.32c9.76,0.36,17.96-7.25,18.32-17"
          />
          <g>
            <path
              style={{fill: "#C1A68E"}}
              d="M150.69,110.09c0-0.02-0.01-0.04-0.01-0.06c-0.32-1.44-0.48-2.94-0.43-4.48
  					c0.07-2.26,0.65-3.44,3.87-5.42c2.88-2.1,9.08-3.46,8.87-3.38c3.74-0.84,7.57-1.27,11.27-2.29c1.74-0.48,3.58-1.14,5.12-2.18
  					c0.86-0.58,1.64-1.28,2.24-2.13c3.04,5.62,4.2,13.77,4.2,13.77c0.06,0.46,0.1,0.93,0.12,1.4c-0.43,2.72-0.9,5.31-4.24,9.02
  					c-4.01,4.46-5.8,0.94-5.8,0.94c-2.15-4.31-6.75-4.97-9.03-5.34c-3.06-0.5-5.75-0.6-8.53,1.99c-3.19,2.96-7.62-1.82-7.62-1.82
  					c0.14,0.89,1.5,3.92,3.22,7c-0.64,0.41-1.41,0.65-2.23,0.62c-2.15-0.07-3.84-1.87-3.77-4.02
  					C147.99,111.98,149.14,110.56,150.69,110.09z"
            />
            <path
              style={{fill: "#C1A68E"}}
              d="M172.49,129.02l-6.98-0.22l0.6-5.43c2.22-0.16,4.91-0.27,7.19-0.5L172.49,129.02z"
            />
            <path
              style={{fill: "#C1A68E"}}
              d="M166.1,123.86c-4.93-0.55-9.23-3.1-12.11-6.8c-0.01,0.01-0.02,0.01-0.03,0.02
  					c-0.01,0.01-0.02,0.01-0.03,0.02c-1.72-3.07-3.08-6.11-3.22-7c0,0,4.22,4.53,7.62,1.82c2.77-2.2,5.86-2.47,8.53-1.99
  					c2.27,0.41,6.5,1.01,9.03,5.34c0,0,1.44,2.75,5.8-0.94c3.82-3.22,3.81-6.3,4.24-9.02c0.02,0.46,0.03,0.92,0.01,1.38
  					c-0.16,4.96-2.33,9.39-5.7,12.52c-2.01,1.87-4.45,3.27-7.15,4.06C173.1,123.27,169.79,124.27,166.1,123.86z"
            />
          </g>
          <circle style={{fill: "#0D1E36"}} cx="176.85" cy="102.37" r="1.44" />
          <circle style={{fill: "#0D1E36"}} cx="164.47" cy="104.03" r="1.44" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M170.95,106.57c0.26,0.95,0.52,1.91,0.78,2.86c0.04,0.14,0.07,0.27,0.11,0.41
  						c0.18-0.24,0.37-0.48,0.55-0.72c-0.68,0.01-1.37,0.03-2.05,0.04c-0.3,0.01-0.58,0.26-0.57,0.57c0.01,0.3,0.25,0.58,0.57,0.57
  						c0.68-0.01,1.37-0.03,2.05-0.04c0.37-0.01,0.65-0.36,0.55-0.72c-0.26-0.95-0.52-1.91-0.78-2.86c-0.04-0.14-0.07-0.27-0.11-0.41
  						c-0.08-0.29-0.41-0.49-0.7-0.4C171.05,105.97,170.86,106.27,170.95,106.57L170.95,106.57z"
              />
            </g>
          </g>
          <path
            style={{fill: "#FFFFFF"}}
            d="M164.67,112.71c0.13-0.2,0.41-0.23,0.6-0.09c0.45,0.34,1.27,1.06,2.46,1.34
  				c1.62,0.39,3.06-0.21,3.39,0.22c0.18,0.24-0.06,0.53-0.52,0.88c-0.46,0.36-1.85,1.03-3.33,0.69c-1.56-0.35-2.67-2.22-2.65-2.86
  				C164.61,112.83,164.63,112.77,164.67,112.71z"
          />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M164.74,98.85c-0.95-0.23-2.06-0.03-2.86,0.53c-0.45,0.31-0.8,0.69-1.09,1.15
  						c-0.23,0.36-0.09,0.91,0.29,1.11c0.4,0.21,0.87,0.09,1.11-0.29c0.03-0.05,0.07-0.11,0.1-0.16c0.02-0.02,0.04-0.05,0.05-0.07
  						c-0.08,0.1-0.1,0.12-0.04,0.06c0.07-0.09,0.15-0.17,0.23-0.25c0.04-0.04,0.08-0.07,0.12-0.11c0.05-0.05,0.21-0.13-0.01,0.01
  						c0.07-0.05,0.14-0.1,0.22-0.14c0.08-0.05,0.16-0.09,0.25-0.13c0.18-0.08-0.16,0.06-0.04,0.02c0.04-0.02,0.08-0.03,0.12-0.04
  						c0.15-0.05,0.31-0.09,0.46-0.11c0.07-0.01,0.21-0.01-0.09,0.01c0.04,0,0.07-0.01,0.11-0.01c0.07-0.01,0.14-0.01,0.2-0.01
  						c0.11,0,0.22,0,0.33,0.01c0.04,0,0.08,0.01,0.12,0.01c0.1,0.01-0.27-0.04-0.15-0.02c0.01,0,0.03,0,0.04,0.01
  						c0.03,0,0.06,0.01,0.08,0.02c0.41,0.1,0.9-0.13,1-0.57C165.41,99.43,165.19,98.95,164.74,98.85L164.74,98.85z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M175.89,98.75c-0.34,0.27-0.03,0.03,0.07-0.02c0.04-0.02,0.08-0.04,0.12-0.06
  						c0.13-0.06-0.1,0.05-0.1,0.04c0,0,0.08-0.03,0.09-0.03c0.12-0.04,0.24-0.08,0.36-0.1c0.03-0.01,0.07-0.01,0.1-0.02
  						c0.14-0.03-0.22,0.02-0.08,0.01c0.07-0.01,0.15-0.01,0.22-0.01c0.09,0,0.17,0.01,0.26,0.01c0.18,0.01-0.14-0.02-0.05-0.01
  						c0.05,0.01,0.1,0.02,0.15,0.03c0.09,0.02,0.18,0.04,0.27,0.07c0.05,0.01,0.09,0.03,0.14,0.05c0.02,0.01,0.05,0.02,0.07,0.03
  						c0.06,0.02,0.03,0.01-0.07-0.03c0.01-0.03,0.6,0.34,0.65,0.38c0.35,0.25,0.92,0.07,1.11-0.29c0.22-0.42,0.08-0.85-0.29-1.11
  						c-0.85-0.6-1.85-0.86-2.87-0.67c-0.47,0.09-0.91,0.3-1.29,0.59c-0.15,0.12-0.24,0.39-0.24,0.57c0,0.2,0.09,0.44,0.24,0.57
  						c0.15,0.14,0.36,0.25,0.57,0.24C175.54,98.98,175.71,98.89,175.89,98.75L175.89,98.75z"
              />
            </g>
          </g>
        </g>

        <line
          style={{
            clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)",
            fill: "#5DBDAF",
          }}
          x1="157.63"
          y1="162.73"
          x2="182.37"
          y2="160.12"
        />
        <g style={{clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)"}}>
          <path
            style={{fill: "#609E92"}}
            d="M187.2,144.22c-2.58-2.48-4.88-4.56-7.46-7.04c-0.37-0.36-0.75-0.72-1.12-1.08
  				c-0.81-0.78-1.4-1.71-1.49-2.87c-0.08-1,0.26-2.24,0.94-2.99c1.42-1.57,4.18-2.16,5.86-0.55l0,0c2.58,2.48,5.17,4.97,7.75,7.45
  				c0.37,0.36,0.75,0.72,1.12,1.08"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)"}}>
          <defs>
            <polygon
              id="SVGID_00000103987634665432278150000006947019981760321667_"
              points="167.94,141.72 173.78,138.68 179.33,142.23
  					179.04,148.81 173.19,151.84 167.64,148.3 				"
            />
          </defs>
          <clipPath id="04eea55a-SVGID_00000122681679194329281620000011171408558811360394_">
            <use
              xlinkHref="#SVGID_00000103987634665432278150000006947019981760321667_"
              style={{overflow: "visible"}}
            />
          </clipPath>
        </g>
        <g style={{clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)"}}>
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M117.67,257.69c0,0-8.22,5.92-7.19,8.67
  				c0,0,9.14,7.58,23.28,11.72c0,0,5.6,0.26,1.2-5.17c0,0-8.19-7.15-7.42-11.38"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M133.23,271.37c0,0-4.87,1.12-3.23,5.5"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M111.43,263.12c0,0,2.31,3.45,18.44,10.84"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M122.23,260.63c0,0-1.76,4.63,9.3,11.89"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M124.62,264.2c0,0,2.76-1.04,1.9,0.86
  				c-0.86,1.9-0.86,1.9,1.04,2.76c1.9,0.86,1.9,0.86,1.47,1.81c-0.43,0.95,1.9,0.86,1.9,0.86"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)"}}>
          <path
            style={{fill: "#5DBDAF"}}
            d="M162.89,127.2l11.23,0.11c8.43,0.05,11.67,4.78,11.88,11.13l-1.45,30.59l-31.58,1.03l-0.22-30.72
  				C152.73,132.11,156.54,127.41,162.89,127.2z"
          />
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)",
            fill: "#C1A68E",
          }}
          d="M159.74,168.02
  			c0,0-0.65,0.29-0.52,0.59c0.13,0.3,1.02,0.8,2.13,0.3c0.73-0.33,3.96-1.12,5.71,1.09c0,0,1.44,3-1.35,4.86
  			c-2.09,1.4-4.05,2.06-7.54,1.17c-3.26-0.83-4.68-2.32-5.67-2.83c-3.54-0.81-7.09-1.61-10.63-2.42c-0.52-0.12-1.03-0.23-1.55-0.35
  			l-0.42-6.09c3.62,0.82,9.19,1.92,12.81,2.74c0.37,0.08,0.7,0.19,1.53-0.34c0.87-0.67,2.99-2.15,4.94-2.32c0,0,0.92-0.21,2.01,0.29
  			C162.3,165.21,161.43,167.36,159.74,168.02z"
        />
        <g style={{clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)"}}>
          <g>
            <path
              style={{fill: "#5DBDAF"}}
              d="M148.29,141.78c1.79-3.11,3.24-5.84,5.03-8.94c0.26-0.45,0.52-0.9,0.77-1.35
  					c0.56-0.98,1.32-1.77,2.42-2.14c0.95-0.32,2.23-0.29,3.13,0.19c1.86,1,3.11,3.53,1.95,5.55l0,0c-1.79,3.11-3.57,6.22-5.36,9.32
  					c-0.26,0.45-0.52,0.9-0.77,1.35"
            />
          </g>
        </g>
        <polygon
          style={{
            clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)",
            fill: "#5DBDAF",
          }}
          points="172.9,125.94
  			165.52,125.94 165.52,128.58 172.55,128.58 		"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)",
            fill: "#C1A68E",
          }}
          d="M181.4,162.71
  			c0,0,0.71-0.03,0.73,0.3c0.02,0.32-0.56,1.17-1.78,1.21c-0.8,0.02-4.04,0.75-4.64,3.51c0,0,0.04,3.32,3.36,3.76
  			c2.49,0.33,4.54,0.05,7.28-2.29c2.56-2.19,3.18-4.15,3.83-5.04c2.82-2.29,5.64-4.58,8.46-6.87c0.41-0.33,0.82-0.67,1.23-1
  			l-2.32-5.65c-2.88,2.34-7.4,5.79-10.28,8.13c-0.3,0.24-0.54,0.48-1.52,0.38c-1.08-0.22-3.63-0.61-5.46,0.1
  			c0,0-0.92,0.21-1.68,1.15C177.86,161.32,179.59,162.86,181.4,162.71z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)",
            fill: "#5DBDAF",
          }}
          d="M185.31,153.1
  			l-0.58,10.23c0,0-4.62,1.46-8.94,1.46c-4.32,0,0-9.73,0-9.73L185.31,153.1z"
        />
        <g style={{clipPath: "url(#SVGID_00000065044676070683901820000011478740891633328521_)"}}>
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M177.2,273.26c0,0-4.57,9.04-2.38,11.01
  				c0,0,11.61,2.52,26.06-0.33c0,0,5.09-2.35-1.31-5.15c0,0-10.57-2.57-11.83-6.68"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M197.31,278.22c0,0-3.81,3.24-0.34,6.37"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M174.17,280.96c0,0,3.64,2,21.36,1.12"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M182.6,273.77c0,0,0.57,4.92,13.73,6.27"
          />
          <path
            style={{fill: "none", stroke: "#5DBDAF", strokeMiterlimit: "10"}}
            d="M186.37,275.83c0,0,1.97-2.19,2.08-0.11
  				c0.11,2.08,0.11,2.08,2.19,1.97c2.08-0.11,2.08-0.11,2.14,0.93c0.05,1.04,2.08-0.11,2.08-0.11"
          />
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default Character09
