import {FormControl, FormControlLabel, Switch, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {addHours, formatDistanceToNow, isBefore} from "date-fns"
import {func, object} from "prop-types"
import {Component, Fragment} from "react"
import {MdCheck as CheckIcon} from "react-icons/md"

import {distanceDateTime} from "lib/date-time-formatters"

import AccessControlled from "../access-control/access-controlled"

const pageCompleted = (page, pagesCompleted) => !!pagesCompleted.find(id => page.id === id)

class RewardProgress extends Component {
  onChange = event => {
    this.props.onUpdateJourney({[event.target.name]: event.target.checked})
  }

  render() {
    const {classes, journey, template} = this.props

    const {templatePages} = template
    const pagesCompleted = journey.analyticsCache.pagesCompleted || []

    let timeLimit

    if (template.journeyDurationHours) {
      const dueInHours = addHours(new Date(journey.insertedAt), template.journeyDurationHours)

      if (isBefore(new Date(), dueInHours))
        timeLimit = `(${formatDistanceToNow(dueInHours)} remaining)`
    }

    const completionPercent = Math.floor(
      Math.min(1, pagesCompleted.length / templatePages.length) * 100
    )

    return (
      <div className={classes.container}>
        <Typography id="reward-progress-header" variant="h4">
          Reward Progress
        </Typography>
        This journey has <b className={classes.important}>{completionPercent}%</b> of its
        requirements for <b className={classes.important}>${template?.reward?.toLocaleString()}</b>{" "}
        {timeLimit}.
        <div className={classes.progress}>
          {templatePages.map((templatePage, i) => {
            const completed = pageCompleted(templatePage.page, pagesCompleted)

            return (
              <Fragment key={templatePage.id}>
                <div className={cx(classes.dot, {[classes.completed]: completed})}>
                  <div>
                    {templatePage.page.contentName}
                    <br />
                    {completed && <CheckIcon size={20} />}
                  </div>
                </div>
                {i < templatePages.length - 1 && (
                  <div className={cx(classes.line, {[classes.completed]: completed})} />
                )}
              </Fragment>
            )
          })}
        </div>
        <div>
          <div className={classes.control}>
            <AccessControlled requiredPermissions="contacts:edit">
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      defaultChecked={!!journey.completionVerifiedAt}
                      name="isCompletionConfirmed"
                      onChange={this.onChange}
                    />
                  }
                  label="Completion confirmed"
                />
              </FormControl>
            </AccessControlled>
            <i className={classes.date}>
              {journey.completionVerifiedAt && distanceDateTime(journey.completionVerifiedAt)}
            </i>
          </div>
          <div className={classes.control}>
            <AccessControlled requiredPermissions="contacts:edit">
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      defaultChecked={!!journey.rewardDispensedAt}
                      name="isRewardDispensed"
                      onChange={this.onChange}
                    />
                  }
                  label="Reward dispensed"
                />
              </FormControl>
            </AccessControlled>
            <i className={classes.date}>
              {journey.rewardDispensedAt && distanceDateTime(journey.rewardDispensedAt)}
            </i>
          </div>
        </div>
      </div>
    )
  }
}

RewardProgress.propTypes = {
  classes: object.isRequired,
  journey: object.isRequired,
  onUpdateJourney: func.isRequired,
  template: object.isRequired,
}

const styles = theme => ({
  container: {
    color: theme.palette.text.primary,
  },
  important: {
    color: theme.palette.primary.main,
  },
  control: {
    display: "inline-block",
    minWidth: 1,
    verticalAlign: "top",
  },
  progress: {
    margin: `${theme.spacing(3)} 0`,
    display: "flex",
    alignItems: "center",
  },
  date: {
    display: "block",
    fontSize: "0.8rem",
  },
  completed: {
    "&$dot, &$line": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:before": {
        background: theme.palette.primary.main,
      },
      "&:after": {
        background: theme.palette.primary.main,
      },
    },
  },
  dot: {
    display: "flex",
    height: theme.spacing(14),
    width: theme.spacing(14),
    padding: theme.spacing(1),
    borderRadius: "50%",
    background: theme.palette.grey[200],
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    textAlign: "center",
    zIndex: 200,
  },
  line: {
    position: "relative",
    flex: 1,
    height: theme.spacing(3),
    background: theme.palette.grey[200],
    "&:before": {
      position: "absolute",
      display: "block",
      content: `""`,
      width: theme.spacing(1),
      left: theme.spacing(-1),
      height: theme.spacing(3),
      background: theme.palette.grey[200],
    },
    "&:after": {
      position: "absolute",
      display: "block",
      content: `""`,
      width: theme.spacing(1),
      right: theme.spacing(-1),
      height: theme.spacing(3),
      background: theme.palette.grey[200],
    },
  },
})

export default withStyles(styles)(RewardProgress)
