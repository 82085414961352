export default function triggerCsvDownload(csvString, fileName) {
  const blob = new window.Blob([csvString], {type: "text/csv"})

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName)
    return
  }

  const anchor = document.createElement("a")
  const objectURL = window.URL.createObjectURL(blob)

  anchor.download = fileName
  anchor.href = objectURL
  anchor.dispatchEvent(new window.MouseEvent("click"))
  setTimeout(window.URL.revokeObjectURL.bind(window.URL, objectURL))
}
