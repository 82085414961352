import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material"
import {bool, func} from "prop-types"
import {useState} from "react"

const isEmpty = str => !str || str.length === 0

const SplitTestingGroupCreateDialog = ({isOpen, onAdd, onClose}) => {
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState(false)
  const [group, setGroup] = useState({})

  const handleChange = ({target: {name, value}}) => {
    setGroup({
      ...group,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (isEmpty(group.name)) {
      setError(true)
      return
    }

    setError(false)
    setDisabled(true)

    onAdd(group, success, failure)
  }

  const success = () => setDisabled(false)
  const failure = () => setDisabled(false)

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={isOpen}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Split Testing Group</DialogTitle>
        <DialogContent>
          <TextField
            error={error}
            fullWidth={true}
            helperText={error ? "Required field." : " "}
            label="Name"
            margin="normal"
            name="name"
            onChange={handleChange}
          />
          <DialogActions>
            <Button className="cancel" color="primary" onClick={onClose} type="button">
              Cancel
            </Button>
            <Button
              className="submit"
              color="primary"
              disabled={disabled}
              type="submit"
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  )
}

SplitTestingGroupCreateDialog.propTypes = {
  isOpen: bool.isRequired,
  onAdd: func.isRequired,
  onClose: func.isRequired,
}

export default SplitTestingGroupCreateDialog
