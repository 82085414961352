import {FormControlLabel, Grid, Switch, TextField} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"
import {connect} from "react-redux"

import useForm from "lib/hooks/use-form"

import {controlAccess} from "../access-control/access-controlled"
import SaveButton from "../save-button/save-button"
import {updateTeamSettings} from "./team-actions"

const IpRestrictionSettings = ({classes, initialValues, onSubmit, disabled}) => {
  const {field, handleSubmit, submitting} = useForm({
    onSubmit,
    enableReinitialize: true,
    initialValues,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={4}>
        <Grid item={true} lg={6} md={12}>
          <TextField
            className={classes.field}
            disabled={disabled}
            multiline={true}
            fullWidth={true}
            helperText="IP addresses you want to be allowed to access Digital Onboarding. Enter one address or CIDR block per line."
            label="IP Addresses"
            {...field("config.do.ip_restrict_list")}
          />
        </Grid>
        <Grid item={true} lg={6} md={12}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                disabled={disabled}
                {...field("config.do.ip_restrict_login", {
                  bool: true,
                  exclude: ["error", "helperText"],
                })}
              />
            }
            label="Restrict user logins to your list of allowed IPs"
          />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                disabled={disabled}
                {...field("config.do.ip_restrict_api", {
                  bool: true,
                  exclude: ["error", "helperText"],
                })}
              />
            }
            label="Restrict API requests to your list of allowed IPs"
          />
        </Grid>
      </Grid>
      <div className={classes.formActions}>
        <SaveButton disabled={disabled} submitting={submitting} />
      </div>
    </form>
  )
}

IpRestrictionSettings.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  initialValues: object,
  onSubmit: func.isRequired,
}

const styles = {
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
}

export default withStyles(styles)(
  connect(({session}) => ({initialValues: session.team}), {onSubmit: updateTeamSettings})(
    controlAccess({requiredPermissions: "team_settings:general"})(IpRestrictionSettings)
  )
)
