import {Paper, Typography} from "@mui/material"
import {arrayOf, number, oneOf, shape, string} from "prop-types"

const MessageOverview = ({message, variant}) => (
  <Paper
    sx={theme => ({
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
    })}
  >
    <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
      Name:
    </Typography>
    <Typography variant="body2" gutterBottom>
      {message.contentName}
    </Typography>

    {message.type !== "sms" && (
      <>
        <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
          Subject:
        </Typography>
        <Typography variant="body2" gutterBottom>
          {message.payload.subject ? message.payload.subject.blocks[0].text : ""}
        </Typography>
      </>
    )}

    <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
      Type:
    </Typography>
    <Typography variant="body2" gutterBottom>
      {message.type}
    </Typography>

    {variant === "drip" && (
      <>
        <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
          Message order:
        </Typography>
        <Typography variant="body2" gutterBottom>
          {message.order}
        </Typography>
      </>
    )}

    {variant === "scheduled" && (
      <>
        <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
          Scheduled at:
        </Typography>
        <Typography variant="body2" gutterBottom>
          {message.scheduledAt}
        </Typography>
      </>
    )}

    <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
      Objective:
    </Typography>
    <Typography variant="body2" gutterBottom>
      {message.objective?.name}
    </Typography>

    <Typography variant="subtitle1" component="div" sx={{fontWeight: "bold"}}>
      Don't send if:
    </Typography>
    <Typography variant="body2" gutterBottom>
      {message.objectiveId ? "Objective Completed" : "Always Send"}
    </Typography>
  </Paper>
)

MessageOverview.propTypes = {
  message: shape({
    contentName: string.isRequired,
    payload: shape({
      subject: shape({
        blocks: arrayOf(
          shape({
            text: string,
          })
        ),
      }),
    }),
    type: oneOf(["sms", "email"]).isRequired,
    order: number,
    scheduledAt: string,
    objective: shape({
      name: string,
    }),
    objectiveId: string,
  }).isRequired,
  variant: oneOf(["drip", "scheduled"]).isRequired,
}

export default MessageOverview
