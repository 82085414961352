import {useEffect, useState} from "react"
import {useParams} from "react-router-dom"

import SmsPreview from "components/chromic-pdf/sms-preview"

import {fetchTemplateMessage} from "lib/api"

const MessagePreview = () => {
  const {templateId, templateMessageId} = useParams()
  const [message, setMessage] = useState(null)

  useEffect(() => {
    fetchTemplateMessage(templateId, templateMessageId).then(({message}) => setMessage(message))
  }, [templateId, templateMessageId])

  if (message && message.type === "sms") return <SmsPreview message={message} />

  return "Cannot render message preview"
}

export default MessagePreview
