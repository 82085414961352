import {isEqual, isNil, zipObject} from "lodash"
import {number} from "prop-types"

import completionConditions from "lib/completion-conditions"
import toPercent from "lib/to-percent"

import RelativePercentage from "./relative-percentage"

export const COLORS = ["#489AD4", "#5CBDAF", "#EF6568", "#F89C74", "#9B59B6", "#002956"]
export const INSIGHTS_COLORS = COLORS

const colorNames = ["lightBlue", "lightGreen", "red", "orange", "purple", "darkBlue"]

const namedColors = zipObject(colorNames, COLORS)
export const NAMED_INSIGHTS_COLORS = {
  ...namedColors,
  coral: namedColors.red,
  navyBlue: namedColors.darkBlue,
  gray: "#5F6565",
  lightGray: "#D6D6D6",
}

export const simplePercentFormat = num => `${num}%`

export const Metrics = {Total: "total", Rate: "rate"}

export const newInsightsRow = messageId => ({
  messageId,
  messageSentCount: 0,
  emailSentCount: 0,
  emailOpenedCount: 0,
  ctaClickedCount: 0,
  emailCtaClickedCount: 0,
  messageBouncedCount: 0,
  openRate: 0,
  clickThruRate: 0,
  clickToOpenRate: 0,
  bounceRate: 0,
})

export const toMessagingInsightsByMessage = insightsData => {
  const totals = newInsightsRow()

  const insightsDataByMessage = Object.entries(insightsData).reduce((acc, [id, datum]) => {
    const {messageSentCount, emailOpenedCount, ctaClickedCount, messageBouncedCount, type} = datum

    totals.messageSentCount += messageSentCount
    if (type === "email") totals.emailSentCount += messageSentCount
    totals.emailOpenedCount += emailOpenedCount
    totals.ctaClickedCount += ctaClickedCount
    if (type === "email") totals.emailCtaClickedCount += ctaClickedCount
    totals.messageBouncedCount += messageBouncedCount

    return {
      ...acc,
      [id]: {
        ...datum,
        openRate: toPercent(datum.emailOpenedCount, datum.messageSentCount),
        clickThruRate: toPercent(datum.ctaClickedCount, datum.messageSentCount),
        clickToOpenRate: toPercent(datum.ctaClickedCount, datum.emailOpenedCount),
        bounceRate: toPercent(datum.messageBouncedCount, datum.messageSentCount),
      },
    }
  }, {})

  return {
    messagingInsights: {
      ...insightsDataByMessage,
      totals: {
        ...totals,
        openRate: toPercent(totals.emailOpenedCount, totals.emailSentCount),
        clickThruRate: toPercent(totals.ctaClickedCount, totals.messageSentCount),
        clickToOpenRate: toPercent(totals.emailCtaClickedCount, totals.emailOpenedCount),
        bounceRate: toPercent(totals.messageBouncedCount, totals.messageSentCount),
      },
    },
  }
}

export function statusColor(benchmark, comparison, classes) {
  const completionRate = comparison / benchmark

  if (completionRate > 1.02) return classes.better
  if (completionRate > 0.97) return classes.same
  return classes.worse
}

// Insights 2.0 helpers:

export const Types = {
  CompletionCondition: "CompletionCondition",
  Number: "Number",
  Percentage: "Percentage",
  RelativePercentage: "RelativePercentage",
  Text: "Text",
}

export function formatValue(value, type) {
  if (!value && value !== 0) return "-"

  if (
    isNaN(value) &&
    (type === Types.Number || type === Types.Percentage || type === Types.RelativePercentage)
  )
    return "-"

  switch (type) {
    case Types.CompletionCondition:
      return completionConditions.find(condition => condition.value === value).primary
    case Types.Number:
      return parseFloat(value.toFixed(2)).toLocaleString()
    case Types.Percentage:
      return `${parseFloat(value.toFixed(0))}%`
    case Types.RelativePercentage:
      return <RelativePercentage value={value} />
    default:
      return value
  }
}

export const numberFormatter = v => formatValue(v, Types.Number)

// http://recharts.org/en-US/examples/PieChartWithCustomizedLabel
const RADIAN = Math.PI / 180

export const RenderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
  const radius = Number(percent >= 0.05 ? 0 : 85) + innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      dominantBaseline="central"
      fill={percent >= 0.05 ? "white" : "black"}
      textAnchor="middle"
      x={x}
      y={y}
    >
      {`${(percent * 100).toFixed(1)}%`}
    </text>
  )
}

RenderCustomizedLabel.propTypes = {
  cx: number.isRequired,
  cy: number.isRequired,
  innerRadius: number.isRequired,
  midAngle: number.isRequired,
  outerRadius: number.isRequired,
  percent: number.isRequired,
}

export const buttonGroupsContainerStyle = theme => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "flex-end",
  paddingTop: theme.spacing(1),

  // Applies to all children except last
  "& > :nth-last-child(n + 2)": {
    marginBottom: theme.spacing(0.5),
  },
})

export const groupByContainerStyle = theme => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "row",
  margin: theme.spacing(2, 0),
})

// Round a large number and return a string truncation, such as "12,345 -> 12.3K"

export function roundNumberForPieChart(num) {
  if (num < 10_000) return num
  if (num < 100_000) return `${(num / 1_000).toFixed(1)}K`
  if (num < 1_000_000) return `${(num / 1_000).toFixed(0)}K`

  if (num < 10_000_000) return `${(num / 1_000_000).toFixed(2)}M`
  if (num < 100_000_000) return `${(num / 1_000_000).toFixed(1)}M`
  if (num < 1_000_000_000) return `${(num / 1_000_000).toFixed(0)}M`

  if (num < 10_000_000_000) return `${(num / 1_000_000_000).toFixed(2)}B`
  if (num < 100_000_000_000) return `${(num / 1_000_000_000).toFixed(1)}B`
  if (num < 1_000_000_000_000) return `${(num / 1_000_000_000).toFixed(0)}B`

  return num
}

// filter set as null in reducer state can come back as an empty string in the insightsCache
const areValuesEqual = (leftVal, rightVal) =>
  isEqual(leftVal, rightVal) ||
  (isNil(leftVal) && isNil(rightVal)) ||
  (isNil(leftVal) && rightVal === "") ||
  (isNil(rightVal) && leftVal === "")

// only checks that the `params` "covers" the `filter` state
// seems like this is reasonable since being too strict on exact matching could
// cause results to be dropped if an unexpected param is included in the insightsCache
export const areFiltersMismatched = (filter, params) =>
  Object.entries(filter).some(([k, v]) => !areValuesEqual(v, params[k]))
