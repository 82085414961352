import {Button} from "@mui/material"
import {bool, func, object, string} from "prop-types"

import customStyleFn from "lib/draft-js/custom-style-fn"
import {findBlockByEntity, getEntitySelectionRange} from "lib/draft-js/entity-helpers"

import DefaultEntity from "./default-entity"
import RewardsEntity from "./rewards-entity"
import SurveyEntity from "./survey-entity"

// Make sure to also update the list of secure personalization strings in
// Gondor to match this list lib/gondor/content_blocks/content_block.ex
const authenticatedValues = [
  "account_number",
  "adverse_status",
  "channel_opened",
  "charge_off",
  "closed_reason",
  "credit_card_activation",
  "in_person_deposit_check_date",
  "last_mobile_pay_amount",
  "last_debit_card_transaction",
  "last_statement_balance",
  "last_transaction_amount",
  "loan_payoff_date",
  "loan_term",
  "maturity_date",
  "meta_private",
  "micr",
  "number_transactions_last_30_days",
  "payment_method",
  "product.description",
  "product.name",
  "product.type",
  "remote_deposit_last_30_days",
  "routing_number",
  "status_code",
  "total_amount_last_30_days",
  "uniqueId",
]

const PersonalizationEntityReadonly = ({
  entityKey,
  contentState,
  isLoggedIn,
  openLogin,
  ...props
}) => {
  const {value, defaultValue} = contentState.getEntity(entityKey).getData()
  const [subject, ...paramParts] = value.split(value.match(/^survey::/) ? "::" : ".")
  const shouldHideValue =
    !isLoggedIn &&
    (authenticatedValues.includes(paramParts[0]) ||
      authenticatedValues.includes(`${paramParts[0]}.${paramParts[1]}`))

  const target = props[subject]
  const hasDefault = Boolean(defaultValue)

  if (!target && !hasDefault) return null

  const entitySelectionRange = getEntitySelectionRange(contentState, entityKey)
  const block = findBlockByEntity(contentState, entityKey)
  const inlineStyles = block.getInlineStyleAt(entitySelectionRange.getAnchorOffset())

  return shouldHideValue ? (
    <Button
      className="reveal-private-information"
      color="primary"
      onClick={openLogin}
      sx={theme => ({
        padding: `0 ${theme.spacing(1)}`,
        borderRadius: theme.spacing(2),
        textTransform: "none",
      })}
      variant="outlined"
    >
      click to reveal
    </Button>
  ) : (
    <>
      {subject === "survey" && (
        <SurveyEntity
          customStyle={customStyleFn(inlineStyles)}
          defaultValue={defaultValue}
          paramParts={paramParts}
          target={target}
        />
      )}
      {subject === "rewards" && (
        <RewardsEntity
          customStyle={customStyleFn(inlineStyles)}
          defaultValue={defaultValue}
          paramParts={paramParts}
          target={{contact: props.contact, rewards: target}}
        />
      )}
      {!["rewards", "survey"].includes(subject) && (
        <DefaultEntity
          customStyle={customStyleFn(inlineStyles)}
          defaultValue={defaultValue}
          paramParts={paramParts}
          target={target}
        />
      )}
    </>
  )
}

PersonalizationEntityReadonly.propTypes = {
  contact: object,
  contentState: object.isRequired,
  entityKey: string.isRequired,
  isLoggedIn: bool,
  openLogin: func,
}

export default PersonalizationEntityReadonly
