import {
  Button,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material"
import cx from "classnames"
import {EditorState, Modifier} from "draft-js"
import {func, number, object, string} from "prop-types"
import {useMemo, useRef, useState} from "react"
import {MdFormatLineSpacing as LineHeightIcon} from "react-icons/md"

import {getCurrentlySelectedBlock} from "./create-block-align-button"

const DATA_KEY = "lineHeight"

const toggleLineHeight = (editorState, {content, currentBlock, target}, lineHeight) => {
  const blockData = currentBlock.getData()
  const selectionState = editorState.getSelection()
  const lineHeightToSet =
    blockData && blockData.get(DATA_KEY) === lineHeight ? undefined : lineHeight
  const newEditorState = EditorState.push(
    editorState,
    Modifier.mergeBlockData(content, target, {
      [DATA_KEY]: lineHeightToSet,
    }),
    "change-block-data"
  )

  return EditorState.forceSelection(newEditorState, selectionState)
}

const isLineHeightActive = ({currentBlock} = {}) => {
  if (!currentBlock) return false

  const blockData = currentBlock.getData()

  return blockData && blockData.get(DATA_KEY)
}

const Option = ({name, value, editorState, currentlySelectedBlock, onClose, onSetEditorState}) => {
  const toggleHeight = e => {
    e.preventDefault()
    onSetEditorState(toggleLineHeight(editorState, currentlySelectedBlock, value))
    onClose()
  }

  return <MenuItem onClick={toggleHeight}>{name}</MenuItem>
}

Option.propTypes = {
  currentlySelectedBlock: object,
  editorState: object,
  name: string,
  onClose: func.isRequired,
  onSetEditorState: func.isRequired,
  value: number,
}

const CustomLineHeightInput = ({
  classes,
  editorState,
  currentlySelectedBlock,
  onClose,
  onSetEditorState,
}) => {
  const {currentBlock} = currentlySelectedBlock
  const blockData = currentBlock.getData()
  const currentLineHeight = blockData && blockData.get(DATA_KEY)
  const [customLineHeight, setCustomLineHeight] = useState(currentLineHeight)

  const stopPropagation = e => e.stopPropagation()
  const onChange = ({target}) => setCustomLineHeight(target.value)
  const saveLineHeight = () => {
    onSetEditorState(toggleLineHeight(editorState, currentlySelectedBlock, customLineHeight))
    onClose()
  }
  const onKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      saveLineHeight()
    } else if (e.key === "Escape") {
      e.preventDefault()
      onClose()
    }
  }

  return (
    <div style={{padding: 18}}>
      <FormControl fullWidth={true} margin="normal">
        <TextField
          autoFocus={true}
          inputProps={{onKeyDown}}
          label="Custom Line Spacing"
          onChange={onChange}
          onMouseDown={stopPropagation}
          type="number"
          value={customLineHeight}
        />
      </FormControl>
      <div className={classes.actions}>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={saveLineHeight}>Save</Button>
      </div>
    </div>
  )
}

CustomLineHeightInput.propTypes = {
  classes: object,
  currentlySelectedBlock: object,
  editorState: object,
  onClose: func.isRequired,
  onSetEditorState: func.isRequired,
}

const options = [
  {name: "Default", value: null},
  {name: "Single", value: 1},
  {name: "1.15", value: 1.15},
  {name: "1.5", value: 1.5},
  {name: "Double", value: 2},
]

const LineHeightButton = ({onSetEditorState, editorState, classes}) => {
  const currentlySelectedBlock = useMemo(() => getCurrentlySelectedBlock(editorState), [
    editorState,
  ])
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isShowCustom, setIsShowCustom] = useState(false)
  const wrapperRef = useRef(null)

  const stopPropagation = e => e.stopPropagation()
  const onClick = () => setIsPopoverOpen(true)
  const onShowCustom = () => setIsShowCustom(true)
  const onClose = () => {
    setIsPopoverOpen(false)
    setIsShowCustom(false)
  }

  return (
    <>
      <Tooltip title="Line Spacing">
        <div ref={wrapperRef}>
          <IconButton
            className={cx(classes.button, {
              [classes.buttonActive]: isLineHeightActive(currentlySelectedBlock),
            })}
            onClick={onClick}
            onMouseDown={stopPropagation}
            size="medium"
          >
            <LineHeightIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Popover
        anchorEl={wrapperRef.current}
        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
        onClose={onClose}
        open={isPopoverOpen}
      >
        {!isShowCustom && (
          <>
            {options.map(({name, value}) => (
              <Option
                currentlySelectedBlock={currentlySelectedBlock}
                editorState={editorState}
                key={name}
                name={name}
                onClose={onClose}
                onSetEditorState={onSetEditorState}
                value={value}
              />
            ))}
            <Divider />
            <MenuItem onClick={onShowCustom}>Custom</MenuItem>
          </>
        )}
        {isShowCustom && (
          <CustomLineHeightInput
            classes={classes}
            currentlySelectedBlock={currentlySelectedBlock}
            editorState={editorState}
            onClose={onClose}
            onSetEditorState={onSetEditorState}
          />
        )}
      </Popover>
    </>
  )
}

LineHeightButton.propTypes = {
  classes: object.isRequired,
  editorState: object,
  onSetEditorState: func.isRequired,
}

export default LineHeightButton
