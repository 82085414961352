export default function remToPx(remSize, muiTheme) {
  if (!remSize.includes("rem")) return remSize

  // While the pxToRem function considers `htmlFontSize`, When I actually try to
  // do the opposite, the number is consistently wrong. This calculation is
  // consistently accurate even w/ fontSize changes, so it might be because of
  // the fact we're NOT setting `htmlFontSize`?

  remSize = parseFloat(remSize)
  const fontSize = muiTheme.typography.fontSize
  const coef = fontSize / 16

  return `${Math.round((remSize / coef) * fontSize)}px`
}
