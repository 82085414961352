import {FormControl, Grid, InputLabel, MenuItem, TextField} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import {darken} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import {any, func, object} from "prop-types"

import ColorInput from "components/color-input/color-input"
import DOSelect from "components/do-select/do-select"
import FontSelector from "components/font-selector/font-selector"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"
import ResetLabel from "components/teams/branding-settings/reset-label"

import BrandedButton from "../../branded-button/branded-button"
import FontWeightSelector from "./font-weight-selector"

const HalfColumn = ({children}) => (
  <Grid item lg={6} md={12} xs={12}>
    {children}
  </Grid>
)

HalfColumn.propTypes = {
  children: any,
}

const ButtonSettings = ({change, classes, field, muiTheme}) => {
  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <div className={classes.stage}>
          <BrandedButton color="primary" variant="contained">
            Sample Button
          </BrandedButton>
        </div>
      </ThemeProvider>
      <Grid container spacing={3}>
        <HalfColumn>
          <FormControl fullWidth>
            <InputLabel htmlFor="textTransform">
              <ResetLabel name="typography.button.textTransform">Text Transform</ResetLabel>
            </InputLabel>
            <DOSelect
              id="textTransform"
              {...field(`typography.button.textTransform`, {
                defaultValue: muiTheme.typography.button.textTransform,
                exclude: ["helperText"],
              })}
            >
              <MenuItem value="capitalize">Capitalize</MenuItem>
              <MenuItem value="lowercase">LowerCase</MenuItem>
              <MenuItem value="uppercase">Uppercase</MenuItem>
            </DOSelect>
          </FormControl>
        </HalfColumn>

        <HalfColumn>
          <FormControl fullWidth>
            <InputLabel htmlFor="fontFamily">
              <ResetLabel name="typography.button.fontFamily">Font Family</ResetLabel>
            </InputLabel>
            <FontSelector
              enableGoogleFonts={true}
              id="fontFamily"
              {...field("typography.button.fontFamily", {
                defaultValue:
                  muiTheme.typography.button.fontFamily || muiTheme.typography.fontFamily,
                exclude: ["helperText"],
              })}
              onSelect={({value}) => change("typography.button.fontFamily", `${value}`)}
            />
          </FormControl>
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            id="fontSize"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="typography.button.fontSize">Font Size</ResetLabel>}
            type="text"
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            {...field(`typography.button.fontSize`, {
              defaultValue: muiTheme.typography.button.fontSize || muiTheme.typography.fontSize,
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <FontWeightSelector
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="typography.button.fontWeight">Font Weight</ResetLabel>}
            {...field(`typography.button.fontWeight`, {
              defaultValue: muiTheme.typography.button.fontWeight || 500,
              exclude: ["helperText"],
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="lineHeight"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="typography.button.lineHeight">Line Height</ResetLabel>}
            type="text"
            {...field(`typography.button.lineHeight`, {
              defaultValue: muiTheme.typography.button.lineHeight || 1.75,
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="letterSpacing"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="typography.button.letterSpacing">Letter Spacing</ResetLabel>}
            type="text"
            {...field(`typography.button.letterSpacing`, {
              defaultValue: muiTheme.typography.button.letterSpacing || "0px",
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <ColorInput
            fullWidth
            id="borderColor"
            input={{
              ...field("overrides.MuiButton.containedPrimary.borderColor", {
                defaultValue:
                  muiTheme.overrides?.MuiButton?.containedPrimary?.borderColor ||
                  muiTheme.palette.primary.contrastText,
              }),
              onChange: hex => change("overrides.MuiButton.containedPrimary.borderColor", hex),
            }}
            label={
              <ResetLabel name="overrides.MuiButton.containedPrimary.borderColor">
                Border Color
              </ResetLabel>
            }
          />
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="borderRadius"
            InputLabelProps={{shrink: true}}
            label={
              <ResetLabel name="overrides.MuiButton.containedPrimary.borderRadius">
                Border Radius
              </ResetLabel>
            }
            type="text"
            {...field(`overrides.MuiButton.containedPrimary.borderRadius`, {
              defaultValue: muiTheme.overrides?.MuiButton?.containedPrimary?.borderRadius || "3px",
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="borderWidth"
            InputLabelProps={{shrink: true}}
            label={
              <ResetLabel name="overrides.MuiButton.containedPrimary.borderWidth">
                Border Width
              </ResetLabel>
            }
            type="text"
            {...field(`overrides.MuiButton.containedPrimary.borderWidth`, {
              defaultValue: muiTheme.overrides?.MuiButton?.containedPrimary?.borderWidth || "0px",
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <FormControl fullWidth>
            <InputLabel htmlFor="borderStyle">
              <ResetLabel name="overrides.MuiButton.containedPrimary.borderStyle">
                Border Style
              </ResetLabel>
            </InputLabel>
            <DOSelect
              id="borderStyle"
              {...field(`overrides.MuiButton.containedPrimary.borderStyle`, {
                defaultValue:
                  muiTheme.overrides?.MuiButton?.containedPrimary?.borderStyle || "none",
                exclude: ["helperText"],
              })}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="hidden">Hidden</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="double">Double</MenuItem>
              <MenuItem value="groove">Groove</MenuItem>
              <MenuItem value="ridge">Ridge</MenuItem>
              <MenuItem value="inset">Inset</MenuItem>
              <MenuItem value="outset">Outset</MenuItem>
            </DOSelect>
          </FormControl>
        </HalfColumn>

        <HalfColumn>
          <ColorInput
            fullWidth
            id="color"
            input={{
              ...field("overrides.MuiButton.containedPrimary.color", {
                defaultValue:
                  muiTheme.overrides?.MuiButton?.containedPrimary?.color ||
                  muiTheme.palette.primary.contrastText,
              }),
              onChange: hex => change("overrides.MuiButton.containedPrimary.color", hex),
            }}
            label={<ResetLabel name="overrides.MuiButton.containedPrimary.color">Color</ResetLabel>}
          />
        </HalfColumn>

        <HalfColumn>
          <ColorInput
            fullWidth
            id="backgroundColor"
            input={{
              ...field("overrides.MuiButton.containedPrimary.backgroundColor", {
                defaultValue:
                  muiTheme.overrides?.MuiButton?.containedPrimary?.backgroundColor ||
                  muiTheme.palette.primary.main,
              }),
              onChange: hex => {
                change("overrides.MuiButton.containedPrimary.backgroundColor", `${hex}`)
                change("custom.buttons.hover.backgroundColor", darken(hex, 0.1))
              },
            }}
            label={
              <ResetLabel
                names={[
                  "overrides.MuiButton.containedPrimary.backgroundColor",
                  "custom.buttons.hover.backgroundColor",
                ]}
              >
                Background Color
              </ResetLabel>
            }
          />
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="paddingX"
            InputLabelProps={{shrink: true}}
            label={
              <ResetLabel
                names={[
                  "overrides.MuiButton.containedPrimary.paddingLeft",
                  "overrides.MuiButton.containedPrimary.paddingRight",
                ]}
              >
                Padding X
              </ResetLabel>
            }
            type="text"
            {...field(`overrides.MuiButton.containedPrimary.paddingLeft`, {
              defaultValue: muiTheme.overrides?.MuiButton?.containedPrimary?.paddingLeft || "16px",
            })}
            onChange={({target: {value}}) => {
              change("overrides.MuiButton.containedPrimary.paddingLeft", value)
              change("overrides.MuiButton.containedPrimary.paddingRight", value)
            }}
          />
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="paddingY"
            InputLabelProps={{shrink: true}}
            label={
              <ResetLabel
                names={[
                  "overrides.MuiButton.containedPrimary.paddingTop",
                  "overrides.MuiButton.containedPrimary.paddingBottom",
                ]}
              >
                Padding Y
              </ResetLabel>
            }
            type="text"
            {...field(`overrides.MuiButton.containedPrimary.paddingTop`, {
              defaultValue: muiTheme.overrides?.MuiButton?.containedPrimary?.paddingTop || "6px",
            })}
            onChange={({target: {value}}) => {
              change("overrides.MuiButton.containedPrimary.paddingTop", value)
              change("overrides.MuiButton.containedPrimary.paddingBottom", value)
            }}
          />
        </HalfColumn>
      </Grid>
    </>
  )
}

const styles = {
  stage: {
    height: 200,
    marginBottom: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}

ButtonSettings.propTypes = {
  change: func.isRequired,
  classes: object.isRequired,
  field: func.isRequired,
  muiTheme: object.isRequired,
}

export default withStyles(styles)(brandingSettings(ButtonSettings))
