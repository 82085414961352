import {Grid, Tooltip as MaterialTooltip, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {differenceInDays} from "date-fns"
import {bool, node, object} from "prop-types"
import {useEffect} from "react"
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts"

import {getDays, getHours} from "components/journey-duration-input/journey-duration-input"

import {fetchTemplateGeneralInsights} from "lib/api"
import {formatDate} from "lib/date-time-formatters"
import useFeatures from "lib/hooks/use-features"
import pluralize from "lib/string/pluralize"

import {useInsights} from "./insights-context"
import {COLORS, RenderCustomizedLabel, numberFormatter} from "./insights-helpers"

function getDaysLive(launchedAt, isArchived, archivedAt) {
  if (launchedAt)
    return isArchived
      ? differenceInDays(new Date(archivedAt), new Date(launchedAt)).toLocaleString()
      : differenceInDays(new Date(), new Date(launchedAt)).toLocaleString()

  return "n/a"
}

const Skeletonized = ({loaded, children, skeletonProps}) =>
  loaded ? <>{children}</> : <Skeleton {...skeletonProps} />

Skeletonized.propTypes = {
  loaded: bool,
  children: node,
  skeletonProps: object,
}

const GeneralInsights = ({classes}) => {
  const {
    insights: {general_insights},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()

  useEffect(() => {
    fetchTemplateGeneralInsights(templateId, filter).then(addInsightsCache)
  }, [addInsightsCache, templateId, filter])

  const {hasFeature} = useFeatures()
  const launchedAtTooltip = hasFeature("campaign-approval")
    ? "The day that the campaign was Launched after Approval"
    : "The day that the first journey in the campaign was created"

  const {
    journeyDurationHours,
    journeysData,
    journeysTotal,
    launchedAt,
    isArchived,
    archivedAt,
    minContactAddedAt,
  } = general_insights?.value || {}
  const pieData = !!general_insights ? journeysData.filter(journey => journey.value > 0) : []
  const launchedAtValue = hasFeature("campaign-approval") ? launchedAt : minContactAddedAt
  const days = getDays(journeyDurationHours || 60)
  const hours = getHours(journeyDurationHours || 60)

  return (
    <Grid container={true} spacing={3}>
      <Grid className={classes.statsGridItem} item={true} lg={6} md={6} sm={12} xl={6} xs={12}>
        <dl className={classes.dl}>
          <MaterialTooltip title={launchedAtTooltip}>
            <Typography component="dt">Launch Date</Typography>
          </MaterialTooltip>
          <Typography component="dd">
            <Skeletonized loaded={!!general_insights} skeletonProps={{variant: "text"}}>
              {launchedAtValue ? formatDate(launchedAtValue, "MMMM dd, yyyy") : "Not launched yet"}
            </Skeletonized>
          </Typography>
          {isArchived && (
            <>
              <Typography component="dt">Archived</Typography>
              <Typography component="dd">
                <Skeletonized loaded={!!general_insights} skeletonProps={{variant: "text"}}>
                  {archivedAt ? formatDate(archivedAt, "MMMM dd, yyyy") : "Unknown"}
                </Skeletonized>
              </Typography>
            </>
          )}
          <MaterialTooltip title="Number of days the contact has to complete their journey before it expires">
            <Typography component="dt">Journey Duration</Typography>
          </MaterialTooltip>
          <Typography component="dd">
            <Skeletonized loaded={!!general_insights} skeletonProps={{variant: "text"}}>
              {days} {pluralize("day", days)} {hours} {pluralize("hour", hours)}
            </Skeletonized>
          </Typography>
          <MaterialTooltip title="Number of days from the Launch Date to today">
            <Typography component="dt">Days Live</Typography>
          </MaterialTooltip>
          <Typography component="dd">
            <Skeletonized loaded={!!general_insights} skeletonProps={{variant: "text"}}>
              {getDaysLive(launchedAtValue, isArchived, archivedAt)}
            </Skeletonized>
          </Typography>
          <MaterialTooltip title="Number of journeys in this campaign">
            <Typography component="dt">Journeys Sent</Typography>
          </MaterialTooltip>
          <Typography component="dd">
            <Skeletonized loaded={!!general_insights} skeletonProps={{variant: "text"}}>
              {numberFormatter(journeysTotal)}
            </Skeletonized>
          </Typography>
        </dl>
      </Grid>

      <Grid item={true} lg={6} md={6} sm={12} xl={6} xs={12}>
        <Skeletonized
          loaded={!!general_insights}
          skeletonProps={{variant: "circle", width: 250, height: 250}}
        >
          <ResponsiveContainer minHeight={400}>
            <PieChart margin={{top: 50}}>
              <Pie
                data={pieData}
                dataKey="value"
                isAnimationActive={false}
                label={RenderCustomizedLabel}
                labelLine={false}
                stroke={pieData.length <= 1 ? COLORS[0] : undefined}
              >
                {(journeysData || []).map((entry, index) => (
                  <Cell fill={COLORS[index]} key={index} />
                ))}
              </Pie>
              <Tooltip formatter={numberFormatter} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Skeletonized>
      </Grid>
    </Grid>
  )
}

GeneralInsights.propTypes = {
  classes: object.isRequired,
}

export default GeneralInsights
