import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import memoize from "memoize-one"
import {func} from "prop-types"
import queryString from "query-string"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {useLocation, useParams} from "react-router-dom"

import {ContentBlockEditorProvider} from "components/content-block-editor/content-block-editor-context"
import {
  COMPLETED_SURVEY_LIST_SET,
  clearCompletedSurveys,
} from "components/content-block-editor/plugins/survey/survey-actions"
import {JourneyContextProvider} from "components/journeys/journey-context"
import themeBases from "components/journeys/theme-bases"

import {
  fetchActions,
  fetchContentContainer,
  fetchContentContainerPreview,
  fetchCrossChannelJourney,
  updateContentContainer,
} from "lib/api"
import {loadFontsFromTheme} from "lib/fonts"
import createTeamTheme from "themes/team-theme-creator"

const createClasses = memoize(base => makeStyles(base))

const CrossChannelEngagementContainer = ({render}) => {
  // Path params
  const {channelId, contactId, contentContainerId} = useParams()
  // Query params
  const {search} = useLocation()
  const {journey_id: journeyId} = queryString.parse(search)

  const [contentContainer, setContentContainer] = useState(null)
  const channelBrandingStyles = contentContainer?.engagementChannel?.themeStyles || {}
  const muiTheme = createTeamTheme(channelBrandingStyles)
  const classes = createClasses(themeBases.default)()
  const dispatch = useDispatch()

  const handleUpdateContentContainer = attrs => {
    const {id, engagementChannelId} = contentContainer

    updateContentContainer(engagementChannelId, id, attrs).then(setContentContainer)
  }

  useEffect(() => {
    loadFontsFromTheme(muiTheme)
  }, [muiTheme])

  useEffect(() => {
    // Params exist for read cross channel content
    if (contentContainerId && (contactId || journeyId)) {
      fetchCrossChannelJourney(contentContainerId, {
        contactId,
        journeyId,
      }).then(setContentContainer)
    }

    // Params exist for edit cross channel content
    if (contentContainerId && channelId) {
      fetchContentContainer(channelId, contentContainerId).then(setContentContainer)
    }

    // Params exist for cross channel content preview
    if (contentContainerId && !(channelId || contactId)) {
      fetchContentContainerPreview(contentContainerId).then(setContentContainer)
    }
  }, [channelId, contactId, contentContainerId, journeyId])

  // Preload any required data for survey widgets that may appear in content
  useEffect(() => {
    if (contactId) {
      fetchActions({contactId, name: "survey_completed"}).then(actions => {
        dispatch({type: COMPLETED_SURVEY_LIST_SET, payload: actions.map(a => a.meta.widgetSlug)})
      })
    }

    return () => {
      dispatch(clearCompletedSurveys())
    }
  }, [contactId, dispatch])

  return (
    <ThemeProvider theme={muiTheme}>
      {/*
       * TODO: Read these values in content blocks via the content block context
       *       instead of journey context, then remove journey context provider
       */}
      <JourneyContextProvider
        legacyInitialState={{
          account: contentContainer?.journey?.account,
          contact: contentContainer?.contact,
          engagementChannelId: channelId || null,
          journey: contentContainer?.journey,
          journeyId,
          pageId: contentContainer?.pageId,
          themeClasses: classes,
        }}
      >
        <ContentBlockEditorProvider>
          {render({
            classes,
            contentContainer,
            contentContainerId,
            updateContentContainer: handleUpdateContentContainer,
          })}
        </ContentBlockEditorProvider>
      </JourneyContextProvider>
    </ThemeProvider>
  )
}

CrossChannelEngagementContainer.propTypes = {render: func}

export default CrossChannelEngagementContainer
