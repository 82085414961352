import {Button, TextField, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {object, shape, string} from "prop-types"
import {Component} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"

import {sendDraftMessage} from "lib/api"
import {validEmail, validPhone} from "lib/field-validations"

import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"

class MessageTestSettingsPanel extends Component {
  constructor({message, currentUser}) {
    super()
    const recipient = message.type === "sms" ? currentUser.phoneMobile : currentUser.email

    this.state = {
      isRecipientValid: !!recipient,
      isSendingSampleMessage: false,
      recipient,
    }
  }

  onChangeRecipient = ({target}) => {
    const {
      message: {type},
    } = this.props
    const validator = type === "sms" ? validPhone : validEmail

    this.setState({
      recipient: target.value,
      isRecipientValid: !validator(target.value),
    })
  }

  onSendTest = event => {
    event.preventDefault()
    const {recipient} = this.state
    const {
      message: {id: messageId},
      match: {
        params: {templateId, templateMessageId},
      },
    } = this.props

    this.setState({isSendingSampleMessage: true})
    sendDraftMessage({
      type: "email",
      messageId,
      recipient,
      templateId,
      templateMessageId,
    })
      .catch(({message: sendSampleMessageFailureMessage}) => {
        this.setState({sendSampleMessageFailureMessage})
      })
      .finally(() => {
        this.setState({isSendingSampleMessage: false})
      })
  }

  render() {
    const {message, classes} = this.props

    const {
      isRecipientValid,
      isSendingSampleMessage,
      sendSampleMessageFailureMessage,
      recipient: defaultValue,
    } = this.state

    return (
      <form className={classes.expansionDetailInner} onSubmit={this.onSendTest}>
        Enter the {message.type === "sms" ? "phone number" : "e-mail address"} that you would like
        this sample message to be sent to:
        <br />
        <Typography variant="caption">
          If you have entered any personalizations, they will not be replaced in the sample.
        </Typography>
        <TextField
          margin="normal"
          fullWidth={true}
          defaultValue={defaultValue}
          onChange={this.onChangeRecipient}
        />
        {!isRecipientValid && (
          <Typography className={classes.errorText} gutterBottom={true} variant="caption">
            This {message.type === "sms" ? "phone number" : "e-mail address"} is invalid. Please
            double check you have entered a valid{" "}
            {message.type === "sms" ? "phone number" : "e-mail address"} and try again.
          </Typography>
        )}
        {!!sendSampleMessageFailureMessage && (
          <Typography className={classes.errorText} gutterBottom={true} variant="caption">
            {sendSampleMessageFailureMessage}
          </Typography>
        )}
        <div className={classes.saveButtonRow}>
          <Button
            color="primary"
            disabled={isSendingSampleMessage}
            variant="contained"
            type="submit"
          >
            Send
          </Button>
        </div>
      </form>
    )
  }
}

MessageTestSettingsPanel.propTypes = {
  classes: object,
  currentUser: object,
  match: shape({
    params: shape({
      templateId: string,
    }),
  }),
  message: object,
}

const styles = theme => ({
  expansionDetailInner: {
    width: "100%",
  },
  errorText: {
    marginTop: 10,
    color: theme.palette.error.main,
  },
  saveButtonRow: {
    display: "flex",
    justifyContent: "flex-end",
  },
})

export default connect(({session}) => ({currentUser: session?.user}))(
  withRouter(withStyles(styles)(contentBlockEditorConsumer(MessageTestSettingsPanel)))
)
