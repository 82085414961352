import {
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  Box as MUIBox,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material"
import {array, arrayOf, bool, func, oneOf, shape, string} from "prop-types"

import DOSelect from "components/do-select/do-select"

import useForm from "lib/hooks/use-form"
import {fullName} from "lib/names"

const NotificationSettingForm = ({
  initialValues,
  isAnyUserPermittedToReceiveNotifications,
  isWebhookUrlSet,
  onSubmit,
  recipients,
}) => {
  const {change, field, handleSubmit, inputs} = useForm({
    autoSubmitOnChange: true,
    enableReinitialize: true,
    initialValues,
    onSubmit,
  })

  const selectedRecipients = inputs.recipients.value
  const isEmailEnabled = inputs.isEmailEnabled.checked

  const renderSelectedRecipients = selected => {
    if (selected.length === 0) return "No Recipients Selected"

    const newlySelectedRecipients = recipients.filter(u => selected.includes(u.id))

    return (
      <MUIBox sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
        {newlySelectedRecipients.map(user => (
          <Chip key={user.id} label={fullName(user)} />
        ))}
      </MUIBox>
    )
  }

  return (
    <form onSubmit={handleSubmit} style={{display: "flex"}}>
      <MUIBox
        sx={{
          width: 180,
          ml: 6,
          "& > code": {
            display: "block",
            fontSize: 12,
            color: "brand.lightCoral",
            userSelect: "all",
          },
        }}
      >
        <FormControlLabel
          control={<Switch color="primary" {...field("isWebhookEnabled")} />}
          disabled={!isWebhookUrlSet}
          label="Webhook"
        />
        <code>{initialValues.type}</code>
      </MUIBox>

      <MUIBox sx={{ml: 6}}>
        <FormControlLabel
          control={<Switch color="primary" {...field("isEmailEnabled")} />}
          disabled={!isAnyUserPermittedToReceiveNotifications}
          label="Email"
        />
        {isEmailEnabled && (
          <MUIBox>
            <RadioGroup row {...field("deliveryFrequency")}>
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Individually"
                labelPlacement="end"
                value="individual"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Daily Digest"
                labelPlacement="end"
                value="daily"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Weekly Digest"
                labelPlacement="end"
                value="weekly"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Monthly Digest"
                labelPlacement="end"
                value="monthly"
              />
            </RadioGroup>

            {/* The minHeight on this form control keeps the page from jittering when we go from no recipients selected to 1 selected because the chip height is a little bit bigger than the empty value height */}
            <FormControl sx={{mt: 1, width: 237, "& > label + div": {minHeight: 41}}}>
              <InputLabel id="recipient-select-label" shrink>
                Select Recipients
              </InputLabel>
              <DOSelect
                displayEmpty
                multiple
                renderValue={renderSelectedRecipients}
                {...field("recipients", {defaultValue: []})}
              >
                <MenuItem onClick={() => change("recipients", [])}>Deselect All</MenuItem>
                <Divider />
                {recipients.map(recipient => (
                  <MenuItem key={recipient.id} value={recipient.id}>
                    <Checkbox checked={selectedRecipients.includes(recipient.id)} color="primary" />
                    <ListItemText primary={fullName(recipient)} />
                  </MenuItem>
                ))}
              </DOSelect>
            </FormControl>
          </MUIBox>
        )}
      </MUIBox>
    </form>
  )
}

NotificationSettingForm.propTypes = {
  initialValues: shape({
    deliveryFrequency: oneOf(["individual", "daily", "weekly", "monthly"]),
    isEmailEnabled: bool,
    isWebhookEnabled: bool,
    recipients: arrayOf(string),
  }),
  isAnyUserPermittedToReceiveNotifications: bool,
  isWebhookUrlSet: bool,
  onSubmit: func,
  recipients: array,
}

export default NotificationSettingForm
