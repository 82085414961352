import {Button} from "@mui/material"
import {arrayOf, func, object} from "prop-types"
import React from "react"

import AccountsTableSettingsColumn from "./accounts-table-settings-column"

const maxColumns = 7

const AccountsTableSettingsColumnPanel = ({columns, onChange}) => {
  const handleAddColumn = () => {
    onChange([
      ...columns,
      {
        id: columns.length + 1,
        header: "",
        contentId: "",
        error: true,
      },
    ])
  }

  const handleChangeColumn = changed => {
    onChange([...columns.map(existing => (existing.id === changed.id ? changed : existing))])
  }

  const handleDeleteColumn = deletedColumnId => {
    onChange(
      columns
        .filter(column => column.id !== deletedColumnId) //remove the deleted column
        .map((column, index) => ({...column, id: index + 1})) // remap the ids so they are in order without gaps
    )
  }
  return (
    <>
      {columns.map(column => (
        <AccountsTableSettingsColumn
          column={column}
          key={column.id}
          onChange={handleChangeColumn}
          onDelete={handleDeleteColumn}
        />
      ))}

      {columns.length < maxColumns && <Button onClick={() => handleAddColumn()}>Add Column</Button>}
    </>
  )
}

AccountsTableSettingsColumnPanel.propTypes = {
  columns: arrayOf(object),
  onChange: func,
}

export default AccountsTableSettingsColumnPanel
