import {Button} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {node, object} from "prop-types"

const useStyles = makeStyles(theme => {
  if (theme.custom?.buttons?.hover?.backgroundColor) {
    return {
      containedPrimary: {
        "&:hover": {
          backgroundColor: theme.custom.buttons.hover.backgroundColor,
        },
      },
    }
  }

  return {}
})

/*
 * Wrapper component to apply branding styles to MUI button. This is necessary
 *   because MUI does not support overriding hover state styles
 *   for button components by other means. If there's no style override, then
 *   this component simply passes through all props directly to the button.
 */
const BrandedButton = props => {
  const classes = useStyles()
  const shimmedClasses = {
    ...props.classes,
    containedPrimary: cx(props.classes.containedPrimary, classes.containedPrimary),
  }

  return <Button {...props} classes={shimmedClasses} />
}

BrandedButton.defaultProps = {classes: {}}

BrandedButton.propTypes = {children: node.isRequired, classes: object}

export default BrandedButton
