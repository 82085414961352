import {push} from "connected-react-router"

import {createUser, deleteUser, updateUser} from "lib/api"

import {displayAppMessage} from "../../actions/app-actions"

export const USER_DELETED = "USER_DELETED"
export const USER_CREATED = "USER_CREATED"
export const USER_UPDATED = "USER_UPDATED"
export const USER_LIST_CLEARED = "USER_LIST_CLEARED"

export const onDeleteUser = userId => dispatch =>
  deleteUser(userId).then(() => {
    dispatch({type: USER_DELETED, payload: {id: userId}})
    dispatch(displayAppMessage("User Deleted"))
  })

export const clearUsers = () => ({type: USER_LIST_CLEARED})

export const onUpdateUser = (userId, attrs) => dispatch =>
  updateUser(userId, attrs).then(user => dispatch({type: USER_UPDATED, payload: user}))

export const onSendUserInvite = user => dispatch =>
  createUser(user).then(() => dispatch(push("/admin/team")))
