import {array, number} from "prop-types"

import pluralize from "lib/string/pluralize"

import {NAMED_INSIGHTS_COLORS} from "./insights-helpers"
import StatsPie from "./stats-pie"

const {lightBlue, red, gray} = NAMED_INSIGHTS_COLORS

const SurveyCompletionDonut = ({stats, totalViews}) => {
  const totalLabel = pluralize("view", totalViews)
  // Sync color sequence to stats sequence
  const colors = stats.map(({name}) => {
    switch (name) {
      case "Completed":
        return lightBlue
      case "Abandoned":
        return red
      default:
        return gray
    }
  })

  return totalViews > 0 ? (
    <StatsPie colors={colors} data={stats} total={totalViews} totalLabel={totalLabel} />
  ) : (
    <div>No data available</div>
  )
}

SurveyCompletionDonut.propTypes = {
  stats: array.isRequired,
  totalViews: number.isRequired,
}

export default SurveyCompletionDonut
