import {Snackbar} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {arrayOf, bool, func, object, string} from "prop-types"
import {PureComponent} from "react"
import {Redirect} from "react-router-dom"

import {featurify} from "lib/hooks/use-features"

import {LOADING_STATE_COMPLETED} from "../../actions/loading-state-actions"
import {templateContentContext} from "../../contexts/template-content-context"
import BackButton from "../back-button/back-button"
import {ContentBlockEditorProvider} from "../content-block-editor/content-block-editor-context"
import PreviewControls from "../content-block-editor/preview-controls"
import DocumentTitle from "../document-title/document-title"
import ContentWrapper from "../journeys/content-wrapper"
import Journey from "../journeys/themed-journey"
import PreviewContext from "./preview-context"
import PreviewWrapper from "./preview-wrapper"
import SnackbarTransition from "./snackbar-transition"
import TemplateSidebar from "./template-sidebar"

export class Template extends PureComponent {
  constructor(props) {
    super()

    this.state = {
      preview: {
        isPreviewMode: props.isPreviewMode,
        togglePreviewMode: this.togglePreviewMode,
      },
    }
  }

  componentDidMount() {
    this.props.getComponentData(this.props.templateId)
    this.props.fetchTemplateMessages()
    this.props.fetchTimelineMarkers()
    // TODO: remove this when we're not using redux... I didn't want to tackle
    // moving CRUD operations on the pages/template itself, just the
    // messages/timeline markers for now
    if (this.props.template?.templatePages)
      this.props.setTemplatePages(this.props.template.templatePages)
  }

  componentDidUpdate(prevProps) {
    const {isPreviewMode, templateId, template} = this.props

    if (prevProps.isPreviewMode !== isPreviewMode)
      this.setState({preview: {...this.state.preview, isPreviewMode}}) // eslint-disable-line react/no-did-update-set-state
    if (prevProps.templateId !== templateId) {
      this.props.getComponentData(templateId)
      this.props.fetchTemplateMessages()
    }
    // TODO: remove this when we're not using redux... I didn't want to tackle
    // moving CRUD operations on the pages/template itself, just the
    // messages/timeline markers for now
    if (!prevProps.template?.templatePages && template?.templatePages)
      this.props.setTemplatePages(template.templatePages)
  }

  componentWillUnmount = () => {
    this.props.clearComponentData()
  }

  pageUrlGenerator = pageSlug => `/admin/templates/${this.props.templateId}/edit/${pageSlug}`

  onContentChange = (pageId, attrs) => this.onUpdateContent(pageId, attrs)

  onCreatePage = (newPage, shouldDuplicatePage = false) =>
    this.props.onCreateTemplatePage(
      this.props.templateId,
      {
        order: this.props.template.templatePages.length + 1,
        page: newPage,
      },
      shouldDuplicatePage
    )

  onDeletePage = pageId => {
    const {
      templateId,
      template: {templatePages},
      hasFeature,
    } = this.props
    const currTemplatePage = templatePages.find(tp => tp.page.id === pageId)
    const currTemplatePageIndex = templatePages.indexOf(currTemplatePage)
    const nextTemplatePageIndex =
      currTemplatePageIndex === templatePages.length - 1
        ? currTemplatePageIndex - 1
        : currTemplatePageIndex + 1
    const nextTemplatePage = templatePages[nextTemplatePageIndex]

    // You can remove all pages if you have atomic assets
    if (hasFeature("atomic-assets"))
      return this.props.onRemoveTemplatePage(
        templateId,
        currTemplatePage.id,
        nextTemplatePage?.page?.slug
      )

    // no-op if trying to remove the last page if you don't have atomic assets
    if (nextTemplatePage)
      this.props.onDeletePage(
        templateId,
        currTemplatePage.id,
        currTemplatePage.pageId,
        nextTemplatePage.page.slug
      )
  }

  // Wowowowow, making a page unique messes with state in both places so we have
  // to re-sync it
  onMakePageUnique = (...args) =>
    this.props.onMakePageUnique(...args).then(() => {
      this.props.setTemplatePages(this.props.template.templatePages)
    })

  onUpdateContent = (pageId, {content}) => {
    this.props.onUpdatePage(pageId, {content})
    this.props.onStashPage(pageId, {content})
  }

  onSortPages = pages => this.props.onSortPages(this.props.templateId, pages)

  onUpdateTemplate = template => {
    const {templateId} = this.props

    this.props.onUpdateTemplate(templateId, template)
  }

  togglePreviewMode = () => this.props.setPreviewMode(!this.props.isPreviewMode)

  render() {
    const {
      classes,
      hasFeature,
      template,
      templateId,
      pageSlug,
      loadingStates,
      onUpdateTemplatePage,
      onUpdatePage,
    } = this.props

    if (!templateId) return null

    if (template.templatePages && !template.templatePages.length)
      return <Redirect to={`/admin/templates/${templateId}/content`} />

    const templatePage =
      template.templatePages && template.templatePages.find(tp => tp.page.slug === pageSlug)
    const page = templatePage ? templatePage.page : null
    const nextPage =
      template.templatePages?.[template.templatePages.indexOf(templatePage) + 1]?.page

    return (
      <ContentBlockEditorProvider>
        <PreviewContext.Provider value={this.state.preview}>
          {template.name && <DocumentTitle title={`Campaigns - ${template.name} - Editor`} />}
          <BackButton
            className={classes.returnLink}
            to={`/admin/templates/${template.id}/${hasFeature("atomic-assets") ? "content" : ""}`}
          >
            Return to Template
          </BackButton>
          <PreviewControls layoutBasis={page?.layoutBasis} status={template.status} />
          <PreviewWrapper>
            {/*TODO: Ideally we wouldn't need to check isPageLoaded and page,
            but when we switch to a new template (from the file dialog) the component
            data does not change before the route. Will be storing the state locally,
            maybe we will get some of that for FREE!!!!*/}
            {this.props.isPageLoaded && page && (
              <ContentWrapper>
                <Journey
                  clearComponentData={this.props.clearComponentData}
                  isEditable={true}
                  isPageLoaded={true}
                  onChange={this.onContentChange}
                  onNavigateToPage={this.props.onNavigateToPage}
                  onSortPages={this.onSortPages}
                  onUpdatePage={onUpdatePage}
                  onUpdateTemplate={this.onUpdateTemplate}
                  onUpdateTemplatePage={onUpdateTemplatePage}
                  pageId={page.id}
                  pageSlug={page.slug}
                  pageUrlGenerator={this.pageUrlGenerator}
                  template={template}
                  templateId={templateId}
                />
              </ContentWrapper>
            )}
            {this.props.isTemplateLoaded && (
              <TemplateSidebar
                nextPage={nextPage}
                onCreatePage={this.onCreatePage}
                onDeletePage={this.onDeletePage}
                onMakePageUnique={this.onMakePageUnique}
                onPageUpdated={this.props.onPageUpdated}
                onUpdatePage={onUpdatePage}
                onUpdateTemplate={this.onUpdateTemplate}
                onUpdateTemplatePage={onUpdateTemplatePage}
                page={page}
                template={template}
                templatePage={templatePage}
                team={this.props.team}
              />
            )}
            {loadingStates.map(loadingState => (
              <Snackbar
                key={loadingState.key}
                message={loadingState.message}
                onClose={this.handleClose}
                open={loadingState.code !== LOADING_STATE_COMPLETED}
                transition={SnackbarTransition}
              />
            ))}
          </PreviewWrapper>
        </PreviewContext.Provider>
      </ContentBlockEditorProvider>
    )
  }
}

const styles = theme => ({
  returnLink: {
    float: "left",
    paddingTop: 5,
    position: "absolute",
    left: 30,
    width: "auto",
    zIndex: 100,
  },
})

Template.propTypes = {
  classes: object.isRequired,
  clearComponentData: func.isRequired,
  fetchTemplateMessages: func.isRequired,
  fetchTimelineMarkers: func.isRequired,
  getComponentData: func.isRequired,
  hasFeature: func.isRequired,
  isPageLoaded: bool.isRequired,
  isPreviewMode: bool.isRequired,
  isTemplateLoaded: bool.isRequired,
  loadingStates: arrayOf(object),
  onCreateTemplatePage: func.isRequired,
  onDeletePage: func.isRequired,
  onMakePageUnique: func.isRequired,
  onNavigateToPage: func.isRequired,
  onPageUpdated: func,
  onRemoveTemplatePage: func.isRequired,
  onSortPages: func.isRequired,
  onStashPage: func.isRequired,
  onUpdatePage: func.isRequired,
  onUpdateTemplate: func.isRequired,
  onUpdateTemplatePage: func.isRequired,
  pageSlug: string,
  setPreviewMode: func.isRequired,
  setTemplatePages: func.isRequired,
  template: object,
  templateId: string,
  team: object,
}

export default withStyles(styles)(featurify(templateContentContext(Template)))
