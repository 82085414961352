/*eslint-disable no-shadow*/
import withStyles from "@mui/styles/withStyles"
import {func, node, number, object, string} from "prop-types"
import {useEffect} from "react"
import {animated, useSpringValue} from "react-spring"

const _onComplete = () => {}

const Counter = ({initial, children, classes, className, onComplete = _onComplete}) => {
  const accrued = useSpringValue(0, {delay: 500})

  useEffect(() => {
    accrued.start(children)
  }, [accrued, children])

  return (
    <animated.span className={className || classes.counter} data-testid="counter">
      {accrued.to(number => Math.ceil(number).toLocaleString())}
    </animated.span>
  )
}

Counter.propTypes = {
  children: node,
  classes: object,
  className: string,
  initial: number,
  onComplete: func,
}

Counter.defaultProps = {
  initial: 0,
}

const styles = theme => ({
  counter: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
})

export default withStyles(styles)(Counter)
