import {array, shape, string} from "prop-types"

import GroupedBarchart from "./grouped-barchart"
import {Metrics, Types, formatValue} from "./insights-helpers"
import {metricHeaders} from "./message-performance-insights-table"

const dataKeys = {
  [Metrics.Total]: {
    messageSent: "Messages Sent",
    emailOpened: "Messages Opened",
    ctaClicked: "Messages Clicked",
  },
  [Metrics.Rate]: {
    emailOpenRate: "Email Open Rate",
    clickThruRate: "Message Click Thru Rate",
    emailClickToOpenRate: "Email Click to Open Rate",
  },
}

const fields = Object.values(metricHeaders)
  .flat()
  .map(header => header.field)

const metricFormatter = metric => value =>
  formatValue(value, metric === Metrics.Rate ? Types.Percentage : Types.Number)

const MessagePerformanceInsightsBarchart = ({messagePerformance, metric}) => {
  const {rows} = messagePerformance
  const isEmpty =
    rows.reduce(
      (acc, cur) =>
        acc +
        Object.entries(cur)
          .filter(([key, value]) => value !== null && fields.includes(key))
          .reduce((jacc, [key, value]) => jacc + value, 0),
      0
    ) < 1

  const labelFormatter = metricFormatter(metric)

  const barData = Object.entries(dataKeys[metric])

  const data = rows.map(messagePerformanceRow => ({
    ...messagePerformanceRow,
    name: messagePerformanceRow.message.contentName,
  }))

  return (
    <GroupedBarchart
      barData={barData}
      data={data}
      isEmpty={isEmpty}
      labelFormatter={labelFormatter}
    />
  )
}

MessagePerformanceInsightsBarchart.propTypes = {
  messagePerformance: shape({
    rows: array,
  }),
  metric: string,
}

export default MessagePerformanceInsightsBarchart
