import {Grid, Typography} from "@mui/material"
import {object} from "prop-types"

import ObjectiveCompletionBenchmarking from "components/advanced-team-insights/objective-completion-benchmarking"
import ObjectiveCompletionRate from "components/advanced-team-insights/objective-completion-rate"
import Expandable from "components/expandable/expandable"

const AdvancedCampaignObjectives = ({classes}) => {
  return (
    <div className={classes.section}>
      <Typography className={classes.header} variant="h5">
        Campaign Objectives
      </Typography>

      <div className={classes.expandableContainer}>
        <Expandable label="How we use this section">
          <div>
            <p>
              The Campaign objectives section shows your objective completion rates over time,
              compares them to your site-wide benchmarks, <em>and</em> reports platform averages for
              all standard objectives.
            </p>

            <Typography variant="h6">At a Glance</Typography>
            <ul>
              <li>
                <b>Objective completion rate by time line chart</b>: View the objective completion
                rate by time line chart by different time segments for reporting and behavioral
                analysis. Hover over the chart’s data labels to read a full list of completion rates
                during that timeframe. Monitor trends over time to see where there are upticks and
                downturns in completion rates.
              </li>
            </ul>

            <ul>
              <li>
                <b>Objective Completion Benchmarking</b>: View current objective completion rates to
                set site-wide benchmarks and the % difference to understand where current rates
                stand and what next steps should be taken to further exceed your benchmarks. Check
                the platform’s top performer (only standard platform objective) to see how your
                benchmark and current rate compare to platform averages.
              </li>
            </ul>

            <Typography variant="h6">Use of Page Filters</Typography>

            <ul>
              <li>
                <b>Date Filter</b>: Easily build reports showcasing increases in objective
                completion over time. Monitor contacts’ behavior over different timeframes to see if
                there are seasonal changes or if your other channels supported an uptick in
                completions (ex. You ran a complimentary in-branch campaign for card on file for 2
                weeks).
              </li>
              <li>
                <b>Objectives</b>: Focus your view on specific objectives.
              </li>
              <li>
                <b>Status</b>: View “Live” campaigns to see how your current campaigns are
                performing.
              </li>
            </ul>
          </div>
        </Expandable>
      </div>

      <Expandable label="Hide section" labelClassName={classes.hideChartLabel} startExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
            <ObjectiveCompletionRate classes={classes} />
          </Grid>
          <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
            <ObjectiveCompletionBenchmarking classes={classes} />
          </Grid>
        </Grid>
      </Expandable>
    </div>
  )
}

AdvancedCampaignObjectives.propTypes = {
  classes: object,
}

export default AdvancedCampaignObjectives
