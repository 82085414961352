import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"
import {array, bool, number, shape, string} from "prop-types"
import React, {useContext, useEffect} from "react"

import DOSelect from "components/do-select/do-select"
import {RewardSetFormContext} from "components/reward/reward-set-form-context-provider"
import {compileValues, isDirty} from "components/reward/reward-set-form-helpers"
import {
  ConditionCard,
  ConditionCardContent,
  Form,
} from "components/reward/reward-step-conditions/styled-components"
import {RewardsContext} from "components/reward/rewards-context-provider"

import {nonEmptyArray, requiredField, validPositiveInteger} from "lib/field-validations"
import useForm from "lib/hooks/use-form"

const CardOnFileAnyMerchants = ({initialValues, isEditable}) => {
  const {dispatch} = useContext(RewardSetFormContext)
  const {templates} = useContext(RewardsContext)

  // Form config
  const {field, inputs} = useForm({
    initialValues: {
      requiredSwitches: !!initialValues.meta.requiredSwitches
        ? initialValues.meta.requiredSwitches
        : undefined,
      anyTemplates: !!initialValues.meta.anyTemplates ? initialValues.meta.anyTemplates : "true",
      requiredTemplates: !!initialValues.meta.requiredTemplates
        ? initialValues.meta.requiredTemplates
        : [],
      value: initialValues.value ? parseInt(initialValues.value) : undefined,
    },
    validators: {
      anyTemplates: [requiredField],
      requiredSwitches: [validPositiveInteger],
      requiredTemplates: [nonEmptyArray],
      value: [validPositiveInteger],
    },
  })

  // When inputs change, dispatch updated step state
  useEffect(() => {
    const {value, ...meta} = compileValues(inputs)
    const updated = {
      data: {
        condition: "card_on_file_any",
        id: initialValues.id,
        meta,
        value,
      },
      isDirty: isDirty(inputs),
      isValid: Object.values(inputs).reduce((acc, fieldObject) => {
        const shouldNotValidate =
          !acc || (inputs.anyTemplates.value === "true" && fieldObject.name === "requiredTemplates")

        if (shouldNotValidate) {
          return acc
        }

        if (
          fieldObject.name === "anyTemplates" &&
          !requiredField(initialValues.meta.anyTemplates)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "requiredSwitches" &&
          !validPositiveInteger(initialValues.meta.requiredSwitches)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (
          fieldObject.name === "requiredTemplates" &&
          !nonEmptyArray(initialValues.meta.requiredTemplates)
        ) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        if (fieldObject.name === "value" && !validPositiveInteger(initialValues.value)) {
          return "error" in fieldObject ? !fieldObject.error : true
        }

        return "error" in fieldObject ? !fieldObject.error : false
      }, true),
    }

    dispatch({type: "SET_REWARD_STEP_FORM", rewardStepForm: updated})
  }, [dispatch, initialValues, inputs])

  return (
    templates?.length && (
      <ConditionCard>
        <ConditionCardContent>
          <Form id={`card-on-file-any-${initialValues.id}`}>
            <Container sx={{paddingTop: "1em"}} />
            <TextField
              disabled={!isEditable}
              label="has made X successful Card on File switches"
              fullWidth={true}
              type="number"
              {...field("requiredSwitches")}
            />

            <Container sx={{paddingTop: "1em"}} />
            <FormLabel>Which templates are eligible?</FormLabel>
            <RadioGroup {...field("anyTemplates", {exclude: ["error", "helperText"]})}>
              <FormControlLabel
                value="true"
                control={<Radio disabled={!isEditable} />}
                label="Any Template"
              />
              <FormControlLabel
                value="false"
                control={<Radio disabled={!isEditable} />}
                label="Specific Templates Only"
              />
            </RadioGroup>

            {inputs.anyTemplates.value === "false" && (
              <>
                <Container sx={{paddingTop: "1em"}} />
                <FormControl fullWidth>
                  <DOSelect
                    disabled={!isEditable}
                    multiple
                    label="Campaigns"
                    {...field("requiredTemplates")}
                    renderValue={templateIds =>
                      templateIds.map(id => templates.find(t => t.id === id).name).join(", ")
                    }
                  >
                    {templates.map(template => (
                      <MenuItem key={template.id} value={template.id}>
                        <Checkbox
                          disabled={!isEditable}
                          checked={inputs.requiredTemplates.value.includes(template.id)}
                          sx={{pointerEvents: "none"}}
                          color={"primary"}
                        />
                        {template.name.length < 32
                          ? template.name
                          : `${template.name.slice(0, 31)} ...`}
                      </MenuItem>
                    ))}
                  </DOSelect>
                </FormControl>
              </>
            )}

            <Container sx={{paddingTop: "1em"}} />
            <TextField
              disabled={!isEditable}
              label="Value"
              fullWidth={true}
              type="number"
              {...field("value")}
            />

            <Container sx={{paddingTop: "1em"}} />
          </Form>
        </ConditionCardContent>
      </ConditionCard>
    )
  )
}

CardOnFileAnyMerchants.defaultProps = {
  initialValues: {
    condition: "card_on_file_any",
    meta: {
      anyMerchants: "true",
      anyTemplates: "true",
      requiredSwitches: undefined,
      requiredTemplates: [],
    },
    value: undefined,
  },
}

CardOnFileAnyMerchants.propTypes = {
  initialValues: shape({
    condition: string,
    id: string.isRequired,
    meta: shape({
      anyMerchants: string,
      anyTemplates: string,
      requiredSwitches: string,
      requiredTemplates: array,
    }),
    value: number,
  }).isRequired,
  isEditable: bool,
}

export default CardOnFileAnyMerchants
