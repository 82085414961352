import {Typography, useTheme} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import PropTypes, {array, string} from "prop-types"
import {Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
  tooltipContent: {
    backgroundColor: "white",
    border: "2px solid #2260c5",
    borderRadius: "3px",
    padding: "10px",
  },
  tooltipValue: {
    color: "#6ba2d3",
  },
}))

const ellipse = (string, num) => (string.length > num ? `${string.substring(0, num)}...` : string)

// The default tooltip doesn't wrap the label text, so we replace it with an imitation that does wrap.
// Thanks to https://recharts.org/en-US/examples/CustomContentOfTooltip
const CustomTooltip = ({active, payload}) => {
  const classes = useStyles()

  if (active && payload && payload.length) {
    const answer = payload[0].payload

    return (
      <div className={classes.tooltipContent}>
        <div>{answer.title}</div>
        <div className={classes.tooltipValue}>Value: {answer.value}</div>
      </div>
    )
  }

  return null
}

const SurveyWidgetBarchart = ({questionTitle, answers}) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography sx={{mb: 3}} variant="h6">
        {questionTitle}
      </Typography>
      <ResponsiveContainer height={320}>
        <BarChart data={answers} margin={{top: 25}} layout="vertical">
          <XAxis type="number" allowDecimals={false} />
          <YAxis
            dataKey="title"
            type="category"
            width={90}
            tickFormatter={title => ellipse(title, 30)}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Bar
            dataKey="value"
            fill={theme.palette.brand.lightBlue}
            isAnimationActive={false}
            maxBarSize={75}
          >
            <LabelList dataKey="value" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

SurveyWidgetBarchart.propTypes = {
  answers: array.isRequired,
  questionTitle: string.isRequired,
}

CustomTooltip.propTypes = {
  active: PropTypes.any,
  payload: array,
}

export default SurveyWidgetBarchart
