// import Box from "@mui/material/Box"
import {Box, Typography} from "@mui/material"
import {styled} from "@mui/material/styles"

export const ConditionCard = styled(Box)({width: "100%"})

export const ConditionCardHeader = styled(Typography)({
  display: "flex",
  justifyContent: "space-between",
  variant: "overline",
})

export const ConditionCardContent = styled("div")({})

export const Form = styled("form")({width: "100%"})
