import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, shape, string} from "prop-types"
import {useState} from "react"

import PluginColorInput from "components/content-block-editor/plugins/helpers/plugin-color-input"

import {validDomainAndTld} from "lib/field-validations"
import useForm from "lib/hooks/use-form"
import {formatUrl} from "lib/string/url-helpers"

import {templatePageContext} from "../../../../contexts/template-page-context"
import FormActions from "../form-actions"

const PluginMeterSettings = ({
  contentBlock,
  content_variables: _content_variables,
  initialValues,
  onSubmit,
  setContentVariables: onUpdateContentVariables,
  team,
  template,
}) => {
  const [content_variables, setContentVariables] = useState(_content_variables)
  const {change, field, handleSubmit, resetForm} = useForm({
    initialValues,
    parse: {url: formatUrl},
    onSubmit: settings => {
      if (onUpdateContentVariables)
        return onUpdateContentVariables(content_variables).then(() => onSubmit(settings))
      else return onSubmit(settings)
    },
    validators: {url: [validDomainAndTld]},
  })
  const theme = template?.theme || team?.themeStyles

  const classes = useStyles()

  const handleResetForm = () => {
    setContentVariables(_content_variables)
    resetForm()
  }

  return (
    <form className={classes.root} aria-label="Progress Ring Settings" onSubmit={handleSubmit}>
      <TextField
        fullWidth={true}
        id={`progress-meter-${contentBlock.slug}-reward`}
        label="Reward"
        helperText="Use %% to insert the percentage completed in the text."
        margin="normal"
        {...field("reward")}
      />
      <TextField
        fullWidth={true}
        id={`progress-meter-${contentBlock.slug}-subtitle`}
        label="Sub Title"
        helperText="Use %% to insert the percentage completed in the text."
        margin="normal"
        {...field("subtitle")}
      />
      <FormControl fullWidth={true} margin="normal">
        <FormLabel htmlFor={`progress-meter-${contentBlock.slug}-resize-type`}>Type</FormLabel>
        <RadioGroup
          row={true}
          id={`progress-meter-${contentBlock.slug}-type`}
          {...field("type", {exclude: ["helperText"]})}
        >
          <FormControlLabel value="circular" control={<Radio />} label="Circular" />
          <FormControlLabel value="linear" control={<Radio />} label="Linear" />
        </RadioGroup>
      </FormControl>

      <PluginColorInput
        theme={theme}
        {...field("color", {defaultValue: theme?.palette?.primary?.main})}
        onChange={hex => change("color", hex)}
      />
      <FormActions resetForm={handleResetForm} />
    </form>
  )
}

PluginMeterSettings.propTypes = {
  contentBlock: shape({
    slug: string.isRequired,
  }).isRequired,
  content_variables: object,
  initialValues: shape({
    iconSrc: string,
  }).isRequired,
  onSubmit: func.isRequired,
  setContentVariables: func,
  team: object,
  template: object,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
  },
}))

export default templatePageContext(PluginMeterSettings)
