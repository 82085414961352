import {Box, TextField, Typography} from "@mui/material"
import {func, number} from "prop-types"
import {useEffect} from "react"

const TargetingSelfServiceInput = ({
  impactCount,
  selfServiceTargetingLimit,
  setSelfServiceTargetingLimit,
  setShowSelfServiceTargetingLimit,
  targetingLimit,
}) => {
  // When targetingLimit is under 100000, round to nearest 1000 + wiggle room
  // When targetingLimit is 100000 and above, round to nearest 1/100th + wiggle room
  const calculateRoundedTargetingLimit = num => {
    const digits = Math.floor(Math.log10(num)) + 1
    const roundedLevel = digits < 6 ? 1000 : 10 ** (digits - 2)
    const nearestRoundedLevel = Math.ceil(num / roundedLevel) * roundedLevel
    const needsWiggleRoom = nearestRoundedLevel - num < roundedLevel / 2
    return needsWiggleRoom ? nearestRoundedLevel + roundedLevel : nearestRoundedLevel
  }

  const roundedTargetingLimit = calculateRoundedTargetingLimit(impactCount)

  useEffect(() => {
    if (impactCount > targetingLimit) {
      setShowSelfServiceTargetingLimit(true)
      setSelfServiceTargetingLimit(roundedTargetingLimit)
    }
  }, [
    impactCount,
    roundedTargetingLimit,
    targetingLimit,
    setShowSelfServiceTargetingLimit,
    setSelfServiceTargetingLimit,
  ])

  const handleChange = e => setSelfServiceTargetingLimit(e.target.value)
  return (
    <Box
      sx={{
        backgroundColor: "callout.light",
        mb: 8,
      }}
    >
      <Typography
        variant={"h4"}
        sx={{
          p: 1,
          mb: 0,
          color: "white",
          fontWeight: "800",
          backgroundColor: "brand.lightGreen",
          borderRadius: 1,
        }}
      >
        The projected impact is over your targeting limit...
      </Typography>
      <Box sx={{p: 1}}>
        <>
          <Typography sx={{paddingTop: "1em"}}>
            Due to the projected impact being greater than your team's targeting limit of{" "}
            <b>{targetingLimit.toLocaleString()}</b>, targeting will be unable to create journeys.
            If the projected impact looks good to you, we recommend raising your team's targeting
            limit to {roundedTargetingLimit.toLocaleString()}, otherwise our targeting engine will
            not be able to create any journeys in this campaign.
          </Typography>
          <form className="targeting-limit-update-form" onChange={handleChange}>
            <TextField
              label="Targeting Limit"
              margin="normal"
              type="number"
              value={selfServiceTargetingLimit}
              helperText="This new targeting limit will be saved when you save your targeting conditions."
            />
          </form>
        </>
      </Box>
    </Box>
  )
}

TargetingSelfServiceInput.propTypes = {
  impactCount: number,
  selfServiceTargetingLimit: number,
  setSelfServiceTargetingLimit: func,
  setShowSelfServiceTargetingLimit: func,
  targetingLimit: number,
}

export default TargetingSelfServiceInput
