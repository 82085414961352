import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Badge,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import {styled} from "@mui/material/styles"
import {arrayOf, bool, func, node, object, shape, string} from "prop-types"
import {Component, Fragment} from "react"
import {MdExpandMore as ExpandIcon, MdHelp as HelpIcon} from "react-icons/md"

import ContainerCssSettings from "components/templates/container-css-settings"
import ContainerStyleSettings from "components/templates/container-style-settings"

import completionConditions, {
  calculateInitialProductValue,
  getCombinedProductList,
} from "lib/completion-conditions"
import {validString} from "lib/field-validations"
import {featurify} from "lib/hooks/use-features"
import {formify} from "lib/hooks/use-form.js"

import {templateContentContext} from "../../contexts/template-content-context"
import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import {layoutBases} from "../content-block-editor/layout-basis"
import DangerButton from "../danger-button/danger-button"
import ConfirmDialog from "../dialogs/confirm-dialog.jsx"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import DOSelect from "../do-select/do-select"
import Feature from "../feature/feature"
import {actionableWidgetTypes} from "../journeys/page-completion-descriptor"
import ObjectiveSelect from "../objective-select/objective-select"
import PageCrossChannelSettings from "./page-cross-channel-settings"
import PageMessageSettings from "./page-message-settings"
import DuplicateConfirmationDialog from "./page-settings/duplicate-confirmation-dialog-LEGACY"
import HiddenBlockNotice from "./page-settings/hidden-block-notice-LEGACY"
import SharedPageNotice from "./page-settings/shared-page-notice-LEGACY"

export const checkPageForActionableWidget = page => {
  const isActionableWidgetPresent =
    page?.contentBlocks?.findIndex(block => actionableWidgetTypes.includes(block.type)) ?? -1

  return isActionableWidgetPresent < 0
}

const SettingsContainer = styled("div")(({theme}) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
}))

const SettingsForm = styled("form")(({theme}) => ({marginBottom: theme.spacing(1)}))

const anyHiddenBlocks = contentBlocks => {
  return contentBlocks.some(contentBlock =>
    Object.values(contentBlock.grid).some(value => value === 0)
  )
}

class PageSettings extends Component {
  state = {
    showDeleteConfirmation: false,
    showDuplicateConfirmation: false,
    expanded: "general",
    completionProduct: calculateInitialProductValue(
      this.props.team,
      this.props.initialValues.completionProductType,
      this.props.initialValues.completionProductId
    ),
  }

  onChangeObjective = objective => this.props.change("objectiveId", objective.id || null)

  onShowDeleteConfirmation = () => {
    this.setState({showDeleteConfirmation: true})
  }

  onHideDeleteConfirmation = () => {
    this.setState({showDeleteConfirmation: false})
  }

  onDeletionConfirmed = () => {
    const {onDeletePage} = this.props

    onDeletePage()

    this.onHideDeleteConfirmation()
  }

  onShowDuplicateConfirmation = () => {
    this.setState({showDuplicateConfirmation: true})
  }

  onHideDuplicateConfirmation = () => {
    this.setState({showDuplicateConfirmation: false})
  }

  onProductCompletionChanges = newValue => {
    this.setState({completionProduct: newValue})
    if (newValue.type === "Products") {
      this.props.change("completionProductId", newValue.value)
    } else if (newValue.type === "Product Types") {
      this.props.change("completionProductType", newValue.value)
    }
  }

  // We need to refetch template messages because this state storage is split --
  // template pages in redux, template messages in the context.
  onDuplicateConfirmed = () =>
    this.props.onDuplicatePage(this.props.page).then(this.props.fetchTemplateMessages)

  onSelectGeneralPanel = () => this.setState({expanded: "general"})

  onSelectStylesPanel = () => this.setState({expanded: "styles"})

  onSelectCssPanel = () => this.setState({expanded: "css"})

  onSelectMessagesPanel = () => this.setState({expanded: "messages"})

  onSelectCrossChannelPanel = () => this.setState({expanded: "crossChannel"})

  renderCompletionCondition = ({primary, secondary, value}) => {
    const {classes, page} = this.props

    return (
      <MenuItem
        classes={{root: classes.menuItem}}
        disabled={value === "action" && checkPageForActionableWidget(page)}
        key={value}
        value={value}
      >
        <ListItemText
          classes={{secondary: classes.listItemTextSecondary}}
          primary={primary}
          secondary={secondary}
        />
      </MenuItem>
    )
  }

  render() {
    const {
      classes,
      containers,
      field,
      scrollableContainer,
      nextPageChildren,
      page,
      initialValues,
      inputs,
      isMissingPageSlugs,
      hasMismatchedObjective,
      hasFeature,
      templateMessages,
      team,
    } = this.props
    const {expanded} = this.state
    const contentBlocks = Object.keys(containers)
      .filter(key => containers[key] && Array.isArray(containers[key].contentBlocks))
      .reduce((acc, key) => acc.concat(containers[key].contentBlocks), [])

    const combinedProductList = getCombinedProductList(team)

    return [
      !!this.props.page.isShared && (
        <Fragment key="sharedNotice">
          <SharedPageNotice
            editingTemplateId={this.props.editingTemplateId}
            onDuplicatePage={this.onDuplicateConfirmed}
            onShowDuplicateConfirmation={this.onShowDuplicateConfirmation}
            page={page}
          />
          <DuplicateConfirmationDialog
            onClose={this.onHideDuplicateConfirmation}
            onDuplicatePage={this.onDuplicateConfirmed}
            open={this.state.showDuplicateConfirmation}
          />
        </Fragment>
      ),

      anyHiddenBlocks(contentBlocks) && (
        <HiddenBlockNotice key="hiddenNotice" contentBlocks={contentBlocks} />
      ),

      <SettingsContainer key="expansions">
        <Accordion
          classes={{root: classes.expansionPanel}}
          data-testid="general-page-settings"
          elevation={0}
          expanded={expanded === "general"}
          key="general"
          onChange={this.onSelectGeneralPanel}
        >
          <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
            <Typography>General</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionContent}}>
            <div>
              <SettingsForm>
                <TextField
                  className={classes.field}
                  FormHelperTextProps={{classes: {root: classes.helperText}}}
                  fullWidth={true}
                  helperText="Sets the internal name of the content which can be used for organizing content"
                  id="page-content-name"
                  label="Content Name"
                  {...field("contentName")}
                />
                <TextField
                  className={classes.field}
                  FormHelperTextProps={{classes: {root: classes.helperText}}}
                  fullWidth={true}
                  helperText="Shown to contacts as a link to the page in the journey"
                  id="page-navigation-title"
                  label="Navigation Title"
                  {...field("navigationTitle")}
                />
                <>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="layoutBasis">Layout Basis</InputLabel>
                    <DOSelect
                      className={classes.field}
                      id="layoutBasis"
                      limitHeight={false}
                      renderValue={value =>
                        layoutBases.find(layoutBasis => layoutBasis.value === value).label
                      }
                      {...field("layoutBasis", {exclude: ["helperText"]})}
                    >
                      {layoutBases
                        .filter(({value}) => value !== "table")
                        .map(layoutBasis => (
                          <MenuItem
                            classes={{root: classes.menuItem}}
                            key={layoutBasis.value}
                            value={layoutBasis.value}
                          >
                            <ListItemText
                              classes={{secondary: classes.listItemTextSecondary}}
                              primary={layoutBasis.label}
                              secondary={layoutBasis.description}
                            />
                          </MenuItem>
                        ))}
                    </DOSelect>
                  </FormControl>
                  <Collapse appear={true} in={page.layoutBasis === "grid"}>
                    <TextField
                      className={classes.field}
                      fullWidth={true}
                      inputProps={{
                        min: 0,
                        max: 100,
                      }}
                      label="Grid Gap"
                      type="number"
                      {...field("styles.gap", {defaultValue: 8})}
                    />
                  </Collapse>
                </>
                <FormControl fullWidth={true} margin="normal">
                  <InputLabel id="page-objective-label">Objective</InputLabel>
                  <ObjectiveSelect
                    labelId="page-objective-label"
                    onChange={this.onChangeObjective}
                    value={initialValues.objectiveId ? {id: initialValues.objectiveId} : ""}
                  />
                </FormControl>
                <Collapse appear={true} in={!!page.objectiveId}>
                  <TextField
                    className={classes.field}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    inputProps={{
                      //eslint-disable-line react/jsx-no-duplicate-props
                      min: 0,
                      max: 100,
                    }}
                    label="Benchmark"
                    type="number"
                    {...field("benchmark")}
                  />
                </Collapse>
                <FormControl fullWidth={true} sx={theme => ({marginTop: theme.spacing(2)})}>
                  <InputLabel htmlFor="completionCondition">Completion Condition</InputLabel>
                  <DOSelect
                    className={classes.field}
                    id="completionCondition"
                    limitHeight={false}
                    renderValue={value =>
                      completionConditions.find(condition => condition.value === value).primary
                    }
                    helperText="Page completion does not cause Objective Completion"
                    {...field("completionCondition", {exclude: ["helperText"]})}
                  >
                    {completionConditions
                      .filter(
                        condition =>
                          hasFeature("product-opening-value-reporting") ||
                          condition.value !== "open"
                      )
                      .map(this.renderCompletionCondition)}
                  </DOSelect>
                </FormControl>
                {inputs.completionCondition?.value === "open" && (
                  <Feature featureKey="product-opening-value-reporting">
                    <Autocomplete
                      options={combinedProductList}
                      groupBy={option => option.type}
                      getOptionLabel={option => option.name}
                      renderInput={params => <TextField {...params} label="Account" />}
                      value={this.state.completionProduct}
                      onChange={(_event, newValue) => {
                        this.onProductCompletionChanges(newValue)
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value}
                    />
                  </Feature>
                )}
                <Collapse appear={true} in={page.completionCondition === "objective"}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          {...field("hideWhenComplete", {
                            bool: true,
                            exclude: ["error", "helperText"],
                          })}
                        />
                      }
                      label={
                        <Tooltip title="Hides the page from the journey navigation if the user has fulfilled the page's Completion Condition. Accessing the page via a direct link or message CTA will still work.">
                          <span>
                            Hide When Complete
                            <span className={classes.switchTooltipIconWrapper}>
                              <HelpIcon className={classes.switchTooltipIcon} />
                            </span>
                          </span>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </Collapse>
              </SettingsForm>
              {nextPageChildren}
              {!!this.props.page.isShared && (
                <Feature featureKey="atomic-assets">
                  <Button fullWidth={true} onClick={this.onShowDuplicateConfirmation} size="small">
                    Make a unique copy
                  </Button>
                </Feature>
              )}
              <DangerButton
                className={classes.field}
                fullWidth={true}
                onClick={this.onShowDeleteConfirmation}
                size="small"
              >
                {hasFeature("atomic-assets") ? "Remove" : "Delete"} Page
              </DangerButton>
              {hasFeature("atomic-assets") ? (
                <ConfirmDialog
                  content="This will remove the page from this campaign, but it will remain active in the content library and other campaigns."
                  key={page.id}
                  onClose={this.onHideDeleteConfirmation}
                  onConfirm={this.onDeletionConfirmed}
                  open={this.state.showDeleteConfirmation}
                />
              ) : (
                <UltraConfirmDialog
                  confirmationText={this.props.page.contentName}
                  key={page.id}
                  onClose={this.onHideDeleteConfirmation}
                  onConfirm={this.onDeletionConfirmed}
                  open={this.state.showDeleteConfirmation}
                  recordType="page"
                />
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          classes={{root: classes.expansionPanel}}
          data-testid="page-style-settings"
          elevation={0}
          expanded={expanded === "styles"}
          onChange={this.onSelectStylesPanel}
        >
          <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
            <Typography>Styles</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionContent}}>
            <ContainerStyleSettings
              styles={page.styles || {}}
              containerType="page"
              onSubmit={styles => this.props.change("styles", styles)}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          classes={{root: classes.expansionPanel}}
          elevation={0}
          expanded={expanded === "css"}
          onChange={this.onSelectCssPanel}
        >
          <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
            <Typography>Custom CSS</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionContent}}>
            <ContainerCssSettings
              initialValues={{css: page.css || ""}}
              onSubmit={({css}) => this.props.change("css", css)}
              containerType="page"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          classes={{root: classes.expansionPanel}}
          elevation={0}
          expanded={expanded === "messages"}
          key="messages"
          onChange={this.onSelectMessagesPanel}
        >
          <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
            <Badge
              color="error"
              invisible={!hasMismatchedObjective && !isMissingPageSlugs}
              variant="dot"
            >
              <Typography>Messages</Typography>
            </Badge>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionContent}}>
            <PageMessageSettings
              key={page.id}
              pageId={page.id}
              scrollableContainer={scrollableContainer}
              templateMessages={templateMessages.filter(tm => tm.templatePage?.pageId === page.id)}
            />
          </AccordionDetails>
        </Accordion>
        {this.props.template.isCceEnabled && (
          <Accordion
            classes={{root: classes.expansionPanel}}
            elevation={0}
            expanded={expanded === "crossChannel"}
            key="crossChannel"
            onChange={this.onSelectCrossChannelPanel}
          >
            <AccordionSummary
              classes={{root: classes.expansionContent}}
              expandIcon={<ExpandIcon />}
            >
              <Badge
                color="error"
                invisible={!hasMismatchedObjective && !isMissingPageSlugs}
                variant="dot"
              >
                <Typography>Cross Channel</Typography>
              </Badge>
            </AccordionSummary>
            <AccordionDetails classes={{root: classes.expansionContent}}>
              <PageCrossChannelSettings
                page={page}
                field={field}
                classes={classes}
                contentContainers={page?.contentContainers || []}
                templateId={this.props.template.id}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </SettingsContainer>,
    ]
  }
}

PageSettings.propTypes = {
  change: func.isRequired,
  classes: object.isRequired,
  containers: object,
  editingTemplateId: string,
  fetchTemplateMessages: func.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  hasFeature: func.isRequired,
  hasMismatchedObjective: bool,
  initialValues: object,
  isMissingPageSlugs: bool,
  nextPageChildren: node,
  onDeletePage: func.isRequired,
  onDuplicatePage: func.isRequired,
  page: shape({
    isShared: bool,
  }).isRequired,
  scrollableContainer: object,
  setContainer: func.isRequired,
  template: object.isRequired,
  templateMessages: arrayOf(
    shape({
      templatePage: shape({
        pageId: string,
      }),
    })
  ),
  team: object,
  inputs: object,
}

export default formify({
  form: "pageSettings",
  enableReinitialize: true,
  autoSubmitOnChange: true,
  autoSubmitDebounceTime: 1000,
  validators: {contentName: [validString], navigationTitle: [validString]},
})(featurify(contentBlockEditorConsumer(templateContentContext(PageSettings))))
