import {every, some} from "lodash"

export const roles = {
  admin: {
    name: "Admin",
    permissions: [
      {name: "team:users", label: "Users management"},
      {name: "templates:support_owner", label: "Set support owner"},
      {
        name: "emails:transactional",
        label: "Make emails transactional",
        featureKey: "transactional-emails",
      },
    ],
  },
  manager: {
    name: "Manager",
    permissions: [
      {name: "templates:launch", label: "Launch campaigns", featureKey: "campaign-approval"},
      {name: "contacts:view", label: "View and export contact data"},
      {name: "contacts:edit", label: "Modify contact data"},
      {name: "contacts:create", label: "Create contacts"},
      {name: "contacts:delete", label: "Delete contact data"},
      {name: "team_settings:general", label: "General team settings"},
      {name: "team_settings:branding", label: "Edit logo and favicon"},
      {name: "journeys:performance", label: "Export journey performance data"},
      {name: "insights:edit", label: "Manage insights"},
      {name: "notifications:receive", label: "Receive Notification Emails"},
      {name: "rewards:manage", label: "Manage Rewards", featureKey: "rewards"},
    ],
  },
  tech: {
    name: "Technical Manager",
    permissions: [
      {name: "team_settings:email", label: "Email settings management"},
      {name: "team_settings:api", label: "API access management"},
      {name: "team_settings:sftp", label: "SFTP settings management"},
      {name: "notifications:manage", label: "Notification Management"},
    ],
  },
  editor: {
    name: "Content Editor",
    permissions: [
      {name: "templates:view", label: "View templates"},
      {name: "templates:edit", label: "Edit templates"},
      {name: "templates:delete", label: "Delete templates"},
      {name: "templates:archive", label: "Archive templates"},
      {name: "targeting_conditions:edit", label: "Modify targeting conditions"},
      {name: "insights:view", label: "View insights"},
    ],
  },
  approver: {
    name: "Approver",
    permissions: [{name: "templates:approve", label: "Approve campaigns for launch"}],
  },
}

export const allPermissions = Object.values(roles)
  .map(r => r.permissions)
  .reduce((acc, pgroup) => [...acc, ...pgroup], []) // flatten :eyeroll:
  .reduce((acc, p) => ({...acc, [p.name]: p.label}), {})

export const getUserRoles = selectedPermissions =>
  Object.keys(roles).reduce((acc, roleKey) => {
    if (isRoleChecked(roleKey, selectedPermissions)) return [...acc, roles[roleKey]?.name]

    if (isRolePartiallyChecked(roleKey, selectedPermissions))
      return [...acc, `${roles[roleKey]?.name} (Limited)`]

    return acc
  }, [])

const isRolePartiallyChecked = (role, selectedPermissions) =>
  some(roles[role].permissions, p => selectedPermissions?.includes(p.name))

export const isRoleChecked = (role, selectedPermissions) =>
  every(roles[role].permissions, p => selectedPermissions?.includes(p.name))
