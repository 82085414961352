// This file was generated automatically from character-21.svg with the following command:
// ./svg-converter.js src/components/superhero/character-21.svg
import SuperheroSvg from "./superhero-svg"

const Character21 = props => (
  <SuperheroSvg viewBox="245 85 100 220" {...props}>
    <g id="Layer_13">
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M300.94,298.65l-0.04-0.02c-0.08-0.03-1.97-0.82-1.97-2.67c0-1.39,1-2.84,3.08-4.42l0.06-0.04
  				c0.02-0.01,2.38-1.22,4.82-3.01c3.16-2.32,5.05-4.54,5.46-6.44l0.7,0.14c-1.1,5.12-10.07,9.82-10.77,10.18
  				c-2.17,1.66-2.63,2.8-2.62,3.46c0,1,1.05,1.66,1.5,1.86c12.12,0.76,22.04-3.54,23.14-4.03c1.3-2.07-0.55-7.99-1.39-10.14
  				l0.66-0.52c0.34,0.86,3.24,8.51,1.16,11.37l-0.07,0.1l-0.09,0.05c-0.1,0.05-10.63,4.96-23.59,4.14L300.94,298.65z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M304.81,298.57l-0.5-0.61c0.79-1.09,1.18-2.18,1.16-3.26c-0.04-2.02-1.51-3.38-1.53-3.39
  				l0.47-0.81c0.07,0.06,1.74,1.59,1.8,4.05C306.23,295.9,305.76,297.25,304.81,298.57z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M305.82,295.56l0.03-0.96c15.52-1.98,18.97-4.87,19-4.9l0.36,0.77
  				C325.07,290.6,321.68,293.54,305.82,295.56z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M305.62,294.25l-0.18-0.89c11.01-5.58,11.09-11.02,11.09-11.07l0.73-0.15
  				C317.27,282.39,317.29,288.34,305.62,294.25z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M307.01,291.59c-0.35-0.09-0.65-0.25-0.8-0.32l0.35-0.9c0.52,0.28,1.16,0.43,1.33,0.31
  				c0-0.01,0-0.02,0-0.05c0.02-0.65,0.03-1.21,0.48-1.55c0.32-0.24,0.78-0.3,1.81-0.42c0.73-0.09,1.25-0.15,1.38-0.34
  				c0.13-0.18,0.15-0.87,0.18-1.82c0.02-0.79,0.22-1.31,0.58-1.55c0.72-0.47,1.65,0.58,1.92,0.91l-0.54,0.74
  				c-0.44-0.54-0.99-0.89-1.13-0.8c0,0-0.09,0.11-0.11,0.61c-0.08,2.7-0.25,2.94-2.32,3.2c-0.74,0.09-1.37,0.17-1.49,0.29
  				c-0.03,0.06-0.03,0.37-0.04,0.64c-0.01,0.37-0.14,0.69-0.36,0.9C307.93,291.74,307.44,291.71,307.01,291.59z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M272.06,298.34l-0.04-0.02c-0.08-0.03-1.95-0.87-1.93-2.72c0.02-1.4,1.04-2.82,3.14-4.35
  				l0.06-0.04c0.02-0.01,2.39-1.16,4.86-2.89c3.19-2.24,5.11-4.42,5.55-6.3l0.7,0.16c-1.17,5.09-10.2,9.56-10.91,9.91
  				c-2.19,1.6-2.66,2.73-2.67,3.39c-0.01,1,1.02,1.69,1.47,1.9c12.1,1.07,22.07-2.97,23.18-3.44c1.33-2.03-0.44-8.01-1.25-10.18
  				l0.67-0.51c0.33,0.87,3.12,8.6,1,11.41l-0.07,0.1l-0.1,0.04c-0.11,0.05-10.69,4.69-23.62,3.53L272.06,298.34z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M275.93,298.36l-0.49-0.63c0.8-1.07,1.21-2.16,1.2-3.23c-0.01-2.02-1.47-3.42-1.48-3.43l0.48-0.8
  				c0.07,0.07,1.72,1.64,1.74,4.1C277.39,295.73,276.9,297.07,275.93,298.36z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M276.97,295.38l0.04-0.95c15.54-1.58,19.02-4.38,19.06-4.41l0.35,0.78
  				C296.28,290.91,292.86,293.76,276.97,295.38z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M276.8,294.06l-0.17-0.9c11.07-5.3,11.24-10.74,11.24-10.79l0.73-0.13
  				C288.6,282.49,288.54,288.45,276.8,294.06z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M278.22,291.43c-0.34-0.1-0.65-0.26-0.79-0.35l0.36-0.89c0.51,0.29,1.16,0.46,1.32,0.34
  				c0-0.01,0-0.02,0-0.05c0.03-0.65,0.05-1.21,0.5-1.54c0.32-0.23,0.79-0.28,1.81-0.38c0.73-0.07,1.25-0.12,1.39-0.3
  				c0.13-0.18,0.16-0.86,0.2-1.81c0.03-0.79,0.24-1.31,0.61-1.54c0.72-0.45,1.64,0.62,1.91,0.96l-0.55,0.73
  				c-0.43-0.55-0.98-0.92-1.12-0.83c0,0-0.09,0.11-0.12,0.61c-0.12,2.7-0.29,2.94-2.37,3.14c-0.74,0.07-1.37,0.13-1.49,0.25
  				c-0.03,0.06-0.04,0.37-0.05,0.64c-0.02,0.37-0.15,0.69-0.38,0.89C279.14,291.61,278.65,291.56,278.22,291.43z"
          />
        </g>
      </g>
      <g>
        <defs>
          <rect
            id="SVGID_00000035513519609194690260000010395584300186471346_"
            x="147.23"
            y="66.67"
            width="194.48"
            height="160.52"
          />
        </defs>
        <clipPath id="15848399-SVGID_00000055689525262040515890000008644888118214890645_">
          <use
            xlinkHref="#SVGID_00000035513519609194690260000010395584300186471346_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#123252",
          }}
          d="M291.32,104.49
  			c0,0-0.79,10.93,3.15,28.15c0,0-1.57,10.08,14.57,12.19c0,0,6.69,4.62,15.75,2.1c0,0,4.72,0,3.54-7.14
  			c-1.25-7.55-0.39-33.62-4.72-41.6"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M258.18,122.78
  			c-0.28-0.19-0.93-0.66-1.54-0.41c-0.67,0.26-1.07,1.29-1.12,1.99c-0.12,1.87,2.21,3.15,9.83,8.74c0.99,0.73,1.85,1.36,3.72,2.73
  			c6.49,4.74,4.37,3.06,5.43,3.88c2.94,2.25,3.48,3,4.7,2.9c0.94-0.08,1.7-0.6,3.15-1.6c0.94-0.65,1.49-1.2,2.58-2.17
  			c1.55-1.38,2.33-2.23,3.87-3.48c2.96-2.41,4.44-2.83,4.77-4.6c0.17-0.95,0-2.28-0.85-2.89c-0.18-0.13-0.97-0.75-3.21,0.14
  			c-2.24,0.88-3.64,2.35-4.38,3.08c-4.51,4.46-8.07,5.12-7.8,6.17c0.29,1.15,4.76,1,4.89,0.23c0.08-0.45-1.39-0.8-4.11-2.71
  			c-0.39-0.28-0.46-0.34-4.14-3.38c-2.61-2.16-3.12-2.57-4.01-3.2c-0.41-0.29-1.6-1.1-3.24-2
  			C261.58,123.35,260.72,124.51,258.18,122.78z"
        />
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <path
            style={{fill: "#9C7862"}}
            d="M315.23,133.74c0.53,1.06,1.06,2.12,1.59,3.18c1.27,2.55,2.54,5.1,3.82,7.64
  				c1.54,3.08,3.08,6.17,4.62,9.25c1.34,2.68,2.68,5.36,4.01,8.04c0.65,1.29,1.26,2.61,1.94,3.89c0.01,0.02,0.02,0.04,0.03,0.05
  				c0.8-1.66,1.6-3.32,2.41-4.98c-2.34,0.25-4.67,0.5-7.01,0.76c-3.69,0.4-7.39,0.8-11.08,1.2c-0.86,0.09-1.72,0.19-2.58,0.28
  				c-0.75,0.08-1.42,0.31-1.97,0.97c-0.48,0.57-0.85,1.52-0.82,2.34c0.06,1.66,1.23,3.48,2.79,3.31c2.34-0.25,4.67-0.5,7.01-0.76
  				c3.69-0.4,7.39-0.8,11.08-1.2c0.86-0.09,1.72-0.19,2.58-0.28c1-0.11,1.88-0.56,2.41-1.64c0.5-1.02,0.51-2.33,0-3.34
  				c-0.53-1.06-1.06-2.12-1.59-3.18c-1.27-2.55-2.54-5.1-3.82-7.64c-1.54-3.08-3.08-6.17-4.62-9.25c-1.34-2.68-2.68-5.36-4.01-8.04
  				c-0.65-1.29-1.27-2.61-1.94-3.89c-0.01-0.02-0.02-0.04-0.03-0.05c-0.74-1.49-2.54-2.16-3.81-1.19
  				C314.97,130.17,314.44,132.14,315.23,133.74L315.23,133.74z"
          />
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M260.68,121.81
  			c2.78,1.98,5.56,3.95,8.34,5.93l-2.9,5.74c-0.4-0.28-0.79-0.56-1.19-0.84c-2.72-1.94-5.45-3.87-8.17-5.81
  			c-0.94-0.34-2.78-0.33-5.35-2.05c-2.74-1.84-3.54-3.66-3.94-6.08c-0.54-3.22,2.28-4.26,2.28-4.26c2.52-0.27,4.02,2.52,4.26,3.26
  			c0,0,3.24-0.43,4,0.84"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#CC5F64",
          }}
          d="M286.77,138.35
  			c2.64-1.71,5.28-3.43,7.92-5.14c0.38-0.25,0.76-0.49,1.14-0.74c0.77-0.5,1.39-1.33,1.63-2.26c0.23-0.91,0.13-2.11-0.36-2.91
  			c-1.02-1.7-3.15-2.46-4.85-1.36l0,0c-2.64,1.71-5.28,3.43-7.92,5.14c-0.38,0.25-0.76,0.49-1.14,0.74L286.77,138.35z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M308.06,87.47
  			c0.56,0,1.12,0.03,1.67,0.09c0.33,14.01,12.24,21.35,14.49,22.61c-1.96,7.56-8.45,13.1-16.16,13.11c-9.26,0-16.77-8.01-16.77-17.9
  			S298.8,87.47,308.06,87.47z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M291.29,105.32
  			c0,7.96,5.24,14.7,11.96,17.04l-1,14.61l6.79,0l0.39-13.79c4.65-0.4,8.77-2.82,11.58-6.43c0.6,0.46,1.33,0.73,2.12,0.73
  			c2.03,0,3.67-1.75,3.67-3.91c0-1.72-1.04-3.17-2.48-3.7c0-0.02,0.01-0.04,0.01-0.06c-0.12-0.06-0.23-0.14-0.35-0.2l-32.49-7.06
  			C291.37,103.45,291.29,104.37,291.29,105.32z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M316.16,100.75
  			l-22.29-4.84c-1.2,2.01-2.03,4.29-2.38,6.75l32.16,6.99C320.27,107.71,317.61,104.57,316.16,100.75z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#EB6669",
          }}
          d="M309.33,126.04
  			l2.92,0.45c5.24,0.76,8.91,5.92,8.2,11.52l-2.57,16.54l-31.87,0l3.17-20.37c0.95-6.46,4.14-9.18,10.79-8.75l3.06,0.2
  			c0,0-0.7,3.66,2.85,4.08S309.24,127.01,309.33,126.04z"
        />
        <polygon
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#EB6669",
          }}
          points="
  			318.35,151.23 286.02,154.54 285.2,159.85 317.04,164.01 		"
        />

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#0D1E36",
          }}
          cx="300.25"
          cy="101.33"
          rx="1.34"
          ry="1.43"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M309.73,87.56
  			c8.48,0.9,15.1,8.53,15.1,17.81c0,1.66-0.22,3.27-0.61,4.8C321.97,108.9,310.06,101.57,309.73,87.56z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M309.76,87.67
  			c0,0-4.64,5.54-14,5.54C295.76,93.21,301.09,86.46,309.76,87.67z"
        />

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#0D1E36",
          }}
          cx="311.71"
          cy="103.37"
          rx="1.34"
          ry="1.43"
        />

        <polygon
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#FFFFFF",
            stroke: "#000000",
            strokeWidth: "0.25",
            strokeMiterlimit: "10",
          }}
          points="
  			253.55,126.9 251.33,126.43 253.28,115.93 255.5,116.39 		"
        />
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M306.49,106.71c-0.14-0.29-0.34-0.56-0.57-0.79c-0.47-0.47-1.08-0.72-1.75-0.62
  					c-0.51,0.08-0.96,0.37-1.35,0.7c-0.15,0.13-0.13,0.38,0,0.51c0.15,0.15,0.36,0.13,0.51,0c0.15-0.13,0.32-0.24,0.5-0.34
  					c0.14-0.07,0.25-0.12,0.43-0.16c0.03-0.01,0.06-0.01,0.1-0.02c-0.08,0.01,0.02,0,0.04,0c0.08,0,0.15,0,0.22,0.01
  					c0.07,0-0.05-0.01,0.02,0c0.03,0.01,0.06,0.01,0.1,0.02c0.06,0.02,0.12,0.04,0.18,0.06c0.05,0.02-0.06-0.03-0.01,0
  					c0.01,0.01,0.02,0.01,0.04,0.02c0.03,0.01,0.05,0.03,0.08,0.04c0.05,0.03,0.1,0.06,0.15,0.1c0.03,0.02,0.07,0.06,0.02,0.01
  					c0.02,0.02,0.05,0.04,0.07,0.06c0.04,0.04,0.09,0.08,0.13,0.12c0.08,0.08,0.14,0.16,0.21,0.24c0.01,0.01-0.05-0.06,0,0
  					c0.01,0.02,0.02,0.03,0.03,0.05c0.03,0.04,0.05,0.07,0.08,0.11c0.05,0.08,0.1,0.16,0.14,0.25c0.08,0.18,0.34,0.22,0.5,0.13
  					C306.54,107.1,306.57,106.89,306.49,106.71L306.49,106.71z"
            />
          </g>
        </g>
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M311.14,100.1c0.99,0.2,1.99,0.41,2.98,0.61c0.14,0.03,0.28,0.06,0.42,0.09
  					c0.38,0.08,0.83-0.11,0.92-0.52c0.09-0.37-0.11-0.84-0.52-0.92c-0.99-0.2-1.99-0.41-2.98-0.61c-0.14-0.03-0.28-0.06-0.42-0.09
  					c-0.38-0.08-0.83,0.11-0.92,0.52C310.53,99.55,310.73,100.01,311.14,100.1L311.14,100.1z"
            />
          </g>
        </g>
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M298.96,97.46c0.98,0.27,1.96,0.55,2.93,0.82c0.14,0.04,0.28,0.08,0.42,0.12
  					c0.38,0.11,0.83-0.13,0.92-0.52c0.09-0.4-0.12-0.81-0.52-0.92c-0.98-0.27-1.96-0.55-2.93-0.82c-0.14-0.04-0.28-0.08-0.42-0.12
  					c-0.38-0.11-0.83,0.13-0.92,0.52C298.34,96.93,298.55,97.35,298.96,97.46L298.96,97.46z"
            />
          </g>
        </g>
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <path
            style={{fill: "#EB6669"}}
            d="M325.19,139.47c-1.61-2.82-3.21-5.63-4.82-8.45c-0.23-0.4-0.46-0.81-0.69-1.21
  				c-0.47-0.82-1.24-1.48-2.12-1.74c-0.85-0.25-1.98-0.14-2.73,0.38c-1.59,1.09-2.3,3.37-1.27,5.17l0,0
  				c1.61,2.82,3.21,5.63,4.82,8.45c0.23,0.4,0.46,0.81,0.69,1.21L325.19,139.47z"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <defs>
            <rect
              id="SVGID_00000098185041322490472880000004077415360799767444_"
              x="152.58"
              y="66.67"
              width="188.96"
              height="160.52"
            />
          </defs>
          <clipPath id="15848399-SVGID_00000034087543684167796390000010075267352924391351_">
            <use
              xlinkHref="#SVGID_00000098185041322490472880000004077415360799767444_"
              style={{overflow: "visible"}}
            />
          </clipPath>
        </g>
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <g>
            <path
              style={{fill: "#0D1B30"}}
              d="M309.15,112.66c-1.81,0.54-3.98-0.13-4.9-0.48c-0.13,0.82-0.24,1.98-0.05,2.72l0,0
  					c0.15,0.6,0.49,1.14,1,1.49c0.34-0.6,0.81-1.1,1.39-1.45l2.15,0.04C308.74,114.97,308.96,114.42,309.15,112.66z"
            />
            <path
              style={{fill: "#FFFFFF"}}
              d="M309.25,111.65c0,0-1.86,0.48-4.82-0.43c0,0-0.09,0.4-0.18,0.96c0.92,0.35,3.09,1.02,4.9,0.48
  					C309.19,112.36,309.22,112.03,309.25,111.65z"
            />
          </g>
          <path
            style={{fill: "#F05C60"}}
            d="M308.65,115.36c0.13-0.3,0.2-0.61,0.22-0.91c-0.56-0.07-1.13-0.01-1.67,0.19
  				c-0.85,0.33-1.54,0.95-1.99,1.75c0.09,0.06,0.18,0.12,0.27,0.17C306.66,117.16,308.08,116.63,308.65,115.36z"
          />
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#123252",
          }}
          d="M295.84,93.35
  			l24.01,6.3c0,0-0.98,8.19,4.92,10.29c0,0,3.93-11.77-7.97-19.86C316.81,90.09,305.29,82.43,295.84,93.35z"
        />
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <path
            style={{
              fill: "#FFFFFF",
              stroke: "#000000",
              strokeWidth: "0.25",
              strokeMiterlimit: "10",
            }}
            d="M259.12,114.15
  				c-0.44,2.32-2.53,3.82-4.65,3.34c-2.13-0.48-3.49-2.75-3.05-5.08l1.26-6.61l7.7,1.74L259.12,114.15z"
          />
          <path
            style={{
              fill: "#EB6669",
              stroke: "#000000",
              strokeWidth: "0.25",
              strokeMiterlimit: "10",
            }}
            d="M258.73,112.18l-0.15,1.66
  				c-0.38,1.98-2.15,3.25-3.96,2.84c-1.81-0.41-2.97-2.35-2.59-4.32C252.02,112.36,256.36,114.57,258.73,112.18z"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)"}}>
          <path
            style={{fill: "#F5F6F5"}}
            d="M249.54,126.04c0,0,3.05-1.87,5.57,1.21L249.54,126.04z"
          />
          <g>
            <path
              d="M249.61,126.17c0.51-0.31,1.15-0.48,1.74-0.53c1.47-0.12,2.73,0.61,3.65,1.72c0.05-0.08,0.1-0.17,0.15-0.25
  					c-1.63-0.35-3.25-0.71-4.88-1.06c-0.23-0.05-0.46-0.1-0.69-0.15c-0.19-0.04-0.27,0.25-0.08,0.29c1.63,0.35,3.25,0.71,4.88,1.06
  					c0.23,0.05,0.46,0.1,0.69,0.15c0.15,0.03,0.24-0.14,0.15-0.25c-0.95-1.15-2.29-1.91-3.81-1.81c-0.66,0.05-1.37,0.22-1.94,0.57
  					C249.3,126.01,249.45,126.27,249.61,126.17z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000055689525262040515890000008644888118214890645_)",
            fill: "#9C7862",
          }}
          d="M258.77,121.84
  			c-0.97,0.18-3.26,0.49-4.88-0.26c0,0-0.81-0.24-1.47-1.17c-0.65-0.93,0.94-2.39,2.55-2.19c0,0,0.64,0.05,0.66-0.27
  			C255.63,117.96,257.03,116.99,258.77,121.84z"
        />
      </g>
      <path
        style={{fill: "#EB6669"}}
        d="M283.32,184.31l-7.84,99.7l52.78-0.74l-9.51-97.73c0.01-0.45,0.02-0.91,0.02-1.36
  		c0-8.28-2.44-15.69-6.29-20.65l0.02-0.17l-26.28-8.22l-2.35,22.89c-0.17,1.14-0.3,2.31-0.38,3.5l-0.21,1.89l0.13,0.03
  		c0,0.24-0.01,0.49-0.01,0.73"
      />
      <polygon
        style={{fill: "#D9D8D6"}}
        points="312.44,146.97 313.92,168.78 282.05,168.19 280.27,146.38 	"
      />
      <path
        style={{fill: "#9C7862"}}
        d="M302.81,164.41c0,0,0.56,0.35,0.42,0.63c-0.15,0.28-1.01,0.67-1.96,0.06
  		c-0.63-0.4-3.48-1.52-5.3,0.44c0,0-1.61,2.75,0.73,4.86c1.76,1.58,3.47,2.44,6.73,1.96c3.05-0.45,4.49-1.73,5.44-2.12
  		c3.3-0.39,6.6-0.79,9.9-1.18c0.48-0.06,0.96-0.11,1.44-0.17l-1.12-6.43c-3.37,0.4-6.74,0.81-10.11,1.21
  		c-0.35,0.04-0.69,0.08-1.04,0.12c-0.72-0.75-2.49-2.41-4.25-2.79c0,0-0.81-0.31-1.86,0.06S301.35,163.58,302.81,164.41z"
      />
      <ellipse style={{fill: "#FFFFFF"}} cx="296.66" cy="157.14" rx="2.13" ry="2.27" />
      <g>
        <ellipse style={{fill: "#5198D2"}} cx="322.52" cy="116.39" rx="0.41" ry="0.57" />
        <path
          style={{fill: "none", stroke: "#5198D2", strokeWidth: "0.75", strokeMiterlimit: "10"}}
          d="M322.52,116.39v4.57l-0.41,0.86
  			c0,0,0,0.57,0.41,0.57c0.41,0,0.41-0.57,0.41-0.57l-0.41-1.14"
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Character21
