import {Typography} from "@mui/material"
import {keyframes, styled} from "@mui/material/styles"

const swipeNotifierAnimation = keyframes`
  0%{opacity: 1}
  100%{opacity: 0}
`

const swipNotifierSvg = keyframes`
  0%{transform: translateX(0px);}
  10%{transform: translateX(-10px);}
  20%{transform: translateX(0px);}
  70%{transform: translateX(0px);}
  80%{transform: translateX(10px);}
  90%{transform: translateX(0px);}
`

const NotifierDiv = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  animation: ${swipeNotifierAnimation} 3s 1;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  & .content {
    background: #000b;
    color: #eee;
    text-align: center;
    width: 75vw;
    border-radius: 10px;
    font-size: 8rem;
  }

  & .content p {
    color: #eee;
    font-size: 1rem;
    line-height: 2rem;
    margin: 2rem 0;
  }

  & svg {
    vertical-align: middle;
    animation: ${swipNotifierSvg} 2s 3;
  }
`

export default function SwipeNotifier() {
  return (
    <NotifierDiv>
      <div className="content">
        <svg
          fill="currentColor"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 40 40"
          width="1em"
        >
          <path d="M-1-1h7v7h-7z" fill="none" />
          <path d="M16.735 11.523q-.92 0-1.535.614t-.69 1.536v15.35l-2.533-3.453q-.768-.92-1.843-.92-.92 0-1.535.613t-.615 1.612q0 .69.384 1.305l6.6 8.75q.69.844 1.765.844h12.28q.385 0 .692-.23t.384-.537l1.613-6.294q.384-1.69.384-3.378V23.65q0-.69-.46-1.227t-1.23-.537-1.15.537-.46 1.15h-.538V22.5q0-.845-.538-1.382t-1.382-.614q-.768 0-1.382.614t-.537 1.305v1.15h-.536V22.04q0-1-.69-1.613t-1.536-.69q-.92 0-1.535.613t-.614 1.536v1.688h-.615V13.75q0-.922-.614-1.613t-1.535-.614zm0-2.226q1.843 0 3.07 1.305t1.306 3.147v3.837q.384-.077.538-.077 1.69 0 2.993 1.23.77-.385 1.69-.385 1.92 0 3.147 1.458.46-.077.92-.077 1.613 0 2.764 1.152t1.076 2.763v3.685q0 1.996-.46 3.838l-1.536 6.37q-.307 1.075-1.228 1.766t-1.996.69h-12.28q-1.075 0-1.996-.46t-1.535-1.305l-6.6-8.75q-.846-1.152-.846-2.61 0-1.842 1.304-3.147t3.07-1.305q1.23 0 2.226.614v-9.364q0-1.843 1.228-3.07t3.147-1.306z" />
          <g>
            <path d="M7.027 19.832C6.953 20 6.805 20 6.73 20s-.22 0-.37-.168l-4.587-5.725s-.148-.253-.148-.42.074-.338.148-.422S6.36 7.54 6.36 7.54c.15-.253.52-.253.667 0s.222.59 0 .757L3.18 13.18h10.313v1.01H3.18l3.847 4.884c.11.084.167.23.167.38 0 .036-.004.072-.01.108-.008.036-.018.07-.032.104-.014.033-.03.064-.052.092-.02.028-.045.053-.073.074zM26.153 7.456c.074-.168.222-.168.296-.168s.22 0 .37.168l4.587 5.725s.148.254.148.422-.074.337-.148.42-4.588 5.726-4.588 5.726c-.15.253-.52.253-.667 0s-.222-.59 0-.758L30 14.107H19.688v-1.01H30l-3.847-4.883c-.11-.085-.166-.232-.166-.38 0-.036.003-.073.01-.11.007-.035.017-.07.03-.102.015-.033.032-.064.053-.092.02-.028.045-.053.073-.074z" />
          </g>
        </svg>
        <Typography>Swipe left or right to navigate.</Typography>
      </div>
    </NotifierDiv>
  )
}
