import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {MdCode as HtmlIcon} from "react-icons/md"
import shortid from "shortid"

const HtmlButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {code: "", css: "", js: ""},
      type: "html",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <HtmlIcon />
      </ListItemIcon>
      <ListItemText primary="HTML" ref={ref} />
    </MenuItem>
  )
})

HtmlButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func,
}

export {HtmlIcon}

export default HtmlButton
