import cx from "classnames"
import {node, object, string} from "prop-types"

import {journeyContext} from "components/journeys/journey-context"

// Ugh... because our font-size is customly larger, the icons also scaled up and
// look goofy. This tones them down a bit while still enabling them to get
// larger if the user specifies a theme that is larger than ours.
//
// If you're wondering why we're actually making this fontSize larger than the
// theme's fontSize, it's something to do with with using rems and the fontSize
// of the theme being different than the fontSize of actual components ¯\_(ツ)_/¯

const MuiIcon = ({icon, className, themeClasses}) => (
  <span className={cx("mui-icon", className, themeClasses && themeClasses.muiIconWrapper)}>
    {icon}
  </span>
)

MuiIcon.propTypes = {
  className: string,
  icon: node.isRequired,
  themeClasses: object,
}

export default journeyContext(MuiIcon)
