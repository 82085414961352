import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material"
import {sortBy} from "lodash/collection"
import {Fragment, useEffect, useReducer, useRef, useState} from "react"
import {Link} from "react-router-dom"

import {createSplitTestingGroup, fetchObjectives} from "lib/api"

import {
  fetchCardOnFileTargetingValues,
  fetchContentBlocks,
  fetchSplitTestingGroups,
  fetchTemplateTargeting,
  fetchTemplates,
  updateTemplate,
} from "../../lib/api.js"
import Box from "../box/box"
import SplitTestingGroupCreateDialog from "../dialogs/split-testing-group-create-dialog"
import WarningDialog from "../dialogs/warning-dialog"
import DOSelect from "../do-select/do-select"
import Feature from "../feature/feature"
import IconTooltip from "../icon-tooltip/icon-tooltip"
import SaveButton from "../save-button/save-button"
import {useTemplateContext} from "../template-router/template-context"
import TargetingGroup from "./targeting-group"
import TargetingImpact from "./targeting-impact.jsx"
import {targetingStyles} from "./targeting-styles"
import TargetingWelcome from "./targeting-welcome"
import {provideTemplateTargeting, useTemplateTargeting} from "./template-targeting-context"
import {
  FORM_STATES,
  TARGETING_IMPACT,
  atomizeName,
  humanizeAtomizedNames,
  targetingImpactFormState,
  uniqueBy,
} from "./template-targeting-helpers"
import modalReducer, {
  modalInitState,
  openGroupModal,
  openOverwriteModal,
  openUpdateModal,
} from "./template-targeting-modal-reducer"

const TemplateTargeting = () => {
  const {template: globalTemplate} = useTemplateContext()
  const {
    addTargetingGroup,
    formState,
    isLoading,
    isTargetingPriority,
    saveTargeting,
    setFormState,
    setIsLoading,
    setIsTargetingPriority,
    setJourneyCreationStrategy,
    setTargetingGroupLogicalOperator,
    setTargetingGroups,
    setTargetingImpact,
    subscribeToImpactUpdates,
    journeyCreationStrategy,
    targetingGroupLogicalOperator,
    targetingGroups,
    targetingImpact,
  } = useTemplateTargeting()
  const [template, setTemplate] = useState({})
  const [isSubmittingGroups, setIsSubmittingGroups] = useState(false)
  const [modalState, dispatchModal] = useReducer(modalReducer, modalInitState)
  const [groups, setGroups] = useState([])
  const [groupId, setGroupId] = useState("")
  const [cardOnFileTargetingValues, setCardOnFileTargetingValues] = useState({
    merchants: [],
    failureReasons: [],
  })
  const [enrollmentContentBlocks, setEnrollmentContentBlocks] = useState([])
  const [surveyContentBlocks, setSurveyContentBlocks] = useState([])
  const [surveyQuestions, setSurveyQuestions] = useState([])
  const [objectives, setObjectives] = useState([])
  const [templates, setTemplates] = useState([])

  // NB: This submitButton ref exists to serve rendering for ChromicPDF.
  // We use it to check whether the submitButton is disabled or not, so we
  // can click to retrieve the impact results.
  const submitButton = useRef(null)

  const classes = targetingStyles()

  const saveConditions = () => saveTargeting(template, groups)

  const onSaveSplitTestingGroup = async () => {
    if (!template.id) return

    const splitTestingGroup = groups.find(group => group.id === groupId)

    /**
     * Check if we are adding a template to a group that already has a leader (not including current template)
     * or removing a template from a group
     *
     * Note: Will need to fetch leader's conditions
     */
    if (
      (splitTestingGroup &&
        splitTestingGroup.templates &&
        splitTestingGroup.templates.filter(t => t.id !== template.id).length > 0) ||
      (!groupId && template.splitTestingGroupId)
    ) {
      dispatchModal(openOverwriteModal(true))
      return
    }

    await saveSplitTestingGroup()
  }

  const saveSplitTestingGroup = async () => {
    setIsSubmittingGroups(true)

    try {
      const updatedTemplate = await updateTemplate(template.id, {splitTestingGroupId: groupId})
      const updatedGroups = await fetchSplitTestingGroups()
      const selectedGroup = updatedGroups.find(g => g.id === groupId)
      const templateLeader = selectedGroup
        ? selectedGroup.templates.find(t => t.isSplitTestingGroupLeader)
        : updatedTemplate
      const {targetingGroups: newTargetingGroups} = await fetchTemplateTargeting(templateLeader.id)

      setTemplate(updatedTemplate)
      setGroups(updatedGroups)
      setIsTargetingPriority(templateLeader.isTargetingPriority)
      setTargetingGroupLogicalOperator(template.targetingGroupLogicalOperator)
      setTargetingGroups(newTargetingGroups)
      setIsSubmittingGroups(false)
    } catch {
      setFormState(FORM_STATES.ERROR)
      setIsSubmittingGroups(false)
    }
  }

  const onChangeSplitTestingGroup = ({target: {value}}) => {
    if (value === "custom") dispatchModal(openGroupModal(true))
    else setGroupId(value)
  }

  const onAddSplitTestingGroup = async (group, success, failure) => {
    try {
      const newGroup = await createSplitTestingGroup({...group})

      setGroups([...groups, newGroup])
      setGroupId(newGroup.id)
      onCloseSplitTestingGroup()
      success()
    } catch {
      failure()
    }
  }

  const onCloseSplitTestingGroup = () => {
    dispatchModal(openGroupModal(false))
  }

  const onContinueOverwriteWarning = async () => {
    await saveSplitTestingGroup()
    dispatchModal(openOverwriteModal(false))
  }

  const onCloseOverwriteWarning = () => dispatchModal(openOverwriteModal(false))

  const onContinueUpdateWarning = () => {
    saveConditions().then(() => dispatchModal(openUpdateModal(false)))
  }

  const onCloseUpdateWarning = () => dispatchModal(openUpdateModal(false))

  useEffect(() => {
    if (!globalTemplate) return
    setTemplate(globalTemplate)
    setIsTargetingPriority(globalTemplate.isTargetingPriority)
    setJourneyCreationStrategy(globalTemplate.journeyCreationStrategy)
    setTargetingGroupLogicalOperator(globalTemplate.targetingGroupLogicalOperator)
    subscribeToImpactUpdates(globalTemplate.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTemplate])

  useEffect(() => {
    if (!template || !template.id) return

    setIsLoading(true)
    fetchSplitTestingGroups().then(groups => {
      const selectedGroup = groups.find(group => group.id === template.splitTestingGroupId)

      setGroups(groups)
      setGroupId(selectedGroup ? selectedGroup.id : "")

      // If a part of a group, grab template leader's conditions
      if (selectedGroup) {
        const templateLeader = selectedGroup.templates.find(t => t.isSplitTestingGroupLeader)

        setIsTargetingPriority(templateLeader.isTargetingPriority)
        fetchTemplateTargeting(templateLeader.id).then(
          ({targetingGroups: newTargetingGroups, targetingImpact}) => {
            setIsLoading(false)
            setTargetingGroups(newTargetingGroups)
            setTargetingImpact(targetingImpact)
            const targetingFormState = targetingImpactFormState(targetingImpact)
            setFormState(targetingFormState ? targetingFormState : FORM_STATES.CLEAN)
          }
        )
      }
    })

    // If not part of a group, then get conditions as normal
    if (!template.splitTestingGroupId) {
      setIsTargetingPriority(template.isTargetingPriority)
      fetchTemplateTargeting(template.id).then(({targetingGroups, targetingImpact}) => {
        setIsLoading(false)
        setTargetingGroups(targetingGroups)
        setTargetingImpact(targetingImpact)
        const targetingFormState = targetingImpactFormState(targetingImpact)
        if (targetingFormState) {
          setFormState(targetingFormState)
        } else if (targetingGroups.length === 0) {
          setFormState(FORM_STATES.PRISTINE)
        } else {
          setFormState(FORM_STATES.CLEAN)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template])

  useEffect(() => {
    fetchContentBlocks({type: "enrollment"}).then(contentBlocks => {
      setEnrollmentContentBlocks([
        {
          name: "any enrollment widget",
          value: "any",
          priority: true,
        },
        ...contentBlocks
          .map(({id, data}, i) => ({
            name: humanizeAtomizedNames(data?.name) ?? id,
            value: id,
            priority: i <= 4,
          }))
          .reduce(uniqueBy("name"), []),
      ])
    })
  }, [])

  useEffect(() => {
    fetchContentBlocks({type: "survey"}).then(contentBlocks => {
      setSurveyContentBlocks([
        {
          atomizedName: atomizeName("any survey widget"),
          name: "any survey widget",
          value: "any",
          priority: true,
        },
        ...contentBlocks
          .map(({id, data}, i) => ({
            contentBlockId: id,
            atomizedName: atomizeName(data.name),
            name: humanizeAtomizedNames(data.name),
            value: id,
            priority: i <= 4,
          }))
          .reduce(uniqueBy("name"), []),
      ])

      const formatAnswers = answers => {
        // NB: filter null `answers` and any answer with a null `title`
        // See https://trello.com/c/kwTMy5oC
        if (!answers) return []
        return answers
          .filter(({title}) => !!title)
          .map(answer => ({
            ...answer,
            atomizedName: atomizeName(answer.title),
            name: humanizeAtomizedNames(answer.title),
          }))
          .reduce(uniqueBy("name"), [])
      }

      setSurveyQuestions([
        {
          atomizedName: atomizeName("entire survey"),
          name: "entire survey",
          value: "any",
          priority: true,
        },
        ...contentBlocks
          .flatMap(({id, data}, i) =>
            // NB: Filtering out `null` titles until we require survey questions `title` to be set
            // via validation
            // See https://trello.com/c/AFCu0x82
            data.questions
              .filter(({title}) => !!title)
              .map(({answers, slug, title, type}) => ({
                contentBlockId: id,
                atomizedContentBlockName: atomizeName(data.name),
                contentBlockName: humanizeAtomizedNames(data.name),
                atomizedName: atomizeName(title),
                name: humanizeAtomizedNames(title),
                answers: formatAnswers(answers),
                type,
                value: slug,
                priority: i <= 4,
              }))
          )
          .reduce(uniqueBy(["contentBlockName", "name"]), []),
      ])
    })
  }, [])

  useEffect(() => {
    fetchCardOnFileTargetingValues().then(setCardOnFileTargetingValues)
  }, [])

  useEffect(() => {
    fetchObjectives().then(objectives => {
      setObjectives(
        objectives.map(({key, name}, i) => ({
          name,
          value: key,
          priority: i <= 4,
        }))
      )
    })
  }, [])

  useEffect(() => {
    fetchTemplates({sortColumn: "name", sortDirection: "asc", includeArchived: true}).then(
      _templates => {
        setTemplates([
          {
            name: "any campaign",
            value: "any",
            priority: true,
            isArchived: false,
          },
          ..._templates.map(({id, name, isArchived, type}, i) => ({
            name,
            value: id,
            priority: i <= 4,
            isArchived,
            type,
          })),
        ])
      }
    )
  }, [])

  // NB: This useEffect exists to serve rendering for ChromicPDF.
  // ready-to-continue lets us know when the Get Impact button
  // is no longer disabled, so that we can click to retrieve
  // the impact results.
  useEffect(() => {
    if (submitButton.current?.disabled === false)
      document.getElementById("root")?.setAttribute("ready-to-continue", "true")
  }, [submitButton.current?.disabled])

  // NB: This useEffect exists to serve rendering for ChromicPDF,
  // ready-to-print lets us know when the impact results have
  // been fetched and impact has errored, so ChromicPDF can
  // take a screenshot. This should help prevent timeouts in
  // case of an error.
  useEffect(() => {
    if (formState === FORM_STATES.ERROR)
      document.getElementById("root")?.setAttribute("ready-to-print", "true")
  }, [formState])

  if (!template?.id) return null

  const splitTestingGroup = groups.find(group => group.id === template.splitTestingGroupId)
  const overwriteWarningMessage =
    !groupId && splitTestingGroup
      ? `This will remove ${template.name} from ${splitTestingGroup.name} and all of it's current targeting conditions.`
      : `This will overwrite ${template.name}'s current targeting conditions.`

  if (formState === FORM_STATES.PRISTINE) return <TargetingWelcome />

  const showTargetingPrioritySwitch = template.type !== "tactical"

  const disableTargetingConditionControls =
    isLoading ||
    [TARGETING_IMPACT.AVAILABLE, TARGETING_IMPACT.COMPLETED, TARGETING_IMPACT.EXECUTING].includes(
      targetingImpact?.state
    )

  const disableTargetingStrategyDropdown =
    disableTargetingConditionControls ||
    template?.analytics?.analytics?.journeysTotal > 0 ||
    targetingGroups.filter(g => !g.isNew).length > 0

  // NB: .preview-wrapper exists to serve rendering for ChromicPDF
  return (
    <>
      <Box className="preview-wrapper">
        <WarningDialog
          isOpen={modalState.isOverwriteWarningOpen}
          message={overwriteWarningMessage}
          onClose={onCloseOverwriteWarning}
          onContinue={onContinueOverwriteWarning}
          title="Warning"
        />
        <WarningDialog
          isOpen={modalState.isUpdateWarningOpen}
          message={`This will update all other template targeting conditions in this split testing group.`}
          onClose={onCloseUpdateWarning}
          onContinue={onContinueUpdateWarning}
          title="Warning"
        />
        <SplitTestingGroupCreateDialog
          isOpen={modalState.isGroupDialogOpen}
          onAdd={onAddSplitTestingGroup}
          onClose={onCloseSplitTestingGroup}
        />
        <Typography gutterBottom={true}>
          If you want contacts to automatically be added to this campaign when they are added to the
          system, you can add conditions here to define what type of contact to target.
        </Typography>
        <form className={classes.rootTargetingControls}>
          <Typography component="div" className={classes.journeyCreationStrategy}>
            In the event that multiple accounts match the conditions,{" "}
            <DOSelect
              onChange={({target}) => setJourneyCreationStrategy(target.value)}
              value={journeyCreationStrategy}
              disabled={disableTargetingStrategyDropdown}
            >
              {template.type === "tactical" && (
                <MenuItem value="ONE_FOR_ONE">
                  <span>
                    create <b>separate journeys</b> for each matching account.
                  </span>
                </MenuItem>
              )}
              <MenuItem value="ONE_FOR_ALL">
                <span>
                  create <b>a single journey</b> and group any matching accounts.
                </span>
              </MenuItem>
              <MenuItem value="NO_JOURNEY">don't create a journey.</MenuItem>
            </DOSelect>
            <IconTooltip
              iconClassName={classes.tooltip}
              title={
                template.status === "live"
                  ? "You can't edit this setting because the campaign is already live."
                  : "When your targeting conditions describe accounts we will automatically associate the account that matched with the journey that's created."
              }
            />
          </Typography>
          <RadioGroup
            name="status"
            onChange={({target}) => setTargetingGroupLogicalOperator(target.value)}
            row={true}
            value={targetingGroupLogicalOperator}
          >
            <FormControlLabel
              control={<Radio classes={{root: classes.radioButtonRoot}} color="primary" />}
              disabled={targetingGroups.length < 2}
              label="Match ALL of the groups below"
              labelPlacement="end"
              value="AND"
            />
            <FormControlLabel
              control={<Radio classes={{root: classes.radioButtonRoot}} color="primary" />}
              disabled={targetingGroups.length < 2}
              label="Match ANY of the groups below"
              labelPlacement="end"
              value="OR"
            />
          </RadioGroup>
          <div>
            {showTargetingPrioritySwitch && (
              <FormControlLabel
                className={classes.isTargetingPriorityLabel}
                control={
                  <Switch
                    color="primary"
                    checked={isTargetingPriority}
                    disabled={disableTargetingConditionControls}
                    onChange={({target}) => setIsTargetingPriority(target.checked)}
                  />
                }
                label="This is a priority campaign"
              />
            )}
            <IconTooltip
              iconClassName={classes.tooltip}
              title={
                "Priority campaigns will always be sent to contacts if they match the targeting conditions of the campaign, regardless of other campaigns the contact may be in."
              }
            />
          </div>
        </form>
        {targetingGroups.map((targetingGroup, i) => (
          <Fragment key={targetingGroup.id}>
            <TargetingGroup
              {...targetingGroup}
              cardOnFileTargetingValues={cardOnFileTargetingValues}
              enrollmentContentBlocks={enrollmentContentBlocks}
              objectives={objectives}
              surveyContentBlocks={surveyContentBlocks}
              surveyQuestions={surveyQuestions}
              templates={templates}
              disabled={disableTargetingConditionControls}
            />
            {i + 1 !== targetingGroups.length && (
              <div className={classes.logicalOperator}>
                <span className={targetingGroupLogicalOperator.toLowerCase()}>
                  {targetingGroupLogicalOperator}
                </span>
              </div>
            )}
          </Fragment>
        ))}
        <div className={classes.addTargetingGroup}>
          <Button
            color="primary"
            disabled={isLoading || disableTargetingConditionControls}
            onClick={addTargetingGroup}
          >
            Add new group of conditions
          </Button>
        </div>
        <TargetingImpact dispatchModal={dispatchModal} groups={groups} template={template} />
      </Box>
      <Feature featureKey="split-testing">
        <Box className={classes.secondBox}>
          <Typography variant="h5">Split Testing Group</Typography>
          <Typography variant="subtitle1">
            You can add this campaign to a split testing group to evenly distribute contacts between
            campaigns in the group. If a campaign is added to a split testing group, all campaigns
            in the group will use the same targeting conditions.
          </Typography>
          <FormControl fullWidth={true} style={{maxWidth: 300}}>
            <InputLabel htmlFor="testingGroupId">Testing Group</InputLabel>
            <DOSelect id="testingGroupId" onChange={onChangeSplitTestingGroup} value={groupId}>
              <MenuItem value={""}>None</MenuItem>
              <Divider />
              <ListSubheader classes={{root: classes.listSubheader}}>Group</ListSubheader>
              {groups.map(({id, name}) => (
                <MenuItem key={`split-testing-group-menu-item-${id}`} value={id}>
                  {name}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem component="em" value="custom">
                New Split Testing Group...
              </MenuItem>
            </DOSelect>
          </FormControl>
          <div style={{marginBottom: 8}}>
            <div className={classes.groupButtonContainer}>
              {splitTestingGroup &&
                splitTestingGroup.templates &&
                sortBy(splitTestingGroup.templates, t => t.id === template.id).map(({id, name}) => (
                  <Button
                    component={Link}
                    disabled={id === template.id}
                    key={`split-testing-group-template-button-${id}`}
                    to={`/admin/templates/${id}/targeting`}
                    color="grey"
                    variant="outlined"
                  >
                    {name}
                  </Button>
                ))}
            </div>
            <Typography variant="caption">
              When a contact matches the conditions specified above they will be added to one of the
              campaigns listed in this group.
            </Typography>
            <div className={classes.actions}>
              <SaveButton
                disabled={isLoading}
                failed={formState === FORM_STATES.ERROR}
                onClick={onSaveSplitTestingGroup}
                submitting={isSubmittingGroups}
              />
            </div>
          </div>
        </Box>
      </Feature>
    </>
  )
}

export default provideTemplateTargeting(TemplateTargeting)
