import {Button, Link, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object} from "prop-types"

import {triggerDownload} from "../../csv-download-button/csv-download-button"
import {accountFields} from "../../teams/csv-processing-settings/csv-processing-helpers"

const AccountsBatchUploadPanel = ({classes, onContinue}) => {
  // Build template with certain cols to the left for visibility
  const createCsvContent = () => {
    const forVisibility = ["account_number", "contact.unique_id", "product.code", "product.type"]

    const fields = accountFields.reduce((acc, field) => {
      if (!forVisibility.includes(field.value)) {
        return [...acc, field.value]
      }

      return acc
    }, [])

    return Promise.resolve([[...forVisibility, ...fields]])
  }

  const downloadCsvTemplate = () => {
    triggerDownload(createCsvContent, "batch-account-upload.csv")
  }

  const onFileUpload = ({target: {files}}) => {
    if (files[0]) {
      onContinue({
        file: files[0],
        batchType: "account",
      })
    }
  }

  return (
    <>
      <Typography className={classes.sectionHeader} variant="h2">
        Accounts
      </Typography>
      <Typography className={classes.helperText}>
        Use this to upload account information for existing contacts in the system. This route will
        not create new contacts. Instead, it simplifies getting related account information for
        contacts into the system for use in targeting and personalization.
      </Typography>
      <input
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="accounts-csv-file"
        name="batchUploadAccountsCSV"
        onChange={onFileUpload}
        style={{display: "none"}}
        type="file"
      />
      <label className={classes.uploadButton} htmlFor="accounts-csv-file">
        <Button className={classes.button} color="primary" component="span" variant="contained">
          Upload Accounts
        </Button>
      </label>
      <br />
      <Link
        href="https://support.digitalonboarding.com/support/solutions/articles/48001186845-csv-file-import-specifications"
        target="_blank"
      >
        Need help creating a file?
      </Link>{" "}
      Or want to <Link onClick={downloadCsvTemplate}>download a csv template?</Link>
    </>
  )
}

AccountsBatchUploadPanel.propTypes = {
  classes: object.isRequired,
  onContinue: func.isRequired,
}

const styles = theme => ({
  button: {
    marginBottom: 10,
    marginRight: 10,
    marginTop: theme.spacing(8),
  },
  uploadButton: {
    display: "flex",
    justifyContent: "center",
  },
  helperText: {
    display: "block",
    marginBottom: 5,
    minHeight: 150,
  },
  sectionHeader: {
    fontSize: theme.typography.fontSize * 2,
    marginBottom: theme.spacing(3),
  },
})

export default withStyles(styles)(AccountsBatchUploadPanel)
