import {TableCell, TableRow, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import get from "lodash/get"
import {arrayOf, func, object, shape, string} from "prop-types"
import {Fragment, PureComponent, createRef} from "react"
import {FaChevronRight as ChevronRightIcon, FaStreetView as ContactsIcon} from "react-icons/fa"
import {MdClose as DeleteIcon} from "react-icons/md"
import {Link} from "react-router-dom"

import {fullName} from "lib/names"

import AccessControlled from "../access-control/access-controlled"
import Box from "../box/box"
import DangerButton from "../danger-button/danger-button"
import UltraConfirmDialog from "../dialogs/ultra-confirm-dialog"
import DocumentTitle from "../document-title/document-title"
import Padded from "../padded/padded"
import DOTable from "../table/table"
import TitleBar from "../title-bar/title-bar"
import ActionHistory from "./action-history"
import RewardProgress from "./reward-progress"
import UrlBox from "./url-box"

class JourneysShow extends PureComponent {
  state = {isDeleteDialogOpen: false}

  componentDidMount = () => {
    const {getJourney, journeyId} = this.props

    getJourney(journeyId)
  }

  componentWillUnmount() {
    this.props.clearJourney()
  }

  surveyAnswers = createRef()
  onOpenDeleteDialog = () => this.setState({isDeleteDialogOpen: true})
  onCloseDeleteDialog = () => this.setState({isDeleteDialogOpen: false})

  onUpdateJourney = attrs => {
    const {journey, onUpdateJourney} = this.props

    onUpdateJourney(journey.templateId, journey.id, attrs)
  }

  onDeleteJourney = () => {
    const {journey, onDeleteJourney} = this.props

    onDeleteJourney(journey.templateId, journey.id)
  }

  scrollToSurveyAnswers = () => this.surveyAnswers.current.scrollIntoView({behavior: "smooth"})

  renderSurveyAnswer = field => {
    const answer = field.answer
    const answers = field.answers

    if (answer) return answer
    else if (Array.isArray(answers)) return answers.join(", ")
    else return <i>not answered</i>
  }

  renderSurveyResults = () => {
    const {classes, journey} = this.props
    const surveys = get(journey, ["analyticsCache", "survey_completed"], [])

    if (surveys.length < 1) return null

    return (
      <Padded>
        <Box aria-labelledby="survey-answers-header" component="section">
          <Typography id="survey-answers-header" variant="h4">
            Survey Answers
          </Typography>
          <dl className={classes.surveyAnswers} ref={this.surveyAnswers}>
            {surveys.map(survey =>
              get(survey, ["meta", "fields"], []).map(field => (
                <Fragment key={field.questionSlug}>
                  <dt>{field.questionTitle}</dt>
                  <dd>{this.renderSurveyAnswer(field)}</dd>
                </Fragment>
              ))
            )}
          </dl>
        </Box>
      </Padded>
    )
  }

  render() {
    const {classes, journey, template, onDeleteJourneyScheduledMessage} = this.props
    const {isDeleteDialogOpen} = this.state
    const {contact} = journey

    if (!journey.analyticsCache || !template || !template.templatePages) return <div />

    return (
      <div>
        <DocumentTitle title={`Journeys - ${fullName(contact) || "Unknown"}`} />
        <TitleBar
          backButtonAttrs={{
            to: `/admin/templates/${journey.templateId}`,
            children: "Return to Campaign",
          }}
          title={fullName(contact) || "Unknown"}
        />

        {contact && (
          <Padded>
            <Link className={classes.contactLink} to={`/admin/contacts/${contact.id}`}>
              <Box className={classes.contactContainer}>
                <ContactsIcon size={40} />
                <div className={classes.contactInfo}>
                  <Typography className={classes.contactName} variant="h4">
                    {fullName(contact) || "Unknown"}
                  </Typography>
                  <Typography>
                    <b>Unique ID</b> {contact.uniqueId}
                  </Typography>
                </div>
                <ChevronRightIcon size={40} />
              </Box>
            </Link>
          </Padded>
        )}

        {template.templatePages.length > 0 && (
          <>
            <Padded className={this.props.classes.grid}>
              <UrlBox url={`journeys/${journey.slug}`} />
            </Padded>

            <Padded>
              <Box aria-labelledby="reward-progress-header" component="section">
                <RewardProgress
                  journey={journey}
                  onUpdateJourney={this.onUpdateJourney}
                  template={this.props.template}
                />
              </Box>
            </Padded>
          </>
        )}

        {this.renderSurveyResults()}

        {journey.accounts.length > 0 && (
          <Padded>
            <Typography variant="h5">Associated Account(s)</Typography>
            <Box>
              <DOTable
                rows={journey.accounts}
                paginationEnabled={false}
                noResults="No accounts have been imported yet"
                headers={[
                  {field: "name", label: "Product Name", sortable: false},
                  {field: "code", label: "Product Code", sortable: false},
                  {field: "type", sortable: false},
                  {field: "nickname", sortable: false},
                  {field: "role", sortable: false},
                ]}
              >
                {account => (
                  <TableRow key={account.id}>
                    <TableCell>{account.product.name}</TableCell>
                    <TableCell>{account.product.code}</TableCell>
                    <TableCell>{account.product.type}</TableCell>
                    <TableCell>{account.nickname}</TableCell>
                    <TableCell>{account.contactAccount.role}</TableCell>
                  </TableRow>
                )}
              </DOTable>
            </Box>
          </Padded>
        )}

        <ActionHistory
          journey={journey}
          onDeleteJourneyScheduledMessage={onDeleteJourneyScheduledMessage}
          scrollToSurveyAnswers={this.scrollToSurveyAnswers}
          template={template}
        />

        <Padded>
          <div className={classes.deleteButtonContainer}>
            <AccessControlled requiredPermissions="contacts:delete">
              <DangerButton onClick={this.onOpenDeleteDialog}>
                <DeleteIcon className={classes.leftIcon} /> Delete this Journey
              </DangerButton>
            </AccessControlled>
          </div>
          <UltraConfirmDialog
            confirmationText={fullName(contact) || "Unknown"}
            key={journey.id}
            onClose={this.onCloseDeleteDialog}
            onConfirm={this.onDeleteJourney}
            open={isDeleteDialogOpen}
            recordType="journey"
          />
        </Padded>
      </div>
    )
  }
}

JourneysShow.propTypes = {
  classes: object.isRequired,
  clearJourney: func.isRequired,
  contactAccounts: arrayOf(
    shape({
      id: string,
      role: string,
      account: shape({
        nickname: string,
        product: shape({
          name: string,
          code: string,
          type: string,
        }),
      }),
    })
  ),
  getJourney: func.isRequired,
  journey: object.isRequired,
  journeyId: string.isRequired,
  onDeleteJourney: func.isRequired,
  onDeleteJourneyScheduledMessage: func.isRequired,
  onUpdateJourney: func.isRequired,
  template: object.isRequired,
}

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  deleteButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  surveyAnswers: {
    color: theme.palette.text.primary,
    "& dt": {
      fontWeight: "bold",
    },
    "& dd": {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
    },
  },
  contactContainer: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  contactInfo: {
    flex: 1,
    margin: `0 ${theme.spacing(2)}`,
  },
  contactName: {
    marginBottom: 0,
  },
  contactLink: {
    textDecoration: "none",
  },
})

export default withStyles(styles)(JourneysShow)
