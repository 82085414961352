import {FormControl, InputLabel, MenuItem, TextField} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object} from "prop-types"

import DOSelect from "components/do-select/do-select"

import {requiredField, validDomainAndTld} from "lib/field-validations"
import useForm, {formify} from "lib/hooks/use-form"
import {formatUrl} from "lib/string/url-helpers"

import CtaButtonSettings from "../../cta-button-settings"
import FormActions from "../form-actions"

// NB: Override the requiredField's helperText
const requiredNameField = val =>
  Boolean(requiredField(val))
    ? "Please provide a name for your enrollment widget, as this is used for insights, analytics, retargeting, and webhooks"
    : null

const useStyles = makeStyles(theme => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  root: {
    width: "100%",
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
}))

const EnrollmentSettings = ({
  change,
  contentBlock,
  field,
  handleSubmit,
  onChangeMeta,
  resetForm,
}) => {
  const {field: metaField, resetForm: resetMeta} = useForm({onChange: onChangeMeta})
  const classes = useStyles()
  const {slug} = contentBlock

  const handleReset = () => {
    resetForm()

    // NB: Reset the meta form input values
    resetMeta()

    // NB: Reset the readonly state
    onChangeMeta(null)
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        autoFocus={true}
        fullWidth={true}
        helperText="Used for insights and analytics"
        id={`enrollment-${slug}-name`}
        label="Name"
        margin="normal"
        {...field("name")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        label="Heading"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-heading",
        }}
        {...field("heading")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        label="Agreement Text"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-agreement-text",
        }}
        {...field("agreementText")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        label="Document Link Text"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-document-text",
        }}
        {...field("documentLinkText")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        label="Document Link URL"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-document-link",
        }}
        {...field("documentLinkUrl")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        label="Signature Box Text"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-signature-text",
        }}
        {...field("signatureBoxText")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        label="Disagree Text"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-disagree-text",
        }}
        {...field("disagreeText")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        helperText="Text that the contact will see after accepting and submitting the form"
        label="Accepted Confirmation Text"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-confirmation-text",
        }}
        {...field("acceptedConfirmationText")}
      />
      <TextField
        autoFocus={true}
        fullWidth={true}
        helperText="Text that the contact will see after declining and submitting the form"
        label="Declined Confirmation Text"
        margin="normal"
        inputProps={{
          "data-testid": "enrollment-declined-text",
        }}
        {...field("declinedConfirmationText")}
      />
      <CtaButtonSettings change={change} field={field} />
      <FormControl fullWidth margin="normal">
        <InputLabel htmlFor="viewAs">View As</InputLabel>
        <DOSelect
          helperText="Used for altering display state only"
          id="viewAs"
          {...metaField("editorOnlyViewAsState", {defaultValue: "unsubmitted"})}
        >
          <MenuItem value="unsubmitted">Unsubmitted</MenuItem>
          <MenuItem value="accepted">Accepted</MenuItem>
          <MenuItem value="declined">Declined</MenuItem>
        </DOSelect>
      </FormControl>
      <FormActions resetForm={handleReset} />
    </form>
  )
}

EnrollmentSettings.propTypes = {
  change: func,
  contentBlock: object,
  field: func,
  handleSubmit: func,
  onChangeMeta: func,
  resetForm: func,
}

export default formify({
  parse: {documentLinkUrl: formatUrl},
  validators: {documentLinkUrl: [validDomainAndTld], name: [requiredNameField]},
})(EnrollmentSettings)
