import {Tooltip, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import debounce from "lodash/debounce"
import {bool, func, object, string} from "prop-types"
import {PureComponent} from "react"
import {MdDragHandle as DragHandleIcon} from "react-icons/md"

import AppendPluginMenu from "components/content-block-editor/append-plugin-menu"
import SortableDragHandle from "components/content-block-editor/drag-handle"

import {contentBlockEditorConsumer} from "../../content-block-editor-context"
import {contentBlockPropType} from "../../content-block-editor-prop-types"

export class DividerReadOnly extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {height: null}
  }

  onMouseDown = event => {
    event.preventDefault()
    if (!this.props.selectedBlock) return
    this.heightDiff = 0
    this.startingY = event.clientY
    document.body.addEventListener("mousemove", this.onMouseMove)
    document.body.addEventListener("mouseup", this.onMouseUp)
  }

  onMouseMove = debounce(event => {
    event.preventDefault()
    this.heightDiff = event.clientY - this.startingY
    const height = Math.max(this.props.contentBlock.data.height + this.heightDiff, 10)
    this.setState({height})
  })

  onMouseUp = event => {
    event.preventDefault()
    document.body.removeEventListener("mousemove", this.onMouseMove)
    document.body.removeEventListener("mouseup", this.onMouseUp)

    this.props.onUpdateContentBlock({
      data: this.state,
      id: this.props.selectedBlock.id,
    })
    this.setState({height: null})
  }

  render() {
    const {
      className,
      isEditMode,
      classes,
      contentBlock,
      customCss,
      onClick,
      selectedBlock,
      style,
    } = this.props
    const {
      data: {height},
    } = contentBlock

    return (
      <div
        className={cx(className, classes.divider, {[classes.editDivider]: isEditMode})}
        data-testid="divider-readonly"
        onClick={onClick}
        style={{...style, height: `${this.state.height || height}px`}}
      >
        <SortableDragHandle />
        <AppendPluginMenu contentBlock={contentBlock} />
        <style>{customCss}</style>
        {isEditMode && (
          <>
            <Typography className={classes.heightHelperText}>{`${
              this.state.height || height
            }px`}</Typography>
            {!!selectedBlock && (
              <Tooltip placement="bottom" title="Drag to resize the divider">
                <span className={classes.dragHandle}>
                  <DragHandleIcon
                    className={classes.dragHandleIcon}
                    onMouseDown={this.onMouseDown}
                  />
                </span>
              </Tooltip>
            )}
          </>
        )}
      </div>
    )
  }
}

DividerReadOnly.propTypes = {
  className: string,
  classes: object,
  contentBlock: contentBlockPropType.isRequired,
  customCss: string,
  isEditMode: bool,
  onClick: func,
  onUpdateContentBlock: func,
  selectedBlock: object,
  style: object,
}

const styles = theme => ({
  divider: {
    display: "flex",
  },
  dragHandle: {
    position: "absolute",
    bottom: 0,
    left: "calc(50% - 7px)",
  },
  dragHandleIcon: {
    cursor: "row-resize",
    color: theme.palette.text.hint,
  },
  editDivider: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    border: `1px dashed ${theme.palette.text.hint}`,
    transition: "height linear .1s",
  },
  heightHelperText: {
    color: theme.palette.text.hint,
    fontWeight: 500,
  },
})

export default withStyles(styles)(contentBlockEditorConsumer(DividerReadOnly))
