import {TableCell, TableRow, Typography} from "@mui/material"
import {node, number, object, string} from "prop-types"
import {useEffect} from "react"
import {BsDashLg as DashIcon} from "react-icons/bs"
import {CgArrowLongDown as ArrowDownIcon, CgArrowLongUp as ArrowUpIcon} from "react-icons/cg"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"
import Expandable from "components/expandable/expandable"
import DOTable from "components/table/table"

import {fetchAdvancedInsightsPerformance} from "lib/api"
import theme from "themes/main"

const namespace = "advanced-team-insights-performance"

const headers = [
  {
    field: "name",
    label: "",
    sortable: false,
  },
  {
    field: "emailCtr",
    label: "Email CTR",
    sortable: false,
    tooltipText:
      "Email CTR: total number of unique email message clicks / total number of email messages sent excluding bounces",
  },
  {
    field: "smsCtr",
    label: "SMS CTR",
    sortable: false,
    tooltipText:
      "SMS CTR: total number of unique SMS message clicks / total number of SMS messages sent excluding bounces",
  },
  {
    field: "bouncedRate",
    label: "Bounce Rate",
    sortable: false,
    tooltipText: "Bounce Rate: total number of message bounces / total number of messages sent",
  },
  {
    field: "unsubscribeRate",
    label: "Unsubscribe Rate",
    sortable: false,
    tooltipText:
      "Unsubscribe Rate: total number of unique unsubscribes / total number of messages sent excluding bounces",
  },
  {
    field: "pageCtr",
    label: "Page CTR",
    sortable: false,
    tooltipText: "Page CTR: total number of unique page clicks / total number of unique page views",
  },
  {
    field: "mobileActionRate",
    label: "Percent of Actions on Mobile",
    sortable: false,
    tooltipText:
      "Percent of Actions on Mobile: total number of engagement actions completed on mobile / total number of all engagement actions",
  },
]
const lightBlue = theme.palette.brand.lightBlue
const lightCoral = theme.palette.brand.lightCoral
const directions = ["up", "down"]

const getColor = color => {
  switch (color) {
    case "blue":
      return lightBlue
    case "red":
      return lightCoral
    default:
      return "#000000"
  }
}

// NB: We need to sort the row object in accordance to the header's index per field to get the corresponding data for each column.
const fields = headers.map(header => header.field)
const sortByIndex = ([a], [b]) =>
  fields.findIndex(field => field === a) - fields.findIndex(field => field === b)

// NB: Input -> Output
// "My String" -> "My String"
// 1 -> 1
// 0.44 -> .4
// 1.40 -> 1.4
const humanize = value => {
  if (isNaN(value)) return value
  if (!value.toString().includes(".")) return parseInt(value, 10).toString()

  return value < 1 ? `.${value.toFixed(1).toString().split(".")[1]}` : value.toFixed(1).toString()
}

const Arrow = ({children, color, difference, direction}) => {
  const renderArrow = () => {
    switch (direction) {
      case "up":
        return <ArrowUpIcon color={color} size={20} />
      case "down":
        return <ArrowDownIcon color={color} size={20} />
      default:
        return <DashIcon color="#000000" size={20} />
    }
  }

  const valueMarginRight = direction === "dash" ? 10 : 0
  const valueAlignment =
    direction === "up" ? "flex-start" : direction === "down" ? "flex-end" : "center"

  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <span style={{display: "inline-block", marginRight: valueMarginRight}}>{children}</span>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: valueAlignment,
        }}
      >
        {renderArrow()}
        {directions.includes(direction) && (
          <span style={{color: color, display: "inline-block", fontSize: 10}}>{`${humanize(
            difference
          )}pp`}</span>
        )}
      </div>
    </div>
  )
}

Arrow.propTypes = {
  children: node,
  color: string,
  difference: number,
  direction: string,
}

const Performance = ({classes}) => {
  const {
    fetchInsights,
    filterParams,
    hasSocket,
    performance,
    performanceIsLoading,
  } = useAdvancedTeamInsights()

  useEffect(() => {
    if (hasSocket) fetchInsights("performance", fetchAdvancedInsightsPerformance, [filterParams])
  }, [fetchInsights, filterParams, hasSocket])

  const renderCell = ({color, difference, direction, hasArrow, value}) => {
    const sign = isNaN(value) ? "" : "%"

    if (!hasArrow) return `${humanize(value)}${sign}`

    return (
      <Arrow color={getColor(color)} difference={difference} direction={direction}>
        {`${humanize(value)}${sign}`}
      </Arrow>
    )
  }

  return (
    <div>
      <div className={classes.expandableContainer}>
        <Expandable label="How we use this table">
          <p>
            The performance table reports sitewide key performance indicators and compares them to
            platform averages.
          </p>

          <Typography variant="h6">At a Glance</Typography>
          <ul>
            <li>
              <b>Email CTR</b> and <b>SMS CTR</b>: Compare message click through rates to platform
              averages. If lower, focus on optimizing message type, content, and scheduling
              personalization so contacts engage more with outreach. If higher, that is great, but
              there is always room for improvement.
            </li>
            <li>
              <b>Bounce Rate</b>: A high bounce rate shows that messages are not being delivered to
              contacts. To investigate bounces on campaigns with high bounce rates, export analytics
              filtered by message bounced.
            </li>
            <li>
              <b>Unsubscribe Rate</b>: High unsubscribe rates mean that contacts do not react well
              to messaging. The next step is to figure out why they don't welcome outreach.
            </li>
            <li>
              <b>Single Page Session Rate</b> and <b>Multi-Page Session Rate</b>: These two metrics
              are complimentary for campaigns with multiple pages. If the multi-page session rate is
              higher than the single page rate, contacts are fully utilizing the microsite. If
              lower, consider content changes to increase organic navigation.
            </li>
            <li>
              <b>Page CTR</b>: In many instances, a page’s call to action button or link is the
              final step to complete an objective. A high rate shows strong engagement by contacts
              in a campaign. For a low rate, make sure the story the content tells is cohesive and
              benefit-driven from message to page.
            </li>
            <li>
              <b>Presented Objective Completion Rate</b>: This metric shows how well campaigns
              support objective completion. An important note: This metric is only 100 percent
              accurate when you report on all campaign objectives.
            </li>
            <li>
              <b>Percent of Actions on Mobile</b>: This metric shows the usability of content for
              mobile users. If this is low, consider improvements to cater to users on mobile
              devices.
            </li>
          </ul>

          <Typography variant="h6">Use of Page Filters</Typography>
          <ul>
            <li>
              <b>Dates</b>: Compare engagement metrics by time frames to monitor seasonality and
              notable changes over time.
            </li>
            <li>
              <b>Objectives</b>: View engagement metrics by specific objectives to see if specific
              content has more results than others.
            </li>
            <li>
              <b>Status</b>: Focus the view by live or archived campaign status to compare, or to
              view a specific campaign category.
            </li>
          </ul>
        </Expandable>
      </div>
      <DOTable
        headers={headers}
        isTableLoading={performanceIsLoading}
        noResults="No data found."
        paginationEnabled={false}
        rows={performance?.data ?? []}
      >
        {(row, index) => {
          return (
            <TableRow key={`${namespace}-${index}`}>
              {Object.entries(row)
                .sort(sortByIndex)
                .map(([key, value], i) => (
                  <TableCell key={`${namespace}-${key}-${index}-${i}`}>
                    {renderCell(value)}
                  </TableCell>
                ))}
            </TableRow>
          )
        }}
      </DOTable>
    </div>
  )
}

Performance.propTypes = {
  classes: object,
}

export default Performance
