import {forwardRef} from "react"
import {IMaskInput} from "react-imask"

const PhoneTextMask = forwardRef((props, ref) => (
  <IMaskInput
    {...props}
    eager={true}
    mask="(000) 000-0000"
    inputRef={ref}
    type="tel"
    unmask={true}
  />
))

export default PhoneTextMask
