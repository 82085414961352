import {Button, Link, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, func, object} from "prop-types"

import {triggerDownload} from "../../csv-download-button/csv-download-button"
import {contactFields} from "../../teams/csv-processing-settings/csv-processing-helpers"

const ContactsBatchUploadPanel = ({classes, objectives, onContinue}) => {
  // Build template with certain cols to the left for visibility
  const createCsvContent = () => {
    const forVisibility = ["unique_id", "email", "template_id"]
    const objectiveKeys = objectives.map(o => o.key)

    const fields = contactFields
      .reduce((acc, field) => {
        if (!forVisibility.includes(field.value)) {
          return [...acc, field.value]
        }

        return acc
      }, [])
      .concat(objectiveKeys)

    return Promise.resolve([[...forVisibility, ...fields]])
  }

  const downloadCsvTemplate = () => {
    triggerDownload(createCsvContent, "batch-contact-upload-template.csv")
  }

  const onFileUpload = ({target: {files}}) => {
    if (files[0]) {
      onContinue({
        file: files[0],
        batchType: "contact",
      })
    }
  }

  return (
    <>
      <Typography className={classes.sectionHeader} variant="h2">
        Contacts
      </Typography>
      <Typography className={classes.helperText}>
        Use this route to create new contacts or update existing contacts in the platform. The
        information uploaded here should only contain information related to the contact record,
        like: name, email, and objective completions. Information related to specific accounts
        should be uploaded using the accounts uploader (to the right).
      </Typography>
      <input
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="contacts-csv-file"
        name="batchUploadContactsCSV"
        onChange={onFileUpload}
        style={{display: "none"}}
        type="file"
      />
      <label className={classes.uploadButton} htmlFor="contacts-csv-file">
        <Button className={classes.button} color="primary" component="span" variant="contained">
          Upload Contacts
        </Button>
      </label>
      <br />
      <Link
        href="https://support.digitalonboarding.com/support/solutions/articles/48001186845-csv-file-import-specifications"
        target="_blank"
      >
        Need help creating a file?
      </Link>{" "}
      Or want to <Link onClick={downloadCsvTemplate}>download a csv template?</Link>
    </>
  )
}

ContactsBatchUploadPanel.propTypes = {
  classes: object.isRequired,
  objectives: array.isRequired,
  onContinue: func.isRequired,
}

const styles = theme => ({
  button: {
    marginBottom: 10,
    marginRight: 10,
    marginTop: theme.spacing(8),
  },
  uploadButton: {
    display: "flex",
    justifyContent: "center",
  },
  helperText: {
    display: "block",
    marginBottom: 5,
    minHeight: 150,
  },
  sectionHeader: {
    fontSize: theme.typography.fontSize * 2,
    marginBottom: theme.spacing(3),
  },
})

export default withStyles(styles)(ContactsBatchUploadPanel)
