import {styled} from "@mui/material/styles"
import {func, string} from "prop-types"

const ResetLink = styled("span")(({theme}) => ({
  padding: theme.spacing(1),
  cursor: "pointer",
  textDecoration: "underline",
}))

const ResetDefaultValue = ({defaultValue, name, reset, text, value}) => {
  const isDefaultValue = defaultValue === value || value === ""

  const onClick = event => {
    // Cancel parent click action
    event.preventDefault()
    reset(defaultValue)
  }

  return (
    <div name={name}>
      {text}
      {!isDefaultValue && <ResetLink onClick={onClick}>(reset to default)</ResetLink>}
    </div>
  )
}

ResetDefaultValue.propTypes = {
  defaultValue: string,
  name: string,
  reset: func,
  text: string,
  value: string,
}

export default ResetDefaultValue
