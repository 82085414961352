import {Grid, TextField} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"
import {connect} from "react-redux"

import useForm from "lib/hooks/use-form"
import {formatUrl} from "lib/string/url-helpers"

import {controlAccess} from "../access-control/access-controlled"
import SaveButton from "../save-button/save-button"
import {updateTeamSettings} from "./team-actions"

const SmsSettings = ({classes, initialValues, onSubmit, disabled}) => {
  const {field, handleSubmit, submitting} = useForm({
    onSubmit,
    enableReinitialize: true,
    initialValues,
    parse: {smsLongUrl: formatUrl},
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={4}>
        <Grid item={true} lg={6} md={12}>
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            helperText="To use your own 800 number in sms replies to HELP messages add it here."
            label="Toll Free Number"
            {...field("tollFreeNumber")}
          />
        </Grid>
        <Grid item={true} lg={6} md={12}>
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            helperText="To use your own URL in sms replies to HELP messages add it here."
            label="Help URL"
            {...field("smsLongUrl")}
          />
        </Grid>
      </Grid>
      <div className={classes.formActions}>
        <SaveButton disabled={disabled} submitting={submitting} />
      </div>
    </form>
  )
}

SmsSettings.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  initialValues: object,
  onSubmit: func.isRequired,
}

const styles = {
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
}

export default withStyles(styles)(
  connect(({session}) => ({initialValues: session.team}), {onSubmit: updateTeamSettings})(
    controlAccess({requiredPermissions: "team_settings:general"})(SmsSettings)
  )
)
