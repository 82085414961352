import * as Sentry from "@sentry/browser"

import {setAccessToken} from "./access-token"
import apiReq from "./api-req"
import storage from "./storage"
import synchronize from "./synchronize"

const refreshAccessToken = synchronize(async refreshToken => {
  if (!refreshToken) Sentry.captureMessage("Attempting refresh without a refreshToken!")
  const newToken = await apiReq(
    `oauth2/token`,
    {
      method: "POST",
      body: {
        grantType: "refresh_token",
        refreshToken,
      },
    },
    true
  )

  setAccessToken(storage, newToken, true)

  return newToken
})

export default refreshAccessToken
