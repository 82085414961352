import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {FaRegMoneyBillAlt as DepositIcon} from "react-icons/fa"
import shortid from "shortid"

import {feature} from "../../../feature/feature"

const DirectDepositButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      type: "direct-deposit",
      data: {text: "Direct Deposit"},
    })

    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <DepositIcon />
      </ListItemIcon>
      <ListItemText primary="Direct Deposit" ref={ref} />
    </MenuItem>
  )
})

DirectDepositButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func,
}

export {DepositIcon}

export default feature("direct-deposit", DirectDepositButton)
