import {TableCell} from "@mui/material"
import {number, oneOfType, string} from "prop-types"
import React from "react"

import {formatMoney} from "lib/currency-formatters"
import {formatDate} from "lib/date-time-formatters"

const isValidDate = value => value instanceof Date && !isNaN(value.getTime())

const AccountsTableCell = ({contentId, label, value}) => {
  const format = value => {
    switch (true) {
      case [
        "account.closedDate",
        "account.creditCardActivation",
        "account.inPersonDepositCheckDate",
        "account.openedDate",
        "account.lastDebitCardTransaction",
        "account.lastMobilePayTransaction",
        "account.lastTransactionDate",
        "account.loanPayoffDate",
        "account.maturityDate",
        "account.paymentDueDate",
      ].includes(contentId):
        // Workaround for browser localization of timezone offsets
        const utcDate = new Date(new Date(value).toUTCString().slice(0, 25))
        // If the value isn't a parseable date, just return it raw instead of attempting to format.
        return isValidDate(utcDate) ? formatDate(utcDate) : value
      case [
        "account.apy",
        "account.balance",
        "account.lastMobilePayAmount",
        "account.lastStatementBalance",
        "account.lastTransactionAmount",
        "account.loanPayment",
        "account.loanPrincipal",
        "account.totalAmountLast30Days",
      ].includes(contentId):
        return formatMoney(value)
      default:
        return value
    }
  }

  return <TableCell data-label={label}>{format(value)}</TableCell>
}

AccountsTableCell.defaultProps = {value: ""}

AccountsTableCell.propTypes = {
  contentId: string,
  label: string.isRequired,
  value: oneOfType([number, string]),
}

export default AccountsTableCell
