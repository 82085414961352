// This file was generated automatically from builder-1.svg with the following command:
// ./svg-converter.js src/components/superhero/builder-1.svg
import SuperheroSvg from "./superhero-svg"

const Builder1 = props => (
  <SuperheroSvg viewBox="0 0 700 800" {...props}>
    <defs>
      <clipPath id="d20043f5-clip-path">
        <rect height="800" style={{fill: "none"}} width="687.5" x="12.5" y="0.5" />
      </clipPath>
    </defs>
    <g style={{clipPath: "url(#d20043f5-clip-path)"}}>
      <g>
        <g>
          <polygon
            points="352.79 937.62 322.84 1174.87 126.1 1174.87 179.22 787.17 404.87 764.03 352.79 937.62"
            style={{fill: "#5f9e92"}}
          />
          <path
            d="M259,397.27l-4.93,3.8-13.3,10.22L220.9,426.51l-24,18.44-26.47,20.34-26.62,20.45L119,504.82,98,520.93,82.94,532.52,75.8,538l-.31.24a28.82,28.82,0,0,0-10.26,10.26,21.25,21.25,0,0,0-3.39,10.63,21.71,21.71,0,0,0,.51,11.41,28.65,28.65,0,0,0,7.35,12.62l12.39,11.24,29.75,27,36,32.63,31,28.12c5,4.56,10,9.13,15.08,13.67l.21.2a21.44,21.44,0,0,0,9.21,5.91,25.82,25.82,0,0,0,22,0,21.44,21.44,0,0,0,9.21-5.91l4.47-5.79a28.37,28.37,0,0,0,3.9-14.44l-1-7.6a28.6,28.6,0,0,0-7.35-12.62l-12.39-11.24-29.75-27-36-32.63-31-28.12c-5-4.56-10-9.15-15.08-13.68l-.21-.19-5.79,44.92,4.93-3.79,13.3-10.22,19.82-15.22,24-18.44,26.47-20.34,26.62-20.46,24.82-19.07,21-16.12,15.08-11.59,7.14-5.49.31-.23A32.07,32.07,0,0,0,301,429.58a21.72,21.72,0,0,0,.51-11.41,21.22,21.22,0,0,0-3.39-10.63l-4.47-5.79A28.56,28.56,0,0,0,281,394.39l-7.6-1a28.52,28.52,0,0,0-14.44,3.9Z"
            style={{fill: "#b5927e"}}
          />
          <polygon
            points="250.32 280.52 255.09 377.54 522.05 384.48 522.05 297.8 250.32 280.52"
            style={{fill: "#123252"}}
          />
          <path
            d="M446.57,102.12a148.42,148.42,0,0,1,4.29-23.24,150.8,150.8,0,0,0-147.1,4.81,138.05,138.05,0,0,0,86.55,30.36A138.25,138.25,0,0,0,446.57,102.12Z"
            style={{fill: "#123252"}}
          />
          <path
            d="M468.71,89.75a138.75,138.75,0,0,1-22.14,12.37,149.17,149.17,0,0,0,81.07,148.45A150.65,150.65,0,0,0,468.71,89.75Z"
            style={{fill: "#123252"}}
          />
          <g>
            <path
              d="M445.78,117.51a150.45,150.45,0,0,1,.79-15.39,138.25,138.25,0,0,1-56.26,11.93,138.05,138.05,0,0,1-86.55-30.36,151.71,151.71,0,0,0-49.42,48.38l202.32,41.2A148.28,148.28,0,0,1,445.78,117.51Z"
              style={{fill: "#b5927e"}}
            />
            <path
              d="M230.82,212.86a150.85,150.85,0,0,0,104,143.37v21.31h58.94v-14.4a150.51,150.51,0,0,0,104-54.13,32.91,32.91,0,1,0,29.76-57.94c0-.17.07-.34.11-.5-1-.53-2.06-1.15-3.1-1.71L232.71,189.43A150.76,150.76,0,0,0,230.82,212.86Z"
              style={{fill: "#b5927e"}}
            />
            <path
              d="M456.66,173.27l-202.32-41.2a149.7,149.7,0,0,0-21.63,57.36l291.83,59.43A149.56,149.56,0,0,1,456.66,173.27Z"
              style={{fill: "#ec6568"}}
            />
          </g>
          <polygon
            points="386.84 384.48 528.99 408.74 678.07 1098.68 421.51 1098.68 386.84 384.48"
            style={{fill: "#0f2c48"}}
          />
          <rect height="24.27" style={{fill: "#5cbdaf"}} width="58.94" x="334.83" y="377.54" />
          <g>
            <path
              d="M483.48,490a87,87,0,0,0-74.38-97.92l-76.76-9.07a87,87,0,0,0-97.92,74.38l-28.74,173.2H460.15Z"
              style={{fill: "#5cbdaf"}}
            />
            <path
              d="M511.44,812.38q.21-4.17.21-8.39a166,166,0,0,0-59.16-127.21l.18-1.07H198.2l-15,90.37a166.77,166.77,0,0,0-3.58,21.59l-1.93,11.66,1.24.16c0,1.5-.11,3-.11,4.5A166.45,166.45,0,0,0,312.87,967.23L314,970.4,345.23,1175h215Z"
              style={{fill: "#5cbdaf"}}
            />
            <polygon
              points="460.15 630.63 205.68 630.63 198.2 675.71 452.67 675.71 460.15 630.63"
              style={{fill: "#0f2c48"}}
            />
          </g>
          <path
            d="M446.57,102.12a138.75,138.75,0,0,0,22.14-12.37,151.19,151.19,0,0,0-17.85-10.87A148.42,148.42,0,0,0,446.57,102.12Z"
            style={{fill: "#123252"}}
          />
          <path
            d="M345.23,274.4a17.34,17.34,0,0,0,34.67,0V256.19l-.61.46c-13.86,9.84-34.06,6.48-34.06,6.48Z"
            style={{fill: "#fff"}}
          />
          <circle cx="308.83" cy="178.19" r="12.13" style={{fill: "#0b1d37"}} />
          <circle cx="412.84" cy="195.52" r="12.13" style={{fill: "#0b1d37"}} />
          <path
            d="M418.73,158.16a16.13,16.13,0,0,1,2.38-.56l.62-.1c.44-.09-.59.06.18,0,.45,0,.9-.1,1.36-.13a27.25,27.25,0,0,1,3.5,0l.87.07.51.06h.08c.67.11,1.33.24,2,.4a20.66,20.66,0,0,1,3.78,1.32,21.65,21.65,0,0,1,7.8,6.41,2.68,2.68,0,0,0,3.56.93,2.6,2.6,0,0,0,.93-3.55,26.55,26.55,0,0,0-14.55-10.09,28.83,28.83,0,0,0-12.16-.31,16.82,16.82,0,0,0-2.23.53c-3.16,1.05-1.81,6.07,1.38,5Z"
            style={{fill: "#0b1d37"}}
          />
          <path
            d="M333.34,136.7c-3.95-5.53-11-8.53-17.67-8.76a31,31,0,0,0-11,1.81,2.67,2.67,0,0,0-1.82,3.2,2.63,2.63,0,0,0,3.2,1.82,26.21,26.21,0,0,1,10.74-1.57,19.78,19.78,0,0,1,6.76,2,23.21,23.21,0,0,1,2.64,1.64c.6.42-.14-.13.19.14l.52.43c.26.23.53.46.78.71a16.57,16.57,0,0,1,1.16,1.21,2.08,2.08,0,0,0,.32.4c-.45-.28-.46-.62-.28-.36a2.67,2.67,0,0,0,3.56.93,2.62,2.62,0,0,0,.93-3.56Z"
            style={{fill: "#0b1d37"}}
          />
          <g>
            <path d="M340,212.86s17.34-13.87,27.74,6.93" style={{fill: "#b5927e"}} />
            <path
              d="M341,213.78c-.36.28,0,0,.22-.16.42-.29.85-.58,1.29-.84a22.65,22.65,0,0,1,4.29-2.08c4.2-1.49,8.74-1.51,12.61.91,3.3,2.07,5.55,5.42,7.29,8.84.75,1.49,3,.18,2.24-1.32-3-5.9-7.75-11.21-14.66-12a20.8,20.8,0,0,0-12.52,3.06,21.5,21.5,0,0,0-2.6,1.75c-1.3,1,.55,2.87,1.84,1.84Z"
              style={{fill: "#0b1d37"}}
            />
          </g>
          <path
            d="M436,456.71l4.62,3.61,12.57,9.84,18.59,14.54,22.68,17.75,24.85,19.44,25.07,19.62,23.3,18.23,19.84,15.53,14.15,11.07c2.25,1.76,4.49,3.53,6.75,5.28l.29.23,5.79-44.92-12.75,7.53-30.38,17.95L534.61,594.1l-31.82,18.81c-5.14,3-10.31,6.05-15.44,9.12l-.21.12a27.56,27.56,0,0,0-13.65,28.5,21.29,21.29,0,0,0,3.39,10.64l4.48,5.79A28.54,28.54,0,0,0,494,674.43l7.6,1a28.5,28.5,0,0,0,14.44-3.9L528.77,664l30.37-18,36.71-21.69,31.82-18.8c5.15-3,10.31-6.05,15.44-9.12l.21-.13a28.66,28.66,0,0,0,10.26-10.26,21.36,21.36,0,0,0,3.4-10.63,21.72,21.72,0,0,0-.51-11.41,28.67,28.67,0,0,0-7.36-12.62l-4.62-3.61L631.92,538,613.33,523.4l-22.68-17.74-24.84-19.45-25.08-19.62-23.29-18.23L497.6,432.84l-14.16-11.08-6.74-5.28-.29-.22a31.79,31.79,0,0,0-20.23-8.38A31.79,31.79,0,0,0,436,416.26L431.48,422a28.42,28.42,0,0,0-3.9,14.44l1,7.6A28.63,28.63,0,0,0,436,456.71Z"
            style={{fill: "#b5927e"}}
          />
          <polygon
            points="377.71 480.67 327.22 447 272.81 473.9 268.9 534.46 319.39 568.12 373.8 541.23 377.71 480.67"
            style={{
              fill: "#f5f5f5",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "6.93555283587243px",
            }}
          />
          <polygon
            points="377.71 480.67 327.22 447 272.81 473.9 268.9 534.46 319.39 568.12 373.8 541.23 377.71 480.67"
            style={{fill: "#0f2c48"}}
          />
          <path
            d="M317,491.43a17.19,17.19,0,0,0-5.84-1.46,16.76,16.76,0,1,0,11.45,27.79A16.78,16.78,0,1,0,324,497.37a22.26,22.26,0,0,0-3.39-3.74A11.82,11.82,0,0,0,317,491.43Z"
            style={{
              fill: "#f5f5f5",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "5.85351121598736px",
            }}
          />
          <polygon
            points="367.89 485.52 326.51 457.93 281.92 479.97 278.72 529.6 320.1 557.2 364.69 535.16 367.89 485.52"
            style={{
              fill: "none",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "3.46703431698277px",
            }}
          />
        </g>
        <g>
          <path
            d="M304.08,506.22a5.86,5.86,0,1,0,6.26-5.43A5.88,5.88,0,0,0,304.08,506.22Z"
            style={{fill: "#5da4dc"}}
          />
          <path
            d="M336.82,502.65a5.86,5.86,0,1,0,5.44,6.25A5.86,5.86,0,0,0,336.82,502.65Z"
            style={{fill: "#5da4dc"}}
          />
          <path
            d="M337.59,491.78a16.61,16.61,0,0,0-13.65,5.6,22.37,22.37,0,0,0-3.38-3.76l-1,13.69a9.62,9.62,0,1,1-8.94-10.23,9.8,9.8,0,0,1,5.74,2.42l.57-8.1a17.45,17.45,0,0,0-5.84-1.48,16.76,16.76,0,1,0,11.33,27.83,16.75,16.75,0,1,0,15.13-26Zm-1.85,26.27A9.58,9.58,0,1,1,346,509.17,9.59,9.59,0,0,1,335.74,518.05Z"
            style={{fill: "#5da4dc"}}
          />
        </g>
        <path
          d="M432.82,646.53s6.33.89,5.95,3.77-6.8,9.4-17.56,7.77c-7.08-1.07-36.77.08-46.47,23.43,0,0-5,29.3,23.52,38.47,21.4,6.88,39.84,7.77,67.69-8.44,26-15.12,34.6-31.4,41.81-38.21l85.49-46.86,12.43-6.82-32.57-50.32-87.27,47.84-9,4.93c-9.16-3.64-31-11.15-48.19-7.87a30.27,30.27,0,0,0-16.59,7.41C403.9,628.62,416.7,645,432.82,646.53Z"
          style={{fill: "#ec6568"}}
        />
        <path
          d="M431.8,649.66s6.35.72,6,3.61l-12.93,5.3s24.36,1.27,33-7.41h0L461,628H404C408,637.39,420.56,648.87,431.8,649.66Z"
          style={{fill: "#0f2c48"}}
        />
        <path
          d="M463.74,610.66c-10.42-2.7-27.67-3.7-38.83-1.27,0,0-12.27,4.36-20.25,11.57-2.44,2.19-2.34,5.88-.62,10h57Z"
          style={{fill: "#5cbdaf"}}
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Builder1
