import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef} from "react"
import {MdVideocam as VideoIcon} from "react-icons/md"
import shortid from "shortid"

const VideoButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {},
      type: "video",
    })
    onClose()
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon classes={{root: classes.icon}}>
        <VideoIcon />
      </ListItemIcon>
      <ListItemText primary="Video" ref={ref} />
    </MenuItem>
  )
})

VideoButton.propTypes = {
  classes: object.isRequired,
  onAddContentBlock: func.isRequired,
  onClose: func.isRequired,
}

export {VideoIcon}

export default VideoButton
