import {styled} from "@mui/material/styles"
import {string} from "prop-types"

import {ordinal} from "lib/date-time-formatters"

const DateTimeSpan = styled("span")(({theme}) => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
}))

const TargetingImpactDate = ({completedAt}) => {
  const date = new Date(completedAt)
  const timeString = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })
  const day = ordinal(date.getDate())
  const month = date.toLocaleString([], {month: "long"})
  const year = date.getFullYear()
  const ordinalDateTime = `${timeString} on ${month} ${day}, ${year}`

  return <DateTimeSpan>{ordinalDateTime}</DateTimeSpan>
}

TargetingImpactDate.propTypes = {
  completedAt: string,
}

export default TargetingImpactDate
