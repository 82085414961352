import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"

import DangerButton from "../danger-button/danger-button"
import FileRelatedContent from "../file-browser/file-related-content"

export const DeleteFileDialog = ({classes, file, isOpen, onClose, onDelete}) => (
  <Dialog onClose={onClose} open={isOpen}>
    <DialogTitle>Delete File</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This action cannot be undone. If this file is being used in Pages/Messages, those
        Pages/Messages will display a broken image link.
      </DialogContentText>
      <DialogContentText className={classes.relatedContent}>
        <FileRelatedContent file={file} />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <DangerButton onClick={onDelete}>Delete</DangerButton>
    </DialogActions>
  </Dialog>
)

DeleteFileDialog.propTypes = {
  classes: object,
  file: object,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onDelete: func.isRequired,
}

const styles = {
  relatedContent: {
    marginTop: 15,
    opacity: 0.7,
  },
}

export default withStyles(styles)(DeleteFileDialog)
