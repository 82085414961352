import {Container, FormControl, FormControlLabel, Switch, Tooltip} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {arrayOf, func, object, string} from "prop-types"
import {MdHelp as HelpIcon} from "react-icons/md"
import {useHistory} from "react-router-dom"

import useFeatures from "lib/hooks/use-features"

import CrossChannelContentImage from "./cross-channel-content-image"

const useStyles = makeStyles(theme => ({
  crossChannelSectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
  },
  previewContainer: {backgroundColor: theme.palette.common.white, padding: theme.spacing(2)},
}))

const PageCrossChannelSettings = ({contentContainers, field, page, classes, templateId}) => {
  const localClasses = useStyles()
  const {hasFeature} = useFeatures()
  let history = useHistory()

  return (
    hasFeature("cross-channel-engagement") && (
      <>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                {...field("excludeFromCce", {
                  bool: true,
                  exclude: ["error", "helperText"],
                })}
              />
            }
            label={
              <Tooltip title="When enabled, this page will be visible in the Journey Microsite, but will be excluded / hidden from the CCE (Cross-Channel Engagement) page flow and will not need to be completed in order to proceed in CCE.">
                <span>
                  Exclude from CCE
                  <span className={classes.switchTooltipIconWrapper}>
                    <HelpIcon className={classes.switchTooltipIcon} />
                  </span>
                </span>
              </Tooltip>
            }
          />
        </FormControl>

        <Container sx={{paddingTop: "1em"}}></Container>
        <div className={localClasses.crossChannelSectionContainer}>
          {contentContainers.map(({id: contentContainerId, engagementChannel}) => (
            <div
              key={contentContainerId}
              className={localClasses.previewContainer}
              onClick={() =>
                history.push(
                  `/admin/templates/engagement-channels/${engagementChannel.id}/content-containers/${contentContainerId}?pageSlug=${page.slug}&templateId=${templateId}`
                )
              }
            >
              <div>
                <CrossChannelContentImage contentContainerId={contentContainerId} />
              </div>
              <div>{engagementChannel.name}</div>
            </div>
          ))}
        </div>
      </>
    )
  )
}

PageCrossChannelSettings.propTypes = {
  contentContainers: arrayOf(object),
  page: object,
  field: func,
  classes: object,
  templateId: string,
}

export default PageCrossChannelSettings
