if (!window.hasOwnProperty("inMemoryStorage")) window.inMemoryStorage = {}

export default {
  clear() {
    window.inMemoryStorage = {}
  },

  getItem(name) {
    return window.inMemoryStorage.hasOwnProperty(name) ? window.inMemoryStorage[name] : null
  },

  key(index) {
    return Object.keys(window.inMemoryStorage)[index] || null
  },

  removeItem(name) {
    delete window.inMemoryStorage[name]

    return null
  },

  setItem(name, value) {
    window.inMemoryStorage[name] = String(value)
  },

  get length() {
    return Object.keys(window.inMemoryStorage).length
  },
}
