import {Typography} from "@mui/material"
import {styled} from "@mui/material/styles"
import {node, oneOf, string} from "prop-types"
import {FaChevronLeft as LeftChevronIcon} from "react-icons/fa"
import {Link} from "react-router-dom"

const BackDiv = styled("div")`
  width: 100%;

  & a {
    color: #999;
    font-size: 13px;
    text-decoration: none;
    text-transform: none;
  }

  & a:hover {
    text-decoration: underline;
  }

  & svg {
    margin-right: 10px;
    position: relative;
    top: -1px;
  }
`

const BackButton = ({className, href, component, to, children}) => (
  <BackDiv className={className}>
    <Typography component={component} href={href} to={to}>
      <LeftChevronIcon /> {children}
    </Typography>
  </BackDiv>
)

BackButton.propTypes = {
  children: node.isRequired,
  className: string,
  component: oneOf(["a", Link]),
  href: string,
  to: string,
}

BackButton.defaultProps = {
  component: Link,
}

export default BackButton
