// This file was generated automatically from character-14.svg with the following command:
// ./svg-converter.js src/components/superhero/character-14.svg
import SuperheroSvg from "./superhero-svg"

const Character14 = props => (
  <SuperheroSvg viewBox="270 85 130 220" {...props}>
    <g id="Layer_23">
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M359.55,300.42l-0.04,0c-0.09-0.01-2.18-0.19-2.73-1.94c-0.42-1.32,0.15-2.99,1.73-5.1l0.05-0.05
  				c0.02-0.02,1.98-1.86,3.86-4.28c2.43-3.13,3.63-5.8,3.47-7.72l0.73-0.07c0.42,5.17-7.01,12.29-7.6,12.84
  				c-1.65,2.22-1.76,3.43-1.56,4.05c0.3,0.94,1.52,1.26,2.02,1.31c12.14-2.9,20.64-9.92,21.58-10.71c0.67-2.34-2.9-7.4-4.36-9.18
  				l0.5-0.69c0.59,0.72,5.7,7.08,4.5,10.41l-0.04,0.11l-0.08,0.07c-0.09,0.08-8.99,7.86-21.98,10.94L359.55,300.42z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M363.34,299.18l-0.67-0.43c0.46-1.27,0.52-2.42,0.18-3.43c-0.64-1.9-2.49-2.74-2.5-2.75l0.22-0.91
  				c0.09,0.04,2.18,0.99,2.96,3.29C363.95,296.24,363.89,297.66,363.34,299.18z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M363.44,296.04l-0.26-0.91c14.69-6.5,17.23-10.26,17.25-10.3l0.58,0.62
  				C380.91,285.61,378.45,289.4,363.44,296.04z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M362.86,294.86l-0.44-0.79c9.18-8.56,7.67-13.73,7.65-13.78l0.68-0.36
  				C370.82,280.17,372.6,285.79,362.86,294.86z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M363.44,291.93c-0.37,0.01-0.71-0.04-0.88-0.07l0.08-0.95c0.59,0.11,1.27,0.06,1.4-0.1
  				c0-0.01-0.01-0.02-0.01-0.05c-0.17-0.62-0.32-1.16,0.01-1.61c0.24-0.32,0.68-0.52,1.65-0.94c0.69-0.3,1.19-0.52,1.26-0.73
  				c0.08-0.21-0.11-0.87-0.36-1.77c-0.21-0.76-0.17-1.31,0.12-1.64c0.57-0.66,1.79,0.05,2.16,0.29l-0.31,0.86
  				c-0.59-0.38-1.24-0.55-1.35-0.42c0,0-0.06,0.13,0.07,0.61c0.72,2.58,0.62,2.86-1.34,3.72c-0.7,0.31-1.3,0.57-1.38,0.72
  				c-0.01,0.07,0.08,0.36,0.15,0.62c0.1,0.36,0.07,0.7-0.09,0.96C364.39,291.79,363.9,291.91,363.44,291.93z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M299.38,299.38l-0.04-0.02c-0.08-0.04-1.98-0.94-1.89-2.77c0.06-1.38,1.17-2.75,3.38-4.18
  				l0.06-0.03c0.02-0.01,2.49-1.05,5.09-2.66c3.36-2.09,5.4-4.17,5.91-6.02l0.71,0.19c-1.38,4.99-10.8,9.06-11.54,9.38
  				c-2.31,1.5-2.83,2.6-2.86,3.25c-0.05,0.99,0.99,1.72,1.45,1.94c12.39,1.54,22.78-2.07,23.93-2.49c1.44-1.96-0.18-7.95-0.94-10.13
  				l0.7-0.48c0.31,0.88,2.91,8.63,0.64,11.33l-0.08,0.09l-0.1,0.04c-0.11,0.04-11.14,4.22-24.39,2.56L299.38,299.38z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M303.36,299.56l-0.48-0.64c0.86-1.03,1.32-2.09,1.34-3.15c0.05-2-1.39-3.44-1.4-3.46l0.52-0.78
  				c0.07,0.07,1.71,1.69,1.64,4.12C304.95,297.01,304.4,298.32,303.36,299.56z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M304.54,296.64l0.07-0.94c16.02-0.95,19.69-3.58,19.73-3.61l0.33,0.78
  				C324.52,292.99,320.9,295.68,304.54,296.64z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M304.4,295.34l-0.14-0.89c11.56-4.81,11.91-10.19,11.91-10.24l0.76-0.1
  				C316.92,284.35,316.65,290.24,304.4,295.34z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M305.95,292.79c-0.35-0.12-0.66-0.29-0.8-0.37l0.4-0.86c0.52,0.31,1.17,0.5,1.35,0.39
  				c0-0.01,0-0.02,0-0.05c0.05-0.64,0.09-1.2,0.57-1.5c0.34-0.22,0.82-0.25,1.87-0.3c0.75-0.04,1.29-0.07,1.44-0.24
  				s0.2-0.85,0.27-1.79c0.06-0.78,0.29-1.29,0.68-1.5c0.76-0.42,1.67,0.68,1.93,1.02l-0.59,0.7c-0.43-0.56-0.97-0.95-1.12-0.86
  				c0,0-0.1,0.1-0.14,0.6c-0.21,2.67-0.4,2.9-2.54,3.01c-0.76,0.04-1.41,0.08-1.54,0.19c-0.03,0.06-0.05,0.36-0.07,0.63
  				c-0.03,0.37-0.18,0.68-0.42,0.86C306.89,293,306.39,292.94,305.95,292.79z"
          />
        </g>
      </g>
      <path
        style={{fill: "#CC5F64"}}
        d="M308.07,237.47l8.75-60.88l23.22-2.38l-5.36,17.86l-4.82,51.39c0,0,1.24,16.31,1.99,41.32l-27.8-1.6
  		C304.06,283.17,305.98,256.4,308.07,237.47z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M356.64,169.08L356.64,169.08L356.64,169.08c0.82-0.43,1.64-0.87,2.46-1.3
  		c-1.56,0.82-3.12,1.65-4.67,2.47c-0.27-2.17-0.54-4.34-0.81-6.51c-0.43-3.48-0.86-6.95-1.29-10.43c-0.1-0.79-0.2-1.59-0.3-2.38
  		c-0.08-0.69,0.05-1.33,0.59-1.86c0.5-0.49,1.43-0.88,2.3-0.89c1.78-0.01,3.84,1,4.02,2.46c0.27,2.17,0.54,4.34,0.81,6.51
  		c0.43,3.48,0.86,6.95,1.29,10.43c0.1,0.79,0.2,1.59,0.3,2.38c0.11,0.93-0.22,1.77-1.2,2.3c-1.43,0.78-3.47,0.17-3.47,0.17s0,0,0,0
  		c-0.01-0.01-2.42-1.42-2.24-2.19C354.61,169.51,356.63,169.08,356.64,169.08z"
      />
      <path
        style={{fill: "#EB6669"}}
        d="M357.22,237.39l-4.2-56.39l-1.15-13.93l-5.08-1.06l-27.03-6.12l-2.68,15.44
  		c-0.19,0.77-0.34,1.56-0.43,2.36l-0.23,1.28l0.14,0.02c-0.01,0.16-0.02,0.33-0.02,0.49c-0.14,8.87,6.06,16.36,14.42,18.15
  		l0.12,0.35l4.83,36.74c0,0,1.21,8.65,4.24,13.96l17.91,33.41l26.19-3.74l-23.31-33.39C360.94,244.97,357.91,241.18,357.22,237.39z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M318.48,160.75L318.48,160.75L318.48,160.75c0.93-0.06,1.85-0.12,2.78-0.18
  		c-1.76,0.11-3.52,0.22-5.28,0.34c0.65-2.09,1.29-4.18,1.94-6.27c1.03-3.35,2.07-6.69,3.1-10.04c0.24-0.77,0.47-1.53,0.71-2.3
  		c0.2-0.66,0.59-1.19,1.3-1.45c0.65-0.24,1.66-0.22,2.47,0.14c1.63,0.72,3.09,2.49,2.66,3.9c-0.65,2.09-1.29,4.18-1.94,6.27
  		c-1.03,3.35-2.07,6.69-3.1,10.04c-0.24,0.77-0.47,1.53-0.71,2.3c-0.28,0.89-0.92,1.52-2.04,1.61c-1.63,0.12-3.24-1.27-3.24-1.27
  		s0,0,0,0c-0.01-0.01-1.63-2.29-1.14-2.92C316.44,160.31,318.47,160.75,318.48,160.75z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M312.17,158.95c-0.37-0.09-0.73-0.18-1.1-0.27c-0.47-0.97-1.68-3.19-3.32-4.21
  		c0,0-0.71-0.59-1.91-0.63c-1.19-0.04-1.38,2.22-0.19,3.54c0,0,0.45,0.54,0.2,0.74l0.24,0.82H312.17"
      />
      <g>
        <g>
          <path
            style={{fill: "#D9D8D6"}}
            d="M379.26,177.94c3.92-14.73,5.39-30.05,5.27-45.27c-0.08-10.64-0.91-21.3-2.54-31.81
  				c-0.3-1.93-0.61-3.86-1.01-5.77c-0.06-0.31-0.43-0.48-0.71-0.4c-3.97,1.11-7.78,3.48-11.26,5.6
  				c-12.11,7.38-23.02,16.76-33.29,26.46c-10,9.44-19.43,19.47-28.52,29.79c-0.49,0.56,0.33,1.38,0.82,0.82
  				c14.05-15.95,28.93-31.41,45.56-44.7c7.29-5.83,15.04-11.52,23.55-15.47c1.13-0.53,2.21-1.04,3.45-1.38
  				c-0.24-0.13-0.47-0.27-0.71-0.4c1.15,5.46,1.83,11.04,2.39,16.59c1.32,13.05,1.55,26.24,0.41,39.32
  				c-0.77,8.89-2.22,17.71-4.52,26.33C377.96,178.35,379.07,178.66,379.26,177.94L379.26,177.94z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#123252"}}
            d="M379.19,92.41c-4.96,1.8-9.66,4.61-14.12,7.39c-12.88,8.04-24.57,17.99-35.32,28.67
  				c-9.26,9.2-17.83,19.06-25.87,29.33c-0.77,0.98-0.96,2.32,0,3.28c0.8,0.8,2.5,0.99,3.28,0c12.77-16.32,26.93-31.71,43.01-44.84
  				c8.05-6.58,16.82-12.81,26.24-17.52c1.11-0.55,2.23-1.07,3.36-1.57c0.53-0.24-0.63,0.25,0,0c0.12-0.05,0.24-0.1,0.36-0.14
  				c0.1-0.04,0.19-0.08,0.29-0.11c1.18-0.43,1.97-1.57,1.62-2.85C381.73,92.9,380.37,91.98,379.19,92.41L379.19,92.41z"
          />
        </g>
      </g>
      <path
        style={{fill: "#E3B59D"}}
        d="M315.33,159.55c3.57,0.87,7.13,1.75,10.7,2.62l-1.14,6.46c-0.51-0.12-1.02-0.25-1.52-0.37
  		c-3.49-0.86-6.98-1.71-10.48-2.57c-1.1,0.01-3.02,0.69-6.28-0.03c-3.48-0.76-3.65-1.7-4.87-3.84l1.6-2.67
  		c2.55-1.16,3.16-1.72,3.66-1.11c0.75,0.93,1.76,0.88,2.01,0.68l1.88-0.11L315.33,159.55z"
      />
      <path
        style={{fill: "#D9D8D6"}}
        d="M325.74,94.22c-1.33,5.17,0.84,10.02,2.93,14.76c2.64,5.99,6.44,6.7,12.24,8.68
  		c1.3,0.45,15.34,4.43,15.34,5.3c0-0.19,1.38-1.56,3.12-3.21c6.21-5.9,8.26-15.01,4.56-22.84c-4.91-10.39-19.58-12.24-29.65-9.72
  		c-3.47,0.87-6.86,2.24-8.16,5.84C325.97,93.43,325.85,93.83,325.74,94.22z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M344.21,131.84c1.72,0.43,4.52,1.3,4.52,1.3l-1.08,5.15l-8.18,3.44l-8.61-3.87v-6.02
  		c0,0-0.43,0,2.72-0.19c3.15-0.19,3.92-0.48,3.92-0.48l0-2.5c-7.08-2.31-12.6-8.96-12.6-16.81c0-0.94,0.08-1.85,0.22-2.75
  		l34.23,6.97c0.12,0.07,0.24,0.14,0.36,0.2c0,0.02-0.14,0.43-0.14,0.45c1.52,0.52,2.74,1.57,2.74,3.26c0,2.13-1.73,3.86-3.86,3.86
  		c-0.83,0-1.61-0.27-2.24-0.72c-2.96,3.57-7.3,5.96-12.2,6.35l0,1.69v0.67H344.21z"
      />
      <path
        style={{fill: "#123252"}}
        d="M336.08,131.54c-5.19-0.47-9.04-1.29-10.76,0.86c-1.4,1.76-2.17,5.05-2.62,7.93l-3.33,20.1l33.57,0
  		l2.71-16.32c0.35-2.59,1.34-6.13,0.24-8.61c-1.65-3.71-7.1-3.08-10.76-3.53l-6.62,5.67L336.08,131.54z"
      />
      <polygon
        style={{fill: "#123252"}}
        points="353.42,157.16 319.36,160.43 318.06,169.54 352.57,173.06 	"
      />
      <path
        style={{fill: "#123252"}}
        d="M356.46,135.55c-1.4-4.12-6.78-3.53-8.16,0.27s3.13,21.03,3.13,21.03l8.24-3.46
  		C359.68,153.38,357.5,138.61,356.46,135.55z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M350.07,186.89c0,0,0.55-0.43,0.75-0.18c0.2,0.25,0.22,1.27-0.73,1.99c-0.62,0.48-2.8,2.92-1.7,5.5
  		c0,0,1.92,2.66,4.81,1.11c2.17-1.17,3.64-2.56,4.5-6.02c0.79-3.23,0.17-5.17,0.19-6.27c0.95-3.47,1.89-6.94,2.84-10.41
  		c0.14-0.5,0.28-1.01,0.41-1.51l-6.43-1.31c-0.97,3.54-1.93,7.08-2.9,10.62c-0.1,0.36-0.2,0.73-0.3,1.09
  		c-0.98,0.44-3.23,1.59-4.29,3.21c0,0-0.61,0.7-0.68,1.89C346.46,187.8,348.72,188.05,350.07,186.89z"
      />
      <polygon
        style={{fill: "#123252"}}
        points="352.75,163.21 352.13,168.93 348.82,168.19 348.82,164.75 	"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M332.28,97.28c4.06,5.22,10.73,8.17,16.55,11.17c2.74,1.42,10.01,5.4,11.25,6.09
  		c-1.31,8.53-8.68,15.05-17.57,15.05c-9.82,0-17.78-7.96-17.78-17.78C324.74,105.8,327.72,100.49,332.28,97.28z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M360.27,114.63c0,0-0.07-0.04-0.19-0.1c0,0,0-0.01,0-0.01L360.27,114.63z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M332.27,97.26l0.03,0.02c0,0-0.01,0-0.01,0.01C332.28,97.27,332.27,97.26,332.27,97.26z"
      />
      <path
        style={{fill: "#D9D8D6"}}
        d="M334.71,117.95c-0.5,1.56-1.38,4.88-1.38,8.93c0,0,5.16,3.87,13.34,2.15c0,0,0.38-6.05-0.28-9.99
  		c-0.08-0.5-0.39-0.94-0.83-1.2c-1.48-0.87-5.22-2.62-9.55-1.21C335.39,116.83,334.91,117.33,334.71,117.95z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M337.2,118.71c0,0-0.86,1.72-0.86,3.44c0,0,3.44,1.72,7.32,0.43c0,0,0.43-2.58,0-3.44
  		C343.66,119.14,340.64,118.28,337.2,118.71z"
      />
      <circle style={{fill: "#0D1E36"}} cx="336.4" cy="107.56" r="1.41" />
      <g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M338.1,103.59c-0.29-0.29-0.7-0.5-1.09-0.62c-0.9-0.28-1.84-0.07-2.65,0.36
  				c-0.26,0.14-0.36,0.52-0.2,0.77c0.17,0.26,0.49,0.35,0.77,0.2c0.06-0.03,0.12-0.06,0.18-0.09c0.02-0.01,0.19-0.08,0.09-0.04
  				c0.09-0.04,0.19-0.07,0.29-0.1c0.1-0.03,0.21-0.06,0.32-0.08c0.01,0,0.03-0.01,0.04-0.01c0.06-0.01,0.06-0.01-0.02,0
  				c0.02,0.01,0.1-0.01,0.12-0.01c0.09-0.01,0.17-0.01,0.26-0.01c0.07,0,0.17,0.03,0.24,0.02c-0.14,0.02-0.07-0.01-0.01,0
  				c0.04,0.01,0.08,0.02,0.11,0.02c0.07,0.02,0.13,0.03,0.2,0.06c0.04,0.01,0.13,0.07,0.17,0.06c-0.09-0.04-0.11-0.05-0.06-0.02
  				c0.01,0.01,0.02,0.01,0.04,0.02c0.03,0.01,0.05,0.03,0.08,0.04c0.08,0.04,0.17,0.09,0.24,0.15c0.03,0.02,0.05,0.04,0.08,0.06
  				c-0.18-0.12-0.08-0.06-0.04-0.02c0.02,0.01,0.03,0.03,0.05,0.05c0.21,0.21,0.59,0.22,0.8,0
  				C338.31,104.16,338.32,103.81,338.1,103.59L338.1,103.59z"
          />
        </g>
      </g>
      <circle style={{fill: "#0D1E36"}} cx="348.48" cy="109.57" r="1.41" />
      <g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M348.62,106.11c0.09-0.03,0.19-0.05,0.29-0.06c0.05-0.01,0.13-0.01-0.07,0.01
  				c0.03,0,0.06-0.01,0.08-0.01c0.05-0.01,0.11-0.01,0.16-0.01c0.13-0.01,0.27,0,0.4,0.01c0.01,0,0.12,0.02,0.12,0.01
  				c-0.01,0.01-0.16-0.03-0.03,0c0.08,0.01,0.17,0.03,0.25,0.05c0.08,0.02,0.15,0.04,0.23,0.07c0.15,0.05-0.06-0.03,0.03,0.01
  				c0.03,0.02,0.07,0.03,0.1,0.05c0.09,0.04,0.18,0.1,0.26,0.15c0.03,0.02,0.06,0.05,0.1,0.07c0.05,0.04,0.04,0.03-0.02-0.02
  				c0.02,0.02,0.04,0.03,0.06,0.05c0.08,0.06,0.15,0.13,0.22,0.21c0.04,0.04,0.08,0.09,0.12,0.14c0.02,0.02,0.04,0.05,0.06,0.07
  				c-0.04-0.05-0.04-0.05,0,0c0.17,0.24,0.5,0.38,0.77,0.2c0.23-0.15,0.39-0.52,0.2-0.77c-0.31-0.42-0.68-0.79-1.14-1.04
  				c-0.39-0.21-0.8-0.32-1.24-0.37c-0.41-0.05-0.86-0.02-1.25,0.1c-0.28,0.09-0.49,0.4-0.39,0.69
  				C348.02,106,348.32,106.2,348.62,106.11L348.62,106.11z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#0D1E36"}}
            d="M342.05,110.39c-0.5,0.63-1,1.26-1.51,1.88c-0.07,0.09-0.14,0.18-0.21,0.27
  				c-0.13,0.16-0.11,0.46,0.13,0.5c0.72,0.14,1.43,0.29,2.15,0.43c0.16,0.03,0.33-0.05,0.37-0.21c0.04-0.15-0.05-0.34-0.21-0.37
  				c-0.72-0.14-1.43-0.29-2.15-0.43c0.04,0.17,0.09,0.34,0.13,0.5c0.5-0.63,1-1.26,1.51-1.88c0.07-0.09,0.14-0.18,0.21-0.27
  				c0.1-0.13,0.12-0.3,0-0.43C342.37,110.28,342.15,110.26,342.05,110.39L342.05,110.39z"
          />
        </g>
      </g>
      <path
        style={{fill: "#D9D8D6"}}
        d="M334.82,118.01c-0.5,1.56-1.38,4.88-1.38,8.93c0,0,5.16,3.87,13.34,2.15c0,0,0.38-6.05-0.28-9.99
  		c-0.08-0.5-0.39-0.94-0.83-1.2c-1.48-0.87-5.22-2.62-9.55-1.21C335.5,116.89,335.02,117.39,334.82,118.01z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M337.31,118.77c0,0-0.86,1.72-0.86,3.44c0,0,3.44,1.72,7.32,0.43c0,0,0.43-2.58,0-3.44
  		C343.76,119.2,340.75,118.34,337.31,118.77z"
      />
      <polygon
        style={{fill: "#123252"}}
        points="308.62,158.95 304.91,156.34 299.63,164.63 302.93,166.7 	"
      />
      <path
        style={{fill: "#5DBDAF"}}
        d="M376.34,180.17c-0.12-1.02,0.6-1.94,1.62-2.07c1.02-0.12,1.94,0.6,2.07,1.62l-0.94,7.6
  		L376.34,180.17z"
      />
      <g>
        <g>
          <path
            style={{fill: "#D9D8D6"}}
            d="M377.75,178.07c-0.22,0.62-0.44,1.24-0.66,1.86c-0.24,0.68-0.53,1.36-0.73,2.06
  				c-0.14,0.5-0.08,1.15,0.18,1.6c0.52,0.87,2.05,0.95,2.7,0.2c0.5-0.58,0.36-1.46,0.08-2.11c-0.15-0.34-0.64-0.05-0.5,0.29
  				c-0.06-0.15,0.01,0.04,0.03,0.08c0.04,0.13,0.08,0.25,0.1,0.38c0.05,0.28,0.07,0.62-0.08,0.87c-0.22,0.38-0.77,0.44-1.17,0.39
  				c-0.36-0.05-0.63-0.24-0.75-0.59c-0.09-0.26-0.09-0.55-0.06-0.82c0.01-0.09-0.01,0.02,0.01-0.07c0-0.02,0-0.03,0.01-0.05
  				c0.01-0.03,0.02-0.07,0.03-0.1c0.23-0.66,0.47-1.32,0.7-1.98c0.22-0.62,0.44-1.24,0.66-1.86
  				C378.43,177.88,377.87,177.73,377.75,178.07L377.75,178.07z"
          />
        </g>
      </g>
      <path
        style={{fill: "#4A9AD4"}}
        d="M378.03,179.25c-0.28-0.51-0.09-1.14,0.42-1.42c0.51-0.28,1.14-0.09,1.42,0.42l1.08,4.17
  		L378.03,179.25z"
      />
      <path
        style={{fill: "#D9D8D6"}}
        d="M340.09,188.87l4.25-5.84c0.43-0.58,1.1-0.69,1.68-0.26l13.63,9.92c0.58,0.43,0.69,1.1,0.26,1.68
  		l-4.25,5.84c-0.43,0.58-1.1,0.69-1.68,0.26l-13.63-9.92C339.77,190.13,339.66,189.46,340.09,188.87z"
      />
      <path
        style={{fill: "#E3B59D"}}
        d="M349.79,189.28c0.95-0.73,0.93-1.74,0.73-1.99c0,0,0.29-0.53-0.73-1.77
  		c-1.02-1.24,0.05-1.78,0.05-1.78l7.74-0.14c-0.01,1.1,0.61,3.04-0.19,6.27c-0.85,3.46-2.33,4.86-4.5,6.02
  		c-2.89,1.55-4.81-1.11-4.81-1.11C347,192.21,349.17,189.76,349.79,189.28"
      />
      <g>
        <g>
          <path
            style={{fill: "#123252"}}
            d="M303.04,158.4c-0.27,0.22-0.49,0.54-0.71,0.81c-1.02,1.31-1.94,2.69-2.82,4.11
  				c-0.85,1.37-1.66,2.75-2.45,4.15c-0.6,1.05-0.28,2.58,0.83,3.17c1.1,0.58,2.53,0.29,3.17-0.83c1.19-2.09,2.41-4.17,3.75-6.17
  				c0.33-0.5,0.68-0.98,1.04-1.46c0.19-0.26-0.45,0.57-0.16,0.2c0.06-0.07,0.11-0.14,0.17-0.21c0.08-0.09,0.16-0.19,0.24-0.27
  				c0.07-0.07,0.13-0.15,0.21-0.21c0.43-0.35,0.68-1.11,0.68-1.64c0-0.57-0.25-1.25-0.68-1.64c-0.44-0.41-1.02-0.71-1.64-0.68
  				C304.02,157.75,303.54,157.99,303.04,158.4L303.04,158.4z"
          />
        </g>
      </g>
      <path
        style={{fill: "#E3B59D"}}
        d="M304.82,156.32c0.25,0.52,0.46,1.06,0.64,1.65c0.96,3.14-2.02,4.5-2.02,4.5
  		c-0.89,0.19-1.71,0.04-2.41-0.28"
      />
    </g>
  </SuperheroSvg>
)

export default Character14
