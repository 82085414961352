import withStyles from "@mui/styles/withStyles"
import {object, string} from "prop-types"
import {cloneElement} from "react"
import {MdPictureAsPdf, MdPlayCircleFilled, MdPresentToAll} from "react-icons/md"

import UploadedImage from "../../components/uploaded-image/uploaded-image"

const icon = {
  "application/pdf": <MdPictureAsPdf />,
  "video/mp4": <MdPlayCircleFilled />,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": <MdPresentToAll />,
}

const FileIcon = props => {
  if (props.type.match(/^image\//))
    return (
      <div className={props.classes[`${props.variant}VariantContainer`]}>
        <UploadedImage
          alt=""
          src={`${process.env.REACT_APP_API_URL}/files/${props.id}`}
          variant={props.variant}
        />
      </div>
    )

  if (!icon[props.type]) return null

  return cloneElement(icon[props.type], {className: props.classes.icon})
}

const styles = {
  icon: {
    fontSize: 72,
    marginTop: 30,
    opacity: 0.2,
  },
  iconVariantContainer: {
    height: 24,
    width: 100,
    display: "inline-block",
  },
}

FileIcon.defaultProps = {
  variant: "preview",
}

FileIcon.propTypes = {
  classes: object.isRequired,
  id: string.isRequired,
  type: string.isRequired,
  variant: string,
}

export default withStyles(styles)(FileIcon)
