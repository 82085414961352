import {createContext} from "react"

const PreviewContext = createContext({
  isPreviewMode: false,
  togglePreviewMode: () => {},
})

PreviewContext.displayName = "PreviewContext"

export default PreviewContext

export const previewContext = Component => props => (
  <PreviewContext.Consumer>
    {({isPreviewMode}) => <Component {...props} isPreviewMode={isPreviewMode} />}
  </PreviewContext.Consumer>
)
