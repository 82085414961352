import VideoButton, {VideoIcon} from "./video-button"
import VideoReadonly from "./video-readonly"
import VideoSettings from "./video-settings"

export default {
  title: "Video",
  type: "video",
  Button: VideoButton,
  Icon: VideoIcon,
  Readonly: VideoReadonly,
  Settings: VideoSettings,
  suitableFor: ["content_container", "page", "template"],
}
