import CtaButton, {CtaIcon} from "./cta-button"
import CtaReadonly from "./cta-readonly"
import CtaSettings from "./cta-settings"

export default {
  title: "Call to Action",
  type: "cta",
  Button: CtaButton,
  Icon: CtaIcon,
  Readonly: CtaReadonly,
  Settings: CtaSettings,
  suitableFor: ["content_container", "message", "page"],
}
