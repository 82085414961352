import {Grid, Typography} from "@mui/material"
import {object} from "prop-types"

import Expandable from "components/expandable/expandable"

import Feature from "../feature/feature"
import TotalValueOfOpenedProducts from "./total-value-of-opened-products"
import ValueOfOpenedProductsOverTime from "./value-of-opened-products-over-time"

const AdvancedValueDrivers = ({classes}) => {
  return (
    <Feature featureKey="product-opening-value-reporting">
      <div className={classes.section}>
        <Typography className={classes.header} variant="h5">
          Products
        </Typography>

        <Expandable
          label="Hide section"
          labelClassName={classes.hideChartLabel}
          startExpanded={true}
        >
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
              <ValueOfOpenedProductsOverTime />
            </Grid>

            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
              <TotalValueOfOpenedProducts />
            </Grid>
          </Grid>
        </Expandable>
      </div>
    </Feature>
  )
}

AdvancedValueDrivers.propTypes = {
  classes: object,
}

export default AdvancedValueDrivers
