import {push, replace} from "connected-react-router"

import {dismissAppMessage, displayAppMessage} from "actions/app-actions"

import apiActionCreator from "lib/api-action-creator"
import {TEMPLATE_LOADED, TEMPLATE_PAGES_LOADED} from "lib/loading-states"

import {
  loadingStateCompleted,
  loadingStateFailed,
  loadingStateStarted,
} from "../../actions/loading-state-actions"
import {getUsers} from "../../actions/session-actions"
import {
  createTemplate,
  createTemplatePage,
  deletePage,
  deleteTemplate,
  duplicatePage,
  duplicateTemplatePage,
  fetchCsvExport,
  fetchSurveyAnswers,
  fetchTemplate,
  fetchTemplatePages,
  removeTemplatePage,
  sortTemplatePages,
  updatePage,
  updateTemplate,
  updateTemplatePage,
} from "../../lib/api"
import {clearUsers} from "../teams/users-actions"

export const PAGE_CREATED = "PAGE_CREATED"
export const PAGE_DELETED = "PAGE_DELETED"
export const PAGE_UPDATED = "PAGE_UPDATED"
export const PAGE_DUPLICATED = "PAGE_DUPLICATED"

export const TEMPLATE_SET = "TEMPLATE_SET"
export const TEMPLATE_INSIGHTS_SET = "TEMPLATE_INSIGHTS_SET"
export const TEMPLATE_PAGE_LIST_SET = "TEMPLATE_PAGE_LIST_SET"
export const TEMPLATE_PAGE_UPDATED = "TEMPLATE_PAGE_UPDATED"
export const TEMPLATE_PAGE_STASHED = "TEMPLATE_PAGE_STASHED"
export const TEMPLATE_PAGE_CREATED = "TEMPLATE_PAGE_CREATED"
export const TEMPLATE_PAGE_DELETED = "TEMPLATE_PAGE_DELETED"
export const PAGE_MADE_UNIQUE = "PAGE_MADE_UNIQUE"
export const TEMPLATE_UPDATED = "TEMPLATE_UPDATED"
export const TEMPLATE_DELETED = "TEMPLATE_DELETED"
export const TEMPLATE_CLEARED = "TEMPLATE_CLEARED"

export const getTemplateComponentData = (templateId, pageSlug, hash, params = {}) => dispatch =>
  Promise.all([
    dispatch(getUsers()),
    dispatch(getTemplate(templateId)),
    dispatch(getTemplatePages(templateId, params)).then(
      templatePages =>
        templatePages.length &&
        !pageSlug &&
        dispatch(
          replace(`/admin/templates/${templateId}/edit/${templatePages[0].page.newSlug}${hash}`)
        )
    ),
  ])

export const getTemplateWithPages = templateId => dispatch =>
  Promise.all([dispatch(getTemplate(templateId)), dispatch(getTemplatePages(templateId))])

export const clearComponentData = () => dispatch => {
  dispatch(clearUsers())
  dispatch(clearTemplate())
}

export const getTemplate = (templateId, params = {}) => dispatch =>
  fetchTemplate(templateId, params).then(template => {
    dispatch({type: TEMPLATE_SET, payload: template})
    dispatch(loadingStateCompleted(TEMPLATE_LOADED))

    return template
  })

export const clearTemplate = () => ({type: TEMPLATE_CLEARED})

export const getTemplatePages = (templateId, params = {}) => dispatch =>
  fetchTemplatePages(templateId, params.pageOptions).then(templatePages => {
    dispatch({type: TEMPLATE_PAGE_LIST_SET, payload: templatePages})
    dispatch(loadingStateCompleted(TEMPLATE_PAGES_LOADED))

    return templatePages
  })

export const onCreateTemplate = attrs => dispatch =>
  createTemplate(attrs)
    .then(async template => {
      const isDuplicating = !!attrs.sourceTemplateId

      if (isDuplicating) return template

      await createTemplatePage(template.id, {
        order: 1,
        page: {navigationTitle: "Welcome", contentName: `Welcome ${template.name}`},
      })

      return template
    })
    .then(template => dispatch(push(`/admin/templates/${template.id}/edit`)))

export const onUpdateTemplate = apiActionCreator(TEMPLATE_UPDATED, updateTemplate)

export const onDeleteTemplate = templateId => dispatch =>
  deleteTemplate(templateId).then(() => {
    // Backend deletion is async, so we put up a 10 second message to let the user know
    dispatch(displayAppMessage("Your template is being deleted. This may take a few moments."))
    setTimeout(() => {
      dispatch(dismissAppMessage())
    }, 10000)
    dispatch({type: TEMPLATE_DELETED})
    dispatch(push("/admin/templates"))
  })

export const onSortPages = apiActionCreator(TEMPLATE_PAGE_LIST_SET, sortTemplatePages)

export const onDuplicatePage = page => dispatch => {
  dispatch(loadingStateStarted(PAGE_DUPLICATED, "Duplicating page..."))

  return duplicatePage(page.id)
    .then(duplicatedPage => {
      dispatch({type: PAGE_DUPLICATED, payload: duplicatedPage})
      dispatch(loadingStateCompleted(PAGE_DUPLICATED))

      return duplicatedPage
    })
    .catch(error => {
      dispatch(loadingStateFailed(PAGE_DUPLICATED))
      throw error
    })
}

export const onMakePageUnique = oldTemplatePage => async dispatch => {
  const {templateId, id} = oldTemplatePage

  const newTemplatePage = await duplicateTemplatePage(templateId, id)

  await removeTemplatePage(templateId, id)

  await dispatch({type: PAGE_MADE_UNIQUE, payload: {oldTemplatePage, newTemplatePage}})
  await dispatch(push(`/admin/templates/${templateId}/edit/${newTemplatePage.page.newSlug}`))

  return newTemplatePage
}

export const onCreateTemplatePage = (templateId, attrs, shouldDuplicatePage) => async dispatch => {
  dispatch(loadingStateStarted(TEMPLATE_PAGE_CREATED, "Adding page..."))

  let page = attrs.page

  if (shouldDuplicatePage) page = await dispatch(onDuplicatePage(page))

  const params = page?.id ? {...attrs, pageId: page.id} : attrs

  return createTemplatePage(templateId, params)
    .then(templatePage => {
      dispatch({type: TEMPLATE_PAGE_CREATED, payload: templatePage})
      dispatch(loadingStateCompleted(TEMPLATE_PAGE_CREATED))

      return templatePage
    })
    .catch(() => dispatch(loadingStateFailed(TEMPLATE_PAGE_CREATED)))
}

export const onUpdateTemplatePage = (templateId, templatePageId, attrs) => dispatch =>
  updateTemplatePage(templateId, templatePageId, attrs).then(templatePage => {
    dispatch({type: TEMPLATE_PAGE_UPDATED, payload: templatePage})

    return Promise.resolve(templatePage)
  })

export const onUpdatePage = (pageId, attrs) => dispatch =>
  updatePage(pageId, attrs).then(page => dispatch({type: PAGE_UPDATED, payload: page}))

export const onStashPage = (pageId, payload = {}) => ({
  type: TEMPLATE_PAGE_STASHED,
  payload: {pageId, ...payload},
})

export const onDeletePage = pageId => dispatch => {
  dispatch(loadingStateStarted(PAGE_DELETED, "Deleting page..."))
  return deletePage(pageId)
    .then(() => {
      dispatch({type: PAGE_DELETED, payload: {pageId}})
      dispatch(loadingStateCompleted(PAGE_DELETED))
    })
    .catch(() => dispatch(loadingStateFailed(PAGE_DELETED)))
}

export const onDeletePageAndRemoveFromTemplate = (
  templateId,
  templatePageId,
  pageId,
  nextPageSlug
) => dispatch =>
  removeTemplatePage(templateId, templatePageId).then(() =>
    dispatch(onDeletePage(pageId)).then(() => {
      dispatch(push(`/admin/templates/${templateId}/edit/${nextPageSlug}`))
      dispatch({type: TEMPLATE_PAGE_DELETED, payload: pageId})
    })
  )

export const onRemoveTemplatePage = (templateId, templatePageId, nextPageSlug) => dispatch =>
  removeTemplatePage(templateId, templatePageId).then(() => {
    dispatch({type: TEMPLATE_PAGE_DELETED, payload: templatePageId})

    if (nextPageSlug) dispatch(push(`/admin/templates/${templateId}/edit/${nextPageSlug}`))
    else dispatch(push(`/admin/templates/${templateId}/content`))
  })

export const getSurveyAnswers = templateId => () => fetchSurveyAnswers(templateId)

export const getCsvExport = templateId => () => fetchCsvExport(templateId)
