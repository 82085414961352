import * as Sentry from "@sentry/browser"
import sortBy from "lodash/sortBy"

import {
  AUTHENTICATION_ERROR,
  CURRENT_TEAM_SET,
  CURRENT_TEAM_USERS_SET,
  CURRENT_USER_SET,
  SESSION_CLEARED,
} from "../actions/session-actions"
import {TEAM_UPDATED} from "../components/teams/team-actions"
import {USER_DELETED, USER_UPDATED} from "../components/teams/users-actions"

const initialState = {
  teamUsers: [],
}

function session(state = initialState, action) {
  switch (action.type) {
    case CURRENT_TEAM_SET:
      Sentry.configureScope(scope => scope.setTag("team", action.payload.subdomain))

      Sentry.addBreadcrumb({
        message: "Current Team Set",
        level: "debug",
        data: action.payload,
      })

      return {
        ...state,
        team: action.payload,
      }

    case CURRENT_TEAM_USERS_SET:
      return {
        ...state,
        teamUsers: action.payload,
      }

    case USER_UPDATED: {
      const users = state.teamUsers.filter(u => u.id !== action.payload.id).concat([action.payload])

      // update current user if that's the one being updated (i.e. via team/permissions)
      const user = state.user.id === action.payload.id ? action.payload : state.user

      return {
        ...state,
        teamUsers: sortBy(users, u => u.nameLast),
        user,
      }
    }

    case USER_DELETED:
      return {
        ...state,
        teamUsers: state.teamUsers.filter(user => user.id !== action.payload.id),
      }

    case CURRENT_USER_SET:
      Sentry.configureScope(scope =>
        scope.setUser({id: action.payload.id, type: action.payload.type})
      )

      return {
        ...state,
        user: action.payload,
      }

    case SESSION_CLEARED:
      Sentry.configureScope(scope => {
        scope.setTag("team", null)
        scope.setUser(null)
      })

      return {
        ...state,
        team: null,
        user: null,
      }

    case TEAM_UPDATED:
      Sentry.addBreadcrumb({
        message: "Current Team Updated",
        level: "debug",
        data: {prevState: state.team, nextState: action.payload},
      })

      return {
        ...state,
        team: action.payload,
      }

    case AUTHENTICATION_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default session
