import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import debounce from "lodash/debounce"
import throttle from "lodash/throttle"
import {bool, func, object} from "prop-types"
import {Component, createRef} from "react"

import {fetchFonts} from "lib/api"
import {loadGoogleFontSamples} from "lib/fonts"

import mainTheme from "../../themes/main"
import DOSelect from "../do-select/do-select"

const SCROLL_THRESHOLD = 400
const FONTS_PER_PAGE = 20

export class FontDialog extends Component {
  static propTypes = {
    classes: object.isRequired,
    onClose: func.isRequired,
    onSelect: func.isRequired,
    open: bool.isRequired,
  }

  state = {
    filter: "all",
    fonts: [],
    page: 0,
    selectedFont: null,
    sort: "popularity",
    term: "",
    totalResults: 1,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open && this.state.fonts.length === 0)
      this.searchImmediately()
  }

  onSave = () => {
    this.props.onClose()
    this.props.onSelect(this.state.selectedFont)
  }

  onSelectFont = ({target}) =>
    this.setState({selectedFont: this.state.fonts[target.dataset.fontIndex]})

  onSearch = ({target}) => {
    this.resetSearch({term: target.value})
  }

  onFilter = ({target}) => {
    this.resetSearch({filter: target.value})
  }

  onSort = ({target}) => {
    this.resetSearch({sort: target.value})
  }

  resetSearch = params => {
    // Every search entry point gets one of these...
    this.loading = true
    this.setState({...params, page: 0, fonts: [], totalResults: 1}, this.search)
  }

  searchImmediately = () => {
    const {term, filter, sort, page} = this.state

    // This is here to prevent paging while processing an initial search result.
    this.loading = true
    fetchFonts({term, filter, sort, offset: page * FONTS_PER_PAGE, limit: FONTS_PER_PAGE}).then(
      ([newFonts, res]) => {
        loadGoogleFontSamples(newFonts)
        this.setState(
          state => ({
            ...state,
            fonts: state.fonts.concat(newFonts),
            totalResults: parseInt(res.headers.get("x-total-count"), 10),
          }),
          () => (this.loading = false)
        )
      }
    )
  }

  search = debounce(this.searchImmediately, 500)

  fontsList = createRef()

  _onScrollFonts = () => {
    if (this.loading || this.hasLoadedAllResults()) return

    const fontsList = this.fontsList.current
    const distanceFromBottom = fontsList.scrollHeight - fontsList.clientHeight - fontsList.scrollTop

    if (distanceFromBottom < SCROLL_THRESHOLD) {
      // To prevent skipping pages or attempting to load more results before we've
      // finished processing previous requests we set this.loading to true
      // immediately so that this gets blocked from running again.
      this.loading = true
      this.setState(state => ({...state, page: state.page + 1}), this.search)
    }
  }

  onScrollFonts = throttle(this._onScrollFonts, 100)

  hasLoadedAllResults = () => {
    const {fonts, totalResults} = this.state

    return fonts.length >= totalResults
  }

  render() {
    const {classes, open} = this.props
    const {filter, fonts, selectedFont, sort, term} = this.state

    return (
      <ThemeProvider theme={mainTheme}>
        <Dialog fullWidth={true} maxWidth="md" onClose={this.props.onClose} open={open}>
          <DialogTitle>Fonts</DialogTitle>
          <DialogContent>
            <div className={classes.filterBar}>
              <TextField
                autoFocus={true}
                className={classes.filterSearch}
                label="Search"
                margin="normal"
                onChange={this.onSearch}
                type="search"
                value={term}
              />
              <FormControl className={classes.filterSelector} margin="normal">
                <InputLabel>Font Type</InputLabel>
                <DOSelect onChange={this.onFilter} value={filter}>
                  <MenuItem value="all">All Fonts</MenuItem>
                  <MenuItem value="display">Display</MenuItem>
                  <MenuItem value="handwriting">Handwriting</MenuItem>
                  <MenuItem value="monospace">Monospace</MenuItem>
                  <MenuItem value="serif">Serif</MenuItem>
                  <MenuItem value="sans-serif">Sans Serif</MenuItem>
                </DOSelect>
              </FormControl>
              <FormControl className={classes.filterSelector} margin="normal">
                <InputLabel>Sort By</InputLabel>
                <DOSelect onChange={this.onSort} value={sort}>
                  <MenuItem value="popularity">Popularity</MenuItem>
                  <MenuItem value="alpha">Alphabetical</MenuItem>
                  <MenuItem value="date">Date added</MenuItem>
                  <MenuItem value="trending">Trending</MenuItem>
                </DOSelect>
              </FormControl>
            </div>
            <ul className={classes.fontList} onScroll={this.onScrollFonts} ref={this.fontsList}>
              {fonts.map((font, i) => (
                <li
                  className={cx(classes.fontListItem, {
                    [classes.fontListItemSelected]:
                      selectedFont && font.family === selectedFont.family,
                  })}
                  data-font-index={i}
                  key={font.family}
                  onClick={this.onSelectFont}
                  style={{fontFamily: font.family}}
                >
                  {font.family}
                </li>
              ))}
              {!this.hasLoadedAllResults() && (
                <li className={classes.fontListLoading}>
                  <CircularProgress className={classes.progressIndicator} />
                </li>
              )}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} type="button">
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={selectedFont === null}
              onClick={this.onSave}
              type="button"
            >
              Use {selectedFont && `"${selectedFont.family}"`}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    )
  }
}

const styles = theme => ({
  filterBar: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
    alignItems: "baseline",
  },
  filterSearch: {
    flex: 1,
  },
  filterSelector: {
    minWidth: 110,
    marginLeft: theme.spacing(1.5),
  },
  fontList: {
    height: 300,
    overflow: "auto",
    padding: 0,
    margin: 0,
  },
  fontListLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // because a 40x40 box rotated 45° is 56.6px tall and will cause the whole
    // list to bounce if we don't give it a little room to spin (double for
    // bouncy scrolling)
    height: 80,
  },
  fontListItem: {
    listStyle: "none",
    "&+$fontListItem": {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    cursor: "pointer",
    fontSize: "1.2rem",
    padding: `${theme.spacing(2)} 0`,
  },
  fontListItemSelected: {
    color: theme.palette.primary.main,
  },
  fontDescription: {
    fontSize: "0.8rem",
    color: theme.palette.text.hint,
  },
})

export default withStyles(styles)(FontDialog)
