import {Button, Link, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import WebhookSettingsForm from "components/teams/notifications/webhook-settings-form"
import {CLIENT_LIST_SET, TEAM_UPDATED} from "components/teams/team-actions"

import {fetchClients, updateCurrentTeam} from "lib/api"
import SubmissionError from "lib/submission-error"

const WebhookSettings = () => {
  const dispatch = useDispatch()

  const {list: clients} = useSelector(({clients}) => clients)
  const team = useSelector(({session}) => session.team)

  const classes = useStyles()

  const hasActiveClients = clients.filter(c => !c.revokedAt).length > 0

  const onSubmit = payload =>
    updateCurrentTeam(payload)
      .then(team => dispatch({type: TEAM_UPDATED, payload: team}))
      .catch(({body}) => {
        throw new SubmissionError(body.errors)
      })

  useEffect(() => {
    fetchClients().then(clients => dispatch({type: CLIENT_LIST_SET, payload: clients}))
  }, [dispatch])

  return (
    <>
      <Typography className={classes.contentSpacing}>
        You can get webhook notifications of significant events by adding an address here. We'll
        send a <b>POST</b> request to it whenever any of those events occur. If you do not have any
        active API access credentials we will not send any webhooks because we can't sign the
        request for authenticity.
      </Typography>
      <div className={classes.formActions}>
        <Button
          className={classes.docsButton}
          color="primary"
          href={`${process.env.REACT_APP_DOCS_URL}/docs/api`}
          target="_blank"
        >
          Visit Documentation
        </Button>
      </div>
      {hasActiveClients && <WebhookSettingsForm initialValues={team} onSubmit={onSubmit} />}
      {!hasActiveClients && (
        <Typography className={classes.contentSpacing} component="em">
          You need to generate a set of{" "}
          <Link href={`/admin/settings/api`} target="_blank">
            credentials
          </Link>{" "}
          before you can test and add a webhook.
        </Typography>
      )}
    </>
  )
}

const useStyles = makeStyles(() => ({
  docsButton: {
    marginBottom: "1em",
    alignSelf: "flex-end",
    minWidth: 180,
  },
  contentSpacing: {
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
  },
}))

export default WebhookSettings
