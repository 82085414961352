import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object, shape, string} from "prop-types"
import {Component} from "react"
import {MdExpandMore as ExpandIcon} from "react-icons/md"
import {withRouter} from "react-router"

import JourneyDurationInput from "components/journey-duration-input/journey-duration-input"
import ContainerCssSettings from "components/templates/container-css-settings"
import ContainerStyleSettings from "components/templates/container-style-settings"

import {formify} from "lib/hooks/use-form"
import humanize from "lib/string/humanize"

import {templateContentContext} from "../../contexts/template-content-context"
import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import {layoutBases} from "../content-block-editor/layout-basis"
import {targetingPersonalizationStrategies} from "../content-block-editor/targeting-personalization-strategies"
import DOSelect from "../do-select/do-select"
import Feature from "../feature/feature"
import MessageScheduler from "../message-scheduler/message-scheduler"
import BrandingSettings from "./branding-settings"
import CustomCSSEditor from "./custom-css-editor"
import JourneyTitleOptions from "./journey-title-options"
import SupportOwnerOptions from "./support-owner-options"
import ThemeBaseChooser from "./theme-base-chooser"

class TemplateSettings extends Component {
  state = {
    isBrowserOpen: false,
    expanded: "general",
    showCustomCSSEditor: false,
    isBrandingSettingsOpen: false,
  }

  onSelectThemeBase = themeBase => {
    this.props.change("themeBase", themeBase)
  }

  onOpenCustomCssEditor = () => {
    this.setState({showCustomCSSEditor: true})
  }

  onCancelCustomCSS = () => {
    this.setState({showCustomCSSEditor: false})
  }

  onSaveCustomCSS = customCss => {
    this.setState({showCustomCSSEditor: false})
    this.props.change("customCss", customCss)
  }

  onSelectGeneralPanel = () => this.setState({expanded: "general"})
  onSelectJourneyTitlePanel = () => this.setState({expanded: "journey_title"})
  onSelectMessagesPanel = () => this.setState({expanded: "messages"})
  onSelectFooterPanel = () => this.setState({expanded: "footer"})
  onSelectFooterStylesPanel = () => this.setState({expanded: "footer_styles"})
  onSelectFooterCssPanel = () => this.setState({expanded: "footer_css"})
  onSelectThemeBasesPanel = () => this.setState({expanded: "theme_bases"})
  onSelectSupportOwnerPanel = () => this.setState({expanded: "support_owner"})
  onSelectStylesPanel = () => this.setState({expanded: "styles"})

  render() {
    const {classes, field, template, hasNurturingMessagesAndNoTimelineMarkers} = this.props
    const {expanded, showCustomCSSEditor, isBrandingSettingsOpen} = this.state

    if (!template?.id) return null

    return [
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "general"}
        key="general"
        onChange={this.onSelectGeneralPanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>General</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          <form onChange={this.debouncedOnChange}>
            <TextField className={classes.field} fullWidth={true} label="Name" {...field("name")} />
            <Feature featureKey="campaign-approval">
              <TextField
                className={classes.field}
                disabled={true}
                label="Current Status"
                fullWidth={true}
                readOnly={true}
                value={`${humanize(template.status)}`}
              />
            </Feature>
            {!template.hideProgressRing && (
              <TextField
                className={classes.field}
                fullWidth={true}
                InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}
                label="Reward"
                {...field("reward")}
              />
            )}
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    {...field("hideProgressRing", {
                      bool: true,
                      exclude: ["error", "helperText"],
                    })}
                  />
                }
                label="Hide progress ring"
              />
            </FormControl>
            <JourneyDurationInput {...field("journeyDurationHours")} />
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    {...field("isAuthRequired", {bool: true, exclude: ["error", "helperText"]})}
                  />
                }
                label="Authentication Required"
              />
              <FormHelperText className={classes.helperText} margin="dense">
                Require authentication before the contact may view their journey. By enabling this,
                the contact must verify their identity using an email address or phone number on
                file. Using this option also allows you to specify private metadata inside of
                personalizations.
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth={true} margin="normal">
              <InputLabel htmlFor="targetingAccountPriority">
                Single Account Personalizations
              </InputLabel>
              <DOSelect
                className={classes.field}
                id="targetingAccountPriority"
                limitHeight={false}
                renderValue={value =>
                  targetingPersonalizationStrategies.find(selection => selection.value === value)
                    .label
                }
                {...field("targetingAccountPriority", {exclude: ["helperText"]})}
              >
                {targetingPersonalizationStrategies.map(personalization => (
                  <MenuItem
                    classes={{root: classes.menuItem}}
                    key={personalization.value}
                    value={personalization.value}
                  >
                    {<ListItemText primary={personalization.label} />}
                  </MenuItem>
                ))}
              </DOSelect>
              <FormHelperText className={classes.helperText} margin="dense">
                If multiple accounts match your targeting conditions, Single Account
                Personalizations in Text Blocks will show information from the account described
                above.
              </FormHelperText>
            </FormControl>
          </form>
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "styles"}
        key="styles"
        onChange={this.onSelectStylesPanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>Styles</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          {expanded === "styles" && (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({isBrandingSettingsOpen: true})}
              >
                Update Branding
              </Button>
              {isBrandingSettingsOpen && (
                <BrandingSettings onClose={() => this.setState({isBrandingSettingsOpen: false})} />
              )}
            </div>
          )}
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "footer"}
        onChange={this.onSelectFooterPanel}
        key="footer"
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>Footer</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          <div className={classes.expansionDetailInner}>
            <FormControl fullWidth={true} margin="normal">
              <InputLabel htmlFor="layoutBasis">Layout Basis</InputLabel>
              <DOSelect
                className={classes.field}
                id="layoutBasis"
                limitHeight={false}
                renderValue={value =>
                  layoutBases.find(layoutBasis => layoutBasis.value === value).label
                }
                {...field("layoutBasis", {exclude: ["helperText"]})}
              >
                {layoutBases
                  .filter(({value}) => value !== "table")
                  .map(layoutBasis => (
                    <MenuItem
                      classes={{root: classes.menuItem}}
                      key={layoutBasis.value}
                      value={layoutBasis.value}
                    >
                      <ListItemText
                        classes={{secondary: classes.listItemTextSecondary}}
                        primary={layoutBasis.label}
                        secondary={layoutBasis.description}
                      />
                    </MenuItem>
                  ))}
              </DOSelect>
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "footer_styles"}
        key="footer_styles"
        onChange={this.onSelectFooterStylesPanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>Footer Styles</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          <ContainerStyleSettings
            styles={template.styles || {}}
            onSubmit={styles => this.props.change("styles", styles)}
            containerType="template"
          />
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "footer_css"}
        key="footer_css"
        onChange={this.onSelectFooterCssPanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>Custom Footer CSS</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          <ContainerCssSettings
            initialValues={{css: template.css || ""}}
            onSubmit={({css}) => this.props.change("css", css)}
            containerType="template"
          />
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "theme_bases"}
        key="theme_bases"
        onChange={this.onSelectThemeBasesPanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>Theme</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          <div>
            <ThemeBaseChooser
              onSelect={this.onSelectThemeBase}
              options={["default", "simple-horizontal", "clean-slate"]}
              selected={template.themeBase || "default"}
            />
            <Button
              className={classes.customCssButton}
              fullWidth={true}
              onClick={this.onOpenCustomCssEditor}
              variant="outlined"
            >
              Add Custom CSS
            </Button>
            <CustomCSSEditor
              defaultValue={template.customCss}
              onCancel={this.onCancelCustomCSS}
              onSave={this.onSaveCustomCSS}
              open={showCustomCSSEditor}
            />
          </div>
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "messages"}
        key="messages"
        onChange={this.onSelectMessagesPanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Badge color="error" invisible={!hasNurturingMessagesAndNoTimelineMarkers} variant="dot">
            <Typography>Message Scheduler</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          {expanded === "messages" && <MessageScheduler template={template} />}
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "support_owner"}
        key="support_owner"
        onChange={this.onSelectSupportOwnerPanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>Support Owner Options</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          {expanded === "support_owner" && <SupportOwnerOptions classes={classes} />}
        </AccordionDetails>
      </Accordion>,
      <Accordion
        classes={{root: classes.expansionPanel}}
        elevation={0}
        expanded={expanded === "journey_title"}
        key="journey_title"
        onChange={this.onSelectJourneyTitlePanel}
      >
        <AccordionSummary classes={{root: classes.expansionContent}} expandIcon={<ExpandIcon />}>
          <Typography>Journey Title Options</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.expansionContent}}>
          {expanded === "journey_title" && <JourneyTitleOptions classes={classes} />}
        </AccordionDetails>
      </Accordion>,
    ]
  }
}

TemplateSettings.propTypes = {
  change: func.isRequired,
  classes: object.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  hasNurturingMessagesAndNoTimelineMarkers: bool,
  match: shape({
    params: shape({
      pageSlug: string,
    }),
  }),
  setContainer: func.isRequired,
  template: object,
}

const styles = {
  expansionPanel: {},
  expansionContent: {},
  field: {},
  helperText: {},
  select: {},
  menuItem: {},
  listItemTextSecondary: {},
  customCssButton: {},
  expansionDetailInner: {
    width: "100%",
  },
}

export default withRouter(
  formify({
    autoSubmitOnChange: true,
  })(contentBlockEditorConsumer(templateContentContext(withStyles(styles)(TemplateSettings))))
)
