import makeStyles from "@mui/styles/makeStyles"
import {object, shape, string} from "prop-types"
import {useEffect, useState} from "react"

import {useContentBlockEditor} from "components/content-block-editor/content-block-editor-context"
import ContentContainerReadonly from "components/cross-channel-engagement/content-container-readonly"

const useStyles = makeStyles({
  container: {
    maxWidth: 1135,
    margin: "0 auto",
    position: "relative",
    width: "100%",
  },
})

const ContentContainerEditable = ({containerClasses, contentContainer}) => {
  const classes = useStyles()
  const {pushDeviceToList, setDevice} = useContentBlockEditor()
  // The channel-specific device should be selected as default on component mount and should not be
  // reset on an update to the contentContainer (e.g., by saved sidebar edits)
  const [hasSetDefaultDevice, setHasSetDefaultDevice] = useState(false)

  useEffect(() => {
    if (!!contentContainer) {
      const {
        engagementChannel: {
          name,
          properties: {height, width},
        },
      } = contentContainer
      const channelDevice = {
        name,
        type: "channel",
        height: parseInt(height, 10),
        width: parseInt(width, 10),
      }
      pushDeviceToList(channelDevice)

      // Set the channel-specific device as default only after mount at initial load of the contentContainer
      if (!hasSetDefaultDevice) {
        setDevice(channelDevice)
        setHasSetDefaultDevice(true)
      }
    }
  }, [contentContainer, hasSetDefaultDevice, pushDeviceToList, setDevice])

  return (
    <div className={classes.container}>
      <ContentContainerReadonly
        classes={containerClasses}
        contentContainer={contentContainer}
        isEditable={true}
      />
    </div>
  )
}

ContentContainerEditable.propTypes = {
  containerClasses: object.isRequired,
  contentContainer: shape({
    engagementChannel: object.isRequired,
    id: string.isRequired,
    styles: object,
  }).isRequired,
}

export default ContentContainerEditable
