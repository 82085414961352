import {Button} from "@mui/material"
import withStyles from "@mui/styles/withStyles"

const DangerButton = props => <Button {...props} />

const styles = theme => ({
  root: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
})

export default withStyles(styles)(DangerButton)
