import {Button, FormControl, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"
import {Field, reduxForm} from "redux-form"

import {RFTextField} from "components/mui-redux-form/mui-redux-form"

import CenteredBox from "../centered-box/centered-box"
import DocumentTitle from "../document-title/document-title"

const ForgotPassword = ({classes, handleSubmit, submitting, wasSubmitted}) => (
  <CenteredBox title="Forgot Your Password?">
    <DocumentTitle title="Forgot Your Password" />
    <form onSubmit={handleSubmit}>
      {!wasSubmitted ? (
        <>
          <Field component={RFTextField} fullWidth={true} label="Email" name="email" />
          <FormControl className={classes.actions} fullWidth={true} margin="normal">
            <Button color="primary" disabled={submitting} type="submit" variant="contained">
              {submitting ? "Submitting" : "Reset My Password"}
            </Button>
          </FormControl>
        </>
      ) : (
        <Typography>Check your email for instructions on how to reset your password.</Typography>
      )}
    </form>
  </CenteredBox>
)

const styles = {
  actions: {
    display: "block",
    marginTop: 20,
    textAlign: "right",
  },
}

ForgotPassword.propTypes = {
  classes: object.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool,
  wasSubmitted: bool,
}

export default withStyles(styles)(reduxForm({form: "forgot-password"})(ForgotPassword))
