import {TextField} from "@mui/material"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {any, func, string} from "prop-types"

const WrappedDatePicker = ({format, name, onChange, value: currentValue, variant, ...props}) => (
  <DatePicker
    {...props}
    inputFormat={format}
    name={name}
    onChange={value => onChange({target: {name, value}})}
    renderInput={params => <TextField {...params} variant={variant} />}
    value={currentValue || null}
  />
)

WrappedDatePicker.propTypes = {
  format: string,
  name: string.isRequired,
  onChange: func.isRequired,
  value: any,
  variant: string,
}

WrappedDatePicker.defaultProps = {
  format: "MM/dd/yyyy", // MMMM dd yyyy
  variant: "standard",
}

export default WrappedDatePicker
