import {TextField, Tooltip} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object} from "prop-types"
import {useEffect, useState} from "react"

import toggleInlineStyle from "lib/draft-js/toggle-inline-style"

const getFontSizeFromEditorState = editorState => {
  if (!editorState) return 16

  const fontSizeAsString = editorState
    .getCurrentInlineStyle()
    .filter(style => style.startsWith("font-size"))
    .first()

  if (!fontSizeAsString) return 16
  const parsed = parseFloat(fontSizeAsString.replace("font-size-", ""))

  return isNaN(parsed) ? undefined : parsed
}

const FontSizeInput = ({editorState, onSetEditorState, classes}) => {
  const editorFontSize = getFontSizeFromEditorState(editorState)
  const stopPropagation = e => e.stopPropagation()
  const [fontSize, setFontSize] = useState(editorFontSize)

  const onChange = event => {
    const fontSizeAsString = event.currentTarget.value

    if (event.currentTarget.value === "") setFontSize("")

    const parsed = parseFloat(fontSizeAsString)

    if (!isNaN(parsed)) {
      setFontSize(parsed)
      if (parsed >= 6 && parsed <= 100)
        onSetEditorState(toggleInlineStyle(editorState, "font-size", parsed))
    }
  }

  useEffect(() => {
    setFontSize(editorFontSize)
  }, [editorFontSize])

  return (
    <Tooltip title="Font Size">
      <TextField
        className={classes.fontInput}
        inputProps={{
          style: {textAlign: "center"},
        }}
        onChange={onChange}
        onMouseDown={stopPropagation}
        value={fontSize}
      />
    </Tooltip>
  )
}

const styles = () => ({
  fontInput: {
    width: 30,
  },
})

FontSizeInput.propTypes = {
  classes: object,
  editorState: object,
  onSetEditorState: func,
}

export default withStyles(styles)(FontSizeInput)
