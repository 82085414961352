import makeStyles from "@mui/styles/makeStyles"

export const targetingStyles = makeStyles(theme => ({
  addTargetingGroup: {
    margin: `${theme.spacing(2)} 0`,
  },
  actions: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
  groupButtonContainer: {
    "& > :nth-child(n + 1)": {
      marginTop: theme.spacing(1),
    },
    "& > :nth-last-child(n + 2)": {
      marginRight: theme.spacing(1),
    },
  },
  footer: {
    marginTop: 40,
    display: "flex",
  },
  tooltip: {
    marginLeft: 10,
  },
  footerText: {
    marginRight: 5,
  },
  secondBox: {
    marginTop: theme.spacing(8),
  },
  impactContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  counterLarge: {
    color: theme.palette.primary.main,
    display: "inline-block",
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
  },
  linearProgressRoot: {
    height: 10,
    borderRadius: 5,
  },
  linearProgressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  buttonLabel: {
    display: "flex",
    alignItems: "center",
    transition: theme.transitions.create("all"),
  },
  alertIcon: {
    color: theme.palette.error.main,
    display: "inline-block",
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  isTargetingPriorityLabel: {
    marginRight: 0,
  },
  logicalOperator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderLeft: `2px dashed ${theme.palette.brand.mediumGray}`,
    margin: "auto",
    minHeight: 60,
    width: 0,
    "& span": {
      borderRadius: theme.spacing(2.5),
      padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
      backgroundColor: theme.palette.brand.lightGreen,
      border: `2px solid ${theme.palette.common.white}`,
      outline: `2px solid ${theme.palette.brand.lightGreen}`,
      color: theme.palette.common.white,
      fontWeight: "bold",
      fontSize: 18,
      boxShadow: theme.shadows[6],
      "&.or": {
        borderStyle: "dashed",
      },
    },
  },
  rootTargetingControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    backgroundColor: theme.palette.brand.lightestGray,
    boxShadow: theme.shadows[6],
    borderRadius: theme.spacing(0.5),
    marginBottom: 60,
    padding: `${theme.spacing(0.5)} ${theme.spacing()}`,
  },
  journeyCreationStrategy: {
    flex: "0 0 100%",
  },
}))
