import isEqual from "lodash/isEqual"
import {node} from "prop-types"
import {createContext, useCallback, useContext, useReducer} from "react"

import {
  addContentBlock,
  deleteContentBlock,
  sortContentBlocks,
  updateContentBlock,
} from "../../lib/api"
import {actions, initialState, reducer} from "./content-block-editor-reducer"

export const EditorModes = {
  Edit: "Edit",
  Preview: "Preview",
  View: "View",
}

export const editorModePropType = (props, propName, componentName) => {
  const value = props[propName]

  if (!value || EditorModes[value]) return

  return new Error(
    `Invalid prop(${props[propName]}) \`${propName}\` supplied to` +
      ` \`${componentName}\`. Validation failed.`
  )
}

const ContentBlockEditorContext = createContext()
ContentBlockEditorContext.displayName = "ContentBlockEditorContext"

const {Provider} = ContentBlockEditorContext

export const ContentBlockEditorProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setContainer = container => dispatch({type: actions.setContainer, container})
  const setSelectedBlock = contentBlock => dispatch({type: actions.setSelectedBlock, contentBlock})
  const setMode = mode => dispatch({type: actions.setMode, mode})
  // Set active "Viewing As" device
  const setDevice = useCallback(
    (device, scaleToFit) => dispatch({type: actions.setDevice, device, scaleToFit}),
    [dispatch]
  )
  const setSelectedBlockDataChanges = useCallback(
    selectedBlockDataChanges =>
      dispatch({type: actions.setSelectedBlockDataChanges, selectedBlockDataChanges}),
    [dispatch]
  )
  const setSelectedBlockMetaChanges = useCallback(
    selectedBlockMetaChanges =>
      dispatch({type: actions.setSelectedBlockMetaChanges, selectedBlockMetaChanges}),
    [dispatch]
  )
  // Pushes device definition to the top of the stack of devices for previewing
  const pushDeviceToList = useCallback(
    device => dispatch({type: actions.pushDeviceToList, device}),
    [dispatch]
  )

  const onAddContentBlock = (containerId, attrs) => {
    const id = `optimistic-${attrs.slug}`
    dispatch({type: actions.addContentBlock, contentBlock: {...attrs, id}, containerId})
    return addContentBlock(state.containers[containerId], attrs)
      .then(contentBlock => {
        dispatch({type: actions.updateContentBlock, contentBlock, containerId, id})
        return contentBlock
      })
      .catch(() => {
        // TODO flexible-content: add snackbar to explain what happened
        dispatch({type: actions.removeContentBlock, id, containerId})
      })
  }

  const onUpdateContentBlock = attrs => {
    const {id, containerId} = state.selectedBlock
    const nextSelectedBlock = {...attrs}

    if (nextSelectedBlock.data)
      nextSelectedBlock.data = {...state.selectedBlock.data, ...attrs.data}

    const payload = {
      type: actions.updateContentBlock,
      id,
      containerId,
      contentBlock: nextSelectedBlock,
    }

    dispatch(payload)

    return updateContentBlock(state.selectedBlock, nextSelectedBlock)
      .then(contentBlock => {
        setSelectedBlockDataChanges(null)
        dispatch({...payload, contentBlock})
        return contentBlock
      })
      .catch(() => {
        // TODO flexible-content: add snackbar to explain what happened
        dispatch({
          ...payload,
          contentBlock: state.selectedBlock,
        })
      })
  }

  const onSortContentBlocks = (containerId, attrs) => {
    const prevContentBlocks = state.containers[containerId].contentBlocks

    const payload = {
      type: actions.setContentBlocks,
      containerId,
      contentBlocks: attrs,
    }

    dispatch(payload)

    return sortContentBlocks(state.containers[containerId], {order: attrs.map(({id}) => id)})
      .then(contentBlocks => {
        dispatch({...payload, contentBlocks})
        return contentBlocks
      })
      .catch(() => {
        // TODO flexible-content: add snackbar to explain what happened
        dispatch({...payload, contentBlocks: prevContentBlocks})
      })
  }

  const onDeleteContentBlock = () => {
    const {id, containerId} = state.selectedBlock
    dispatch({type: actions.removeContentBlock, id, containerId})
    deleteContentBlock(state.selectedBlock)
  }

  const hasUnsavedContentBlockDataChanges = () =>
    state.selectedBlockDataChanges &&
    state.selectedBlock &&
    !isEqual(state.selectedBlockDataChanges, state.selectedBlock.data)

  const value = {
    ...state,
    dispatch,

    isPage: containerId => state.containers[containerId]?._type === "page",
    isEmail: containerId =>
      state.containers[containerId]?._type === "message" &&
      state.containers[containerId]?.type === "email",
    isTemplateFooter: containerId => state.containers[containerId]?._type === "template",

    isEditMode: state.mode === EditorModes.Edit,
    isPreviewMode: state.mode === EditorModes.Preview,
    isViewMode: state.mode === EditorModes.View,

    setSelectedBlockDataChanges,
    setSelectedBlockMetaChanges,
    hasUnsavedContentBlockDataChanges,

    onAddContentBlock,
    onDeleteContentBlock,
    onSortContentBlocks,
    onUpdateContentBlock,

    setContainer,
    setSelectedBlock,

    pushDeviceToList,
    setDevice,
    setMode,
  }

  return <Provider value={value}>{children}</Provider>
}

ContentBlockEditorProvider.propTypes = {
  children: node.isRequired,
}

export const useContentBlockEditor = () => useContext(ContentBlockEditorContext)

export const provideContentBlockEditor = Component => props => (
  <ContentBlockEditorProvider>
    <Component {...props} />
  </ContentBlockEditorProvider>
)

export const contentBlockEditorConsumer = Component => props => (
  <Component {...useContentBlockEditor()} {...props} />
)
