import {InputBase} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import * as Sentry from "@sentry/browser"
import cx from "classnames"
import debounce from "lodash/debounce"
import {array, arrayOf, bool, func, object, string} from "prop-types"
import {Component, createRef} from "react"

export const actionableWidgetTypes = [
  "card-on-file",
  "cta",
  "enrollment",
  "journey-signup-form",
  "mobile-app-download",
  "survey",
]

const actionText = (completionCondition, pageActionableWidgetTypes) => {
  let pageActionableWidgetType

  switch (completionCondition) {
    case "confirm":
      return "Please confirm when you've completed this step."

    case "objective":
      return null

    case "action":
      if (pageActionableWidgetTypes.length !== 1)
        return "Perform one of the actions on the page to complete this step."

      pageActionableWidgetType = pageActionableWidgetTypes[0]

      switch (pageActionableWidgetType) {
        case "card-on-file":
          return "Add your card to at least one merchant site to complete this step."

        case "cta":
          return "Click the link to complete this step."

        case "enrollment":
          return "Enroll to complete this step."

        case "mobile-app-download":
          return "Install the app to complete this step."

        case "survey":
          return "Complete the survey to complete this step."

        case "journey-signup-form":
          return "Fill in your details to finish this step."

        default:
        // noop (doesn't return, so it skips to default below)
      }

      break

    default:
    // noop (doesn't return, so it skips to default below)
  }

  Sentry.captureException(
    `Page's completion condition (\`${completionCondition}\`) or actionable widget type (\`${pageActionableWidgetType}\`) isn't covered by switches.`
  )
}

export class PageCompletionDescriptor extends Component {
  state = {completionMessage: this.props.completionMessage || ""}

  inputRef = createRef()

  onUpdatePage = debounce((pageId, page) => this.props.onUpdatePage(pageId, page), 1000)

  onUpdatePageCompletionMessage = e => {
    const {pageId} = this.props

    this.onUpdatePage(pageId, {completionMessage: e.target.value})

    this.setState({completionMessage: e.target.value})
  }

  onFocusInput = () => this.inputRef.current.focus()

  render() {
    const {
      classes,
      pageId,
      className,
      completionCondition,
      content,
      contentBlocks,
      pagesCompleted,
      readOnly,
    } = this.props
    const {completionMessage} = this.state
    const isComplete = pagesCompleted.includes(pageId)
    let pageActionableWidgetTypes = []
    let noActionError = false

    if (!completionCondition || completionCondition === "view") return null

    // Support content-block-editor and do-editor for the time being
    if (completionCondition === "action" && contentBlocks) {
      pageActionableWidgetTypes = contentBlocks
        .filter(b => actionableWidgetTypes.includes(b.type))
        .map(b => b.type)
      if (pageActionableWidgetTypes.length === 0) noActionError = true
    } else if (completionCondition === "action" && content && content.blocks) {
      pageActionableWidgetTypes = content.blocks
        .filter(b => b.type === "atomic" && actionableWidgetTypes.includes(b.data.type))
        .map(b => b.data.type)
      if (pageActionableWidgetTypes.length === 0) noActionError = true
    }

    return (
      <div
        className={cx("journey-completion-descriptor", className, {
          "is-complete": isComplete,
          [classes.isComplete]: isComplete,
        })}
      >
        {readOnly ? (
          completionMessage || actionText(completionCondition, pageActionableWidgetTypes)
        ) : (
          <>
            <InputBase
              color="primary"
              fullWidth={true}
              inputProps={{style: {textAlign: "center"}}}
              inputRef={this.inputRef}
              multiline={true}
              onChange={this.onUpdatePageCompletionMessage}
              placeholder={actionText(completionCondition, pageActionableWidgetTypes)}
              value={completionMessage}
              variant="outlined"
            />
            {noActionError && (
              <div className={classes.error}>
                Your page completion condition is <b>Perform Action</b> but your page has no actions
                (survey, CTA, mobile app download, enrollment).
              </div>
            )}
            {!completionMessage && completionCondition === "objective" && (
              <div
                className={`${classes.error} ${classes.clickableDiv}`}
                onClick={this.onFocusInput}
              >
                We can't supply an automatic guide to completing pages when the completion condition
                is set to <b>Complete Objective</b>, click here to add one.
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

const styles = theme => ({
  isComplete: {
    display: "none",
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 10,
    fontStyle: "normal",
  },
  clickableDiv: {
    "&:hover": {
      cursor: "pointer",
    },
  },
})

PageCompletionDescriptor.defaultProps = {
  pagesCompleted: [],
}

PageCompletionDescriptor.propTypes = {
  className: string,
  classes: object.isRequired,
  completionCondition: string,
  completionMessage: string,
  content: object,
  contentBlocks: array,
  onUpdatePage: func,
  pageId: string.isRequired,
  pagesCompleted: arrayOf(string),
  readOnly: bool,
}

export default withStyles(styles)(PageCompletionDescriptor)
