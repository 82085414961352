import {Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {push} from "connected-react-router"
import {func, object} from "prop-types"
import {PureComponent} from "react"
import {connect} from "react-redux"

import Box from "components/box/box"
import DocumentTitle from "components/document-title/document-title"
import Padded from "components/padded/padded"
import DOTable from "components/table/table"
import {tabular} from "components/table/table-state"

import {fetchWebhookLogs} from "lib/api"
import NoData from "lib/svgs/no-data"

import WebhookLogRow from "./webhook-log-row"

const headers = [
  {field: "type", label: "Notification", isDefault: true, sortable: false},
  {field: "url", label: "URL", isDefault: true, sortable: false},
  {field: "statusCode", label: "Response", isDefault: true, sortable: false},
  {field: "numRetries", label: "# Retries", isDefault: true, sortable: false},
  {field: "timestamp", isDefault: true, sortable: true},
]

class WebhookLogs extends PureComponent {
  state = {isTableLoading: false}

  componentDidMount = () => {
    this.refresh({})
  }

  refresh = attrs => {
    const {updateStateForRequest} = this.props
    const params = updateStateForRequest(attrs)

    this.setState({isTableLoading: true}, () => {
      fetchWebhookLogs(params)
        .then(([rows, fetchResponse]) => {
          this.props.setTableState({fetchResponse, rows})
        })
        .finally(() => {
          this.setState({isTableLoading: false})
        })
    })
  }

  render() {
    const {classes, navigateToWebhookDetails} = this.props
    const {isTableLoading} = this.state

    const noResults = (
      <div className={classes.empty}>
        <NoData height="400" />
        <div className={classes.emptyHelpTextContainer}>
          <Typography className={classes.helperText} variant="h2">
            No webhook logs found
          </Typography>
          <Typography variant="caption">
            If you're still stuck,{" "}
            <a href="https://docs.digitalonboarding.com">visit our documentation</a> or{" "}
            <a href="mailto:support@digitalonboarding.com">email our support team</a>.
          </Typography>
        </div>
      </div>
    )

    return (
      <Box>
        <Padded>
          <DocumentTitle title="Team Dashboard - Webhook Logs" />
          <DOTable
            headers={headers}
            isTableLoading={isTableLoading}
            noResults={noResults}
            refresh={this.refresh}
            storageName="webhook-logs"
          >
            {(row, index, columns) => (
              <WebhookLogRow
                columns={columns}
                key={row.id}
                onClick={navigateToWebhookDetails}
                row={row}
              />
            )}
          </DOTable>
          <div className={classes.webhookLogFooter}>
            <Typography variant="caption">
              Webhook logs are retained for 30 days. They can not be recovered after that.
            </Typography>
          </div>
        </Padded>
      </Box>
    )
  }
}

WebhookLogs.propTypes = {
  classes: object.isRequired,
  navigateToWebhookDetails: func.isRequired,
  setTableState: func.isRequired,
  updateStateForRequest: func.isRequired,
}

const styles = theme => ({
  empty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyHelpTextContainer: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.hint,
    "& h2": {
      fontSize: "1.4rem",
    },
  },
  helperText: {
    marginBottom: theme.spacing(2),
  },
})

const mapDispatchToProps = dispatch => ({
  navigateToWebhookDetails: id => {
    dispatch(push(`/admin/team/webhook-logs/${id}`))
  },
})

export default connect(
  null,
  mapDispatchToProps
)(
  withStyles(styles)(
    tabular({
      sortColumn: "timestamp",
      sortDirection: "desc",
      useQueryParams: true,
    })(WebhookLogs)
  )
)
