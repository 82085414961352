import {
  addDays,
  differenceInYears,
  format,
  formatDistance,
  formatRelative,
  isAfter,
  isBefore,
  parseISO,
  subDays,
} from "date-fns"

const dateFormat = "LL/dd/yy"
const timeFormat = "h:mma"

const pFormat = (dateTime, template) =>
  format(dateTime instanceof Date ? dateTime : parseISO(dateTime), template)

const formatDate = (dateTime, f = dateFormat) => pFormat(dateTime, f)
const formatTime = dateTime => pFormat(dateTime, timeFormat)
const formatDateTime = (dateTime, template) =>
  pFormat(dateTime, template || `${dateFormat} @ ${timeFormat}`)

const distanceDateTime = (
  dateString,
  fallback = `${dateFormat} @ ${timeFormat}`,
  relativeTime = timeFormat
) => {
  const date = new Date(dateString)

  if (isNaN(date.valueOf())) return dateString

  if (isBefore(date, subDays(new Date(), 1))) return pFormat(date, fallback)
  else if (isAfter(date, addDays(new Date(), 7))) return pFormat(date, fallback)
  else
    return `${formatDistance(new Date(), date)} ${
      isBefore(date, new Date()) ? "ago" : "from now"
    } (${pFormat(date, relativeTime)})`
}

const relativeDateTime = (dateString, fallback = `${dateFormat} @ ${timeFormat}`) => {
  const date = new Date(dateString)

  if (isNaN(date.valueOf())) return dateString

  if (isBefore(date, subDays(new Date(), 1))) return pFormat(date, fallback)
  else if (isAfter(date, addDays(new Date(), 7))) return pFormat(date, fallback)
  else return formatRelative(date, new Date())
}

/* eslint-disable no-param-reassign */

const to24Hour = timeString => {
  if (timeString.length < 7) timeString = `0${timeString}`.toLowerCase()
  // we need 01:30am instead of 1:30AM

  const cardinality = timeString.substring(5, 7)
  let [hour, minute] = timeString.substring(0, 5).split(":")

  hour = parseInt(hour, 10)
  minute = parseInt(minute, 10)

  if (cardinality === "pm") hour = hour + 12
  if (hour === 12 && cardinality === "am") hour = 0

  if (`${hour}`.length === 1) hour = `0${hour}`
  if (`${minute}`.length === 1) minute = `0${minute}`

  return `${hour}:${minute}`
}

const to12Hour = timeString => {
  if (timeString.length < 5) timeString = `0${timeString}`.toLowerCase()
  // we need 01:30 instead of 1:30

  let [hour, minute] = timeString.substring(0, 5).split(":")

  hour = parseInt(hour, 10)
  minute = parseInt(minute, 10)
  let cardinality = "am"

  if (hour >= 12) {
    hour = hour - 12
    cardinality = "pm"
  }
  if (hour === 0) hour = 12

  if (`${minute}`.length === 1) minute = `0${minute}`

  return `${hour}:${minute}${cardinality}`
}

const age = isoDateString => {
  if (isoDateString) return differenceInYears(new Date(), new Date(isoDateString))
}

const ordinal = day => {
  let nth
  if (4 < day && day < 21) nth = "th"
  else {
    switch (day % 10) {
      case 1:
        nth = "st"
        break
      case 2:
        nth = "nd"
        break
      case 3:
        nth = "rd"
        break
      default:
        nth = "th"
        break
    }
  }
  return `${day}${nth}`
}

/* eslint-enable no-param-reassign */

export {
  age,
  distanceDateTime,
  formatDateTime,
  formatDate,
  formatDistance,
  formatTime,
  ordinal,
  relativeDateTime,
  to12Hour,
  to24Hour,
}
