import isPlainObject from "lodash/isPlainObject"
import memoize from "lodash/memoize"

import {fetchFont} from "../api"

const GOOGLE_FONTS_ROOT = "https://fonts.googleapis.com/css?family="

const fontFamilies = [
  {
    value: "Arial, sans-serif",
    fontFamily: "Arial",
  },
  {
    value: "Comic Sans MS, sans-serif",
    fontFamily: "Comic Sans MS",
  },
  {
    value: "Courier New, serif",
    fontFamily: "Courier New",
  },
  {
    value: "Georgia, serif",
    fontFamily: "Georgia",
  },
  {
    value: "Impact, sans-serif",
    fontFamily: "Impact",
  },
  {
    value: "Lucida Console, serif",
    fontFamily: "Lucida Console",
  },
  {
    value: "Lucida Sans Unicode, sans-serif",
    fontFamily: "Lucida Sans Unicode",
  },
  {
    value: "Roboto, sans-serif",
    fontFamily: "Roboto",
    text: "Roboto (Default)",
  },
  {
    value: "Tahoma, sans-serif",
    fontFamily: "Tahoma",
  },
  {
    value: "Times New Roman, serif",
    fontFamily: "Times New Roman",
  },
  {
    value: "Trebuchet MS, sans-serif",
    fontFamily: "Trebuchet MS",
  },
  {
    value: "Verdana, sans-serif",
    fontFamily: "Verdana",
  },
]

export const safelyGetFontStyles = theme =>
  theme && theme.typography
    ? {fontFamily: theme.typography.fontFamily, fontSize: theme.typography.fontSize}
    : {}

export const getFontFamilies = () => fontFamilies

export const getThemeFontFamilies = theme =>
  Object.entries(theme).reduce((acc, [key, value]) => {
    if (isPlainObject(value)) return [...acc, ...getThemeFontFamilies(value)]

    if (key === "fontFamily" && value) {
      return [...acc, value]
    }

    return acc
  }, [])

export const fontFamilyFallback = font => {
  switch (font.category) {
    case "display":
    case "handwriting":
      return "cursive"
    default:
      return font.category
  }
}

export const googleFontToHref = (font, variants) => {
  const pickedVariants = variants
    .filter(variant => font.variants.includes(variant))
    .map(variant =>
      variant
        .replace(/(\d+\w).*/, "$1")
        .replace("regular", "400")
        .replace("italic", "400i")
    )

  if (!pickedVariants.length) pickedVariants.push(font.variants[0])

  if (pickedVariants.length === 1 && pickedVariants[0] === "400") pickedVariants.pop()

  const suffix = pickedVariants.length ? `:${pickedVariants.join(",")}` : ""

  return `${font.family.replace(/ /g, "+")}${suffix}`
}

export const googleFontFamily = (fontFamily = "") => {
  let [rootFamily] = fontFamily.split(",")
  rootFamily = rootFamily.replace(/"/g, "")

  return !fontFamilies.find(({fontFamily: _fontFamily}) => _fontFamily === rootFamily) && rootFamily
}

export const loadFontStylesheet = href => {
  const link = document.createElement("link")

  link.setAttribute("rel", "stylesheet")
  link.setAttribute("href", `${GOOGLE_FONTS_ROOT}${href}`)
  document.head.appendChild(link)
}

export const loadGoogleFontSamples = fonts =>
  loadFontStylesheet(fonts.map(font => googleFontToHref(font, ["regular"])).join("|"))

export const loadGoogleFont = font =>
  loadFontStylesheet(`${googleFontToHref(font, ["regular", "700", "italic", "700italic"])}`)

export const loadGoogleFontFromFamily = memoize(family =>
  fetchFont(family).then(font => {
    loadGoogleFont(font)
    return `${family}, ${fontFamilyFallback(font)}`
  })
)

export const loadFontsFromTheme = theme => {
  if (!theme) return

  const families = getThemeFontFamilies(theme)

  families
    .map(googleFontFamily)
    .filter(family => !!family) // remove falses (native fonts)
    .map(loadGoogleFontFromFamily)
}

export const loadFontFromTemplate = theme => {
  const fontFamily = !!theme && googleFontFamily(safelyGetFontStyles(theme).fontFamily)

  if (fontFamily) return loadGoogleFontFromFamily(fontFamily)
}
