import createTeamTheme from "themes/team-theme-creator"

// Helper to compile stack of branding colors and custom colors that is used as the palette
//   in the ColorInput where quick-select options are used.
export const compilePalette = theme => {
  if (!theme) {
    return []
  }
  const teamTheme = createTeamTheme(theme)

  const primary = {hex: teamTheme.palette.primary.main, name: "Brand Primary"}
  const secondary = {hex: teamTheme.palette.secondary.main, name: "Brand Secondary"}
  const custom = Array.isArray(teamTheme.custom?.palette) ? teamTheme.custom.palette : []

  return [primary, secondary, ...custom]
}
