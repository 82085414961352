import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import {any, func, object} from "prop-types"

import ColorInput from "components/color-input/color-input"
import DOSelect from "components/do-select/do-select"
import FontSelector from "components/font-selector/font-selector"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"
import ResetLabel from "components/teams/branding-settings/reset-label"

import FontWeightSelector from "./font-weight-selector"

const HalfColumn = ({children}) => (
  <Grid item lg={6} md={12} xs={12}>
    {children}
  </Grid>
)

HalfColumn.propTypes = {
  children: any,
}

const LinkSettings = ({change, field, muiTheme}) => {
  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <Box mb={5} textAlign="center">
          <Typography>
            <Link underline="hover">Sample Link</Link>
          </Typography>
        </Box>
      </ThemeProvider>

      <Grid container spacing={3}>
        <HalfColumn>
          <FormControl fullWidth>
            <InputLabel htmlFor="fontFamily">
              <ResetLabel name="overrides.MuiLink.underlineHover.fontFamily">
                Font Family
              </ResetLabel>
            </InputLabel>
            <FontSelector
              enableGoogleFonts={true}
              id="fontFamily"
              {...field("overrides.MuiLink.underlineHover.fontFamily", {
                defaultValue:
                  muiTheme.overrides?.MuiLink?.underlineHover?.fontFamily ||
                  muiTheme.typography.fontFamily,
                exclude: ["helperText"],
              })}
              onSelect={({value}) =>
                change("overrides.MuiLink.underlineHover.fontFamily", `${value}`)
              }
            />
          </FormControl>
        </HalfColumn>

        <HalfColumn>
          <FontWeightSelector
            label={
              <ResetLabel name="overrides.MuiLink.underlineHover.fontWeight">
                Font Weight
              </ResetLabel>
            }
            {...field(`overrides.MuiLink.underlineHover.fontWeight`, {
              defaultValue:
                muiTheme.overrides?.MuiLink?.underlineHover?.fontWeight ||
                muiTheme.typography.fontWeightRegular,
              exclude: ["helperText"],
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="letterSpacing"
            InputLabelProps={{shrink: true}}
            label={
              <ResetLabel name="overrides.MuiLink.underlineHover.letterSpacing">
                Letter Spacing
              </ResetLabel>
            }
            type="text"
            {...field(`overrides.MuiLink.underlineHover.letterSpacing`, {
              defaultValue: muiTheme.overrides?.MuiLink?.underlineHover?.letterSpacing || "0px",
            })}
          />
        </HalfColumn>

        <HalfColumn>
          <ColorInput
            fullWidth
            id="color"
            input={{
              ...field("overrides.MuiLink.underlineHover.color", {
                defaultValue:
                  muiTheme.overrides?.MuiLink?.underlineHover?.color ||
                  muiTheme.palette.primary.main,
              }),
              onChange: hex => change("overrides.MuiLink.underlineHover.color", hex),
            }}
            label={<ResetLabel name="overrides.MuiLink.underlineHover.color">Color</ResetLabel>}
          />
        </HalfColumn>

        <HalfColumn>
          <FormControl fullWidth>
            <InputLabel htmlFor="textDecorationLine">
              <ResetLabel name="overrides.MuiLink.underlineHover.textDecorationLine">
                Text Decoration
              </ResetLabel>
            </InputLabel>
            <DOSelect
              id="textDecorationLine"
              {...field(`overrides.MuiLink.underlineHover.textDecorationLine`, {
                defaultValue:
                  muiTheme.overrides?.MuiLink?.underlineHover?.textDecorationLine || "none",
                exclude: ["helperText"],
              })}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="underline">Underline</MenuItem>
              <MenuItem value="overline">Overline</MenuItem>
              <MenuItem value="line-through">Line Through</MenuItem>
            </DOSelect>
          </FormControl>
        </HalfColumn>

        {/*NOTE: There is a bug, if you set muiTheme.palette.primary.main and then set overrides.MuiLink.underlineHover.textDecorationLine,
        overrides.MuiLink.underlineHover.&:hover.color will still display muiTheme.palette.primary.main as its default, even though the
        link will be using the overrides.MuiLink.underlineHover.textDecorationLine color on hover */}
        <HalfColumn>
          <ColorInput
            fullWidth
            id="hoverColor"
            input={{
              ...field("overrides.MuiLink.underlineHover.&:hover.color", {
                defaultValue:
                  muiTheme.overrides?.MuiLink?.underlineHover?.["&:hover"]?.color ||
                  muiTheme.palette.primary.main,
              }),
              onChange: hex => change("overrides.MuiLink.underlineHover.&:hover.color", hex),
            }}
            label={
              <ResetLabel name="overrides.MuiLink.underlineHover.&:hover.color">
                Hover Color
              </ResetLabel>
            }
          />
        </HalfColumn>

        <HalfColumn>
          <FormControl fullWidth>
            <InputLabel htmlFor="hoverTextDecorationLine">
              <ResetLabel name="overrides.MuiLink.underlineHover.&:hover.textDecorationLine">
                Hover Text Decoration
              </ResetLabel>
            </InputLabel>
            <DOSelect
              id="hoverTextDecorationLine"
              {...field(`overrides.MuiLink.underlineHover.&:hover.textDecorationLine`, {
                defaultValue:
                  muiTheme.overrides?.MuiLink?.underlineHover?.["&:hover"]?.textDecorationLine ||
                  "underline",
                exclude: ["helperText"],
              })}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="underline">Underline</MenuItem>
              <MenuItem value="overline">Overline</MenuItem>
              <MenuItem value="line-through">Line Through</MenuItem>
            </DOSelect>
          </FormControl>
        </HalfColumn>
      </Grid>
    </>
  )
}

LinkSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  muiTheme: object.isRequired,
}

export default brandingSettings(LinkSettings)
