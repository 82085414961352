import {Button, IconButton, Paper, TextField, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, func, number, object, string} from "prop-types"
import {useEffect, useRef, useState} from "react"
import {useDrag} from "react-dnd"
import {getEmptyImage} from "react-dnd-html5-backend"
import {MdClose as DeleteIcon, MdEmail as EmailIcon, MdSms as SMSIcon} from "react-icons/md"

import {to12Hour} from "lib/date-time-formatters"

import DeletePinDialog from "./delete-pin-dialog"

export const DRAG_TYPE = "MessagePin"

const MessagePin = ({classes, day, id, label, onDelete, onUpdate, stack, type}) => {
  const [isTimeEditorOpen, setIsTimeEditorOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const [{isDragging}, drag, preview] = useDrag(() => ({
    type: DRAG_TYPE,
    item: {
      day,
      id,
      type,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  useEffect(() => {
    preview(getEmptyImage())
  }, [preview])

  const openTimeEditor = () => setIsTimeEditorOpen(true)
  const closeTimeEditor = () => setIsTimeEditorOpen(false)
  const onOpenDeleteDialog = () => setIsDeleteDialogOpen(true)
  const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false)

  const timeEditorRef = useRef(null)

  const onUpdateTime = () => {
    onUpdate({id, time: timeEditorRef.current.value})
    closeTimeEditor()
  }
  const onDeleteTime = () => {
    onCloseDeleteDialog()
    onDelete(id)
  }

  const deletePinMessage =
    day > 0
      ? `Are you sure you want to delete this ${type} pin that sends a message at ${to12Hour(
          label
        )} on day ${day}?`
      : `Are you sure you want to delete this ${type} pin that sends a message immediately upon journeys being created?`

  return (
    <div
      className={cx(classes.messagePin, {
        [classes.stack]: stack,
        [classes.isDragging]: isDragging,
      })}
      data-testid={`message-pin-${type}-${id}`}
    >
      <div className={classes.arrowContainer} data-testid="arrow-container" ref={drag}>
        <div className="arrow" />
        <div className="icon">{type === "sms" ? <SMSIcon /> : <EmailIcon />}</div>
      </div>

      {day === 0 ? (
        <Typography className={cx(classes.label, {showOnHover: !!label})}>immediate</Typography>
      ) : (
        <Typography className={cx(classes.label, {showOnHover: !!label})} onClick={openTimeEditor}>
          {label ? to12Hour(label) : type === "sms" ? "SMS" : "Email"}
        </Typography>
      )}
      {label && (
        <IconButton
          className={cx("delete", classes.deleteIconButton, {showOnHover: !!label})}
          onClick={onOpenDeleteDialog}
          data-testid="delete"
          size="medium"
        >
          <DeleteIcon />
        </IconButton>
      )}
      {isTimeEditorOpen && (
        <div>
          <div className={classes.timeEditorBackdrop} onClick={closeTimeEditor} />
          <Paper className={classes.timeEditor}>
            <TextField
              defaultValue={label}
              inputProps={{step: 300, ref: timeEditorRef}}
              label="Send at:"
              type="time"
            />
            <Button
              className={classes.doneButton}
              color="primary"
              onClick={onUpdateTime}
              size="small"
            >
              save
            </Button>
          </Paper>
        </div>
      )}
      {isDeleteDialogOpen && (
        <DeletePinDialog
          message={deletePinMessage}
          onClose={onCloseDeleteDialog}
          onDelete={onDeleteTime}
        />
      )}
    </div>
  )
}

MessagePin.propTypes = {
  classes: object.isRequired,
  day: number,
  id: string,
  label: string,
  onDelete: func,
  onUpdate: func,
  stack: bool,
  type: string.isRequired,
}

MessagePin.defaultProps = {
  stack: true,
}

const styles = theme => ({
  messagePin: {
    display: "inline-block",
    position: "relative",
    marginRight: theme.spacing(1.25),
    "& .arrow": {
      display: "inline-block",
      width: 0,
      height: 0,
      borderWidth: theme.spacing(2),
      borderStyle: "solid",
      borderColor: "transparent",
      borderRightColor: theme.palette.primary.main,
      borderRightWidth: theme.spacing(3.5),
      borderRadius: 1,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-1.4),
      marginBottom: theme.spacing(0.25),
      marginTop: theme.spacing(0.25),
    },
    "& .icon": {
      display: "inline-flex",
      boxSizing: "content-box",
      background: theme.palette.primary.main,
      fontSize: 22,
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.contrastText,
      height: theme.spacing(4),
      width: theme.spacing(4),
      padding: theme.spacing(0.25, 0.5),
      borderRadius: "50%",
    },
    "& .showOnHover": {
      visibility: "hidden",
    },
    "&:hover .showOnHover": {
      visibility: "visible",
    },
  },
  deleteIconButton: {
    width: 22,
    height: 22,
    fontSize: 13,
    padding: 5,
    position: "absolute",
    right: -5,
    top: -11,
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    zIndex: 4998,
  },
  stack: {
    "& + &": {
      top: theme.spacing(-1 / 2.66),
      marginLeft: theme.spacing(-2.5),
      marginRight: 0,
      "& .arrow": {
        display: "none",
      },
      "& .icon": {
        borderLeftColor: theme.palette.primary.dark,
        borderLeftWidth: 2,
        borderLeftStyle: "solid",
      },
    },
    "& .icon": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      marginRight: theme.spacing(1),
    },
    "&:not(:first-child) .icon": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "&:last-child .icon": {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
      paddingRight: theme.spacing(0.5),
    },
  },
  isDragging: {
    opacity: 0.25,
  },
  arrowContainer: {
    display: "inline-flex",
    alignItems: "flex-end",
    cursor: "move",
  },
  label: {
    textAlign: "right",
    fontSize: theme.typography.fontSize * 0.9,
  },
  timeEditor: {
    bottom: 0,
    display: "flex",
    padding: theme.spacing(2),
    position: "absolute",
    zIndex: 5000,
  },
  timeEditorBackdrop: {
    zIndex: 4999,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  doneButton: {
    alignSelf: "flex-end",
  },
})

export default withStyles(styles)(MessagePin)
