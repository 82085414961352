export default [
  {
    primary: "None",
    secondary: "This means this page will not affect how this journey is completed.",
    value: null,
  },
  {
    primary: "View Page",
    secondary: "When recipients view this page, the page will be reported as completed.",
    value: "view",
  },
  {
    primary: "Confirm Completion",
    secondary:
      "A checkbox will be appended to the end of the content on this page and the page will be reported as completed when the box is checked.",
    value: "confirm",
  },
  {
    primary: "Complete Objective",
    secondary:
      "When you report that the objective associated with this page has been completed, the page will be reported as completed.",
    value: "objective",
  },
  {
    primary: "Perform Action",
    secondary:
      "When a recipient clicks a button in the call to action widget, the enrollment widget, the mobile app download widget from a mobile device, clicks the mobile app download link in the SMS message, or completes a survey, this page will be reported as completed.",
    value: "action",
  },
  {
    primary: "Open Account",
    secondary:
      "When you report that the contact has opened an account of the product associated with this page, the page will be reported as completed",
    value: "open",
  },
]

export const getCombinedProductList = team => {
  if (!team?.products) {
    return []
  }

  return Object.values(
    team.products.reduce(
      (acc, product) =>
        product.trackOpens
          ? {
              ...acc,
              [product.id]: {
                type: "Products",
                value: product.id,
                name: product.name,
              },
              [product.type]: {
                type: "Product Types",
                value: product.type,
                name: product.type,
              },
            }
          : acc,
      {}
    )
  )
    .filter(item => item.value && item.name && item.name.trim() !== "")
    .sort((a, b) => {
      // Sort products by type and then alphabetically by name
      if (a.type !== b.type) {
        return a.type === "Products" ? -1 : 1
      }
      return a.name.localeCompare(b.name)
    })
}

export const calculateInitialProductValue = (
  team,
  initialCompletionProductType,
  initialCompletionProductId
) => {
  const combinedProductList = getCombinedProductList(team)

  if (initialCompletionProductType) {
    return (
      combinedProductList.find(
        product =>
          product.type === "Product Types" && product.value === initialCompletionProductType
      ) || null
    )
  } else if (initialCompletionProductId) {
    return (
      combinedProductList.find(
        product => product.type === "Products" && product.value === initialCompletionProductId
      ) || null
    )
  }

  return null
}
