import {Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {bool, elementType, node, string} from "prop-types"

const DefaultSkeleton = ({variant = "body2", ...props}) => (
  <Typography variant={variant} {...props}>
    <Skeleton component="span" />
    <Skeleton component="span" />
    <Skeleton component="span" />
  </Typography>
)

DefaultSkeleton.propTypes = {
  variant: string,
}

const SkeletonLoader = ({children, loaded, Loader = DefaultSkeleton, ...props}) =>
  loaded ? children : <Loader {...props} />

SkeletonLoader.propTypes = {
  Loader: elementType,
  children: node,
  loaded: bool.isRequired,
}

export default SkeletonLoader
