import {Grid, Typography} from "@mui/material"
import {object} from "prop-types"

import CampaignEngagement from "components/advanced-team-insights/campaign-engagement"
import CampaignEnrollment from "components/advanced-team-insights/campaign-enrollment"
import OverallNumberOfCampaigns from "components/advanced-team-insights/number-of-campaigns"
import Expandable from "components/expandable/expandable"

const AdvancedCampaignPerformance = ({classes}) => {
  return (
    <div className={classes.section}>
      <Typography className={classes.header} variant="h5">
        Overall
      </Typography>
      <div className={classes.expandableContainer}>
        <Expandable label="How we use this section">
          <div>
            <p>The overall section gives a high-level view of campaign efforts and engagement.</p>
            <Typography variant="h6">At a Glance</Typography>
            <ul>
              <li>
                <b>Number of Campaigns</b>: Quickly see how many outreach efforts you have on the
                platform. Use the live filter to focus on those running.
              </li>
              <li>
                <b>Active Campaign Enrollment</b>: View the number campaigns a contact is enrolled
                in to note the level of engagement with each.
              </li>
              <li>
                <b>Active Campaign Engagement</b>: See if contacts enrolled in at least one campaign
                engage with content.
              </li>
            </ul>
            <Typography variant="h6">Use of Page Filters</Typography>
            <ul>
              <li>
                <b>Dates</b>: View high-level engagement metrics for specific time frames to see how
                outreach and engagement fluctuate over time.
              </li>
              <li>
                <b>Objectives</b>: Focus on high-level engagement by specific objectives to see how
                campaigns with those objectives perform.
              </li>
              <li>
                <b>Status</b>: Focus this high-level view on live campaigns to see only current
                performance.
              </li>
            </ul>
          </div>
        </Expandable>
      </div>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={12} md={3} lg={3} xl={3}>
          <OverallNumberOfCampaigns />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={4} lg={4} xl={4}>
          <CampaignEnrollment />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={5} lg={5} xl={5}>
          <CampaignEngagement />
        </Grid>
      </Grid>
    </div>
  )
}

AdvancedCampaignPerformance.propTypes = {
  classes: object,
}

export default AdvancedCampaignPerformance
