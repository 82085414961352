import {node} from "prop-types"
import {createContext, useContext, useEffect, useState} from "react"

import {fetchObjectives} from "lib/api"

const ObjectivesContext = createContext([])

export const ObjectivesContextProvider = ({children}) => {
  const [objectives, setObjectives] = useState([])

  useEffect(() => {
    fetchObjectives().then(objectives => setObjectives(objectives))
  }, [])

  return <ObjectivesContext.Provider value={objectives}>{children}</ObjectivesContext.Provider>
}

ObjectivesContextProvider.propTypes = {
  children: node.isRequired,
}

export const useObjectives = () => useContext(ObjectivesContext)

export const provideObjectives = Component => props => (
  <ObjectivesContextProvider>
    <Component {...props} />
  </ObjectivesContextProvider>
)
