// This file was generated automatically from leaning-out.svg with the following command:
// ./svg-converter.js src/components/superhero/leaning-out.svg
import SuperheroSvg from "./superhero-svg"

const LeaningOut = props => (
  <SuperheroSvg viewBox="0 0 290 388" {...props}>
    <defs>
      <clipPath id="c16d699f-clip-path">
        <rect height="493" style={{fill: "none"}} width="447" />
      </clipPath>
    </defs>
    <g data-name="Layer 1" id="Layer_1">
      <g style={{clipPath: "url(#c16d699f-clip-path)"}}>
        <g>
          <polygon
            points="87 172 33.24 135.82 -243.18 281.61 -170.17 368.62 87 172"
            style={{fill: "#5cbdaf"}}
          />
          <path
            d="M92.53,235.23,99.45,241l16.6,13.79,20.08,16.67,17.36,14.42q4.22,3.51,8.44,7l.12.09a12.64,12.64,0,0,0,5.58,3.26,9.65,9.65,0,0,0,5.05.23,9.4,9.4,0,0,0,4.71-1.51,12.66,12.66,0,0,0,4.54-4.54l12.84-17.22,20.46-27.46,4.7-6.32c1.84-2.46,2.06-6.93,1.28-9.76a12.9,12.9,0,0,0-5.82-7.56,12.73,12.73,0,0,0-9.76-1.28l-3,1.28a12.66,12.66,0,0,0-4.54,4.54l-12.84,17.22-20.46,27.46-4.7,6.32L180,275.05,173,269.31l-16.6-13.79-20.08-16.68L119,224.42c-2.81-2.33-5.61-4.68-8.43-7l-.12-.1c-2.65-2.2-5.39-3.71-9-3.71a12.89,12.89,0,0,0-9,3.71,12.76,12.76,0,0,0-3.71,9c.15,3.18,1.17,6.84,3.71,9Z"
            style={{fill: "#8c715d"}}
          />
          <polygon
            points="-112.84 348.59 -173.34 417.38 -112.16 490.29 3.54 363.12 -64 263.52 -112.84 348.59"
            style={{fill: "#c95d67"}}
          />
          <g>
            <path
              d="M18.62,174.39A38.5,38.5,0,0,1,73,171.75l24.92,23.46a38.48,38.48,0,0,1,2.64,54.37L50,308.62l-72.42-86.3Z"
              style={{fill: "#ec6568"}}
            />
            <path
              d="M-98.66,256.64c.9-.84,1.83-1.66,2.78-2.46a73.51,73.51,0,0,1,60-16.13l.31-.37L36.83,324,10.45,354.79a74,74,0,0,1-6.31,7.36l-3.4,4-.41-.38c-.49.44-1,.89-1.49,1.32a73.69,73.69,0,0,1-93.51,1l-1.41.5-78.25,47.63-61.18-72.9Z"
              style={{fill: "#ec6568"}}
            />
            <polygon
              points="-22.43 222.32 49.99 308.62 36.83 323.98 -35.59 237.68 -22.43 222.32"
              style={{fill: "#0f2c48"}}
            />
          </g>
          <path
            d="M32.33,161.1l-15.74,6.06-37.53,14.45-45.35,17.46-39.16,15.08c-6.34,2.44-12.71,4.79-19,7.32l-.26.1,12.32,21.17,18.12-17.14,28.84-27.28,6.58-6.22a13,13,0,0,0,3.71-9,12.89,12.89,0,0,0-3.71-8.95,12.74,12.74,0,0,0-9-3.71,13.46,13.46,0,0,0-9,3.71l-18.12,17.13-28.84,27.29-6.58,6.22a12.18,12.18,0,0,0-3.48,7.27,12.1,12.1,0,0,0,1.5,8.07,15,15,0,0,0,4.54,4.55c2.45,1.57,7,2.34,9.76,1.27l15.74-6.06,37.54-14.45L-19.37,208,19.8,192.94c6.33-2.44,12.68-4.86,19-7.32l.27-.1c3.16-1.22,5.79-2.79,7.56-5.82a12.66,12.66,0,0,0-4.54-17.33c-2.84-1.5-6.63-2.48-9.76-1.27Z"
            style={{fill: "#8c715d"}}
          />
          <path
            d="M-10,182.8s-2.42-1.2-1.88-2.31,4-2.85,8.08-.75c2.7,1.37,14.75,4.9,21.77-3.16,0,0,5.93-11.07-4.26-18.57-7.65-5.63-14.93-8.46-28.26-5.71C-27,154.88-32.62,160.25-36.42,162L-77,169.32l-5.9,1.06,6.3,24.54,41.4-7.46,4.27-.77c3.18,2.69,10.91,8.62,18.26,9.62A12.75,12.75,0,0,0-5,195.57C-.79,193.86-3.73,185.58-10,182.8Z"
            style={{fill: "#39c"}}
          />
        </g>
        <path
          d="M225.57,185.61s.13,2.69,1.36,2.65,4.21-2.5,3.95-7.09c-.18-3,1.45-15.48,11.65-18.66,0,0,12.53-1,15.29,11.39,2.07,9.27,1.72,17.07-6.17,28.16-7.38,10.36-14.56,13.35-17.71,16.12l-23,34.17c-1.12,1.65-2.23,3.31-3.35,5l-19.92-15.66,23.52-34.88,2.42-3.59c-1.18-4-3.5-13.47-1.45-20.59a12.81,12.81,0,0,1,3.76-6.69C219.15,172.74,225.54,178.76,225.57,185.61Z"
          style={{fill: "#39c"}}
        />
        <g>
          <path
            d="M75.5,53.75a65,65,0,0,0-.12-10.36A66.15,66.15,0,0,1,138.57,56.5,60.77,60.77,0,0,1,75.5,53.75Z"
            style={{fill: "#123252"}}
          />
          <path
            d="M66.86,46.75a61.48,61.48,0,0,0,8.64,7,67.48,67.48,0,0,1-.82,6.71A65.34,65.34,0,0,1,29.34,111.8a66.87,66.87,0,0,1,.75-16.65A66,66,0,0,1,66.86,46.75Z"
            style={{fill: "#123252"}}
          />
          <g>
            <path
              d="M74.68,60.46a67.48,67.48,0,0,0,.82-6.71,60.77,60.77,0,0,0,63.07,2.75,66.47,66.47,0,0,1,17.72,24.6L65.8,83.73A65.1,65.1,0,0,0,74.68,60.46Z"
              style={{fill: "#8c715d"}}
            />
            <path
              d="M104.71,171.9a66.15,66.15,0,0,0,55.68-54.14,65.24,65.24,0,0,0,.94-10.26L30.82,111.29c-.5.17-1,.36-1.48.51,0,.07,0,.15,0,.22a14.43,14.43,0,1,0,8.51,27.26,66,66,0,0,0,40.86,31.18,10.51,10.51,0,0,1-1.08,6.22"
              style={{fill: "#8c715d"}}
            />
            <path
              d="M65.8,83.73l90.49-2.63a65.7,65.7,0,0,1,5,26.4L30.82,111.29A65.5,65.5,0,0,0,65.8,83.73Z"
              style={{fill: "#4f98d6"}}
            />
          </g>
          <path
            d="M75.5,53.75a61.48,61.48,0,0,1-8.64-7,65.84,65.84,0,0,1,8.52-3.36A65,65,0,0,1,75.5,53.75Z"
            style={{fill: "#123252"}}
          />
          <circle cx="129.29" cy="96.94" r="5.32" style={{fill: "#0b1d37"}} />
          <circle cx="83.06" cy="96.63" r="5.32" style={{fill: "#0b1d37"}} />
          <path
            d="M84.38,78a11.79,11.79,0,0,0-4.51-1.22A11.48,11.48,0,0,0,71.12,80c-1.08,1,.53,2.61,1.61,1.61A9.24,9.24,0,0,1,80,79.1a11.51,11.51,0,0,1,2.41.56l.43.16c.28.1-.21-.1.1,0l.28.14c1.29.69,2.45-1.28,1.15-2Z"
            style={{fill: "#0b1d37"}}
          />
          <path
            d="M123.49,78.72l.19-.17.12-.09c.17-.16-.23.15,0,0l.31-.22a8.65,8.65,0,0,1,.93-.54,4.92,4.92,0,0,1,1.08-.45,9.69,9.69,0,0,1,7.6,1.06c1.29.71,2.44-1.26,1.15-2a12,12,0,0,0-9.24-1.33,9.08,9.08,0,0,0-3.7,2.06c-1,1,.56,2.64,1.61,1.61Z"
            style={{fill: "#0b1d37"}}
          />
          <g>
            <path d="M113.21,109.57s-6.44-7.29-12.5.92" style={{fill: "#8c715d"}} />
            <path
              d="M113.62,109.17c-2.25-2.53-6-4-9.25-2.57a10.76,10.76,0,0,0-4.15,3.6c-.44.59.55,1.16,1,.58a8.71,8.71,0,0,1,4.37-3.41,5.91,5.91,0,0,1,3.67.19,9,9,0,0,1,3.56,2.42c.49.55,1.29-.26.81-.81Z"
              style={{fill: "#0b1d37"}}
            />
          </g>
          <path
            d="M38.06,139.78c9.45,17.27,26.69,28.08,47.6,31.71,28.73,5,54.79-9.64,68.28-33.71-4,2.5-19.13,10.75-27.94.08-8.33-10.1-19.29-13.44-29.32-13.74-8.52-.25-24.28-1.33-36.74,12.36,0,0-7.3,8.83-20.26-7.84-5.43-7-6.08-13.08-8.11-19l-2.46-1.81c.27,10.4,4.88,23.35,9,31.91"
            style={{fill: "#123252"}}
          />
          <path
            d="M79.43,144.2a1.17,1.17,0,0,1,1.69-.32,17.92,17.92,0,0,0,7.1,3.55c4.63.92,8.65-.93,9.61.26.54.68-.12,1.5-1.39,2.55a12.38,12.38,0,0,1-9.35,2.32c-4.46-.83-7.81-6-7.81-7.83A1,1,0,0,1,79.43,144.2Z"
            style={{fill: "#fff"}}
          />
          <path
            d="M104.71,171.9A31.86,31.86,0,0,1,101,187L77.65,176.68"
            style={{fill: "#8c715d"}}
          />
        </g>
        <path
          d="M75,182l20.83,15.65S104.72,185.09,105,178l-27-4A14.63,14.63,0,0,1,75,182Z"
          style={{fill: "#ec6568"}}
        />
      </g>
      <g>
        <g>
          <polygon
            points="86.05 227.74 72.38 207 47.58 208.47 36.45 230.68 50.12 251.42 74.92 249.95 86.05 227.74"
            style={{
              fill: "#f5f5f5",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "2.83902266260332px",
            }}
          />
          <polygon
            points="86.05 227.74 72.38 207 47.58 208.47 36.45 230.68 50.12 251.42 74.92 249.95 86.05 227.74"
            style={{fill: "#0f2c48"}}
          />
          <path
            d="M61.46,222.12a7.55,7.55,0,0,0-2-1.48,6.86,6.86,0,1,0-.12,12.3,6.87,6.87,0,1,0,3.77-7.46,8.64,8.64,0,0,0-.68-1.95A4.85,4.85,0,0,0,61.46,222.12Z"
            style={{
              fill: "#f5f5f5",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "2.39609608509317px",
            }}
          />
          <polygon
            points="81.58 228.01 70.37 211.01 50.05 212.21 40.93 230.41 52.13 247.41 72.45 246.21 81.58 228.01"
            style={{
              fill: "none",
              stroke: "#f5f5f5",
              strokeMiterlimit: "10",
              strokeWidth: "1.41920755718666px",
            }}
          />
        </g>
        <g>
          <path
            d="M54.27,225.66a2.39,2.39,0,1,0,3.22-1A2.39,2.39,0,0,0,54.27,225.66Z"
            style={{fill: "#5da4dc"}}
          />
          <path
            d="M67.18,229.53a2.4,2.4,0,1,0,1,3.23A2.39,2.39,0,0,0,67.18,229.53Z"
            style={{fill: "#5da4dc"}}
          />
          <path
            d="M69.2,225.55a6.82,6.82,0,0,0-6-.06,9,9,0,0,0-.67-2l-2.55,5a3.94,3.94,0,1,1-1.74-5.28A4,4,0,0,1,60,225.09l1.51-3a7.18,7.18,0,0,0-2-1.48,6.86,6.86,0,1,0-.16,12.3,6.86,6.86,0,0,0,12.85,1.84A6.87,6.87,0,0,0,69.2,225.55Zm-4.88,9.62a3.92,3.92,0,1,1,5.27-1.72A3.93,3.93,0,0,1,64.32,235.17Z"
            style={{fill: "#5da4dc"}}
          />
        </g>
      </g>
    </g>
  </SuperheroSvg>
)

export default LeaningOut
