import reject from "lodash/reject"

import {CLIENT_CREATED, CLIENT_LIST_SET, CLIENT_REVOKED, NEW_CLIENT_CLEARED} from "./team-actions"

const initialState = {
  list: [],
}

function clients(state = initialState, action) {
  switch (action.type) {
    case CLIENT_LIST_SET:
      return {
        ...state,
        list: action.payload,
      }

    case CLIENT_REVOKED:
      return {
        ...state,
        list: [action.payload].concat(reject(state.list, {id: action.payload.id})),
      }

    case CLIENT_CREATED:
      return {
        ...state,
        new: action.payload,
      }

    case NEW_CLIENT_CLEARED:
      return {
        ...state,
        new: null,
      }

    default:
      return state
  }
}

export default clients
