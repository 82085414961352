import {Typography} from "@mui/material"
import {object} from "prop-types"

import CampaignPerformance from "components/advanced-team-insights/campaign-performance"
import ObjectiveContent from "components/advanced-team-insights/objective-content"
import Pages from "components/advanced-team-insights/pages"

import Messages from "./messages"

const AdvancedEngagement = ({classes}) => {
  return (
    <div className={classes.section}>
      <Typography className={classes.header} variant="h5">
        Engagement
      </Typography>
      <div className={classes.tables} style={{paddingTop: 25}}>
        <CampaignPerformance classes={classes} />
        <ObjectiveContent />
        <Messages classes={classes} />
        <Pages classes={classes} />
      </div>
    </div>
  )
}

AdvancedEngagement.propTypes = {
  classes: object,
}

export default AdvancedEngagement
