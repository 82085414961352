// This file was generated automatically from character-16.svg with the following command:
// ./svg-converter.js src/components/superhero/character-16.svg
import SuperheroSvg from "./superhero-svg"

const Character16 = props => (
  <SuperheroSvg viewBox="255 75 110 230" {...props}>
    <g id="Layer_12">
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M316.13,300.77l-0.04-0.02c-0.08-0.04-1.95-0.88-1.91-2.53c0.03-1.24,1.09-2.45,3.21-3.69
  				l0.06-0.03c0.02-0.01,2.41-0.89,4.91-2.29c3.23-1.81,5.19-3.64,5.65-5.29l0.71,0.19c-1.25,4.46-10.36,7.93-11.08,8.19
  				c-2.22,1.3-2.7,2.27-2.72,2.86c-0.03,0.88,1,1.56,1.45,1.77c12.11,1.62,22.17-1.4,23.3-1.76c1.36-1.73-0.34-7.14-1.12-9.1
  				l0.68-0.42c0.32,0.79,3.02,7.81,0.85,10.19l-0.07,0.08l-0.1,0.03c-0.11,0.04-10.78,3.57-23.75,1.82L316.13,300.77z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M320.02,301l-0.49-0.59c0.82-0.9,1.24-1.84,1.25-2.8c0.01-1.8-1.42-3.11-1.44-3.13l0.5-0.69
  				c0.07,0.06,1.7,1.55,1.69,3.74C321.52,298.74,321.01,299.91,320.02,301z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M321.11,298.41l0.05-0.86c15.61-0.53,19.14-2.83,19.17-2.85l0.34,0.72
  				C340.54,295.52,337.06,297.87,321.11,298.41z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M320.95,297.24l-0.16-0.81c11.18-4.09,11.42-8.91,11.42-8.95l0.75-0.08
  				C332.95,287.61,332.81,292.9,320.95,297.24z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M322.41,294.98c-0.34-0.11-0.64-0.27-0.79-0.35l0.38-0.78c0.52,0.29,1.14,0.47,1.32,0.38
  				c0-0.01,0-0.02,0-0.04c0.04-0.58,0.07-1.08,0.53-1.34c0.33-0.19,0.8-0.2,1.83-0.24c0.73-0.02,1.26-0.04,1.39-0.19
  				c0.14-0.15,0.17-0.76,0.23-1.59c0.04-0.7,0.26-1.15,0.63-1.34c0.73-0.36,1.64,0.64,1.91,0.96l-0.57,0.62
  				c-0.43-0.52-0.97-0.87-1.11-0.8c0,0-0.09,0.09-0.12,0.53c-0.08,1.2-0.11,1.8-0.48,2.21c-0.37,0.41-0.89,0.42-1.94,0.45
  				c-0.74,0.02-1.38,0.04-1.49,0.14c-0.03,0.05-0.04,0.32-0.06,0.56c-0.02,0.33-0.16,0.61-0.39,0.77
  				C323.33,295.19,322.84,295.12,322.41,294.98z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M281.35,299.52l-0.04-0.02c-0.08-0.04-1.95-0.88-1.91-2.53c0.03-1.24,1.09-2.45,3.21-3.69
  				l0.06-0.03c0.02-0.01,2.41-0.89,4.91-2.29c3.23-1.81,5.19-3.64,5.65-5.29l0.71,0.19c-1.25,4.46-10.36,7.93-11.08,8.19
  				c-2.22,1.3-2.7,2.27-2.72,2.86c-0.03,0.88,1,1.56,1.45,1.77c12.11,1.62,22.17-1.4,23.3-1.76c1.36-1.73-0.34-7.14-1.12-9.1
  				l0.68-0.42c0.32,0.79,3.02,7.81,0.85,10.19l-0.07,0.08l-0.1,0.03c-0.11,0.04-10.78,3.57-23.75,1.82L281.35,299.52z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M285.24,299.75l-0.49-0.59c0.82-0.9,1.24-1.84,1.25-2.8c0.01-1.8-1.42-3.11-1.44-3.13l0.5-0.69
  				c0.07,0.06,1.7,1.55,1.69,3.74C286.73,297.49,286.23,298.66,285.24,299.75z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M286.32,297.16l0.05-0.86c15.61-0.53,19.14-2.83,19.17-2.85l0.34,0.72
  				C305.75,294.27,302.28,296.62,286.32,297.16z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M286.16,295.99l-0.16-0.81c11.18-4.09,11.42-8.91,11.42-8.95l0.75-0.08
  				C298.16,286.36,298.02,291.65,286.16,295.99z"
          />
        </g>
        <g>
          <path
            style={{fill: "#EB6669"}}
            d="M287.62,293.73c-0.34-0.11-0.64-0.27-0.79-0.35l0.38-0.78c0.52,0.29,1.14,0.47,1.32,0.38
  				c0-0.01,0-0.02,0-0.04c0.04-0.58,0.07-1.08,0.53-1.34c0.33-0.19,0.8-0.2,1.83-0.24c0.73-0.02,1.26-0.04,1.39-0.19
  				c0.14-0.15,0.17-0.76,0.23-1.59c0.04-0.7,0.26-1.15,0.63-1.34c0.73-0.36,1.64,0.64,1.91,0.96l-0.57,0.62
  				c-0.43-0.52-0.97-0.87-1.11-0.8c0,0-0.09,0.09-0.12,0.53c-0.08,1.2-0.11,1.8-0.48,2.21c-0.37,0.41-0.89,0.42-1.94,0.45
  				c-0.74,0.02-1.38,0.04-1.49,0.14c-0.03,0.05-0.04,0.32-0.06,0.56c-0.02,0.33-0.16,0.61-0.39,0.77
  				C288.55,293.94,288.05,293.87,287.62,293.73z"
          />
        </g>
      </g>
      <g>
        <polygon
          style={{fill: "#CC5F64"}}
          points="316.62,192.23 311.47,259.37 292.11,259.37 300.17,173.39 319.87,170.48 		"
        />
        <path
          style={{fill: "#CC5F64"}}
          d="M311.66,256.96c-0.94,10.31-1.87,20.61-2.81,30.92l-19.49,0c0.98-10.19,1.96-20.38,2.94-30.56
  			C298.76,257.19,305.21,257.07,311.66,256.96z"
        />
      </g>
      <path
        style={{fill: "#EB6669"}}
        d="M315.53,209.32l3.39,53l21.12-0.46l3.19,27.27l-22.64,1.22l-1.67-28.03l21.12-0.46l-7.83-80.62
  		c0.02-0.37,0.03-0.74,0.03-1.12c0.08-6.82-2.1-12.96-5.61-17.12l0.02-0.14c-8.41,1.27-16.81,2.54-25.22,3.81
  		c-0.45,2.24-0.91,4.48-1.36,6.72c-0.09,0.79-0.18,1.58-0.27,2.38c-0.05,0.49-0.11,0.97-0.16,1.45l-0.21,1.55l0.12,0.02
  		c-0.01,0.2-0.02,0.4-0.02,0.6c-0.13,10.8,5.43,19.92,12.91,22.1"
      />
      <g>
        <g>
          <path
            style={{fill: "#E3B59D"}}
            d="M331.14,135.13c0.65,1.23,1.15,1.67,3.58,4.26c3.29,3.5,3,3.37,4.44,4.76
  				c0.84,0.81,1.98,1.8,4.28,3.79c2.97,2.58,2.96,2.47,3.88,3.37c2.36,2.29,3.44,3.92,3.71,3.71c0.14-0.11,0.06-0.75-1.06-3.16
  				c0.64-0.45,1.29-0.89,1.93-1.34c-2.34-0.4-3.73,0.17-4.57,0.79c-0.18,0.13-0.2,0.17-0.54,0.51c-0.08,0.08-0.38,0.38-1,0.98
  				c-0.33,0.32-0.58,0.55-1.02,0.96c-1.21,1.12-1.8,1.66-1.8,1.66c-2.03,1.86-2,1.81-2.21,2.05c-0.57,0.6-2.17,2.5-1.63,1.87l0,0
  				c0,0-1.15,2.89-0.18,4.08c1.06,1.31,4.29,0.07,4.57-0.14c0.26-0.19,0.2-0.2,1.09-1.12c1.11-1.15,1.98-1.94,2.85-2.74
  				c1.03-0.94,1.87-1.65,3.12-2.71c1.73-1.46,2.24-1.85,2.72-2.81c0.26-0.52,0.75-1.55,0.52-2.7c-0.13-0.64-0.44-1.09-1.17-1.94
  				c-1.32-1.54-2.46-2.58-2.61-2.72c-1.12-1.03-0.45-0.51-3.42-3.45c-1.59-1.58-2.87-2.8-3.58-3.48c-1.67-1.59-3.74-3.52-3.77-3.49
  				c-0.02,0.03,2.26,2.26,2.26,2.26c0,0-0.45-0.45-1.34-1.34c-0.68-0.68-1.75-1.75-3.46-3.46c-0.55-0.55-2.41-2.41-2.41-2.41
  				c0,0-3.25-2.05-4.21-1.12C329.33,130.8,330.27,133.49,331.14,135.13z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#E3B59D"}}
            d="M275.65,133.43c0.57-0.05,0.76,2.35,2.23,2.85c1.02,0.35,1.6-0.61,2.36-0.17
  				c1.26,0.74,1.28,4.06,0.82,4.23c-0.24,0.09-0.67-0.66-0.85-1.08c-0.42-0.99-0.55-2.57,0.03-3.15c1.64-1.66,8.98,4.84,10.59,7.23
  				c1.26,1.87,1.45,2.17,1.45,2.17c0.95,1.44,1.06,1.64,1.66,2.43c2.11,2.78,3.59,3.99,3.41,4.35c-0.1,0.2-0.66,0.15-2.84-1.02
  				c-0.38,0.87-0.75,1.75-1.13,2.62c-0.43-2.96,0.03-4.8,0.57-5.96c0.18-0.39,0.16-0.25,1.28-2.14c0.29-0.5,0.58-0.98,2.25-3.91
  				c0.58-1.02,1.18-2.03,1.75-3.07c1.33-2.43,1.4-2.8,1.94-3.19c0.96-0.7,2.42-0.74,3.29,0.22c1.29,1.41,0.73,4.33,0.02,5.88
  				c-0.16,0.35-0.17,0.28-0.96,1.52c-0.99,1.55-1.66,2.75-2.35,3.96c-0.8,1.43-1.41,2.58-2.31,4.3c-1.25,2.38-1.57,3.08-2.41,3.8
  				c-0.45,0.39-1.35,1.14-2.39,0.96c-0.58-0.1-0.98-0.44-1.76-1.29c-1.41-1.53-2.37-2.87-2.5-3.05c-0.95-1.32-0.46-0.52-3.17-4.01
  				c-1.45-1.87-2.58-3.38-3.2-4.22c-1.46-1.97-3.24-4.42-3.21-4.45c0.03-0.04,2.08,2.65,2.08,2.65c0,0-0.41-0.52-1.23-1.57
  				c-0.63-0.8-1.61-2.05-3.18-4.06c-0.5-0.64-2.22-2.84-2.22-2.84C275.66,133.43,275.65,133.43,275.65,133.43
  				c-0.11-0.02-0.93,6.21-1.09,6.19C274.41,139.59,274.75,133.51,275.65,133.43z"
          />
        </g>
      </g>
      <g>
        <defs>
          <rect
            id="SVGID_00000023280588862223685990000004686944452939968444_"
            x="165.34"
            y="62.29"
            width="195.14"
            height="168.49"
          />
        </defs>
        <clipPath id="ed45163b-SVGID_00000095338881444359212920000018321379735990817920_">
          <use
            xlinkHref="#SVGID_00000023280588862223685990000004686944452939968444_"
            style={{overflow: "visible"}}
          />
        </clipPath>
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#0A273F",
          }}
          d="M313.52,133.08
  			c2.03-3.33-2.91-7.69-5.63-5.96c-2.85,1.82-10.74,13.53-10.74,13.53l6.23,4.61C303.39,145.27,312.01,135.56,313.52,133.08z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#E3B59D",
          }}
          d="M324.2,89.47
  			c0.54,0,1.08,0.03,1.61,0.09c0.32,14.17,11.81,21.59,13.98,22.87c-1.9,7.64-8.16,13.26-15.59,13.26
  			c-8.94,0-16.19-8.11-16.19-18.11S315.26,89.48,324.2,89.47z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#E3B59D",
          }}
          d="M325.69,128.02
  			c1.58,0.44,4.15,1.33,4.15,1.33l-0.99,5.28l-7.49,3.53l-7.88-3.97v-6.18c0,0-0.39,0,2.49-0.19s3.59-0.49,3.59-0.49l0-2.56
  			c-6.49-2.37-11.54-9.19-11.54-17.23c0-0.96,0.07-1.9,0.2-2.82l31.35,7.14c0.11,0.07,0.22,0.14,0.33,0.21
  			c0,0.02-0.13,0.44-0.13,0.46c1.39,0.53,2.51,1.61,2.51,3.34c0,2.19-1.58,3.96-3.54,3.96c-0.76,0-1.47-0.27-2.05-0.74
  			c-2.71,3.66-6.68,6.11-11.17,6.51l0,1.73v0.69H325.69z"
        />
        <g style={{clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)"}}>
          <path
            style={{fill: "#123252"}}
            d="M318.24,127.71c-4.76-0.48-8.28-1.32-9.86,0.88c-1.29,1.8-1.99,5.18-2.4,8.13l-3.05,20.61l30.75,0
  				l2.48-16.73c0.32-2.65,0.65-7.25-0.97-9.36c-2.37-3.09-5.32-2.62-8.67-3.09l-6.06,5.81L318.24,127.71z"
          />
          <polygon
            style={{fill: "#123252"}}
            points="334.13,153.97 302.93,157.33 301.74,166.66 332.47,170.87 			"
          />
        </g>

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#0D1E36",
          }}
          cx="316.67"
          cy="103.5"
          rx="1.29"
          ry="1.44"
        />
        <g style={{clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M318.35,99.34c-0.28-0.31-0.69-0.53-1.08-0.67c-0.86-0.3-1.81-0.07-2.57,0.39
  					c-0.31,0.19-0.46,0.64-0.25,0.96c0.2,0.32,0.62,0.45,0.96,0.25c0.1-0.06,0.2-0.12,0.31-0.17c0.16-0.08,0,0,0-0.01
  					c0,0,0.15-0.06,0.17-0.06c0.08-0.03,0.17-0.05,0.26-0.07c0.04-0.01,0.09-0.01,0.13-0.02c-0.18,0.05-0.11,0.01-0.04,0.01
  					c0.08-0.01,0.16-0.01,0.24-0.01c0.03,0,0.07,0,0.1,0.01c0.16,0.01-0.1-0.02-0.04-0.01c0.13,0.03,0.26,0.05,0.38,0.1
  					c0.03,0.01,0.05,0.02,0.08,0.03c-0.23-0.07-0.11-0.05-0.05-0.02c0.04,0.02,0.09,0.04,0.13,0.07c0.02,0.01,0.22,0.16,0.23,0.16
  					c0,0-0.17-0.14-0.09-0.07c0.01,0.01,0.02,0.02,0.03,0.03c0.02,0.02,0.04,0.04,0.06,0.05c0.02,0.02,0.03,0.03,0.05,0.05
  					c0.25,0.27,0.75,0.27,0.99,0C318.62,100.04,318.61,99.63,318.35,99.34L318.35,99.34z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#E3B59D",
          }}
          d="M325.82,89.56
  			c8.18,0.91,14.57,8.63,14.57,18.02c0,0-0.25,4.44-0.75,5.45c0,0-13.91-8.25-13.91-23.25"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#E3B59D",
          }}
          d="M325.84,89.68
  			c0,0-4.47,5.61-13.51,5.61C312.33,95.28,317.47,88.46,325.84,89.68z"
        />

        <ellipse
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#0D1E36",
          }}
          cx="327.73"
          cy="105.56"
          rx="1.29"
          ry="1.44"
        />
        <g style={{clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M327.91,102.13c0.08-0.03,0.17-0.05,0.26-0.06c0.02,0,0.04-0.01,0.07-0.01
  					c0.1-0.02-0.23,0.03-0.1,0.01c0.05-0.01,0.11-0.01,0.16-0.01c0.12-0.01,0.24,0,0.35,0.01c0.16,0.01-0.17-0.03-0.06-0.01
  					c0.04,0.01,0.07,0.01,0.11,0.02c0.06,0.01,0.13,0.03,0.19,0.05c0.08,0.02,0.15,0.05,0.23,0.08c-0.23-0.08-0.08-0.03-0.02-0.01
  					c0.04,0.02,0.08,0.04,0.11,0.06c0.07,0.04,0.13,0.08,0.2,0.13c0.1,0.07-0.14-0.12,0,0c0.04,0.03,0.08,0.07,0.11,0.1
  					c0.08,0.07,0.15,0.15,0.22,0.23c0.02,0.02,0.04,0.04,0.05,0.07c-0.07-0.09-0.08-0.1-0.03-0.04c0.03,0.05,0.07,0.1,0.1,0.15
  					c0.2,0.31,0.63,0.46,0.96,0.25c0.31-0.2,0.47-0.63,0.25-0.96c-0.43-0.66-1.02-1.17-1.78-1.4c-0.5-0.15-1.02-0.15-1.52-0.06
  					c-0.08,0.01-0.16,0.03-0.23,0.06c-0.35,0.12-0.61,0.49-0.49,0.86C327.16,101.99,327.54,102.26,327.91,102.13L327.91,102.13z"
            />
          </g>
        </g>
        <g style={{clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)"}}>
          <path
            style={{fill: "#E3B59D"}}
            d="M278.13,127.77c-0.07-0.82-0.89-4.12-3.33-4.58c0,0-2.88,0.24-3.07,3.69
  				c-0.14,2.59,0.22,4.68,2.42,7.36c2.05,2.51,3.79,3.03,4.61,3.65c2.16,2.77,4.31,5.54,6.47,8.31c0.31,0.4,0.63,0.81,0.94,1.21
  				l3.97-5.02c-2.2-2.83-6.03-7.41-8.24-10.24"
          />
          <g>
            <path
              style={{
                fill: "none",
                stroke: "#B5B6B7",
                strokeWidth: "4.2277",
                strokeMiterlimit: "10",
              }}
              d="M275.93,124.44l2.6,0.86
  					c0,0,2.04,0.51,1.72,2.33l-1.07,6.08c0,0-0.32,1.83-2.36,1.32l-3.01-0.79"
            />
            <path
              style={{
                fill: "#F8D73E",
                stroke: "#B5B6B7",
                strokeWidth: "2.8184",
                strokeMiterlimit: "10",
              }}
              d="M278.68,120.66L275.65,138
  					c-0.21,1.18-3.22,1.42-6.74,0.54c-3.52-0.88-6.2-2.54-5.99-3.72l3.04-17.34"
            />

            <ellipse
              transform="matrix(0.2508 -0.968 0.968 0.2508 88.7536 352.8255)"
              style={{
                fill: "#B5B6B7",
                stroke: "#B5B6B7",
                strokeWidth: "2.8184",
                strokeMiterlimit: "10",
              }}
              cx="272.32"
              cy="119.07"
              rx="2.16"
              ry="6.56"
            />
          </g>
          <path
            style={{fill: "#E3B59D"}}
            d="M277.43,133.95c-0.11,0.38-0.04-0.79-0.07-1.19c-0.77-0.66-2.52-2.32-3.15-4.21
  				c0,0-0.4-0.85-0.26-2.09c0.14-1.24,2.14-1.03,3.1,0.45c0,0,0.4,0.56,0.62,0.34C277.68,127.25,279.23,127.64,277.43,133.95z"
          />
        </g>
        <g style={{clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)"}}>
          <path
            style={{fill: "#123252"}}
            d="M333.74,129.8c-2.91-2.08-5.97,1.32-4.94,4.73c1.03,3.41,12.4,12.87,12.4,12.87l3.4-6.99
  				C344.6,140.4,335.91,131.35,333.74,129.8z"
          />
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#5DBDAF",
          }}
          d="M336.38,99.92
  			l2.85,0.9c2.28,5.72,0.57,11.61,0.57,11.61l-2.63-3.25C337.17,109.19,337.56,103.89,336.38,99.92"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#E3B59D",
          }}
          d="M332.5,165.7
  			c0,0,0.61-0.06,0.62,0.26c0.01,0.32-0.5,1.19-1.55,1.29c-0.69,0.06-3.49,0.94-4.04,3.72c0,0-0.02,3.31,2.83,3.59
  			c2.14,0.21,3.9-0.16,6.29-2.62c2.23-2.3,2.79-4.28,3.37-5.21c2.46-2.42,4.92-4.83,7.38-7.25c0.36-0.35,0.72-0.7,1.07-1.05
  			l-3.87-4.65c-2.51,2.47-5.02,4.93-7.54,7.4c-0.26,0.25-0.52,0.51-0.78,0.76c-0.93-0.17-3.11-0.43-4.69,0.36
  			c0,0-0.79,0.26-1.46,1.22C329.48,164.48,330.94,165.93,332.5,165.7z"
        />
        <polygon
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#123252",
          }}
          points="
  			333.52,160.18 332.95,166.04 329.92,165.28 329.92,161.76 		"
        />
        <g style={{clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)"}}>
          <g>
            <path
              style={{fill: "#0D1E36"}}
              d="M321.71,106.43c-0.46,0.64-0.92,1.29-1.38,1.93c-0.07,0.09-0.13,0.18-0.2,0.28
  					c-0.14,0.19,0,0.5,0.22,0.55c0.66,0.15,1.31,0.29,1.97,0.44c0.2,0.04,0.41-0.06,0.46-0.26c0.05-0.19-0.06-0.42-0.26-0.46
  					c-0.66-0.15-1.31-0.29-1.97-0.44c0.07,0.18,0.15,0.37,0.22,0.55c0.46-0.64,0.92-1.29,1.38-1.93c0.07-0.09,0.13-0.18,0.2-0.28
  					c0.12-0.16,0.03-0.42-0.13-0.51C322.04,106.19,321.83,106.27,321.71,106.43L321.71,106.43z"
            />
          </g>
        </g>
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#5DBDAF",
          }}
          d="M316.99,113.78
  			c-0.46,1.6-1.27,5-1.27,9.16c0,0,4.73,3.97,12.22,2.21c0,0,0.35-6.2-0.26-10.24c-0.08-0.52-0.35-0.97-0.76-1.23
  			c-1.36-0.89-4.79-2.68-8.75-1.24C317.62,112.63,317.18,113.15,316.99,113.78z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#E3B59D",
          }}
          d="M319.28,114.56
  			c0,0-0.79,1.76-0.79,3.53c0,0,3.15,1.76,6.7,0.44c0,0,0.39-2.65,0-3.53C325.19,115,322.43,114.12,319.28,114.56z"
        />
        <path
          style={{
            clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)",
            fill: "#FFFFFF",
          }}
          d="M324.72,115.61
  			c-0.09-0.18-0.32-0.22-0.47-0.1c-0.37,0.29-1.03,0.9-1.98,1.11c-1.29,0.29-2.42-0.29-2.69,0.08c-0.15,0.21,0.03,0.47,0.39,0.8
  			c0.35,0.33,1.43,0.97,2.61,0.73c1.25-0.26,2.18-1.88,2.18-2.45C324.76,115.72,324.74,115.66,324.72,115.61z"
        />
        <g style={{clipPath: "url(#SVGID_00000095338881444359212920000018321379735990817920_)"}}>
          <g>
            <path
              style={{fill: "#FFFFFF"}}
              d="M268.37,122.03c-0.21,0.05-0.41,0.15-0.6,0.25c-0.39,0.22-0.74,0.55-0.95,0.94
  					c-0.18,0.33-0.26,0.66-0.29,1.03c-0.01,0.18,0.09,0.4,0.22,0.53c0.13,0.13,0.34,0.23,0.53,0.22c0.19-0.01,0.4-0.07,0.53-0.22
  					c0.14-0.15,0.2-0.32,0.22-0.53c0-0.04,0.01-0.08,0.01-0.12c-0.01,0.07-0.02,0.13-0.03,0.2c0.02-0.14,0.06-0.27,0.11-0.4
  					c-0.03,0.06-0.05,0.12-0.08,0.18c0.05-0.12,0.12-0.24,0.2-0.34c-0.04,0.05-0.08,0.1-0.12,0.15c0.09-0.11,0.19-0.21,0.31-0.3
  					c-0.05,0.04-0.1,0.08-0.15,0.12c0.14-0.1,0.29-0.19,0.45-0.26c-0.06,0.03-0.12,0.05-0.18,0.08c0.07-0.03,0.14-0.06,0.21-0.07
  					c0.18-0.05,0.36-0.19,0.45-0.34c0.09-0.16,0.13-0.4,0.08-0.58C269.17,122.16,268.78,121.92,268.37,122.03L268.37,122.03z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          style={{fill: "#5198D2"}}
          d="M315.43,92.52l24.7,10.28c0.84,0.34,1.78-0.14,2.08-1.09l0.92-2.83c0.31-0.94-0.13-1.99-0.97-2.33
  			l-24.7-10.28c-0.84-0.34-1.78,0.14-2.08,1.09l-0.92,2.83C314.15,91.13,314.59,92.17,315.43,92.52z"
        />
        <path
          style={{fill: "#5198D2"}}
          d="M316.46,88.74c2.37-7.67,9.85-11.74,16.7-9.08s10.49,11.02,8.12,18.69"
        />
      </g>
    </g>
  </SuperheroSvg>
)

export default Character16
