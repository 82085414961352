import * as Sentry from "@sentry/browser"
import {push} from "connected-react-router"

import apiActionCreator from "lib/api-action-creator"
import {toCamelCase} from "lib/case-converter"
import SubmissionError from "lib/submission-error"

import {loadingStateCompleted, loadingStateStarted} from "../../actions/loading-state-actions"
import {
  createJourney,
  deleteJourney,
  deleteScheduledMessage,
  fetchActions,
  fetchJourney,
  fetchJourneyAnalytics,
  fetchJourneyById,
  fetchScheduledMessages,
  updateJourney,
} from "../../lib/api"
import {getContactAccounts} from "../contacts/contact-actions"
import {
  clearCompletedSurveys,
  getCompletedSurveys,
} from "../content-block-editor/plugins/survey/survey-actions"
import {clearTemplate, getTemplate, getTemplatePages} from "../templates/template-actions"
import {navigableTemplatePages} from "./journey-helpers"

export const CONFIRMED_PAGE_ADDED = "CONFIRMED_PAGE_ADDED"
export const CONFIRMED_PAGE_LIST_SET = "CONFIRMED_PAGE_LIST_SET"
export const JOURNEY_ANALYTIC_LIST_SET = "JOURNEY_ANALYTIC_LIST_SET"
export const JOURNEY_CLEAR = "JOURNEY_CLEAR"
export const JOURNEY_COMPONENT_DATA_SET = "JOURNEY_COMPONENT_DATA_SET"
export const JOURNEY_CONTACT_UPDATED = "JOURNEY_CONTACT_UPDATED"
export const JOURNEY_CREATED = "JOURNEY_CREATED"
export const JOURNEY_DELETED = "JOURNEY_DELETED"
export const JOURNEY_SCHEDULED_MESSAGES_LIST_SET = "JOURNEY_SCHEDULED_MESSAGES_LIST_SET"
export const JOURNEY_SCHEDULED_MESSAGE_DELETED = "JOURNEY_SCHEDULED_MESSAGE_DELETED"
export const JOURNEY_SET = "JOURNEY_SET"
export const JOURNEY_UPDATED = "JOURNEY_UPDATED"
export const PENDING_REWARD_LIST_SET = "PENDING_REWARD_LIST_SET"

export const getJourneyComponentData = (
  journeyId,
  journeySlug,
  pageSlug,
  params = {}
) => dispatch => {
  dispatch(loadingStateStarted(JOURNEY_COMPONENT_DATA_SET))
  // remove breadcrumbs after we figure out why the JOURNEY_SET payload is undefined
  Sentry.addBreadcrumb({
    message: "FETCH JOURNEY BY SLUG",
    level: "debug",
    data: {journeyId, journeySlug, pageSlug, params},
  })
  return fetchJourney(journeySlug)
    .then(journey => {
      Sentry.addBreadcrumb({
        message: "JOURNEY BY SLUG RESPONSE",
        level: "debug",
        data: journey,
      })

      return Promise.all([
        dispatch({type: JOURNEY_SET, payload: journey}),
        dispatch(getJourneyPagesConfirmed(journey.id)),
        dispatch(getTemplate(journey.templateId, params)),
        dispatch(getCompletedSurveys(journey.id)),
        dispatch(getTemplatePages(journey.templateId, params)).then(templatePages => {
          if (pageSlug) return

          // This can be slightly racey... analytics cache happens asynchronously,
          // so if we need this to be more tightly coupled we'll need to get the
          // page_completed actions manually here.
          const firstPageSlug = navigableTemplatePages(
            templatePages,
            journey.analyticsCache?.pagesCompleted
          )[0]?.page?.newSlug

          return dispatch(push(`/journeys/${journeySlug}/${firstPageSlug}`))
        }),
      ])
    })
    .then(() => {
      dispatch(loadingStateCompleted(JOURNEY_COMPONENT_DATA_SET))
    })
}

export const clearComponentData = () => dispatch => {
  dispatch({type: JOURNEY_CLEAR})
  dispatch({type: CONFIRMED_PAGE_LIST_SET, payload: []})
  dispatch(clearTemplate())
  dispatch(clearCompletedSurveys())
}

export const reloadJourneyBySlug = journeySlug => dispatch =>
  fetchJourney(journeySlug).then(journey => {
    dispatch({type: JOURNEY_UPDATED, payload: journey})
  })

export const getAdminJourneyComponentData = id => dispatch =>
  fetchJourneyById(id).then(journey => {
    dispatch({type: JOURNEY_SET, payload: journey})
    dispatch(getTemplate(journey.templateId))
    dispatch(getTemplatePages(journey.templateId, {pageOptions: {isLive: true}}))
    dispatch(getJourneyAnalytics(id))
    dispatch(getJourneyScheduledMessages(journey.templateId, id))
    dispatch(getContactAccounts(journey.contact.id))
  })

export const clearJourney = () => ({type: JOURNEY_CLEAR})

export const getJourneyAnalytics = apiActionCreator(
  JOURNEY_ANALYTIC_LIST_SET,
  fetchJourneyAnalytics
)

export const getJourneyScheduledMessages = apiActionCreator(
  JOURNEY_SCHEDULED_MESSAGES_LIST_SET,
  fetchScheduledMessages
)
export const onDeleteJourneyScheduledMessage = (templateId, journeyId, jobId) => dispatch => {
  deleteScheduledMessage(templateId, journeyId, jobId).then(() => {
    dispatch({type: JOURNEY_SCHEDULED_MESSAGE_DELETED, payload: {id: jobId}})
  })
}
export const onCreateJourney = (templateId, params) => dispatch => {
  const attrs = {
    ...params,
    contact: {
      ...params,
    },
  }

  return createJourney(templateId, attrs)
    .then(journey => {
      dispatch({type: JOURNEY_CREATED, payload: journey})
      dispatch(push(`/admin/journeys/${journey.id}`))

      return journey
    })
    .catch(({status, body}) => {
      if (status === 422) throw new SubmissionError(toCamelCase(body.errors))
      else throw new SubmissionError({uniqueId: body.errors})
    })
}

export const onUpdateContact = attrs => ({type: JOURNEY_CONTACT_UPDATED, payload: attrs})

export const onUpdateJourney = apiActionCreator(JOURNEY_UPDATED, updateJourney)

export const onDeleteJourney = (templateId, journeyId) => dispatch =>
  deleteJourney(templateId, journeyId).then(() => {
    dispatch({type: JOURNEY_DELETED})
    dispatch(push(`/admin/templates/${templateId}`))
  })

export const getJourneyPagesConfirmed = journeyId => dispatch =>
  fetchActions({journeyId, name: "page_confirmed"}).then(actions => {
    dispatch({type: CONFIRMED_PAGE_LIST_SET, payload: actions.map(a => a.pageId)})
  })

export const addConfirmedPage = pageId => ({type: CONFIRMED_PAGE_ADDED, payload: pageId})
