import withStyles from "@mui/styles/withStyles"
import {object} from "prop-types"

const SkipToMainLink = ({classes}) => (
  <a className={classes.skipToMain} href="#main-content">
    Skip to content
  </a>
)

SkipToMainLink.propTypes = {
  classes: object,
}

const styles = {
  skipToMain: {
    position: "absolute",
    left: -999,
    top: 0,
    width: 1,
    height: 1,
    overflow: "hidden",
    zIndex: -999,
    backgroundColor: "#000",
    color: "#fff",
    textDecoration: "none",
    "&:focus, &:active": {
      left: 0,
      width: 200,
      height: "auto",
      overflow: "auto",
      padding: 20,
      textAlign: "center",
      zIndex: 999,
    },
  },
}

export default withStyles(styles)(SkipToMainLink)
