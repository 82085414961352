// This file was generated automatically from character-24.svg with the following command:
// ./svg-converter.js src/components/superhero/character-24.svg
import SuperheroSvg from "./superhero-svg"

const Character24 = props => (
  <SuperheroSvg viewBox="115 70 90 230" {...props}>
    <g id="Layer_5">
      <g>
        <path
          style={{fill: "#E3B59D"}}
          d="M150.23,123.52c-0.59,1.01-1.18,2.02-1.77,3.03c-1.41,2.43-2.83,4.86-4.24,7.29
  			c-1.71,2.94-3.42,5.88-5.14,8.82c-1.49,2.55-2.98,5.11-4.46,7.66c-0.72,1.23-1.41,2.5-2.16,3.7c-0.01,0.02-0.02,0.03-0.03,0.05
  			c-0.7-1.68-1.4-3.36-2.1-5.04c2.31,0.39,4.62,0.77,6.93,1.16c3.65,0.61,7.31,1.22,10.96,1.83c0.85,0.14,1.7,0.28,2.55,0.43
  			c0.74,0.12,1.39,0.39,1.9,1.07c0.45,0.59,0.75,1.54,0.68,2.35c-0.16,1.63-1.43,3.35-2.97,3.09c-2.31-0.39-4.62-0.77-6.93-1.16
  			c-3.65-0.61-7.31-1.22-10.96-1.83c-0.85-0.14-1.7-0.28-2.55-0.43c-0.99-0.17-1.83-0.66-2.3-1.75c-0.44-1.03-0.37-2.32,0.19-3.29
  			c0.59-1.01,1.18-2.02,1.77-3.03c1.41-2.43,2.83-4.86,4.24-7.29c1.71-2.94,3.42-5.88,5.14-8.82c1.49-2.55,2.98-5.11,4.46-7.66
  			c0.72-1.23,1.42-2.49,2.16-3.7c0.01-0.02,0.02-0.03,0.03-0.05c0.83-1.42,2.66-1.98,3.86-0.94
  			C150.7,120.03,151.12,122,150.23,123.52L150.23,123.52z"
        />
      </g>
      <g>
        <path
          style={{fill: "#E3B59D"}}
          d="M163.8,106.21c0.76,1.41,1.51,2.5,1.67,3.09c0.33,1.2,0.3,2.16,0.3,2.43
  			c-0.02,3.4,4.46,7.04,8.57,13.97c0.23,0.39,0.06,0.11,4.22,7.8c0.68,1.26,1.4,2.5,2.04,3.77c0.01,0.02,0.02,0.04,0.03,0.05
  			c-1.8-0.28-3.6-0.55-5.4-0.83c1.88-2.3,3.49-4.11,4.6-5.31c2.78-3.02,5.56-5.8,5.59-5.78c0.02,0.02-2.19,2.58-2.36,2.45
  			c-0.18-0.14,2.45-3.08,5.74-7.02c0,0,0.54-0.63,1.15-0.88c0.23-0.09,0.48-0.16,0.75-0.19c0.19-0.02,1.63-0.16,2.36,0.65
  			c0.22,0.25,0.59,0.82,0.34,2.28c-0.4,2.34-1.89,3.97-2.86,5.08c-2.83,3.24-7.06,8.66-8.26,10.5c-0.46,0.71-0.93,1.07-1.69,1.95
  			c-0.66,0.76-1.52,1.22-2.69,1.05c-1.11-0.16-2.17-0.89-2.7-1.88c-0.56-1.03-1.11-2.06-1.67-3.09c-1.34-2.47-2.67-4.95-4.01-7.42
  			c-1.62-2.99-3.24-5.99-4.85-8.98c-1.41-2.6-2.81-5.2-4.22-7.8c-0.68-1.26-1.38-2.51-2.04-3.77c-0.01-0.02-0.02-0.04-0.03-0.05
  			c-0.78-1.45-0.3-3.3,1.21-3.79C161.07,103.99,162.97,104.66,163.8,106.21z"
        />
      </g>
    </g>
    <g id="Layer_20">
      <polygon
        style={{fill: "#4A8FC2"}}
        points="161.96,182.65 167.13,251.17 186.57,251.17 178.47,163.42 158.69,160.46 	"
      />
      <path
        style={{fill: "#4A9AD4"}}
        d="M161.87,194.91L158.34,249l-21.93-0.47l-3.31,27.83l23.51,1.24l1.73-28.6l-21.93-0.47l8.13-82.27
  		c-0.02-0.38-0.03-0.76-0.04-1.14c-0.08-6.96,2.19-13.23,5.83-17.47l-0.02-0.15l25.15-7.45l2.49,19.17c0.18,0.96,0.32,1.93,0.4,2.93
  		l0.22,1.58l-0.13,0.02c0.01,0.2,0.02,0.41,0.02,0.61c0.13,11.02-5.64,20.33-13.41,22.55"
      />
      <g>
        <path
          style={{fill: "#F05C60"}}
          d="M167.83,101.73c0,0,1.85,25.43-16.13,28.9c0,0-11.7-0.24-17.14-8.04
  			c-4.89-7.02,3.45-36.11,11.96-44.07L167.83,101.73z"
        />
        <g>
          <path
            style={{fill: "#E3B59D"}}
            d="M194.21,114.78c0,0-0.13,0.72,0.14,0.75c0.27,0.03,1.08-0.52,1.3-1.74
  				c0.14-0.8,1.24-4.04,3.69-4.55c0,0,2.83,0.16,2.71,3.51c-0.09,2.52-0.63,4.57-3.04,7.24c-2.25,2.5-4.02,3.05-4.88,3.68
  				c-2.38,2.76-4.76,5.52-7.13,8.28c-0.35,0.4-0.69,0.8-1.04,1.2l-3.49-4.79c2.43-2.82,4.85-5.63,7.28-8.45
  				c0.25-0.29,0.5-0.58,0.75-0.87c-0.02-1.09,0.02-3.67,0.9-5.49c0,0,0.32-0.92,1.23-1.65
  				C193.55,111.17,194.61,112.97,194.21,114.78z"
          />
          <path
            style={{fill: "#0A273F"}}
            d="M172.2,132.13l5.8-3.23l-6.15-12.28c0,0-2.34-3.85-8.66-2.99l1.46,4.93L172.2,132.13z"
          />
          <path
            style={{fill: "#E3B59D"}}
            d="M155.31,74.65c-0.5,0-1,0.03-1.49,0.09c-0.29,14.37-10.93,21.89-12.94,23.19
  				c1.75,7.75,7.55,13.44,14.43,13.44c8.27,0,14.98-8.22,14.98-18.36C170.29,82.87,163.59,74.65,155.31,74.65z"
          />
          <path
            style={{fill: "#E3B59D"}}
            d="M170.29,92.96c0,8.16-4.68,15.07-10.68,17.47l0,2.6l-5.52,0l0-1.75
  				c-4.15-0.41-7.83-2.89-10.34-6.6c-0.54,0.47-1.19,0.75-1.9,0.75c-1.81,0-3.27-1.8-3.27-4.01c0-1.76,0.93-3.25,2.21-3.79
  				c0-0.02-0.01-0.04-0.01-0.06c0.11-0.07,0.21-0.14,0.31-0.21l29.01-7.24C170.22,91.03,170.29,91.99,170.29,92.96z"
          />
          <path
            style={{fill: "#E3B59D"}}
            d="M148.08,88.27l19.91-4.97c1.07,2.06,1.81,4.4,2.13,6.92l-28.72,7.17
  				C144.41,95.41,146.79,92.18,148.08,88.27z"
          />

          <rect
            x="153.93"
            y="112.91"
            transform="matrix(-1 -9.641981e-05 9.641981e-05 -1 313.6469 228.7665)"
            style={{fill: "#123252"}}
            width="5.8"
            height="2.93"
          />
          <path
            style={{fill: "#123252"}}
            d="M144.25,126.48c-0.64-5.74,2.64-11.03,7.32-11.81l10.97-1.09c5.94-0.44,8.79,2.35,9.63,8.97
  				l2.83,20.9l-28.46,0L144.25,126.48z"
          />
          <polygon
            style={{fill: "#123252"}}
            points="146.13,140.04 175,143.44 175.73,148.88 147.3,153.15 			"
          />
          <ellipse style={{fill: "#0D1E36"}} cx="162.29" cy="88.86" rx="1.19" ry="1.46" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M161.71,85.67c0.02-0.03,0.05-0.05,0.07-0.08c0.03-0.03,0.06-0.05,0.09-0.08
  						c0.02-0.02,0.04-0.03,0.06-0.05c0.08-0.07-0.18,0.13-0.07,0.05c0.1-0.07,0.21-0.13,0.32-0.19c0.11-0.05-0.19,0.07-0.05,0.02
  						c0.04-0.01,0.07-0.03,0.11-0.04c0.08-0.02,0.17-0.04,0.25-0.05c-0.06,0.01-0.13,0.02-0.19,0.03c0.15-0.02,0.31-0.02,0.46,0
  						c-0.06-0.01-0.13-0.02-0.19-0.03c0.16,0.02,0.32,0.07,0.47,0.12c0.05,0.02,0.02,0.01-0.08-0.04c0.03,0.01,0.05,0.02,0.08,0.03
  						c0.04,0.02,0.08,0.04,0.12,0.06c0.1,0.05,0.19,0.11,0.28,0.17c0.32,0.21,0.82,0.07,1-0.26c0.19-0.37,0.08-0.77-0.26-1
  						c-0.71-0.47-1.62-0.7-2.44-0.39c-0.38,0.14-0.78,0.36-1.03,0.68c-0.13,0.15-0.21,0.31-0.21,0.51c0,0.18,0.08,0.39,0.21,0.51
  						C160.94,85.91,161.45,85.98,161.71,85.67L161.71,85.67z"
              />
            </g>
          </g>
          <path
            style={{fill: "#F05C60"}}
            d="M153.82,74.74c-7.57,0.92-13.48,8.75-13.48,18.27c0,1.71-1.76,3.97-1.41,5.53
  				C144.55,95.95,153.53,89.11,153.82,74.74z"
          />
          <path
            style={{fill: "#F05C60"}}
            d="M153.8,74.85c0,0,4.14,5.68,12.5,5.68C166.3,80.54,161.54,73.61,153.8,74.85z"
          />
          <ellipse style={{fill: "#0D1E36"}} cx="152.05" cy="90.95" rx="1.19" ry="1.46" />
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M152.25,86.09c-0.25-0.09-0.53-0.12-0.79-0.13c-0.57-0.01-1.16,0.16-1.62,0.5
  						c-0.39,0.29-0.7,0.64-0.95,1.06c-0.2,0.33-0.08,0.81,0.26,1c0.35,0.19,0.79,0.09,1-0.26c0.06-0.09,0.12-0.19,0.18-0.28
  						c0.07-0.11-0.12,0.14,0,0c0.03-0.04,0.06-0.07,0.1-0.11c0.06-0.07,0.13-0.13,0.2-0.19c0.14-0.12-0.12,0.08-0.02,0.02
  						c0.03-0.02,0.07-0.05,0.1-0.07c0.06-0.04,0.13-0.08,0.2-0.11c0.06-0.03,0.2-0.07-0.05,0.02c0.03-0.01,0.07-0.03,0.1-0.04
  						c0.12-0.04,0.25-0.07,0.38-0.09c-0.06,0.01-0.13,0.02-0.19,0.03c0.18-0.02,0.37-0.02,0.56,0c-0.06-0.01-0.13-0.02-0.19-0.03
  						c0.12,0.02,0.25,0.04,0.36,0.08c0.17,0.06,0.41,0.01,0.56-0.07c0.15-0.09,0.29-0.26,0.33-0.43
  						C152.85,86.57,152.63,86.24,152.25,86.09L152.25,86.09z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{fill: "#0D1E36"}}
                d="M157.35,94.67c-0.02,0.06-0.04,0.1-0.01,0.02c0.02-0.05,0.05-0.1,0.08-0.15
  						c0.04-0.07,0.08-0.15,0.13-0.22c0.02-0.04,0.05-0.07,0.07-0.11c0.01-0.02,0.03-0.04,0.04-0.06c0.03-0.05-0.02,0.02-0.02,0.03
  						c0.01-0.01,0.02-0.03,0.03-0.04c0.07-0.08,0.14-0.16,0.22-0.23c0.02-0.02,0.04-0.03,0.06-0.05c0.07-0.06-0.03,0.02,0.01-0.01
  						c0.05-0.03,0.09-0.06,0.14-0.09c0.02-0.01,0.05-0.02,0.07-0.04c0.08-0.04-0.03,0.01,0.02-0.01c0.06-0.02,0.11-0.04,0.17-0.05
  						c0.05-0.01,0.07-0.01,0.01,0c0.03,0,0.06-0.01,0.1-0.01c0.03,0,0.06,0,0.09,0c0.04,0,0.13,0.03,0.04,0
  						c0.07,0.02,0.14,0.03,0.22,0.05c0.02,0.01,0.04,0.01,0.06,0.02c0.06,0.02-0.03-0.01,0.01,0.01c0.04,0.02,0.08,0.04,0.12,0.06
  						c0.21,0.12,0.33,0.22,0.5,0.39c0.34,0.33,0.85-0.19,0.51-0.51c-0.54-0.52-1.3-0.95-2.05-0.64c-0.5,0.2-0.87,0.61-1.13,1.07
  						c-0.07,0.12-0.13,0.25-0.19,0.38c-0.07,0.18,0.08,0.4,0.25,0.45C157.11,94.98,157.28,94.85,157.35,94.67L157.35,94.67z"
              />
            </g>
          </g>
          <path
            style={{fill: "#0D1B30"}}
            d="M154.58,100.06c1.67,0.58,3.69-0.14,4.54-0.51c0.12,0.87,0.22,2.1,0.05,2.89l0,0
  				c-0.13,0.64-0.45,1.21-0.93,1.59c-0.31-0.64-0.75-1.17-1.28-1.54l-1.99,0.04C154.96,102.52,154.76,101.94,154.58,100.06z"
          />
          <path
            style={{fill: "#FFFFFF"}}
            d="M154.49,98.99c0,0,1.72,0.51,4.46-0.46c0,0,0.08,0.43,0.16,1.02c-0.85,0.37-2.87,1.09-4.54,0.51
  				C154.55,99.74,154.52,99.39,154.49,98.99z"
          />
          <path
            style={{fill: "#F05C60"}}
            d="M155.05,102.94c-0.12-0.32-0.19-0.65-0.21-0.97c0.52-0.07,1.05-0.01,1.55,0.2
  				c0.79,0.35,1.43,1.01,1.85,1.87c-0.08,0.06-0.16,0.12-0.25,0.18C156.89,104.85,155.58,104.28,155.05,102.94z"
          />
          <polygon
            style={{fill: "#6B6C6C"}}
            points="151.4,135.68 150.08,158.04 178.55,157.44 180.13,135.07 			"
          />
          <path
            style={{fill: "#E3B59D"}}
            d="M160,153.56c0,0-0.5,0.36-0.37,0.64c0.13,0.28,0.9,0.68,1.75,0.06c0.56-0.41,3.11-1.56,4.74,0.45
  				c0,0,1.44,2.82-0.65,4.98c-1.57,1.62-3.09,2.5-6.01,2.01c-2.72-0.46-4.01-1.78-4.86-2.18c-2.95-0.4-5.9-0.81-8.84-1.22
  				c-0.43-0.06-0.86-0.12-1.29-0.18l1-6.59c3.01,0.41,6.02,0.83,9.03,1.24c0.31,0.04,0.62,0.09,0.93,0.13
  				c0.65-0.77,2.23-2.47,3.8-2.86c0,0,0.72-0.32,1.66,0.06C161.82,150.48,161.31,152.72,160,153.56z"
          />
          <ellipse style={{fill: "#FFFFFF"}} cx="165.5" cy="146.11" rx="1.9" ry="2.33" />
        </g>
        <path
          style={{fill: "#123252"}}
          d="M144.33,134.12l-5.8-3.23l6.15-12.28c0,0,1.41-3.45,6.5-3.66l0.7,5.6L144.33,134.12z"
        />
        <ellipse style={{fill: "#F8D73E"}} cx="157.86" cy="122.27" rx="0.88" ry="1.08" />
        <g>
          <defs>
            <path
              id="SVGID_00000005977395499754159070000016042006527525273248_"
              d="M144.19,126.64c-0.64-5.74,2.64-11.03,7.32-11.81
  					l10.97-1.09c5.94-0.44,8.79,2.35,9.63,8.97l2.83,20.9l-28.46,0L144.19,126.64z"
            />
          </defs>
          <clipPath id="cf1a5f72-SVGID_00000103946104065757989180000015273520874776741521_">
            <use
              xlinkHref="#SVGID_00000005977395499754159070000016042006527525273248_"
              style={{overflow: "visible"}}
            />
          </clipPath>

          <polyline
            style={{
              clipPath: "url(#SVGID_00000103946104065757989180000015273520874776741521_)",
              fill: "none",
              stroke: "#F8D73E",
              strokeMiterlimit: "10",
            }}
            points="
  				161.84,113.27 157.86,121.84 151.61,114.15 			"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M158.34,291.48l0.04-0.01c0.08-0.03,1.93-0.64,2.04-2.3c0.08-1.25-0.8-2.6-2.7-4.12l-0.05-0.04
  				c-0.02-0.01-2.21-1.2-4.45-2.93c-2.9-2.23-4.58-4.32-4.86-6.04l-0.68,0.1c0.76,4.64,9.08,9.29,9.73,9.65
  				c1.98,1.59,2.35,2.63,2.32,3.23c-0.06,0.89-1.1,1.44-1.54,1.6c-11.65,0.1-20.9-4.23-21.93-4.72c-1.13-1.92,0.98-7.15,1.91-9.03
  				l-0.6-0.5c-0.37,0.76-3.59,7.48-1.76,10.15l0.06,0.09l0.09,0.05c0.1,0.05,9.89,4.96,22.35,4.84L158.34,291.48z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M154.63,291.22l0.51-0.53c-0.69-1.01-1.01-2.02-0.92-2.98c0.16-1.81,1.64-2.96,1.66-2.97
  				l-0.4-0.75c-0.07,0.05-1.76,1.35-1.95,3.55C153.42,288.76,153.79,289.99,154.63,291.22z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M153.84,288.47l0.03-0.86c-14.75-2.52-17.89-5.27-17.92-5.3l-0.39,0.67
  				C135.69,283.1,138.76,285.9,153.84,288.47z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M154.1,287.31l0.23-0.79c-10.22-5.53-10-10.42-9.99-10.47l-0.69-0.17
  				C143.62,276.1,143.27,281.44,154.1,287.31z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M152.92,284.85c0.34-0.07,0.64-0.19,0.78-0.25l-0.29-0.82c-0.51,0.22-1.14,0.33-1.29,0.21
  				c0-0.01,0-0.02,0-0.04c0.02-0.58,0.04-1.09-0.37-1.41c-0.29-0.23-0.73-0.31-1.71-0.47c-0.69-0.12-1.19-0.2-1.31-0.37
  				c-0.12-0.17-0.1-0.79-0.07-1.64c0.02-0.71-0.14-1.19-0.47-1.42c-0.66-0.45-1.61,0.44-1.89,0.72l0.47,0.69
  				c0.45-0.46,1-0.75,1.13-0.66c0,0,0.08,0.1,0.07,0.56c-0.08,2.43,0.07,2.65,2.04,2.98c0.7,0.12,1.3,0.22,1.41,0.33
  				c0.02,0.06,0.01,0.33,0,0.58c-0.01,0.34,0.09,0.63,0.3,0.82C152.03,284.94,152.51,284.94,152.92,284.85z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M194.32,291.11l0.04-0.01c0.08-0.03,1.9-0.72,1.94-2.38c0.03-1.25-0.91-2.56-2.87-4l-0.06-0.03
  				c-0.02-0.01-2.25-1.11-4.56-2.74c-2.99-2.11-4.76-4.12-5.11-5.83l-0.68,0.12c0.96,4.61,9.46,8.9,10.13,9.24
  				c2.05,1.51,2.46,2.53,2.45,3.13c-0.02,0.9-1.04,1.49-1.47,1.66c-11.63,0.59-21.06-3.35-22.11-3.8c-1.21-1.87,0.68-7.18,1.53-9.11
  				l-0.62-0.48c-0.34,0.77-3.27,7.63-1.34,10.22l0.07,0.09l0.09,0.04c0.1,0.05,10.09,4.54,22.53,3.9L194.32,291.11z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M190.6,291.01l0.49-0.55c-0.74-0.98-1.09-1.97-1.05-2.94c0.08-1.82,1.52-3.03,1.53-3.04
  				l-0.43-0.74c-0.07,0.06-1.7,1.42-1.8,3.63C189.29,288.6,189.72,289.82,190.6,291.01z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M189.7,288.3l-0.01-0.86c-14.84-1.9-18.09-4.52-18.12-4.55l-0.36,0.69
  				C171.34,283.69,174.53,286.36,189.7,288.3z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M189.91,287.12l0.19-0.8c-10.44-5.1-10.42-9.99-10.42-10.04l-0.7-0.14
  				C178.97,276.36,178.84,281.71,189.91,287.12z"
          />
        </g>
        <g>
          <path
            style={{fill: "#5EBDAE"}}
            d="M188.63,284.72c0.33-0.08,0.63-0.22,0.77-0.29l-0.32-0.81c-0.5,0.25-1.12,0.38-1.28,0.27
  				c0-0.01,0-0.02,0-0.04c-0.01-0.58-0.01-1.09-0.43-1.4c-0.3-0.22-0.75-0.27-1.72-0.4c-0.7-0.09-1.2-0.15-1.32-0.31
  				s-0.13-0.78-0.14-1.63c-0.01-0.71-0.19-1.18-0.53-1.4c-0.68-0.43-1.59,0.51-1.86,0.8l0.5,0.67c0.43-0.48,0.97-0.79,1.1-0.71
  				c0,0,0.09,0.1,0.09,0.55c0.02,2.43,0.18,2.65,2.16,2.89c0.7,0.09,1.31,0.16,1.42,0.27c0.02,0.06,0.03,0.33,0.03,0.57
  				c0,0.34,0.12,0.62,0.33,0.81C187.74,284.84,188.22,284.82,188.63,284.72z"
          />
        </g>
      </g>
      <path
        style={{fill: "#4A8FC2"}}
        d="M166.76,246.24c0.83,10.25,1.66,20.5,2.5,30.76h19.17c-0.62-8.61-1.24-17.22-1.86-25.83
  		C179.97,249.52,173.36,247.88,166.76,246.24z"
      />
    </g>
  </SuperheroSvg>
)

export default Character24
