import AccountsTableButton, {AccountsTableIcon} from "./accounts-table-button"
import AccountsTableReadonly from "./accounts-table-readonly"
import AccountsTableSettings from "./accounts-table-settings"

export default {
  title: "Accounts Table",
  type: "accounts-table",
  Button: AccountsTableButton,
  Icon: AccountsTableIcon,
  Readonly: AccountsTableReadonly,
  Settings: AccountsTableSettings,
  suitableFor: ["content_container", "page"],
}
