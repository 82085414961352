import {Tooltip as MUITooltip, Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {object} from "prop-types"
import {useEffect, useState} from "react"
import {
  Bar,
  BarChart,
  Tooltip as ChartTooltip,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
} from "recharts"

import {useInsights} from "components/template-insights/insights-context"
import {INSIGHTS_COLORS} from "components/template-insights/insights-helpers"

import {fetchInsightCrossChannelBarchart} from "lib/api"
import pluralize from "lib/string/pluralize"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const labelFormatter = (value, name, props) =>
  Boolean(props?.payload?.tooltip) ? [value, props?.payload?.tooltip] : [value, name]

const legend = {
  top: 20,
  right: -220 - 18,
  width: 210,
}

// NB: By using a single stackId we can provide a different color for each bar while using the same dataKey
const stackId = "a"

const emptyBarchart = [
  {name: "Completed 1+ campaign objective(s)", value: 0},
  {name: "Interacted with 1+ microsite widget(s)", value: 0},
  {name: "Clicked on a page CTA button(s)", value: 0},
  {name: "Viewed 2+ microsite pages", value: 0},
  {name: "Viewed only 1 microsite page", value: 0},
].map(datum => ({...datum, [datum.name]: datum.value}))

const SkeletonLoader = () => (
  <div style={{display: "flex"}}>
    <div>
      <Skeleton variant="circular" width={150} height={150} />
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginLeft: 25,
        width: 330,
      }}
    >
      <Skeleton variant="rectangular" width={60} height={280} />
      <Skeleton variant="rectangular" width={60} height={280} />
      <Skeleton variant="rectangular" width={60} height={280} />
      <Skeleton variant="rectangular" width={60} height={280} />
      <Skeleton variant="rectangular" width={60} height={280} />
    </div>
  </div>
)

const CrossChannelBarchart = ({classes}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const _classes = useStyles()

  const {
    addInsightsCache,
    insights: {cross_channel_engagement_barchart},
    templateId,
    filter,
  } = useInsights()

  const data =
    cross_channel_engagement_barchart?.value?.result?.map(datum => ({
      ...datum,
      [datum.name]: datum.value,
    })) ?? emptyBarchart
  const engagedJourneys = cross_channel_engagement_barchart?.value?.totalJourneys ?? 0
  const engagedLabel = pluralize("journey", engagedJourneys)

  useEffect(() => {
    fetchInsightCrossChannelBarchart(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return <SkeletonLoader />

  return (
    <div className={_classes.container}>
      <div className={_classes.headerWrapper}>
        <MUITooltip
          placement="top"
          title="This bar chart shows how journeys that engaged through cross-channel engagement interacted with the microsite following their cross-channel engagement"
        >
          <Typography className={classes.subheader} style={{marginBottom: 5}} variant="h6">
            Engaged
          </Typography>
        </MUITooltip>
        <MUITooltip title="# of journeys who have engaged with the CCE widget">
          <div>
            <Typography className={classes.subheader} style={{fontWeight: "bold"}} variant="h6">
              {engagedJourneys}
            </Typography>
            <span className={_classes.subtitle}>{engagedLabel}</span>
          </div>
        </MUITooltip>
      </div>

      <div className={cx(classes.chartContainer, _classes.chartWrapper)}>
        <ResponsiveContainer width={300} minHeight={280}>
          <BarChart
            data={data}
            layout="horizontal"
            margin={{top: 26, right: 10, bottom: 5, left: 25}}
          >
            <XAxis dataKey="name" hide={true} interval={0} type="category" />
            <ChartTooltip
              contentStyle={{whiteSpace: "normal"}}
              cursor={{fill: "transparent"}}
              formatter={labelFormatter}
            />
            {data.map(({name, value}, i) => (
              <Bar
                background={i === 0}
                barSize={60}
                dataKey={name}
                fill={colors[i]}
                key={name}
                isAnimationActive={false}
                stackId={stackId}
              >
                <LabelList dataKey="value" position="insideTop" fill="#FFFFFF" />
              </Bar>
            ))}
            <Legend align="left" layout="horizontal" verticalAlign="middle" wrapperStyle={legend} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

CrossChannelBarchart.propTypes = {
  classes: object.isRequired,
}

const useStyles = makeStyles(() => ({
  chartWrapper: {
    "& li.recharts-legend-item": {
      marginBottom: 10,
      textIndent: -18,
    },
  },
  container: {
    display: "flex",
  },
  headerWrapper: {textAlign: "center"},
  subtitle: {
    fontSize: "16px",
    margin: 0,
    padding: 0,
    position: "relative",
    top: -5,
  },
}))

export default CrossChannelBarchart
