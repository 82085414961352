import {TableCell} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {arrayOf, node, object, oneOfType, string} from "prop-types"
import {Link} from "react-router-dom"

const TableCellLink = ({children, className, classes, to, ...tableCellProps}) => (
  <TableCell className={cx(classes.cell, className)} {...tableCellProps}>
    <Link className={classes.link} to={to}>
      {children}
    </Link>
  </TableCell>
)

TableCellLink.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string,
  classes: object,
  to: oneOfType([string, object]),
}

const styles = {
  cell: {
    padding: 0,
  },
  link: {
    color: "inherit",
    display: "table-cell",
    padding: "14px 40px 14px 16px",
    textDecoration: "inherit",
  },
}

export default withStyles(styles)(TableCellLink)
