import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {bool, func, object, string} from "prop-types"
import {Component, createRef} from "react"

import {compileCss} from "lib/custom-css"

import CustomCSSDocumentation from "./custom-css-documentation"

export class CustomCSSEditor extends Component {
  state = {syntaxError: false}
  editorRef = createRef()

  onSave = () => {
    this.setState({syntaxError: false})
    const source = this.editorRef.current.value

    try {
      compileCss("#custom-css-root", source)
      this.props.onSave(source)
    } catch (e) {
      this.setState({syntaxError: e.message})
    }
  }

  render() {
    const {classes, defaultValue} = this.props
    const {syntaxError} = this.state

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
        onClose={this.props.onCancel}
        open={this.props.open}
      >
        <DialogTitle id="form-dialog-title">Custom CSS</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            To customize the styling of journeys that use this template add your custom css here.
          </DialogContentText>
          <TextField
            autoFocus={true}
            classes={{root: classes.textEditorRoot}}
            defaultValue={defaultValue}
            fullWidth={true}
            id="customCss"
            inputProps={{className: classes.textEditor, ref: this.editorRef}}
            InputProps={{classes: {root: classes.textEditorInput}}} //eslint-disable-line react/jsx-no-duplicate-props
            margin="dense"
            multiline={true}
            rows={20}
            type="text"
            variant="outlined"
          />
          {syntaxError && (
            <>
              <Typography className={classes.errorText} gutterBottom={true} variant="caption">
                There is a syntax error in your css. Please validate it before saving.
              </Typography>
              <Typography
                className={cx(classes.errorText, classes.errorMessage)}
                gutterBottom={true}
              >
                {syntaxError}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="grey" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={this.onSave}>
            Save
          </Button>
        </DialogActions>
        <DialogContent>
          <CustomCSSDocumentation />
        </DialogContent>
      </Dialog>
    )
  }
}

CustomCSSEditor.propTypes = {
  classes: object.isRequired,
  defaultValue: string,
  onCancel: func.isRequired,
  onSave: func.isRequired,
  open: bool,
}

const styles = theme => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 300,
  },
  textEditorRoot: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  textEditorInput: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  textEditor: {
    fontFamily: `"Monaco","Consolas","Lucida Console",monospace`,
    flex: 1,
  },
  errorText: {
    marginTop: 10,
    color: theme.palette.error.main,
  },
  errorMessage: {
    fontFamily: `"Monaco","Consolas","Lucida Console",monospace`,
    paddingLeft: theme.spacing(2),
  },
})

export default withStyles(styles)(CustomCSSEditor)
