import {connect} from "react-redux"

import {getUsers} from "../../actions/session-actions"
import {onDeleteUser, onUpdateUser} from "./users-actions"
import UsersList from "./users-list"

const mapStateToProps = ({session: {teamUsers}}) => ({users: teamUsers || []})

const addActionsToProps = {
  onDeleteUser,
  onUpdateUser,
  onFetchUsers: getUsers,
}

export default connect(mapStateToProps, addActionsToProps)(UsersList)
