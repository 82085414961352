import {Grid, TextField} from "@mui/material"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import {ThemeProvider} from "@mui/material/styles"
import {func, object} from "prop-types"

import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"
import ResetLabel from "components/teams/branding-settings/reset-label"

import {UnorderedList} from "lib/draft-js/block-renderers/lists"

import {unparse} from "./helpers"

const ListSettings = ({field, muiTheme}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={6} md={12} xs={12}>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Box mb={3} width={400}>
              <UnorderedList>
                <div key="list-item-1">Blandit aliquam etiam erat velit</div>
                <div key="list-item-2">Praesent elementum facilisis leo vel</div>
                <div key="list-item-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Bibendum neque egestas congue
                  quisque.
                </div>
                <div key="list-item-4">
                  Nibh tellus molestie nunc non blandit massa enim nec dui. Viverra ipsum nunc
                  aliquet bibendum enim facilisis gravida neque.
                </div>
              </UnorderedList>
            </Box>
          </ThemeProvider>
        </Grid>

        <Grid item lg={6} md={12} xs={12}>
          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="lineHeight"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="custom.listItems.lineHeight">Line Height</ResetLabel>}
            margin="normal"
            type="text"
            {...field(`custom.listItems.lineHeight`, {
              defaultValue:
                muiTheme.custom?.listItems?.lineHeight ||
                muiTheme.typography.p?.lineHeight ||
                "16px",
            })}
          />

          <TextField
            fullWidth
            helperText="Accepts CSS numbers like 14px, 1.2em, etc"
            id="marginBottom"
            InputLabelProps={{shrink: true}}
            label={<ResetLabel name="custom.listItems.marginBottom">Margin Bottom</ResetLabel>}
            margin="normal"
            type="text"
            {...field(`custom.listItems.marginBottom`, {
              defaultValue:
                unparse(muiTheme.custom?.listItems?.marginBottom) ||
                unparse(muiTheme.components?.MuiTypography?.styleOverrides?.p?.marginBottom) ||
                "0px",
            })}
          />
        </Grid>
      </Grid>
    </>
  )
}

ListSettings.propTypes = {
  field: func.isRequired,
  muiTheme: object.isRequired,
}

export default brandingSettings(ListSettings)
