/**
 * Used to display a warning if a message has a call to action that refers to an unavailable page.
 *
 * @param {object} message - a template message
 * @param {function} isPageSlugSet - should return boolean for a given page slug string
 * @returns {boolean}
 */
export default function isMissingPageSlugs(templateMessage, templatePages) {
  let templateMessageSlugs = []
  const {message} = templateMessage

  if (message.type === "sms" && message.payload?.linksTo === "journey-page")
    templateMessageSlugs = [templateMessage.content_variables?.[message.payload?.slug]]
  else if (message.type === "email")
    templateMessageSlugs = getTemplateMessageSlugs(message, templateMessage)

  return templateMessageSlugs.some(
    templateMessageSlug => !templatePages?.find(tp => tp.page.newSlug === templateMessageSlug)
  )
}

function getTemplateMessageSlugs(message, templateMessage) {
  return (
    message.contentBlocks
      ?.filter(block => block?.type === "cta" && block?.data?.linksTo === "journey-page")
      .map(block => templateMessage.content_variables?.[block?.slug]) || []
  )
}
