import {object} from "prop-types"
import {arrayOf, number} from "prop-types"

import pluralize from "lib/string/pluralize"

import {INSIGHTS_COLORS} from "./insights-helpers"
import StatsPie from "./stats-pie"

let colors = [...INSIGHTS_COLORS]
colors.unshift(INSIGHTS_COLORS[INSIGHTS_COLORS.length - 1])

const SecurePersonalizationChart = ({securePersonalizationData, totalContacts}) => {
  const totalLabel = pluralize("contact", totalContacts)

  return (
    <>
      {totalContacts > 0 ? (
        <>
          <StatsPie
            colors={colors}
            data={securePersonalizationData}
            total={totalContacts}
            totalLabel={totalLabel}
          />
        </>
      ) : null}
    </>
  )
}

SecurePersonalizationChart.propTypes = {
  securePersonalizationData: arrayOf(object).isRequired,
  totalContacts: number.isRequired,
}

export default SecurePersonalizationChart
