import {ListItemIcon, ListItemText, MenuItem} from "@mui/material"
import {func, object} from "prop-types"
import {forwardRef, useRef} from "react"
import {MdFeedback as OptInIcon} from "react-icons/md"
import {useSelector} from "react-redux"
import {Link} from "react-router-dom"
import shortid from "shortid"

import {feature} from "components/feature/feature"

import {teamConfig} from "lib/config"
import MaybeTooltip from "lib/maybe-tooltip"

const OptInButton = forwardRef(({classes, onClose, onAddContentBlock}, ref) => {
  const privacyPolicyUrl = useSelector(
    ({session}) => teamConfig(session?.team, "do").privacyPolicyUrl
  )
  const termsOfServiceUrl = useSelector(
    ({session}) => teamConfig(session?.team, "do").termsOfServiceUrl
  )

  const buttonEl = useRef(null)

  const onClick = () => {
    const slug = shortid.generate()

    onAddContentBlock({
      slug,
      data: {},
      type: "opt-in",
    })
    onClose()
  }

  const legalLinksSet = privacyPolicyUrl && termsOfServiceUrl

  const legalLinksNotSetHelpText = !legalLinksSet && (
    <div>
      This widget can only be used if your privacy policy url and terms of service url are set. You
      can set those in <Link to="/admin/settings/general">general team settings</Link>.
    </div>
  )

  return (
    <MaybeTooltip
      isTooltip={!legalLinksSet}
      PopperProps={{anchorEl: buttonEl.current}}
      title={legalLinksNotSetHelpText}
    >
      <MenuItem disabled={!legalLinksSet} onClick={onClick} ref={buttonEl}>
        <ListItemIcon classes={{root: classes.icon}}>
          <OptInIcon />
        </ListItemIcon>
        <ListItemText primary="Full opt-in" ref={ref} />
      </MenuItem>
    </MaybeTooltip>
  )
})

OptInButton.propTypes = {
  onAddContentBlock: func.isRequired,
  onClose: func,
  classes: object.isRequired,
}

export {OptInIcon}

export default feature("opt-in", OptInButton)
