import {Button, FormHelperText, Grid, TextField, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {bool, func, object} from "prop-types"
import {useEffect, useState} from "react"
import {connect} from "react-redux"

import {validEmail} from "lib/field-validations"
import {formify} from "lib/hooks/use-form"

import {controlAccess} from "../access-control/access-controlled"
import SaveButton from "../save-button/save-button"
import {testSmtpSettings, updateTeamSettings} from "./team-actions"

const smtpFields = [
  "emailServer",
  "emailPort",
  "emailUsername",
  "emailPassword",
  "emailSender",
  "emailSenderName",
]

const filterFormInputs = inputs =>
  Object.entries(inputs).reduce((acc, [key, {value}]) => {
    if (smtpFields.includes(key)) return {...acc, [key]: !!value ? value : null}

    return acc
  }, {})

const safelyGetEmailServer = ({emailServer}) =>
  emailServer && emailServer.value ? emailServer.value : null

const checkIfTestEmailIsSendable = ({emailServer, emailUsername, emailSender}) =>
  !!emailServer &&
  !!emailServer.value &&
  !!emailUsername &&
  !!emailUsername.value &&
  !!emailSender &&
  !!emailSender.value

export const SmtpSettings = ({
  classes,
  inputs,
  field,
  handleSubmit,
  onSendTestEmail,
  submitting,
  disabled,
}) => {
  const [isTestEmailSendable, setIsTestEmailSendable] = useState(true)

  const _onSendTestEmail = () => {
    const params = filterFormInputs(inputs)

    onSendTestEmail(params)
  }

  useEffect(() => setIsTestEmailSendable(checkIfTestEmailIsSendable(inputs)), [inputs])

  const isUsingDigitalOnboardingSmtp = safelyGetEmailServer(inputs) === "smtp.digitalonboarding.com"

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={4}>
        <Grid item={true} lg={6} md={12}>
          {!isUsingDigitalOnboardingSmtp && (
            <>
              <TextField
                className={classes.field}
                disabled={disabled}
                fullWidth={true}
                label="Host"
                {...field("emailServer")}
              />
              <TextField
                className={classes.field}
                disabled={disabled}
                fullWidth={true}
                label="Port"
                {...field("emailPort")}
              />
              <TextField
                className={classes.field}
                disabled={disabled}
                fullWidth={true}
                label="Username"
                {...field("emailUsername")}
              />
              <TextField
                className={classes.field}
                disabled={disabled}
                fullWidth={true}
                label="Password"
                type="password"
                {...field("emailPassword")}
              />
              <FormHelperText margin="dense">leave blank to use existing password</FormHelperText>
            </>
          )}
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="Sender Name"
            {...field("emailSenderName")}
          />
          <FormHelperText margin="dense">
            the name that should appear in the from field
          </FormHelperText>
          <TextField
            className={classes.field}
            disabled={disabled}
            fullWidth={true}
            label="Sender Email"
            {...field("emailSender")}
          />
          <FormHelperText margin="dense">
            the email address that should appear in the from field
          </FormHelperText>
        </Grid>
        <Grid item={true} lg={6} md={12}>
          {isUsingDigitalOnboardingSmtp ? (
            <Typography className={classes.helperText}>
              You are currently using Digital Onboarding's SMTP service to send emails. If you would
              like to use your own SMTP service, please contact our customer success team to do so.
            </Typography>
          ) : (
            <>
              <Typography className={classes.helperText}>
                You can use your own email service to send messages from your institution's address.
                This is recommended to improve trust between you and your end users.
              </Typography>
              <Typography className={classes.helperText}>
                It is also recommended that you use the <strong>Send Test Email</strong> button to
                send yourself an email whenever you change these settings.
              </Typography>
              <Typography className={classes.helperText}>
                For your safety we only support TLS SMTP connections.
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <div className={classes.formActions}>
        <Button color="primary" disabled={!isTestEmailSendable} onClick={_onSendTestEmail}>
          Send Test Email
        </Button>
        <SaveButton disabled={disabled} submitting={submitting} />
      </div>
    </form>
  )
}

SmtpSettings.propTypes = {
  classes: object.isRequired,
  disabled: bool,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  inputs: object.isRequired,
  onSendTestEmail: func.isRequired,
  submitting: bool.isRequired,
}

const styles = {
  field: {
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 20,
    "& button": {
      marginLeft: 15,
    },
  },
  helperText: {
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
  },
}

const mapStateToProps = state => ({initialValues: state.session.team})
const addActionsToProps = {
  onSubmit: updateTeamSettings,
  onSendTestEmail: testSmtpSettings,
}

export default connect(
  mapStateToProps,
  addActionsToProps
)(
  formify({enableReinitialize: true, validators: {emailSender: [validEmail]}})(
    withStyles(styles)(controlAccess({requiredPermissions: "team_settings:email"})(SmtpSettings))
  )
)
