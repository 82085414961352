import {FormHelperText, TextField, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {func, object} from "prop-types"

import {compileCss} from "lib/custom-css"
import {formify} from "lib/hooks/use-form"

import {contentBlockEditorConsumer} from "../content-block-editor/content-block-editor-context"
import {contentBlockPropType} from "../content-block-editor/content-block-editor-prop-types"
import FormActions from "../content-block-editor/plugins/form-actions"

const WidgetCssSettings = ({classes, field, handleSubmit, resetForm, selectedBlock}) => {
  const {error, helperText, ...cssField} = field("css")
  let line, char, message

  if (error && helperText) [, line, char, message] = helperText[0].split(":")

  return (
    <form style={{width: "100%"}} onSubmit={handleSubmit}>
      <TextField
        autoFocus={true}
        className={classes.inputContainer}
        fullWidth={true}
        helperText="You can give this widget its own class for targeting in CSS"
        id="widget-custom-class"
        label="Custom Class"
        margin="normal"
        {...field("data.customClass")}
      />
      {selectedBlock.containerType !== "message" && (
        <>
          <FormHelperText>
            You can override any of the styling of this component through custom CSS here. Use{" "}
            <code className={classes.code}>&</code> to select the root of the content block.
          </FormHelperText>

          <TextField
            autoFocus={true}
            fullWidth={true}
            id="customCss"
            inputProps={{className: classes.cssEditor}}
            label="Custom CSS"
            margin="dense"
            multiline={true}
            rows={10}
            type="text"
            variant="outlined"
            error={!!error}
            {...cssField}
          />
        </>
      )}
      {message && (
        <>
          <Typography gutterBottom={true} variant="caption">
            There is a syntax error in your css. Please validate it before saving.
          </Typography>
          <Typography className={classes.errorText} gutterBottom={true} variant="caption">
            Line {line} Character {char}
            <br />
            {message}
          </Typography>
        </>
      )}
      <FormActions resetForm={resetForm} />
    </form>
  )
}

WidgetCssSettings.propTypes = {
  classes: object.isRequired,
  field: func.isRequired,
  handleSubmit: func.isRequired,
  resetForm: func.isRequired,
  selectedBlock: contentBlockPropType.isRequired,
}
const monospace = `"Monaco","Consolas","Lucida Console",monospace`

const styles = theme => ({
  inputContainer: {
    marginTop: 0,
    marginBottom: theme.spacing(3.5),
  },
  cssEditor: {
    fontFamily: monospace,
  },
  errorText: {
    display: "block",
    color: theme.palette.error.main,
    fontFamily: monospace,
  },
  code: {
    color: theme.palette.brand.lightCoral,
    fontFamily: monospace,
  },
})

const validateCss = css => {
  try {
    compileCss("#validator", css)
    return null
  } catch (e) {
    return e.message
  }
}

const WidgetCssSettingsForm = formify({
  validators: {
    css: [validateCss],
  },
})(WidgetCssSettings)

export default withStyles(styles)(contentBlockEditorConsumer(WidgetCssSettingsForm))
