import {RESET_PASSWORD_ERRORED} from "./reset-password-actions"

function resetPassword(state = {}, action) {
  switch (action.type) {
    case RESET_PASSWORD_ERRORED:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default resetPassword
