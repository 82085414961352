/**
 * The DOTable component is a table component that provides functionality for sorting,
 * pagination, column customization, loading state, empty state handling, and exporting data.
 *
 * To use DOTable, you need to provide the table data as the `rows` prop and define the columns
 * using the `headers` prop. The `children` prop should be a function that returns the table rows
 * to render, which will receive the row data, index, and array of visible column definitions.
 *
 * Pagination functionality is enabled by default. To have the table update the page and rows per
 * page in response to user actions, you need to wrap your component with the `tabular` HOC.
 *
 * Sorting is enabled on a per-column basis by setting `sortable: true` on the corresponding header
 * definition. When a user clicks a sortable header, DOTable will call the `refresh` prop with the
 * updated `sortColumn` and `sortDirection`. You should implement `refresh` to sort your data and
 * update the table state.
 *
 * DOTable also supports row selection using the `onSelect` and `isSelected` props, and filtering
 * by providing a `filterComponent` that uses the `filters` prop.
 *
 * While DOTable takes care of many common tasks, there are a few things you need to handle:
 * - Fetching and updating the table data in response to prop changes
 * - Transforming the data into the format expected by DOTable
 * - Implementing the `refresh` callback to re-fetch or re-sort data
 *
 * Props:
 * - allowColumnCustomization (bool): Enables the ability for users to show/hide columns. Default is false.
 * - children (func, required): A function that returns the table rows to render. It receives the row data,
 *   row index, and array of visible column definitions.
 * - exporterProps (object): Props passed to the optional ExportButton component for table data exporting.
 * - filterComponent (node): A component for rendering table filters. It should use the `formify` HOC
 *   which will pass the `filters` object as `initialValues` prop.
 * - filters (object): An object containing the currently applied table filters.
 * - headers (array): Definitions for the table columns. Each object can include:
 *   - field (string, required): The key of the row data to display in this column
 *   - label (string): Display name for the column header
 *   - sortable (bool): Whether the column is sortable
 *   - isDefault (bool): Whether the column is shown by default if allowColumnCustomization is true
 * - idField (string): The key of each row's unique identifier. Used for row selection. Default is 'id'.
 * - isTableLoading (bool): Indicates if data is currently loading. Displays a loading indicator.
 * - itemsPerPage (number): The number of rows to display per page.
 * - noResults (node): Content to display if no rows are returned.
 * - page (number): The current page number (zero-indexed).
 * - paginationEnabled (bool): Whether to show the pagination controls. Default is true.
 * - refresh (func): Callback fired when the table state is updated (sorting, pagination, etc).
 * - rows (array, required): The table data to display.
 * - setTableState (func): Callback to update the table state. Usually provided by the `tabular` HOC.
 * - sortColumn (string): The column currently being sorted by.
 * - sortDirection (string): The current sort direction, either 'asc' or 'desc'.
 * - storageName (string): A unique name used to persist the user's column customizations in local storage.
 * - totalCount (number): The total number of rows across all pages.
 * - variant (string): Variant of the table. Currently supports 'light' for a lighter colored header row.
 */
import {
  Checkbox,
  Divider,
  IconButton,
  LinearProgress,
  ListItemText,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import {array, bool, func, node, number, object, string} from "prop-types"
import {PureComponent, cloneElement, createRef} from "react"
import {FaEllipsisV as MoreIcon} from "react-icons/fa"
import {SortableContainer} from "react-sortable-hoc"

import MaybeTooltip from "lib/maybe-tooltip"
import storage from "lib/storage"
import humanize from "lib/string/humanize"
import labelDisplayedRows from "lib/string/label-displayed-rows"

import {DEFAULT_MENU_PROPS, HEIGHT_LIMITING_PROPS} from "../do-select/do-select"
import ExportButton from "../export-button/export-button"
import TablePagination from "./table-pagination"
import {tableContext} from "./table-state"

const getDefaultColumns = ({storageName, headers}) => {
  const stored = storage.getItem(prefixedStorageName(storageName))

  if (stored) return JSON.parse(stored)
  else return headers.filter(column => column.isDefault).map(({field}) => field)
}

const prefixedStorageName = name => `table:columns:${name}`

const SortableTableBody = SortableContainer(({tableBodyRef, ...props}) => (
  <TableBody ref={tableBodyRef} {...props} />
))

const defaultSortableProps = {
  axis: "y",
  lockAxis: "y",
  lockToContainerEdges: true,
  useDragHandle: true,
}

export class DOTable extends PureComponent {
  state = {
    showColumnSelector: false,
    visibleColumns: getDefaultColumns(this.props),
    isSorting: false,
  }

  tableBody = createRef()

  getColumnSortDirection = column =>
    this.props.sortColumn === column ? this.props.sortDirection : false

  onSort = sortColumn => {
    let {sortDirection} = this.props

    if (this.props.sortColumn === sortColumn)
      sortDirection = sortDirection === "desc" ? "asc" : "desc"

    this.refresh({sortColumn, sortDirection})
  }

  onPageChange = (event, page) => {
    this.refresh({page})
  }

  refresh = attrs => {
    this.props.setTableState(attrs)
    this.props.refresh(attrs)
  }

  columnSelectorRef = createRef()

  onToggleColumnSelectorMenu = () => {
    this.setState(state => ({showColumnSelector: !state.showColumnSelector}))
  }

  onToggleColumn = field => {
    this.setState(({visibleColumns}) => {
      if (visibleColumns.includes(field)) {
        const nextColumns = visibleColumns.filter(aField => aField !== field)

        if (nextColumns.length > 0) return {visibleColumns: nextColumns}
      } else {
        return {visibleColumns: visibleColumns.concat([field])}
      }
    }, this.persistColumnPrefs)
  }

  onRowsPerPageChange = ({target: {value: nextItemsPerPage}}) => {
    const {itemsPerPage: currentItemsPerPage, page: currentPage} = this.props
    const offset = currentPage * currentItemsPerPage

    const nextPage = (offset - (offset % nextItemsPerPage)) / nextItemsPerPage

    this.props.setTableState({itemsPerPage: nextItemsPerPage, page: nextPage})
    this.refresh({itemsPerPage: nextItemsPerPage, page: nextPage})
  }

  onSortEnd = (...args) => {
    this.setState({isSorting: false})
    if (this.props.rowSortOptions?.onSortEnd) this.props.rowSortOptions.onSortEnd(...args)
  }

  updateBeforeSortStart = () => {
    this.setState({isSorting: true})
  }

  persistColumnPrefs = () => {
    const {storageName} = this.props

    if (!storageName) return

    storage.setItem(prefixedStorageName(storageName), JSON.stringify(this.state.visibleColumns))
  }

  getVisibleColumns = () => {
    const {allowColumnCustomization, headers} = this.props

    if (allowColumnCustomization)
      return headers.filter(({field}) => this.state.visibleColumns.includes(field))
    else return headers
  }

  renderColumnSelector = index => {
    const {allowColumnCustomization, classes} = this.props

    if (!allowColumnCustomization || index !== 0) return null

    return (
      <Tooltip title="Show/hide columns...">
        <IconButton
          className={classes.columnSelector}
          onClick={this.onToggleColumnSelectorMenu}
          ref={this.columnSelectorRef}
          size="small"
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
    )
  }

  renderHeaderCell = (
    {classes: headerClasses, field, label, sortable, title, tooltipText},
    index
  ) => {
    const {classes, sortColumn, sortDirection} = this.props

    const humanizedLabel = label ?? humanize(field)
    const columnSelector = this.renderColumnSelector(index)

    const optionalProps = title ? {title} : {}

    if (sortable === false)
      return (
        <TableCell
          className={cx(headerClasses)}
          component="th"
          key={field}
          scope="row"
          {...optionalProps}
        >
          <MaybeTooltip isTooltip={!!tooltipText} title={tooltipText}>
            {humanizedLabel}
          </MaybeTooltip>
          {columnSelector}
        </TableCell>
      )

    return (
      <TableCell
        className={cx(headerClasses)}
        component="th"
        key={field}
        scope="row"
        sortDirection={this.getColumnSortDirection(field)}
        {...optionalProps}
      >
        {columnSelector}
        <Tooltip
          placement="top"
          title={
            tooltipText ? (
              <>
                {tooltipText} <span>({sortDirection === "asc" ? "ascending" : "descending"})</span>
              </>
            ) : (
              `${humanizedLabel} (${sortDirection === "asc" ? "ascending" : "descending"})`
            )
          }
        >
          <TableSortLabel
            active={sortColumn === field}
            classes={{
              root: classes.sortLabel,
              active: classes.activeSortLabel,
              icon: classes.sortIcon,
            }}
            direction={sortDirection}
            onClick={() => this.onSort(field)}
          >
            {humanizedLabel}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    )
  }

  render() {
    const {
      children,
      classes,
      exporterProps,
      filterComponent,
      filters,
      headers,
      isTableLoading,
      itemsPerPage,
      noResults,
      page,
      paginationEnabled,
      rows,
      tableFooter,
      totalCount,
      rowSortOptions,
      variant,
    } = this.props

    const {showColumnSelector, visibleColumns, isSorting} = this.state

    const columns = this.getVisibleColumns()
    const showNoResults = rows.length === 0 && !isTableLoading && noResults

    return (
      <>
        {/*Note: filterComponent relies on the usage of `formify` to pass down `filters` to `initialValues`*/}
        {filterComponent && (
          <div className={classes.filtersRoot}>
            {cloneElement(filterComponent, {initialValues: filters})}
            {exporterProps && (
              <ExportButton disabled={rows.length === 0} params={filters} {...exporterProps} />
            )}
          </div>
        )}
        <div className={classes.tableContainer}>
          <Popover
            anchorEl={this.columnSelectorRef.current}
            anchorOrigin={{horizontal: "left", vertical: "bottom"}}
            classes={{paper: classes.columnSelectorPopover}}
            onClose={this.onToggleColumnSelectorMenu}
            open={showColumnSelector}
            transformOrigin={{horizontal: "left", vertical: "top"}}
          >
            <MenuItem className={classes.columnSelectorHeader}>Columns available</MenuItem>
            <Divider />
            {headers.map(({field, label}) => {
              const checked = visibleColumns.includes(field)
              const disabled = checked && visibleColumns.length === 1

              return (
                <Tooltip
                  key={field}
                  placement="right"
                  title={disabled ? "You can't remove the last column from your table" : ""}
                >
                  <MenuItem
                    className={classes.columnSelectorItem}
                    onClick={() => this.onToggleColumn(field)}
                    value={field}
                  >
                    <Checkbox checked={checked} color={"primary"} disabled={disabled} />
                    <ListItemText primary={label || humanize(field)} />
                  </MenuItem>
                </Tooltip>
              )
            })}
          </Popover>
          {isTableLoading && <LinearProgress />}
          <Table
            className={cx(classes.tableRoot, {
              [classes.isTableLoading]: isTableLoading,
              [classes.tableLight]: variant === "light",
            })}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>{columns.map(this.renderHeaderCell)}</TableRow>
            </TableHead>
            <SortableTableBody
              classes={{root: cx(classes.tableBody, {isSorting})}}
              helperClass={rowSortOptions?.helperClass ?? classes.sortingRow}
              helperContainer={this.tableBody.current}
              data-testid="table"
              onSortEnd={this.onSortEnd}
              tableBodyRef={this.tableBody}
              updateBeforeSortStart={this.updateBeforeSortStart}
              {...defaultSortableProps}
            >
              {rows.map((row, index) => children(row, index, columns))}
            </SortableTableBody>
            {tableFooter && (
              <TableFooter className={classes.tableFooter}>{tableFooter()}</TableFooter>
            )}
          </Table>
          {showNoResults && <div className={classes.noResults}>{noResults}</div>}
          <div className={classes.tableRollup}>
            {exporterProps ? (
              <ExportButton disabled={rows.length === 0} params={filters} {...exporterProps} />
            ) : (
              <div />
            )}
            {paginationEnabled && (
              <TablePagination
                component="div"
                count={totalCount || 0}
                labelDisplayedRows={labelDisplayedRows}
                onPageChange={this.onPageChange}
                onRowsPerPageChange={this.onRowsPerPageChange}
                page={page}
                rowsPerPage={itemsPerPage}
                SelectProps={{MenuProps: {...DEFAULT_MENU_PROPS, ...HEIGHT_LIMITING_PROPS}}}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

export const noWrapCellClass = "do-table-no-wrap-cell"
export const numberCellClass = "do-table-number-cell"

const styles = theme => ({
  filtersRoot: {
    display: "flex",
    flexFlow: "wrap",
    marginBottom: theme.spacing(2.5),
    alignItems: "baseline",
    position: "relative",
    "& > *": {
      marginRight: theme.spacing(2),
    },
    "& > :first-child": {
      flex: 1,
    },

    "& > :last-child": {
      marginRight: 0,
    },
  },
  tableContainer: {
    overflowX: "auto",
    [`& .${noWrapCellClass}`]: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: 300,
      overflow: "hidden",
    },
    [`& .${numberCellClass}`]: {
      textAlign: "right",
    },
  },
  tableRoot: {
    borderCollapse: "separate",
  },
  tableLight: {
    "& $tableHead tr": {
      borderRadius: 0,
      boxShadow: "none",
      "& th": {
        backgroundColor: theme.palette.brand.lightestGray,
        color: `${theme.palette.brand.darkestGray} !important`,
      },
      "& th:first-child, & td:first-child": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
      "& th:last-child, & td:last-child": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
    },
    "& $tableBody": {
      "& tr th, & tr td": {
        borderBottom: `1px solid #e0e0e0`,
      },
    },
    "& $tableFooter td": {
      backgroundColor: theme.palette.brand.lightestGray,
    },
  },
  tableHead: {
    "& tr": {
      height: 40,
      boxShadow: theme.shadows[2],
      borderRadius: theme.spacing(0.5),
    },
    "& th": {
      backgroundColor: "#0B1E34",
      color: "#dedede !important",
      fontWeight: "bold",
      fontSize: theme.typography.fontSize,
    },
    "& th:first-child, & td:first-child": {
      borderTopLeftRadius: theme.spacing(0.5),
      borderBottomLeftRadius: theme.spacing(0.5),
    },
    "& th:last-child, & td:last-child": {
      borderTopRightRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
  },
  tableBody: {
    "& tr th, & tr td": {
      borderBottom: `${theme.spacing(0.5)} solid ${theme.palette.grey[50]}`,
    },
  },
  tableFooter: {
    "& td": {
      fontSize: theme.typography.fontSize,
    },
  },
  tableRollup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  isTableLoading: {
    opacity: 0.6,
  },
  sortIcon: {
    color: "#dedede !important",
  },
  activeSortLabel: {
    color: `${theme.palette.common.white} !important`,
  },
  sortLabel: {
    flex: 1,
    "&:hover": {
      color: "#dedede",
    },
    "&:focus": {
      color: "#dedede",
    },
  },
  columnSelector: {
    color: "#dedede",
    fontSize: 12,
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(),
  },
  columnSelectorHeader: {
    fontStyle: "italic",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "inherit",
      cursor: "default",
    },
  },
  columnSelectorPopover: {
    maxHeight: 275,
  },
  columnSelectorItem: {
    paddingLeft: theme.spacing(1),
  },
  noResults: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontStyle: "italic",
    minHeight: 100,
    background: theme.palette.background.default,
    fontSize: theme.typography.fontSize + 2,
  },
  sortingRow: {
    display: "block",
  },
})

DOTable.propTypes = {
  allowColumnCustomization: bool,
  children: func.isRequired,
  classes: object.isRequired,
  exporterProps: object,
  filterComponent: node,
  filters: object,
  headers: array,
  idField: string,
  isTableLoading: bool,
  itemsPerPage: number,
  noResults: node,
  page: number,
  paginationEnabled: bool,
  refresh: func,
  rowSortOptions: object,
  rows: array.isRequired,
  setTableState: func,
  sortColumn: string,
  sortDirection: string,
  storageName: string,
  tableFooter: func,
  totalCount: number,
  variant: string,
}

DOTable.defaultProps = {
  headers: [],
  idField: "id",
  rowProps: {},
  paginationEnabled: true,
}

export default withStyles(styles)(tableContext(DOTable))
