import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import {bool, func, node, oneOfType, string} from "prop-types"

import DangerButton from "../danger-button/danger-button"

const ConfirmDialog = ({content, open, onClose, onConfirm, title, cancelText, continueText}) => (
  <Dialog onClose={onClose} open={open}>
    <DialogTitle>{title}</DialogTitle>
    {content && (
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      <Button color="grey" onClick={onClose}>
        {cancelText}
      </Button>
      <DangerButton className="delete" onClick={onConfirm}>
        {continueText}
      </DangerButton>
    </DialogActions>
  </Dialog>
)

ConfirmDialog.propTypes = {
  cancelText: string,
  continueText: string,
  content: oneOfType([string, node]),
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  open: bool,
  title: string,
}

ConfirmDialog.defaultProps = {
  title: "Are you sure?",
  cancelText: "No",
  continueText: "Yes",
}

export default ConfirmDialog
