import {connect} from "react-redux"

import {getContactSubscriptionStatus, updateContactSubscriptionStatus} from "./contact-actions"
import EmailSubscriptions from "./email-subscriptions"

const mapStateToProps = ({contact}, ownProps) => {
  const searchParams = new URLSearchParams(ownProps.location.search)

  return {
    contactId: ownProps.match.params.id,
    messageId: searchParams.get("message_id"),
    journeyId: searchParams.get("journey_id"),
    ...contact,
  }
}

const mapDispatchToProps = {
  getContactSubscriptionStatus,
  updateContactSubscriptionStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptions)
