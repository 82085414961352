import {Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {object} from "prop-types"
import {useEffect, useState} from "react"

import {fetchInsightCrossChannelOverview} from "lib/api"
import pluralize from "lib/string/pluralize"

import {useInsights} from "./insights-context"
import {INSIGHTS_COLORS} from "./insights-helpers"
import StatsPie from "./stats-pie"

// eslint-disable-next-line
const [_c1, lightGreen, _c2, _c3, _c4, darkBlue] = INSIGHTS_COLORS
const colors = [darkBlue, lightGreen]

const CrossChannelOverview = ({classes}) => {
  const [isLoaded, setIsLoaded] = useState(true)
  const {
    insights: {cross_channel_engagement_overview},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const cceData = cross_channel_engagement_overview?.value.result || []
  const totalImpressions = cross_channel_engagement_overview?.value.totalImpressions || 0
  const totalLabel = pluralize("Impression", totalImpressions)

  useEffect(() => {
    fetchInsightCrossChannelOverview(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return <Skeleton variant="circular" width={150} height={150} />

  return (
    <>
      <Typography className={classes.subheader} variant="h6">
        Overview
      </Typography>
      {totalImpressions > 0 ? (
        <StatsPie colors={colors} data={cceData} total={totalImpressions} totalLabel={totalLabel} />
      ) : (
        <div>No data available</div>
      )}
    </>
  )
}

CrossChannelOverview.propTypes = {
  classes: object.isRequired,
}

export default CrossChannelOverview
