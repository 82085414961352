import {Button, Fade, Menu, Slide} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import arrayMove from "array-move"
import cx from "classnames"
import {bool, node} from "prop-types"
import {forwardRef, useRef, useState} from "react"
import {FaPlus as PlusIcon} from "react-icons/fa"

import {containerPlugins} from "components/content-block-editor/plugins"

import doTheme from "themes/main"

import {contentBlockEditorConsumer, useContentBlockEditor} from "./content-block-editor-context"
import {contentBlockPropType} from "./content-block-editor-prop-types"

const SlideAndFade = forwardRef(({children, ...props}, ref) => (
  <Fade {...props} easing={{enter: "easeOut"}} ref={ref} timeout={350}>
    <div>
      <Slide {...props} direction={"up"} easing={{enter: "easeOut"}} timeout={350}>
        {children}
      </Slide>
    </div>
  </Fade>
))

SlideAndFade.propTypes = {
  children: node.isRequired,
  in: bool,
}

const AppendPluginMenu = ({contentBlock}) => {
  const [open, setOpen] = useState(false)

  const ref = useRef(null)

  const onTogglePluginMenu = e => {
    e.stopPropagation()
    setOpen(state => !state)
  }

  const onClosePluginMenu = () => setOpen(false)

  const classes = useStyles()

  const {
    onAddContentBlock,
    onSortContentBlocks,
    containers,
    isEmail: _isEmail,
  } = useContentBlockEditor()

  const container = containers[contentBlock.containerId]
  const isEmail = _isEmail(contentBlock.containerId)

  const reorderContentBlocks = recentlyAddedContentBlock => {
    const contentBlocks = [
      ...containers[contentBlock.containerId].contentBlocks,
      recentlyAddedContentBlock,
    ]
    const newPosition = contentBlocks.findIndex(block => block.id === contentBlock.id)

    return arrayMove(contentBlocks, contentBlocks.length - 1, newPosition + 1)
  }

  const appendContentBlock = attrs =>
    onAddContentBlock(contentBlock.containerId, attrs).then(recentlyAddedContentBlock =>
      onSortContentBlocks(contentBlock.containerId, reorderContentBlocks(recentlyAddedContentBlock))
    )

  // Waiting for newly added content blocks to be persisted
  if (!contentBlock.containerId) return null

  return (
    <div
      className={cx(
        "append-plugin-menu",
        classes.root,
        classes[`root-${contentBlock.containerType}`]
      )}
    >
      <ThemeProvider theme={doTheme}>
        <Menu
          anchorEl={ref.current}
          keepMounted
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={SlideAndFade}
          onClose={onTogglePluginMenu}
        >
          {containerPlugins[contentBlock.containerType].map(({Button, type}) => (
            <Button
              classes={{icon: classes.icon}}
              container={container}
              key={type}
              onClose={onClosePluginMenu}
              isEmail={isEmail}
              onAddContentBlock={appendContentBlock}
            />
          ))}
        </Menu>
      </ThemeProvider>
      <Button
        centerRipple={true}
        classes={{root: classes.buttonRoot}}
        onClick={onTogglePluginMenu}
        ref={ref}
      >
        <PlusIcon color="#fff" size={20} />
      </Button>
    </div>
  )
}

AppendPluginMenu.propTypes = {
  contentBlock: contentBlockPropType.isRequired,
}

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    backgroundColor: theme.palette.grey[600],
    height: 30,
    width: 30,
    minWidth: 30,
    borderRadius: "100%",

    "&:hover": {
      backgroundColor: theme.palette.grey[500],
    },
  },
  icon: {
    minWidth: "33px !important",
  },
  root: {
    position: "absolute",
    zIndex: 1000,
    right: theme.spacing(-3.5),
    opacity: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
}))

export default contentBlockEditorConsumer(({isEditMode, ...props}) =>
  isEditMode ? <AppendPluginMenu {...props} /> : null
)
