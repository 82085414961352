import {arrayOf, bool, func, object, shape, string} from "prop-types"
import {SortableContainer} from "react-sortable-hoc"

import {useContentBlockEditor} from "../content-block-editor/content-block-editor-context"
import {previewContext} from "../templates/preview-context"
import {journeyContext} from "./journey-context"
import NavigationItem from "./navigation-item"

const NavigationList = ({
  themeClasses,
  completedPages,
  currentPageId,
  isSortable,
  isPreviewMode,
  onCloseMobileMenu,
  pages,
  pageUrlGenerator,
  visitedPages,
}) => {
  const {isPreviewMode: isContentBlocksPreviewMode} = useContentBlockEditor()

  return (
    <ul className={themeClasses.navigationList} role="presentation">
      {pages.map((page, index) =>
        page.isLive || !isPreviewMode ? (
          <NavigationItem
            disabled={isPreviewMode || isContentBlocksPreviewMode || !isSortable}
            id={page.id}
            index={index}
            isActive={page.id === currentPageId}
            isCompletable={!!page.completionCondition}
            isCompleted={completedPages.includes(page.id)}
            isLive={page.isLive}
            isSortable={!isPreviewMode && !isContentBlocksPreviewMode && isSortable}
            isVisited={visitedPages.includes(page.id)}
            key={page.id}
            linkTo={`${pageUrlGenerator(page.slug)}${
              isPreviewMode || isContentBlocksPreviewMode ? "#preview" : ""
            }`}
            onCloseMobileMenu={onCloseMobileMenu}
            title={page.navigationTitle}
          />
        ) : null
      )}
    </ul>
  )
}

NavigationList.propTypes = {
  completedPages: arrayOf(string),
  currentPageId: string.isRequired,
  isSortable: bool,
  isPreviewMode: bool,
  onCloseMobileMenu: func.isRequired,
  pageUrlGenerator: func.isRequired,
  pages: arrayOf(
    shape({
      id: string.isRequired,
      navigationTitle: string.isRequired,
    })
  ),
  themeClasses: object.isRequired,
  visitedPages: arrayOf(string),
}

export default SortableContainer(journeyContext(previewContext(NavigationList)))
