import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, oneOf, shape, string} from "prop-types"
import {useState} from "react"

import pluralize from "lib/string/pluralize"

import {useTemplateContentContext} from "../../contexts/template-content-context"
import MessageLibrary from "../content-library/message-library"
import {TemplateContentObjectiveSelect} from "../objective-select/objective-select"

const useDialogStyles = makeStyles(() => ({
  dialog: {
    maxWidth: 800,
  },
}))

const MessageAddDialog = ({messageSelectorOptions, setMessageSelectorOptions}) => {
  const classes = useDialogStyles()

  const {template, completeTemplateMessageAddition} = useTemplateContentContext()

  const [objective, setObjective] = useState(null)

  if (!messageSelectorOptions) return null

  const {queue, type} = messageSelectorOptions

  const onCancel = () => {
    setMessageSelectorOptions(null)
    setObjective(null)
  }

  const onComplete = messages => {
    setMessageSelectorOptions(null)
    const templateMessages = messages.map(message => {
      if (message.type === "sms")
        // eslint-disable-next-line no-param-reassign
        message = {
          ...message,
          payload: {
            ...message.payload,
            // From this view, all new messages are attached without a page
            // context, so start out with a url CTA to reduce the
            // error-prone-ness of new default data
            linksTo: "url",
          },
        }

      return {
        message,
        queue,
      }
    })

    completeTemplateMessageAddition(templateMessages)
    setObjective(null)
  }

  return (
    <Dialog
      classes={{paper: classes.dialog}}
      fullWidth={true}
      maxWidth={false}
      onClose={onCancel}
      open={true}
    >
      {!objective ? (
        <ObjectiveSelection
          onCancel={onCancel}
          onSelectObjective={o => setObjective(o)}
          templateId={template.id}
        />
      ) : (
        <MessageSelection
          objective={objective}
          onCancel={onCancel}
          onComplete={onComplete}
          type={type}
        />
      )}
    </Dialog>
  )
}

MessageAddDialog.propTypes = {
  messageSelectorOptions: shape({
    queue: oneOf(["draft", "nurturing", "scheduled"]),
    type: oneOf(["email", "sms"]),
  }),
  setMessageSelectorOptions: func.isRequired,
}

const useObjectiveSelectionStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const ObjectiveSelection = ({templateId, onCancel, onSelectObjective}) => {
  const [objective, setObjective] = useState("")

  const classes = useObjectiveSelectionStyles()

  const onClickNext = () => onSelectObjective(objective)

  if (!templateId) return null

  return (
    <>
      <DialogTitle>Choose your objective</DialogTitle>
      <DialogContent>
        <FormControl fullWidth={true}>
          <InputLabel htmlFor="attach-message-objective" id="attach-message-objective-label">
            Objective
          </InputLabel>
          <TemplateContentObjectiveSelect
            id="attach-message-objective"
            labelId="attach-message-objective-label"
            name="attach-message-objective"
            onChange={o => setObjective(o)}
            required={false}
            value={objective}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="grey" onClick={onCancel}>
          Cancel
        </Button>
        <Tooltip title={!!objective ? "" : "Select an objective to continue."}>
          <span>
            <Button color="primary" disabled={!objective} onClick={onClickNext} variant="contained">
              Next
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  )
}

ObjectiveSelection.propTypes = {
  onCancel: func.isRequired,
  onSelectObjective: func.isRequired,
  templateId: string.isRequired,
}

const useMessageSelectionStyles = makeStyles(theme => ({
  header: {
    zIndex: 1,
    boxShadow: "0px -8px 10px 8px rgba(0,0,0,0.2)",
  },
  footer: {
    zIndex: 1,
    boxShadow: "0px 8px 10px 8px rgba(0,0,0,0.2)",
  },
  content: {
    width: "100%",
    height: 550,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
  },
}))

const MessageSelection = ({type, objective, onCancel, onComplete}) => {
  const [selectedMessages, setSelectedMessages] = useState([])

  const classes = useMessageSelectionStyles()

  return (
    <>
      <DialogTitle className={classes.header}>Add new {objective.name} message</DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        <MessageLibrary
          contentType={type}
          objective={objective}
          onChange={setSelectedMessages}
          selectedMessages={selectedMessages || []}
        />
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          color="primary"
          disabled={selectedMessages.length === 0}
          onClick={() => onComplete(selectedMessages)}
          variant="contained"
        >
          {selectedMessages.length > 0
            ? `Add ${selectedMessages.length} ${pluralize("message", selectedMessages.length)}`
            : "Select a message"}
        </Button>
      </DialogActions>
    </>
  )
}

MessageSelection.propTypes = {
  objective: shape({id: string, name: string}),
  onCancel: func.isRequired,
  onComplete: func.isRequired,
  type: oneOf(["sms", "email"]).isRequired,
}

export default MessageAddDialog
