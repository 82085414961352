import {Box, Typography} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cx from "classnames"
import times from "lodash/times"
import {arrayOf, bool, func, number, object, shape, string} from "prop-types"
import {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useDrop} from "react-dnd"

import {getDays, getHours} from "components/journey-duration-input/journey-duration-input"

import {headTemplateJourneys} from "lib/api"
import pluralize from "lib/string/pluralize"

import {templateContentContext} from "../../contexts/template-content-context"
import MessageDragLayer, {DRAG_PADDING} from "./message-drag-layer"
import MessagePin, {DRAG_TYPE} from "./message-pin"

const TIMELINE_PADDING = DRAG_PADDING / 2
const DEFAULT_MAX_DAY = 30

const MessageScheduler = ({
  classes,
  createTimelineMarker,
  deleteTimelineMarker,
  hasNurturingMessagesAndNoTimelineMarkers,
  template,
  timelineMarkers,
  timelineMarkersLoading,
  updateTimelineMarker,
}) => {
  const [maxDay, setMaxDay] = useState(DEFAULT_MAX_DAY)
  const [totalJourneys, setTotalJourneys] = useState(0)
  const timelineRef = useRef(null)
  const timelineRect = useCallback(() => timelineRef.current?.getBoundingClientRect(), [])

  const positionToDay = useCallback(
    pos => {
      const {height, top} = timelineRect()
      const adjustedHeight = height - TIMELINE_PADDING
      const percent = Math.min(pos - top, adjustedHeight) / adjustedHeight
      const day = Math.floor(percent * maxDay)

      return Math.max(Math.min(day, maxDay), 0)
    },
    [timelineRect, maxDay]
  )

  const [, drop] = useDrop(
    () => ({
      accept: DRAG_TYPE,
      drop(item, monitor) {
        const pos = monitor.getClientOffset().y || 0
        const day = positionToDay(pos)

        if (item.id) updateTimelineMarker({...item, day})
        else createTimelineMarker({...item, day, time: "10:00"})
      },
    }),
    [createTimelineMarker, updateTimelineMarker, positionToDay]
  )

  const allDays = useMemo(
    () =>
      times(Math.max(...timelineMarkers.map(m => m.day)) + 1, () => []).map((_day, i) =>
        timelineMarkers.filter(m => m.day === i)
      ),
    [timelineMarkers]
  )

  const refreshMaxDay = useCallback(() => {
    const daysWithPins = allDays.filter(day => day.length)

    if (daysWithPins.length) {
      const lastDayWithPins = daysWithPins.pop() || []
      const lastPin = lastDayWithPins[lastDayWithPins.length - 1] || {}
      const lastDay = lastPin.day + 10 || 0

      setMaxDay(Math.max(lastDay, Math.ceil(template.journeyDurationHours || 0) / 24))
    } else {
      setMaxDay(DEFAULT_MAX_DAY)
    }
  }, [allDays, template.journeyDurationHours])

  // Getting the timeline markers from outside of this component causes some
  // rendering issues. This forces a re-render after mount for the sake of the
  // draggable situation
  const [, forceUpdate] = useState()
  useEffect(() => {
    forceUpdate({})
  }, [])

  useEffect(() => {
    refreshMaxDay()

    headTemplateJourneys(template.id).then(([, fetchResponse]) => {
      const totalJourneys = parseInt(fetchResponse.headers.get("x-total-count"), 10)
      setTotalJourneys(totalJourneys)
    })
  }, [allDays, refreshMaxDay, template.id])

  const dayToPosition = (bounds, day) => {
    if (!bounds) return {top: 0, opacity: 0}

    const height = bounds.height - TIMELINE_PADDING * 2

    return {top: height * (day / maxDay)}
  }

  const attachDropRef = useCallback(
    node => {
      timelineRef.current = node
      return drop(node)
    },
    [drop]
  )

  const bounds = timelineRect()

  const days = getDays(template.journeyDurationHours)
  const hours = getHours(template.journeyDurationHours)

  return (
    <div className={classes.root} style={{opacity: timelineMarkersLoading ? 0.5 : 1}}>
      {hasNurturingMessagesAndNoTimelineMarkers && (
        <Typography className={classes.errorText} gutterBottom={true} variant="caption">
          There are no message pins in this template's message scheduler; if you continue, only
          scheduled messages will be sent for this journey.
        </Typography>
      )}
      <Typography>
        Drag pins to the timeline to schedule messages. Pins scheduled for <i>Day 0</i> are sent
        immediately upon creating journeys with this template.
      </Typography>
      {!!totalJourneys && (
        <Box my={2}>
          <Typography className={classes.errorText}>
            This template already has <b>{totalJourneys.toLocaleString()}</b> journeys. Adding,
            changing, moving, or removing pins will produce unexpected results.
          </Typography>
        </Box>
      )}
      <div className={classes.pinDrawer}>
        <MessagePin stack={false} type="sms" />
        <MessagePin stack={false} type="email" />
      </div>
      <div ref={attachDropRef} data-testid="drop-target">
        <div className={classes.timeline} data-testid="timeline">
          <MessageDragLayer
            maxDay={maxDay}
            positionToDay={positionToDay}
            timelineRect={timelineRect}
          />
          <span className={cx(classes.dayLabel, classes.dayAxisLabel)}>Day</span>
          <div data-testid="persisted-markers">
            {allDays.map(
              (day, i) =>
                !!day.length && (
                  <div
                    className={classes.markers}
                    key={`${i}.${day.length}`}
                    style={dayToPosition(bounds, i)}
                  >
                    <span className={classes.dayLabel}>{i}</span>
                    <div>
                      {day.map(pin => (
                        <MessagePin
                          day={pin.day}
                          id={pin.id}
                          key={pin.id}
                          label={pin.time}
                          onDelete={deleteTimelineMarker}
                          onUpdate={updateTimelineMarker}
                          type={pin.type}
                        />
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
          {true && (
            <div
              className={classes.deadline}
              style={dayToPosition(bounds, Math.floor(template.journeyDurationHours / 24))}
            >
              <span className={classes.dayLabel}>
                {Math.floor(template.journeyDurationHours / 24)}
              </span>
              <div className={classes.arm} />
              <span className={classes.deadlineLabel}>
                Projected Completion
                <br />
                {days} {pluralize("day", days)}
                {!!hours && ` ${hours} ${pluralize("hour", hours)}`}
              </span>
              <div className={classes.arm} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

MessageScheduler.propTypes = {
  classes: object.isRequired,
  createTimelineMarker: func.isRequired,
  deleteTimelineMarker: func.isRequired,
  hasNurturingMessagesAndNoTimelineMarkers: bool,
  template: object.isRequired,
  timelineMarkers: arrayOf(
    shape({
      day: number,
      time: string,
      id: string,
      type: string,
    })
  ),
  timelineMarkersLoading: bool,
  updateTimelineMarker: func.isRequired,
}

const styles = theme => ({
  root: {
    flex: 1,
  },
  pinDrawer: {
    paddingTop: theme.spacing(1),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  timeline: {
    position: "relative",
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
    padding: `${TIMELINE_PADDING}px 0`,
    minHeight: 500,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  markers: {
    position: "absolute",
    marginTop: theme.spacing(-2.25),
    transition: "all ease .5s",
    "&:hover": {
      zIndex: 5000,
    },
  },
  dayAxisLabel: {
    marginTop: theme.spacing(-5),
  },
  dayLabel: {
    position: "absolute",
    marginLeft: theme.spacing(-3),
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
    lineHeight: theme.spacing(4.5),
  },
  deadline: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(-0.5),
    width: "100%",
    transition: "all ease .5s",
  },
  deadlineLabel: {
    padding: `0 ${theme.spacing(0.5)}`,
    textTransform: "uppercase",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 0.9,
    color: theme.palette.primary.light,
    fontWeight: 700,
    textAlign: "center",
  },
  arm: {
    flex: 1,
    borderBottomWidth: 2,
    borderBottomStyle: "dotted",
    borderBottomColor: theme.palette.primary.light,
  },
  errorText: {
    color: theme.palette.error.main,
  },
})

export default withStyles(styles)(templateContentContext(MessageScheduler))
