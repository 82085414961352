import {CircularProgress, Dialog, DialogContent} from "@mui/material"
import {func, object, string} from "prop-types"
import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {useHistory} from "react-router-dom"

import humanize from "lib/string/humanize"

import {
  createMessage,
  createPage,
  deleteMessage,
  duplicateMessage,
  fetchMessage,
  sendDraftMessage,
  updateMessage,
} from "../../lib/api"
import MessageForm from "../templates/message-form-LEGACY"
import ObjectivePicker from "./objective-picker"

const saveContent = (id, attrs) => {
  if (id.startsWith("new")) return createMessage(attrs)
  return updateMessage(id, attrs)
}

const titleFromObjective = (objective, type) => {
  if (!objective.id) return `New ${humanize(type)} (No Objective)`
  return `New ${objective.name} ${humanize(type)}`
}

const ContentLibraryEditor = ({
  type,
  id,
  onComplete,
  onClose,
  onChangeSelection,
  ...restContent
}) => {
  const [content, setContent] = useState(id.startsWith("new") ? {id, ...restContent, type} : null)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const onSelectObjective = objective => {
    const objectiveId = objective.id
    const contentName = titleFromObjective(objective, type)

    if (type === "page") {
      setIsLoading(true)

      createPage({
        ...content,
        contentName,
        objectiveId,
        navigationTitle: contentName,
      }).then(({id}) => {
        setIsLoading(false)
        history.push(`/admin/templates/content-library/pages/${id}`)
      })
    } else if (type === "email") {
      createMessage({
        ...content,
        contentName,
        objectiveId,
        navigationTitle: contentName,
      }).then(({id}) => {
        setIsLoading(false)
        history.push(`/admin/templates/content-library/messages/${id}`)
      })
    } else {
      setContent({...content, objectiveId, contentName})
    }
  }

  const onSaveMessage = attrs => saveContent(content.id, {...content, ...attrs}).then(onComplete)

  useEffect(() => {
    if (!id || !type || id.startsWith("new")) return
    setIsLoading(true)
    fetchMessage(id)
      .then(fetchedContent => {
        setIsLoading(false)
        setContent(fetchedContent)
      })
      .catch(() => {
        onClose()
      })
  }, [id, onClose, type])

  if (isLoading || !content) return <ContentLoadingInterstitial />

  // check that newly created content has made the user select an objective
  // do not show when user selects the "None" objective with an objectiveId of `null`
  if (id.startsWith("new") && content.objectiveId === undefined)
    return <ObjectivePicker onClose={onClose} onSelectObjective={onSelectObjective} type={type} />

  return (
    <MessageEditor
      content={content}
      onChangeSelection={onChangeSelection}
      onClose={onClose}
      onSave={onSaveMessage}
    />
  )
}

ContentLibraryEditor.propTypes = {
  id: string.isRequired,
  onChangeSelection: func,
  onClose: func.isRequired,
  onComplete: func.isRequired,
  type: string.isRequired,
}

const ContentLoadingInterstitial = () => (
  <Dialog aria-label="Loading" open={true} role="dialog">
    <DialogContent>
      <CircularProgress />
    </DialogContent>
  </Dialog>
)

const MessageEditor = ({content, onSave, onClose, onChangeSelection}) => {
  const currentUser = useSelector(({session}) => session.user)

  const dialogTitle = ""

  const onDelete = id => {
    if (id.startsWith("new")) onClose()
    else deleteMessage(id).then(() => onClose("delete"))
  }

  const onDuplicate = (id, attrs) => duplicateMessage(id, attrs).then(onChangeSelection)

  const onSendSampleMessage = message =>
    sendDraftMessage({
      ...message,
      // where else would we send them besides back here?
      payload: {...message.payload, linksTo: "url", url: window.location.href},
      pageId: null,
    })

  return (
    <MessageForm
      currentUser={currentUser}
      dialogTitle={dialogTitle}
      id={content.id}
      isContentLibrary={true}
      message={content}
      onClose={onClose}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      onSendSampleMessage={onSendSampleMessage}
      onSubmit={onSave}
      pageId={null}
      pages={[]}
      templateId={null}
      type={content.type}
    />
  )
}

MessageEditor.propTypes = {
  content: object.isRequired,
  onChangeSelection: func.isRequired,
  onClose: func.isRequired,
  onSave: func.isRequired,
}

export default ContentLibraryEditor
