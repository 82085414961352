import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {AiOutlineDrag as MoveIcon} from "react-icons/ai"
import {SortableHandle} from "react-sortable-hoc"

import {contentBlockEditorConsumer} from "./content-block-editor-context"

const DragHandle = () => {
  const classes = useStyles()

  return (
    <div className={cx("drag-handle", classes.root)}>
      <div className={classes.dragHandle}>
        <MoveIcon size={24} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: -38,
    opacity: 0,
    paddingRight: theme.spacing(),
    cursor: "move",
    // `pointerEvents` prevents activating others drag handles when sorting & hovering
    pointerEvents: "auto",
  },
  dragHandle: {
    alignItems: "center",
    backgroundColor: theme.palette.grey[600],
    borderRadius: "100%",
    boxShadow: theme.shadows[12],
    color: "#fff",
    display: "flex",
    height: 30,
    justifyContent: "center",
    width: 30,
  },
}))

const SortableDragHandle = SortableHandle(DragHandle)

export default contentBlockEditorConsumer(({isEditMode}) =>
  isEditMode ? <SortableDragHandle /> : null
)
