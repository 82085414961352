import {Typography} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {object} from "prop-types"
import {useEffect, useMemo, useState} from "react"
import {Cell, Legend, Pie, PieChart, Surface, Tooltip} from "recharts"

import {fetchInsightCardOnFileCompletions} from "lib/api"

import {useInsights} from "./insights-context"
import {INSIGHTS_COLORS} from "./insights-helpers"

const COLORS = [INSIGHTS_COLORS[5], INSIGHTS_COLORS[0], INSIGHTS_COLORS[1]]
const RADIAN = Math.PI / 180

const useStyles = makeStyles(() => ({
  chartWrapper: {
    margin: "25px auto 0 auto",
    "& li.recharts-legend-item": {
      marginBottom: 5,
      textIndent: -18,
    },
  },
  legendList: {margin: "0 0 0 18px", padding: 0},
  legendListItem: {
    color: "#000",
    display: "block",
    margin: "0 4px 5px 0",
    textIndent: "-18px",
    verticalAlign: "middle",
  },
  pieDescription: {
    "&:first-of-type": {margin: "0 0 16px"},
    "&:not(:first-of-type)": {margin: "16px 0 0"},
    "& .pie-description-heading": {display: "block"},
    "& .pie-description-subhead": {display: "block", fontStyle: "italic"},
  },
  tooltipList: {listStyle: "none", margin: 0, padding: 0},
  tooltipListItem: {color: "#000", display: "block", margin: 0, padding: "4px 0"},
  tooltipWrapper: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    margin: 0,
    padding: "10px",
    whiteSpace: "nowrap",
  },
}))

const CardOnFileMerchantDetails = props => {
  const {
    insights: {card_on_file_merchant_detail},
    addInsightsCache,
    templateId,
    filter,
  } = useInsights()
  const [isLoaded, setIsLoaded] = useState(false)
  const classes = {...props.classes, ...useStyles()}

  const aggregateCounts = useMemo(
    () => card_on_file_merchant_detail?.value.completionCountAggregates || [],
    [card_on_file_merchant_detail]
  )

  const merchantCounts = useMemo(
    () => card_on_file_merchant_detail?.value.completionCountsByMerchant || [],
    [card_on_file_merchant_detail]
  )

  const renderInnerLabel = data => {
    const {cx, cy, midAngle, innerRadius, outerRadius, journeyCount} = data
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{fontSize: "10px"}}
      >
        {journeyCount}
      </text>
    )
  }

  const renderLegend = ({payload}) => (
    <>
      <div className={classes.pieDescription}>
        <span className="pie-description-heading">Inner Pie Chart</span>
        <span className="pie-description-subhead">Journeys by # of completions</span>
      </div>
      <ul className={classes.legendList}>
        {payload
          .filter(({type}) => type !== "none")
          .map((entry, index) => (
            <li className={classes.legendListItem} key={`item-${index}`}>
              <Surface
                width={14}
                height={14}
                viewBox={{x: 0, y: 0, width: 32, height: 32}}
                style={{display: "inline-block", verticalAlign: "middle", marginRight: 4}}
              >
                <path
                  stroke="none"
                  fill={entry.color}
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                />
              </Surface>
              <span className="recharts-legend-item-text">{entry.value}</span>
            </li>
          ))}
      </ul>
      <div className={classes.pieDescription}>
        <span className="pie-description-heading">Outer Ring</span>
        <span className="pie-description-subhead">
          % of completions by merchant in each inner pie chart slice
        </span>
      </div>
    </>
  )

  const renderOuterLabel = data => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      percentOfCompletionCount,
      merchantName,
    } = data
    const radiusInnerText =
      Number(percent >= 0.05 ? 0 : 35) +
      innerRadius +
      (outerRadius - innerRadius) * Number(percent >= 0.05 ? 0.525 : 0.25)
    const radiusOuterText = innerRadius + (outerRadius - innerRadius) * 2
    const xInnerText = cx + radiusInnerText * Math.cos(-midAngle * RADIAN)
    const yInnerText = cy + radiusInnerText * Math.sin(-midAngle * RADIAN)
    const xOuterText = cx + radiusOuterText * Math.cos(-midAngle * RADIAN)
    const yOuterText = cy + radiusOuterText * Math.sin(-midAngle * RADIAN)

    return (
      <>
        <text
          x={xInnerText}
          y={yInnerText}
          fill={percent >= 0.05 ? "white" : "black"}
          textAnchor="middle"
          dominantBaseline="central"
          style={{fontSize: "10px"}}
        >
          {`${Math.round(percentOfCompletionCount)}%`}
        </text>
        <text
          x={xOuterText}
          y={yOuterText}
          fill="black"
          textAnchor={xOuterText > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{fontSize: "10px"}}
        >
          {merchantName}
        </text>
      </>
    )
  }

  const renderTooltipContent = data => {
    if (data.payload.length) {
      const [
        {
          dataKey,
          name,
          payload: {completionCount: sliceCompletionCount, percentOfTotalJourneyCompletions},
        },
      ] = data.payload
      switch (dataKey) {
        case "journeyCount":
          return (
            <div className={classes.tooltipWrapper}>
              <span>{name}</span>
              <span> : </span>
              <span>{percentOfTotalJourneyCompletions}%</span>
            </div>
          )
        case "percentOfTotalMerchantCompletions":
          return (
            <div className={classes.tooltipWrapper}>
              <ul className={classes.tooltipList}>
                {merchantCounts
                  .filter(({completionCount}) => completionCount === sliceCompletionCount)
                  .map(({merchantName, journeyCount}, index) => (
                    <li className={classes.tooltipListItem} key={`merchant-${index}`}>
                      <span>{merchantName}</span>
                      <span> : </span>
                      <span>{journeyCount}</span>
                    </li>
                  ))}
              </ul>
            </div>
          )
        default:
          return null
      }
    }

    return null
  }

  useEffect(() => {
    fetchInsightCardOnFileCompletions(templateId, filter)
      .then(addInsightsCache)
      .finally(() => setIsLoaded(true))
  }, [addInsightsCache, templateId, filter])

  if (!isLoaded) return <Skeleton variant="circular" width={150} height={150} />

  return (
    <>
      <Typography className={cx(classes.subheader, classes.coffSubheader)} variant="h6">
        Completions
      </Typography>

      {aggregateCounts.length && merchantCounts.length ? (
        <PieChart className={classes.chartWrapper} height={350} width={555}>
          <Pie
            data={aggregateCounts}
            dataKey="journeyCount"
            cx={180}
            cy={145}
            isAnimationActive={false}
            outerRadius={64}
            label={renderInnerLabel}
            labelLine={false}
          >
            {aggregateCounts.map(({completionCount, groupName}, index) => (
              <Cell name={groupName} key={`cell-${index}`} fill={COLORS[completionCount - 1]} />
            ))}
          </Pie>
          <Pie
            data={merchantCounts}
            dataKey="percentOfTotalMerchantCompletions"
            nameKey="merchantName"
            cx={180}
            cy={145}
            innerRadius={79}
            isAnimationActive={false}
            outerRadius={109}
            label={renderOuterLabel}
            labelLine={false}
            paddingAngle={1}
            legendType="none"
          >
            {merchantCounts.map(({completionCount}, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[completionCount - 1]} />
            ))}
          </Pie>
          <Tooltip content={renderTooltipContent} />
          <Legend
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={{top: 0, right: "18px", width: "160px"}}
            content={renderLegend}
          />
        </PieChart>
      ) : (
        <div>No data available</div>
      )}
    </>
  )
}

CardOnFileMerchantDetails.propTypes = {
  classes: object.isRequired,
}

export default CardOnFileMerchantDetails
