import * as Sentry from "@sentry/browser"
import {node} from "prop-types"
import {PureComponent} from "react"

export default class ErrorBoundary extends PureComponent {
  state = {
    error: undefined,
    info: undefined,
  }

  static getDerivedStateFromError = error => ({error})

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      scope.setExtras(info)
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.error) return this.props.errorFallback

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: node,
  errorFallback: node.isRequired,
}

export const withErrorBoundary = (Component, errorFallback) => props => (
  <ErrorBoundary errorFallback={errorFallback}>
    <Component {...props} />
  </ErrorBoundary>
)
