import {useCallback} from "react"
import {useSelector} from "react-redux"

import {betaFeatures, diagnosticFeatures} from "lib/hooks/feature-types"
import storage from "lib/storage"
import wrapDisplayName from "lib/wrap-display-name"

const isBeta = featureKey => storage.getItem("beta") !== null && betaFeatures.includes(featureKey)

const useFeatures = () => {
  const features = useSelector(({session}) => session?.team?.features || {})

  const hasFeature = useCallback(
    featureKey => {
      if (diagnosticFeatures.includes(featureKey)) {
        return !!(!!features[featureKey] && storage.getItem("diagnostic"))
      }

      return isBeta(featureKey) || !!features[featureKey]
    },
    [features]
  )

  return {
    features,
    hasFeature,
  }
}

export const featurify = Component => {
  wrapDisplayName(Component, "featurify")

  return props => <Component {...props} {...useFeatures()} />
}

export default useFeatures
