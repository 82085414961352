import {object} from "prop-types"
import {NavLink} from "react-router-dom"

const AccountSubNav = ({classes}) => (
  <>
    <NavLink className={classes.link} exact={true} title="Account - General" to="/admin/account">
      <span>General</span>
    </NavLink>

    <NavLink className={classes.link} title="Account - Audit Logs" to="/admin/account/audit-logs">
      <span>Audit Logs</span>
    </NavLink>
  </>
)

AccountSubNav.propTypes = {
  classes: object,
}

export default AccountSubNav
