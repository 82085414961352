const actions = [
  "auth_code_sent",
  "auth_failed",
  "auth_presented",
  "auth_succeeded",
  "card_on_file_abandoned",
  "card_on_file_completed",
  "card_on_file_failed",
  "contact_method_verified",
  "cta_clicked",
  "direct_deposit_company_searched",
  "direct_deposit_completed",
  "direct_deposit_failed",
  "direct_deposit_followup_cta_clicked",
  "direct_deposit_payroll_searched",
  "direct_deposit_started",
  "direct_deposit_widget_opened",
  "email_opened",
  "enrollment_accepted",
  "enrollment_declined",
  "enrollment_started",
  "enrollment_terms_link_clicked",
  "journey_completed",
  "journey_opened",
  "journey_signup_form_submitted",
  "message_bounced",
  "message_failed",
  "message_sent",
  "message_skipped",
  "mobile_app_download_link_clicked",
  "mobile_app_download_sms_sent",
  "objective_completed",
  "opted_in",
  "opted_out",
  "page_completed",
  "page_confirmed",
  "page_viewed",
  "premier_objective_completed",
  "sms_received",
  "survey_completed",
  "survey_question_answered",
  "token_errored",
]

export default actions
