import {push} from "connected-react-router"

import apiActionCreator from "lib/api-action-creator"
import {toCamelCase} from "lib/case-converter"
import SubmissionError from "lib/submission-error"

import {
  createContact,
  deleteContact,
  fetchContact,
  fetchContactAccounts,
  fetchContactObjectives,
  fetchContactSubscription,
  fetchJourneys,
  fetchTemplatePages,
  updateContact,
  updateContactObjectiveStatus,
  updateContactSubscription,
} from "../../lib/api"

export const CONTACT_OBJECTIVES_LIST_SET = "CONTACT_OBJECTIVES_LIST_SET"
export const CONTACT_ACCOUNTS_LIST_SET = "CONTACT_ACCOUNTS_LIST_SET"
export const CONTACT_OBJECTIVE_UPDATED = "CONTACT_OBJECTIVE_UPDATED"
export const CONTACT_SET = "CONTACT_SET"
export const CONTACT_CREATED = "CONTACT_CREATED"
export const CONTACT_CLEARED = "CONTACT_CLEARED"
export const CONTACT_SUBSCRIPTION_SET = "CONTACT_SUBSCRIPTION_SET"
export const CONTACT_SUBSCRIPTION_UPDATED = "CONTACT_SUBSCRIPTION_UPDATED"
export const CONTACT_JOURNEY_LIST_SET = "CONTACT_JOURNEY_LIST_SET"
export const CONTACT_COMPLETED_OBJECTIVE_LIST_SET = "CONTACT_COMPLETED_OBJECTIVE_LIST_SET"
export const CONTACT_DELETED = "CONTACT_DELETED"
export const CONTACT_UPDATED = "CONTACT_UPDATED"
export const CONTACT_TEMPLATE_PAGES_SET = "CONTACT_TEMPLATE_PAGES_SET"

export const getContactComponentData = contactId => dispatch =>
  Promise.all([
    dispatch(getContact(contactId)),
    fetchJourneys({contactId}).then(async journeys => {
      dispatch({type: CONTACT_JOURNEY_LIST_SET, payload: journeys})
      const templateIds = [...new Set(journeys.map(journey => journey.templateId))]
      const pagesByTemplate = await Promise.all(
        templateIds.map(async templateId => {
          const templatePages = await fetchTemplatePages(templateId, {isLive: true})

          return {[templateId]: templatePages}
        })
      )

      dispatch({
        type: CONTACT_TEMPLATE_PAGES_SET,
        payload: pagesByTemplate.reduce((acc, curr) => ({...acc, ...curr}), {}),
      })
    }),
    dispatch(getContactAccounts(contactId)),
    dispatch(getContactObjectives(contactId)),
  ])

export const onDeleteContact = contactId => dispatch =>
  deleteContact(contactId).then(() => {
    dispatch({type: CONTACT_DELETED})
    dispatch(push("/admin/contacts"))
  })

export const onUpdateContact = (contactId, params) => dispatch =>
  updateContact(contactId, params)
    .then(contact => dispatch({type: CONTACT_UPDATED, payload: contact}))
    .catch(({body}) => {
      throw new SubmissionError(body.errors)
    })

export const onCreateContact = params => dispatch =>
  createContact(params)
    .then(contact => {
      dispatch({type: CONTACT_CREATED, payload: contact})
      dispatch(push("/admin/contacts"))
    })
    .catch(({body}) => {
      throw new SubmissionError(toCamelCase(body.errors))
    })

export const clearContactComponentData = () => ({type: CONTACT_CLEARED})

export const getContact = apiActionCreator(CONTACT_SET, fetchContact)
export const getContactSubscriptionStatus = apiActionCreator(
  CONTACT_SUBSCRIPTION_SET,
  fetchContactSubscription
)
export const updateContactSubscriptionStatus = apiActionCreator(
  CONTACT_SUBSCRIPTION_UPDATED,
  updateContactSubscription
)
export const getContactObjectives = apiActionCreator(
  CONTACT_OBJECTIVES_LIST_SET,
  fetchContactObjectives
)

export const getContactAccounts = apiActionCreator(CONTACT_ACCOUNTS_LIST_SET, fetchContactAccounts)

export const updateContactObjective = (contactId, objectiveId, newStatus) => dispatch =>
  updateContactObjectiveStatus(contactId, objectiveId, newStatus).then(contact => {
    dispatch({type: CONTACT_OBJECTIVE_UPDATED, payload: contact})

    fetchJourneys({contactId}).then(journeys =>
      dispatch({type: CONTACT_JOURNEY_LIST_SET, payload: journeys})
    )
  })
