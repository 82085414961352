import {Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import {array, func, object, string} from "prop-types"

import Feature from "components/feature/feature"

import dateParser from "lib/date/date-parser"
import {betaFeatureActions} from "lib/hooks/feature-types"
import useFeatures from "lib/hooks/use-features"
import {formify} from "lib/hooks/use-form"
import humanize from "lib/string/humanize"

import DatePicker from "../date-picker/date-picker"
import DOSelect from "../do-select/do-select"
import actions from "./actions"

const channels = ["cce", "journey"]

const getSelectValue = filterActions => {
  if (filterActions.length > 4) return <i>{filterActions.length} actions</i>

  return <span>{filterActions.slice(0, 4).map(humanize).join(", ")}</span>
}

const getCtasValue = (selected, ctas) => {
  if (ctas.length === 0) return null

  if (selected.length > 4) return <i>{selected.length} ctas</i>

  return (
    <span>
      {selected
        .map(contentBlockId => {
          const selectedCta = ctas.find(cta => cta.id === contentBlockId)

          return selectedCta?.data?.name || selectedCta?.slug
        })
        .join(", ")}
    </span>
  )
}

const Filters = ({classes, ctas, field, inputs, resetForm, id}) => {
  const channelInputValue = inputs.channel?.value ?? []
  const nameField = field("name", {defaultValue: []})
  const ctaField = field("cta_clicked", {defaultValue: []})
  const {hasFeature} = useFeatures()
  return (
    <>
      <DatePicker
        className={classes.filterSelector}
        disableMaskedInput={true}
        format="MMMM dd yyyy"
        label="From"
        {...field("startDate")}
      />
      <DatePicker
        className={classes.filterSelector}
        disableMaskedInput={true}
        format="MMMM dd yyyy"
        label="To"
        {...field("endDate")}
      />
      <FormControl className={classes.filterSelector} margin="normal">
        <InputLabel id={`${id}-label`}>Filter by Action</InputLabel>
        <DOSelect
          id={`${id}-action-select`}
          labelId={`${id}-label`}
          multiple={true}
          {...nameField}
          renderValue={() => getSelectValue(nameField.value)}
        >
          {actions
            .filter(action => !betaFeatureActions[action] || hasFeature(betaFeatureActions[action]))
            .map(action => (
              <MenuItem key={action} value={action}>
                <Checkbox
                  checked={nameField.value.includes(action)}
                  className={classes.passthrough}
                  color={"primary"}
                />
                <ListItemText primary={humanize(action)} />
              </MenuItem>
            ))}
        </DOSelect>
      </FormControl>
      <Feature featureKey="cross-channel-engagement">
        <FormControl className={classes.filterSelector} margin="normal">
          <InputLabel id={`${id}-label`}>Filter by Channel</InputLabel>
          <DOSelect
            id={`${id}-channel-select`}
            labelId={`${id}-label`}
            multiple={true}
            {...field("channel", {defaultValue: []})}
            renderValue={() => getSelectValue(channelInputValue)}
          >
            {channels.map(channel => (
              <MenuItem key={channel} value={channel}>
                <Checkbox
                  checked={channelInputValue.includes(channel)}
                  className={classes.passthrough}
                  color={"primary"}
                />
                <ListItemText primary={humanize(channel)} />
              </MenuItem>
            ))}
          </DOSelect>
        </FormControl>
      </Feature>
      {nameField.value.includes("cta_clicked") && ctas?.length > 0 && (
        <FormControl className={classes.filterSelector} margin="normal">
          <InputLabel id={`${id}-action-label`}>Filter by CTA</InputLabel>
          <DOSelect
            id={`${id}-cta-select`}
            labelId={`${id}-action-label`}
            multiple={true}
            {...ctaField}
            renderValue={() => getCtasValue(ctaField.value, ctas)}
          >
            {ctas.map(cta => (
              <MenuItem key={cta.id} value={cta.id}>
                <Checkbox
                  checked={ctaField.value.includes(cta.id)}
                  className={classes.passthrough}
                  color={"primary"}
                />
                <ListItemText primary={cta.data.name || cta.slug} secondary={humanize(cta.type)} />
              </MenuItem>
            ))}
          </DOSelect>
        </FormControl>
      )}
      <Button color="grey" onClick={resetForm}>
        reset
      </Button>
    </>
  )
}

Filters.propTypes = {
  batchId: string,
  classes: object.isRequired,
  ctas: array,
  field: func.isRequired,
  id: string,
  inputs: object,
  resetForm: func.isRequired,
}
Filters.defaultProps = {
  id: "analytics-filter",
}

const styles = () => ({
  filterSelector: {
    flex: 1,
  },
  passthrough: {
    pointerEvents: "none",
  },
})

export default withStyles(styles)(
  formify({
    autoSubmitOnChange: true,
    autoSubmitDebounceTime: 250,
    enableReinitialize: true,
    parse: {
      startDate: dateParser,
      endDate: dateParser,
    },
  })(Filters)
)
