import withStyles from "@mui/styles/withStyles"
import arrayMove from "array-move"
import {bool, func, object, string} from "prop-types"
import {Component} from "react"

import ConfirmDialog from "../dialogs/confirm-dialog"
import Container, {SortableContainer} from "./container"
import {contentBlockEditorConsumer, editorModePropType} from "./content-block-editor-context"
import {containerPropType, contentBlockPropType} from "./content-block-editor-prop-types"
import generateLayoutBasisCss, {forceBreakPoint} from "./layout-basis"

class ContentBlockEditor extends Component {
  state = {sortIndex: -1, isUnsavedContentBlockConfirmDialogOpen: false}

  componentDidMount() {
    const {
      containers,
      containerId,
      initialContainer,
      initialMode,
      setContainer,
      setMode,
    } = this.props

    if (!containers[containerId]) {
      setContainer(initialContainer)
    }

    if (this.props.mode === undefined) {
      setMode(initialMode)
    }

    // NB: ChromicPDF use only
    this.onNextFrame(() => {
      document.getElementById("root")?.setAttribute("ready-to-print", "true")
    })
  }

  componentDidUpdate(prevProps) {
    const shouldUpdateContainer =
      this.props.initialContainer?.id !== prevProps.initialContainer?.id ||
      this.props.initialContainer?.styles?.gap !== prevProps.initialContainer?.styles?.gap

    if (shouldUpdateContainer) this.props.setContainer(this.props.initialContainer)
    if (this.props.initialMode !== prevProps.initialMode) this.props.setMode(this.props.initialMode)
  }

  componentWillUnmount() {
    this.props.setContainer(null)
    this.endAutoscroll()
  }

  // NB: ChromicPDF use only
  // Wait to call callback until next paint to help ensure
  // DOM elements, such as images, are loaded.
  // Source: https://stackoverflow.com/a/34999925
  onNextFrame(callback) {
    setTimeout(function () {
      requestAnimationFrame(callback)
    })
  }

  updateBeforeSortStart = ({node, index, collection, isKeySorting, event}) => {
    this.setState({sortIndex: index})
  }

  // Why? Because the autoscroll in sortable-hoc assumes the container is what scrolls
  // but wait, it has useWindowAsScrollContainer to solve for that problem. but that
  // secretly also changes the window to be what it uses for dimensons so it starts
  // displaying things outside of the actual dimensions of the container ...so we have
  // to build our own autoscroll :finnadie:
  startAutoscroll = direction => {
    if (!this.autoScroller)
      this.autoScroller = setInterval(this.autoScroll.bind(this, direction === "up"), 10)
  }

  endAutoscroll = () => {
    if (this.autoScroller) this.autoScroller = clearInterval(this.autoScroller)
  }

  autoScroll = goingUp => {
    if (
      (goingUp && window.scrollY === 0) ||
      (!goingUp && window.scrollY + window.innerHeight === document.body.scrollHeight)
    )
      return this.endAutoscroll()

    window.scrollBy(0, goingUp ? -10 : 10)
  }

  onSortMove = event => {
    if (window.innerHeight - event.clientY < 50) this.startAutoscroll("down")
    else if (event.clientY < 50) this.startAutoscroll("up")
    else this.endAutoscroll()
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.endAutoscroll()
    this.setState({sortIndex: -1})

    if (oldIndex === newIndex) return

    const {containers, containerId} = this.props
    const {contentBlocks} = containers[containerId]

    this.props.onSortContentBlocks(containerId, arrayMove(contentBlocks, oldIndex, newIndex))
  }

  closeWidgetEditor = () => {
    if (!this.props.hasUnsavedContentBlockDataChanges()) this.clearSelectedBlock()
    else this.setState({isUnsavedContentBlockConfirmDialogOpen: true})

    // NB: Meta changes should always be cleared when closing the widget editor, regardless of unsaved changes.
    this.props.setSelectedBlockMetaChanges(null)
  }

  clearSelectedBlock = () => {
    this.props.setSelectedBlock(null)
    this.props.setSelectedBlockDataChanges(null)
    this.props.setSelectedBlockMetaChanges(null)
    this.setState({isUnsavedContentBlockConfirmDialogOpen: false})
  }

  render() {
    const {classes, isEditMode, selectedBlock, device, containerId, containers} = this.props
    const container = containers?.[containerId]
    const {isUnsavedContentBlockConfirmDialogOpen} = this.state

    if (!container) return null

    const {layoutBasis} = container

    const ContentBlocksContainer = isEditMode ? SortableContainer : Container

    let layoutCss
    if (device.type !== "desktop")
      layoutCss = forceBreakPoint(device, classes.container, layoutBasis)
    else layoutCss = generateLayoutBasisCss(classes.container, layoutBasis)

    return [
      <style key="style">{layoutCss}</style>,
      isEditMode && container.id === selectedBlock?.containerId ? (
        <div className={classes.hasSelection} key="overlay" onClick={this.closeWidgetEditor} />
      ) : null,
      <ContentBlocksContainer
        axis="xy"
        classes={{container: classes.container}}
        sortIndex={this.state.sortIndex}
        key="container"
        containerId={containerId}
        onSortMove={this.onSortMove}
        onSortEnd={this.onSortEnd}
        updateBeforeSortStart={this.updateBeforeSortStart}
        useDragHandle={true}
      />,
      isUnsavedContentBlockConfirmDialogOpen && (
        <ConfirmDialog
          cancelText="Cancel"
          continueText="Abandon Changes"
          content="You have unsaved changes for this widget."
          key="isUnsavedContentBlockConfirmDialogOpen"
          onClose={() => this.setState({isUnsavedContentBlockConfirmDialogOpen: false})}
          onConfirm={this.clearSelectedBlock}
          open={true}
          title="Abandon changes?"
        />
      ),
    ]
  }
}

ContentBlockEditor.propTypes = {
  classes: object.isRequired,
  containerId: string.isRequired,
  containers: object,
  device: object,
  hasUnsavedContentBlockDataChanges: func.isRequired,
  initialContainer: containerPropType,
  initialMode: editorModePropType,
  isEditMode: bool,
  mode: string,
  onSortContentBlocks: func.isRequired,
  selectedBlock: contentBlockPropType,
  setContainer: func.isRequired,
  setSelectedBlock: func.isRequired,
  setSelectedBlockDataChanges: func.isRequired,
  setSelectedBlockMetaChanges: func.isRequired,
  setMode: func.isRequired,
}

export default withStyles(theme => ({
  container: {
    "&.grid": {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
    },
    "&.table": {
      position: "relative",
      width: "100%",
    },
  },
  hasSelection: {
    content: `""`,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 100,
    background: "#0008",
  },
}))(contentBlockEditorConsumer(ContentBlockEditor))
