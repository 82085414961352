import {validDate, validEmail} from "lib/field-validations"

import {
  ADDRESS,
  CHECKBOXES,
  DATE,
  EMAIL,
  MULTIPLE_CHOICE,
  NUMBER,
  PARAGRAPH,
  SHORT_ANSWER,
} from "./survey-question-types"

export const getQuestionAnsweredTrackingBody = ({
  currentQuestion,
  slug: surveySlug,
  contentBlockId,
  contentContainerId,
  journeyId,
  pageId,
}) => {
  return currentQuestion.type === "DATE"
    ? {
        ...currentQuestion,
        answer: currentQuestion.answer.toISOString(),
        contentBlockId,
        contentContainerId,
        surveySlug,
        journeyId,
        pageId,
      }
    : {
        ...currentQuestion,
        contentBlockId,
        contentContainerId,
        surveySlug,
        journeyId,
        pageId,
      }
}

export const getSurveyCompletedTrackingBody = ({
  questions,
  contentBlockId,
  contentContainerId,
  journeyId,
  pageId,
  slug: widgetSlug,
}) => ({
  contentBlockId,
  contentContainerId,
  journeyId,
  pageId,
  widgetSlug,
  fields: questions.map(convertQuestionToAnalytics),
})

const convertQuestionToAnalytics = question => {
  const {title: questionTitle, slug: questionSlug} = question

  switch (question.type) {
    case PARAGRAPH:
    case SHORT_ANSWER:
    case NUMBER:
    case EMAIL:
    case ADDRESS:
      return {
        answer: question.answer,
        questionSlug,
        questionTitle: question.title,
      }
    case DATE:
      return {
        answer: question.answer.toISOString(),
        questionSlug,
        questionTitle: question.title,
      }
    case MULTIPLE_CHOICE:
      return {
        answer: question.answers.find(a => a.value === question.answer).title,
        questionTitle,
        questionSlug,
        answerSlug: question.answer,
      }
    case CHECKBOXES:
      return {
        answers: question.answer.map(qa => question.answers.find(a => a.value === qa).title),
        questionSlug,
        questionTitle,
        answersSlugs: question.answers.map(a => a.value),
      }

    default:
      throw new Error("unknown question type specified")
  }
}

export const answerHasErrors = question =>
  (question.type !== "CHECKBOXES" && !question.answer) ||
  (question.type === "EMAIL" && !!validEmail(question.answer)) ||
  (question.type === "DATE" && !!validDate(question.answer))
