import {Button} from "@mui/material"
import {TiPlus as PlusIcon} from "react-icons/ti"
import {Link} from "react-router-dom"
import {Route, Switch} from "react-router-dom"

import AccessControlled from "../access-control/access-controlled"
import BatchUploader from "../batch-uploaders/batch-uploader"
import Box from "../box/box"
import ContactsListContainer from "../contacts-list/contacts-list-container"
import Padded from "../padded/padded"
import TitleBar from "../title-bar/title-bar"

export const ContactRouter = () => (
  <>
    <TitleBar title="Contacts Dashboard">
      <AccessControlled requiredPermissions="contacts:create">
        <Button
          color="primary"
          component={Link}
          size="small"
          to="/admin/contacts/new"
          variant="contained"
        >
          <PlusIcon /> Create a new Contact
        </Button>
      </AccessControlled>
    </TitleBar>

    <Padded>
      <Box>
        <Switch>
          <Route component={BatchUploader} path="/admin/contacts/batch-upload" />
          <Route component={ContactsListContainer} path="/admin/contacts" />
        </Switch>
      </Box>
    </Padded>
  </>
)

ContactRouter.propTypes = {}

export default ContactRouter
