import makeStyles from "@mui/styles/makeStyles"
import {string} from "prop-types"
import {useState} from "react"
import {BsImage as GeneratingIcon} from "react-icons/bs"
import {useSelector} from "react-redux"

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.brand.lightBlue,
  },
  iconRoot: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  image: {
    height: 144,
    width: 229,
    objectFit: "contain",
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("box-shadow"),
  },
}))

const CrossChannelContentImage = ({contentContainerId}) => {
  const classes = useStyles()
  const [hasFetchFailed, setHasFetchFailed] = useState(false)
  const teamId = useSelector(({session}) => session?.team?.id)

  return hasFetchFailed ? (
    <div className={classes.iconRoot}>
      <GeneratingIcon className={classes.icon} size={80} />
    </div>
  ) : (
    <img
      alt="Cross channel content preview"
      className={classes.image}
      onError={() => setHasFetchFailed(true)}
      src={`${process.env.REACT_APP_API_URL}/content-library/previews?team_id=${teamId}&content_container_id=${contentContainerId}`}
    />
  )
}

CrossChannelContentImage.propTypes = {contentContainerId: string}

export default CrossChannelContentImage
