import OptInButton, {OptInIcon} from "./opt-in-button"
import OptInReadonly from "./opt-in-readonly"
import OptInSettings from "./opt-in-settings"

export default {
  title: "Full Opt-in",
  type: "opt-in",
  Button: OptInButton,
  Icon: OptInIcon,
  Readonly: OptInReadonly,
  Settings: OptInSettings,
  suitableFor: ["content_container", "page"],
}
