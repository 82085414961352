import {TableCell, TableRow} from "@mui/material"
import {arrayOf, shape, string} from "prop-types"

import {DOTable} from "../table/table"

const CtaSummaryTable = ({rows}) => {
  return (
    <DOTable
      classes={{}}
      headers={[
        {field: "name", label: "CTA Name"},
        {field: "location", label: "CTA Location"},
        {field: "type", label: "Type"},
        {field: "destination", label: "CTA Destination"},
      ]}
      noResults="No CTAs found"
      paginationEnabled={false}
      rows={rows}
    >
      {(row, index) => {
        return (
          <TableRow key={`row-${index}-content-source-id-${row.contentSourceId}`}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.location}</TableCell>
            <TableCell>
              {row.type.toLowerCase() === "cta" ? "button" : row.type.toLowerCase()}
            </TableCell>
            <TableCell style={{maxWidth: 150, wordBreak: "break-all"}}>{row.destination}</TableCell>
          </TableRow>
        )
      }}
    </DOTable>
  )
}

CtaSummaryTable.propTypes = {
  rows: arrayOf(
    shape({
      contentSourceId: string.isRequired,
      destination: string.isRequired,
      name: string,
      type: string.isRequired,
    })
  ),
}

export default CtaSummaryTable
