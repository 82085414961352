import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  IconButton,
  Popover,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import cx from "classnames"
import {func, object} from "prop-types"
import {useEffect, useRef, useState} from "react"
import {MdInsertLink as LinkIcon} from "react-icons/md"

import {contentTypeProp, editorContext, isPage} from "contexts/editor-context"
import {validDomainAndTld} from "lib/field-validations"

import {getCurrentEntity, hasEntity, removeEntity, setEntity} from "./entity-button-helpers"

const getButtonColor = (currentType, buttonType) =>
  currentType === buttonType ? "primary" : "grey"

const getCurrentType = link => {
  if (link.match(/^tel:/i)) return "phone"

  if (link.match(/^mailto:/i)) return "email"

  // It's only other if it has a protocol but isn't http(s).
  // We can't swap these orders because we want website to be the default
  // (and to add http on save if they still haven't set it)
  if (link.match(/^(?!(?:http(s)?:))\w+:/)) return "other"

  return "website"
}

const typesToProtocol = {
  website: "http://",
  email: "mailto:",
  phone: "tel:",
  other: "other:",
}

const protocolRegex = /^\w+:(\/\/)?/

const toProperLink = link => (link.match(protocolRegex) ? link : `http://${link}`)

const getEntityData = editorState => {
  if (hasEntity(editorState))
    return {url: "", target: "_self", ...getCurrentEntity(editorState).data}

  return {url: "", target: "_blank"}
}

const LinkButton = ({contentType, onSetEditorState, editorState, classes}) => {
  const rangeHasEntity = hasEntity(editorState, "LINK")
  const {url: editorCurrentLinkValue, target: editorCurrentTargetValue} = getEntityData(editorState)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const wrapperRef = useRef(null)
  const [link, setLink] = useState(editorCurrentLinkValue)
  const [hasError, setHasError] = useState(false)
  const [target, setTarget] = useState(editorCurrentTargetValue)

  const onChangeLink = e => {
    setLink(e.target.value)
  }

  const onChangeTarget = e => {
    setTarget(e.target.checked ? "_blank" : "_self")
  }

  const onClose = () => setIsPopoverOpen(false)

  const currentType = getCurrentType(link)

  useEffect(() => {
    setLink(editorCurrentLinkValue)
  }, [editorCurrentLinkValue, rangeHasEntity])

  useEffect(() => {
    setTarget(editorCurrentTargetValue)
  }, [editorCurrentTargetValue, rangeHasEntity])

  const setType = type => {
    setHasError(false)
    setLink(toProperLink(link).replace(protocolRegex, typesToProtocol[type]))
  }

  const saveEntity = () => {
    if (currentType === "website" && validDomainAndTld(link)) {
      setHasError(true)
      return
    }
    onSetEditorState(setEntity(editorState, "LINK", {url: toProperLink(link), target}))
    onClose()
  }

  const onKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      saveEntity()
    } else if (e.key === "Escape") {
      e.preventDefault()
      setIsPopoverOpen(false)
    }
  }

  const onClick = () => {
    if (rangeHasEntity) onSetEditorState(removeEntity(editorState))
    else setIsPopoverOpen(true)
  }

  return (
    <>
      <Tooltip title={`${rangeHasEntity ? "Remove" : "Insert"} Link`}>
        <div ref={wrapperRef}>
          <IconButton
            className={cx(classes.button, {
              [classes.buttonActive]: hasEntity(editorState, "LINK"),
            })}
            onClick={onClick}
            size="medium"
          >
            <LinkIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Popover
        anchorEl={wrapperRef.current}
        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
        onClose={onClose}
        open={isPopoverOpen}
      >
        <div style={{padding: 18}}>
          <FormControl fullWidth={true} margin="normal">
            <TextField
              autoFocus={true}
              inputProps={{onKeyDown}}
              label="URL"
              name="url"
              onChange={onChangeLink}
              value={link}
            />
            {hasError && (
              <Typography className={classes.errorText} gutterBottom={true} variant="caption">
                This website link is not valid.
                <br />
                Please double check that you have entered a valid website and try again.
              </Typography>
            )}
          </FormControl>
          {currentType === "website" && isPage(contentType) && (
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={target === "_blank"}
                    color="primary"
                    onChange={onChangeTarget}
                    value={target}
                  />
                }
                label="Open in new tab"
              />
            </div>
          )}
          <div>
            <FormControl margin="normal">
              <ButtonGroup size="small" variant="outlined">
                <Button
                  color={getButtonColor(currentType, "website")}
                  onClick={() => setType("website")}
                >
                  Website
                </Button>
                <Button
                  color={getButtonColor(currentType, "phone")}
                  onClick={() => setType("phone")}
                >
                  Phone
                </Button>
                <Button
                  color={getButtonColor(currentType, "email")}
                  onClick={() => setType("email")}
                >
                  Email
                </Button>
                <Tooltip title="This is auto-selected when you add a custom protocol to the beginning of your link (ex: ftp://)">
                  <Button
                    color={getButtonColor(currentType, "other")}
                    onClick={() => setType("other")}
                  >
                    Other
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </FormControl>
          </div>
          <div className={classes.actions}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={saveEntity}>Save</Button>
          </div>
        </div>
      </Popover>
    </>
  )
}

LinkButton.propTypes = {
  classes: object.isRequired,
  contentType: contentTypeProp,
  editorState: object,
  onSetEditorState: func.isRequired,
}

export default editorContext(LinkButton)
