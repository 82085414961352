import {Button} from "@mui/material"
import {array, shape, string} from "prop-types"
import {useState} from "react"
import {MdAdd as AddIcon} from "react-icons/md"

import {createPage, createTemplatePage, duplicatePage} from "lib/api"

import {history} from "../../reducers/store"
import NewPageForm from "./new-page-form"

const getOrCreatePage = async (attrs, shouldDuplicatePage) => {
  if (!attrs?.id) return await createPage(attrs)
  if (shouldDuplicatePage) return await duplicatePage(attrs.id)

  return attrs
}

const AddPageToTemplateButton = ({template, templatePages}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const submit = async (attrs, shouldDuplicatePage = false) => {
    const {id: templateId} = template
    const order = templatePages.length + 1

    const page = await getOrCreatePage(attrs, shouldDuplicatePage)

    await createTemplatePage(templateId, {pageId: page.id, order})

    history.push(`/admin/templates/${templateId}/edit/${page.newSlug}`)
  }

  return (
    <>
      <Button color="primary" onClick={() => setIsDialogOpen(true)} variant="contained">
        <AddIcon size={20} /> Add Page
      </Button>
      {isDialogOpen && (
        <NewPageForm onClose={() => setIsDialogOpen(false)} onSubmit={submit} template={template} />
      )}
    </>
  )
}

AddPageToTemplateButton.propTypes = {
  template: shape({
    id: string,
  }),
  templatePages: array,
}

export default AddPageToTemplateButton
