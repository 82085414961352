import {Button} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {string} from "prop-types"

import {
  EditorModes,
  useContentBlockEditor,
} from "../content-block-editor/content-block-editor-context"
import DeviceControls from "./device-controls"

const useStyles = makeStyles(theme => ({
  deviceSelect: {
    marginRight: theme.spacing(2),
  },
  selectValue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      marginRight: theme.spacing(),
    },
  },
  banner: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: 88,
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2),
    transition: theme.transitions.create("margin-top"),
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  bannerHidden: {
    marginTop: -88,
  },
  exitButton: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: theme.spacing(2),
  },
}))

const PreviewControls = ({layoutBasis, status}) => {
  const {isPreviewMode, setMode} = useContentBlockEditor()
  const classes = useStyles()

  return (
    <div className={cx(classes.banner, {[classes.bannerHidden]: !isPreviewMode})}>
      <DeviceControls layoutBasis={layoutBasis} status={status} />
      <Button
        className={classes.exitButton}
        color="primary"
        onClick={() => setMode(EditorModes.Edit)}
        size="small"
        variant="contained"
      >
        Exit Preview
      </Button>
    </div>
  )
}

PreviewControls.propTypes = {
  layoutBasis: string,
  status: string,
}

export default PreviewControls
