import {any, node, string} from "prop-types"

const BrandingTabPanel = ({children, namespace, value, index, ...props}) => (
  <div
    aria-labelledby={`${namespace}-branding-tab-${index}`}
    hidden={value !== index}
    id={`${namespace}-branding-tabpanel-${index}`}
    role="tabpanel"
    style={{width: "100%", padding: 20, flexGrow: 1}}
    {...props}
  >
    {value === index && children}
  </div>
)

BrandingTabPanel.propTypes = {
  children: node,
  namespace: string,
  index: any.isRequired,
  value: any.isRequired,
}

BrandingTabPanel.defaultProps = {
  namespace: "team",
}

export default BrandingTabPanel
