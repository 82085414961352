/**
 * Score string similarity with the quicksilver algorithm
 * @param  {String} string
 * @param  {String} search
 * @param  {Number} offset
 * @return {Number}
 */
export default function score(string, search, offset = 0) {
  if (search.length === 0) return 0.9
  if (search.length > string.length) return 0.0

  for (let i = search.length; i > 0; i--) {
    const sub_search = search.substring(0, i)
    const index = string.indexOf(sub_search)

    if (index < 0) continue
    if (index + search.length > string.length + offset) continue

    const next_string = string.substring(index + sub_search.length)
    let next_search = null

    if (i >= search.length) next_search = ""
    else next_search = search.substring(i)

    const remaining_score = score(next_string, next_search, index + offset)

    if (remaining_score > 0) {
      let _score = string.length - next_string.length

      if (index !== 0) {
        let j = 0,
          c = string.charCodeAt(index - 1)

        if (c === 32 || c === 9)
          for (j = index - 2; j >= 0; j--) {
            c = string.charCodeAt(j)
            _score -= c === 32 || c === 9 ? 1 : 0.15
          }
        else _score -= index
      }

      _score += remaining_score * next_string.length
      _score /= string.length

      return _score
    }
  }

  return 0.0
}
