import {
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {func, object} from "prop-types"
import {useState} from "react"

import PagesList, {newPageItem} from "../content-library/pages-list"
import ObjectiveSelect from "../objective-select/objective-select"
import SaveButton from "../save-button/save-button"

const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: 800,
  },
  duplicateOption: {
    flex: 1,
  },
  header: {
    zIndex: 1,
    transition: theme.transitions.create("box-shadow"),
  },
  headerOpen: {
    boxShadow: "0px -8px 10px 8px rgba(0,0,0,0.2)",
  },
  footer: {
    zIndex: 1,
    minHeight: 78,
    justifyContent: "space-around",
    transition: theme.transitions.create("box-shadow"),
  },
  footerOpen: {
    boxShadow: "0px 8px 10px 8px rgba(0,0,0,0.2)",
  },
  content: {
    background: theme.palette.background.default,
    padding: 0,
  },
  saveButton: {
    maxHeight: 36,
  },
  select: {
    maxWidth: 252,
  },
  menuItem: {
    boxSizing: "border-box",
    height: "auto",
    maxWidth: 252,
    whiteSpace: "normal",
  },
  listSubheader: {
    backgroundColor: "#fff",
  },
}))

const NewPageForm = ({onClose, onSubmit, template}) => {
  const [selectedPage, setSelectedPage] = useState(null)
  const [selectedObjective, setSelectedObjective] = useState("")

  const [duplicatePage, setDuplicatePage] = useState(false)

  const [failed, setFailed] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const classes = useStyles()

  const currentTemplatePages = template.templatePages || []
  const needsPageSelection = selectedPage === null
  const selectedObjectiveId = selectedObjective?.id
  const isObjectiveSelected = selectedObjective !== ""
  const isExistingPageSelected = selectedPage && selectedPage !== newPageItem
  const pageToSubmit = isExistingPageSelected
    ? selectedPage
    : {
        objectiveId: selectedObjectiveId,
        navigationTitle: "New Page",
        contentName: `New Page ${template.name}`,
      }

  const onAddPage = () => {
    setFailed(false)
    setSubmitting(true)
    onSubmit(pageToSubmit, duplicatePage)
      .then(onClose)
      .catch(() => {
        setFailed(true)
        setSubmitting(false)
      })
  }

  return (
    <Dialog
      classes={{paper: classes.dialog}}
      fullWidth={true}
      maxWidth={false}
      onClose={onClose}
      open={true}
    >
      <DialogTitle className={cx(classes.header, {[classes.headerOpen]: isObjectiveSelected})}>
        Add a New Page
        <Box my={1}>
          <Typography>Select an objective to see available pages.</Typography>
        </Box>
        <form>
          <FormControl fullWidth={true}>
            <InputLabel id="new-page-objective-label">Page Objective</InputLabel>
            <ObjectiveSelect
              labelId="new-page-objective-label"
              onChange={objective => setSelectedObjective(objective)}
              value={selectedObjective}
            />
          </FormControl>
        </form>
      </DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        <Collapse appear={true} in={isObjectiveSelected} unmountOnExit={true}>
          <PagesList
            excludedPageIds={currentTemplatePages.map(tp => tp.page.id)}
            objectiveId={selectedObjectiveId}
            onChange={page => setSelectedPage(page)}
            selectedPage={selectedPage}
          />
        </Collapse>
      </DialogContent>
      <DialogActions className={cx(classes.footer, {[classes.footerOpen]: isObjectiveSelected})}>
        <Collapse className={classes.duplicateOption} in={Boolean(isExistingPageSelected)}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={duplicatePage}
                  color="primary"
                  onChange={e => setDuplicatePage(e.target.checked)}
                />
              }
              label="Make a copy of this page"
            />
            <FormHelperText>
              This will make a unique duplicate of the page in your content library.
            </FormHelperText>
          </FormControl>
        </Collapse>
        <SaveButton
          className={classes.saveButton}
          disabled={needsPageSelection}
          failed={failed}
          onClick={onAddPage}
          stateLabels={{
            default: "Add Page",
            submitting: "Adding Page...",
            saved: "Page Added!",
          }}
          submitting={submitting}
        />
      </DialogActions>
    </Dialog>
  )
}

NewPageForm.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  template: object.isRequired,
}

export default NewPageForm
