const flattenObj = (object, path = null) =>
  Object.keys(object).reduce((acc, key) => {
    const newPath = [path, key].filter(Boolean).join(".")

    const value = object?.[key]

    if (value === null)
      return {
        ...acc,
        [newPath]: null,
      }

    if (typeof value === "object") return {...acc, ...flattenObj(object[key], newPath)}

    return {...acc, [newPath]: object[key]}
  }, {})

export default flattenObj
