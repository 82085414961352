import React from "react"

import ContentContainerPreview from "components/cross-channel-engagement/content-container-preview"
import CrossChannelEngagementContainer from "components/cross-channel-engagement/cross-channel-engagement-container"

const ThemedContentContainerPreview = () => (
  <CrossChannelEngagementContainer
    render={({classes, contentContainer}) => (
      <ContentContainerPreview classes={classes} contentContainer={contentContainer} />
    )}
  />
)

export default ThemedContentContainerPreview
