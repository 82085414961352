import {FormControl, Grid, InputLabel, TextField, Typography} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, string} from "prop-types"
import {Fragment} from "react"

import ColorInput from "components/color-input/color-input"
import FontSelector from "components/font-selector/font-selector"
import {brandingSettings} from "components/teams/branding-settings/branding-settings-context"
import ResetLabel from "components/teams/branding-settings/reset-label"

import remToPx from "lib/rem-to-px"

import FontWeightSelector from "./font-weight-selector"
import {unparse} from "./helpers"

const defaults = {
  h1: {
    fontSize: "96px",
    fontWeight: 300,
    lineHeight: "96px",
    marginBottom: "34px",
  },
  h2: {
    fontSize: "60px",
    fontWeight: 300,
    lineHeight: "60px",
    marginBottom: "21px",
  },
  h3: {
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "49px",
    marginBottom: "17px",
  },
  h4: {
    fontSize: "19.2px",
    fontWeight: 400,
    lineHeight: "22px",
    marginBottom: "7px",
  },
  h5: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "31.92px",
    marginBottom: "8px",
  },
  h6: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "32px",
    marginBottom: "7px",
  },
}

const getTitle = variant => {
  switch (variant.toLowerCase()) {
    case "h1":
      return "Heading 1"
    case "h2":
      return "Heading 2"
    case "h3":
      return "Heading 3"
    case "h4":
      return "Heading 4"
    case "h5":
      return "Heading 5"
    case "h6":
      return "Heading 6"
    case "body1":
      return "Paragraph"
    default:
      return "Unknown Variant"
  }
}

export const HeadingForm = ({change, field, muiTheme, variant}) => {
  const labelFor = name => `${name}${variant}`

  const classes = useHeadingFormStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{getTitle(variant)}</Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel htmlFor={labelFor("fontFamily")}>
          <ResetLabel name={`typography.${variant}.fontFamily`}>Font Family</ResetLabel>
        </InputLabel>
        <FontSelector
          enableGoogleFonts={true}
          id={labelFor("fontFamily")}
          {...field(`typography.${variant}.fontFamily`, {
            defaultValue: muiTheme.typography[variant].fontFamily || muiTheme.typography.fontFamily,
            exclude: ["helperText"],
          })}
          onSelect={({value}) => change(`typography.${variant}.fontFamily`, `${value}`)}
        />
      </FormControl>

      <TextField
        fullWidth
        helperText="Accepts CSS numbers like 14px, 1.2em, etc"
        id={labelFor("fontSize")}
        InputLabelProps={{shrink: true}}
        label={<ResetLabel name={`typography.${variant}.fontSize`}>Font Size</ResetLabel>}
        margin="normal"
        type="text"
        {...field(`typography.${variant}.fontSize`, {
          defaultValue:
            remToPx(muiTheme.typography[variant].fontSize, muiTheme) || defaults[variant].fontSize,
        })}
      />

      <FontWeightSelector
        label={<ResetLabel name={`typography.${variant}.fontWeight`}>Font Weight</ResetLabel>}
        margin="normal"
        {...field(`typography.${variant}.fontWeight`, {
          defaultValue: muiTheme.typography[variant].fontWeight || defaults[variant].fontWeight,
          exclude: ["helperText"],
        })}
      />

      <TextField
        fullWidth
        helperText="Accepts CSS numbers like 14px, 1.2em, etc"
        id={labelFor("lineHeight")}
        InputLabelProps={{shrink: true}}
        margin="normal"
        label={<ResetLabel name={`typography.${variant}.lineHeight`}>Line Height</ResetLabel>}
        type="text"
        {...field(`typography.${variant}.lineHeight`, {
          defaultValue: muiTheme.typography[variant].lineHeight || defaults[variant].lineHeight,
        })}
      />

      <TextField
        fullWidth
        helperText="Accepts CSS numbers like 14px, 1.2em, etc"
        id={labelFor("letterSpacing")}
        InputLabelProps={{shrink: true}}
        margin="normal"
        label={<ResetLabel name={`typography.${variant}.letterSpacing`}>Letter Spacing</ResetLabel>}
        type="text"
        {...field(`typography.${variant}.letterSpacing`, {
          defaultValue: muiTheme.typography[variant].letterSpacing || "0px",
        })}
      />

      <TextField
        fullWidth
        helperText="Accepts CSS numbers like 14px, 1.2em, etc"
        id={labelFor("marginBottom")}
        InputLabelProps={{shrink: true}}
        margin="normal"
        label={
          <ResetLabel name={`overrides.MuiTypography.${variant}.marginBottom`}>
            Margin Bottom
          </ResetLabel>
        }
        type="text"
        {...field(`overrides.MuiTypography.${variant}.marginBottom`, {
          defaultValue:
            unparse(muiTheme.components?.MuiTypography?.styleOverrides?.[variant]?.marginBottom) ||
            defaults[variant].marginBottom,
        })}
      />

      <ColorInput
        fullWidth
        id={labelFor("color")}
        input={{
          ...field(`overrides.MuiTypography.${variant}.color`, {
            defaultValue: muiTheme.palette.text.primary,
          }),
          onChange: hex => change(`overrides.MuiTypography.${variant}.color`, hex),
        }}
        label={<ResetLabel name={`overrides.MuiTypography.${variant}.color`}>Color</ResetLabel>}
        style={{marginBottom: 8, marginTop: 16}}
      />
    </div>
  )
}

HeadingForm.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  muiTheme: object.isRequired,
  variant: string.isRequired,
}

const useHeadingFormStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(12),
    width: "100%",
  },
  title: {
    fontSize: 21,
    fontWeight: "bold",
  },
}))

const HeadingSettings = ({change, field, muiTheme}) => {
  return (
    <Grid container spacing={3}>
      {["h1", "h2", "h3", "h4", "h5", "h6"].map(variant => (
        <Fragment key={`header-${variant}`}>
          <Grid item lg={6} md={12} xs={12}>
            <ThemeProvider theme={muiTheme}>
              <Typography variant={variant} gutterBottom={true}>
                Lorem ipsum
              </Typography>
              <Typography>
                dolor sit amet, consectetur adipiscing elit. Vestibulum viverra.
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <HeadingForm change={change} field={field} muiTheme={muiTheme} variant={variant} />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}

HeadingSettings.propTypes = {
  change: func.isRequired,
  field: func.isRequired,
  muiTheme: object.isRequired,
}

export default brandingSettings(HeadingSettings)
