import {TextField} from "@mui/material"
import {bool, func, object, shape, string} from "prop-types"
import {useState} from "react"

import UploadedImage from "../uploaded-image/uploaded-image"

export const AccountAvatarSettingsForm = ({classes, currentUser, updateAvatar}) => {
  const [isTemporarilyShowingOriginalAvatar, setIsTemporarilyShowingOriginalAvatar] = useState(
    false
  )

  const onUpdateAvatar = e => {
    updateAvatar(e.target.files[0])

    // If you update an avatar, after the upload completes, the `/photo` variant will still be the
    // old version, so this is to show the original until we're pretty sure the old one was deleted
    setIsTemporarilyShowingOriginalAvatar(true)

    setTimeout(() => setIsTemporarilyShowingOriginalAvatar(false), 1500)
  }

  return (
    <div className={classes.avatarSection}>
      <div className={classes.avatarWrapper}>
        {currentUser.isAvatarUploaded ? (
          <UploadedImage
            cacheBuster={currentUser.updatedAt}
            className={classes.avatar}
            src={`${process.env.REACT_APP_API_URL}/users/${currentUser.id}/avatar`}
            variant={isTemporarilyShowingOriginalAvatar ? "original" : "large"}
          />
        ) : (
          <div className={classes.noFile}>no file</div>
        )}
      </div>
      <TextField
        InputLabelProps={{shrink: true}}
        label="Profile Picture"
        margin="normal"
        name="avatar"
        onChange={onUpdateAvatar}
        type="file"
      />
    </div>
  )
}

AccountAvatarSettingsForm.propTypes = {
  classes: object,
  currentUser: shape({
    id: string.isRequired,
    isAvatarUploaded: bool,
    updatedAt: string.isRequired,
  }),
  updateAvatar: func.isRequired,
}

export default AccountAvatarSettingsForm
