import {Divider, MenuItem} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import {func, object, objectOf, oneOfType, string} from "prop-types"
import {useEffect, useRef, useState} from "react"

import {fetchObjectives} from "lib/api"

import {useTemplateContentContext} from "../../contexts/template-content-context"
import DOSelect from "../do-select/do-select"
import CustomObjectiveDialogContainer from "../templates/custom-objective-dialog-container"
import ObjectiveCounters, {CountersProptype} from "./objective-counters"

const useStyles = makeStyles({
  listSubheader: {
    background: "rgba(255,255,255,0.8)",
  },
  menuList: {
    paddingTop: 12,
  },
})

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////
/////  **README**
/////
/////  MUI Selects have a bit of a complicated relationship of undefined vs.
/////  null. the `value` prop is actually required, so we follow that pattern.
/////
/////  **INPUT**
/////
/////  "" - nothing has been selected yet (and the label is collapsed if you're
/////       using one)
/////  {id: null} - the "None" option is selected (and the label is shrunk above)
/////
/////  {id: "UUID", ...} - for actual objectives. this component only uses
/////                      the`id` attribute
/////  **OUTPUT**
/////
/////  N/A - this will never call on change in a way to represent
/////        "the user had not selected an objective"
/////
/////  {id: null} - if the user selects "None"
/////
/////  {id: "UUID", name: "name", key: "key", teamId: null/"UUID"} -
/////      if the user selects an actual objective
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

const SELECTED_NONE = "none"
const NEW_CUSTOM_OBJECTIVE = "custom"

const coerceValueToString = (value, availableObjectives) => {
  if (typeof value === "object") {
    if (value.id && availableObjectives.find(obj => obj.id === value.id)) return value.id
    return SELECTED_NONE
  }
  // Material UI needs a string for input values.
  // coerce falsy to strings, meaning the user has not selected any objective.
  return ""
}

const ObjectiveSelect = ({
  objectiveCounters,
  onChange,
  value,
  labelId,
  MenuProps = {},
  SelectDisplayProps = {},
  ...selectProps
}) => {
  const classes = useStyles()
  const [showCustomObjectiveDialog, setShowCustomObjectiveDialog] = useState(false)
  const [objectives, setObjectives] = useState([])
  const isMounted = useRef(true)

  useEffect(() => {
    fetchObjectives().then(res => isMounted.current && setObjectives(res))

    return () => (isMounted.current = false)
  }, [])

  const _onChange = e => {
    // Material UI considers ListSubheader onClick as an onChange
    if (e.target.value === undefined) return

    if (e.target.value === NEW_CUSTOM_OBJECTIVE) {
      setShowCustomObjectiveDialog(true)
      return
    }

    if (e.target.value === SELECTED_NONE) {
      onChange({id: null})
      return
    }

    const objective = objectives.find(obj => obj.id === e.target.value)
    onChange(objective)
  }

  const onSetCustomObjective = objective => {
    onChange(objective)
    setObjectives([...objectives, objective])
    setShowCustomObjectiveDialog(false)
  }

  const selectClasses = Object.keys(objectiveCounters).length > 0 ? {select: classes.menuList} : {}

  return (
    <>
      <DOSelect
        classes={selectClasses}
        fullWidth={true}
        MenuProps={{
          MenuListProps: {
            "aria-labelledby": labelId,
            ...MenuProps.MenuListProps,
          },
          ...MenuProps,
        }}
        onChange={_onChange}
        SelectDisplayProps={{
          "aria-labelledby": labelId,
          ...SelectDisplayProps,
        }}
        value={coerceValueToString(value, objectives)}
        {...selectProps}
      >
        <MenuItem value={SELECTED_NONE}>None</MenuItem>
        <Divider />
        {objectives.map(objective => (
          <MenuItem key={objective.id} value={objective.id}>
            {objective.name}
            {objectiveCounters[objective.id] && (
              <ObjectiveCounters counters={objectiveCounters[objective.id]} name={objective.name} />
            )}
          </MenuItem>
        ))}
        <MenuItem component="em" value={NEW_CUSTOM_OBJECTIVE}>
          New Custom Objective...
        </MenuItem>
      </DOSelect>
      {showCustomObjectiveDialog && (
        <CustomObjectiveDialogContainer
          onAdd={onSetCustomObjective}
          onClose={() => setShowCustomObjectiveDialog(false)}
        />
      )}
    </>
  )
}

ObjectiveSelect.propTypes = {
  MenuProps: object,
  SelectDisplayProps: object,
  labelId: string,
  objectiveCounters: objectOf(CountersProptype),
  onChange: func.isRequired,
  value: oneOfType([object, string]).isRequired,
}

ObjectiveSelect.defaultProps = {
  objectiveCounters: {},
}

export function getObjectiveCounters(templatePages, templateMessages) {
  const empty = {smses: 0, emails: 0, pages: 0}

  const pages = templatePages.reduce((acc, {page: cur}) => {
    if (cur.objectiveId) {
      const prev = acc[cur.objectiveId] || empty

      return {
        ...acc,
        [cur.objectiveId]: {
          ...prev,
          pages: prev.pages + 1,
        },
      }
    }

    return acc
  }, {})

  return templateMessages.reduce((acc, {message: cur}) => {
    if (cur.objectiveId) {
      const prev = acc[cur.objectiveId] || empty

      return {
        ...acc,
        [cur.objectiveId]: {
          ...prev,
          smses: cur.type === "sms" ? prev.smses + 1 : prev.smses,
          emails: cur.type === "email" ? prev.emails + 1 : prev.emails,
        },
      }
    }

    return acc
  }, pages)
}

export const TemplateContentObjectiveSelect = props => {
  const {templateMessages, templatePages} = useTemplateContentContext()

  const objectiveCounters = getObjectiveCounters(templatePages, templateMessages)

  return <ObjectiveSelect {...props} objectiveCounters={objectiveCounters} />
}

TemplateContentObjectiveSelect.propTypes = ObjectiveSelect.propTypes

export default ObjectiveSelect
