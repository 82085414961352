import {func, object} from "prop-types"
import {Component} from "react"

import TemplatePageContext from "../../contexts/template-page-context"
import MessageForm from "./message-form-LEGACY"

class TemplateMessageForm extends Component {
  state = {content_variables: this.props.templateMessage?.content_variables ?? {}}

  changeContentVariables = content_variables =>
    // Promise prevents race condition that can happen in message-form for sms
    // if setState and onSubmit are called simultaneously. The race condition that can
    // occur is onSubmit can receive stale content_variables.
    new Promise(resolve => {
      this.setState(
        state => ({
          ...state,
          content_variables: {
            ...state.content_variables,
            ...content_variables,
          },
        }),
        resolve
      )
    })

  onSubmitForm = formValues => {
    const {templateMessage} = this.props
    const {content_variables} = this.state

    return this.props.onSubmit({
      ...templateMessage,
      content_variables,
      message: {...templateMessage.message, ...formValues},
    })
  }

  onMakeMessageUnique = (messageId, attrs) =>
    this.props.onMakeMessageUnique(this.props.templateMessage, attrs)

  render() {
    const {templateMessage} = this.props
    const {content_variables} = this.state

    return (
      <TemplatePageContext.Provider
        value={{setContentVariables: this.changeContentVariables, content_variables}}
      >
        <MessageForm
          {...this.props}
          message={templateMessage.message}
          onDuplicate={this.onMakeMessageUnique}
          onSubmit={this.onSubmitForm}
        />
      </TemplatePageContext.Provider>
    )
  }
}

TemplateMessageForm.propTypes = {
  onMakeMessageUnique: func,
  onSubmit: func.isRequired,
  templateMessage: object.isRequired,
}

export default TemplateMessageForm
