import isPlainObject from "lodash/isPlainObject"
import set from "lodash/set"

export const a11yProps = (index, namespace = "team") => ({
  id: `${namespace}-branding-tab-${index}`,
  "aria-controls": `${namespace}-branding-tabpanel-${index}`,
})

export const removeEmptyValues = obj => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (isPlainObject(value)) return {...acc, [key]: removeEmptyValues(value)}

    return {...acc, [key]: value === "" ? undefined : value}
  }, {})
}

export const validCssNumber = value => {
  if (!value) return null

  if (
    !/^-?(?:0|[\d.]+(?:cm|mm|in|pc|pt|px|%|r?em|ch|lh|vh|vw|vmin|vmax|[\d]?))$/.test(value.trim())
  )
    return "Invalid CSS Number"
}

export const validators = {
  // Button Settings
  "typography.button.fontSize": [validCssNumber],
  "typography.button.lineHeight": [validCssNumber],
  "typography.button.letterSpacing": [validCssNumber],
  "overrides.MuiButton.containedPrimary.borderRadius": [validCssNumber],
  "overrides.MuiButton.containedPrimary.borderWidth": [validCssNumber],
  "overrides.MuiButton.containedPrimary.paddingLeft": [validCssNumber],
  "overrides.MuiButton.containedPrimary.paddingTop": [validCssNumber],

  // Header 1 Settings
  "typography.h1.fontSize": [validCssNumber],
  "typography.h1.lineHeight": [validCssNumber],
  "typography.h1.letterSpacing": [validCssNumber],
  "overrides.MuiTypography.h1.marginBottom": [validCssNumber],

  // Header 2 Settings
  "typography.h2.fontSize": [validCssNumber],
  "typography.h2.lineHeight": [validCssNumber],
  "typography.h2.letterSpacing": [validCssNumber],
  "overrides.MuiTypography.h2.marginBottom": [validCssNumber],

  // Header 3 Settings
  "typography.h3.fontSize": [validCssNumber],
  "typography.h3.lineHeight": [validCssNumber],
  "typography.h3.letterSpacing": [validCssNumber],
  "overrides.MuiTypography.h3.marginBottom": [validCssNumber],

  // Header 4 Settings
  "typography.h4.fontSize": [validCssNumber],
  "typography.h4.lineHeight": [validCssNumber],
  "typography.h4.letterSpacing": [validCssNumber],
  "overrides.MuiTypography.h4.marginBottom": [validCssNumber],

  // Header 5 Settings
  "typography.h5.fontSize": [validCssNumber],
  "typography.h5.lineHeight": [validCssNumber],
  "typography.h5.letterSpacing": [validCssNumber],
  "overrides.MuiTypography.h5.marginBottom": [validCssNumber],

  // Header 6 Settings
  "typography.h6.fontSize": [validCssNumber],
  "typography.h6.lineHeight": [validCssNumber],
  "typography.h6.letterSpacing": [validCssNumber],
  "overrides.MuiTypography.h6.marginBottom": [validCssNumber],

  // Paragraph (Body1) Settings
  "typography.body1.fontSize": [validCssNumber],
  "typography.body1.lineHeight": [validCssNumber],
  "typography.body1.letterSpacing": [validCssNumber],
  "overrides.MuiTypography.body1.marginBottom": [validCssNumber],

  // Link Settings
  "overrides.MuiLink.underlineHover.letterSpacing": [validCssNumber],

  // List Settings
  "custom.listItems.lineHeight": [validCssNumber],
  "custom.listItems.marginBottom": [validCssNumber],

  // Table Settings
  "custom.accountsTable.header.fontSize": [validCssNumber],
  "custom.accountsTable.columnHeader.fontSize": [validCssNumber],
  "custom.accountsTable.dataRow.fontSize": [validCssNumber],
}

const importantAttrs = [
  "overrides.MuiTypography.h1.marginBottom",
  "overrides.MuiTypography.h2.marginBottom",
  "overrides.MuiTypography.h3.marginBottom",
  "overrides.MuiTypography.h4.marginBottom",
  "overrides.MuiTypography.h5.marginBottom",
  "overrides.MuiTypography.h6.marginBottom",
  "overrides.MuiTypography.body1.marginBottom",
  "custom.listItems.marginBottom",
]

// The function used when submitting, turning inputs to what we should store
// in the db.
// Keep in mind that this could be called on a value that's already been parsed
// (or put in the field in parsed form); don't assume it's in unparsed form
// #idempotent-parse
export const parse = (value, name) => {
  if (name === "typography.fontSize") return parseInt(value, 10)
  if (name === "custom.palette") return value
  value = !name?.includes("fontWeight") && value && !isNaN(value) ? `${value}px` : value
  value =
    importantAttrs.includes(name) && !value?.includes("!important") ? `${value} !important` : value
  return value
}

// The function used to go from the db value to the input value
export const unparse = (value, name) =>
  typeof value === "string" ? value.replace(" !important", "") : value

export const getThemeFromInputs = inputs =>
  Object.keys(inputs).reduce((acc, name) => {
    const value = inputs[name].value

    if (value === undefined) return acc

    // We really only have to parse the inputs that have changed here (because
    // the values coming from the database are already parsed), but because
    // parsing should be idempotent, this is safe
    // #idempotent-parse
    set(acc, name, parse(value, name))

    return acc
  }, {})
