import {format} from "date-fns"

// Can probably be moved to a common src/components/charts/constants.js file
// once ready.
export const PERIOD = {
  MONTH: "Month",
  QUARTER: "Quarter",
  YEAR: "Year",
}

// TODO - Move to src/charts/helpers.js
export const formatPeriodLabel = ({currentPeriod, timestamp}) => {
  switch (true) {
    case typeof timestamp !== "string" || timestamp === "auto":
      return ""
    case currentPeriod === PERIOD.QUARTER:
      return `${format(new Date(timestamp), "QQQ")} ${format(new Date(timestamp), "yyyy")}`
    case currentPeriod === PERIOD.YEAR:
      return format(new Date(timestamp), "yyyy")
    default:
      return `${format(new Date(timestamp), "MMM")}. ${format(new Date(timestamp), "yyyy")}`
  }
}
