import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import debounce from "lodash/debounce"
import {node, string} from "prop-types"
import {useEffect, useRef, useState} from "react"

import {useContentBlockEditor} from "../content-block-editor/content-block-editor-context"

const useStyles = makeStyles(theme => ({
  ContentWrapper: {
    flexGrow: 1,
    transition: theme.transitions.create(["transform", "flex", "height"], {duration: 200}),
  },
  deviceFrame: {
    margin: "auto",
    boxShadow: theme.shadows[24],
    overflow: "auto",
  },
}))

const ContentWrapper = ({children, type}) => {
  const {device, scaleToFit} = useContentBlockEditor()
  const classes = useStyles()
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

  const style = {}

  const framed = device.type !== "desktop"

  if (framed) {
    // This relies on themes declaring this css var as a loophole
    // It's brittle but I'm not sure of a more straightforward way
    // to accomplish this
    style.background = type === "email" ? "white" : "var(--main-background-color)"
    style.flex = `0 0 ${device.width}px`
    style.height = device.height

    // 100 extra for breathing room
    const scale = viewportHeight / (device.height + 288)

    if (scaleToFit && scale < 1) {
      style.transform = `scale(${scale})`
      // The intent here is to get a real-world 50px margin between the preview
      // bar and the content. The order of operations here is intentional. We
      // want the 50px buffer to be added after the multiplication happens.
      const margin = (device.height - (scale * device.height + 50)) / -2
      style.marginTop = margin
      style.marginBottom = margin
    }
  }

  const resizeListener = useRef(
    debounce(() => {
      setViewportHeight(window.innerHeight)
    }, 50)
  )

  useEffect(() => {
    const listener = resizeListener.current
    window.addEventListener("resize", listener)

    return () => window.removeEventListener("resize", listener)
  })

  return (
    <div
      className={cx(classes.ContentWrapper, {
        [classes.deviceFrame]: !!framed,
        "is-mobile-preview": device?.type === "mobile",
        "is-tablet-preview": device?.type === "tablet",
      })}
      id="custom-css-root"
      style={style}
    >
      {children}
    </div>
  )
}

ContentWrapper.propTypes = {
  children: node.isRequired,
  type: string,
}

export default ContentWrapper
