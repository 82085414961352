import omit from "lodash/omit"
import pick from "lodash/pick"
import snakeCase from "lodash/snakeCase"
import queryString from "query-string"
import {createContext, forwardRef, useState} from "react"

import useQueryParams from "lib/hooks/use-query-params"

const initialState = {
  itemsPerPage: 20,
  page: 0,
  rows: [],
  totalCount: 0,
  filters: {},
}

export const tableState = nextState => {
  const {fetchResponse, itemsPerPage, ...rest} = nextState

  if (fetchResponse instanceof window.Response) {
    const page = parseInt(queryString.parse(fetchResponse.url.replace(/^.*\?/, "")).offset, 10)

    rest.totalCount = parseInt(fetchResponse.headers.get("x-total-count"), 10)
    rest.page = isNaN(page) ? rest.page : page / itemsPerPage
  }

  return {
    ...initialState,
    ...rest,
    itemsPerPage,
  }
}

export const prepStateForRequest = ({page, itemsPerPage, sortColumn, sortDirection, filters}) => ({
  offset: page * itemsPerPage,
  limit: itemsPerPage,
  sortColumn: snakeCase(sortColumn),
  sortDirection,
  ...filters,
})

const findTopLevelKey = (currentParam, validQueryParams) =>
  validQueryParams.filter(param => currentParam.includes(`${param}-`))

const namespaceQueryParam = (namespace, param) => {
  if (namespace) return `${namespace}-${param}`

  return param
}

const denamespaceQueryParam = (namespace, param) => {
  if (namespace) return param.split(`${namespace}-`)[1]

  return param
}

const isNamespaceMatch = (namespace, param) => {
  if (!namespace) return true

  return !!param.match(`${namespace}-`)
}

const objectToQueryParams = (prefix, obj) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`${prefix}-${key}`]: value,
    }),
    {}
  )

const transformQueryParamInput = (input, {namespace, validQueryParams}) =>
  Object.entries(pick(input, validQueryParams)).reduce((acc, [key, value]) => {
    if (typeof value === "object" && value !== null)
      return {
        ...acc,
        ...objectToQueryParams(namespaceQueryParam(namespace, key), value),
      }

    return {
      ...acc,
      [namespaceQueryParam(namespace, key)]: value,
    }
  }, {})

const transformLocalStateInput = (input, {validQueryParams}) => omit(input, validQueryParams)

const transformQueryParamOutput = (input, {namespace, validQueryParams}) =>
  Object.entries(input)
    .filter(([key]) => isNamespaceMatch(namespace, key))
    .reduce((acc, [key, value]) => {
      const [topLevelKey] = findTopLevelKey(key, validQueryParams)

      if (topLevelKey) {
        const subLevelKey = denamespaceQueryParam(namespace, key).split(`${topLevelKey}-`)[1]

        return {
          ...acc,
          [topLevelKey]: {...acc[topLevelKey], [subLevelKey]: value},
        }
      }

      return {
        ...acc,
        [denamespaceQueryParam(namespace, key)]: value,
      }
    }, {})

// FIXME: Nothing in this hook is memoized. setTableState, defaultState, opts, and the object
// returned from the hook should be memoized using useCallback etc. for the sake of avoiding
// unnecessary re-renders. Short of refactoring this hook, the consuming component's response
// can be wrapped in useMemo to protect performance. (Thanks @RobNealan)
export const useTable = defaultInput => {
  const defaultState = {...initialState, ...defaultInput}
  const opts = {
    namespace: defaultState?.namespace,
    ignoreTypeCoercion: defaultState?.ignoreTypeCoercion ?? [],
    validQueryParams: defaultState?.useQueryParams
      ? ["itemsPerPage", "page", "sortColumn", "sortDirection", "filters"]
      : [],
  }
  const {updateSearchQuery, ...queryState} = useQueryParams(
    transformQueryParamInput(defaultState, opts),
    opts.ignoreTypeCoercion
  )
  const [localState, setLocalState] = useState(transformLocalStateInput(defaultState, opts))

  const setTableState = input => {
    const queryParamOutput = transformQueryParamOutput(queryState, opts)
    const filters = {...localState?.filters, ...queryParamOutput?.filters, ...input?.filters}
    const nextState = tableState({...localState, ...queryParamOutput, ...input, filters})

    updateSearchQuery(transformQueryParamInput(nextState, opts))
    setLocalState(transformLocalStateInput(nextState, opts))
  }

  return {
    ...localState,
    ...transformQueryParamOutput(queryState, opts),
    setTableState,
    updateStateForRequest: params => {
      const queryParamOutput = transformQueryParamOutput(queryState, opts)
      const filters = {...localState?.filters, ...queryParamOutput?.filters, ...params?.filters}

      setTableState(params)
      return prepStateForRequest({...localState, ...queryParamOutput, ...params, filters})
    },
  }
}

export const {Provider, Consumer} = createContext()

export const tabular = defaultState => Component =>
  forwardRef((props, ref) => {
    const table = useTable(defaultState)

    return (
      <Provider value={table}>
        <Component ref={ref} {...props} {...table} />
      </Provider>
    )
  })

export const tableContext = Component =>
  forwardRef((props, ref) => (
    <Consumer>{context => <Component ref={ref} {...props} {...context} />}</Consumer>
  ))
