import {object} from "prop-types"
import React, {useEffect, useState} from "react"

import {useAdvancedTeamInsights} from "components/advanced-team-insights/advanced-team-insights-context"

import {fetchAdvancedInsightsAddedValueByTime} from "lib/api"

import ValueOverTimeChartTruncateHeader from "./value-over-time-chart-truncate-header"
import ValueOverTimeLineChart from "./value-over-time-line-chart"

const AddedValueByTime = () => {
  const {
    addedValueByTime,
    addedValueByTimeIsLoading,
    fetchInsights,
    filterParams,
    hasSocket,
  } = useAdvancedTeamInsights()
  const PERIODS = ["Month", "Quarter", "Year"]
  const [currentPeriod, setCurrentPeriod] = useState(PERIODS[0])

  // Fetch data
  useEffect(() => {
    if (hasSocket) {
      fetchInsights(
        "addedValueByTime",
        fetchAdvancedInsightsAddedValueByTime,
        [{...filterParams, period: currentPeriod.toLowerCase()}],
        {ignoreCaseConversionForPaths: [/value\.data\.\d/]}
      )
    }
  }, [fetchInsights, filterParams, hasSocket, currentPeriod])

  return (
    <div>
      <ValueOverTimeChartTruncateHeader
        heading="Added Value by Time"
        onChange={setCurrentPeriod}
        periods={PERIODS}
        selected={currentPeriod}
      />
      <ValueOverTimeLineChart
        businessDriverNames={addedValueByTime?.meta.businessDriverNames || []}
        chartData={addedValueByTime?.data || []}
        isLoading={addedValueByTimeIsLoading}
        period={currentPeriod}
      />
    </div>
  )
}

AddedValueByTime.propTypes = {classes: object.isRequired}

export default AddedValueByTime
