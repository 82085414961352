import {Collapse, FormControl, InputLabel, ListItemText, MenuItem, TextField} from "@mui/material"
import {func, object, string} from "prop-types"
import {PureComponent, useRef} from "react"
import {connect} from "react-redux"

import useForm from "lib/hooks/use-form"

import DOSelect from "../do-select/do-select"
import optionTypes from "./journey-title-option-types"
import {onUpdateTemplate} from "./template-actions"

const options = [
  {primary: "Default", type: optionTypes.NONE},
  {primary: "Contact Name", type: optionTypes.CONTACT_NAME},
  {primary: "Campaign Name", type: optionTypes.TEMPLATE_NAME},
  {primary: "Team Name", type: optionTypes.TEAM_NAME},
  {primary: "Custom", type: optionTypes.CUSTOM},
]

const JourneyTitleOptionsForm = ({
  classes,
  initialValues,
  journeyTitleOptions,
  onSubmit,
  teamName,
}) => {
  const customTitleInputRef = useRef(null)

  const {field} = useForm({
    initialValues,
    onSubmit,
    autoSubmitOnChange: true,
    enableReinitialize: true,
  })

  const onAnimationComplete = ref => (node, done) => {
    if (journeyTitleOptions.type === optionTypes.CUSTOM) focusInput(ref.current)

    node.addEventListener("transitioned", done, false)
  }

  const focusInput = node => {
    node.focus()
    node.scrollIntoView({behavior: "smooth"})
  }

  const secondary = type => {
    const {NONE, CONTACT_NAME, TEMPLATE_NAME, TEAM_NAME, CUSTOM} = optionTypes

    switch (type) {
      case NONE:
        return `The default title is a combination of your team's name and the contact's name (e.g. ${teamName} - John Doe)`
      case CONTACT_NAME:
        return "The name of the contact viewing the journey"
      case TEMPLATE_NAME:
        return "The name of the campaign that is being viewed"
      case TEAM_NAME:
        return "The name of your team"
      case CUSTOM:
        return "A custom title will display exactly as entered (e.g. My Custom Title)"
      default:
        return ""
    }
  }

  const renderOption = option => {
    const {primary, type} = option

    return (
      <MenuItem classes={{root: classes.menuItem}} key={type} value={type}>
        <ListItemText
          classes={{secondary: classes.listItemTextSecondary}}
          primary={primary}
          secondary={secondary(type)}
        />
      </MenuItem>
    )
  }

  return (
    <div style={{width: "100%"}}>
      <FormControl fullWidth={true}>
        <InputLabel>Title</InputLabel>
        <DOSelect
          classes={{root: classes.select}}
          className={classes.field}
          fullWidth={true}
          limitHeight={false}
          renderValue={type => options.find(option => option.type === type).primary}
          {...field("type")}
        >
          {options.map(renderOption)}
        </DOSelect>
      </FormControl>
      <Collapse
        addEndListener={onAnimationComplete(customTitleInputRef)}
        in={journeyTitleOptions.type === optionTypes.CUSTOM}
      >
        <TextField
          autoFocus={true}
          className={classes.field}
          fullWidth={true}
          inputRef={customTitleInputRef}
          label="Custom Title"
          {...field("custom")}
        />
      </Collapse>
    </div>
  )
}

JourneyTitleOptionsForm.propTypes = {
  classes: object.isRequired,
  initialValues: object.isRequired,
  journeyTitleOptions: object.isRequired,
  onSubmit: func.isRequired,
  teamName: string.isRequired,
}

class JourneyTitleOptionsContainer extends PureComponent {
  onUpdateJourneyTitleOptions = ({custom, type}) => {
    const {template} = this.props

    this.props.onUpdateTemplate(template.id, {
      journeyTitleOptions: {
        custom,
        type,
      },
    })
  }

  render() {
    const {
      classes,
      template: {journeyTitleOptions},
    } = this.props

    return (
      <JourneyTitleOptionsForm
        classes={classes}
        initialValues={journeyTitleOptions}
        journeyTitleOptions={journeyTitleOptions}
        onSubmit={this.onUpdateJourneyTitleOptions}
        teamName={this.props.template?.team?.name ?? "FI Name"}
      />
    )
  }
}

JourneyTitleOptionsContainer.propTypes = {
  classes: object,
  onUpdateTemplate: func.isRequired,
  template: object.isRequired,
}

const mapStateToProps = ({template}) => ({
  template,
})

const mapDispatchToProps = {onUpdateTemplate}

export default connect(mapStateToProps, mapDispatchToProps)(JourneyTitleOptionsContainer)
