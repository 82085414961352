import {Button, Typography} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import cx from "classnames"
import {useEffect, useState} from "react"

import Character01 from "components/superhero/character-01"
import Character02 from "components/superhero/character-02"
import Character03 from "components/superhero/character-03"
import Character04 from "components/superhero/character-04"
import Character05 from "components/superhero/character-05"
import Character06 from "components/superhero/character-06"
import Character07 from "components/superhero/character-07"
import Character08 from "components/superhero/character-08"
import Character09 from "components/superhero/character-09"
import Character10 from "components/superhero/character-10"
import Character11 from "components/superhero/character-11"
import Character12 from "components/superhero/character-12"
import Character13 from "components/superhero/character-13"
import Character14 from "components/superhero/character-14"
import Character15 from "components/superhero/character-15"
import Character16 from "components/superhero/character-16"
import Character17 from "components/superhero/character-17"
import Character18 from "components/superhero/character-18"
import Character19 from "components/superhero/character-19"
import Character20 from "components/superhero/character-20"
import Character21 from "components/superhero/character-21"
import Character22 from "components/superhero/character-22"
import Character23 from "components/superhero/character-23"
import Character24 from "components/superhero/character-24"
import Character25 from "components/superhero/character-25"

import Box from "../box/box"
import {useTemplateTargeting} from "./template-targeting-context"

const characters = [
  Character01,
  Character02,
  Character03,
  Character04,
  Character05,
  Character06,
  Character07,
  Character08,
  Character09,
  Character10,
  Character11,
  Character12,
  Character13,
  Character14,
  Character15,
  Character16,
  Character17,
  Character18,
  Character19,
  Character20,
  Character21,
  Character22,
  Character23,
  Character24,
  Character25,
]

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
  },
  welcome: {
    margin: theme.spacing(4),
    textAlign: "center",
  },
  character: {
    animationTimingFunction: "ease-out",
    animationDuration: "4s",
    animationFillMode: "forwards",
  },
  characterLeft: {
    animationName: "$swipeLeft",
  },
  "@keyframes swipeLeft": {
    "92%": {
      opacity: 1,
      transform: "initial",
    },
    "100%": {
      transform: "translateX(-300%) rotate(-5deg)",
      opacity: 0,
    },
  },
  characterRight: {
    animationName: "$swipeRight",
  },
  "@keyframes swipeRight": {
    "92%": {
      opacity: 1,
      transform: "initial",
    },
    "100%": {
      transform: "translateX(300%) rotate(5deg)",
      opacity: 0,
    },
  },
  characterScanner: {
    position: "relative",
    maxWidth: "33%",
    margin: `${theme.spacing(4)} auto`,
    textAlign: "center",
  },
  scannerLine: {
    position: "absolute",
    zIndex: 20,
    mixBlendMode: "multiply",
    borderRadius: theme.spacing(),
    height: theme.spacing(),
    width: "100%",
    top: theme.spacing(-1),
    opacity: 0,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[8],
    animation: "$scannerLine infinite alternate",
    animationTimingFunction: "ease",
    animationDuration: "2.5s",
  },
  "@keyframes scannerLine": {
    "70%": {
      top: theme.spacing(-1),
      opacity: 0,
    },
    "100%": {
      top: "100%",
      opacity: 1,
    },
  },
}))

const randomCharacterIndex = () => Math.floor(characters.length * Math.random())

const TargetingWelcome = () => {
  const {addTargetingGroup} = useTemplateTargeting()
  const classes = useStyles()
  const [characterIndex, setCharacterIndex] = useState(randomCharacterIndex())
  const [left, setLeft] = useState(false)
  const Character = characters[characterIndex]

  useEffect(() => {
    const interval = setInterval(() => {
      setCharacterIndex(randomCharacterIndex())
      setLeft(!!Math.round(Math.random()))
    }, 4000)

    return () => clearInterval(interval)
  })

  return (
    <Box className={classes.root}>
      <Typography variant="h5">
        Targeting can automatically add contacts to this campaign...
      </Typography>
      <Typography>
        Our targeting functionality allows you to describe the kind of contacts you want to be
        automatically added to your campaign. When we find a contact that matches your conditions,
        we'll add them to the campaign for you.
      </Typography>
      <div className={classes.characterScanner} key={characterIndex}>
        <div className={classes.scannerLine} />
        <Character
          className={cx(classes.character, {
            [classes.characterLeft]: left,
            [classes.characterRight]: !left,
          })}
          height={400}
        />
      </div>
      <div className={classes.welcome}>
        <Button color="primary" onClick={() => addTargetingGroup()} variant="contained">
          Start using targeting...
        </Button>
      </div>
    </Box>
  )
}

export default TargetingWelcome
