import {useCallback} from "react"

const useActionCreator = (actions, dispatch) =>
  Object.fromEntries(
    Object.keys(actions).map(action => [
      action,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback(payload => dispatch({type: actions[action], payload}), [action]),
    ])
  )

export default useActionCreator
