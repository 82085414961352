import {Collapse, Dialog, Typography} from "@mui/material"
import cx from "classnames"
import Hammer from "hammerjs"
import debounce from "lodash/debounce"
import get from "lodash/get"
import omit from "lodash/omit"
import memoize from "memoize-one"
import {array, arrayOf, bool, func, object, shape, string} from "prop-types"
import queryString from "query-string"
import {PureComponent} from "react"
import {MdWarning as WarningIcon} from "react-icons/md"

import {useContentBlockEditor} from "components/content-block-editor/content-block-editor-context"

import isTouchDevice from "lib/browser/is-touch-device"
import {compileCss} from "lib/custom-css"
import {getRawContentFromEditorState} from "lib/draft-js/editor-helpers"
import {safelyGetFontStyles} from "lib/fonts"
import {analytical} from "lib/hooks/use-analytics"
import {fullName} from "lib/names"
import setFavicon from "lib/set-favicon"

import TemplatePageContext from "../../contexts/template-page-context"
import ContentBlockEditor from "../content-block-editor/content-block-editor"
import DocumentTitle from "../document-title/document-title"
import ErrorBoundary from "../error-boundary/error-boundary"
import SwipeNotifier from "../swipe-notifier/swipe-notifier"
import journeyTitleOptionsTypes from "../templates/journey-title-option-types"
import {previewContext} from "../templates/preview-context"
import ContactLogin from "./contact-login"
import {JourneyContextProvider} from "./journey-context"
import JourneyHeader from "./journey-header"
import {getTemplatePageSibling, navigableTemplatePages} from "./journey-helpers"
import JourneyOwnerInfo from "./journey-owner-info"
import "./journey.css"
import Navigation from "./navigation"
import Page from "./page"
import SkipToMainLink from "./skip-to-main"

const createCompiledCustomCss = memoize((scope, source) => {
  try {
    return compileCss(scope, source)
  } catch (e) {
    // nothing to see here, it's bad css
  }
})

const getCurrentTemplatePageFromProps = props =>
  props.template.templatePages && props.pageSlug
    ? props.template.templatePages.find(tp => tp.page.slug === props.pageSlug)
    : null

const getJourneyContext = memoize(({template, ...context}) => ({
  ...context,
  template,
  fontFamily: template?.theme?.typography?.fontFamily,
}))

const isHammerTime = (props, hammer) =>
  props.isPageLoaded &&
  props.template &&
  props.template.templatePages &&
  navigableTemplatePages(props.template.templatePages, props.analyticsCache?.pagesCompleted)
    .length > 1 &&
  isTouchDevice() &&
  !hammer
const isInitialJourneyLoad = (props, prevProps) => props.id && props.id !== prevProps.id
const isTeamSet = (props, prevProps) =>
  props.template.team &&
  props.template.team.id &&
  (!prevProps.template.team || !prevProps.template.team.id)

const getDocumentTitle = ({contact, team, template}) => {
  const contactName = fullName(contact)
  const contactInDocTitle = !!contactName ? ` - ${contactName}` : ""
  const defaultTitle = team ? `${team.name}${contactInDocTitle}` : ""
  const options = template?.journeyTitleOptions
  const {CONTACT_NAME, TEMPLATE_NAME, TEAM_NAME, CUSTOM} = journeyTitleOptionsTypes

  switch (options?.type) {
    case CONTACT_NAME:
      return contact ? contactName : defaultTitle
    case TEMPLATE_NAME:
      return template?.name ?? defaultTitle
    case TEAM_NAME:
      return team?.name ?? defaultTitle
    case CUSTOM:
      return options?.custom ?? defaultTitle
    default:
      return defaultTitle
  }
}

const JourneyErrorFallback = ({classes}) => (
  <Typography className={`page-content ${classes.content} ${classes.error}`} component="div">
    <h5 className={classes.errorHeading}>
      <WarningIcon size={30} /> This page appears to be broken.
    </h5>
    We're sorry for the inconvenience. This error has been logged.
  </Typography>
)

JourneyErrorFallback.propTypes = {
  classes: object,
}

class Journey extends PureComponent {
  constructor(props) {
    super(props)
    // When being opened from a message, we want to immediately
    // track the open and then redirect to a non-tracked url
    // (e.g. remove message_id from query params)
    if (props.id) this.trackJourneyOpen(props.id)
  }

  state = {
    focusedEditor: "content",
    swipeStatus: "",
    isMobileMenuOpen: false,
    isLoginOpen: false,
  }

  componentDidMount() {
    if (!this.props.template.templatePages)
      this.props.getComponentData(this.props.journeySlug, {pageOptions: {isLive: true}})
  }

  componentDidUpdate(prevProps) {
    if (
      (!this.props.template.templatePages && !this.props.isComponentDataLoading) ||
      prevProps.user !== this.props.user
    ) {
      this.props.getComponentData(this.props.journeySlug, {pageOptions: {isLive: true}})
    }

    if (isHammerTime(this.props, this.hammer) && !!this.mainContainer) {
      this.hammer = new Hammer(this.mainContainer)
      this.hammer.on("swipe", this.handleSwipe)
    }

    if (isInitialJourneyLoad(this.props, prevProps)) this.trackJourneyOpen(this.props.id)

    if (isTeamSet(this.props, prevProps))
      setFavicon(this.props.template.team.id, this.props.template.team.updatedAt)
  }

  componentWillUnmount() {
    if (this.props.id) this.props.clearComponentData()
  }

  getCurrentPageSibling({reverse} = {}) {
    const {page: currentPage} = getCurrentTemplatePageFromProps(this.props)
    const {isPreviewMode, analyticsCache} = this.props
    let {
      template: {templatePages},
    } = this.props
    const pagesCompleted = analyticsCache?.pagesCompleted

    if (!templatePages || !currentPage) return null

    templatePages = templatePages.filter(
      tp => tp.page.isLive || !isPreviewMode || tp.page.id === currentPage.id
    )

    return getTemplatePageSibling(templatePages, currentPage.id, {reverse, pagesCompleted})?.page
  }

  mainRef = el => (this.mainContainer = el)

  handleSwipe = e => {
    const reverse = e.deltaX > 0,
      {
        template: {templatePages},
        analyticsCache,
      } = this.props,
      currentTemplatePage = getCurrentTemplatePageFromProps(this.props),
      // NB: Default to the currentTemplatePage when are at the beginning of the array and trying to swipe in reverse and
      // when we are at the end of the array and trying to swipe forward to avoid undefined behavior.
      {page: nextPage} =
        getTemplatePageSibling(templatePages, currentTemplatePage.pageId, {
          reverse,
          pagesCompleted: analyticsCache?.pagesCompleted,
        }) ?? currentTemplatePage

    if (nextPage) setTimeout(() => this.props.onNavigateToPage(nextPage.slug), 300)

    if (reverse) this.setState({swipeStatus: `${nextPage ? "" : "no-"}swipe-backward`})
    else this.setState({swipeStatus: `${nextPage ? "" : "no-"}swipe-forward`})

    setTimeout(() => this.setState({swipeStatus: ""}), 600)
  }

  onFooterEditorChange = debounce(
    editorState =>
      this.props.onUpdateTemplate({
        ...this.props.template,
        footerContent: getRawContentFromEditorState(editorState),
      }),
    2000
  )

  onUpdateContentVariables = content_variables => {
    const currentTemplatePage = getCurrentTemplatePageFromProps(this.props)

    return this.props.onUpdateTemplatePage(this.props.template.id, currentTemplatePage.id, {
      content_variables,
    })
  }

  openMobileMenu = () => this.setState({isMobileMenuOpen: true})
  closeMobileMenu = () => this.setState({isMobileMenuOpen: false})

  onFocusContent = () => this.setState({focusedEditor: "content"})
  onFocusFooter = () => this.setState({focusedEditor: "footer"})

  openLogin = () => this.setState({isLoginOpen: true})
  closeLogin = () => this.setState({isLoginOpen: false})

  trackJourneyOpen = () => {
    const {track, history} = this.props

    const queryParams = queryString.parse(window.location.search)
    const {message_id: messageId, page_id: pageId} = queryParams

    track("journey_opened", {messageId, pageId})
    if (messageId || pageId) {
      const newQueryParams = omit(queryParams, ["message_id", "page_id"])
      const newQueryString = Object.keys(newQueryParams).length
        ? `?${queryString.stringify(newQueryParams)}`
        : ""

      history.replace(`${window.location.pathname}${newQueryString}`)
    }
  }

  render() {
    const {
      account,
      accounts,
      analyticsCache,
      classes,
      contact,
      customCss,
      id,
      isEditable,
      isPreviewMode,
      onNavigateToPage,
      onUpdateContact,
      pagesConfirmed,
      template,
      isPageLoaded,
      user,
      pageUrlGenerator,
      onChange,
      onUpdatePage,
      survey,
      rewards,
    } = this.props

    if (!isPageLoaded || (id && !this.props.isTemplateLoaded)) return <div />

    const {logoFileId, team} = template
    const currentTemplatePage = getCurrentTemplatePageFromProps(this.props) || {}
    const currentPage = currentTemplatePage?.page
    const nextPage = this.getCurrentPageSibling()
    const prevPage = this.getCurrentPageSibling({reverse: true})
    const isCurrentPageConfirmed = pagesConfirmed && pagesConfirmed.includes(currentPage.id)
    const {focusedEditor, isMobileMenuOpen, swipeStatus, isLoginOpen} = this.state
    const owner = this.props.owner || template.owner
    const documentTitle = getDocumentTitle({contact, team, template})
    const journeyContext = getJourneyContext({
      analyticsCache,
      themeClasses: classes,
      journeyId: id,
      journeySlug: this.props.journeySlug,
      pageId: currentPage.id,
      templatePageId: currentTemplatePage.id,
      account,
      accounts,
      contact,
      onUpdateContact,
      onNavigateToPage,
      owner,
      survey,
      rewards,
      template,
      openLogin: this.openLogin,
      isLoggedIn: user && user.id === contact.id,
    })
    // This can be slightly racey... analytics cache happens asynchronously, so
    // if we need this to be more tightly coupled we'll need to get the
    // page_completed actions manually here.
    const templatePages = navigableTemplatePages(
      template.templatePages,
      analyticsCache?.pagesCompleted
    )

    if (!currentPage.id)
      return (
        <div className={classes.pageNotFoundWrapper}>
          <Typography align="center" variant="h1">
            404
          </Typography>
          <Typography
            align="center"
            className={classes.pageNotFoundSubtitleText}
            variant="subtitle1"
          >
            Page Not Found
          </Typography>
        </div>
      )

    if (id && template.isAuthRequired && (!user || contact.id !== user.id))
      return (
        <JourneyContextProvider legacyInitialState={journeyContext}>
          <ContactLogin contact={contact} journeyId={id} template={template} />
        </JourneyContextProvider>
      )

    const compiledCss = createCompiledCustomCss("#footer-content", template?.css || "")

    return (
      <JourneyContextProvider legacyInitialState={journeyContext}>
        {!isEditable && <DocumentTitle isNakedTitle={true} title={documentTitle} />}
        {!isEditable && id && (
          <Dialog
            onClose={this.closeLogin}
            open={isLoginOpen}
            PaperProps={{classes: {root: classes.contactLoginPaperRoot}}}
          >
            <ContactLogin
              contact={contact}
              isModal={true}
              journeyId={id}
              onLoginSuccess={this.closeLogin}
              template={template}
            />
          </Dialog>
        )}
        <div className={classes.journeyWrapper}>
          {/*This skipToMain link would be better outside the wrapper but it breaks the tests there because enzyme doesn't handle Contexts properly*/}
          <SkipToMainLink />
          <JourneyHeader
            analyticsCache={analyticsCache}
            logoFileId={logoFileId}
            openMobileMenu={this.openMobileMenu}
            pages={templatePages.map(tp => tp.page)}
            reward={template.reward}
            showMobileMenuIcon={templatePages.length > 1}
            showProgressMeter={!template.hideProgressRing}
            teamName={template.team?.name}
          />
          <Navigation
            completedPages={get(analyticsCache, "pagesCompleted", [])}
            currentPageId={currentPage.id}
            isMobileMenuOpen={isMobileMenuOpen}
            isSortable={isEditable}
            onCloseMobileMenu={this.closeMobileMenu}
            onSortPages={this.props.onSortPages}
            pageUrlGenerator={pageUrlGenerator}
            templatePages={templatePages}
            visitedPages={get(analyticsCache, "pagesViewed", [])}
          />
          <main
            className={classes.journey}
            ref={this.mainRef}
            style={safelyGetFontStyles(template.theme)}
          >
            <style>{customCss}</style>
            {isTouchDevice() && templatePages.length > 1 && <SwipeNotifier />}
            <div className={cx("page-wrapper", classes.pageWrapper)}>
              <ErrorBoundary
                errorFallback={<JourneyErrorFallback classes={classes} />}
                key={currentPage.id}
              >
                <Collapse
                  aria-hidden="true"
                  className={classes.notLiveWarning}
                  in={!currentPage.isLive}
                >
                  <Typography
                    className={classes.notLiveWarningContent}
                    component="span"
                    variant="h5"
                  >
                    This page is not live yet.
                  </Typography>
                </Collapse>
                <TemplatePageContext.Provider
                  value={{
                    setContentVariables: this.onUpdateContentVariables,
                    content_variables: currentTemplatePage.content_variables,
                  }}
                >
                  <>
                    <Page
                      key={currentPage.id}
                      {...currentPage}
                      analyticsCache={analyticsCache}
                      classes={classes}
                      isConfirmed={isCurrentPageConfirmed}
                      isEditable={isEditable}
                      isFocused={focusedEditor === "content"}
                      journeyId={id}
                      nextPage={nextPage}
                      onChange={onChange}
                      onEditorFocus={this.onFocusContent}
                      onUpdatePage={onUpdatePage}
                      pageUrlGenerator={pageUrlGenerator}
                      prevPage={prevPage}
                      swipeStatus={swipeStatus}
                    />
                    <div
                      className={`footer-content ${classes.footerContent} ${swipeStatus}`}
                      data-testid="footer-content"
                      id="footer-content"
                      style={template?.styles ?? {}}
                    >
                      {<style>{compiledCss}</style>}
                      {template.id && (
                        <ContentBlockEditor
                          initialContainer={{...template, id: template.id}}
                          containerId={template.id}
                          initialMode={isEditable ? "Edit" : "View"}
                        />
                      )}
                    </div>
                  </>
                </TemplatePageContext.Provider>
              </ErrorBoundary>
            </div>
            {owner && team && (
              <JourneyOwnerInfo
                {...owner}
                isSidebarOpen={isEditable && !isPreviewMode}
                name={fullName(owner)}
                options={template.supportOwnerOptions}
                teamName={team.name}
              />
            )}
          </main>
        </div>
      </JourneyContextProvider>
    )
  }
}

Journey.defaultProps = {
  isEditable: false,
}

Journey.propTypes = {
  analyticsCache: object,
  classes: object.isRequired,
  clearComponentData: func,
  account: object,
  accounts: arrayOf(object),
  contact: object,
  customCss: string,
  getComponentData: func,
  history: object,
  id: string,
  isComponentDataLoading: bool,
  isEditable: bool,
  isPageLoaded: bool,
  isPreviewMode: bool,
  isTemplateLoaded: bool,
  journeySlug: string,
  onChange: func,
  onNavigateToPage: func.isRequired,
  onSortPages: func,
  onUpdateContact: func,
  onUpdatePage: func,
  onUpdateTemplate: func,
  onUpdateTemplatePage: func,
  owner: object,
  pageId: string,
  pageSlug: string,
  pageUpdatedAt: string,
  pageUrlGenerator: func.isRequired,
  pagesConfirmed: array,
  rewards: object,
  setA11yMessage: func,
  survey: object,
  template: shape({
    journeyTitleOptions: shape({
      custom: string,
      type: string,
      value: string,
    }),
    pages: arrayOf(object),
    owner: object,
    team: shape({
      name: string,
    }),
  }),
  track: func.isRequired,
  user: object,
}

export {Journey}

// NB: We have to override `isPreviewMode` when using contentBlocks because
// unlike do-editor, the content-block-editor does not rely on a `#preview`
// hash in its url to determine if `isPreviewMode` is activated.
// See: template-container.js `isPreviewMode`
const JourneyOverrides = props => {
  const {isPreviewMode: isContentBlockEditorPreviewMode} = useContentBlockEditor()

  return <Journey {...props} isPreviewMode={isContentBlockEditorPreviewMode} />
}

JourneyOverrides.propTypes = {
  isPreviewMode: bool,
}

export default previewContext(analytical(JourneyOverrides))
