import CardOnFileAnyMerchants from "./card-on-file-any"
import CardOnFileSpecficMerchants from "./card-on-file-specific"
import DirectDepositCompleted from "./direct-deposit-completed"
import DirectDepositStarted from "./direct-deposit-started"
import MobileAppDownload from "./mobile-app-download"
import PageCompleted from "./page-completed"

// Configs for reward step conditions. Add new conditions here, then build the related
// edit/view component.

export const defaultStep = {
  component: CardOnFileAnyMerchants,
  initialValues: {
    condition: "card_on_file_any",
    meta: {
      anyMerchants: "true",
      requiredSwitches: undefined,
      anyTemplates: "true",
      requiredTemplates: [],
    },
    value: undefined,
  },
  label: "Card On File Completed Switches",
}

export const stepComponents = [
  defaultStep,
  {
    component: CardOnFileSpecficMerchants,
    initialValues: {
      condition: "card_on_file_specific",
      meta: {
        anyMerchants: "false",
        requiredMerchants: [],
        anyTemplates: "true",
        requiredTemplates: [],
      },
      value: undefined,
    },
    label: "Card On File Completed with Specific Merchants",
  },
  {
    component: DirectDepositStarted,
    initialValues: {
      condition: "direct_deposit_started",
      meta: {templateIds: []},
      value: undefined,
    },
    label: "Direct Deposit Started",
  },
  {
    component: DirectDepositCompleted,
    initialValues: {
      condition: "direct_deposit_completed",
      meta: {evaluate: "count", templateIds: [], threshold: undefined},
      value: undefined,
    },
    label: "Direct Deposit Completed",
  },
  {
    component: MobileAppDownload,
    initialValues: {
      condition: "mobile_app_download_link_clicked",
      meta: {templateIds: []},
      value: undefined,
    },
    label: "Mobile App Download Link Clicked",
  },
  {
    component: PageCompleted,
    initialValues: {
      condition: "page_completed",
      meta: {templateId: "", templatePageId: ""},
      value: undefined,
    },
    label: "Page Completed",
  },
]
