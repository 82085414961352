import {Button, Dialog, DialogActions, DialogContent, Tab, Tabs} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import cloneDeep from "lodash/cloneDeep"
import merge from "lodash/merge"
import {func, object} from "prop-types"
import {useEffect, useMemo, useState} from "react"
import {connect} from "react-redux"

import {TemplateBrandingSettingsContextProvider} from "components/teams/branding-settings/branding-settings-context"
import ButtonSettings from "components/teams/branding-settings/button-settings"
import GeneralSettings from "components/teams/branding-settings/general-settings"
import HeadingSettings from "components/teams/branding-settings/heading-settings"
import {
  a11yProps,
  getThemeFromInputs,
  parse,
  unparse,
  validators,
} from "components/teams/branding-settings/helpers"
import LinkSettings from "components/teams/branding-settings/link-settings"
import ListSettings from "components/teams/branding-settings/list-settings"
import RewardSettings from "components/teams/branding-settings/reward-settings"
import TableSettings from "components/teams/branding-settings/table-settings"
import {onUpdateTemplate} from "components/templates/template-actions"

import {loadFontsFromTheme} from "lib/fonts"
import useFeatures from "lib/hooks/use-features"
import useForm from "lib/hooks/use-form"
import createTeamTheme from "themes/team-theme-creator"

import {controlAccess} from "../access-control/access-controlled"
import BrandingTabPanel from "../teams/branding-settings/branding-tab-panel"
import LogoSettings from "./branding-settings/logo-settings"

const defaultTheme = createTeamTheme({})

// TODO: this should probably be in the database?
const emptyThemeOverrides = {}

const ensureTopLevels = theme => {
  if (!theme.overrides) theme.overrides = {}
  if (!theme.palette) theme.palette = {}
  if (!theme.typography) theme.typography = {}
  if (!theme.custom) theme.custom = {}
  return theme
}

export const BrandingSettings = ({classes, team, template, onUpdateTemplate, onClose}) => {
  const [logoFileId, setLogoFileId] = useState(template?.logoFileId)
  const [tabIndex, setTabIndex] = useState(0)
  const {hasFeature} = useFeatures()

  const {change, field, handleSubmit, inputs, resetForm} = useForm({
    enableReinitialize: true,
    // #theme-overrides (this hashtag spans into gondor)
    initialValues: template?.themeOverrides || emptyThemeOverrides,
    parse,
    unparse,
    onSubmit: theme =>
      onUpdateTemplate(template.id, {
        theme: ensureTopLevels(theme),
        logoFileId,
      }),
    skipUndefined: true,
    validators,
  })

  const onChangeLogoFileId = file => setLogoFileId(file.id)
  const onRemoveLogoFileId = () => setLogoFileId(null)

  const theme = getThemeFromInputs(inputs)

  const muiTheme = useMemo(() => {
    const templateOverrides = getThemeFromInputs(inputs)

    const clonedTheme = cloneDeep(team.themeStyles)

    merge(clonedTheme, templateOverrides)

    return !template ? defaultTheme : createTeamTheme(clonedTheme)
  }, [template, inputs, team.themeStyles])

  useEffect(() => {
    loadFontsFromTheme({
      overrides: muiTheme.overrides,
      typography: muiTheme.typography,
    })
  }, [muiTheme.overrides, muiTheme.typography])

  const onReset = () => {
    setLogoFileId(template?.logoFileId)
    resetForm()
    onClose()
  }

  const onSaveAndClose = () => {
    handleSubmit()
    onClose()
  }

  return (
    <TemplateBrandingSettingsContextProvider
      change={change}
      field={field}
      inputs={inputs}
      muiTheme={muiTheme}
      theme={theme}
    >
      <Dialog onClose={onClose} open={true} fullWidth={true} maxWidth="lg">
        <DialogContent className={classes.root}>
          <Tabs
            aria-label="Template branding tabs"
            className={classes.tabs}
            indicatorColor="secondary"
            textColor="inherit"
            orientation="vertical"
            onChange={(e, i) => setTabIndex(i)}
            value={tabIndex}
          >
            <Tab
              classes={{root: classes.tabWrapper}}
              label="General"
              {...a11yProps(0, "template")}
            />
            <Tab classes={{root: classes.tabWrapper}} label="Logo" {...a11yProps(1, "template")} />
            <Tab
              classes={{root: classes.tabWrapper}}
              label="Buttons"
              {...a11yProps(2, "template")}
            />
            <Tab
              classes={{root: classes.tabWrapper}}
              label="Headings"
              {...a11yProps(3, "template")}
            />
            <Tab classes={{root: classes.tabWrapper}} label="Links" {...a11yProps(4, "template")} />
            <Tab classes={{root: classes.tabWrapper}} label="Lists" {...a11yProps(5, "template")} />

            {hasFeature("accounts-table") && (
              <Tab
                classes={{root: classes.tabWrapper}}
                label="Table"
                {...a11yProps(6, "template")}
              />
            )}
            <Tab
              classes={{root: classes.tabWrapper}}
              label="Rewards"
              {...a11yProps(7, "template")}
            />
          </Tabs>
          <div className={classes.tabPanels}>
            <BrandingTabPanel namespace="template" value={tabIndex} index={0}>
              <GeneralSettings />
            </BrandingTabPanel>
            <BrandingTabPanel namespace="template" value={tabIndex} index={1}>
              <LogoSettings
                logoFileId={logoFileId}
                onChangeLogoFileId={onChangeLogoFileId}
                onRemoveLogoFileId={onRemoveLogoFileId}
                onUpdateTemplate={onUpdateTemplate}
              />
            </BrandingTabPanel>
            <BrandingTabPanel namespace="template" value={tabIndex} index={2}>
              <ButtonSettings />
            </BrandingTabPanel>
            <BrandingTabPanel namespace="template" value={tabIndex} index={3}>
              <HeadingSettings />
            </BrandingTabPanel>
            <BrandingTabPanel namespace="template" value={tabIndex} index={4}>
              <LinkSettings />
            </BrandingTabPanel>
            <BrandingTabPanel namespace="template" value={tabIndex} index={5}>
              <ListSettings />
            </BrandingTabPanel>

            {hasFeature("accounts-table") && (
              <BrandingTabPanel namespace="template" value={tabIndex} index={6}>
                <TableSettings />
              </BrandingTabPanel>
            )}
            <BrandingTabPanel namespace="template" value={tabIndex} index={7}>
              <RewardSettings />
            </BrandingTabPanel>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="grey" onClick={onReset} type="button">
            Cancel
          </Button>
          <Button
            className={classes.marginButton}
            color="primary"
            type="button"
            variant="outlined"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            className={classes.marginButton}
            color="primary"
            type="button"
            variant="contained"
            onClick={onSaveAndClose}
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </TemplateBrandingSettingsContextProvider>
  )
}

BrandingSettings.propTypes = {
  classes: object,
  onClose: func.isRequired,
  onUpdateTemplate: func.isRequired,
  template: object,
  team: object,
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  tabPanels: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tabWrapper: {
    alignItems: "flex-start",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  // We should get this for free after upgrading past MUI 4.6
  marginButton: {
    marginLeft: 8,
  },
})

export default withStyles(styles)(
  connect(({session, template}) => ({team: session.team, template}), {
    onUpdateTemplate,
  })(controlAccess({requiredPermissions: "templates:edit"})(BrandingSettings))
)
