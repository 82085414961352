import {bool, node, string} from "prop-types"

import useFeatures from "lib/hooks/use-features"

const Feature = ({children, emptyComponent = null, featureKey}) => {
  const {hasFeature} = useFeatures()

  // If a featureKey is falsey we can assume the intent is to show the content
  // because there is no way to turn on a non-existant feature for a team.
  if (!featureKey || hasFeature(featureKey)) return children

  return emptyComponent
}

Feature.propTypes = {
  beta: bool,
  diagnostic: bool,
  children: node,
  emptyComponent: node,
  featureKey: string,
}

export const feature = (featureKey, Component) => props => (
  <Feature featureKey={featureKey}>
    <Component {...props} />
  </Feature>
)

export default Feature
